import { useEffect, useState } from 'react'
import { FilterProps, Renderer } from 'react-table'
import cx from 'classnames'
import Dropdown from 'rc-dropdown'
import Menu, { Divider, Item as MenuItem } from 'rc-menu'

import Button from 'common/components/button'
import CheckboxComponent from 'common/components/checkbox'
import { FilterHub } from 'common/icons'

type Props = {
  defaultFilters: string[]
}

function Filter({ defaultFilters }: Props) {
  return function SelectColumnFilter<D extends {}>({
    column: { filterValue, setFilter, preFilteredRows, id, filteredRows },
  }): Renderer<FilterProps<D>> {
    const [checkedItems, setCheckedItems] = useState<string[]>(
      filterValue || defaultFilters || []
    )
    const [isOpened, setIsOpened] = useState(false)

    const onClickCheckbox = (key) => {
      if (checkedItems.some((currentKey) => currentKey === key)) {
        setCheckedItems((checkedItems) =>
          checkedItems.filter((item) => item !== key)
        )
      } else {
        setCheckedItems((checkedItems) => [...checkedItems, key])
      }
    }
    const hasTableEnoughHeightToFitDropdown = filteredRows.length > 5

    useEffect(() => {
      setFilter(checkedItems)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
      <Dropdown
        trigger={['click']}
        animation="slide-up"
        key={`${filteredRows.length}`}
        overlay={
          <CustomMenu
            checkedItems={checkedItems}
            options={[
              {
                key: 'approved',
                onClick: onClickCheckbox,
                label: 'Approved',
              },
              { key: 'dropped', onClick: onClickCheckbox, label: 'Dropped' },
              { key: 'live', onClick: onClickCheckbox, label: 'Live' },
              { key: 'pending', onClick: onClickCheckbox, label: 'Pending' },
            ]}
            onConfirm={() => {
              setIsOpened(false)
              setFilter(checkedItems)
            }}
            onReset={() => setCheckedItems(defaultFilters)}
          />
        }
        visible={isOpened}
        getPopupContainer={
          hasTableEnoughHeightToFitDropdown
            ? (trigger) => {
                // move the dropdown when scrolling a parent of the table
                return trigger.parentNode as HTMLElement
              }
            : () => {
                // Allows to display the dropdown without scrolling
                // It would be better to have a min height for the table with an empty state
                return document.body
              }
        }
        onVisibleChange={setIsOpened}
      >
        <Button
          className="h-auto"
          variant="icon"
          prefixIcon={<FilterHub size={4} className="text-base-700" />}
        />
      </Dropdown>
    )
  }
}

const CustomMenu = ({
  options,
  checkedItems,
  onConfirm,
  onReset,
}: {
  options: any[]
  checkedItems: string[]
  onConfirm: () => void
  onReset: () => void
}) => (
  <Menu
    selectable={false}
    className="z-40 px-4 pt-2 bg-gold-50 border border-gold-200"
  >
    {options.map((option) => (
      <MenuItem
        disabled={option.isDisabled}
        key={option.key}
        className={cx(
          'group flex items-center text-base-700 text-sm font-normal py-2 px-4 -mx-4',
          {
            'cursor-not-allowed pointer-events-auto opacity-50':
              option.isDisabled,
            'cursor-pointer hover:bg-gold-300': !option.isDisabled,
          }
        )}
      >
        <CheckboxComponent
          isChecked={checkedItems?.some((key) => key === option.key)}
          onChange={() => option.onClick(option.key)}
          className="mr-2"
          label={option.label}
          labelColor="text-base-700"
        />
      </MenuItem>
    ))}
    <Divider />
    <MenuItem className="flex justify-between">
      <Button variant="link" onClick={onConfirm}>
        OK
      </Button>
      <Button
        onClick={() => {
          onReset()
        }}
        variant="link"
      >
        Reset
      </Button>
    </MenuItem>
  </Menu>
)

export default Filter
