import { memo, useState } from 'react'

import { restApi } from 'common/api'
import { SelectValue } from 'common/components/BaseSelect'
import ConfirmationModal from 'common/components/confirmationModal'
import Modal from 'common/components/Modal'

import CsvUploadResults from './csvUploadResults/CsvUploadResults'
import InputResultsTabs from './InputResultsTabs'

import styles from './InputResultModal.module.css'

type Props = {
  isOpen: boolean
  projectName: string | undefined
  campaignName: string
  campaignId: string
  onClose: () => void
  onSubmit: () => void
}

const modes: SelectValue[] = [
  { value: 'excel', label: 'Copy and paste from Excel' },
  { value: 'csv', label: 'Upload a .CSV file' },
]
export type Value = typeof modes[number]['value']

const InputResultModal: React.FC<Props> = ({
  isOpen,
  onClose,
  projectName,
  campaignName,
  campaignId,
  onSubmit,
}) => {
  const [isExitConfirmationModalOpened, setIsExitConfirmationModalOpened] =
    useState(false)
  const [selectedMode, setSelectedMode] = useState<Value>('excel')

  return (
    <Modal
      key={`${isOpen}`}
      className={styles.modal}
      centered
      closable
      maskClosable={false}
      onClose={() => setIsExitConfirmationModalOpened(true)}
      hasMargin={false}
      visible={isOpen}
      size="large"
    >
      <div className="flex flex-col justify-between items-stretch h-full">
        {selectedMode === 'excel' && (
          <InputResultsTabs
            modes={modes}
            selectedMode={selectedMode}
            onChangeMode={setSelectedMode}
            projectName={projectName}
            campaignName={campaignName}
            campaignId={campaignId}
            onSubmit={onSubmit}
            onClose={() => setIsExitConfirmationModalOpened(true)}
          />
        )}
        {selectedMode === 'csv' && (
          <CsvUploadResults
            selectedMode={selectedMode}
            onChangeMode={setSelectedMode}
            projectName={projectName}
            campaignName={campaignName}
            campaignId={campaignId}
            onClose={() => setIsExitConfirmationModalOpened(true)}
            modes={modes}
            onUploadCsv={(csvFile) => {
              const formdata = new FormData()
              formdata.append('results', csvFile)
              return restApi
                .post(`/campaigns/${campaignId}/results-csv`, formdata)
                .then(() => {
                  onSubmit()
                })
            }}
          />
        )}
      </div>
      <ConfirmationModal
        data-cy="close-inputResult-modal"
        data-testid="close-inputResult-modal"
        title="Cancel uploading your results?"
        confirmationText="Are you sure you would like to stop uploading your results and leave this page?"
        confirmButtonText="Yes please"
        cancelButtonText="Cancel"
        open={isExitConfirmationModalOpened}
        onConfirm={() => {
          setIsExitConfirmationModalOpened(false)
          onClose()
        }}
        onCancel={() => {
          setIsExitConfirmationModalOpened(false)
        }}
      />
    </Modal>
  )
}

const InputResultModalWithResetState = (props: Props) => (
  <InputResultModal key={`${props.isOpen}`} {...props} />
)

// The parent component updates every few seconds, so we need to memoize this to avoid flickering the table
export default memo(InputResultModalWithResetState)
