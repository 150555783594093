import Button from 'common/components/button'
import { ChevronDown, ChevronUp } from 'common/icons'

import type { TopicsProps } from '../Topics'

type Props = {
  className?: string
} & Pick<TopicsProps, 'isCollapsed' | 'isCollapsible' | 'onCollapseClick'>

const ToggleButton = ({
  isCollapsed,
  isCollapsible = false,
  onCollapseClick,
  className,
}: Props) => {
  return isCollapsible ? (
    <Button variant="icon" className={className} onClick={onCollapseClick}>
      {isCollapsed ? <ChevronDown /> : <ChevronUp />}
    </Button>
  ) : null
}

export default ToggleButton
