import React from 'react'
import cx from 'classnames'

import Alert from 'common/components/alert'
import Loader from 'common/components/loaders/Loader'
import SingleSelect from 'common/components/singleSelect'
import { stringToNumber } from 'common/helpers/numeric'

import { SplitSizeData } from '../interface'

type Props = {
  data?: SplitSizeData
  isLoading?: boolean
  isDisabled?: boolean
  className?: string
  value?: number
  updateSplitSizeNumber: (splitNumber: number) => void
}

const SplitSize = ({
  data,
  className,
  updateSplitSizeNumber,
  isLoading = false,
  isDisabled = false,
  value,
}: Props) => {
  if (isLoading) {
    return (
      <div style={{ height: '146px' }}>
        <Loader isLoading={isLoading} />
      </div>
    )
  }
  if (!data) {
    return null
  }
  const {
    headline,
    show_other_split_options,
    other_split_options,
    split_result,
    warning,
  } = data

  return (
    <div className={cx('text-sm', className)}>
      <hr className="w-full pb-6 border-gold-400" />
      <span
        className="text-base-700 font-medium mr-1"
        data-testid="splitsize-headline"
      >
        {headline}
      </span>
      <span className="font-medium text-gold-500" data-testid="splitsize-value">
        {split_result}
      </span>
      {warning && (
        <Alert
          type="warn"
          className="flex items-center mt-6"
          showIcon={true}
          data-testid="splitsize-warning"
        >
          {warning}
        </Alert>
      )}
      {show_other_split_options ? (
        <React.Fragment>
          <p className="py-2.5 text-base-700 font-normal">
            Unable to test the recommended number of splits? No problem.
          </p>

          <div className="flex items-center font-normal text-base-700">
            <p>Select the right number</p>
            <SingleSelect
              className="regular w-24 ml-2.5"
              data-testid="splitsize-selection"
              isDisabled={isDisabled}
              onChange={(val) => {
                updateSplitSizeNumber(stringToNumber(val?.value) ?? 0)
              }}
              value={value?.toString()}
              menuPlacement="top"
              placeholder="Select an option"
              options={other_split_options.map((option: number) => ({
                label: option.toString(),
                value: option.toString(),
              }))}
            />
          </div>
        </React.Fragment>
      ) : null}
    </div>
  )
}

export default SplitSize
