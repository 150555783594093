import { useLayoutEffect, useRef, useState } from 'react'
import cx from 'classnames'

import { useWindowDimensions } from 'common/hooks/useWindowDimensions'

export type RightPaneVisibility = 'auto' | 'hidden' | 'visible' | 'none'

type Props = {
  visibility: RightPaneVisibility
  children: React.ReactNode
  scrollableParentId?: string
  onOutsideClick?: () => void
}

const RightPane = ({
  visibility,
  children,
  scrollableParentId,
  onOutsideClick,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const { screenWidth } = useWindowDimensions()
  const [isPanelFullyOpen, setIsPanelFullyOpen] = useState(false)

  const scrollbarWidthRef = useRef(0)
  const hasModifiedParent = useRef(false)

  const hasAbsolutePosition = screenWidth < 1600

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      if (
        scrollableParentId &&
        hasAbsolutePosition &&
        visibility === 'visible'
      ) {
        const scrollableParent = document.getElementById(scrollableParentId)
        if (scrollableParent) {
          const scrollbarWidth =
            scrollableParent.offsetWidth - scrollableParent.clientWidth
          if (scrollbarWidth > 0) {
            scrollbarWidthRef.current = scrollbarWidth
            scrollableParent.style.paddingRight = `${scrollbarWidth}px`
            scrollableParent.scrollTop = 0
          }
          scrollableParent.classList.remove('overflow-y-auto')
          scrollableParent.classList.add('overflow-y-hidden')
          hasModifiedParent.current = true
        }
      } else if (scrollableParentId && hasModifiedParent.current) {
        const scrollableParent = document.getElementById(scrollableParentId)
        if (scrollableParent) {
          scrollableParent.classList.remove('overflow-y-hidden')
          scrollableParent.classList.add('overflow-y-auto')
          if (scrollbarWidthRef.current > 0) {
            scrollableParent.style.paddingRight = `0px`
          }
          hasModifiedParent.current = false
        }
      }
    })

    observer.observe(document.body)

    return () => {
      observer.unobserve(document.body)
    }
  }, [scrollableParentId, visibility, hasAbsolutePosition])

  if (visibility === 'none') {
    return null
  }

  return (
    <>
      {visibility === 'visible' && (
        <div
          className={cx(
            'fixed lg:hidden left-0 w-full h-full bg-transparent cursor-default'
          )}
          onClick={onOutsideClick}
        />
      )}
      <div
        ref={ref}
        onTransitionEnd={() => {
          const result = visibility === 'visible' || visibility === 'auto'
          setIsPanelFullyOpen(result)
        }}
        className={cx(
          `flex flex-col absolute right-0 lg:relative transform transition-all translate-x-0 duration-300 ease-in-out
           bg-gold-100 w-90 ml-6 min-h-full overflow-y-auto shadow-xl lg:shadow-none shadow-offset-left`,
          {
            'lg:-ml-90 translate-x-full': visibility === 'hidden',
            'translate-x-full lg:translate-x-0': visibility === 'auto',
            'h-0': !isPanelFullyOpen && visibility === 'hidden',
            'max-h-full': hasAbsolutePosition,
          }
        )}
      >
        <div className="overflow-auto">{children}</div>
      </div>
    </>
  )
}

export default RightPane
