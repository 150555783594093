import { useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { ControlledMenu, useMenuState } from '@szhsin/react-menu'
import cx from 'classnames'

import Button from 'common/components/button'
import DynamicFields from 'common/components/dynamicFields'
import {
  ConditionalValue,
  FieldsConfiguration,
  FieldType,
} from 'common/components/dynamicFields/interfaces'
import { errorToast } from 'common/components/toastNotification'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Settings as SettingsIcon } from 'common/icons'

import { getReplacerValue } from '../../api'
import { toggleConfigurationMenu, updateTopic } from '../../store/topicsSlice'

import { ConfigurationState } from './interfaces'

type Props = {
  topicId: string
  isDisabled?: boolean
  configuration: FieldsConfiguration
  hasReplacerError?: boolean
}

const ConfigurationMenu = ({
  topicId,
  isDisabled,
  configuration,
  hasReplacerError,
}: Props) => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const canClose = useRef(true)
  const campaignId = useAppSelector(
    (state) => state.campaigns.selectedCampaignId
  )

  const containerRef = useRef(null)
  const [menuProps, toggleMenu] = useMenuState()

  const toggleMenuOpen = (open: boolean) => {
    toggleMenu(open)
    dispatch(toggleConfigurationMenu(open))
  }

  const getReplacerValueFunc = async (
    formValues: Record<string, ConditionalValue<FieldType> | undefined>
  ) => {
    const configurationState: ConfigurationState<FieldType> = []
    for (const [key, value] of Object.entries(formValues)) {
      if (value != null) {
        const fieldConfig = configuration.find((field) => field.name === key)
        const label =
          fieldConfig?.type === FieldType.Dropdown ||
          fieldConfig?.type === FieldType.Radio
            ? fieldConfig.options?.find((option) => option.value === value)
                ?.label
            : undefined

        let shoudPushField = true
        fieldConfig?.conditions?.forEach((cond) => {
          if (formValues[cond.fieldName] !== cond.condition) {
            shoudPushField = false
          }
        })

        if (shoudPushField) {
          configurationState.push({
            name: key,
            value,
            label,
          })
        }
      }
    }

    try {
      if (configurationState.length > 0) {
        setIsLoading(true)

        const newValue = await getReplacerValue(
          configurationState,
          topicId.split('-')[1],
          campaignId
        )

        dispatch(
          updateTopic({ id: topicId, name: newValue, configurationState })
        )
        toggleMenuOpen(false)
      }
    } catch {
      errorToast('Something went wrong. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  const onCancel = () => {
    toggleMenuOpen(false)
  }

  const initialValues = configuration.reduce(
    (acc, state) => ({ ...acc, [state.name]: state.value }),
    {}
  )

  return (
    <>
      <div ref={containerRef} onClick={() => toggleMenuOpen(true)}>
        <Button
          variant="icon"
          className="mr-2"
          data-testid="open-configuration-menu-button"
          data-cy="open-configuration-menu-button"
        >
          <SettingsIcon
            size={5}
            isDefaultColor={!hasReplacerError}
            className={cx({ 'text-red-400': hasReplacerError })}
            role="img"
          />
        </Button>
      </div>
      <ControlledMenu
        {...menuProps}
        id="node-configuration-menu"
        anchorRef={containerRef}
        direction="right"
        align="center"
        position="anchor"
        viewScroll="auto"
        arrow={true}
        portal={true}
        offsetX={24}
        onBlurCapture={(e) => {
          // DatePicker fires blur events without giving us any control over them. Here we capture the blur event
          // to check if it was fired by DatePicker or not. https://phraseephoundry.atlassian.net/browse/HH-2574
          // We do the same for MultiSelect, to prevent the menu from closing when the user clicks onApply button.
          const datePicker = document.getElementsByClassName('DayPicker')[0]

          if (
            e.target.id.includes('react-select') ||
            datePicker?.contains(e.target)
          ) {
            canClose.current = false
          } else {
            canClose.current = true
          }
        }}
        onClose={({ reason }) => {
          if (reason === 'blur' && canClose.current) {
            toggleMenuOpen(false)
          }
        }}
      >
        {menuProps.state === 'open' && (
          <Form
            onSubmit={(values) => getReplacerValueFunc(values)}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="min-h-24 p-6 max-w-100">
                  <DynamicFields
                    fieldsConfiguration={configuration}
                    isDisabled={isDisabled}
                  />
                  <div className="flex justify-end items-center mt-8">
                    <Button ghost className="mr-4" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      loading={isLoading}
                      disabled={isDisabled || submitting}
                      data-testid="get-replacer-value-button"
                    >
                      All done
                    </Button>
                  </div>
                </div>
              </form>
            )}
          />
        )}
      </ControlledMenu>
    </>
  )
}

export default ConfigurationMenu
