import { DashboardMode } from '@phrasee/phrasee-typings/Graphql/interfaces'

import InfoAction from 'common/components/InfoAction'
import Loader from 'common/components/loaders/Loader'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { WidgetIncrementalError } from 'common/components/WidgetMessage'
import { useAppSelector } from 'common/hooks/redux'

import { CampaignTypes } from './CampaignMetrics'
import { PerformanceIndicator } from './PerformanceIndicator'
import UpgradeNotice from './UpgradeNotice'

interface Props {
  campaignType: CampaignTypes
  hasError: boolean
  isLoading: boolean
  totalIncrementalOpens: number
  totalIncrementalClicks: number
  totalProjects: number
  totalCampaigns: number
  averageClicksUplift: number
  averageOpensUplift: number
}

const KPICard = ({
  campaignType,
  hasError,
  isLoading,
  totalProjects,
  totalIncrementalOpens,
  totalIncrementalClicks,
  totalCampaigns,
  averageClicksUplift,
  averageOpensUplift,
}: Props) => {
  const { dashboardMode } = useAppSelector((state) => state.authStates)
  const hasToolTip = !isLoading && totalCampaigns !== 0

  const hasMetrics = !isLoading && !hasError
  const hasNoProjects = totalProjects === 0

  const hasNoData = (dashboardMode: DashboardMode | undefined): boolean => {
    return (
      (dashboardMode === 'Incremental' &&
        totalIncrementalClicks === 0 &&
        totalIncrementalOpens === 0) ||
      (dashboardMode === 'Average_uplift' &&
        averageClicksUplift === 0 &&
        averageOpensUplift === 0)
    )
  }

  return (
    <>
      {hasNoProjects && !isLoading ? (
        <UpgradeNotice campaignType={campaignType} />
      ) : (
        <Widget
          className="max-h-full border-none h-full justify-between"
          bgClassName="bg-transparent"
          defaultPadding={false}
        >
          <WidgetHeader
            data-cy={`${campaignType}-KPI-Widget`}
            data-testid={`${campaignType}-KPI-Widget`}
            className="text-base-700"
            title={
              hasError || hasNoData(dashboardMode) ? (
                <span
                  data-cy={`number-of-${campaignType}-experiments-widget-title`}
                  data-testid={`number-of-${campaignType}-experiments-widget-title`}
                >
                  {campaignType === 'broadcast' ? 'Broadcast' : 'Triggers'}
                </span>
              ) : (
                <span
                  data-cy={`number-of-${campaignType}-experiments-widget-title`}
                  data-testid={`number-of-${campaignType}-experiments-widget-title`}
                >
                  {`${totalCampaigns} `}
                  {campaignType === 'broadcast' ? 'Broadcast' : 'Triggers'}
                </span>
              )
            }
            actions={
              hasToolTip && (
                <InfoAction
                  isDefaultColor={false}
                  className="text-base-700"
                  message={
                    dashboardMode === 'Incremental'
                      ? `This widget displays your total incremental opens and clicks for all active ${campaignType} email projects for the past 12 months. If you want to dive deeper into your KPIs, head to the Reports page.`
                      : `This widget displays your average uplift for opens and clicks for all active ${campaignType} email projects for the past 12 months. If you want to dive deeper into your KPIs, head to the Reports page.`
                  }
                />
              )
            }
          />
          {isLoading && !hasError && (
            <Loader
              data-cy="number-of-triggers-widget-loader"
              data-testid="number-of-triggers-widget-loader"
            />
          )}
          {hasError && <WidgetIncrementalError />}
          {hasMetrics && (
            <>
              {hasNoData(dashboardMode) && (
                <p className="text-5 font-relais font-medium text-maroon-500">
                  Run your first experiment, then data will start to show.
                </p>
              )}
              <div>
                <div className="flex flex-row flex-nowrap gap-6 text-base-700">
                  <PerformanceIndicator
                    className="flex flex-1 flex-col pr-6"
                    kpiTitle={
                      dashboardMode === 'Incremental'
                        ? 'Incremental opens'
                        : 'Average uplift for opens'
                    }
                    kpiValue={
                      dashboardMode === 'Incremental'
                        ? totalIncrementalOpens
                        : `${averageOpensUplift}%`
                    }
                  />
                  <PerformanceIndicator
                    className="flex flex-1 flex-col max-h-screen"
                    kpiTitle={
                      dashboardMode === 'Incremental'
                        ? 'Incremental clicks'
                        : 'Average uplift for clicks'
                    }
                    kpiValue={
                      dashboardMode === 'Incremental'
                        ? totalIncrementalClicks
                        : `${averageClicksUplift}%`
                    }
                  />
                </div>
              </div>
            </>
          )}
        </Widget>
      )}
    </>
  )
}

export default KPICard
