import { useMemo } from 'react'
import { ComponentElement, Element } from '@phrasee/phrasee-typings'
import cx from 'classnames'

import Button from 'common/components/button'
import { useAppDispatch } from 'common/hooks/redux'
import { AlertCircleInverted as AlertCircleInvertedIcon } from 'common/icons'
import { ChevronDown, ChevronUp } from 'common/icons'
import { VariableCombination } from 'features/unifiedFlow/api'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import ElementActionsMenu from '../ElementActionsMenu'
import ElementCardIcon from '../ElementCardIcon'

import LanguageContent from './LanguageContent'

type Props = {
  title: string
  className?: string
  content?: string | JSX.Element | null
  element?: Element
  icon?: string
  isRemoveOptionShown?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  hasError?: boolean
  isApproved?: boolean
  isConfigurable?: boolean
  hasSmallTitle?: boolean
  hasElementActionsMenu?: boolean
  isElementTemplate?: boolean
  variableCombinations: VariableCombination['items']
  selectedPersonalizationId: string | undefined
  onClickPersonalizationId: (personalizationId: string | undefined) => void
  onClick?: () => void
  expandedElementId: number | undefined
  onSetExpandedElementId: (element: number | undefined) => void
  elements: ComponentElement[]
}

const ElementCardPersonalize = ({
  title,
  className,
  content,
  element,
  icon,
  selectedPersonalizationId,
  onClickPersonalizationId,
  onClick,
  variableCombinations,
  isRemoveOptionShown,
  isDisabled = false,
  isSelected = false,
  hasError = false,
  isApproved = false,
  isConfigurable = false,
  hasSmallTitle = false,
  isElementTemplate = false,
  hasElementActionsMenu = true,
  expandedElementId,
  onSetExpandedElementId,
  elements,
}: Props) => {
  const hasContent = !!content
  const isContextExpanded = expandedElementId === element?.element_id

  const dispatch = useAppDispatch()

  const pendingVariantsCountPerCombination:
    | { [key: string]: number }
    | undefined = useMemo(() => {
    const personalizedElement = elements.find(
      (el) => el.element_id === element?.element_id
    )
    return personalizedElement?.personalized_variants
      ?.filter((variant) =>
        personalizedElement?.personalized_ids_to_approve?.includes(
          variant.variant_id
        )
      )
      .reduce((acc, variant) => {
        const { combinationId } = variant
        if (combinationId) {
          acc[combinationId] = (acc[combinationId] || 0) + 1
        }
        return acc
      }, {})
  }, [elements, element?.element_id])

  const pendingCombinationsCount = useMemo(
    () =>
      Object.values(pendingVariantsCountPerCombination ?? []).reduce(
        (acc: number, value) => {
          if (value > 0) {
            acc++
          }
          return acc
        },
        0
      ),
    [pendingVariantsCountPerCombination]
  )

  return (
    <div
      data-testid="element-card-personalize"
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick?.()
        }
      }}
      tabIndex={0}
      className={cx(
        'relative  group rounded-sm cursor-pointer hover:bg-gold-300 px-4 py-6 focus:outline-none focus-visible:outline-black',
        {
          'bg-gold-100 opacity-40 cursor-not-allowed': isDisabled,
          'bg-gold-100':
            (!isElementTemplate && !isDisabled && !isSelected) ||
            isContextExpanded,
          'border-l-2 border-black bg-yellow-200': isSelected,
          'bg-white cursor-pointer': isElementTemplate && !isSelected,
          'bg-coral-100  cursor-pointer ': !isDisabled && hasError,
        },
        className
      )}
      aria-selected={isSelected}
      aria-label={isApproved ? 'Approved' : undefined}
      role="tab"
    >
      <div className={cx('flex items-center ')}>
        {icon && !hasError && (
          <div className="mr-3">
            <ElementCardIcon icon={icon} />
          </div>
        )}
        {hasError && (
          <div className="mr-3">
            <AlertCircleInvertedIcon
              isDefaultColor={false}
              className={cx({ 'text-RAGred': isSelected })}
            />
          </div>
        )}

        <div
          className={cx(
            'flex items-center flex-wrap gap-1 font-medium overflow-hidden'
          )}
        >
          <span
            className={cx('leading-4 uppercase', {
              'text-RAGred': hasError && isSelected,
              'text-sm text-base-700': !isDisabled && !hasSmallTitle,
              'text-sm text-coolGray-500': isDisabled && !hasSmallTitle,
              'text-xs text-base-700': hasSmallTitle && !isDisabled,
              'text-xs text-coolGray-500': hasSmallTitle && isDisabled,
            })}
            aria-disabled={isDisabled}
          >
            {title}
          </span>
          {hasContent && !hasError && <div className="min-h-5">{content}</div>}
          {hasError && (
            <div
              className={cx('min-h-5 text-2.5 text-base-700', {
                'text-RAGred': isSelected,
              })}
            >
              Unable to generate language, please try again.
            </div>
          )}
        </div>
        <ElementActionsMenu
          hasError={hasError}
          isApproved={isApproved}
          isConfigurable={isConfigurable}
          isRemoveOptionShown={isRemoveOptionShown}
          isSelected={isSelected}
          element={element}
          hasElementActionsMenu={hasElementActionsMenu}
          showCustomBriefIcon={false}
        />
      </div>
      <div className="mt-8  flex justify-between items-center text-xs">
        <Button
          variant="link"
          className="text-gold-600 uppercase"
          onClick={(e) => {
            e.stopPropagation()
            if (!isContextExpanded) {
              onSetExpandedElementId(element?.element_id)
              dispatch(selectElement({ elementId: element?.element_id }))
              onClickPersonalizationId(undefined)
            } else {
              onSetExpandedElementId(undefined)
            }
          }}
          prefixIcon={
            !isContextExpanded ? (
              <ChevronUp
                className="w-4 h-4 text-gold-500"
                isDefaultColor={false}
              />
            ) : (
              <ChevronDown
                className="w-4 h-4 text-gold-500"
                isDefaultColor={false}
              />
            )
          }
        >
          {isContextExpanded ? 'Hide context' : 'Show context'}
        </Button>
        <div className="text-base-700">Total: {pendingCombinationsCount}</div>
      </div>
      <div className={cx({ hidden: !isContextExpanded })}>
        <LanguageContent
          variableCombinations={variableCombinations}
          selectedPersonalizationId={selectedPersonalizationId}
          onClickPersonalizationId={onClickPersonalizationId}
          pendingVariantsCountPerCombination={
            pendingVariantsCountPerCombination ?? {}
          }
        />
      </div>
    </div>
  )
}

export default ElementCardPersonalize
