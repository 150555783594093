import { useMutation, useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

import { showErrorToast } from 'common/api/helpers'
import { imagesKeys } from 'common/components/images/api/queryKeys'
import { useAppSelector } from 'common/hooks/redux'

import type { UpdateImageRequest } from '../api'
import { ImagePageResponse, updateImageMetadata } from '../api'

type MutationContext = { previousData: ImagePageResponse }

const useUpdateImageMetadataMutation = () => {
  const queryClient = useQueryClient()
  const pagination = useAppSelector((state) => state.images.pagination)
  const query = useAppSelector((state) => state.images.query)
  const sort = useAppSelector((state) => state.images.sort)
  const imageStatus = useAppSelector((state) => state.images.imageStatus)
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const filteredQueryParams = omitBy(
    { ...pagination, ...sort, status: imageStatus, query },
    isNil
  )

  return useMutation<
    unknown,
    unknown,
    { imageId: string; body: UpdateImageRequest },
    MutationContext
  >({
    mutationFn: ({ imageId, body }) =>
      updateImageMetadata(accountId, imageId, body),
    onMutate: async ({ imageId, body }) => {
      const previousData = queryClient.getQueryData<ImagePageResponse>(
        imagesKeys.queryParams(accountId, filteredQueryParams)
      )

      queryClient.setQueryData<ImagePageResponse>(
        imagesKeys.queryParams(accountId, filteredQueryParams),
        (old) => {
          if (old !== undefined) {
            return {
              ...old,
              data: old.data?.map((oldItem) => {
                return oldItem.id === imageId
                  ? { ...oldItem, ...body }
                  : oldItem
              }),
            }
          }
        }
      )

      return { previousData } as MutationContext
    },
    onError: (error, _, context) => {
      if (context?.previousData) {
        queryClient.setQueryData<ImagePageResponse>(
          imagesKeys.queryParams(accountId, filteredQueryParams),
          context.previousData
        )
      }
      showErrorToast(error)
    },
    onSettled: () => {
      queryClient.invalidateQueries(
        imagesKeys.queryParams(accountId, filteredQueryParams)
      )
    },
  })
}

export default useUpdateImageMetadataMutation
