import React from 'react'

import Loader from 'common/components/loaders/Loader'
interface Props {
  children: React.ReactNode
  isLoading: boolean
  'data-testid'?: string
}

const ChartContainer = ({
  children,
  isLoading,
  'data-testid': dataTestId = 'chartContainer',
}: Props) => {
  return (
    <div
      className="relative flex items-center justify-center"
      data-testid={dataTestId}
    >
      <Loader isLoading={isLoading}>{children}</Loader>
    </div>
  )
}

export default ChartContainer
