import { useHistory } from 'react-router-dom'
import { IntegrationAccount } from '@phrasee/phrasee-typings/typings/integrations/accounts'
import cond from 'lodash/cond'
import stubTrue from 'lodash/stubTrue'
import Dropdown from 'rc-dropdown'

import Button from 'common/components/button'
import { Variant } from 'common/components/button/Button'
import { capitalizeFirstLetter } from 'common/helpers/string'
import { useAppSelector } from 'common/hooks/redux'
import { ArrowDropdownDown } from 'common/icons'

import useUpdateIntegrationAccountMutation from '../api/mutations/useUpdateIntegrationAccountMutation'

import IntegrationsMenu from './IntegrationsMenu'
import { Options } from './interfaces'

type Props = {
  accounts: IntegrationAccount[]
  children: React.ReactNode
  contentId: string
  elementId: number
  variant?: Variant
  className?: string
  placement?: 'bottomLeft' | 'bottomCenter' | 'bottomRight'
}

const IntegrationsSelect = ({
  accounts,
  variant,
  contentId,
  elementId,
  children,
  className,
  placement = 'bottomCenter',
}: Props) => {
  const history = useHistory()

  const accountId = useAppSelector((state) => state.authStates.accountId)

  const updateIntegrationAccountMutation = useUpdateIntegrationAccountMutation()

  const submitOptions: Options[] = accounts.map((account) => ({
    key: account.id,
    logoUrl: account.logoUrl,
    provider: account.provider,
    label: `${capitalizeFirstLetter(account.provider)} ${account.label ?? ''}`,
  }))

  const handleItemAction = cond([
    [
      (id: string) => id === 'redirect',
      () => history.push(`/admin/accounts/${accountId}/integrations`),
    ],
    [
      stubTrue,
      (id: string) =>
        updateIntegrationAccountMutation.mutate({
          accountId,
          contentId,
          elementId,
          updatedElement: { integration_account_id: id },
        }),
    ],
  ])

  return (
    <div id="integrations-dropdown" className={className}>
      <Dropdown
        trigger={['click']}
        animation="slide-up"
        placement={placement}
        getPopupContainer={() =>
          document.getElementById('integrations-dropdown')!
        }
        overlay={() => (
          <IntegrationsMenu
            options={submitOptions}
            onClick={({ key: id }) => handleItemAction(id.toString())}
          />
        )}
      >
        <Button
          variant={variant}
          suffixIcon={<ArrowDropdownDown isDefaultColor={false} />}
        >
          {children}
        </Button>
      </Dropdown>
    </div>
  )
}

export default IntegrationsSelect
