import { useEffect, useState } from 'react'

import Button from 'common/components/button'
import EmptyState from 'common/components/emptyState'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'

import useGetAccountPrivacyRegionsQuery from '../../../../common/api/queries/useGetAccountPrivacyRegionsQuery'
import useGetCustomerAttributeRecordsQuery from '../api/queries/useGetCustomerAttributeRecordsQuery'
import useGetCustomerAttributesQuery from '../api/queries/useGetCustomerAttributesQuery'

import CreateRecordModal from './createRecord/CreateRecordModal'
import CustomerAttributesTable from './CustomerAttributesTable'

const CustomerAttributes = () => {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false)

  const [selectedRecord, setSelectedRecord] =
    useState<string | undefined>(undefined)

  const accountPrivacyRegionsQuery = useGetAccountPrivacyRegionsQuery()
  const privacyRegions = accountPrivacyRegionsQuery.data ?? []
  const customerAttributeRecordsQuery = useGetCustomerAttributeRecordsQuery({
    privacyRegions,
  })

  const selectedAttribute = customerAttributeRecordsQuery.data?.find(
    (record) => record.id === selectedRecord
  )

  const catalogAttributesQuery = useGetCustomerAttributesQuery({
    catalogueAttributeId: selectedAttribute?.id,
    region: selectedAttribute?.region ?? '',
  })

  useEffect(() => {
    if (customerAttributeRecordsQuery.isSuccess) {
      setSelectedRecord(customerAttributeRecordsQuery.data?.[0]?.id)
    }
  }, [
    customerAttributeRecordsQuery.data,
    customerAttributeRecordsQuery.isSuccess,
  ])

  const getState = () => {
    if (
      customerAttributeRecordsQuery.isFetched &&
      customerAttributeRecordsQuery.data?.length === 0
    ) {
      return 'empty'
    }
    return 'success'
  }
  const state = getState()

  return (
    <div className="flex flex-1 flex-col gap-12">
      <PageHeader>
        <div className="flex justify-between flex-1">
          <div className="pt-9">
            <PageTitle title="Customer attributes" />
            <div className="text-coolGray-400">
              Configure the below settings to let Jacquard know what we should
              or should not use in the generation of your content.
            </div>
          </div>
        </div>
      </PageHeader>
      {
        {
          empty: (
            <EmptyState
              title="Start creating your first record"
              description="We don’t have any records stored. "
              action={
                <Button
                  variant="primary"
                  onClick={() => {
                    setIsCreateModalVisible(true)
                  }}
                >
                  Create record
                </Button>
              }
            />
          ),
          success: (
            <CustomerAttributesTable
              isLoading={
                customerAttributeRecordsQuery.isLoading ||
                catalogAttributesQuery.isLoading
              }
              onChangeRecord={setSelectedRecord}
              selectedRecordId={
                selectedRecord ?? customerAttributeRecordsQuery.data?.[0]?.id
              }
              records={customerAttributeRecordsQuery.data ?? []}
              attributes={catalogAttributesQuery.data ?? []}
              onAddNewRecord={() => setIsCreateModalVisible(true)}
            />
          ),
        }[state]
      }

      <CreateRecordModal
        isVisible={isCreateModalVisible}
        onClose={() => {
          setIsCreateModalVisible(false)
        }}
      />
    </div>
  )
}

export default CustomerAttributes
