import { useEffect } from 'react'
import { fetchCampaignData } from 'workflow/Workflow.actions'

import Loader from 'common/components/loaders/Loader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useSelectedComponentElement } from 'features/unifiedFlow/contentPage/hooks'
import useTweakSuccess from 'features/unifiedFlow/contentPage/hooks/useTweakSuccess'

import TweakVariantModal from '../../../components/tweakVariantModal'
import useVariantTweakWebsocketSubscription from '../../../hooks/useVariantTweakWebsocketSubscription'
import useWebsocketSentiments from '../hooks/useWebsocketSentiments'

import OptimizeResultsTable from './OptimizeResultsTable'

type Props = {
  isReadOnly: boolean
}

const OptimizeResults = ({ isReadOnly = false }: Props) => {
  useWebsocketSentiments()

  const { tweakSuccessVariantIds, onTweakSuccess } = useTweakSuccess()

  useVariantTweakWebsocketSubscription({ onTweakSuccess })

  const dispatch = useAppDispatch()
  const { data: selectedElement } = useSelectedComponentElement()

  const {
    isLoading,
    isWaitingState: { isWaiting },
  } = useAppSelector((state) => state.campaignStates)

  useEffect(() => {
    if (!!selectedElement?.campaign_id) {
      dispatch(fetchCampaignData(selectedElement?.campaign_id))
    }
  }, [dispatch, selectedElement?.campaign_id])

  return isLoading || isWaiting ? (
    <Loader className="m-auto" />
  ) : (
    <>
      <div
        className="flex flex-col w-full gap-y-8"
        data-testid="optimizeResults"
      >
        <OptimizeResultsTable
          isReadOnly={isReadOnly}
          tweakSuccessVariantIds={tweakSuccessVariantIds}
        />
      </div>
      <TweakVariantModal campaignType="linguo" />
    </>
  )
}

export default OptimizeResults
