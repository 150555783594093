import { useEffect } from 'react'
import { DateRangePicker, DateRangePickerShape } from 'react-dates'
import cx from 'classnames'

import Button from 'common/components/button'
import { options } from 'common/components/dateRange/defaultOptions'
import type {
  CustomProps,
  SelectorDate,
} from 'common/components/dateRange/interfaces'

import 'react-dates/initialize'

import Tooltip from '../Tooltip'

import 'react-dates/lib/css/_datepicker.css'

const doDaysMatch = (date1: SelectorDate, date2: SelectorDate) => {
  return date1 === date2 || date1?.isSame(date2, 'day')
}

const DateRange = ({
  focusedInput,
  footer,
  startDate,
  endDate,
  onOutsideClick,
  disabled,
  hasTooltip = false,
  tooltipText,
  ...rest
}: DateRangePickerShape & CustomProps) => {
  useEffect(() => {
    if (!focusedInput && onOutsideClick) {
      onOutsideClick()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedInput])

  return (
    <Tooltip show={hasTooltip} overlay={tooltipText}>
      <DateRangePicker
        focusedInput={focusedInput}
        startDate={startDate}
        endDate={endDate}
        firstDayOfWeek={1}
        renderCalendarInfo={() => {
          if (footer) {
            const {
              onCancel,
              onApply,
              isApplyDisabled,
              applyDefaultOption,
              areDefaultOptionsDisabled,
            } = footer

            return (
              <div
                className={cx('flex items-center pb-4 px-6', {
                  'justify-between': applyDefaultOption,
                  'justify-end': !applyDefaultOption,
                })}
              >
                {applyDefaultOption && (
                  <div>
                    {options.map(({ id, title, start, end }) => {
                      const isSelected =
                        doDaysMatch(startDate, start) &&
                        doDaysMatch(endDate, end)

                      return (
                        <Button
                          data-testid={`option-${id}`}
                          data-cy={`default-date-option-${id}`}
                          key={title}
                          onClick={() => applyDefaultOption(start, end)}
                          disabled={areDefaultOptionsDisabled}
                          className={cx(
                            'uppercase underline mr-2',
                            { 'text-gold-600': !isSelected },
                            { 'text-base-700': isSelected }
                          )}
                          variant="link"
                          size="medium"
                        >
                          {title}
                        </Button>
                      )
                    })}
                  </div>
                )}

                <div>
                  <Button
                    className="uppercase underline mr-2 text-gold-600"
                    variant="link"
                    size="medium"
                    data-testid="cancel"
                    data-cy="date-selector-cancel"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="uppercase"
                    variant="primary"
                    size="medium"
                    data-testid="apply-dates"
                    data-cy="date-selector-apply"
                    disabled={isApplyDisabled}
                    onClick={() => onApply()}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            )
          }
        }}
        disabled={disabled}
        hideKeyboardShortcutsPanel={true}
        {...rest}
      />
    </Tooltip>
  )
}

export default DateRange
