import { useState } from 'react'
import cx from 'classnames'

import type { ImageEntity } from 'common/components/images/api/api'
import CropImage from 'common/components/images/cropImage'
import ImageErrorFallback from 'common/components/images/imageErrorFallback'
import ImagePreviewModal from 'common/components/images/imagePreviewModal'

type Props = {
  image: ImageEntity | undefined
  className?: string
}

const ImageThumbnailButton = ({ image, className }: Props) => {
  const [isValidImage, setIsValidImage] = useState(!!image?.url)
  const [isImagePreviewVisible, setImagePreview] = useState<boolean>(false)

  if (!image) {
    return null
  }

  return (
    <div className={cx('flex', className)}>
      {isValidImage ? (
        <>
          <button
            className="focus:outline-none hover:opacity-50"
            onClick={() => setImagePreview(true)}
          >
            <CropImage
              src={image.url}
              width={50}
              height={50}
              onError={(value) => setIsValidImage(value)}
            />
          </button>
          <ImagePreviewModal
            destroyOnClose
            isVisible={isImagePreviewVisible}
            image={image!}
            onClose={() => setImagePreview(false)}
          />
        </>
      ) : (
        <ImageErrorFallback width="w-12.5" height="h-12.5" />
      )}
    </div>
  )
}

export default ImageThumbnailButton
