import { useAppSelector } from 'common/hooks/redux'

import { getContentHasVariants } from '../../helpers'
import { useContent } from '../../hooks'

import ExportMenuButton from './ExportMenuButton'
import RightPanelButton from './RightPanelButton'

const HeaderActions = () => {
  const { content } = useContent()
  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )

  return (
    <>
      {selectedElementId && getContentHasVariants(content) && (
        <ExportMenuButton />
      )}
      <RightPanelButton />
    </>
  )
}

export default HeaderActions
