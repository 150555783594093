import cx from 'classnames'
import AdvancedVisualisationErrorBoundary from 'workflow/CampaignSummary/PhraseeXContent/AdvancedVisualisations/AdvancedVisualisationErrorBoundary'

import Button from 'common/components/button/Button'
import IconTitleGroup from 'common/components/iconTitleGroup'
import Loader from 'common/components/loaders/Loader'
import Select, { Option } from 'common/components/Select'
import { WidgetError } from 'common/components/WidgetMessage'
import { useAppSelector } from 'common/hooks/redux'
import { ReactComponent as ReplayAnimation } from 'common/icons/replayAnimation/default.svg'

import { GraphsModuleProps } from '../../interface'

const GraphsModule = ({
  requestedGraphType,
  requestedGraphTemplate,
  sortedGraphList,
  shouldEnableRestartButton,
  handleSelectUiGraphChange,
  setCurrentIndex,
  renderAdvancedVisualisationGraph,
  requestedGraphOrdinal,
  variantColorMapping,
  className,
}: GraphsModuleProps) => {
  // Redux state data
  const {
    advancedVisualisationsLoading,
    advancedVisualisationsData,
    advancedVisualisationsError,
  } = useAppSelector((state) => state.campaignStates)
  // end Redux state data
  return (
    <>
      <div className={cx('flex flex-col mb-6', className)}>
        <IconTitleGroup title="Graph type" className="mb-6" />
        <div className="flex flex-row">
          <Select
            onChange={(config) => handleSelectUiGraphChange(config)}
            value={requestedGraphTemplate}
            placeholder="Select a graph"
            className="w-125"
            loading={!requestedGraphTemplate}
          >
            {sortedGraphList &&
              sortedGraphList.map((graph: any) => {
                return (
                  <Option
                    key={graph?.ordinal}
                    value={graph?.visualisation_template}
                  >
                    <span key={graph?.ordinal}>{graph?.title}</span>
                  </Option>
                )
              })}
          </Select>
          <div>
            {requestedGraphType === 'bar_graph' && (
              <Button
                data-cy="graphs-replay-animation-button"
                data-testid="graphs-replay-animation-button"
                className="ant-select-selection-selected-value text-sm self-end ml-5"
                prefixIcon={<ReplayAnimation width={16} height={16} />}
                onClick={() => setCurrentIndex(0)}
                disabled={!shouldEnableRestartButton}
              >
                Replay animation
              </Button>
            )}
          </div>
        </div>
      </div>
      <>
        {!advancedVisualisationsLoading &&
        !!advancedVisualisationsData[requestedGraphTemplate] ? (
          <AdvancedVisualisationErrorBoundary>
            {renderAdvancedVisualisationGraph(
              requestedGraphType,
              advancedVisualisationsData[requestedGraphTemplate],
              requestedGraphOrdinal,
              variantColorMapping,
              requestedGraphTemplate
            )}
          </AdvancedVisualisationErrorBoundary>
        ) : advancedVisualisationsLoading && !advancedVisualisationsError ? (
          <Loader data-testid="GraphLoader" className="py-20" />
        ) : (
          <WidgetError className="mt-6 mx-6" />
        )}
      </>
    </>
  )
}

export default GraphsModule
