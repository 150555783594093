import { integrationsKeys as defaultIntegrationsKeys } from 'common/api/queryKeys/integrationsKeys'

export const integrationsKeys = {
  list: () => [...defaultIntegrationsKeys.all, 'list'] as const,
}

export const mergeTagsKeys = {
  all: ['admin-merge-tags'] as const,
  list: (accountId) => [...mergeTagsKeys.all, 'list', { accountId }] as const,
  systemList: () => [...mergeTagsKeys.all, 'systemList'] as const,
}
