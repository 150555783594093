import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { showBanner } from 'workflow/common/common.actions'

import { connectInternalApi } from 'common/api'
import Alert from 'common/components/alert'
import Button from 'common/components/button'
import Checkbox from 'common/components/checkbox'
import FormItem from 'common/components/formItem'
import { useAppDispatch } from 'common/hooks/redux'
import { ReactComponent as SmallTick } from 'common/icons/smallTick/default.svg'

import CreateVariantsCard from '../../components/card/CreateVariantsCard'
import SelectComponent from '../../components/select/SelectComponent'
import { URL } from '../ResponsysIntegration'

interface Props {
  projectId: string
  campaignId?: string
  state: {
    isStraightSplitTest: boolean
    selectedCampaign?: string
  }
  hasCreatedEmails: boolean
  isScheduled: boolean
  setHasCreatedEmails: Dispatch<SetStateAction<boolean>>
  onChange: (name, value) => void
}

type Status = 'idle' | 'loading' | 'error'
interface Campaign {
  id: string
  name: string
  linkTablePath?: string
}

interface PaginatedCampaignsList {
  campaigns: ResponseCampaign[]
  isLastPage: boolean
}

interface ResponseCampaign extends Campaign {
  autoCloseOption: string
  autoCloseValue: string
  campaignStatus: string
  enableExternalTracking: boolean
  enableLinkTracking: boolean
  folderName: string
  fromEmail: string
  fromName: string
  htmlMessagePath: string
  links: { rel: string; href: string; method: string }[]
  listName: string
  locale: string
  proofListPath: string
  purpose: string
  refiningDataSourcePath: string
  replyToEmail: string
  sendTextIfHTMLUnknown: boolean
  subject: string
  trackConversions: boolean
  trackHTMLOpens: boolean
  type: string
  unsubscribeOption: string
  useUTF8: boolean
}

export default function CreateEmails({
  projectId,
  campaignId,
  state,
  onChange,
  hasCreatedEmails,
  setHasCreatedEmails,
  isScheduled,
}: Props) {
  const dispatch = useAppDispatch()

  const [campaigns, setCampaigns] = useState<Campaign[]>()
  const [campaignsStatus, setCampaignsStatus] = useState<Status>('idle')
  const [isCreatingEmails, setIsCreatingEmails] = useState<boolean>(false)
  const [isDeletingEmails, setIsDeletingEmails] = useState<boolean>(false)

  useEffect(() => {
    if (!hasCreatedEmails) {
      const fetchAllCampaigns = async () => {
        const campaignsPerPage = 200
        const allCampaigns: Campaign[] = []
        let currentPage = 0
        let isPaginationFinished = false

        while (!isPaginationFinished) {
          const offset = currentPage * campaignsPerPage
          const campaignsPage = await fetchCampaignsPage(
            offset,
            campaignsPerPage
          )

          allCampaigns.push(...mapCampaigns(campaignsPage.campaigns))

          isPaginationFinished = campaignsPage.isLastPage
          currentPage++
        }

        return allCampaigns.reverse()
      }

      const fetchCampaignsPage = async (offset: number, limit: number) => {
        const response = await connectInternalApi.get<PaginatedCampaignsList>(
          `${URL}projects/${projectId}/get-list-campaigns?offset=${offset}&limit=${limit}`
        )

        return response.data
      }

      const mapCampaigns = (campaigns: ResponseCampaign[]) => {
        return campaigns.map(({ id, name, linkTablePath }) => {
          return { id, name, linkTablePath }
        })
      }

      setCampaignsStatus('loading')

      fetchAllCampaigns()
        .then((campaigns) => {
          setCampaigns(campaigns)
          setCampaignsStatus('idle')
        })
        .catch(() => setCampaignsStatus('error'))
    }
  }, [projectId, hasCreatedEmails])

  const createEmails = async () => {
    setIsCreatingEmails(true)

    const selectedCampaign = campaigns?.find(
      ({ id }) => id === state.selectedCampaign
    )

    connectInternalApi
      .post(
        `${URL}projects/${projectId}/campaigns/${campaignId}/create-link-table?isStraightSplit=${state.isStraightSplitTest}`,
        {
          linkTablePath: selectedCampaign?.linkTablePath,
          responsysCampaignName: selectedCampaign?.name,
        }
      )
      .then(() => setHasCreatedEmails(true))
      .catch(() =>
        dispatch(
          showBanner({
            content: 'Failed to create emails',
            type: 'error',
          })
        )
      )
      .finally(() => setIsCreatingEmails(false))
  }

  const deleteEmails = async () => {
    setIsDeletingEmails(true)

    connectInternalApi
      .delete(
        `${URL}projects/${projectId}/campaigns/${campaignId}/delete-campaign-assets`
      )
      .then(() => setHasCreatedEmails(false))
      .then(() => onChange('selectedCampaign', undefined))
      .catch(() =>
        dispatch(
          showBanner({
            content: 'Failed to delete emails',
            type: 'error',
          })
        )
      )
      .finally(() => setIsDeletingEmails(false))
  }

  return (
    <>
      <SelectComponent
        dataTestId="select-campaign"
        isLoading={campaignsStatus === 'loading'}
        errorMessage={
          campaignsStatus === 'error' ? 'Failed to load campaigns' : ''
        }
        isSelectDisabled={hasCreatedEmails}
        labelTitle="Responsys campaign"
        placeholder="Select"
        selectOptions={campaigns}
        onChange={(val) => onChange('selectedCampaign', val)}
        selectValue={state.selectedCampaign}
        allowClear={false}
      />
      <FormItem>
        <Checkbox
          data-testid="straight-split-test"
          data-cy="straight-split-test-checkbox"
          label="This is a straight split test"
          isDisabled={hasCreatedEmails}
          isChecked={state.isStraightSplitTest}
          onChange={(isChecked: boolean) => {
            onChange('isStraightSplitTest', isChecked)
          }}
        />
      </FormItem>

      {state?.selectedCampaign && !hasCreatedEmails && (
        <CreateVariantsCard
          cardTitle="Create emails"
          cardText="This will create the emails to be used within your Responsys campaign."
          buttonText={isCreatingEmails ? 'Creating' : 'Create'}
          onSubmit={createEmails}
          isDisabled={hasCreatedEmails}
          isLoading={isCreatingEmails}
        />
      )}

      {hasCreatedEmails && (
        <>
          <Alert type="success" className="flex justify-between items-center">
            <span>Create and send emails to Responsys.</span>
            <SmallTick className="h-6 w-6 text-white" />
          </Alert>

          <div className="mt-6 flex justify-between items-center">
            <div className="mr-4 text-coolGray-400">
              Delete the variations from your Responsys campaign and unlink this
              Jacquard experiment. This may take up to 5 minutes to delete in
              Responsys. We will not delete the variations in Jacquard.
            </div>
            <Button
              data-cy="delete-emails-button"
              data-testid="delete-emails-button"
              variant="secondary"
              onClick={() => deleteEmails()}
              disabled={isScheduled}
              loading={isDeletingEmails}
            >
              Delete
            </Button>
          </div>
        </>
      )}
    </>
  )
}
