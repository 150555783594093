import { useState } from 'react'
import cx from 'classnames'
import Menu, { Item as MenuItem } from 'rc-menu'

import Button from 'common/components/button/Button'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Check } from 'common/icons'

import type { ImageStatus } from '../store/imagesSlice'
import {
  initialState,
  onImageStatusFilterChange,
  onStatusFilterReset,
} from '../store/imagesSlice'

type Option = {
  key: Lowercase<ImageStatus | 'ALL'>
  label: string
  value?: ImageStatus
}

const options: Option[] = [
  {
    key: 'all',
    label: 'Show all',
    value: undefined,
  },
  {
    key: 'active',
    label: 'Show active',
    value: 'ACTIVE',
  },
  {
    key: 'inactive',
    label: 'Show inactive',
    value: 'INACTIVE',
  },
  {
    key: 'archived',
    label: 'Show archived',
    value: 'ARCHIVED',
  },
]

const ImageStatusFilterMenu = () => {
  const dispatch = useAppDispatch()

  const statusFilter = useAppSelector((state) => state.images.imageStatus)

  const [filter, setFilter] = useState<ImageStatus | undefined>(statusFilter)

  return (
    <Menu
      selectable={false}
      className="z-40 px-4 py-2 bg-gold-50 border border-gold-200 rounded-sm"
    >
      {options.map(({ key, label, value }) => {
        const isActive = value === filter

        return (
          <MenuItem
            key={key}
            className={cx(
              'group flex items-center text-xs font-normal py-2 px-4 -mx-4 cursor-pointer hover:bg-gold-300 gap-2',
              {
                'text-base-700': isActive,
                'text-gold-700': !isActive,
              }
            )}
            onClick={() => setFilter(value)}
          >
            <Check
              isDefaultColor={false}
              size={4}
              className={cx({
                visible: isActive,
                invisible: !isActive,
              })}
            />
            {label}
          </MenuItem>
        )
      })}
      <hr className="w-full my-2 border-gold-400" />
      <MenuItem className="flex justify-between">
        <Button
          size="small"
          variant="link"
          onClick={() => {
            setFilter(initialState.imageStatus)
            dispatch(onStatusFilterReset())
          }}
          className="uppercase underline"
        >
          Reset
        </Button>
        <Button
          onClick={() => dispatch(onImageStatusFilterChange(filter))}
          variant="primary"
          className="uppercase"
          size="small"
        >
          Apply
        </Button>
      </MenuItem>
    </Menu>
  )
}

export default ImageStatusFilterMenu
