import { MenuProps } from '@szhsin/react-menu'

import ButtonWithDropdown from 'common/components/ButtonWithDropdown'
import { ReorderDown } from 'common/icons'

export type SortOptionsValue = { property: string; isAscending: boolean }

export const sortByOptions = <T,>(
  data: T[],
  sortOptions: SortOptionsValue
): T[] => {
  const comparer = (a: T, b: T) => {
    const property = sortOptions.property

    if (typeof a[property] === 'string' && typeof b[property] === 'string') {
      return a[property].localeCompare(b[property], 'en', { numeric: true })
    }

    if (a[property] === b[property]) {
      return 0
    }

    return a[property] > b[property] ? 1 : -1
  }

  const sortAscending = data.sort(comparer)
  return sortOptions.isAscending ? sortAscending : sortAscending.reverse()
}

type Props = {
  sortOptions?: SortOptions
  onSortClick: (value: SortOptionsValue) => void
} & Partial<MenuProps>

export type SortOptions = {
  label: string
  value: SortOptionsValue
}[]

const defaultSortOptions: SortOptions = [
  {
    label: 'Recent',
    value: {
      property: 'lastModified',
      isAscending: false,
    },
  },
  {
    label: 'Oldest',
    value: {
      property: 'lastModified',
      isAscending: true,
    },
  },
  {
    label: 'Date created',
    value: { property: 'createdDate', isAscending: true },
  },
  {
    label: 'Ascending',
    value: { property: 'name', isAscending: true },
  },
  {
    label: 'Descending',
    value: { property: 'name', isAscending: false },
  },
]

const SortMenu = ({ sortOptions = defaultSortOptions, onSortClick }: Props) => {
  return (
    <ButtonWithDropdown
      overlayClassName="capitalize"
      data-cy="sort_menu"
      data-testid="sort_menu"
      aria-label="sort menu"
      suffixIcon={false}
      options={sortOptions
        .map(({ label, value }) => [
          {
            key: `${value.property}-${value.isAscending}`,
            label: label,
            onClick: () => onSortClick(value),
          },
        ])
        .flat()}
      prefixIcon={<ReorderDown />}
    ></ButtonWithDropdown>
  )
}

export default SortMenu
