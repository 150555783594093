import { useAppDispatch } from 'common/hooks/redux'
import { hideLeftPanel } from 'features/unifiedFlow/store/unifiedFlowSlice'

import LeftPanel from '../../components/layout/LeftPanel'

import ElementsList from './ElementsList'

type Props = {
  selectedPersonalizationId: string | undefined
  onSelectPersonalization: (personalizationId: string | undefined) => void
}

const LeftSection = ({
  selectedPersonalizationId,
  onSelectPersonalization,
}: Props) => {
  const dispatch = useAppDispatch()

  return (
    <LeftPanel onOutsideClick={() => dispatch(hideLeftPanel())}>
      <div className="flex flex-col gap-6">
        <ElementsList
          selectedPersonalizationId={selectedPersonalizationId}
          onSelectPersonalization={onSelectPersonalization}
        />
      </div>
    </LeftPanel>
  )
}

export default LeftSection
