import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { CalendarSquare as CalendarSquareIcon } from 'common/icons'

import 'react-dates/initialize'

import type { DatePickerProps } from './DatePicker'
import DatePicker from './DatePicker'
import {
  renderCustomCloseIcon,
  renderCustomInputIcon,
  renderNavNextButton,
  renderNavPrevButton,
} from './datePickerIcons'

import 'react-dates/lib/css/_datepicker.css'
import styles from './DateStyles.module.css'
import defaultStyles from './DateStylesDefault.module.css'

const DatePickerPerFlag = (
  datePickerProps: Omit<
    DatePickerProps,
    | 'date'
    | 'onDateChange'
    | 'focused'
    | 'onFocusChange'
    | 'renderMonthText'
    | 'renderMonthElement'
  > & {
    value?: moment.Moment | null | undefined
    onChange?: (date: moment.Moment | null, dateString: string) => void
    date?: moment.Moment | null | undefined
    onDateChange?: (date: moment.Moment | null) => void
  }
) => {
  const { date, value, onChange, onDateChange, ...rest } = datePickerProps
  const flags = useFlags()
  const currentDate = value !== undefined ? value : date ?? null

  return flags?.showL2Components ? (
    <div className={styles.container}>
      <DatePicker
        date={currentDate}
        onDateChange={(date) => {
          onDateChange?.(date)
          onChange?.(date, '')
        }}
        renderNavNextButton={renderNavNextButton}
        renderNavPrevButton={renderNavPrevButton}
        customInputIcon={renderCustomInputIcon(datePickerProps.disabled)}
        customCloseIcon={renderCustomCloseIcon()}
        {...rest}
      />
    </div>
  ) : (
    <div className={defaultStyles.container}>
      <DatePicker
        date={currentDate}
        onDateChange={(date) => {
          onDateChange?.(date)
          onChange?.(date, '')
        }}
        customInputIcon={
          <CalendarSquareIcon
            isDefaultColor={!datePickerProps.disabled}
            className={cx('text-base-700', {
              'cursor-not-allowed': datePickerProps.disabled,
            })}
            size={5}
          />
        }
        {...rest}
      />
    </div>
  )
}

export default DatePickerPerFlag

export type { DatePickerProps }
