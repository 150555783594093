import { useEffect, useMemo } from 'react'
import { Field, useForm } from 'react-final-form'

import useGetBrandVoicesQuery from 'common/api/queries/useGetBrandVoicesQuery'
import FormItem from 'common/components/formItem'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import { useAppDispatch } from 'common/hooks/redux'
import { templateSetupComplete } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { getContentPrefixedName } from '../helpers'

import { TemplateInfoWithInternalId } from './AddedComponentList'
import SetupButton from './SetupButton'

const validateBrandVoice = (value: SelectValue) => {
  if (!value) {
    return 'Select your brand voice.'
  }
}

type Props = {
  tabToShow: string | undefined
  template: TemplateInfoWithInternalId
}

const ToggleButtonGroupTabs = ({ tabToShow, template }: Props) => {
  const form = useForm()
  const appDispatch = useAppDispatch()

  const { data } = useGetBrandVoicesQuery()

  const brandVoiceOptions = useMemo(() => {
    return (data || [])
      .sort((a, b) => {
        if (a.isDefault === b.isDefault) {
          return a.name.toLowerCase().localeCompare(b.name.toLocaleLowerCase())
        }
        return a.isDefault ? -1 : 1
      })
      .map(({ id, name }) => ({
        value: id,
        label: name,
      }))
  }, [data])

  const defaultBrandVoice = useMemo(
    () => data?.find((item) => item.isDefault),
    [data]
  )

  // https://github.com/final-form/react-final-form/issues/686

  const brandVoiceInitialValue = useMemo(
    () =>
      brandVoiceOptions.find((item) => item.label === defaultBrandVoice?.name),
    [brandVoiceOptions, defaultBrandVoice]
  )

  const brandVoiceValue = form.getFieldState(
    getContentPrefixedName(template, 'brandVoice')
  )?.value

  useEffect(() => {
    if (!!brandVoiceValue?.value) {
      appDispatch(
        templateSetupComplete({
          internalId: template.internalId,
          status: 'complete',
        })
      )
    }
  }, [appDispatch, brandVoiceValue, template.internalId])

  return (
    <>
      {tabToShow === 'content' && (
        <div className="mt-6">
          <div>
            <Field<SelectValue>
              name={getContentPrefixedName(template, 'brandVoice')}
              validate={validateBrandVoice}
              initialValue={brandVoiceInitialValue}
            >
              {({ input, meta }) => (
                <FormItem
                  label="Brand voice"
                  htmlFor={input.name}
                  error={meta.touched ? meta.error : undefined}
                  className="w-full"
                >
                  <SingleSelect
                    className="w-full"
                    data-cy={`${input.name}-select`}
                    data-testid="brand-voice-select"
                    name={input.name}
                    placeholder="Select your brand voice"
                    value={input.value.value}
                    onChange={(value) => {
                      input.onChange(value)
                      appDispatch(
                        templateSetupComplete({
                          internalId: template.internalId,
                          status: !value ? 'incomplete' : 'complete',
                        })
                      )
                    }}
                    onBlur={input.onBlur}
                    options={brandVoiceOptions}
                    menuPortalTarget={document.body}
                  />
                </FormItem>
              )}
            </Field>
          </div>
        </div>
      )}
      {(tabToShow === 'optimize' || tabToShow === 'personalize') && (
        <div className="mt-6">
          <div className="text-coolGray-800 font-medium text-sm">
            <SetupButton template={template} selectedTab={tabToShow} />
          </div>
        </div>
      )}
    </>
  )
}

export default ToggleButtonGroupTabs
