import { useCallback, useEffect, useRef } from 'react'
import useWebSocket from 'react-use-websocket'
import { useFlags } from 'launchdarkly-react-client-sdk'

import tokenFetcher from 'common/auth/tokenFetcher'
import { useAppSelector } from 'common/hooks/redux'
import Logger from 'common/services/Logger'

const REACT_APP_WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL as string
const HEARTBEAT_INTERVAL = 5 * 60 * 1000 // AWS Idle timeout is 10 minutes

export type WsResponseBase = {
  status?: 'success' | 'error'
  topics?: string[]
  errorMessage?: string
}

let isAuthenticated = false

const useWebsockets = () => {
  const { websocketAuthenticationAcknowledgement } = useFlags()

  const user_id = useAppSelector((state) => state.authStates.user_id)

  const tokenRef = useRef<string>()

  const getSocketUrl = useCallback(async () => {
    tokenRef.current = await tokenFetcher.getAccessToken()
    return REACT_APP_WEBSOCKET_URL
  }, [])

  const webSocket = useWebSocket(getSocketUrl, {
    share: true,
    queryParams: {
      user_id,
    },
    shouldReconnect: () => true,
    reconnectAttempts: 10,
    reconnectInterval: 2000,
    onOpen: () => {
      webSocket.sendJsonMessage({
        action: 'authenticate',
        token: tokenRef.current,
      })
      if (!websocketAuthenticationAcknowledgement) {
        isAuthenticated = true
      }
    },
    onClose: () => {
      isAuthenticated = false
    },
    onMessage: (message) => {
      if (websocketAuthenticationAcknowledgement) {
        const jsonMessage = JSON.parse(message.data)
        if (jsonMessage.authenticated === true) {
          isAuthenticated = true
        }
      }
    },
    onError: (e) => {
      Logger.error('Websocket error', e)
    },
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (webSocket.readyState === WebSocket.OPEN) {
        webSocket.sendJsonMessage({ action: 'healthcheck' })
      }
    }, HEARTBEAT_INTERVAL)

    return () => clearInterval(interval)
  }, [webSocket])

  return {
    ...webSocket,
    isConnectionReady:
      isAuthenticated && webSocket.readyState === WebSocket.OPEN,
  }
}

export default useWebsockets
