/* eslint-disable no-nested-ternary */

/* eslint-disable max-lines */

import { Component } from 'react'
import { connect } from 'react-redux'
import { generatePath } from 'react-router-dom'
import { ClickParam } from 'antd/lib/menu'
import { selectedCampaignPath } from 'app/navigation/paths'
import cx from 'classnames'
import isEqual from 'lodash.isequal'

import BlankScreen from 'common/components/blankScreen'
import { DETAILS_TRANSITION_DURATION } from 'common/components/detailsPanel'
import Overlay from 'common/components/Overlay'
import PageLeaveModal from 'common/components/pageLeaveModal'
import { Fragment, Topic } from 'common/components/topics/interfaces'
import { resetCurrentState } from 'common/components/topics/store/topicsSlice'
import CampaignDetails from 'features/campaigns/components/details/CampaignDetails'
import CampaignsHeader from 'features/campaigns/components/header/'
import ContentGeneration from 'features/campaigns/create/contentGeneration'
import { getContentGenerationPayload } from 'features/campaigns/create/contentGeneration/api/helpers'
import ContentGenerationLoader from 'features/campaigns/create/contentGeneration/components/ContentGenerationLoader'
import ContentGenerationLlm from 'features/campaigns/create/contentGeneration/ContentGenerationLlm'
import {
  cancelCreateCampaign,
  getAllProjects,
  startNewCampaign,
  toggleShowPageExitModal,
} from 'features/campaigns/store/campaignSlice'

import Loader from '../common/components/loaders/Loader'
import { showBanner } from '../workflow/common/common.actions'

import CampaignSetup from './CampaignSetup/CampaignSetup'
import CampaignSummary from './CampaignSummary/CampaignSummary'
import Insights from './Insights/Insights'
import LanguageApproval from './LanguageApproval/LanguageApproval'
import LanguageGeneration from './LanguageGeneration/LanguageGeneration'
import helpers from './utils/helpers'
import { CampaignNavigationSteps, Step, UserInput } from './interface'
import {
  clickToggleCampaignDetails,
  fetchCampaignData,
  saveCampaign,
  setUserInputForNavigation,
} from './Workflow.actions'
import { initialStepsConfig, initialWorkflowState } from './Workflow.reducer'

interface ComponentManagerState {
  campaignName?: string
  showCampaignNameButton: boolean
  isUserInput: boolean
  chosenStep: string | null
  dataFromCampaignProvided: boolean
  selectedCampaignId?: string
  showConfirmationModal: boolean
  clickedStep: Step | undefined
  isSaving: boolean
}

interface ComponentProps {
  match: any
  history: any
  fetchCampaignData: Function
  campaignData: any
  steps: Step[]
  setUserInputForNavigation: Function
  isUserInput: boolean
  chosenStep: string
  campaignId: string
  campaignName: string
  error: string
  showBanner: Function
  results: any
  warnings: Array<string>
  shouldUpdateCampaignData: boolean
  campaignValidationRules: any
  startNewCampaign: Function
  showAdvancedVisualisations: boolean
  shouldShowPageExitModal: boolean
}

export const findCurrentStepName = (
  steps: Step[],
  chosenStep: string | null,
  isUserInput: boolean
) => {
  const activeStep = steps.find((step) => step.active)

  if (isUserInput && chosenStep) {
    return chosenStep
  } else if (activeStep) {
    return activeStep.step
  } else {
    return 'setup'
  }
}

class ComponentManager extends Component<
  ComponentProps & any,
  ComponentManagerState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      campaignName: '',
      showCampaignNameButton: false,
      isUserInput: false,
      chosenStep: null,
      dataFromCampaignProvided: false,
      showConfirmationModal: false,
      clickedStep: undefined,
      isSaving: false,
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { campaignId },
      },
      campaignName,
    } = this.props
    this.stateReset(campaignId, campaignName)
    return true
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { selectedCampaignId: prevSelectedCampaignId } = prevState
    const { selectedCampaignId, setUserInputForNavigation, steps } = nextProps

    if (prevSelectedCampaignId !== selectedCampaignId) {
      setUserInputForNavigation({
        isUserInput: false,
        chosenStep: findCurrentStepName(steps, null, false),
      })
    }
    return null
  }

  componentDidUpdate(prevProps: Readonly<ComponentProps>): void {
    const {
      steps,
      campaignId,
      history,
      campaignName,
      campaignData,
      error,
      showBanner,
      warnings,
      results,
      fetchCampaignData,
      shouldUpdateCampaignData,
      match: {
        params: { campaignId: campaignIdfromUrl },
      },
      chosenStep,
      isUserInput,
      selectedCampaignId,
      accountId,
    } = this.props
    const {
      steps: prevSteps,
      campaignId: prevCampaignId,
      campaignName: prevCampaignName,
      error: prevError,
      results: prevResults,
      warnings: prevWarnings,
      match: {
        params: { campaignId: prevId },
      },
    } = prevProps
    const {
      dataFromCampaignProvided,
      selectedCampaignId: stateSelectedCampaignId,
    } = this.state

    if (campaignIdfromUrl !== prevId) {
      this.stateReset(campaignIdfromUrl, campaignName)
    }
    if (error !== prevError && error) {
      showBanner({ content: error, type: 'error' })
    }
    if (campaignId !== prevCampaignId && campaignId) {
      if (!campaignIdfromUrl) {
        history.push(
          generatePath(selectedCampaignPath, { accountId, campaignId })
        )
      }
    }

    if (stateSelectedCampaignId !== selectedCampaignId) {
      this.setState({ selectedCampaignId })
    }

    if (campaignName !== prevCampaignName && campaignName) {
      this.setState({
        campaignName,
      })
    }
    if (!isEqual(warnings, prevWarnings) && warnings) {
      warnings.forEach((warning: any) => {
        // if the text contains linebreak i.e. <br /> (e.g. when showing subject lines in warnings)
        // we need to add separators
        if (warning.includes('<br />')) {
          warning = warning
            .split('<br />')
            .reduce(
              (finalMessage: any, currentItem: any, currentIndex: any) => {
                return `${finalMessage} ${currentIndex}.) ${currentItem}`
              }
            )
        }
        const config = {
          content: warning,
          type: 'warning',
        }
        showBanner(config)
      })
    }
    if (!isEqual(results, prevResults) && results && shouldUpdateCampaignData) {
      fetchCampaignData(campaignIdfromUrl)
    }
    if (!isEqual(steps, prevSteps)) {
      const { setUserInputForNavigation } = this.props
      setUserInputForNavigation({
        isUserInput: false,
        chosenStep: findCurrentStepName(steps, chosenStep, isUserInput),
      })
    }

    if (!dataFromCampaignProvided) {
      const campaignDataToUse = campaignIdfromUrl
        ? campaignData
        : initialWorkflowState.campaignData

      if (campaignDataToUse && campaignDataToUse.name) {
        this.setState({
          dataFromCampaignProvided: true,
        })
      }
    }
  }

  stateReset = (id: string, campaignName: string) => {
    const { fetchCampaignData, startNewCampaign } = this.props
    if (!id) {
      startNewCampaign()
    } else {
      fetchCampaignData(id)
    }
    this.setState({
      campaignName: id ? campaignName : '',
    })
    resetCurrentState()
  }

  nameRetriever = (campaignName: string) => {
    const { state } = this
    this.setState({
      ...state,
      campaignName,
    })
  }

  renderCurrentStep = (
    stepName: string | null,
    campaignValidationRules: any,
    campaignData: any
  ) => {
    const isReactCampaign = helpers.isReactCampaign(campaignData)
    let stepToRender = stepName

    // EASY ACCESS TO ADVANCED VIS FOR DEV / POC TODO: REMOVE THIS BEFORE PUSHING TO PROD
    const { showAdvancedVisualisations } = this.props
    if (showAdvancedVisualisations) {
      stepToRender = CampaignNavigationSteps.summary
    }

    switch (stepToRender) {
      case CampaignNavigationSteps.setup:
        return (
          <CampaignSetup
            campaignValidationRules={campaignValidationRules}
            nameRetriever={this.nameRetriever}
            campaignData={campaignData}
            campaignName={this.props.campaignName}
          />
        )
      case CampaignNavigationSteps.language_generation:
        return <LanguageGeneration key={campaignData._id} />
      case CampaignNavigationSteps.content_generation:
        return <ContentGeneration key={campaignData._id} />
      case CampaignNavigationSteps.content_generation_llm:
        return <ContentGenerationLlm key={campaignData._id} />
      case CampaignNavigationSteps.language_approval:
        return <LanguageApproval campaignData={campaignData} type="frink" />
      case CampaignNavigationSteps.summary:
        return (
          <CampaignSummary
            updatedCampaignId={this.props.match.params.campaignId}
            innerContainerClassName="p-6"
            outerContainerClassName="flex flex-col flex-auto overflow-y-auto"
            shouldFetchOnMount={true}
            key={campaignData._id}
          />
        )
      case CampaignNavigationSteps.insights:
        return (
          <Insights
            isReactCampaign={isReactCampaign}
            campaignId={campaignData._id}
            variantsWithState={(
              this.props?.results?.variants ??
              this.props?.results?.split_campaign_results
            )?.values?.map(({ _id, variant, variant_status }) => ({
              _id,
              variant,
              variant_status,
            }))}
          />
        )
      default:
        return <Loader />
    }
  }

  onMenuClick = (e: ClickParam, step: Step) => {
    const { chosenStep, setUserInputForNavigation, shouldShowPageExitModal } =
      this.props
    const { step: key, active } = step

    const isDisabled = active && key === chosenStep

    if (isDisabled) {
      return
    }

    if (shouldShowPageExitModal && chosenStep !== key) {
      this.setState({ showConfirmationModal: true, clickedStep: step })
      return
    }

    setUserInputForNavigation({
      isUserInput: true,
      chosenStep: key,
    })
  }

  onConfirmMenuClick = (resetCurrentState?: () => void) => {
    const { chosenStep, setUserInputForNavigation, toggleShowPageExitModal } =
      this.props
    const { step: key, active } = this.state.clickedStep!

    const isDisabled = active && key === chosenStep

    if (isDisabled) {
      return
    }

    if (resetCurrentState) {
      resetCurrentState()
    }

    this.setState({ showConfirmationModal: false, clickedStep: undefined })
    toggleShowPageExitModal(false)
    setUserInputForNavigation({
      isUserInput: true,
      chosenStep: key,
    })
  }

  onConfirmMenuClickAndSave = async (
    currentTopics: Topic[],
    fragments: Fragment[],
    descriptionFragments: Fragment[]
  ) => {
    const { saveCampaign, campaignId, projectId } = this.props

    this.setState({ isSaving: true })
    const contentGenerationPayload = getContentGenerationPayload({
      topics: currentTopics,
      fragments,
      descriptionFragments,
    })

    const data = {
      step: 'content_generation',
      save_and_continue: false,
      save_and_exit: true,
      data: {
        campaign_id: campaignId,
        project_id: projectId,
        ...contentGenerationPayload,
      },
    }
    const result = await saveCampaign(data)
    if (result) {
      this.onConfirmMenuClick()
    }
    this.setState({ isSaving: false })
  }

  updateStepsNavigationValues = (steps: Step[]) => {
    const {
      steps: propsSteps,
      isUserInput,
      chosenStep,
      match: {
        params: { campaignId },
      },
    } = this.props
    const newSteps = propsSteps.map((propsStep: any) => {
      if (propsStep.step === chosenStep) {
        return {
          ...propsStep,
          active: true,
        }
      }
      if (propsStep.active === true) {
        return {
          ...propsStep,
          active: false,
        }
      }
      return propsStep
    })
    return isUserInput && campaignId ? newSteps : steps
  }

  render() {
    const { showConfirmationModal, isSaving } = this.state
    const {
      campaignData,
      steps,
      match: {
        params: { campaignId },
      },
      campaignValidationRules,
      chosenStep,
      isUserInput,
      selectedCampaignId,
      isNewCampaign,
      isDetailsExpanded,
      clickToggleCampaignDetails,
      projects,
      projectId,
      resetCurrentState,
      currentTopics,
      fragments,
      descriptionFragments,
    } = this.props
    const projectName = projects.find(({ id }) => id === projectId)?.name

    const currentStepName = campaignId
      ? findCurrentStepName(steps, chosenStep, isUserInput)
      : CampaignNavigationSteps.setup
    const campaignDataToUse = campaignId
      ? campaignData
      : initialWorkflowState.campaignData

    if (selectedCampaignId || isNewCampaign) {
      return (
        <>
          <div
            className={cx(
              `h-full flex flex-col transition-all duration-${DETAILS_TRANSITION_DURATION}`,
              {
                'mr-18': !isNewCampaign,
                'md:mr-18': !isDetailsExpanded && !isNewCampaign,
                'md:mr-80': isDetailsExpanded && !isNewCampaign,
              }
            )}
          >
            {currentStepName === CampaignNavigationSteps.content_generation && (
              <ContentGenerationLoader />
            )}
            <CampaignsHeader
              onMenuClick={this.onMenuClick}
              projectName={projectName}
            />
            <div className="flex-grow bg-gold-40">
              {this.renderCurrentStep(
                currentStepName,
                campaignValidationRules,
                campaignDataToUse
              )}
            </div>
          </div>
          {!isNewCampaign && (
            <>
              <CampaignDetails
                isExpanded={isDetailsExpanded}
                setIsExpanded={clickToggleCampaignDetails}
                projectName={projectName}
              />
              <Overlay
                className={cx('md:hidden', { hidden: !isDetailsExpanded })}
                onClick={() => clickToggleCampaignDetails(false)}
              />
            </>
          )}
          <PageLeaveModal
            isOpen={showConfirmationModal}
            isSaving={isSaving}
            onConfirm={() =>
              this.onConfirmMenuClickAndSave(
                currentTopics,
                fragments,
                descriptionFragments
              )
            }
            onCancel={() => this.onConfirmMenuClick(resetCurrentState)}
          />
        </>
      )
    } else if (!selectedCampaignId) {
      return <BlankScreen type="experiment" />
    } else {
      return null
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    projects: getAllProjects(state),
    error: state.campaignStates.error,
    userId: state.authStates.user_id,
    campaignData: state.campaignStates.campaignData,
    campaignName: state.campaignStates?.campaignData?.name,
    campaignId: state.campaignStates?.campaignData?._id,
    steps: state.campaignStates?.campaignData?.steps || initialStepsConfig,
    isUserInput: state.campaignStates.isUserInput,
    chosenStep: state.campaignStates.chosenStep,
    results: state.campaignStates.results,
    warnings: state.campaignStates.warnings,
    shouldUpdateCampaignData: state.campaignStates.shouldUpdateCampaignData,
    projectId: state.campaignStates.campaignData.project_id,
    campaignValidationRules: state.campaignStates.campaignValidationRules,
    isNewCampaign: state.campaigns.isNewCampaign,
    selectedCampaignId: state.campaigns.selectedCampaignId,
    isDetailsExpanded: state.campaignStates.isDetailsPanelExpanded,
    accountId: state.authStates.accountId,
    shouldShowPageExitModal: state.campaigns.shouldShowPageExitModal,
    currentTopics: state.contentGeneration.currentTopics,
    fragments: state.contentGeneration.fragments,
    descriptionFragments: state.contentGeneration.fragments,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    startNewCampaign: () => dispatch(startNewCampaign()),
    saveCampaign: (data) => dispatch(saveCampaign(data)),
    fetchCampaignData: (id: string) => dispatch(fetchCampaignData(id)),
    setUserInputForNavigation: (config: UserInput) =>
      dispatch(setUserInputForNavigation(config)),
    showBanner: (config: any) => dispatch(showBanner(config)),
    cancelCreateCampaign: () => dispatch(cancelCreateCampaign()),
    clickToggleCampaignDetails: (val: boolean) =>
      dispatch(clickToggleCampaignDetails(val)),
    toggleShowPageExitModal: (val: boolean) =>
      dispatch(toggleShowPageExitModal(val)),
    resetCurrentState: () => dispatch(resetCurrentState()),
  }
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ComponentManager)
