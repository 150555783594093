import { ReactNode } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useContentHomePathPerFlag from 'common/hooks/useContentPathPerFlag'
import {
  AiContent as AiContentIcon,
  LanguageSetup as LanguageSetupIcon,
  Reaction as ReactionIcon,
} from 'common/icons'
import { clickCreateCampaign } from 'features/campaigns/store/campaignSlice'
import { showTemplates } from 'features/futurama/store/contentSlice'
import ListWidget, {
  ListWidgetAction,
  ListWidgetItem,
} from 'features/homePage/components/listWidget'

import IconTitleGroup from '../../../common/components/iconTitleGroup'
type ToDoItem = {
  id: string
  prefixIcon: ReactNode
  name: string
  progress: string
  action: ListWidgetAction
}

const TodoEmpty = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const accountId = useAppSelector((state) => state.authStates.accountId)
  const contentPathPerFlag = useContentHomePathPerFlag()

  const defaultToDoList: ToDoItem[] = [
    {
      id: 'todo-1',
      prefixIcon: <LanguageSetupIcon isDefaultColor={false} />,
      name: 'Setup your brand voice',
      progress: 'in a few simple steps.',
      action: {
        label: 'Get started',
        onClick: () => {
          history.push(generatePath(`admin/accounts/${accountId}/brand-voice`))
        },
      },
    },
    {
      id: 'todo-2',
      prefixIcon: <ReactionIcon isDefaultColor={false} />,
      name: 'Create your first Experiment',
      progress: 'for optimisation.',
      action: {
        label: 'Create Experiment',
        onClick: () => {
          history.push(generatePath('/campaigns', { accountId }))
          dispatch(clickCreateCampaign())
        },
      },
    },
    {
      id: 'todo-3',
      prefixIcon: <AiContentIcon isDefaultColor={false} />,
      name: 'Start generating content',
      progress: 'assisted with AI',
      action: {
        label: 'Generate Content',
        onClick: () => {
          history.push(generatePath(contentPathPerFlag))
          dispatch(showTemplates())
        },
      },
    },
  ]

  return (
    <ListWidget
      title={
        <div className="flex flex-col w-full">
          <IconTitleGroup title="To-dos" />
          <hr className="w-full mt-4 mb-6 border-gold-400" />
        </div>
      }
      data-cy="todo-widget"
      data-testid="todo-widget"
      className="w-full border-none"
      isLoading={false}
      error={null}
      defaultPadding={false}
      bgClassName="bg-transparent"
    >
      <ul>
        {defaultToDoList.map(
          ({ id, prefixIcon, name, progress, action }: ToDoItem, index) => (
            <ListWidgetItem
              key={id}
              onClick={action.onClick}
              data-cy={`todo-default-widget-link-${index}`}
              data-testid={`todo-default-widget-link-${index}`}
            >
              <span className="text-base-700 group-hover:text-maroon-400">
                {prefixIcon}
              </span>
              <span className="text-base-700 font-medium ml-3">{name}</span>
              <span className="text-coolGray-500"> {progress}</span>
              <span className="text-pink-500 group-hover:underline group-hover:text-maroon-400">
                {action.label}
              </span>
            </ListWidgetItem>
          )
        )}
      </ul>
    </ListWidget>
  )
}

export default TodoEmpty
