import { forwardRef } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import DefaultNumericInput from './DefaultNumericInput'
import type { NumericInputProps } from './NumericInput'
import NumericInput from './NumericInput'

const NumericInputPerFlag = forwardRef<HTMLInputElement, NumericInputProps>(
  (props: NumericInputProps, ref) => {
    const flags = useFlags()

    return flags?.showL2Components ? (
      <NumericInput {...props} ref={ref} />
    ) : (
      <DefaultNumericInput {...props} />
    )
  }
)

export type { NumericInputProps }

export default NumericInputPerFlag
