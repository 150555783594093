import Button from 'common/components/button/Button'
import { ChevronDownSquare, ChevronUpSquare } from 'common/icons'

type Props = {
  isExpanded: boolean
  setIsExpanded: (isExpanded: boolean) => void
}

const ShowMoreButton = ({ isExpanded, setIsExpanded }: Props) => (
  <div className="-mt-4">
    <Button
      variant="link"
      onClick={() => setIsExpanded(!isExpanded)}
      data-cy="show-more-attributes-button"
      data-testid="show-more-attributes-button"
    >
      <div className="flex items-center">
        {isExpanded ? (
          <>
            <ChevronUpSquare size={6} />
            <span className="uppercase font-medium text-xs underline">
              Show less
            </span>
          </>
        ) : (
          <>
            <ChevronDownSquare size={6} />
            <span className="uppercase font-medium text-xs underline">
              Show more
            </span>
          </>
        )}
      </div>
    </Button>
  </div>
)

export default ShowMoreButton
