import { useStackOneHub } from '@stackone/react-hub'
import { useQueryClient } from '@tanstack/react-query'

import { integrationsKeys } from 'common/api/queryKeys/integrationsKeys'
import Modal from 'common/components/Modal'

import useUpdateIntegrationMutation from '../../api/queries/useUpdateIntegrationMutation'

import IntegrationForm from './integrationCard/IntegrationForm'

type Props = {
  onCancel: () => void
  onClose: () => void
  accountId: string
  integrationId: string
  logoUrl?: string
  accountName: string
  label?: string
}

const IntegrationsUpdateModal = ({
  onCancel,
  onClose,
  accountId,
  logoUrl,
  label,
  integrationId,
  accountName,
}: Props) => {
  const updateMutation = useUpdateIntegrationMutation(accountId)
  const { startConnect } = useStackOneHub()
  const queryClient = useQueryClient()

  const handleSave = (label) => {
    updateMutation.mutate(
      { id: integrationId, label },
      {
        onSuccess: (sessionToken) => {
          startConnect({
            sessionToken,
            onSuccess: () => {
              queryClient.invalidateQueries({
                queryKey: integrationsKeys.accounts(accountId),
              })
              onClose()
            },
          })
          onClose()
        },
      }
    )
  }

  return (
    <Modal
      closable={false}
      visible
      centered
      data-testid="integrations-update-modal"
    >
      <IntegrationForm
        logoUrl={logoUrl}
        defaultIntegrationLabel={label}
        onCancel={onCancel}
        onSubmit={handleSave}
        name={accountName}
        submitButtonText="Save New Name"
        inputLabel="Rename card"
        isLoading={updateMutation.isLoading}
      />
    </Modal>
  )
}

export default IntegrationsUpdateModal
