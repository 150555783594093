import { useQueryClient } from '@tanstack/react-query'

import Button from 'common/components/button'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ArrowRight as ArrowRightIcon } from 'common/icons'
import { isTemplateElement } from 'features/futurama/api/interfaces'
import useGenerateVariantsMutation from 'features/futurama/api/mutations/useGenerateVariantsMutation'
import useSelectElementMutation from 'features/futurama/api/mutations/useSelectElementMutation'
import { getGenerateWsTopic } from 'features/futurama/contentPage/ContentPage'
import {
  getAllElements,
  getElementHasVariants,
  setVariantsLoadingState,
} from 'features/futurama/contentPage/helpers'
import { useContent } from 'features/futurama/contentPage/hooks'
import {
  componentConfigurationClicked,
  workflowCardClicked,
} from 'features/futurama/store/contentSlice'

const CrossChannelActionButtons = () => {
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const { content } = useContent()
  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const generateVariantsMutation = useGenerateVariantsMutation()
  const selectElementMutation = useSelectElementMutation()

  const allElements = getAllElements(content)

  const nextElement =
    allElements[
      allElements.findIndex(
        (component) => component.element_id === selectedElementId
      ) + 1
    ]
  const nextElementId = nextElement?.element_id
  const nextComponentElementId = nextElement
    ? isTemplateElement(nextElement)
      ? nextElement.elements[0].element_id
      : nextElementId
    : undefined

  const hasNextComponentVariants = getElementHasVariants(nextElement)

  const generateVariants = () => {
    if (!content) {
      return
    }

    if (
      nextElementId &&
      nextComponentElementId &&
      nextElement.user_input_form_compatible
    ) {
      const wsTopic = getGenerateWsTopic(content._id, nextComponentElementId)

      setVariantsLoadingState(queryClient, content._id, nextComponentElementId)

      generateVariantsMutation.mutate({
        accountId,
        wsTopic,
        contentId: content._id,
        elementId: nextComponentElementId,
      })
    } else if (nextElementId && !nextElement.user_input_form_compatible) {
      selectElementMutation.mutate(
        {
          accountId,
          contentId: content._id,
          elementId: nextElementId,
        },
        {
          onSuccess: () => {
            dispatch(
              componentConfigurationClicked({ elementId: nextElementId })
            )
          },
        }
      )
    }
  }

  const getState = () => {
    if (nextElement && hasNextComponentVariants) {
      return 'continue'
    } else if (nextElement && !hasNextComponentVariants) {
      return 'generate'
    } else {
      return 'empty'
    }
  }

  const state = getState()

  return (
    <>
      {
        {
          continue: (
            <Button
              className="ml-4"
              data-cy="content-generate-cross-continue-button"
              data-testid="content-generate-cross-continue-button"
              variant="primary"
              suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
              onClick={() =>
                dispatch(
                  workflowCardClicked({
                    elementId: nextComponentElementId,
                  })
                )
              }
            >
              Continue
            </Button>
          ),
          generate: (
            <Button
              className="ml-4"
              data-cy="content-generate-cross-next-step-button"
              data-testid="content-generate-cross-next-step-button"
              disabled={
                generateVariantsMutation.isLoading ||
                selectElementMutation.isLoading
              }
              loading={
                generateVariantsMutation.isLoading ||
                selectElementMutation.isLoading
              }
              variant="primary"
              suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
              onClick={generateVariants}
            >
              Generate {nextElement?.display_name || nextElement?.name}
            </Button>
          ),
          empty: null,
        }[state]
      }
    </>
  )
}

export default CrossChannelActionButtons
