import Button from 'common/components/button'
import FormItem from 'common/components/formItem/FormItem'
import Input from 'common/components/input/Input'
import Label from 'common/components/label/Label'
import ModalComponent from 'common/components/Modal'
import Textarea from 'common/components/textarea/Textarea'
import { Info } from 'common/icons'

import { CustomerAttributesResponse } from '../api'

type Props = {
  attribute: CustomerAttributesResponse
  isOpened: boolean
  onClose: () => void
  onClickNeedHelp: () => void
  description: string
  onChangeDescription: (description: string) => void
  onSave: (values: { description: string }) => void
  isSaving: boolean
}

const EditAttributeModal = ({
  attribute,
  isOpened,
  onClose,
  onClickNeedHelp,
  onSave,
  isSaving,
  description,
  onChangeDescription,
}: Props) => {
  return (
    <ModalComponent
      isResponsive
      centered
      visible={isOpened}
      className="max-w-381"
      style={{ maxHeight: 900 }}
      destroyOnClose={true}
      closable={false}
    >
      <div className="text-base-700 text-2xl mb-10"> Edit Attribute</div>
      <form>
        <div>
          <Label>Attribute Name</Label>
          <Input
            type="text"
            value={attribute.name}
            disabled
            className="max-w-85"
          />
        </div>

        <div>
          <FormItem
            label={
              <div className="flex justify-between items-center">
                <div>Description</div>
                <Button
                  variant="link"
                  prefixIcon={<Info isDefaultColor={false} size={4} />}
                  onClick={onClickNeedHelp}
                >
                  Need help with your description?
                </Button>
              </div>
            }
          >
            <Textarea
              value={description}
              onChange={(e) => onChangeDescription(e.target.value)}
              className="min-h-25 flex-1 max-h-106 -mt-2"
              style={{ height: '50vh' }}
            />
          </FormItem>
        </div>
        <div className="flex justify-end gap-4 mt-4">
          <Button variant="link" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => onSave({ description })}
            disabled={description === attribute.description}
            loading={isSaving}
          >
            Save
          </Button>
        </div>
      </form>
    </ModalComponent>
  )
}

const EditAttributeModalWithResetState = (props: Props) => (
  <EditAttributeModal key={`${props.isOpened}`} {...props} />
)
export default EditAttributeModalWithResetState
