import { memo, useCallback, useMemo, useState } from 'react'
import { Row } from 'react-table'
import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { getOptimizedVariantsColumnParams } from 'workflow/common/getColumnParams'

import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import TableCheckbox from 'common/components/table/Checkbox'
import { TableData } from 'common/components/table/Table'
import TableWidget from 'common/components/widget/tableWidget'

import ResultsTableCell from '../../common/resultsTableCell'

import useUpdateVariantStatus from './api/mutations/useUpdateVariantStatus'
import ChangeVariantStatusModal from './components/changeVariantStatusModal'
import { ResultsTableProps, Variant, VariantStatusUpdate } from './interfaces'

import styles from './CampaignResultsTable.module.css'

const CampaignResultsTable = ({
  campaignId,
  results: { values, columns },
  rowSelectionEnabled,
  selectedRowKeys,
  isBanditCampaign,
  isLinguoCampaign,
  isBanditManualConversionsCampaign,
  appliedFilters,
  resultType,
  testTrackedMetrics,
  updateResultsVariantStatus,
  isBroadcastCampaign = false,
  isStatusDropdownDisabled = false,
  showMachineOpens = false,
  rowSelectionAction,
}: ResultsTableProps) => {
  const [variantToUpdate, setVariantToUpdate] =
    useState<VariantStatusUpdate | undefined>(undefined)

  const { isLoading, mutate } = useUpdateVariantStatus({
    campaignId,
    updateResultsVariantStatus,
  })

  const isMatureTabAndAllZeroRecipients =
    resultType === 'mature' && values.every((value) => !value?.num_recipients)

  const tableColumns: Column<Variant>[] = useMemo(() => {
    return [
      {
        id: 'selection',
        className: 'sticky left-0 z-10 bg-inherit',
        headerClassName: 'sticky left-0 z-10',
        Header: ({ getToggleAllRowsSelectedProps }) => {
          return <TableCheckbox {...getToggleAllRowsSelectedProps()} />
        },
        Cell: ({ row }) => {
          return (
            <BaseCell>
              <TableCheckbox
                {...row.getToggleRowSelectedProps()}
                backgroundColor={row.original.color}
              />
            </BaseCell>
          )
        },
      },
      ...columns.map((column) => {
        const {
          dataIndex: accessor,
          key: id,
          title,
          is_percentage,
          isSortingDisabled,
        } = column

        return {
          id,
          Header: is_percentage ? `${title} (%)` : title,
          accessor,
          Cell: ({ row }) => {
            return (
              <ResultsTableCell
                showMachineOpens={showMachineOpens}
                isLoading={isLoading}
                isStatusDropdownDisabled={isStatusDropdownDisabled}
                setVariantToUpdate={setVariantToUpdate}
                data={row.original}
                accessor={accessor}
                testTrackedMetrics={testTrackedMetrics}
                resultType={resultType}
                isMatureTabAndAllZeroRecipients={
                  isMatureTabAndAllZeroRecipients
                }
                isBanditManualConversionsCampaign={
                  isBanditManualConversionsCampaign
                }
              />
            )
          },
          ...getOptimizedVariantsColumnParams(
            accessor,
            isBanditCampaign ?? false,
            isLinguoCampaign ?? false,
            appliedFilters,
            isSortingDisabled,
            rowSelectionEnabled
          ),
        }
      }),
    ]
  }, [
    isLinguoCampaign,
    isBanditCampaign,
    appliedFilters,
    columns,
    isBanditManualConversionsCampaign,
    resultType,
    testTrackedMetrics,
    isLoading,
    isStatusDropdownDisabled,
    isMatureTabAndAllZeroRecipients,
    showMachineOpens,
    rowSelectionEnabled,
  ])

  const tableData: Variant[] = useMemo(
    () =>
      values.map((item) => {
        const { variant, variant_text, ...rest } = item

        return { ...rest, variant: variant_text }
      }),
    [values]
  )

  const onRowSelection = useCallback(
    (rows) => {
      if (!rowSelectionEnabled) {
        return
      }

      rowSelectionAction!(
        rows.map(({ key }) => key),
        rows,
        appliedFilters
      )
    },
    [rowSelectionAction, appliedFilters, rowSelectionEnabled]
  )

  const getInitialSelectedRowIds = () => {
    const defaultFilters =
      isBanditCampaign && isLinguoCampaign
        ? ['live', 'approved', 'pending']
        : isBanditCampaign
        ? ['live', 'approved']
        : []

    const rowKeys =
      selectedRowKeys ||
      values
        .filter((value) => defaultFilters.includes(value.variant_status))
        .map((value) => value.key)

    return rowKeys.reduce((acc, key) => {
      acc[key] = true
      return acc
    }, {})
  }

  const generateRowClassName = useCallback(
    <T extends TableData>({ row }: { row: Row<T> }) => {
      return cx(styles.campaignResultsTable, {
        'bg-gold-40': !row.original.hasError,
      })
    },
    []
  )

  return (
    <>
      <TableWidget.Widget<Variant>
        data-testid="table-view"
        className="border-none bg-gold-40 mt-6"
        data={tableData}
        columns={tableColumns}
        disablePagination
        onSelectRow={onRowSelection}
        initialState={{
          selectedRowIds: getInitialSelectedRowIds(),
          hiddenColumns: rowSelectionEnabled ? [] : ['selection'],
        }}
        generateRowClassName={generateRowClassName}
      ></TableWidget.Widget>

      <ChangeVariantStatusModal
        isOpen={variantToUpdate !== undefined}
        onConfirm={() => {
          if (variantToUpdate) {
            mutate(variantToUpdate)
          }
          setVariantToUpdate(undefined)
        }}
        onCancel={() => {
          setVariantToUpdate(undefined)
        }}
        updateResultsVariantStatus={updateResultsVariantStatus}
        isBroadcastCampaign={isBroadcastCampaign}
        campaignId={campaignId}
      />
    </>
  )
}

const excludedProps = ['results', 'selectedRowKeys']

export default memo(CampaignResultsTable, (oldProps, newProps) => {
  return isEqual(omit(oldProps, excludedProps), omit(newProps, excludedProps))
})
