import { useState } from 'react'

import Avatar from 'common/components/Avatar'
import Button from 'common/components/button'
import Checkbox from 'common/components/checkbox'
import Input from 'common/components/input'
import Loader from 'common/components/loaders/Loader'
import Modal from 'common/components/Modal'
import Scrollable from 'common/components/scrollable'
import { sortAlphabetically } from 'features/admin/users/teams/modal/addTeamFormContent/AddTeamFormContent'

import { User } from '../accessCard/AccessCard'

import styles from './AddUsersModal.module.css'

type Props = {
  isOpen: boolean
  isLoading: boolean
  onCancelClick: () => void
  users: User[]
  assignedUsers: User[]
  applySelectedUsers: (value: User[]) => void
}

const AddUsersModal = ({
  isOpen,
  onCancelClick,
  isLoading,
  users,
  assignedUsers,
  applySelectedUsers,
}: Props) => {
  const [selectedUsers, setSelectedUsers] = useState<User[]>(assignedUsers)
  const [search, setSearch] = useState<string>('')

  const selectUser = (user: User): void => {
    setSelectedUsers((current) => [...current, user])
  }

  const unselectUser = (user: User): void => {
    setSelectedUsers(selectedUsers.filter(({ id }) => id !== user.id))
  }

  const filteredUsers = users.filter(({ firstName, lastName }) => {
    const fullName = `${firstName} ${lastName}`
    return fullName.toLowerCase().includes(search.toLowerCase())
  })
  const hasSearchResults = filteredUsers.length && users.length && !isLoading

  return (
    <Modal
      data-cy="add-users-modal"
      data-testid="add-users-modal"
      visible={isOpen}
      centered
      hasMargin={false}
      closable={false}
      footer={null}
      destroyOnClose
    >
      <div className="flex py-4">
        <Scrollable
          header={
            <div className="px-4 pb-4 flex flex-grow flex-col">
              <div className="mb-4 flex gap-4">
                <h1 className="mt-2 text-2xl font-bold text-coolGray-800">
                  Add users
                </h1>
              </div>
              <Input
                data-cy="search-users"
                data-testid="search-users"
                type="search"
                variant="default"
                placeholder="Search users by name"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                clear={() => setSearch('')}
              />
            </div>
          }
          contentClassName={styles.content}
          content={
            <Loader isLoading={isLoading}>
              <div className="pl-6">
                {filteredUsers.sort(sortAlphabetically).map((user) => {
                  const { id, firstName, lastName, avatarColor } = user

                  return (
                    <Checkbox
                      key={id}
                      data-cy={`user-checkbox-${firstName}-${lastName}`}
                      data-testid={`user-checkbox-${firstName}-${lastName}`}
                      className="py-3 min-w-full hover:bg-gray-50"
                      label={
                        <div className="flex items-center">
                          <Avatar
                            className="text-xs w-6 h-6 mr-4"
                            firstName={firstName}
                            lastName={lastName}
                            color={avatarColor}
                          />
                          {firstName} {lastName}
                        </div>
                      }
                      isChecked={selectedUsers.some((user) => user.id === id)}
                      onChange={(isChecked) =>
                        isChecked ? selectUser(user) : unselectUser(user)
                      }
                    />
                  )
                })}
                {!hasSearchResults && 'No users match search'}
              </div>
            </Loader>
          }
          footer={
            <div className="pt-4 px-4 flex justify-end gap-4">
              <Button
                data-cy="cancel-button"
                data-testid="cancel-button"
                ghost
                onClick={onCancelClick}
              >
                Cancel
              </Button>
              <Button
                data-cy="add-users-button"
                data-testid="add-users-button"
                variant="primary"
                onClick={() => applySelectedUsers(selectedUsers)}
              >
                Add users
              </Button>
            </div>
          }
        />
      </div>
    </Modal>
  )
}

const AddUsersModalWithResetState = (props: Props) => (
  <AddUsersModal key={`${props.isOpen}`} {...props} />
)
export default AddUsersModalWithResetState
