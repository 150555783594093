import { generatePath, useHistory } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { ContentType, ContentTypeIcon } from 'common/components/ContentTypeIcon'
import IconTitleGroup from 'common/components/iconTitleGroup'
import { useAppDispatch } from 'common/hooks/redux'
import useContentHomePathPerFlag from 'common/hooks/useContentPathPerFlag'
import { showTemplates as showLegacyTemplates } from 'features/futurama/store/contentSlice'
import ListWidget, {
  ListWidgetAction,
  ListWidgetItem,
} from 'features/homePage/components/listWidget'
import { showTemplates as showUnifiedFlowTemplates } from 'features/unifiedFlow/store/unifiedFlowSlice'

type Props = {
  contents: {
    id: string
    name: string
    elements: { icon: string | undefined }[]
  }[]
  status: 'error' | 'success' | 'loading'
}

const RecentContents = ({ contents, status }: Props) => {
  const flags = useFlags()
  const dispatch = useAppDispatch()
  const history = useHistory()
  const contentPathPerFlag = useContentHomePathPerFlag()

  const createAction: ListWidgetAction = {
    label: 'Create new content',
    onClick: () => {
      history.push(generatePath(contentPathPerFlag))
      flags.switchToContentLibrary || flags.showUnifiedFlow
        ? dispatch(showUnifiedFlowTemplates())
        : dispatch(showLegacyTemplates())
    },
  }

  return (
    <ListWidget
      title={
        <>
          <IconTitleGroup title="Recent content" />
        </>
      }
      className="w-full h-full border-none"
      bgClassName="bg-transparent"
      data-cy="recent-contents-widget"
      data-testid="recent-contents-widget"
      action={createAction}
      isLoading={status === 'loading'}
      error={
        status === 'error' ? (
          <div className="px-4 py-2">Loading Content failed</div>
        ) : null
      }
    >
      <hr className="w-full mt-3 mb-6 border-gold-400" />
      <ul className="overflow-auto max-h-52">
        {contents.map((item, index) => (
          <ListWidgetItem
            key={item.id}
            onClick={() => {
              history.push(generatePath(`${contentPathPerFlag}/${item.id}`))
            }}
            data-cy={`recent-content-widget-link-${index}`}
            data-testid={`recent-content-widget-link-${index}`}
            className="bg-gold-100 hover:bg-gold-400 cursor-pointer min-h-10 items-center"
          >
            <span className="hover:bg-gold-300">
              <ContentTypeIcon
                className="text-base-700 w-4 h-4"
                contentType={item.elements[0].icon as ContentType}
              />
            </span>
            <span className="text-base-700 ml-3 text-sm">{item.name}</span>
          </ListWidgetItem>
        ))}
      </ul>
    </ListWidget>
  )
}

export default RecentContents
