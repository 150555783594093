import { useState } from 'react'
import { useForm } from 'react-final-form'

import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import { Add } from 'common/icons'
import { TrackingMetric } from 'features/projects/interface'

import AddMetricsModal, { ModalOption } from '../addMetricsModal'

type Option = ModalOption<TrackingMetric>
type Props = {
  options: Option[]
  selectedValues: TrackingMetric[]
}

const AddTrackingMetricsModalButton = ({ options, selectedValues }: Props) => {
  const { change: formChange } = useForm()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const hasOptionalMetrics = options.some((option) => !option.default)

  return (
    <>
      <ButtonWithTooltip
        tooltip={!hasOptionalMetrics}
        tooltipText="No optional metrics available"
        data-cy="add-tracking-metrics-button"
        data-testid="add-tracking-metrics-button"
        variant="primary"
        prefixIcon={<Add isDefaultColor={false} />}
        onClick={() => setIsModalOpen(true)}
        disabled={!hasOptionalMetrics}
      >
        Tracking metric
      </ButtonWithTooltip>
      <AddMetricsModal<TrackingMetric>
        data-cy="add-tracking-metrics"
        data-testid="add-tracking-metrics"
        title="Add tracking metrics"
        applyButtonText="Add tracking metrics"
        isOpen={isModalOpen}
        onAddClick={(selectedValues) => {
          formChange('trackingMetrics', selectedValues)
          setIsModalOpen(false)
        }}
        onCancelClick={() => setIsModalOpen(false)}
        options={options}
        selectedValues={selectedValues}
      />
    </>
  )
}

export default AddTrackingMetricsModalButton
