import { useState } from 'react'

import Button from 'common/components/button'
import type { ImageEntity } from 'common/components/images/api/api'
import CropImage from 'common/components/images/cropImage'
import ImageErrorFallback from 'common/components/images/imageErrorFallback'
import Modal from 'common/components/Modal'
import {
  bytesToKilobytes,
  getFileExtensionFromMimeType,
} from 'common/helpers/files'
import { formatDate } from 'common/helpers/formatDate'
import { Bin as BinIcon, CloseSquare as CloseSquareIcon } from 'common/icons'

import styles from 'common/components/images/imagesStyles.module.css'

type Props = {
  image: Pick<
    ImageEntity,
    'url' | 'fileName' | 'fileSize' | 'updatedAt' | 'contentType'
  >
  isVisible: boolean
  destroyOnClose?: boolean
  onClose: () => void
  onDelete?: () => void
}

const ImagePreviewModal = ({
  isVisible,
  image,
  destroyOnClose = false,
  onClose,
  onDelete,
}: Props) => {
  const [isValidImage, setIsValidImage] = useState<boolean>(!!image.url)

  return (
    <Modal
      closable={false}
      visible={isVisible}
      centered
      destroyOnClose={destroyOnClose}
      className={styles.modal}
      maskClosable
      onClose={onClose}
    >
      <div className="flex pb-6 justify-between	items-center">
        <p className="text-lg font-normal text-base-700 truncate max-w-100">
          {image.fileName}
        </p>
        <Button
          onClick={onClose}
          variant="link"
          size="medium"
          suffixIcon={
            <CloseSquareIcon
              isDefaultColor={false}
              className="text-base-700"
              size={4}
            />
          }
        >
          Close
        </Button>
      </div>
      <div className="flex items-center text-base-700 text-sm font-normal pb-3">
        <p>Aspect Ratio: 1:1</p>
        <div className="h-4 border-l border-gold-400 mx-4" />

        <p>Image weight: {bytesToKilobytes(image.fileSize)}kb</p>
        <div className="h-4 border-l border-gold-400 mx-4" />
        <p>
          Format:{' '}
          <span className="uppercase">
            {getFileExtensionFromMimeType(image.contentType)}
          </span>
        </p>
        {image.updatedAt && (
          <>
            <div className="h-4 border-l border-gold-400 mx-4" />
            <p>Added: {formatDate(image.updatedAt)}</p>
          </>
        )}
      </div>
      {isValidImage ? (
        <CropImage
          width={600}
          height={600}
          src={image.url}
          onError={() => setIsValidImage(false)}
        />
      ) : (
        <ImageErrorFallback width="w-150" height="h-150" hasIcon />
      )}
      {onDelete && (
        <Button
          onClick={onDelete}
          variant="link"
          size="medium"
          suffixIcon={
            <BinIcon
              isDefaultColor={false}
              className="text-base-700"
              size={4}
            />
          }
        >
          Delete
        </Button>
      )}
    </Modal>
  )
}

export default ImagePreviewModal
