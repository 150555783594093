import React from 'react'
import { generatePath, useHistory, useLocation } from 'react-router-dom'
import { TRANSITION_DURATION } from 'app/navigation/constants'
import { campaignsPath, createProjectPath } from 'app/navigation/paths'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  experimentsPermissions,
  getIsAuthorized,
  permissions as allPermissions,
} from 'common/auth/permissions'
import MenuButton, { MenuItem } from 'common/components/menuButton'
import { resetCurrentState as resetContentGenerationState } from 'common/components/topics/store/topicsSlice'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ArrowDropdownDown } from 'common/icons'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'
import { clickCreateCampaign } from 'features/campaigns/store/campaignSlice'

type Props = {
  isCollapsed?: boolean
  className?: string
}

export const createCampaignButtonMessage = {
  expanded: 'Create Experiment',
}

const CreateCampaignButton: React.FC<Props> = ({
  isCollapsed = false,
  className,
}) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const flags = useFlags()
  const { accountId, permissions } = useAppSelector((state) => state.authStates)
  const hasCreateExperimentPermission = getIsAuthorized(
    permissions,
    experimentsPermissions.create
  )

  const canCreateProjects =
    getIsAuthorized(permissions, allPermissions.projectsCreate) &&
    flags.projectScreens

  const onCreateCampaignClick = () => {
    history.push(generatePath(campaignsPath, { accountId }))
    dispatch(clickCreateCampaign())
    dispatch(resetContentGenerationState())
  }

  const onCreateProjectClick = () => {
    const currentPath = location.pathname
    const newPath = generatePath(createProjectPath, { accountId })

    if (currentPath !== newPath) {
      history.push(newPath)
    }
  }

  const options = (
    <>
      <MenuItem
        data-cy="create-experiment-option"
        data-testid="create-experiment-option"
        onClick={onCreateCampaignClick}
      >
        {createCampaignButtonMessage.expanded}
      </MenuItem>
      <MenuItem
        data-cy="create-project-option"
        data-testid="create-project-option"
        onClick={onCreateProjectClick}
      >
        Create Project
      </MenuItem>
    </>
  )

  const buttonProps = {
    'data-cy': 'create-campaign-button',
    'data-testid': 'create-campaign-button',
    className: cx(
      `mt-4 w-52 h-10 flex flex-shrink-0 items-center overflow-hidden text-base 
   text-white font-medium transition-width duration-${TRANSITION_DURATION}
  focus:outline-none focus-visible:outline-black`,
      className,
      {
        'bg-maroon-200': hasCreateExperimentPermission,
        'sm:w-10 sm:rounded-full': isCollapsed,
        'justify-center': isCollapsed || !canCreateProjects,
        'pl-4 pr-2 py-2': !isCollapsed,
        'text-coolGray-400': !hasCreateExperimentPermission,
        'bg-coolGray-100': !hasCreateExperimentPermission,
        'cursor-not-allowed': !hasCreateExperimentPermission,
      }
    ),
    children: (
      <>
        <span
          className={cx({
            'sm:hidden': isCollapsed,
            'flex-grow flex justify-between': canCreateProjects,
          })}
        >
          {createCampaignButtonMessage.expanded}
          {canCreateProjects && (
            <ArrowDropdownDown
              className="text-maroon-200"
              isDefaultColor={false}
            />
          )}
        </span>
        <AddIcon
          className={cx('hidden h-5 w-5', {
            'sm:inline-block': isCollapsed,
          })}
        />
      </>
    ),
  }

  if (canCreateProjects && hasCreateExperimentPermission) {
    return <MenuButton options={options} buttonProps={buttonProps} />
  } else {
    return (
      <button
        {...buttonProps}
        onClick={onCreateCampaignClick}
        disabled={!hasCreateExperimentPermission}
      />
    )
  }
}

export default CreateCampaignButton
