import { useEffect, useState } from 'react'
import { AdvancedVisualisationsDataResult } from 'workflow/interface'

import { connectInternalApi } from 'common/api'
import SingleSelect, {
  SelectValue,
  SingleValue,
} from 'common/components/singleSelect'

import { CampaignListItem } from '../api'

import LineGraphWidget from './LineGraphWidget'

export type RatesResult = {
  average_rate: number[][]
  results: AdvancedVisualisationsDataResult[]
}

type Props = {
  campaigns: CampaignListItem[]
}

const ReactCampaignRates = ({ campaigns }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>()
  const [campaignsList, setCampaignsList] = useState<SelectValue[]>([])
  const [directOpenRateData, setDirectOpenRateData] = useState<RatesResult>()
  const [totalOpensRateData, setTotalOpensRateData] = useState<RatesResult>()
  const [hasErrorDirectOpenRate, setHasDirectOpenRateError] = useState(false)
  const [hasErrorTotalOpensRate, setHasTotalOpensRateError] = useState(false)
  const [openRateData, setOpenRateData] = useState<RatesResult>()
  const [clickRateData, setClickRateData] = useState<RatesResult>()
  const [hasOpenRateError, setHasOpenRateError] = useState(false)
  const [hasClickRateError, setHasClickRateError] = useState(false)

  useEffect(() => {
    const currentCampaigns = campaigns.map((campaign) => ({
      value: campaign.id,
      label: campaign.display_name,
    }))

    setCampaignsList(currentCampaigns)
    setSelectedCampaignId(
      currentCampaigns.length > 0 ? currentCampaigns[0].value : undefined
    )
  }, [campaigns])

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true)
        setHasOpenRateError(false)
        setHasClickRateError(false)
        setHasDirectOpenRateError(false)
        setHasTotalOpensRateError(false)

        const openRatesPromise = connectInternalApi.get<RatesResult>(
          `/v1/core/reporting/reporting/campaigns/${selectedCampaignId}/data/openrates`,
          {
            params: {
              data_filter: 'mature',
            },
          }
        )

        const directOpenRatesPromise = connectInternalApi.get<RatesResult>(
          `/v1/core/reporting/reporting/campaigns/${selectedCampaignId}/data/rates?rate=direct_open_rate`,
          {
            params: {
              data_filter: 'mature',
            },
          }
        )

        const clickRatesPromise = connectInternalApi.get<RatesResult>(
          `/v1/core/reporting/reporting/campaigns/${selectedCampaignId}/data/clickrates`,
          {
            params: {
              data_filter: 'mature',
            },
          }
        )

        const totalOpensRatePromise = connectInternalApi.get<RatesResult>(
          `/v1/core/reporting/reporting/campaigns/${selectedCampaignId}/data/rates?rate=total_opens_rate`,
          {
            params: {
              data_filter: 'mature',
            },
          }
        )

        const [
          openRateResult,
          clickRateResult,
          directOpenRatesResult,
          totalOpensRateData,
        ] = await Promise.allSettled([
          openRatesPromise,
          clickRatesPromise,
          directOpenRatesPromise,
          totalOpensRatePromise,
        ])

        if (openRateResult.status === 'fulfilled') {
          setOpenRateData(openRateResult.value.data)
        } else {
          setHasOpenRateError(true)
        }

        if (clickRateResult.status === 'fulfilled') {
          setClickRateData(clickRateResult.value.data)
        } else {
          setHasClickRateError(true)
        }

        if (directOpenRatesResult.status === 'fulfilled') {
          setDirectOpenRateData(directOpenRatesResult.value.data)
        } else {
          setHasDirectOpenRateError(true)
        }

        if (totalOpensRateData.status === 'fulfilled') {
          setTotalOpensRateData(totalOpensRateData.value.data)
        } else {
          setHasDirectOpenRateError(true)
        }
      } catch {
        setHasOpenRateError(true)
        setHasClickRateError(true)
        setHasDirectOpenRateError(true)
        setHasTotalOpensRateError(true)
      } finally {
        setIsLoading(false)
      }
    }

    if (selectedCampaignId) {
      fetchData()
    }
  }, [selectedCampaignId])

  if (campaignsList.length === 0) {
    return null
  }

  return (
    <>
      <SingleSelect
        className="w-69 mt-10"
        data-cy="reports-react-graphs-campaign-select"
        data-testid="reports-react-graphs-campaign-select"
        label="Experiment"
        placeholder="Select experiment"
        onChange={(newValue: SingleValue<SelectValue>) =>
          setSelectedCampaignId(newValue?.value)
        }
        value={selectedCampaignId}
        options={campaignsList}
        isSearchable={true}
      />
      <LineGraphWidget
        title="Open rate"
        isLoading={isLoading}
        graphData={openRateData}
        hasError={hasOpenRateError}
      />
      <LineGraphWidget
        title="Click rate"
        isLoading={isLoading}
        graphData={clickRateData}
        hasError={hasClickRateError}
      />
      <LineGraphWidget
        title="Direct Open rate"
        isLoading={isLoading}
        graphData={directOpenRateData}
        hasError={hasErrorDirectOpenRate}
      />
      <LineGraphWidget
        title="Total Opens rate"
        isLoading={isLoading}
        graphData={totalOpensRateData}
        hasError={hasErrorTotalOpensRate}
      />
    </>
  )
}

export default ReactCampaignRates
