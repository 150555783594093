import { Form } from 'react-final-form'
import compact from 'lodash/compact'

import Button from 'common/components/button'
import Drawer from 'common/components/drawer'
import { useAppSelector } from 'common/hooks/redux'
import useCreateMergeTagMutation from 'features/admin/accountSettings/api/mutations/useCreateMergeTagMutation'

import FormContent from './FormContent'

const ManageMergeTagDrawer = ({ isOpen, onOpenChange }) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const createMergeTagMutation = useCreateMergeTagMutation()

  const handleSave = async (values) => {
    const name = values.merge_tag_name
    const description = values.merge_tag_description
    const category = values.merge_tag_category.label
    const code = values.merge_tag_code
    const exampleValues = compact<string>(values.merge_tag_example_values)
    const integration = values.merge_tag_integration.value
    const customCategory = values.merge_tag_custom_category_name

    const payload = {
      accountId,
      name,
      description,
      category:
        category?.toLowerCase() === 'custom' ? customCategory : category,
      code,
      example_values: exampleValues,
      integration,
    }

    createMergeTagMutation.mutate(payload, {
      onSuccess: () => {
        onOpenChange(false)
      },
    })
  }

  return (
    <Drawer
      isOpen={isOpen}
      title="Create a new merge tag"
      onOpenChange={onOpenChange}
      content={
        <div className="w-219 flex flex-col h-full">
          <Form
            onSubmit={handleSave}
            render={({
              handleSubmit,
              hasValidationErrors,
              pristine,
              values,
            }) => {
              const hasAtleastOnExample = values.merge_tag_example_values?.some(
                (value) => value
              )

              return (
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col flex-1 h-full"
                >
                  <FormContent />
                  <div className="flex flex-none justify-end mt-auto w-full py-6 border-t border-gold-400 gap-3">
                    <Button
                      onClick={() => onOpenChange(false)}
                      data-testid="add-merge-tag-drawer-cancel-button"
                      className="flex items-center bg-gold-300"
                    >
                      <span className="text-gold-700">Cancel</span>
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      className="mr-6"
                      data-testid="add-merge-tag-drawer-add-button"
                      loading={createMergeTagMutation.isLoading}
                      disabled={
                        hasValidationErrors ||
                        pristine ||
                        createMergeTagMutation.isLoading ||
                        !hasAtleastOnExample
                      }
                    >
                      Save
                    </Button>
                  </div>
                </form>
              )
            }}
          />
        </div>
      }
    />
  )
}

export default ManageMergeTagDrawer
