import { MergeTagListItem, MergeTagsBEResponse } from './api'

export const mapMergeTag = (
  mergeTag: MergeTagsBEResponse
): MergeTagListItem => ({
  id: mergeTag._id,
  name: mergeTag.name,
  description: mergeTag.description,
  integration: mergeTag.integration,
  category: mergeTag.category,
  code: mergeTag.code,
  lastModified: mergeTag.last_updated,
  exampleValues: mergeTag.example_values,
})
