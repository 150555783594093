import { useEffect, useRef, useState } from 'react'
import { Field, useForm } from 'react-final-form'
import {
  calculateSplitResult,
  CalculatorResponse,
} from 'workflow/CampaignSetup/SplitCalculator/calculatorService'
import SplitSize from 'workflow/CampaignSetup/SplitSize'
import { showBanner } from 'workflow/common/common.actions'
import { SplitSizeData } from 'workflow/interface'

import {
  DropdownType,
  NumericType,
  PercentageType,
} from 'common/components/dynamicFields/interfaces'
import { useAppDispatch } from 'common/hooks/redux'
import useDebounce from 'common/hooks/useDebounce'
import { SplitCalculatorConfiguration } from 'features/unifiedFlow/api'
import { updateTemplateSplitConfiguration } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { TemplateInfoWithInternalId } from '../../addedTemplates/AddedComponentList'
import { getPrefixedName, getSelectedProjectId } from '../../helpers'

import CalculatorCard from './components/CalculatorCard'
import ListSize from './components/ListSize'
import Rate from './components/Rate'
import SelectionMetric from './components/SelectionMetric'

export const SPLIT_CACLULATOR_DELAY = 500

export const validateSplitSize = (value?: number) => {
  if (!value) {
    return 'Please input the number of splits'
  }
}

type Props = {
  configuration: SplitCalculatorConfiguration['splitCalculation']
  template?: TemplateInfoWithInternalId
  isDisabled?: boolean
  splitCalculationResult?: SplitSizeData
  onLoading?: (isLoading: boolean) => void
  onResult?: (result?: SplitSizeData) => void
  resetCalculatorCallback?: (callback: () => void) => void
}

const calculateColSpan = (condition: boolean) => (condition ? 1 : 0)

const MetricSelection = ({
  configuration,
  template,
  splitCalculationResult: splitCalculationResultInitial,
  isDisabled = false,
  onLoading,
  onResult,
  resetCalculatorCallback,
}: Props) => {
  const form = useForm()
  const dispatch = useAppDispatch()

  const [splitCalculationResult, setSplitCalculationResult] = useState<
    SplitSizeData | undefined
  >(splitCalculationResultInitial)

  const [isCalculatorLoading, setIsCalculatorLoading] = useState(false)
  const previousCalculationParamsString = useRef<string>('')

  const selectionMetricConfiguration = configuration.fields.find(
    (field) => field.name === 'selection_metric'
  ) as DropdownType
  const listSizeConfiguration = configuration.fields.find(
    (field) => field.name === 'list_size'
  ) as NumericType
  const openRateConfiguration = configuration.fields.find(
    (field) => field.name === 'baseline_open_rate'
  ) as PercentageType
  const clickRateConfiguration = configuration.fields.find(
    (field) => field.name === 'baseline_click_rate'
  ) as PercentageType

  const selectionMetricValue = form.getFieldState(
    getPrefixedName('selectionMetric', template)
  )?.value?.value

  const selectedProjectId = getSelectedProjectId(form.getState(), template)

  const shouldShowClickRate =
    selectionMetricValue === 'click_rate' ||
    selectionMetricValue === 'phrasee_score'
  const shouldShowOpenRate =
    selectionMetricValue === 'open_rate' ||
    selectionMetricValue === 'phrasee_score'
  const isListSizeValid = form.getFieldState(
    getPrefixedName('listSize', template)
  )?.valid

  const getSplitCalculation = async ({
    minSplitNumber,
    maxSplitNumber,
  }: { minSplitNumber?: number; maxSplitNumber?: number } = {}) => {
    const listSizeValue = form.getFieldState(
      getPrefixedName('listSize', template)
    )?.value
    const baselineOpenRateValue = form.getFieldState(
      getPrefixedName('openRate', template)
    )?.value
    const baselineClickRateValue = form.getFieldState(
      getPrefixedName('clickRate', template)
    )?.value

    const calculationParamsString = [
      selectionMetricValue,
      listSizeValue,
      baselineOpenRateValue,
      baselineClickRateValue,
    ].join('-')

    if (
      previousCalculationParamsString.current === '' ||
      previousCalculationParamsString.current !== calculationParamsString ||
      maxSplitNumber ||
      minSplitNumber
    ) {
      previousCalculationParamsString.current = calculationParamsString
      setIsCalculatorLoading(true)
      onLoading?.(true)

      form.mutators.setError(getPrefixedName('listSize', template), undefined)

      let splitResult: CalculatorResponse = {}
      try {
        splitResult = await calculateSplitResult(selectedProjectId, undefined, {
          // TODO: Add campaignId here when it is available
          selectionMetric: selectionMetricValue,
          listSize: listSizeValue,
          baselineOpenRate: baselineOpenRateValue,
          baselineClickRate: baselineClickRateValue,
          maxSplitNumber,
          minSplitNumber,
        })
      } catch (error: any) {
        if (error.name === 'INVALID AUDIENCE_SIZE') {
          form.mutators.setError(
            getPrefixedName('listSize', template),
            error.message
          )
        } else {
          showBanner({ content: error.message, type: 'error' })
        }
      }

      if (splitResult.result && splitResult.expectedEngagementRates) {
        const rates = splitResult.expectedEngagementRates
        const expectedBaselineClickRateValue = rates['click_rate']?.value
        const expectedBaselineOpenRateValue = rates['open_rate']?.value

        if (selectionMetricValue === 'phrasee_score') {
          form.change(
            getPrefixedName('clickRate', template),
            baselineClickRateValue || expectedBaselineClickRateValue
          )
          form.change(
            getPrefixedName('openRate', template),
            baselineOpenRateValue || expectedBaselineOpenRateValue
          )
        } else if (!baselineClickRateValue && shouldShowClickRate) {
          form.change(
            getPrefixedName('clickRate', template),
            expectedBaselineClickRateValue
          )
        } else if (!baselineOpenRateValue && shouldShowOpenRate) {
          form.change(
            getPrefixedName('openRate', template),
            expectedBaselineOpenRateValue
          )
        }
      }
      setSplitCalculationResult(splitResult.result)
      onResult?.(splitResult.result)
      setIsCalculatorLoading(false)
      onLoading?.(false)
    }
  }

  const getSplitCalculationDebounced = useDebounce(
    getSplitCalculation,
    SPLIT_CACLULATOR_DELAY
  )

  const clearFields = () => {
    form.change(getPrefixedName('openRate', template), undefined)
    form.change(getPrefixedName('clickRate', template), undefined)
    form.change(getPrefixedName('listSize', template), undefined)
    form.resetFieldState(getPrefixedName('listSize', template))
    setSplitCalculationResult(undefined)
    onResult?.(undefined)
  }

  useEffect(() => {
    if (template?.internalId) {
      dispatch(
        updateTemplateSplitConfiguration({
          internalId: template.internalId,
          splitConfiguration: {
            type: 'metric_selection',
            splitCalculationResult: splitCalculationResult,
          },
        })
      )
    }
  }, [dispatch, splitCalculationResult, template?.internalId])

  useEffect(() => {
    if (resetCalculatorCallback && splitCalculationResultInitial) {
      resetCalculatorCallback?.(() => {
        setSplitCalculationResult(splitCalculationResultInitial)
        form.mutators.setError(getPrefixedName('listSize', template), undefined)
      })
    }
  }, [
    form.mutators,
    resetCalculatorCallback,
    splitCalculationResultInitial,
    template,
  ])

  return (
    <CalculatorCard className="flex-col">
      {selectionMetricConfiguration && (
        <SelectionMetric
          name={getPrefixedName('selectionMetric', template)}
          configuration={selectionMetricConfiguration}
          hasInitialValue={!!template}
          onChange={clearFields}
          isDisabled={isDisabled}
        />
      )}

      <div
        className={`grid w-full gap-4 grid-cols-${
          calculateColSpan(!!(openRateConfiguration && shouldShowOpenRate)) +
          calculateColSpan(!!listSizeConfiguration)
        }`}
      >
        {listSizeConfiguration && (
          <ListSize
            name={getPrefixedName('listSize', template)}
            configuration={listSizeConfiguration}
            onChange={() => getSplitCalculationDebounced()}
            isDisabled={isDisabled}
          />
        )}
        {openRateConfiguration && shouldShowOpenRate && (
          <Rate
            name={getPrefixedName('openRate', template)}
            data-cy="openRate-input"
            data-testid="openRate-input"
            isDisabled={!isListSizeValid || isDisabled}
            configuration={openRateConfiguration}
            className="last:mb-6"
            onChange={() => getSplitCalculationDebounced()}
          />
        )}
      </div>
      {clickRateConfiguration && shouldShowClickRate && (
        <Rate
          name={getPrefixedName('clickRate', template)}
          data-cy="clickRate-input"
          data-testid="clickRate-input"
          isDisabled={!isListSizeValid || isDisabled}
          configuration={clickRateConfiguration}
          className="last:mb-6"
          onChange={() => getSplitCalculationDebounced()}
        />
      )}
      <Field<number>
        name={getPrefixedName('splitNumber', template)}
        validate={validateSplitSize}
        initialValue={splitCalculationResult?.split_number}
      >
        {({ input }) => (
          <SplitSize
            data={splitCalculationResult}
            updateSplitSizeNumber={(newSplitNumber: number) => {
              const splitNumber = splitCalculationResult?.split_number
              if (!splitNumber || newSplitNumber === splitNumber) {
                return
              }
              input.onChange(newSplitNumber)

              if (newSplitNumber > splitNumber!) {
                getSplitCalculationDebounced({
                  minSplitNumber: newSplitNumber,
                })
              } else {
                getSplitCalculationDebounced({
                  maxSplitNumber: newSplitNumber,
                })
              }
            }}
            value={splitCalculationResult?.split_number}
            isLoading={isCalculatorLoading}
            isDisabled={isDisabled}
          />
        )}
      </Field>
    </CalculatorCard>
  )
}

export default MetricSelection
