import { NLGAction } from '@phrasee/phrasee-typings'

import LoadingOverlay from 'common/components/LoadingOverlay'

const getGeneratePhrases = () => {
  return [
    `Generating 100s of unique variants…`,
    'Curating variants to match your brand voice…',
    `Discarding non-compliant variants…`,
    'Running Performance Prediction Engine…',
    'Selecting high-performing variants…',
    'Selecting variants for reverification…',
    'Selecting variants to maximise new data points…',
  ]
}
const briefPhrases = [`Creating optimised brief...`]
const topicsPhrases = ['Extracting topics and entities…']

type Props = {
  isLoading: boolean
  action?: NLGAction
}

const ContentLoadingOverlay = ({ isLoading, action }: Props) => {
  let phrases: string[] = []
  switch (action) {
    case 'brief':
      phrases = briefPhrases
      break
    case 'topics':
      phrases = topicsPhrases
      break
    default:
      phrases = getGeneratePhrases()
      break
  }

  return (
    <LoadingOverlay
      isLoading={isLoading}
      phrases={phrases}
      className="z-100"
      overlayType="local"
    />
  )
}

export default ContentLoadingOverlay
