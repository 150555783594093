import { useEffect, useState } from 'react'
import cx from 'classnames'

import { connectInternalApi } from 'common/api'
import * as GraphWidget from 'common/components/GraphWidget'
import InfoAction from 'common/components/InfoAction'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import ChartWidgetMenu from 'common/components/widget/menu/ChartWidgetMenu'
import { WidgetError, WidgetFirstUse } from 'common/components/WidgetMessage'
import { useAppSelector } from 'common/hooks/redux'
import { useChartRefCallback } from 'common/hooks/useChartRefCallback'

import { selectEndDate, selectStartDate } from '../../../store/dashboardSlice'

import FeedbackWidget from './FeedbackWidget'
import UsageGraph, { UsageGraphData } from './UsageGraph'

interface Props {
  title: string
  accountId: string
  projectIds: string[]
}

const UsageWidget = ({ title, accountId, projectIds }: Props) => {
  const [variations, setVariations] = useState<UsageGraphData>()
  const [campaigns, setCampaigns] = useState<UsageGraphData>()
  const [categories, setCategories] = useState<string[]>([])

  const startDate = useAppSelector(selectStartDate)
  const endDate = useAppSelector(selectEndDate)

  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { chart, chartRefCallback } = useChartRefCallback()

  useEffect(() => {
    function fetchData() {
      return connectInternalApi.get<{
        filters: {
          start_date: string
          period_type: string
          usage_type: string[]
        }
        data: [
          { data: number[]; label: string },
          { data: number[]; label: string }
        ]
        xAxis: { categories: string[] }
      }>(
        `v1/core/reporting/reporting/accounts/${accountId}/usage/report/graph-data`,
        {
          params: {
            project_ids: projectIds,
            usage_type: ['campaigns', 'variants'],
            start_date: startDate,
            end_date: endDate,
          },
        }
      )
    }

    setHasError(false)
    fetchData()
      .then((graphResponse) => {
        const { data: graphData, xAxis } = graphResponse.data
        setCampaigns(graphData[0])

        setVariations(graphData[1])
        setCategories(xAxis.categories)
      })
      .catch((err) => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [accountId, projectIds, startDate, endDate])

  const areResultsOnlyZeros =
    campaigns?.data.every((val) => val === 0) &&
    variations?.data.every((val) => val === 0)

  const hasResults = !areResultsOnlyZeros && !isLoading && !hasError
  const firstUse = areResultsOnlyZeros && !isLoading && !hasError

  return (
    <>
      <GraphWidget.Widget
        title={<div>{title}</div>}
        actions={
          hasResults ? (
            <div className="inline-flex">
              <ChartWidgetMenu chart={chart} title={title} />
              <InfoAction
                message={`Keep a track of ongoing usage and watch out
                for increases in feedback for the team. If you find the
                team is having to add too much feedback then speak to our
                Customer Success team.`}
              />
            </div>
          ) : undefined
        }
        className="pb-0"
      >
        <div className="flex flex-col sm:flex-row">
          <div className={cx('flex-grow', { 'mb-6': firstUse || hasError })}>
            {isLoading && (
              <WidgetLoader
                data-cy="usage-widget-loader"
                data-testid="usage-widget-loader"
              />
            )}
            {firstUse && <WidgetFirstUse />}
            {hasError && <WidgetError className="h-full" />}
            {!!hasResults ? (
              <UsageGraph
                variations={variations}
                campaigns={campaigns}
                ref={chartRefCallback}
                categories={categories}
              />
            ) : undefined}
          </div>

          {hasResults && (
            <div className="sm:ml-12 sm:w-69 mb-6">
              <FeedbackWidget accountId={accountId} projectIds={projectIds} />
            </div>
          )}
        </div>
      </GraphWidget.Widget>
    </>
  )
}

export default UsageWidget
