import cx from 'classnames'

import Image from 'common/components/image'

type Props = {
  name: string
  logoUrl?: string
  className?: string
  onClick?: () => void
}

const IntegrationDetails = ({ name, logoUrl, className, onClick }: Props) => {
  return (
    <div
      className={cx('flex flex-col items-center', className)}
      onClick={onClick}
      role="button"
    >
      <div className="mb-4 h-32">
        <Image isResponsive src={logoUrl} alt={`${name}-logo`} />
      </div>
      <div className="text-coolGray-800 text-base font-medium">{name}</div>
    </div>
  )
}

export default IntegrationDetails
