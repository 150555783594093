import Button from 'common/components/button/Button'
import { ArrowRight } from 'common/icons'

import { CampaignTypes } from './CampaignMetrics'

type Props = {
  campaignType: CampaignTypes
}

const UpgradeNotice = ({ campaignType }: Props) => {
  const openContactUsPage = () => {
    window.location.href = 'https://jacquard.com/contact/'
  }

  return (
    <div
      data-cy={`upgrade-${campaignType}-widget`}
      data-testid={`upgrade-${campaignType}-widget`}
      className="max-h-full px-6 py-4 box-border shadow-widget rounded-sm bg-gold-100"
    >
      <div className="flex flex-col justify-between items-start gap-4">
        <div className="font-relais font-medium text-2xl text-base-700">
          Elevate marketing with {campaignType} campaigns
        </div>

        <div className="font-normal text-xs text-base-700">
          Engage customers, optimising messaging in real-time for peak
          performance.
        </div>

        <Button
          size="small"
          variant="primary"
          data-cy={`upgrade-${campaignType}-project-button`}
          data-testid={`upgrade-${campaignType}-project-button`}
          onClick={() => openContactUsPage()}
          suffixIcon={<ArrowRight size={4.5} isDefaultColor={false} />}
          className="uppercase"
        >
          Upgrade now
        </Button>
      </div>
    </div>
  )
}

export default UpgradeNotice
