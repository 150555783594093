import { Option } from 'common/components/tags'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { IntegrationConfiguration } from 'features/projects/interface'

import MetricsItem from './components/MetricsItem'

type Props = {
  projectIntegrationMetricsSettings: {
    data?: IntegrationConfiguration
    show: boolean
    name: string
  }
}

const ProjectIntegrationMetricsCard = ({
  projectIntegrationMetricsSettings,
}: Props) => {
  const rawMetrics: Option<string>[] =
    projectIntegrationMetricsSettings?.data?.raw_metrics?.map((metric) => {
      return {
        label: metric.display_name,
        value: metric.event_type_name,
        isDisabled: true,
      }
    }) ?? []

  const additionalMetrics: Option<string>[] =
    projectIntegrationMetricsSettings.data?.derived_metrics?.map((metric) => ({
      value: metric.display_name,
      label: metric.display_name,
      isDisabled: true,
    })) ?? []

  return (
    <Widget data-cy="integration-metrics" data-testid="integration-metrics">
      <WidgetHeader title="Project Metrics" />
      <div className="flex flex-col gap-6">
        {rawMetrics.length > 0 && (
          <MetricsItem
            data-cy="raw-metrics"
            data-testid="raw-metrics"
            title="Raw Metrics"
            subtitle="Data as it comes into our system, usually as individual events."
            data={rawMetrics}
          />
        )}
        {additionalMetrics.length > 0 && (
          <MetricsItem
            data-cy="additional-metrics"
            data-testid="additional-metrics"
            title="Additional Metrics"
            subtitle="Metrics that are calculated based on the raw metrics above, e.g. a
            rate or a total."
            data={additionalMetrics}
          />
        )}
      </div>
    </Widget>
  )
}

export default ProjectIntegrationMetricsCard
