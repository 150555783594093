import { unifiedFlowPaths } from 'app/navigation/paths'

import Button from 'common/components/button'
import { errorToast } from 'common/components/toastNotification'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'
import useApproveElementMutation from 'features/unifiedFlow/api/mutations/useApproveElementMutation'
import { getAllElements } from 'features/unifiedFlow/contentPage/helpers'
import {
  useContent,
  useSelectedElement,
} from 'features/unifiedFlow/contentPage/hooks'

import useAddElementImagesMutation from '../../api/mutations/useAddElementImagesMutation'
import useGenerateImageTextCombinationsMutation from '../../api/mutations/useGenerateImageTextCombinationsMutation'

type Props = {
  isReadOnly?: boolean
  isApproveButtonDisabled?: boolean
  imageIds: string[]
  isVariantOnly: boolean
}
const ImagesActionButtons = ({
  isApproveButtonDisabled,
  isReadOnly = false,
  imageIds,
  isVariantOnly,
}: Props) => {
  const { content } = useContent()
  const addElementImagesMutation = useAddElementImagesMutation()

  const approveElementMutation = useApproveElementMutation({
    redirectTo: unifiedFlowPaths.launch,
  })

  const generateImageTextCombinationsMutation =
    useGenerateImageTextCombinationsMutation()

  const { data: selectedElement } = useSelectedElement()

  const allComponentElements =
    getAllElements(content).filter(isComponentElement)

  const approvedElements = allComponentElements.filter(
    (element) => element.approved
  )

  const handleApprove = async () => {
    //TODO: To make it more efficient, these 3 requests should be combined into one in COS-498.
    try {
      if (selectedElement && content) {
        await addElementImagesMutation.mutateAsync({
          isVariantOnly,
          imageIds,
        })

        await generateImageTextCombinationsMutation.mutateAsync({
          imageIds,
        })

        await approveElementMutation.mutateAsync({
          accountId: content.account_id,
          element_id: selectedElement.element_id,
          content_id: content?._id,
        })
      }
    } catch (error) {
      errorToast('Something went wrong. Please try again.')
    }
  }

  return (
    <div className="flex items-center justify-between w-full mt-8">
      <p className="text-gold-500 font-normal text-sm">
        {`${approvedElements.length} out of ${allComponentElements.length} approvals complete`}
      </p>
      <Button
        disabled={
          isApproveButtonDisabled ||
          approveElementMutation.isLoading ||
          isReadOnly
        }
        loading={approveElementMutation.isLoading}
        className="ml-4 cta-button"
        variant="primary"
        onClick={handleApprove}
      >
        {selectedElement?.approved ? 'Approved' : 'Approve'}
      </Button>
    </div>
  )
}

export default ImagesActionButtons
