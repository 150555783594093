import { generatePath, useHistory } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'
import { useAppDispatch } from 'common/hooks/redux'
import { getAllElements } from 'features/unifiedFlow/contentPage/helpers'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import useVariantsToApproveQuery from '../../contentPage/hooks/useVariantsToApproveQuery'
import { approveElement } from '../api'
import { isComponentElement } from '../interfaces'
import { contentKeys } from '../queryKeys'

const useApproveElementMutation = ({
  redirectTo,
  selectedElementId,
}: {
  redirectTo: string
  selectedElementId?: number
}) => {
  const dispatch = useAppDispatch()
  const { invalidateAll: invalidateAllVariantsToApproveCountQuery } =
    useVariantsToApproveQuery()
  const queryClient = useQueryClient()
  const history = useHistory()

  return useMutation(approveElement, {
    onError: () => {
      errorToast('Something went wrong. Please try again.')
    },
    onSuccess: (content) => {
      queryClient.setQueryData(contentKeys.content(content._id), content)
      invalidateAllVariantsToApproveCountQuery()

      const allComponentElements =
        getAllElements(content).filter(isComponentElement)

      const notApprovedElements = allComponentElements.filter((element) => {
        return (
          !element.approved ||
          (element.personalized_ids_to_approve?.length ?? 0) > 0
        )
      })

      const selectedElement = notApprovedElements.find(
        (element) => element.element_id === selectedElementId
      )

      if (notApprovedElements.length > 0) {
        if (selectedElement) {
          dispatch(selectElement({ elementId: selectedElement.element_id }))
        } else {
          dispatch(
            selectElement({ elementId: notApprovedElements[0].element_id })
          )
        }
      } else {
        queryClient.refetchQueries(
          contentKeys.contentLibrary(content.account_id)
        )
        history.push(generatePath(redirectTo, { contentId: content?._id }))
      }
    },
  })
}

export default useApproveElementMutation
