import { useQuery } from '@tanstack/react-query'

import { getSplitCalculatorConfiguration } from '../api'
import { contentKeys } from '../queryKeys'

const useGetSplitCalculatorConfigurationQuery = ({
  projectId,
  isImageOptimizationEnabled,
}: {
  projectId?: string
  isImageOptimizationEnabled?: boolean
}) => {
  const getSplitCalculatorConfigurationQuery = useQuery(
    contentKeys.projectConfiguration(projectId!, isImageOptimizationEnabled),
    () =>
      getSplitCalculatorConfiguration({
        projectId: projectId!,
        isImageOptimizationEnabled,
      }),
    {
      enabled: !!projectId,
      staleTime: Infinity,
    }
  )

  return getSplitCalculatorConfigurationQuery
}

export default useGetSplitCalculatorConfigurationQuery
