import useVariantsToApproveQuery from '../../hooks/useVariantsToApproveQuery'

const ApprovalCounterBadge = ({ children }) => {
  const { variantsToBeApprovedCount, isLoading } = useVariantsToApproveQuery()

  return (
    <div className="flex items-center">
      {children}
      {variantsToBeApprovedCount > 0 && !isLoading && (
        <span className="flex items-center justify-center bg-yellow-200 ml-2 font-medium text-base-700 text-xs px-1 py-0.5 rounded">
          {variantsToBeApprovedCount}
        </span>
      )}
    </div>
  )
}

export default ApprovalCounterBadge
