import { useMemo } from 'react'
import cx from 'classnames'
import cond from 'lodash/cond'
import sortBy from 'lodash/sortBy'
import stubTrue from 'lodash/stubTrue'
import { ResponseVariant } from 'workflow/Insights/Insights'

import { Column } from 'common/components/table'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppSelector } from 'common/hooks/redux'
import useTableActions from 'common/hooks/useTableActions'
import type { Sentiments } from 'features/reports/insights/api'
import { VARIANTS_CATEGORIES } from 'features/unifiedFlow/contentPage/generate/constants'
import { useVariantInsights } from 'features/unifiedFlow/contentPage/generate/context/VariantInsightsContext'
import {
  checkVariantActionLoading,
  getFilteredVariants,
} from 'features/unifiedFlow/contentPage/helpers'
import {
  useContent,
  useSelectedComponentElement,
} from 'features/unifiedFlow/contentPage/hooks'

import ActionCell from '../../../components/ActionsCell'
import ContentTableActionButtons from '../contentTable/contentTableActionButtons'
import TableHeading from '../tableHeading'

import useSelectedVariant from './useSelectedVariant'
import VariantCell from './VariantCell'

const REASON_INCLUDED_MAPPER = {
  'Ranked high': VARIANTS_CATEGORIES.PERFORMANCE,
  Diversity: VARIANTS_CATEGORIES.EXPLORATION,
  'Ranked low': VARIANTS_CATEGORIES.BASELINE,
} as const

const categoryOrder = [
  VARIANTS_CATEGORIES.EXPLORATION,
  VARIANTS_CATEGORIES.PERFORMANCE,
  VARIANTS_CATEGORIES.BASELINE,
  VARIANTS_CATEGORIES.USER_MODIFIED,
]

type ReasonKey = keyof typeof REASON_INCLUDED_MAPPER

const getVariantCategory = (reason: ReasonKey | undefined, isEdited: boolean) =>
  cond([
    [() => !!reason, () => REASON_INCLUDED_MAPPER[reason as ReasonKey]],
    [() => isEdited, () => VARIANTS_CATEGORIES.USER_MODIFIED],
    [stubTrue, () => undefined],
  ])()

type ExtendedResponseVariant = ResponseVariant & {
  category: string
  isHighlighted: boolean
  isRefreshed: boolean
  isLoading: boolean
  sentiments: Sentiments
}

type Props = {
  tweakSuccessVariantIds: string[]
  isReadOnly?: boolean
}

const OptimizeResultsTable = ({
  isReadOnly,
  tweakSuccessVariantIds,
}: Props) => {
  const { content } = useContent()
  const { data: selectedElement } = useSelectedComponentElement()
  const { setVariantState, tableRef } = useVariantInsights()

  const subjectLines = useAppSelector(
    (state) => state.campaignStates.subjectLines
  )
  const campaignData = useAppSelector(
    (state) => state.campaignStates.campaignData
  )

  const variantsToBeApproved = useAppSelector(
    (state) => state.campaignStates.variantsToBeApproved
  )
  const { selectedVariantId, setSelectedVariantId } = useSelectedVariant()

  const variantsWithModifiedState = useMemo(() => {
    const filteredData = getFilteredVariants(
      campaignData,
      subjectLines,
      variantsToBeApproved
    ).map((variant) => {
      const isEdited = !!variant?.why_tweak
      const category = getVariantCategory(variant?.reason_included, isEdited)
      const isSelected = selectedVariantId === variant._id
      const isLoading = checkVariantActionLoading({
        content,
        selectedElement,
        variantId: variant._id,
      })

      return {
        ...variant,
        isHighlighted: isSelected,
        isRefreshed: tweakSuccessVariantIds.includes(variant._id),
        isEdited,
        ...(category ? { category } : {}),
        ...(variant.ownsl ? { category: '' } : {}),
        isLoading: isLoading,
      }
    })

    return sortBy(filteredData, [
      (item) => {
        return item.ownsl ? 1 : 0
      },
      (item) => {
        return item.category ? categoryOrder.indexOf(item.category) : -1
      },
    ])
  }, [
    campaignData,
    content,
    selectedElement,
    selectedVariantId,
    subjectLines,
    tweakSuccessVariantIds,
    variantsToBeApproved,
  ])

  const isApproveButtonDisabled = variantsWithModifiedState.some(
    (variant) => variant.isLoading
  )

  const elementId = selectedElement?.element_id

  const { onCopy, onRequestChange } = useTableActions()

  const columns: Column<ExtendedResponseVariant>[] = useMemo(
    () => [
      {
        Header: 'Variants',
        accessor: 'text',
        disableSortBy: true,
        disableFilters: true,
        direction: 'auto',
        Cell: ({ row }) => {
          return (
            <VariantCell
              index={row.pageRowIndex}
              text={row.original.text}
              isControlVariant={row.original.ownsl}
              className={cx('min-h-14', {
                'text-base-700': row.original.isHighlighted,
                'text-gold-700': !row.original.isHighlighted,
              })}
            />
          )
        },
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: '_id',
        disableSortBy: true,
        align: 'right',
        disableFilters: true,
        width: 140,
        className: 'flex-none',
        Cell: ({ row }) => {
          if (row.original?.bandit_status?.status === 'live') {
            return null
          }

          return (
            <ActionCell
              onCopy={() => onCopy(row.values.text)}
              onRequestChange={
                row.original.ownsl
                  ? undefined
                  : () => onRequestChange(row.original)
              }
              isTweaking={row.original.isLoading}
              isSuccessful={row.original.isRefreshed}
            />
          )
        },
      },
      {
        accessor: 'category',
      },
    ],
    [onCopy, onRequestChange]
  )

  const handleRowClick = (variant: ExtendedResponseVariant) => {
    setSelectedVariantId(variant._id)
    setVariantState({
      category: variant.category,
      sentiments: variant.sentiments,
      variantId: variant._id,
    })
  }
  const isFirstRowGrouped = !!variantsWithModifiedState[0]?.category

  if (variantsWithModifiedState.length === 0) {
    return null
  }

  return (
    <div className="w-full" style={{ height: 'max-content' }} ref={tableRef}>
      <TableHeading
        heading={selectedElement?.display_name || selectedElement?.name}
        subHeading={selectedElement?.description}
      />
      <TableWidget.Widget<ExtendedResponseVariant>
        key={elementId}
        data={variantsWithModifiedState}
        columns={columns}
        autoResetPage={false}
        onRowClick={handleRowClick}
        headerClassName={cx({
          'bg-transparent text-base-700': isFirstRowGrouped,
        })}
        initialState={{
          groupBy: ['category'],
          hiddenColumns: isReadOnly ? ['actions', 'category'] : ['category'],
        }}
        data-testid="optimise-variants-table"
      >
        {variantsToBeApproved.length === 0 && (
          <TableWidget.ActionButtons className="w-full">
            <ContentTableActionButtons
              isReadOnly={isReadOnly}
              isApproveButtonDisabled={isApproveButtonDisabled}
            />
          </TableWidget.ActionButtons>
        )}
      </TableWidget.Widget>
    </div>
  )
}

export default OptimizeResultsTable
