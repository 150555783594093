import { useEffect } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { brandVoicePath } from 'app/navigation/paths'

import Banner from 'common/components/banner'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { MissingData as MissingDataIcon } from 'common/icons'
import { initializePage } from 'features/admin/brandVoice/store/brandVoiceSlice'

const BrandVoiceBanner = () => {
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector((state) => state.brandVoice.isLoading)
  const brandVoices = useAppSelector(
    (state) => state.brandVoice.editedContent.brandVoices
  )

  const { accountId } = useAppSelector((state) => state.authStates)

  const brandVoicesLength = brandVoices.length

  useEffect(() => {
    if (brandVoicesLength === 0) {
      dispatch(initializePage())
    }
  }, [dispatch, brandVoicesLength])

  if (isLoading || brandVoicesLength > 0) {
    return null
  }

  return (
    <Banner
      className="mx-6 mb-6"
      icon={
        <MissingDataIcon
          size={4}
          isDefaultColor={false}
          className="text-base-700"
        />
      }
      type="info"
      heading="Missing brand voice"
      text="You don’t have a brand voice set up in your settings yet. Set one up before creating your campaign."
      footer={
        <Link
          to={generatePath(brandVoicePath, { accountId })}
          className="font-medium text-base-700 uppercase underline text-xs hover:opacity-50 ml-auto"
        >
          Brand voice library
        </Link>
      }
    />
  )
}

export default BrandVoiceBanner
