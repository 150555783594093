import { useMemo } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { generatePath, useHistory } from 'react-router-dom'
import { personalizationCombinationPerIdPath } from 'app/navigation/paths'
import { parseISO } from 'date-fns'

import Button from 'common/components/button'
import Base from 'common/components/table/cells/Base'
import { Column } from 'common/components/table/Table'
import { successToast } from 'common/components/toastNotification'
import Tooltip from 'common/components/Tooltip'
import TableWidget from 'common/components/widget/tableWidget'
import { formatDate, formatDateToAgo } from 'common/helpers/date'
import { Copy as CopyIcon } from 'common/icons'
import ContentStatus from 'features/unifiedFlow/contentsPage/components/ContentStatus'

import { PersonalizedContentItem } from '../api/queries/useGetPersonalizedContentQuery'

interface Props {
  data: PersonalizedContentItem[]
}

const initialState = { sortBy: [{ id: 'lastEditDate', desc: false }] }

const PersonalizedContentTable = ({ data }: Props) => {
  const history = useHistory()
  const columns: Column<PersonalizedContentItem>[] = useMemo(
    () => [
      {
        Header: 'Campaign Name',
        accessor: 'campaignName',
        width: 200,
        Cell: ({ row }) => (
          <Base
            wordBreak="all"
            className="cursor-pointer"
            onClick={() =>
              history.push(
                generatePath(personalizationCombinationPerIdPath, {
                  personalizationId: row.original.personalizationId,
                })
              )
            }
          >
            {row.original.campaignName}
          </Base>
        ),
      },
      { Header: 'Account', accessor: 'accountName' },
      {
        Header: 'Campaign ID',
        accessor: 'personalizationId',
        Cell: ({ row }) => (
          <Base className="flex gap-3">
            <span className="w-30 truncate">
              {row.original.personalizationId}
            </span>
            <CopyToClipboard
              text={row.original.personalizationId}
              onCopy={() => successToast('Campaign ID copied successfully')}
            >
              <Tooltip overlay="Copy campaign ID">
                <Button
                  className=""
                  data-cy="personalizationId-copy-button"
                  data-testid="personalizationId-copy-button"
                  variant="icon"
                  prefixIcon={<CopyIcon size={4} />}
                ></Button>
              </Tooltip>
            </CopyToClipboard>
          </Base>
        ),
      },
      {
        Header: 'Start date',
        accessor: 'createdDate',
        sortType: (a, b) => {
          return (
            parseISO(b.original.createdDate).getTime() -
            parseISO(a.original.createdDate).getTime()
          )
        },
        Cell: ({ row }) => <Base>{formatDate(row.original.createdDate)}</Base>,
      },
      {
        Header: 'Modified date',
        accessor: 'lastEditDate',
      },
      { Header: 'Variants (total)', accessor: 'numberOfVariants' },
      {
        Header: 'Status',
        accessor: 'status',
        minWidth: 200,
        Cell: ({ row }) => {
          return (
            <Base className="uppercase">
              <ContentStatus value={row.original.status} />
            </Base>
          )
        },
      },
    ],
    [history]
  )

  const dataFormatted = useMemo(
    () =>
      data.map((campaign) => ({
        ...campaign,
        createdDate: campaign.createdDate,
        lastEditDate: formatDateToAgo(parseISO(campaign.lastEditDate)),
      })),
    [data]
  )

  return (
    <TableWidget.Widget
      columns={columns}
      data={dataFormatted}
      initialState={initialState}
      firstUseText="There is no content to show."
      cacheId="1"
    />
  )
}

export default PersonalizedContentTable
