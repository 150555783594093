import { useEffect, useState } from 'react'
import {
  graphConfig,
  Highcharts,
} from 'workflow/CampaignSummary/PhraseeXContent/AdvancedVisualisations/Graphs'

import HighchartsReact, {
  HighchartsReactProps,
} from 'common/components/AutoResizeChart'
import { useDeepCompare, useShallowCompare } from 'common/hooks/custom'
import { replaceNewLineEmojiAsLineBreak } from 'common/variantsUtils'

import { AdvancedVisualisationsData } from '../../../../interface'

import './LineGraph.css'

interface Props extends AdvancedVisualisationsData {
  highchartProps?: Partial<HighchartsReactProps>
  navigatorDefaultRange?: number
}

const makeMarker = ({ dataPoint, variantColor }) => ({
  x: dataPoint[0],
  y: dataPoint[1],
  marker: {
    enabled: true,
    radius: 3,
    fillColor: variantColor,
  },
})

const makeData = (result, variantColorMapping) => {
  const variantColor = variantColorMapping.get(result.id)?.color || '#000'
  if (result?.variant_status === 'dropped') {
    return result.data.map((dataPoint: any, index: number) => {
      if (result.data.length - 1 === index || index === 0) {
        return makeMarker({ dataPoint, variantColor })
      }
      return dataPoint
    })
  } else {
    return result.data.map((dataPoint: any, index: number) => {
      if (index === 0) {
        return makeMarker({ dataPoint, variantColor })
      }
      return dataPoint
    })
  }
}

const LineGraph = (props: Props) => {
  const { results, average, selectedRows, variantColorMapping } = props
  const updatedResults: any = results.map((result: any) => {
    return {
      ...result,
      marker: {
        symbol: 'circle',
      },
      data: makeData(result, variantColorMapping),
      type: 'spline',
      color: variantColorMapping.get(result.id)?.color || '#000',
      name: replaceNewLineEmojiAsLineBreak(result.name),
    }
  })

  const [series, setSeries] =
    useState<Highcharts.SeriesOptionsType[]>(updatedResults)
  const selecedRowsIds = selectedRows?.map((row: any) => row._id)
  const filteredVariants = updatedResults.filter((variant: any) => {
    return selecedRowsIds?.includes(variant.id)
  })

  const selectedRowsHasChanged = useShallowCompare(selectedRows)
  // We 'useDeepCompare' since 'variantColorMapping' or 'results' can be empty on initial load.
  // As those results come in a little bit later, we need a hook to watch for changes.
  const hasDataChanged = useDeepCompare(updatedResults)

  useEffect(() => {
    if (
      selectedRowsHasChanged ||
      // If we pass selectedRows via props, we want to show filtered values. HG-1489
      (selectedRows !== undefined && hasDataChanged)
    ) {
      setSeries(filteredVariants)
    } else if (hasDataChanged) {
      setSeries(updatedResults)
    }
  }, [
    selectedRowsHasChanged,
    filteredVariants,
    hasDataChanged,
    updatedResults,
    selectedRows,
  ])

  const options: Highcharts.Options = {
    ...graphConfig,
    navigator: {
      ...graphConfig.navigator,
      series: {
        data: average,
        type: 'areaspline',
        ...(graphConfig.navigator?.series as Highcharts.NavigatorSeriesOptions),
      },
    },
    xAxis: {
      ...(graphConfig.xAxis as Highcharts.XAxisOptions),
      ordinal: false,
      minTickInterval: 1000 * 3600 * 24, // interval in mms (24hrs)
      type: 'datetime',
      range: props.navigatorDefaultRange,
      labels: {
        formatter: (chart: any) => {
          return Highcharts.dateFormat('%e %b', chart.value)
        },
        style: {
          ...(graphConfig.xAxis as Highcharts.XAxisOptions)?.labels?.style,
        },
      },
      gridLineColor: '#EFE2C8',
    },
    yAxis: {
      ...graphConfig.yAxis,
      labels: {
        enabled: false,
        format: '{value}%',
        style: {
          ...(graphConfig.yAxis as Highcharts.YAxisOptions)?.labels?.style,
        },
      },
      gridLineDashStyle: 'Solid',
    },
    chart: {
      spacing: graphConfig.chart?.spacing,
      backgroundColor: graphConfig.chart?.backgroundColor,
      borderRadius: graphConfig.chart?.borderRadius,
      borderWidth: graphConfig.chart?.borderWidth,
      borderColor: graphConfig.chart?.borderColor,
      type: 'spline',
      style: {
        fontFamily: 'Roboto',
      },
      margin: graphConfig.chart?.margin,
      events: graphConfig.chart?.events,
    },
    tooltip: {
      shared: false,
      split: false,
      useHTML: true,
      borderRadius: 5,
      padding: 16,
      backgroundColor: '#ffffff',
      shadow: true,
      formatter: function () {
        const isDropped =
          this.series.userOptions['variant_status'] === 'dropped'
        return `${this.series.name}: <b>${this.y}%</b>
        <br/> ${isDropped ? 'This line has been <b>dropped</b>' : ''}`
      },
    },
    plotOptions: {
      spline: {
        marker: {
          enabled: false,
        },
      },
      series: {
        stickyTracking: false,
        dataGrouping: {
          enabled: true,
          forced: true,
        },
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    series,
  }
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="stockChart"
      options={options}
      {...props.highchartProps}
    />
  )
}

export default LineGraph
