import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { getCustomerAttributes } from '../api'
import { keys } from '../queryKeys'

export type CustomerAttributes = {
  fileName: string
  type: string
  created: string
  columns: string[]
  data: Object[]
}

const useGetCustomerAttributesQuery = ({
  catalogueAttributeId = '',
  region,
}: {
  catalogueAttributeId: string | undefined
  region: string
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const getCustomerAttributesQuery = useQuery(
    keys.customerAttributes({ accountId, catalogueAttributeId, region }),
    () => getCustomerAttributes({ accountId, catalogueAttributeId, region }),
    {
      enabled: !!accountId && !!catalogueAttributeId,
      retry: false,
      meta: {
        errorMessage: 'The customer attributes could not be loaded',
      },
    }
  )

  return getCustomerAttributesQuery
}

export default useGetCustomerAttributesQuery
