import { useEffect } from 'react'

import { useAppDispatch } from 'common/hooks/redux'
import { useSelectedComponentElement } from 'features/unifiedFlow/contentPage/hooks'
import {
  hideRightPanel,
  showRightPanel,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

const useRightPanelVisibilityState = () => {
  const dispatch = useAppDispatch()
  const { data: selectedElement } = useSelectedComponentElement()

  useEffect(() => {
    dispatch(showRightPanel({ visibility: 'auto' }))
  }, [dispatch])

  useEffect(() => {
    if (selectedElement?.campaign_id) {
      dispatch(showRightPanel({ visibility: 'auto' }))
    } else {
      dispatch(hideRightPanel({ visibility: 'none' }))
    }
  }, [selectedElement?.campaign_id, dispatch])
}

export default useRightPanelVisibilityState
