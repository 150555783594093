import { forwardRef } from 'react'
import { ReactNode } from 'react'
import cx from 'classnames'
import type { TimePickerProps as TimePickerPropsDefault } from 'rc-time-picker'
import RcTimePicker from 'rc-time-picker'

import {
  Clock as ClockIcon,
  CloseSquare as CloseSquareIcon,
} from 'common/icons'

import 'rc-time-picker/assets/index.css'
import './TimePicker.module.css'

export type TimePickerProps = TimePickerPropsDefault & {
  allowClear?: boolean
  suffixIcon?: ReactNode
  style?: React.CSSProperties
}

const TimePicker = forwardRef<RcTimePicker, TimePickerProps>(
  ({ disabled, allowClear = true, suffixIcon, ...rest }, ref) => {
    return (
      <RcTimePicker
        ref={ref}
        disabled={disabled}
        inputIcon={
          <div className="rc-time-picker-clock">
            <ClockIcon
              isDefaultColor={false}
              className={cx(
                'rc-time-picker-clear text-gold-700  mr-4 top-3 right-0',
                {
                  'cursor-not-allowed': disabled,
                }
              )}
              size={4}
            />
          </div>
        }
        clearIcon={
          <button
            className={cx(
              'rc-time-picker-clear text-gold-700 hover:text-base-700 right-0 mr-4 top-2',
              {
                hidden: !allowClear,
              }
            )}
            disabled={disabled}
          >
            <CloseSquareIcon isDefaultColor={false} size={4} />
          </button>
        }
        {...rest}
      />
    )
  }
)

export default TimePicker
