import { PersonalizationRegion } from './api'

const ROOT_KEY = 'personalizationResults'

export const personalizationResultsKeys = {
  variants: ({
    accountId,
    personalizationId,
    resultType,
    personalizationRegion,
  }: {
    accountId: string
    personalizationId: string | undefined
    resultType: 'mature' | 'raw'
    personalizationRegion: PersonalizationRegion | undefined
  }) =>
    [
      ROOT_KEY,
      'variants',
      accountId,
      personalizationId,
      resultType,
      personalizationRegion,
    ] as const,
}
