import { useEffect, useState } from 'react'

import Button from 'common/components/button/Button'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ReactComponent as SettingsDefault } from 'common/icons/settings/default.svg'
import { updateProfile } from 'features/auth/store/authSlice'

import { AvatarColor } from './AvatarColorPicker'

type Props = {
  firstname: string
  lastname: string
  jobTitle: string
  phoneNumber: string
  avatarColor: AvatarColor
  resetForm: () => void
  isHidden: boolean
}

const ProfileUpdateButtons = ({
  firstname,
  lastname,
  jobTitle,
  phoneNumber,
  avatarColor,
  resetForm,
  isHidden,
}: Props) => {
  const dispatch = useAppDispatch()

  const { updateProfileLoading: isLoading } = useAppSelector(
    (state) => state.authStates
  )
  const [showSuccess, setShowSuccess] = useState(false)

  useEffect(() => {
    let timeoutID: number | undefined
    if (showSuccess) {
      timeoutID = window.setTimeout(() => {
        setShowSuccess(false)
      }, 2000)
    }
    return () => window.clearTimeout(timeoutID)
  }, [showSuccess])

  return isHidden && !showSuccess ? null : (
    <div className="flex justify-end place-items-end">
      <Button
        data-cy="cancel-profile-update-button"
        data-testid="cancel-profile-update-button"
        className="mr-4 font-medium text-base"
        ghost
        onClick={() => {
          resetForm()
        }}
      >
        Cancel
      </Button>
      <Button
        data-cy="save-profile-button"
        data-testid="save-profile-button"
        className="text-white"
        variant={showSuccess ? 'success' : 'primary'}
        loading={isLoading}
        disabled={firstname.trim().length === 0 || lastname.trim().length === 0}
        prefixIcon={<SettingsDefault width={24} height={24} />}
        onClick={() =>
          dispatch(
            updateProfile({
              fname: firstname,
              lname: lastname,
              phoneNumber: phoneNumber,
              jobTitle: jobTitle,
              avatarColor,
            })
          ).then(() => {
            setShowSuccess(true)
          })
        }
      >
        Save profile
      </Button>
    </div>
  )
}

export default ProfileUpdateButtons
