import { useEffect, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router'

import ErrorPage from 'common/components/error/ErrorPage'
import Loader from 'common/components/loaders/Loader'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Tags from 'common/components/tags'
import { capitalizeFirstLetter } from 'common/helpers/string'
import { useAppSelector } from 'common/hooks/redux'

import useTweakVariantMutation from '../api/mutations/useTweakVariantMutation'
import useGetCombinationLibraryQuery from '../api/queries/useGetCombinationLibraryQuery'
import useGetCombinationVariantsQuery from '../api/queries/useGetCombinationVariantsQuery'
import useGetPersonalizedContentQuery from '../api/queries/useGetPersonalizedContentQuery'

import CombinationVariantsTable from './components/CombinationVariantsTable'
import TweakVariantModal from './components/TweakVariantModal'

const Combination = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const { personalizationId, combinationId } =
    useRouteMatch<{ personalizationId: string; combinationId: string }>().params

  const [isTweakModalOpen, setIsTweakModalOpen] = useState(false)
  const [tweakingVariantId, setTweakingVariantId] =
    useState<string | undefined>(undefined)

  const personalizedContentQuery = useGetPersonalizedContentQuery()
  const currentPersonalization = personalizedContentQuery.data?.find(
    (personalization) => personalization.personalizationId === personalizationId
  )

  const { data: combinationVarants, ...combinationVariantsQuery } =
    useGetCombinationVariantsQuery({
      personalizationId,
      combinationId,
      region: currentPersonalization?.region,
    })

  const { approvedVariants, unapprovedVariants, isTableReadOnly } =
    useMemo(() => {
      const notRejectedVariants =
        combinationVarants?.personalized.filter(
          (variant) => variant.status !== 'rejected'
        ) || []

      const approvedVariants = notRejectedVariants.filter(
        (variant) => variant.status === 'approved'
      )

      const unapprovedVariants = notRejectedVariants.filter(
        (variant) => variant.status === 'pending'
      )

      const isTableReadOnly = notRejectedVariants.every(
        (variant) => variant.status === 'approved'
      )

      return {
        approvedVariants,
        unapprovedVariants,
        isTableReadOnly,
      }
    }, [combinationVarants?.personalized])

  const { data: combinationData = [], ...combinationLibraryQuery } =
    useGetCombinationLibraryQuery({
      personalizationId,
      accountId: currentPersonalization?.accountId,
      region: currentPersonalization?.region,
    })

  const currentCombination = combinationData.find(
    (combination) => combination.id === combinationId
  )

  const tweakingVariant = useMemo(
    () =>
      unapprovedVariants?.find(
        (variant) => variant?.variant_id === tweakingVariantId
      ),
    [tweakingVariantId, unapprovedVariants]
  )

  const tweakVariantMutation = useTweakVariantMutation({
    accountId,
    personalizationId,
    combinationId,
    region: currentPersonalization?.region,
  })

  useEffect(() => {
    if (tweakVariantMutation.isSuccess) {
      setIsTweakModalOpen(false)
    }
  }, [tweakVariantMutation.isSuccess])

  if (
    personalizedContentQuery.isLoading ||
    combinationLibraryQuery.isLoading ||
    combinationVariantsQuery.isLoading
  ) {
    return <Loader />
  }

  if (!currentCombination) {
    throw new Error('Combination data not available')
  }

  const { campaignType, channel } = currentCombination

  const dimensionsTags =
    currentCombination?.dimensions.map(({ key, value }) => ({
      label: `${capitalizeFirstLetter(key)} : ${value}`,
      value,
    })) ?? []

  const attributeTags: { label: string; value: string }[] = [
    {
      label: `Campaign type : ${capitalizeFirstLetter(campaignType)}`,
      value: campaignType,
    },
    {
      label: `Channel : ${capitalizeFirstLetter(channel)}`,
      value: channel,
    },
    ...dimensionsTags,
  ]

  if (
    personalizedContentQuery.error ||
    combinationLibraryQuery.error ||
    combinationVariantsQuery.error
  ) {
    return <ErrorPage />
  }

  const areUnapprovedVariantsAvailable =
    combinationVariantsQuery.isSuccess && (unapprovedVariants?.length ?? 0) > 0

  return (
    <PageContainer className="max-w-318 sm:mx-auto px-6 py-8">
      <PageHeader className="mb-10">
        <PageTitle
          title={
            areUnapprovedVariantsAvailable && !isTableReadOnly
              ? `Variants to approve: ${unapprovedVariants?.length}`
              : 'Personalised content'
          }
        />
      </PageHeader>
      <Tags isFreeText tags={attributeTags} className="mb-6" />
      <CombinationVariantsTable
        data={(isTableReadOnly ? approvedVariants : unapprovedVariants) ?? []}
        onOpenTweakModal={setIsTweakModalOpen}
        onSetTweakingVariantId={setTweakingVariantId}
        isReadOnly={isTableReadOnly}
      />

      <TweakVariantModal
        isOpen={isTweakModalOpen}
        onCancel={() => {
          setTweakingVariantId(undefined)
          setIsTweakModalOpen(false)
        }}
        onEditVariant={({ variantId, variantText, reason, changeType }) => {
          setIsTweakModalOpen(false)
          tweakVariantMutation.mutate({
            region: currentPersonalization?.region,
            accountId,
            personalizationId,
            combinationId,
            variantText,
            variantId,
            reason,
            changeType,
          })
        }}
        isLoading={tweakVariantMutation.isLoading}
        tweakingVariant={tweakingVariant}
      />
    </PageContainer>
  )
}

export default Combination
