import { useMutation } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import type { CreateImagesRequest } from '../api'
import { generatePresignedUrls } from '../api'

const useGeneratePresignedUrlsMutation = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return useMutation({
    mutationFn: (body: CreateImagesRequest) =>
      generatePresignedUrls(accountId, body),
  })
}

export default useGeneratePresignedUrlsMutation
