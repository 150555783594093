import values from 'lodash/values'

import type { ImagePageOptions } from './api'

export const imagesKeys = {
  all: ['images'] as const,
  byAccount: (accountId: string) =>
    [...imagesKeys.all, 'byAccount', accountId] as const,
  queryParams: (accountId: string, queryParams: ImagePageOptions) =>
    [
      ...imagesKeys.byAccount(accountId),
      'queryParams',
      ...values(queryParams),
    ] as const,
  infiniteQueryParams: (accountId: string, queryParams: ImagePageOptions) =>
    [
      ...imagesKeys.byAccount(accountId),
      'infiniteQueryParams',
      ...values(queryParams),
    ] as const,
  byImageId: (accountId: string, imageId: string) =>
    [...imagesKeys.all, ...imagesKeys.byAccount(accountId), imageId] as const,
  byImageIds: (accountId: string, imageIds: string[]) =>
    [
      ...imagesKeys.all,
      ...imagesKeys.byAccount(accountId),
      ...imageIds,
    ] as const,
}
