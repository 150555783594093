import GuidingText from 'common/components/guidingText'
import Select from 'common/components/Select'
import { default as Option } from 'common/components/SelectOption'

import { InputElement } from '../../interface'

import FormItem from './FormItem'
import { FormInstance } from './interfaces'

type Props = {
  field: InputElement
  form: FormInstance
  disabled?: boolean
  onChange: (value: number) => void
  className?: string
  showTooltip?: boolean
}

const SelectionMetric: React.FC<Props> = ({
  field,
  form,
  className,
  disabled = false,
  onChange,
  showTooltip = true,
}) => {
  const { getFieldDecorator } = form

  return (
    <FormItem form={form} field={field}>
      <div>
        {getFieldDecorator(field.property_name, {
          rules: field.rules,
        })(
          <Select
            data-cy="campaign-setup-primary-goal"
            data-testid="campaign-setup-primary-goal"
            id={field.field_id}
            className={className}
            placeholder={field.placeholder}
            disabled={disabled}
            size="large"
            onChange={(value: number) => {
              form.resetFields([
                'baseline_open_rate',
                'list_size',
                'baseline_click_rate',
              ])
              onChange(value)
            }}
          >
            {field.elements &&
              field.elements.map((option: { value: string; name: string }) => {
                return (
                  <Option key={option.value} value={option.value}>
                    {option.name}
                  </Option>
                )
              })}
          </Select>
        )}
        {showTooltip && field.tooltip && <GuidingText text={field.tooltip} />}
      </div>
    </FormItem>
  )
}

export default SelectionMetric
