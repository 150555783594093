import Tags, { Option } from 'common/components/tags/Tags'

type Props = {
  'data-cy'?: string
  'data-testid'?: string
  title: string
  subtitle: string
  data: Option<string>[]
}

const MetricsItem = ({
  'data-cy': dataCy,
  'data-testid': dataTestId,
  title,
  subtitle,
  data,
}: Props) => {
  return (
    <div data-cy="metrics-item" data-testid="metrics-item">
      <p
        data-cy="metrics-item-title"
        data-testid="metrics-item-title"
        className="mb-2 text-4 text-coolGray-800 font-medium"
      >
        {title}
      </p>
      <p
        data-cy="metrics-item-subtitle"
        data-testid="metrics-item-subtitle"
        className="mb-4 text-3 text-coolGray-400"
      >
        {subtitle}
      </p>
      <Tags
        data-cy={dataCy}
        data-testid={dataTestId}
        isFreeText={false}
        tags={data}
      />
    </div>
  )
}

export default MetricsItem
