import React, { ReactNode } from 'react'

import PerformanceReport from './components/PerformanceReport'
import Results from './components/Results'
import WinnerVariant from './components/WinnerVariant'
import { colCount } from './constants'
import type {
  ReportsData,
  ResultsData,
  ShowMachineOpensTicked,
} from './useWinnerVariantInfo'
import useWinnerVariantInfo from './useWinnerVariantInfo'

type Props = {
  results: ResultsData
  reports?: ReportsData
  showMachineOpensTicked?: ShowMachineOpensTicked
}

const getEmptyCells = (count: number, key: string): ReactNode => {
  if (count <= 0) {
    return null
  }
  return Array.from(Array(count).keys()).map((index) => (
    <div key={`{${key}-${index}`} />
  ))
}

const WinnerVariantInfo: React.FC<Props> = ({
  results,
  reports,
  showMachineOpensTicked,
}) => {
  const {
    hasResultsData,
    hasReportsData,
    winnerVariant,
    reportsCollection,
    emptyReportsCellCount,
    emptyResultsCellCount,
    resultsCollection,
  } = useWinnerVariantInfo({
    results,
    reports,
    showMachineOpensTicked,
  })

  if (!hasReportsData && !hasResultsData) {
    return null
  }

  return (
    <div
      className="w-full py-8"
      data-cy="winner-variant-info"
      data-testid="winner-variant-info"
    >
      {winnerVariant && (
        <WinnerVariant winnerVariant={winnerVariant} className="pb-6" />
      )}
      <div className={`grid grid-cols-${colCount} gap-6`}>
        {hasReportsData && (
          <PerformanceReport reports={reportsCollection}>
            {getEmptyCells(emptyReportsCellCount, 'report')}
          </PerformanceReport>
        )}
        {hasResultsData && (
          <Results results={resultsCollection}>
            {getEmptyCells(emptyResultsCellCount, 'result')}
          </Results>
        )}
      </div>
    </div>
  )
}

export default WinnerVariantInfo
