import Button from 'common/components/button/Button'
import Tooltip from 'common/components/Tooltip'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { SidebarRightMenu as SidebarRightMenuIcon } from 'common/icons'
import {
  hideRightPane,
  showRightPane,
} from 'features/futurama/store/contentSlice'

import { getContentHasVariants } from '../../helpers'
import { useContent } from '../../hooks'

import ExportMenuButton from './headerActionButtons/ExportMenuButton'

const HeaderActions = () => {
  const { content } = useContent()
  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )
  const dispatch = useAppDispatch()

  const rightPaneVisibility = useAppSelector(
    (state) => state.content.rightPaneVisibility
  )

  return (
    <>
      {selectedElementId && getContentHasVariants(content) && (
        <ExportMenuButton />
      )}
      {rightPaneVisibility !== 'none' && (
        <Button
          size="small"
          variant="icon"
          className="text-white hover:text-yellow-200 ml-6"
          prefixIcon={
            <Tooltip
              overlay={
                rightPaneVisibility === 'hidden' ? 'Show panel' : 'Hide panel'
              }
            >
              <SidebarRightMenuIcon
                isDefaultColor={false}
                className="text-white w-5 h-5"
              />
            </Tooltip>
          }
          onClick={() => {
            rightPaneVisibility === 'hidden'
              ? dispatch(showRightPane())
              : dispatch(hideRightPane())
          }}
        />
      )}
    </>
  )
}

export default HeaderActions
