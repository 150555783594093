import { useState } from 'react'
import axios from 'axios'

import CheckboxComponent from 'common/components/checkbox'
import ConfirmationModal from 'common/components/confirmationModal/ConfirmationModal'
import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'
import { Node } from 'common/interfaces/nodes'
import useUpdateNodeMutation from 'features/content/api/mutations/useUpdateNodeMutation'

const EditNode = ({ currentNode }: { currentNode: Node }) => {
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] =
    useState(false)

  const userId = useAppSelector((state) => state.authStates.user_id)

  const updateNodeMutation = useUpdateNodeMutation()

  const handlePrivacyChange = ({ isPrivate }: { isPrivate: boolean }) => {
    updateNodeMutation.mutate(
      {
        userId,
        nodeId: parseInt(currentNode.id),
        isPrivate,
      },
      {
        onSuccess: () => {
          successToast('Private status changed successfully')
        },
        onError: (error) => {
          if (axios.isAxiosError(error)) {
            errorToast(error.response?.data?.message)
          }
        },
      }
    )
    setIsConfirmationModalVisible(false)
  }

  const handleDisplayChange = ({ isDisplayed }: { isDisplayed: boolean }) => {
    updateNodeMutation.mutate(
      {
        userId,
        nodeId: parseInt(currentNode.id),
        isDisplayed,
      },
      {
        onSuccess: () => {
          successToast('Display status changed successfully')
        },
        onError: (error) => {
          if (axios.isAxiosError(error)) {
            errorToast(error.response?.data?.message)
          }
        },
      }
    )
  }

  return (
    <div className="mb-6 mt-4 gap-8 flex">
      <CheckboxComponent
        data-cy="node-private-checkbox"
        data-testid="node-private-checkbox"
        label="Set as private node"
        isChecked={currentNode.isPrivate || false}
        onChange={() => {
          setIsConfirmationModalVisible(true)
        }}
      />
      <CheckboxComponent
        data-cy="node-display-checkbox"
        data-testid="node-display-checkbox"
        label="Display node to user"
        isChecked={currentNode.isDisplayed || false}
        onChange={() => {
          handleDisplayChange({
            isDisplayed: !currentNode.isDisplayed,
          })
        }}
      />
      <ConfirmationModal
        open={isConfirmationModalVisible}
        title={
          currentNode.isPrivate
            ? 'Are you sure you want to mark this node as public?'
            : 'Are you sure you want to mark this node as private?'
        }
        data-cy="node-private-confirmation-modal"
        data-testid="node-private-confirmation-modal"
        confirmationText={
          currentNode.isPrivate
            ? 'Public nodes can be used in generations whenever they are part of a project’s assigned trees.'
            : 'Private nodes can not be assigned children and can only be used by projects they are specifically assigned to. Add it to the project settings that require access.'
        }
        onCancel={() => {
          setIsConfirmationModalVisible(false)
        }}
        onConfirm={() =>
          handlePrivacyChange({
            isPrivate: !currentNode.isPrivate,
          })
        }
        confirmButtonText="Assign"
      />
    </div>
  )
}

export default EditNode
