import { ChangeEvent, useEffect, useRef, useState } from 'react'

import FormError from 'common/components/formError'
import InlineTextEdit from 'common/components/inlineTextEdit'
import { errorToast, successToast } from 'common/components/toastNotification'
import { stripForbiddenCharacters } from 'common/helpers/stripForbiddenCharacters'
import { useAppSelector } from 'common/hooks/redux'
import { Node } from 'common/interfaces/nodes'
import useUpdateNodeMutation from 'features/content/api/mutations/useUpdateNodeMutation'

type Props = {
  currentNode: Node
}

const NameInput = ({ currentNode }: Props) => {
  const nameWrapperRef = useRef<HTMLDivElement>(null)

  const [error, setError] = useState<string>()
  const [value, setValue] = useState<string>(currentNode.name)
  const userId = useAppSelector((state) => state.authStates.user_id)

  const updateNodeMutation = useUpdateNodeMutation()

  const validateName = (value: string) => {
    let validationError

    if (!value) {
      validationError = 'Please enter node name'
    }

    return validationError
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newNodeName = event.target.value
    const validationError = validateName(newNodeName)

    setValue(stripForbiddenCharacters(newNodeName))
    if (validationError) {
      setError(validationError)
    } else {
      setError(undefined)
    }
  }

  const saveName = () => {
    if (value.length === 0) {
      return
    }

    updateNodeMutation.mutate(
      {
        userId,
        nodeId: parseInt(currentNode.id),
        updatedName: value,
      },
      {
        onSuccess: () => {
          successToast('Node renamed successfully')
        },
        onError: () => errorToast('Failed to rename node'),
      }
    )

    setError(undefined)
  }

  useEffect(() => {
    setValue(currentNode.name)
  }, [currentNode.name])
  return (
    <div className="w-4/5">
      <div
        className="flex justify-between items-center pb-2"
        ref={nameWrapperRef}
      >
        <div className="flex items-center">
          <InlineTextEdit
            data-cy="node-name"
            data-testid="node-name"
            placeholder="Enter node name…"
            savedValue={currentNode.name}
            showCharacterCounter
            maxLength={128}
            onChange={onChange}
            onSave={saveName}
            className="text-3xl"
            isLoading={updateNodeMutation.isLoading}
            value={value}
          />
        </div>
      </div>
      {error && <FormError>{error}</FormError>}
    </div>
  )
}

export default NameInput
