import CenterPanel from '../../components/layout/CenterPanel'
import { useSelectedComponentElement } from '../../hooks'

import OptimizeResults from './optimizeResults/OptimizeResults'
import PersonalizedVariantsTable from './personalizeResults/PersonalizedVariantsTable'
type Props = {
  selectedPersonalizationId: string | undefined
  onSelectPersonalization: (personalizationId: string | undefined) => void
}

const CenterSection = ({
  selectedPersonalizationId,
  onSelectPersonalization,
}: Props) => {
  const { data: selectedElement } = useSelectedComponentElement()

  const getState = () => {
    if (!selectedElement) {
      return 'empty'
    } else if (selectedElement?.campaign_id) {
      return 'optimizeResults'
    } else {
      return 'personalizeResults'
    }
  }

  const state = getState()

  return (
    <CenterPanel data-testid="reviewCenterPanel" className="overflow-auto">
      {
        {
          empty: null,
          optimizeResults: <OptimizeResults />,
          personalizeResults: (
            <PersonalizedVariantsTable
              selectedPersonalizationId={selectedPersonalizationId}
              onSelectPersonalization={onSelectPersonalization}
            />
          ),
        }[state]
      }
    </CenterPanel>
  )
}

export default CenterSection
