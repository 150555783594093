import type { ReactNode } from 'react'
import { ExperimentTypeStatus } from '@phrasee/phrasee-typings/typings/futurama/experiment_type'
import cx from 'classnames'

import Badge from 'common/components/Badge'
import Tooltip from 'common/components/Tooltip'
import { Encrypted } from 'common/icons'

type ExperimentTypeProps = {
  icon?: ReactNode
  name: 'optimise' | 'personalise'
  status: ExperimentTypeStatus
  className?: string
}

const ExperimentType = ({
  name,
  status,
  className,
  icon = null,
}: ExperimentTypeProps) => {
  const isBlocked = status === 'blocked'
  const isDisabled = status === 'disabled'

  const experimentIcon = isBlocked ? (
    <Encrypted isDefaultColor={false} size={3} />
  ) : (
    icon
  )

  const text = (
    <span className="flex items-center gap-1">
      {experimentIcon}
      {name}
    </span>
  )

  return !isDisabled ? (
    <Tooltip
      overlay="Talk to us about upgrading"
      placement="top"
      show={isBlocked}
    >
      <Badge
        text={text}
        variant="feature"
        size="small"
        className={cx('mr-2 inline-block', className)}
        data-testid={`${name}-badge`}
      />
    </Tooltip>
  ) : null
}

export default ExperimentType
