import {
  generatePath,
  matchPath,
  Redirect,
  Route,
  useHistory,
} from 'react-router-dom'
import { isAtLeastOneAccessFlagEnabled } from 'app/navigation/helpers'
import {
  adminLanguagePath,
  combinationPerPersonalizationPath,
  personalizationCombinationPerIdPath,
  regionSettingsPath,
} from 'app/navigation/paths'
import { personalizedContentPath } from 'app/navigation/paths'
import { languageSubroutes } from 'app/navigation/staticSubRoutes.language'
import CustomSwitch from 'app/router/Switch'
import { useFlags } from 'launchdarkly-react-client-sdk'

import SubNavBar from 'common/components/navigation/subNavBar'
import PageContainer from 'common/components/PageContainer'

import useGetPersonalizedContentQuery from '../personalizedContent/api/queries/useGetPersonalizedContentQuery'

import LanguageSubNav from './LanguageSubNav'

const Language = () => {
  const flags = useFlags()
  const history = useHistory()
  const combinationLibraryPathMatch = matchPath<{ personalizationId: string }>(
    history.location.pathname,
    {
      path: personalizationCombinationPerIdPath,
      exact: true,
      strict: false,
    }
  )

  const combinationPathMatch = matchPath<{
    personalizationId: string
    combinationId: string
  }>(history.location.pathname, {
    path: combinationPerPersonalizationPath,
    exact: true,
    strict: false,
  })

  const personalizationId =
    combinationLibraryPathMatch?.params.personalizationId

  const personalizedContentQuery = useGetPersonalizedContentQuery()
  const currentPersonalization = personalizedContentQuery.data?.find(
    (personalization) => personalization.personalizationId === personalizationId
  )

  const routes = languageSubroutes
    .filter(({ accessFlags }) =>
      isAtLeastOneAccessFlagEnabled(flags, accessFlags?.flags)
    )
    .map((route) => {
      return { ...route, to: generatePath(route.to) }
    })

  const subNavBarPerRoute = combinationLibraryPathMatch ? (
    <SubNavBar
      title={currentPersonalization?.campaignName}
      backLink={personalizedContentPath}
    />
  ) : combinationPathMatch ? (
    <SubNavBar
      title="Back"
      backLink={generatePath(personalizationCombinationPerIdPath, {
        personalizationId: combinationPathMatch.params.personalizationId,
      })}
    />
  ) : (
    <LanguageSubNav subroutes={languageSubroutes} />
  )

  return (
    <>
      {subNavBarPerRoute}
      <PageContainer>
        <CustomSwitch>
          {routes.map(({ key, to, component: Component }) => (
            <Route key={key} path={to}>
              <Component />
            </Route>
          ))}
          <Redirect
            exact
            from={adminLanguagePath}
            to={generatePath(regionSettingsPath)}
          />
        </CustomSwitch>
      </PageContainer>
    </>
  )
}

export default Language
