import { AccountCategory } from '@phrasee/phrasee-typings/Graphql/interfaces'

export const routesPermissions = {
  accountsView: 'view:accounts-page',
  experimentsView: 'view:campaigns',
  reportsView: 'view:reports',
  brandVoiceSettingsView: 'view:brand-voice-settings-page',
  usersView: 'view:users-page',
  projectsView: 'view:projects',
  projectsPageView: 'view:projects-page',
  contentView: 'view:languages-contents',
  languageView: 'view:languages-nodes',
  personalizedContentView: 'view:languages-personalized-content',
  variantsEdit: 'edit:variants',
  dataSourcesCreate: 'create:personalization-data',
  demoPageView: 'view:poc-page',
}

export const accountSettingsPermissions = {
  accountSettingsView: 'view:accounts-settings-page',
  accountSettingsIntegrationsView: 'view:account-settings-integrations',
  accountSettingsMergeTagsView: 'view:account-settings-merge-tags',
  accountSettingsRevenueView: 'view:account-settings-revenue',
  accountSettingsApiTokensView: 'view:account-settings-api-keys',
}

export const experimentsPermissions = {
  create: 'create:campaigns',
  delete: 'delete:campaigns',
  edit: 'edit:campaigns',
  list: 'list:campaigns',
}

export const projectsPermissions = {
  create: 'create:projects',
  listRelation: 'list:projects.relation',
  list: 'list:projects',
}

export const accountsPermissions = {
  edit: 'edit:accounts',
}

export const usersPermissions = {
  create: 'create:users',
  delete: 'delete:users',
  deactivate: 'deactivate:users',
  edit: 'edit:users',
  list: 'list:users',
  view: 'view:users',
}

export const contentPermissions = {
  create: 'create:languages-contents',
}
export const contentPermissionsIds = {
  view: 'view:languages-contents-ids',
}

export const unifiedFlowPermissions = {
  viewCreationOptions: 'view:content-creation-options',
}

export const permissions = {
  projectsCreate: 'create:projects',
  languageContentEdit: 'edit:languages-contents',
  ...routesPermissions,
}

export const getIsAuthorized = (
  permissions?: string[],
  accessPermission?: string
): boolean => {
  if (permissions?.length) {
    return accessPermission ? permissions.includes(accessPermission) : true
  } else {
    return false
  }
}

export const canAccessAccount = (
  categoryAccount: AccountCategory,
  accessPermission: string
) => {
  const permissions = categoryAccountMapping[categoryAccount]
  return permissions?.length ? permissions.includes(accessPermission) : false
}

// We do the mapping between the type of account and the permission given
export const categoryAccountMapping: { [key: string]: string[] } = {
  Starter: [],
  Pro: [
    'view:campaigns',
    'view:reports',
    'view:projects',
    'view:projects-page',
    'view:accounts-settings-page',
    'view:account-settings-revenue',
    'view:account-settings-merge-tags',
    'view:account-settings-api-keys',
  ],
  Enterprise: [
    'view:campaigns',
    'view:reports',
    'view:projects',
    'view:projects-page',
    'view:accounts-settings-page',
    'view:account-settings-revenue',
    'view:account-settings-merge-tags',
    'view:account-settings-api-keys',
  ],
}
