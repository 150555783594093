import { useState } from 'react'
import helpers from 'workflow/utils/helpers'

import { restApi } from 'common/api'
import Button from 'common/components/button'
import Scrollable from 'common/components/scrollable'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { Download } from 'common/icons'

import Footer from '../Footer'
import { Value } from '../InputResultModal'

import CsvUploader from './CsvUploader'

type Props = {
  campaignId: string
  campaignName: string
  projectName: string | undefined
  onClose: () => void
  modes: SelectValue[]
  selectedMode: Value
  onChangeMode: (value: Value) => void
  onUploadCsv: (file: File) => Promise<void>
}

const CsvUploadResults = ({
  projectName,
  campaignName,
  onClose,
  campaignId,
  modes,
  selectedMode,
  onChangeMode,
  onUploadCsv,
}: Props) => {
  const [isCsvUploading, setIsCsvUploading] = useState(false)

  const handleUploadCsv = (file: File) => {
    setIsCsvUploading(true)
    return onUploadCsv(file).finally(() => setIsCsvUploading(false))
  }

  return (
    <div className="overflow-y-auto h-full mt-16">
      <Scrollable
        contentClassName="py-8 px-6 w-full flex items-center"
        content={
          <div className="m-auto flex-1" style={{ maxWidth: 1300 }}>
            <>
              <>
                <h1 className="font-medium text-xl text-coolGray-800">
                  Upload your results
                </h1>
                <h3 className="text-coolGray-500 mt-1">
                  You can upload a .CSV file or copy and paste directly from
                  Excel.
                </h3>
                <div className="mt-4 flex gap-8 justify-between items-center">
                  <SingleSelect
                    className="w-75"
                    data-cy="splitResults-mode-select"
                    data-testid="splitResults-mode-select"
                    name="mode"
                    options={modes}
                    value={selectedMode}
                    onChange={(val) => {
                      if (val?.value) {
                        onChangeMode(val.value)
                      }
                    }}
                  />
                  <div className="flex gap-4 items-center">
                    <span className="text-coolGray-500">
                      Download our blank template file
                    </span>
                    <Button
                      data-cy="download-template"
                      data-testid="download-template"
                      title="Download"
                      onClick={() => {
                        restApi
                          .get(`/campaigns/${campaignId}/getResultsCSV`)
                          .then((res) => {
                            const csvString = res.data
                            helpers.downloadCsvDeprecated(
                              campaignName,
                              csvString
                            )
                          })
                      }}
                      variant="primary"
                      suffixIcon={<Download isDefaultColor={false} />}
                    >
                      Download
                    </Button>
                  </div>
                </div>

                <Widget className="mt-8" type="table">
                  <WidgetHeader
                    className="mt-6 mx-6"
                    title="Experiment results"
                  />

                  <CsvUploader onUploadCsv={handleUploadCsv} />
                </Widget>
              </>
            </>
          </div>
        }
        footer={
          <Footer
            campaignName={campaignName}
            projectName={projectName}
            onClose={onClose}
            isCancelDisabled={isCsvUploading}
          />
        }
      />
    </div>
  )
}

export default CsvUploadResults
