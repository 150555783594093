import { ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import cx from 'classnames'

interface Props {
  to: React.ComponentProps<typeof RouterLink>['to']
  children: ReactNode
  'data-cy'?: string
  'data-testid'?: string
  className?: string
}

const Link = ({
  to,
  children,
  className,
  'data-cy': dataCy,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <RouterLink
      to={to}
      className={cx('text-sm', className)}
      data-cy={dataCy}
      data-testid={dataTestId}
    >
      {children}
    </RouterLink>
  )
}

export default Link
