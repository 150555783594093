import { useEffect } from 'react'
import cx from 'classnames'

import breakpoints from 'common/breakpoints'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useWindowDimensions } from 'common/hooks/useWindowDimensions'
import {
  selectLeftPanelVisibility,
  showLeftPanel,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

export type LeftPaneVisibility = 'auto' | 'hidden' | 'visible'

type Props = {
  children: React.ReactNode
  onOutsideClick?: () => void
  'data-testid'?: string
}

const LeftPanel = ({
  children,
  onOutsideClick,
  'data-testid': dataTestId = 'leftPanel',
}: Props) => {
  const dispatch = useAppDispatch()
  const { screenWidth } = useWindowDimensions()
  const visibility = useAppSelector(selectLeftPanelVisibility)

  useEffect(() => {
    if (screenWidth > breakpoints.lg) {
      dispatch(showLeftPanel({ visibility: 'auto' }))
    }
  }, [dispatch, screenWidth])

  return (
    <div
      className={cx({
        hidden: visibility === 'hidden',
        'hidden md:flex': visibility === 'auto',
      })}
      data-testid={dataTestId}
    >
      <div
        className={cx(
          'z-60 fixed right-0 w-full h-full bg-transparent md:hidden',
          {
            'pointer-events-none': visibility === 'hidden',
          }
        )}
        onClick={onOutsideClick}
      />
      <div
        className={cx(
          'z-60 fixed left-0 shadow-xl shadow-offset-right md:shadow-none md:static md:flex flex-col bg-gold-40 w-90',
          {
            flex: visibility === 'auto' || visibility === 'visible',
            hidden: visibility === 'hidden',
            'hidden md:flex': visibility === 'auto',
          }
        )}
      >
        <div className="h-full px-6 pb-6 mt-6 border-gold-400 border-r">
          {children}
        </div>
      </div>
    </div>
  )
}

export default LeftPanel
