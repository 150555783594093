/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import { Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import type { Moment } from 'moment'
import moment from 'moment-timezone'

import Alert from 'common/components/alert'
import Button from 'common/components/button/Button'
import Loader from 'common/components/loaders/Loader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import SelectComponent from '../components/select/SelectComponent'

import {
  fetchExperiments,
  fetchProjects,
  fetchTimezone,
  scheduleCampaign,
  setError,
  setLoading,
  setTimezone,
  updateExperiments,
  updateProjects,
} from './store/optimizelySlice'
import DrawerContent from './DrawerContent'

export const initialValidationErrors: ValidationErrors = {
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  selectProject: '',
  selectExperiment: '',
  isVariantsCreated: '',
  primaryGoal: '',
}

interface ValidationErrors {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  selectProject: string
  selectExperiment: string
  isVariantsCreated: string
  primaryGoal: string
}

interface State {
  startDate: Moment | undefined
  startTime: Moment | undefined
  endDate: Moment | undefined
  endTime: Moment | undefined
  selectedProject: number | undefined
  selectedExperiment: string | undefined
  isVariantsCreated: boolean
  isExperimentScheduled: boolean
  errorMessages: ValidationErrors
  selectedPrimaryGoal: string | undefined
  showUnscheduleConfirmation: boolean
  showUnlinkConfirmation: boolean
  campaignUnscheduled: boolean
  campaignUnlinked: boolean
}

export const initialState: State = {
  startDate: undefined,
  startTime: undefined,
  endDate: undefined,
  endTime: undefined,
  selectedProject: undefined,
  selectedExperiment: undefined,
  isVariantsCreated: false,
  isExperimentScheduled: false,
  selectedPrimaryGoal: undefined,
  showUnscheduleConfirmation: false,
  showUnlinkConfirmation: false,
  campaignUnscheduled: false,
  campaignUnlinked: false,
  errorMessages: {
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    selectProject: '',
    selectExperiment: '',
    isVariantsCreated: '',
    primaryGoal: '',
  },
}

export default function OptimizelyIntegration() {
  const dispatch = useAppDispatch()

  const [state, setState] = useState<State>(initialState)
  const {
    endTime,
    endDate,
    startDate,
    startTime,
    errorMessages,
    isVariantsCreated,
    selectedExperiment,
    selectedProject,
    isExperimentScheduled,
    selectedPrimaryGoal,
    campaignUnscheduled,
    campaignUnlinked,
  } = state

  const { projectsList, experimentsList, isLoading, timeZone, error } =
    useAppSelector((state) => state.optimizely)

  const {
    campaignData: {
      project_id,
      _id: campaign_id,
      campaign_data: { integration_data },
    },
  } = useAppSelector((state) => state.campaignStates)

  useEffect(() => {
    if (!projectsList && project_id && !campaignUnlinked) {
      dispatch(setLoading({ field: 'projects', value: true }))

      fetchProjects(project_id)
        .then((res) => {
          dispatch(updateProjects(res.data))
        })
        .catch((err) => {
          dispatch(setError(err.message))
        })
        .finally(() =>
          dispatch(setLoading({ field: 'projects', value: false }))
        )
    }
    if (selectedProject && !experimentsList) {
      dispatch(setLoading({ field: 'experiments', value: true }))
      fetchExperiments(project_id, selectedProject)
        .then((res) => {
          dispatch(updateExperiments(res.data))
        })
        .catch((err) => {
          dispatch(setError(err.message))
        })
        .finally(() =>
          dispatch(setLoading({ field: 'experiments', value: false }))
        )
    }
    if (!timeZone) {
      dispatch(setLoading({ field: 'timezone', value: true }))
      fetchTimezone(project_id, campaign_id)
        .then((res) => {
          dispatch(setTimezone(res.data))
        })
        .catch((err) => {
          dispatch(setError(err.message))
        })
        .finally(() =>
          dispatch(setLoading({ field: 'timezone', value: false }))
        )
    }

    if (
      (!selectedExperiment || !selectedProject) &&
      integration_data?.abSplits &&
      integration_data?.experimentId &&
      !campaignUnlinked
    ) {
      setState({
        ...state,
        isVariantsCreated: true,
        selectedExperiment: integration_data.experimentId,
        selectedProject: integration_data.projectId,
      })
    }
    if (
      timeZone &&
      integration_data?.primaryGoal &&
      integration_data?.campaignTestEndDate &&
      integration_data?.campaignTestStartDate &&
      (!startDate || !startTime || !endDate || !endTime) &&
      !campaignUnscheduled
    ) {
      const initialStartDatetime = moment
        .utc(integration_data?.campaignTestStartDate)
        .tz(timeZone)
      const initialEndDatetime = moment
        .utc(integration_data?.campaignTestEndDate)
        .tz(timeZone)
      setState({
        ...state,
        isExperimentScheduled: true,
        endDate: initialEndDatetime,
        endTime: initialEndDatetime,
        startTime: initialStartDatetime,
        startDate: initialStartDatetime,
        selectedPrimaryGoal: integration_data?.primaryGoal,
      })
    }
  }, [
    state,
    endDate,
    endTime,
    dispatch,
    timeZone,
    startDate,
    startTime,
    project_id,
    campaign_id,
    projectsList,
    experimentsList,
    selectedProject,
    campaignUnlinked,
    selectedExperiment,
    campaignUnscheduled,
    integration_data?.offers,
    integration_data?.abSplits,
    integration_data?.projectId,
    integration_data?.primaryGoal,
    integration_data?.experimentId,
    integration_data?.campaignTestEndDate,
    integration_data?.campaignTestStartDate,
  ])

  const onDateTimeChange = (val: any, type: string): void => {
    setState({
      ...state,
      ...getChangedSchedulerValues({
        state,
        type,
        value: val,
      }),
    })
  }

  const validateInputs = (): boolean => {
    let isFormValid = true
    const validationErrors: ValidationErrors = initialValidationErrors
    // Validate that all required values exist
    if (!selectedProject) {
      isFormValid = false
      validationErrors.selectProject = 'Please select an Optimizely project'
    }
    if (!selectedExperiment) {
      isFormValid = false
      validationErrors.selectExperiment =
        'Please select an Optimizely experiment'
    }
    if (!startDate || !startTime) {
      isFormValid = false
      validationErrors.startDate =
        'experiment start date and time fields are required'
    }
    if (!endDate || !endTime) {
      isFormValid = false
      validationErrors.endDate =
        'experiment end date and time fields are required'
    }
    if (!selectedPrimaryGoal) {
      isFormValid = false
      validationErrors.primaryGoal = 'Primary goal field is required'
    }
    if (!isVariantsCreated) {
      isFormValid = false
      validationErrors.isVariantsCreated = 'Offers have not been created'
    }
    setState({ ...state, errorMessages: validationErrors })
    return isFormValid
  }

  const onSchedule = () => {
    if (
      validateInputs() &&
      startDate &&
      startTime &&
      endDate &&
      endTime &&
      selectedPrimaryGoal &&
      timeZone
    ) {
      const test_start_time = moment()
        .tz(timeZone)
        .set({
          year: startDate.year(),
          month: startDate.month(),
          date: startDate.date(),
          hours: startTime.hours(),
          minutes: startTime.minutes(),
        })
        .utc()
        .format('YYYY-MM-DD HH:mm')
      const test_end_time = moment()
        .tz(timeZone)
        .set({
          year: endDate.year(),
          month: endDate.month(),
          date: endDate.date(),
          hours: endTime.hours(),
          minutes: endTime.minutes(),
        })
        .utc()
        .format('YYYY-MM-DD HH:mm')
      const schedulePayload = {
        test_start_time,
        test_end_time,
        primary_goal: selectedPrimaryGoal,
      }
      dispatch(setLoading({ field: 'scheduler', value: true }))
      scheduleCampaign(project_id, campaign_id, schedulePayload)
        .then(() => {
          setState({
            ...state,
            isExperimentScheduled: true,
            errorMessages: initialValidationErrors,
            campaignUnscheduled: false,
          })
        })
        .catch((err) => {
          dispatch(setError(err.message))
        })
        .finally(() =>
          dispatch(setLoading({ field: 'scheduler', value: false }))
        )
    }
  }

  const getChangedSchedulerValues = ({ state, type, value }) => {
    const { startDate, startTime, endDate, endTime } = state
    switch (type) {
      case 'startDate':
        return {
          startDate: moment(value),
          endDate: moment(value).isSameOrAfter(endDate)
            ? moment(value)
            : endDate,
          startTime: startTime
            ? moment(value).set({
                hours: startTime.hours(),
                minutes: startTime.minutes(),
              })
            : undefined,
        }
      case 'startTime':
        return {
          startDate: startDate
            ? startDate.set({
                hours: value.hours(),
                minutes: value.minutes(),
              })
            : undefined,
          startTime: moment(value),
          endTime: endTime && value.format() > endTime.format(),
        }
      case 'endDate':
        return {
          endDate: moment(value),
          endTime: endTime
            ? moment(value).set({
                day: endTime.day(),
                month: endTime.month(),
                year: endTime.year(),
              })
            : undefined,
        }
      case 'endTime':
        return {
          endDate: endDate
            ? moment(endDate).set({
                hour: value.hour(),
                minute: value.minute(),
              })
            : undefined,
          endTime: moment(value),
        }
      default:
        return {}
    }
  }

  return (
    <>
      <Drawer.Content>
        <Form>
          <Loader isLoading={Object.values(isLoading).includes(true)}>
            <div
              data-testid="optimizelyIntegrationTitle"
              className="text-base font-medium text-gray-400"
            >
              Connect your Optimizely account with your Jacquard experiment.
            </div>
            {isExperimentScheduled && (
              <Alert type="success" className="flex items-center mt-6">
                This experiment is currently saved and collecting data from
                Optimizely.
              </Alert>
            )}
            <SelectComponent
              className="mt-8"
              isLoading={isLoading.projects}
              selectOptions={projectsList}
              isSelectDisabled={
                isLoading.projects || isExperimentScheduled || isVariantsCreated
              }
              dataTestId="selectProject"
              onChange={(val) => setState({ ...state, selectedProject: val })}
              selectValue={selectedProject}
              labelTitle="Optimizely Project"
              bottomText={`This is the Optimizely Project
             that contains your Experiment for this Jacquard experiment.`}
              errorMessage={errorMessages.selectProject}
              defaultOption="Select"
            />
            <DrawerContent
              experimentsList={experimentsList}
              onDateTimeChange={onDateTimeChange}
              campaignId={campaign_id}
              projectId={project_id}
              isLoading={isLoading}
              currentState={state}
              timeZone={timeZone}
              setState={setState}
            />
          </Loader>
        </Form>
      </Drawer.Content>
      <Drawer.Footer error={error}>
        <Button
          loading={isLoading.scheduler}
          variant={isExperimentScheduled ? 'success' : 'primary'}
          disabled={
            isExperimentScheduled ||
            !selectedExperiment ||
            !isVariantsCreated ||
            !startDate ||
            !startTime ||
            !endDate ||
            !endTime
          }
          data-testid="scheduleBtn"
          onClick={onSchedule}
        >
          {isExperimentScheduled ? 'Saved' : 'Save schedule'}
        </Button>
      </Drawer.Footer>
    </>
  )
}
