import React from 'react'

import Input from 'common/components/input'
import Tooltip from 'common/components/Tooltip'
import YesNoButton from 'common/components/YesNoButton'

type Props = {
  defaultValue: any
  name: string
  type: string
  onChange: Function
  tooltip?: string
  placeholder?: string
  disabled?: boolean
}
class SelectRadio extends React.Component<Props, any> {
  constructor(props: Props) {
    super(props)
    this.state = {
      checked: !!props?.defaultValue?.radioChecked || !!props?.defaultValue,
      defaultValue: props.defaultValue,
      hasUpdatedPropsValue: false,
    }
  }

  componentDidUpdate(_, prevState: any) {
    const { defaultValue } = this.props
    const defaultValueObject = {
      radioChecked:
        typeof defaultValue === 'string'
          ? !!defaultValue
          : defaultValue?.radioChecked,
      radioText:
        typeof defaultValue === 'string'
          ? defaultValue
          : defaultValue?.radioText,
    }
    if (
      defaultValueObject?.radioChecked !== prevState?.defaultValue?.radioChecked
    ) {
      this.setState({
        hasUpdatedPropsValue: true,
        checked: defaultValueObject?.radioChecked,
        defaultValue: defaultValueObject,
      })
    }
  }

  onChange = (e: any) => {
    e.persist()
    this.setState(
      (state: any) => ({
        ...state,
        checked: e?.target?.value === 'yes',
        hasUpdatedPropsValue: false,
        defaultValue: {
          radioText: state.defaultValue.radioText,
          radioChecked: e?.target?.value === 'yes',
        },
      }),
      () => this.parentOnChange()
    )
  }

  onTextInputChange = (e: any) => {
    e.persist()
    this.setState(
      (state: any) => ({
        defaultValue: {
          ...state.defaultValue,
          radioText: e?.target?.value,
        },
      }),
      () => this.parentOnChange()
    )
  }

  parentOnChange = () => {
    const {
      checked,
      defaultValue: { radioText },
    } = this.state
    const { name, type, onChange } = this.props
    const val = radioText || ''
    onChange(name, type, val, false, { radio: checked })
  }

  render() {
    const {
      checked,
      defaultValue: { radioText },
      defaultValue,
    } = this.state
    const { tooltip, placeholder, disabled } = this.props

    return (
      <div className="w-full flex items-center justify-start">
        <YesNoButton
          selectedValue={checked ? 'yes' : 'no'}
          onClick={this.onChange}
          isDisabled={disabled ?? false}
        />
        {checked && (
          <Tooltip
            overlay={tooltip}
            trigger="focus"
            placement="right"
            overlayStyle={{ maxWidth: 226 }}
          >
            <div className="w-56 ml-4">
              <Input
                variant="default"
                className="text-base"
                value={typeof radioText === 'string' ? radioText : defaultValue}
                type="text"
                onChange={this.onTextInputChange}
                placeholder={placeholder}
                disabled={disabled}
              />
            </div>
          </Tooltip>
        )}
      </div>
    )
  }
}

export default SelectRadio
