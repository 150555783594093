import Menu, { Divider, MenuItemGroup } from 'rc-menu'
import type { MenuClickEventHandler } from 'rc-menu/lib/interface'

import useAvailableIntegrations from '../../hooks/useAvailableIntegrations'

import AddIntegrationButton from './AddIntegrationButton'
import IntegrationItem from './IntegrationItem'
import { Options } from './interfaces'

const IntegrationsMenu = ({
  options,
  onClick,
}: {
  options: Options[]
  onClick: MenuClickEventHandler
}) => {
  const { data } = useAvailableIntegrations()

  const unavailableIntegrations = options?.filter(
    ({ provider }) => !data?.includes(provider)
  )

  const availableIntegrations = options?.filter(({ provider }) =>
    data?.includes(provider)
  )

  return (
    <Menu
      className="z-40 px-4 pt-2 bg-gold-50 border border-gold-200 min-w-60"
      selectable={false}
      onClick={onClick}
      data-testid="integrations-menu"
    >
      {availableIntegrations?.length > 0 && (
        <MenuItemGroup
          key="available-integrations"
          aria-label="Available for this channel"
        >
          {availableIntegrations.map((option, index) => {
            const { key, label, logoUrl, provider } = option

            return (
              <IntegrationItem
                key={key}
                logoUrl={logoUrl}
                alt={`${provider}-logo-${index}`}
                label={label}
              />
            )
          })}
        </MenuItemGroup>
      )}

      {unavailableIntegrations?.length > 0 && (
        <MenuItemGroup
          aria-label="Unavailable for this channel"
          key="unavailable-integrations"
          className="pt-2 text-base-700 font-medium"
          title="Unavailable for this channel"
          disabled
        >
          {unavailableIntegrations.map((option, index) => {
            const { key, label, logoUrl, provider } = option

            return (
              <IntegrationItem
                isDisabled
                key={key}
                logoUrl={logoUrl}
                alt={`${provider}-logo-${index}`}
                label={label}
              />
            )
          })}
        </MenuItemGroup>
      )}

      <Divider />
      <AddIntegrationButton key="redirect" />
    </Menu>
  )
}

export default IntegrationsMenu
