import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Table } from 'antd'
import { FilterDropdownProps } from 'antd/lib/table'
import { DynamicOptimizationResultType } from 'workflow/interface'

import ConfirmationModal from 'common/components/confirmationModal'
import StatusSelect from 'common/components/statusSelect'
import { errorToast, successToast } from 'common/components/toastNotification'
import Tooltip from 'common/components/Tooltip'

import api from './api'
import { CustomFilterDropdown } from './CustomFilterDropdown'
import {
  getRoundedNumber,
  variantStatusFilters,
  variantStatusSelectOptions,
} from './helpers'
import { ComponentProps, Variant, VariantStatusUpdate } from './interfaces'

import customStyles from './CampaignResultsTable.module.css'
import styles from 'common/components/table/AntdTable.module.css'

const COLUMN_WIDTH = 'auto'
const VARIANTS_WIDTH = 400

export const Methods = (showMachineOpens: boolean = true) => {
  const customiseValues = ({
    values,
    resultType,
    testTrackedMetrics,
    isBanditManualConversionsCampaign = false,
    setVariantToUpdate,
    isLoading,
    isStatusDropdownDisabled = false,
  }: {
    values: Array<any>
    resultType?: DynamicOptimizationResultType
    testTrackedMetrics?: { name: string; type: string }[]
    isBanditManualConversionsCampaign?: boolean
    setVariantToUpdate: (value: VariantStatusUpdate) => void
    isLoading: boolean
    isStatusDropdownDisabled?: boolean
  }) => {
    const isMatureTabAndAllZeroRecipients =
      resultType === 'mature' && values.every((value) => !value?.num_recipients)
    return values.map((value: any) => {
      const convertedValues: any = {}
      Object.keys(value).forEach((key: string) => {
        const isHumanUpliftCell =
          (key === 'open_uplift' || key === 'click_uplift') && value.ownsl
        const isPhraseeScoreCell =
          key === 'phrasee_score' || key === 'phrasee_score_rate'
        const isDroppedVariant = value.variant_status === 'dropped'
        const isTrackedMetricOrUpliftCell =
          testTrackedMetrics?.some((metricObj) => metricObj?.name === key) ||
          key === 'open_uplift' ||
          key === 'click_uplift'
        const isBanditManualConversionsCell =
          key === 'conversions' &&
          value[key] !== '' &&
          isBanditManualConversionsCampaign &&
          resultType !== 'test'
        if (key === 'variant_status') {
          convertedValues[key] = (
            <StatusSelect
              isDisabled={isLoading || isStatusDropdownDisabled || value.ownsl}
              value={value[key]}
              onChange={(onChangeValue) => {
                if (onChangeValue!.value === value[key]) {
                  return
                }
                return setVariantToUpdate({
                  id: value._id,
                  variantStatus: onChangeValue!.value,
                })
              }}
              options={variantStatusSelectOptions[value[key]]}
            />
          )
        } else if (
          (value[key] === '' ||
            (isMatureTabAndAllZeroRecipients && isTrackedMetricOrUpliftCell)) &&
          !isBanditManualConversionsCell &&
          !isHumanUpliftCell &&
          !(isPhraseeScoreCell && isDroppedVariant)
        ) {
          convertedValues[key] = (
            <Tooltip overlay="Data not available yet">
              <span className="text-left select-none font-bold text-coolGray-400">
                -
              </span>
            </Tooltip>
          )
        } else if (key === 'is_significant') {
          if (value[key] === 1) {
            convertedValues[key] = 'Yes'
          }
          if (value[key] === 0) {
            convertedValues[key] = 'No'
          }
        } else if (
          key === 'num_opens' &&
          !showMachineOpens &&
          value.num_machine_opens !== undefined
        ) {
          convertedValues[key] = getRoundedNumber(
            value[key] - value.num_machine_opens,
            2
          )
        } else if (
          key === 'open_rate' &&
          !showMachineOpens &&
          value.open_rate_excluded_machine_opens
        ) {
          convertedValues[key] = value.open_rate_excluded_machine_opens
        } else if (
          key === 'open_uplift' &&
          !showMachineOpens &&
          value.open_rate_uplift_machine_opens_excluded
        ) {
          convertedValues[key] = value.open_rate_uplift_machine_opens_excluded
        } else {
          convertedValues[key] = getRoundedNumber(value[key], 2)
        }
        return convertedValues
      })

      return {
        ...convertedValues,
      }
    })
  }
  const customiseColumns = (
    columns: Array<any>,
    isBanditCampaign: boolean,
    isLinguoCampaign: boolean,
    appliedFilters?: any // eslint-disable-next-line max-params
  ) => {
    return columns.map((column, index) => {
      const fixedValue = index === 0 ? 'left' : false
      column.sorter = column.isSortingDisabled
        ? null
        : getColumnSorter(column.key)
      switch (column.key) {
        case 'variant':
          return {
            ...column,
            width: VARIANTS_WIDTH,
            fixed: fixedValue,
            className: 'vertical-align--middle text-white-space--preline',
          }
        case 'variant_status':
          const onFilter = (value: string, record: any) => {
            return (
              record.variant_status?.props.value.toLowerCase() ===
              value.toLowerCase()
            )
          }
          const defaultFilteredValue =
            isBanditCampaign && isLinguoCampaign
              ? ['live', 'approved', 'pending']
              : isBanditCampaign
              ? ['live', 'approved']
              : []

          return {
            ...column,
            width: COLUMN_WIDTH,
            align: 'left',
            className: 'center vertical-align--middle',
            filters: variantStatusFilters,
            disabled: true,
            onFilter,
            filteredValue:
              appliedFilters?.variant_status || defaultFilteredValue,
            filterDropdown: (filterProps: FilterDropdownProps) => (
              <CustomFilterDropdown
                {...filterProps}
                defaultFilteredValue={defaultFilteredValue}
              />
            ),
            // Note for the feature. "defaultFilterValue" won't work with controlled filter state
          }
        default:
          return {
            ...column,
            width: COLUMN_WIDTH,
            className: `center vertical-align--middle ${customStyles.customTableHeader}`,
            align: 'left',
            title: column.is_percentage ? `${column.title} (%)` : column.title,
          }
      }
    })
  }
  const getColumnSorter = (columnKey: string | number) => {
    let sorter
    switch (columnKey) {
      case 'variant':
        sorter = (
          a: { variant: { props: { text: string } } },
          b: { variant: { props: { text: string } } }
        ) => {
          const aText = a?.variant?.props?.text || ''
          const bText = b?.variant?.props?.text || ''
          return aText.toLowerCase().localeCompare(bText.toLowerCase())
        }
        break
      case 'variant_status':
        sorter = (
          a: { [x: string]: { props: { title: string } } },
          b: { [x: string]: { props: { title: string } } }
        ) => {
          const aText = a[columnKey]?.props?.title || ''
          const bText = b[columnKey]?.props?.title || ''
          // sort as strings
          return aText.localeCompare(bText)
        }
        break
      default:
        // default sorting for columns not handled above
        sorter = (a: { [x: string]: any }, b: { [x: string]: any }) => {
          const aVal = a[columnKey] ?? ''
          const bVal = b[columnKey] ?? ''

          // first check if both aVal and bVal are or can be converted to numbers
          // defaulting blank strings to -1, as we want to sort blank strings before actual zero values
          const aNum = Number(
            aVal === '' ? -1 : aVal.toString().replace('%', '')
          )
          const bNum = Number(
            bVal === '' ? -1 : bVal.toString().replace('%', '')
          )

          // if both are valid numbers, sort them as numbers
          if (!isNaN(aNum) && !isNaN(bNum)) {
            return aNum - bNum
          }

          // if not valid numbers, then try to sort as strings
          const aText = aVal.toString().toLowerCase()
          const bText = bVal.toString().toLowerCase()
          return aText.localeCompare(bText)
        }
        break
    }
    return sorter
  }
  return {
    customiseValues,
    customiseColumns,
    getColumnSorter,
  }
}

const CampaignResultsTableDefault = (props: ComponentProps) => {
  const {
    campaignId,
    results: { values, columns },
    bordered,
    className,
    header,
    rowSelectionEnabled,
    selectedRowKeys,
    isBanditCampaign,
    isLinguoCampaign,
    isBanditManualConversionsCampaign,
    appliedFilters,
    resultType,
    testTrackedMetrics,
    updateResultsVariantStatus,
    isBroadcastCampaign = false,
    isStatusDropdownDisabled = false,
    showMachineOpens = false,
    hasResultsReceived = false,
  } = props
  const defaultFilters =
    isBanditCampaign && isLinguoCampaign
      ? ['live', 'approved', 'pending']
      : isBanditCampaign
      ? ['live', 'approved']
      : []
  const { customiseValues, customiseColumns } = Methods(showMachineOpens)
  const [variantToUpdate, setVariantToUpdate] =
    useState<VariantStatusUpdate | undefined>(undefined)

  const rowSelection = {
    getCheckboxProps: (record: any) => {
      return {
        className: styles.checkbox,
        style: { color: record?.color },
        name: record.color,
      }
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys:
      selectedRowKeys ||
      values
        .filter((value) => defaultFilters.includes(value.variant_status))
        .map((value) => value.key),
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      if (!rowSelectionEnabled) {
        return
      }
      props.rowSelectionAction!(selectedRowKeys, selectedRows, appliedFilters)
    },
  }
  const onChangeCallBack = (
    pagination: any,
    filters: any,
    sorter: any,
    { currentDataSource }: any // eslint-disable-next-line max-params
  ) => {
    if (!rowSelectionEnabled) {
      return
    }
    const selectedRowKeys = currentDataSource.map(
      (variant: Variant) => variant.key
    )
    props.rowSelectionAction!(selectedRowKeys, currentDataSource, filters)
  }

  const updateVariantStatusMutation = useMutation({
    mutationFn: ({ id, variantStatus }: VariantStatusUpdate) =>
      api.updateVariantStatus(id, campaignId, variantStatus),
    onError: () => errorToast('Something went wrong. Please try again.'),
    onSuccess(data, { id, variantStatus }) {
      updateResultsVariantStatus(id, variantStatus)
      successToast('Variant status updated successfully.')
    },
  })

  return (
    <div className="mt-2">
      {header && (
        <h2 data-testid="split-header" className="text-maroon-200 p-3">
          {header}
        </h2>
      )}
      <div className="flex w-full">
        <Table
          onChange={onChangeCallBack}
          rowSelection={
            rowSelectionEnabled && hasResultsReceived ? rowSelection : undefined
          }
          bordered={bordered || false}
          pagination={false}
          data-testid="table-view"
          className={`w-full results with-scroll ${className} ${customStyles.customTableHeader}`}
          columns={customiseColumns(
            columns,
            isBanditCampaign ?? false,
            isLinguoCampaign ?? false,
            appliedFilters
          )}
          dataSource={customiseValues({
            values,
            resultType,
            testTrackedMetrics,
            isBanditManualConversionsCampaign,
            setVariantToUpdate,
            isLoading: updateVariantStatusMutation.isLoading,
            isStatusDropdownDisabled,
          })}
          size="middle"
          scroll={{ x: true }}
        />
      </div>

      <ConfirmationModal
        data-cy="change-variant-status-modal"
        data-testid="change-variant-status-modal"
        title="Change status or variant?"
        confirmationText={`Do you really want to change the status of this variant? Changes could take up to ${
          isBroadcastCampaign ? '1 hour' : '24 hours'
        } to take effect.`}
        cancelButtonText="Cancel"
        confirmButtonText="Yes, do it!"
        isLoading={false}
        confirmButtonVariant="primary"
        open={variantToUpdate !== undefined}
        onConfirm={() => {
          if (variantToUpdate) {
            updateVariantStatusMutation.mutate(variantToUpdate)
          }
          setVariantToUpdate(undefined)
        }}
        onCancel={() => {
          setVariantToUpdate(undefined)
        }}
      />
    </div>
  )
}

export default CampaignResultsTableDefault
