import { ChangeEvent, forwardRef, InputHTMLAttributes, useState } from 'react'
import cx from 'classnames'

import Input from 'common/components/input'
import { ReactComponent as Eye } from 'common/icons/eye/default.svg'
import { ReactComponent as EyeNo } from 'common/icons/eyeNo/default.svg'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean
  hideLastPassIcon?: boolean
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const PasswordInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { hasError, hideLastPassIcon = false, className, ...rest } = props
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
  const [showEye, setShowEye] = useState<boolean>(false)

  return (
    <div
      className={cx('relative w-full', className)}
      onFocus={() => {
        setShowEye(true)
      }}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget as Node)) {
          setShowEye(false)
        }
      }}
    >
      <Input
        {...rest}
        className={cx(
          `h-10 w-full px-3 py-2 text-sm text-coolGray-800  caret-maroon-200 
           placeholder-sm placeholder-coolGray-800 placeholder-opacity-50 
           border border-blueGray-300 bg-coolGray-50 focus:outline-none
           focus:bg-opacity-10`,
          {
            [`focus:placeholder-maroon-200
             focus:border-maroon-400 `]: !hasError,
            [`focus:text-red-400 focus:placeholder-red-400
             focus:border-red-400 focus:bg-red-400`]: hasError,
          }
        )}
        {...(hideLastPassIcon ? { 'data-lpignore': 'true' } : {})}
        ref={ref}
        type={isPasswordVisible ? 'text' : 'password'}
        autoComplete="new-password"
      />
      <button
        className={cx('absolute top-2 right-2 text-maroon-200 ', {
          invisible: !showEye,
        })}
        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        tabIndex={-1}
      >
        {isPasswordVisible ? (
          <EyeNo width={24} height={24} />
        ) : (
          <Eye width={24} height={24} />
        )}
      </button>
    </div>
  )
})

export default PasswordInput
