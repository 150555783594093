import { useEffect } from 'react'
import moment from 'moment-timezone'
import {
  fetchEpsilonBusinessUnits,
  fetchEpsilonCampaigns,
  fetchEpsilonFolders,
  fetchEpsilonSequences,
} from 'workflow/Workflow.actions'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { SchedulerState } from '../EpsilonReactIntegration'

import { EpsilonSelector } from './EpsilonSelector'

interface Props {
  selectedBusinessUnit: string
  selectedCampaign: string
  selectedFolder: string
  schedulerState: SchedulerState
  selectedSequence?: string
  isCampaignScheduled: boolean
  isVariantsPushed: boolean
  projectId: string
  setSelectedBusinessUnit: (val: string) => void
  setSelectedCampaign: (val: string) => void
  setSelectedFolder: (val: string) => void
  setSelectedSequence?: (val: string) => void
  setSelectedSeedList: (val: string) => void
  setIsVariantsPushed: (val: boolean) => void
  setHasBeenInitialized: (val: boolean) => void
  setIsCampaignScheduled: (val: boolean) => void
  setSchedulerState?: (val: SchedulerState) => void
}

export const CreateVariantsForm = ({
  selectedBusinessUnit,
  selectedFolder,
  selectedCampaign,
  selectedSequence,
  schedulerState,
  isVariantsPushed,
  isCampaignScheduled,
  projectId,
  setSelectedBusinessUnit,
  setSelectedFolder,
  setSelectedCampaign,
  setSelectedSequence,
  setSelectedSeedList,
  setSchedulerState,
  setHasBeenInitialized,
  setIsCampaignScheduled,
  setIsVariantsPushed,
}: Props) => {
  const {
    epsilonBusinessUnits,
    epsilonFolders,
    epsilonCampaigns,
    epsilonSequences,
    campaignData,
    campaignData: {
      campaign_data: { integration_data: integrationData = {} } = {},
    } = {},
  } = useAppSelector((state) => state.campaignStates)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!epsilonBusinessUnits?.length) {
      dispatch(fetchEpsilonBusinessUnits(projectId))
    }

    // Set initial business unit, folder and campaign received from props
    if (
      integrationData?.businessUnitId &&
      integrationData?.folderId &&
      integrationData?.campaignId &&
      integrationData?.sequenceId &&
      setSelectedSequence &&
      setSchedulerState
    ) {
      setSelectedBusinessUnit(integrationData.businessUnitId)
      setSelectedFolder(integrationData.folderId)
      setSelectedCampaign(integrationData.campaignId)
      setSelectedSequence(integrationData.sequenceId)
      setIsVariantsPushed(true)
      setHasBeenInitialized(true)
      setIsCampaignScheduled(false)
      // If there is some optimisation start and end time we fill the state with it.
      if (
        campaignData?.campaign_data.optimization_start_time &&
        campaignData?.campaign_data.optimization_end_time
      ) {
        setSchedulerState({
          ...schedulerState,
          startDate: moment(
            campaignData?.campaign_data.optimization_start_time
          ),
          endDate: moment(campaignData?.campaign_data.optimization_end_time),
          startTime: moment(
            campaignData?.campaign_data.optimization_start_time
          ),
          endTime: moment(campaignData?.campaign_data.optimization_end_time),
        })
        setIsCampaignScheduled(true)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId])

  useEffect(() => {
    if (
      integrationData?.businessUnitId &&
      integrationData?.folderId &&
      integrationData?.campaignId &&
      integrationData?.sequenceId &&
      !epsilonFolders?.length &&
      !epsilonCampaigns?.length &&
      (isCampaignScheduled || isVariantsPushed)
    ) {
      dispatch(fetchEpsilonFolders(projectId, integrationData.businessUnitId))
      dispatch(
        fetchEpsilonCampaigns(
          projectId,
          integrationData.businessUnitId,
          integrationData.folderId,
          integrationData.campaignId
        )
      )
      dispatch(
        fetchEpsilonSequences(
          projectId,
          integrationData.businessUnitId,
          integrationData.folderId,
          integrationData.campaignId
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusinessUnit, selectedCampaign, selectedFolder])

  const onBusinessUnitChange = (val: string): void => {
    if (projectId) {
      if (val) {
        dispatch(fetchEpsilonFolders(projectId, val))
      }
      setSelectedBusinessUnit(val)
      setSelectedFolder('')
      setSelectedCampaign('')
      setSelectedSeedList('')
    }
  }
  const onFolderChange = (val: string): void => {
    dispatch(fetchEpsilonCampaigns(projectId, selectedBusinessUnit, val, ''))
    setSelectedCampaign('')
    setSelectedFolder(val)
  }
  const onCampaignChange = (val: string): void => {
    setSelectedCampaign(val)
    dispatch(
      fetchEpsilonSequences(projectId, selectedBusinessUnit, selectedFolder, '')
    )
  }
  const onSequenceChange = (val: string): void => {
    if (setSelectedSequence) {
      setSelectedSequence(val)
    }
  }

  return (
    <>
      <EpsilonSelector
        onChange={onBusinessUnitChange}
        selectedOption={selectedBusinessUnit}
        selectorOptions={epsilonBusinessUnits}
        isDisabled={
          isCampaignScheduled ||
          isVariantsPushed ||
          !epsilonBusinessUnits ||
          epsilonBusinessUnits?.length === 0
        }
        selectorOptionName="business_unit_name"
        selectorOptionId="business_unit_id"
        label="Epsilon business unit"
        placeholder="Business unit"
        testId="selectBusinessUnit"
        defaultValue="Select"
      />

      <EpsilonSelector
        onChange={onFolderChange}
        selectedOption={selectedFolder}
        selectorOptions={epsilonFolders}
        isDisabled={
          isCampaignScheduled ||
          isVariantsPushed ||
          !selectedBusinessUnit ||
          epsilonFolders?.length === 0
        }
        selectorOptionName="epsilon_folder_name"
        selectorOptionId="epsilon_folder_id"
        label="Epsilon folder"
        placeholder="Folder"
        testId="selectFolder"
        defaultValue="Select"
      />

      <EpsilonSelector
        onChange={onCampaignChange}
        selectedOption={selectedCampaign}
        selectorOptions={epsilonCampaigns}
        isDisabled={
          isCampaignScheduled ||
          isVariantsPushed ||
          !selectedBusinessUnit ||
          !selectedFolder ||
          epsilonCampaigns?.length === 0
        }
        selectorOptionName="epsilon_campaign_name"
        selectorOptionId="epsilon_campaign_id"
        label="Epsilon campaign"
        placeholder="Select an unscheduled campaign"
        testId="selectCampaign"
        tooltipContent="Select a draft campaign that has all required fields completed, including the Properties, Audience, and Content"
        defaultValue="Select an unscheduled campaign"
      />

      <EpsilonSelector
        onChange={onSequenceChange}
        selectedOption={selectedSequence as string}
        selectorOptions={epsilonSequences}
        isDisabled={
          isCampaignScheduled ||
          isVariantsPushed ||
          !selectedBusinessUnit ||
          !selectedFolder ||
          !selectedCampaign ||
          epsilonSequences?.length === 0
        }
        selectorOptionName="epsilon_campaign_name"
        selectorOptionId="epsilon_campaign_id"
        label="Epsilon sequence"
        placeholder="Select an unscheduled sequence"
        testId="selectsequence"
        tooltipContent="Select a draft sequence that has all required fields completed, including the Properties, Audience, and Content"
        defaultValue="Select an unscheduled sequence"
      />
    </>
  )
}

export default CreateVariantsForm
