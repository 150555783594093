import { useEffect } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { unifiedFlowPaths } from 'app/navigation/paths'
import CampaignSummary from 'workflow/CampaignSummary/CampaignSummary'
import PersonalizationSummary from 'workflow/PersonalizationSummary/PersonalizationSummary'
import { fetchCampaignData } from 'workflow/Workflow.actions'

import Loader from 'common/components/loaders/Loader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'

import { CurrentUsersBanner } from '../../components/CurrentUsersBanner'
import CenterPanel from '../../components/layout/CenterPanel'
import PageDetails from '../../components/PageDetails'
import { useContent } from '../../hooks'

const CenterSection = () => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(
    (state) => state.campaignStates.isLoadingCampaignDetails
  )
  const { content } = useContent()
  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )
  const elements = content?.elements ?? []
  const componentElements = elements?.filter(isComponentElement)

  const selectedComponentElement = componentElements?.find(
    (element) => element.element_id === selectedElementId
  )

  const currentCampaignId = selectedComponentElement?.campaign_id

  useEffect(() => {
    const abortController = new AbortController()
    if (currentCampaignId) {
      dispatch(fetchCampaignData(currentCampaignId, {}, abortController.signal))
    }
    return () => {
      abortController.abort()
    }
  }, [dispatch, currentCampaignId])

  return (
    <CenterPanel className="flex flex-col overflow-auto">
      {isLoading ? (
        <Loader className="m-auto" />
      ) : (
        <>
          {!selectedComponentElement?.personalization_id && (
            <>
              <CurrentUsersBanner />
              <PageDetails
                title="Results"
                description="View your results based on inputs and dynamic optimization"
                className="pb-8"
              />
              <CampaignSummary
                updatedCampaignId={currentCampaignId}
                key={currentCampaignId}
                shouldFetchOnMount={false}
                onRedirect={() => {
                  history.push(
                    generatePath(unifiedFlowPaths.review, {
                      contentId: content?._id,
                    })
                  )
                }}
              />
            </>
          )}
          {selectedComponentElement?.personalization_id && (
            <>
              <PageDetails
                title="Results"
                description="View your results based on inputs"
                className="pb-8"
              />
              <PersonalizationSummary />
            </>
          )}
        </>
      )}
    </CenterPanel>
  )
}

export default CenterSection
