import { ChangeEvent, FC } from 'react'

import Input from 'common/components/input'

type Props = {
  value: string
  onChange: (value: string) => void
  'data-cy': string
  'data-testid': string
}

const SearchInput: FC<Props> = ({
  value,
  onChange,
  'data-cy': dataCy,
  'data-testid': dataTestId,
}) => {
  return (
    <Input
      type="search"
      value={value}
      placeholder="Search"
      data-cy={dataCy}
      data-testid={dataTestId}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.value)
      }
      clear={() => onChange('')}
      className={`h-10 w-full px-12 py-2 text-sm text-coolGray-800 
        placeholder-sm placeholder-coolGray-800 placeholder-opacity-50 
        border border-blueGray-300 bg-white focus:outline-none 
        focus:text-maroon-200 focus:placeholder-maroon-200 
        focus:border-maroon-400 focus:bg-maroon-200 focus:bg-opacity-10`}
    />
  )
}

export default SearchInput
