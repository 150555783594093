import Button from 'common/components/button/Button'
import { ArrowRight } from 'common/icons'

const UpgradeAccountComponent = () => {
  return (
    <div
      data-cy="upgrade-account-widget"
      data-testid="upgrade-account-widget"
      className="max-h-full p-6 box-border shadow-widget bg-gold-100 mb-6"
    >
      <div className="flex flex-col justify-between items-start gap-4">
        <div className="font-relais font-semibold text-2xl text-base-700">
          Get started with Optimisation
        </div>

        <div className="font-normal text-xs text-base-700">
          Create experiments that benefit your customer and drive conversion.
        </div>

        <Button
          variant="primary"
          size="small"
          data-cy="upgrade-account-project-button"
          data-testid="upgrade-account-project-button"
          onClick={() => window.open('https://jacquard.com/pricing/')}
          suffixIcon={<ArrowRight size={4.5} isDefaultColor={false} />}
          className="uppercase"
        >
          Upgrade now
        </Button>
      </div>
    </div>
  )
}

export default UpgradeAccountComponent
