import { ComponentType, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js'
import { RouteProps, StaticContext } from 'react-router'
import { match, Redirect, Route } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import App from 'app/App'
import {
  isAtLeastOneAccessFlagEnabled,
  isAtLeastOneDenyFlagEnabled,
} from 'app/navigation/helpers'
import { getIsUserTypeAuthorized } from 'app/router/routesUtils'
import { History, Location } from 'history'
import jwt_decode from 'jwt-decode'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { canAccessAccount, getIsAuthorized } from 'common/auth/permissions'
import tokenFetcher from 'common/auth/tokenFetcher'
import Loader from 'common/components/loaders/Loader'
import { errorToast } from 'common/components/toastNotification'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  callbackOAuth,
  setPermissions,
  UserType,
} from 'features/auth/store/authSlice'

interface GenericComponent {
  history: History<unknown>
  location: Location<unknown>
  match: match<{
    [x: string]: string | undefined
  }>
  staticContext?: StaticContext
}

interface Props extends RouteProps {
  onlyDisplayComponent?: boolean
  component: ComponentType<GenericComponent>
  accessFlags?: string[]
  denyFlags?: string[]
  accessLevel?: UserType
  accessPermission?: string
  shouldValidateAccountAccess?: boolean
}

const ProtectedRoute: (props: Props) => JSX.Element = ({
  component: Component,
  onlyDisplayComponent,
  accessFlags,
  denyFlags,
  accessPermission,
  shouldValidateAccountAccess,
  accessLevel,
  ...rest
}) => {
  const {
    isAuthenticated,
    isLoading,
    logout,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  } = useAuth0()

  const onIdle = () => {
    if (isAuthenticated) {
      logout({
        returnTo: `${window.location.origin}/`,
      })
    }
  }

  const dispatch = useAppDispatch()
  const flags = useFlags()

  const IDLE_TIMEOUT_IN_MIN = flags.idleTimeInMinutes
  useIdleTimer({
    onIdle,
    timeout:
      typeof IDLE_TIMEOUT_IN_MIN === 'number'
        ? IDLE_TIMEOUT_IN_MIN * 60 * 1000
        : undefined,
    throttle: 500,
  })

  const { rolesAndPermissions } = flags
  const {
    isLoading: isAuthLoading,
    permissions,
    userType,
    accountCategory,
  } = useAppSelector((state) => state.authStates)

  const [token, setToken] = useState<string>()

  const isUserLoggedIn = isAuthenticated && !isLoading && !!token
  const isAuthorized = flags.rolesAndPermissions
    ? getIsAuthorized(permissions, accessPermission)
    : accessLevel
    ? getIsUserTypeAuthorized(userType, accessLevel)
    : true
  const hasAccountAccess =
    accessPermission && shouldValidateAccountAccess
      ? canAccessAccount(accountCategory, accessPermission)
      : true

  const isEnabled =
    isAtLeastOneAccessFlagEnabled(flags, accessFlags) &&
    !isAtLeastOneDenyFlagEnabled(flags, denyFlags) &&
    isAuthorized &&
    hasAccountAccess

  useEffect(() => {
    tokenFetcher.setTokenFetcher(getAccessTokenSilently)
  }, [getAccessTokenSilently])

  useEffect(() => {
    if (token && rolesAndPermissions) {
      const decoded: { permissions: string[] } = jwt_decode(token)
      dispatch(setPermissions(decoded.permissions))
    }
  }, [token, rolesAndPermissions, dispatch])

  /* 
    enforce authentication for accessing a protected route;
    run only if user's auth session is expired;
  */
  useEffect(() => {
    const shouldForceAuthentication: boolean = !(isAuthenticated || isLoading)

    const login = async (): Promise<void> => {
      try {
        await loginWithRedirect({
          appState: {
            returnTo: `${window.location.pathname}${window.location.search}`,
          },
          assetsUrl: `${window.location.origin}`,
        })
      } catch (error: any) {
        errorToast(error.message, { autoClose: 10000 })
      }
    }

    if (shouldForceAuthentication) {
      login()
    }
  }, [isAuthenticated, isLoading, loginWithRedirect])

  /*
    set token and user info to state;
    run only when user has an active auth session,
    but there's no valid token or user info stored in memory,
    e.g. after app refresh;
  */
  useEffect(() => {
    const shouldFetchTokenAfterAuth: boolean =
      isAuthenticated && !isLoading && !!user && !token

    const getAccessToken = async (): Promise<void> => {
      try {
        const accessToken: string = await getAccessTokenSilently()

        setToken(accessToken)
      } catch (error: any) {
        errorToast(error.message, { autoClose: 10000 })
      }
    }

    if (shouldFetchTokenAfterAuth) {
      getAccessToken()
    }

    if (isUserLoggedIn) {
      dispatch(callbackOAuth())
    }
  }, [
    dispatch,
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    isUserLoggedIn,
    user,
    token,
  ])

  return (
    <Route
      {...rest}
      render={(props) => {
        return isAuthLoading ? (
          <Loader isFullPage />
        ) : !isEnabled ? (
          <Redirect
            to={{
              pathname: '/404',
            }}
          />
        ) : onlyDisplayComponent ? (
          <Component {...props} />
        ) : (
          <App>
            <Component {...props} />
          </App>
        )
      }}
    />
  )
}

export default ProtectedRoute
