import React from 'react'

import Button from 'common/components/button/Button'

export interface Props {
  name?: string
  integrationType: string
  onSubmit: () => void
  onDisconnect: () => void
}

export default function Login({
  name,
  integrationType,
  onSubmit,
  onDisconnect,
}: Props) {
  return (
    <div className="mt-4 mb-4">
      <div className="font-medium mb-4">
        {name ? `Welcome ${name}` : 'Sign in'}
      </div>
      <div className="mb-4">
        {!name ? (
          `You will need your ${integrationType} connect account
           details to sign in and connect Jacquard to ${integrationType}`
        ) : (
          <>
            {'Not you? '}
            <Button
              data-cy="login-click-here-button"
              data-testid="login-click-here-button"
              className="pr-0 pl-0"
              variant="link"
              onClick={() => onDisconnect()}
            >
              Click here
            </Button>
            {' to sign in'}
          </>
        )}
      </div>
      {!name && (
        <div className="content-start flex flex-wrap">
          <Button
            data-cy="login-button"
            data-testid="login-button"
            variant="primary"
            loading={false}
            onClick={() => onSubmit()}
            className="mr-auto"
          >
            Sign in
          </Button>
        </div>
      )}
    </div>
  )
}
