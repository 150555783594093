import { FC, useState } from 'react'
import { showBanner } from 'workflow/common/common.actions'

import Button from 'common/components/button/Button'
import { useAppDispatch } from 'common/hooks/redux'
import { Bin } from 'common/icons'

import { generateToken, Token } from './api'
import InputForm from './Input'

interface Props {
  accountId: string
  onNewTokenCreated: (val: Token) => void
  onClearClick: () => void
}

export const generateTokenText = {
  tokenNameLabel: 'Token name',
  generateButton: 'Generate',
  successGenerate: 'Token generated successfully',
  errorGenerate: 'Failed to generate token',
}

const GenerateToken: FC<Props> = ({
  accountId,
  onNewTokenCreated,
  onClearClick,
}) => {
  const dispatch = useAppDispatch()

  const [name, setName] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onCreate = () => {
    setIsLoading(true)

    generateToken({ name, accountId })
      .then(({ data }) => {
        const { _id, customer_identifier, expires, token } = data
        onNewTokenCreated({
          id: _id,
          expires,
          name: customer_identifier,
          token,
        })
        dispatch(
          showBanner({
            content: generateTokenText.successGenerate,
            type: 'success',
          })
        )
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
        return dispatch(
          showBanner({
            content: generateTokenText.errorGenerate,
            type: 'error',
          })
        )
      })
  }

  return (
    <div>
      <form
        className="flex items-end"
        onSubmit={(e) => {
          e.preventDefault()
          onCreate()
        }}
      >
        <InputForm
          width={350}
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={generateTokenText.tokenNameLabel}
          isDisabled={isLoading}
        />
        <Button
          className="ml-4 h-10"
          data-cy="generate-token-button"
          data-testid="generate-token-button"
          variant="primary"
          onClick={() => onCreate()}
          disabled={name.trim().length === 0 || isLoading}
          loading={isLoading}
        >
          {generateTokenText.generateButton}
        </Button>
        <Button
          aria-label="Clear"
          className="ml-6 h-10"
          variant="icon"
          data-cy="generate-token-clear"
          data-testid="generate-token-clear"
          disabled={isLoading}
          onClick={() => {
            onClearClick()
            setName('')
          }}
        >
          <Bin />
        </Button>
      </form>
    </div>
  )
}

export default GenerateToken
