import { useEffect, useRef, useState } from 'react'

const VARIANT_SUCCESS_TIMEOUT = 2000

const useTweakSuccess = () => {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined)
  const [tweakSuccessVariantIds, setTweakSuccessVariantIds] = useState<
    string[]
  >([])

  const onTweakSuccess = (variantId: string) => {
    setTweakSuccessVariantIds((prev) => [...prev, variantId])
    timeoutRef.current = setTimeout(() => {
      setTweakSuccessVariantIds((prev) => prev.filter((id) => id !== variantId))
    }, VARIANT_SUCCESS_TIMEOUT)
  }

  useEffect(() => {
    const timeoutCurrent = timeoutRef.current
    return () => {
      if (timeoutCurrent) {
        clearTimeout(timeoutCurrent)
      }
    }
  }, [])

  return { tweakSuccessVariantIds, onTweakSuccess }
}

export default useTweakSuccess
