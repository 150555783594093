import { useFlags } from 'launchdarkly-react-client-sdk'

import type { Props } from './GuidingText'
import GuidingText from './GuidingText'
import GuidingTextDefault from './GuidingTextDefault'

const GuidingTextPerFlag = (props: Props) => {
  const flags = useFlags()

  return flags?.showL2Components ? (
    <GuidingText {...props} />
  ) : (
    <GuidingTextDefault {...props} />
  )
}

export default GuidingTextPerFlag
