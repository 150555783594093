import {
  connectInternalApi,
  getRegionalizedApiPerRegion,
  regionalReportingApis,
} from 'common/api'

export type PersonalizationRegion = 'EU' | 'US' | 'UK'

export type VariantsResponse = {
  clickRate: string
  clicks: number
  opens: number
  sends: number
  openRate: string
  variantText: string
  variantStatus: string
  variantId: string
  clickUplift: string
  machineOpens: number
  openRateExcludedMachineOpens: string
  openUpliftMachineOpensExcluded: string
  openUplift: string
  isHoldout?: boolean
}[]

export const fetchVariants = async ({
  accountId,
  personalizationId,
  personalizationRegion,
  resultType,
}: {
  accountId: string
  personalizationId: string | undefined
  personalizationRegion: PersonalizationRegion | undefined
  resultType: 'mature' | 'raw'
}) => {
  if (!personalizationRegion) {
    throw new Error('Region is not defined')
  }

  if (!accountId) {
    throw new Error('Account id is not defined for this content')
  }

  if (!personalizationId) {
    throw new Error('Personalization id is not defined')
  }

  const result = personalizationRegion
    ? await regionalReportingApis[personalizationRegion].post<{
        items: VariantsResponse
      }>(
        `/accounts/${accountId}/personalization/${personalizationId}/results/variants`,
        { ...(resultType === 'mature' ? { mature_only: 'false' } : {}) }
      )
    : await connectInternalApi.post<{
        items: VariantsResponse
      }>(
        `v1/accounts/${accountId}/personalization/${personalizationId}/results/variants`,
        { ...(resultType === 'mature' ? { mature_only: 'false' } : {}) }
      )

  return result.data.items
}

export const updateVariantStatus = async ({
  accountId,
  personalizationId,
  combinationId,
  variantId,
  status,
  personalizationRegion,
  isBaselineVariant,
}: {
  accountId: string
  personalizationId: string
  combinationId: string | undefined
  variantId: string
  status: string
  personalizationRegion: PersonalizationRegion | undefined
  isBaselineVariant: boolean
}) => {
  if (!personalizationRegion) {
    throw new Error('Region is not defined')
  }

  if (!accountId) {
    throw new Error('Account id is not defined for this content')
  }

  if (!combinationId && !isBaselineVariant) {
    throw new Error('Variant to update could not be found')
  }

  const statusKeyMap = {
    approved: 'approve_variant_ids',
    dropped: 'drop_variant_ids',
    live: 'live_variant_ids',
  }

  if (!statusKeyMap[status]) {
    throw new Error('Invalid status')
  }

  const statusKey = statusKeyMap[status]

  const approvalURLPath = isBaselineVariant
    ? `accounts/${accountId}/personalization/${personalizationId}/language/approval`
    : `accounts/${accountId}/personalization/${personalizationId}/combinations/${combinationId}/approval`

  const response = await getRegionalizedApiPerRegion(
    personalizationRegion.toLowerCase()
  ).post(approvalURLPath, {
    [statusKey]: [variantId],
  })

  return response.data
}
