import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'

import ActionIcon from 'common/components/ActionIcon'
import Button from 'common/components/button/Button'
import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import Loader from 'common/components/loaders/Loader'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import SortMenu from 'common/components/sortMenu'
import {
  sortByOptions,
  SortOptionsValue,
} from 'common/components/sortMenu/SortMenu'
import Tabs, { addCountToTabName, Tab } from 'common/components/tabs'
import Tooltip from 'common/components/Tooltip'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useLocalStorage } from 'common/hooks/useLocalStorage'
import { Grid, ListIcon, MoreOptions } from 'common/icons'
import { ReactComponent as AddDefault } from 'common/icons/add/default.svg'
import {
  hideTemplates,
  showTemplates,
} from 'features/futurama/store/contentSlice'

import useDeleteContentMutation from '../api/mutations/useDeleteContentMutation'
import useGetContentsQuery from '../api/queries/useGetContentsQuery'
import MainContent from '../components/MainContent'

import ContentGrid from './components/ContentGrid'
import DeleteConfirmationModal from './components/DeleteConfirmationModal'
import FuturamaAutocomplete from './components/FuturamaAutocomplete'
import Templates from './components/Templates'
import ContentTable from './ContentTable'
import EmptyState from './EmptyState'
import { ContentInfo, ContentTypesByTab } from './interfaces'

export const CONTENTS_PAGE_VIEW_KEY = `contents-page-view`

export const contentTypesByTab: ContentTypesByTab = {
  all: [],
  email: [
    'marketing email',
    'email headline',
    'email pre-header',
    'email cta',
    'email subject line',
    'cold email',
  ],
  social: ['facebook ad', 'instagram ad'],
  messaging: ['push title', 'push body', 'sms'],
  articles: [
    'heading',
    'section titles',
    'section paragraphs',
    'bullet points',
    'article',
  ],
  web: ['website call to action', 'website headline', 'website banner'],
  ads: ['google responsive search ad', 'google responsive display ad'],
  ecommerce: ['product description', 'product features'],
}

const ContentsPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory<{ templateTab?: string; contentTab?: string }>()
  const { location } = history
  useDocumentTitle('Content Library | Jacquard')

  const { data: contentData = [], status } = useGetContentsQuery()

  const isNewContent = useAppSelector((state) => state.content.showTemplateView)

  const [selectedContentTab, setSelectedContentTab] = useState('all')
  const [selectedTemplateTab, setSelectedTemplateTab] = useState<string>('all')

  const [searchContentValue, setSearchContentValue] = useState<string>('')
  const [sortOptions, setSortOptions] = useState<SortOptionsValue>({
    property: 'modifiedDate',
    isAscending: false,
  })

  const [isTableView, setIsTableView] = useLocalStorage(
    CONTENTS_PAGE_VIEW_KEY,
    false
  )

  const deleteContentMutation = useDeleteContentMutation()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const [contentIdToDelete, setContentIdToDelete] = useState<string>('')

  const closeModal = () => setContentIdToDelete('')

  useEffect(() => {
    const { templateTab, contentTab } = location.state ?? {}

    if (templateTab) {
      dispatch(showTemplates())
      setSelectedTemplateTab(templateTab)
    }

    if (contentTab) {
      setSelectedContentTab(contentTab)
    }
  }, [location, dispatch])

  const filterByName = useCallback(
    ({ name }: ContentInfo) =>
      name
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(searchContentValue.replaceAll(' ', '').toLowerCase()),
    [searchContentValue]
  )

  const filterByContentType = useCallback(
    ({ contentType }: ContentInfo) => {
      return (
        selectedContentTab === 'all' ||
        selectedContentTab.toLowerCase() ===
          (contentType ? contentType.toLowerCase() : '')
      )
    },
    [selectedContentTab]
  )

  const contentFiltered = useMemo(() => {
    const filtredData = contentData
      .filter(filterByContentType)
      .filter(filterByName)

    const sortedData = sortByOptions(filtredData, sortOptions)

    return sortedData
  }, [contentData, filterByContentType, filterByName, sortOptions])

  const generateTabContent = useCallback(
    (content: ContentInfo[]) => {
      if (content.length === 0) {
        if (searchContentValue !== '') {
          return (
            <div className="text-5xl font-bold text-coolGray-800 leading-normal">
              There are no content items matching your search, please try again
            </div>
          )
        }
        return (
          <div className="text-5xl font-bold text-coolGray-800 leading-normal">
            There is no saved content for this category
          </div>
        )
      } else if (isTableView) {
        return (
          <ContentTable
            content={content}
            isLoading={false}
            hasError={false}
            onDelete={setContentIdToDelete}
          />
        )
      } else {
        return (
          <ContentGrid
            contents={contentFiltered}
            onDelete={setContentIdToDelete}
          />
        )
      }
    },
    [isTableView, contentFiltered, searchContentValue]
  )

  const tabs: Tab[] = [
    {
      key: 'all',
      name: addCountToTabName({
        tabName: 'All',
        count: contentData.length,
      }),
      content: generateTabContent(contentFiltered),
      isDisabled: false,
    },
    ...Object.keys(contentTypesByTab)
      .filter((category) => category !== 'all')
      .map((category) => ({
        key: category,
        name: addCountToTabName({
          tabName: category,
          count:
            contentData.filter((item) => {
              return (
                category === 'all' ||
                category.toLowerCase() ===
                  (item.contentType ? item.contentType.toLowerCase() : '')
              )
            }).length || 0,
        }),
        content: generateTabContent(contentFiltered),
        isDisabled: false,
      })),
  ]

  const getState = () => {
    if (status === 'loading') {
      return 'loading'
    } else if (status === 'error') {
      return 'error'
    } else if (status === 'success' && contentData.length === 0) {
      return 'empty'
    } else {
      return 'success'
    }
  }

  const state = getState()
  return (
    <>
      <MainContent className="lg:px-40 px-6 pt-6 ">
        {
          {
            empty: <EmptyState />,
            success: (
              <div className="flex flex-col w-full h-full bg-gold-40 max-w-screen-lg sm:mx-auto">
                <PageHeader>
                  <PageTitle title="Content" />
                </PageHeader>
                <div className="flex justify-between items-stretch relative">
                  <Tabs
                    data-testid="content-tabs"
                    className="w-full"
                    tabs={tabs.filter((tab) => !tab.isDisabled)}
                    activeKey={selectedContentTab}
                    onTabClick={(tab) => {
                      setSelectedContentTab(tab)
                    }}
                    variant="filter"
                    moreIcon={<MoreOptions />}
                  />

                  <div
                    className={cx(
                      'flex absolute right-0 items-center sm:top-0'
                    )}
                  >
                    <ActionIcon
                      aria-label="Toggle view button"
                      onClick={() => setIsTableView(!isTableView)}
                    >
                      {isTableView ? (
                        <Tooltip overlay="Tile view">
                          <Grid />
                        </Tooltip>
                      ) : (
                        <Tooltip overlay="List view">
                          <ListIcon />
                        </Tooltip>
                      )}
                    </ActionIcon>
                    <Tooltip overlay="Sort">
                      <SortMenu
                        className="text-base pt-3"
                        onSortClick={setSortOptions}
                      />
                    </Tooltip>
                    <Button
                      data-cy="add-content-button"
                      data-testid="add-content-button"
                      aria-label="Create content"
                      variant="primary"
                      onClick={() => dispatch(showTemplates())}
                      className="text-base"
                      prefixIcon={<AddDefault width={24} height={24} />}
                    >
                      Content
                    </Button>
                    <FuturamaAutocomplete
                      onSearchContentValue={setSearchContentValue}
                      data-cy="content-search"
                      data-testid="content-search"
                      aria-label="content search"
                      options={contentData.map((content: ContentInfo) => ({
                        value: content.id,
                        label: content.name,
                      }))}
                    />
                  </div>
                </div>
                <Footer />
              </div>
            ),
            loading: (
              <div className="w-full">
                <Loader data-testid="contents-loader" />
              </div>
            ),
            error: <ErrorPage />,
          }[state]
        }
      </MainContent>
      <Templates
        isVisible={isNewContent}
        onClose={() => dispatch(hideTemplates())}
        intialSelectedTab={selectedTemplateTab}
      />
      {contentIdToDelete && (
        <DeleteConfirmationModal
          onCancel={closeModal}
          onConfirm={() => {
            deleteContentMutation.mutate({
              accountId,
              contentId: contentIdToDelete,
            })
            closeModal()
          }}
        />
      )}
    </>
  )
}

export default ContentsPage
