import { IntegrationAccount } from '@phrasee/phrasee-typings'
import { IntegrationRouting } from '@phrasee/phrasee-typings/typings/integrations/routing'
import keys from 'lodash/keys'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import uniq from 'lodash/uniq'

export const getAvailableIntegrations = (
  data: IntegrationRouting[]
): string[] => {
  return uniq(
    data.reduce<string[]>((acc, { componentId, name, ...rest }) => {
      const values = mapValues(rest, (value) => !!value)
      const filteredValues = pickBy(values, (value) => value)

      return [...acc, ...keys(filteredValues)]
    }, [])
  )
}

export const getSelectedIntegrationAccount = (
  accounts: IntegrationAccount[] | undefined,
  availableIntegrations: string[] | undefined,
  elementIntegrationAccountId: string | undefined
) => {
  return (
    accounts?.find((account) => account.id === elementIntegrationAccountId) ||
    accounts?.find(({ provider }) => availableIntegrations?.includes(provider))
  )
}
