import { DistributionType } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { formatIsoDate } from 'common/helpers/date'
import { changeAccountId } from 'features/auth/store/authSlice'

import { Campaign } from '../components/CampaignSelector'
import { ChannelOption, WidgetListResponse } from '../performance/api'

export interface ReportsState {
  product?: DistributionType
  channel?: ChannelOption
  projects: string[]
  campaigns: string[] // TODO: Rename to selectedCampaignIds
  campaignList?: Campaign[]
  startDate?: string
  endDate?: string
  widgetList?: WidgetListResponse
  error?: boolean
}

const initialState: ReportsState = {
  projects: [],
  campaigns: [],
  error: false,
}

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    applyFiltersAndData: (state, action: PayloadAction<ReportsState>) => {
      state.product = action.payload.product
      state.channel = action.payload.channel
      state.projects = action.payload.projects
      state.campaigns = action.payload.campaigns
      state.startDate = formatIsoDate(action.payload.startDate)
      state.endDate = formatIsoDate(action.payload.endDate)
      state.widgetList = action.payload.widgetList
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload
    },
    setCampaignList: (state, action: PayloadAction<Campaign[]>) => {
      state.campaignList = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, () => initialState)
  },
})

export const { applyFiltersAndData, setError, setCampaignList } =
  reportsSlice.actions

export default reportsSlice.reducer
