import React, { useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { projectSettingsPath } from 'app/navigation/paths'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Loader from 'common/components/loaders/Loader'
import SubNav, { Count, Error } from 'common/components/subNav'
import SearchInput from 'common/components/subNav/components/searchInput'
import Tabs, { Tab } from 'common/components/tabs'
import { useAppSelector } from 'common/hooks/redux'
import { Project } from 'features/projects/interface'

import useProjectsQuery from '../queries/useProjectsQuery'

import Link from './link'

type ProjectsFilter = 'active' | 'deactivated' | 'archived'
const DEFAULT_STATUS_FILTER: ProjectsFilter = 'active'

const filterProjectsByStatus = (
  projects: Project[],
  status: ProjectsFilter
): Project[] => {
  switch (status) {
    case 'active':
      return projects?.filter(
        ({ isDeleted, isArchived }) => !isDeleted && !isArchived
      )
    case 'deactivated':
      return projects?.filter(({ isDeleted }) => isDeleted)
    case 'archived':
      return projects?.filter(({ isArchived }) => isArchived)
  }
}

const filterProjectsBySearch = (
  projects: Project[],
  searchValue: string
): Project[] =>
  projects.filter(({ name }) =>
    name.toLowerCase().includes(searchValue.toLowerCase())
  )

const ProjectNav = () => {
  const flags = useFlags()
  const { pathname } = useLocation()
  const match = matchPath<{
    projectId?: string
  }>(pathname, {
    path: projectSettingsPath,
  })

  const accountId = useAppSelector((state) => state.authStates.accountId)
  const projectsQuery = useProjectsQuery(accountId)

  const [searchValue, setSearchValue] = useState<string>('')
  const [statusFilter, setStatusFilter] = useState<ProjectsFilter>(
    DEFAULT_STATUS_FILTER
  )

  const filteredProjects =
    projectsQuery.data &&
    filterProjectsBySearch(
      filterProjectsByStatus(projectsQuery.data, statusFilter),
      searchValue
    )

  const projectsCount = filteredProjects?.length
  const selectedProjectId = match?.params.projectId
  const hasData = projectsCount && projectsQuery.status === 'success'
  const maxHeight = 'calc(100vh - 210px)'

  const TabContent = (
    <>
      {hasData ? (
        <nav className="overflow-y-auto" style={{ maxHeight: maxHeight }}>
          {filteredProjects?.map((project) => (
            <Link
              key={project.id}
              data={project}
              isSelected={project.id === selectedProjectId}
            />
          ))}
        </nav>
      ) : (
        <div className="p-4">No projects</div>
      )}
    </>
  )

  let tabs: Tab<ProjectsFilter>[] = [
    { key: 'active', name: 'Active', content: TabContent },
    { key: 'deactivated', name: 'Deactivated', content: TabContent },
  ]

  if (flags.projectDetailsPanel) {
    tabs = [...tabs, { key: 'archived', name: 'Archived', content: TabContent }]
  }

  return (
    <SubNav>
      {
        {
          loading: <Loader />,
          success: (
            <>
              <div className="pt-5 px-5">
                <Count
                  className="mb-4"
                  value={projectsCount as number}
                  name="project"
                />
                <SearchInput
                  data-cy="projects-search"
                  data-testid="projects-search"
                  value={searchValue}
                  onChange={setSearchValue}
                />
              </div>
              <Tabs<ProjectsFilter>
                variant="inPage"
                hasContentMargin={false}
                defaultActiveKey={DEFAULT_STATUS_FILTER}
                onTabClick={setStatusFilter}
                moreIcon={null}
                tabs={tabs}
              />
            </>
          ),
          error: <Error name="project" />,
        }[projectsQuery.status]
      }
    </SubNav>
  )
}

export default React.memo(ProjectNav)
