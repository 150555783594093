import moment from 'moment'

import type { DefaultOption } from 'common/components/dateRange/interfaces'

export const defaultOptions: DefaultOption[] = [
  {
    id: '3-months',
    title: 'Last 3 months',
    start: moment().subtract(3, 'months'),
    end: moment(),
  },
  {
    id: '6-months',
    title: 'Last 6 months',
    start: moment().subtract(6, 'months'),
    end: moment(),
  },
  {
    id: '12-months',
    title: 'Last 12 months',
    start: moment().subtract(12, 'months'),
    end: moment(),
  },
]

export const options: DefaultOption[] = [
  {
    id: '3-months',
    title: '3M',
    start: moment().subtract(3, 'months'),
    end: moment(),
  },
  {
    id: '6-months',
    title: '6M',
    start: moment().subtract(6, 'months'),
    end: moment(),
  },
  {
    id: '12-months',
    title: '12M',
    start: moment().subtract(12, 'months'),
    end: moment(),
  },
]
