import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'

import { contentPermissionsIds, getIsAuthorized } from 'common/auth/permissions'
import Button from 'common/components/button/Button'
import ConfirmationModal from 'common/components/confirmationModal/ConfirmationModal'
import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import Loader from 'common/components/loaders/Loader'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ReactComponent as AddDefault } from 'common/icons/add/default.svg'
import {
  applyContentLibraryFilters,
  hideTemplates,
  showTemplates,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import useDeleteContentMutation from '../api/mutations/useDeleteContentMutation'
import useGetContentLibraryQuery, {
  ContentLibraryEntry,
} from '../api/queries/useGetContentLibraryQuery'
import MainContent from '../components/MainContent'
import Searchbox from '../components/Searchbox'
import TemplateSelection from '../components/templateSelection'

import DeleteConfirmationModal from './components/DeleteConfirmationModal'
import Filters from './components/Filters'
import ContentLibraryTable from './ContentLibraryTable'
import EmptyState from './EmptyState'

import styles from './ContentsPage.module.css'

export const CONTENTS_PAGE_VIEW_KEY = `unified-flow-page-view`

const ContentsPage = () => {
  const dispatch = useAppDispatch()
  const history = useHistory<{ templateTab?: string; contentTab?: string }>()
  const isNewContent = useAppSelector(
    (state) => state.unifiedFlow.showTemplateView
  )
  useDocumentTitle(
    `${isNewContent ? 'Templates' : 'Content Library'} | Jacquard`
  )

  const { location } = history

  const { data: contentData = [], status } = useGetContentLibraryQuery()

  const projects = [
    ...new Set(
      contentData
        .map<string[]>((content) =>
          content.subRows.map((element) => element.project)
        )
        .flat(2)
        .filter((project) => project.trim().length > 0)
    ),
  ].sort((a, b) => a.localeCompare(b))

  const owners = [
    ...new Set(
      contentData
        .map<string[]>((content) =>
          content.subRows.map((element) => element.owner)
        )
        .flat(2)
        .filter((owner) => owner.trim().length > 0)
    ),
  ].sort((a, b) => a.localeCompare(b))

  const {
    selectedProjects,
    selectedOwners,
    selectedTypes,
    selectedTemplates,
    selectedStatuses,
  } = useAppSelector((state) => state.unifiedFlow.contentLibraryFilters)

  const { permissions } = useAppSelector((state) => state.authStates)
  const canViewId = getIsAuthorized(permissions, contentPermissionsIds.view)

  const templatesToAdd = useAppSelector(
    (state) => state.unifiedFlow.templatesToAdd
  )

  const [selectedContentTab, setSelectedContentTab] = useState('all')
  const [selectedTemplateTab, setSelectedTemplateTab] = useState<string>('all')

  const [searchContentValue, setSearchContentValue] = useState<string>('')

  const [isConfirmTemplateModalShown, setIsConfirmTemplateModalShown] =
    useState(false)

  const deleteContentMutation = useDeleteContentMutation()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const [contentIdToDelete, setContentIdToDelete] = useState<string>('')

  const closeModal = () => setContentIdToDelete('')

  useEffect(() => {
    const { templateTab, contentTab } = location.state ?? {}

    if (templateTab) {
      dispatch(showTemplates())
      setSelectedTemplateTab(templateTab)
    }

    if (contentTab) {
      setSelectedContentTab(contentTab)
    }
  }, [location, dispatch])
  const searchOptions = useMemo(
    () => [
      ...contentData.map((content: ContentLibraryEntry) => ({
        value: content.id,
        label: content.name,
      })),
      ...(canViewId
        ? contentData.map((content: ContentLibraryEntry) => ({
            value: content.id,
            label: content.id,
          }))
        : []),
    ],
    [canViewId, contentData]
  )

  const filterByNameOrId = useCallback(
    ({ name, id }) => {
      const normalizedSearchValue = searchContentValue
        .replaceAll(' ', '')
        .toLowerCase()
      const matchesName = name
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(normalizedSearchValue)
      const matchesId =
        canViewId && id.toLowerCase().includes(normalizedSearchValue)
      return matchesName || matchesId
    },
    [canViewId, searchContentValue]
  )

  const filterByContentType = useCallback(
    ({ contentType }) => {
      return (
        selectedContentTab === 'all' ||
        selectedContentTab.toLowerCase() ===
          (contentType ? contentType.toLowerCase() : '')
      )
    },
    [selectedContentTab]
  )
  const selectedProjectsSet = useMemo(
    () => new Set(selectedProjects),
    [selectedProjects]
  )

  const filterByProject = useCallback(
    ({ subRows }: ContentLibraryEntry) =>
      selectedProjectsSet.size === 0
        ? true
        : subRows
            .map((row) => row.project)
            .some((project) => selectedProjectsSet.has(project)),
    [selectedProjectsSet]
  )
  const filterByType = useCallback(
    ({ subRows }: ContentLibraryEntry) =>
      selectedTypes.length === 0
        ? true
        : subRows
            .map((row) => row.experimentType)
            .some((type) =>
              selectedTypes
                .map((type) => type.toLowerCase())
                .includes(type.toLowerCase())
            ),
    [selectedTypes]
  )

  const filterByStatus = useCallback(
    ({ subRows }: ContentLibraryEntry) =>
      selectedStatuses.length === 0
        ? true
        : subRows
            .map((row) => row.status)
            .some((status) =>
              selectedStatuses
                .map((status) => status.toLowerCase())
                .includes(status.toLowerCase())
            ),
    [selectedStatuses]
  )

  const filterByOwner = useCallback(
    ({ subRows }: ContentLibraryEntry) =>
      selectedOwners.length === 0
        ? true
        : subRows
            .map((row) => row.owner)
            .some((owner) =>
              selectedOwners
                .map((owner) => owner.toLowerCase())
                .includes(owner.toLowerCase())
            ),
    [selectedOwners]
  )
  const filterByTemplate = useCallback(
    ({ subRows }: ContentLibraryEntry) =>
      selectedTemplates.length === 0
        ? true
        : subRows
            .map((row) => row.template)
            .some((template) =>
              selectedTemplates
                .map((template) => template.toLowerCase())
                .includes(template.toLowerCase())
            ),
    [selectedTemplates]
  )
  const contentFiltered = useMemo(() => {
    const filteredData = contentData
      .filter(filterByContentType)
      .filter(filterByNameOrId)
      .filter(filterByProject)
      .filter(filterByType)
      .filter(filterByStatus)
      .filter(filterByOwner)
      .filter(filterByTemplate)

    return filteredData
  }, [
    contentData,
    filterByContentType,
    filterByNameOrId,
    filterByOwner,
    filterByProject,
    filterByStatus,
    filterByTemplate,
    filterByType,
  ])

  const content = useCallback(
    (content: ContentLibraryEntry[]) => {
      if (content.length === 0) {
        if (searchContentValue !== '') {
          return (
            <div className="text-5xl font-bold text-coolGray-800 leading-normal">
              There are no content items matching your search, please try again
            </div>
          )
        }
        return (
          <div className="text-5xl font-bold text-coolGray-800 leading-normal">
            There are no results matching that search.
          </div>
        )
      } else {
        return (
          <ContentLibraryTable
            content={contentFiltered}
            onDelete={setContentIdToDelete}
          />
        )
      }
    },
    [searchContentValue, contentFiltered]
  )

  const getState = () => {
    if (status === 'loading') {
      return 'loading'
    } else if (status === 'error') {
      return 'error'
    } else if (status === 'success' && contentData.length === 0) {
      return 'empty'
    } else {
      return 'success'
    }
  }

  const handleTemplateSelectionClose = () => {
    if (templatesToAdd.length > 0) {
      setIsConfirmTemplateModalShown(true)
    } else {
      dispatch(hideTemplates())
    }
  }

  const state = getState()

  return (
    <>
      <MainContent className="lg:px-40 px-6 pt-6 ">
        {
          {
            empty: <EmptyState />,
            success: (
              <div className="flex flex-col w-full h-full max-w-screen-lg sm:mx-auto">
                <PageHeader className="mb-4">
                  <PageTitle
                    className="text-4xl font-medium text-base-700"
                    title="Content Library"
                  />
                </PageHeader>

                <div className="flex justify-between items-stretch relative">
                  <div className={cx('w-full', styles.contentPageTabs)}>
                    <div className="mb-8 flex gap-4 justify-between items-start">
                      <Filters
                        projects={projects}
                        owners={owners}
                        selectedOwners={selectedOwners}
                        selectedProjects={selectedProjects}
                        selectedTypes={selectedTypes}
                        selectedStatuses={selectedStatuses}
                        selectedTemplates={selectedTemplates}
                        onChangeOwner={(value) => {
                          dispatch(
                            applyContentLibraryFilters({
                              selectedOwners: value,
                            })
                          )
                        }}
                        onChangeProject={(value) => {
                          dispatch(
                            applyContentLibraryFilters({
                              selectedProjects: value,
                            })
                          )
                        }}
                        onChangeType={(value) => {
                          dispatch(
                            applyContentLibraryFilters({ selectedTypes: value })
                          )
                        }}
                        onChangeStatus={(value) => {
                          dispatch(
                            applyContentLibraryFilters({
                              selectedStatuses: value,
                            })
                          )
                        }}
                        onChangeTemplate={(value) => {
                          dispatch(
                            applyContentLibraryFilters({
                              selectedTemplates: value,
                            })
                          )
                        }}
                      />
                      <div className="flex items-center relative top-6">
                        <Button
                          data-cy="add-content-button"
                          data-testid="add-content-button"
                          aria-label="Create content"
                          variant="primary"
                          onClick={() => dispatch(showTemplates())}
                          className="uppercase"
                          prefixIcon={<AddDefault width={24} height={24} />}
                        >
                          Create
                        </Button>
                        <Searchbox
                          className="w-60 ml-4"
                          onSearchContentValue={setSearchContentValue}
                          data-cy="content-search"
                          data-testid="content-search"
                          aria-label="content search"
                          options={searchOptions}
                        />
                      </div>
                    </div>

                    {content(contentFiltered)}
                  </div>
                </div>
                <Footer />
              </div>
            ),
            loading: (
              <div className="w-full">
                <Loader data-testid="contents-loader" />
              </div>
            ),
            error: <ErrorPage />,
          }[state]
        }
      </MainContent>
      <TemplateSelection
        isVisible={isNewContent}
        onClose={handleTemplateSelectionClose}
        intialSelectedTab={selectedTemplateTab}
      />
      {contentIdToDelete && (
        <DeleteConfirmationModal
          onCancel={closeModal}
          onConfirm={() => {
            deleteContentMutation.mutate({
              accountId,
              contentId: contentIdToDelete,
            })
            closeModal()
          }}
        />
      )}
      <ConfirmationModal
        open={isConfirmTemplateModalShown}
        title="Hold up!"
        data-cy="template-selection-confirmation-modal"
        data-testid="template-selection-confirmation-modal"
        confirmationText="You’re about to leave the page and any work carried out will be lost. Are you sure you want to do this?"
        cancelButtonText="Leave without saving"
        confirmButtonText="Keep editing"
        confirmButtonVariant="primary"
        onConfirm={() => setIsConfirmTemplateModalShown(false)}
        onCancel={() => {
          setIsConfirmTemplateModalShown(false)
          dispatch(hideTemplates())
        }}
      />
    </>
  )
}

export default ContentsPage
