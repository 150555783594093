import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { createProfileAttributesCatalogue } from '../api'
import { keys } from '../queryKeys'

const useCreateCustomerAttributesMutation = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ region, name }: { region: string; name: string }) =>
      createProfileAttributesCatalogue({ accountId, region, name }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: keys.customerAttributeRecords({
          accountId,
        }),
      })
    },
  })
}

export default useCreateCustomerAttributesMutation
