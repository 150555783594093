import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import find from 'lodash/find'

import type { FileUploaderFileType } from 'common/components/fileUploader'
import { imagesKeys } from 'common/components/images/api/queryKeys'
import { useAppSelector } from 'common/hooks/redux'
import useGeneratePresignedUrlsMutation from 'features/admin/imageLibrary/api/mutations/useGeneratePresignedUrlsMutation'
import useUpdateImageStatusMutation from 'features/admin/imageLibrary/api/mutations/useUpdateImageStatusMutation'

import type { FileContentType, ImageFile } from '../../../api/api'
import { calculateMD5Base64 } from '../helpers'

const useImageUpload = ({ processFiles, onSuccess, onError }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const generatePresignedUrlsMutation = useGeneratePresignedUrlsMutation()
  const updateImageStatusMutation = useUpdateImageStatusMutation()

  const uploadImages = async (images: FileUploaderFileType[]) => {
    try {
      setIsLoading(true)
      const files: ImageFile[] = await Promise.all(
        images.map(async ({ file }) => {
          const md5Hash = await calculateMD5Base64(file)

          return {
            fileName: file.name,
            contentType: file.type as FileContentType,
            fileSize: file.size,
            md5Hash,
          }
        })
      )

      const presignedFiles = await generatePresignedUrlsMutation.mutateAsync({
        files,
      })

      presignedFiles.forEach(({ fileName, presignedUrl, id }) => {
        const image = find(images, (img) => img.filename === fileName)
        if (image) {
          image.setMetadata('presignedUrl', presignedUrl)
          image.setMetadata('id', id)
        }
      })

      const uploadedFiles = await processFiles(images.map((image) => image.id))

      await updateImageStatusMutation.mutateAsync(
        {
          imagesIds: uploadedFiles?.map(({ serverId }) => serverId) || [],
          status: 'UPLOADED',
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(imagesKeys.byAccount(accountId))
            onSuccess()
          },
        }
      )
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      onError()
    }
  }

  return {
    uploadImages,
    isLoading,
  }
}

export default useImageUpload
