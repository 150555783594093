import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { getMergeTags, MergeTagListItem } from '../api'
import { mergeTagsKeys } from '../queryKeys'

const useGetMergeTagsQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getMergeTagsQuery = useQuery<MergeTagListItem[]>(
    mergeTagsKeys.list(accountId),
    () => getMergeTags({ accountId }),
    {
      enabled: !!accountId,
    }
  )

  return getMergeTagsQuery
}

export default useGetMergeTagsQuery
