// TODO: Refactor this file
/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Variant } from '@phrasee/phrasee-typings/typings/futurama/element'
import cx from 'classnames'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'

import { CellProps, Column, OnCellChange } from 'common/components/table'
import { successToast } from 'common/components/toastNotification'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppSelector } from 'common/hooks/redux'
import { useFocusEditableCell } from 'common/hooks/table'
import useTableActions from 'common/hooks/useTableActions'
import useDeleteVariantMutation from 'features/unifiedFlow/api/mutations/useDeleteVariantMutation'
import useMoreLikeThisVariantsMutation from 'features/unifiedFlow/api/mutations/useMoreLikeThisVariantsMutation'
import useReplaceVariantMutation from 'features/unifiedFlow/api/mutations/useReplaceVariantMutation'
import useSelectVariantMutation from 'features/unifiedFlow/api/mutations/useSelectVariantMutation'
import useUpdateVariantMutation from 'features/unifiedFlow/api/mutations/useUpdateVariantMutation'
import {
  useContent,
  useSelectedComponentElement,
  useVariants,
} from 'features/unifiedFlow/contentPage/hooks'
import useWebsocketMessage from 'features/unifiedFlow/contentPage/hooks/websocketMessage'
import { getWsTopic } from 'features/unifiedFlow/contentPage/hooks/websocketMessage/helpers'

import useCoreVariantsWebsocketSubscription from '../hooks/useCoreVariantsWebsocketSubscription'
import RecommendedCell from '../RecommendedCell'
import TableHeading from '../tableHeading'

import { ActionCells } from './ActionsCell'
import ContentTableActionButtons from './contentTableActionButtons'
import useSelectedRows from './useSelectedRows'

import classes from './ContentTable.module.css'

const VARIANT_SUCCESS_TIMEOUT = 2000

export type ExtendedVariant = Variant & {
  isDisabled?: boolean
  checked?: boolean
  subRows?: ExtendedVariant[]
  isRefreshSuccessful?: boolean
  isRefreshing?: boolean
  isMoreLikeThisSuccessful?: boolean
  isLoadingMoreLikeThis?: boolean
}

type Props = {
  isReadOnly?: boolean
}

const ContentTable = ({ isReadOnly = false }: Props) => {
  const { sendWebsocketMessage } = useWebsocketMessage()
  const { TextEditableCellWithFocus } = useFocusEditableCell()

  const refreshTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined)
  const moreLikeThisTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined)

  const [refreshSuccessVariantIds, setRefreshSuccessVariantIds] = useState<
    string[]
  >([])
  const onRefreshSuccess = (variantId: string) => {
    setRefreshSuccessVariantIds((prev) => [...prev, variantId])
    refreshTimeoutRef.current = setTimeout(() => {
      setRefreshSuccessVariantIds((prev) =>
        prev.filter((id) => id !== variantId)
      )
    }, VARIANT_SUCCESS_TIMEOUT)
  }
  const [moreLikeThisSuccessVariantIds, setMoreLikeThisSuccessVariantIds] =
    useState<string[]>([])
  const onMoreLikeThisSuccess = (variantId: string) => {
    setMoreLikeThisSuccessVariantIds((prev) => [...prev, variantId])
    moreLikeThisTimeoutRef.current = setTimeout(() => {
      setMoreLikeThisSuccessVariantIds((prev) =>
        prev.filter((id) => id !== variantId)
      )
    }, VARIANT_SUCCESS_TIMEOUT)
  }

  useCoreVariantsWebsocketSubscription({
    onRefreshSuccess,
    onMoreLikeThisSuccess,
  })

  const accountId = useAppSelector((state) => state.authStates.accountId)

  const activeContentId = useAppSelector(
    (state) => state.unifiedFlow.activeContentId
  )

  const { content } = useContent()
  const { data: variants } = useVariants(activeContentId, { withSubrows: true })
  const { data: selectedElement } = useSelectedComponentElement()

  const elementId = selectedElement?.element_id

  const { selectedRows, setSelectedRows } = useSelectedRows(
    variants,
    selectedElement
  )

  const replaceVariantMutation = useReplaceVariantMutation()
  const moreLikeThisVariantsMutation = useMoreLikeThisVariantsMutation()
  const selectVariantMutation = useSelectVariantMutation(activeContentId)

  const variantsWithModifiedState = useMemo(() => {
    return sortBy(
      variants?.map((variant) => {
        const recommended = variant?.sortOrder === 1
        let isRefreshing = false
        let isLoadingMoreLikeThis = false

        if (content && selectedElement) {
          const refreshTopic = getWsTopic({
            action: 'refreshVariant',
            data: {
              contentId: content._id,
              elementId: selectedElement.element_id,
              variantId: String(variant.variant_id),
            },
          })

          isRefreshing =
            content.ws_messages?.some(
              (message) => message.wsTopic === refreshTopic
            ) || false

          const moreLikeThisWsTopic = getWsTopic({
            action: 'moreLikeThisVariants',
            data: {
              contentId: content._id,
              elementId: selectedElement.element_id,
              variantId: String(variant.variant_id),
            },
          })

          isLoadingMoreLikeThis =
            content.ws_messages?.some(
              (message) => message.wsTopic === moreLikeThisWsTopic
            ) || false
        }

        const isDisabled = isRefreshing || isLoadingMoreLikeThis

        const subRows = variant.subRows?.map((subRow) => {
          let isSubRowRefreshing = false

          if (content && selectedElement) {
            const refreshTopic = getWsTopic({
              action: 'refreshVariant',
              data: {
                contentId: content._id,
                elementId: selectedElement.element_id,
                variantId: String(subRow.variant_id),
              },
            })
            isSubRowRefreshing =
              content.ws_messages?.some(
                (message) => message.wsTopic === refreshTopic
              ) || false
          }

          return {
            ...subRow,
            recommended: false,
            isDisabled: isSubRowRefreshing || isDisabled,
            isRefreshing: isSubRowRefreshing,
            isRefreshed: refreshSuccessVariantIds.includes(
              String(subRow.variant_id)
            ),
            isRefreshSuccessful: refreshSuccessVariantIds.includes(
              String(subRow.variant_id)
            ),
            checked: selectedElement?.selected_variants?.includes(
              subRow.variant_id
            ),
          }
        })

        return {
          ...variant,
          isDisabled,
          recommended,
          isRefreshing,
          isLoadingMoreLikeThis,
          isRefreshed:
            refreshSuccessVariantIds.includes(String(variant.variant_id)) ||
            moreLikeThisSuccessVariantIds.includes(String(variant.variant_id)),
          isRefreshSuccessful: refreshSuccessVariantIds.includes(
            String(variant.variant_id)
          ),
          isMoreLikeThisSuccessful: moreLikeThisSuccessVariantIds.includes(
            String(variant.variant_id)
          ),
          checked: selectedElement?.selected_variants?.includes(
            variant.variant_id
          ),
          subRows,
        }
      }),
      [(item) => (item.recommended === true ? 0 : 1)]
    )
  }, [
    variants,
    content,
    selectedElement,
    refreshSuccessVariantIds,
    moreLikeThisSuccessVariantIds,
  ])

  const isApproveButtonDisabled = variantsWithModifiedState.some((variant) => {
    if (variant.isRefreshing || variant.isLoadingMoreLikeThis) {
      return true
    }

    if (variant.subRows) {
      return variant.subRows.some((subRow) => subRow.isRefreshing)
    }

    return false
  })

  const handleReplaceVariants = useCallback(
    async (variantId: number) => {
      if (activeContentId && elementId) {
        const wsTopic = getWsTopic({
          data: {
            contentId: activeContentId,
            elementId,
            variantId: String(variantId),
          },
          action: 'refreshVariant',
        })

        sendWebsocketMessage({
          action: 'refreshVariant',
          data: {
            contentId: activeContentId,
            elementId,
            variantId: String(variantId),
          },
          subscriptionAction: 'subscribe',
        })

        replaceVariantMutation.mutate({
          accountId,
          contentId: activeContentId,
          elementId,
          variantId,
          wsTopic,
        })
      }
    },
    [
      accountId,
      activeContentId,
      elementId,
      replaceVariantMutation,
      sendWebsocketMessage,
    ]
  )

  const deleteVariantMutation = useDeleteVariantMutation(activeContentId)
  const updateVariantMutation = useUpdateVariantMutation(activeContentId)

  const handleDeleteVariants = useCallback(
    (variantToDelete: Variant) => {
      if (activeContentId && elementId) {
        deleteVariantMutation.mutate(
          {
            accountId,
            contentId: activeContentId,
            elementId: elementId,
            variantId: variantToDelete.variant_id,
          },
          {
            onSuccess: () => {
              successToast('Line deleted successfully')
            },
          }
        )
      }
    },
    [accountId, activeContentId, deleteVariantMutation, elementId]
  )

  const { onCopy } = useTableActions()

  const handleUpdateVariant = (variant: Variant, text: string) => {
    if (activeContentId && elementId) {
      if (text) {
        updateVariantMutation.mutate({
          accountId,
          contentId: activeContentId,
          elementId,
          variantId: variant.variant_id,
          updatedVariantText: text,
        })
      } else {
        deleteVariantMutation.mutate(
          {
            accountId,
            contentId: activeContentId,
            elementId: elementId,
            variantId: variant.variant_id,
          },
          {
            onSuccess: () => {
              successToast('Line deleted successfully')
            },
          }
        )
      }
    }
  }

  const handleMoreLikeThisVariants = useCallback(
    (variantId: number) => {
      if (activeContentId && elementId) {
        const wsTopic = getWsTopic({
          data: {
            contentId: activeContentId,
            elementId,
            variantId: String(variantId),
          },
          action: 'moreLikeThisVariants',
        })

        sendWebsocketMessage({
          action: 'moreLikeThisVariants',
          data: {
            contentId: activeContentId,
            elementId,
            variantId: String(variantId),
          },
          subscriptionAction: 'subscribe',
        })

        moreLikeThisVariantsMutation.mutate({
          accountId,
          contentId: activeContentId,
          elementId,
          variantId,
          wsTopic,
        })
      }
    },
    [
      accountId,
      activeContentId,
      elementId,
      moreLikeThisVariantsMutation,
      sendWebsocketMessage,
    ]
  )

  const columns: Column<ExtendedVariant & { recommended: boolean }>[] = useMemo(
    () => [
      {
        accessor: 'text',
        disableSortBy: true,
        disableFilters: true,
        Cell: (
          props: CellProps<Variant & { recommended: boolean }> & {
            onCellChange: OnCellChange<Variant>
          }
        ) => {
          return (
            <TextEditableCellWithFocus
              inputType="textarea"
              className="py-4 pl-2 min-h-14"
              postValueElement={
                <RecommendedCell
                  isRecommended={props.row.original.recommended}
                />
              }
              {...props}
            />
          )
        },
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: 'variant_id',
        disableSortBy: true,
        align: 'right',
        disableFilters: true,
        width: 140,
        className: 'flex-none',
        Cell: ({ row }) => (
          <ActionCells
            onMoreLikeThis={() =>
              handleMoreLikeThisVariants(row.original.variant_id)
            }
            isMoreLikeThisEnabled={row.depth === 0}
            onCopy={() => onCopy(row.values.text)}
            onRefresh={() => handleReplaceVariants(row.original.variant_id)}
            onDelete={() => handleDeleteVariants(row.original)}
            isDisabled={row.original.isDisabled}
            isRefreshing={row.original.isRefreshing || false}
            isRefreshSuccessful={row.original.isRefreshSuccessful}
            isMoreLikeThisSuccessful={row.original.isMoreLikeThisSuccessful}
            isLoadingMoreLikeThis={row.original.isLoadingMoreLikeThis || false}
          />
        ),
      },
    ],
    [
      TextEditableCellWithFocus,
      handleMoreLikeThisVariants,
      onCopy,
      handleReplaceVariants,
      handleDeleteVariants,
    ]
  )

  const handleSelectRow = (rows: ExtendedVariant[]) => {
    const variantId = rows[0]?.variant_id
    const parsedRows: Variant[] = rows.map(
      ({ isDisabled, checked, ...row }) => row
    )
    if (
      !isEqual(parsedRows, selectedRows) &&
      activeContentId &&
      elementId &&
      variantId &&
      variantId !== selectedRows[0]?.variant_id
    ) {
      selectVariantMutation.mutate({
        accountId,
        contentId: activeContentId,
        elementId,
        variantId,
      })
      setSelectedRows(parsedRows)
    }
  }

  useEffect(() => {
    return () => {
      if (refreshTimeoutRef.current) {
        clearTimeout(refreshTimeoutRef.current)
      }
      if (moreLikeThisTimeoutRef.current) {
        clearTimeout(moreLikeThisTimeoutRef.current)
      }
    }
  }, [])

  if (variantsWithModifiedState.length === 0) {
    return null
  }

  return (
    <>
      <TableHeading
        heading={selectedElement?.display_name || selectedElement?.name}
        subHeading={selectedElement?.description}
      />
      <TableWidget.Widget<ExtendedVariant>
        className={cx('border-none bg-gold-40', classes.contentTable)}
        key={elementId}
        data={variantsWithModifiedState}
        columns={columns}
        initialState={{
          hiddenColumns: isReadOnly ? ['actions'] : [],
        }}
        autoResetPage={false}
        onSelectRow={handleSelectRow}
        rowSelection={isReadOnly ? undefined : 'single'}
        selectionHeader="Select a variant"
        onCellChange={({ columnId, value, rowOriginal }) => {
          if (columnId === 'text') {
            const variant = rowOriginal
            if (variant && value !== variant.text && variant?.variant_id) {
              handleUpdateVariant(variant, String(value))
            }
          }
        }}
        headerClassName="bg-gold-50"
        data-testid="content-variants-table"
      >
        <TableWidget.ActionButtons className="w-full">
          <ContentTableActionButtons
            isReadOnly={isReadOnly}
            isApproveButtonDisabled={isApproveButtonDisabled}
          />
        </TableWidget.ActionButtons>
      </TableWidget.Widget>
    </>
  )
}

export default ContentTable
