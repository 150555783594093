import { ReactNode } from 'react'
import cx from 'classnames'

import { ReactComponent as DataIcon } from './icons/data.svg'

import styles from './HeaderBanner.module.css'
interface Props {
  title: ReactNode
  'data-testid'?: string
}

const HeaderBanner = ({ title, 'data-testid': dataTestId }: Props) => {
  return (
    <div
      className={cx(
        'bg-maroon-400 p-6 flex relative h-full max-h-28 sm:max-h-40',
        styles.lines
      )}
      data-testid={dataTestId}
    >
      <DataIcon className="absolute top-10 right-10" />
      <div className="my-auto sm:mb-0 sm:mt-auto text-white text-6 sm:text-5xl md:text-6xl">
        {title}
      </div>
    </div>
  )
}

export default HeaderBanner
