import React, { useState } from 'react'
import classNames from 'classnames'

import { mapContentTypeToIcon } from 'common/components/ContentTypeIcon'
import Tooltip from 'common/components/Tooltip'

import { DistributionChannel, DistributionChannelType } from '../../interface'

interface Props {
  readonly?: boolean
  disabled?: boolean
  preSelected?: DistributionChannelType
  distributionChannels: DistributionChannel[]
  tooltipText?: string
  pickOption(type: string): void
}

const OptionPicker: React.FC<Props> = (props: Props) => {
  // render will know everything!
  const {
    distributionChannels,
    pickOption,
    disabled,
    readonly,
    preSelected,
    tooltipText,
  } = props
  const [optionSelected, setSelectedOption] = useState<number>()
  return (
    <Tooltip
      placement="right"
      overlay={disabled ? tooltipText : undefined}
      overlayStyle={{ maxWidth: 226 }}
      show={disabled}
    >
      <>
        <div className="text-lg mb-4">
          <h2
            data-cy="campaign-setup-stage-header"
            data-testid="campaign-setup-stage-header"
            className="font-medium"
          >
            What type of experiment is this?
          </h2>
        </div>
        <div className="flex mb-8">
          {distributionChannels.map((value: DistributionChannel, index) => {
            const CurrentIcon =
              mapContentTypeToIcon[value.type.toLowerCase()] ||
              mapContentTypeToIcon['email']
            const isSelected =
              index === optionSelected || value.type === preSelected
            const isEnabled = value.enabled && !readonly

            return (
              // eslint-disable-next-line react/no-array-index-key
              <React.Fragment key={value.type}>
                <div
                  data-cy={value.type}
                  data-testid={value.type}
                  className={classNames(
                    `w-24 h-28 mr-4 border-2 cursor-pointer text-sm flex flex-col justify-between text-center p-1`,
                    {
                      'border-maroon-400 text-maroon-400 bg-maroon-40':
                        isSelected,
                      'border-coolGray-300 hover:border-maroon-400 text-coolGray-800':
                        !isSelected && isEnabled,
                      'bg-maroon-300 cursor-not-allowed': !isEnabled,
                    }
                  )}
                  onClick={(): void => {
                    if (isEnabled && !disabled) {
                      pickOption(value.type)
                      setSelectedOption(index)
                    }
                  }}
                >
                  <div className="mt-3">
                    <CurrentIcon
                      size={9}
                      state={isSelected ? 'selected' : 'default'}
                    />
                  </div>
                  <div className="h-10 flex justify-center flex-col mb-4 text-sm font-medium">
                    {value.title}
                  </div>
                </div>

                <div className="separator" />
              </React.Fragment>
            )
          })}
        </div>
      </>
    </Tooltip>
  )
}

export default OptionPicker
