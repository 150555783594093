import React, { ChangeEvent } from 'react'
import { Input } from 'antd'
import { Key } from 'workflow/interface'

import RadioGroup from 'common/components/radioGroup'
import { stringToNumber } from 'common/helpers/numeric'

import { useTweakVariant } from '../context/TweakVariantContext'

const { TextArea } = Input

const changeReasonPlaceholder =
  'Explain as best as possible so we can learn and give better results in the future.'

const ReplaceForm = () => {
  const context = useTweakVariant()

  const {
    nfmReasonDetails,
    nfmNoReasonDetails,
    nfmReason,
    updateState,
    selectedState,
    feedbackRejectEnabled,
    newlinesInVariants,
  } = context

  const onNfmReasonDetails = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value

    updateState({
      nfmReasonDetails: value,
      nfmNoReasonDetails: value.trim() === '',
    })
  }

  return feedbackRejectEnabled && selectedState === 'replace' ? (
    <>
      <div className="column-first nfmmodalsection">
        <React.Fragment>
          <p className="mt-6 mb-2 text-coolGray-800 text-base ">
            Please tell us why
          </p>
          <TextArea
            data-cy="textarea-reason"
            data-testid="replace-reason-textarea"
            placeholder={changeReasonPlaceholder}
            onChange={onNfmReasonDetails}
            value={nfmReasonDetails}
            onPressEnter={(e): void => {
              const numberOfBreakLine = (
                (e.target as HTMLTextAreaElement).value.match(/\n/g) || []
              ).length
              const maxBreakLine = newlinesInVariants?.max
              if (
                maxBreakLine !== undefined &&
                e.keyCode === Key.Enter &&
                numberOfBreakLine >= maxBreakLine
              ) {
                // prevent default behavior
                e.preventDefault()
              }
            }}
            rows={4}
            className="placeholder-gold-700 bg-gold-40 focus:border-gold-400 hover:border-gold-400"
          />
          {nfmNoReasonDetails ? (
            <div className="text-red-400">Please give us a reason</div>
          ) : undefined}
          <RadioGroup
            className="mt-6 font-normal text-base"
            name="reason_selection"
            value={nfmReason.toString()}
            options={[
              {
                label: 'Against our brand voice',
                value: '1',
                'data-cy': 'replace-radio-brand-voice',
                'data-testid': 'replace-radio-brand-voice',
              },
              {
                label: 'Legal or compliance issue',
                value: '2',
                'data-cy': 'replace-radio-legal',
                'data-testid': 'replace-radio-legal',
              },
            ]}
            direction="vertical"
            onChange={(value) => {
              updateState({
                nfmReason: stringToNumber(value) || 0,
              })
            }}
          />
        </React.Fragment>
      </div>
    </>
  ) : null
}

export default ReplaceForm
