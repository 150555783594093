import { Children } from 'react'
import cx from 'classnames'
import isNil from 'lodash/isNil'

type Props = {
  children: React.ReactNode
}

const BannerContainer = ({ children }: Props) => {
  const hasChildren = Children.toArray(children).some((child) => !isNil(child))

  return (
    <div
      className={cx('grid gap-y-3', {
        'mb-10': hasChildren,
      })}
    >
      {children}
    </div>
  )
}

export default BannerContainer
