import { useEffect, useState } from 'react'

import RightPanelHeading from 'features/unifiedFlow/contentPage/components/layout/RightPanelHeading'
import { useVariantInsights } from 'features/unifiedFlow/contentPage/generate/context/VariantInsightsContext'

import SentimentAnalysisChart from './sentimentAnalysis/SentimentAnalysisChart'
import InsightsCard from './InsightsCard'
import InsightsCategories from './InsightsCategories'
import SentimentDescription from './SentimentDescription'

const VariantInspector = () => {
  const {
    state: { sentiments, defaultSentiment, category, isLoading },
  } = useVariantInsights()

  const [selectedSentiment, setSentiment] = useState<string | undefined>(
    defaultSentiment?.name
  )

  useEffect(() => {
    setSentiment(defaultSentiment?.name)
  }, [defaultSentiment?.name])

  return (
    <div className="p-6 text-coolGray-800">
      <RightPanelHeading text="Variant inspector" className="mb-3" />
      <p className="text-xs font-normal mb-4 leading-5 text-base-700">
        Uncover key insights at a glance using Jacquard.
      </p>
      {category && <InsightsCategories category={category} />}
      <hr className="my-2 border-gold-400" />
      {sentiments && (
        <InsightsCard
          title="Sentiment map"
          hasTitleIcons
          content={
            <SentimentAnalysisChart
              isLoading={isLoading}
              selectedSentiment={selectedSentiment}
              series={sentiments}
              onClickSentiment={({ name }) => setSentiment(name)}
            />
          }
        />
      )}
      {selectedSentiment && (
        <SentimentDescription sentiment={selectedSentiment} />
      )}
    </div>
  )
}

export default VariantInspector
