import { Fragment } from 'react'
import type { Row } from 'react-table'

import type { TableData } from '../types/interfaces'

import type { DefaultRowProps } from './DefaultRow'
import DefaultRow from './DefaultRow'

type GroupRowProps<T extends object> = DefaultRowProps<T> & {
  prepareRow: Function
}

type SubRowProps<T extends object> = Omit<GroupRowProps<T>, 'row'> & {
  subRows: Row<T>[]
}

const SubRows = <T extends object>({
  subRows,
  depth,
  prepareRow,
  ...restProps
}: SubRowProps<T>) => {
  return (
    <>
      {subRows.map((row, subRowIndex) => {
        prepareRow(row)
        return (
          <Fragment key={row.id}>
            <DefaultRow<T>
              {...restProps}
              row={row}
              depth={depth}
              subRowIndex={subRowIndex}
            />
            {row.subRows && row.subRows.length > 0 && (
              <SubRows<T>
                subRows={row.subRows}
                depth={depth + 1}
                prepareRow={prepareRow}
                {...restProps}
              />
            )}
          </Fragment>
        )
      })}
    </>
  )
}

const GroupRows = <T extends TableData>({
  row,
  ...restProps
}: GroupRowProps<T>) => {
  return (
    <div className="flex flex-col">
      {row.groupByVal !== 'undefined' && row.groupByVal !== '' && (
        <div className="bg-gold-100 border-t border-b border-gold-200 px-6 py-4">
          <p className="font-medium text-gold-700 uppercase">
            {row.groupByVal}
          </p>
        </div>
      )}
      <SubRows<T> subRows={row.subRows} {...restProps} />
    </div>
  )
}

export default GroupRows
