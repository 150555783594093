import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { getProductCatalogs, Region } from '../api'
import { contentKeys } from '../queryKeys'

const useGetProductCatalogsQuery = ({
  region,
}: {
  region: Region | undefined
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getProductCatalogsQuery = useQuery(
    contentKeys.productCatalogs({ accountId, region }),
    () => getProductCatalogs({ accountId, region }),
    {
      enabled: !!region && !!accountId,
      meta: {
        errorMessage: 'Product catalogs could not be loaded',
      },
    }
  )

  return getProductCatalogsQuery
}

export default useGetProductCatalogsQuery
