import { useCallback, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { combinationPerPersonalizationPath } from 'app/navigation/paths'
import cx from 'classnames'

import Badge from 'common/components/Badge'
import Table, { Column, Row } from 'common/components/table/Table'

import { CombinationLibraryEntryStatus } from '../api/api'

type CombinationEntry = {
  id: string
  channel: string
  campaignType: string
  productItem: string
  productCategory: string
  status: CombinationLibraryEntryStatus
  dimensions: { key: string; value: string }[]
  owner: string | undefined
  numberOfVariants: number
  approvalRate: string
  rejectedCount: number
}

type Props = {
  content: CombinationEntry[]
  personalizationId: string
}

const initialState = { sortBy: [{ id: 'campaignType', desc: false }] }

const mapStatusToVariant = (status: CombinationLibraryEntryStatus) => {
  switch (status) {
    case 'New combination':
      return 'danger'
    case 'Awaiting approval':
      return 'warning'
    case 'Approved':
      return 'success'
    case 'Regeneration':
      return 'neutral'
    default:
      return 'danger'
  }
}

const getStatusSubtext = (row: Row<CombinationEntry>) => {
  if (row.original.status === 'New combination' && !row.original.owner) {
    return 'Awaiting ownership'
  } else if (row.original.status === 'New combination' && row.original.owner) {
    return 'Prompt in progress'
  } else if (row.original.status === 'Awaiting approval') {
    return `${row.original.numberOfVariants} variants`
  } else {
    return `${row.original.approvalRate} approval rate`
  }
}

const Property = ({ name, value }: { name: string; value: string }) => (
  <div className="flex gap-1">
    <div className="font-medium text-base-700 capitalize-first">{name} :</div>
    <div className="text-maroon-400 capitalize-first">{value}</div>
  </div>
)

const statusOrder: Record<CombinationLibraryEntryStatus, number> = {
  'New combination': 0,
  'Awaiting approval': 1,
  Approved: 2,
  Regeneration: 3,
}

const CombinationLibraryTable = ({ content, personalizationId }: Props) => {
  const history = useHistory()
  const columns: Column<CombinationEntry>[] = useMemo(
    () => [
      {
        accessor: 'campaignType',
        sortType: (a, b) => {
          const statusA = statusOrder[a.original.status]
          const statusB = statusOrder[b.original.status]
          return statusA - statusB
        },
        Cell: ({ row }) => {
          return (
            <div
              className="flex flex-col h-32 justify-between w-full p-6 border border-coolGray-200 hover:bg-coolGray-50"
              onClick={() =>
                row.original.id &&
                history.push(
                  generatePath(combinationPerPersonalizationPath, {
                    personalizationId,
                    combinationId: row.original.id,
                  })
                )
              }
            >
              <div className="flex justify-between">
                <div className="flex flex-col gap-4">
                  <div className="flex gap-6">
                    <Property
                      name="Campaign type"
                      value={row.original.campaignType}
                    />
                    <Property name="Channel" value={row.original.channel} />
                  </div>
                </div>
                <Badge
                  className="uppercase"
                  variant={mapStatusToVariant(row.original.status)}
                  text={row.original.status}
                />
              </div>

              <div
                className={cx(
                  'flex',
                  row.original.dimensions.length > 0
                    ? 'justify-between'
                    : 'justify-end'
                )}
              >
                {row.original.dimensions.length > 0 ? (
                  <div className="flex flex-col gap-1">
                    <span className="text-xs ">Recommended: </span>
                    <div className="flex gap-6 pt-1">
                      {row.original.dimensions.map(({ key, value }) => (
                        <Property key={key} name={key} value={value} />
                      ))}
                    </div>
                  </div>
                ) : null}
                <div className="flex flex-col justify-end">
                  <span>{getStatusSubtext(row)}</span>
                  {row.original.rejectedCount > 0 && (
                    <span>{row.original.rejectedCount}x Rejected</span>
                  )}
                </div>
              </div>
            </div>
          )
        },
      },
    ],
    [personalizationId, history]
  )

  const rowClassName = useCallback(
    () => 'mb-2 border border-coolGray-200 bg-white',
    []
  )

  return (
    <Table
      className="border-none"
      columns={columns}
      data={content}
      firstUseText="Nothing to see here… There is no content to show."
      generateRowClassName={rowClassName}
      isHeaderHidden
      isHorizontalSeparatorHidden
      initialState={initialState}
    />
  )
}

export default CombinationLibraryTable
