import CenterPanel from '../../components/layout/CenterPanel'
import { useSelectedComponentElement } from '../../hooks'

import BriefPage from './BriefPage'

type Props = {
  isWebsocketLoading: boolean
}

const CenterSection = ({ isWebsocketLoading }: Props) => {
  const { data: selectedElement } = useSelectedComponentElement()
  return (
    <CenterPanel>
      <BriefPage
        key={selectedElement?.element_id || 'brief'}
        isWebsocketLoading={isWebsocketLoading}
      />
    </CenterPanel>
  )
}

export default CenterSection
