import { useFlags } from 'launchdarkly-react-client-sdk'

import Button from 'common/components/button/Button'
import SubNavBar, { SubNavItem } from 'common/components/navigation/subNavBar'
import Tooltip from 'common/components/Tooltip'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  Add as AddIcon,
  Connect as ConnectIcon,
  SidebarLeftMenu as SidebarLeftMenuIcon,
} from 'common/icons'
import useGetTemplateQuery from 'features/futurama/api/queries/useGetTemplateQuery'
import {
  hideWorkflow,
  showWorkflow,
} from 'features/futurama/store/contentSlice'

import { getIsAtleastOneElementGenerationComplete } from '../../helpers'
import { useContentOrTemplate } from '../../hooks'

import HeaderActions from './HeaderActions'

const Header = () => {
  const activeTemplateId = useAppSelector(
    (state) => state.content.activeTemplateId
  )
  const isWorkflowVisible = useAppSelector(
    (state) => state.content.isWorkflowVisible
  )
  const dispatch = useAppDispatch()
  const { showContentIntegrations } = useFlags()

  const { content } = useContentOrTemplate()

  const { data: contentTemplate } = useGetTemplateQuery(content?.template_id)
  const { data: template } = useGetTemplateQuery(activeTemplateId)

  const contentTemplateDisplayName = contentTemplate?.display_name
  const templateDisplayName = template?.display_name

  const isIntegrationPageEnabled =
    getIsAtleastOneElementGenerationComplete(content)

  return (
    <SubNavBar
      title={contentTemplateDisplayName ?? templateDisplayName}
      preTitle={
        <Button
          className="pr-1 md:hidden pl-0"
          prefixIcon={
            <Tooltip
              overlay={isWorkflowVisible ? 'Hide workflow' : 'Show workflow'}
            >
              <SidebarLeftMenuIcon
                isDefaultColor={false}
                className="text-white w-5 h-5"
              />
            </Tooltip>
          }
          onClick={() => {
            isWorkflowVisible
              ? dispatch(hideWorkflow())
              : dispatch(showWorkflow())
          }}
        />
      }
      backLink="/content"
      rightNav={<HeaderActions />}
    >
      {showContentIntegrations && content && (
        <>
          <SubNavItem
            key="content"
            title="Content"
            to={`/content/${content?._id}`}
            Icon={AddIcon}
          />
          <SubNavItem
            key="integration"
            title="Integration"
            to={`/content/${content?._id}/integration`}
            Icon={ConnectIcon}
            isDisabled={!isIntegrationPageEnabled}
          />
        </>
      )}
    </SubNavBar>
  )
}

export default Header
