import { useMutation, useQueryClient } from '@tanstack/react-query'

import { successToast } from 'common/components/toastNotification'
import { campaignKeys } from 'features/campaigns/api/queryKeys'

import { createProject } from '../api'
import { projectKeys } from '../api/queryKeys'

const useCreateProjectMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(createProject, {
    onSuccess: async (data, { accountId }) => {
      if (data.state !== 'validationError') {
        queryClient.invalidateQueries(projectKeys.projects(accountId))
        queryClient.invalidateQueries(campaignKeys.projects(accountId))
        successToast('Project saved successfully')
      }
    },
  })
}

export default useCreateProjectMutation
