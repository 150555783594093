import cx from 'classnames'
import filter from 'lodash/filter'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'

import Badge from 'common/components/Badge'
import IconTitleGroup from 'common/components/iconTitleGroup'
import Loader from 'common/components/loaders/Loader'
import { containsArabic } from 'common/helpers/string'
import { useAppSelector } from 'common/hooks/redux'
import { TextWithLineBreaks } from 'common/variantsUtils'

import { CurrentUsersBanner } from '../../components/CurrentUsersBanner'
import ElementCardIcon from '../../components/ElementCardIcon'
import ImageThumbnailButton from '../../components/imageThumbnailButton'
import { getFilteredVariants } from '../../helpers'
import { useSelectedElement } from '../../hooks'
import useCampaignImages from '../hooks/useCampaignImages'

const OptimizeView = () => {
  const { data: selectedElement } = useSelectedElement()
  const { campaignData } = useAppSelector((state) => state.campaignStates)

  const isLoadingCampaignData = useAppSelector(
    (state) => state.campaignStates.isLoadingCampaignDetails
  )
  const variantsToBeApproved = useAppSelector(
    (state) => state.campaignStates.variantsToBeApproved
  )

  const slCollection = sortBy(
    filter(get(campaignData, 'sl', []), (item) => !item.ownsl),
    'sort_order'
  )

  const variants = getFilteredVariants(
    campaignData,
    slCollection,
    variantsToBeApproved
  )

  const images = useCampaignImages(variants)

  if (!selectedElement) {
    return null
  }

  const elementName = selectedElement.display_name || selectedElement.name
  const campaignName = campaignData?.name ?? ''

  const controlVariants =
    campaignData?.sl?.filter((variant) => variant.ownsl) || []

  return isLoadingCampaignData ? (
    <Loader className="m-auto" />
  ) : (
    <section className="bg-gold-40 w-full flex flex-col">
      <CurrentUsersBanner />
      <header>
        <h5
          className="text-base-700 text-4xl font-medium mb-2.5"
          data-testid="launch-heading"
        >{`Launch: ${campaignName}`}</h5>
        <p className="text-base-700 text-base mb-8 font-normal">
          Fill out the necessary details and connect to your delivery platform.
        </p>
      </header>

      <article>
        <IconTitleGroup title="Content" className="pb-6" />
        <div className="py-3 rounded-sm">
          <div className="rounded-md">
            <div className="flex items-center bg-gold-100 p-4">
              {selectedElement.icon && (
                <ElementCardIcon icon={selectedElement.icon} size={6} />
              )}
              <span className="ml-2 text-base-700 font-medium uppercase">
                {elementName}
              </span>
            </div>
            <div
              className="ml-2"
              role="list"
              aria-label="Variant list"
              data-testid="variant-list"
            >
              {variants?.map((variant, idx) => {
                const isArabic = containsArabic(variant.text)
                const image = images[variant?.image_id]

                return (
                  <div
                    key={variant._id}
                    className="flex items-center text-sm text-gold-700 py-4 border-b border-gold-400 min-h-14"
                    role="listitem"
                  >
                    <span className={cx({ 'mr-2': !isArabic })}>{`${
                      idx + 1
                    }.`}</span>
                    {image && (
                      <ImageThumbnailButton className="mr-2" image={image} />
                    )}
                    <p dir={isArabic ? 'rtl' : 'ltr'}>
                      <TextWithLineBreaks text={variant.text} />
                    </p>
                  </div>
                )
              })}
              {controlVariants.map((controlVariant) => {
                const isControlVariantArabic = containsArabic(
                  controlVariant.text
                )
                const image = images[controlVariant?.image_id]

                return (
                  <div
                    key={controlVariant._id}
                    className="flex items-center py-4 text-gold-700 min-h-14 border-b border-gold-400 last:border-b-0"
                    role="listitem"
                    aria-label="Control variant"
                    dir={isControlVariantArabic ? 'rtl' : 'ltr'}
                  >
                    {!isControlVariantArabic && (
                      <Badge
                        size="small"
                        className="flex-none mr-2 text-white uppercase"
                        text="Control"
                        variant="neutral"
                      />
                    )}
                    {image && (
                      <ImageThumbnailButton className="mr-2" image={image} />
                    )}
                    <TextWithLineBreaks text={controlVariant.text} />
                    {isControlVariantArabic && (
                      <Badge
                        size="small"
                        className="flex-none mr-2 text-white uppercase"
                        text="Control"
                        variant="neutral"
                      />
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </article>
    </section>
  )
}

export default OptimizeView
