import { forwardRef } from 'react'
import { ReactNode } from 'react'
import {
  components,
  ControlProps,
  GroupBase,
  InputProps,
  MenuListProps,
  OptionProps,
  SingleValue,
  SingleValueProps,
  StylesConfig,
} from 'react-select'
import { FormatOptionLabelMeta } from 'react-select'
import cx from 'classnames'

import { SmallTick } from 'common/icons'

import type { BaseProps, SelectRef, SelectValue } from '../BaseSelect'
import BaseSelect from '../BaseSelect'

type Props<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
  OptionType extends SelectValue = SelectValue
> = Omit<BaseProps<Option, IsMulti, Group>, 'value'> & {
  value?: string
  customStyles?: StylesConfig<SelectValue, false>
  'data-testid'?: string
  'data-cy'?: string
  formatOptionLabel?: (
    option: OptionType,
    labelMeta: FormatOptionLabelMeta<OptionType>
  ) => ReactNode
  footer?: ReactNode
}

export type SingleSelectRef = SelectRef<
  SelectValue,
  false,
  GroupBase<SelectValue>
>

const Value = <T,>({
  innerProps,
  ...props
}: SingleValueProps<T, false, GroupBase<T>>) => (
  <components.SingleValue
    {...props}
    className={cx(
      'overflow-hidden whitespace-nowrap overflow-ellipsis',
      props.className
    )}
    innerProps={{
      ...innerProps,
      // @ts-ignore
      'data-testid': 'selected-value-label',
    }}
  />
)
const Input = <T,>(props: InputProps<T, false, GroupBase<T>>) => (
  <components.Input
    {...{
      'data-lpignore': true,
      'data-testid': `single-select-input-${props.selectProps['name']}`,
    }}
    {...props}
  />
)

export const Control = <T,>({
  innerProps,
  ...props
}: ControlProps<T, false>) => (
  <components.Control
    {...props}
    innerProps={{
      ...innerProps,
      // @ts-ignore
      'data-testid': props.selectProps['data-testid'],
      'data-cy': props.selectProps['data-cy'],
    }}
  />
)

export const Option = <T,>({
  isSelected,
  children,
  ...rest
}: OptionProps<T, false, GroupBase<T>>) => {
  return (
    <components.Option isSelected={isSelected} {...rest}>
      <div
        className={cx(
          `mx-3 px-3 py-2 flex justify-between items-center hover:bg-gray-100 whitespace-pre-line
          cursor-pointer`,
          {
            'text-coolGray-800': !isSelected,
            'text-maroon-400': isSelected,
          }
        )}
      >
        <div className="mr-4" role="option" aria-selected={isSelected}>
          {children}
        </div>
        <div className="w-6 h-6">
          <SmallTick
            state="selected"
            className={cx('w-6 h-6 text-selected-icon', {
              hidden: !isSelected,
            })}
          />
        </div>
      </div>
    </components.Option>
  )
}

export const MenuList = <T,>({
  children,
  ...props
}: MenuListProps<T, false, GroupBase<T>>) => {
  return (
    <components.MenuList {...props}>
      {children}
      {props.selectProps['footer'] && (
        <div className="px-6 pb-3">
          <hr className="w-full mb-3 border-gold-400" />
          <div>{props.selectProps['footer']}</div>
        </div>
      )}
    </components.MenuList>
  )
}

export type SingleSelectProps = Props<
  SelectValue,
  false,
  GroupBase<SelectValue>
>

const SingleSelect = forwardRef<SingleSelectRef, SingleSelectProps>(
  (
    { options, value, menuPlacement = 'auto', isSearchable = false, ...rest },
    ref
  ) => {
    let selectedOption: SelectValue | null = null
    options?.forEach((option) => {
      if ('value' in option && option.value === value) {
        selectedOption = option
      } else if ('options' in option) {
        selectedOption =
          option.options.find((groupOption) => groupOption.value === value) ??
          selectedOption
      }
    })

    return (
      <BaseSelect<SelectValue, false>
        ref={ref}
        value={selectedOption}
        options={options}
        hideSelectedOptions={false}
        menuPlacement={menuPlacement}
        isSearchable={isSearchable}
        components={{
          Option,
          SingleValue: Value,
          Input,
          Control,
          MenuList,
        }}
        {...rest}
      />
    )
  }
)

export type { SingleValue, SelectValue }
export default SingleSelect
