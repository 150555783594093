import { useCallback, useMemo, useState } from 'react'
import { CellProps } from 'react-table'
import isEqual from 'lodash/isEqual'

import Button from 'common/components/button'
import ConfirmationModal from 'common/components/confirmationModal'
import { Column, OnCellChange } from 'common/components/table'
import Tags from 'common/components/tags'
import { errorToast } from 'common/components/toastNotification'
import TableWidget from 'common/components/widget/tableWidget'
import { ButtonWithResetPaging } from 'common/components/widget/tableWidget/TableWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useFocusEditableCell } from 'common/hooks/table'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'

import {
  addEmojiRow,
  deactivateEmojis,
  updateEmojiCell,
} from '../store/styleGuideSlice'

type Row = {
  id: number
  lookup: string
  values: string[]
}

type Props = {
  className?: string
}

const EmojisWidget = ({ className }: Props) => {
  const emojis = useAppSelector(
    (state) => state.styleGuide.editedContent.emojis
  )

  const dispatch = useAppDispatch()
  const {
    TextEditableCellWithFocus,
    editableCellFocus,
    editableCellLoseFocus,
  } = useFocusEditableCell()

  const [isConfirmationDeactivateVisible, setIsConfirmationDeactivateVisible] =
    useState<boolean>(false)
  const [resetFilterKey, setResetFilterKey] = useState(0)

  const columns: Column<Row>[] = useMemo(
    () => [
      {
        Header: 'Emoji',
        isResizable: true,
        minWidth: 50,
        maxWidth: 55,
        width: 50,
        accessor: 'lookup',
        disableSortBy: true,
        Cell: (props: CellProps<Row> & { onCellChange: OnCellChange }) => (
          <>
            <TextEditableCellWithFocus
              {...props}
              validations={[
                {
                  validate: (value: string | undefined) =>
                    value === undefined ? false : !isEmoji(value.trim()),
                  message: 'Please enter an Emoji.',
                },
              ]}
              className="text-2xl"
            />
          </>
        ),
      },
      {
        minWidth: 300,
        width: 350,
        accessor: 'values',
        disableSortBy: true,
        Header: 'Trigger words',
        Cell: ({ value: cellValue, row }) => {
          return (
            <div className="ml-6 my-4 flex items-center">
              <Tags
                isFreeText={true}
                data-cy="emojis-trigger-words"
                data-testid="emojis-trigger-words"
                onAddClick={(tagValue: string) => {
                  dispatch(
                    updateEmojiCell({
                      value: [
                        ...row.original['values'],
                        tagValue.toLowerCase(),
                      ],
                      rowIndex: row.index,
                      columnId: 'values',
                    })
                  )
                  setResetFilterKey((prev) => prev + 1)
                }}
                onRemoveClick={(value: string) => {
                  dispatch(
                    updateEmojiCell({
                      value: row.original['values'].filter(
                        (item) => item !== value
                      ),
                      rowIndex: row.index,
                      columnId: 'values',
                    })
                  )
                  setResetFilterKey((prev) => prev + 1)
                }}
                onInputChange={(value: string) => value.replaceAll(' ', '')}
                tags={cellValue.map((value, index) => ({
                  value: row.original['values'][index],
                  label: row.original['values'][index],
                }))}
                maxLength={50}
              />
            </div>
          )
        },
      },
    ],
    [TextEditableCellWithFocus, dispatch]
  )
  const [selectedRows, setSelectedRows] = useState<Row[]>([])
  const handleDeactivateCancel = () => {
    setSelectedRows([])
    setIsConfirmationDeactivateVisible(false)
  }

  const handleDeactivateConfirm = () => {
    dispatch(
      deactivateEmojis(
        selectedRows.map((row) => {
          return {
            lookup: row.lookup,
            values: row['values'],
          }
        })
      )
    )
    setIsConfirmationDeactivateVisible(false)
  }
  const handleSelectRow = useCallback(
    (rows) => {
      if (!isEqual(rows, selectedRows)) {
        setSelectedRows(rows)
      }
    },
    [selectedRows]
  )

  return (
    <>
      <TableWidget.Widget
        data-cy="emojis-widget"
        data-testid="emojis-widget"
        className={className}
        columns={columns}
        data={emojis}
        autoResetPage={false}
        selectedRowsActionButtons={
          selectedRows.length > 0 ? (
            <Button
              variant="link"
              onClick={() => setIsConfirmationDeactivateVisible(true)}
            >
              Deactivate
            </Button>
          ) : undefined
        }
        rowSelection="multiple"
        onSelectRow={handleSelectRow}
        onCellChange={({ rowIndex, columnId, value }) => {
          if (
            columnId === 'lookup' &&
            typeof value === 'string' &&
            !!value &&
            emojis.some(
              (row, index) =>
                index !== rowIndex && row?.lookup?.trim() === value.trim()
            )
          ) {
            errorToast('Emoji already exists')
            setResetFilterKey((prev) => prev + 1)
          } else {
            dispatch(updateEmojiCell({ rowIndex, columnId, value }))
            editableCellLoseFocus()
            setResetFilterKey((prev) => prev + 1)
          }
        }}
      >
        <TableWidget.Header
          title="Emojis"
          subtitle="Click the +Emoji button to add a new emoji to the list. Paste the emoji 
            character into the first cell and then configure the trigger words that can be 
            used to allow this emoji to generate in the content. If you want to disable an 
            emoji from generating, either remove all trigger words for it, or check the 
            checkbox next to that emoji and click deactivate. This will remove all trigger 
            words and therefore the emoji will never generate."
        >
          <ButtonWithResetPaging
            className="mr-4"
            variant="primary"
            data-cy="add-emoji-button"
            data-testid="add-emoji-button"
            prefixIcon={<AddIcon width={24} height={24} />}
            onClick={() => {
              dispatch(addEmojiRow())
              editableCellFocus()
              setResetFilterKey((prev) => prev + 1)
            }}
          >
            Emoji
          </ButtonWithResetPaging>
          <TableWidget.Filter key={`${resetFilterKey}`} />
        </TableWidget.Header>
      </TableWidget.Widget>
      <ConfirmationModal
        open={isConfirmationDeactivateVisible}
        title="Deactivate emojis rows!"
        data-cy="emojis-row-deactivate-modal"
        data-testid="emojis-row-deactivate-modal"
        confirmationText="Are you sure you want to deactivate those rows?"
        onCancel={() => handleDeactivateCancel()}
        onConfirm={() => handleDeactivateConfirm()}
        confirmButtonText="Yes, deactivate!"
      />
    </>
  )
}

export default EmojisWidget

function isEmoji(value: string) {
  return /\p{Extended_Pictographic}/u.test(value)
}
