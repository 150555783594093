import { Item as MenuItem } from 'rc-menu'

import { AddSquare } from 'common/icons'

const AddIntegrationButton = ({ ...restProps }) => {
  return (
    <MenuItem
      className="flex items-center p-4 -mx-4 cursor-pointer hover:bg-gold-300"
      {...restProps}
    >
      <AddSquare isDefaultColor={false} size={4} className="text-base-700" />
      <span className="font-medium text-base-700 text-sm uppercase ml-1">
        Add integration
      </span>
    </MenuItem>
  )
}

export default AddIntegrationButton
