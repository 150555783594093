import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import ElementCard from '../ElementCard'

import { BRIEF_ELEMENT_ID } from './constants'

const UniversalBriefElement = () => {
  const dispatch = useAppDispatch()
  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )

  return (
    <ElementCard
      title="Universal Brief"
      className="mb-4"
      icon="messageText"
      isLoading={false}
      hasElementActionsMenu={false}
      isSelected={selectedElementId === BRIEF_ELEMENT_ID}
      onClick={() => dispatch(selectElement({ elementId: BRIEF_ELEMENT_ID }))}
    />
  )
}

export default UniversalBriefElement
