import React from 'react'

interface AiAuthoringInterface {
  onClick(): void
}

const AiAuthoring: React.FC<AiAuthoringInterface> = (
  props: AiAuthoringInterface
) => {
  return (
    <div className="relative">
      <div
        className="mx-auto text-coolGray-800"
        style={{ width: '400px', paddingTop: '300px' }}
      >
        <div className="text-4xl font-bold">Custom experiment in progress…</div>
        <div className="my-6 text-base">
          Our computational linguists use advanced AI technology to produce
          optimised content that gets you the highest level of engagement for
          one-off experiments.
        </div>
        <div className="font-thin">
          We’ll get back to you as soon as content is ready for your approval.
        </div>
      </div>
    </div>
  )
}

export default AiAuthoring
