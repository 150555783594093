import { useLayoutEffect } from 'react'

import { useAppDispatch } from 'common/hooks/redux'
import { selectElementAndFetchCampaignData } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { getOptimizedOrPersonalizedElements } from '../../helpers'
import { useContent } from '../../hooks'

const useElementState = () => {
  const dispatch = useAppDispatch()
  const { content } = useContent()

  const optimizedOrPersonalizedElements = getOptimizedOrPersonalizedElements(
    content?.elements
  )

  useLayoutEffect(() => {
    const firstEnabledElement = optimizedOrPersonalizedElements.find(
      (element) => element.status !== 'disabled'
    )

    if (firstEnabledElement) {
      const action = dispatch(
        selectElementAndFetchCampaignData(firstEnabledElement)
      )

      return () => {
        action.abort()
      }
    }

    // This hook should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useElementState
