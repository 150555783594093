import Lottie from 'react-lottie-player'

import animationData1 from './element-loader-1.json'
import animationData2 from './element-loader-2.json'
import animationData3 from './element-loader-3.json'
import animationData4 from './element-loader-4.json'

export const ELEMENT_LOADER_ANIMATIONS_COUNT = 4

export type ElementLoaderProps = {
  animation: '1' | '2' | '3' | '4'
}

const ElementLoader = ({ animation }: ElementLoaderProps) => {
  const animationData = {
    '1': animationData1,
    '2': animationData2,
    '3': animationData3,
    '4': animationData4,
  }[animation]

  const animationProps = {
    loop: true,
    play: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    style: {
      width: '32px',
      height: '32px',
    },
  }

  return <Lottie {...animationProps} />
}

export default ElementLoader
