import cx from 'classnames'

type Props = {
  type: 'info' | 'success' | 'error'
  icon: React.ReactChild
  text?: string
  heading?: string
  cta?: React.ReactNode
  onClick?: React.MouseEventHandler<HTMLDivElement>
  className?: string
  footer?: React.ReactNode
}

const Banner = (props: Props) => {
  const { icon, text, cta, onClick, heading, type, className, footer } = props

  return (
    <div
      role={onClick ? 'button' : undefined}
      onClick={onClick}
      className={cx(
        'flex flex-col px-4 py-3 rounded-sm',
        {
          'cursor-pointer': onClick,
          'bg-RAGamber text-base-700': type === 'info',
          'bg-RAGGreen text-white': type === 'success',
          'bg-RAGred text-white': type === 'error',
        },
        className
      )}
    >
      <div className="flex items-start">
        <div className="self-start mr-2 text-inherit">{icon}</div>
        <div
          className={cx('flex flex-col', {
            'gap-2': heading && text,
            'self-center': (heading && !text) || (!heading && text),
          })}
        >
          {heading && <p className="text-base font-normal">{heading}</p>}
          {text && <p className="text-xs">{text}</p>}
        </div>
        {cta && (
          <div className="flex items-center uppercase underline ml-auto pl-2">
            {cta}
          </div>
        )}
      </div>

      {footer && <div className="flex mt-4">{footer}</div>}
    </div>
  )
}

export default Banner
