import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { changeAccountId } from 'features/auth/store/authSlice'

export interface DateRange {
  startDate: string | undefined
  endDate: string | undefined
}

export interface InsightsState {
  project: string
  projectIdToCompare?: string
  startDate?: string
  endDate?: string
  startDateToCompare?: string
  endDateToCompare?: string
  mode: Mode
}
export type Mode = 'basicReport' | 'projectComparison' | 'dateRangeComparison'

const initialState: InsightsState = {
  project: '',
  mode: 'basicReport',
}

export const insightsSlice = createSlice({
  name: 'insights',
  initialState,
  reducers: {
    applyFilter: (
      state,
      action: PayloadAction<{
        project: string
        dateRange: DateRange
        projectToCompare?: string
        dateRangeToCompare?: DateRange
      }>
    ) => {
      const { project, dateRange, projectToCompare, dateRangeToCompare } =
        action.payload
      state.project = project
      state.startDate = dateRange.startDate
      state.endDate = dateRange.endDate
      if (projectToCompare) {
        state.projectIdToCompare = projectToCompare
      }
      if (dateRangeToCompare) {
        state.startDateToCompare = dateRangeToCompare.startDate
        state.endDateToCompare = dateRangeToCompare.endDate
      }
    },
    applyProject: (state, action: PayloadAction<string>) => {
      state.project = action.payload
    },
    applyDateRange: (
      state,
      action: PayloadAction<{ start?: string; end?: string }>
    ) => {
      state.startDate = action.payload.start
      state.endDate = action.payload.end
    },
    selectMode: (state, action: PayloadAction<Mode>) => {
      state.mode = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, () => initialState)
  },
})

export const { applyFilter, applyProject, applyDateRange, selectMode } =
  insightsSlice.actions

export default insightsSlice.reducer
