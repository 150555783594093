import React from 'react'

export const replaceNewLineEmojiAsString = (text: string) => {
  // When a string only is required
  return text.replace(/〽/g, '\n')
}

export const replaceNewLineEmojiAsLineBreak = (text: string) => {
  return text.replace(/〽/g, '\n').trim().replace(/\n/g, '<br />')
}

export const replaceLineBreakAsNewLineEmoji = (text: string) => {
  return text.replace(/\n/g, '〽')
}

export const replaceNewLineEmojiAsElement = (text: string) => {
  const specialCharacter = '〽'

  // Return result as HTML element
  const lineBreaksCount = text?.split(specialCharacter).length

  if (lineBreaksCount > 1) {
    const textArr = [...text].map((t, i) =>
      t === specialCharacter ? <br key={i} /> : t
    )

    return <span>{textArr}</span>
  } else {
    return <span>{text}</span>
  }
}

export const ignoreNewLineEmoji = (text: string) => {
  // When a string only is required
  return text.replace(/〽/g, ' ')
}

export function TextWithLineBreaks({ text }) {
  return (
    <>
      {text.split('〽').map((line, index, array) => (
        <React.Fragment key={index}>
          {line}
          {index < array.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  )
}
