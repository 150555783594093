/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import { Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import moment from 'moment-timezone'
import {
  cancelEpsilonReactCampaign,
  deleteVariantsFromEpsilon,
  fetchCampaignData,
  fetchEpsilonTimeZone,
  initializeReactEpsilonCampaign,
} from 'workflow/Workflow.actions'

import Alert from 'common/components/alert'
import Loader from 'common/components/loaders/Loader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Time as TimeIcon } from 'common/icons'
import CreateVariantsCard from 'features/campaigns/integration-options/components/card/CreateVariantsCard'
import {
  Date,
  Time,
} from 'features/campaigns/integration-options/components/scheduler/interface'
import Scheduler from 'features/campaigns/integration-options/components/scheduler/Scheduler'

import { CreateDeleteButtons } from './components/CreateDeleteButtons'
import CreateVariantsForm from './components/CreateVariantsForm'
import { FrequencySelector } from './components/FrequencySelector'
import { SeedListSelector } from './components/SeedListSelector'

const {
  REACT_APP_CONNECT_INTERNAL_END_POINT,
  REACT_APP_CONNECT_INTERNAL_V1_URL,
} = process.env

const DATE_FORMAT = process.env.REACT_APP_DEFAULT_DATE_FORMAT || 'YYYY-MM-DD'
export const urlStart = `${REACT_APP_CONNECT_INTERNAL_END_POINT}/${REACT_APP_CONNECT_INTERNAL_V1_URL}/epsilon/projects`

export interface SchedulerState {
  startDate: Date
  startTime?: Time
  endDate: Date
  endTime?: Time
}
const initialSchedulerState: SchedulerState = {
  startDate: null,
  endDate: null,
}

type Props = {
  onSuccess?: () => void
  onCancel?: () => void
}

export default function EpsilonReactIntegration({
  onSuccess,
  onCancel,
}: Props) {
  const { projectId, isWaitingState, epsilonTimeZone, currentCampaignId } =
    useAppSelector((state) => state.campaignStates)

  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<string>('')
  const [selectedSeedList, setSelectedSeedList] = useState<string>('')
  const [selectedCampaign, setSelectedCampaign] = useState<string>('')
  const [selectedSequence, setSelectedSequence] = useState<string>('')
  const [isCampaignScheduled, setIsCampaignScheduled] = useState<boolean>(false)
  const [campaignStatus, setCampaignStatus] = useState<string>('idle')
  const [isVariantsPushed, setIsVariantsPushed] = useState<boolean>(false)
  const [hasBeenInitialized, setHasBeenInitialized] = useState<boolean>(false)
  const [frequency, setFrequency] = useState<string>('days')
  const [selectedFolder, setSelectedFolder] = useState<string>('')
  const [schedulerState, setSchedulerState] = useState<SchedulerState>(
    initialSchedulerState
  )
  const dispatch = useAppDispatch()

  const { endTime, endDate, startDate, startTime } = schedulerState

  const isLoading: boolean =
    (isWaitingState.isWaitingFor === 'deleteVariantsFromEpsilon' ||
      isWaitingState.isWaitingFor === 'fetchingEpsilonBusinessUnits' ||
      isWaitingState.isWaitingFor === 'fetchingEpsilonFolders' ||
      isWaitingState.isWaitingFor === 'fetchingEpsilonCampaigns' ||
      isWaitingState.isWaitingFor === 'fetchingEpsilonSequences' ||
      isWaitingState.isWaitingFor === 'initializeReactEpsilonCampaign' ||
      isWaitingState.isWaitingFor === 'fetchEpsilonTimeZone' ||
      isWaitingState.isWaitingFor === 'fetchingSeedList' ||
      isWaitingState.isWaitingFor === 'cancelEpsilonCampaign' ||
      campaignStatus === 'loading') &&
    isWaitingState.isWaiting

  // We want to hide this option for now.
  const IS_SEED_LIST_ENABLED = false

  useEffect(() => {
    if (!epsilonTimeZone) {
      dispatch(fetchEpsilonTimeZone(projectId))
    }
  }, [projectId, epsilonTimeZone, dispatch])
  const handleSchedulerChange = (name: string, value: Date) => {
    setSchedulerState({ ...schedulerState, [name]: value })
  }
  const createSplits = (): void => {
    // We rebuild the optimisation start/end time with the date and time information.
    const optimizationStartTime = schedulerState.startDate
    optimizationStartTime?.hour(schedulerState.startTime?.hour() as number)
    optimizationStartTime?.minute(schedulerState.startTime?.minute() as number)
    optimizationStartTime?.second(schedulerState.startTime?.second() as number)
    const optimizationEndTime = schedulerState.endDate
    optimizationEndTime?.hour(schedulerState.endTime?.hour() as number)
    optimizationEndTime?.minute(schedulerState.endTime?.minute() as number)
    optimizationEndTime?.second(schedulerState.endTime?.second() as number)

    const payload = {
      epsilon_campaign_id: selectedCampaign,
      folder_id: selectedFolder,
      business_unit_id: selectedBusinessUnit,
      epsilon_sequence_id: selectedSequence,
      frequency,
      optimization_start_time: moment(optimizationStartTime).toISOString(),
      optimization_end_time: moment(optimizationEndTime).toISOString(),
    }
    dispatch(
      initializeReactEpsilonCampaign(
        projectId,
        currentCampaignId,
        payload,
        () => {
          setIsVariantsPushed(true)
          setHasBeenInitialized(true)
          setIsCampaignScheduled(true)
          dispatch(fetchCampaignData(currentCampaignId))
          onSuccess && onSuccess()
        }
      )
    )
  }

  const deleteVariant = (): void => {
    const payload = { business_unit_id: selectedBusinessUnit }
    dispatch(
      deleteVariantsFromEpsilon(projectId, currentCampaignId, payload, () => {
        setSelectedBusinessUnit('')
        setSelectedFolder('')
        setSelectedCampaign('')
        setSelectedSequence('')
        setIsVariantsPushed(false)
        setSchedulerState({
          startDate: null,
          startTime: undefined,
          endDate: null,
          endTime: undefined,
        })
        setHasBeenInitialized(false)
        setIsCampaignScheduled(false)
        dispatch(fetchCampaignData(currentCampaignId))
        onCancel && onCancel()
      })
    )
  }

  const cancelCampaign = () => {
    const callback = () => {
      setIsCampaignScheduled(false)
      setSchedulerState({
        startDate: null,
        startTime: undefined,
        endDate: null,
        endTime: undefined,
      })
      setCampaignStatus('idle')
    }
    dispatch(
      cancelEpsilonReactCampaign(
        projectId,
        currentCampaignId,
        selectedBusinessUnit,
        callback
      )
    )
  }

  const hasOptimisationStartTimePassed = (): boolean => {
    if (epsilonTimeZone) {
      const optimizationStartTime = schedulerState.startDate
      optimizationStartTime?.hour(schedulerState.startTime?.hour() as number)
      optimizationStartTime?.minute(
        schedulerState.startTime?.minute() as number
      )
      optimizationStartTime?.second(
        schedulerState.startTime?.second() as number
      )
      return moment()
        .tz(epsilonTimeZone)
        .isAfter(moment(optimizationStartTime).tz(epsilonTimeZone, true))
    } else {
      return false
    }
  }

  return (
    <>
      <Drawer.Content>
        <Form>
          <Loader isLoading={isLoading}>
            <div className="mb-8 text-base font-medium text-coolGray-500">
              Schedule your Jacquard experiment with your Epsilon account.
            </div>
            <CreateVariantsForm
              selectedBusinessUnit={selectedBusinessUnit}
              selectedFolder={selectedFolder}
              selectedCampaign={selectedCampaign}
              selectedSequence={selectedSequence}
              schedulerState={schedulerState}
              projectId={projectId}
              setSelectedBusinessUnit={setSelectedBusinessUnit}
              setSelectedCampaign={setSelectedCampaign}
              setSelectedFolder={setSelectedFolder}
              setSelectedSequence={setSelectedSequence}
              setIsVariantsPushed={setIsVariantsPushed}
              setSchedulerState={setSchedulerState}
              setIsCampaignScheduled={setIsCampaignScheduled}
              setHasBeenInitialized={setHasBeenInitialized}
              setSelectedSeedList={setSelectedSeedList}
              isVariantsPushed={isVariantsPushed}
              isCampaignScheduled={isCampaignScheduled}
            />
            <FrequencySelector
              value={frequency}
              onChange={setFrequency}
              isDisabled={
                isCampaignScheduled || !selectedSequence || hasBeenInitialized
              }
            />
            <Scheduler
              icon={<TimeIcon size={6} />}
              minuteStep={15}
              endDate={endDate}
              endTime={endTime}
              timeFormat="HH:mm"
              timeZone={epsilonTimeZone}
              dateFormat={DATE_FORMAT}
              integrationType="Epsilon"
              isStartDateDisabled={
                isCampaignScheduled || hasBeenInitialized || !selectedSequence
              }
              isStartTimeDisabled={
                isCampaignScheduled || !startDate || hasBeenInitialized
              }
              isEndDateDisabled={
                isCampaignScheduled ||
                !startDate ||
                !startTime ||
                hasBeenInitialized
              }
              isEndTimeDisabled={
                isCampaignScheduled ||
                !startDate ||
                !startTime ||
                !endDate ||
                hasBeenInitialized
              }
              startDate={startDate}
              startTime={startTime}
              onChange={(val, type) => handleSchedulerChange(type, val)}
              title="Optimisation schedule"
              endTimeTitle="End schedule"
              startTimeTitle="Start schedule"
            />
            {IS_SEED_LIST_ENABLED && (
              <SeedListSelector
                selectedSeedList={selectedSeedList}
                setSelectedSeedList={setSelectedSeedList}
                selectedBusinessUnit={selectedBusinessUnit}
                projectId={projectId}
                isDisabled={
                  isCampaignScheduled ||
                  !selectedSequence ||
                  !startDate ||
                  !startTime ||
                  !endDate ||
                  !endTime
                }
              />
            )}
            <CreateDeleteButtons
              deleteVariant={deleteVariant}
              createSplits={createSplits}
              isDisplayingDeleteButton={hasOptimisationStartTimePassed()}
              isCampaignScheduled={isCampaignScheduled}
              isVariantsPushed={isVariantsPushed}
              isCreateDisabled={
                isCampaignScheduled ||
                isVariantsPushed ||
                !selectedBusinessUnit ||
                !selectedFolder ||
                !selectedCampaign ||
                !selectedSequence ||
                !startDate ||
                !startTime ||
                !endDate ||
                !endTime
              }
            />
            <Alert
              className="mb-6 flex items-center"
              type="warn"
              children="Make sure your campaign is in an Approved state in Epsilon. Only
        approved campaigns in Epsilon can be scheduled."
            />
          </Loader>
        </Form>
        {startDate && startTime && hasOptimisationStartTimePassed() && (
          <CreateVariantsCard
            isDisabled={!isCampaignScheduled}
            isLoading={false}
            cardText="This will stop important data from Epsilon and cancel this experiment schedule. This will not delete any data you have already received."
            cardTitle="Cancel experiment schedule"
            buttonText="Cancel"
            onSubmit={() => cancelCampaign()}
          />
        )}
      </Drawer.Content>
    </>
  )
}
