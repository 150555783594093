import { generatePath } from 'react-router'
import { unifiedFlowPaths } from 'app/navigation/paths'
import cx from 'classnames'

import { SubNavItem } from 'common/components/navigation/subNavBar'
import { getStepStatus } from 'features/unifiedFlow/contentPage/helpers'
import { useContent } from 'features/unifiedFlow/contentPage/hooks'

import ApprovalCounterBadge from './ApprovalCounterBadge'

type Props = {
  className?: string
}

const HeaderNavigation = ({ className }: Props) => {
  const { content } = useContent()

  const launchStepsStatus = getStepStatus(content?.steps_status, 'launch')
  const resultsStepsStatus = getStepStatus(content?.steps_status, 'results')
  const reviewStepStatus = getStepStatus(content?.steps_status, 'review')

  if (!content) {
    return null
  }

  return (
    <div
      className={cx(
        'flex justify-between items-center bg-maroon-600',
        className
      )}
    >
      <div
        className="flex items-center justify-end font-medium gap-6 md:gap-10 text-sm whitespace-nowrap"
        data-testid="nav-links"
      >
        <SubNavItem
          key="unified-flow-content-generate"
          to={generatePath(unifiedFlowPaths.generate, {
            contentId: content._id,
          })}
          title="1. Generate"
        />
        <SubNavItem
          key="unified-flow-content-launch"
          to={generatePath(unifiedFlowPaths.launch, {
            contentId: content._id,
          })}
          title="2. Launch"
          isDisabled={launchStepsStatus === 'disabled'}
        />
        {reviewStepStatus !== 'hidden' && (
          <ApprovalCounterBadge>
            <SubNavItem
              key="unified-flow-personalizations-approvals"
              to={generatePath(unifiedFlowPaths.review, {
                contentId: content._id,
              })}
              isDisabled={reviewStepStatus === 'disabled'}
              title="3. Review"
            />
          </ApprovalCounterBadge>
        )}
        {resultsStepsStatus !== 'hidden' && (
          <SubNavItem
            key="unified-flow-content-results"
            to={generatePath(unifiedFlowPaths.results, {
              contentId: content._id,
            })}
            isDisabled={resultsStepsStatus === 'disabled'}
            title={`${reviewStepStatus !== 'hidden' ? '4' : '3'}. Results`}
          />
        )}
      </div>
    </div>
  )
}

export default HeaderNavigation
