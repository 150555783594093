import React from 'react'

import MultiSelect from 'common/components/MultiSelect'
import Searchbox from 'features/unifiedFlow/components/Searchbox'

type Props = {
  selectedAccounts: string[]
  onChangeAccounts: (value: string[]) => void
  selectedStatuses: string[]
  onChangeStatuses: (value: string[]) => void
  onSearchCampaign: (value: string) => void
  accounts: { label: string; value: string; id: string }[]
  statuses: { label: string; value: string; id: string }[]
  campaignNames: { label: string; value: string }[]
}

const Header: React.FC<Props> = ({
  selectedAccounts,
  onChangeAccounts,
  selectedStatuses,
  onChangeStatuses,
  onSearchCampaign,
  accounts,
  statuses,
  campaignNames,
}) => {
  return (
    <div className="flex justify-between">
      <div className="flex gap-6">
        <MultiSelect
          className="w-52 flex-none"
          data-cy="account-select"
          data-testid="account-select"
          name="account"
          label="Account"
          placeholder="Select your account"
          onApply={(value) => value && onChangeAccounts(value)}
          selectedItems={selectedAccounts}
          items={accounts}
        />
        <MultiSelect
          className="w-52 flex-none"
          data-cy="status-select"
          data-testid="status-select"
          name="status"
          label="Status"
          placeholder="Select your status"
          onApply={(value) => value && onChangeStatuses(value)}
          selectedItems={selectedStatuses}
          items={statuses}
        />
      </div>
      <div className="flex gap-8 items-end">
        <Searchbox
          className="w-52 flex-none"
          aria-label="Search campaign"
          placeholder="Search campaign"
          options={campaignNames}
          onSearchContentValue={onSearchCampaign}
        />
      </div>
    </div>
  )
}

export default Header
