import Banner from 'common/components/banner'
import { AlertCircle } from 'common/icons'

const RawDataTabBanner = () => {
  return (
    <Banner
      type="info"
      icon={<AlertCircle className="text-base-700" isDefaultColor={false} />}
      text="Raw data is not necessarily representative of experiment performance. High performing variants will receive the most sends.
            Sends that have occurred within the last 48 hours will not have received the full set of open / click data, making the open 
            rate / click rate for those variants look low. For a true view on performance, please see the Mature Data tab."
    />
  )
}

export default RawDataTabBanner
