import * as GraphWidget from 'common/components/GraphWidget'
import { WidgetError, WidgetFirstUse } from 'common/components/WidgetMessage'
import { useChartRefCallback } from 'common/hooks/useChartRefCallback'

import WidgetLoader from './loaders/WidgetLoader'
import ChartWidgetMenu from './widget/menu/ChartWidgetMenu'
import IncrementalEventsGraph, {
  IncrementalEventsData,
} from './IncrementalEventsGraph'
import InfoAction from './InfoAction'

type Props = {
  title: string
  info: string
  isLoading: boolean
  xAxisLabels: string[]
  data: IncrementalEventsData[]
  hasError: boolean
}

const IncrementalEventsWidget = ({
  title,
  info,
  isLoading,
  xAxisLabels,
  data,
  hasError,
}: Props) => {
  const { chart, chartRefCallback } = useChartRefCallback()

  const firstUse = !isLoading && !data.length && !hasError
  const hasResults = !isLoading && !hasError && data.length

  return (
    <GraphWidget.Widget
      type="inner"
      title={title}
      data-cy="incremental-events-widget"
      data-testid="incremental-events-widget"
      actions={
        hasResults ? (
          <div className="inline-flex">
            <ChartWidgetMenu
              chart={chart}
              title={title}
              problematicExports={['pdf', 'print']}
              exportOptions={{
                legend: { symbolWidth: 16 },
              }}
            />
            <InfoAction message={info} />
          </div>
        ) : undefined
      }
    >
      {hasError && <WidgetError className="mb-6" />}
      {isLoading && (
        <WidgetLoader
          data-cy="incremental-events-widget-loader"
          data-testid="incremental-events-widget-loader"
        />
      )}

      {!isLoading && !!data.length ? (
        <IncrementalEventsGraph
          data={data}
          xAxisLabels={xAxisLabels}
          ref={chartRefCallback}
          type={title.toLowerCase().includes('opens') ? 'opens' : 'clicks'}
        />
      ) : undefined}

      {firstUse ? (
        <WidgetFirstUse shouldShowCreateCampaignButton className="mb-6 mr-6" />
      ) : undefined}
    </GraphWidget.Widget>
  )
}

export default IncrementalEventsWidget
