import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'

import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import { createCustomerJourney } from '../api'
import { contentKeys } from '../queryKeys'

const useCreateCustomerJourneyMutation = () => {
  const queryClient = useQueryClient()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return useMutation(
    ({
      customerJourneyName,
      contextDescription,
    }: {
      customerJourneyName: string
      contextDescription: string
    }) =>
      createCustomerJourney({
        customerJourneyName,
        contextDescription,
        accountId,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(contentKeys.customerJourneys(accountId))
      },
      onError: (error) => {
        const errorMessage =
          axios.isAxiosError(error) && error.response?.data?.message
            ? error.response.data.message
            : 'Something went wrong. Please try again'
        errorToast(errorMessage)
      },
    }
  )
}

export default useCreateCustomerJourneyMutation
