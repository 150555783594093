import Button from 'common/components/button'
import Loader from 'common/components/loaders/Loader'
import { Topic } from 'common/components/topics/interfaces'
import { SmallTick } from 'common/icons'

import Footer from '../../components/footer'
import Option from '../../components/option'

interface Props {
  isLoading: boolean
  replacers: Topic[] | undefined
  onPreviousClick: () => void
  onFinishedClick: () => void
}

const AddSpecifics = ({
  isLoading,
  replacers,
  onPreviousClick,
  onFinishedClick,
}: Props) => {
  return (
    <div className="h-full flex flex-col justify-between gap-10">
      <div className="flex flex-col w-150 self-center h-full">
        <div className="mt-5 mb-3 flex items-center gap-4 text-2xl font-bold text-coolGray-800">
          <span>Add a few specifics</span>
        </div>
        <div className="mb-9 text-lg text-coolGray-500">
          Based on the topics you selected we found the following items. Please
          choose the ones you would like to activate.
        </div>
        <Loader isLoading={isLoading}>
          <div className="relative w-full h-full overflow-y-auto">
            <div className="absolute top-0 w-full">
              {replacers?.map((replacer) => {
                const { id } = replacer

                return <Option key={id} topic={replacer} />
              })}
            </div>
          </div>
        </Loader>
      </div>

      <Footer>
        <Button
          data-cy="previous-button"
          data-testid="previous-button"
          className="mr-auto"
          ghost
          onClick={onPreviousClick}
        >
          Previous
        </Button>
        <div className="col-start-2 self-center">Add topics</div>
        <Button
          data-cy="finished-button"
          data-testid="finished-button"
          className="place-self-end"
          variant="primary"
          prefixIcon={
            <SmallTick isDefaultColor={false} className="text-white" />
          }
          onClick={onFinishedClick}
        >
          Finished
        </Button>
      </Footer>
    </div>
  )
}

export default AddSpecifics
