import { useCallback, useEffect, useMemo, useState } from 'react'
import { PersonalizedVariant } from '@phrasee/phrasee-typings/typings/futurama/element'
import { unifiedFlowPaths } from 'app/navigation/paths'
import cx from 'classnames'

import Autocomplete from 'common/components/autocomplete'
import Badge from 'common/components/Badge'
import ConfirmationModalWithResetState from 'common/components/confirmationModal'
import BaseCell from 'common/components/table/cells/Base'
import { Column } from 'common/components/table/Table'
import TableWidget from 'common/components/widget/tableWidget'
import useTableActions from 'common/hooks/useTableActions'
import useApproveElementMutation from 'features/unifiedFlow/api/mutations/useApproveElementMutation'
import useDeletePersonalizedVariantMutation from 'features/unifiedFlow/api/mutations/useDeletePersonalizedVariantMutation'
import ActionCell from 'features/unifiedFlow/contentPage/components/ActionsCell'
import TweakVariantModal from 'features/unifiedFlow/contentPage/components/tweakVariantModal'
import useSelectedVariant from 'features/unifiedFlow/contentPage/generate/centerSection/optimizeResults/useSelectedVariant'
import { checkVariantActionLoading } from 'features/unifiedFlow/contentPage/helpers'
import {
  useContent,
  useSelectedComponentElement,
} from 'features/unifiedFlow/contentPage/hooks'
import useTweakSuccess from 'features/unifiedFlow/contentPage/hooks/useTweakSuccess'
import useVariantTweakWebsocketSubscription from 'features/unifiedFlow/contentPage/hooks/useVariantTweakWebsocketSubscription'

import ApproveButton from './ApproveButton'
import ApproveVariantsConfirmationModal from './ApproveVariantsConfirmationModal'

export type ExtendedPersonalizedVariant = PersonalizedVariant & {
  isHighlighted: boolean
  isLoading?: boolean
  isRefreshed?: boolean
  category: string | undefined
}

type PersonalizedVariantWithLoading = PersonalizedVariant & {
  isLoading?: boolean
}

type Props = {
  selectedPersonalizationId: string | undefined
  onSelectPersonalization: (personalizationId: string | undefined) => void
}

const PersonalizedVariantsTable = ({
  selectedPersonalizationId,
  onSelectPersonalization,
}: Props) => {
  const { onCopy, onRequestChange } = useTableActions()

  const deletePersonalizedVariantMutation =
    useDeletePersonalizedVariantMutation()
  const [variantIdToDelete, setVariantIdToDelete] =
    useState<string | undefined>()

  const { content } = useContent()
  const [variantSearchValue, setVariantSearchValue] = useState('')
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false)

  const { selectedVariantId, setSelectedVariantId } = useSelectedVariant()

  const { data: selectedElement } = useSelectedComponentElement()
  const variants: PersonalizedVariantWithLoading[] | undefined =
    selectedElement?.personalized_variants

  const selectedElementId = selectedElement?.element_id

  const { onTweakSuccess } = useTweakSuccess()

  useVariantTweakWebsocketSubscription({ onTweakSuccess })

  const approveElementMutation = useApproveElementMutation({
    redirectTo: unifiedFlowPaths.results,
    selectedElementId,
  })

  useEffect(() => {
    if (approveElementMutation.status === 'success') {
      onSelectPersonalization(undefined)
      setVariantSearchValue('')
    }
  }, [approveElementMutation.status, onSelectPersonalization])

  const variantsWithHighlightedState = variants?.map((variant) => {
    const isSelected = selectedVariantId === variant.variant_id

    return {
      ...variant,
      isHighlighted: isSelected,
    }
  })

  const filterByVariantName = useCallback(
    ({ text }) => {
      const normalizedSearchValue = variantSearchValue
        .replaceAll(' ', '')
        .toLowerCase()

      return text
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(normalizedSearchValue)
    },
    [variantSearchValue]
  )

  const filterByCombination = useCallback(
    ({ combinationId }) =>
      selectedPersonalizationId === undefined
        ? true
        : combinationId === selectedPersonalizationId,
    [selectedPersonalizationId]
  )

  const filterOnlyVariantsToApprove = useCallback(
    ({ variant_id }) =>
      selectedElement?.personalized_ids_to_approve?.includes(variant_id),
    [selectedElement]
  )

  const filteredVariants = useMemo(() => {
    const mappedVariants = variantsWithHighlightedState
      ?.filter(filterByVariantName)
      .filter(filterByCombination)
      .filter(filterOnlyVariantsToApprove)
      .map((variant) => {
        const isModifiedByUser = !!variant?.why_tweak
        const isLoading = checkVariantActionLoading({
          content,
          selectedElement,
          variantId: variant.variant_id,
        })

        return {
          ...variant,
          category: isModifiedByUser ? 'User modified' : undefined,
          isLoading: variant.isLoading || isLoading,
        }
      })
    return mappedVariants?.toSorted(
      (a, b) => (a.category ? 1 : 0) - (b.category ? 1 : 0)
    )
  }, [
    filterByVariantName,
    filterByCombination,
    filterOnlyVariantsToApprove,
    variantsWithHighlightedState,
    content,
    selectedElement,
  ])

  const isApproveButtonDisabled =
    filteredVariants?.some((variant) => variant.isLoading) ?? false

  const searchOptions = useMemo(() => {
    return filteredVariants
      ?.filter((variant) => variant.variant_id !== undefined)
      .map((variant) => ({
        value: variant.variant_id,
        label: variant.text,
      }))
  }, [filteredVariants])

  const areVariantsFiltered =
    filteredVariants?.length !==
    selectedElement?.personalized_ids_to_approve?.length

  const approveButtonText = useMemo(() => {
    if (areVariantsFiltered || selectedPersonalizationId) {
      if (filteredVariants?.length === 1) {
        return 'Approve 1 variant'
      }
      return `Approve ${filteredVariants?.length} variants`
    }
    return 'Approve all'
  }, [areVariantsFiltered, selectedPersonalizationId, filteredVariants?.length])

  const handleApprove = () => {
    if (selectedElement && content) {
      const variantIds = filteredVariants
        ?.map(({ variant_id }) => variant_id)
        .filter((id): id is string => id !== undefined)

      approveElementMutation.mutate({
        accountId: content.account_id,
        element_id: selectedElement.element_id,
        content_id: content?._id,
        variant_ids: variantIds,
      })
    }
  }

  const columns: Column<ExtendedPersonalizedVariant>[] = useMemo(
    () => [
      {
        Header: `Variants to approve (${filteredVariants?.length})`,
        accessor: 'text',
        disableSortBy: true,
        direction: 'auto',
        Cell: ({ row }) => {
          return (
            <BaseCell className="min-h-14">
              <div className="flex gap-4">
                <div>{row.original.text}</div>
                {row.original.control && (
                  <div>
                    <Badge variant="info" text="Control" size="small" />
                  </div>
                )}
              </div>
            </BaseCell>
          )
        },
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: 'variant_id',
        disableSortBy: true,
        align: 'right',
        disableFilters: true,
        width: 140,
        className: 'flex-none',
        Cell: ({ row }) => {
          return (
            <ActionCell
              onCopy={() => onCopy(row.values.text)}
              onRequestChange={() => onRequestChange(row.original)}
              isTweaking={row.original.isLoading}
              isSuccessful={row.original.isRefreshed}
              onDelete={() => {
                setVariantIdToDelete(row.original.variant_id)
              }}
            />
          )
        },
      },
      {
        accessor: 'category',
      },
    ],
    [onCopy, filteredVariants?.length, onRequestChange]
  )

  const handleRowClick = (variant: ExtendedPersonalizedVariant) => {
    setSelectedVariantId(variant.variant_id)
  }

  const isFirstRowGrouped = !!filteredVariants?.[0]?.category

  return (
    <div className="w-full">
      <div className="flex justify-end">
        <Autocomplete
          data-testid="search-variants-input"
          aria-label="Search variants"
          placeholder="Search variants"
          options={searchOptions}
          value={{ value: '', label: '' }}
          onSearch={setVariantSearchValue}
          inputValue={variantSearchValue}
          backspaceRemovesValue={true}
          className="w-85 mb-6"
        />
      </div>
      <div className="w-full" data-testid="personalized-variants-table">
        <TableWidget.Widget<ExtendedPersonalizedVariant>
          key={selectedElementId}
          data={filteredVariants}
          columns={columns}
          autoResetPage={false}
          onRowClick={handleRowClick}
          headerClassName={cx({
            'bg-transparent text-base-700': isFirstRowGrouped,
          })}
          initialState={{ groupBy: ['category'], hiddenColumns: ['category'] }}
          firstUseText="No variants could match the search criteria..."
        >
          {(filteredVariants?.length ?? 0) > 0 && (
            <TableWidget.ActionButtons className="w-full flex justify-end">
              <ApproveButton
                isDisabled={
                  filteredVariants?.length === 0 ||
                  approveElementMutation.isLoading ||
                  isApproveButtonDisabled
                }
                isLoading={approveElementMutation.isLoading}
                buttonText={approveButtonText}
                onClick={() => {
                  if (!areVariantsFiltered && !selectedPersonalizationId) {
                    setIsConfirmationVisible(true)
                  } else {
                    handleApprove()
                  }
                }}
              />
            </TableWidget.ActionButtons>
          )}
        </TableWidget.Widget>
      </div>
      <ConfirmationModalWithResetState
        onCancel={() => setVariantIdToDelete(undefined)}
        open={variantIdToDelete !== undefined}
        onConfirm={() => {
          if (
            content?.account_id &&
            content?._id &&
            selectedElement?.element_id &&
            variantIdToDelete
          ) {
            deletePersonalizedVariantMutation.mutate({
              accountId: content.account_id,
              contentId: content._id,
              elementId: selectedElement.element_id,
              subjectLineId: variantIdToDelete,
            })
          }
          setVariantIdToDelete(undefined)
        }}
        confirmationText="This is a permanent action you cannot undo"
        confirmButtonText="Delete"
        title="Are you sure you want to delete this variant?"
      />
      <ApproveVariantsConfirmationModal
        isOpen={isConfirmationVisible}
        variantsLength={
          selectedElement?.personalized_ids_to_approve?.length ?? 0
        }
        onClickCancel={() => setIsConfirmationVisible(false)}
        onClickApprove={handleApprove}
        isLoading={approveElementMutation.isLoading}
      />
      <TweakVariantModal campaignType="linguo" />
    </div>
  )
}

export default PersonalizedVariantsTable
