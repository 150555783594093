import type {
  ImageEntity,
  ImagePageResponse,
} from 'common/components/images/api/api'
import ImagePagination from 'common/components/images/imagePagination'

import ImageCard from './imageCard'

type Props = ImagePageResponse & {
  selectedImagesIds: string[]
  onSelectImage: (image: Pick<ImageEntity, 'id' | 'fileName' | 'name'>) => void
  onDeleteImage: (image: Pick<ImageEntity, 'id' | 'fileName' | 'name'>) => void
}

const ImageGallery = ({
  data,
  total,
  selectedImagesIds,
  onSelectImage,
  onDeleteImage,
}: Props) => {
  return (
    <>
      <div className="flex flex-1 overflow-y-auto relative">
        <div
          className="grid w-full gap-2.5 absolute"
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(242px, 1fr))',
          }}
        >
          {data.map(({ id, fileName, name, fileSize, updatedAt, url }) => {
            return (
              <ImageCard
                key={id}
                id={id}
                fileName={name ?? fileName}
                fileSize={fileSize}
                url={url}
                updatedAt={updatedAt}
                isSelected={selectedImagesIds.includes(id)}
                onClick={() => onSelectImage({ fileName, name, id })}
                onDelete={() => onDeleteImage({ fileName, name, id })}
                onPreview={() => {}}
              />
            )
          })}
        </div>
      </div>

      <ImagePagination className="mt-8.5 mb-3" total={total} />
    </>
  )
}

export default ImageGallery
