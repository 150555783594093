import { useMutation } from '@tanstack/react-query'

import { generateImageTextCombinations } from 'features/unifiedFlow/api/api'
import { useSelectedElement } from 'features/unifiedFlow/contentPage/hooks'

const useGenerateImageTextCombinationsMutation = () => {
  const { data: selectedElement } = useSelectedElement()

  return useMutation({
    mutationFn: ({ imageIds }: { imageIds: string[] }) =>
      generateImageTextCombinations({
        campaignId: selectedElement?.campaign_id!,
        imageIds,
      }),
  })
}

export default useGenerateImageTextCombinationsMutation
