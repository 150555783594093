import { useMemo } from 'react'
import { IntegrationAccount } from '@phrasee/phrasee-typings'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ActionIcon from 'common/components/ActionIcon'
import Avatar from 'common/components/Avatar'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget'
import { formatDate } from 'common/helpers/formatDate'
import { Bin, Edit } from 'common/icons'
import { avatarColors } from 'features/profile/AvatarColorPicker'

type Props = {
  accounts: IntegrationAccount[]
  onUpdate: (integrationAccount: IntegrationAccount) => void
  onDelete: (integrationAccount: IntegrationAccount) => void
}

const AccountsList = ({ accounts, onUpdate, onDelete }: Props) => {
  const flags = useFlags()

  const columns: Column<IntegrationAccount>[] = useMemo(
    () => [
      {
        Header: 'Provider',
        accessor: 'provider',
        className: 'uppercase',
        Cell: ({ value, row }) => (
          <BaseCell>
            <span className="capitalize">{`${value} ${
              row.original.label ?? ''
            }`}</span>
          </BaseCell>
        ),
        width: 400,
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: ({ value }) => (
          <BaseCell className="capitalize">{formatDate(value)}</BaseCell>
        ),
        align: 'right',
        width: 100,
        className: 'uppercase',
      },
      {
        Header: 'Added By',
        accessor: 'userName',
        className: 'uppercase',
        Cell: ({ value, row }) => {
          const [firstName, lastName] = value ? value.split(' ') : []

          return (
            <BaseCell className="mx-auto">
              <Avatar
                firstName={firstName}
                lastName={lastName}
                className="text-xs w-7 h-7"
                color={avatarColors[row.index % avatarColors.length]}
              />
            </BaseCell>
          )
        },
        align: 'right',
        width: 80,
      },
      {
        Header: 'Actions',
        accessor: 'id',
        align: 'right',
        width: 70,
        disableSortBy: true,
        className: 'uppercase',
        Cell: ({ row }) =>
          flags?.s1UpdateIntegration || flags?.s1DeleteIntegration ? (
            <BaseCell className={cx('w-full justify-end space-x-4')}>
              {flags?.s1DeleteIntegration && (
                <ActionIcon onClick={() => onDelete(row.original)}>
                  <Bin
                    isDefaultColor={false}
                    className="text-base-700 hover:text-maroon-600"
                  />
                </ActionIcon>
              )}

              {flags?.s1UpdateIntegration && (
                <ActionIcon onClick={() => onUpdate(row.original)}>
                  <Edit
                    isDefaultColor={false}
                    className="text-base-700 hover:text-maroon-600"
                  />
                </ActionIcon>
              )}
            </BaseCell>
          ) : null,
      },
    ],
    [onDelete, onUpdate, flags?.s1UpdateIntegration, flags?.s1DeleteIntegration]
  )

  return <TableWidget.Widget columns={columns} data={accounts} />
}

export default AccountsList
