/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import helpers from 'workflow/utils/helpers'

import { SelectValue, SingleValue } from 'common/components/singleSelect'
import { capitalizeFirstLetter } from 'common/helpers/string'
import { useAppSelector } from 'common/hooks/redux'

import {
  AdvancedSettingsActions,
  AdvancedSettingsState,
} from '../../advancedOptionsReducer'
import { OptimizationMetric, OptimizationMode } from '../../types'

import {
  clicksToOpens,
  clicksToSends,
  DEFAULT_HIGH_PERFORMERS_NUMBER,
  DEFAULT_PROPORTION_SENT_TO_WINNER_NUMBER,
  opensToSends,
  phraseeScore,
  singleBest,
  testId,
} from './constants'
import { labelText } from './constants'
import {
  confidence,
  highPerformers as highPerformersConst,
  optimizationMetricOptions,
  optimizationModeOptions,
} from './constants'
import { NumericInputWithSideLabel } from './NumericInputWithSideLabel'
import { PhraseeXSelector } from './PhraseeXSelector'

interface Props {
  advancedSettingsState: AdvancedSettingsState
  dispatchAdvancedSettings: (acton: AdvancedSettingsActions) => void
}

const genericOption = [
  {
    value: 'generic',
    label: 'Generic',
  },
]

export const AdvancedOptions = ({
  advancedSettingsState,
  dispatchAdvancedSettings,
}: Props) => {
  const {
    hasTrackedClickMetrics,
    highPerformersNumber,
    isAdvancedOptionsDisabled,
    isInBody,
    optimizationMetric,
    optimizationMode,
    proportionSendToWinnerNumber,
    testedContentSection,
  } = advancedSettingsState
  const variants = useAppSelector((state) => state.campaignStates.subjectLines)
  const campaignData = useAppSelector(
    (state) => state.campaignStates?.campaignData
  )

  const integrationConfiguration =
    campaignData.campaign_configuration?.integration_options?.configuration

  const hasIntegrationConfiguration = !!integrationConfiguration

  const integrationOptimizationMetrics =
    integrationConfiguration?.optimization_metrics

  const initialIntegrationOptimizationMetric =
    campaignData?.campaign_data?.bandit_data?.optimization_metric
  const initialIntegrationOptimizationAudience =
    campaignData?.campaign_data?.bandit_data?.optimization_audience

  const [integrationOptimizationMetric, setIntegrationOptimizationMetric] =
    useState<string>(
      integrationOptimizationMetrics?.find(
        (metric) =>
          metric.optimization_metric === initialIntegrationOptimizationMetric &&
          metric.optimization_audience ===
            initialIntegrationOptimizationAudience
      )?.display_name ?? ''
    )

  useEffect(() => {
    hasIntegrationConfiguration &&
      dispatchAdvancedSettings({
        type: 'set_optimization_metric',
        value: 'generic',
      })
  }, [hasIntegrationConfiguration, dispatchAdvancedSettings])

  const integrationConfigurationMetrics = [
    ...(integrationConfiguration?.optimization_metrics?.map((metric) => ({
      value: metric.display_name,
      label: capitalizeFirstLetter(metric.display_name),
    })) ?? []),
  ]

  const isEngageCampaign = helpers.isEngageCampaign(campaignData)
  const filteredOptimizationModeOptions = isEngageCampaign
    ? [singleBest]
    : optimizationModeOptions

  const isBloomreachMoengageInAppMessage =
    helpers.isBloomreachMoengageInAppMessage(campaignData)

  /**
   * [HG-1388] Handles these cases depending on campaign configuration.
   * 1. has TrackedClickMetrics and is in_body: [clicksToSends, clicksToOpens]
   * 2. does not have TrackedClickMetrics and in_body: []
   * 3. does not have TrackedClickMetrics and not in_body: [opensToSends]
   * 4. has TrackedClickMetrics and not in_body: optimizationMetricOptions (all options)
   * [HG-2093] New logic added
   * 5. has TrackedClickMetrics and testedContentSection is subject_line: [opensToSends, clicksToSends, phraseeScore]
   */
  const getOptimizationMetricOptions = () => {
    if (hasTrackedClickMetrics && isInBody) {
      return [clicksToSends, clicksToOpens]
    } else if (!hasTrackedClickMetrics && isInBody) {
      return []
    } else if (!hasTrackedClickMetrics && !isInBody) {
      return [opensToSends]
    } else if (
      hasTrackedClickMetrics &&
      testedContentSection === 'subject_line'
    ) {
      return [opensToSends, clicksToSends, phraseeScore]
    } else if (isBloomreachMoengageInAppMessage) {
      return [clicksToOpens]
    }
    return optimizationMetricOptions
  }

  const filteredOptimizationMetricOptions = getOptimizationMetricOptions()
    // Remove phrasee-score unless it is already selected
    .filter((x) =>
      optimizationMetric !== 'phrasee-score'
        ? x.value !== 'phrasee-score'
        : true
    )

  const createVariantsIndexesForSelector = () => {
    return variants.map((_variant, index) => {
      const value = (index + 1).toString()
      return { label: value, value }
    })
  }

  // Set default values for loaded campaign data when one of these values are undefined.
  const handleOptimizationModeChange = (value: OptimizationMode) => {
    if (value === 'random_high_performers') {
      dispatchAdvancedSettings({
        type: 'set_high_performers_number',
        value: highPerformersNumber ?? DEFAULT_HIGH_PERFORMERS_NUMBER,
      })
    }

    if (value === 'confidence') {
      dispatchAdvancedSettings({
        type: 'set_proportion_send_to_winner_number',
        value:
          proportionSendToWinnerNumber ??
          DEFAULT_PROPORTION_SENT_TO_WINNER_NUMBER,
      })
    }
    dispatchAdvancedSettings({
      type: 'set_optimization_mode',
      value,
    })
  }

  const variantsIndexesForSelector = createVariantsIndexesForSelector()

  const renderAdditionalOptionForOptimizationMode = () => {
    if (optimizationMode === highPerformersConst.value) {
      return createSelectionForHighPerformers()
    } else if (optimizationMode === confidence.value) {
      return createInputForConfidence()
    }
  }

  const createSelectionForHighPerformers = () => {
    return (
      <div className="mb-6 flex justify-start items-start">
        <PhraseeXSelector
          className="w-24"
          datatestId={testId.highPerformersSelector}
          isDisabled={isAdvancedOptionsDisabled}
          value={highPerformersNumber.toString()}
          onChange={(singleValue: SingleValue<SelectValue>) =>
            dispatchAdvancedSettings({
              type: 'set_high_performers_number',
              value: Number(singleValue?.value),
            })
          }
          options={variantsIndexesForSelector}
        />
        <span className="font-medium text-coolGray-800 ml-3">
          {labelText.topPerformers}
        </span>
      </div>
    )
  }

  const createInputForConfidence = () => {
    return (
      <NumericInputWithSideLabel
        data-testid={testId.proportionSentToWinnerInput}
        label={labelText.proportionSendToWinner}
        inputValue={Number(proportionSendToWinnerNumber)}
        onInputChange={(value) => {
          dispatchAdvancedSettings({
            type: 'set_proportion_send_to_winner_number',
            value,
          })
        }}
        minValue={50}
        maxValue={100}
        defaultValue={DEFAULT_PROPORTION_SENT_TO_WINNER_NUMBER}
        disabled={isAdvancedOptionsDisabled}
      />
    )
  }

  return (
    <>
      <PhraseeXSelector
        label={labelText.optimizationMode}
        className="mb-6 w-full"
        datatestId={testId.optimizationModeSelector}
        isDisabled={isAdvancedOptionsDisabled}
        value={optimizationMode}
        onChange={(singleValue: SingleValue<SelectValue>) =>
          handleOptimizationModeChange(singleValue?.value as OptimizationMode)
        }
        options={filteredOptimizationModeOptions}
      />
      {renderAdditionalOptionForOptimizationMode()}
      {!hasIntegrationConfiguration && (
        <PhraseeXSelector
          label={labelText.optimizationMetric}
          className="mb-6 w-full"
          datatestId={testId.optimizationMetricSelector}
          isDisabled={
            isAdvancedOptionsDisabled ||
            filteredOptimizationMetricOptions.length === 0 ||
            hasIntegrationConfiguration
          }
          value={hasIntegrationConfiguration ? 'generic' : optimizationMetric}
          onChange={(singleValue: SingleValue<SelectValue>) =>
            dispatchAdvancedSettings({
              type: 'set_optimization_metric',
              value: singleValue?.value as OptimizationMetric,
            })
          }
          options={
            hasIntegrationConfiguration
              ? genericOption
              : filteredOptimizationMetricOptions
          }
        />
      )}
      {hasIntegrationConfiguration && (
        <PhraseeXSelector
          label={labelText.optimizationMetric}
          placeholder="Select optimization metrics"
          className="mb-6 w-full"
          isDisabled={false}
          datatestId="integration-optimization-selector"
          value={integrationOptimizationMetric}
          onChange={(value) => {
            if (!value) {
              return
            }

            const result = integrationOptimizationMetrics?.find(
              (metric) => metric.display_name === value.value
            )

            dispatchAdvancedSettings({
              type: 'set_integration_optimization_metrics',
              value: {
                integrationOptimizationMetric:
                  result?.optimization_metric ?? '',
                integrationOptimizationAudience:
                  result?.optimization_audience ?? '',
              },
            })

            setIntegrationOptimizationMetric(value.value)
          }}
          options={integrationConfigurationMetrics}
        />
      )}
    </>
  )
}

export default AdvancedOptions
