import cx from 'classnames'

import Button from 'common/components/button'
import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Add, Minus } from 'common/icons'
import { useContent } from 'features/unifiedFlow/contentPage/hooks'
import {
  addTemplate,
  MAX_TEMPLATES_TO_ADD,
  removeTemplate,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

const getIconClassName = (isDisabled: boolean) =>
  cx('h-5 w-full p-1 rounded-full bg-gold-100', {
    'bg-gold-100 text-base-700 hover:bg-yellow-400': !isDisabled,
    'opacity-40': isDisabled,
  })

type Props = {
  templateId: string
  isOptimizable: boolean
  isPersonalizable: boolean
}

const SelectBox = ({ templateId, isOptimizable, isPersonalizable }: Props) => {
  const dispatch = useAppDispatch()
  const { content } = useContent()
  const templatesToAdd = useAppSelector(
    (state) => state.unifiedFlow.templatesToAdd
  )

  const handleAdd = () => {
    dispatch(addTemplate({ templateId, isOptimizable, isPersonalizable }))
  }

  const handleMinus = () => {
    dispatch(removeTemplate({ templateId }))
  }

  const alreadyAddedTemplatesCount = content?.elements.length
    ? content.elements.length - 1
    : 0
  const templatesToAddCount = templatesToAdd.length
  const isAddingDisabled =
    templatesToAddCount + alreadyAddedTemplatesCount >= MAX_TEMPLATES_TO_ADD
  const templateCount = templatesToAdd.filter(
    (template) => template.id === templateId
  ).length
  const isMinusDisabled = templateCount === 0

  return (
    <div className="flex items-center ">
      <Button
        variant="icon"
        onClick={() => handleMinus()}
        disabled={isMinusDisabled}
        aria-label="subtract"
        data-testid="remove-template"
        className="h-5 w-8 rounded-full"
      >
        <Minus
          isDefaultColor={false}
          className={getIconClassName(isMinusDisabled)}
        />
      </Button>

      <div
        data-testid="template-count"
        className="flex justify-center items-center h-5 w-5 mx-1 bg-coolGray-50 outline-none
                        font-medium text-base-700 select-none rounded-full text-xs"
      >
        {templateCount}
      </div>
      <ButtonWithTooltip
        variant="icon"
        onClick={() => handleAdd()}
        disabled={isAddingDisabled}
        tooltip={isAddingDisabled}
        tooltipText="Template limit reached"
        aria-label="add"
        data-testid="add-template"
        className="h-5 w-8 rounded-full"
      >
        <Add
          isDefaultColor={false}
          className={getIconClassName(isAddingDisabled)}
        />
      </ButtonWithTooltip>
    </div>
  )
}

export default SelectBox
