import Checkbox from 'common/components/checkbox'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import {
  clickIsUsingDoubleEmoji,
  updateEmojiSettingsLeftFrequency,
  updateEmojiSettingsRightFrequency,
} from '../../store/contentSettingsSlice'

import EmojiSettingsSlider from './EmojiSettingsSlider'

const EmojiSettingsWidget = () => {
  const dispatch = useAppDispatch()

  const emojiSettings = useAppSelector(
    (state) => state.contentSettings.editedContent.emojiSettings
  )

  return (
    <Widget data-cy="emoji-settings-widget" data-testid="emoji-settings-widget">
      <WidgetHeader
        title="Emoji frequency"
        subtitle="Select the frequency for which emojis can generate on the left (beginning) and right (end) of a copy variant. To disable emojis from generating on the project, set both frequencies to zero."
      />
      <div className="mt-4">
        <div>
          <Checkbox
            tooltip={{
              label:
                'If checked, emojis will be able to generate at the beginning and end of a variant.',
              placement: 'top',
            }}
            className="inline-flex "
            label="Use double Emojis?"
            isChecked={emojiSettings.isUsingDoubleEmoji}
            onChange={(value) => dispatch(clickIsUsingDoubleEmoji(value))}
            isDisabled={
              emojiSettings.leftFrequency === 0 &&
              emojiSettings.rightFrequency === 0
            }
          />
        </div>
        <div className="flex flex-1 gap-12 mt-6">
          <EmojiSettingsSlider
            data-cy="left-frequency"
            data-testid="left-frequency"
            label="Left frequency (beginning)"
            value={emojiSettings.leftFrequency}
            onChange={(value) => {
              if (typeof value === 'number') {
                dispatch(updateEmojiSettingsLeftFrequency(value))
              }
            }}
          />
          <EmojiSettingsSlider
            data-cy="right-frequency"
            data-testid="right-frequency"
            label="Right frequency (end)"
            value={emojiSettings.rightFrequency}
            onChange={(value) => {
              if (typeof value === 'number') {
                dispatch(updateEmojiSettingsRightFrequency(value))
              }
            }}
          />
        </div>
      </div>
    </Widget>
  )
}

export default EmojiSettingsWidget
