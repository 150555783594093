import { TemplateElement } from '@phrasee/phrasee-typings'
import cx from 'classnames'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'
import { getExperimentBadgesState } from 'features/unifiedFlow/contentPage/helpers'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import ElementActionsMenu from './ElementActionsMenu'
import ElementCard, { getAnimationIndex } from './ElementCard'
import ElementCardIcon from './ElementCardIcon'
import ElementLoader from './ElementLoader'
import ExperimentTypeBadges from './ExperimentTypeBadges'

type Props = {
  element: TemplateElement
  isRemoveOptionShown?: boolean
  index: number
  isLoading?: boolean
  className?: string
}

const ElementTemplateCard = ({
  element,
  index,
  className,
  isRemoveOptionShown,
  isLoading = false,
}: Props) => {
  const dispatch = useAppDispatch()

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )
  const areElementsDisabled = useAppSelector(
    (state) => state.unifiedFlow.areElementsDisabled
  )

  const { icon, display_name, elements } = element

  const isTemplateElementSelected = element.elements
    .filter(isComponentElement)
    .some((element) => element.element_id === selectedElementId)

  const isTemplateSelected = element.element_id === selectedElementId

  const badgesState = getExperimentBadgesState(element)
  const animationIndex = getAnimationIndex(index)

  return (
    <div
      className={cx(
        'p-4 group',
        {
          'bg-yellow-200 cursor-pointer':
            isTemplateElementSelected || isTemplateSelected,
          'bg-gold-100': !areElementsDisabled,
          'bg-gold-100 opacity-40 cursor-not-allowed': areElementsDisabled,
        },
        className
      )}
    >
      <div className="flex mb-4 items-center">
        {icon && !isLoading && <ElementCardIcon icon={icon} className="mr-3" />}
        {isLoading && (
          <div className="flex items-center mr-3 h-6 w-6">
            <ElementLoader animation={animationIndex} />
          </div>
        )}
        <div>
          <div
            className={cx('text-base font-medium overflow-hidden uppercase', {
              'text-maroon-400': isLoading,
              'text-coolGray-400': areElementsDisabled,
              'text-coolGray-800': !areElementsDisabled,
            })}
          >
            {display_name}
          </div>
          {badgesState && (
            <ExperimentTypeBadges className="mt-1 min-h-5" {...badgesState} />
          )}
        </div>
        <ElementActionsMenu
          isConfigurable={true}
          isRemoveOptionShown={isRemoveOptionShown}
          isSelected={isTemplateSelected}
          element={element}
        />
      </div>
      {elements &&
        elements
          .filter(isComponentElement)
          .map(
            ({
              element_id,
              name,
              display_name,
              status,
              approved,
              nlg_status,
            }) => {
              const isElementDisabled =
                areElementsDisabled || status === 'disabled'
              const hasError = nlg_status === 'error'

              return (
                <ElementCard
                  className="mb-2"
                  key={element_id}
                  title={display_name || name}
                  isDisabled={isElementDisabled}
                  showCustomBriefIcon={false}
                  isLoading={false}
                  isElementTemplate
                  isApproved={approved}
                  isSelected={
                    selectedElementId === element_id && !isElementDisabled
                  }
                  hasError={hasError}
                  onClick={() =>
                    !isElementDisabled &&
                    dispatch(selectElement({ elementId: element_id }))
                  }
                />
              )
            }
          )}
    </div>
  )
}

export default ElementTemplateCard
