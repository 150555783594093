import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import RouterPromptStay from 'app/router/RouterPromptStay'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { showErrorToast } from 'common/api/helpers'
import useRegionsListQuery from 'common/api/queries/useRegionsListQuery'
import BottomToolbar from 'common/bottomToolbar'
import Button from 'common/components/button'
import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import Loader from 'common/components/loaders/Loader'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import Scrollable from 'common/components/scrollable'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import AlternativeWordsWidget from './components/AlternativeWordsWidget'
import EmojiSettingsWidget from './components/emojiSettingsWidget/EmojiSettingsWidget'
import EmojisWidget from './components/EmojisWidget'
import ForbiddenWordsWidget from './components/ForbiddenWordsWidget'
import IncompatibleWordsWidget from './components/IncompatibleWordsWidget'
import PunctuationWidget from './components/punctuationWidget/PunctuationWidget'
import RegionStructureWidget from './components/RegionStructureWidget'
import StylisticsWidget from './components/StylisticsWidget'
import SynonymsWidget from './components/SynonymsWidget'
import {
  initializePage,
  resetContent,
  saveContent,
  selectHasBeenEdited,
} from './store/contentSettingsSlice'

const ContentSettings = () => {
  useDocumentTitle('Content settings | Jacquard')
  const { projectId } = useParams<{ projectId: string }>()
  const dispatch = useAppDispatch()
  const regionsListQuery = useRegionsListQuery()
  const isSaving = useAppSelector((state) => state.contentSettings.isSaving)
  const regionId = useAppSelector(
    (state) => state.contentSettings.editedContent.regionStructure.regionId
  )

  const flags = useFlags()
  const fetchContentQuery = useQuery(
    ['admin/content', projectId, regionId],
    () =>
      dispatch(
        initializePage({
          projectId,
          isEmojiInheritanceDisabled: flags.breakEmojiInheritance,
        })
      ).unwrap(),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )

  const hasBeenEdited = useAppSelector(selectHasBeenEdited)

  if (fetchContentQuery.error || regionsListQuery.error) {
    return <ErrorPage />
  }

  if (fetchContentQuery.isLoading || regionsListQuery.isLoading) {
    return <Loader />
  }
  return (
    <>
      <Scrollable
        isFullHeight={false}
        content={
          <PageContainer className="max-w-318 sm:mx-auto px-6">
            <PageHeader className="mt-12 lg:mt-12">
              <div>
                <div
                  data-testid="content-page-title"
                  className="text-xl text-coolGray-800 font-medium"
                >
                  Content
                </div>
                <div className="text-coolGray-400">
                  A little description can go here of what content settings are,
                  do and what they affect.
                </div>
              </div>
            </PageHeader>
            <div className="flex flex-col text-coolGray-400 gap-8 mt-5">
              <RegionStructureWidget projectId={projectId} />
              <StylisticsWidget />
              <PunctuationWidget />
              <AlternativeWordsWidget />
              <ForbiddenWordsWidget />
              <IncompatibleWordsWidget />
              <SynonymsWidget />
              <EmojiSettingsWidget />
              <EmojisWidget />
            </div>
            <Footer />
          </PageContainer>
        }
        footer={
          hasBeenEdited ? (
            <BottomToolbar className="justify-center">
              <div className="max-w-318 xl:max-w-363 w-full flex justify-between">
                <Button
                  data-cy="cancel-button"
                  data-testid="cancel-button"
                  variant="danger"
                  className="mr-4 pointer-events-auto"
                  ghost
                  disabled={isSaving}
                  onClick={() => dispatch(resetContent())}
                >
                  Reset
                </Button>
                <Button
                  data-cy="save-button"
                  data-testid="save-button"
                  variant="success"
                  ghost
                  loading={isSaving}
                  disabled={isSaving}
                  onClick={() =>
                    dispatch(
                      saveContent({
                        projectId,
                        isEmojiInheritanceDisabled: flags.breakEmojiInheritance,
                      })
                    )
                      .unwrap()
                      .catch((e) => {
                        showErrorToast(e)
                      })
                  }
                >
                  Save
                </Button>
              </div>
            </BottomToolbar>
          ) : undefined
        }
      />
      <RouterPromptStay
        shouldShow={hasBeenEdited}
        isSaving={false}
        onOK={() => {
          dispatch(resetContent())
          return Promise.resolve(true)
        }}
      />
    </>
  )
}

export default ContentSettings
