import { useAppSelector } from 'common/hooks/redux'
import { selectRightPanelVisibility } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { isComponentElement } from '../../../api/interfaces'
import ExperimentSettings from '../../brief/rightSection/experimentSettings'
import { useSelectedElement } from '../../hooks'

import RightSection from './RightSection'

type Props = {
  isAdvancedSettingsShown?: boolean
}

const RightSectionContent = ({ isAdvancedSettingsShown = false }: Props) => {
  const { data: selectedElement } = useSelectedElement()

  const rightPanelVisibility = useAppSelector(selectRightPanelVisibility)

  const shouldShowExperimentSettings =
    selectedElement &&
    isComponentElement(selectedElement) &&
    selectedElement.campaign_id

  if (rightPanelVisibility === 'none') {
    return null
  }

  return shouldShowExperimentSettings && isAdvancedSettingsShown ? (
    <ExperimentSettings key={selectedElement?.element_id} />
  ) : (
    <RightSection />
  )
}

export default RightSectionContent
