import { useDocumentTitle } from 'common/hooks/custom'
import MainContent from 'features/unifiedFlow/components/MainContent'

import ContentLoadingOverlay from '../components/ContentLoadingOverlay'
import useWebsocketFullScreenLoader from '../hooks/useWebsocketFullScreenLoader'

import useElementState from './hooks/useElementState'
import CenterSection from './centerSection'
import LeftSection from './leftSection'
import RightSection from './rightSection'

const Brief = () => {
  useDocumentTitle('Brief | Jacquard')

  const { isWebsocketLoading, websocketAction } = useWebsocketFullScreenLoader()

  useElementState()

  return (
    <MainContent className="flex-wrap" id="main-content-page">
      <LeftSection />
      <div className="relative flex flex-1">
        <ContentLoadingOverlay
          isLoading={isWebsocketLoading}
          action={websocketAction}
        />
        <CenterSection isWebsocketLoading={isWebsocketLoading} />
        <RightSection />
      </div>
    </MainContent>
  )
}

export default Brief
