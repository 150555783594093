import { ChangeEvent, ReactNode, useState } from 'react'
import cx from 'classnames'

import {
  Checkbox as CheckboxIcon,
  CheckboxIndeterminate as CheckboxIndeterminateIcon,
} from 'common/icons'
import { ReactComponent as DeselectedDisabledIcon } from 'common/icons/checkbox/deselected-disabled.svg'
import { ReactComponent as DeselectedFocusedIcon } from 'common/icons/checkbox/deselected-focused.svg'
import { ReactComponent as SelectedDisabledIcon } from 'common/icons/checkbox/selected-disabled.svg'
import { ReactComponent as SelectedFocusedIcon } from 'common/icons/checkbox/selected-focused.svg'

import Tooltip from '../Tooltip'

import CheckboxIconComponent from './CheckboxIcon'

export interface Props {
  isChecked: boolean
  label?: string | ReactNode
  labelColor?: `text-${string}`
  labelClassName?: string
  onChange: (isChecked: boolean, event: ChangeEvent<HTMLInputElement>) => void
  isDisabled?: boolean
  'data-cy'?: string
  'data-testid'?: string
  className?: string
  disableAlignItemsCenter?: boolean
  indeterminate?: boolean
  tooltip?: { label: string; placement?: 'left' | 'right' | 'top' | 'bottom' }
  colorChecked?: `text-${string}`
  backgroundColor?: string
  value?: string | number | readonly string[]
}

function CheckboxOld({
  label,
  labelColor = 'text-coolGray-800',
  labelClassName,
  isDisabled,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  isChecked,
  value,
  onChange,
  className,
  disableAlignItemsCenter = false,
  indeterminate,
  tooltip,
  colorChecked = 'text-maroon-200',
  backgroundColor,
}: Props) {
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const getIcon = () => {
    const className = 'w-6 h-6 min-w-6 text-coolGray-300'

    if (indeterminate) {
      return (
        <CheckboxIndeterminateIcon className={cx('min-w-6', colorChecked)} />
      )
    } else if (isChecked && isDisabled) {
      return <SelectedDisabledIcon className={className} />
    } else if (!isChecked && isDisabled) {
      return <DeselectedDisabledIcon className={className} />
    } else if (isChecked && isFocused) {
      return <SelectedFocusedIcon className={className} />
    } else if (!isChecked && isFocused) {
      return <DeselectedFocusedIcon className={className} />
    } else {
      return !!backgroundColor ? (
        <CheckboxIconComponent
          state={isChecked ? 'selected' : 'default'}
          backgroundColor={backgroundColor}
          className={cx(
            'inline-block  hover:text-maroon-200 group-hover:text-maroon-400 min-w-6 text-coolGray-300',
            {
              [colorChecked]: isChecked,
              'text-coolGray-300': !isChecked,
              'text-gray-300': isDisabled,
            }
          )}
        />
      ) : (
        <CheckboxIcon
          state={isChecked ? 'selected' : 'default'}
          isDefaultColor={colorChecked === 'text-maroon-200'}
          className={cx('min-w-6', {
            [colorChecked]: isChecked,
            'text-coolGray-300': !isChecked,
            'text-gray-300': isDisabled,
          })}
        />
      )
    }
  }

  return (
    <Tooltip
      placement={tooltip?.placement || 'left'}
      overlayClassName="max-w-xs break-words"
      data-testid={`checkbox-tooltip-${tooltip}`}
      overlay={tooltip?.label}
      show={tooltip?.label !== undefined}
    >
      <label
        className={cx('relative flex cursor-pointer', className, {
          'items-center': !disableAlignItemsCenter,
          'cursor-not-allowed': isDisabled,
        })}
      >
        <input
          type="checkbox"
          data-cy={dataCy}
          data-testid={dataTestId}
          checked={isChecked}
          disabled={isDisabled}
          onChange={(e: any) => onChange(e.target.checked, e)}
          /* transparent checkbox on top of the icon to fix focus/ blur handling issues */
          className="absolute w-6 h-6 opacity-0 cursor-pointer"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={value}
        />
        {getIcon()}
        <span
          className={cx(
            'ml-4',
            {
              'leading-normal font-normal': !labelClassName,
            },
            labelClassName,
            labelColor
          )}
        >
          {label}
        </span>
      </label>
    </Tooltip>
  )
}

export default CheckboxOld
