import { generatePath, useHistory } from 'react-router-dom'
import { unifiedFlowPaths } from 'app/navigation/paths'

import { useAppSelector } from 'common/hooks/redux'
import useUpdateElementMutation from 'features/unifiedFlow/api/mutations/useUpdateElementMutation'
import {
  useContent,
  useSelectedElement,
} from 'features/unifiedFlow/contentPage/hooks'

const useIntegrationStatusCallbacks = () => {
  const history = useHistory()
  const updateElementMutation = useUpdateElementMutation()
  const { content } = useContent()
  const { data: selectedElement } = useSelectedElement()

  const accountId = useAppSelector((state) => state.authStates.accountId)

  const onSuccess = () => {
    if (content && selectedElement) {
      updateElementMutation.mutate({
        accountId,
        contentId: content._id,
        elementId: selectedElement.element_id,
        updatedElement: {
          integration_status: 'finished',
        },
      })
      history.push(
        generatePath(unifiedFlowPaths.results, {
          contentId: content._id,
        })
      )
    }
  }

  const onCancel = () => {
    if (content && selectedElement) {
      updateElementMutation.mutate({
        accountId,
        contentId: content._id,
        elementId: selectedElement.element_id,
        updatedElement: {
          integration_status: 'pending',
        },
      })
    }
  }

  return { onSuccess, onCancel }
}

export default useIntegrationStatusCallbacks
