import { Field } from 'react-final-form'
import { InputNumber } from 'antd'
import {
  MAX_FLOAT_VALUE,
  MIN_FLOAT_VALUE,
} from 'workflow/CampaignSetup/UiBuilder/UiBuilder.component'

import { PercentageType } from 'common/components/dynamicFields/interfaces'
import FormItem from 'common/components/formItem'

type Props = {
  name: string
  configuration: PercentageType
  onChange: (value: string) => void
  className?: string
  isDisabled?: boolean
  'data-cy'?: string
  'data-testid'?: string
}

const Rate = ({
  name,
  configuration,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  className,
  isDisabled,
  onChange,
}: Props) => {
  const validateRate = (value: number) => {
    if (!value) {
      return `Please input your ${configuration.label.toLowerCase()}`
    }
  }

  return (
    <Field<number> name={name} validate={validateRate}>
      {({ input, meta }) => (
        <FormItem
          error={meta.touched ? meta.error : undefined}
          htmlFor={input.name}
          label={configuration.label}
          className={className}
        >
          <InputNumber
            data-cy={dataCy}
            data-testid={dataTestId}
            className="w-full"
            id={input.name}
            placeholder={configuration.placeholder}
            disabled={isDisabled}
            precision={2}
            min={configuration.min || MIN_FLOAT_VALUE}
            max={configuration.max || MAX_FLOAT_VALUE}
            step={configuration.step || 1}
            decimalSeparator="."
            onChange={(value) => {
              let stringValue = ''
              if (
                value &&
                value >= MIN_FLOAT_VALUE &&
                value <= MAX_FLOAT_VALUE
              ) {
                stringValue = value?.toString() ?? ''
              }
              input.onChange(stringValue)
              onChange(stringValue)
            }}
            formatter={(value) => {
              const newValue = String(value).replace(/[^\d.]/g, '')
              return `${newValue}%`
            }}
            parser={(value) => value?.replace(/[^\d.]/g, '') ?? ''}
            value={input.value}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
          />
        </FormItem>
      )}
    </Field>
  )
}

export default Rate
