import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import type { Route } from 'app/navigation/interfaces'
import { homePath } from 'app/navigation/paths'
import Dropdown from 'rc-dropdown'
import Menu, { Item as MenuItem, ItemGroup } from 'rc-menu'

import { getIsAuthorized, routesPermissions } from 'common/auth/permissions'
import Button from 'common/components/button'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  Account,
  changeAccountId,
  getSelectedAccountName,
} from 'features/auth/store/authSlice'

import AccountSelectButtonContent from './components/AccountSelectButtonContent'
import SearchInput from './components/SearchInput'

import styles from './AccountSelect.module.css'

interface Props {
  accounts: Account[]
  accountsRoute: Route
}

const filterAccountsBySearch = (accounts: Account[], searchValue: string) =>
  accounts.filter(({ name }) =>
    name.toLowerCase().includes(searchValue.toLowerCase())
  )

const AccountSelector = ({ accounts, accountsRoute }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const { accountId, permissions } = useAppSelector((state) => state.authStates)
  const accountName = useAppSelector(getSelectedAccountName)

  const [searchValue, setSearchValue] = useState<string>('')

  const onSelectAccount = async (id: string): Promise<void> => {
    await dispatch(changeAccountId(id)).then(() => {
      history.replace(homePath)
    })
  }

  const hasAccountsViewPermissions = getIsAuthorized(
    permissions,
    routesPermissions.accountsView
  )

  const filteredAccounts = searchValue
    ? filterAccountsBySearch(accounts, searchValue)
    : accounts

  return (
    <Dropdown
      overlayClassName={styles.container}
      trigger={['click']}
      onVisibleChange={(visible) => setIsDropdownVisible(visible)}
      overlay={() => {
        return (
          <Menu
            className="shadow box-border z-40 pt-2 border border-solid border-coolGray-300 text-base-700"
            selectable={true}
            selectedKeys={[accountId]}
            data-testid="account-selector-menu"
          >
            <div className="pt-2 px-4 pb-4">
              <SearchInput
                data-testid="account-search"
                value={searchValue}
                onChange={(value) => setSearchValue(value)}
              />
            </div>
            {/* <div className="max-h-90 overflow-auto"> */}
            <ItemGroup key="accounts" className="max-h-90 overflow-auto">
              {filteredAccounts.map(({ id, name }) => (
                <MenuItem
                  data-cy={`${name}-account-menu-item`}
                  data-testid="account-menu-item"
                  key={id}
                  onClick={() => onSelectAccount(id)}
                  className="bg-white pt-4 px-4 pb-2 hover:bg-coolGray-50 hover:text-maroon-400 cursor-pointer"
                >
                  {name}
                </MenuItem>
              ))}
            </ItemGroup>
            {/* </div> */}
            {hasAccountsViewPermissions && (
              <div
                data-testid="edit-accounts-menu-item"
                className="pt-4 px-4 pb-4 border-t border-coolGray-200 cursor-pointer "
              >
                <Link to={accountsRoute.to}>Edit Accounts</Link>
              </div>
            )}
          </Menu>
        )
      }}
    >
      <Button
        size="large"
        data-cy={`${accountName}-button`}
        data-testid="account-selection-button"
        className="min-w-0 text-white pr-0"
      >
        <AccountSelectButtonContent
          accountName={accountName}
          isDropdownVisible={isDropdownVisible}
        />
      </Button>
    </Dropdown>
  )
}

export default AccountSelector
