import { Element } from '@phrasee/phrasee-typings'

import {
  isComponentElement,
  isTemplateElement,
} from 'features/unifiedFlow/api/interfaces'
import { useSelectedElement } from 'features/unifiedFlow/contentPage/hooks'
import { getAvailableIntegrations } from 'features/unifiedFlow/contentPage/integrations/helpers'

import useGetIntegrationsRoutingConfigQuery from '../centerSection/api/queries/useGetIntegrationsRoutingConfigQuery'

const getAssets = (element: Element): string[] => {
  if (isComponentElement(element)) {
    return [element.name]
  } else if (isTemplateElement(element)) {
    return element.elements.map((nestedElement) => nestedElement.name)
  } else {
    return []
  }
}

const useAvailableIntegrations = () => {
  const { data: selectedElement } = useSelectedElement()

  return useGetIntegrationsRoutingConfigQuery<string[]>({
    enabled: !!selectedElement,
    select: (integrations) => {
      const assets = getAssets(selectedElement!)
      const filteredIntegrations = integrations.filter(({ name }) => {
        return assets.includes(name)
      })

      return getAvailableIntegrations(filteredIntegrations)
    },
  })
}

export default useAvailableIntegrations
