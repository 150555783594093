import Loader from 'common/components/loaders/Loader'
import Modal from 'common/components/Modal'

import Actions from './components/Actions'
import EditForm from './components/EditForm'
import ReplaceForm from './components/ReplaceForm'
import TypeSwitch from './components/TypeSwitch'
import VariantText from './components/VariantText'
import { TweakVariantProvider } from './context/TweakVariantContext'
import { CampaignType } from './types'
import useTweakVariantModal from './useTweakVariantModal'

type Props = {
  campaignType: CampaignType
}

const TweakVariantModal = ({ campaignType }: Props) => {
  const {
    isVisible,
    isTweakingLoading,
    onRejectLine,
    onTweakLine,
    onClose,
    ...rest
  } = useTweakVariantModal()

  return (
    <Modal
      visible={isVisible}
      centered
      maskClosable
      closable={false}
      footer={null}
      destroyOnClose
      data-testid="request-change-modal"
    >
      <TweakVariantProvider {...rest} campaignType={campaignType}>
        <Loader isLoading={isTweakingLoading}>
          <div style={{ width: 500 }}>
            <div>
              <p className="text-base-700 font-normal mb-2">
                Request a change to:
              </p>
              <VariantText />
              <div>
                <p className="text-base-700 font-normal mt-6 text-sm mb-2">
                  What would you like to do?
                </p>
                <TypeSwitch />
                <ReplaceForm />
                <EditForm />
                <Actions
                  isTweakingLoading={isTweakingLoading}
                  onClose={onClose}
                  onRejectLine={onRejectLine}
                  onTweakLine={onTweakLine}
                />
              </div>
            </div>
          </div>
        </Loader>
      </TweakVariantProvider>
    </Modal>
  )
}

export default TweakVariantModal
