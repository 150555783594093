import React, { useState } from 'react'
import type { RenderMonthProps, SingleDatePickerShape } from 'react-dates'
import { SingleDatePicker } from 'react-dates'
import moment from 'moment'

const DATE_FORMAT = process.env.REACT_APP_DEFAULT_DATE_FORMAT

export type DatePickerProps = {
  autoFocus?: boolean
  onBlur?: () => void
} & Omit<
  SingleDatePickerShape,
  'focused' | 'onFocusChange' | 'renderMonthText' | 'renderMonthElement'
> &
  RenderMonthProps & { isTodayVisible?: boolean }

const DatePicker = ({
  autoFocus = false,
  isTodayVisible,
  onBlur,
  ...rest
}: DatePickerProps) => {
  const [isFocused, setIsFocused] = useState(autoFocus)
  const [key, setKey] = React.useState(0)

  return (
    <SingleDatePicker
      displayFormat={DATE_FORMAT}
      focused={isFocused}
      onFocusChange={({ focused }) => {
        setIsFocused(focused)
        if (!focused && onBlur) {
          onBlur()
        }
        // when the user types an invalid date, the date is set to null but the input is not cleared
        // see https://github.com/react-dates/react-dates/issues/2116
        if (!focused && rest.date === null) {
          setKey(key + 1)
        }
      }}
      key={key}
      inputIconPosition="after"
      hideKeyboardShortcutsPanel={true}
      css={{ zIndex: 2 }}
      renderCalendarInfo={() =>
        isTodayVisible ? (
          <div className="flex flex-col items-center justify-center p-4">
            <button
              className="focus:outline-none whitespace-nowrap font-medium text-sm text-base-700 hover:text-base-700 hover:opacity-50"
              disabled={rest.isOutsideRange?.(moment())}
              onClick={() => {
                rest.onDateChange?.(moment())
                setIsFocused(false)
              }}
            >
              Today
            </button>
          </div>
        ) : null
      }
      {...rest}
    />
  )
}
export default DatePicker
