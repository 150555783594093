import { useCallback, useMemo, useState } from 'react'
import { CellProps } from 'react-table'
import isEqual from 'lodash/isEqual'

import Breakpoints from 'common/breakpoints'
import Button from 'common/components/button'
import ConfirmationModal from 'common/components/confirmationModal'
import SingleSelectTag from 'common/components/singleSelect/SingleSelectTag'
import { Column, OnCellChange } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import Tags from 'common/components/tags'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useFocusEditableCell } from 'common/hooks/table'
import { useWindowDimensions } from 'common/hooks/useWindowDimensions'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'
import {
  EMPTY_OPTION,
  PART_OF_SPEACH_OPTIONS,
  splitSentenceIntoPartOfSpeach,
} from 'common/partOfSpeach'

import {
  addAlternativeWordsRow,
  deactivateAlternativeWordsRows,
  deleteAlternativeWordsRow,
  updateAlternativeWordsCell,
} from '../store/styleGuideSlice'

type Row = {
  id: number
  originalWord: string
  partOfSpeech: { label: string; value: string }[]
  replacements: string[]
}

type Props = {
  className?: string
}

const AlternativeWordsWidget = ({ className }: Props) => {
  const alternativeWords = useAppSelector(
    (state) => state.styleGuide.editedContent.alternativeWords
  )

  const { screenWidth } = useWindowDimensions()
  const dispatch = useAppDispatch()
  const {
    TextEditableCellWithFocus,
    editableCellFocus,
    editableCellLoseFocus,
  } = useFocusEditableCell()

  const [rowIdToDelete, setRowIdToDelete] =
    useState<number | undefined>(undefined)
  const [resetFilterKey, setResetFilterKey] = useState(0)

  const columns: Column<Row>[] = useMemo(
    () => [
      {
        Header: 'Original word or phrase',
        accessor: 'originalWord',
        disableSortBy: true,
        Cell: (props: CellProps<Row> & { onCellChange: OnCellChange<Row> }) => (
          <TextEditableCellWithFocus
            {...props}
            onCellChange={({ rowIndex, columnId, value, rowOriginal }) => {
              const partsOfSpeach = splitSentenceIntoPartOfSpeach(
                value as string
              ).map((pos) => ({
                label: pos,
                value: undefined,
              }))

              if (
                !isEqual(
                  partsOfSpeach.map(({ label }) => label),
                  rowOriginal?.['partOfSpeech']?.map(({ label }) => label)
                )
              ) {
                dispatch(
                  updateAlternativeWordsCell({
                    value: partsOfSpeach.map(({ label }) => ({
                      label,
                      value: EMPTY_OPTION.value,
                    })),
                    rowIndex: rowIndex,
                    columnId: 'partOfSpeech',
                  })
                )
                props.onCellChange({ rowIndex, columnId, value, rowOriginal })
              }
            }}
          />
        ),
        isResizable: true,
        minWidth: 55,
      },
      {
        accessor: 'partOfSpeech',
        disableSortBy: true,
        Header: 'Part of speech',
        Cell: ({ value, row }) => {
          return (
            <BaseCell className="w-full py-2">
              <div className="flex gap-2 flex-wrap">
                {value.map((pos, index) => (
                  <SingleSelectTag
                    key={index}
                    title={pos.label}
                    options={[EMPTY_OPTION, ...PART_OF_SPEACH_OPTIONS]}
                    value={pos.value}
                    onChange={(option) => {
                      const updatedPartOfSpeech = row.original[
                        'partOfSpeech'
                      ].map((pos, i) =>
                        i === index ? { ...pos, value: option?.value } : pos
                      )
                      dispatch(
                        updateAlternativeWordsCell({
                          value: updatedPartOfSpeech,
                          rowIndex: row.index,
                          columnId: 'partOfSpeech',
                        })
                      )
                    }}
                  />
                ))}
              </div>
            </BaseCell>
          )
        },
      },
      {
        accessor: 'replacements',
        disableSortBy: true,
        Header: 'Replacement (s)',
        Cell: ({ value, row }) => {
          return (
            <div className="ml-6 my-4 flex items-center">
              <Tags
                data-cy="alternative-words-replacements"
                data-testid="alternative-words-replacements"
                isFreeText
                onAddClick={(value: string) => {
                  const trimmedValue = value.trim()
                  if (trimmedValue !== '') {
                    dispatch(
                      updateAlternativeWordsCell({
                        value: [...row.original['replacements'], trimmedValue],
                        rowIndex: row.index,
                        columnId: 'replacements',
                      })
                    )
                  }

                  setResetFilterKey((prev) => prev + 1)
                }}
                onRemoveClick={(value: string) => {
                  dispatch(
                    updateAlternativeWordsCell({
                      value: row.original['replacements'].filter(
                        (item) => item !== value
                      ),
                      rowIndex: row.index,
                      columnId: 'replacements',
                    })
                  )
                  setResetFilterKey((prev) => prev + 1)
                }}
                tags={value.map((value) => ({ value, label: value }))}
                maxLength={50}
              />
            </div>
          )
        },
      },
      generateDeleteButtonColumn<Row>({
        onClick: (row) => {
          setRowIdToDelete(row.id)
        },

        buttonLabel: 'Delete',
      }),
    ],
    [TextEditableCellWithFocus, dispatch]
  )
  const [selectedRows, setSelectedRows] = useState<Row[]>([])
  const handleSelectRow = useCallback(
    (rows) => {
      if (!isEqual(rows, selectedRows)) {
        setSelectedRows(rows)
      }
    },
    [selectedRows]
  )
  const handleDeleteCancel = () => {
    setRowIdToDelete(undefined)
  }
  const handleDeleteConfirm = () => {
    if (rowIdToDelete !== undefined) {
      dispatch(deleteAlternativeWordsRow(rowIdToDelete))
    }
    setRowIdToDelete(undefined)
  }

  return (
    <>
      <TableWidget.Widget
        data-cy="alternative-words-widget"
        data-testid="alternative-words-widget"
        columns={columns}
        className={className}
        data={alternativeWords}
        firstUseText="No alternative words added yet"
        selectedRowsActionButtons={
          selectedRows.length > 0 ? (
            <Button
              variant="link"
              onClick={() => {
                dispatch(
                  deactivateAlternativeWordsRows(
                    selectedRows.map((row) => row.id)
                  )
                )
              }}
            >
              Deactivate
            </Button>
          ) : undefined
        }
        rowSelection="multiple"
        onSelectRow={handleSelectRow}
        onCellChange={({ rowIndex, columnId, value }) => {
          dispatch(
            updateAlternativeWordsCell({
              rowIndex,
              columnId,
              value: value?.toString().toLowerCase(),
            })
          )
          editableCellLoseFocus()
          setResetFilterKey((prev) => prev + 1)
        }}
      >
        <TableWidget.Header
          title="Alternative words"
          subtitle="Enter a new row to configure a list of alternative words that can generate. Delete a row or replacement if it is no longer required."
        >
          <Button
            className="mr-4"
            variant="primary"
            data-cy="add-alternative-words-button"
            data-testid="add-alternative-words-button"
            prefixIcon={<AddIcon width={24} height={24} />}
            onClick={() => {
              dispatch(addAlternativeWordsRow())
              editableCellFocus()
              setResetFilterKey((prev) => prev + 1)
            }}
          >
            {screenWidth < Breakpoints.sm ? undefined : 'Alternative word'}
          </Button>
          <TableWidget.Filter key={`${resetFilterKey}`} />
        </TableWidget.Header>
      </TableWidget.Widget>
      <ConfirmationModal
        open={rowIdToDelete !== undefined}
        title="Remove alternative words row!"
        data-cy="alternative-words-row-delete-modal"
        data-testid="alternative-words-row-delete-modal"
        confirmationText="Are you sure you want to remove this row?"
        onCancel={() => handleDeleteCancel()}
        onConfirm={() => handleDeleteConfirm()}
      />
    </>
  )
}

export default AlternativeWordsWidget
