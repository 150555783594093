import { createContext, useContext } from 'react'

export const IntegrationContext = createContext<boolean | undefined>(undefined)

export const useHasIntegrationContext = () => {
  const context = useContext(IntegrationContext)
  if (context === undefined) {
    // if the context is not available, it means this is a new project then we can set it as true
    return true
  }
  return context
}
