import Button from 'common/components/button'
import { Reject } from 'common/icons'
import { ContentWithProgress } from 'features/futurama/api/queries/useGetContentQuery'

interface Props {
  progress: ContentWithProgress['approval_progress']
  onReject: () => void
}

const RejectButton = ({ progress, onReject }: Props) => {
  return (
    <>
      {{
        approved: (
          <Button
            data-cy="reject-button"
            data-testid="reject-button"
            prefixIcon={<Reject isDefaultColor={false} size={4} />}
            ghost
            disabled
          >
            Reject
          </Button>
        ),
        rejected: (
          <Button
            data-cy="reject-button"
            data-testid="rejected-button"
            className="border-red-400 text-red-400 border-1"
            prefixIcon={
              <Reject
                isDefaultColor={false}
                className="text-red-400"
                size={4}
              />
            }
            disabled
          >
            Rejected
          </Button>
        ),
      }[progress] || (
        <Button
          data-cy="reject-button"
          data-testid="reject-button"
          prefixIcon={<Reject size={4} />}
          ghost
          onClick={onReject}
        >
          Reject
        </Button>
      )}
    </>
  )
}

export default RejectButton
