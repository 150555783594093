import { QueryCache, QueryClient } from '@tanstack/react-query'
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CancelToken,
  CancelTokenSource,
} from 'axios'
import memoize from 'lodash/memoize'
import Qs from 'qs'
import { Store } from 'redux/store'

import tokenFetcher from 'common/auth/tokenFetcher'
import { errorToast } from 'common/components/toastNotification'

let store: Store | undefined = undefined

export function injectStore(_store: Store) {
  store = _store
}

if (!process.env.REACT_APP_GRAPHQL_ENDPOINT) {
  throw new Error(
    'The REACT_APP_GRAPHQL_ENDPOINT is missing from the .env file'
  )
}

if (!process.env.REACT_APP_CONNECT_INTERNAL_END_POINT) {
  throw new Error(
    'The REACT_APP_CONNECT_INTERNAL_END_POINT is missing from the .env file'
  )
}

const DENTALPLAN_API_URL = process.env.REACT_APP_END_POINT as string
export const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT
const CONNECT_INTERNAL = process.env.REACT_APP_CONNECT_INTERNAL_END_POINT

if (!process.env.REACT_APP_REGIONAL_REPORTING_URLS) {
  throw new Error('REGIONAL_REPORTING_URLS are not defined')
}

const REGIONAL_REPORTING_URLS: { [key: string]: string } = JSON.parse(
  process.env.REACT_APP_REGIONAL_REPORTING_URLS
)

export enum GlobalHandledErrors {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
}

interface GraphqlResponse<T> {
  data: { data: T }
}

export const createAxiosInstance = (baseURL: string) => {
  const instance = axios.create({
    baseURL,
    paramsSerializer: function (params) {
      return Qs.stringify(params, { arrayFormat: 'comma' })
    },
  })
  instance.interceptors.request.use(
    async (config) => {
      const token: string = await tokenFetcher.getAccessToken()

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
      }
      if (['post', 'put', 'patch'].includes(config.method || '')) {
        config.headers['Content-Type'] = 'application/json'
      }

      if (store) {
        config.headers['x-account-id'] = store.getState().authStates.accountId
      }

      return config
    },
    (error) => {
      Promise.reject(error)
    }
  )

  return instance
}

export const createApi = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorMessage =
        error.response?.data?.message || error.response?.data?.msg

      if (
        error.response?.status === GlobalHandledErrors.BadRequest &&
        errorMessage
      ) {
        errorToast(errorMessage, { autoClose: 10000 })
      }
      if (
        [
          GlobalHandledErrors.Unauthorized,
          GlobalHandledErrors.Forbidden,
        ].includes(error.response?.status)
      ) {
        window.location.href = '/401'
      }
      if ([GlobalHandledErrors.NotFound].includes(error.response?.status)) {
        window.location.href = '/404'
      }

      throw error
    }
  )
  return {
    get: axiosInstance.get,
    patch: axiosInstance.patch,
    post: axiosInstance.post,
    put: axiosInstance.put,
    delete: axiosInstance.delete,
  }
}

const dentalBackendClient = createAxiosInstance(DENTALPLAN_API_URL)
const connectInternalClient = createAxiosInstance(CONNECT_INTERNAL)
const graphQLClient = createAxiosInstance(GRAPHQL_ENDPOINT)

const connectInternalApi = createApi(connectInternalClient)
const regionalReportingApis =
  REGIONAL_REPORTING_URLS &&
  Object.fromEntries(
    Object.entries(REGIONAL_REPORTING_URLS).map(([key, value]) => [
      key,
      createApi(createAxiosInstance(value)),
    ])
  )

const restApi = createApi(dentalBackendClient)

async function fetchGraphQL<T>(
  { query, variables }: { query: string; variables?: unknown },
  config?: AxiosRequestConfig
): Promise<T> {
  const response = await graphQLClient.post<GraphqlResponse<T>>(
    '',
    { query, variables },
    config
  )
  return response.data.data.data
}

export type { CancelTokenSource, CancelToken }

export function createCancelTokenSource() {
  return axios.CancelToken.source()
}

export {
  connectInternalApi,
  fetchGraphQL,
  graphQLClient,
  restApi,
  regionalReportingApis,
}

export const isCancel = axios.isCancel

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (_error, query) => {
      if (query.meta?.errorMessage) {
        errorToast(query.meta?.errorMessage)
      }
    },
  }),
})

const PERSONALIZATION_LANGUAGE_URLS =
  process.env.REACT_APP_PERSONALIZATION_LANGUAGE
if (!PERSONALIZATION_LANGUAGE_URLS) {
  throw new Error('REACT_APP_PERSONALIZATION_LANGUAGE is not defined')
}

const DATA_SOURCES_URLS = process.env.REACT_APP_DATA_SOURCES_URLS
if (!DATA_SOURCES_URLS) {
  throw new Error('REACT_APP_DATA_SOURCES_URLS is not defined')
}

const getApiPerRegion = ({
  urls,
  region,
}: {
  urls: string
  region: string
}) => {
  const urlPerRegion = JSON.parse(urls)
  const baseURL = urlPerRegion[region.toLowerCase()]
  return createApi(createAxiosInstance(baseURL))
}

export const getRegionalizedApiPerRegion = memoize((region: string) =>
  getApiPerRegion({ urls: PERSONALIZATION_LANGUAGE_URLS, region })
)

export const getDataSourceApiPerRegion = memoize((region: string) =>
  getApiPerRegion({ urls: DATA_SOURCES_URLS, region })
)
