import { ChangeEvent, ReactNode } from 'react'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  Checkmark as CheckmarkIcon,
  Subtract as SubtractIcon,
} from '../../icons'
import Tooltip from '../Tooltip'

import CheckboxOld from './CheckboxOld'

import styles from './Checkbox.module.css'

const ICON_PROPS = {
  isDefaultColor: false,
  size: 4,
}

export interface Props {
  isChecked: boolean
  label?: string | ReactNode
  labelColor?: `text-${string}`
  labelClassName?: string
  onChange: (isChecked: boolean, event: ChangeEvent<HTMLInputElement>) => void
  isDisabled?: boolean
  'data-cy'?: string
  'data-testid'?: string
  className?: string
  disableAlignItemsCenter?: boolean
  indeterminate?: boolean
  tooltip?: { label: string; placement?: 'left' | 'right' | 'top' | 'bottom' }
  colorChecked?: `text-${string}`
  backgroundColor?: string
  value?: string | number | readonly string[]
}

// This is exported for Storybook for now, because there is no feature flag for Storybook setup
export const Checkbox = ({
  label,
  labelColor,
  labelClassName,
  isDisabled,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  isChecked,
  value,
  onChange,
  className,
  disableAlignItemsCenter = false,
  indeterminate,
  tooltip,
  backgroundColor,
}: Props) => {
  const isActive = isChecked || indeterminate

  const checkboxStyles = cx(
    'flex items-center justify-center w-5 h-5 border rounded-sm transition-all duration-200',
    {
      'bg-gold-40': !backgroundColor,
      'border-gold-400 group-hover:border-gold-500':
        !backgroundColor && !isActive,
      'border-base-700': !backgroundColor && isActive,
      'text-base-700': !backgroundColor && isActive,
      [styles.customCheckbox]: backgroundColor,
      [styles.customIcon]: backgroundColor,
    }
  )

  const labelStyles = cx(
    'ml-2 font-normal text-sm',
    {
      'text-base-700': !labelColor,
    },
    labelClassName,
    labelColor
  )

  return (
    <Tooltip
      placement={tooltip?.placement || 'left'}
      overlayClassName="max-w-xs break-words"
      data-testid={`checkbox-tooltip-${tooltip}`}
      overlay={tooltip?.label}
      show={tooltip?.label !== undefined}
    >
      <label
        className={cx('relative flex group', className, {
          'items-center': !disableAlignItemsCenter,
          'opacity-40 cursor-not-allowed': isDisabled,
          'cursor-pointer': !isDisabled,
        })}
        style={
          backgroundColor
            ? ({
                '--checkbox-bg': `${backgroundColor}20`,
                '--checkbox-border': backgroundColor,
              } as { [key: string]: string })
            : undefined
        }
      >
        <div className="relative">
          <input
            type="checkbox"
            data-cy={dataCy}
            data-testid={dataTestId}
            checked={isChecked}
            disabled={isDisabled}
            onChange={(e) => onChange(e.target.checked, e)}
            className={cx('absolute w-0 h-0 opacity-0', styles.input)}
            value={value}
          />
          <div className={checkboxStyles}>
            {isActive && (
              <>
                {indeterminate ? (
                  <SubtractIcon {...ICON_PROPS} />
                ) : (
                  <CheckmarkIcon {...ICON_PROPS} />
                )}
              </>
            )}
          </div>
        </div>
        {label && <span className={labelStyles}>{label}</span>}
      </label>
    </Tooltip>
  )
}

function CheckboxComponent(props: Props) {
  const { showL2Components } = useFlags()

  return showL2Components ? <Checkbox {...props} /> : <CheckboxOld {...props} />
}

export default CheckboxComponent
