import { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'
import {
  graphConfig,
  Highcharts,
} from 'workflow/CampaignSummary/PhraseeXContent/AdvancedVisualisations/Graphs'

import HighchartsReact from 'common/components/AutoResizeChart'
import { useDeepCompare, useShallowCompare } from 'common/hooks/custom'
import { replaceNewLineEmojiAsLineBreak } from 'common/variantsUtils'

type DataItem = {
  minutes_since_start: number
  open_rate?: number
  click_rate?: number
  open_rate_machine_open_excluded?: number
  timestamp: string
  variant_id: string
  variant_text: string
  color: string
}

type Data = Array<DataItem>

const createDataPoint = (
  item: DataItem,
  areMachineOpensExcluded: boolean,
  template: string,
  variantColorMapping: Record<string, any>
  // eslint-disable-next-line max-params
) => {
  const dataPoint = {
    y:
      (areMachineOpensExcluded &&
      template === 'lil_bandit_open_rate_leaderboard'
        ? item.open_rate_machine_open_excluded
        : item.open_rate) ??
      item.click_rate ??
      0,
    name: replaceNewLineEmojiAsLineBreak(item.variant_text),
    color: variantColorMapping.get(item.variant_id)?.color ?? '#000',
    id: item.variant_id,
  }

  return dataPoint
}

const BarChart = ({
  results,
  selectedRows,
  variantColorMapping,
  template,
  currentIndex,
  setCurrentIndex,
  shouldEnableRestartButton,
  areMachineOpensExcluded,
}) => {
  const selectedRowsIds = selectedRows?.map((row: any) => row._id)

  const filteredResult: Array<Data> = results
    .map((subArray: Data) =>
      subArray.filter((variant: DataItem) =>
        selectedRowsIds.includes(variant.variant_id)
      )
    )
    .filter((subArray: Data) => subArray.length > 0)

  const initialDataset = filteredResult[0]?.map((item) =>
    createDataPoint(
      item,
      areMachineOpensExcluded,
      template,
      variantColorMapping
    )
  )

  const [chartOptions, setChartOptions] = useState<any>({
    ...graphConfig,
    chart: {
      spacing: [0, 10, 10, 0],
      backgroundColor: graphConfig.chart?.backgroundColor,
      borderRadius: graphConfig.chart?.borderRadius,
      borderWidth: graphConfig.chart?.borderWidth,
      borderColor: graphConfig.chart?.borderColor,
      type: 'bar',
      style: {
        fontFamily: 'Roboto',
      },
    },
    xAxis: {
      ...graphConfig.xAxis,
      type: 'category',
      labels: {
        enabled: false,
        style: {
          ...(graphConfig.xAxis as Highcharts.XAxisOptions)?.labels?.style,
        },
      },
      gridLineColor: '#e4e4e4',
    },
    plotOptions: {
      series: {
        animation: {
          duration: 400,
        },
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{y}%',
          style: {
            fontWeight: 'normal',
            fontSize: '10px',
            fontFamily: 'Outfit',
            color: '#15020C',
          },
        },
      },
    },
    tooltip: {
      shared: false,
      split: false,
      useHTML: false,
      padding: 16,
      borderRadius: 5,
      backgroundColor: '#ffffff',
      shadow: true,
    },
    series: [
      {
        name: 'Open Rate',
        dataSorting: {
          enabled: true,
        },
        data: initialDataset,
      },
    ],
  })

  const hasChangedSelectedRows = useShallowCompare(selectedRows)
  const hasChangedData = useDeepCompare(filteredResult)
  const indexedDataLength = filteredResult.length - 1

  const metricName =
    template === 'lil_bandit_open_rate_leaderboard' ? 'Open Rate' : 'Click Rate'

  useEffect(() => {
    const newIndex = Math.min(currentIndex + 1, indexedDataLength)
    const graphTimer = setInterval(() => {
      if (filteredResult[newIndex]) {
        const graphDataset = filteredResult[newIndex]?.map((item) =>
          createDataPoint(
            item,
            areMachineOpensExcluded,
            template,
            variantColorMapping
          )
        )

        setChartOptions({
          ...chartOptions,
          series: [
            {
              name: metricName,
              dataSorting: {
                enabled: true,
              },
              data: graphDataset,
            },
          ],
        })
      }
      shouldEnableRestartButton(false)
      if (currentIndex < indexedDataLength) {
        setCurrentIndex(currentIndex + 1)
      } else {
        shouldEnableRestartButton(true)
        clearInterval(graphTimer)
        return
      }
    }, 500)
    if (hasChangedSelectedRows || hasChangedData) {
      setChartOptions({
        ...chartOptions,
        series: [
          {
            name: metricName,
            dataSorting: {
              enabled: true,
            },
            data: filteredResult[newIndex]?.length
              ? filteredResult[newIndex]
              : [],
          },
        ],
      })
    }
    return () => {
      clearInterval(graphTimer)
      if (currentIndex === indexedDataLength) {
        shouldEnableRestartButton(true)
      }
    }
    // FIXME there might be a bug here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, hasChangedSelectedRows, selectedRows, hasChangedData])

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType="chart"
      // cloneDeep fixes crash when changing and resetting filters
      options={cloneDeep(chartOptions)}
    />
  )
}

export default BarChart
