import { Content } from '@phrasee/phrasee-typings'
import { UpdateAccountQuery } from '@phrasee/phrasee-typings/typings/integrations/accounts'
import { GetIntegrationRoutingRequest } from '@phrasee/phrasee-typings/typings/integrations/routing'
import {
  CreateContentBlockRequest,
  ListEmailTemplatesResponse,
  UpdateEmailTemplateRequest,
} from '@phrasee/phrasee-typings/typings/integrations/templates'
import { StackOneMetadata } from '@phrasee/phrasee-typings/typings/integrations/templates'

import { connectInternalApi } from 'common/api'

export const updateAsset = async ({
  templateId,
  stackOneAccountId,
  body,
}: UpdateAccountQuery &
  GetIntegrationRoutingRequest & {
    body: Partial<UpdateEmailTemplateRequest> & {
      metadata: StackOneMetadata
    }
  }): Promise<Content> => {
  const result = await connectInternalApi.patch(
    `/v1/content-integrations/stackone-accounts/${stackOneAccountId}/templates/emails/${templateId}`,
    body
  )

  return result.data
}

export const createAsset = async ({
  stackOneAccountId,
  body,
}: UpdateAccountQuery & {
  body: CreateContentBlockRequest & { metadata: StackOneMetadata }
}): Promise<Content> => {
  const result = await connectInternalApi.post(
    `/v1/content-integrations/stackone-accounts/${stackOneAccountId}/templates/content_blocks`,
    body
  )

  return result.data
}

export const getListEmailTemplates = async ({
  stackOneAccountId,
  next,
}: UpdateAccountQuery & {
  next?: string
}): Promise<ListEmailTemplatesResponse> => {
  const result = await connectInternalApi.get<ListEmailTemplatesResponse>(
    `v1/content-integrations/stackone-accounts/${stackOneAccountId}/templates/emails`,
    { params: { next } }
  )

  return result.data
}
