import { useQuery } from '@tanstack/react-query'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { TreeNode } from 'common/components/catalog/Catalog'
import { useAppSelector } from 'common/hooks/redux'
import { CustomerAttributes } from 'features/personalization/api'

import { getCategoryPerformance, PersonalizationRegion } from '../api'
import { personalizationKeys } from '../queryKeys'

const useGetCategoryPerformanceQuery = ({
  personalizationId,
  metric,
  productCategories,
  customerAttributes,
  personalizationRegion,
}: {
  personalizationId: string
  metric: 'opens' | 'clicks'
  customerAttributes: CustomerAttributes | undefined
  productCategories: TreeNode | undefined
  personalizationRegion: PersonalizationRegion | undefined
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const flags = useFlags()

  const getCustomerAttributesQuery = useQuery(
    personalizationKeys.categoryPerformance({
      accountId,
      personalizationId,
      metric,
      customerAttributes,
      productCategories,
    }),
    () =>
      getCategoryPerformance({
        accountId,
        personalizationId,
        metric,
        customerAttributes,
        productCategories,
        ...(flags.switchToRegionalizedData && {
          personalizationRegion: personalizationRegion?.toLocaleLowerCase(),
        }),
      }),
    {
      enabled: !!accountId && !!personalizationId && !!metric,
      retry: false,
      refetchOnWindowFocus: false,
      meta: {
        errorMessage: 'The category performance could not be loaded',
      },
    }
  )

  return getCustomerAttributesQuery
}

export default useGetCategoryPerformanceQuery
