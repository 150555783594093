import FormItem from 'common/components/formItem'

type UnsupportedAssetFieldProps = {
  label: string
}

const UnsupportedAssetField = ({ label }: UnsupportedAssetFieldProps) => {
  return (
    <FormItem label={label} data-testid="unsupported-integration-asset">
      <div>-</div>
    </FormItem>
  )
}

export default UnsupportedAssetField
