import { ReactNode } from 'react'
import cx from 'classnames'
import isString from 'lodash/isString'

import TextLoader from 'common/components/loaders/TextLoader'
import Widget from 'common/components/Widget'
import { ArrowRight as ArrowRightIcon } from 'common/icons'

export type ListWidgetAction = {
  label: string
  onClick: () => void
}

type Props = {
  title: string | ReactNode
  action?: ListWidgetAction
  isLoading: boolean
  error: ReactNode
  className?: string
  bgClassName?: string
  defaultPadding?: boolean
  children: ReactNode
}

// TODO: remove bgClassName once Tailwind is updated
const ListWidget = ({
  title,
  action,
  isLoading,
  error,
  className,
  bgClassName,
  children,
  defaultPadding,
}: Props) => {
  const getState = () => {
    if (isLoading) {
      return 'isLoading'
    } else if (error) {
      return 'error'
    } else {
      return 'results'
    }
  }
  const state = getState()

  return (
    <div className={cx('flex flex-col', className)}>
      <div className="flex justify-between">
        {isString(title) ? (
          <span className="mb-4 text-base-700 font-medium text-xl mr-4 capitalize-first">
            {title}
          </span>
        ) : (
          title
        )}
        {action && (
          <button
            className="group ml-5 gap-2 flex items-center text-gold-600 uppercase underline  hover:text-maroon-200"
            onClick={action.onClick}
          >
            {action.label}
            <ArrowRightIcon
              className="group-hover:text-maroon-200"
              isDefaultColor={false}
              size={4}
            />
          </button>
        )}
      </div>
      <Widget
        type="table"
        bgClassName={bgClassName}
        className={cx('py-2 h-full max-h-88 overflow-auto', className)}
        defaultPadding={defaultPadding}
      >
        {
          {
            isLoading: (
              <div
                data-testid="loader"
                data-cy="list-widget-loader"
                className="px-6 py-4"
              >
                <TextLoader />
              </div>
            ),
            error,
            results: children,
          }[state]
        }
      </Widget>
    </div>
  )
}

export default ListWidget
