import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { getContentLibrary } from '../api'
import { contentKeys } from '../api/queryKeys'

type ContentLibraryElement = {
  id: string
  name: string
  project: string
  template: string
  campaign_id: string
  experimentType: string
  owner: string
  createdDate: string
  sendDate: string
  status: string
}

export type ContentLibraryEntry = {
  id: string
  name: string
  status: string
  createdDate: string
  isLegacy: boolean
  subRows: ContentLibraryElement[]
}

const useGetContentLibraryQuery = ({ isEnabled }: { isEnabled: boolean }) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const getContentLibraryQuery = useQuery(
    contentKeys.contentLibrary(accountId),
    () => getContentLibrary(accountId),
    {
      select: (data) => {
        return {
          live: data.Live,
          completed: data.Completed,
          needApproval: data['Pending Approval'],
        }
      },
      refetchOnWindowFocus: false,
      enabled: !!accountId && isEnabled,
    }
  )
  return getContentLibraryQuery
}

export default useGetContentLibraryQuery
