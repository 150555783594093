import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import { ProfileAttributeItemResponse, updateCustomerAttributes } from '../api'
import { keys } from '../queryKeys'

type CustomerAttributeUpdateParams = {
  attributeId: string
  catalogueAttributeId: string
  region: string
  description: string
}

const useUpdateCustomerAttributesMutation = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const queryClient = useQueryClient()

  return useMutation<
    ProfileAttributeItemResponse[],
    Error,
    CustomerAttributeUpdateParams
  >({
    mutationFn: ({ attributeId, catalogueAttributeId, region, description }) =>
      updateCustomerAttributes({
        accountId,
        attributeId,
        description,
        catalogueAttributeId,
        region,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: keys.customerAttributeRecords({
          accountId,
        }),
      })
    },
    onError: () => {
      errorToast('Failed to update the attribute')
    },
  })
}

export default useUpdateCustomerAttributesMutation
