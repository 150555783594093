import { useEffect } from 'react'
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types'
import { SubjectLine } from 'workflow/interface'
import { updateVariantSentiments } from 'workflow/Workflow.actions'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useWebsockets, { WsResponseBase } from 'common/hooks/useWebsockets'

import { useVariantInsights } from '../../context/VariantInsightsContext'

interface WsSentimentsResponse extends WsResponseBase {
  variant: SubjectLine
}

const useWebsocketSentiments = () => {
  const {
    lastJsonMessage,
    sendJsonMessage,
    isConnectionReady,
  }: {
    lastJsonMessage: WsSentimentsResponse
    sendJsonMessage: SendJsonMessage
    isConnectionReady: boolean
  } = useWebsockets()

  const dispatch = useAppDispatch()
  const { setVariantState, state } = useVariantInsights()

  const { currentCampaignId } = useAppSelector((state) => state.campaignStates)
  const topic = `campaigns/${currentCampaignId}/sentiments`

  useEffect(() => {
    if (isConnectionReady && currentCampaignId) {
      sendJsonMessage({
        action: 'subscribe',
        topics: [topic],
      })
    }

    return () => {
      if (isConnectionReady && currentCampaignId) {
        sendJsonMessage({
          action: 'unsubscribe',
          topics: [topic],
        })
      }
    }
  }, [currentCampaignId, isConnectionReady, sendJsonMessage, topic])

  useEffect(() => {
    if (lastJsonMessage && lastJsonMessage.topics?.includes(topic)) {
      if (lastJsonMessage.status === 'success') {
        const variant = lastJsonMessage.variant

        dispatch(updateVariantSentiments(variant))

        if (state.variantId === variant._id) {
          setVariantState({
            sentiments: variant.sentiments,
            variantId: variant._id,
          })
        }
      }
    }
    // Adding state will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, lastJsonMessage, setVariantState, topic])
}

export default useWebsocketSentiments
