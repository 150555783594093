import moment from 'moment'

import { formatNumber } from 'common/helpers/numeric'

export const calculateSplit = (
  num_splits: number,
  split_size: number,
  list_size: number
) => {
  const calc = (num_splits * split_size) / list_size
  return `${Number(calc * 100).toFixed(2)}%`
}

const getWaitTime = (campaignData) => {
  // IF SFMCv2 INTEGRATION:
  if (
    'typeOfIntegration' in campaignData &&
    campaignData.typeOfIntegration === 'sfmcv2'
  ) {
    if (
      campaignData.campaign_configuration &&
      campaignData.integration_options &&
      'wait_time' in campaignData
    ) {
      const { wait_time } = campaignData
      return wait_time ? `${wait_time} Hour${wait_time > 1 ? 's' : ''}` : null
    }
  }
}

// TODO value null means that some data are not delivered by BE in campaign by id endpoint
export const getDetailsSectionFields = (campaignData) => {
  return [
    {
      label: 'Audience size',
      value: campaignData?.list_size
        ? formatNumber(campaignData?.list_size)
        : null,
    },
    {
      label: 'Split Calculation',
      value:
        campaignData.num_splits &&
        campaignData.split_size &&
        campaignData.list_size
          ? `${campaignData.num_splits} splits of ${
              campaignData.split_size
            } recipients (${calculateSplit(
              campaignData.num_splits,
              campaignData.split_size,
              campaignData.list_size
            )})`
          : null,
    },
    {
      label: 'Wait time',
      value: getWaitTime(campaignData) ? getWaitTime(campaignData) : null,
    },
    {
      label: 'User initiated email',
      value: campaignData.send_definition_name
        ? campaignData.send_definition_name
        : null,
    },
    {
      label: 'Completed date',
      value: campaignData?.inputResultsAt
        ? `${moment(campaignData.inputResultsAt).format('DD MMM YYYY')}`
        : null,
    },
    {
      label: 'Sent date',
      value: campaignData?.iso_send_date
        ? `${moment(campaignData.iso_send_date).format('DD MMM YYYY')}`
        : null,
    },
    {
      label: 'Created date',
      value: campaignData?.created
        ? `${moment(campaignData.created).format('DD MMM YYYY')}`
        : null,
    },
  ]
}
