import { ReactNode } from 'react'
import { Field } from 'react-final-form'
import { GroupBase, OptionsOrGroups } from 'react-select'

import { SelectValue } from 'common/components/BaseSelect'
import FormItem from 'common/components/formItem/FormItem'
import SingleSelect from 'common/components/singleSelect'

type Props = {
  name: string
  label: string
  placeholder: string
  initialValue?: string
  guidingText?: string
  options: OptionsOrGroups<SelectValue, GroupBase<SelectValue>> | undefined
  validate?: (value: string) => string | undefined
  isDisabled?: boolean
  'data-cy'?: string
  'data-testid'?: string
  noOptionsMessage?: () => ReactNode
  footer?: ReactNode
}

export const SingleSelectField = ({
  name,
  label,
  placeholder,
  initialValue,
  guidingText,
  options,
  validate,
  isDisabled,
  noOptionsMessage,
  footer,
}: Props) => (
  <Field<string>
    name={name}
    validate={validate}
    initialValue={initialValue}
    render={({ input, meta }) => (
      <FormItem
        label={label}
        htmlFor={name}
        guidingText={guidingText}
        error={meta.touched ? meta.error : undefined}
      >
        <SingleSelect
          id={name}
          placeholder={placeholder}
          onChange={(val) => input.onChange(val?.value)}
          onBlur={input.onBlur}
          value={input.value}
          isSearchable={true}
          options={options}
          isDisabled={isDisabled}
          noOptionsMessage={noOptionsMessage}
          footer={footer}
        />
      </FormItem>
    )}
  />
)

export default SingleSelectField
