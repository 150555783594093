import cx from 'classnames'

import Button from 'common/components/button'
import Label from 'common/components/label'
import Widget from 'common/components/Widget'
import { CloseSmall, SmallTick } from 'common/icons'

import { Tone } from '../../../store/brandVoiceSlice'

type Props = {
  className?: string
  tone: Tone
  isDisabled?: boolean
  isEditable?: boolean
  isSelected: boolean
  onToggle?: Function
}

const ToneCard = ({
  className,
  tone,
  isEditable,
  isDisabled,
  isSelected,
  onToggle = () => {},
}: Props) => {
  const { id, title, description, language, color } = tone

  const bgColor = isSelected && !!color ? `bg-${color}-50` : 'bg-coolGray-50'
  const borderColor =
    isSelected && color ? `border-${color}-200` : 'border-coolGray-200'

  return (
    <Widget type="basic" className={cx(className, bgColor, borderColor)}>
      <div className="flex justify-between w-full">
        <div>
          <span className="text-xl text-coolGray-800 font-medium leading-7 capitalize">
            {title}
          </span>
          <p className="text-coolGray-400">{description}</p>
        </div>

        {isEditable ? (
          <Button
            data-cy="use-button"
            data-testid="use-button"
            variant={isSelected ? 'secondary' : 'primary'}
            onClick={() => {
              onToggle(id)
            }}
            size="small"
            className="text-base ml-4"
            prefixIcon={
              isSelected ? (
                <CloseSmall isDefaultColor={false} />
              ) : (
                <SmallTick isDefaultColor={false} />
              )
            }
            disabled={isDisabled}
          >
            {isSelected ? 'Remove' : 'Use'}
          </Button>
        ) : undefined}
      </div>
      {language && (
        <>
          <Label className="mt-6">The language</Label>
          <p className="text-coolGray-400">{language}</p>
        </>
      )}
    </Widget>
  )
}

export default ToneCard
