import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import type { Topic } from '../interfaces'

import TopicCard from './TopicCard'

export type SortableTopicCardProps = {
  topic: Topic
  isDisabled?: boolean
  isDragged?: boolean
  isDragDisabled?: boolean
  className?: string
}

const SortableTopicCard = ({
  topic,
  isDragDisabled,
  isDragged,
  className,
  ...rest
}: SortableTopicCardProps) => {
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
    isSorting,
    rect,
  } = useSortable({ id: topic.id, animateLayoutChanges: () => true })

  const style = {
    transform: isSorting ? undefined : CSS.Translate.toString(transform),
    transition,
    cursor: isDragDisabled ? 'default' : 'pointer',
    zIndex: 1,
  }

  return (
    <>
      {isDragging ? (
        <div
          className="py-2 px-4 rounded-sm justify-between z-10 border border-gold-400 border-dashed bg-gold-100 bg-opacity-50 min-w-40 min-h-10 max-w-36 max-w-52"
          style={{
            width: `${rect.current?.width}px`,
          }}
        ></div>
      ) : (
        <TopicCard
          isDragged={isDragged}
          ref={setNodeRef}
          style={style}
          topic={topic}
          className={className}
          {...attributes}
          {...listeners}
          {...rest}
        />
      )}
    </>
  )
}

export default SortableTopicCard
