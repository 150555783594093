import { useCallback, useEffect, useState } from 'react'

import { sendApprovalEmail, Team, TeamWithColour } from 'common/api/teamsApi'
import Avatar from 'common/components/Avatar'
import Button from 'common/components/button'
import Checkbox from 'common/components/checkbox'
import Modal from 'common/components/Modal'
import { errorToast } from 'common/components/toastNotification'
import { avatarColors } from 'features/profile/AvatarColorPicker'

import style from './SendForApprovalModal.module.css'

interface SendForApprovalModalProps {
  isVisible: boolean
  updateIsVisible: (isVisible: boolean) => void
  teamsList: Team[]
  updateSelectedTeamsList: (teams: TeamWithColour[]) => void
  campaignId: string
  accountId: string
}

const SendForApprovalModal = ({
  isVisible,
  updateIsVisible,
  teamsList,
  updateSelectedTeamsList,
  campaignId,
  accountId,
}: SendForApprovalModalProps) => {
  const handleCloseModal = useCallback(
    () => updateIsVisible(false),
    [updateIsVisible]
  )

  const [isSendingForApproval, setIsSendingForApproval] = useState(false)
  const [teamsListWithColours, setTeamsListWithColours] = useState<
    TeamWithColour[]
  >([])
  const [selectedTeams, setSelectedTeams] = useState<TeamWithColour[]>([])

  useEffect(() => {
    if (teamsList.length > 0) {
      const teams = teamsList.map((team) => {
        return { ...team, colour: selectRandomAvatarColour() }
      })
      setTeamsListWithColours(teams)
    }
  }, [teamsList])

  const handleTeamSelected = (selectedTeam) => {
    if (selectedTeams.some((team) => team.id === selectedTeam.id)) {
      setSelectedTeams(
        selectedTeams.filter((team) => team.id !== selectedTeam.id)
      )
    } else {
      setSelectedTeams([...selectedTeams, selectedTeam])
    }
  }

  const selectRandomAvatarColour = () => {
    const numberOfColours = avatarColors.length
    return avatarColors[Math.floor(Math.random() * numberOfColours)]
  }

  const returnTeamAvatarNameArray = (name) => {
    return name.split(/\s/)
  }

  const handleSendForApproval = async () => {
    setIsSendingForApproval(true)
    try {
      const teamslists = selectedTeams.map((team) => team.id)
      await sendApprovalEmail({ accountId, campaignId, teamslists })
      setIsSendingForApproval(false)
      updateSelectedTeamsList(selectedTeams)
      handleCloseModal()
    } catch (err) {
      setIsSendingForApproval(false)
      errorToast('Error sending approval email. Please try again later.')
    }
  }

  return (
    <Modal
      className={style.modal}
      maskClosable
      centered
      bodyStyle={{
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '0px',
      }}
      data-cy="send-for-approval-modal"
      data-testid="send-for-approval-modal"
      title={
        <h1 className="mx-2 my-2 text-2xl font-rob text-coolGray-800 flex items-center">
          Send for approval
        </h1>
      }
      wrapClassName="center-send-for-approval-modal"
      visible={isVisible}
      onClose={handleCloseModal}
      footer={
        <div className="flex flex-row-reverse">
          <Button
            className="ml-4"
            data-cy="send-for-approval-button"
            data-testid="send-for-approval-button"
            variant="primary"
            onClick={handleSendForApproval}
            loading={isSendingForApproval}
            disabled={selectedTeams.length < 1}
          >
            Send for approval
          </Button>

          <Button
            data-cy="cancel-send-for-approval-button"
            data-testid="cancel-send-for-approval-button"
            ghost
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
        </div>
      }
    >
      {teamsListWithColours && teamsListWithColours.length > 0 ? (
        <>
          <div className="flex flex-col">
            <span className="font-medium pb-4 text-coolGray-400">
              Select a team from the list below to send this experiment's
              variants for approval.
            </span>
          </div>
          <div className="overflow-y-auto max-h-96">
            {teamsListWithColours.map((team) => {
              return (
                <div
                  key={team.id}
                  className="flex items-start justify-start justify-items-start p-1"
                >
                  <Checkbox
                    isChecked={selectedTeams.some(
                      (selectedTeams) => selectedTeams.id === team.id
                    )}
                    onChange={() => handleTeamSelected(team)}
                  />
                  <Avatar
                    firstName={returnTeamAvatarNameArray(team.name)[0]}
                    lastName={returnTeamAvatarNameArray(team.name)[1]}
                    color={team.colour}
                    className="w-6 h-6 text-xs mr-4 flex-shrink-0"
                  />
                  <div className="font-medium text-coolGray-800 truncate text-ellipsis overflow-hidden">
                    {team.name}
                  </div>
                </div>
              )
            })}
          </div>
        </>
      ) : (
        <span className="font-medium pb-4 text-coolGray-400">
          There are no teams configured for this account.
        </span>
      )}
    </Modal>
  )
}

export default SendForApprovalModal
