import { useEffect, useState } from 'react'

import Button from 'common/components/button'
import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import CheckboxComponent from 'common/components/checkbox'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Add, Minus } from 'common/icons'
import { useContent } from 'features/futurama/contentPage/hooks'
import {
  addTemplate,
  MAX_TEMPLATES_TO_ADD,
  removeTemplate,
} from 'features/futurama/store/contentSlice'

type Props = {
  templateId: string
}

const SelectBox = ({ templateId }: Props) => {
  const [checked, setChecked] = useState<boolean>(false)

  const dispatch = useAppDispatch()
  const { content } = useContent()
  const templatesToAdd = useAppSelector((state) => state.content.templatesToAdd)
  const templateToAdd = templatesToAdd.find(
    (template) => template.id === templateId
  )

  const handleAdd = () => {
    dispatch(addTemplate({ templateId }))
  }

  const handleMinus = (erase = false) => {
    dispatch(removeTemplate({ templateId, erase }))
  }

  useEffect(() => {
    if (templateToAdd && templateToAdd.count > 0) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [templateToAdd])

  const alreadyAddedTemplatesCount = content?.elements.length
    ? content.elements.length - 1
    : 0
  const templatesToAddCount = templatesToAdd.reduce(
    (acc, template) => acc + template.count,
    0
  )
  const isAddingDisabled =
    templatesToAddCount + alreadyAddedTemplatesCount >= MAX_TEMPLATES_TO_ADD

  return (
    <div className="flex justify-between">
      {templateToAdd && templateToAdd.count > 0 && (
        <div className="flex items-center">
          <Button variant="icon" onClick={() => handleMinus()}>
            <Minus
              isDefaultColor={false}
              className="text-coolGray-400 w-4 h-4"
            />
          </Button>
          <div
            className="flex justify-center items-center  h-10 w-10 mx-2 bg-coolGray-50 outline-none
                      border border-coolGray-300 font-medium text-coolGray-800 select-none"
          >
            {templateToAdd?.count || 0}
          </div>
          <ButtonWithTooltip
            variant="icon"
            onClick={() => handleAdd()}
            disabled={isAddingDisabled}
            tooltip={isAddingDisabled}
            tooltipText="Template limit reached"
          >
            <Add isDefaultColor={false} className="text-base-700 w-4 h-4" />
          </ButtonWithTooltip>
        </div>
      )}
      <CheckboxComponent
        className="ml-2"
        isChecked={checked}
        isDisabled={isAddingDisabled && !checked}
        onChange={(isChecked) => (isChecked ? handleAdd() : handleMinus(true))}
      />
    </div>
  )
}

export default SelectBox
