import React from 'react'
import classNames from 'classnames'
interface Props {
  icon: React.ReactNode
  text: string
  onClick?: () => void
}

const MetricItem = ({ icon, text, onClick }: Props) => {
  return (
    <div
      className={classNames(
        `flex group place-items-center px-4 py-3 bg-gold-100 hover:bg-gold-300`,
        {
          'cursor-pointer': onClick,
        }
      )}
      onClick={onClick}
    >
      <div className="text-base-700 group-hover:text-maroon-400">{icon}</div>
      <div className="text-base-700 text-base font-normal ml-3">{text}</div>
    </div>
  )
}

export default MetricItem
