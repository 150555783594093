/* eslint-disable max-lines */

import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import scrollToComponent from 'react-scroll-to-component'
import { Form } from 'antd'
import { cloneDeep, isEqual } from 'lodash'
import moment from 'moment'
import { bindActionCreators } from 'redux'
import ActionBar from 'workflow/ActionBar'

import { getIsAuthorized, routesPermissions } from 'common/auth/permissions'
import { experimentsPermissions } from 'common/auth/permissions'
import GuidingText from 'common/components/guidingText'
import { ReactComponent as Experiment } from 'common/icons/experiment/default.svg'
import { Campaign } from 'features/campaigns/api/interface'
import { addNewCampaign } from 'features/campaigns/store/campaignSlice'

import { notifyWithMandrill, showBanner } from '../common/common.actions'
import Error from '../common/common.error'
import {
  AiAuthoring,
  GenerateAnimation,
  getCascaderOptions,
  NervalidationModal,
  QuestionsComponent,
  RegenerateWarningModal,
  ValidationError,
} from '../pcl'
import helpers from '../utils/helpers'
import validationMessages from '../utils/validationMessage.json'
import * as actions from '../Workflow.actions'

const { isAManagedCampaign } = helpers
class LanguageGeneration extends React.Component<Props, any> {
  firstInterval: any
  secondInterval: any
  thirdInterval: any
  offset: any
  align: any
  duration: any
  ease: any
  type: any
  permissions: any

  constructor(props) {
    super(props)
    this.state = {
      isGetQuestionApiCall: false,
      focusedInput: undefined,
    }

    this.onChange = this.onChange.bind(this)
    this.updateDependentConditionals =
      this.updateDependentConditionals.bind(this)
    this.validationFieldMessages = this.validationFieldMessages.bind(this)
    this.cancelRegenerateConfirmationModal =
      this.cancelRegenerateConfirmationModal.bind(this)
    this.SlAnimationLoader = this.SlAnimationLoader.bind(this)
    this.saveAndExit = this.saveAndExit.bind(this)
  }

  componentDidMount() {
    const {
      actions,
      campaignData: { grammar_id: grammarID },
    } = this.props

    const data = { campaign_id: this.getCampaignId() }
    actions.getCampaignCommon(data)
    actions.getQuestions(this.getCampaignId(), grammarID)
  }

  componentWillMount() {
    const { actions } = this.props
    actions.updateValidationErrors({})
  }

  componentDidUpdate(prevProps) {
    const { stepProps: prevStepProps } = prevProps
    const {
      stepProps,
      stepProps: { questions, values },
      actions,
      campaignData: { grammar_id: grammarID },
      isQuestionLoaded,
    } = this.props
    const { isGetQuestionApiCall } = this.state
    if (
      stepProps.stepTwoErrMsg !== prevStepProps.stepTwoErrMsg &&
      stepProps.stepTwoErrMsg
    ) {
      actions.showBanner({
        content: stepProps.stepTwoErrMsg,
        type: 'error',
      })
    }
    if (questions.length === 0 && !isGetQuestionApiCall) {
      this.setState({ isGetQuestionApiCall: true }, () => {
        actions.getQuestions(this.getCampaignId(), grammarID)
      })
    }
    if (
      !isEqual(prevStepProps.values, stepProps.values) ||
      !isEqual(
        prevStepProps.conditionalHiddenStatus,
        stepProps.conditionalHiddenStatus
      ) ||
      (stepProps.values &&
        stepProps.conditionalHiddenStatus &&
        isQuestionLoaded)
    ) {
      let updatedConditionalHiddenStatus = {}
      let updatedConditionalOpenParents = {}
      let curUpdatedValidationErrors = {}
      // loop through all questions and update their dependent conditional fields
      questions.forEach((question) => {
        const questionName = question.name
        const value =
          values[questionName] || values[questionName] === false
            ? values[questionName]
            : ''
        const affectedConditionalsInfo = this.updateDependentConditionals(
          questionName,
          value,
          {},
          {},
          {},
          {},
          stepProps.globalConditionalFields
        )
        // Update values only for questions that are in object
        if (
          affectedConditionalsInfo.affectedParentConditionalFields[questionName]
        ) {
          updatedConditionalHiddenStatus = Object.assign(
            stepProps.conditionalHiddenStatus,
            affectedConditionalsInfo.affectedFieldsHiddenStatus
          )
          updatedConditionalOpenParents = Object.assign(
            stepProps.conditionalOpenParents,
            affectedConditionalsInfo.affectedParentConditionalFields
          )
          curUpdatedValidationErrors = {
            ...stepProps.validationErrors,
            ...affectedConditionalsInfo.updatedValidationErrors,
          }
        } else {
          // Otherwise use props
          updatedConditionalHiddenStatus = stepProps.conditionalHiddenStatus
          updatedConditionalOpenParents = stepProps.conditionalOpenParents
          curUpdatedValidationErrors = stepProps.validationErrors
        }
      })
      actions.setNewValues(
        values,
        updatedConditionalHiddenStatus,
        updatedConditionalOpenParents,
        curUpdatedValidationErrors,
        true,
        {}
      )
    }
  }

  uploadManagedCampaignFile = (file, updateList) => {
    const { actions } = this.props
    const campaignId = this.getCampaignId()
    actions.uploadManagedCampaignFile(campaignId, file, updateList)
  }

  removeManagedCampaignFile = () => {
    const { actions } = this.props
    const campaignId = this.getCampaignId()
    actions.removeManagedCampaignFile(campaignId)
  }

  // eslint-disable-next-line max-params
  onChange(name, type, val, required = false, options: any = {}) {
    const { stepProps, actions } = this.props
    let value = val
    value = value === null || value === true ? value : value.toString()
    let convertedValue = value
    let validationMessage = ''
    let secondaryValues = {}
    const validation_message = validationMessages.SECOND_STEP
    switch (type) {
      case 'text_number':
        convertedValue = parseFloat(value) || value
        if (Number.isNaN(convertedValue) && convertedValue !== '') {
          validationMessage = validation_message.TEXT_INVALID
        }
        break
      case 'text_percentage':
        if (value) {
          const removeSymbol = value.replace(/%/g, '')
          convertedValue = value.replace(/%/g, '')
          if (
            Number.isNaN(Number(removeSymbol)) ||
            Number.isNaN(convertedValue)
          ) {
            validationMessage = validation_message.TEXT_INVALID
          } else if (!/^(100|0|[1-9]|[1-9]\d)$/.test(convertedValue)) {
            validationMessage = validation_message.TEXT_PERCENTAGE
          } else if (convertedValue === 0) {
            validationMessage = validation_message.TEXT_ZERO
          }
        } else if (required && !value) {
          validationMessage = validation_message.TEXT_BLANK
        } else if (!required && !value) {
          validationMessage = ''
        }
        break
      case 'text_currency':
        if (value) {
          const afterParse = parseFloat(value)
          if (afterParse === 0) {
            validationMessage = validation_message.TEXT_ZERO
          } else if (
            !/^-?\d+\.?\d*$/.test(value) ||
            !/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(afterParse.toString())
          ) {
            validationMessage = validation_message.TEXT_CURRENCY
          }
        } else if (required && !value) {
          validationMessage = validation_message.TEXT_BLANK
        } else if (!required && !value) {
          validationMessage = ''
        }
        break
      case 'text_integer': {
        const afterParse = parseFloat(value)
        convertedValue = afterParse || afterParse === 0 ? afterParse : value
        if (!required && !value && convertedValue !== 0) {
          validationMessage = ''
        } else if (
          !Number.isInteger(convertedValue) ||
          Number.isNaN(convertedValue) ||
          isNaN(val)
        ) {
          validationMessage = validation_message.TEXT_INVALID
        } else if (!value && convertedValue !== 0) {
          validationMessage = validation_message.TEXT_BLANK
        } else if (convertedValue === 0) {
          validationMessage = validation_message.TEXT_ZERO
        }
        break
      }
      case 'text_list':
      case 'text_short':
        validationMessage =
          required && !convertedValue ? validation_message.TEXT_SHORT : ''
        if (convertedValue && convertedValue.length > 60) {
          validationMessage = validation_message.TEXT_SHORT_LENGTH
        }
        break
      case 'text_long':
        // no validation needed
        break
      case 'daystogo':
        validationMessage =
          required && !convertedValue ? validation_message.DATE_FIELD : ''
        break
      case 'date':
        if (!value) {
          validationMessage = !convertedValue
            ? validation_message.DATE_FIELD
            : ''
        } else {
          const daysToGo = moment(value.split('-').reverse().join('-')).format(
            'YYYY-MM-DD hh:mm:ss'
          )
          actions.setDaysToGo(daysToGo)
        }
        break
      case 'select_single_checkbox':
        validationMessage =
          required && !value ? validation_message.SELECT_SINGLE_CHECKBOX : ''
        break
      case 'select_single':
        validationMessage =
          required && !value ? validation_message.SELECT_SINGLE : ''
        break
      case 'select_radio':
      case 'select_radio_integer':
      case 'select_radio_percentage':
      case 'select_radio_currency':
        if (options.radio && value) {
          if (type === 'select_radio' && !value) {
            validationMessage = validation_message.SELECT_RADIO
          } else if (type === 'select_radio_integer') {
            const afterParsed = parseFloat(value)
            convertedValue =
              afterParsed || afterParsed === 0 ? afterParsed : value
            if (
              !Number.isInteger(convertedValue) ||
              Number.isNaN(convertedValue) ||
              isNaN(val)
            ) {
              validationMessage = validation_message.SELECT_RADIO_INVALID
            } else if (!value && convertedValue !== 0) {
              validationMessage = validation_message.SELECT_RADIO_BLANK
            } else if (convertedValue === 0) {
              validationMessage = validation_message.SELECT_RADIO_ZERO
            } else if (!required && !value && convertedValue !== 0) {
              validationMessage = ''
            }
          } else if (type === 'select_radio_percentage') {
            if (value) {
              const removeSymbol = value.replace(/%/g, '')
              convertedValue = parseFloat(value.replace(/%/g, ''))
              if (
                Number.isNaN(Number(removeSymbol)) ||
                Number.isNaN(convertedValue)
              ) {
                validationMessage = validation_message.SELECT_RADIO_INVALID
              } else if (!/^(100|0|[1-9]|[1-9]\d)$/.test(removeSymbol)) {
                validationMessage = validation_message.TEXT_PERCENTAGE
              } else if (convertedValue === 0) {
                validationMessage = validation_message.SELECT_RADIO_ZERO
              }
            } else if (required && !value) {
              validationMessage = validation_message.SELECT_RADIO_BLANK
            } else if (!required && !value) {
              validationMessage = ''
            }
          } else if (type === 'select_radio_currency') {
            if (value) {
              const removeSymbol = value.replace(/[£€$]/g, '')
              if (
                !/^((\d+(,\d{2,3})?(,\d{3})*)(\.\d{2})?|\.\d{2})$/.test(
                  removeSymbol
                ) ||
                /^(\d+(,\d{2})+(,\d{3}|\.\d{2})+)$/.test(removeSymbol)
              ) {
                validationMessage = validation_message.SELECT_RADIO_INVALID
              } else if (removeSymbol === '0') {
                validationMessage = validation_message.SELECT_RADIO_ZERO
              }
            } else if (required && !value) {
              validationMessage = validation_message.SELECT_RADIO_BLANK
            } else if (!required && !value) {
              validationMessage = ''
            }
          }
          convertedValue = {
            radioChecked: options.radio,
            radioText: value,
          }
          break
        } else {
          validationMessage = ''
          convertedValue = {
            radioChecked: options.radio,
            radioText: '',
          }
        }
        break
      case 'select_list':
        validationMessage =
          required && !value ? validation_message.SELECT_LIST : ''
        if (options.max_limit && value) {
          if (options.max_limit < value.split(';').length) {
            validationMessage =
              validation_message.SELECT_LIST_MAX + options.max_limit
            break
          }
        }
        break
      case 'upload':
      case 'yes_no':
        convertedValue = val
        break
      case 'radio_button':
        validationMessage =
          required && !value ? validation_message.RADIO_BUTTON : ''
        break
      case 'cascader':
        const newVal = {}
        Object.values(options.valueNameMapping).forEach((name: any) => {
          newVal[name] = {
            type: 'cascader',
            value: '',
          }
        })
        if (val.length) {
          val.forEach((theVal) => {
            const nestedName = options.valueNameMapping[theVal]
            newVal[nestedName] = {
              type: 'cascader',
              value: theVal,
            }
          })
        }
        secondaryValues = newVal
        convertedValue = val || ''
        validationMessage =
          required && !value ? validation_message.CASCADER : ''

        this.setState({ cascaderValue: convertedValue })
        break
      default:
    } // switch()

    if (required && convertedValue === '' && validationMessage === '') {
      validationMessage = validation_message.DEFAULT_MESSAGE
    }
    const curUpdatedValidationErrors = {}
    if (validationMessage !== '') {
      curUpdatedValidationErrors[name] = validationMessage
    } else {
      curUpdatedValidationErrors[name] = ''
    }
    const affectedConditionalsInfo = this.updateDependentConditionals(
      name,
      convertedValue
    )
    const values = {
      ...stepProps.values,
      [name]: convertedValue,
      ...affectedConditionalsInfo.updatedValues,
    }
    const conditionalHiddenStatus = Object.assign(
      stepProps.conditionalHiddenStatus,
      affectedConditionalsInfo.affectedFieldsHiddenStatus
    )
    const conditionalOpenParents = {
      ...stepProps.conditionalOpenParents,
      ...affectedConditionalsInfo.affectedParentConditionalFields,
    }
    const validationErrors = {
      ...stepProps.validationErrors,
      ...curUpdatedValidationErrors,
      ...affectedConditionalsInfo.updatedValidationErrors,
    }
    actions.setNewValues(
      values,
      conditionalHiddenStatus,
      conditionalOpenParents,
      validationErrors,
      false,
      secondaryValues
    )
  }

  getCampaignId = () => {
    return this.props.match.params.campaignId
  }

  // eslint-disable-next-line max-params
  updateDependentConditionals(
    name,
    valueParam,
    affectedFieldsHiddenStatusParam = {},
    affectedParentConditionalFieldsParam = {},
    updatedValidationErrorsParam = {},
    updatedValue = {},
    newStateGlobalConditionalFields = {}
  ): any {
    const { stepProps } = this.props
    let updatedValues = updatedValue
    let value = valueParam
    let affectedFieldsHiddenStatus = affectedFieldsHiddenStatusParam
    let affectedParentConditionalFields = affectedParentConditionalFieldsParam
    let updatedValidationErrors = updatedValidationErrorsParam
    updatedValues[name] = value // update current fields value
    // get conditional fields object for the current question using its name
    const { globalConditionalFields } = stepProps
    // sometimes, current state does not have globalConditionalFields yet
    let curConditionalFields = globalConditionalFields[name]
    if (Object.keys(globalConditionalFields).length === 0) {
      curConditionalFields = newStateGlobalConditionalFields[name]
    }
    // if current question has conditional fields, then need to show/hide dependent fields
    if (curConditionalFields) {
      const curAffectedFieldsHiddenStatus = {}
      // value is mapped by replacing space with hyphen and changing to lower case
      if (typeof value === 'boolean') {
        value = value ? '1' : '0'
      } else {
        value = Array.isArray(value)
          ? value.map((val) => val.toString())
          : value.toString()
      }
      // handle yes_no case by picking the value as 1
      const valueToBeMatched = Array.isArray(value)
        ? value.map((val) => val.replace(/ /g, '-').toLowerCase())
        : value.replace(/ /g, '-').toLowerCase()
      // get all the dependent fields for this question
      // loop through the various values
      // first hide all dependent fields, while keeping track of the fields to be shown
      const fieldsToBeShown: any[] = []
      Object.keys(curConditionalFields).forEach((val) => {
        // only the matching value fields would be shown
        let addToShownFields = valueToBeMatched === val

        // For input types such as the cascader, multiple values can be selected, so we should check
        // if any of these match the value for the conditional fields
        const isOneOfMany =
          Array.isArray(valueToBeMatched) && valueToBeMatched.includes(val)

        // check for * condition (non-empty input not matching any other value)
        // match * if entered value is not empty and none of the other value keys matched yet
        if (
          (val === '*' &&
            valueToBeMatched !== '' &&
            valueToBeMatched !== '0' &&
            fieldsToBeShown.length === 0) ||
          isOneOfMany
        ) {
          addToShownFields = true
        }

        // get all affected fields for this value
        curConditionalFields[val].forEach((questionName: any) => {
          // hide each dependent fields, if it is not explicitly marked to be shown previously
          curAffectedFieldsHiddenStatus[questionName] =
            affectedFieldsHiddenStatus[questionName] !== false

          updatedValidationErrors[questionName] = ''
          if (addToShownFields) {
            fieldsToBeShown.push(questionName)
          }
        }) // forEach()
      }) // forEach()
      // set the current parent conditional status if it has any matching dependent fields
      affectedParentConditionalFields[name] = fieldsToBeShown.length > 0

      // overrride the hidden status of matching hidden fields
      fieldsToBeShown.forEach((item) => {
        curAffectedFieldsHiddenStatus[item] = false
      }) // forEach()

      affectedFieldsHiddenStatus = Object.assign(
        affectedFieldsHiddenStatus,
        curAffectedFieldsHiddenStatus
      )

      // recursively handle all affected fields
      const curValues = stepProps.values
      Object.keys(curAffectedFieldsHiddenStatus).forEach(function (
        questionName
      ) {
        let questionType = ''
        stepProps.questions.some((item) => {
          if (item.name === questionName) {
            questionType = item.type
            return true
          }
          return false
        })
        let curValue =
          questionType === 'yes_no' && !curValues[questionName]
            ? false
            : curValues[questionName] || ''
        // if this field has been hidden, reset its value to empty string
        if (affectedFieldsHiddenStatus[questionName]) {
          curValue = ''
        }
        const curUpdatedConditionalsInfo = this.updateDependentConditionals(
          questionName,
          curValue,
          affectedFieldsHiddenStatus,
          affectedParentConditionalFields,
          updatedValidationErrors,
          updatedValues,
          newStateGlobalConditionalFields
        )
        affectedFieldsHiddenStatus = Object.assign(
          affectedFieldsHiddenStatus,
          curUpdatedConditionalsInfo.affectedFieldsHiddenStatus
        )
        affectedParentConditionalFields = Object.assign(
          affectedParentConditionalFields,
          curUpdatedConditionalsInfo.affectedParentConditionalFields
        )
        updatedValidationErrors = Object.assign(
          updatedValidationErrors,
          curUpdatedConditionalsInfo.updatedValidationErrors
        )
        updatedValues = Object.assign(
          updatedValues,
          curUpdatedConditionalsInfo.updatedValues
        )
      },
      this) // forEach()
    } // if()
    const updatedConditionals: any = {}
    updatedConditionals.affectedFieldsHiddenStatus = affectedFieldsHiddenStatus
    updatedConditionals.affectedParentConditionalFields =
      affectedParentConditionalFields
    updatedConditionals.updatedValidationErrors = updatedValidationErrors
    updatedConditionals.updatedValues = updatedValues
    return updatedConditionals
  } // updateDependentConditionals()

  /**
   * Saving function works for when the user presses the saveAndExit button
   * */

  saveAndExit() {
    const { stepProps, campaignData, actions } = this.props
    const { values, currencyPattern } = stepProps
    const questions = { ...stepProps.secondaryValues }
    stepProps.questions.forEach((question) => {
      questions[question.name] = {}
      questions[question.name].type = question.type
      questions[question.name].value =
        values[question.name] || values[question.name] === false
          ? values[question.name]
          : ''
    })
    const config = {
      save_and_exit: true,
      save_and_continue: false,
      step: 'language_generation',
      data: {
        project_id: campaignData.project_id,
        campaign_id: campaignData._id,
        questions,
        currency_pattern: currencyPattern,
      },
    }
    actions.saveCampaign(config)
  }

  SlAnimationLoader() {
    const {
      actions,
      stepProps: { slGeneratingStatus },
    } = this.props
    actions.updatePushAnimationProgress(1) // Before restart animation set initial state 1
    const that = this
    this.firstInterval = setInterval(() => {
      let progress = that.props.slAnimationProgress
      if (slGeneratingStatus) {
        clearInterval(that.firstInterval)
        if (progress === 2) {
          that.secondInterval = setInterval(() => {
            let progress2 = that.props.slAnimationProgress
            if (progress2 === 4) {
              clearInterval(that.secondInterval)
            } else {
              progress2 += 1
              that.props.actions.updatePushAnimationProgress(progress2)
            }
          }, 1000)
        } else {
          that.props.actions.updatePushAnimationProgress(4)
        }
      } else {
        progress += 1
        if (progress === 4 && !slGeneratingStatus) {
          clearInterval(that.firstInterval)
          that.thirdInterval = setInterval(() => {
            if (slGeneratingStatus) {
              that.props.actions.updatePushAnimationProgress(4)
              clearInterval(that.thirdInterval)
            }
          }, 1000)
        } else if (progress >= 5 && !slGeneratingStatus) {
          clearInterval(that.firstInterval)
          clearInterval(that.secondInterval)
          clearInterval(that.thirdInterval)
        } else {
          that.props.actions.updatePushAnimationProgress(progress)
        }
      }
    }, 5000)
  }

  cancelRegenerateConfirmationModal() {
    const { actions } = this.props
    // Hide the regenerate modal
    actions.updateRegenerateModalVisible(false)
  }

  // eslint-disable-next-line max-params
  scrollAnimation(elem, offset, align, duration, ease) {
    this.offset = offset || 0
    this.align = align || 'middle'
    this.duration = duration || 1000
    this.ease = ease || 'out-circ'
    scrollToComponent(elem, this)
  }

  // TODO: use library to host validation?.
  validationFieldMessages(type, value, options: any = {}) {
    const validation_message = validationMessages.SECOND_STEP
    let validationMessage = validation_message.DEFAULT_MESSAGE
    this.type = type
    switch (this.type) {
      case 'text_number': {
        const afterParsed = parseFloat(value) || value
        if (Number.isNaN(afterParsed) && afterParsed !== '') {
          validationMessage = validation_message.TEXT_INVALID
        }
        break
      }
      case 'text_percentage':
        if (value) {
          const removeSymbol = value.replace(/%/g, '')
          const convertedValue = parseFloat(value.replace(/%/g, ''))
          if (
            Number.isNaN(Number(removeSymbol)) ||
            Number.isNaN(convertedValue)
          ) {
            validationMessage = validation_message.TEXT_INVALID
          } else if (!/^(100|0|[1-9]|[1-9]\d)$/.test(removeSymbol)) {
            validationMessage = validation_message.TEXT_PERCENTAGE
          } else if (convertedValue === 0) {
            validationMessage = validation_message.TEXT_ZERO
          }
        } else if (!value) {
          validationMessage = validation_message.TEXT_BLANK
        }
        break
      case 'text_currency':
        if (value) {
          const afterParse = parseFloat(value)
          if (afterParse === 0) {
            validationMessage = validation_message.TEXT_ZERO
          } else if (
            !/^-?\d+\.?\d*$/.test(value) ||
            !/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(afterParse.toString())
          ) {
            validationMessage = validation_message.TEXT_CURRENCY
          }
        } else if (!value) {
          validationMessage = validation_message.TEXT_BLANK
        }
        break
      case 'text_integer': {
        const afterParse = parseInt(value, 10)
        const convertedValue =
          afterParse && afterParse === 0 ? afterParse : value
        if (Number.isNaN(convertedValue)) {
          validationMessage = validation_message.TEXT_INVALID
        } else if (!value && convertedValue !== 0) {
          validationMessage = validation_message.TEXT_BLANK
        } else if (convertedValue === 0) {
          validationMessage = validation_message.TEXT_ZERO
        }
        break
      }
      case 'text_list':
      case 'text_short':
        validationMessage = !value ? validation_message.TEXT_SHORT : ''
        if (value && value.length > 60) {
          validationMessage = validation_message.TEXT_SHORT_LENGTH
        }
        break
      case 'daystogo':
      case 'date':
        validationMessage = !value ? validation_message.DATE_FIELD : ''
        break
      case 'select_single_checkbox':
        validationMessage = !value
          ? validation_message.SELECT_SINGLE_CHECKBOX
          : ''
        break
      case 'select_single':
        validationMessage = !value ? validation_message.SELECT_SINGLE : ''
        break
      case 'select_radio':
        validationMessage = !value ? validation_message.SELECT_RADIO : ''
        break
      case 'select_radio_integer': {
        const afterParse2 = parseFloat(value)
        const convertedValue2 =
          afterParse2 || afterParse2 === 0 ? afterParse2 : value
        if (
          !Number.isInteger(convertedValue2) ||
          Number.isNaN(convertedValue2)
        ) {
          validationMessage = validation_message.SELECT_RADIO_INVALID
        } else if (!value && convertedValue2 !== 0) {
          validationMessage = validation_message.SELECT_RADIO_BLANK
        } else if (convertedValue2 === 0) {
          validationMessage = validation_message.SELECT_RADIO_ZERO
        } else if (!value && convertedValue2 !== 0) {
          validationMessage = ''
        }
        break
      }
      case 'select_radio_percentage':
        if (value) {
          const removeSymbol = value.replace(/%/g, '')
          const convertedValue = parseFloat(value.replace(/%/g, ''))
          if (
            Number.isNaN(Number(removeSymbol)) ||
            Number.isNaN(convertedValue)
          ) {
            validationMessage = validation_message.SELECT_RADIO_INVALID
          } else if (!/^(100|0|[1-9]|[1-9]\d)$/.test(removeSymbol)) {
            validationMessage = validation_message.TEXT_PERCENTAGE
          } else if (convertedValue === 0) {
            validationMessage = validation_message.SELECT_RADIO_ZERO
          }
        } else if (!value) {
          validationMessage = validation_message.SELECT_RADIO_BLANK
        }
        break
      case 'select_radio_currency':
        if (value) {
          const removeSymbol = value.replace(/[£€$]/g, '')
          if (
            !/^((\d+(,\d{2,3})?(,\d{3})*)(\.\d{2})?|\.\d{2})$/.test(
              removeSymbol
            ) ||
            /^(\d+(,\d{2})+(,\d{3}|\.\d{2})+)$/.test(removeSymbol)
          ) {
            validationMessage = validation_message.SELECT_RADIO_INVALID
          } else if (removeSymbol === '0') {
            validationMessage = validation_message.SELECT_RADIO_ZERO
          }
        } else if (!value) {
          validationMessage = validation_message.SELECT_RADIO_BLANK
        }
        break
      case 'select_list':
        validationMessage = !value ? validation_message.SELECT_LIST : ''
        if (options.max_limit && value) {
          if (options.max_limit < value.split(';').length) {
            validationMessage =
              validation_message.SELECT_LIST_MAX + options.max_limit
            break
          }
        }
        break
      case 'radio_button':
        validationMessage = !value ? validation_message.RADIO_BUTTON : ''
        break
      case 'cascader':
        validationMessage = !value ? validation_message.CASCADER : ''
        break
      default:
    } // switch()
    return validationMessage
  }

  getErrorNodesData = (incomingObj) => {
    const errorNodesList: any[] = []
    let errKey = ''
    Object.keys(incomingObj).forEach((key) => {
      if (incomingObj[key]) {
        const errorNode = document.getElementsByName(key)[0]
        errorNodesList.push(errorNode)
        errKey = key
        return true
      }
    })
    return {
      errorField: errKey,
      errorNodes: errorNodesList,
    }
  }

  validateFields(regenerate, isUpdateBtn, skipPoochieValidation = false) {
    const {
      actions,
      campaignData,
      stepProps: {
        conditionalHiddenStatus,
        validationErrors,
        values,
        currencyPattern,
        secondaryValues,
        questions,
        subjectLines,
        daysToGoDate,
      },
    } = this.props
    const copySecondaryValues = cloneDeep(secondaryValues)
    const requiredErrors = {}
    let questionObj = {}
    questions.forEach((question) => {
      const curHiddenStatus = conditionalHiddenStatus[question.name]
      questionObj[question.name] = {}
      questionObj[question.name].type = question.type

      questionObj[question.name].value =
        values[question.name] || values[question.name] === false
          ? values[question.name]
          : ''

      if (
        question.type === 'select_single_checkbox' &&
        values[question.name] === 'false'
      ) {
        questionObj[question.name].value = ''
      }
      // change copySecondaryValues string values to objects
      if (question.type === 'cascader' && !this.state.cascaderValue) {
        const cascaderSelections: any[] = Object.values(copySecondaryValues)
          .filter((val) => Array.isArray(val))
          .flat()

        if (cascaderSelections) {
          const cascaderOptions: { [key: string]: string } =
            getCascaderOptions(question)
          const cascaderValue: string[] | '' = questionObj[question.name].value

          Object.keys(cascaderOptions).forEach((key) => {
            copySecondaryValues[cascaderOptions[key]] = {
              type: 'cascader',
              value: Array.isArray(cascaderValue)
                ? cascaderValue.find((el) => el === key)
                : cascaderValue,
            }
          })
        }
      }
      // for hidden fields, we just keep the value already set there in field
      if (question.type === 'hidden') {
        questionObj[question.name].value = question.value
      }
      let checkValue = values[question.name]
      let checkRequired = question.required
      if (
        question.type === 'select_radio_currency' ||
        question.type === 'select_radio_percentage' ||
        question.type === 'select_radio' ||
        question.type === 'select_radio_integer'
      ) {
        const radioValue = values[question.name]
        if (typeof radioValue !== 'undefined') {
          if (radioValue.radioChecked && !radioValue.radioText) {
            checkRequired = true
            checkValue = ''
          } else {
            checkValue =
              typeof radioValue === 'string' ? radioValue : radioValue.radioText
            questionObj[question.name].value =
              typeof radioValue === 'string' ? radioValue : radioValue.radioText
          }
        }
      }
      if (
        (question.type === 'date' ||
          question.type === 'date_time' ||
          question.type === 'daystogo') &&
        checkValue === ''
      ) {
        checkValue = false
      } else {
        const UpdatedValidationError = cloneDeep(validationErrors)
        UpdatedValidationError[question.name] = ''
        actions.updateValidationErrors(UpdatedValidationError)
      }
      if (checkRequired && checkValue && Number.isNaN(checkValue)) {
        if (typeof checkValue === 'object') {
          checkValue = checkValue.value.trim()
        } else {
          checkValue = checkValue.trim()
        }
      }
      // hidden dependent fields should be ignored even if set as required,
      // so check for curHiddenStatus
      if (checkRequired && !checkValue && !curHiddenStatus) {
        const options: any = {}
        if (question.type === 'select_list') {
          options.max_limit = question.max_limit || 'X'
        }
        requiredErrors[question.name] = this.validationFieldMessages(
          question.type,
          checkValue,
          options
        )
      }
    })
    if (copySecondaryValues) {
      questionObj = {
        ...copySecondaryValues,
        ...questionObj,
      }
    }
    const copyValidationErrors = validationErrors
    const copyRequiredErrors = requiredErrors

    const updatedValidationErrors = {
      ...copyValidationErrors,
      ...copyRequiredErrors,
    }
    const { errorField, errorNodes } = this.getErrorNodesData(
      updatedValidationErrors
    )
    if (errorField) {
      if (!conditionalHiddenStatus[errorField]) {
        this.scrollAnimation(errorNodes[0], -80, 'top', 1500, '')
      }
    }
    if (isUpdateBtn && !errorField) {
      // If no errors occured, show the regeneration modal to the user
      actions.updateRegenerateModalVisible(true)
    } else {
      actions.updateRegenerateModalVisible(false)
      const isNoAdBody = false
      ;(actions.updateValidationErrors as any)(updatedValidationErrors).then(
        () => {
          if (!errorField) {
            const questions = Object.assign(questionObj)
            questions.grammar = campaignData.grammar_name
            const obj: any = {}
            obj.campaign_id = campaignData._id
            obj.project_id = campaignData.project_id
            obj.questions_and_ans = regenerate ? questions : {}
            obj.regenerate = regenerate
            obj.subjectlines = regenerate ? null : subjectLines
            obj.currencyPattern = currencyPattern
            obj.isNoAdBody = isNoAdBody
            obj.skipPoochieValidation = skipPoochieValidation
            if (regenerate) {
              this.SlAnimationLoader()
            }
            if (isAManagedCampaign(campaignData)) {
              if (regenerate) {
                actions.saveCampaign({
                  save_and_exit: false,
                  save_and_continue: false,
                  regenerate,
                  step: 'language_generation',
                  data: {
                    project_id: campaignData.project_id,
                    campaign_id: campaignData._id,
                    questions,
                  },
                })
                actions.notifyWithMandrill(
                  campaignData,
                  'ai-authoring-request',
                  {
                    Distribution_Channel:
                      campaignData.campaign_configuration?.distribution_channel,
                    Tested_Content_Section:
                      campaignData.campaign_configuration?.testing_method
                        ?.tested_content_sections?.[0].section,
                    Date: daysToGoDate,
                    Campaign: campaignData.name,
                    Audience_Size: campaignData.list_size,
                    Num_Splits: campaignData.num_splits,
                    Control: campaignData.own_subject_line,
                    Questions_Object: this.generateListOfQuestions(questions),
                  },
                  'managed-campaign-recipients'
                )
              } else {
                actions.generateSubjectLines(obj)
              }
            } else {
              actions.generateSubjectLines(obj)
            }
          }
        }
      )
    }
  }

  generateListOfQuestions = (questions) => {
    let html = '<ul>'
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(questions)) {
      if ((value as any).value) {
        html += '<li>'
        html += `${key} : ${(value as any).value}`
        html += '</li>'
      }
    }
    html += '</ul>'
    return html
  }

  nerValidationModalOnCancel = () => {
    const { actions } = this.props
    return actions.updateNerValidationModal({
      visible: false,
      message: '',
    })
  }

  nerValidationModalOnContinue = () => {
    this.validateFields(true, false, true)
  }

  componentWillUnmount() {
    clearInterval(this.firstInterval)
    clearInterval(this.secondInterval)
    clearInterval(this.thirdInterval)
  }

  redirectToDashboard() {
    const {
      history,
      campaignData: { project_id: projectId },
    } = this.props
    history.push(`/dashboard/${projectId}`)
  }

  render() {
    const {
      stepProps,
      slAnimationProgress,
      stepProps: {
        stepTwoSubmitLoading,
        questions = [],
        daysToGoDate,
        disabled,
        hasEditPermission,
      },
      isWaitingState,
      campaignData,
      isUploading,
    } = this.props
    const { error, focusedInput } = this.state

    if (error) {
      return <Error error={error} />
    }
    const questionsParentChildren = questions.reduce(
      (accumulator, question) => {
        const conditionalParentOpenStatus =
          !!stepProps.conditionalOpenParents[question.name]
        const conditionalHiddenStatus =
          stepProps.conditionalHiddenStatus[question.name]

        if (
          !conditionalParentOpenStatus &&
          conditionalHiddenStatus === undefined
        ) {
          accumulator.push({ ...question, children: [] })
        } else {
          if (conditionalHiddenStatus === false) {
            accumulator[accumulator.length - 1].children.push(question)
          } else {
            if (!conditionalHiddenStatus) {
              accumulator.push({ ...question, children: [] })
            }
          }
        }
        return accumulator
      },
      []
    )
    let display = (
      <React.Fragment>
        {questions && (
          <div
            id="campaign-step-2"
            data-expanded={stepProps.expanded}
            className="w-full h-full overflow-y-auto flex flex-col flex-auto bg-gold-40"
          >
            <GenerateAnimation
              progress={slAnimationProgress}
              display={stepTwoSubmitLoading}
            />
            <RegenerateWarningModal
              cancelModal={this.cancelRegenerateConfirmationModal}
              visible={stepProps.regenerateModalVisible}
              onKeepExisting={() => this.validateFields(false, false)}
              onGenerateNew={() => this.validateFields(true, false)}
              description="Updating inputs requires you to generate new copy variants."
            />
            <NervalidationModal
              visible={stepProps.nerValidationModalVisible}
              message={stepProps.nerValidationErrorMessage}
              onCancel={this.nerValidationModalOnCancel}
              onContinue={this.nerValidationModalOnContinue}
            />
            <div
              data-cy="campaign-language-generation-page"
              data-testid="campaign-language-generation-page"
              className="pt-8 ml-8 pr-8 overflow-x-auto flex-1-0 pb-24"
            >
              <Form layout="horizontal" colon={false}>
                {questionsParentChildren.map((question, index) => {
                  const conditionalHiddenStatus =
                    stepProps.conditionalHiddenStatus[question.name]
                  const conditionalParentOpenStatus =
                    !!stepProps.conditionalOpenParents[question.name]
                  if (conditionalHiddenStatus !== true) {
                    // show only if it doesn't have hidden status true
                    const getQuestionComponent = (isLegendQuestion = false) => {
                      return (
                        <QuestionsComponent
                          key={index.toString()}
                          daysToGoDate={daysToGoDate}
                          data={question}
                          validationError={
                            (!isLegendQuestion &&
                              stepProps.validationErrors[question.name]) ||
                            ''
                          }
                          onChange={this.onChange}
                          value={stepProps.values[question.name] || ''}
                          conditionalHiddenStatus={conditionalHiddenStatus}
                          conditionalParentOpenStatus={
                            conditionalParentOpenStatus || false
                          }
                          uploadManagedCampaignFile={
                            this.uploadManagedCampaignFile
                          }
                          removeManagedCampaignFile={
                            this.removeManagedCampaignFile
                          }
                          isUploading={isUploading}
                          hideGuidingText={isLegendQuestion}
                          autoFocus={focusedInput === question.name}
                          onFocus={() =>
                            this.setState({ focusedInput: question.name })
                          }
                          onBlur={() =>
                            this.setState({ focusedInput: undefined })
                          }
                          disabled={disabled || !hasEditPermission}
                        />
                      )
                    }
                    const questionComponent = getQuestionComponent()

                    return question.children.length ? (
                      <fieldset
                        key={index}
                        className="border-2 border-maroon-100 border-solid pl-6 mt-9 pb-2 mb-5"
                      >
                        <legend className="-top-3">
                          {getQuestionComponent(true)}
                        </legend>
                        <ValidationError
                          message={
                            stepProps.validationErrors[question.name] || ''
                          }
                          style={{
                            position: 'relative',
                            top: '-1rem',
                          }}
                        />
                        <GuidingText
                          text={question.tooltip}
                          style={{
                            position: 'relative',
                            top: '-1.5rem',
                            marginBottom: '16px',
                          }}
                        />

                        {question.children.map((childQuestion, childIndex) => (
                          <div key={childIndex} className="mb-4">
                            <QuestionsComponent
                              daysToGoDate={daysToGoDate}
                              data={childQuestion}
                              validationError={
                                stepProps.validationErrors[
                                  childQuestion.name
                                ] || ''
                              }
                              onChange={this.onChange}
                              value={stepProps.values[childQuestion.name] || ''}
                              conditionalHiddenStatus={
                                stepProps.conditionalHiddenStatus[
                                  childQuestion.name
                                ]
                              }
                              conditionalParentOpenStatus={
                                !!stepProps.conditionalOpenParents[
                                  childQuestion.name
                                ]
                              }
                              uploadManagedCampaignFile={
                                this.uploadManagedCampaignFile
                              }
                              removeManagedCampaignFile={
                                this.removeManagedCampaignFile
                              }
                              isUploading={isUploading}
                              disabled={disabled || !hasEditPermission}
                            />
                          </div>
                        ))}
                      </fieldset>
                    ) : (
                      <div className="mb-4">{questionComponent}</div>
                    )
                  }
                  return null
                })}
              </Form>
            </div>
            {!disabled && hasEditPermission ? (
              <ActionBar
                saveButton={{
                  isDisplayed:
                    !campaignData?.campaign_progress?.text_variants_generated,
                  isDisabled: false,
                  onClick: () => this.saveAndExit(),
                  isLoading: isWaitingState.isWaiting,
                }}
                nextInfoMessage="Next up..."
                nextButton={{
                  isDisabled: !(
                    questions.length &&
                    getIsAuthorized(
                      this.props.permissions,
                      routesPermissions.variantsEdit
                    )
                  ),
                  name: 'Generate',
                  onClick: () =>
                    stepProps.completed
                      ? this.validateFields(false, true)
                      : this.validateFields(true, false),
                  isLoading: isWaitingState.isWaiting,
                  icon: <Experiment width={16} height={16} />,
                }}
              />
            ) : undefined}
          </div>
        )}
      </React.Fragment>
    )
    if (
      isAManagedCampaign(campaignData) &&
      campaignData?.campaign_progress &&
      !campaignData?.campaign_progress?.text_variants_generated &&
      campaignData?.campaign_progress?.campaign_details_set
    ) {
      display = (
        <div className="h-full bg-white">
          <AiAuthoring
            onClick={() => {
              this.redirectToDashboard()
            }}
          />
        </div>
      )
    }
    return display
  }
}

function mapStateToProps(state) {
  const stepProps = {
    expanded: state.campaignStates.expanded,
    completed: state.campaignStates.completed,
    updating: state.campaignStates.updating,
    disabled: state.campaignStates?.campaignData?.steps?.[1]?.disabled ?? false,
    loading: state.campaignStates.loading,
    isValidationSuccess: state.campaignStates.isValidationSuccess,
    regenerateModalVisible: state.campaignStates.regenerateModalVisible,
    nerValidationModalVisible: state.campaignStates.nerValidationModalVisible,
    nerValidationErrorMessage: state.campaignStates.nerValidationErrorMessage,
    questions: state.campaignStates.questions,
    conditionalHiddenStatus: state.campaignStates.conditionalHiddenStatus,
    conditionalOpenParents: state.campaignStates.conditionalOpenParents,
    globalConditionalFields: state.campaignStates.globalConditionalFields,
    validationErrors: state.campaignStates.validationErrors,
    values: state.campaignStates.values,
    secondaryValues: state.campaignStates.secondaryValues,
    stepTwoErrMsg: state.campaignStates.stepTwoErrMsg,
    requiredRefs: state.campaignStates.requiredRefs,
    stepTwoSubmitLoading: state.campaignStates.stepTwoSubmitLoading,
    slGeneratingStatus: state.campaignStates.slGeneratingStatus,
    daysToGoDate: state.campaignStates.daysToGoDate,
    currencyPattern: state.campaignStates.currencyPattern,
    subjectLines: state.campaignStates.subjectLines,
    permissions: state.authStates.permissions,
    hasEditPermission: state.authStates.permissions?.includes(
      experimentsPermissions.edit
    ),
  }

  return cloneDeep({
    slAnimationProgress: state.campaignStates.slAnimationProgress,
    isUploading:
      state.campaignStates.isWaitingState.isWaiting &&
      state.campaignStates.isWaitingState.isWaitingFor ===
        'uploadManagedCampaignFile',
    isQuestionLoaded: state.campaignStates.isQuestionLoaded,
    isWaitingState: state.campaignStates.isWaitingState,
    error: state.campaignStates.error,
    campaignData: state.campaignStates.campaignData,
    loading: state.campaignStates.loading,
    commonValidationError: state.campaignStates.commonValidationError,
    stepProps,
    isNewCampaign: state.campaigns.isNewCampaign,
    user: {
      firstName: state.authStates.firstName,
      lastName: state.authStates.lastName,
    },
    permissions: state.authStates.permissions,
  })
}

function mapDispatchToProps(dispatch) {
  return {
    addNewCampaign: (data: Campaign) =>
      dispatch(addNewCampaign({ campaign: data })),
    actions: bindActionCreators(
      {
        ...actions,
        showBanner,
        notifyWithMandrill,
      },
      dispatch
    ),
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)
type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & RouteComponentProps<{ campaignId: string }>
export default withRouter(connector(LanguageGeneration))
