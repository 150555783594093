import { generatePath } from 'react-router'
import { useHistory } from 'react-router-dom'
import { unifiedFlowPaths } from 'app/navigation/paths'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { getExperimentBadgesState } from 'features/unifiedFlow/contentPage/helpers'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import AddTemplateButton from '../../components/AddTemplateButton'
import ElementCard from '../../components/ElementCard'
import ExperimentTypeBadges from '../../components/ExperimentTypeBadges'
import UniversalBriefElement from '../../components/universalBriefElement'
import { useContent } from '../../hooks'
import { getWsTopic } from '../../hooks/websocketMessage/helpers'
import useCheckVariants from '../hooks/useCheckVariants'

const ElementsList = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { hasVariants } = useCheckVariants()
  const { content } = useContent()

  const elements = content?.elements || []

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )
  const areElementsDisabled = useAppSelector(
    (state) => state.unifiedFlow.areElementsDisabled
  )
  const isUsingUniversalBrief = useAppSelector(
    (state) => state.unifiedFlow.isUsingUniversalBrief
  )

  const isRemoveOptionShown = content?.elements && content.elements.length > 1
  const isOnlyOneElement = content?.elements && content.elements.length === 1

  const handleElementClick = ({ isElementDisabled, elementId }) => {
    if (isElementDisabled) {
      return
    }

    if (isOnlyOneElement && hasVariants) {
      history.push(
        generatePath(unifiedFlowPaths.generate, {
          contentId: content?._id,
        })
      )
    }

    dispatch(selectElement({ elementId }))
  }

  return (
    <>
      {elements?.length > 0 && isUsingUniversalBrief && (
        <UniversalBriefElement />
      )}
      {elements.map((element, index) => {
        const { element_id, name, display_name, icon } = element
        const isElementDisabled = isUsingUniversalBrief || areElementsDisabled

        const badgesState = getExperimentBadgesState(element)
        const elementIndex = index + 1

        const wsTopicBrief = content
          ? getWsTopic({
              data: {
                contentId: content._id,
                elementId: element.element_id,
              },
              action: 'generateElementBrief',
            })
          : ''
        const wsTopicVariants = content
          ? getWsTopic({
              data: {
                contentId: content._id,
                elementId: element.element_id,
              },
              action: 'generateElementVariants',
            })
          : ''

        const isLoading = content?.ws_messages?.some(
          (msg) =>
            msg.wsTopic === wsTopicVariants || msg.wsTopic === wsTopicBrief
        )

        return (
          <ElementCard
            className="mb-4"
            index={elementIndex}
            key={element_id}
            element={element}
            title={display_name || name}
            icon={icon}
            isRemoveOptionShown={isRemoveOptionShown}
            isLoading={isLoading}
            isDisabled={isElementDisabled}
            isSelected={element_id === selectedElementId && !isElementDisabled}
            isConfigurable={true}
            content={badgesState && <ExperimentTypeBadges {...badgesState} />}
            hasElementActionsMenu={true}
            onClick={() =>
              handleElementClick({ isElementDisabled, elementId: element_id })
            }
          />
        )
      })}
      <AddTemplateButton isDisabled={areElementsDisabled} />
    </>
  )
}

export default ElementsList
