import Button from 'common/components/button'

import { useTweakVariant } from '../context/TweakVariantContext'
import type { RejectLineData, TweakLineData, TweakVariant } from '../types'

type ActionsProps = Pick<
  TweakVariant,
  'isTweakingLoading' | 'onClose' | 'onRejectLine' | 'onTweakLine'
>

const Actions = ({
  isTweakingLoading,
  onClose,
  onRejectLine,
  onTweakLine,
}: ActionsProps) => {
  const context = useTweakVariant()

  const {
    feedbackSettings,
    nfmReason,
    whyDislike,
    selectedState,
    showTweakingReason,
    selectedLine,
    variantText,
    tweakingReason,
    nfmReasonDetails,
    campaignType,
    updateState,
  } = context

  const rejectLine = () => {
    if (nfmReasonDetails.trim() === '') {
      updateState({ nfmNoReasonDetails: true })
    } else {
      let whyDislikeReason = whyDislike[nfmReason]
      if (nfmReason === 1 || nfmReason === 2) {
        whyDislikeReason = `${whyDislikeReason} - ${nfmReasonDetails}`
      }
      const data: RejectLineData = {
        sl_id: selectedLine._id,
        grammar_nfm_used: selectedLine.grammar_nfm_used || 0,
        why_dislike: whyDislikeReason,
        feedback_type: 'reject',
      }
      if (feedbackSettings?.reject.feedback_only) {
        data.feedbackOnly = true
      }
      onRejectLine(data)
    }
  }

  const tweakLine = () => {
    if (tweakingReason.trim() !== '') {
      const data: TweakLineData = {
        sl_id: selectedLine._id,
        text: variantText,
        text_before_tweak: selectedLine.text,
        why_tweak: tweakingReason,
        feedback_type: 'tweak',
      }
      onTweakLine(data, campaignType)
    } else if (tweakingReason.trim() === '' && showTweakingReason) {
      updateState({ noTweakingReason: true })
    } else {
      onClose()
    }
  }

  const isReplaceButtonDisable = nfmReasonDetails.trim() === ''
  const isEditButtonDisable =
    tweakingReason.trim() === '' || variantText === selectedLine.text

  return (
    <div className="mt-12 flex justify-end">
      <Button
        ghost
        onClick={onClose}
        className="uppercase mr-4"
        data-cy="tweek-variant-cancel-button"
        data-testid="tweek-variant-cancel-button"
      >
        Cancel
      </Button>
      {selectedState === 'replace' ? (
        <Button
          name="replace"
          className="uppercase"
          data-cy="save-one"
          data-testid="save-one"
          variant="primary"
          loading={isTweakingLoading}
          onClick={rejectLine}
          disabled={isReplaceButtonDisable}
        >
          Replace
        </Button>
      ) : (
        <Button
          name="edit"
          className="uppercase"
          data-cy="save-two"
          data-testid="save-two"
          variant="primary"
          loading={isTweakingLoading}
          onClick={tweakLine}
          disabled={isEditButtonDisable}
        >
          Edit
        </Button>
      )}
    </div>
  )
}

export default Actions
