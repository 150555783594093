import cx from 'classnames'

import Button from 'common/components/button'

type Props = {
  className?: string
  onClickAddImage?: () => void
}

const GalleryEmptyState = ({ onClickAddImage, className }: Props) => {
  return (
    <div className={cx(className, 'flex-1 flex justify-center items-center')}>
      <div className="flex flex-col items-center">
        <h3 className="text-base-700 text-3xl font-medium mb-9">
          You don’t have any images
        </h3>
        {!!onClickAddImage && (
          <Button
            className="uppercase"
            variant="primary"
            onClick={onClickAddImage}
          >
            Add Images
          </Button>
        )}
      </div>
    </div>
  )
}

export default GalleryEmptyState
