import FormItem from 'common/components/formItem'
import MultiSelect, { MultiSelectValue } from 'common/components/MultiSelect'

import { CommonFieldPropsGeneric, DropdownMultiType } from '../interfaces'

const ERROR_MESSAGES = {
  required: 'Please select an option.',
}

type Props = CommonFieldPropsGeneric & {
  value: DropdownMultiType['value']
  min: DropdownMultiType['min']
  max: DropdownMultiType['max']
  options: DropdownMultiType['options']
}

const DropdownMulti = ({
  min,
  max,
  label,
  name,
  value,
  options,
  tooltip,
  errorCode,
  isDisabled,
  placeholder,
  onChange,
  'data-testid': dataTestId,
}: Props) => {
  const items = options.map((option) => ({
    ...option,
    id: option.value,
  }))

  const onApply = (val: string[]) => {
    const values = items
      .filter((item) => val.includes(item.id))
      .map((item) => item.value)

    onChange(values.length > 0 ? values : undefined)
  }

  return (
    <FormItem
      guidingText={tooltip}
      error={errorCode ? ERROR_MESSAGES[errorCode] : undefined}
    >
      <MultiSelect<MultiSelectValue>
        id={name}
        data-testid={dataTestId}
        items={items}
        selectedItems={value || []}
        label={label}
        name=""
        placeholder={placeholder || ''}
        onApply={onApply}
        minOptions={min}
        maxOptions={max}
        isDisabled={isDisabled}
      />
    </FormItem>
  )
}

export default DropdownMulti
