import { contentPath, unifiedFlowPaths } from 'app/navigation/paths'
import { useFlags } from 'launchdarkly-react-client-sdk'

const useContentHomePathPerFlag = (): string => {
  const flags = useFlags()
  return flags.switchToContentLibrary || flags.showUnifiedFlow
    ? unifiedFlowPaths.home
    : contentPath
}

export default useContentHomePathPerFlag
