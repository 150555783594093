import { useForm } from 'react-final-form'

import Button from 'common/components/button'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ArrowLeft as ArrowLeftIcon } from 'common/icons'
import useCheckExperimentNameQuery from 'features/unifiedFlow/api/queries/useCheckExperimentNameQuery'
import { templateSetupComplete } from 'features/unifiedFlow/store/unifiedFlowSlice'
import { selectTemplatesToAddWithPersonalization } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { useExperimentForm } from '../context/ExperimentFormContext'
import {
  getExperimentPrefixedName,
  getFormValue,
  getInitialExperimentNameValue,
  getIsExperimentFormValid,
} from '../helpers'

const SetUpExperimentFooter = () => {
  const {
    state: { template },
    dispatch,
  } = useExperimentForm()
  const form = useForm()
  const formState = form.getState()
  const appDispatch = useAppDispatch()
  const templatesToAddWithPersonalization = useAppSelector(
    selectTemplatesToAddWithPersonalization
  )

  const experimentName = getFormValue({
    formState,
    name: 'experiment.experimentName',
    templateInternalId: template?.internalId,
  })
  const projectId = getFormValue({
    formState,
    name: 'experiment.project',
    templateInternalId: template?.internalId,
  })?.value
  const experimentType: 'optimize' | 'personalize' = getFormValue({
    formState,
    name: 'experimentType',
    templateInternalId: template?.internalId,
  })

  const { isRefetching, refetch } = useCheckExperimentNameQuery({
    projectId,
    experimentName,
  })

  const isExperimentFormValid = getIsExperimentFormValid(form, template)

  const closeExperimentForm = () => {
    dispatch({
      type: 'set_experiment_form_visible',
      value: false,
    })
  }

  const onBackArrowClick = () => {
    if (template) {
      appDispatch(
        templateSetupComplete({
          internalId: template.internalId,
          status: 'incomplete',
        })
      )
    }
    closeExperimentForm()
  }

  const onDoneClick = async () => {
    if (template) {
      if (experimentType === 'optimize') {
        const result = await refetch()

        if (result?.data?.status === 'invalid') {
          form.mutators.setError(
            getExperimentPrefixedName(template, 'experimentName'),
            result.data?.message
          )
          return
        }
      }

      if (experimentType === 'personalize') {
        form.change(
          getExperimentPrefixedName(template, 'experimentName'),
          getInitialExperimentNameValue({
            formState,
            template,
            templatesToAdd: templatesToAddWithPersonalization,
          })
        )
      }

      appDispatch(
        templateSetupComplete({
          internalId: template.internalId,
          status: 'complete',
        })
      )
      closeExperimentForm()
    }
  }

  return (
    <div className="px-6 py-4 w-full">
      <div className="flex justify-between">
        <Button
          onClick={onBackArrowClick}
          className="flex items-center gap-2 bg-gold-300"
        >
          <ArrowLeftIcon size={4} />
          <span className="text-gold-700">Back</span>
        </Button>
        <Button
          variant="primary"
          onClick={onDoneClick}
          disabled={!isExperimentFormValid}
          loading={isRefetching}
        >
          Done
        </Button>
      </div>
    </div>
  )
}

export default SetUpExperimentFooter
