import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react'
import has from 'lodash/has'
import isObject from 'lodash/isObject'
import maxBy from 'lodash/maxBy'
import reduce from 'lodash/reduce'
import toPairs from 'lodash/toPairs'

import { useAppDispatch } from 'common/hooks/redux'
import type { Sentiments } from 'features/reports/insights/api'
import { showRightPanel } from 'features/unifiedFlow/store/unifiedFlowSlice'

interface FormattedSentiment {
  name: string
  value: number
}

interface State {
  sentiments?: FormattedSentiment[]
  category?: string
  defaultSentiment?: FormattedSentiment
  isVariantSelected: boolean
  isLoading: boolean
  variantId?: string
}

type VariantInsightsReducerActions =
  | {
      type: 'set_variant'
      value: {
        category?: string
        sentiments?: Sentiments
        variantId: string
      }
    }
  | { type: 'clear_variant' }

const initialState: State = {
  sentiments: undefined,
  category: undefined,
  defaultSentiment: undefined,
  isVariantSelected: false,
  isLoading: true,
  variantId: undefined,
}

const variantInsightsReducer = (
  state: State,
  action: VariantInsightsReducerActions
) => {
  switch (action.type) {
    case 'set_variant': {
      const { sentiments, category } = action.value

      const formattedSentiments = sentiments
        ? reduce(
            toPairs(sentiments),
            (acc: { name: string; value: number }[], [key, value]) => {
              if (isObject(value) && has(value, 'score')) {
                const score = (value as { score: number }).score
                return [...acc, { name: key, value: score }]
              }

              return acc
            },
            []
          )
        : []

      return {
        ...state,
        sentiments: formattedSentiments,
        category: category ?? state.category,
        defaultSentiment: maxBy(formattedSentiments, 'value'),
        isVariantSelected: !!formattedSentiments,
        isLoading: formattedSentiments.length === 0,
        variantId: action.value.variantId,
      }
    }
    case 'clear_variant':
      return initialState
    default:
      return state
  }
}

type VariantInsightsContextType = {
  state: State
  rightPanelRef: React.Ref<HTMLDivElement>
  tableRef: React.Ref<HTMLDivElement>
  setVariantState: (value: {
    category?: string
    sentiments?: Sentiments
    variantId: string
  }) => void
}

const VariantInsightsContext = createContext<VariantInsightsContextType>({
  rightPanelRef: null,
  tableRef: null,
  state: initialState,
  setVariantState: () => undefined,
})

const VariantInsightsProvider = ({
  children,
  onSetVariantState,
}: {
  children: React.ReactNode
  onSetVariantState: () => void
}) => {
  const tableRef = useRef<HTMLDivElement>(null)
  const rightPanelRef = useRef<HTMLDivElement>(null)

  const [state, dispatchVariantInsightsState] = useReducer(
    variantInsightsReducer,
    initialState
  )

  const dispatch = useAppDispatch()

  const setVariantState = useCallback(
    ({
      variantId,
      category,
      sentiments,
    }: {
      variantId: string
      category?: string
      sentiments?: Sentiments
    }) => {
      dispatchVariantInsightsState({
        type: 'set_variant',
        value: { category, sentiments, variantId },
      })

      dispatch(showRightPanel({ visibility: 'visible', isUserAction: true }))
      onSetVariantState()
    },
    [dispatch, onSetVariantState]
  )

  const onClearVariant = () =>
    dispatchVariantInsightsState({
      type: 'clear_variant',
    })

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        state.isVariantSelected &&
        !rightPanelRef.current?.contains(event.target as HTMLElement) &&
        !tableRef.current?.contains(event.target as HTMLElement)
      ) {
        onClearVariant()
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [state.isVariantSelected])

  const value = {
    state,
    tableRef,
    rightPanelRef,
    setVariantState,
    clearVariantState: onClearVariant,
  }

  return (
    <VariantInsightsContext.Provider value={value}>
      {children}
    </VariantInsightsContext.Provider>
  )
}

const useVariantInsights = () => {
  const context = useContext(VariantInsightsContext)
  if (context === undefined) {
    throw new Error(
      'useVariantInsights must be used within a VariantInsightsProvider'
    )
  }
  return context
}

export { VariantInsightsProvider, useVariantInsights }
