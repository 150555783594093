import Textarea, { TextareaProps } from '../textarea/Textarea'

type Props = TextareaProps

const CodeBlock = (props: Props) => {
  return (
    <Textarea
      showCharacterCount={true}
      placeholder="Add your code here"
      customClassName="bg-base-700 text-white font-normal rounded-sm p-4 
                placeholder-base-300 outline-none w-full font-sourceCodePro"
      {...props}
    />
  )
}

export default CodeBlock
