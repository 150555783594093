import { useQuery } from '@tanstack/react-query'

import Tags, { Option } from 'common/components/tags'

import { EndNode, getEndNodeSuggestions } from './api'

type Props = {
  startNodeId: string | undefined
  tags: Option<string>[]
  onAddClick: (value: string) => void
  onRemoveClick: ((value: string) => void) | undefined
}

const EndTags = ({ startNodeId, tags, onAddClick, onRemoveClick }: Props) => {
  const { data: suggestions } = useQuery(
    ['end-nodes-sugestions', startNodeId],
    () => getEndNodeSuggestions(startNodeId!),
    {
      select: (data: EndNode[]): string[] => {
        return data
          .filter(({ nodeId }) => String(nodeId) !== startNodeId)
          .map(({ displayName }) => displayName)
          .filter((value) => !!value)
      },
      refetchOnWindowFocus: false,
      enabled: !!startNodeId,
    }
  )

  return (
    <div className="ml-6 my-4 flex items-center">
      <Tags
        data-cy="end-nodes"
        data-testid="end-nodes"
        tags={tags}
        onAddClick={suggestions?.length ? onAddClick : undefined}
        onRemoveClick={onRemoveClick}
        suggestions={suggestions}
        hasTagButtonLabel
        tagButtonLabel="Node"
        placeholder="Add a node"
        isFreeText={false}
        isInsideTable
      />
    </div>
  )
}

export default EndTags
