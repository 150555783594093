import { GroupBase, StylesConfig } from 'react-select'

import { SelectValue } from '../BaseSelect'

import { AutocompleteType } from './Autocomplete'

export const styles = <T extends SelectValue, Group extends GroupBase<T>>(
  type: AutocompleteType
): StylesConfig<T, false, Group> => {
  return {
    control: (defaultStyles, state) => ({
      ...defaultStyles,
      width: '100%',
      height: type === 'default' ? 40 : 55,
      paddingLeft: type === 'default' ? 0 : 35,
      paddingRight: type === 'default' ? 0 : 24,
      ...(type === 'default' && {
        borderColor: state.isFocused ? '#af6693' : '#d1d5db',
        boxShadow: state.isFocused
          ? '0 2px 4px 0 rgba(251, 146, 60, 0.24)'
          : 'none',
      }),
      ...(type === 'search' && {
        borderColor: '#d1d5db',
        boxShadow: 'none',
      }),
      ...(type === 'search' &&
        !state.isFocused && {
          borderRadius: '8px',
        }),
      ...(type === 'search' &&
        state.isFocused && {
          borderRadius: '8px',
          // TODO: use commented styles when autocomplete options are available
          // borderTopRightRadius: '8px',
          // borderTopLeftRadius: '8px',
          // borderBottomLeftRadius: '0',
          // borderBottomRightRadius: '0',
        }),
      backgroundColor: 'white',
      ...(state.isDisabled
        ? {
            cursor: 'not-allowed',
            pointerEvents: 'auto',
          }
        : {}),
      '&:hover': {
        borderColor: type === 'default' ? '#af6693' : '#d1d5db',
      },
    }),
    placeholder: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        color: '#9ca3af',
        fontWeight: 400,
      }
    },
    clearIndicator: () => {
      return {
        display: 'none',
      }
    },
    indicatorSeparator: () => {
      return {
        display: 'none',
      }
    },
    indicatorsContainer: () => {
      return {
        display: 'none',
      }
    },
    dropdownIndicator: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        color: state.isFocused
          ? '#7a004b'
          : state.isDisabled
          ? '#999999'
          : '#000000',
        '&:hover': { color: state.isDisabled ? '#999999' : '#7a004b' },
      }
    },
    menu: (defaultStyles) => {
      return {
        ...defaultStyles,
        margin: 0,
        zIndex: 20, // needs to be higher than the z-index used for the search icon
        boxShadow: `0 4px 6px -2px rgba(0, 0, 0, 0.05), 
        0 10px 15px -3px rgba(0, 0, 0, 0.1)`,
        ...(type === 'default' && {
          border: '1px solid  #e4e4e7',
        }),
        ...(type === 'search' && {
          border: '1px solid #d1d5db',
          borderTop: 'none',
          borderTopLeftRadius: '0',
          borderTopRightRadius: '0',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        }),
      }
    },
    option: (defaultStyles, state) => {
      return {
        minHeight: 40,
        paddingLeft: type === 'default' ? 16 : 0,
        display: 'block',
        height: 'auto',
        padding: '10px 20px',
        overflowWrap: 'break-word',
        backgroundColor: !state.isFocused ? '' : '#f3f4f6',
        '&:hover': {
          backgroundColor: '#f3f4f6',
          cursor: 'pointer',
        },
      }
    },
    valueContainer: (defaultStyles, state) => {
      const showSearchIcon = state.selectProps['showSearchIcon']

      return {
        ...defaultStyles,
        height: '100%',
        color: '#1f2937',
        fontWeight: 500,
        padding: '0.5rem 0.75rem',
        paddingLeft: showSearchIcon ? 30 : 0,
      }
    },
    singleValue: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        '&:focus': {
          display: 'none',
          cursor: 'pointer',
        },
      }
    },
    input: (defaultStyles, state) => {
      return {
        ...defaultStyles,
        margin: '0 1rem 0 0',
        padding: 0,
        overflow: 'hidden',
      }
    },
  }
}
