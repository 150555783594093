import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { fetchCombinationVariants, Region } from '../api'
import { personalizedContentKeys } from '../queryKeys'

const useGetCombinationVariantsQuery = ({
  personalizationId,
  combinationId,
  region,
}: {
  personalizationId: string
  combinationId: string
  region: Region | undefined
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return useQuery(
    personalizedContentKeys.combinationVariants({
      personalizationId,
      accountId,
      combinationId,
      region,
    }),
    () =>
      fetchCombinationVariants({
        accountId,
        personalizationId,
        combinationId,
        region,
      })
  )
}

export default useGetCombinationVariantsQuery
