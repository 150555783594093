import IconTitleGroup from 'common/components/iconTitleGroup'

import ContentTemplateCard from '../../contentsPage/ContentTemplateCard/ContentTemplateCard'
import { ContentTypesByTab, TemplateInfo } from '../../contentsPage/interfaces'

import useTemplateTypesBlocked from './hooks/useTemplateTypesBlocked'

type Props = {
  templates: TemplateInfo[]
  contentTypes: ContentTypesByTab
  experimentTypes: string[] | undefined
}

const TemplatesGrid = ({ templates, contentTypes }: Props) => {
  const typesOfTemplatesBlocked = useTemplateTypesBlocked(templates)

  return (
    <div>
      {Object.keys(contentTypes).map((type) => {
        const filterByContentType = (template: TemplateInfo): boolean =>
          template.contentType.toLowerCase() === type

        const templatesOfType = templates.filter(filterByContentType)

        if (templatesOfType.length === 0) {
          return null
        }
        return (
          <div
            key={type}
            className="mb-9"
            data-testid={`${type}-template-grid`}
          >
            <IconTitleGroup
              title={
                <h2 className="text-sm font-medium text-base-700 uppercase">
                  {type}
                </h2>
              }
              className="mb-6"
            />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {templatesOfType.map(
                ({
                  id,
                  displayName,
                  disabled,
                  optimization,
                  personalization,
                  icon,
                }) => {
                  const isTemplateDisabled =
                    disabled ||
                    (type !== 'ecommerce' &&
                      typesOfTemplatesBlocked === 'non-ecommerce') ||
                    (type === 'ecommerce' &&
                      typesOfTemplatesBlocked === 'ecommerce')

                  return (
                    <div
                      key={id}
                      onClick={() => {}}
                      className={isTemplateDisabled ? '' : 'cursor-pointer'}
                    >
                      <ContentTemplateCard
                        icon={icon}
                        templateId={id}
                        header={displayName}
                        disabled={isTemplateDisabled}
                        isComingSoon={disabled}
                        optimization={optimization}
                        personalization={personalization}
                      />
                    </div>
                  )
                }
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default TemplatesGrid
