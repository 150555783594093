import { useState } from 'react'
import { Element } from '@phrasee/phrasee-typings'
import cx from 'classnames'

import ConfirmationModal from 'common/components/confirmationModal/ConfirmationModal'
import MenuButton, { MenuDivider, MenuItem } from 'common/components/menuButton'
import { useAppSelector } from 'common/hooks/redux'
import { Bin, Edit, MoreOptionsVertical, Settings } from 'common/icons'
import useDeleteElementMutation from 'features/futurama/api/mutations/useDeleteElementMutation'

import { getElementHasVariants } from '../../helpers'

import ContentRenameModal from './ContentRenameModal'

type Props = {
  element?: Element
  isSelected?: boolean
  isMenuOpen?: boolean
  isEditHidden?: boolean
  isRemoveHidden?: boolean
  onMenuChange?: (changeEvent: { open: boolean }) => void
  onEditClick: () => void
}

const VerticalDotMenu = ({
  isSelected,
  element,
  isMenuOpen,
  isEditHidden,
  isRemoveHidden,
  onMenuChange,
  onEditClick,
}: Props) => {
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [showRenameModal, setShowRenameModal] = useState(false)

  const deleteElementMutation = useDeleteElementMutation()

  const accountId = useAppSelector((state) => state.authStates.accountId)
  const activeContentId = useAppSelector(
    (state) => state.content.activeContentId
  )

  const showComponentConfiguration = useAppSelector(
    (state) => state.content.showComponentConfiguration
  )

  const elementId = element?.element_id

  const handleRename = () => {
    setShowRenameModal(true)
  }

  const handleRemove = (event) => {
    event.stopPropagation()
    if (elementId && activeContentId && elementId > 1) {
      if (getElementHasVariants(element) && !showRemoveModal) {
        setShowRemoveModal(true)
        return
      }
      deleteElementMutation.mutate({
        elementId,
        accountId,
        contentId: activeContentId,
      })
    }
    setShowRemoveModal(false)
  }

  return (
    <>
      <MenuButton
        portal
        onMenuChange={onMenuChange}
        buttonProps={{
          'aria-label': 'More options',
          onClick: (e) => e.stopPropagation(),
          children: (
            <MoreOptionsVertical
              isDefaultColor={false}
              size={6}
              className={cx('hover:text-maroon-200 outline-none', {
                'text-coolGray-400':
                  !isSelected || (isSelected && !showComponentConfiguration),
                'text-maroon-200': isSelected && showComponentConfiguration,
                'invisible group-hover:visible': !isMenuOpen,
                visible: isMenuOpen,
              })}
            />
          ),
        }}
        options={
          <>
            <MenuItem key="1" onClick={handleRename} aria-label="Rename">
              <Edit isDefaultColor={false} className="mr-4 text-coolGray-400" />
              Rename
            </MenuItem>
            {!isEditHidden && (
              <MenuItem
                key="2"
                onClick={(event) => {
                  event.syntheticEvent?.stopPropagation()
                  onEditClick()
                }}
                aria-label="Rename"
              >
                <Settings
                  isDefaultColor={false}
                  className="mr-4 text-coolGray-400"
                />
                Edit settings
              </MenuItem>
            )}
            {!isRemoveHidden && (
              <>
                <MenuDivider />
                <MenuItem
                  key="3"
                  onClick={(e) => handleRemove(e.syntheticEvent)}
                  aria-label="Delete"
                >
                  <Bin
                    isDefaultColor={false}
                    className="mr-4 text-coolGray-400"
                  />
                  Remove
                </MenuItem>
              </>
            )}
          </>
        }
      />
      <ConfirmationModal
        data-cy="remove-element-modal"
        data-testid="remove-element-modal"
        title="Remove content"
        confirmationText="Are you sure you want to permanently remove this content? Once it's gone, it's gone!"
        confirmButtonText="Remove"
        cancelButtonText="Cancel"
        open={showRemoveModal}
        hasConfirmationSlider={true}
        onConfirm={handleRemove}
        onCancel={() => setShowRemoveModal(false)}
      />
      {showRenameModal && element && (
        <ContentRenameModal
          element={element}
          onClose={() => setShowRenameModal(false)}
        />
      )}
    </>
  )
}

export default VerticalDotMenu
