import Toggle from 'common/components/toggle'
import Tooltip from 'common/components/Tooltip'
import { InfoCircle as InfoCircleIcon } from 'common/icons'

type Props = {
  isActionsDisabled?: boolean
  isVariantOnly: boolean
  toggleVariantOnly: () => void
  onLoadedVariantOnly: (data: boolean) => void
}

const VariantOnlyToggle = ({
  isVariantOnly,
  isActionsDisabled,
  toggleVariantOnly,
}: Props) => {
  return (
    <div className="mb-4 flex">
      <Toggle
        disabled={isActionsDisabled}
        checked={isVariantOnly}
        onChange={() => toggleVariantOnly()}
        label="Include variant-only test"
      />
      {isVariantOnly && (
        <Tooltip
          overlayClassName="max-w-md break-words"
          overlay="Enable this option to test a variant with only text content, no images. This is useful for focusing solely on the impact of textual content. When this is enabled, you can select a maximum of two images for other variants, as one of the three available slots will be reserved for the text-only variant."
          placement="right"
        >
          <InfoCircleIcon
            className="cursor-pointer ml-2 text-base-700"
            size={4}
            isDefaultColor={false}
          />
        </Tooltip>
      )}
    </div>
  )
}

export default VariantOnlyToggle
