import { ReactNode } from 'react'
import { Row } from 'react-table'
import cx from 'classnames'

import Button from 'common/components/button'
import BaseCell from 'common/components/table/cells/Base'
import { ChevronDownSquare, ChevronUpSquare } from 'common/icons'

type Props<T extends object> = {
  row: Row<T>
  children: ReactNode
  onCellClick?: () => void
  onExpandClick?: () => void
}

const ExpandableCell = <T extends object>({
  row,
  children,
  onCellClick,
  onExpandClick,
}: Props<T>) => {
  const CellComponent = row.depth > 0 ? BaseCell : 'div'

  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onExpandClick?.()
    if (row.depth === 0) {
      row.toggleRowExpanded(!row.isExpanded)
    }
  }

  return (
    <CellComponent
      className={cx(
        'flex items-center w-full pr-4 border-r-1 border-gold-200',
        {
          'ml-9': row.depth > 0,
          'cursor-pointer': row.depth === 0,
        }
      )}
      onClick={onCellClick}
    >
      {row.depth === 0 && (
        <Button
          variant="icon"
          className="flex-shrink-0 h-full pl-6 pr-3"
          onClick={handleExpandClick}
        >
          {row.isExpanded ? (
            <ChevronUpSquare size={6} className="text-gold-700" />
          ) : (
            <ChevronDownSquare size={6} className="text-gold-700" />
          )}
        </Button>
      )}
      {children}
    </CellComponent>
  )
}

export default ExpandableCell
