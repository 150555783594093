import { Fragment, useEffect } from 'react'

import { formatDate } from 'common/helpers/date'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  hideRightPane,
  showRightPane,
} from 'features/futurama/store/contentSlice'

import RightPane from '../components/RightPane'
import { useContent } from '../hooks'

import useGetLogsQuery from './api/queries/useGetLogsQuery'
import IntegrationLogSectionEmpty from './IntegrationLogSectionEmpty'

const IntegrationLogSection = () => {
  const dispatch = useAppDispatch()

  const rightPaneVisibility = useAppSelector(
    (state) => state.content.rightPaneVisibility
  )

  const { content } = useContent()
  const { data: logs, isLoading } = useGetLogsQuery(content?._id)

  const getState = () => {
    if (isLoading) {
      return 'loading'
    } else if (!logs?.length) {
      return 'empty'
    } else {
      return 'hasLogs'
    }
  }

  const state = getState()

  useEffect(() => {
    if (state === 'hasLogs' && rightPaneVisibility === 'none') {
      dispatch(showRightPane('auto'))
    } else if (state === 'empty') {
      dispatch(hideRightPane('none'))
    }
  }, [state, dispatch, rightPaneVisibility])

  if (state === 'empty' || state === 'loading') {
    return <IntegrationLogSectionEmpty />
  }

  return (
    <RightPane
      visibility={rightPaneVisibility}
      scrollableParentId="main-content-page"
      onOutsideClick={() => dispatch(hideRightPane())}
    >
      <div className="flex flex-col p-6 bg-gold-40 flex-1">
        <h5 className="text-coolGray-800 font-medium text-xl">Log</h5>
        {
          {
            hasLogs: (
              <>
                {logs?.map(({ elementId, entries }, index) => {
                  const element = content?.elements.find(
                    (element) => element.element_id === elementId
                  )
                  const elementName = element?.display_name || element?.name

                  return (
                    <div
                      key={index}
                      className="flex flex-col mt-6 p-4 bg-coolGray-50"
                    >
                      <span className="font-medium text-coolGray-800">
                        {elementName}
                      </span>
                      <hr className="my-6 text-coolGray-300 border-dashed" />
                      {entries.map(
                        ({ files, providerName, sendDate, user }, index) => (
                          <Fragment key={index}>
                            {index > 0 && (
                              <hr className="my-4 text-coolGray-300" />
                            )}
                            <div className="flex gap-2">
                              <div>
                                <span className="block text-coolGray-500 text-sm">
                                  User:
                                </span>
                                <span className="block text-coolGray-500 text-sm">
                                  Sent date:
                                </span>
                                <span className="block text-coolGray-500 text-sm mt-6">
                                  Files:
                                </span>
                              </div>
                              <div className="text-sm text-coolGray-800">
                                <span className="block">{user}</span>
                                <span className="block">
                                  {formatDate(sendDate)}
                                </span>
                                <div className="mt-6">
                                  <span className="block font-bold">
                                    {providerName}
                                  </span>
                                  <ul className="list-disc list-inside pl-2">
                                    {files.map((file, index) => (
                                      <li key={index}>{file}</li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        )
                      )}
                    </div>
                  )
                })}
              </>
            ),
          }[state]
        }
      </div>
    </RightPane>
  )
}

export default IntegrationLogSection
