import React, { ReactNode } from 'react'
import cx from 'classnames'

import CreateCampaignButton from 'common/createCampaignButton'

interface Props {
  children: ReactNode
  className?: string
  'data-cy'?: string
  'data-testid'?: string
}

export const messages = {
  error:
    'Oh drat, diggity, dang! Something has gone horribly wrong and your data can’t be loaded. Try refreshing the page and if that doesn’t work pop us a message.',
  noData: 'Waiting for data...',
  waitingForChartsData:
    'We are waiting for a little data before we can show off some fancy dancy charts. Once the data starts to arrive it will appear here.',
}

const WidgetMessage = ({
  children,
  className,
  'data-cy': dataCy,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <div
      className={cx(
        `flex flex-1 flex-col justify-center items-center py-8 px-16 bg-gray-50 
        border text-center`,
        className
      )}
      data-cy={dataCy}
      data-testid={dataTestId}
    >
      {children}
    </div>
  )
}

interface FirstUseProps {
  shouldShowCreateCampaignButton?: boolean
  className?: string
  text?: string
}

export const WidgetFirstUse = ({
  shouldShowCreateCampaignButton,
  className,
  text = messages.noData,
}: FirstUseProps) => {
  return (
    <WidgetMessage
      className={className}
      data-cy="widget-first-use"
      data-testid="widget-first-use"
    >
      <p className="text-coolgray-500">{text}</p>

      {shouldShowCreateCampaignButton && (
        <CreateCampaignButton className="mt-6" />
      )}
    </WidgetMessage>
  )
}

export const WidgetError = ({ className }: { className?: string }) => {
  return (
    <WidgetMessage
      className={cx('border-red-500 py-18', className)}
      data-cy="widget-error"
      data-testid="widget-error"
    >
      <p className="text-red-400 w-90">{messages.error}</p>
    </WidgetMessage>
  )
}

export const WidgetWaitingForChartsData = ({
  className,
}: {
  className?: string
}) => {
  return (
    <WidgetMessage
      className={className}
      data-cy="widget-waiting-for-data"
      data-testid="widget-waiting-for-data"
    >
      <p className="text-coolgray-500">{messages.waitingForChartsData}</p>
    </WidgetMessage>
  )
}

export const WidgetIncrementalError = ({
  className,
}: {
  className?: string
}) => {
  return (
    <WidgetMessage
      className={cx('border-red-500 h-32', className)}
      data-cy="widget-error"
      data-testid="widget-error"
    >
      <p className="text-red-400">
        Oh drat, diggity, dang! Your data couldn’t be loaded. Try refreshing the
        page or send us a message.
      </p>
    </WidgetMessage>
  )
}

export default WidgetMessage
