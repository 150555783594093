import cx from 'classnames'
import Tabs, { TabPane } from 'rc-tabs'

import { MoreOptions } from 'common/icons'

import { WidgetFirstUse } from '../WidgetMessage'

import 'rc-tabs/assets/index.css'
import styles from './TabWidget.module.css'

export type Tab = {
  name: string | JSX.Element
  content: JSX.Element
}

interface Props {
  tabs: Tab[] | undefined
  firstUse?: boolean
  onTabClick?: (
    activeKey: string,
    e: React.KeyboardEvent | React.MouseEvent
  ) => void
  destroyInactiveTabPane?: boolean
  activeTabKey?: string
}

const TabWidget = ({
  tabs,
  firstUse,
  onTabClick,
  destroyInactiveTabPane,
  activeTabKey,
}: Props) => {
  return (
    <Tabs
      activeKey={activeTabKey}
      destroyInactiveTabPane={destroyInactiveTabPane}
      onTabClick={onTabClick}
      defaultActiveKey="0"
      className={cx(styles.tabs, { [styles.emptyTab]: firstUse })}
      moreIcon={<MoreOptions className="opacity-50 text-coolGray-400 mr-5" />}
    >
      {firstUse ? (
        <TabPane tab="">
          <div className={cx(styles.widget)}>
            <WidgetFirstUse shouldShowCreateCampaignButton className="m-6" />
          </div>
        </TabPane>
      ) : (
        tabs?.map(({ name, content }, index) => (
          <TabPane
            tab={
              <span data-cy={`tab-${index}`} data-testid={`tab-${index}`}>
                {name}
              </span>
            }
            key={index}
            className="text-sm"
          >
            <div className={cx(styles.widget)}>{content}</div>
          </TabPane>
        ))
      )}
    </Tabs>
  )
}

export default TabWidget
