import type { UpdateImageStatusRequest } from '@phrasee/phrasee-typings/typings/image/image'
import type { UpdateImageRequest } from '@phrasee/phrasee-typings/typings/image/image'
import type { ImagePageResponse } from '@phrasee/phrasee-typings/typings/image/page'
import type {
  CreateImagesRequest,
  FileContentType,
  ImageFile,
  ImageUploadUrl,
  PresignedUrl,
} from '@phrasee/phrasee-typings/typings/image/presigned-url'

import { connectInternalApi } from 'common/api'

export type {
  CreateImagesRequest,
  ImageUploadUrl,
  UpdateImageStatusRequest,
  PresignedUrl,
  FileContentType,
  ImageFile,
  UpdateImageRequest,
  ImagePageResponse,
}

export const generatePresignedUrls = async (
  accountId: string,
  body: CreateImagesRequest
) => {
  const result = await connectInternalApi.post<ImageUploadUrl[]>(
    `v1/images-api/accounts/${accountId}/images`,
    body
  )

  return result.data
}

export const updateImageStatus = async (
  accountId: string,
  body: UpdateImageStatusRequest
) => {
  const result = await connectInternalApi.put(
    `v1/images-api/accounts/${accountId}/images/status`,
    body
  )

  return result.data
}

export const updateImageMetadata = async (
  accountId: string,
  imageId: string,
  body: UpdateImageRequest
) => {
  const result = await connectInternalApi.patch(
    `v1/images-api/accounts/${accountId}/images/${imageId}`,
    body
  )

  return result.data
}
