import { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import cx from 'classnames'

import useGetNodesQuery from 'common/api/queries/useGetNodesQuery'
import BottomToolbar from 'common/bottomToolbar'
import Button from 'common/components/button'
import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import Loader from 'common/components/loaders/Loader'
import NodesGraph from 'common/components/nodesGraph'
import PageContainer from 'common/components/PageContainer'
import { errorToast } from 'common/components/toastNotification'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'

import { createNode, getNode } from '../api'
import { NodeMetadata } from '../api/interfaces'
import { contentKeys } from '../api/queryKeys'

import AddNodeForm from './components/AddNodeForm'
import Header from './components/Header'
import NodesActions from './components/NodesActions'
import PhrasesTable from './components/PhrasesTable'
import Reporting from './components/Reporting'

// Hardcoded for now
const SELECTED_ROOT_NODE_ID = '5'

const Nodes = () => {
  const { user_id: userId } = useAppSelector((state) => state.authStates)

  const [selectedNodeId, setSelectedNodeId] = useState<string>()
  const [selectedRootId, setSelectedRootId] = useState<string>()
  const [isAddingNode, setIsAddingNode] = useState(false)

  const isAddingNodeRef = useRef(isAddingNode)

  useDocumentTitle('Nodes | Jacquard')

  const { status, data, refetch } = useGetNodesQuery()

  const isTreeView = !selectedNodeId || selectedNodeId === SELECTED_ROOT_NODE_ID

  const { data: nodeMetadata } = useQuery<NodeMetadata>(
    contentKeys.nodeMetadata(selectedNodeId!),
    () => getNode(selectedNodeId!),
    {
      enabled: !isTreeView,
    }
  )

  const nodes = data?.nodes || []
  const metadata = isTreeView ? data?.metadata : nodeMetadata
  const selectedNode = nodes?.find(
    (node) => node.id === (selectedNodeId || SELECTED_ROOT_NODE_ID)
  )
  const isParentNode =
    nodes.filter((node) => node.parent === selectedNodeId).length > 0

  const getHasChildren = (nodeId?: string) => {
    return nodes.some((node) => node.parent === nodeId)
  }

  const onNodeClick = (nodeId?: string, rootNodeId?: string) => {
    setSelectedRootId((prevRootId) => {
      const newSelectedNodeId = nodeId === prevRootId ? rootNodeId : nodeId
      const newSelectedNode = nodes.find(
        (node) => node.id === newSelectedNodeId
      )

      const shouldSelectNode = !(
        isAddingNodeRef.current && newSelectedNode?.isPrivate
      )
      if (shouldSelectNode) {
        setSelectedNodeId(newSelectedNodeId)
      }
      return getHasChildren(newSelectedNodeId)
        ? rootNodeId
        : nodes.find((node) => node.id === newSelectedNodeId)?.parent
    })
  }

  const updateSelectedNodeId = (nodeId?: string) => {
    const newSelectedRootId = nodeId
      ? getHasChildren(nodeId)
        ? nodeId
        : nodes.find((node) => node.id === nodeId)?.parent
      : undefined

    setSelectedNodeId(nodeId)
    setSelectedRootId(newSelectedRootId)
  }

  const onNodeAdd = (nodeId: string, rootId: string) => {
    setIsAddingNode(false)
    setSelectedNodeId(nodeId)
    setSelectedRootId(rootId)
    refetch()
  }

  const createNodeMutation = useMutation({
    mutationFn: ({
      nodeName,
      parentNode,
      isPrivate,
      isDisplayed,
    }: {
      nodeName: string
      parentNode: string
      isPrivate: boolean
      isDisplayed: boolean
    }) => {
      return createNode({
        displayName: nodeName,
        parentNodeId: Number(parentNode),
        userId,
        isPrivate,
        isDisplayed,
      })
    },
    onError: () => errorToast('Failed to save node'),
    onSuccess: ({ nodeId, parentNodeId }) =>
      onNodeAdd(String(nodeId), String(parentNodeId)),
  })

  useEffect(() => {
    isAddingNodeRef.current = isAddingNode

    if (isAddingNode && selectedNode?.isPrivate) {
      setSelectedNodeId(undefined)
      setSelectedRootId(SELECTED_ROOT_NODE_ID)
    }
  }, [isAddingNode, selectedNode])

  return (
    <PageContainer className="pl-6">
      {
        {
          loading: <Loader />,
          error: <ErrorPage />,
          success: (
            <div
              className={cx({
                'mx-6 pt-8 lg:pt-12 max-w-400': !isAddingNode,
              })}
            >
              {isAddingNode ? (
                <AddNodeForm
                  nodes={nodes}
                  selectedNodeId={selectedNodeId}
                  updateSelectedNodeId={updateSelectedNodeId}
                  onSubmit={({
                    nodeName,
                    parentNode,
                    isPrivate,
                    isDisplayed,
                  }) =>
                    createNodeMutation.mutate({
                      nodeName,
                      parentNode,
                      isPrivate,
                      isDisplayed,
                    })
                  }
                />
              ) : (
                <Header
                  nodes={nodes}
                  selectedNodeId={selectedNodeId || SELECTED_ROOT_NODE_ID}
                  updateSelectedNodeId={updateSelectedNodeId}
                  onAddNodeClick={() => setIsAddingNode(true)}
                />
              )}
              <NodesGraph
                nodes={nodes}
                selectedRootId={selectedRootId}
                onNodeClick={onNodeClick}
              />
              {selectedNode && (
                <NodesActions
                  selectedNode={selectedNode}
                  rootNodeId={SELECTED_ROOT_NODE_ID}
                  isParentNode={isParentNode}
                  nodes={nodes}
                  setSelectedNodeId={setSelectedNodeId}
                  onNodeClick={onNodeClick}
                />
              )}
              {selectedNode && (
                <Reporting
                  node={selectedNode}
                  metadata={metadata}
                  isTreeView={isTreeView}
                />
              )}
              {!isAddingNode &&
                selectedNodeId &&
                selectedNodeId !== SELECTED_ROOT_NODE_ID && (
                  <PhrasesTable
                    selectedNodeId={selectedNodeId}
                    rootNodeId={SELECTED_ROOT_NODE_ID}
                  />
                )}
              {isAddingNode ? (
                <BottomToolbar className="justify-between">
                  <Button
                    data-cy="add-node-cancel-button"
                    data-testid="add-node-cancel-button"
                    variant="danger"
                    ghost
                    onClick={() => setIsAddingNode(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-cy="add-node-save-button"
                    data-testid="add-node-save-button"
                    variant="primary"
                    disabled={createNodeMutation.isLoading}
                    loading={createNodeMutation.isLoading}
                    onClick={() =>
                      document
                        .getElementById('create-project-form')
                        ?.dispatchEvent(
                          new Event('submit', {
                            cancelable: true,
                            bubbles: true,
                          })
                        )
                    }
                  >
                    Save node
                  </Button>
                </BottomToolbar>
              ) : (
                <Footer />
              )}
            </div>
          ),
        }[status]
      }
    </PageContainer>
  )
}

export default Nodes
