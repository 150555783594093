import { useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import { isEqual } from 'lodash'

import Button from 'common/components/button'
import Loader from 'common/components/loaders/Loader'
import { SelectValue } from 'common/components/singleSelect'
import Widget from 'common/components/Widget'
import { useDocumentTitle } from 'common/hooks/custom'
import { ReactComponent as EmailIcon } from 'common/icons/email/default.svg'
import { ReactComponent as PersonaIcon } from 'common/icons/persona/default.svg'
import { ReactComponent as PushIcon } from 'common/icons/push/default.svg'
import { ReactComponent as RefreshIcon } from 'common/icons/refresh/default.svg'

import useGetVariantsQuery from '../api/queries/useGetVariantsQuery'
import useDemoData from '../hooks/useDemoData'

import DemoTable from './DemoTable'
import SingleSelectField from './SingleSelectField'

export type FormValues = {
  previousVariantsIds: string[]
  brand: string
  channel: string
  persona: string
}

type BrandSelectOption = SelectValue & {
  description: string
}

type PersonaSelectOption = SelectValue & {
  location: string
  age: number
  loyalty: string
}

const DemoContent = () => {
  const { brands, channels, personas } = useDemoData()
  useDocumentTitle('Personalised Language Preview')

  const [formState, setFormState] = useState<FormValues>({
    previousVariantsIds: [],
    brand: '',
    channel: '',
    persona: '',
  })

  const variantsQuery = useGetVariantsQuery({
    prevVariantsIds: formState.previousVariantsIds,
    brand: formState.brand,
    channel: formState.channel,
    persona: formState.persona,
  })

  return (
    <Form<FormValues>
      initialValues={{
        brand: brands?.[0].value,
        channel: channels?.[0].value,
        persona: personas?.[0].value,
      }}
      onSubmit={() => {}}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit} className="pb-5">
            <div className="flex justify-between">
              <div className="flex items-center gap-6 mt-2">
                <SingleSelectField<BrandSelectOption>
                  name="brand"
                  label="Brand"
                  options={brands ?? []}
                  formatOptionLabel={(option, { context }) => {
                    return context === 'menu' ? (
                      <div className="flex flex-col gap-3">
                        <div className="font-medium">{option.label}</div>
                        <div className="text-xs">{option.description}</div>
                      </div>
                    ) : (
                      <div>{option.label}</div>
                    )
                  }}
                />
                <SingleSelectField
                  name="channel"
                  label="Channel"
                  options={channels ?? []}
                  formatOptionLabel={(option) => {
                    return (
                      <div className="flex items-center gap-2">
                        {option.label === 'Email' ? (
                          <EmailIcon width={16} height={16} />
                        ) : (
                          <PushIcon width={16} height={16} />
                        )}
                        {option.label}
                      </div>
                    )
                  }}
                />
                <SingleSelectField<PersonaSelectOption>
                  name="persona"
                  label="Persona"
                  options={personas ?? []}
                  formatOptionLabel={(option, { context }) => {
                    return context === 'menu' ? (
                      <div className="flex gap-1">
                        <div className="flex items-center">
                          <PersonaIcon width={24} height={24} />
                        </div>
                        <div className="text-sm">
                          <div className="mb-2 font-medium">{option.label}</div>
                          <div>Location: {option.location}</div>
                          <div>Age: {option.age}</div>
                          <div>Loyalty Tier: {option.loyalty}</div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center gap-1">
                        <PersonaIcon width={24} height={24} />
                        {option.label}
                      </div>
                    )
                  }}
                />
                <Button
                  variant="primary"
                  className="uppercase"
                  onClick={() => {
                    setFormState((prevState) => {
                      return {
                        ...prevState,
                        previousVariantsIds:
                          variantsQuery.data?.map(
                            (variant) => variant.variantId
                          ) ?? [],
                      }
                    })
                  }}
                >
                  Regenerate
                  <RefreshIcon className="ml-2" width={24} height={24} />
                </Button>
              </div>
            </div>
            {variantsQuery.isLoading ? (
              <Widget className="flex w-full justify-center min-h-200  bg-white">
                <Loader />
              </Widget>
            ) : (
              <DemoTable
                data={variantsQuery.data ?? []}
                channel={formState.channel}
              />
            )}
            <FormSpy<FormValues>
              onChange={(formState) => {
                if (formState.values) {
                  setFormState((prevState) => ({
                    ...prevState,
                    ...formState.values,
                    ...(!isEqual(values, formState.values) && {
                      previousVariantsIds: [],
                    }),
                  }))
                }
              }}
            />
          </form>
        )
      }}
    />
  )
}

export default DemoContent
