import { useEffect, useRef, useState } from 'react'
import cx from 'classnames'

import Tooltip from 'common/components/Tooltip'
import { Encrypted } from 'common/icons'

type ToggleButtonGroupItem = {
  value: string
  label: string
  icon?: React.ReactNode
  disabled?: boolean
  tooltipMessage?: string
}

type Props = {
  onChange: (value: ToggleButtonGroupItem['value']) => void
  items: ToggleButtonGroupItem[]
  value?: ToggleButtonGroupItem['value']
  className?: string
  label?: string
}

const findActiveIndex = (items: Props['items'], value: Props['value']) =>
  items.findIndex((item) => item.value === value)

const calculateOffset = (
  refs: (HTMLButtonElement | null)[],
  activeIndex: number
) =>
  refs
    .map((btn) => btn?.offsetWidth ?? 0)
    .slice(0, activeIndex)
    .reduce((acc, width) => acc + width, 0)

const ToggleButtonGroup = ({ value, items, onChange, className }: Props) => {
  const [shakeItem, setShakeEffect] = useState<string | undefined>(undefined)
  const [activeButtonStyle, setActiveButtonStyle] = useState<{
    width: number
    transform: string
  }>({ width: 0, transform: 'translateX(0px)' })

  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([])

  const handleClick = (value: string, index: number) => {
    if (!items[index].disabled) {
      onChange(value)
    } else {
      setShakeEffect(value)
    }
  }

  const activeIndex = findActiveIndex(items, value)

  useEffect(() => {
    const activeButtonRef = buttonRefs.current[activeIndex]
    if (activeButtonRef) {
      setActiveButtonStyle({
        width: activeButtonRef.offsetWidth ?? 0,
        transform: `translateX(${calculateOffset(
          buttonRefs.current,
          activeIndex
        )}px)`,
      })
    }
  }, [value, items, activeIndex])

  return (
    <div
      className="relative flex items-center bg-yellow-200 bg-opacity-20 rounded-full text-base-700 w-max"
      role="group"
    >
      <div
        className="absolute inset-0 bg-yellow-400 rounded-full bezier-all-transition"
        style={activeButtonStyle}
      />

      {items.map(({ value, disabled, tooltipMessage, label, icon }, index) => (
        <Tooltip
          key={value}
          overlay={tooltipMessage}
          placement="top"
          show={disabled}
        >
          <div data-testid={`toggleButtonGroup-${value}`} className="relative">
            <button
              aria-disabled={disabled}
              onAnimationEnd={() => setShakeEffect(undefined)}
              type="button"
              onClick={() => handleClick(value, index)}
              className={cx(
                'relative flex items-center gap-1 px-2 py-1 text-xs font-medium focus:outline-none focus:ring-0 rounded-full z-10 text-2.5',
                {
                  'opacity-50 cursor-not-allowed': disabled,
                  'animate-shake': disabled && shakeItem === value,
                },
                className
              )}
              ref={(el) => (buttonRefs.current[index] = el)}
            >
              {disabled ? <Encrypted isDefaultColor={false} size={3} /> : icon}
              {label}
            </button>
          </div>
        </Tooltip>
      ))}
    </div>
  )
}

export default ToggleButtonGroup
