import { useQuery } from '@tanstack/react-query'
import Content from 'app/Content'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { canAccessAccount, routesPermissions } from 'common/auth/permissions'
import IconTitleGroup from 'common/components/iconTitleGroup'
import Footer from 'common/components/layout/Footer'
import Loader from 'common/components/loaders/Loader'
import PageHeader from 'common/components/PageHeader'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'
import { useWindowDimensions } from 'common/hooks/useWindowDimensions'
import ContentKpi from 'features/homePage/components/ContentKpi'

import useGetRecentContentsQuery from './api/queries/useGetRecentContentsQuery'
import { contentKeys } from './api/queryKeys'
import HomepageBanner from './components/HomepageBanner/HomepageBanner'
import QuickCards from './components/QuickCards'
import RecentContents from './components/RecentContents'
import RecentExperiments from './components/RecentExperiments'
import Todo from './components/ToDo'
import TodoEmpty from './components/ToDoEmpty'
import UpgradeAccountComponent from './components/UpgradeComponent'
import OptimizationMetrics from './optimizationWidgets/Optimization'
import { fetchRecentlyCreated, fetchTodos, getContentKpi } from './api'
const EXPERIMENTS_LIST_SIZE = 8
const TO_DO_LIST_SIZE = 8

const HomePage = () => {
  useDocumentTitle('Home | Jacquard')
  const flags = useFlags()

  const {
    accountId,
    user_id: userId,
    accountCategory,
  } = useAppSelector((state) => state.authStates)
  const { screenWidth } = useWindowDimensions()

  const { data: dataExperiments, status: statusExperiments } = useQuery(
    [accountId],
    async () => await fetchRecentlyCreated(0, EXPERIMENTS_LIST_SIZE, accountId)
  )

  const { data: kpiContent, status: kpiContentStatus } = useQuery(
    contentKeys.contentKpi(accountId, flags.switchToContentLibrary),
    async () => await getContentKpi(accountId, flags.switchToContentLibrary)
  )

  const experiments = dataExperiments ? dataExperiments.campaigns : []

  const { data: dataTodos, status: statusToDos } = useQuery(
    [accountId, userId],
    async () =>
      await fetchTodos({
        page: 0,
        pageSize: TO_DO_LIST_SIZE,
        userId,
        accountId,
      })
  )
  const todos = dataTodos ? dataTodos.campaigns : []

  const { data: recentContents, status: statusContents } =
    useGetRecentContentsQuery()

  const hasAccessToExperiments = canAccessAccount(
    accountCategory,
    routesPermissions.experimentsView
  )

  const isLoading =
    statusExperiments === 'loading' ||
    statusToDos === 'loading' ||
    statusContents === 'loading' ||
    kpiContentStatus === 'loading'

  const layoutMd = (
    <div>
      <IconTitleGroup title="Optimisation" />
      <hr className="w-full mt-4 mb-6 border-gold-400" />
      {hasAccessToExperiments && <OptimizationMetrics accountId={accountId} />}
      {!hasAccessToExperiments && <UpgradeAccountComponent />}
      <hr className="w-full my-6 border-gold-400" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-10">
        {!flags.switchToContentLibrary && (
          <>
            <div className="w-full flex flex-1 overflow-hidden">
              <ContentKpi
                title={
                  <IconTitleGroup
                    title={
                      flags.showUnifiedFlow
                        ? 'Content library'
                        : 'Content generation'
                    }
                  />
                }
                kpiContent={kpiContent}
                isLoading={kpiContentStatus === 'loading'}
              />
            </div>
            {hasAccessToExperiments && (
              <>
                {(todos.length > 0 || statusToDos !== 'loading') && (
                  <div className="w-full flex flex-1 overflow-hidden">
                    {todos.length > 0 && (
                      <Todo campaigns={todos} status={statusToDos} />
                    )}
                    {todos.length === 0 && statusToDos !== 'loading' && (
                      <TodoEmpty />
                    )}
                  </div>
                )}

                {experiments.length > 0 && (
                  <div className="w-full flex flex-1 overflow-hidden">
                    <RecentExperiments
                      campaigns={experiments}
                      status={statusExperiments}
                    />
                  </div>
                )}
              </>
            )}
          </>
        )}

        {recentContents && recentContents.length > 0 && (
          <div className="w-full flex flex-1 overflow-hidden">
            <RecentContents contents={recentContents} status={statusContents} />
          </div>
        )}
      </div>
    </div>
  )

  const layoutSm = (
    <div className="grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] sm:gap-10">
      <div className="flex-1 overflow-hidden">
        <IconTitleGroup title="Optimisation" />
        <hr className="w-full mt-4 mb-6 border-gold-400" />
        {!hasAccessToExperiments && <UpgradeAccountComponent />}
        {hasAccessToExperiments && (
          <OptimizationMetrics accountId={accountId} />
        )}
        <hr className="w-full my-6 border-gold-400" />
        {!flags.switchToContentLibrary &&
          experiments.length > 0 &&
          hasAccessToExperiments && (
            <div className="mb-6 sm:mb-0">
              <RecentExperiments
                campaigns={experiments}
                status={statusExperiments}
              />
            </div>
          )}
      </div>
      <div className="flex-1 overflow-hidden">
        {!flags.switchToContentLibrary && (
          <>
            <div className="mb-6">
              <ContentKpi
                title={
                  <IconTitleGroup
                    title={
                      flags.showUnifiedFlow
                        ? 'Content library'
                        : 'Content generation'
                    }
                  />
                }
                kpiContent={kpiContent}
                isLoading={kpiContentStatus === 'loading'}
              />
            </div>
            {hasAccessToExperiments && (
              <>
                {(todos.length > 0 || statusToDos !== 'loading') && (
                  <div className="mb-6">
                    {todos.length > 0 && (
                      <Todo campaigns={todos} status={statusToDos} />
                    )}
                    {todos.length === 0 && statusToDos !== 'loading' && (
                      <TodoEmpty />
                    )}
                  </div>
                )}
              </>
            )}
          </>
        )}
        {recentContents && recentContents.length > 0 && (
          <RecentContents contents={recentContents} status={statusContents} />
        )}
      </div>
    </div>
  )

  return (
    <div className="overflow-y-auto">
      <HomepageBanner />
      <Content>
        <div className="max-w-screen-2xl mx-auto w-full relative">
          <PageHeader className="flex flex-wrap justify-between w-full mb-6">
            <QuickCards />
          </PageHeader>
          <Loader isLoading={isLoading}>
            {screenWidth >= 1200 && layoutMd}
            {screenWidth < 1200 && layoutSm}
            <Footer />
          </Loader>
        </div>
      </Content>
    </div>
  )
}

export default HomePage
