import { useQuery } from '@tanstack/react-query'

import { getSystemMergeTags, MergeTagListItem } from '../api'
import { mergeTagsKeys } from '../queryKeys'

const useGetSystemMergeTagsQuery = () => {
  const getSystemMergeTagsQuery = useQuery<MergeTagListItem[]>(
    mergeTagsKeys.systemList(),
    getSystemMergeTags
  )

  return getSystemMergeTagsQuery
}

export default useGetSystemMergeTagsQuery
