import { Field } from 'react-final-form'

import { SelectValue } from 'common/components/BaseSelect'
import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import Textarea from 'common/components/textarea'
import { MergeTagListItem } from 'features/admin/accountSettings/api'

const validate = (value: string) => {
  if (!value) {
    return 'Required'
  }
}

type Props = {
  selectedCategory: SelectValue
  systemMergeTags?: MergeTagListItem[]
}

const CategoryDescription = ({ systemMergeTags, selectedCategory }: Props) => {
  const description = systemMergeTags?.find(
    (tag) => tag.id === selectedCategory?.value
  )?.description

  const isCustom = selectedCategory?.value === 'custom'

  return (
    <>
      {isCustom && (
        <Field<string>
          name="merge_tag_custom_category_name"
          validate={validate}
          render={({ input, meta }) => (
            <FormItem
              label="Custom category name"
              htmlFor="merge_tag_custom_category_name"
              className="flex-none"
              error={meta.touched ? meta.error : undefined}
            >
              <Input
                type="text"
                id="merge_tag_custom_category_name"
                variant="default"
                className="text-coolGray-800"
                value={input.value}
                name={input.name}
                onChange={input.onChange}
                onFocus={input.onFocus}
                onBlur={input.onBlur}
              />
            </FormItem>
          )}
        />
      )}
      <Field<string>
        name="merge_tag_description"
        initialValue={description ?? ''}
        validate={validate}
      >
        {({ input, meta }) => (
          <FormItem
            htmlFor={input.name}
            className="flex-none"
            error={meta.touched ? meta.error : undefined}
          >
            <Textarea
              maxLength={250}
              rows={4}
              disabled={!isCustom}
              placeholder="Enter a clear instruction about what this merge tag does limited to 15 words or less."
              {...input}
            />
          </FormItem>
        )}
      </Field>
    </>
  )
}

export default CategoryDescription
