import { useState } from 'react'

import type { ImageDetailsProps } from 'common/components/images/imageDetails'
import ImageDetails from 'common/components/images/imageDetails'
import ImageErrorFallback from 'common/components/images/imageErrorFallback'
import type { SelectableImageButtonProps } from 'common/components/images/selectableImageButton'
import SelectableImageButton from 'common/components/images/selectableImageButton'

type Props = Omit<ImageDetailsProps, 'children'> &
  SelectableImageButtonProps & {
    onDisableClick?: () => void
  }

const ImageCard = ({
  fileName,
  updatedAt,
  fileSize,
  url,
  isSelected,
  isDisabled,
  onClick,
  onDisableClick,
}: Props) => {
  const [isValidImage, setIsValidImage] = useState(!!url)

  return (
    <ImageDetails
      fileName={fileName}
      updatedAt={updatedAt}
      fileSize={fileSize}
      className="w-50 h-full"
    >
      {isValidImage ? (
        <SelectableImageButton
          url={url}
          width={200}
          height={200}
          isSelected={isSelected}
          isDisabled={isDisabled}
          onClick={onClick}
          onDisableClick={onDisableClick}
          setIsValidImage={(value) => setIsValidImage(value)}
        />
      ) : (
        <ImageErrorFallback hasBorder width="w-50" height="h-50" hasIcon />
      )}
    </ImageDetails>
  )
}

export default ImageCard
