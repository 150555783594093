import { useCallback, useMemo, useState } from 'react'
import { components, GroupBase, OptionProps } from 'react-select'
import cx from 'classnames'

import useGetNodesQuery from 'common/api/queries/useGetNodesQuery'
import Autocomplete from 'common/components/autocomplete'
import HighlightText from 'common/components/HighlightText'
import { buildBreadcrumbs } from 'common/components/nodes/Breadcrumbs'
import useDebounce from 'common/hooks/useDebounce'
import { NodeFilterOption } from 'common/interfaces/nodes'

const Option = ({
  isSelected,
  children,
  data,
  ...rest
}: OptionProps<NodeFilterOption, false, GroupBase<NodeFilterOption>>) => {
  const { path, highlightedLabel, label } = data

  return (
    <components.Option isSelected={isSelected} data={data} {...rest}>
      <div
        data-cy={`nodes-search-option-${label}`}
        data-testid={`nodes-search-option-${label}`}
        className="flex flex-col gap-1 px-4"
        role="option"
        aria-selected={isSelected}
      >
        <div className="font-medium text-coolGray-800">{highlightedLabel}</div>
        <div className="font-normal text-coolGray-500">{path}</div>
      </div>
    </components.Option>
  )
}

const MINIMUM_SEARCH_LENGTH = 2

const canSearch = (searchValue: string) =>
  searchValue.length >= MINIMUM_SEARCH_LENGTH

const containsSearchValue = (text: string, searchValue?: string) => {
  if (!searchValue) {
    return false
  }

  return text
    .replaceAll(' ', '')
    .toLowerCase()
    .includes(searchValue.replaceAll(' ', '').toLowerCase())
}

type Props = {
  updateSelectedNodeId: (id: string | undefined) => void
}

const NodesFilter = ({ updateSelectedNodeId }: Props) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [options, setOptions] = useState<NodeFilterOption[]>([])

  const isSearchable = canSearch(searchValue)
  const { data } = useGetNodesQuery()

  const nodes = useMemo(() => data?.nodes || [], [data?.nodes])

  const getOptions = useCallback(() => {
    const newOptions: NodeFilterOption[] = []

    if (isSearchable) {
      nodes.forEach((node) => {
        if (!containsSearchValue(node.name, searchValue)) {
          return
        }
        const { breadcrumbsString } = buildBreadcrumbs(nodes, node.id)

        newOptions.push({
          label: node.name,
          highlightedLabel: (
            <HighlightText text={node.name} searchValue={searchValue} />
          ),
          value: node.id,
          path: breadcrumbsString
            .substring(0, breadcrumbsString.lastIndexOf('/'))
            .trim(),
        })
      })
    }

    newOptions.sort((a, b) => a.label.localeCompare(b.label))
    setOptions(newOptions)
  }, [isSearchable, nodes, searchValue])

  const debouncedGetOptions = useDebounce(getOptions, 500)

  const onSearch = (value: string) => {
    setSearchValue(value)
    debouncedGetOptions()
  }

  return (
    <Autocomplete
      data-cy="nodes-searchbox"
      data-testid="nodes-searchbox"
      className={cx('focus-within:w-106 transition-width', {
        'w-60': !searchValue,
        'w-106': searchValue,
      })}
      placeholder="Find a node"
      options={isSearchable && options ? options : []}
      value={
        searchValue ? { value: searchValue, label: searchValue } : undefined
      }
      defaultInputValue={searchValue}
      onSearch={onSearch}
      onChange={(option) => {
        if (option) {
          updateSelectedNodeId(option.value)
        } else {
          updateSelectedNodeId(undefined)
        }
      }}
      components={{ Option }}
      backspaceRemovesValue={true}
    />
  )
}

export default NodesFilter
