import RightPanel from '../../components/layout/RightPanel'

import IntegrationLogSection from './IntegrationLogSection'
import OptimizeIntegrationSection from './OptimizeIntegrationSection'

type Props = {
  showOptimizeView: boolean
}
const RightSection = ({ showOptimizeView }: Props) => {
  return showOptimizeView ? (
    <OptimizeIntegrationSection />
  ) : (
    <RightPanel className="h-full">
      <IntegrationLogSection />
    </RightPanel>
  )
}

export default RightSection
