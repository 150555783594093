/* eslint-disable max-lines */
import React from 'react'
import { connect } from 'react-redux'
import { Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import cx from 'classnames'
import { isEmpty } from 'lodash'
import type { Moment } from 'moment'
import moment from 'moment-timezone'
import { bindActionCreators } from 'redux'
import helpers from 'workflow/utils/helpers'

import Button from 'common/components/button/Button'
import DatePicker from 'common/components/datePicker'
import GuidingText from 'common/components/guidingText'
import Input from 'common/components/input'
import Label from 'common/components/label'
import Loader from 'common/components/loaders/Loader'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import TimePicker from 'common/components/timePicker'
import Tooltip from 'common/components/Tooltip'
import { stringToNumber } from 'common/helpers/numeric'
import { Time as TimeIcon } from 'common/icons'

import {
  AsyncThunkDispatch,
  cancelEpsilonCampaign,
  deleteVariantsFromEpsilon,
  fetchCampaignData,
  fetchEpsilonBusinessUnits,
  fetchEpsilonCampaigns,
  fetchEpsilonFolders,
  fetchEpsilonSeedList,
  fetchEpsilonTimeZone,
  pushVariantsToEpsilon,
  resetEpsilonState,
  scheduleEpsilonCampaign,
} from '../../../Workflow.actions'

import {
  hoursDropdownOptions,
  minutesDropdownOptions,
} from './delay-dropdown-options'
import {
  LockAudienceType,
  Props,
  RelativeTimeType,
  State,
  ValidationErrors,
} from './EpsilonIntegration.types'

import styles from '../Integrations.module.css'

const FormItem = Form.Item
const { isLockAudienceRelativeTime } = helpers

export const VALIDATION_ERRORS = {
  businessUnit: { required: 'This field is required' },
  folder: { required: 'This field is required' },
  campaign: { required: 'This field is required' },
  isVariantsPushed: { required: 'Variants have not been created' },
  startDate: {
    required: 'Experiment start date and time fields are required',
    notAvailable: 'This date is not available',
  },
  startTime: {
    required: 'Experiment start date and time fields are required',
    notAvailable: 'This time is not available',
  },
  endDate: {
    required: 'Experiment end date and time fields are required',
    notAvailable: 'This date is not available',
  },
  endTime: {
    required: 'Experiment end date and time fields are required',
    notAvailable: 'This time is not available',
  },
  delayTime: {
    required: 'Please set up winner sending delay time',
  },
  missingInfo:
    'You need to complete all the inputs to schedule your experiments.',
}

export const initialState: State = {
  selectedBusinessUnit: '',
  selectedFolder: '',
  selectedCampaign: 'Select an unscheduled campaign',
  selectedLockOption: LockAudienceType.SCHEDULE_TIME,
  selectedLockTimeOption: RelativeTimeType.HOURS,
  selectedLockTimeAmountOption: 4,
  selectedSeedListId: '',
  startDate: null,
  startTime: null,
  endDate: null,
  endTime: null,
  isVariantsPushed: false,
  isCampaignScheduled: false,
  isCampaignStarted: false,
  selectedDelayHours: 1,
  selectedDelayMinutes: 0,
  timeZone: '',
  errorMessages: {
    businessUnits: '',
    folders: '',
    campaigns: '',
    isVariantsPushed: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    delayTime: '',
    missingInfo: '',
  },
}

class EpsilonIntegration extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = initialState
  }

  componentDidMount(): void {
    const { businessUnits, actions, projectId, timeZone, campaignData } =
      this.props

    const integrationData = campaignData?.campaign_data?.integration_data

    // Fetch timezone if not exists
    if (!timeZone) {
      actions.fetchEpsilonTimeZone(projectId)
    }

    // Fetch business units if not exist
    if (!businessUnits?.length) {
      actions.fetchEpsilonBusinessUnits(projectId)
    }

    let receivedCampaignData = {}
    // Set initial business unit, folder and campaign received from props
    if (
      integrationData?.businessUnitId &&
      integrationData?.folderId &&
      integrationData?.campaignId
    ) {
      receivedCampaignData = {
        selectedBusinessUnit: integrationData?.businessUnitId,
        selectedFolder: integrationData?.folderId,
        selectedCampaign: integrationData?.campaignId,
      }
    }

    if (!isEmpty(integrationData?.absplitDeployment?.audienceScheduleParams)) {
      const { lockAudienceType, relativeTime, relativeTimeType } =
        integrationData.absplitDeployment.audienceScheduleParams
      receivedCampaignData = {
        ...receivedCampaignData,
        selectedLockOption: lockAudienceType as LockAudienceType,
        selectedLockTimeOption: relativeTimeType || RelativeTimeType.HOURS,
        selectedLockTimeAmountOption: relativeTime || 4,
      }
    }

    if (!isEmpty(receivedCampaignData)) {
      this.setState((prevState) => {
        return {
          ...prevState,
          ...receivedCampaignData,
        }
      })
    }
  }

  componentDidUpdate(): void {
    const {
      timeZone: propsTimeZone,
      campaignData,
      folders,
      campaigns,
      actions,
      projectId,
      seedList,
    } = this.props
    const {
      timeZone: stateTimeZone,
      startTime,
      startDate,
      endDate,
      endTime,
      isVariantsPushed,
      selectedSeedListId,
    } = this.state
    const integrationData = campaignData?.campaign_data?.integration_data
    // Put props timezone to state
    if (propsTimeZone && !stateTimeZone) {
      this.setState({ timeZone: propsTimeZone })
    }
    // Fetch folders and campaigns if ids exist in props
    if (
      integrationData?.businessUnitId &&
      integrationData?.folderId &&
      integrationData?.campaignId &&
      !folders?.length &&
      !campaigns?.length &&
      !isVariantsPushed
    ) {
      actions.fetchEpsilonFolders(projectId, integrationData.businessUnitId)
      actions.fetchEpsilonCampaigns(
        projectId,
        integrationData.businessUnitId,
        integrationData.folderId,
        integrationData.campaignId
      )
      if (!seedList?.length) {
        actions.fetchEpsilonSeedList(projectId, integrationData.businessUnitId)
      }
      this.setState({ isVariantsPushed: true })
    }
    // Set initial seed list
    if (
      seedList?.length &&
      integrationData?.seedListId &&
      !selectedSeedListId
    ) {
      this.setState({ selectedSeedListId: integrationData.seedListId })
    }

    // Set initial scheduled campaign data if values exist in props
    if (
      integrationData?.absplitDeployment?.waitInSeconds &&
      integrationData?.absplitDeployment?.absplitDeploymentDate &&
      integrationData?.campaignEndTime &&
      integrationData?.absplitSubList &&
      !startTime &&
      !startDate &&
      !endDate &&
      !endTime &&
      propsTimeZone
    ) {
      const initialStartDate = moment
        .utc(integrationData.absplitDeployment.absplitDeploymentDate)
        .tz(propsTimeZone)
      const initialEndDate = moment
        .utc(integrationData.campaignEndTime)
        .tz(propsTimeZone)

      const minutesDiff = Math.floor(
        (integrationData.absplitDeployment.waitInSeconds % 3600) / 60
      )
      const hoursDiff = Math.floor(
        integrationData.absplitDeployment.waitInSeconds / 3600
      )
      this.setState({
        startDate: initialStartDate,
        startTime: initialStartDate,
        endDate: initialEndDate,
        endTime: initialEndDate,
        selectedDelayMinutes: minutesDiff,
        selectedDelayHours: hoursDiff,
        isCampaignScheduled: true,
        isVariantsPushed: true,
        isCampaignStarted:
          moment(initialStartDate).tz(propsTimeZone).format() <
          moment().tz(propsTimeZone).format(),
      })
    }
    // Set default initial values
    if (
      !integrationData?.absplitDeployment?.waitInSeconds &&
      !integrationData?.absplitDeployment?.absplitDeploymentDate &&
      !integrationData?.absplitDeployment?.campaignEndTime &&
      !integrationData?.absplitDeployment?.absplitSubList &&
      !startTime &&
      !startDate &&
      !endDate &&
      !endTime &&
      stateTimeZone
    ) {
      // Round minutes by 15min step
      const remainder = 15 - (moment().minute() % 15)
      this.setState({
        startDate: moment()
          .add(1, 'hours')
          .add(remainder, 'minutes')
          .tz(stateTimeZone),
        startTime: moment()
          .add(1, 'hours')
          .add(remainder, 'minutes')
          .tz(stateTimeZone),
        endDate: moment()
          .add(4, 'days')
          .add(remainder, 'minutes')
          .tz(stateTimeZone),
        endTime: moment()
          .add(4, 'days')
          .add(remainder, 'minutes')
          .tz(stateTimeZone),
      })
    }
  }

  componentWillUnmount(): void {
    this.setState(initialState)
    this.props.actions.resetEpsilonState()
  }

  onBusinessUnitChange = (val: string): void => {
    const { actions, projectId } = this.props
    if (projectId) {
      if (val) {
        actions.fetchEpsilonFolders(projectId, val)
        actions.fetchEpsilonSeedList(projectId, val)
      }

      this.setState({
        selectedBusinessUnit: val,
        selectedFolder: '',
        selectedCampaign: '',
      })
    }
  }

  onFolderChange = (val: string): void => {
    const { actions, projectId } = this.props
    const { selectedBusinessUnit } = this.state
    actions.fetchEpsilonCampaigns(projectId, selectedBusinessUnit, val, '')
    this.setState({
      selectedFolder: val,
      selectedCampaign: '',
    })
  }

  onCampaignChange = (val: string): void => {
    this.setState({ selectedCampaign: val })
  }

  onLockAudienceChange = (val: LockAudienceType): void => {
    this.setState({ selectedLockOption: val })
  }

  onSeedListChange = (val: string): void => {
    this.setState({ selectedSeedListId: val })
  }

  onLockTimeAmountChange = (e: any): void | boolean => {
    const { value } = e.target
    const newAmount = parseInt(value)
    if (newAmount > 100) {
      return false
    }

    this.setState({ selectedLockTimeAmountOption: newAmount })
  }

  onLockAudienceTimeChange = (val: RelativeTimeType): void => {
    this.setState({ selectedLockTimeOption: val })
  }

  createSplits = (): void => {
    const { projectId, campaignId, actions } = this.props
    const { selectedCampaign, selectedBusinessUnit, selectedFolder } =
      this.state
    const payload = {
      epsilon_campaign_id: selectedCampaign,
      folder_id: selectedFolder,
      business_unit_id: selectedBusinessUnit,
    }
    actions.pushVariantsToEpsilon(projectId, campaignId, payload, () =>
      this.setState({ isVariantsPushed: true })
    )
  }

  deleteVariant = (): void => {
    const { projectId, campaignId, actions } = this.props
    const { selectedBusinessUnit } = this.state
    const payload = { business_unit_id: selectedBusinessUnit }
    actions.deleteVariantsFromEpsilon(projectId, campaignId, payload, () =>
      this.setState(initialState)
    )
  }

  getDisabledHoursArray = (
    dateFormatted: string,
    timeFormatted: string,
    compareEndDate?: boolean
  ): Array<number> => {
    const { selectedDelayHours, selectedDelayMinutes } = this.state
    const extraTime = !compareEndDate
      ? 3600
      : selectedDelayHours * 3600 + selectedDelayMinutes * 60
    const hourIncludingExtraTime = moment(`${dateFormatted} ${timeFormatted}`)
      .add(extraTime, 'seconds')
      .hour()
    // if selected date is in the future, do not disable hours.
    return Array.from(Array(hourIncludingExtraTime).keys())
  }

  // eslint-disable-next-line max-params
  getDisabledMinutesArray = (
    selectedHour: any,
    dateFormatted: string,
    timeFormatted: string,
    compareEndDate?: boolean
    // eslint-disable-next-line max-params
  ): Array<number> => {
    const { selectedDelayHours, selectedDelayMinutes } = this.state
    const extraTime = !compareEndDate
      ? 3600
      : selectedDelayHours * 3600 + selectedDelayMinutes * 60
    const minutesIncludingExtraTime = moment(
      `${dateFormatted} ${timeFormatted}`
    )
      .add(extraTime, 'seconds')
      .minutes()
    const nowHourIncludingTimeZoneAndExtraTime = moment(
      `${dateFormatted} ${timeFormatted}`
    )
      .add(extraTime, 'seconds')
      .hour()
    // When user chooses a future hour, we need to figure out
    // disabled minutes for the new hour:
    if (
      selectedHour &&
      selectedHour - nowHourIncludingTimeZoneAndExtraTime >= 1
    ) {
      return []
    }
    // If NOT, figure out the UI.
    return Array.from(Array(minutesIncludingExtraTime).keys())
  }

  disableDate = (current: any, compareStartDate = false): boolean => {
    const { timeZone, startDate } = this.state
    if (timeZone) {
      let dateToCompare = moment().tz(timeZone).format('YYYY-MM-DD')
      if (compareStartDate) {
        dateToCompare = moment(startDate).tz(timeZone).format('YYYY-MM-DD')
      }
      return (
        current &&
        moment(current).tz(timeZone).format('YYYY-MM-DD') < dateToCompare
      )
    }
    return false
  }

  disableStartHours = (): Array<number> => {
    const { timeZone, startDate } = this.state
    if (
      startDate &&
      timeZone &&
      !moment(startDate).isSame(moment().tz(timeZone), 'day') &&
      moment(startDate).format() > moment().tz(timeZone).format()
    ) {
      return []
    }
    if (timeZone && moment(startDate).isSame(moment().tz(timeZone), 'day')) {
      const dateFormatted = moment().tz(timeZone).format('Y-MM-DD')
      const timeFormatted = moment().tz(timeZone).format('HH:mm')
      return this.getDisabledHoursArray(dateFormatted, timeFormatted)
    }
    return Array.from(Array(moment().tz(timeZone).daysInMonth() + 1).keys())
  }

  disableStartMinutes = (selectedHour: any): Array<number> => {
    const { timeZone, startDate } = this.state
    // If selected date is in future do not disable anything and return.
    if (startDate && moment(startDate) > moment().tz(timeZone)) {
      return []
    }
    if (timeZone) {
      const dateFormatted = moment().tz(timeZone).format('Y-MM-DD')
      const timeFormatted = moment().tz(timeZone).format('HH:mm')
      return this.getDisabledMinutesArray(
        selectedHour,
        dateFormatted,
        timeFormatted
      )
    }
    return []
  }

  disableEndHours = (): Array<number> => {
    const { timeZone, startTime, endDate, startDate } = this.state
    const dateFormatted = startTime.format('Y-MM-DD')
    const timeFormatted = startTime.format('HH:mm')
    if (startDate.isSame(endDate, 'day') && timeZone) {
      return this.getDisabledHoursArray(dateFormatted, timeFormatted, true)
    }
    return []
  }

  disableEndMinutes = (selectedHour: any): Array<number> => {
    const { timeZone, startDate, startTime, endDate } = this.state
    const dateFormatted = startDate.format('Y-MM-DD')
    const timeFormatted = startTime.format('HH:mm')
    // If selected date is in future do not disable anything and return.
    if (startDate.isSame(endDate, 'day') && timeZone) {
      return this.getDisabledMinutesArray(
        selectedHour,
        dateFormatted,
        timeFormatted,
        true
      )
    }
    return []
  }

  onDateTimeChange = (val: any, type: string): void => {
    const { startTime, startDate, endTime, endDate } = this.state
    switch (type) {
      case 'startDate':
        this.setState({
          endDate: moment(val).add(4, 'days'),
          startDate: moment(val),
          startTime: startTime
            ? moment(val).set({
                hours: startTime.hours(),
                minutes: startTime.minutes(),
              })
            : null,
        })
        break
      case 'startTime':
        this.setState({
          startDate: startDate
            ? startDate.set({
                hours: val.hours(),
                minutes: val.minutes(),
              })
            : null,
          startTime: moment(val),
        })
        break
      case 'endDate':
        this.setState({
          endDate: moment(val),
          endTime: endTime
            ? moment(val).set({
                day: endTime.day(),
                month: endTime.month(),
                year: endTime.year(),
              })
            : null,
        })
        break
      case 'endTime':
        this.setState({
          endDate: endDate
            ? moment(endDate).set({
                hour: val.hour(),
                minute: val.minute(),
              })
            : null,
          endTime: moment(val),
        })
        break
      default:
        break
    }
  }

  validateInputsOnSubmit = (): boolean => {
    const {
      timeZone,
      selectedCampaign,
      selectedBusinessUnit,
      selectedFolder,
      startDate,
      startTime,
      selectedDelayHours,
      selectedDelayMinutes,
      endDate,
      endTime,
      isVariantsPushed,
    } = this.state
    const validationErrorMessages: ValidationErrors = {
      businessUnits: '',
      folders: '',
      campaigns: '',
      isVariantsPushed: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      delayTime: '',
      missingInfo: '',
    }
    let isFormValid = true

    // Validate that all required values exist
    if (!selectedBusinessUnit) {
      isFormValid = false
      validationErrorMessages.businessUnits =
        VALIDATION_ERRORS.businessUnit.required
      validationErrorMessages.missingInfo = VALIDATION_ERRORS.missingInfo
    }
    if (!selectedFolder) {
      isFormValid = false
      validationErrorMessages.folders = VALIDATION_ERRORS.folder.required
      validationErrorMessages.missingInfo = VALIDATION_ERRORS.missingInfo
    }
    if (!selectedCampaign) {
      isFormValid = false
      validationErrorMessages.campaigns = VALIDATION_ERRORS.campaign.required
      validationErrorMessages.missingInfo = VALIDATION_ERRORS.missingInfo
    }
    if (!startDate || !startTime) {
      isFormValid = false
      validationErrorMessages.startDate = VALIDATION_ERRORS.startDate.required
      validationErrorMessages.missingInfo = VALIDATION_ERRORS.missingInfo
    }
    if (
      !selectedDelayHours ||
      (!selectedDelayMinutes && selectedDelayMinutes !== 0)
    ) {
      isFormValid = false
      validationErrorMessages.delayTime = VALIDATION_ERRORS.delayTime.required
      validationErrorMessages.missingInfo = VALIDATION_ERRORS.missingInfo
    }
    if (!endDate || !endTime) {
      isFormValid = false
      validationErrorMessages.endDate = VALIDATION_ERRORS.endDate.required
      validationErrorMessages.missingInfo = VALIDATION_ERRORS.missingInfo
    }
    if (!isVariantsPushed) {
      isFormValid = false
      validationErrorMessages.isVariantsPushed =
        VALIDATION_ERRORS.isVariantsPushed.required
      validationErrorMessages.missingInfo = VALIDATION_ERRORS.missingInfo
    }

    // If values exist validate that data is correct
    if (
      selectedBusinessUnit &&
      selectedFolder &&
      selectedCampaign &&
      startDate &&
      startTime &&
      selectedDelayHours &&
      (selectedDelayMinutes || selectedDelayMinutes === 0) &&
      endDate &&
      endTime &&
      isVariantsPushed &&
      timeZone
    ) {
      const campaignStartDateTime = moment(startDate).set({
        hours: startTime.hours(),
        minutes: startTime.minutes(),
      })
      const campaignEndDateTime = moment(endDate).set({
        hours: endTime.hours(),
        minutes: endTime.minutes(),
      })
      const delaySeconds = selectedDelayHours * 3600 + selectedDelayMinutes * 60
      const campaignTestEndDateTime = campaignStartDateTime.add(
        delaySeconds,
        'seconds'
      )

      if (
        campaignEndDateTime.format('YYYY-MM-DD HH:mm') <
        campaignTestEndDateTime.format('YYYY-MM-DD HH:mm')
      ) {
        isFormValid = false
        validationErrorMessages.endTime = VALIDATION_ERRORS.endTime.notAvailable
      }
      if (
        campaignStartDateTime.format('YYYY-MM-DD') <
        moment().tz(timeZone).format('YYYY-MM-DD')
      ) {
        isFormValid = false
        validationErrorMessages.startDate =
          VALIDATION_ERRORS.startDate.notAvailable
      }
      if (
        campaignStartDateTime.isSame(moment().tz(timeZone), 'day') &&
        campaignStartDateTime.format('YYYY-MM-DD HH:mm') <
          moment().tz(timeZone).format('YYYY-MM-DD HH:mm')
      ) {
        isFormValid = false
        validationErrorMessages.startTime =
          VALIDATION_ERRORS.startTime.notAvailable
      }
    }
    this.setState({ errorMessages: validationErrorMessages })
    return isFormValid
  }

  onDelayTimeChange = (val: number, type: string): void => {
    switch (type) {
      case 'hours':
        this.setState({ selectedDelayHours: val })
        break
      case 'minutes':
        this.setState({ selectedDelayMinutes: val })
        break
      default:
        break
    }
  }

  handleScheduleCampaign = (): void => {
    const { actions, projectId, campaignId, updateCampaignData, onSuccess } =
      this.props
    const {
      selectedCampaign,
      selectedBusinessUnit,
      selectedSeedListId,
      startDate,
      startTime,
      endDate,
      endTime,
      selectedDelayHours,
      selectedDelayMinutes,
      selectedLockOption,
      selectedLockTimeOption,
      selectedLockTimeAmountOption,
    } = this.state

    if (this.validateInputsOnSubmit()) {
      const campaignStartDateTime = moment(startDate)
        .set({
          hours: startTime.hours(),
          minutes: startTime.minutes(),
        })
        .utc()
      const campaignEndDateTime = moment(endDate)
        .set({
          hours: endTime.hours(),
          minutes: endTime.minutes(),
        })
        .utc()

      const delaySeconds = selectedDelayHours * 3600 + selectedDelayMinutes * 60
      const payload = {
        epsilon_campaign_id: selectedCampaign,
        business_unit_id: selectedBusinessUnit,
        epsilon_seed_id: selectedSeedListId,
        optimization_wait_in_seconds: delaySeconds,
        campaign_start_time: campaignStartDateTime.format(),
        campaign_end_time: campaignEndDateTime.format(),
        lock_audience: {
          lockAudienceType: selectedLockOption,
          relativeTimeType: isLockAudienceRelativeTime(selectedLockOption)
            ? selectedLockTimeOption
            : null,
          relativeTime: isLockAudienceRelativeTime(selectedLockOption)
            ? selectedLockTimeAmountOption
            : null,
          scheduledLockDate: null, // Not used
        },
      }

      actions.scheduleEpsilonCampaign(projectId, campaignId, payload, () =>
        updateCampaignData(campaignId, () => {
          this.setState({
            isCampaignScheduled: true,
            isVariantsPushed: true,
          })
          onSuccess && onSuccess()
        })
      )
    }
  }

  returnFiltered = (input: string, option: any) => {
    return (
      option.props.children.props.children
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    )
  }

  handleCancelCampaign = (): void => {
    const { actions, projectId, campaignId, updateCampaignData, onCancel } =
      this.props
    const { selectedBusinessUnit } = this.state
    const payload = { business_unit_id: selectedBusinessUnit }
    actions.cancelEpsilonCampaign(projectId, campaignId, payload, () => {
      updateCampaignData(campaignId, () =>
        this.setState({
          isCampaignScheduled: false,
        })
      )
      onCancel && onCancel()
    })
  }

  render() {
    const { isLoading, businessUnits, folders, campaigns, seedList } =
      this.props
    const {
      timeZone,
      errorMessages,
      selectedBusinessUnit,
      selectedFolder,
      selectedCampaign,
      isVariantsPushed,
      selectedDelayMinutes,
      startDate,
      startTime,
      selectedDelayHours,
      endDate,
      endTime,
      isCampaignStarted,
      isCampaignScheduled,
      selectedLockOption,
      selectedLockTimeOption,
      selectedLockTimeAmountOption,
      selectedSeedListId,
    } = this.state

    const isSetTestVariantsDisabled =
      isCampaignScheduled || isVariantsPushed || isCampaignStarted

    const isSetScheduleDetailsDisabled =
      !isVariantsPushed ||
      !selectedBusinessUnit ||
      !selectedFolder ||
      !selectedCampaign ||
      !timeZone ||
      isCampaignScheduled ||
      isCampaignStarted

    const businessUnitOptions: SelectValue[] =
      businessUnits?.map((option) => ({
        label: option.business_unit_name,
        value: option.business_unit_id,
      })) ?? []

    const folderOptions: SelectValue[] =
      folders?.map((folder) => ({
        label: folder.epsilon_folder_name,
        value: folder.epsilon_folder_id,
      })) ?? []

    const campaignOptions: SelectValue[] =
      campaigns?.map((campaign) => ({
        label: campaign.epsilon_campaign_name,
        value: campaign.epsilon_campaign_id,
      })) ?? []

    const seedListOptions: SelectValue[] =
      seedList?.map((list) => ({
        label: list.epsilon_seed_name,
        value: list.epsilon_seed_id,
      })) ?? []

    return (
      <>
        <Drawer.Content>
          <Form>
            <Loader isLoading={!timeZone || isLoading}>
              <p className="mb-8 text-base text-coolGray-500 font-medium">
                Schedule your Jacquard experiment with your Epsilon account.
              </p>

              <FormItem
                label="Epsilon business unit"
                help={errorMessages.businessUnits || null}
                className={
                  errorMessages.businessUnits ? 'error required' : 'required'
                }
              >
                <SingleSelect
                  isClearable
                  data-testid="selectBusinessUnit"
                  placeholder="Business unit"
                  className="required w-88"
                  isSearchable
                  onChange={(val) =>
                    this.onBusinessUnitChange(val?.value || '')
                  }
                  value={
                    selectedBusinessUnit && businessUnits?.length
                      ? selectedBusinessUnit
                      : 'Select'
                  }
                  isOptionDisabled={(option) => option.value === 'Select'}
                  isDisabled={
                    isSetTestVariantsDisabled ||
                    !businessUnits ||
                    businessUnits?.length === 0
                  }
                  options={[
                    {
                      label: 'Select',
                      value: 'Select',
                    },
                    ...businessUnitOptions,
                  ]}
                />
              </FormItem>

              <FormItem
                label="Epsilon folder"
                help={errorMessages.folders || null}
                className={
                  errorMessages.folders ? 'error required' : 'required'
                }
              >
                <SingleSelect
                  isClearable
                  data-testid="selectFolder"
                  placeholder="Folder"
                  className="required w-88"
                  isSearchable
                  onChange={(val) => this.onFolderChange(val?.value || '')}
                  value={
                    selectedFolder && folders?.length
                      ? selectedFolder
                      : 'Select'
                  }
                  isOptionDisabled={(option) => option.value === 'Select'}
                  isDisabled={
                    isSetTestVariantsDisabled ||
                    !selectedBusinessUnit ||
                    folders?.length === 0
                  }
                  options={[
                    {
                      label: 'Select',
                      value: 'Select',
                    },
                    ...folderOptions,
                  ]}
                  menuPortalTarget={document.body}
                  menuShouldBlockScroll={true}
                />
              </FormItem>

              <FormItem
                label="Epsilon campaign"
                help={errorMessages.campaigns || null}
                className={
                  errorMessages.campaigns ? 'error required' : 'required'
                }
              >
                <SingleSelect
                  isClearable
                  data-testid="selectCampaign"
                  placeholder="Select an unscheduled campaign"
                  className="required w-88"
                  isSearchable
                  menuPortalTarget={document.body}
                  menuShouldBlockScroll={true}
                  onChange={(val) => this.onCampaignChange(val?.value || '')}
                  value={
                    selectedCampaign && campaigns?.length
                      ? selectedCampaign
                      : 'Select an unscheduled campaign'
                  }
                  isOptionDisabled={(option) => option.value === 'Select'}
                  isDisabled={
                    isSetTestVariantsDisabled ||
                    !selectedBusinessUnit ||
                    !selectedFolder ||
                    campaigns?.length === 0
                  }
                  options={[
                    {
                      label: 'Select an unscheduled campaign',
                      value: 'Select',
                    },
                    ...campaignOptions,
                  ]}
                />
                <GuidingText
                  text={`Select a draft campaign that has all required fields
                completed, including the Properties, Audience, and Content`}
                />
              </FormItem>

              <FormItem>
                {!isVariantsPushed &&
                !isCampaignScheduled &&
                !isCampaignStarted ? (
                  <Tooltip
                    overlay={`This will create the split test variants and 
                    populate the subject lines for testing purposes 
                    before scheduling`}
                    trigger="hover"
                    placement="right"
                    overlayStyle={{ maxWidth: 226 }}
                  >
                    <Button
                      variant="primary"
                      disabled={
                        isSetTestVariantsDisabled ||
                        !selectedBusinessUnit ||
                        !selectedFolder ||
                        !selectedCampaign
                      }
                      loading={false}
                      onClick={() => this.createSplits()}
                      data-testid="createSplitsBtn"
                      data-cy="epsilon-create-splits-button"
                    >
                      Create Test Variants
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip
                    overlay={`This will remove the split test variants 
                    from Epsilon`}
                    trigger="hover"
                    placement="right"
                    overlayStyle={{ maxWidth: 226 }}
                  >
                    <Button
                      variant="primary"
                      disabled={isCampaignScheduled || isCampaignStarted}
                      loading={false}
                      onClick={() => this.deleteVariant()}
                      data-testid="deleteVariantsBtn"
                      data-cy="epsilon-delete-variants-button"
                    >
                      Delete Test Variants
                    </Button>
                  </Tooltip>
                )}
              </FormItem>

              <div className="mb-8">
                <p
                  data-testid="timeZone"
                  className="mb-1 text-coolGray-700 font-medium"
                >
                  Account Timezone - {timeZone}
                </p>
                <GuidingText
                  text={`Input your experiment time details below based on your
              Epsilon Account timezone.`}
                />
              </div>

              <FormItem
                label="When do you want to send the split test emails?"
                className={
                  errorMessages.startDate || errorMessages.startTime
                    ? 'error required'
                    : 'required'
                }
                help={
                  errorMessages.startDate || errorMessages.startTime || null
                }
              >
                <div className="flex justify-between">
                  <DatePicker
                    id="date-picker"
                    numberOfMonths={1}
                    verticalHeight={372}
                    isTodayVisible={true}
                    disabled={isSetScheduleDetailsDisabled}
                    isOutsideRange={(current: Moment | null): boolean =>
                      this.disableDate(current, false)
                    }
                    ariaLabel="startDatePicker"
                    data-testid="startDatePicker"
                    date={startDate}
                    showClearDate={false}
                    onDateChange={(date: Moment | null) => {
                      if (date) {
                        this.onDateTimeChange(date, 'startDate')
                      }
                    }}
                  />

                  <TimePicker
                    disabled={isSetScheduleDetailsDisabled || !startDate}
                    disabledHours={() => this.disableStartHours()}
                    allowClear={false}
                    disabledMinutes={(selectedHour) =>
                      this.disableStartMinutes(selectedHour)
                    }
                    data-testid="startTimePicker"
                    className={styles.integrationTimePicker}
                    format="HH:mm"
                    style={{ width: '100%' }}
                    minuteStep={15}
                    value={startTime}
                    onChange={(val: Moment | null) =>
                      this.onDateTimeChange(val, 'startTime')
                    }
                    suffixIcon={<TimeIcon size={6} />}
                  />
                </div>
                <GuidingText text="Select the date you want to start the experiment" />
              </FormItem>

              <FormItem
                label="How long do you want to wait before sending the winner?"
                className={
                  errorMessages.delayTime ? 'error required' : 'required'
                }
                help={errorMessages.delayTime || null}
              >
                <div className="flex">
                  <SingleSelect
                    data-testid="selectDelayHours"
                    placeholder="Select wait hours"
                    className="required mr-4 flex-1"
                    onChange={(val) => {
                      const value = stringToNumber(val?.value)
                      value && this.onDelayTimeChange(value, 'hours')
                    }}
                    value={`${selectedDelayHours}`}
                    isDisabled={
                      isSetScheduleDetailsDisabled || !startDate || !startTime
                    }
                    options={hoursDropdownOptions.map((option) => ({
                      label: option.label,
                      value: `${option.value}`,
                    }))}
                  />
                  <SingleSelect
                    data-testid="selectDelayMinutes"
                    className="required flex-1"
                    onChange={(val) => {
                      const value = stringToNumber(val?.value)
                      value && this.onDelayTimeChange(value, 'minutes')
                    }}
                    value={`${selectedDelayMinutes}`}
                    isDisabled={
                      isSetScheduleDetailsDisabled || !startDate || !startTime
                    }
                    options={minutesDropdownOptions.map((option) => ({
                      label: option.label,
                      value: `${option.value}`,
                    }))}
                  />
                </div>
              </FormItem>

              <FormItem
                label="Experiment end date / time"
                className={
                  errorMessages.endDate || errorMessages.endTime
                    ? 'error required'
                    : 'required'
                }
                help={errorMessages.endDate || errorMessages.endTime || null}
              >
                <div className="flex justify-between">
                  <DatePicker
                    id="date-picker"
                    numberOfMonths={1}
                    verticalHeight={372}
                    isTodayVisible={true}
                    disabled={
                      isSetScheduleDetailsDisabled || !startDate || !startTime
                    }
                    isOutsideRange={(current: Moment | null): boolean =>
                      this.disableDate(current, true)
                    }
                    ariaLabel="endDatePicker"
                    data-testid="endDatePicker"
                    date={endDate}
                    showClearDate={false}
                    onDateChange={(date: Moment | null) => {
                      if (date) {
                        this.onDateTimeChange(date, 'endDate')
                      }
                    }}
                  />
                  <TimePicker
                    disabled={
                      isSetScheduleDetailsDisabled ||
                      !startDate ||
                      !startTime ||
                      !endDate
                    }
                    disabledHours={() => this.disableEndHours()}
                    allowClear={false}
                    disabledMinutes={(selectedHour) =>
                      this.disableEndMinutes(selectedHour)
                    }
                    data-testid="endTimePicker"
                    className={styles.integrationTimePicker}
                    style={{ width: '100%' }}
                    format="HH:mm"
                    minuteStep={15}
                    value={endTime}
                    onChange={(val: Moment | null) =>
                      this.onDateTimeChange(val, 'endTime')
                    }
                    suffixIcon={<TimeIcon size={6} />}
                  />
                </div>
                <GuidingText
                  text={`Select the date you want Jacquard to pull the results
                  of the Final Send`}
                />
              </FormItem>

              <FormItem label="Lock Audience">
                <SingleSelect
                  data-testid="epsilonSelectedLockOption"
                  onChange={(val) =>
                    val?.value &&
                    this.onLockAudienceChange(val.value as LockAudienceType)
                  }
                  value={selectedLockOption}
                  isDisabled={
                    isSetScheduleDetailsDisabled ||
                    !startDate ||
                    !startTime ||
                    !endDate ||
                    !endTime
                  }
                  options={[
                    {
                      label: 'Immediately upon scheduling',
                      value: LockAudienceType.SCHEDULE_TIME,
                    },
                    {
                      label: 'Specific time before deployment',
                      value: LockAudienceType.RELATIVE_TIME,
                    },
                    {
                      label: 'At the time of deployment',
                      value: LockAudienceType.DEPLOYMENT_TIME,
                    },
                  ]}
                />

                <GuidingText
                  text={`Select the timing for which the audience
              of your send will be locked within Epsilon`}
                />
              </FormItem>

              {isLockAudienceRelativeTime(selectedLockOption) && (
                <>
                  <FormItem label="Time before deployment" className="mb-0">
                    <div className="flex">
                      <FormItem className="required mb-0 w-24">
                        <Input
                          data-testid="epsilonSelectedLockTimeAmountOption"
                          onChange={this.onLockTimeAmountChange}
                          type="number"
                          min={0}
                          max={12}
                          variant="default"
                          value={String(selectedLockTimeAmountOption)}
                          disabled={
                            isSetScheduleDetailsDisabled ||
                            !startDate ||
                            !startTime ||
                            !endDate ||
                            !endTime
                          }
                          className={cx(
                            'required text-base',
                            styles.integrationDatePicker
                          )}
                        />
                      </FormItem>

                      <FormItem
                        help={errorMessages.campaigns || null}
                        className={cx(
                          'ml-4 mb-0',
                          {
                            'error required mb-2': errorMessages.campaigns,
                            required: !errorMessages.campaigns,
                          },
                          styles.integrationTimePicker
                        )}
                      >
                        <SingleSelect
                          data-testid="epsilonSelectedLockTimeOption"
                          onChange={(val) =>
                            val?.value &&
                            this.onLockAudienceTimeChange(
                              val.value as RelativeTimeType
                            )
                          }
                          value={selectedLockTimeOption}
                          isDisabled={
                            isSetScheduleDetailsDisabled ||
                            !startDate ||
                            !startTime ||
                            !endDate ||
                            !endTime
                          }
                          options={[
                            {
                              label: 'Hours',
                              value: RelativeTimeType.HOURS,
                            },
                            {
                              label: 'Days',
                              value: RelativeTimeType.DAYS,
                            },
                          ]}
                        />
                      </FormItem>
                    </div>

                    <i className="block mb-6">
                      {moment(startDate)
                        .set({
                          hours: startTime?.hours(),
                          minutes: startTime?.minutes(),
                        })
                        .subtract(
                          selectedLockTimeOption === RelativeTimeType.DAYS
                            ? selectedLockTimeAmountOption * 24
                            : selectedLockTimeAmountOption,
                          'hours'
                        )
                        .format('DD/MM/YYYY, hh:mm A')}
                    </i>
                  </FormItem>
                </>
              )}

              <FormItem>
                <Label
                  className="mb-2"
                  optional={true}
                  children="Add Seed List"
                />
                <SingleSelect
                  isClearable
                  isSearchable
                  data-testid="selectSeedList"
                  placeholder="Search Seed Lists"
                  className="required w-88"
                  onChange={(val) =>
                    val?.value && this.onSeedListChange(val.value)
                  }
                  value={selectedSeedListId || ''}
                  isOptionDisabled={(option) => option.value === 'default'}
                  isDisabled={
                    isSetScheduleDetailsDisabled ||
                    !startDate ||
                    !startTime ||
                    !endDate ||
                    !endTime ||
                    !selectedLockOption
                  }
                  options={[
                    { label: 'Search seed lists', value: 'default' },
                    ...seedListOptions,
                  ]}
                  menuPortalTarget={document.body}
                  menuShouldBlockScroll={true}
                />
              </FormItem>

              {errorMessages.missingInfo && (
                <FormItem
                  className={
                    errorMessages.missingInfo || errorMessages.missingInfo
                      ? 'error required'
                      : 'required'
                  }
                  help={errorMessages.missingInfo}
                  data-testid="missingInfoError"
                />
              )}

              <div
                className={`mb-6 p-4 border border-yellow-400 text-amber-700
              bg-amber-50 font-medium`}
              >
                Make sure your campaign is in an Approved state in Epsilon. Only
                approved campaigns in Epsilon can be scheduled.
              </div>
            </Loader>
          </Form>
        </Drawer.Content>

        <Drawer.Footer>
          <Tooltip
            overlay="This will cancel your Epsilon campaign deployment"
            trigger="hover"
            placement="right"
            overlayStyle={{ maxWidth: 226 }}
          >
            <>
              {isVariantsPushed}
              <Button
                className="mr-4"
                variant="primary"
                disabled={
                  !isVariantsPushed ||
                  !isCampaignScheduled ||
                  isCampaignStarted ||
                  isLoading
                }
                onClick={() => this.handleCancelCampaign()}
                data-testid="cancelCampaign"
                data-cy="epsilon-cancel-campaign-button"
              >
                Cancel
              </Button>
            </>
          </Tooltip>
          <Tooltip
            overlay="This will schedule your Epsilon campaign"
            trigger="hover"
            placement="left"
            overlayStyle={{ maxWidth: 226 }}
          >
            <Button
              variant="primary"
              disabled={
                isSetScheduleDetailsDisabled ||
                !startDate ||
                !startTime ||
                !endDate ||
                !endTime ||
                isLoading
              }
              onClick={() => this.handleScheduleCampaign()}
              data-testid="scheduleCampaign"
              data-cy="epsilon-schedule-campaign-button"
            >
              Schedule experiment
            </Button>
          </Tooltip>
        </Drawer.Footer>
      </>
    )
  }
}

export function mapDispatchToProps(dispatch: AsyncThunkDispatch) {
  return {
    updateCampaignData: async (campaignId: string, callback: any) => {
      await fetchCampaignData(campaignId)
      callback()
    },
    actions: bindActionCreators(
      {
        fetchEpsilonBusinessUnits,
        fetchEpsilonSeedList,
        fetchEpsilonFolders,
        fetchEpsilonCampaigns,
        pushVariantsToEpsilon,
        fetchEpsilonTimeZone,
        cancelEpsilonCampaign,
        scheduleEpsilonCampaign,
        deleteVariantsFromEpsilon,
        resetEpsilonState,
      },
      dispatch
    ),
  }
}

export function mapStateToProps(state: any, ownsProps: any) {
  const {
    epsilonCampaigns,
    epsilonBusinessUnits,
    epsilonSeedList,
    epsilonFolders,
    currentCampaignId,
    projectId,
    campaignData,
    epsilonTimeZone,
    isWaitingState,
  } = state.campaignStates
  const isLoading: boolean =
    (isWaitingState.isWaitingFor === 'deleteVariantsFromEpsilon' ||
      isWaitingState.isWaitingFor === 'fetchingEpsilonBusinessUnits' ||
      isWaitingState.isWaitingFor === 'fetchingEpsilonFolders' ||
      isWaitingState.isWaitingFor === 'fetchingEpsilonCampaigns' ||
      isWaitingState.isWaitingFor === 'pushVariantsToEpsilon' ||
      isWaitingState.isWaitingFor === 'fetchEpsilonTimeZone' ||
      isWaitingState.isWaitingFor === 'scheduleEpsilonCampaign' ||
      isWaitingState.isWaitingFor === 'fetchingSeedList' ||
      isWaitingState.isWaitingFor === 'cancelEpsilonCampaign') &&
    isWaitingState.isWaiting
  return {
    campaignId: currentCampaignId,
    projectId,
    timeZone: epsilonTimeZone,
    campaignData,
    businessUnits: epsilonBusinessUnits,
    folders: epsilonFolders,
    campaigns: epsilonCampaigns,
    seedList: epsilonSeedList,
    isLoading,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EpsilonIntegration)
