import Badge from 'common/components/Badge'

type Props = {
  value: string
}

const mapStatusToVariant = (status: string) => {
  switch (status) {
    case 'New Language':
      return 'info'
    case 'Draft':
      return 'neutral'
    case 'Missing Results':
      return 'danger'
    case 'Pending Approval':
      return 'warning'
    case 'Live':
      return 'success'
    case 'Completed':
      return 'feature'
    default:
      return 'neutral'
  }
}

const ContentStatus = ({ value }: Props) => {
  return (
    <Badge
      size="small"
      text={value}
      variant={mapStatusToVariant(value)}
      type={value === 'Completed' ? 'transparent' : 'solid'}
    />
  )
}

export default ContentStatus
