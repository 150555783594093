import { useFlags } from 'launchdarkly-react-client-sdk'
import { MachineSummary } from 'workflow/interface'

import CheckboxComponent from 'common/components/checkbox'
import IconTitleGroup from 'common/components/iconTitleGroup'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget/TableWidget'

const MachineOpensSummary = ({
  machineSummary,
  isMachineOpensVisible,
  onChangeMachineOpensVisible,
}: {
  machineSummary?: MachineSummary
  isMachineOpensVisible?: boolean
  onChangeMachineOpensVisible?: (showMachineOpens: boolean) => void
}) => {
  const { showMachineOpens } = useFlags()

  if (!showMachineOpens || !machineSummary) {
    return null
  }

  const columns = [
    {
      Header: <IconTitleGroup title="Opens" />,
      accessor: 'title',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <BaseCell className="w-full text-base-700 border-r-1 font-normal border-gold-200">
            {row.original.title}
          </BaseCell>
        )
      },
      Footer: () => (
        <BaseCell className="p-3">
          <CheckboxComponent
            className="col-span-3"
            data-cy="show-machine-opens-checkbox"
            data-testid="show-machine-opens-checkbox"
            label="Include machine opens in results"
            isChecked={isMachineOpensVisible || false}
            onChange={(isChecked: boolean) => {
              if (onChangeMachineOpensVisible) {
                onChangeMachineOpensVisible(isChecked)
              }
            }}
          />
        </BaseCell>
      ),
    },
    {
      Header: 'Rate',
      accessor: 'rate',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <BaseCell className="w-full text-base-700 border-r-1 font-normal border-gold-200">
            {row.original.rate}
          </BaseCell>
        )
      },
    },
    {
      Header: 'Total',
      accessor: 'opens',
      width: 65,
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <BaseCell className="w-full text-gold-700">
            {row.original.opens}
          </BaseCell>
        )
      },
    },
  ]

  const data = [
    {
      title: 'Machine Opens',
      rate: machineSummary.machine_opens_percent,
      opens: machineSummary.total_machine_opens,
    },
    {
      title: 'Other Opens',
      rate: machineSummary.other_opens_percent,
      opens: machineSummary.total_other_opens,
    },
  ]

  return (
    <div className="mt-6">
      <TableWidget.Widget
        columns={columns}
        className="border-none bg-gold-40"
        data={data}
        isHeaderHidden={false}
        data-cy="machine-summary-widget"
        data-testid="machine-summary-widget"
      />
    </div>
  )
}

export default MachineOpensSummary
