import moment from 'moment'

import DatePicker from 'common/components/datePicker'
import FormItem from 'common/components/formItem'

import { CommonFieldPropsGeneric, DateType } from '../interfaces'

const ERROR_MESSAGES = {
  required: 'Please select a date.',
}
const { REACT_APP_DEFAULT_DATE_FORMAT } = process.env

type Props = CommonFieldPropsGeneric & { value: DateType['value'] }

const Date = ({
  label,
  name,
  value,
  tooltip,
  errorCode,
  isDisabled,
  placeholder,
  onChange,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <FormItem
      label={label}
      guidingText={tooltip}
      htmlFor={name}
      error={errorCode ? ERROR_MESSAGES[errorCode] : undefined}
    >
      <DatePicker
        id={name}
        data-testid={dataTestId}
        placeholder={placeholder}
        onDateChange={(date) =>
          onChange(date ? date.format(REACT_APP_DEFAULT_DATE_FORMAT) : '')
        }
        date={value ? moment(value) : null}
        isOutsideRange={() => false}
        disabled={isDisabled}
      />
    </FormItem>
  )
}

export default Date
