import {
  FilePond as FileUploader,
  FilePondProps as FileUploaderProps,
  FileStatus,
  registerPlugin,
} from 'react-filepond'
import type { FilePondFile as FileUploaderFileType } from 'filepond'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'

import 'filepond/dist/filepond.min.css'
import './FileUploader.css'

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize)

export default FileUploader
export { FileStatus }

export type { FileUploaderProps, FileUploaderFileType }
