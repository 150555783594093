import Button from 'common/components/button'
import { Topic } from 'common/components/topics/interfaces'
import {
  addTopic,
  removeTopic,
} from 'common/components/topics/store/topicsSlice'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

type Props = {
  topic: Topic
}

const Option = ({ topic }: Props) => {
  const dispatch = useAppDispatch()
  const currentTopics = useAppSelector((state) => state.topics.topics)

  const { id, category, type } = topic
  const name = type === 'replacer' ? category : topic.name

  const isAlreadyAdded = currentTopics.some((val) => val.id === id)

  return (
    <div
      data-cy={id}
      data-testid={id}
      className="py-2 px-6 flex items-center justify-between text-coolGray-800 font-medium
                hover:bg-coolGray-100"
    >
      <span>{name}</span>
      {isAlreadyAdded ? (
        <Button
          data-cy="remove-button"
          data-testid="remove-button"
          size="small"
          className="text-white bg-green-500"
          onClick={() => dispatch(removeTopic(id))}
        >
          Remove
        </Button>
      ) : (
        <Button
          data-cy="add-button"
          data-testid="add-button"
          variant="primary"
          size="small"
          onClick={() => dispatch(addTopic(topic))}
        >
          Add
        </Button>
      )}
    </div>
  )
}

export default Option
