import { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import { FieldType } from 'common/components/dynamicFields/interfaces'
import { changeAccountId } from 'features/auth/store/authSlice'

import { ConfigurationState } from '../components/nodeConfiguration/interfaces'
import { Topic } from '../interfaces'

interface TopicsState {
  topics: Topic[]
  shouldAllowConstants: boolean
  hasErrorLoadingTopics: boolean
  isConfigurationMenuOpen: boolean
  contentId?: string
}

export const initialState: TopicsState = {
  topics: [],
  shouldAllowConstants: false,
  hasErrorLoadingTopics: false,
  isConfigurationMenuOpen: false,
}

const hasConstants = (topics: Topic[]) =>
  topics.some((topic) => topic.isConstant)

const topicsSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    loadError: (state, action: PayloadAction<boolean>) => {
      state.hasErrorLoadingTopics = action.payload
    },
    toggleConfigurationMenu: (state, action: PayloadAction<boolean>) => {
      state.isConfigurationMenuOpen = action.payload
    },
    addTopic: (state, action: PayloadAction<Topic>) => {
      state.hasErrorLoadingTopics = false
      state.topics.push(action.payload)
    },
    removeTopic: (state, action: PayloadAction<string>) => {
      state.topics = state.topics.filter((topic) => topic.id !== action.payload)
    },

    allowConstantsClicked: (state, action: PayloadAction<boolean>) => {
      const isChecked = action.payload

      if (!isChecked && hasConstants(state.topics)) {
        state.topics.forEach((topic) => {
          topic.isConstant = false
        })
      }
      state.shouldAllowConstants = isChecked
    },
    updateTopics: (state, action: PayloadAction<Topic[]>) => {
      const updatedTopics = action.payload

      state.shouldAllowConstants = hasConstants(updatedTopics)
      state.topics = updatedTopics
    },
    updateTopic: (
      state,
      action: PayloadAction<{
        id: string
        name: string
        configurationState: ConfigurationState<FieldType>
      }>
    ) => {
      const { id, name, configurationState } = action.payload

      const topic = state.topics.find((topic) => topic.id === id)
      if (topic) {
        topic.name = name
        if (topic.type === 'replacer') {
          topic.configuration?.forEach((field) => {
            const value = configurationState.find(
              (s) => s.name === field.name
            )?.value
            field.value = value
          })
        }
      }
    },
    toggleTopicIsConstant: (
      state,
      action: PayloadAction<{ topicId: string; isConstant: boolean }>
    ) => {
      const { topicId, isConstant } = action.payload
      const topic = state.topics.find((topic) => topic.id === topicId)
      if (topic) {
        topic.isConstant = isConstant
      }
    },
    resetCurrentState: (state) => {
      state.topics = []
      state = initialState
    },
    updateContentId: (state, action: PayloadAction<string>) => {
      state.contentId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, () => initialState)
  },
})

export const {
  loadError,
  updateTopics,
  toggleConfigurationMenu,
  addTopic,
  removeTopic,
  updateTopic,
  resetCurrentState,
  allowConstantsClicked,
  toggleTopicIsConstant,
  updateContentId,
} = topicsSlice.actions
export default topicsSlice.reducer
