import { Column } from 'common/components/table'
import Tags from 'common/components/tags'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import {
  addPronounReplacer,
  removePronounReplacer,
} from '../store/regionsSlice'

const PronounsWidget = () => {
  const dispatch = useAppDispatch()
  const regionEdited = useAppSelector((state) => state.regions.regionEdited)
  const regionNodes = useAppSelector((state) => state.regions.nodes)

  const getNodeId = (displayName) => {
    const node = regionNodes.find((node) => node.display_name === displayName)
    return node ? node.id : 0
  }

  const nodesFeminine = regionNodes
    .slice()
    .filter((node) => (regionEdited.pronouns.feminine as any).includes(node.id))

  const nodesMasculine = regionNodes
    .slice()
    .filter((node) =>
      (regionEdited.pronouns.masculine as any).includes(node.id)
    )
  const nodesEpicene = regionNodes
    .slice()
    .filter((node) => (regionEdited.pronouns.epicene as any).includes(node.id))

  const suggestions = regionNodes
    .filter((node) => node.display_name !== null)
    .filter(
      (node) =>
        !(regionEdited.pronouns.feminine as any).includes(node.id) &&
        !(regionEdited.pronouns.masculine as any).includes(node.id) &&
        !(regionEdited.pronouns.epicene as any).includes(node.id)
    )
    .map((node) => node.display_name) as string[]

  const data = [
    {
      pronounReplacement: 'Feminine',
      nodes: nodesFeminine.map((node) => node.display_name),
    },
    {
      pronounReplacement: 'Masculine',
      nodes: nodesMasculine.map((node) => node.display_name),
    },
    {
      pronounReplacement: 'Neuter',
      nodes: nodesEpicene.map((node) => node.display_name),
    },
  ]

  const columns: Column<{
    pronounReplacement: string
    nodes: any[]
  }>[] = [
    {
      accessor: 'pronounReplacement',
      disableSortBy: true,
      Header: 'Pronoun replacement',
      isResizable: true,
      width: 50,
      minWidth: 40,
    },
    {
      accessor: 'nodes',
      disableSortBy: true,
      shouldOverflow: true,
      Header: 'Nodes',
      Cell: ({ value, row: { index } }) => {
        return (
          <div className="ml-6 my-4 flex items-center border-x-2">
            <Tags
              isFreeText
              data-cy="pronouns-tags"
              data-testid="pronouns-tags"
              onAddClick={(value: string) => {
                const nodeId = getNodeId(value)
                dispatch(addPronounReplacer({ value: nodeId, index }))
              }}
              onRemoveClick={(value: string) => {
                const nodeId = getNodeId(value)
                dispatch(removePronounReplacer({ value: nodeId, index }))
              }}
              tags={value.map((value) => ({ value, label: value }))}
              maxLength={50}
              suggestions={suggestions}
            />
          </div>
        )
      },
    },
  ]

  return (
    <TableWidget.Widget
      columns={columns}
      data={data}
      isHeaderHidden={false}
      data-cy="pronouns-widget"
      data-testid="pronouns-widget"
    >
      <TableWidget.Header
        title="Pronouns"
        subtitle={`Select which nodes should trigger the use of each pronoun groups. 
        For example, if Mother's Day triggers the feminine pronouns "You'll love this" 
        will be converted to "She'll love this" when a user generates using the topic Mother's Day.`}
      >
        <TableWidget.Filter />
      </TableWidget.Header>
    </TableWidget.Widget>
  )
}

export default PronounsWidget
