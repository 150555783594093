import { useCallback } from 'react'
import { closeTweakLine } from 'workflow/Workflow.actions'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useDeleteImageVariantsMutation } from 'features/unifiedFlow/api/mutations/useDeleteImageVariantsMutation'
import useTweakVariantMutation from 'features/unifiedFlow/api/mutations/useTweakVariantMutation'
import {
  useContent,
  useSelectedComponentElement,
} from 'features/unifiedFlow/contentPage/hooks'
import { getWsTopic } from 'features/unifiedFlow/contentPage/hooks/websocketMessage/helpers'
import useWebsocketMessage from 'features/unifiedFlow/contentPage/hooks/websocketMessage/useWebsocketMessage'

import { getIsOptimized } from '../../helpers'

import type { RejectLineData, TweakLineData, TweakVariant } from './types'

const useTweakVariantModal = (): TweakVariant => {
  const dispatch = useAppDispatch()
  const { content } = useContent()
  const { data: selectedElement } = useSelectedComponentElement()
  const { sendWebsocketMessage } = useWebsocketMessage()

  const isPersonalizable = selectedElement?.personalization_id
  const isOptimized = getIsOptimized(selectedElement)
  const isImageOptimization =
    selectedElement?.optimization_settings?.type === 'image_optimize'

  const accountId = useAppSelector((state) => state.authStates.accountId)

  const tweakVariantMutation = useTweakVariantMutation()

  const { campaignData, tweakingLoading, showTweakLine, lineBeingTweaked } =
    useAppSelector((state) => state.campaignStates)

  // TODO: Remove this once BE handles delete image variants on their side.
  // This will be removed alongside the useDeleteImageVariantsMutation hook + isImageOptimization variable.
  const {
    mutateAsync: deleteImageVariants,
    isLoading: isDeletingImageVariants,
  } = useDeleteImageVariantsMutation()

  const closeTweakLines = useCallback(() => {
    dispatch(closeTweakLine())
  }, [dispatch])

  const onRejectLine = useCallback(
    async (rejectLineData: RejectLineData) => {
      if (content?._id && selectedElement?.element_id && lineBeingTweaked._id) {
        if (isImageOptimization && selectedElement?.campaign_id) {
          await deleteImageVariants({ campaignId: selectedElement.campaign_id })
        }

        const wsTopic = getWsTopic({
          action: 'rejectVariant',
          data: {
            contentId: content?._id,
            elementId: selectedElement?.element_id,
            variantId: String(lineBeingTweaked._id),
          },
        })
        tweakVariantMutation.mutate({
          accountId,
          contentId: content?._id,
          elementId: selectedElement?.element_id,
          variantId: lineBeingTweaked._id,
          tweakData: { ...rejectLineData, wsTopic },
        })
        sendWebsocketMessage({
          action: 'rejectVariant',
          subscriptionAction: 'subscribe',
          data: {
            contentId: content?._id,
            elementId: selectedElement?.element_id,
            variantId: String(lineBeingTweaked._id),
          },
        })
        closeTweakLines()
      }
    },
    [
      accountId,
      closeTweakLines,
      content?._id,
      lineBeingTweaked._id,
      selectedElement?.element_id,
      selectedElement?.campaign_id,
      sendWebsocketMessage,
      tweakVariantMutation,
      isImageOptimization,
      deleteImageVariants,
    ]
  )

  const onTweakLine = useCallback(
    async (tweakLineData: TweakLineData) => {
      const data = {
        ...tweakLineData,
        ...(isOptimized && {
          campaign_id: selectedElement?.campaign_id,
        }),
      }

      if (content?._id && selectedElement?.element_id && lineBeingTweaked._id) {
        if (isImageOptimization && selectedElement?.campaign_id) {
          await deleteImageVariants({ campaignId: selectedElement.campaign_id })
        }

        const wsTopic = getWsTopic({
          action: 'tweakVariant',
          data: {
            contentId: content?._id,
            elementId: selectedElement?.element_id,
            variantId: String(lineBeingTweaked._id),
          },
        })
        tweakVariantMutation.mutate({
          accountId,
          contentId: content?._id,
          elementId: selectedElement?.element_id,
          variantId: lineBeingTweaked._id,
          tweakData: { ...data, wsTopic },
        })

        sendWebsocketMessage({
          action: 'tweakVariant',
          subscriptionAction: 'subscribe',
          data: {
            contentId: content?._id,
            elementId: selectedElement?.element_id,
            variantId: String(lineBeingTweaked._id),
          },
        })
        closeTweakLines()
      }
    },
    [
      accountId,
      closeTweakLines,
      content?._id,
      lineBeingTweaked._id,
      selectedElement?.element_id,
      sendWebsocketMessage,
      tweakVariantMutation,
      isOptimized,
      selectedElement?.campaign_id,
      isImageOptimization,
      deleteImageVariants,
    ]
  )

  return {
    isVisible: showTweakLine,
    isTweakingLoading: tweakingLoading || isDeletingImageVariants,
    feedbackSettings: isPersonalizable
      ? {
          edit: { feedback_only: false, enabled: true, limit: 30 },
          reject: {
            feedback_only: false,
            enabled: selectedElement?.approved ? false : true,
            limit: 30,
          },
        }
      : campaignData?.campaign_configuration?.feedback_settings,
    newlinesInVariants:
      campaignData?.campaign_configuration?.validation_rules
        ?.newlines_in_variants,
    tweakingLine: lineBeingTweaked,
    onRejectLine,
    onTweakLine,
    onClose: closeTweakLines,
  }
}

export default useTweakVariantModal
