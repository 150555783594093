import { ReactNode } from 'react'

import Widget from 'common/components/Widget'
import { Info } from 'common/icons'

type Props = {
  title: string
  children: ReactNode
}

const GuidanceWidget = ({ title, children }: Props) => {
  return (
    <Widget type="basic" className="bg-base-100 border-base-100">
      <div className="text-base-700 font-medium text-xl mr-4 capitalize-first">
        <div className="flex items-center">
          <Info isDefaultColor={false} className="text-base-700" />
          <div className="ml-2">{title}</div>
        </div>
        <div className="text-base-700 text-sm font-normal mt-4">{children}</div>
      </div>
    </Widget>
  )
}

export default GuidanceWidget
