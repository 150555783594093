type Props = {
  children?: React.ReactNode
}

const TemplatesConfigPanelFooter = ({ children }: Props) => {
  return (
    <div className="mt-auto bg-gold-100 flex overflow-x-hidden">{children}</div>
  )
}

export default TemplatesConfigPanelFooter
