import { useRef, useState } from 'react'
import cx from 'classnames'
import { SplitSizeData } from 'workflow/interface'

import { TextareaType } from 'common/components/dynamicFields/interfaces'
import ControlSubjectLine from 'features/unifiedFlow/components/templateSelection/setupExperiment/ControlSubjectLine'
import ExperimentName from 'features/unifiedFlow/components/templateSelection/setupExperiment/ExperimentName'
import Project from 'features/unifiedFlow/components/templateSelection/setupExperiment/Project'
import SendDate from 'features/unifiedFlow/components/templateSelection/setupExperiment/SendDate'
import SplitCalculator from 'features/unifiedFlow/components/templateSelection/splitCalculator'

import { getStepStatus } from '../../../helpers'
import { useContent } from '../../../hooks'

import ExperimentSettingsFooter from './ExperimentSettingsFooter'

import classes from './ExperimentSettings.module.css'

const ExperimentSettingsFormContent = ({
  optimizationContentType,
  optimizationSettings,
  projectSplitCalculatorConfiguration,
}) => {
  const resetCalculatorCallback = useRef<() => void>(() => {})
  const [isCalculatorLoading, setIsCalculatorLoading] = useState(false)
  const { content } = useContent()

  const initialSplitCalculationResult: SplitSizeData = (
    optimizationSettings as any
  )?.split_calculation.configuration //TODO: Remove casting when SplitCalculationConfigDto matches SplitSizeData

  const splitCalculationResultRef = useRef<SplitSizeData | undefined>(
    initialSplitCalculationResult
  )

  const controlSubjectLineConfig =
    projectSplitCalculatorConfiguration?.experimentMainFields?.find(
      (field) => field.name === 'own_subject_line'
    )
  const dateConfig =
    projectSplitCalculatorConfiguration?.experimentMainFields?.find(
      (field) => field.name === 'iso_send_date'
    )
  const splitCalculatorConfiguration =
    projectSplitCalculatorConfiguration?.splitCalculation
  const integrationType = projectSplitCalculatorConfiguration?.integrationType

  const briefStepsStatus = getStepStatus(content?.steps_status, 'brief')
  const isBriefStepsStatusReadOnly = briefStepsStatus === 'read-only'

  return (
    <>
      <div className={cx(classes.experimentSettingsFormContent)}>
        <ExperimentName
          isDisabled={isBriefStepsStatusReadOnly}
          name="experimentName"
        />
        <Project
          name="project"
          mode="edit"
          data-testid="project-select"
          isDisabled
          optimizationContentType={optimizationContentType}
        />
        {controlSubjectLineConfig && (
          <ControlSubjectLine
            name="controlSubjectLine"
            config={controlSubjectLineConfig as TextareaType}
            integrationType={integrationType}
            isDisabled={isBriefStepsStatusReadOnly}
          />
        )}
        {dateConfig && (
          <SendDate
            isDisabled={isBriefStepsStatusReadOnly}
            name="sendDate"
            config={dateConfig}
            data-testid="send-date"
          />
        )}

        {splitCalculatorConfiguration && (
          <SplitCalculator
            configuration={splitCalculatorConfiguration}
            splitCalculationResult={initialSplitCalculationResult}
            onLoading={setIsCalculatorLoading}
            onResult={(result) => (splitCalculationResultRef.current = result)}
            resetCalculatorCallback={(resetCallback) =>
              (resetCalculatorCallback.current = resetCallback)
            }
            isDisabled={isBriefStepsStatusReadOnly}
          />
        )}
      </div>
      <ExperimentSettingsFooter
        isDisabled={isCalculatorLoading || isBriefStepsStatusReadOnly}
        splitCalculationResult={splitCalculationResultRef.current}
        optimizationSettingsConfiguration={optimizationSettings}
        onReset={() => resetCalculatorCallback.current?.()}
      />
    </>
  )
}

export default ExperimentSettingsFormContent
