import { useState } from 'react'

import type { ImageEntity } from 'common/components/images/api/api'
import ImagePreviewModal from 'common/components/images/imagePreviewModal'

import AddedImageCard from './AddedImageCard'
import EmptyImageCard from './EmptyImageCard'
import LoaderImageCard from './LoaderImageCard'

type Props = {
  index: number
  image: ImageEntity | undefined
  isActionsDisabled?: boolean
  onDeleteImage: (index: number) => void
  onReplaceImage: (index: number) => void
  showImageGallery: (value: boolean) => void
}

const EditableImageCard = ({
  index,
  image,
  isActionsDisabled,
  onDeleteImage,
  onReplaceImage,
  showImageGallery,
}: Props) => {
  const [isImagePreviewVisible, setImagePreview] = useState<boolean>(false)

  const getState = () => {
    if (!!image) {
      return 'imageAdded'
    } else {
      return 'empty'
    }
  }

  const deleteHandler = isActionsDisabled
    ? undefined
    : () => onDeleteImage(index)
  const replaceHandler = isActionsDisabled
    ? undefined
    : () => onReplaceImage(index)
  const previewModalDeleteHandler = isActionsDisabled
    ? undefined
    : () => {
        onDeleteImage(index)
        setImagePreview(false)
      }

  const state = getState()

  return (
    <>
      {
        {
          loading: <LoaderImageCard />,
          empty: (
            <EmptyImageCard showImageGallery={() => showImageGallery(true)} />
          ),
          imageAdded: (
            <>
              <AddedImageCard
                url={image?.url}
                onPreview={() => setImagePreview(true)}
                onDelete={deleteHandler}
                onReplace={replaceHandler}
              />
              <ImagePreviewModal
                destroyOnClose
                isVisible={isImagePreviewVisible}
                image={image!}
                onClose={() => setImagePreview(false)}
                onDelete={previewModalDeleteHandler}
              />
            </>
          ),
        }[state]
      }
    </>
  )
}

export default EditableImageCard
