import { Form } from 'antd'

import { InputElement } from '../../interface'

import { getFieldError } from './helpers'
import { FormInstance } from './interfaces'

type Props = {
  field: InputElement
  form: FormInstance
  className?: string
}

const FormItem: React.FC<Props> = ({ field, form, className, children }) => {
  const fieldError = getFieldError(form, field.field_id)

  return (
    <Form.Item
      key={field?.field_id}
      label={field.title}
      validateStatus={fieldError ? 'error' : ''}
      help={fieldError || ''}
      data-testid={field.field_id}
      className={className}
    >
      {children}
    </Form.Item>
  )
}

export default FormItem
