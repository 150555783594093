import { FC, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import isString from 'lodash/isString'

import { generateUrlWithPreserveTable } from 'common/components/table/Table'
import { ArrowLeft } from 'common/icons'
import Link from 'common/typography/Link'

import NavbarLayout from '../navbarLayout/NavbarLayout'

import navbarLayoutStyles from '../navbarLayout/navbarLayout.module.css'

export type SubNavProps = {
  preTitle?: React.ReactNode
  title?: string | React.ReactNode
  backLink?: string
  className?: string
  children?: React.ReactNode
  rightNav?: React.ReactNode
}

const SubNavBar: FC<SubNavProps> = ({
  title,
  children,
  rightNav,
  backLink,
  preTitle,
  className,
}) => {
  const [navWidth, setNavWidth] = useState(0)

  const backUrl = generateUrlWithPreserveTable({
    backLink,
    backUrl: new URL(backLink ?? '', window.location.origin),
  })

  const navbarRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (!navbarRef.current) {
      return
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setNavWidth(entry.contentRect.width)
      }
    })
    const navbarEl = navbarRef.current
    resizeObserver.observe(navbarEl)

    return () => {
      resizeObserver.unobserve(navbarEl)
    }
  }, [])

  return (
    <NavbarLayout
      variant="subNav"
      className={cx('min-h-12 max-h-12', className)}
    >
      <div
        className="flex justify-between items-center font-medium uppercase hover:text-yellow-20"
        style={{ width: `calc(50% - ${navWidth / 2}px)` }}
      >
        <div className="flex items-center text-white w-full">
          {backLink ? (
            <Link
              data-testid="back-button"
              to={{
                pathname: backUrl.pathname,
                search: backUrl.search,
              }}
            >
              <ArrowLeft
                className="mr-2.5 text-white hover:text-yellow-200"
                isDefaultColor={false}
              />
            </Link>
          ) : undefined}
          {preTitle}
          {isString(title) ? (
            <h3 className="text-xs text-white truncate mr-2.5">{title}</h3>
          ) : (
            title
          )}
        </div>
      </div>
      <nav
        ref={navbarRef}
        className={cx(
          'flex items-center justify-center gap-8 text-sm text-white uppercase',
          navbarLayoutStyles.navbarCenter
        )}
      >
        {children}
      </nav>
      {!!rightNav && (
        <div className="flex items-center justify-end">{rightNav}</div>
      )}
    </NavbarLayout>
  )
}

export default SubNavBar
