import { FC, useEffect } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Checkbox from 'common/components/checkbox'
import FormItem from 'common/components/formItem'
import Label from 'common/components/label'
import MultiSelect, { MultiSelectValue } from 'common/components/MultiSelect'
import RadioGroup from 'common/components/radioGroup'
import SingleSelect from 'common/components/singleSelect'
import { getFieldError } from 'features/projects/helpers'
import {
  HumanControl,
  LanguageGenerationMethod,
  LanguageGenerationSettings,
} from 'features/projects/interface'

import FormCard, { FormCardTitle } from '../formCard'

type Props = {
  languageGenerationSettings: LanguageGenerationSettings
  isCreating: boolean
}

const LanguageGeneration: FC<Props> = ({
  languageGenerationSettings,
  isCreating,
}) => {
  const formState = useFormState()
  const { change: formChange } = useForm()
  const {
    minLinebreak,
    maxLinebreak,
    humanControlSettings: humanControl,
    languageModels,
  } = formState.values

  const flags = useFlags()
  const { showLlmGenerationMode } = flags

  const generationMethod = languageGenerationSettings?.languageGenerationMethods
  if (!showLlmGenerationMode) {
    generationMethod.options = generationMethod.options.filter(
      (methodName) => methodName.value !== 'llm'
    )
  }
  const modelSetting = languageGenerationSettings?.languageModelSettings
  const currencySetting = languageGenerationSettings?.languageCurrencySettings
  const minLinebreakSettings =
    languageGenerationSettings?.linebreakSettings.minLinebreak
  const maxLinebreakSettings =
    languageGenerationSettings?.linebreakSettings.maxLinebreak
  const humanControlSettings = languageGenerationSettings?.humanControlSettings
  const editLinesCheckbox =
    languageGenerationSettings?.languageFeedbackSettings
      .languageFeedbackEditSettings
  const rejectLinesCheckbox =
    languageGenerationSettings?.languageFeedbackSettings
      .languageFeedbackRejectSettings
  const externalApprovalCheckbox =
    languageGenerationSettings?.languageFeedbackSettings
      .externalApprovalRequired

  const maxLinebreaksOptions =
    minLinebreak !== undefined
      ? maxLinebreakSettings?.options?.filter(({ value }) => {
          const minValue = minLinebreak
          return value >= minValue
        })
      : maxLinebreakSettings?.options

  useEffect(() => {
    if (maxLinebreak !== undefined && minLinebreak > maxLinebreak) {
      formChange('maxLinebreak', undefined)
    }
  }, [formChange, minLinebreak, maxLinebreak])

  useEffect(() => {
    if (humanControlSettings?.show && humanControl === undefined) {
      formChange('humanControlSettings', humanControlSettings?.default)
    }
  }, [formChange, humanControl, humanControlSettings])

  const getInitalCurrencyValue = () => {
    if (isCreating) {
      const dollarValue = '$'
      const isDollarAvailable = currencySetting.options.find(
        ({ value }) => value === dollarValue
      )

      return isDollarAvailable ? dollarValue : undefined
    } else {
      return undefined
    }
  }

  const languageModelItems: MultiSelectValue[] = modelSetting.options.map(
    (model) => {
      return { ...model, id: model.value }
    }
  )

  return (
    <FormCard
      data-cy="language-generation-card"
      data-testid="language-generation-card"
    >
      <FormCardTitle title="Language generation method" />
      {generationMethod?.show && (
        <Field<LanguageGenerationMethod> name="languageGenerationMethod">
          {({ input, meta }) => (
            <FormItem className="mt-4 mb-8" error={getFieldError(meta)}>
              <RadioGroup<LanguageGenerationMethod>
                data-cy="lamguage-generation-method"
                data-testid="lamguage-generation-method"
                value={input.value}
                name="language-model"
                options={generationMethod.options}
                onChange={input.onChange}
              />
            </FormItem>
          )}
        </Field>
      )}

      <div className="flex gap-6">
        {modelSetting?.show ? (
          <Field<string> name="languageModels">
            {({ input, meta }) => (
              <FormItem
                className="max-w-xs"
                label="Model name"
                error={getFieldError(meta)}
              >
                <MultiSelect<MultiSelectValue>
                  data-cy="language-model-name-select"
                  data-testid="language-model-name-select"
                  aria-label="Model name"
                  items={languageModelItems}
                  selectedItems={languageModels}
                  name="model names"
                  placeholder="Select..."
                  onApply={(val) => {
                    val && input.onChange(val)
                  }}
                  minOptions={0}
                />
              </FormItem>
            )}
          </Field>
        ) : undefined}
        {currencySetting?.show ? (
          <Field<string>
            name="languageCurrency"
            initialValue={getInitalCurrencyValue()}
          >
            {({ input, meta }) => (
              <FormItem
                className="max-w-xs"
                label="Currency"
                error={getFieldError(meta)}
              >
                <SingleSelect
                  data-cy="language-currency-select"
                  data-testid="language-currency-select"
                  aria-label="Currency"
                  options={currencySetting.options}
                  value={input.value}
                  onChange={(val) => val && input.onChange(val.value)}
                />
              </FormItem>
            )}
          </Field>
        ) : undefined}
      </div>

      <Label>Linebreaks</Label>
      <div className="flex gap-6">
        {minLinebreakSettings?.show && (
          <Field<number>
            name="minLinebreak"
            initialValue={
              isCreating ? minLinebreakSettings.options[0].value : undefined
            }
          >
            {({ input, meta }) => (
              <FormItem
                className="max-w-29"
                label="Min"
                error={getFieldError(meta)}
              >
                <SingleSelect
                  data-cy="min-linebreaks-select"
                  data-testid="min-linebreaks-select"
                  aria-label="Min linebreaks"
                  options={minLinebreakSettings?.options.map((option) => ({
                    ...option,
                    value: String(option.value),
                  }))}
                  value={
                    input.value === undefined
                      ? undefined
                      : input.value.toString()
                  }
                  onChange={(val) => val && input.onChange(Number(val.value))}
                />
              </FormItem>
            )}
          </Field>
        )}
        {maxLinebreakSettings?.show && (
          <Field<number>
            name="maxLinebreak"
            initialValue={
              isCreating ? maxLinebreakSettings.options[0].value : undefined
            }
          >
            {({ input, meta }) => (
              <FormItem
                className="max-w-29"
                label="Max"
                error={getFieldError(meta)}
              >
                <SingleSelect
                  data-cy="max-linebreaks-select"
                  data-testid="max-linebreaks-select"
                  aria-label="Max linebreaks"
                  options={maxLinebreaksOptions?.map((option) => ({
                    ...option,
                    value: String(option.value),
                  }))}
                  value={
                    input.value === undefined
                      ? undefined
                      : input.value.toString()
                  }
                  onChange={(val) => val && input.onChange(Number(val.value))}
                />
              </FormItem>
            )}
          </Field>
        )}
      </div>

      {humanControlSettings?.show ? (
        <Field<HumanControl> name="humanControlSettings">
          {({ input, meta }) => (
            <FormItem
              className="max-w-xs"
              label="Human control"
              error={getFieldError(meta)}
            >
              <SingleSelect
                data-cy="human-control-select"
                data-testid="human-control-select"
                aria-label="Human control"
                options={humanControlSettings.options}
                value={input.value}
                onChange={(val) => val && input.onChange(val.value)}
              />
            </FormItem>
          )}
        </Field>
      ) : undefined}

      <div className="flex gap-6">
        {editLinesCheckbox?.show && (
          <Field<boolean>
            name="languageFeedbackEdit"
            initialValue={isCreating ? true : undefined}
          >
            {({ input, meta }) => (
              <FormItem className="flex-none" error={getFieldError(meta)}>
                <Checkbox
                  data-cy="language-feedback-edit-checkbox"
                  data-testid="language-feedback-edit-checkbox"
                  label="Allow user to edit lines"
                  isChecked={input.value}
                  onChange={input.onChange}
                />
              </FormItem>
            )}
          </Field>
        )}
        {rejectLinesCheckbox?.show && (
          <Field<boolean>
            name="languageFeedbackReject"
            initialValue={isCreating ? true : undefined}
          >
            {({ input, meta }) => (
              <FormItem className="flex-none" error={getFieldError(meta)}>
                <Checkbox
                  data-cy="language-feedback-reject-checkbox"
                  data-testid="language-feedback-reject-checkbox"
                  label="Allow user to reject lines"
                  isChecked={input.value}
                  onChange={input.onChange}
                />
              </FormItem>
            )}
          </Field>
        )}
        {externalApprovalCheckbox?.show && (
          <Field<boolean>
            name="externalApprovalRequired"
            initialValue={isCreating ? false : undefined}
          >
            {({ input, meta }) => (
              <FormItem className="flex-none" error={getFieldError(meta)}>
                <Checkbox
                  data-cy="external-approval-required-checkbox"
                  data-testid="external-approval-required-checkbox"
                  label="External approvals"
                  isChecked={input.value}
                  onChange={input.onChange}
                />
              </FormItem>
            )}
          </Field>
        )}
      </div>
    </FormCard>
  )
}

export default LanguageGeneration
