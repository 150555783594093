import { DateRangePickerShape } from 'react-dates'
import cx from 'classnames'

import {
  ArrowLeft as ArrowLeftIcon,
  ArrowRight as ArrowRightIcon,
  CalendarSquare as CalendarSquareIcon,
  CloseSquare as CloseSquareIcon,
} from 'common/icons'

import NavButton from './NavButton'

export const renderNavNextButton = (props) => (
  <NavButton
    className="DayPickerNavigation_rightButton__horizontalDefault"
    {...props}
  >
    <ArrowRightIcon isDefaultColor={false} size={4} />
  </NavButton>
)

export const renderNavPrevButton = (props) => (
  <NavButton
    className="DayPickerNavigation_leftButton__horizontalDefault"
    {...props}
  >
    {' '}
    <ArrowLeftIcon isDefaultColor={false} size={4} />
  </NavButton>
)

export const renderCustomCloseIcon = () => (
  <CloseSquareIcon
    isDefaultColor={false}
    className="text-gold-700 hover:text-base-700"
    size={4}
  />
)

export const renderCustomInputIcon = (
  disabled: DateRangePickerShape['disabled']
) => (
  <CalendarSquareIcon
    isDefaultColor={!disabled}
    className={cx('text-gold-700', { 'cursor-not-allowed': disabled })}
    size={4}
  />
)

export const renderCustomArrowIcon = () => (
  <ArrowRightIcon isDefaultColor={false} className="text-base-700" size={4} />
)
