import React from 'react'
import moment from 'moment-timezone'

import RadioButtonsCard, { Option } from '../components/card/RadioButtonsCard'
import SelectComponent from '../components/select/SelectComponent'

import FacebookStatus from './FacebookStatus'
import ScheduleCard from './ScheduleCard'

const OPTIMIZATION_TYPES: Option[] = [
  {
    id: 'standard',
    title: 'Standard',
    subtitle: 'Facebook optimizer, all ads in one ad set.',
  },
  {
    id: 'adStudy',
    title: 'Ad Study',
    subtitle: 'One Ad per ad set and Facebook splits the audience.',
  },
]

export default function DrawerContent({
  currentState,
  isLoading,
  setState,
  adAccounts,
  campaigns,
  adSets,
  adverts,
  isCampaignScheduled,
}) {
  const {
    selectedAdAccount,
    selectedCampaign,
    selectedAdSet,
    errorMessages,
    selectedAdvert,
    startDate,
    startTime,
    endDate,
    endTime,
    optimizationType,
  } = currentState

  const getChangedSchedulerValues = (value: moment.Moment, type: string) => {
    switch (type) {
      case 'startDate':
        return setState({
          ...currentState,
          startDate: moment(value),
          endDate: moment(value).isSameOrAfter(endDate)
            ? moment(value)
            : endDate,
          startTime: startTime
            ? moment(value).set({
                hours: startTime.hours(),
                minutes: startTime.minutes(),
              })
            : undefined,
        })
      case 'startTime':
        return setState({
          ...currentState,
          startDate: startDate
            ? startDate.set({
                hours: value.hours(),
                minutes: value.minutes(),
              })
            : undefined,
          startTime: moment(value),
          endTime: endTime && value.format() > endTime.format(),
        })
      case 'endDate':
        return setState({
          ...currentState,
          endDate: moment(value),
          endTime: endTime
            ? moment(value).set({
                day: endTime.day(),
                month: endTime.month(),
                year: endTime.year(),
              })
            : undefined,
        })
      case 'endTime':
        return setState({
          ...currentState,
          endDate: endDate
            ? moment(endDate).set({
                hour: value.hour(),
                minute: value.minute(),
              })
            : undefined,
          endTime: moment(value),
        })
      default:
        return {}
    }
  }

  const hasCampaigns = campaigns?.length > 0
  const hasAdSets = adSets?.length > 0
  const hasAdverts = adverts?.length > 0

  return (
    <>
      <FacebookStatus showButton={true} />
      <SelectComponent
        isLoading={isLoading.adAccount}
        isSelectDisabled={isCampaignScheduled}
        selectOptions={adAccounts}
        dataTestId="selectAdAccount"
        onChange={(val) =>
          setState({ ...currentState, selectedAdAccount: val })
        }
        labelTitle="Facebook ad account"
        bottomText={`This is the Facebook account 
        that contains your advert that you would like to connect to.`}
        selectValue={selectedAdAccount}
        errorMessage={errorMessages.adAccount}
        defaultOption="Select"
      />
      {selectedAdAccount && (
        <SelectComponent
          isLoading={isLoading.campaigns}
          isSelectDisabled={isCampaignScheduled}
          selectOptions={hasCampaigns ? campaigns : undefined}
          dataTestId="selectCampaigns"
          onChange={(val) =>
            setState({ ...currentState, selectedCampaign: val })
          }
          labelTitle="Campaign"
          bottomText={`This is the campaign that will use 
          the Jacquard variants.`}
          selectValue={selectedCampaign}
          errorMessage={errorMessages.campaign}
          defaultOption="Select"
        />
      )}
      {selectedAdAccount && selectedCampaign && (
        <SelectComponent
          isLoading={isLoading.adSet}
          isSelectDisabled={isCampaignScheduled}
          selectOptions={hasAdSets ? adSets : undefined}
          dataTestId="selectAdSets"
          onChange={(val) => setState({ ...currentState, selectedAdSet: val })}
          labelTitle="Ad Set"
          bottomText={`This is the Ad Set that will use 
          the Jacquard variants.`}
          selectValue={selectedAdSet}
          errorMessage={errorMessages.adSet}
          defaultOption="Select"
        />
      )}
      {selectedAdAccount && selectedCampaign && selectedAdSet && (
        <SelectComponent
          isLoading={isLoading.adverts}
          isSelectDisabled={isCampaignScheduled}
          selectOptions={hasAdverts ? adverts : undefined}
          dataTestId="selectAdvert"
          onChange={(val) => setState({ ...currentState, selectedAdvert: val })}
          labelTitle="Advert"
          bottomText={`This is the advert that will use 
          the Jacquard variants.`}
          selectValue={selectedAdvert}
          errorMessage={errorMessages.advert}
          defaultOption="Select"
        />
      )}
      {selectedAdAccount &&
        selectedCampaign &&
        selectedAdSet &&
        selectedAdvert && (
          <>
            <RadioButtonsCard
              cardTitle="Optimisation type"
              onChange={(val) =>
                setState({ ...currentState, optimizationType: val })
              }
              options={OPTIMIZATION_TYPES}
              value={optimizationType}
              errorMessage={errorMessages.optimizationType}
              isDisabled={isCampaignScheduled}
            />
            <ScheduleCard
              endDate={endDate}
              endTime={endTime}
              errorMessages={errorMessages}
              startDate={startDate}
              startTime={startTime}
              onDatesChange={getChangedSchedulerValues}
              isCampaignScheduled={isCampaignScheduled}
              isLoading={isLoading}
            />
          </>
        )}
    </>
  )
}
