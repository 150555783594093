import { JSXElementConstructor } from 'react'

import RadioGroup from '../radioGroup'
import Textarea from '../textarea'

export type FieldsConfiguration = FieldConfiguration[]

export type FieldConfiguration =
  | TextType
  | TextareaType
  | NumericType
  | RadioType
  | PercentageType
  | CurrencyType
  | DateType
  | CheckboxType
  | DropdownType
  | DropdownMultiType
  | TaggedInputType
  | TagWidgetType
  | ImageSelector
  | HeaderType
  | ToggleType

export enum FieldType {
  Text = 'text',
  Textarea = 'textarea',
  Numeric = 'numeric',
  Radio = 'radio',
  Percentage = 'percentage',
  Currency = 'currency',
  Date = 'date',
  Checkbox = 'checkbox',
  Dropdown = 'dropdown',
  DropdownMulti = 'dropdown-multi',
  TaggedInput = 'tagged-input',
  TagWidget = 'tag-widget',
  Header = 'header',
  ImageMulti = 'image-multi',
  Toggle = 'toggle',
}

type CommonTypeProps = {
  ordinal: number
  name: string
  label: string
  required?: boolean
  isDisabled?: boolean
  tooltip?: string
  placeholder?: string
  conditions?: { condition: string; fieldName: string }[]
}

type PartialComponentProps<
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>
> = Omit<Partial<React.ComponentProps<T>>, 'value' | 'defaultValue'>

export type HeaderType = CommonTypeProps & {
  type: FieldType.Header
  componentProps?: { style?: React.CSSProperties }
  value?: ConditionalValue<FieldType.Header>
}

export type TextType = CommonTypeProps & {
  type: FieldType.Text
  componentProps?: { style?: React.CSSProperties }
  value?: ConditionalValue<FieldType.Text>
}

export type TextareaType = CommonTypeProps & {
  type: FieldType.Textarea
  componentProps?: PartialComponentProps<typeof Textarea>
  value?: ConditionalValue<FieldType.Textarea>
  min?: number
  max?: number
  minMaxRows?: {
    min?: number
    max?: number
  }
}

export type NumericType = CommonTypeProps & {
  type: FieldType.Numeric
  value?: ConditionalValue<FieldType.Numeric>
}

export type RadioType = Omit<CommonTypeProps, 'placeholder'> & {
  type: FieldType.Radio
  options: Option[]
  conditional_fields?: ConditionalField[]
  componentProps?: PartialComponentProps<typeof RadioGroup> & {
    style?: React.CSSProperties
  }
  value?: ConditionalValue<FieldType.Radio>
}

export type ConditionalField = {
  condition: Option['label']
  fields: string[]
}

export type Option = {
  value: string
  label: string
  default?: boolean
}

export type PercentageType = CommonTypeProps & {
  type: FieldType.Percentage
  value?: ConditionalValue<FieldType.Percentage>
  min?: number
  max?: number
  step?: number
}

export type CurrencyType = CommonTypeProps & {
  type: FieldType.Currency
  value?: ConditionalValue<FieldType.Currency>
}

export type DateType = CommonTypeProps & {
  type: FieldType.Date
  value?: ConditionalValue<FieldType.Date>
}

export type CheckboxType = CommonTypeProps & {
  type: FieldType.Checkbox
  options: Option[]
  min?: number
  max?: number
  value?: ConditionalValue<FieldType.Checkbox>
}

export type DropdownType = CommonTypeProps & {
  type: FieldType.Dropdown
  options: Option[]
  conditional_fields?: ConditionalField[]
  componentProps?: { style?: React.CSSProperties }
  value?: ConditionalValue<FieldType.Dropdown>
}

export type DropdownMultiType = CommonTypeProps & {
  type: FieldType.DropdownMulti
  options: Option[]
  min?: number
  max?: number
  conditional_fields?: ConditionalField[]
  value?: ConditionalValue<FieldType.DropdownMulti>
}

export type TaggedInputType = CommonTypeProps & {
  type: FieldType.TaggedInput
  options: Option[]
  min?: number
  max?: number
  conditional_fields?: ConditionalField[]
  value?: ConditionalValue<FieldType.TaggedInput>
}

export type TagWidgetType = CommonTypeProps & {
  type: FieldType.TagWidget
  min?: number
  max?: number
  options: Option[]
  conditional_fields?: ConditionalField[]
  value?: ConditionalValue<FieldType.TagWidget>
  tag_button_label?: string
  has_tag_button_label?: boolean
  componentProps?: { style?: React.CSSProperties }
}

export type ImageSelector = CommonTypeProps & {
  type: FieldType.ImageMulti
  min?: number
  max?: number
  value?: ConditionalValue<FieldType.ImageMulti>
  componentProps?: { style?: React.CSSProperties }
}

export type ToggleType = CommonTypeProps & {
  type: FieldType.Toggle
  value?: ConditionalValue<FieldType.Toggle>
}

export type ConditionalValue<T extends FieldType> = T extends
  | FieldType.Numeric
  | FieldType.Percentage
  ? number
  : T extends
      | FieldType.DropdownMulti
      | FieldType.TaggedInput
      | FieldType.Checkbox
      | FieldType.TagWidget
      | FieldType.ImageMulti
  ? string[]
  : string

export type CommonFieldPropsGeneric = {
  label: string
  name: string
  isDisabled?: boolean
  updateFieldsState?: any
  errorCode?: string
  tooltip?: string
  placeholder?: string
  onChange: (value?: ConditionalValue<FieldType>) => void
  'data-testid'?: string
}
