import cx from 'classnames'
import Dropdown from 'rc-dropdown'
import Menu, { MenuItem } from 'rc-menu'

import { ChevronDownSquare } from 'common/icons'

type Props = {
  isDisabled: boolean | undefined
  value: string
  onChange: (value: string) => void
  options: { label: string; value: string }[]
}

const StatusSelect = ({ isDisabled, value, onChange, options }: Props) => {
  return (
    <Dropdown
      trigger={['click']}
      animation="slide-up"
      overlay={() => (
        <Menu
          className="z-40 px-4 py-2 bg-gold-50 border border-gold-200"
          selectable={false}
          onClick={({ key }) => onChange(key as string)}
        >
          {options.map(({ label, value }) => {
            return (
              <MenuItem
                key={value}
                className="group flex items-center text-base-700 text-sm font-normal py-2 px-4 -mx-4 cursor-pointer hover:bg-gold-300"
              >
                {label}
              </MenuItem>
            )
          })}
        </Menu>
      )}
    >
      <button
        className={cx(
          'focus:outline-none whitespace-nowrap font-medium w-full h-full capitalize flex justify-between	items-center py-0.5 px-6',
          {
            'text-deepTurquoise bg-pearlGreen group-hover:bg-inherit':
              'live' === value,
            'text-RAGamber bg-softAlmond group-hover:bg-inherit':
              'pending' === value,
            'text-base-300 bg-dustyPearl group-hover:bg-inherit':
              'dropped' === value,
            'text-maroon-300 bg-ivoryBlush group-hover:bg-inherit':
              'approved' === value,
            'cursor-not-allowed': isDisabled,
          }
        )}
        disabled={isDisabled}
      >
        {value}
        <ChevronDownSquare isDefaultColor={false} size={5} />
      </button>
    </Dropdown>
  )
}

export default StatusSelect
