import React from 'react'
import cx from 'classnames'

export interface Props {
  text: string
  className?: string
  disabled?: boolean
  style?: React.CSSProperties
}

const GuidingText = ({ className = 'mt-2', text, style, disabled }: Props) => (
  <div
    className={cx(
      'text-gold-700 text-xs font-normal leading-normal',
      {
        'opacity-50': disabled,
      },
      className
    )}
    style={style}
  >
    {text}
  </div>
)

export default GuidingText
