import cx from 'classnames'

interface Props {
  description: string
  className?: string
}

const PageSubTitle = ({ description, className }: Props) => {
  return (
    <p className={cx('text-base-700 font-normal leading-5 pt-2.5', className)}>
      {description}
    </p>
  )
}

export default PageSubTitle
