import type { Option } from 'common/components/tags/Tags'

import ContentTags from '../ContentTags'

type Props = {
  title: string
  tags: Option<string>[]
  suggestions: string[]
  onAddClick: (value: string) => void
  onRemoveClick: (value: string) => void
  ['data-cy']: string
  'data-testid': string
  hasTagButtonLabel?: boolean
  tagButtonLabel?: string
}

const PunctuationTag = ({
  title,
  tags,
  suggestions,
  onAddClick,
  onRemoveClick,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  hasTagButtonLabel,
  tagButtonLabel,
}: Props) => {
  return (
    <div>
      <div className="text-coolGray-800 mb-2 font-medium">{title}</div>
      <ContentTags
        isFreeText={false}
        data-cy={dataCy}
        data-testid={dataTestId}
        tags={tags}
        suggestions={suggestions}
        onAddClick={onAddClick}
        onRemoveClick={onRemoveClick}
        hasTagButtonLabel={hasTagButtonLabel}
        tagButtonLabel={tagButtonLabel}
      />
    </div>
  )
}

export default PunctuationTag
