import { useMemo } from 'react'

import useGetBrandVoicesQuery from 'common/api/queries/useGetBrandVoicesQuery'
import { Region } from 'features/unifiedFlow/api'
import useGetCustomerJourneyQuery from 'features/unifiedFlow/api/queries/useGetCustomerJourneyQuery'
import useGetPrivacyRegionsQuery from 'features/unifiedFlow/api/queries/useGetPrivacyRegionsQuery'
import useGetProductCatalogsQuery from 'features/unifiedFlow/api/queries/useGetProductCatalogsQuery'
import useGetProfileAttributesQuery from 'features/unifiedFlow/api/queries/useGetProfileAttributeCatalogsQuery'

const useLoadPersonalizationSelectors = ({
  privacyRegion,
}: {
  privacyRegion: Region | undefined
}) => {
  const { data: brandVoicesData, ...brandVoicesQuery } =
    useGetBrandVoicesQuery()
  const { data: privacyRegions, ...privacyRegionsQuery } =
    useGetPrivacyRegionsQuery()
  const { data: productCatalogs, ...productCatalogsQuery } =
    useGetProductCatalogsQuery({
      region: privacyRegion,
    })
  const { data: profileAttributeCatalogs, ...profileAttributeCatalogsQuery } =
    useGetProfileAttributesQuery({
      region: privacyRegion,
    })

  const { data: customerJourneys, ...customerJourneyQuery } =
    useGetCustomerJourneyQuery()

  const brandVoices = useMemo(
    () =>
      brandVoicesData?.map((brandVoice) => ({
        label: brandVoice.name,
        value: brandVoice.id,
      })),
    [brandVoicesData]
  )

  return {
    brandVoices,
    brandVoicesQuery,
    privacyRegions,
    privacyRegionsQuery,
    productCatalogs,
    productCatalogsQuery,
    profileAttributeCatalogs,
    profileAttributeCatalogsQuery,
    customerJourneys,
    customerJourneyQuery,
  }
}

export default useLoadPersonalizationSelectors
