import Footer from 'common/components/layout/Footer'

const EmptyState = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-full bg-gold-40">
      <h2 className="text-xl font-medium mb-4">Welcome to personalisation</h2>
      <p className="text-base text-coolGray-400 mb-8">
        No personalisation available
      </p>
      <Footer />
    </div>
  )
}

export default EmptyState
