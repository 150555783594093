import { ReactNode, useEffect, useRef, useState } from 'react'
import isString from 'lodash/isString'

import { ChevronDown, ChevronUp } from 'common/icons'

type Props = {
  title?: string | ReactNode
  children: ReactNode
  className?: string
  isOpen?: boolean
}
const AccordionItem = ({
  title,
  className,
  isOpen: isOpenProp = false,
  children,
}: Props) => {
  const [isOpen, setIsOpen] = useState(isOpenProp)
  const contentRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState(0)

  useEffect(() => {
    if (!contentRef.current) {
      return
    }
    const resizeObserver = new ResizeObserver((entries) => {
      setContentHeight(entries[0]?.target?.scrollHeight)
    })
    const contentEl = contentRef.current
    resizeObserver.observe(contentEl)
    return () => {
      resizeObserver.unobserve(contentEl)
    }
  }, [isOpen])

  useEffect(() => {
    setIsOpen(isOpenProp)
  }, [isOpenProp])

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        <button
          className="px-4 py-2 flex justify-between items-center cursor-pointer w-full bg-gold-100"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isString(title) ? (
            <h3 className="text-base-700 text-md font-medium uppercase">
              {title}
            </h3>
          ) : (
            title
          )}
          <span className="flex items-center underline uppercase text-xs font-medium text-gold-500 gap-x-2">
            {isOpen ? 'Hide' : 'Expand'}
            {isOpen ? (
              <ChevronUp
                className="w-4 h-4 text-gold-500"
                isDefaultColor={false}
              />
            ) : (
              <ChevronDown
                className="w-4 h-4 text-gold-500"
                isDefaultColor={false}
              />
            )}
          </span>
        </button>
      </div>

      <div
        className="transition-height duration-200 ease-in-out overflow-hidden"
        style={{
          height: isOpen ? `${contentHeight}px` : '0px',
        }}
      >
        <div ref={contentRef} className="bg-transparent pt-6">
          {children}
        </div>
      </div>
    </div>
  )
}

export default AccordionItem
