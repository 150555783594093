import RightPanelHeading from 'features/unifiedFlow/contentPage/components/layout/RightPanelHeading'

import InsightsCard from './InsightsCard'
import InsightsCategories from './InsightsCategories'

const Insights = () => {
  return (
    <div className="p-6 text-coolGray-800">
      <RightPanelHeading
        data-testid="insightsHeader"
        text="Insights"
        className="mb-12"
      />
      <InsightsCard
        title="Jacquard generation"
        hasTitleIcons
        content={
          <>
            {`Hundreds of variants are generated from your brief. 
            Those that aren’t brand compliant are removed and the Performance Prediction Engine selects the final set, 
            maximising performance and exploration.`}
          </>
        }
      />
      <hr className="my-2 border-gold-400" />
      <InsightsCard
        title="Experiment design"
        hasTitleIcons
        content={
          <>
            Any changes you make to the selected variants will have an effect on
            the result of the experiment.
          </>
        }
      />
      <InsightsCategories />
    </div>
  )
}

export default Insights
