import { useCallback, useMemo, useState } from 'react'
import { IntegrationAccount } from '@phrasee/phrasee-typings'

import ActionIcon from 'common/components/ActionIcon'
import Button from 'common/components/button'
import SortMenu from 'common/components/sortMenu'
import {
  sortByOptions,
  SortOptions,
  SortOptionsValue,
} from 'common/components/sortMenu/SortMenu'
import Tooltip from 'common/components/Tooltip'
import { useLocalStorage } from 'common/hooks/useLocalStorage'
import { Grid, ListIcon } from 'common/icons'
import { Add } from 'common/icons'

import AccountsGrid from './AccountsGrid'
import AccountsList from './AccountsList'
import IntegrationsDeleteModal from './IntegrationsDeleteModal'
import IntegrationsSearchbox from './IntegrationSearchbox'
import IntegrationsUpdateModal from './IntegrationsUpdateModal'

const ACCOUNTS_PAGE_VIEW_KEY = `accounts-page-view`

const sortOptionsAccount: SortOptions = [
  {
    label: 'Recent',
    value: {
      property: 'createdAt',
      isAscending: false,
    },
  },
  {
    label: 'Oldest',
    value: {
      property: 'createdAt',
      isAscending: true,
    },
  },
  {
    label: 'Ascending',
    value: { property: 'provider', isAscending: true },
  },
  {
    label: 'Descending',
    value: { property: 'provider', isAscending: false },
  },
]

type Props = {
  accounts: IntegrationAccount[]
  onAddClick: () => void
}

const AccountsPage = ({ accounts, onAddClick }: Props) => {
  const [integrationAccountToDelete, setIntegrationAccountToDelete] =
    useState<IntegrationAccount | null>(null)
  const [integrationAccountToUpdate, setIntegrationAccountToUpdate] =
    useState<IntegrationAccount | null>(null)

  const [isTableView, setIsTableView] = useLocalStorage(
    ACCOUNTS_PAGE_VIEW_KEY,
    false
  )

  const [sortOptions, setSortOptions] = useState<SortOptionsValue>({
    property: 'createdAt',
    isAscending: false,
  })

  const [searchValue, setSearchValue] = useState<string>('')

  const filterByName = useCallback(
    ({ provider }: IntegrationAccount) =>
      provider
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(searchValue.replaceAll(' ', '').toLowerCase()),
    [searchValue]
  )

  const filteredAccounts = useMemo(() => {
    const filtredData = (accounts ?? []).filter(filterByName)
    const sortedData = sortByOptions(filtredData, sortOptions)

    return sortedData
  }, [accounts, filterByName, sortOptions])

  return (
    <>
      <div className="flex items-center my-8 self-end">
        <ActionIcon
          aria-label="Toggle view button"
          onClick={() => setIsTableView(!isTableView)}
        >
          {isTableView ? (
            <Tooltip overlay="Tile view">
              <Grid />
            </Tooltip>
          ) : (
            <Tooltip overlay="List view">
              <ListIcon />
            </Tooltip>
          )}
        </ActionIcon>
        <Tooltip overlay="Sort">
          <SortMenu
            className="text-base pt-3"
            sortOptions={sortOptionsAccount}
            onSortClick={setSortOptions}
          />
        </Tooltip>
        <Button
          variant="primary"
          prefixIcon={<Add isDefaultColor={false} />}
          onClick={onAddClick}
        >
          Integration
        </Button>
        <IntegrationsSearchbox
          placeholder="Find integration"
          data-cy="integrations-search-box"
          data-testid="integrations-search-box"
          onSearch={setSearchValue}
          options={accounts?.map((account) => ({
            value: account.id,
            label: account.provider,
          }))}
        />
      </div>
      {isTableView ? (
        <AccountsList
          accounts={filteredAccounts}
          onUpdate={(integrationAccount) =>
            setIntegrationAccountToUpdate(integrationAccount)
          }
          onDelete={(integrationAccount) =>
            setIntegrationAccountToDelete(integrationAccount)
          }
        />
      ) : (
        <AccountsGrid
          onUpdate={(integrationAccount) =>
            setIntegrationAccountToUpdate(integrationAccount)
          }
          onDelete={(integrationAccount) =>
            setIntegrationAccountToDelete(integrationAccount)
          }
          accounts={filteredAccounts}
        />
      )}
      {!!integrationAccountToUpdate && (
        <IntegrationsUpdateModal
          accountId={integrationAccountToUpdate.accountId}
          integrationId={integrationAccountToUpdate.id}
          label={integrationAccountToUpdate.label ?? ''}
          accountName={integrationAccountToUpdate.accountName}
          logoUrl={integrationAccountToUpdate.logoUrl}
          onCancel={() => {
            setIntegrationAccountToUpdate(null)
          }}
          onClose={() => {
            setIntegrationAccountToUpdate(null)
          }}
        />
      )}
      {!!integrationAccountToDelete && (
        <IntegrationsDeleteModal
          accountId={integrationAccountToDelete.accountId}
          integrationId={integrationAccountToDelete.id}
          onSuccessDelete={() => {
            setIntegrationAccountToDelete(null)
          }}
          onCancel={() => {
            setIntegrationAccountToDelete(null)
          }}
        />
      )}
    </>
  )
}

export default AccountsPage
