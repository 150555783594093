import { Region } from './api'

export const contentKeys = {
  all: ['unified-flow'] as const,
  contents: () => [...contentKeys.all, 'contents'] as const,
  content: (id: string) => [...contentKeys.contents(), id] as const,
  variableCombinations: ({
    id,
    region,
    accountId,
  }: {
    id: string
    region: string
    accountId: string
  }) =>
    [
      ...contentKeys.all,
      'variableCombinations',
      id,
      region,
      accountId,
    ] as const,
  templates: (accountId: string) =>
    [...contentKeys.all, 'template', accountId] as const,
  projectConfiguration: (
    projectId: string,
    isImageOptimizationEnabled?: boolean
  ) =>
    [
      ...contentKeys.all,
      'projectConfiguration',
      projectId,
      { isImageOptimizationEnabled },
    ] as const,
  campaigns: () => [...contentKeys.all, 'campaigns'] as const,
  campaign: (id: string) => [...contentKeys.campaigns(), id] as const,
  contentLibrary: (accountId: string) =>
    [...contentKeys.all, 'contentLibrary', accountId] as const,
  brandVoices: (accountId: string) =>
    [...contentKeys.all, 'brandVoices', accountId] as const,
  regions: ({ userId }: { userId: string }) =>
    [...contentKeys.all, 'privacyRegions', userId] as const,
  productCatalogs: ({
    accountId,
    region,
  }: {
    accountId: string
    region: Region | undefined
  }) => [...contentKeys.all, 'productCatalogs', accountId, region] as const,
  profileAttributeCatalogs: ({
    accountId,
    region,
  }: {
    accountId: string
    region: Region | undefined
  }) =>
    [
      ...contentKeys.all,
      'profileAttributeCatalogs',
      accountId,
      region,
    ] as const,
  attributeCatalogTags: ({
    accountId,
    attributeCatalogId,
    region,
  }: {
    accountId: string
    attributeCatalogId: string
    region: Region | undefined
  }) =>
    [
      ...contentKeys.all,
      'attributeCatalogTags',
      accountId,
      attributeCatalogId,
      region,
    ] as const,
  customerJourneys: (accountId: string) =>
    [...contentKeys.all, 'customerJourneys', accountId] as const,
  experimentName: (
    accountId: string,
    projectId: string,
    experimentName: string
  ) =>
    [
      ...contentKeys.all,
      'experimentName',
      accountId,
      projectId,
      experimentName,
    ] as const,
}
