import { Menu } from '@szhsin/react-menu'
import { useQueryClient } from '@tanstack/react-query'

import Button from 'common/components/button'
import { MenuItem } from 'common/components/menuButton'
import { ChevronDown } from 'common/icons'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'
import { Phrase } from 'features/content/api/interfaces'
import { contentKeys } from 'features/content/api/queryKeys'

type Props = {
  selectedNodeId: string
  editableCellFocus: () => void
  showAddModal: () => void
}

const AddPhraseButton = ({
  selectedNodeId,
  editableCellFocus,
  showAddModal,
}: Props) => {
  const queryClient = useQueryClient()

  return (
    <>
      <Button
        className="mr-4"
        variant="primary"
        data-cy="add-phrase-button"
        data-testid="add-phrase-button"
        prefixIcon={<AddIcon width={24} height={24} />}
        onClick={() => {
          queryClient.setQueryData<Phrase[]>(
            contentKeys.phrases(selectedNodeId),
            (old) => {
              return [
                {
                  phraseId: 0,
                  nodeId: Number(selectedNodeId),
                  phrase: '',
                  frequency: '1.0',
                  startDate: '',
                  endDate: '',
                  dateRange: '',
                },
                ...(old || []),
              ]
            }
          )
          editableCellFocus()
        }}
      >
        Phrase
        <Menu
          align="end"
          offsetX={16}
          offsetY={12}
          menuButton={
            <button
              className="outline-none"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <ChevronDown isDefaultColor={false} className="ml-2" size={4} />
            </button>
          }
        >
          <MenuItem onClick={showAddModal}>Add multiple phrases</MenuItem>
        </Menu>
      </Button>
    </>
  )
}

export default AddPhraseButton
