import cx from 'classnames'
import helpers from 'workflow/utils/helpers'

import { Team } from 'common/api/teamsApi'
import Button from 'common/components/button/Button'
import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import Label from 'common/components/label'
import Scrollable from 'common/components/scrollable'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'

import Email from '../../Email'

import { Footer } from './Footer'
import Header from './Header'

type Props = {
  isEditMode: boolean
  selectedTeam: Team | undefined
  teamName: string
  onTeamNameChange: (value: string) => void
  teams: Team[]
  emails: string[]
  updateEmails: (value: string[]) => void
  onClickSetMultipleUser: () => void
  onClickCancel: () => void
  onSubmit: () => void
}

export function AddTeamFormContent({
  isEditMode,
  selectedTeam,
  teamName,
  onTeamNameChange,
  teams,
  emails,
  updateEmails,
  onClickSetMultipleUser,
  onSubmit,
  onClickCancel,
}: Props) {
  const nonEmptyEmails = emails.filter((email) => email.length > 0)
  const areValidEmails =
    nonEmptyEmails.length > 0 && nonEmptyEmails.every(helpers.isValidEmail)

  const isNameAlreadyUsed =
    selectedTeam?.name.toLocaleLowerCase().trim() ===
    teamName.toLocaleLowerCase().trim()
      ? false
      : teams.some(
          (team) =>
            team.name.trim().toLowerCase() ===
            teamName.trim().toLocaleLowerCase()
        )
  const isValidTeamName = teamName.length > 0
  const canSubmitForm = isEditMode
    ? areValidEmails
    : isValidTeamName && !isNameAlreadyUsed && areValidEmails

  return (
    <div className="w-123 flex pt-6 pb-6">
      <form
        onSubmit={(event) => {
          event.preventDefault()
          onSubmit()
        }}
        className="flex flex-col flex-1"
      >
        <Scrollable
          header={
            <Header
              isEditMode={isEditMode}
              teamName={selectedTeam?.name ?? '-'}
            />
          }
          contentClassName="pl-6 mt-4 text-sm text-coolGray-500 grid flex-0 pr-6 max-h-149"
          content={
            <>
              {!isEditMode ? (
                <div className="mb-6">
                  <FormItem
                    label="Team name"
                    htmlFor="teamName"
                    error={
                      isNameAlreadyUsed
                        ? 'This team name already exists!'
                        : undefined
                    }
                  >
                    <Input
                      variant="default"
                      type="text"
                      id="teamName"
                      name="teamName"
                      onChange={(event) => {
                        onTeamNameChange(event.target.value)
                      }}
                      value={teamName}
                      placeholder="Be creative, think big ;- }"
                      hasError={isNameAlreadyUsed}
                      isValid={isValidTeamName}
                    />
                  </FormItem>
                </div>
              ) : null}
              <fieldset>
                {!isEditMode && (
                  <legend className="text-base font-bold text-coolGray-800">
                    Add users
                  </legend>
                )}
                <div className="">
                  <Label>Email address</Label>
                </div>
                <div className="mt-6">
                  {emails.map((email, index) => (
                    <Email
                      key={index}
                      onChange={(event) => {
                        const updatedEmails = [...emails]
                        updatedEmails[index] = event.target.value
                        updateEmails(updatedEmails)
                      }}
                      onRemove={() => {
                        const updatedEmails = [...emails]
                        updatedEmails.splice(index, 1)
                        updateEmails(updatedEmails)
                      }}
                      value={email}
                    />
                  ))}
                </div>
              </fieldset>
              <div
                className={cx('flex justify-start text-base items-center pb-6')}
              >
                <Button
                  variant="primary"
                  className="p-0"
                  onClick={() => {
                    updateEmails([...emails, ''])
                  }}
                  data-cy="add-user"
                  data-testid="add-user"
                  prefixIcon={<AddIcon width={24} height={24} />}
                  disabled={
                    emails.length >= 10 ||
                    !isValidTeamName ||
                    isNameAlreadyUsed ||
                    (emails.length > 0 &&
                      emails.some((email) => !helpers.isValidEmail(email)))
                  }
                >
                  User
                </Button>
                <span className="ml-3">or </span>
                <Button
                  variant="link"
                  className="text-sm pl-2"
                  onClick={() => onClickSetMultipleUser()}
                >
                  Add multiple users at once
                </Button>
              </div>
            </>
          }
          footer={
            <Footer
              onClickCancel={() => {
                onClickCancel()
              }}
              canSubmitForm={canSubmitForm}
              isEditMode={isEditMode}
            />
          }
        />
      </form>
    </div>
  )
}

export default AddTeamFormContent
