import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router'
import { styleGuidePath, toneOfVoicePath } from 'app/navigation/paths'
import RouterPromptStay from 'app/router/RouterPromptStay'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { showErrorToast } from 'common/api/helpers'
import useRegionsListQuery from 'common/api/queries/useRegionsListQuery'
import BottomToolbar from 'common/bottomToolbar'
import Button from 'common/components/button'
import CheckboxComponent from 'common/components/checkbox'
import { RegionChangeConfirmationModal } from 'common/components/confirmationModal'
import ErrorPage from 'common/components/error/ErrorPage'
import Input from 'common/components/input'
import Label from 'common/components/label'
import Footer from 'common/components/layout/Footer'
import Loader from 'common/components/loaders/Loader'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import Scrollable from 'common/components/scrollable'
import SingleSelect from 'common/components/singleSelect'
import Tooltip from 'common/components/Tooltip'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import DeleteConfirmationModal from '../brandVoice/components/DeleteConfirmationModal'
import DeletingDefaultBrandVoiceAlert from '../brandVoice/components/DeletingDefaultBrandVoiceAlert'
import DeletionWarningWithRelatedProjectsModal from '../brandVoice/components/DeletionWarningWithRelatedProjectsModal'

import AdvancedLanguageRules from './components/AdvancedLanguageRules'
import AlternativeWordsWidget from './components/AlternativeWordsWidget'
import EmojisWidget from './components/EmojisWidget'
import EmojiUseWidget from './components/EmojiUseWidget'
import IndustryWidget from './components/IndustryWidget'
import NeverGenerateWidget from './components/NeverGenerateWidget'
import PunctuationWidget from './components/PunctuationWidget'
import StylisticsWidget from './components/StylisticsWidget'
import {
  changeDefaultRegion,
  initializePage,
  resetContent,
  saveContent,
  saveRegion,
  selectHasBeenEdited,
  selectIsDefaultRegionSelected,
  selectIsOriginalDefaultRegionSelected,
  updateBrandVoiceName,
} from './store/styleGuideSlice'

type RegionChangeConfirmationModalState = {
  isOpen: boolean
  selectedValue: string | undefined
}

const StyleGuide = () => {
  useDocumentTitle('Style Guide Setup | Jacquard')

  const isLoading = useAppSelector((state) => state.styleGuide.isLoading)
  const regionIdSelected = useAppSelector((state) => state.styleGuide.regionId)
  const brandVoiceName = useAppSelector(
    (state) => state.styleGuide.editedContent.brandVoiceName
  )

  const brandVoices =
    useAppSelector((state) => state.styleGuide.brandVoices) ?? []
  const [regionChangeConfirmationModal, setRegionChangeConfirmationModal] =
    useState<RegionChangeConfirmationModalState>({
      isOpen: false,
      selectedValue: undefined,
    })
  const dispatch = useAppDispatch()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const flags = useFlags()
  const {
    breakEmojiInheritance: isEmojiInheritanceDisabled,
    showBrandIndustry: isBrandIndustryVisible,
    showAccountRules,
  } = flags

  const match = useRouteMatch<{
    accountId: string
    brandVoiceId: string
  }>({
    path: [styleGuidePath, toneOfVoicePath],
  })

  const currentBrandVoiceId = match?.params.brandVoiceId

  const hasBeenEdited = useAppSelector(selectHasBeenEdited)
  const isDefaultRegionSelected = useAppSelector(selectIsDefaultRegionSelected)
  const isOriginalDefaultRegionSelected = useAppSelector(
    selectIsOriginalDefaultRegionSelected
  )

  const isSaving = useAppSelector((state) => state.styleGuide.isSaving)
  const hasError = useAppSelector((state) => state.styleGuide.hasError)
  const isSavingRegion = false

  const { data: regionsData, status: regionStatus } = useRegionsListQuery()
  const regionOptions = regionsData?.map(({ id, name }) => ({
    value: id,
    label: name,
  }))

  useEffect(() => {
    dispatch(
      initializePage({
        isBrandTonePageVisible: flags.showBrandTonePage ?? false,
        selectedBrandVoiceId: currentBrandVoiceId,
        isEmojiInheritanceDisabled,
        isBrandIndustryVisible,
      })
    )
  }, [
    accountId,
    currentBrandVoiceId,
    dispatch,
    flags.showBrandTonePage,
    isEmojiInheritanceDisabled,
    isBrandIndustryVisible,
  ])

  if (hasError || regionStatus === 'error') {
    return <ErrorPage />
  }

  return (
    <>
      <>
        <Scrollable
          isFullHeight={false}
          content={
            <PageContainer className="max-w-318 sm:mx-auto px-6 pt-12 lg:pt-8">
              <Loader isLoading={regionStatus === 'loading' || isLoading}>
                <PageHeader>
                  <div data-cy="header" data-testid="header">
                    <PageTitle title="Style Guide Setup" />
                    <div className="text-coolGray-400">
                      Configure the below settings to let Jacquard know what we
                      should or should not use in the generation of your
                      content.
                    </div>
                  </div>
                </PageHeader>
                <Label className="mt-6">Brand voice name</Label>
                <div className="mt-2">
                  <Input
                    className="flex-none w-full sm:w-80"
                    data-cy="brand-voice-name-input"
                    data-testid="brand-voice-name-input"
                    type="text"
                    variant="default"
                    id="brand-voice-name"
                    name="brand-voice-name"
                    value={brandVoiceName}
                    onChange={(event) => {
                      const val = event.target.value
                      dispatch(updateBrandVoiceName(val))
                    }}
                  />
                  {brandVoiceName?.trim() === '' && (
                    <p className="mt-2 text-red-400 capitalize-first">
                      Brand voice name is not valid
                    </p>
                  )}
                </div>
                <Label className="mt-6">Region</Label>
                <div className="text-coolGray-400">
                  Select a region below. This will set certain default settings
                  in your content generations.
                  <br />
                  Only a single region can be selected, but we are working on
                  enabling the selection of multiple regions within a single
                  account in the future.
                </div>
                <div className="mt-2">
                  <SingleSelect
                    className="flex-none w-full sm:w-80"
                    data-cy="region-select"
                    data-testid="region-select"
                    name="region"
                    placeholder="Select your region"
                    onChange={(value) => {
                      if (value?.value && !regionIdSelected) {
                        dispatch(
                          saveRegion({
                            accountId,
                            regionId: value.value,
                            isEmojiInheritanceDisabled,
                          })
                        )
                      } else {
                        if (
                          regionIdSelected &&
                          value?.value &&
                          value.value !== regionIdSelected
                        ) {
                          setRegionChangeConfirmationModal({
                            isOpen: true,
                            selectedValue: value.value,
                          })
                        }
                      }
                    }}
                    isLoading={isSavingRegion}
                    value={
                      regionChangeConfirmationModal.selectedValue ??
                      regionIdSelected
                    }
                    options={regionOptions}
                    menuPortalTarget={document.body}
                  />
                </div>
                {regionIdSelected && (
                  <>
                    {flags.showBrandTonePage && (
                      <>
                        <div className="w-auto inline-block">
                          <Tooltip
                            overlay={
                              brandVoices.length > 1
                                ? 'To change your default selection change the status within a different brand voice.'
                                : 'Default brand voice must be selected. Add more to change your default.'
                            }
                            show={isOriginalDefaultRegionSelected}
                          >
                            <CheckboxComponent
                              className="mt-8 text-coolGray-800"
                              label="Mark as default"
                              isChecked={isDefaultRegionSelected}
                              onChange={(value) =>
                                dispatch(changeDefaultRegion(value))
                              }
                              isDisabled={
                                isOriginalDefaultRegionSelected ||
                                regionOptions === undefined ||
                                regionOptions.length < 2
                              }
                            />
                          </Tooltip>
                          <DeletingDefaultBrandVoiceAlert />
                        </div>
                        <div className="text-coolGray-400 mt-2">
                          Your default voice will be automatically selected when
                          generating content. This should be the Brand Voice you
                          use most often.
                        </div>
                      </>
                    )}
                    {isBrandIndustryVisible && (
                      <IndustryWidget className="mt-6" />
                    )}
                    <PunctuationWidget className="mt-6" />
                    <StylisticsWidget className="mt-6" />
                    <AlternativeWordsWidget className="mt-6" />
                    <NeverGenerateWidget className="mt-6" />
                    <EmojiUseWidget className="mt-6" />
                    {isEmojiInheritanceDisabled && (
                      <EmojisWidget className="mt-6" />
                    )}
                    {showAccountRules && <AdvancedLanguageRules />}
                    <Footer />
                  </>
                )}
              </Loader>
            </PageContainer>
          }
          footer={
            hasBeenEdited && regionIdSelected ? (
              <BottomToolbar className="justify-center">
                <div className="max-w-318 xl:max-w-363 w-full flex justify-between">
                  <Button
                    data-cy="cancel-button"
                    data-testid="cancel-button"
                    variant="danger"
                    className="mr-4 pointer-events-auto"
                    ghost
                    disabled={isSaving}
                    onClick={() => dispatch(resetContent())}
                  >
                    Reset
                  </Button>
                  <Button
                    data-cy="save-button"
                    data-testid="save-button"
                    variant="success"
                    ghost
                    loading={isSaving}
                    disabled={isSaving}
                    onClick={() =>
                      dispatch(saveContent({ accountId }))
                        .unwrap()
                        .catch((e) => {
                          showErrorToast(e)
                        })
                    }
                  >
                    Save
                  </Button>
                </div>
              </BottomToolbar>
            ) : undefined
          }
        />
      </>
      {regionChangeConfirmationModal.isOpen && (
        <RegionChangeConfirmationModal
          onConfirm={() => {
            if (regionChangeConfirmationModal.selectedValue) {
              setRegionChangeConfirmationModal({
                isOpen: false,
                selectedValue: regionChangeConfirmationModal.selectedValue,
              })
              dispatch(
                saveRegion({
                  accountId,
                  regionId: regionChangeConfirmationModal.selectedValue,
                  isEmojiInheritanceDisabled,
                })
              )
            }
          }}
          onCancel={() => {
            setRegionChangeConfirmationModal({
              isOpen: false,
              selectedValue: regionIdSelected,
            })
          }}
        />
      )}
      <RouterPromptStay
        shouldShow={hasBeenEdited}
        isSaving={false}
        onOK={() => {
          dispatch(resetContent())
          return Promise.resolve(true)
        }}
        confirmationText="You have unsaved changes, are you sure you want to leave without saving? All unsaved settings will be lost."
      />
      <DeleteConfirmationModal />
      <DeletionWarningWithRelatedProjectsModal />
    </>
  )
}

export default StyleGuide
