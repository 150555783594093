import { useState } from 'react'
import filter from 'lodash/filter'
import find from 'lodash/find'

import type { ImageEntity } from 'common/components/images/api/api'

export type SelectedImage = Pick<ImageEntity, 'id' | 'fileName' | 'name'>

const useImageActions = () => {
  const [selectedImages, setImages] = useState<SelectedImage[]>([])
  const [isArchiveModalVisible, setIsArchiveModalVisible] =
    useState<boolean>(false)

  const onSelectImage = (selectedImage: SelectedImage) => {
    setImages((state) => {
      const isAlreadySelected = find(state, { id: selectedImage.id })

      return isAlreadySelected
        ? filter(state, ({ id }) => id !== selectedImage.id)
        : [...state, selectedImage]
    })

    return selectedImage
  }

  const onDeleteImage = (selectedImage: SelectedImage) => {
    setImages([selectedImage])
    setIsArchiveModalVisible(true)
  }

  const onClearImages = () => {
    setImages([])
    setIsArchiveModalVisible(false)
  }

  return {
    isArchiveModalVisible,
    selectedImages,
    setIsArchiveModalVisible,
    onSelectImage,
    onDeleteImage,
    onClearImages,
  }
}

export default useImageActions
