import cx from 'classnames'

import { IconType } from 'common/icons/Icon'

type Props = {
  title: string
  icon: IconType
  isSelected: boolean
  className?: string
  'data-testid'?: string
}

const Item = ({ title, icon: Icon, 'data-testid': dataTestId }: Props) => {
  return (
    <div
      data-testid={dataTestId}
      data-cy={`${title.toLocaleLowerCase()}-variant-button`}
      className={cx(`flex items-center justify-between w-full`)}
    >
      <div className="text-base-700">{title}</div>
      <div className="ml-auto">
        <span className="group-hover:hidden">
          <Icon
            isDefaultColor
            className="text-base-700 group-hover:text-gold-500 hover:text-gold-500"
            size={6}
          />
        </span>
        <span className="hidden group-hover:inline-block">
          <Icon
            isDefaultColor
            className="text-base-700 group-hover:text-gold-500 hover:text-gold-500"
            size={6}
          />
        </span>
      </div>
    </div>
  )
}

export default Item
