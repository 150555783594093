import Button from 'common/components/button'
import Modal from 'common/components/Modal'

type Props = {
  isOpen: boolean
  variantsLength: number
  onClickCancel: () => void
  onClickApprove: () => void
  isLoading: boolean
}

const ApproveVariantsConfirmationModal = ({
  isOpen,
  variantsLength,
  onClickCancel,
  onClickApprove,
  isLoading,
}: Props) => {
  return (
    <Modal visible={isOpen} centered closable={false} className="w-125">
      <div className="flex flex-col gap-2">
        <h4 className="text-2xl font-medium">
          Are you sure you want to approve {variantsLength} selected{' '}
          {variantsLength === 1 ? 'variant' : 'variants'}?
        </h4>
        <p className="text-base">
          This action will approve all variants in the table and will be pushed
          to live.
        </p>
      </div>
      <div className="flex justify-end gap-4 mt-6">
        <Button
          data-cy="approve-modal-cancel-button"
          data-testid="approve-modal-cancel-button"
          variant="link"
          disabled={isLoading}
          onClick={() => onClickCancel()}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          data-cy="approve-modal-approve-button"
          data-testid="approve-modal-approve-button"
          loading={isLoading}
          onClick={() => onClickApprove()}
        >
          Approve all variants
        </Button>
      </div>
    </Modal>
  )
}

export default ApproveVariantsConfirmationModal
