import FormItem from 'common/components/formItem'
import TextareaComponent from 'common/components/textarea'

import { CommonFieldPropsGeneric, TextareaType } from '../interfaces'

type Props = CommonFieldPropsGeneric & {
  value: TextareaType['value']
  max: TextareaType['max']
  min: TextareaType['min']
  componentProps?: TextareaType['componentProps']
}

const Textarea = ({
  max,
  min,
  label,
  name,
  value,
  errorCode,
  tooltip,
  isDisabled,
  placeholder,
  componentProps,
  onChange,
  'data-testid': dataTestId,
  ...rest
}: Props) => {
  const ERROR_MESSAGES = {
    required: 'Please enter an answer.',
    min: `Please type at least ${min} character${min === 1 ? '' : 's'}.`,
  }

  return (
    <FormItem
      label={label}
      guidingText={tooltip}
      htmlFor={name}
      error={errorCode ? ERROR_MESSAGES[errorCode] : undefined}
    >
      <TextareaComponent
        id={name}
        name={name}
        data-testid={dataTestId}
        placeholder={placeholder}
        disabled={isDisabled}
        onChange={(event) => onChange(event.target.value)}
        value={value}
        maxLength={max}
        {...componentProps}
        {...rest}
      />
    </FormItem>
  )
}

export default Textarea
