import { useMemo, useState } from 'react'
import { isEqual } from 'lodash'

import Button from 'common/components/button'
import ConfirmationModal from 'common/components/confirmationModal'
import SingleSelectTag from 'common/components/singleSelect/SingleSelectTag'
import { CellProps, Column, OnCellChange } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import Tags from 'common/components/tags'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useFocusEditableCell } from 'common/hooks/table'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'
import {
  EMPTY_OPTION,
  PART_OF_SPEACH_OPTIONS,
  splitSentenceIntoPartOfSpeach,
} from 'common/partOfSpeach'

import {
  addSynonymRow,
  deleteSynonymRow,
  updateSynonymCell,
} from '../store/contentSettingsSlice'

type Row = {
  id: number
  headToken: string
  partOfSpeech: { label: string; value: string }[]
  isInherited: boolean
  output: string[] | { value: string; label: string; isDisabled: boolean }[]
}

const SynonymsWidget = () => {
  const synonyms = useAppSelector(
    (state) => state.contentSettings.editedContent.synonyms
  )
  const dispatch = useAppDispatch()
  const {
    TextEditableCellWithFocus,
    editableCellFocus,
    editableCellLoseFocus,
  } = useFocusEditableCell()

  const [rowIdToDelete, setRowIdToDelete] =
    useState<number | undefined>(undefined)
  const [resetFilterKey, setResetFilterKey] = useState(0)

  const columns: Column<Row>[] = useMemo(
    () => [
      {
        Header: 'Head token',
        accessor: 'headToken',
        disableSortBy: true,
        Cell: (props: CellProps<Row> & { onCellChange: OnCellChange<Row> }) => (
          <TextEditableCellWithFocus
            {...props}
            onCellChange={({ rowIndex, columnId, value, rowOriginal }) => {
              const partsOfSpeach = splitSentenceIntoPartOfSpeach(
                value as string
              ).map((pos) => ({
                label: pos,
                value: undefined,
              }))

              if (
                !isEqual(
                  partsOfSpeach.map(({ label }) => label),
                  rowOriginal?.['partOfSpeech']?.map(({ label }) => label)
                )
              ) {
                dispatch(
                  updateSynonymCell({
                    value: partsOfSpeach.map(({ label }) => ({
                      label,
                      value: EMPTY_OPTION.value,
                    })),
                    rowIndex: rowIndex,
                    columnId: 'partOfSpeech',
                  })
                )
                props.onCellChange({ rowIndex, columnId, value, rowOriginal })
              }
            }}
          />
        ),

        width: 300,
        isResizable: true,
        minWidth: 150,
        maxWidth: 190,
      },
      {
        accessor: 'partOfSpeech',
        disableSortBy: true,
        Header: 'Part of speech',
        Cell: ({ value, row }) => {
          return (
            <BaseCell className="w-full py-2">
              <div className="flex gap-2 flex-wrap">
                {value.map((pos, index) => (
                  <SingleSelectTag
                    key={index}
                    title={pos.label}
                    options={[EMPTY_OPTION, ...PART_OF_SPEACH_OPTIONS]}
                    value={pos.value}
                    onChange={(option) => {
                      const updatedPartOfSpeech = row.original[
                        'partOfSpeech'
                      ].map((pos, i) =>
                        i === index ? { ...pos, value: option?.value } : pos
                      )
                      dispatch(
                        updateSynonymCell({
                          value: updatedPartOfSpeech,
                          rowIndex: row.index,
                          columnId: 'partOfSpeech',
                        })
                      )
                    }}
                  />
                ))}
              </div>
            </BaseCell>
          )
        },
        minWidth: 251,
      },
      {
        accessor: 'output',
        disableSortBy: true,
        Header: 'Output',
        Cell: ({ value, row }) => {
          return (
            <div className="ml-6 my-4 flex items-center">
              <Tags
                data-cy="synonym-output"
                data-testid="synonym-output"
                isFreeText
                onAddClick={(value: string) => {
                  dispatch(
                    updateSynonymCell({
                      value: [
                        ...row.original.output,
                        {
                          value: value.toLowerCase(),
                          label: value.toLowerCase(),
                          isInherited: false,
                        },
                      ],
                      rowIndex: row.index,
                      columnId: 'output',
                    })
                  )
                  setResetFilterKey((prev) => prev + 1)
                }}
                onRemoveClick={(value: string) => {
                  dispatch(
                    updateSynonymCell({
                      value: (
                        row.original.output as {
                          value: string
                          label: string
                          isDisabled: boolean
                        }[]
                      ).filter((item: any) => item.value !== value),
                      rowIndex: row.index,
                      columnId: 'output',
                    })
                  )
                  setResetFilterKey((prev) => prev + 1)
                }}
                tags={value.map((value) => ({
                  value: value.value,
                  label: value.value,
                  isDisabled: value.isInherited,
                }))}
                maxLength={50}
              />
            </div>
          )
        },
        width: 400,
      },
      generateDeleteButtonColumn<Row>({
        onClick: (row) => {
          setRowIdToDelete(row.id)
        },
        canDelete: (row) => !row.isInherited,
        buttonLabel: 'Delete',
      }),
    ],
    [TextEditableCellWithFocus, dispatch]
  )
  const handleDeleteCancel = () => {
    setRowIdToDelete(undefined)
  }

  const handleDeleteConfirm = () => {
    setRowIdToDelete(undefined)
    if (rowIdToDelete !== undefined) {
      dispatch(deleteSynonymRow(rowIdToDelete))
    }
  }

  return (
    <>
      <TableWidget.Widget
        data-cy="synonyms-widget"
        data-testid="synonyms-widget"
        columns={columns}
        data={synonyms}
        firstUseText="No synonyms added yet"
        onCellChange={({ rowIndex, columnId, value }) => {
          dispatch(updateSynonymCell({ rowIndex, columnId, value }))
          editableCellLoseFocus()
          setResetFilterKey((prev) => prev + 1)
        }}
      >
        <TableWidget.Header
          title="Synonyms"
          subtitle="Some short guideance text can go here..."
        >
          <Button
            className="mr-4"
            variant="primary"
            data-cy="add-synonyms"
            data-testid="add-synonyms"
            prefixIcon={<AddIcon width={24} height={24} />}
            onClick={() => {
              dispatch(addSynonymRow())
              editableCellFocus()
              setResetFilterKey((prev) => prev + 1)
            }}
          >
            Synonym
          </Button>
          <TableWidget.Filter key={resetFilterKey} />
        </TableWidget.Header>
      </TableWidget.Widget>
      <ConfirmationModal
        open={rowIdToDelete !== undefined}
        title="Remove synonyms row!"
        data-cy="synonyms-row-delete-modal"
        data-testid="synonyms-row-delete-modal"
        confirmationText="Are you sure you want to remove this row?"
        onCancel={() => handleDeleteCancel()}
        onConfirm={() => handleDeleteConfirm()}
      />
    </>
  )
}

export default SynonymsWidget
