import { forwardRef } from 'react'
import { UpdateAccountQuery } from '@phrasee/phrasee-typings/typings/integrations/accounts'

import type {
  SingleSelectProps,
  SingleSelectRef,
} from 'common/components/singleSelect'
import SingleSelect from 'common/components/singleSelect'

import useGetListEmailTemplatesInfiniteQuery from '../api/queries/useGetListEmailTemplatesInfiniteQuery'

const EmailsTemplatesSelect = forwardRef<
  SingleSelectRef,
  UpdateAccountQuery & SingleSelectProps
>(({ stackOneAccountId, isLoading, ...restProps }, ref) => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isInitialLoading,
  } = useGetListEmailTemplatesInfiniteQuery({
    stackOneAccountId,
  })

  return (
    <SingleSelect
      ref={ref}
      isSearchable
      options={data}
      placeholder="Select template..."
      onMenuScrollToBottom={() => {
        if (hasNextPage) {
          fetchNextPage()
        }
      }}
      isLoading={isInitialLoading || isFetchingNextPage}
      {...restProps}
    />
  )
})

export default EmailsTemplatesSelect
