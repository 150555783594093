import Button from 'common/components/button'
import {
  Bin as BinIcon,
  Preview as PreviewIcon,
  Replace as ReplaceIcon,
} from 'common/icons'

export type ImageActionsProps = {
  children: React.ReactNode
  onPreview?: () => void
  onDelete?: () => void
  onReplace?: () => void
}

const ImageActions = ({
  children,
  onPreview,
  onDelete,
  onReplace,
}: ImageActionsProps) => {
  return (
    <div className="relative group">
      <div className="flex absolute invisible bg-gold-50 rounded-md group-hover:visible p-2 right-4 top-4 gap-3 z-10">
        {onPreview && (
          <Button
            size="small"
            variant="icon"
            prefixIcon={
              <PreviewIcon
                isDefaultColor={false}
                size={6}
                className="text-base-700 hover:opacity-50"
              />
            }
            onClick={(e) => {
              e.stopPropagation()
              onPreview()
            }}
          />
        )}
        {onReplace && (
          <Button
            size="small"
            variant="icon"
            prefixIcon={
              <ReplaceIcon
                isDefaultColor={false}
                size={6}
                className="text-base-700 hover:opacity-50"
              />
            }
            onClick={(e) => {
              e.stopPropagation()
              onReplace()
            }}
          />
        )}
        {onDelete && (
          <Button
            size="small"
            variant="icon"
            prefixIcon={
              <BinIcon
                isDefaultColor={false}
                size={6}
                className="text-base-700 hover:opacity-50"
              />
            }
            onClick={(e) => {
              e.stopPropagation()
              onDelete()
            }}
          />
        )}
      </div>
      {children}
    </div>
  )
}

export default ImageActions
