import cx from 'classnames'

import type { Size, Variant } from './Button'

type VariantCssClasses = {
  background: string
  backgroundGhost: string
  text: string
  border: string
  outline: string
}

export const colorPerVariant: Record<
  Exclude<Variant, 'link'>,
  VariantCssClasses
> = {
  primary: {
    background: 'bg-yellow-400 hover:bg-yellow-300',
    backgroundGhost: 'hover:bg-yellow-50',
    text: 'text-base-700',
    border: 'border-yellow-500',
    outline:
      'focus-visible:outline-yellow focus-visible:border-yellow-800 focus-visible:border',
  },
  secondary: {
    background: 'bg-pink-500 hover:bg-pink-400',
    backgroundGhost: 'hover:bg-pink-50',
    text: 'text-pink-500',
    border: 'border-pink-400',
    outline:
      'focus-visible:outline-pink focus-visible:border-pink-800 focus-visible:border',
  },
  success: {
    background: 'bg-green-500 hover:bg-green-600',
    backgroundGhost: 'hover:bg-green-50',
    text: 'text-green-500',
    border: 'border-green-400',
    outline:
      'focus-visible:outline-green focus-visible:border-green-700 focus-visible:border',
  },
  danger: {
    background: 'bg-red-400 hover:bg-red-500',
    backgroundGhost: 'hover:bg-red-50',
    text: 'text-red-400',
    border: 'border-red-400',
    outline:
      'focus-visible:outline-red focus-visible:border-red-800 focus-visible:border',
  },
  icon: {
    background: '',
    backgroundGhost: '',
    text: 'text-base-700 hover:text-maroon-500',
    border: 'border-none',
    outline: '',
  },
}

const defaultDisabledClassnames = 'text-coolGray-400 bg-coolGray-100'

export const disabledClassnamesPerVariant: Record<Variant, string> = {
  link: 'text-coolGray-400',
  icon: 'text-base-300',
  danger: defaultDisabledClassnames,
  primary: defaultDisabledClassnames,
  secondary: defaultDisabledClassnames,
  success: defaultDisabledClassnames,
}

export const paddingPerSize: Record<Size, string> = {
  small: 'py-1 px-2',
  medium: 'py-2 px-4',
  large: 'py-2 px-4',
  xl: 'p-4',
}

export const heightPerSize: Record<Size, string> = {
  small: '26px',
  medium: '32px',
  large: '38px',
  xl: 'auto',
}

export const fontSizePerSize: Record<Size, string> = {
  small: 'text-xs',
  medium: 'text-xs',
  large: 'text-sm',
  xl: 'text-sm',
}

const buttonXlStyles = `w-25 flex flex-col justify-between items-center border border-coolGray-300 p-4 focus:outline-none relative`
export const xlButtonDisabled = cx(
  buttonXlStyles,
  'disabled:bg-coolGray-100 disabled:text-coolGray-400 cursor-not-allowed'
)
export const xlButtonEnabled = cx(
  buttonXlStyles,
  `bg-white text-coolGray-800 hover:bg-coolGray-50 hover:text-coolGray-600 group
         focus-visible:outline-marron focus-visible:border-maroon-700 focus-visible:border `
)
