import cx from 'classnames'

import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget'

import VariantCell from './VariantCell'

type Props = {
  channel: string
  data: {
    genericVariant: string
    personalizedVariant: string
    attributes: { [key: string]: string }[]
  }[]
}

const DemoTable = ({ data, channel }: Props) => {
  const columns: Column<{
    variantId: string
    genericVariant: string
    personalizedVariant: string
    attributes: { [key: string]: string }[]
  }>[] = [
    {
      Header: 'Baselines',
      accessor: 'genericVariant',
      disableSortBy: true,
      isResizable: true,
      minWidth: 50,
      width: 80,
      Cell: ({ value }) => <VariantCell channel={channel} value={value} />,
    },
    {
      Header: 'Personalised variants',
      accessor: 'personalizedVariant',
      disableSortBy: true,
      isResizable: true,
      minWidth: 50,
      width: 80,
      Cell: ({ value }) => (
        <VariantCell channel={channel} value={value} isPersonalisedVariant />
      ),
    },
    {
      Header: 'Attributes',
      accessor: 'attributes',
      disableSortBy: true,
      Cell: ({ value }) => {
        return (
          <BaseCell className="p-3 gap-1 flex-wrap leading-6">
            {value.map((attribute, index) => {
              const [key, value] = Object.entries(attribute)[0]
              return (
                <span className="px-2" key={index}>
                  <span className="font-medium capitalize">{key} : </span>
                  <span className="text-maroon-400">{value}</span>
                </span>
              )
            })}
          </BaseCell>
        )
      },
    },
  ]

  return (
    <TableWidget.Widget
      className={cx({ 'pt-6': data.length === 0 })}
      columns={columns}
      data={data}
    />
  )
}
export default DemoTable
