import { useMemo } from 'react'
import { useState } from 'react'
import { useRouteMatch } from 'react-router'

import EmptyState from 'common/components/emptyState'
import ErrorPage from 'common/components/error/ErrorPage'
import Loader from 'common/components/loaders/Loader'
import MultiSelect from 'common/components/MultiSelect'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'

import { CombinationLibraryEntryStatus } from '../api/api'
import useGetCombinationLibraryQuery from '../api/queries/useGetCombinationLibraryQuery'
import useGetPersonalizedContentQuery from '../api/queries/useGetPersonalizedContentQuery'
import CombinationLibraryTable from '../components/CombinationLibraryTable'

const CombinationLibrary = () => {
  const match = useRouteMatch<{ personalizationId: string }>()
  const { personalizationId } = match.params
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([])

  const personalizedContentQuery = useGetPersonalizedContentQuery()
  const currentPersonalization = personalizedContentQuery.data?.find(
    (personalization) => personalization.personalizationId === personalizationId
  )

  const { data: combinationData = [], ...combinationLibraryQuery } =
    useGetCombinationLibraryQuery({
      personalizationId,
      accountId: currentPersonalization?.accountId,
      region: currentPersonalization?.region,
    })

  const filteredCombinationData = useMemo(
    () =>
      combinationData.filter((entry) => {
        return selectedStatuses.length === 0
          ? true
          : selectedStatuses.includes(entry.status.toLowerCase())
      }),
    [combinationData, selectedStatuses]
  )

  if (combinationLibraryQuery.error || personalizedContentQuery.error) {
    return <ErrorPage />
  }

  if (combinationLibraryQuery.isLoading || personalizedContentQuery.isLoading) {
    return <Loader />
  }

  const areCombinationsAvailable =
    combinationLibraryQuery.isSuccess && combinationData.length > 0

  const mapStatusToLabel: Record<string, CombinationLibraryEntryStatus> = {
    'new combination': 'New combination',
    'awaiting approval': 'Awaiting approval',
    regeneration: 'Regeneration',
    approved: 'Approved',
  }

  return (
    <PageContainer className="max-w-318 sm:mx-auto pl-6 pt-8 pb-18">
      <PageHeader className="mb-4">
        <PageTitle
          title={
            !areCombinationsAvailable
              ? 'Combination Library'
              : currentPersonalization?.campaignName ?? ''
          }
        />
      </PageHeader>
      {!areCombinationsAvailable ? (
        <EmptyState
          title="Welcome to Combination Library"
          description="No combinations were found for this campaign..."
        />
      ) : (
        <>
          <MultiSelect
            className="w-52 mb-6 flex-none"
            data-cy="combination-status-select"
            data-testid="combination-status-select"
            name="status"
            label="Status"
            selectedItems={selectedStatuses}
            placeholder="All statuses"
            items={
              combinationData
                ? Array.from(
                    new Set(combinationData.map((item) => item.status))
                  ).map((status) => ({
                    label: mapStatusToLabel[status.toLowerCase()],
                    value: status.toLowerCase(),
                    id: status.toLowerCase(),
                  }))
                : []
            }
            onApply={setSelectedStatuses}
          />
          <CombinationLibraryTable
            content={filteredCombinationData}
            personalizationId={personalizationId}
          />
        </>
      )}
    </PageContainer>
  )
}

export default CombinationLibrary
