import { useState } from 'react'
import { Field } from 'react-final-form'
import { CAMPAIGN_NAME_LIMIT } from 'workflow/utils/helpers'

import FormItem from 'common/components/formItem'
import Input from 'common/components/input'

import {
  parseWorkflowName,
  validateWorkflowName,
} from '../../contentPage/helpers'

const WorkFlowName = () => {
  const [error, setError] = useState<string | undefined>()
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)

  return (
    <div className="flex flex-col w-full p-6">
      <p
        className="font-relais text-2xl font-medium text-base-700 pb-4 lg:pb-4"
        data-testid="workflow-heading"
      >
        Name of workflow
      </p>
      <p className="text-base text-base-700 font-normal	mb-10 hidden lg:flex">
        Your workflow will include all of your selected templates in the panel
        below.
      </p>
      <Field<string>
        name="workflowName"
        validate={validateWorkflowName}
        parse={(value) => {
          const { value: parsedValue, validationError } =
            parseWorkflowName(value)

          setError(validationError)
          return parsedValue
        }}
      >
        {({ input, meta }) => (
          <FormItem
            error={meta.touched ? meta.error || error : undefined}
            className="flex-none mb-4"
          >
            <Input
              data-cy="workflow-name-input"
              data-testid="workflow-name-input"
              autoFocus
              type="text"
              variant="default"
              className="w-full focus:bg-maroon-40"
              value={input.value}
              onChange={input.onChange}
              onBlur={(e) => {
                setIsInputFocused(false)
                input.onBlur(e)
              }}
              onFocus={() => setIsInputFocused(true)}
              id="workflowName"
              name="workflowName"
              placeholder="Select a memorable name"
              maxLength={CAMPAIGN_NAME_LIMIT}
            />
            {isInputFocused && (
              <p className="h-4 text-coolGray-400 mt-2">
                {input.value.length} / {CAMPAIGN_NAME_LIMIT}
              </p>
            )}
          </FormItem>
        )}
      </Field>
    </div>
  )
}

export default WorkFlowName
