import { ComponentProps, forwardRef } from 'react'

import NumericInput from 'common/components/numericInput'

export type SliderInputProps = Pick<
  ComponentProps<typeof NumericInput>,
  'onChange' | 'value' | 'min' | 'max' | 'aria-label' | 'disabled'
>

const SliderInput = forwardRef<HTMLInputElement, SliderInputProps>(
  (props: SliderInputProps, ref) => {
    const {
      onChange,
      value,
      min,
      max,
      'aria-label': ariaLabel,
      disabled,
    } = props

    return (
      <div className="min-w-14 border-1 border-gold-200 p-2 rounded-lg bg-gold-50">
        <NumericInput
          aria-label={ariaLabel}
          disabled={disabled}
          isWithoutStyles
          onChange={onChange}
          value={value}
          precision={0}
          min={min}
          max={max}
          autoResize={{ paddingPx: 8 }}
          className="py-1 px-2 text-xs font-medium text-center"
          height="h-6"
          ref={ref}
        />
      </div>
    )
  }
)

export default SliderInput
