import { generatePath } from 'react-router'
import { dataSourcesPaths } from 'app/navigation/paths'
import cx from 'classnames'

import { SubNavItem } from 'common/components/navigation/subNavBar'
import { useAppSelector } from 'common/hooks/redux'

type Props = {
  className?: string
}

const HeaderNavigation = ({ className }: Props) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  return (
    <div
      className={cx(
        'flex justify-between items-center bg-maroon-600',
        className
      )}
    >
      <div
        className="flex items-center justify-end font-medium gap-6 md:gap-10 text-sm whitespace-nowrap"
        data-testid="nav-links"
      >
        <SubNavItem
          key="data-sources-customer-attributes"
          to={generatePath(dataSourcesPaths.customerAttributes, {
            accountId,
          })}
          title="Customer attributes"
        />
        <SubNavItem
          key="data-sources-product-catalogue"
          to={generatePath(dataSourcesPaths.productCatalog, {
            accountId,
          })}
          title="Product catalogue"
        />
      </div>
    </div>
  )
}

export default HeaderNavigation
