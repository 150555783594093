import Button from 'common/components/button'
import ModalComponent from 'common/components/Modal'
import { ArrowLeft } from 'common/icons'

const Example = ({ title, text }: { title: string; text: string }) => (
  <p>
    - {title}: <span className="text-gold-700">"{text}"</span>
  </p>
)

type Props = {
  isOpened: boolean
  onClose: () => void
}

const ExplainAttributesModal = ({ isOpened, onClose }: Props) => {
  return (
    <ModalComponent
      centered
      isResponsive
      hasMargin
      visible={isOpened}
      className="max-w-250"
      destroyOnClose
      closable={false}
    >
      <h1 className="text-base-700 text-2xl font-medium mb-6">
        How to write an effective description.
      </h1>
      {/* TODO replace by ps-* when upgrading Tailwind*/}
      <ul
        className="list-decimal text-base-700 text-sm flex gap-4 flex-col"
        style={{ paddingInlineStart: 16 }}
      >
        <li>
          <h2>
            Describe the Attribute: Clearly explain what this attribute
            represents and its purpose within the dataset.
          </h2>
          <Example
            title="Example"
            text="Audience Segment: This attribute categorizes users based on shared characteristics and demographics."
          />
        </li>
        <li>
          <h2>
            Define Each Value: Provide a detailed description of each value or
            category, specifying its criteria.
          </h2>
          <Example
            title="Example"
            text="Young Professionals: Users aged 25-35, primarily in urban areas with professional occupations."
          />
        </li>
        <li>
          <h2>
            Add Examples: Include real-world examples to illustrate the value.
          </h2>
          <Example
            title="Example"
            text="E.g., A 30-year-old software engineer living in London."
          />
        </li>
        <li>
          <h2>
            Highlight Special Cases: Note any exceptions or unique scenarios.
          </h2>
          <Example
            title="Example"
            text="Excludes users aged 25-35 who are students or unemployed."
          />
        </li>
        <li>
          <h2>
            Use Familiar Scenarios: Relate to scenarios that your users might
            already understand or deal with in their work.
          </h2>
          <ul>
            <li>
              <Example
                title="Product Tags"
                text="Seasonal: Products available during specific times of the year, e.g., 'Summer Collection' includes items sold between June and August."
              />
            </li>
            <li>
              <Example
                title="User Behaviour Categories"
                text="High Engagement: Users who log in daily and interact with at least 5 pieces of content per week."
              />
            </li>
            <li>
              <Example
                title="Transaction Status"
                text="Pending: Transactions initiated but not completed, such as a checkout started but not finalised."
              />
            </li>
          </ul>
        </li>
        <li>
          <h2>
            Keep It Simple and Clear: Use concise language and consistent
            formatting for readability.
          </h2>
        </li>
      </ul>
      <div className="flex justify-end">
        <Button
          className="mt-8 self-end"
          variant="link"
          prefixIcon={<ArrowLeft isDefaultColor={false} size={4} />}
          onClick={onClose}
        >
          Go back
        </Button>
      </div>
    </ModalComponent>
  )
}

export default ExplainAttributesModal
