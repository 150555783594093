import { useFlags } from 'launchdarkly-react-client-sdk'

import type { Props } from './Label'
import Label from './Label'
import LabelDefault from './LabelDefault'

const LabelPerFlag = (props: Props) => {
  const flags = useFlags()

  return flags?.showL2Components ? (
    <Label {...props} />
  ) : (
    <LabelDefault {...props} />
  )
}

export default LabelPerFlag
