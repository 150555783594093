import Overlay from 'common/components/Overlay'

const RefreshBriefOverlay = () => {
  return (
    <Overlay
      type="local"
      isContentCentered={false}
      className="bg-opacity-90 p-4"
      innerClassName="flex flex-1 justify-center"
      bgColor="bg-white"
    >
      <div className="flex flex-col justify-center items-center mb-2 font-medium text-lg">
        <div className="text-coolGray-600 mb-2">
          You've made some changes, when you refresh brief anything you've
          generated will be lost.
        </div>
        <div className="text-coolGray-400">
          If you would like to cancel these changes, please select revert.
        </div>
      </div>
    </Overlay>
  )
}

export default RefreshBriefOverlay
