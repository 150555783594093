import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import axios, { AxiosResponse } from 'axios'

import { createCancelTokenSource, isCancel } from 'common/api'
import Button from 'common/components/button/Button'
import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'
import { ReactComponent as MagicDefault } from 'common/icons/magic/default.svg'

const PasswordUpdate = () => {
  const history = useHistory()
  const { email } = useAppSelector((state) => state.authStates.intercomInfo)

  const [isLoading, setLoading] = useState(false)
  const [shouldCallUpdatePassword, setShouldCallUpdatePassword] =
    useState(false)
  const [isPasswordRequestSent, setIsPasswordRequestSent] =
    useState<boolean>(false)

  useEffect(() => {
    const source = createCancelTokenSource()
    const updatePassword = async () => {
      try {
        setLoading(true)
        const changePasswordResponse: AxiosResponse<any> = await axios.post(
          `https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
          {
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
            email,
            connection: 'mongo',
          }
        )

        setIsPasswordRequestSent(true)
        successToast(changePasswordResponse.data, { autoClose: 10000 })
      } catch (err: any) {
        if (!isCancel(err) && err?.response?.data?.error) {
          errorToast(
            `Request to change password failed: ${err.response.data.error}`,
            {
              autoClose: 10000,
            }
          )
        }
      } finally {
        setLoading(false)
        setShouldCallUpdatePassword(false)
      }
    }

    if (shouldCallUpdatePassword) {
      updatePassword()
    }
    return () => source.cancel()
  }, [email, history, shouldCallUpdatePassword])

  return (
    <div className="col-span-1 my-auto">
      <Button
        data-cy="request-password-update-button"
        data-testid="request-password-update-button"
        variant="primary"
        loading={isLoading}
        disabled={isPasswordRequestSent}
        prefixIcon={<MagicDefault width={24} height={24} />}
        onClick={() => setShouldCallUpdatePassword(true)}
      >
        Update password
      </Button>
    </div>
  )
}

export default PasswordUpdate
