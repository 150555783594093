import { useCallback, useMemo, useState } from 'react'

import type { IntegrationProvider } from 'common/api/integrationsApi'
import AnimatedOverlay from 'common/components/animatedOverlay'
import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import Loader from 'common/components/loaders/Loader'

import useGetAvailableIntegrationsQuery from '../../api/queries/useGetAvailableIntegrationsQuery'

import IntegrationsSearchbox from './IntegrationSearchbox'
import IntegrationsList from './IntegrationsList'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const SelectIntegrationOverlay = ({ isOpen, onClose }: Props) => {
  const { data, status } = useGetAvailableIntegrationsQuery()

  const [searchValue, setSearchValue] = useState<string>('')

  const filterByName = useCallback(
    ({ name }: IntegrationProvider) =>
      name
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(searchValue.replaceAll(' ', '').toLowerCase()),
    [searchValue]
  )

  const filteredIntegrations = useMemo(() => {
    return (data ?? []).filter(filterByName)
  }, [data, filterByName])

  return (
    <AnimatedOverlay
      title="Integrations"
      className="overflow-y-auto"
      onClose={onClose}
      isVisible={isOpen}
    >
      {
        {
          success: (
            <div className="w-full h-full">
              <div className="flex flex-col px-6 bg-gold-40">
                <div className="relative flex flex-col mb-8 mt-12">
                  <div className="flex justify-end mb-8">
                    <IntegrationsSearchbox
                      placeholder="Find integration"
                      data-cy="integrations-search-box"
                      data-testid="integrations-search-box"
                      onSearch={setSearchValue}
                      options={data?.map((template) => ({
                        value: template.id,
                        label: template.name,
                      }))}
                    />
                  </div>
                  <div>
                    {filteredIntegrations.length === 0 && searchValue !== '' ? (
                      <div className="text-5xl font-bold text-coolGray-800 leading-normal">
                        There are no integrations matching your search, please
                        try again
                      </div>
                    ) : (
                      <IntegrationsList
                        integrations={filteredIntegrations}
                        onSuccess={onClose}
                      />
                    )}
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          ),
          loading: <Loader />,
          error: <ErrorPage />,
        }[status]
      }
    </AnimatedOverlay>
  )
}

export default SelectIntegrationOverlay
