import Alert from 'common/components/alert/Alert'
import { useAppSelector } from 'common/hooks/redux'

export const CurrentUsersBanner = () => {
  const usersOnContentId = useAppSelector(
    (state) => state.unifiedFlow.concurrentUsers
  )
  return (
    <>
      {usersOnContentId && usersOnContentId.length > 1 && (
        <Alert type="warn" showIcon={true} className="mb-4">
          <div className="text-base font-medium mb-2 pt-2">
            Another user is also editing this campaign. Please coordinate your
            work to avoid making conflicting changes.
          </div>
          <div className="text-sm ml-auto">
            Active user: {usersOnContentId.map((user) => user.name).join(', ')}
          </div>
        </Alert>
      )}
    </>
  )
}
