import { useFlags } from 'launchdarkly-react-client-sdk'
import { shouldShowThePhraseeXIntegrationPanel } from 'workflow/CampaignSummary/IntegrationOptions/SfmcIntegration/helpers'
import helpers from 'workflow/utils/helpers'
import { findCurrentStepName } from 'workflow/WorkflowManager'

import { DetailsPanelButton as Button } from 'common/components/detailsPanel'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Connect } from 'common/icons'
import { shouldShowPhraseeXIntegrationPanel } from 'features/campaigns/integration-options/Cordial/helpers'
import { getESPWithoutCancelBtn } from 'features/campaigns/integration-options/helpers'
import { openDrawer } from 'features/campaigns/store/campaignSlice'

import { isAcoustic, isDisabledPhraseeXRealtimeESP, isSFMC } from './helpers'

interface Props {
  isExpanded: boolean
  isRealtimeApiEnabled: boolean
  selectionType?: string
  typeOfIntegration: string
  variantsAccepted: boolean
}

const IntegrationButton = ({
  isExpanded,
  isRealtimeApiEnabled,
  selectionType = '',
  typeOfIntegration,
  variantsAccepted,
}: Props) => {
  const dispatch = useAppDispatch()
  const { showAccousticDrawer } = useFlags()
  const integrationName = getESPTitle(typeOfIntegration)
  const {
    campaignData,
    chosenStep: step,
    isUserInput,
  } = useAppSelector((state) => state.campaignStates)
  const { campaign_progress: campaignProgress, steps } = campaignData
  const chosenStep = findCurrentStepName(steps, step, isUserInput)

  let shouldRenderIntegrationButton = false
  if (campaignProgress) {
    const {
      test_scheduled,
      test_results_received,
      test_cancelled,
      final_send_results_received,
    } = campaignProgress
    const scheduleButtonConfigValue =
      (!test_results_received && test_cancelled) ||
      !test_scheduled ||
      getESPWithoutCancelBtn().includes(typeOfIntegration)
    shouldRenderIntegrationButton =
      scheduleButtonConfigValue &&
      !final_send_results_received &&
      typeOfIntegration
  }
  const isOnResultsStep = variantsAccepted || chosenStep === 'summary'

  if (!campaignProgress && typeOfIntegration) {
    shouldRenderIntegrationButton = true
  }
  if (!isOnResultsStep) {
    shouldRenderIntegrationButton = false
  }

  const shouldRenderButton = (integration: string) => {
    if (
      !integrationName ||
      (isRealtimeApiEnabled && isDisabledPhraseeXRealtimeESP(integration))
    ) {
      return false
    }
    if (integration === 'adobe_campaign_classic') {
      return (
        !isRealtimeApiEnabled && selectionType === 'bandit' && isOnResultsStep
      )
    }
    if (isAcoustic(integration)) {
      return isOnResultsStep && showAccousticDrawer
    }
    if (integration === 'cordial') {
      const isLanguageApprovalFinished = campaignData.steps.find(
        (step) =>
          step.name === 'Language Approval' ||
          step.name === 'Content Generation'
      ).finished
      return (
        !shouldShowPhraseeXIntegrationPanel(campaignData) &&
        isLanguageApprovalFinished
      )
    }
    if (isSFMC(integration)) {
      const isIframe = helpers.isAppInIFrame()

      if (!shouldShowThePhraseeXIntegrationPanel(campaignData)) {
        return isIframe && isOnResultsStep
      } else {
        return shouldRenderIntegrationButton
      }
    } else {
      return shouldRenderIntegrationButton
    }
  }

  return (
    <>
      {shouldRenderButton(typeOfIntegration) && (
        <Button
          isExpanded={isExpanded}
          data-cy="scheduleButton"
          data-testid="scheduleButton"
          title={`${integrationName} setup`}
          onClick={() => dispatch(openDrawer(typeOfIntegration))}
          icon={<Connect />}
        />
      )}
    </>
  )
}

const getESPTitle = (drawer: string): string | undefined => {
  return {
    adobe_campaign_classic: 'Adobe Campaign Classic',
    acoustic: 'Acoustic',
    adobetarget: 'Adobe Target',
    braze: 'Braze',
    cheetah: 'Cheetah',
    emarsys: 'Emarsys',
    epsilon: 'Epsilon',
    epsilon_react: 'Epsilon',
    facebook: 'Facebook',
    cordial: 'Cordial',
    airship: 'Airship',
    ibm: 'Acoustic',
    'ibm watson': 'Acoustic',
    'input-results': 'Input Results',
    mapp: 'Mapp',
    movable_ink: 'Movable Ink',
    optimizely: 'Optimizely',
    phraseex: 'PhraseeX',
    responsys: 'Responsys',
    sailthru: 'Schedule',
    salesforce: 'Salesforce',
    sfmcv2: 'Salesforce',
  }[drawer]
}

export default IntegrationButton
