import Autocomplete from 'common/components/autocomplete'
import { SelectValue } from 'common/components/BaseSelect'

type Props = {
  onSearch: (value: string) => void
  options: SelectValue[] | undefined
}

const Searchbox = ({ onSearch, options }: Props) => {
  return (
    <Autocomplete
      className="w-60"
      data-testid="merge-tags-search"
      placeholder="Search"
      options={options}
      onSearch={onSearch}
      backspaceRemovesValue={true}
    />
  )
}

export default Searchbox
