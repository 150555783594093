import { UpdateAccountQuery } from '@phrasee/phrasee-typings/typings/integrations/accounts'
import { IntegrationProviderMethod } from '@phrasee/phrasee-typings/typings/integrations/routing'
import { StackOneMetadata } from '@phrasee/phrasee-typings/typings/integrations/templates'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { contentKeys } from 'features/unifiedFlow/api/queryKeys'

import { createAsset, updateAsset } from '../api'

type UpsertAssetMutationVariables = UpdateAccountQuery & {
  method: IntegrationProviderMethod
  variant: string
  field?: string
  value: string | { value: string; label: string }
  metadata: Omit<StackOneMetadata, 'elementId'>
  elementId: number
}

const isValueObject = (
  value: string | { value: string; label: string }
): value is { value: string; label: string } => {
  return typeof value === 'object' && 'value' in value && 'label' in value
}

const useUpsertAssetMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      value,
      stackOneAccountId,
      method,
      variant,
      field,
      metadata,
      elementId,
    }: UpsertAssetMutationVariables) => {
      if (method === 'update' && !!field && isValueObject(value)) {
        return updateAsset({
          stackOneAccountId,
          templateId: value.value,
          body: {
            [field]: variant,
            templateName: value.label,
            metadata: {
              ...metadata,
              elementId,
            },
          },
        })
      }

      return createAsset({
        stackOneAccountId,
        body: {
          name: value as string,
          content: variant,
          metadata: {
            ...metadata,
            elementId,
          },
        },
      })
    },
    onSuccess: (content) => {
      queryClient.setQueryData(contentKeys.content(content._id), content)
    },
  })
}

export default useUpsertAssetMutation
