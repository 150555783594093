import { useQuery } from '@tanstack/react-query'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { TreeNode } from 'common/components/catalog/Catalog'
import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import { CustomerAttributes, fetchMetrics, PersonalizationRegion } from '../api'
import { personalizationKeys } from '../queryKeys'

const useGetMetricsQuery = ({
  channelId,
  personalizationId,
  customerAttributes,
  productCategories,
  personalizationRegion,
}: {
  channelId: string | undefined
  personalizationId: string
  customerAttributes: CustomerAttributes | undefined
  productCategories: TreeNode | undefined
  personalizationRegion: PersonalizationRegion | undefined
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const flags = useFlags()

  const query = useQuery(
    personalizationKeys.metrics({
      accountId,
      channelId,
      customerAttributes,
      personalizationId,
      productCategories,
    }),
    () =>
      fetchMetrics({
        accountId,
        channelId,
        personalizationId,
        customerAttributes: customerAttributes,
        productCategories: productCategories,
        ...(flags.switchToRegionalizedData && {
          personalizationRegion: personalizationRegion?.toLocaleLowerCase(),
        }),
      }),
    {
      onError: () => {
        errorToast('Something went wrong. Please try again.')
      },
      cacheTime: 0,
      staleTime: 0,
    }
  )

  return query
}

export default useGetMetricsQuery
