import { FC } from 'react'

type Props = {
  'data-cy'?: string
  'data-testid'?: string
}

const FormCard: FC<Props> = ({
  children,
  'data-cy': dataCy,
  'data-testid': dataTestId,
}) => {
  return (
    <div
      data-cy={dataCy}
      data-testid={dataTestId}
      className="my-6 p-6 border border-coolGray-300 bg-gray-50 bg-opacity-25"
    >
      {children}
    </div>
  )
}

export default FormCard
