import { replaceNewLineEmojiAsElement } from 'common/variantsUtils'

import { useTweakVariant } from '../context/TweakVariantContext'

const VariantText = () => {
  const { selectedLine } = useTweakVariant()

  return (
    <p className="px-6 py-3 text-base-700 font-normal bg-gold-100 text-base">
      {replaceNewLineEmojiAsElement(selectedLine.text)}
    </p>
  )
}

export default VariantText
