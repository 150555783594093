import RightPanel from '../../components/layout/RightPanel'
import useRightPanelVisibilityState from '../hooks/useRightPanelVisibilityState'

import CampaignDetails from './CampaignDetails/CampaignDetails'

const RightSection = () => {
  useRightPanelVisibilityState()

  return (
    <RightPanel className="z-10 bg-gold-100">
      <h2 className="text-base-700 font-medium font-relais text-2xl p-6">
        Log
      </h2>
      <CampaignDetails />
    </RightPanel>
  )
}

export default RightSection
