import Dropdown from 'rc-dropdown'

import Button from 'common/components/button/Button'
import { Sort } from 'common/icons'

import ImageSortMenu from './ImageSortMenu'

const ImageSort = () => {
  return (
    <Dropdown trigger={['click']} overlay={<ImageSortMenu />}>
      <Button
        variant="icon"
        prefixIcon={<Sort isDefaultColor={false} size={6} />}
      />
    </Dropdown>
  )
}

export default ImageSort
