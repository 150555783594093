import { useEffect, useRef } from 'react'
import { Campaign } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { getESPTitle } from 'app/IntegrationDrawer'
import cx from 'classnames'
import { BACKGROUND_CAMPAIGN_FETCH_INTERVAL } from 'workflow/CampaignSummary/CampaignStatus/CampaignStatus'
import AcousticIntegration from 'workflow/CampaignSummary/IntegrationOptions/AcousticIntegration/AcousticIntegration'
import EpsilonIntegration from 'workflow/CampaignSummary/IntegrationOptions/EpsilonIntegration/EpsilonIntegration'
import EpsilonReactIntegration from 'workflow/CampaignSummary/IntegrationOptions/EpsilonIntegration/EpsilonReactIntegration'
import { shouldShowThePhraseeXIntegrationPanel } from 'workflow/CampaignSummary/IntegrationOptions/SfmcIntegration/helpers'
import SFMCIntegration from 'workflow/CampaignSummary/IntegrationOptions/SfmcIntegration/SfmcIntegrationManager'
import helpers from 'workflow/utils/helpers'
import { fetchCampaignBackgroundStatus } from 'workflow/Workflow.actions'

import Loader from 'common/components/loaders/Loader'
import { errorToast } from 'common/components/toastNotification'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { isSFMC } from 'features/campaigns/components/details/helpers'
import PhraseeX from 'features/campaigns/integration-options/PhraseeX/PhraseeXIntegration'

import useIntegrationStatusCallbacks from './hooks/useIntegrationStatusCallbacks'

import optimizeIntegrationSectionStyles from './optimizeIntegrationSection.module.css'

const shouldShowPhraseeXIntegration = (campaignData: Campaign) => {
  const integrationType =
    campaignData?.campaign_configuration?.integration_options?.type || ''
  const isRealtimeApiEnabled =
    campaignData?.campaign_configuration?.realtime_api_enabled
  const { text_variants_accepted: textVariantsAccepted } =
    campaignData?.campaign_progress || {}
  const isBandit = helpers.isBanditCampaign(campaignData.campaign_configuration)

  return (
    (isRealtimeApiEnabled &&
      textVariantsAccepted &&
      !isSFMC(integrationType)) ||
    (textVariantsAccepted && !integrationType && isBandit)
  )
}

const OptimizeIntegrationSection = () => {
  const dispatch = useAppDispatch()
  const shouldShowSfmcError = useRef<boolean | undefined>(undefined)

  const { onSuccess, onCancel } = useIntegrationStatusCallbacks()

  const campaignData = useAppSelector(
    (state) => state.campaignStates.campaignData
  )
  const isLoadingCampaignData = useAppSelector(
    (state) => state.campaignStates.isLoadingCampaignDetails
  )

  const integrationType =
    campaignData?.campaign_configuration?.integration_options?.type

  const parseIntegrationType = (): string => {
    const selectionType =
      campaignData?.campaign_configuration?.testing_method?.optimization_method
        ?.selection_type

    const showPhraseeXIntegration = shouldShowPhraseeXIntegration(campaignData)
    if (showPhraseeXIntegration) {
      return 'phraseex'
    }

    if (selectionType === 'bandit' && integrationType === 'epsilon') {
      return 'epsilon_react'
    }

    if (isSFMC(integrationType)) {
      const isIframe = helpers.isAppInIFrame()

      if (!shouldShowThePhraseeXIntegrationPanel(campaignData) && !isIframe) {
        if (shouldShowSfmcError.current === undefined) {
          shouldShowSfmcError.current = true
        }
        return ''
      }
    }

    return integrationType
  }

  const integrationTypeParsed = parseIntegrationType()

  const getIntegrationContent = () => {
    switch (integrationTypeParsed) {
      case 'sfmcv2':
      case 'salesforce':
        return <SFMCIntegration onSuccess={onSuccess} onCancel={onCancel} />
      case 'phraseex':
      case 'adobe_campaign_classic':
        return <PhraseeX onSuccess={onSuccess} />
      case 'epsilon':
        return <EpsilonIntegration onSuccess={onSuccess} onCancel={onCancel} />
      case 'epsilon_react':
        return (
          <EpsilonReactIntegration onSuccess={onSuccess} onCancel={onCancel} />
        )
      case 'ibm':
      case 'ibm watson':
      case 'acoustic':
        return <AcousticIntegration onSuccess={onSuccess} onCancel={onCancel} />
      default:
        return null
    }
  }

  const integrationContent = getIntegrationContent()
  const integrationTitle = getESPTitle(integrationTypeParsed)

  useEffect(() => {
    if (campaignData && !isLoadingCampaignData && shouldShowSfmcError.current) {
      shouldShowSfmcError.current = false
      errorToast('Access Jacquard via SFMC to continue.')
    }
  }, [campaignData, isLoadingCampaignData])

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined
    const shouldBackgroundFetch =
      integrationTypeParsed !== 'braze' &&
      integrationTypeParsed !== 'cheetah' &&
      integrationTypeParsed !== 'mapp' &&
      integrationTypeParsed !== 'none' &&
      !!integrationTypeParsed &&
      !helpers.isBanditCampaign(campaignData?.campaign_configuration)

    const campaignId = campaignData?._id

    if (campaignId && shouldBackgroundFetch) {
      interval = setInterval(() => {
        dispatch(fetchCampaignBackgroundStatus(campaignId))
      }, BACKGROUND_CAMPAIGN_FETCH_INTERVAL)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [
    campaignData?._id,
    campaignData?.campaign_configuration,
    dispatch,
    integrationTypeParsed,
  ])

  if (!integrationContent && !isLoadingCampaignData) {
    return null
  }

  if (isLoadingCampaignData) {
    return (
      <div
        data-testid="optimizeIntegrationSection"
        className="bg-gold-100 w-106"
      >
        <Loader />
      </div>
    )
  }

  return (
    <div
      data-testid="optimizeIntegrationSection"
      className={cx(
        'bg-gold-100 pt-6 pb-10 w-106 h-auto unifiedFlowOptimizeIntegrationSection'
      )}
    >
      <div className="px-9 flex justify-between">
        <div
          className="text-2xl font-medium text-base-700"
          data-testid="integration-title"
        >
          {integrationTitle}
        </div>
      </div>
      <div
        className={
          optimizeIntegrationSectionStyles.customIntegrationFooterContainer
        }
      >
        {integrationContent}
      </div>
    </div>
  )
}

export default OptimizeIntegrationSection
