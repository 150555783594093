import { ChangeEvent, useEffect, useRef, useState } from 'react'
import cx from 'classnames'

import EditableValue from './EditableValue'
import EditingValue from './EditingValue'

type Props = {
  isLoading?: boolean
  showLeftEditIcon?: boolean
  value: string
  placeholder: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  onFocus?: () => void
  inputLabel?: string
  'data-cy'?: string
  'data-testid'?: string
  className?: string
  textColorClassName?: string
  fontSizeClassName?: string
  iconClassName?: string
  minLength?: number
  maxLength?: number
  savedValue: string
  showCharacterCounter?: boolean
  shouldShowSaveButton?: boolean
  onSave: () => void
}

const InlineTextEdit = ({
  isLoading,
  value,
  savedValue,
  placeholder,
  inputLabel,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  className,
  textColorClassName,
  fontSizeClassName,
  iconClassName,
  minLength,
  maxLength,
  onSave,
  onChange,
  onBlur,
  onFocus,
  showLeftEditIcon,
  showCharacterCounter = false,
  shouldShowSaveButton,
}: Props) => {
  const [isEditing, setIsEditing] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)

  const handleOnSave = () => {
    if (savedValue !== value) {
      onSave()
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleOnSave()
      setIsEditing(false)
    }
  }

  const handleOnBlur = () => {
    setIsEditing(false)
    setIsInputFocused(false)
    onBlur?.()
    handleOnSave()
  }

  useEffect(() => {
    if (isEditing) {
      inputRef?.current?.focus()
    }
  }, [inputRef, isEditing])

  return (
    <div
      className={cx('grid', {
        'w-full': isEditing,
      })}
    >
      {isEditing ? (
        <EditingValue
          ref={inputRef}
          className={className}
          textColorClassName={textColorClassName}
          fontSizeClassName={fontSizeClassName}
          iconClassName={iconClassName}
          value={value}
          placeholder={placeholder}
          minLength={minLength}
          maxLength={maxLength}
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
          onChange={(e) => {
            onChange(e)
            setIsEditing(true)
          }}
          setIsFocused={() => {
            setIsEditing(true)
            setIsInputFocused(true)
            onFocus?.()
          }}
          inputLabel={inputLabel}
          data-cy={dataCy}
          data-testid={dataTestId}
          showIcon={showLeftEditIcon}
          savedValue={savedValue}
          onSaveClick={onSave}
          shouldShowSaveButton={shouldShowSaveButton}
        />
      ) : (
        <EditableValue
          value={value}
          data-cy={dataCy}
          data-testid={dataTestId}
          onEditClick={() => setIsEditing(true)}
          textColorClassName={textColorClassName}
          fontSizeClassName={fontSizeClassName}
          iconClassName={iconClassName}
          isLoading={isLoading}
        />
      )}
      {showCharacterCounter && (
        <span className="h-4 text-coolGray-400 mt-2 flex justify-items-start">
          {isEditing && isInputFocused && (
            <>
              {value.length} / {maxLength}
            </>
          )}
        </span>
      )}
    </div>
  )
}

export default InlineTextEdit
