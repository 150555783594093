import Modal from 'common/components/Modal'

import type { SelectedImage } from '../../hooks/useImageActions'

import ArchiveImageModalFooter from './ArchiveImageModalFooter'

import styles from 'common/components/images/imagesStyles.module.css'

type Props = {
  selectedImages: SelectedImage[]
  isArchiveModalVisible: boolean
  onClearImages: () => void
  onClose: () => void
}

const ArchiveImageModal = ({
  selectedImages,
  isArchiveModalVisible,
  onClose,
  onClearImages,
}: Props) => {
  return (
    <Modal
      visible={isArchiveModalVisible}
      closable={false}
      width="500px"
      className={styles.modal}
      centered
      onClose={onClose}
      data-testid="archive-image-modal"
    >
      <h4 className="text-base-700 text-2xl font-semibold mb-4">
        Are you sure you want to delete this image?
      </h4>
      <div className="flex overflow-y-auto overflow-x-hidden h-100">
        <div className="flex flex-col gap-2 w-full">
          {selectedImages.map(({ id, name, fileName }) => {
            return (
              <div
                key={id}
                className="w-full bg-gold-40 text-sm text-gold-700 font-normal p-4"
              >
                <p>{name ?? fileName}</p>
              </div>
            )
          })}
        </div>
      </div>

      <ArchiveImageModalFooter
        selectedImagesIds={selectedImages.map(({ id }) => id)}
        onCancel={onClose}
        onClearImages={onClearImages}
      />
    </Modal>
  )
}

export default ArchiveImageModal
