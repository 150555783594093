import { useStackOneHub } from '@stackone/react-hub'
import { useFlags } from 'launchdarkly-react-client-sdk'

import type { IntegrationProvider } from 'common/api/integrationsApi'
import { useAppSelector } from 'common/hooks/redux'
import { getSelectedAccountName } from 'features/auth/store/authSlice'

import useSetupIntegrationConnection from '../../api/mutations/useSetupIntegrationConnection'

import DefaultIntegrationCard from './integrationCard/DefaultIntegrationCard'
import IntegrationCard from './integrationCard'

type Props = {
  integrations: IntegrationProvider[]
  onSuccess: () => void
}

const IntegrationsList = ({ integrations, onSuccess }: Props) => {
  const flags = useFlags()

  const { mutate, isLoading, variables } = useSetupIntegrationConnection()

  const { startConnect } = useStackOneHub()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const accountName = useAppSelector(getSelectedAccountName)
  const firstName = useAppSelector((state) => state.authStates.firstName)
  const lastName = useAppSelector((state) => state.authStates.lastName)

  const onClick = async (id: string, label: string, onClose: () => void) => {
    if (accountName) {
      mutate(
        {
          provider: id,
          accountId,
          accountName,
          userName: `${firstName} ${lastName}`,
          label,
        },
        {
          onSuccess: (sessionToken) => {
            onClose?.()
            startConnect({
              sessionToken,
              onSuccess,
            })
          },
        }
      )
    }
  }

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
      {integrations.map(({ id, name, logoUrl }) => {
        return flags?.s1NameIntegration ? (
          <IntegrationCard
            key={id}
            name={name}
            logoUrl={logoUrl}
            onSubmit={(label, onClose) => onClick(id, label, onClose)}
            isLoading={isLoading && id === variables?.provider}
          />
        ) : (
          <DefaultIntegrationCard
            key={id}
            name={name}
            logoUrl={logoUrl}
            onClick={() => onClick(id, '', () => {})}
          />
        )
      })}
    </div>
  )
}

export default IntegrationsList
