import { useEffect } from 'react'
import { fetchCampaignData } from 'workflow/Workflow.actions'

import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch } from 'common/hooks/redux'
import { useSelectedElement } from 'features/unifiedFlow/contentPage/hooks'

import { isComponentElement } from '../../api/interfaces'
import MainContent from '../../components/MainContent'

import useElementState from './hooks/useElementState'
import useRightPanelVisibilityState from './hooks/useRightPanelVisibilityState'
import CenterSection from './centerSection'
import LeftSection from './leftSection'
import RightSection from './rightSection'

const Integrations = () => {
  useDocumentTitle('Launch | Jacquard')
  const dispatch = useAppDispatch()

  const { data: selectedElement } = useSelectedElement()

  const isOptimized = !!(selectedElement && selectedElement.campaign_id)
  const isPersonalizable = !!(
    selectedElement &&
    isComponentElement(selectedElement) &&
    selectedElement.personalization_id
  )

  useElementState()
  useRightPanelVisibilityState({ showOptimizeView: isOptimized })

  useEffect(() => {
    if (selectedElement && selectedElement.campaign_id) {
      dispatch(fetchCampaignData(selectedElement.campaign_id))
    }
  }, [dispatch, selectedElement, isOptimized])

  return (
    <MainContent className="flex-wrap" id="main-content-page">
      <LeftSection />
      {selectedElement !== undefined && (
        <>
          <CenterSection
            showOptimizeView={isOptimized}
            showPersonalizationView={isPersonalizable}
          />
          <RightSection showOptimizeView={isOptimized} />
        </>
      )}
    </MainContent>
  )
}

export default Integrations
