import { FC } from 'react'
import cx from 'classnames'

import { Edit } from 'common/icons'

interface Props {
  value: string
  onEditClick: () => void
  'data-cy'?: string
  'data-testid'?: string
  className?: string
  textColorClassName?: string
  fontSizeClassName?: string
  iconClassName?: string
  isLoading?: boolean
}

const EditableValue: FC<Props> = ({
  value,
  onEditClick,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  className,
  textColorClassName,
  fontSizeClassName,
  iconClassName,
  isLoading,
}) => {
  const doesIncludeSpaces = /\s/g.test(value)

  const fontSize = fontSizeClassName || 'text-2xl lg:text-3xl'
  const textColor = textColorClassName || 'text-coolGray-800'
  return (
    <div className="flex items-center cursor-pointer group truncate h-10">
      <div
        data-cy={dataCy}
        data-testid={dataTestId}
        className={cx(
          `mr-4 font-medium truncate whitespace-pre ${textColor} ${fontSize}`,
          { 'break-words': doesIncludeSpaces },
          { 'break-all': !doesIncludeSpaces },
          className
        )}
      >
        {value}
      </div>
      <button
        data-cy={`${dataCy}-edit`}
        data-testid={`${dataTestId}-edit`}
        aria-label="edit"
        onClick={onEditClick}
        className="outline-none mr-2"
      >
        <Edit
          data-testid="input-edit"
          isDefaultColor={false}
          className={cx(
            'cursor-pointer',
            {
              'invisible group-hover:visible': !isLoading,
            },
            iconClassName
          )}
        />
      </button>
      {isLoading && <p className="text-sm ml-1">Saving...</p>}
    </div>
  )
}

export default EditableValue
