import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type {
  ImagePageOptions,
  ImageSortableKeys,
  ImageSortOrder,
  ImageStatus,
} from 'common/components/images/api/api'
import { changeAccountId } from 'features/auth/store/authSlice'

const PAGINATION_DEFAULT_VALUES = {
  LIMIT: 10,
  OFFSET: 0,
}

export type Pagination = Required<Pick<ImagePageOptions, 'offset' | 'limit'>>

export type { ImageStatus }

export type SortOrder = ImageSortOrder

export type SortBy = ImageSortableKeys

export type Sort = {
  sortBy: SortBy
  sortOrder: SortOrder
}

interface State {
  query: string
  pagination: Pagination
  imageStatus?: ImageStatus
  sort: Sort
}

export const initialState: State = {
  query: '',
  pagination: {
    limit: PAGINATION_DEFAULT_VALUES.LIMIT,
    offset: PAGINATION_DEFAULT_VALUES.OFFSET,
  },
  imageStatus: undefined,
  sort: {
    sortBy: 'createdAt',
    sortOrder: 'DESC',
  },
}

export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    onSearchChange: (state, action: PayloadAction<string>) => {
      state.query = action.payload
      state.pagination.offset = initialState.pagination.offset
    },
    onSearchReset: (state) => {
      state.query = initialState.query
    },
    onPaginationLimitChange: (state, action: PayloadAction<number>) => {
      state.pagination.limit = action.payload
    },
    onPaginationOffsetChange: (state, action: PayloadAction<number>) => {
      state.pagination.offset = action.payload
    },
    onResetState: () => initialState,
    onImageStatusFilterChange: (
      state,
      action: PayloadAction<ImageStatus | undefined>
    ) => {
      state.imageStatus = action.payload
      state.pagination.offset = initialState.pagination.offset
    },
    onStatusFilterReset: (state) => {
      state.imageStatus = initialState.imageStatus
      state.pagination.offset = initialState.pagination.offset
    },
    onSortChange: (state, action: PayloadAction<Sort>) => {
      state.sort.sortOrder = action.payload.sortOrder
      state.sort.sortBy = action.payload.sortBy
      state.pagination.offset = initialState.pagination.offset
    },
    onSortReset: (state) => {
      state.sort = initialState.sort
      state.pagination.offset = initialState.pagination.offset
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, () => initialState)
  },
})

/*
 * Actions
 */
export const {
  onSearchChange,
  onSearchReset,
  onPaginationLimitChange,
  onPaginationOffsetChange,
  onImageStatusFilterChange,
  onStatusFilterReset,
  onSortChange,
  onSortReset,
  onResetState,
} = imagesSlice.actions

export default imagesSlice.reducer
