import { ComponentElement } from '@phrasee/phrasee-typings'
import filter from 'lodash/filter'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isUndefined from 'lodash/isUndefined'

import type { ImageEntity } from 'common/components/images/api/api'

export const getInitialImageIds = (
  selectedElement: ComponentElement | undefined
) => {
  const value = get(
    selectedElement?.user_input?.user_input_form.find(
      ({ type }) => type === 'image-multi'
    ),
    `value`,
    []
  )

  return isArray(value) ? value : []
}

export const getInitialVariantOnly = (
  selectedElement: ComponentElement | undefined
): boolean => {
  const value = get(
    selectedElement?.user_input?.user_input_form?.find(
      ({ type }) => type === 'toggle'
    ),
    `value`,
    false
  )

  return typeof value === 'boolean' ? value : false
}

export const getNonEmptyImages = (
  imagesSlots: (ImageEntity | undefined)[]
): ImageEntity[] => {
  return filter(
    imagesSlots,
    (image): image is ImageEntity => !isUndefined(image)
  )
}
