import cx from 'classnames'
import isString from 'lodash/isString'

import type { ImageEntity } from 'common/components/images/api/api'
import { bytesToKilobytes } from 'common/helpers/files'
import { formatDate } from 'common/helpers/formatDate'

export type ImageDetailsProps = Pick<ImageEntity, 'fileSize' | 'updatedAt'> & {
  children: React.ReactNode
  className?: string
  fileName: Pick<ImageEntity, 'fileName'> | React.ReactNode
}

const ImageDetails = ({
  fileName,
  updatedAt,
  fileSize,
  children,
  className,
}: ImageDetailsProps) => {
  return (
    <div className={cx('flex flex-col', className)}>
      {isString(fileName) ? (
        <p className="text-xs text-base-700 font-normal pb-2 truncate">
          {fileName}
        </p>
      ) : (
        fileName
      )}

      {children}
      <div className="flex pt-2">
        {updatedAt && (
          <p className="text-2.5 font-normal">{formatDate(updatedAt)}</p>
        )}
        <p className="text-2.5 font-normal ml-auto">{`${bytesToKilobytes(
          fileSize
        )}kb`}</p>
      </div>
    </div>
  )
}

export default ImageDetails
