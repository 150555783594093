import { useEffect, useReducer } from 'react'
import * as Drawer from 'app/IntegrationDrawer'
import { loadResultsDetails } from 'workflow/Workflow.actions'

import Form from 'common/components/Form'
import Loader from 'common/components/loaders/Loader'
import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { closeDrawer } from 'features/campaigns/store/campaignSlice'

import {
  getAirshipExperimentResults,
  getAirshipExperiments,
  saveAirshipCampaignIntegrationData,
} from './api'
import DrawerContent from './DrawerContent'
import { airshipReducer, initialState } from './reducer'

export default function AirshipIntegration() {
  const [airshipIntegrationState, dispatchAirshipIntegrationState] = useReducer(
    airshipReducer,
    initialState
  )

  const {
    selectedExperiment,
    experimentsList,
    isExperimentsLoading,
    isRetrieveResultsButtonLoading,
  } = airshipIntegrationState

  const {
    campaignData: {
      project_id,
      _id: campaign_id,
      campaign_data: { integration_data },
    },
  } = useAppSelector((state) => state.campaignStates)

  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatchAirshipIntegrationState({
      type: 'get_experiments',
    })
  }, [])

  useEffect(() => {
    const fetchExperiments = async (project_id) => {
      try {
        const response = await getAirshipExperiments(project_id)
        dispatchAirshipIntegrationState({
          type: 'get_experiments_success',
          value: response.data,
        })
      } catch (err) {
        dispatchAirshipIntegrationState({
          type: 'get_experiments_error',
        })
        const error = err as Error
        errorToast(error.message)
      }
    }
    fetchExperiments(project_id)
  }, [project_id])

  useEffect(() => {
    if (!selectedExperiment && integration_data?.experimentId) {
      dispatchAirshipIntegrationState({
        type: 'select_experiment',
        value: integration_data?.experimentId,
      })
    }
  }, [integration_data?.experimentId, selectedExperiment])

  const retrieveResults = async () => {
    dispatchAirshipIntegrationState({
      type: 'retrieve_results',
    })
    const experimentToUpdate = experimentsList?.find(
      (experiment) => experiment.id === selectedExperiment
    )
    if (!experimentToUpdate) {
      dispatchAirshipIntegrationState({
        type: 'retrieve_results_error',
      })
      errorToast('Experiment not found')
    } else {
      // save campaign integration data, if not already saved
      if (!integration_data?.experimentId) {
        try {
          await saveAirshipCampaignIntegrationData(project_id, campaign_id, {
            experimentId: experimentToUpdate?.id,
            pushId: experimentToUpdate?.push_id,
          })
        } catch (err) {
          dispatchAirshipIntegrationState({
            type: 'retrieve_results_error',
          })
          const error = err as Error
          errorToast(error.message)
        }
      }

      try {
        await getAirshipExperimentResults(project_id, campaign_id)
        dispatchAirshipIntegrationState({
          type: 'retrieve_results_success',
        })
        successToast('Results successfully retrieved')
        dispatch(loadResultsDetails(campaign_id))
        closeDrawer()
      } catch (err) {
        dispatchAirshipIntegrationState({
          type: 'retrieve_results_error',
        })
        const error = err as Error
        errorToast(error.message)
      }
    }
  }

  const updateSelectedExperiment = (experiment) => {
    dispatchAirshipIntegrationState({
      type: 'select_experiment',
      value: experiment,
    })
  }

  return (
    <Drawer.Content>
      <Form>
        <Loader data-testid="airship-loader" isLoading={isExperimentsLoading}>
          <div className="text-base font-medium text-gray-400 mb-4">
            Connect your Airship experiment to Jacquard to retrieve your
            results.
          </div>
          <DrawerContent
            isExperimentsLoading={isExperimentsLoading}
            selectedExperiment={selectedExperiment}
            experiments={experimentsList}
            retrieveResults={retrieveResults}
            updateSelectedExperiment={updateSelectedExperiment}
            isRetrieveResultsButtonLoading={isRetrieveResultsButtonLoading}
            isExperimentSelectDisabled={!!integration_data?.experimentId}
          />
        </Loader>
      </Form>
    </Drawer.Content>
  )
}
