import Button from 'common/components/button/Button'

type Props = {
  hasImages: boolean
  isLoading: boolean
  onCancel: () => void
  onUpload: () => void
}

const UploaderModalFooter = ({
  hasImages,
  isLoading,
  onCancel,
  onUpload,
}: Props) => {
  if (!hasImages) {
    return null
  }

  return (
    <div className="flex items-center justify-end w-full bg-gold-50 mt-6">
      <Button variant="link" className="uppercase underline" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        disabled={isLoading}
        variant="primary"
        className="uppercase ml-4"
        onClick={onUpload}
        loading={isLoading}
      >
        Upload
      </Button>
    </div>
  )
}

export default UploaderModalFooter
