import { DateRangePickerShape } from 'react-dates'
import { isInclusivelyAfterDay } from 'react-dates'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  renderCustomArrowIcon,
  renderCustomCloseIcon,
  renderCustomInputIcon,
  renderNavNextButton,
  renderNavPrevButton,
} from 'common/components/datePicker/datePickerIcons'
import type {
  CustomProps,
  DateRangePickerType,
  DefaultOption,
  FocusedInputType,
  SelectorDate,
  TooltipProps,
} from 'common/components/dateRange/interfaces'

import DateRange from './DateRange'
import DateRangeDefault from './DateRangeDefault'
import { defaultOptions } from './defaultOptions'

import datePickerStyles from 'common/components/datePicker/DateStyles.module.css'
import datePickerDefaultStyles from 'common/components/datePicker/DateStylesDefault.module.css'

const DateRangePerFlag = (
  dateRangeProps: DateRangePickerShape & CustomProps
) => {
  const flags = useFlags()

  return flags?.showL2Components ? (
    <div className={datePickerStyles.container}>
      <DateRange
        renderNavNextButton={renderNavNextButton}
        renderNavPrevButton={renderNavPrevButton}
        customInputIcon={renderCustomInputIcon(dateRangeProps.disabled)}
        customCloseIcon={renderCustomCloseIcon()}
        customArrowIcon={renderCustomArrowIcon()}
        {...dateRangeProps}
      />
    </div>
  ) : (
    <div className={datePickerDefaultStyles.container}>
      <DateRangeDefault {...dateRangeProps} />
    </div>
  )
}

export default DateRangePerFlag

export { isInclusivelyAfterDay, defaultOptions }

export type {
  CustomProps,
  DefaultOption,
  SelectorDate,
  DateRangePickerType,
  FocusedInputType,
  TooltipProps,
}
