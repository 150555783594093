import { KeyboardEvent, TextareaHTMLAttributes, useState } from 'react'
import cx from 'classnames'

export const allowTabCharacter = (e: KeyboardEvent<HTMLTextAreaElement>) => {
  if (e.key === 'Tab') {
    e.preventDefault()
    const start = e.currentTarget.selectionStart
    const end = e.currentTarget.selectionEnd
    e.currentTarget.value = `${e.currentTarget.value.substring(
      0,
      start
    )}\t${e.currentTarget.value.substring(end)}`
    e.currentTarget.selectionStart = e.currentTarget.selectionEnd = start + 1
  }
}

export type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  allowTab?: boolean
  showCharacterCount?: boolean
  className?: string
  customClassName?: string
  isError?: boolean
}

const Textarea = ({
  allowTab = false,
  showCharacterCount = false,
  className,
  customClassName,
  onKeyDown,
  onChange,
  children,
  maxLength,
  isError,
  ...rest
}: TextareaProps) => {
  const [characterCount, setCharacterCount] = useState(
    typeof rest.value === 'string' ? rest.value?.length : 0
  )

  return (
    <div className="relative flex">
      {showCharacterCount && (
        <div
          className={cx(
            'absolute right-0 -top-6 text-gold-500 text-xs font-normal',
            {
              'opacity-50': rest.disabled,
            }
          )}
        >
          {characterCount} / {maxLength}
        </div>
      )}
      <textarea
        className={
          customClassName ??
          cx(
            'bg-gold-40 border-1 text-base-700 font-normal text-sm rounded-sm leading-4 px-4 py-3 placeholder-gold-700 outline-none w-full',
            {
              'border-gold-400 hover:border-gold-500 focus:border-base-700 active:border-base-700':
                !isError && !rest.disabled,
              'border-RAGred': isError,
              'border-gold-400 opacity-50 cursor-not-allowed': rest.disabled,
            },
            className
          )
        }
        onChange={(e) => {
          if (showCharacterCount) {
            setCharacterCount(e.currentTarget.value.length)
          }
          onChange?.(e)
        }}
        onKeyDown={(e) => {
          if (allowTab) {
            allowTabCharacter(e)
          }
          onKeyDown?.(e)
        }}
        maxLength={maxLength}
        {...rest}
      />
    </div>
  )
}

export default Textarea
