import React from 'react'

import Widget, { WidgetProps } from './Widget'
import WidgetHeader from './WidgetHeader'

interface Props extends WidgetProps {
  title: React.ReactChild | React.ReactFragment
  actions?: React.ReactChild | React.ReactFragment
  titleClassName?: string
  children: React.ReactNode
  'data-cy'?: string
  'data-testid'?: string
}

const GraphWidget = ({
  title,
  actions,
  titleClassName,
  children,
  ...rest
}: Props) => (
  <Widget {...rest}>
    <WidgetHeader className={titleClassName} title={title} actions={actions} />

    {children}
  </Widget>
)

export { GraphWidget as Widget }
