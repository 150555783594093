import useListAccountsQuery from 'common/api/queries/useListAccountsQuery'
import Loader from 'common/components/loaders/Loader'
import LoadingOverlay from 'common/components/LoadingOverlay'
import { useAppSelector } from 'common/hooks/redux'
import { getSelectedIntegrationAccount } from 'features/unifiedFlow/contentPage/integrations/helpers'

import { useContent, useSelectedElement } from '../../hooks'
import useAvailableIntegrations from '../hooks/useAvailableIntegrations'

import useConnectContentMutation from './api/mutations/useConnectContentMutation'
import IntegrationAssets from './integrationAssets'
import IntegrationContent from './IntegrationContent'
import IntegrationSelection from './IntegrationSelection'
import MultipleIntegrations from './MultipleIntegrations'
import NoIntegrations from './NoIntegrations'
const IntegrationSection = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const { content } = useContent()
  const { data: accounts, isLoading } = useListAccountsQuery(accountId)
  const connectContentMutation = useConnectContentMutation()

  const { data: selectedElement } = useSelectedElement()
  const { data: availableIntegrations } = useAvailableIntegrations()

  if (!selectedElement) {
    return null
  }

  const elementIntegrationAccountId = selectedElement.integration_account_id

  const selectedIntegrationAccount = getSelectedIntegrationAccount(
    accounts,
    availableIntegrations,
    elementIntegrationAccountId
  )

  const isAccounts = !!accounts && accounts?.length > 0

  const getState = () => {
    if (isLoading) {
      return 'loading'
    } else if (isAccounts && selectedIntegrationAccount) {
      return 'defaultSelected'
    } else if (isAccounts && !selectedIntegrationAccount) {
      return 'defaultNotSelected'
    } else {
      return 'empty'
    }
  }

  const state = getState()

  return (
    <div
      data-testid="integrationSection"
      className="bg-gold-40 flex-1 self-start relative"
    >
      <LoadingOverlay
        title="Connecting"
        isLoading={connectContentMutation.isLoading}
        phrases={['Preparing your content...']}
        overlayType="local"
      />
      <h5
        className="text-base-700 text-4xl font-medium mb-4"
        data-testid="launch-heading"
      >
        Launch
      </h5>
      {
        {
          loading: <Loader />,
          defaultSelected: (
            <IntegrationSelection
              account={selectedIntegrationAccount!}
              accounts={accounts!}
              contentId={content!._id}
              elementId={selectedElement.element_id}
            />
          ),
          defaultNotSelected: (
            <MultipleIntegrations
              accounts={accounts!}
              contentId={content!._id}
              elementId={selectedElement.element_id}
            />
          ),
          empty: <NoIntegrations />,
        }[state]
      }
      <hr className="my-8 border-gold-400" />
      <IntegrationContent element={selectedElement} />
      {selectedIntegrationAccount && (
        <>
          <hr className="my-8 border-gold-400" />
          <IntegrationAssets
            integrationAccountId={selectedIntegrationAccount.id}
            contentId={content!._id}
            accountId={accountId}
          />
        </>
      )}
    </div>
  )
}

export default IntegrationSection
