import { Template } from '@phrasee/phrasee-typings'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import { isComponentElement } from '../interfaces'
import { contentKeys } from '../queryKeys'
import { deletePersonalizedVariant } from '..'

const useDeletePersonalizedVariantMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(deletePersonalizedVariant, {
    onError: () => {
      errorToast('Something went wrong. Please try again.')
    },
    onMutate: (variables) => {
      queryClient.setQueryData<Template>(
        contentKeys.content(variables.contentId),
        (old) => {
          if (!old) {
            return old
          }
          return {
            ...old,
            elements: old.elements.map((element) => {
              if (
                element.element_id !== variables.elementId ||
                !isComponentElement(element) ||
                !element.personalized_variants
              ) {
                return element
              }

              return {
                ...element,
                personalized_variants: element.personalized_variants.map(
                  (variant) => {
                    if (variant.variant_id === variables.subjectLineId) {
                      return {
                        ...variant,
                        isLoading: true,
                      }
                    }
                    return variant
                  }
                ),
              }
            }),
          }
        }
      )
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(contentKeys.content(variables.contentId))
    },
  })
}

export default useDeletePersonalizedVariantMutation
