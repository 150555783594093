/* eslint-disable max-lines */
import React from 'react'

import { ReactComponent as SidebarLeftMenuDefault } from '././sidebarLeftMenu/default.svg'
import { ReactComponent as SidebarRightMenuDefault } from '././sidebarRightMenu/default.svg'
import { ReactComponent as AccountDefault } from './account/default.svg'
import { ReactComponent as AccountSelected } from './account/selected.svg'
import { ReactComponent as AccountsDefault } from './accounts/default.svg'
import { ReactComponent as AddSquareDefault } from './add/add-square-default.svg'
import { ReactComponent as AddDefault } from './add/default.svg'
import { ReactComponent as AddSelected } from './add/selected.svg'
import { ReactComponent as AiContentDefault } from './aiContent/default.svg'
import { ReactComponent as AlertDefault } from './alert/default.svg'
import { ReactComponent as AlertCircleDefault } from './alertCircle/default.svg'
import { ReactComponent as AlertCircleInvertedDefault } from './alertCircle/inverted-colors.svg'
import { ReactComponent as ApprovalDefault } from './approval/default.svg'
import { ReactComponent as ApprovalSelected } from './approval/selected.svg'
import { ReactComponent as ArchiveDefault } from './archive/default.svg'
import { ReactComponent as ArrowDownDefault } from './arrowDown/default.svg'
import { ReactComponent as ArrowDownSelected } from './arrowDown/selected.svg'
import { ReactComponent as arrowDropdownDownDefault } from './arrowDropdownDown/default.svg'
import { ReactComponent as arrowDropdownDownSelected } from './arrowDropdownDown/selected.svg'
import { ReactComponent as arrowDropdownUpDefault } from './arrowDropdownUp/default.svg'
import { ReactComponent as ArrowLeftDefault } from './arrowLeft/default.svg'
import { ReactComponent as ArrowLeftSelected } from './arrowLeft/selected.svg'
import { ReactComponent as ArrowRightDefault } from './arrowRight/default.svg'
import { ReactComponent as ArrowRightSelected } from './arrowRight/selected.svg'
import { ReactComponent as ArrowSmlDefault } from './arrowSml/default.svg'
import { ReactComponent as ArrowSmlSelected } from './arrowSml/selected.svg'
import { ReactComponent as ArrowUpDefault } from './arrowUp/default.svg'
import { ReactComponent as ArrowUpSelected } from './arrowUp/selected.svg'
import { ReactComponent as ArticleDefault } from './article/default.svg'
import { ReactComponent as ArticleSelected } from './article/selected.svg'
import { ReactComponent as BinDefault } from './bin/default.svg'
import { ReactComponent as BinSelected } from './bin/selected.svg'
import { ReactComponent as BinDestructiveSvg } from './binDestructive/default.svg'
import { ReactComponent as CalendarSquareDefault } from './calendar/calendar-square-default.svg'
import { ReactComponent as CalendarDefault } from './calendar/default.svg'
import { ReactComponent as CalendarSelected } from './calendar/selected.svg'
import { ReactComponent as CheckCampaignDefault } from './campaign/check.svg'
import { ReactComponent as CampaignDefault } from './campaign/default.svg'
import { ReactComponent as CampaignSelected } from './campaign/selected.svg'
import { ReactComponent as CampaignsDefault } from './campaigns/default.svg'
import { ReactComponent as CampaignsSelected } from './campaigns/selected.svg'
import { ReactComponent as CampaignTypeDefault } from './campaignType/default.svg'
import { ReactComponent as CampaignTypeSelected } from './campaignType/selected.svg'
import { ReactComponent as CancelDefault } from './cancel/default.svg'
import { ReactComponent as CascadeFlowDefault } from './cascadeFlow/default.svg'
import { ReactComponent as CheckDefault } from './check/default.svg'
import { ReactComponent as CheckboxDefault } from './checkbox/deselected.svg'
import { ReactComponent as CheckboxIndeterminateDefault } from './checkbox/multi-option.svg'
import { ReactComponent as CheckboxSelected } from './checkbox/selected.svg'
import { ReactComponent as CheckCircleDefault } from './checkCircle/default.svg'
import { ReactComponent as CheckCircleInvertedDefault } from './checkCircle/inverted-colors.svg'
import { ReactComponent as CheckmarkDefault } from './checkmark/default.svg'
import { ReactComponent as ChevronDownSquareDefault } from './chevronDown/chevron-down-square-default.svg'
import { ReactComponent as ChevronDownDefault } from './chevronDown/default.svg'
import { ReactComponent as ChevronLeftDefault } from './chevronLeft/default.svg'
import { ReactComponent as ChevronLeftStartDefault } from './chevronLeftStart/default.svg'
import { ReactComponent as ChevronRightDefault } from './chevronRight/default.svg'
import { ReactComponent as ChevronRightEndDefault } from './chevronRightEnd/default.svg'
import { ReactComponent as ChevronUpSquareDefault } from './chevronUp/chevron-up-square-default.svg'
import { ReactComponent as ChevronUpDefault } from './chevronUp/default.svg'
import { ReactComponent as CircleSlashDefault } from './circleSlash/default.svg'
import { ReactComponent as ClipboardDefault } from './clipboard/default.svg'
import { ReactComponent as ClockDefault } from './clock/default.svg'
import { ReactComponent as CloseSquareDefault } from './close/close-square.svg'
import { ReactComponent as CloseDefault } from './close/default.svg'
import { ReactComponent as CloseSelected } from './close/selected.svg'
import { ReactComponent as CloseSmallDefault } from './close-sml/default.svg'
import { ReactComponent as CloseSmallSelected } from './close-sml/selected.svg'
import { ReactComponent as CompleteDefault } from './complete/default.svg'
import { ReactComponent as CompleteSelected } from './complete/selected.svg'
import { ReactComponent as ConnectDefault } from './connect/default.svg'
import { ReactComponent as ConnectSelected } from './connect/selected.svg'
import { ReactComponent as ContentCardPanelDefault } from './contentCard/content-card-panel-default.svg'
import { ReactComponent as ContentCardDefault } from './contentCard/default.svg'
import { ReactComponent as ContentCardSelected } from './contentCard/selected.svg'
import { ReactComponent as CopyDefault } from './copy/default.svg'
import { ReactComponent as CopySelected } from './copy/selected.svg'
import { ReactComponent as CreatedDateDefault } from './createdDate/default.svg'
import { ReactComponent as CreatedDateSelected } from './createdDate/selected.svg'
import { ReactComponent as CrossedCircleDefault } from './crossedCircle/default.svg'
import { ReactComponent as CuphDefault } from './cup/default.svg'
import { ReactComponent as DashboardDefault } from './dashboard/default.svg'
import { ReactComponent as DashboardSelected } from './dashboard/selected.svg'
import { ReactComponent as DataCollectionDefault } from './dataCollection/default.svg'
import { ReactComponent as DeactivateDefault } from './deactivate/default.svg'
import { ReactComponent as DisplayDefault } from './display/default.svg'
import { ReactComponent as DisplaySelected } from './display/selected.svg'
import { ReactComponent as DocumentAwaitingDefault } from './documentAwaiting/default.svg'
import { ReactComponent as DoneDefault } from './done/default.svg'
import { ReactComponent as DoneSelected } from './done/selected.svg'
import { ReactComponent as DownloadDefault } from './download/default.svg'
import { ReactComponent as DownloadSelected } from './download/selected.svg'
import { ReactComponent as ECommerceDefault } from './eCommerce/default.svg'
import { ReactComponent as ECommerceSelected } from './eCommerce/default.svg'
import { ReactComponent as ShoppingCartDefault } from './eCommerce/shoppingCartDefault.svg'
import { ReactComponent as EditDefault } from './edit/default.svg'
import { ReactComponent as EditSelected } from './edit/selected.svg'
import { ReactComponent as EmailDefault } from './email/default.svg'
import { ReactComponent as EmailInboxDefault } from './email/inbox.svg'
import { ReactComponent as EmailSelected } from './email/selected.svg'
import { ReactComponent as EmailFlyDefault } from './emailFly/default.svg'
import { ReactComponent as EncryptedDefault } from './encrypted/default.svg'
import { ReactComponent as ExperimentDefault } from './experiment/default.svg'
import { ReactComponent as ExperimentReaction } from './experiment/reaction.svg'
import { ReactComponent as ExportDefault } from './export/default.svg'
import { ReactComponent as ExportSelected } from './export/selected.svg'
import { ReactComponent as FacebookDefault } from './facebook/default.svg'
import { ReactComponent as FacebookSquareDefault } from './facebook/facebook-square-default.svg'
import { ReactComponent as FacebookSelected } from './facebook/selected.svg'
import { ReactComponent as FavoriteDefault } from './favorite/default.svg'
import { ReactComponent as FavoriteSelected } from './favorite/selected.svg'
import { ReactComponent as FileDefault } from './file/default.svg'
import { ReactComponent as FilterArrowsDefault } from './filterArrows/filterArrows.svg'
import { ReactComponent as FiltersDefault } from './filters/default.svg'
import { ReactComponent as FilterHubDefault } from './filters/filter-hub.svg'
import { ReactComponent as FiltersSelected } from './filters/selected.svg'
import { ReactComponent as FiltersCenteredDefault } from './filtersCentered/default.svg'
import { ReactComponent as FiltersCenteredSelected } from './filtersCentered/selected.svg'
import { ReactComponent as FolderDefault } from './folder/default.svg'
import { ReactComponent as FullscreenDefault } from './fullscreen/default.svg'
import { ReactComponent as FullscreenSelected } from './fullscreen/selected.svg'
import { ReactComponent as GoogleDefault } from './google/default.svg'
import { ReactComponent as GoogleCircularDefault } from './google/google-circular-default.svg'
import { ReactComponent as GoogleSelected } from './google/selected.svg'
import { ReactComponent as GridDefault } from './grid/default.svg'
import { ReactComponent as GridSelected } from './grid/selected.svg'
import { ReactComponent as HorizontalLineDefault } from './horizontalLine/default.svg'
import { ReactComponent as ImageDefault } from './image/default.svg'
import { ReactComponent as InAppDefault } from './inApp/default.svg'
import { ReactComponent as MobileInAppDefault } from './inApp/mobile-in-app-default.svg'
import { ReactComponent as InAppSelected } from './inApp/selected.svg'
import { ReactComponent as InfoDefault } from './info/default.svg'
import { ReactComponent as InfoCircleDefault } from './info/info-circle-default.svg'
import { ReactComponent as InfoSelected } from './info/selected.svg'
import { ReactComponent as InProgressDefault } from './inProgress/default.svg'
import { ReactComponent as InProgressSelected } from './inProgress/selected.svg'
import { ReactComponent as InputResultsDefault } from './inputResults/default.svg'
import { ReactComponent as InputResultsSelected } from './inputResults/selected.svg'
import { ReactComponent as InsightsDefault } from './insights/default.svg'
import { ReactComponent as InsightsSelected } from './insights/selected.svg'
import { ReactComponent as InstagramDefault } from './instagram/default.svg'
import { ReactComponent as InstagramSquareDefault } from './instagram/instagram-square-default.svg'
import { ReactComponent as InstagramSelected } from './instagram/selected.svg'
import { ReactComponent as LandingPageDefault } from './landingPage/default.svg'
import { ReactComponent as LandingPageSelected } from './landingPage/selected.svg'
import { ReactComponent as LanguageSetupDefault } from './language/default.svg'
import { ReactComponent as LineArrowRightDefault } from './lineArrowRight/default.svg'
import { ReactComponent as LineChartDefault } from './lineChart/default.svg'
import { ReactComponent as ListDefault } from './list/default.svg'
import { ReactComponent as ListSelected } from './list/selected.svg'
import { ReactComponent as ListToggleDefault } from './listToggle/closed.svg'
import { ReactComponent as ListToggleSelected } from './listToggle/open.svg'
import { ReactComponent as LogoutDefault } from './logout/default.svg'
import { ReactComponent as LogoutSelected } from './logout/selected.svg'
import { ReactComponent as MagicDefault } from './magic/default.svg'
import { ReactComponent as MagicSelected } from './magic/selected.svg'
import { ReactComponent as Magic2Default } from './magic2/default.svg'
import { ReactComponent as Magic2Selected } from './magic2/selected.svg'
import { ReactComponent as Magic3Default } from './magic3/default.svg'
import { ReactComponent as Magic3Selected } from './magic3/selected.svg'
import { ReactComponent as MenuDefault } from './menu/default.svg'
import { ReactComponent as MenuSelected } from './menu/selected.svg'
import { ReactComponent as MenuHideDefault } from './menuHide/default.svg'
import { ReactComponent as MenuShowDefault } from './menuShow/default.svg'
import { ReactComponent as MessageDefault } from './message/default.svg'
import { ReactComponent as MessageAlignTextDefault } from './messageAlignText/default.svg'
import { ReactComponent as MessageBubbleDefault } from './messageBubble/default.svg'
import { ReactComponent as MinusDefault } from './minus/default.svg'
import { ReactComponent as MissingDataDefault } from './missingData/default.svg'
import { ReactComponent as MissingDataIvertedColorsDefault } from './missingData/inverted-colors.svg'
import { ReactComponent as MissingDataSelected } from './missingData/selected.svg'
import { ReactComponent as MoreLikeThisDefault } from './moreLikeThis/default.svg'
import { ReactComponent as MoreLikeThisSelected } from './moreLikeThis/selected.svg'
import { ReactComponent as MoreOptionsDefault } from './moreOptions/default.svg'
import { ReactComponent as MoreOptionsSelected } from './moreOptions/selected.svg'
import { ReactComponent as MoreOptionsVerticalDefault } from './moreOptionsVertical/default.svg'
import { ReactComponent as MoveDefault } from './move/default.svg'
import { ReactComponent as MoveSelected } from './move/selected.svg'
import { ReactComponent as NotificationBell } from './notification/bell.svg'
import { ReactComponent as PerformanceDefault } from './performance/default.svg'
import { ReactComponent as PerformanceSelected } from './performance/selected.svg'
import { ReactComponent as PersonaDefault } from './persona/default.svg'
import { ReactComponent as PreviewDefault } from './preview/default.svg'
import { ReactComponent as ProfileDefault } from './profile/default.svg'
import { ReactComponent as ProfileSelected } from './profile/selected.svg'
import { ReactComponent as ProjectsDefault } from './projects/default.svg'
import { ReactComponent as ProjectsSelected } from './projects/selected.svg'
import { ReactComponent as PushDefault } from './push/default.svg'
import { ReactComponent as MobilePushDefault } from './push/mobile-push-default.svg'
import { ReactComponent as PushSelected } from './push/selected.svg'
import { ReactComponent as RadioDefault } from './radio/default.svg'
import { ReactComponent as RadioSelected } from './radio/selected.svg'
import { ReactComponent as RoundedRectangleDefault } from './rectangle/rounded-rectangle.svg'
import { ReactComponent as RefreshDefault } from './refresh/default.svg'
import { ReactComponent as RejectDefault } from './reject/default.svg'
import { ReactComponent as ReorderDownDefault } from './reorderDown/default.svg'
import { ReactComponent as ReplaceDefault } from './replace/default.svg'
import { ReactComponent as ReportDefault } from './report/default.svg'
import { ReactComponent as ReportsDefault } from './reports/default.svg'
import { ReactComponent as ReportsSelected } from './reports/selected.svg'
import { ReactComponent as SearchDefault } from './search/default.svg'
import { ReactComponent as SearchSelected } from './search/selected.svg'
import { ReactComponent as SentSelected } from './sent/selected.svg'
import { ReactComponent as SettingsDefault } from './settings/default.svg'
import { ReactComponent as SettingsSelected } from './settings/selected.svg'
import { ReactComponent as SettingsCogDefault } from './settings/settings-cog-default.svg'
import { ReactComponent as SidePanelDefault } from './sidePanel/default.svg'
import { ReactComponent as SidePanelSelected } from './sidePanel/selected.svg'
import { ReactComponent as SidePanelHideDefault } from './sidePanelHide/default.svg'
import { ReactComponent as SidePanelHideSelected } from './sidePanelHide/selected.svg'
import { ReactComponent as SidePanelShowDefault } from './sidePanelShow/default.svg'
import { ReactComponent as SidePanelShowSelected } from './sidePanelShow/selected.svg'
import { ReactComponent as SmallTickDefault } from './smallTick/default.svg'
import { ReactComponent as SmallTickSelected } from './smallTick/selected.svg'
import { ReactComponent as SmsDefault } from './sms/default.svg'
import { ReactComponent as SmsSelected } from './sms/selected.svg'
import { ReactComponent as SmsLoveDefault } from './smsLove/default.svg'
import { ReactComponent as SmsLoveSelected } from './smsLove/selected.svg'
import { ReactComponent as SortDefault } from './sort/default.svg'
import { ReactComponent as SortAscDefault } from './sort/sort-ascending.svg'
import { ReactComponent as SortDescDefault } from './sort/sort-descending.svg'
import { ReactComponent as SortArrowAscDefault } from './sortArrowAsc/default.svg'
import { ReactComponent as SortArrowDescDefault } from './sortArrowDesc/default.svg'
import { ReactComponent as SpinnerDefault } from './spinner/default.svg'
import { ReactComponent as SquareDefault } from './square/default.svg'
import { ReactComponent as StartFlagDefault } from './startFlag/default.svg'
import { ReactComponent as StopDefault } from './stop/default.svg'
import { ReactComponent as SubtractDefault } from './subtract/default.svg'
import { ReactComponent as SwitchDefault } from './switch/default.svg'
import { ReactComponent as SwitchSelected } from './switch/selected.svg'
import { ReactComponent as TestDummyDefault } from './testDummy/default.svg'
import { ReactComponent as ThumbDefault } from './thumb/default.svg'
import { ReactComponent as TickDefault } from './tick/default.svg'
import { ReactComponent as TickSelected } from './tick/selected.svg'
import { ReactComponent as TimeDefault } from './time/default.svg'
import { ReactComponent as TimeSelected } from './time/selected.svg'
import { ReactComponent as TimeZoneDefault } from './timeZone/default.svg'
import { ReactComponent as TriangleDefault } from './triangle/default.svg'
import { ReactComponent as TwitterDefault } from './twitter/default.svg'
import { ReactComponent as TwitterSelected } from './twitter/selected.svg'
import { ReactComponent as UploadDefault } from './upload/default.svg'
import { ReactComponent as UploadSelected } from './upload/selected.svg'
import { ReactComponent as UsageDefault } from './usage/default.svg'
import { ReactComponent as UsageSelected } from './usage/selected.svg'
import { ReactComponent as UsersDefault } from './users/default.svg'
import { ReactComponent as UsersSelected } from './users/selected.svg'
import { ReactComponent as WebsiteDefault } from './website/default.svg'
import { ReactComponent as WebsiteSelected } from './website/selected.svg'
import { ReactComponent as WebPageDefault } from './website/web-page-default.svg'
import { ReactComponent as WebPushDefault } from './website/web-push-default.svg'
import type { IconPropsBase, Size, State } from './Icon'
import Icon from './Icon'

const Account = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={AccountDefault}
    selectedSvg={AccountSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Account }

const Accounts = (props: IconPropsBase) => (
  <Icon defaultSvg={AccountsDefault} selectedSvg={AccountsDefault} {...props} />
)
export { Accounts }

const ChevronUp = (props: IconPropsBase) => (
  <Icon
    defaultSvg={ChevronUpDefault}
    selectedSvg={ChevronUpDefault}
    {...props}
  />
)
export { ChevronUp }

const ChevronDown = (props: IconPropsBase) => (
  <Icon
    defaultSvg={ChevronDownDefault}
    selectedSvg={ChevronDownDefault}
    {...props}
  />
)
export { ChevronDown }

const ChevronLeftStart = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ChevronLeftStartDefault}
    state={state}
    size={size}
    className={className}
  />
)
export { ChevronLeftStart }

const ChevronLeft = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ChevronLeftDefault}
    state={state}
    size={size}
    className={className}
  />
)
export { ChevronLeft }

const ChevronRightEnd = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ChevronRightEndDefault}
    state={state}
    size={size}
    className={className}
  />
)
export { ChevronRightEnd }

const ChevronRight = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ChevronRightDefault}
    state={state}
    size={size}
    className={className}
  />
)
export { ChevronRight }

const Add = (props: IconPropsBase) => (
  <Icon defaultSvg={AddDefault} selectedSvg={AddSelected} {...props} />
)
export { Add }

const AiContent = (props: IconPropsBase) => (
  <Icon
    defaultSvg={AiContentDefault}
    selectedSvg={AiContentDefault}
    {...props}
  />
)
export { AiContent }

const Alert = (props: IconPropsBase) => (
  <Icon defaultSvg={AlertDefault} {...props} />
)
export { Alert }

const AlertCircle = (props: IconPropsBase) => (
  <Icon defaultSvg={AlertCircleDefault} {...props} />
)
export { AlertCircle }

const Approval = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ApprovalDefault}
    selectedSvg={ApprovalSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Approval }

const ArrowDown = (props: IconPropsBase) => (
  <Icon
    defaultSvg={ArrowDownDefault}
    selectedSvg={ArrowDownSelected}
    {...props}
  />
)
export { ArrowDown }

const ArrowDropdownDown = (props: IconPropsBase) => (
  <Icon
    defaultSvg={arrowDropdownDownDefault}
    selectedSvg={arrowDropdownDownDefault}
    {...props}
  />
)
export { ArrowDropdownDown }

const ArrowLeft = ({
  state,
  size,
  className,
  isDefaultColor,
}: {
  state?: State
  size?: Size
  className?: string
  isDefaultColor?: boolean
}) => (
  <Icon
    defaultSvg={ArrowLeftDefault}
    selectedSvg={ArrowLeftSelected}
    state={state}
    size={size}
    isDefaultColor={isDefaultColor}
    className={className}
  />
)
export { ArrowLeft }

const ArrowRight = (props: IconPropsBase) => (
  <Icon
    defaultSvg={ArrowRightDefault}
    selectedSvg={ArrowRightSelected}
    {...props}
  />
)
export { ArrowRight }

const ArrowSml = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ArrowSmlDefault}
    selectedSvg={ArrowSmlSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { ArrowSml }

const ArrowUp = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ArrowUpDefault}
    selectedSvg={ArrowUpSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { ArrowUp }

const Article = (props: IconPropsBase) => (
  <Icon defaultSvg={ArticleDefault} selectedSvg={ArticleSelected} {...props} />
)
export { Article }

const Bin = (props: IconPropsBase) => (
  <Icon defaultSvg={BinDefault} selectedSvg={BinSelected} {...props} />
)
export { Bin }

const BinDestructive = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={BinDestructiveSvg}
    state={state}
    size={size}
    className={className}
  />
)
export { BinDestructive }

const Calendar = (props: IconPropsBase) => (
  <Icon
    defaultSvg={CalendarDefault}
    selectedSvg={CalendarSelected}
    {...props}
  />
)
export { Calendar }

const Campaign = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={CampaignDefault}
    selectedSvg={CampaignSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Campaign }

const CheckCampaign = (props: IconPropsBase) => (
  <Icon
    defaultSvg={CheckCampaignDefault}
    selectedSvg={CheckCampaignDefault}
    {...props}
  />
)
export { CheckCampaign }

const CampaignType = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={CampaignTypeDefault}
    selectedSvg={CampaignTypeSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { CampaignType }

const Cancel = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={CancelDefault}
    state={state}
    size={size}
    className={className}
  />
)
export { Cancel }

const CascadeFlow = (props: IconPropsBase) => (
  <Icon defaultSvg={CascadeFlowDefault} {...props} />
)
export { CascadeFlow }

const CheckCircle = (props: IconPropsBase) => (
  <Icon defaultSvg={CheckCircleDefault} {...props} />
)
export { CheckCircle }

const CheckCircleInverted = (props: IconPropsBase) => (
  <Icon defaultSvg={CheckCircleInvertedDefault} {...props} />
)
export { CheckCircleInverted }

const Checkmark = (props: IconPropsBase) => (
  <Icon defaultSvg={CheckmarkDefault} {...props} />
)
export { Checkmark }

const Campaigns = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={CampaignsDefault}
    selectedSvg={CampaignsSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Campaigns }

const Checkbox = (props: IconPropsBase) => (
  <Icon
    defaultSvg={CheckboxDefault}
    selectedSvg={CheckboxSelected}
    {...props}
  />
)
export { Checkbox }

const CheckboxIndeterminate = (props: IconPropsBase) => (
  <Icon
    defaultSvg={CheckboxIndeterminateDefault}
    selectedSvg={CheckboxIndeterminateDefault}
    {...props}
  />
)
export { CheckboxIndeterminate }

const Clipboard = (props: IconPropsBase) => (
  <Icon defaultSvg={ClipboardDefault} {...props} />
)
export { Clipboard }

const Clock = (props: IconPropsBase) => (
  <Icon defaultSvg={ClockDefault} {...props} />
)
export { Clock }

const Close = (props: IconPropsBase) => (
  <Icon defaultSvg={CloseDefault} selectedSvg={CloseSelected} {...props} />
)
export { Close }

const Complete = (props: IconPropsBase) => (
  <Icon
    defaultSvg={CompleteDefault}
    selectedSvg={CompleteSelected}
    {...props}
  />
)
export { Complete }

const Connect = ({
  state,
  size,
  className,
  isDefaultColor,
}: {
  state?: State
  size?: Size
  className?: string
  isDefaultColor?: boolean
}) => (
  <Icon
    defaultSvg={ConnectDefault}
    selectedSvg={ConnectSelected}
    state={state}
    size={size}
    className={className}
    isDefaultColor={isDefaultColor}
  />
)
export { Connect }

const ContentCard = (props: IconPropsBase) => (
  <Icon
    defaultSvg={ContentCardDefault}
    selectedSvg={ContentCardSelected}
    {...props}
  />
)

export { ContentCard }

const Copy = (props: IconPropsBase) => (
  <Icon defaultSvg={CopyDefault} selectedSvg={CopySelected} {...props} />
)
export { Copy }

const CreatedDate = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={CreatedDateDefault}
    selectedSvg={CreatedDateSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { CreatedDate }

const Dashboard = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={DashboardDefault}
    selectedSvg={DashboardSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Dashboard }

const Display = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={DisplayDefault}
    selectedSvg={DisplaySelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Display }

const Done = ({
  state,
  size,
  className,
  isDefaultColor,
}: {
  state?: State
  size?: Size
  className?: string
  isDefaultColor?: boolean
}) => (
  <Icon
    defaultSvg={DoneDefault}
    selectedSvg={DoneSelected}
    state={state}
    size={size}
    className={className}
    isDefaultColor={isDefaultColor}
  />
)
export { Done }

const Download = (props: IconPropsBase) => (
  <Icon
    defaultSvg={DownloadDefault}
    selectedSvg={DownloadSelected}
    {...props}
  />
)
export { Download }

const Upload = (props: IconPropsBase) => (
  <Icon defaultSvg={UploadDefault} selectedSvg={UploadSelected} {...props} />
)
export { Upload }

const ECommerce = (props: IconPropsBase) => (
  <Icon
    defaultSvg={ECommerceDefault}
    selectedSvg={ECommerceSelected}
    {...props}
  />
)
export { ECommerce }

const Edit = (props: IconPropsBase) => (
  <Icon defaultSvg={EditDefault} selectedSvg={EditSelected} {...props} />
)
export { Edit }

const Email = (props: IconPropsBase) => (
  <Icon defaultSvg={EmailDefault} selectedSvg={EmailSelected} {...props} />
)
export { Email }

const EmailFly = (props: IconPropsBase) => (
  <Icon defaultSvg={EmailFlyDefault} selectedSvg={EmailFlyDefault} {...props} />
)
export { EmailFly }

const Encrypted = (props: IconPropsBase) => (
  <Icon
    defaultSvg={EncryptedDefault}
    selectedSvg={EncryptedDefault}
    {...props}
  />
)
export { Encrypted }

const Experiment = (props: IconPropsBase) => (
  <Icon
    defaultSvg={ExperimentDefault}
    selectedSvg={ExperimentDefault}
    {...props}
  />
)
export { Experiment }

const Reaction = (props: IconPropsBase) => (
  <Icon
    defaultSvg={ExperimentReaction}
    selectedSvg={ExperimentReaction}
    {...props}
  />
)
export { Reaction }

const Export = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ExportDefault}
    selectedSvg={ExportSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Export }

const Facebook = (props: IconPropsBase) => (
  <Icon
    defaultSvg={FacebookDefault}
    selectedSvg={FacebookSelected}
    {...props}
  />
)
export { Facebook }

const Favorite = (props: IconPropsBase) => (
  <Icon
    defaultSvg={FavoriteDefault}
    selectedSvg={FavoriteSelected}
    {...props}
  />
)
export { Favorite }

const File = (props: IconPropsBase) => (
  <Icon defaultSvg={FileDefault} {...props} />
)
export { File }

const Filters = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={FiltersDefault}
    selectedSvg={FiltersSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Filters }

const FilterArrows = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={FilterArrowsDefault}
    state={state}
    size={size}
    className={className}
  />
)
export { FilterArrows }

const FiltersCentered = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={FiltersCenteredDefault}
    selectedSvg={FiltersCenteredSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { FiltersCentered }

const Folder = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={FolderDefault}
    state={state}
    size={size}
    className={className}
  />
)
export { Folder }

const Fullscreen = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={FullscreenDefault}
    selectedSvg={FullscreenSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Fullscreen }

const Google = (props: IconPropsBase) => (
  <Icon defaultSvg={GoogleDefault} selectedSvg={GoogleSelected} {...props} />
)
export { Google }

const Grid = (props: IconPropsBase) => (
  <Icon defaultSvg={GridDefault} selectedSvg={GridSelected} {...props} />
)
export { Grid }

const InApp = (props: IconPropsBase) => (
  <Icon defaultSvg={InAppDefault} selectedSvg={InAppSelected} {...props} />
)
export { InApp }

const InProgress = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={InProgressDefault}
    selectedSvg={InProgressSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { InProgress }

const Info = (props: IconPropsBase) => (
  <Icon defaultSvg={InfoDefault} selectedSvg={InfoSelected} {...props} />
)
export { Info }

const InputResults = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={InputResultsDefault}
    selectedSvg={InputResultsSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { InputResults }

const Insights = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={InsightsDefault}
    selectedSvg={InsightsSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Insights }

const Instagram = (props: IconPropsBase) => (
  <Icon
    defaultSvg={InstagramDefault}
    selectedSvg={InstagramSelected}
    {...props}
  />
)
export { Instagram }

const LanguageSetup = (props: IconPropsBase) => (
  <Icon
    defaultSvg={LanguageSetupDefault}
    selectedSvg={LanguageSetupDefault}
    {...props}
  />
)
export { LanguageSetup }

const LandingPage = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={LandingPageDefault}
    selectedSvg={LandingPageSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { LandingPage }

const LineArrowRight = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={LineArrowRightDefault}
    state={state}
    size={size}
    className={className}
  />
)
export { LineArrowRight }

const LineChart = (props: IconPropsBase) => (
  <Icon
    defaultSvg={LineChartDefault}
    selectedSvg={LineChartDefault}
    {...props}
  />
)
export { LineChart }

const ListIcon = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ListDefault}
    selectedSvg={ListSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { ListIcon }

const ListToggle = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ListToggleDefault}
    selectedSvg={ListToggleSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { ListToggle }

const DropdownToggle = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={arrowDropdownUpDefault}
    selectedSvg={arrowDropdownDownSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { DropdownToggle }

const Logout = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={LogoutDefault}
    selectedSvg={LogoutSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Logout }

const Magic = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={MagicDefault}
    selectedSvg={MagicSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Magic }

const Magic2 = (props: IconPropsBase) => (
  <Icon defaultSvg={Magic2Default} selectedSvg={Magic2Selected} {...props} />
)
export { Magic2 }

const Magic3 = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={Magic3Default}
    selectedSvg={Magic3Selected}
    state={state}
    size={size}
    className={className}
  />
)
export { Magic3 }

const Menu = ({
  state,
  size,
  className,
  isDefaultColor,
  'data-testid': dataTestId,
}: {
  state?: State
  size?: Size
  className?: string
  isDefaultColor?: boolean
  'data-testid'?: string
}) => (
  <Icon
    defaultSvg={MenuDefault}
    selectedSvg={MenuSelected}
    state={state}
    size={size}
    className={className}
    isDefaultColor={isDefaultColor}
    data-testid={dataTestId}
  />
)
export { Menu }

const MenuHide = (props: IconPropsBase) => (
  <Icon defaultSvg={MenuHideDefault} selectedSvg={MenuHideDefault} {...props} />
)
export { MenuHide }

const MenuShow = (props: IconPropsBase) => (
  <Icon defaultSvg={MenuShowDefault} selectedSvg={MenuShowDefault} {...props} />
)
export { MenuShow }

const MessageBubble = (props: IconPropsBase) => (
  <Icon
    defaultSvg={MessageBubbleDefault}
    selectedSvg={MessageBubbleDefault}
    {...props}
  />
)
export { MessageBubble }

const Minus = (props: IconPropsBase) => (
  <Icon defaultSvg={MinusDefault} selectedSvg={MinusDefault} {...props} />
)
export { Minus }

const MissingData = (props: IconPropsBase) => (
  <Icon
    defaultSvg={MissingDataDefault}
    selectedSvg={MissingDataSelected}
    {...props}
  />
)
export { MissingData }

const MissingDataInverted = (props: IconPropsBase) => (
  <Icon defaultSvg={MissingDataIvertedColorsDefault} {...props} />
)
export { MissingDataInverted }

const MoreLikeThis = (props: IconPropsBase) => (
  <Icon
    defaultSvg={MoreLikeThisDefault}
    selectedSvg={MoreLikeThisSelected}
    {...props}
  />
)
export { MoreLikeThis }

const MoreOptions = (props: IconPropsBase) => (
  <Icon
    defaultSvg={MoreOptionsDefault}
    selectedSvg={MoreOptionsSelected}
    {...props}
  />
)
export { MoreOptions }

const MoreOptionsVertical = (props: IconPropsBase) => (
  <Icon
    defaultSvg={MoreOptionsVerticalDefault}
    selectedSvg={MoreOptionsVerticalDefault}
    {...props}
  />
)
export { MoreOptionsVertical }

const Move = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={MoveDefault}
    selectedSvg={MoveSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Move }

const Performance = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={PerformanceDefault}
    selectedSvg={PerformanceSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Performance }

const Persona = (props: IconPropsBase) => (
  <Icon defaultSvg={PersonaDefault} {...props} />
)

export { Persona }

const Profile = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ProfileDefault}
    selectedSvg={ProfileSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Profile }

const Projects = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ProjectsDefault}
    selectedSvg={ProjectsSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Projects }

const Push = (props: IconPropsBase) => (
  <Icon defaultSvg={PushDefault} selectedSvg={PushSelected} {...props} />
)
export { Push }

const Radio = (props: IconPropsBase) => (
  <Icon defaultSvg={RadioDefault} selectedSvg={RadioSelected} {...props} />
)
export { Radio }

const Refresh = (props: IconPropsBase) => (
  <Icon defaultSvg={RefreshDefault} {...props} />
)
export { Refresh }

const ReorderDown = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={ReorderDownDefault}
    selectedSvg={ReorderDownDefault}
    state={state}
    size={size}
    className={className}
  />
)
export { ReorderDown }

const Reports = (props: IconPropsBase) => (
  <Icon defaultSvg={ReportsDefault} selectedSvg={ReportsSelected} {...props} />
)
export { Reports }

const StartFlag = (props: IconPropsBase) => (
  <Icon
    defaultSvg={StartFlagDefault}
    selectedSvg={StartFlagDefault}
    {...props}
  />
)
export { StartFlag }

const Search = (props: IconPropsBase) => (
  <Icon defaultSvg={SearchDefault} selectedSvg={SearchSelected} {...props} />
)
export { Search }

const Sent = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={SentSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Sent }

const Settings = (props: IconPropsBase) => (
  <Icon
    defaultSvg={SettingsDefault}
    selectedSvg={SettingsSelected}
    {...props}
  />
)
export { Settings }

const SidePanel = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={SidePanelDefault}
    selectedSvg={SidePanelSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { SidePanel }

const SidePanelHide = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={SidePanelHideDefault}
    selectedSvg={SidePanelHideSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { SidePanelHide }

const SidePanelShow = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={SidePanelShowDefault}
    selectedSvg={SidePanelShowSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { SidePanelShow }

const SmallTick = (props: IconPropsBase) => (
  <Icon
    data-testid="small-tick"
    defaultSvg={SmallTickDefault}
    selectedSvg={SmallTickSelected}
    {...props}
  />
)
export { SmallTick }

const Sms = (props: IconPropsBase) => (
  <Icon defaultSvg={SmsDefault} selectedSvg={SmsSelected} {...props} />
)
export { Sms }

const SmsLove = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={SmsLoveDefault}
    selectedSvg={SmsLoveSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { SmsLove }

const SortArrowAsc = (props: IconPropsBase) => (
  <Icon
    defaultSvg={SortArrowAscDefault}
    selectedSvg={SortArrowAscDefault}
    {...props}
  />
)
export { SortArrowAsc }

const SortArrowDesc = (props: IconPropsBase) => (
  <Icon
    defaultSvg={SortArrowDescDefault}
    selectedSvg={SortArrowDescDefault}
    {...props}
  />
)
export { SortArrowDesc }

const Spinner = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={SpinnerDefault}
    state={state}
    size={size}
    className={className}
  />
)
export { Spinner }

const Stop = (props: IconPropsBase) => (
  <Icon defaultSvg={StopDefault} {...props} />
)
export { Stop }

const Subtract = (props: IconPropsBase) => (
  <Icon defaultSvg={SubtractDefault} {...props} />
)
export { Subtract }

const Switch = ({
  state,
  size,
  className,
  isDefaultColor,
  ...props
}: IconPropsBase) => (
  <Icon
    isDefaultColor={isDefaultColor}
    defaultSvg={SwitchDefault}
    selectedSvg={SwitchSelected}
    state={state}
    size={size}
    className={className}
    {...props}
  />
)
export { Switch }

const Tick = (props: IconPropsBase) => (
  <Icon defaultSvg={TickDefault} selectedSvg={TickSelected} {...props} />
)
export { Tick }

const TestDummy = (props: IconPropsBase) => (
  <Icon defaultSvg={TestDummyDefault} {...props} />
)
export { TestDummy }

const Thumb = (props: IconPropsBase) => (
  <Icon data-testid="thumb-icon" defaultSvg={ThumbDefault} {...props} />
)
export { Thumb }

const Usage = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={UsageDefault}
    selectedSvg={UsageSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Usage }

const Users = (props: IconPropsBase) => (
  <Icon defaultSvg={UsersDefault} selectedSvg={UsersSelected} {...props} />
)
export { Users }

const Time = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={TimeDefault}
    selectedSvg={TimeSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Time }

const DataCollection = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={DataCollectionDefault}
    state={state}
    size={size}
    className={className}
  />
)
export { DataCollection }

const TimeZone = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={TimeZoneDefault}
    state={state}
    size={size}
    className={className}
  />
)
export { TimeZone }

const Twitter = ({
  state,
  size,
  className,
}: {
  state?: State
  size?: Size
  className?: string
}) => (
  <Icon
    defaultSvg={TwitterDefault}
    selectedSvg={TwitterSelected}
    state={state}
    size={size}
    className={className}
  />
)
export { Twitter }

const Archive = (props: IconPropsBase) => (
  <Icon defaultSvg={ArchiveDefault} {...props} />
)

export { Archive }

const Deactivate = (props: IconPropsBase) => (
  <Icon defaultSvg={DeactivateDefault} {...props} />
)

export { Deactivate }

const CloseSmall = (props: IconPropsBase) => (
  <Icon
    defaultSvg={CloseSmallDefault}
    selectedSvg={CloseSmallSelected}
    {...props}
  />
)

export { CloseSmall }

const Website = (props: IconPropsBase) => (
  <Icon defaultSvg={WebsiteDefault} selectedSvg={WebsiteSelected} {...props} />
)

export { Website }

const BellNotification = (props: IconPropsBase) => (
  <Icon defaultSvg={NotificationBell} {...props} />
)

export { BellNotification }

const Reject = (props: IconPropsBase) => (
  <Icon data-testid="reject-icon" defaultSvg={RejectDefault} {...props} />
)

export { Reject }

const CrossedCircle = (props: IconPropsBase) => (
  <Icon
    data-testid="crossed-circle-icon"
    defaultSvg={CrossedCircleDefault}
    {...props}
  />
)

export { CrossedCircle }

const Square = (props: IconPropsBase) => (
  <Icon data-testid="square-icon" defaultSvg={SquareDefault} {...props} />
)

export { Square }

const ShoppingCart = (props: IconPropsBase) => (
  <Icon
    data-testid="shopping-cart-icon"
    defaultSvg={ShoppingCartDefault}
    {...props}
  />
)

export { ShoppingCart }

const EmailInbox = (props: IconPropsBase) => (
  <Icon
    data-testid="email-inbox-icon"
    defaultSvg={EmailInboxDefault}
    {...props}
  />
)

export { EmailInbox }

const Message = (props: IconPropsBase) => (
  <Icon data-testid="message-icon" defaultSvg={MessageDefault} {...props} />
)

export { Message }

const RoundedRectangle = (props: IconPropsBase) => (
  <Icon
    data-testid="rounded-rectangle-icon"
    defaultSvg={RoundedRectangleDefault}
    {...props}
  />
)

export { RoundedRectangle }

const MessageAlignText = (props: IconPropsBase) => (
  <Icon
    data-testid="message-align-text"
    defaultSvg={MessageAlignTextDefault}
    {...props}
  />
)

export { MessageAlignText }

const Replace = (props: IconPropsBase) => (
  <Icon data-testid="replace" defaultSvg={ReplaceDefault} {...props} />
)

export { Replace }

const CircleSlash = (props: IconPropsBase) => (
  <Icon
    data-testid="rounded-rectangle-icon"
    defaultSvg={CircleSlashDefault}
    {...props}
  />
)

export { CircleSlash }

const WebPage = (props: IconPropsBase) => (
  <Icon
    data-testid="web-dashboard-icon"
    defaultSvg={WebPageDefault}
    {...props}
  />
)

export { WebPage }

const FacebookSquare = (props: IconPropsBase) => (
  <Icon
    data-testid="facebook-square-icon"
    defaultSvg={FacebookSquareDefault}
    {...props}
  />
)

export { FacebookSquare }

const InstagramSquare = (props: IconPropsBase) => (
  <Icon
    data-testid="instagram-square-icon"
    defaultSvg={InstagramSquareDefault}
    {...props}
  />
)

export { InstagramSquare }

const MobilePush = (props: IconPropsBase) => (
  <Icon
    data-testid="mobile-push-icon"
    defaultSvg={MobilePushDefault}
    {...props}
  />
)

export { MobilePush }

const MobileInApp = (props: IconPropsBase) => (
  <Icon
    data-testid="mobile-in-app-icon"
    defaultSvg={MobileInAppDefault}
    {...props}
  />
)

export { MobileInApp }

const ContentCardSquare = (props: IconPropsBase) => (
  <Icon
    data-testid="content-card-panel-icon"
    defaultSvg={ContentCardPanelDefault}
    {...props}
  />
)

export { ContentCardSquare }

const WebPush = (props: IconPropsBase) => (
  <Icon data-testid="web-push-icon" defaultSvg={WebPushDefault} {...props} />
)

export { WebPush }

const GoogleCircular = (props: IconPropsBase) => (
  <Icon
    data-testid="google-circular-icon"
    defaultSvg={GoogleCircularDefault}
    {...props}
  />
)

export { GoogleCircular }

const CloseSquare = (props: IconPropsBase) => (
  <Icon
    data-testid="close-square-icon"
    defaultSvg={CloseSquareDefault}
    {...props}
  />
)

export { CloseSquare }

const Check = (props: IconPropsBase) => (
  <Icon data-testid="check-icon" defaultSvg={CheckDefault} {...props} />
)

export { Check }

const AddSquare = (props: IconPropsBase) => (
  <Icon
    data-testid="add-square-icon"
    defaultSvg={AddSquareDefault}
    {...props}
  />
)

export { AddSquare }

const ChevronDownSquare = (props: IconPropsBase) => (
  <Icon
    data-testid="chevron-down-square-icon"
    defaultSvg={ChevronDownSquareDefault}
    {...props}
  />
)

export { ChevronDownSquare }

const ChevronUpSquare = (props: IconPropsBase) => (
  <Icon
    data-testid="chevron-up-square-icon"
    defaultSvg={ChevronUpSquareDefault}
    {...props}
  />
)

export { ChevronUpSquare }

const Cup = (props: IconPropsBase) => (
  <Icon data-testid="cup-icon" defaultSvg={CuphDefault} {...props} />
)

export { Cup }

const Report = (props: IconPropsBase) => (
  <Icon data-testid="report-icon" defaultSvg={ReportDefault} {...props} />
)

export { Report }

const DocumentAwaiting = (props: IconPropsBase) => (
  <Icon
    data-testid="document-awaiting-icon"
    defaultSvg={DocumentAwaitingDefault}
    {...props}
  />
)

export { DocumentAwaiting }

const CalendarSquare = (props: IconPropsBase) => (
  <Icon
    data-testid="calendar-square-icon"
    defaultSvg={CalendarSquareDefault}
    {...props}
  />
)

export { CalendarSquare }

const InfoCircle = (props: IconPropsBase) => (
  <Icon
    data-testid="info-circle-icon"
    defaultSvg={InfoCircleDefault}
    {...props}
  />
)

export { InfoCircle }

const HorizontalLine = (props: IconPropsBase) => (
  <Icon
    data-testid="horizontal-line-icon"
    defaultSvg={HorizontalLineDefault}
    {...props}
  />
)

export { HorizontalLine }

const ImageIcon = (props: IconPropsBase) => (
  <Icon data-testid="image-icon" defaultSvg={ImageDefault} {...props} />
)

export { ImageIcon }

const AlertCircleInverted = (props: IconPropsBase) => (
  <Icon
    data-testid="alert-circle-inverted-icon"
    defaultSvg={AlertCircleInvertedDefault}
    {...props}
  />
)

export { AlertCircleInverted }

const SettingsCog = (props: IconPropsBase) => (
  <Icon
    data-testid="settings-cog-icon"
    defaultSvg={SettingsCogDefault}
    {...props}
  />
)

export { SettingsCog }

const SidebarLeftMenu = (props: IconPropsBase) => (
  <Icon
    data-testid="sidebar-left-menu-icon"
    defaultSvg={SidebarLeftMenuDefault}
    {...props}
  />
)

export { SidebarLeftMenu }

const SidebarRightMenu = (props: IconPropsBase) => (
  <Icon
    data-testid="sidebar-right-menu-icon"
    defaultSvg={SidebarRightMenuDefault}
    {...props}
  />
)

export { SidebarRightMenu }

const Preview = (props: IconPropsBase) => (
  <Icon data-testid="preview-icon" defaultSvg={PreviewDefault} {...props} />
)

export { Preview }

const Triangle = (props: IconPropsBase) => (
  <Icon data-testid="triangle-icon" defaultSvg={TriangleDefault} {...props} />
)

export { Triangle }

const FilterHub = (props: IconPropsBase) => (
  <Icon
    data-testid="filter-hub-icon"
    defaultSvg={FilterHubDefault}
    {...props}
  />
)

export { FilterHub }

const Sort = (props: IconPropsBase) => (
  <Icon data-testid="sort-icon" defaultSvg={SortDefault} {...props} />
)

export { Sort }

const SortAsc = (props: IconPropsBase) => (
  <Icon data-testid="sort-asc-icon" defaultSvg={SortAscDefault} {...props} />
)

export { SortAsc }

const SortDesc = (props: IconPropsBase) => (
  <Icon data-testid="sort-desc-icon" defaultSvg={SortDescDefault} {...props} />
)

export { SortDesc }
