/* eslint-disable max-lines */
/* eslint-disable react/jsx-key */
// jsx-key rule is disabled due to "'key' is specified more than once,
// so this usage will be overwritten." error
import {
  forwardRef,
  memo,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation, useParams } from 'react-router-dom'
import type {
  CellProps,
  CellValue,
  Column,
  ColumnInstance,
  Row,
  TableInstance,
} from 'react-table'
import {
  useExpanded,
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  useGroupBy,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useExportData } from 'react-table-plugins'
import cx from 'classnames'
import { isNumber } from 'lodash'
import merge from 'lodash/merge'
import LogRocket from 'logrocket'

import Pagination from 'common/components/table/pagination'
import { useAppSelector } from 'common/hooks/redux'

import BaseCell from './cells/Base'
import DefaultCell from './cells/Default'
import DefaultRow from './rows/DefaultRow'
import GroupRows from './rows/GroupRows'
import type {
  OnCellChange,
  OnSwapColumns,
  TableData,
  TableProps,
} from './types/interfaces'
import TableCheckbox from './Checkbox'
import { extendPageRowIndex, getExportFileBlob } from './helpers'
import TableRadio from './Radio'
import Resizer from './Resizer'
import TableHeader from './TableHeader'

import '@szhsin/react-menu/dist/core.css'
import '@szhsin/react-menu/dist/index.css'
import styles from './Table.module.css'

export type TableHandle<T extends object> = {
  exportCsv: () => void
  allColumns: () => ColumnInstance<T>[]
  resetPaging: () => void
}

export const clearTableMetadataCache = () => {
  sessionStorage.removeItem('tableMetadata')
}

export const getCachedTableMetadata = () => {
  let tableMetadata: {
    accountId: string
    tables: { id: string; currentPage: number }[]
  }

  try {
    tableMetadata = JSON.parse(sessionStorage.getItem('tableMetadata') || '{}')
  } catch (error) {
    return clearTableMetadataCache()
  }

  return tableMetadata
}

const filterUrlParams = ({
  url,
  params,
}: {
  url: string
  params: Record<string, string>
}) => {
  return url
    .split('/')
    .map((part) => (Object.values(params).includes(part) ? null : part))
    .filter(Boolean)
    .join('/')
}

const hasCachedTablesMatchedPath = (path: string) => {
  const cachedTableMetadata = getCachedTableMetadata()
  return cachedTableMetadata?.tables?.some((table) =>
    table.id
      .split('/')
      .slice(0, -1)
      .every((tablePathParam) => path.split('/').includes(tablePathParam))
  )
}

export const generateUrlWithPreserveTable = ({
  backLink,
  backUrl,
}: {
  backLink: string | undefined
  backUrl: URL
}): URL => {
  if (hasCachedTablesMatchedPath(backLink ?? '')) {
    backUrl?.searchParams.set('preserveTable', 'true')
  }
  return backUrl
}

function Table<T extends object>(
  {
    columns,
    data,
    onCellChange,
    disablePagination,
    'data-cy': dataCy,
    'data-testid': dataTestId,
    fileName,
    rowSelection = 'disabled',
    selectionHeader,
    onSelectRow,
    onRowClick,
    onSwapColumns,
    globalFilter,
    globalFilterValue,
    updateAllFilterValues,
    isHeaderHidden = false,
    getCheckboxProps,
    className,
    rowClassName,
    disableSubRowArrow,
    generateRowClassName,
    paginateExpandedRows,
    headerClassName,
    isHorizontalSeparatorHidden,
    cacheId,
    ...others
  }: TableProps<T>,
  ref: Ref<TableHandle<T>>
) {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const location = useLocation()
  const tableMetadata = getCachedTableMetadata()
  const params = useParams()
  const staticURLpath = filterUrlParams({
    url: location.pathname,
    params,
  })
  const currentTable = tableMetadata?.tables?.find(
    (table) => table.id === `${staticURLpath}/${cacheId}`
  )
  const searchParams = new URLSearchParams(location.search)
  const hasPreserveTableParam = searchParams.get('preserveTable')

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    pageCount,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    exportData,
    allColumns,
    selectedFlatRows,
    state: {
      pageIndex,
      pageSize,
      columnResizing: { isResizingColumn },
    },
    flatRows,
    rows,
    setGlobalFilter,
    toggleAllRowsSelected,
  } = useTable(
    {
      ...(globalFilter && { globalFilter }),
      columns,
      data,
      pageCount: data.length,
      manualPagination: !!disablePagination,
      initialState: {
        globalFilter: globalFilterValue,
        pageIndex: 0,
        ...others?.initialState,
      },
      selectSubRows: false,
      paginateExpandedRows,
      defaultColumn: {
        Cell: DefaultCell,
        Filter: DefaultColumnFilter,
      },
      // onCellChange isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer
      onCellChange,
      getExportFileBlob: (params) => getExportFileBlob<T>(params),
      getExportFileName: () => fileName ?? 'table_data',
      ...others,
    },

    useFilters,
    useGlobalFilter,
    useGroupBy,
    ...(onSwapColumns !== undefined ? [] : [useSortBy]),
    useExpanded,
    usePagination,
    useRowSelect,
    useExportData,
    useFlexLayout,
    useResizeColumns,
    (hooks) => {
      if (rowSelection !== 'disabled') {
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            className: rowSelection === 'single' ? '' : 'w-min flex-0 min-w-0',
            Header: ({ getToggleAllRowsSelectedProps }) => {
              if (rowSelection === 'multiple') {
                return <TableCheckbox {...getToggleAllRowsSelectedProps()} />
              } else if (rowSelection === 'single') {
                return (
                  <div className="whitespace-nowrap">{selectionHeader}</div>
                )
              }

              return null
            },
            Cell: ({ row }) => (
              <>
                {rowSelection === 'multiple' ? (
                  <BaseCell>
                    <TableCheckbox
                      {...merge(
                        row.getToggleRowSelectedProps(),
                        getCheckboxProps?.(row)
                      )}
                      isDisabled={row.original.isDisabled}
                    />
                  </BaseCell>
                ) : (
                  <BaseCell className="pr-0">
                    <TableRadio
                      {...row.getToggleRowSelectedProps()}
                      onChange={() => {
                        if (selectedFlatRows.length) {
                          toggleAllRowsSelected(false)
                        }
                        row.toggleRowSelected()
                      }}
                      isDisabled={row.original.isDisabled}
                      checked={row.original.checked}
                    />
                  </BaseCell>
                )}
              </>
            ),
          },
          ...columns,
        ])
      }
    }
  )

  const pageIndexRef = useRef(currentTable?.currentPage)

  useEffect(() => {
    if (pageIndexRef.current !== undefined && hasPreserveTableParam) {
      gotoPage(pageIndexRef.current)
    }
  }, [gotoPage, hasPreserveTableParam])

  useEffect(() => {
    if (cacheId) {
      const metaDataToUpdate = getCachedTableMetadata()
      const updatedTables =
        metaDataToUpdate?.tables?.filter(
          (table) => table.id !== `${staticURLpath}/${cacheId}`
        ) || []
      updatedTables.push({
        id: `${staticURLpath}/${cacheId}`,
        currentPage: pageIndex,
      })
      try {
        sessionStorage.setItem(
          'tableMetadata',
          JSON.stringify({ tables: updatedTables })
        )
      } catch {
        LogRocket.error('Unable to stringify table data')
      }
    }
  }, [
    accountId,
    cacheId,
    gotoPage,
    location.pathname,
    pageIndex,
    staticURLpath,
  ])

  useEffect(() => {
    if (globalFilterValue !== undefined) {
      setGlobalFilter(globalFilterValue)
      gotoPage(0)
    }
  }, [globalFilterValue, gotoPage, setGlobalFilter])

  function flattenValues(value) {
    if (Array.isArray(value)) {
      return value.flatMap(flattenValues)
    } else if (typeof value === 'object' && value !== null) {
      return Object.values(value).flatMap(flattenValues)
    } else {
      return [value]
    }
  }

  const allValues = flatRows
    .map((row) => {
      const values = { ...row.values }
      columns
        .filter((c) => c.disableFilters)
        .map((c) => c.id)
        .forEach((id) => {
          if (id) {
            delete values[id]
          }
        })
      return values
    })
    .map((value) => (isNumber(value) ? value.toString() : value))
    .flatMap(flattenValues)
    .filter((value) => typeof value === 'string')

  useEffect(() => {
    updateAllFilterValues?.(allValues)
  }, [allValues, updateAllFilterValues])

  useEffect(() => {
    if (onSelectRow) {
      onSelectRow(selectedFlatRows.map((row) => row.original))
    }
  }, [onSelectRow, selectedFlatRows])

  useImperativeHandle(ref, () => ({
    exportCsv() {
      exportData('csv', true)
    },
    allColumns() {
      return allColumns
    },
    resetPaging: () => gotoPage(0),
  }))

  const showFooter = useMemo(
    () => columns.some((column) => !!column.Footer),
    [columns]
  )

  const extendedPage = extendPageRowIndex(page)

  const [hoveredColumnIndex, setHoveredColumnIndex] = useState<number>()

  const shouldShowEmptyFilterValue =
    globalFilterValue && globalFilterValue.length > 0 && allValues.length === 0

  if (shouldShowEmptyFilterValue) {
    return (
      <div className="text-5xl font-bold text-coolGray-800 ml-6 mb-6 mt-3">
        There are no results matching your search, please try again
      </div>
    )
  }

  return (
    <>
      <div className={cx('overflow-x-auto', className)}>
        <div
          {...getTableProps()}
          data-cy={dataCy}
          data-testid={dataTestId}
          className={cx('w-full min-w-max', styles.table, {
            'cursor-col-resize select-none': isResizingColumn,
          })}
        >
          {!isHeaderHidden && (
            <TableHeader<T>
              dataCy={dataCy}
              headerGroups={headerGroups}
              columns={columns}
              onSwapColumns={onSwapColumns}
              headerClassName={headerClassName}
              setHoveredColumnIndex={setHoveredColumnIndex}
            />
          )}

          <div
            {...getTableBodyProps()}
            data-cy={`${dataCy}-body`}
            data-testid="table-body"
          >
            {extendedPage.map((row, rowIndex) => {
              if (row.isGrouped) {
                return (
                  <GroupRows<T>
                    key={row.id}
                    onRowClick={onRowClick}
                    dataCy={dataCy}
                    rowClassName={rowClassName}
                    isResizingColumn={isResizingColumn}
                    isHeaderHidden={isHeaderHidden}
                    rowIndex={rowIndex}
                    row={row}
                    rows={rows}
                    depth={row.depth}
                    prepareRow={prepareRow}
                    hoveredColumnIndex={hoveredColumnIndex}
                    isHorizontalSeparatorHidden={isHorizontalSeparatorHidden}
                  />
                )
              }

              prepareRow(row)
              return (
                <DefaultRow<T>
                  key={row.id}
                  onRowClick={onRowClick}
                  dataCy={dataCy}
                  rowClassName={rowClassName}
                  isResizingColumn={isResizingColumn}
                  isHeaderHidden={isHeaderHidden}
                  rowIndex={rowIndex}
                  row={row}
                  rows={rows}
                  depth={row.depth}
                  disableSubRowArrow={disableSubRowArrow}
                  generateRowClassName={generateRowClassName}
                  hoveredColumnIndex={hoveredColumnIndex}
                  isHorizontalSeparatorHidden={isHorizontalSeparatorHidden}
                />
              )
            })}
          </div>

          {showFooter && (
            <div>
              {footerGroups.map((group) => (
                <div {...group.getFooterGroupProps()} key={group.id}>
                  {group.headers.map((column, index) => {
                    const { isResizable, getResizerProps, getFooterProps } =
                      column

                    return (
                      <div
                        {...getFooterProps()}
                        className={cx(
                          'flex relative border-t-2 border-gold-200 font-bold',
                          styles.common,
                          {
                            'justify-end': column.align === 'right',
                            'bg-coolGray-50':
                              hoveredColumnIndex === index && !isResizingColumn,
                          }
                        )}
                        key={column.id}
                      >
                        {column.render('Footer')}
                        {isResizable && <Resizer {...getResizerProps()} />}
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {!disablePagination && (
        <Pagination
          pageSize={pageSize}
          pageIndex={pageIndex}
          setPageSize={setPageSize}
          pageCount={pageCount}
          totalRowCount={rows.length}
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canNextPage={canNextPage}
        />
      )}
    </>
  )
}

export type {
  CellProps,
  Column,
  CellValue,
  Row,
  TableInstance,
  OnCellChange,
  TableData,
  OnSwapColumns,
  TableProps,
}

const TypedTable = memo(forwardRef(Table)) as <T extends object>(
  props: TableProps<T> & { ref?: Ref<HTMLDivElement> }
) => ReturnType<typeof Table>
export default TypedTable

function DefaultColumnFilter() {
  return null
}
