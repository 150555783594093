import { useEffect, useRef, useState } from 'react'
import { Field, useForm } from 'react-final-form'
import {
  calculateSplitResult,
  CalculatorResponse,
} from 'workflow/CampaignSetup/SplitCalculator/calculatorService'
import SplitSize from 'workflow/CampaignSetup/SplitSize'
import { showBanner } from 'workflow/common/common.actions'
import { SplitSizeData } from 'workflow/interface'

import {
  NumericType,
  PercentageType,
} from 'common/components/dynamicFields/interfaces'
import { useAppDispatch } from 'common/hooks/redux'
import useDebounce from 'common/hooks/useDebounce'
import { SplitCalculatorConfiguration } from 'features/unifiedFlow/api'
import { updateTemplateSplitConfiguration } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { TemplateInfoWithInternalId } from '../../addedTemplates/AddedComponentList'
import { getPrefixedName, getSelectedProjectId } from '../../helpers'

import CalculatorCard from './components/CalculatorCard'
import ListSize from './components/ListSize'
import Rate from './components/Rate'
import { SPLIT_CACLULATOR_DELAY, validateSplitSize } from './MetricSelection'

const calculateColSpan = (condition: boolean) => (condition ? 1 : 0)

type Props = {
  configuration: SplitCalculatorConfiguration['splitCalculation']
  template?: TemplateInfoWithInternalId
  isDisabled?: boolean
  splitCalculationResult?: SplitSizeData
  onLoading?: (isLoading: boolean) => void
  onResult?: (result?: SplitSizeData) => void
  resetCalculatorCallback?: (callback: () => void) => void
}

const Standard = ({
  configuration,
  template,
  splitCalculationResult: splitCalculationResultInitial,
  isDisabled = false,
  onLoading,
  onResult,
  resetCalculatorCallback,
}: Props) => {
  const form = useForm()
  const dispatch = useAppDispatch()

  const [splitCalculationResult, setSplitCalculationResult] = useState<
    SplitSizeData | undefined
  >(splitCalculationResultInitial)
  const [isCalculatorLoading, setIsCalculatorLoading] = useState(false)
  const previousCalculationParamsString = useRef<string>('')

  const listSizeConfiguration = configuration.fields.find(
    (field) => field.name === 'list_size'
  ) as NumericType
  const openRateConfiguration = configuration.fields.find(
    (field) => field.name === 'baseline_open_rate'
  ) as PercentageType

  const selectedProjectId = getSelectedProjectId(form.getState(), template)

  const isListSizeValid = form.getFieldState(
    getPrefixedName('listSize', template)
  )?.valid

  const getSplitCalculation = async ({
    minSplitNumber,
    maxSplitNumber,
  }: { minSplitNumber?: number; maxSplitNumber?: number } = {}) => {
    const listSizeValue = form.getFieldState(
      getPrefixedName('listSize', template)
    )?.value
    const baselineOpenRateValue = form.getFieldState(
      getPrefixedName('openRate', template)
    )?.value

    const calculationParamsString = [listSizeValue, baselineOpenRateValue].join(
      '-'
    )

    if (!listSizeValue || !baselineOpenRateValue) {
      return
    }

    if (
      previousCalculationParamsString.current === '' ||
      previousCalculationParamsString.current !== calculationParamsString ||
      maxSplitNumber ||
      minSplitNumber
    ) {
      previousCalculationParamsString.current = calculationParamsString
      setIsCalculatorLoading(true)
      onLoading?.(true)
      form.mutators.setError(getPrefixedName('listSize', template), undefined)

      let splitResult: CalculatorResponse = {}
      try {
        splitResult = await calculateSplitResult(selectedProjectId, undefined, {
          // TODO: Add campaignId here when it is available
          selectionMetric: 'open_rate',
          listSize: listSizeValue,
          baselineOpenRate: baselineOpenRateValue,
          maxSplitNumber,
          minSplitNumber,
        })
      } catch (error: any) {
        if (error.name === 'INVALID AUDIENCE_SIZE') {
          form.mutators.setError(
            getPrefixedName('listSize', template),
            error.message
          )
        } else {
          showBanner({ content: error.message, type: 'error' })
        }
      }

      if (splitResult.result && splitResult.expectedEngagementRates) {
        const rates = splitResult.expectedEngagementRates
        const expectedBaselineOpenRateValue = rates['open_rate']?.value

        form.change(
          getPrefixedName('openRate', template),
          baselineOpenRateValue || expectedBaselineOpenRateValue
        )
      }
      setSplitCalculationResult(splitResult.result)
      onResult?.(splitResult.result)
      setIsCalculatorLoading(false)
      onLoading?.(false)
    }
  }

  const getSplitCalculationDebounced = useDebounce(
    getSplitCalculation,
    SPLIT_CACLULATOR_DELAY
  )

  useEffect(() => {
    if (template?.internalId) {
      dispatch(
        updateTemplateSplitConfiguration({
          internalId: template.internalId,
          splitConfiguration: {
            type: 'metric_selection',
            splitCalculationResult: splitCalculationResult,
          },
        })
      )
    }
  }, [dispatch, splitCalculationResult, template?.internalId])

  useEffect(() => {
    if (resetCalculatorCallback && splitCalculationResultInitial) {
      resetCalculatorCallback?.(() =>
        setSplitCalculationResult(splitCalculationResultInitial)
      )
    }
  }, [resetCalculatorCallback, splitCalculationResultInitial])

  useEffect(() => {
    if (!splitCalculationResult) {
      getSplitCalculation()
    }
    // This is supposed to run on mount because the calculator can change based on the image optimization toggle,
    // and while there is form data - it should trigger the refetch
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CalculatorCard className="flex-col">
      <div
        className={`grid w-full gap-1 grid-cols-${
          calculateColSpan(!!listSizeConfiguration) +
          calculateColSpan(!!openRateConfiguration)
        }`}
      >
        {listSizeConfiguration && (
          <ListSize
            name={getPrefixedName('listSize', template)}
            configuration={listSizeConfiguration}
            onChange={() => getSplitCalculationDebounced()}
            isDisabled={isDisabled}
          />
        )}
        {openRateConfiguration && (
          <Rate
            name={getPrefixedName('openRate', template)}
            data-cy="openRate-input"
            data-testid="openRate-input"
            isDisabled={!isListSizeValid || isDisabled}
            configuration={openRateConfiguration}
            className="last:mb-6"
            onChange={() => getSplitCalculationDebounced()}
          />
        )}
      </div>
      <Field<number>
        name={getPrefixedName('splitNumber', template)}
        validate={validateSplitSize}
        initialValue={splitCalculationResult?.split_number}
      >
        {({ input }) => (
          <SplitSize
            data={splitCalculationResult}
            updateSplitSizeNumber={(newSplitNumber: number) => {
              const splitNumber = splitCalculationResult?.split_number
              if (!splitNumber || newSplitNumber === splitNumber) {
                return
              }
              input.onChange(newSplitNumber)

              if (newSplitNumber > splitNumber!) {
                getSplitCalculationDebounced({
                  minSplitNumber: newSplitNumber,
                })
              } else {
                getSplitCalculationDebounced({
                  maxSplitNumber: newSplitNumber,
                })
              }
            }}
            value={splitCalculationResult?.split_number}
            isLoading={isCalculatorLoading}
            isDisabled={isDisabled}
          />
        )}
      </Field>
    </CalculatorCard>
  )
}

export default Standard
