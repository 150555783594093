import { useMutation } from '@tanstack/react-query'

import { errorToast, successToast } from 'common/components/toastNotification'

import { VariantStatusUpdate } from '../../interfaces'
import { updateVariantStatus } from '../api'

const useUpdateVariantStatus = ({
  campaignId,
  updateResultsVariantStatus,
}: {
  campaignId: string
  updateResultsVariantStatus: (variantId: string, variantStatus: string) => void
}) => {
  return useMutation({
    mutationFn: ({ id, variantStatus }: VariantStatusUpdate) =>
      updateVariantStatus(id, campaignId, variantStatus),
    onError: () => errorToast('Something went wrong. Please try again.'),
    onSuccess(data, { id, variantStatus }) {
      updateResultsVariantStatus(id, variantStatus)
      successToast('Variant status updated successfully.')
    },
  })
}

export default useUpdateVariantStatus
