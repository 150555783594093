/* eslint-disable max-lines */
import { useEffect, useMemo, useRef, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import RouterPromptStay from 'app/router/RouterPromptStay'
import { ValidationErrors } from 'final-form'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import { SelectValue } from 'common/components/BaseSelect'
import { parseFieldsConfiguration } from 'common/components/dynamicFields/helpers'
import {
  ConditionalValue,
  FieldType,
} from 'common/components/dynamicFields/interfaces'
import { successToast } from 'common/components/toastNotification'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useGenerateBriefMutation from 'features/unifiedFlow/api/mutations/useGenerateBriefMutation'
import useGenerateCustomBriefMutation from 'features/unifiedFlow/api/mutations/useGenerateCustomBriefMutation'
import useGenerateCustomVariantsMutation from 'features/unifiedFlow/api/mutations/useGenerateCustomVariantsMutation'
import useGenerateVariantsMutation from 'features/unifiedFlow/api/mutations/useGenerateVariantsMutation'
import useUpdateContentMutation from 'features/unifiedFlow/api/mutations/useUpdateContentMutation'
import PageDetails from 'features/unifiedFlow/contentPage/components/PageDetails'
import { userInputFormHasChanged } from 'features/unifiedFlow/store/unifiedFlowSlice'
import { selectTopics } from 'features/unifiedFlow/store/unifiedFlowSlice'

import {
  getAllTemplateElements,
  getIsOptimized,
  getStepStatus,
  parseWorkflowName,
  validateWorkflowName,
} from '../../helpers'
import { useContent, useSelectedElement, useTopics } from '../../hooks'
import useWebsocketMessage from '../../hooks/websocketMessage'
import { getWsTopic } from '../../hooks/websocketMessage/helpers'
import useCheckVariants from '../hooks/useCheckVariants'

import BrandVoiceChangeOverlay from './BrandVoiceChangeOverlay'
import DynamicForm from './DynamicForm'
import FormLeaveModal from './FormLeaveModal'
import GeneratedBrief from './GeneratedBrief'
import GeneratedTopics from './GeneratedTopics'
import RefreshBriefOverlay from './RefreshBriefOverlay'
import RegenerateModal from './RegenerateModal'

export type FormSubmitAction = (
  formValues: Record<string, ConditionalValue<FieldType> | undefined>
) => Promise<void>
export type PageState =
  | 'formChangedWithTopics'
  | 'formChangedWithBrief'
  | 'formChangedWithoutTopics'
  | 'brandVoiceChanged'
  | 'topicsChanged'
  | 'generateBrief'
  | 'generateVariants'
  | 'showGeneratedContentWithoutTopics'
  | 'showGeneratedContent'
  | 'generateWithoutTopics'

type Props = {
  isWebsocketLoading: boolean
}

const BriefPage = ({ isWebsocketLoading }: Props) => {
  const dispatch = useAppDispatch()
  const { content } = useContent()
  const { hasVariants } = useCheckVariants()
  const { sendWebsocketMessage } = useWebsocketMessage()
  const [resetWarningModalState, setResetWarningModalState] = useState<{
    isOpen: boolean
    onConfirm?: () => void
  }>({
    isOpen: false,
    onConfirm: undefined,
  })
  const isModalOpenRef = useRef(false)

  const accountId = useAppSelector((state) => state.authStates.accountId)

  const selectedTopics = useAppSelector(
    (state) => state.unifiedFlow.selectedTopics
  )

  const allTemplateElements = getAllTemplateElements(content)

  const { data: selectedComponentElement } = useSelectedElement()

  const parentTemplateElement = allTemplateElements.find((templateElement) =>
    templateElement.elements.some(
      (element) => selectedComponentElement?.element_id === element.element_id
    )
  )
  const selectedElement = parentTemplateElement || selectedComponentElement

  const { data: { topics, fragments } = {} } = useTopics(
    content?._id,
    selectedElement?.element_id
  )

  const updateContentMutation = useUpdateContentMutation()
  const generateBriefMutation = useGenerateBriefMutation()
  const generateCustomBriefMutation = useGenerateCustomBriefMutation()
  const generateVariantsMutation = useGenerateVariantsMutation()
  const generateCustomVariantsMutation = useGenerateCustomVariantsMutation()

  const isOptimized = getIsOptimized(selectedElement)

  const [hasFormChanged, setHasFormChanged] = useState(false)
  const [hasBrandVoiceChanged, setHasBrandVoiceChanged] = useState(false)

  const shouldUseTopics = content?.requires_ner

  const isGlobalSettings = selectedElement === undefined

  const userInputForm = !isGlobalSettings
    ? cloneDeep(
        selectedElement?.user_input?.user_input_form?.filter(
          (field) => field.name !== 'content_name'
        )
      )
    : cloneDeep(content?.user_input.user_input_form)
  const parsedConfiguration = parseFieldsConfiguration(userInputForm)

  const generatedBrief = !isGlobalSettings
    ? selectedElement?.user_input?.generated_brief
    : content?.user_input.generated_brief

  const brandVoiceId =
    content?.elements.length === 1
      ? content.elements[0].user_input?.brand_voice_id
      : selectedElement?.user_input?.brand_voice_id

  const revertTopics = () => {
    dispatch(selectTopics(topics ?? []))
  }

  const generateBrief: FormSubmitAction = async (formValues) => {
    if (!content || !userInputForm) {
      return
    }

    if (hasVariants && isGlobalSettings && !isModalOpenRef.current) {
      setResetWarningModalState({
        isOpen: true,
        onConfirm: () => generateBrief(formValues),
      })
      return
    }

    const wsTopic = isGlobalSettings
      ? getWsTopic({
          data: { contentId: content._id },
          action: 'generateGlobalBrief',
        })
      : getWsTopic({
          data: {
            contentId: content._id,
            elementId: selectedElement!.element_id,
          },
          action: 'generateElementBrief',
        })

    if (userInputForm) {
      userInputForm.forEach((field) => {
        if (formValues[field.name] != null) {
          field.value = formValues[field.name]
        } else {
          delete field.value
        }
      })
    }

    const selectedBrandVoice = formValues.brand_voice as SelectValue | undefined

    if (!isGlobalSettings && selectedElement?.element_id) {
      sendWebsocketMessage({
        action: 'generateElementBrief',
        data: {
          contentId: content._id,
          elementId: selectedElement.element_id,
        },
        subscriptionAction: 'subscribe',
      })

      generateCustomBriefMutation.mutate({
        accountId,
        contentId: content._id,
        elementId: selectedElement?.element_id,
        userInputForm,
        wsTopic,
        ...(!isOptimized && { brandVoiceId: selectedBrandVoice?.value }),
      })
    } else {
      sendWebsocketMessage({
        action: 'generateGlobalBrief',
        data: {
          contentId: content._id,
        },
        subscriptionAction: 'subscribe',
      })
      generateBriefMutation.mutate({
        accountId,
        contentId: content._id,
        userInputForm,
        wsTopic,
        ...(!isOptimized && { brandVoiceId: selectedBrandVoice?.value }),
      })
    }
  }

  const generateVariants: FormSubmitAction = async (formValues) => {
    if (!content) {
      return
    }

    if (hasVariants && isGlobalSettings && !isModalOpenRef.current) {
      setResetWarningModalState({
        isOpen: true,
        onConfirm: () => generateVariants(formValues),
      })
      return
    }

    const selectedBrandVoice = formValues.brand_voice as SelectValue | undefined

    if (userInputForm) {
      userInputForm.forEach((field) => {
        if (formValues[field.name] != null) {
          field.value = formValues[field.name]
        } else {
          delete field.value
        }
      })
    }

    const wsTopic = !isGlobalSettings
      ? getWsTopic({
          data: {
            contentId: content._id,
            elementId: selectedElement!.element_id,
          },
          action: 'generateElementVariants',
        })
      : ''

    dispatch(userInputFormHasChanged({ hasChanged: false }))

    if (!isGlobalSettings && selectedElement?.element_id) {
      sendWebsocketMessage({
        action: 'generateElementVariants',
        data: {
          contentId: content._id,
          elementId: selectedElement.element_id,
        },
        subscriptionAction: 'subscribe',
      })

      generateCustomVariantsMutation.mutate({
        accountId,
        topics: selectedTopics,
        contentId: content._id,
        elementId: selectedElement?.element_id,
        wsTopic,
        ...(!isOptimized && { brandVoiceId: selectedBrandVoice?.value }),
        ...(!shouldUseTopics && { userInputForm }),
      })
    } else {
      content.elements.forEach((element) => {
        sendWebsocketMessage({
          action: 'generateElementVariants',
          data: {
            contentId: content._id,
            elementId: element.element_id,
          },
          subscriptionAction: 'subscribe',
        })
      })

      generateVariantsMutation.mutate({
        topics: selectedTopics,
        accountId,
        wsTopic,
        contentId: content._id,
        ...(!isOptimized && { brandVoiceId: selectedBrandVoice?.value }),
        ...(!shouldUseTopics && { userInputForm }),
      })
    }
  }

  const updateWorkflowName = (value: string) => {
    const { value: parsedValue, isValid } = parseWorkflowName(value)
    if (!content || !isValid) {
      return
    }

    const userInputForm = cloneDeep(content.user_input.user_input_form)

    let previousName = ''
    userInputForm.forEach((field) => {
      if (field.name === 'workflow_name') {
        previousName = field.value as string
        field.value = parsedValue
      }
    })

    if (previousName !== value) {
      updateContentMutation.mutate(
        {
          contentId: content._id,
          accountId,
          userInputForm,
          updatedName: parsedValue,
        },
        {
          onSuccess: () => {
            successToast('Workflow name updated successfully')
          },
        }
      )
    }
  }

  const hasTopics = topics !== undefined
  const briefStepsStatus = getStepStatus(content?.steps_status, 'brief')
  const isBriefStepsStatusReadOnly = briefStepsStatus === 'read-only'

  const generationStepsStatus = getStepStatus(
    content?.steps_status,
    'generation'
  )
  const isGenerateStepActive =
    generationStepsStatus === 'enabled' || generationStepsStatus === 'active'

  const getState = (): PageState => {
    const hasTopicsChanged = hasTopics && !isEqual(topics, selectedTopics)

    if (hasBrandVoiceChanged && !hasFormChanged && hasVariants) {
      return 'brandVoiceChanged'
    } else if (hasFormChanged && !shouldUseTopics && hasVariants) {
      return 'formChangedWithoutTopics'
    } else if (hasFormChanged && shouldUseTopics && hasTopics) {
      return 'formChangedWithTopics'
    } else if (hasFormChanged && shouldUseTopics && generatedBrief) {
      return 'formChangedWithBrief'
    } else if (hasTopicsChanged && hasVariants) {
      return 'topicsChanged'
    } else if (!shouldUseTopics && hasVariants) {
      return 'showGeneratedContentWithoutTopics'
    } else if (!shouldUseTopics && !hasVariants && !hasTopics) {
      return 'generateWithoutTopics'
    } else if (!hasVariants && generatedBrief && hasTopics) {
      return 'generateVariants'
    } else if (hasVariants) {
      return 'showGeneratedContent'
    } else {
      return 'generateBrief'
    }
  }

  const state = getState()

  useEffect(() => {
    isModalOpenRef.current = resetWarningModalState.isOpen
  }, [resetWarningModalState.isOpen])

  useEffect(() => {
    dispatch(selectTopics(topics ?? []))
  }, [dispatch, topics])

  useEffect(() => {
    if (
      (state === 'topicsChanged' ||
        state === 'formChangedWithTopics' ||
        state === 'formChangedWithBrief' ||
        state === 'formChangedWithoutTopics') &&
      !isWebsocketLoading
    ) {
      dispatch(userInputFormHasChanged({ hasChanged: true, isGlobalSettings }))
    } else {
      dispatch(userInputFormHasChanged({ hasChanged: false }))
    }
  }, [dispatch, isGlobalSettings, isWebsocketLoading, state])

  const serializedUserInputForm = JSON.stringify(userInputForm)
  const formInitialValues: any = useMemo(() => {
    const useInputFormInitialValues = userInputForm?.reduce(
      (acc, state) => ({ ...acc, [state.name]: state.value }),
      {}
    )
    return { ...useInputFormInitialValues, brand_voice: brandVoiceId }
    // UserInputForm might contain properties with array values, that causes the deep comparison to fail and the form to re-initialize
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serializedUserInputForm, brandVoiceId])

  const getSubmitAction = (formValues): FormSubmitAction => {
    const submitAction = formValues?.submitAction

    switch (submitAction) {
      case 'formChangedWithTopics':
      case 'formChangedWithBrief':
      case 'generateBrief':
      case 'refreshBrief':
        return generateBrief
      case 'generateWithoutTopics':
      case 'formChangedWithoutTopics':
      case 'topicsChanged':
      case 'generateVariants':
      case 'brandVoiceChanged':
        return generateVariants
      default:
        return async () => {}
    }
  }

  return (
    <div className="flex flex-col flex-1">
      {userInputForm && (
        <Form
          onSubmit={(values) => getSubmitAction(values)(values)}
          initialValues={formInitialValues}
          key={selectedElement?.element_id}
          validate={(values) => {
            const errors: ValidationErrors = {}
            const workflowNameValidationError = validateWorkflowName(
              values['workflow_name']
            )

            if (!!workflowNameValidationError) {
              errors.workflow_name = workflowNameValidationError
            }

            return errors
          }}
          render={({ handleSubmit, submitting, dirty, submitSucceeded }) => (
            <form
              onSubmit={handleSubmit}
              onBlur={(e) => {
                if (
                  e.target.name === 'workflow_name' &&
                  e.target.value !== ''
                ) {
                  updateWorkflowName(e.target.value)
                }
              }}
            >
              <FormSpy
                onChange={(formState) => {
                  const dirtyFieldsKeys = Object.keys(formState.dirtyFields)
                  const dirtyFields = dirtyFieldsKeys.filter(
                    (field) =>
                      field !== 'workflow_name' && field !== 'brand_voice'
                  )
                  const isDirty = dirtyFields.length > 0
                  setHasFormChanged(isDirty)

                  const brandVoiceChanged =
                    dirtyFieldsKeys.includes('brand_voice') && !isDirty
                  setHasBrandVoiceChanged(brandVoiceChanged)
                }}
              />
              <div className="flex flex-col">
                <PageDetails
                  title="Brief"
                  description="Input a short description and Jacquard will generate an optimised brief."
                  className="pb-6"
                />
                {parsedConfiguration && (
                  <DynamicForm
                    fieldsConfiguration={parsedConfiguration}
                    isDisabled={submitting || isBriefStepsStatusReadOnly}
                    pageState={state}
                    isLoading={generateVariantsMutation.isLoading}
                    brandVoiceId={brandVoiceId}
                    isOptimized={isOptimized}
                  />
                )}
              </div>
              <div className="relative">
                {state === 'formChangedWithTopics' && <RefreshBriefOverlay />}
                {state === 'brandVoiceChanged' && <BrandVoiceChangeOverlay />}
                {generatedBrief && (
                  <GeneratedBrief
                    brief={generatedBrief}
                    pageState={state}
                    fragments={fragments}
                  />
                )}
                <GeneratedTopics
                  contentId={content?._id}
                  topics={selectedTopics}
                  pageState={state}
                  setSelectedTopics={(topicItems) =>
                    dispatch(selectTopics(topicItems))
                  }
                  revertTopics={revertTopics}
                  isReadOnly={isBriefStepsStatusReadOnly}
                  isGeneratingVariants={generateVariantsMutation.isLoading}
                  isGenerateStepActive={isGenerateStepActive}
                />
              </div>
              <RouterPromptStay
                shouldShow={
                  !!content &&
                  dirty &&
                  !submitting &&
                  !submitSucceeded &&
                  !isBriefStepsStatusReadOnly
                }
                onOK={() => Promise.resolve(true)}
              />
            </form>
          )}
        />
      )}
      <FormLeaveModal />
      <RegenerateModal
        isOpen={resetWarningModalState.isOpen}
        onConfirm={() => {
          resetWarningModalState.onConfirm?.()
          setResetWarningModalState({ isOpen: false })
        }}
        onClose={() => setResetWarningModalState({ isOpen: false })}
      />
    </div>
  )
}

export default BriefPage
