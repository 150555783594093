import { Menu } from 'antd'
import { ClickParam } from 'antd/lib/menu'
import cx from 'classnames'
import { Step } from 'workflow/interface'
import helpers from 'workflow/utils/helpers'
import { findCurrentStepName } from 'workflow/WorkflowManager'

import DistributionChannel from 'common/components/DistributionChannel'
import { useAppSelector } from 'common/hooks/redux'

import NameInput from './components/nameInput'
import ToggleCampaignListButton from './ToggleCampaignListButton'

interface Props {
  onMenuClick: (e: ClickParam, step: Step) => void
  projectName: string
}

const CampaignsHeader = ({ onMenuClick, projectName }: Props) => {
  const campaign = useAppSelector((state) => state.campaignStates)
  const { isNewCampaign } = useAppSelector((state) => state.campaigns)
  const isNavCollapsed = useAppSelector(
    (state) => state.navigation.isNavCollapsed
  )

  const {
    chosenStep: step,
    campaignData,
    campaignData: {
      campaign_progress: { phrasee_x_initialised: isInitialised },
    },
    isUserInput,
    variantsToBeApprovedExperimentsFlow,
  } = campaign
  const { steps, campaign_configuration } = campaignData
  const chosenStep = findCurrentStepName(steps, step, isUserInput)

  const distributionChannel =
    campaign.distributionChannel || campaign_configuration?.distribution_channel

  const shouldShowApprovalNotification = (step: any) => {
    const { step: stepName } = step
    const isBandit = helpers.isBanditCampaign(
      campaignData?.campaign_configuration
    )
    const isNlg = helpers.isNlgCampaign(campaignData)
    return (
      stepName === 'language_approval' &&
      isBandit &&
      isNlg &&
      isInitialised &&
      variantsToBeApprovedExperimentsFlow.length > 0
    )
  }

  const shouldDisplayApprovalNotificationForLinguo = (step) => {
    if (step.name === 'Content Generation') {
      return (
        campaignData?.campaign_configuration?.testing_method
          ?.tested_content_sections[0]?.language_source === 'linguo' &&
        variantsToBeApprovedExperimentsFlow.length > 0
      )
    } else {
      return false
    }
  }

  return (
    <div
      data-cy="campaign-panel-header"
      data-testid="campaign-panel-header"
      className="w-full pt-6 px-8 bg-gold-40 shadow-lg z-20"
    >
      <div
        className={cx('pl-0 text-gray-600', {
          'md:pl-0': isNavCollapsed && !isNewCampaign,
        })}
      >
        {!isNewCampaign && <ToggleCampaignListButton />}
        {projectName && (
          <div
            data-cy="campaign-project"
            data-testid="campaign-project"
            className="inline mr-1 capitalize-first"
          >
            {projectName} /
          </div>
        )}
        {distributionChannel && (
          <DistributionChannel
            data-cy="campaign-channel"
            data-testid="campaign-channel"
            value={distributionChannel}
          />
        )}
      </div>
      <NameInput />
      <Menu
        data-cy="campaign-steps"
        data-testid="campaign-steps"
        mode="horizontal"
        selectable={false}
        className="border-transparent bg-gold-40"
        style={{ maxWidth: '90%' }}
      >
        {steps &&
          steps.map((step, index) => (
            <Menu.Item
              data-cy={`campaign-step-${step.step}`}
              data-testid={`campaign-step-${step.step}`}
              key={index}
              className={cx(
                `mr-6 pt-4 pb-3 px-0 text-base text-coolGray-400 font-medium`,
                {
                  'ant-menu-item-selected text-maroon-400':
                    step.step === chosenStep,
                }
              )}
              disabled={!step.clickable}
              onClick={(e: ClickParam) => onMenuClick(e, step)}
            >
              {(shouldShowApprovalNotification(step) ||
                shouldDisplayApprovalNotificationForLinguo(step)) && (
                <span
                  className={`absolute -right-3 top-0.5 bg-red-500
                  inline-block text-white leading-0.5
                  text-xs py-0.75 px-1 border-red-500 border-0.5`}
                >
                  {variantsToBeApprovedExperimentsFlow.length}
                </span>
              )}
              {step.name}
            </Menu.Item>
          ))}
      </Menu>
    </div>
  )
}

export default CampaignsHeader
