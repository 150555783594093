import { useEffect, useState } from 'react'

import Widget from 'common/components/Widget'

import { getAccountData } from './api'
import Error from './Error'
import { defaultRevenueSettings } from './helpers'
import { RevenueSettingsValues } from './interfaces'
import RevenueSettings from './RevenueSettings'

const RevenueReporting = () => {
  const [hasError, setHasError] = useState(false)
  const [revenueSettingsValues, setRevenueSettingsValues] =
    useState<RevenueSettingsValues>(defaultRevenueSettings)

  const onSave = (newRevenueSettingsValues: RevenueSettingsValues) => {
    setRevenueSettingsValues(newRevenueSettingsValues)
  }

  const onDelete = () => {
    setRevenueSettingsValues(defaultRevenueSettings)
  }

  useEffect(() => {
    const loadGlobalSettings = async () => {
      try {
        setHasError(false)
        const mappedResponse = await getAccountData()

        setRevenueSettingsValues((previousValues) => ({
          ...previousValues,
          ...mappedResponse,
        }))
      } catch {
        setHasError(true)
      }
    }

    loadGlobalSettings()
  }, [])

  return (
    <Widget
      data-cy="admin-revenue-reporting"
      data-testid="admin-revenue-reporting"
      className="max-w-318"
    >
      <h3 className="mb-6 text-coolGray-800 text-xl font-medium">
        Revenue reporting
      </h3>
      <h4 className="text-base font-bold text-coolGray-800">Global settings</h4>
      <p className="mt-1 text-coolGray-500 text-sm">
        These will apply to all projects unless you add a Project revenue
        exception below.
      </p>
      {hasError && <Error />}
      <RevenueSettings
        className="mt-6"
        settings={revenueSettingsValues}
        onSave={onSave}
        onDelete={onDelete}
        scope="global"
      />
    </Widget>
  )
}

export default RevenueReporting
