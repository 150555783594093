import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { changeAccountId } from 'features/auth/store/authSlice'

import { RightPaneVisibility } from '../contentPage/components/RightPane'

export const MAX_TEMPLATES_TO_ADD = 10

interface ContentState {
  activeTemplateId?: string
  activeContentId?: string
  selectedElementId?: number | string
  integrationSelectedElementId?: number | string
  showComponentConfiguration?: boolean
  showTemplateView: boolean
  areElementsDisabled: boolean
  rightPaneVisibility: RightPaneVisibility
  isWorkflowVisible: boolean | undefined
  templatesToAdd: TemplateToAdd[]
  shouldShowUnsavedModal?: boolean
  openUnsavedModal?: boolean
  pendingSelection?: {
    elementId?: number | string
    showComponentConfiguration?: boolean
  }
}

export interface TemplateToAdd {
  id: string
  count: number
}

export const initialState: ContentState = {
  activeTemplateId: undefined,
  activeContentId: undefined,
  selectedElementId: undefined,
  integrationSelectedElementId: undefined,
  showComponentConfiguration: false,
  showTemplateView: false,
  areElementsDisabled: false,
  rightPaneVisibility: 'auto',
  isWorkflowVisible: undefined,
  templatesToAdd: [],
  shouldShowUnsavedModal: undefined,
  openUnsavedModal: undefined,
  pendingSelection: undefined,
}

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    userInputFormHasChanged: (state, action: PayloadAction<boolean>) => {
      const hasInputFormChanged = action.payload
      if (hasInputFormChanged && !state.showComponentConfiguration) {
        state.areElementsDisabled = true
      } else if (hasInputFormChanged && state.showComponentConfiguration) {
        state.shouldShowUnsavedModal = true
      } else {
        state.shouldShowUnsavedModal = false
        state.areElementsDisabled = false
      }
    },
    toggleOpenUnsavedModal: (state, action: PayloadAction<boolean>) => {
      state.openUnsavedModal = action.payload
    },
    inputFormLeave: (state) => {
      state.selectedElementId = state.pendingSelection?.elementId
      state.showComponentConfiguration =
        state.pendingSelection?.showComponentConfiguration || false
      state.pendingSelection = undefined
      state.openUnsavedModal = false
      state.shouldShowUnsavedModal = false
    },
    workflowCardClicked: (
      state,
      action: PayloadAction<{
        elementId?: number | string
        showComponentConfiguration?: boolean
        shouldShowUnsavedModal?: boolean
      }>
    ) => {
      const { elementId, showComponentConfiguration, shouldShowUnsavedModal } =
        action.payload

      state.shouldShowUnsavedModal =
        shouldShowUnsavedModal ?? state.shouldShowUnsavedModal

      if (state.shouldShowUnsavedModal) {
        state.openUnsavedModal = true
        state.pendingSelection = { elementId }
      } else {
        state.selectedElementId = elementId
        state.showComponentConfiguration = showComponentConfiguration || false
      }
    },
    integrationWorkflowCardClicked: (
      state,
      action: PayloadAction<{ elementId?: number | string }>
    ) => {
      const { elementId } = action.payload
      state.integrationSelectedElementId = elementId
    },
    updateActiveTemplateId: (state, action: PayloadAction<string>) => {
      state.activeTemplateId = action.payload
      state.activeContentId = undefined
    },
    updateActiveContentId: (state, action: PayloadAction<string>) => {
      state.activeContentId = action.payload
      state.activeTemplateId = undefined
    },
    showTemplates: (state) => {
      state.showTemplateView = true
    },
    hideTemplates: (state) => {
      state.showTemplateView = false
    },
    componentConfigurationClicked: (
      state,
      action: PayloadAction<{ elementId?: number }>
    ) => {
      const { elementId } = action.payload
      if (state.shouldShowUnsavedModal) {
        state.openUnsavedModal = true
        state.pendingSelection = { elementId, showComponentConfiguration: true }
      } else {
        state.showComponentConfiguration = true
        state.selectedElementId = elementId
      }
    },
    cancelTemplatesAdd: (state) => {
      state.templatesToAdd = []
      state.showTemplateView = false
    },
    resetTemplatesToAdd: (state) => {
      state.templatesToAdd = []
    },
    toggleElementsDisabled: (state, action: PayloadAction<boolean>) => {
      state.areElementsDisabled = action.payload
    },
    contentPageCleanup: () => initialState,
    contentPageInitialize: (
      state,
      action: PayloadAction<Partial<ContentState>>
    ) => {
      state.activeContentId = action.payload.activeContentId
      state.activeTemplateId = action.payload.activeTemplateId
      state.selectedElementId = action.payload.selectedElementId
      state.showComponentConfiguration =
        action.payload.showComponentConfiguration || false
    },
    postAddTemplate: (state, action: PayloadAction<Partial<ContentState>>) => {
      state.templatesToAdd = []
      state.showTemplateView = false
      state.selectedElementId = action.payload.selectedElementId
      state.showComponentConfiguration =
        action.payload.showComponentConfiguration
    },
    showRightPane: (
      state,
      action: PayloadAction<RightPaneVisibility | undefined>
    ) => {
      state.rightPaneVisibility = action.payload || 'visible'
    },
    hideRightPane: (
      state,
      action: PayloadAction<RightPaneVisibility | undefined>
    ) => {
      state.rightPaneVisibility = action.payload || 'hidden'
    },
    showWorkflow: (state) => {
      state.isWorkflowVisible = true
    },
    hideWorkflow: (state) => {
      state.isWorkflowVisible = false
    },
    addTemplate(state, action: PayloadAction<{ templateId: string }>) {
      const templatesToAddCount = state.templatesToAdd.reduce(
        (acc, template) => acc + template.count,
        0
      )
      if (templatesToAddCount >= MAX_TEMPLATES_TO_ADD) {
        return
      }

      const templateId = action.payload.templateId
      const templateToAdd = state.templatesToAdd.find(
        (t) => t.id === templateId
      )
      if (templateToAdd) {
        templateToAdd.count++
      } else {
        state.templatesToAdd.push({ id: templateId, count: 1 })
      }
    },
    removeTemplate(
      state,
      action: PayloadAction<{ templateId: string; erase?: boolean }>
    ) {
      const templateId = action.payload.templateId
      const templateToRemove = state.templatesToAdd.find(
        (t) => t.id === templateId
      )
      if (templateToRemove) {
        if (action.payload.erase) {
          state.templatesToAdd = state.templatesToAdd.filter(
            (t) => t.id !== templateId
          )
        } else {
          templateToRemove.count--
          if (templateToRemove.count === 0) {
            state.templatesToAdd = state.templatesToAdd.filter(
              (t) => t.id !== templateId
            )
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, () => initialState)
  },
})

export const {
  userInputFormHasChanged,
  workflowCardClicked,
  integrationWorkflowCardClicked,
  updateActiveTemplateId,
  updateActiveContentId,
  showTemplates,
  hideTemplates,
  toggleElementsDisabled,
  contentPageInitialize,
  contentPageCleanup,
  showRightPane,
  hideRightPane,
  showWorkflow,
  hideWorkflow,
  componentConfigurationClicked,
  addTemplate,
  postAddTemplate,
  removeTemplate,
  cancelTemplatesAdd,
  resetTemplatesToAdd,
  toggleOpenUnsavedModal,
  inputFormLeave,
} = contentSlice.actions
export default contentSlice.reducer
