import { useLayoutEffect, useMemo, useRef } from 'react'

import { useAppDispatch } from 'common/hooks/redux'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import useVariantsToApproveQuery from '../../hooks/useVariantsToApproveQuery'

const useElementState = () => {
  const dispatch = useAppDispatch()
  const hasRunRef = useRef(false)

  const { elementsWithNewLanguage } = useVariantsToApproveQuery()

  const firstElementId = useMemo(
    () => elementsWithNewLanguage[0]?.element_id,
    [elementsWithNewLanguage]
  )

  useLayoutEffect(() => {
    if (hasRunRef.current) {
      return
    }

    if (firstElementId) {
      dispatch(
        selectElement({
          elementId: firstElementId,
        })
      )
      hasRunRef.current = true
    }
  }, [dispatch, firstElementId])
}

export default useElementState
