export type CreateCustomerJourneyState = {
  isCustomerJourneyFormOpen: boolean
  isConfirmationOpen: boolean
}

export const createCustomerJourneyInitialState = {
  isCustomerJourneyFormOpen: false,
  isConfirmationOpen: false,
}

export type CreateCustomerJourneyActions =
  | { type: 'openForm' }
  | { type: 'createJourneyComplete' }
  | { type: 'cancelCreation' }
  | { type: 'cancelConfirmation' }
  | { type: 'closeConfirmation' }

export const CreateCustomerJourneyReducer: React.Reducer<
  CreateCustomerJourneyState,
  CreateCustomerJourneyActions
> = (previousState, action) => {
  switch (action.type) {
    case 'openForm': {
      return { ...previousState, isCustomerJourneyFormOpen: true }
    }
    case 'cancelCreation': {
      return { ...previousState, isConfirmationOpen: true }
    }
    case 'cancelConfirmation': {
      return { ...previousState, isConfirmationOpen: false }
    }
    case 'closeConfirmation': {
      return {
        ...createCustomerJourneyInitialState,
      }
    }
    case 'createJourneyComplete': {
      return {
        ...createCustomerJourneyInitialState,
      }
    }
    default: {
      return previousState
    }
  }
}
