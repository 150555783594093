import React, { useEffect } from 'react'
import { createPortal } from 'react-dom'
import cx from 'classnames'
import { Drawer as VaulDrawer } from 'vaul'

import { useAppDispatch } from 'common/hooks/redux'
import { hideIntercom, showIntercom } from 'features/auth/store/authSlice'

import DrawerPush from './DrawerPush'

type DrawerRootProps = React.ComponentProps<typeof VaulDrawer.Root>

type Props = {
  title?: string
  isOpen?: DrawerRootProps['open']
  container?: DrawerRootProps['container']
  direction?: DrawerRootProps['direction']
  content?: React.ReactNode
  onOpenChange?: DrawerRootProps['onOpenChange']
  isPush?: boolean
}

const PUSH_CONTAINER_ID = 'drawer-push-container-id'

function Drawer({
  isOpen,
  content,
  title,
  onOpenChange,
  isPush = false,
  direction = 'right',
  container = document.body,
}: Props) {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (['right', 'bottom'].includes(direction)) {
      if (isOpen) {
        dispatch(hideIntercom())
      } else {
        dispatch(showIntercom())
      }
      return () => {
        dispatch(showIntercom())
      }
    }
  }, [direction, dispatch, isOpen])

  return isPush ? (
    createPortal(
      <DrawerPush isOpen={isOpen} title={title} onOpenChange={onOpenChange}>
        {content}
      </DrawerPush>,
      document.getElementById(PUSH_CONTAINER_ID) ?? document.body
    )
  ) : (
    <VaulDrawer.Root
      open={isOpen}
      container={container}
      direction={direction}
      onOpenChange={onOpenChange}
      handleOnly={true}
    >
      <VaulDrawer.Overlay className="fixed inset-0 bg-gold-700 z-100 opacity-40" />
      <VaulDrawer.Content
        className={cx('fixed z-110 outline-none flex', {
          'top-0 bottom-0 right-0': direction === 'right',
          'top-0 bottom-0 left-0': direction === 'left',
          'top-0 right-0 left-0': direction === 'top',
          'bottom-0 right-0 left-0': direction === 'bottom',
        })}
      >
        <div className="flex w-full bg-gold-100">{content}</div>
      </VaulDrawer.Content>
    </VaulDrawer.Root>
  )
}

const PushContainer = ({ children }: { children: React.ReactNode }) => {
  useEffect(() => {
    const elements = document.querySelectorAll(`#${PUSH_CONTAINER_ID}`)
    if (elements.length > 1) {
      throw new Error('Only one Drawer.PushContainer is allowed')
    }
  }, [])

  return (
    <div
      className="flex flex-1 h-full overflow-y-auto overflow-x-hidden"
      id={PUSH_CONTAINER_ID}
    >
      {children}
    </div>
  )
}

Drawer.PushContainer = React.memo(PushContainer)

export default Drawer
