import { Checkbox } from 'common/components/dynamicFields/fields'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { toggleIsUsingUniversalBrief } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { useContent } from '../../hooks'

const ApplyBriefToAllTemplatesCheckbox = () => {
  const dispatch = useAppDispatch()
  const { content } = useContent()

  const isUsingUniversalBrief = useAppSelector(
    (state) => state.unifiedFlow.isUsingUniversalBrief
  )

  const handleOnChange = () => {
    if (content && content.elements) {
      dispatch(
        toggleIsUsingUniversalBrief({
          isUsingUniversalBrief: !isUsingUniversalBrief,
          elementId: content.elements[0].element_id,
        })
      )
    }
  }

  if (!content || content.elements.length === 1) {
    return null
  }
  return (
    <Checkbox
      name="apply_brief_to_all_templates"
      data-testid="apply-brief-to-all-templates"
      value={isUsingUniversalBrief ? ['apply_brief_to_all_templates'] : []}
      onChange={handleOnChange}
      label="Apply brief to all templates"
      max={undefined}
      min={undefined}
      className="flex flex-col"
      options={[
        {
          label: 'Apply brief to all templates',
          value: 'apply_brief_to_all_templates',
        },
      ]}
    />
  )
}

export default ApplyBriefToAllTemplatesCheckbox
