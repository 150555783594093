import Alert from 'common/components/alert'
import Button from 'common/components/button/Button'
import Tooltip from 'common/components/Tooltip'
import { SmallTick } from 'common/icons'
import CreateVariantsCard from 'features/campaigns/integration-options/components/card/CreateVariantsCard'

interface Props {
  deleteVariant: () => void
  createSplits: () => void
  isCreateDisabled: boolean
  isDisplayingDeleteButton: boolean
  isCampaignScheduled: boolean
  isVariantsPushed: boolean
}

export const CreateDeleteButtons = ({
  isDisplayingDeleteButton,
  isCampaignScheduled,
  isVariantsPushed,
  deleteVariant,
  createSplits,
  isCreateDisabled,
}: Props) => {
  return (
    <div className="mb-4 mt-4">
      {isCampaignScheduled && !isDisplayingDeleteButton ? (
        <>
          <Alert type="success" className="flex justify-between items-center">
            <span>Create and send variation to Epsilon.</span>
            <SmallTick className="h-6 w-6 text-white" />
          </Alert>

          <div className="mt-6 flex justify-between items-center">
            <div className="mr-4 text-coolGray-400">
              This will unlink your Jacquard experiment from your Epsilon but
              will not delete the variations
            </div>
            <Button
              data-cy="delete-emails-button"
              data-testid="delete-emails-button"
              onClick={() => deleteVariant()}
              variant="secondary"
              disabled={false}
              loading={false}
            >
              Delete
            </Button>
          </div>
        </>
      ) : (
        <Tooltip
          overlay="This will create the initial split variants and populate the subject lines in Epsilon."
          trigger="hover"
          placement="right"
          overlayStyle={{ maxWidth: 226 }}
        >
          <CreateVariantsCard
            isDisabled={isCreateDisabled}
            isLoading={false}
            cardText="This will create the variants in your Epsilon Email campaign and initialize your Jacquard experiment."
            cardTitle="Create and send test variations"
            buttonText="Start optimisation"
            onSubmit={() => createSplits()}
          />
        </Tooltip>
      )}
    </div>
  )
}
