import cx from 'classnames'

import {
  CrossedCircle as CrossedCircleIcon,
  Square as SquareIcon,
} from 'common/icons'

type Props = {
  title?: string
  content: string | JSX.Element
  className?: string
  hasTitleIcons?: boolean
}

const InsightsCard = ({
  title,
  className,
  content,
  hasTitleIcons = false,
}: Props) => {
  return (
    <div className="py-3" role="article" aria-labelledby={title}>
      {!!title && (
        <div className="flex items-center justify-start mb-2">
          {hasTitleIcons && (
            <>
              <SquareIcon
                isDefaultColor={false}
                className="w-3 h-3 text-base-700 mr-1"
              />
              <CrossedCircleIcon
                isDefaultColor={false}
                className="w-3 text-base-700 mr-1"
              />
            </>
          )}
          <span className="text-sm text-base-700 font-medium leading-4 uppercase">
            {title}
          </span>
        </div>
      )}
      <div
        className={cx(
          'relative flex rounded-sm text-base-700 text-sm',
          className
        )}
      >
        {content}
      </div>
    </div>
  )
}

export default InsightsCard
