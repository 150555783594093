import { unifiedFlowPaths } from 'app/navigation/paths'
import { SubjectLine } from 'workflow/interface'

import Button from 'common/components/button'
import useApproveElementMutation from 'features/unifiedFlow/api/mutations/useApproveElementMutation'
import {
  useContent,
  useSelectedElement,
} from 'features/unifiedFlow/contentPage/hooks'

type Props = {
  variants: SubjectLine[]
  isApproveButtonDisabled: boolean
}

const ApprovalsTableActionButtons = ({
  variants,
  isApproveButtonDisabled,
}: Props) => {
  const { content } = useContent()
  const approveElementMutation = useApproveElementMutation({
    redirectTo: unifiedFlowPaths.results,
  })
  const { data: selectedElement } = useSelectedElement()

  const handleApprove = () => {
    if (selectedElement && content) {
      approveElementMutation.mutate({
        accountId: content.account_id,
        element_id: selectedElement.element_id,
        content_id: content?._id,
        variant_ids: variants.map(({ _id }) => _id),
      })
    }
  }

  return (
    <Button
      disabled={approveElementMutation.isLoading || isApproveButtonDisabled}
      loading={approveElementMutation.isLoading}
      className="ml-4"
      variant="primary"
      onClick={handleApprove}
    >
      Approve
    </Button>
  )
}

export default ApprovalsTableActionButtons
