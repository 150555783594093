import cx from 'classnames'

export interface Props {
  htmlFor?: string
  className?: string
  optional?: boolean
  orientation?: 'horizontal' | 'vertical'
  children?: React.ReactNode
  status?: 'error' | 'default'
  disabled?: boolean
}

const Label = ({
  htmlFor,
  className,
  optional = false,
  orientation = 'vertical',
  children,
  disabled,
}: Props) => {
  return (
    <label
      htmlFor={htmlFor}
      className={cx(
        'block text-base-700 font-normal text-xs',
        {
          'mr-4': orientation === 'horizontal',
          'mb-2': orientation === 'vertical',
          'opacity-50': disabled,
        },

        className
      )}
    >
      {children}
      {optional && <span className="text-base-700"> (optional)</span>}
    </label>
  )
}

export default Label
