import { ChangeEvent } from 'react'
import cx from 'classnames'
import helpers from 'workflow/utils/helpers'

import Button from 'common/components/button/Button'
import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import { ReactComponent as Bin } from 'common/icons/bin/default.svg'

interface Props {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: string
  onRemove: () => void
}

const Email = ({ onChange, value, onRemove }: Props) => {
  const hasError = value?.length > 0 && !helpers.isValidEmail(value)
  const isDisabled = value?.trim().length === 0
  return (
    <div className="flex justify-between">
      <FormItem
        error={
          hasError
            ? 'Email address is not valid. eg. robin@jacquard.com'
            : undefined
        }
      >
        <Input
          type="text"
          onChange={onChange}
          name="email"
          value={value}
          placeholder="eg. greg@jacquard.com"
          hasError={hasError}
          isValid={value?.length > 0 && helpers.isValidEmail(value)}
          variant="default"
        />
      </FormItem>
      <Button
        aria-label="Clear"
        className={cx('ml-6 h-10', { 'text-coolGray-200': isDisabled })}
        variant="icon"
        data-cy="generate-token-clear"
        data-testid="generate-token-clear"
        disabled={isDisabled}
        onClick={() => {
          onRemove()
        }}
      >
        <Bin className="h-6 w-6" />
      </Button>
    </div>
  )
}

export default Email
