import React, { ChangeEvent } from 'react'
import { Input } from 'antd'
import ControlInput from 'workflow/common/controlInput'

import { replaceLineBreakAsNewLineEmoji } from 'common/variantsUtils'

import { useTweakVariant } from '../context/TweakVariantContext'

const { TextArea } = Input

const changeReasonPlaceholder =
  'Explain as best as possible so we can learn and give better results in the future.'

const EditForm = () => {
  const context = useTweakVariant()
  const {
    variantText,
    showTweakingReason,
    noTweakingReason,
    tweakingReason,
    selectedLine,
    updateState,
    feedbackEditEnabled,
    selectedState,
    newlinesInVariants,
  } = context

  const tweakingLine = (value: string, originalValue: string) => {
    const showTweakingReason = selectedLine.text.trim() !== originalValue.trim()

    updateState({
      variantText: value,
      showTweakingReason,
    })
  }

  const tweakingReasonChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const reason = event.target.value

    updateState({
      tweakingReason: reason,
      noTweakingReason: reason.trim() === '',
    })
  }

  const onBlur = () => {
    updateState({ variantText: replaceLineBreakAsNewLineEmoji(variantText) })
  }

  return feedbackEditEnabled && selectedState === 'edit' ? (
    <div>
      <React.Fragment>
        <div className="mt-6 text-sm mb-1">
          <p className="mb-2 font-normal text-base text-coolGray-800">
            Make your edits here:
          </p>
          <ControlInput
            data-cy="textarea-edited-line"
            data-testid="edited-line-textarea"
            onBlur={onBlur}
            value={variantText}
            onChange={tweakingLine}
            maxRows={newlinesInVariants?.max}
            rows={5}
            className="placeholder-gold-700 bg-white focus:border-gold-400 hover:border-gold-400"
          />
        </div>
        <div className="mt-6">
          <p className="mb-2 font-normal text-base text-coolGray-800">
            Please tell us why
          </p>
          <TextArea
            data-cy="textarea-reason-test"
            data-testid="edit-reason-textarea"
            onChange={tweakingReasonChange}
            value={tweakingReason}
            placeholder={changeReasonPlaceholder}
            rows={4}
            className="placeholder-gold-700 bg-gold-40 focus:border-gold-400 hover:border-gold-400"
          />
          {showTweakingReason && noTweakingReason ? (
            <div className="text-red-400">Please give us a reason</div>
          ) : undefined}
        </div>
      </React.Fragment>
    </div>
  ) : null
}

export default EditForm
