import { Field, useFormState } from 'react-final-form'
import isString from 'lodash/isString'

import Header from './fields/Header'
import {
  Checkbox,
  Currency,
  Date,
  Dropdown,
  DropdownMulti,
  Numeric,
  Percentage,
  Radio,
  TaggedInput,
  TagWidget,
  Text,
  Textarea,
} from './fields'
import {
  CommonFieldPropsGeneric,
  FieldsConfiguration,
  FieldType,
} from './interfaces'

type Props = {
  fieldsConfiguration: FieldsConfiguration
  isDisabled?: boolean
}

const requiredValidator = (value) => {
  if (isString(value)) {
    return value.trim() ? undefined : 'required'
  }
  return value ? undefined : 'required'
}
const minOptionsValidator = (min) => (values) =>
  values?.length >= min ? undefined : 'min'
const maxOptionsValidator = (max) => (values) =>
  values?.length <= max ? undefined : 'max'

const composeValidators =
  (...validators: (Function | undefined)[]) =>
  (value) =>
    validators.reduce(
      (error, validator) => error || validator?.(value),
      undefined
    )

const DynamicFields = ({ fieldsConfiguration, isDisabled }: Props) => {
  const { values } = useFormState()

  return (
    <>
      {fieldsConfiguration.map((fieldConfig) => {
        let shouldRenderComponent = true

        if (fieldConfig.conditions) {
          shouldRenderComponent = fieldConfig.conditions?.some(
            (cond) => values[cond.fieldName] === cond.condition
          )
        }

        if (!shouldRenderComponent) {
          return null
        }

        const commonProps: Omit<CommonFieldPropsGeneric, 'onChange'> = {
          isDisabled: isDisabled || fieldConfig.isDisabled,
          name: fieldConfig.name,
          label: fieldConfig.label,
          tooltip: fieldConfig.tooltip,
          'data-testid': fieldConfig.name,
        }

        const commonFieldProps = {
          key: fieldConfig.name,
          name: fieldConfig.name,
        }

        let fieldElement: JSX.Element | null = null

        switch (fieldConfig.type) {
          case FieldType.Header:
            fieldElement = (
              <Header
                label={fieldConfig.label}
                componentProps={fieldConfig.componentProps}
              />
            )
            break
          case FieldType.Text:
            fieldElement = (
              <Field<string>
                {...commonFieldProps}
                validate={fieldConfig.required ? requiredValidator : undefined}
                render={({ input, meta }) => (
                  <Text
                    {...input}
                    {...commonProps}
                    componentProps={fieldConfig.componentProps}
                    placeholder={fieldConfig.placeholder}
                    errorCode={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                  />
                )}
              />
            )
            break
          case FieldType.Textarea:
            fieldElement = (
              <Field<string>
                {...commonFieldProps}
                validate={composeValidators(
                  fieldConfig.required ? requiredValidator : undefined,
                  fieldConfig.min
                    ? minOptionsValidator(fieldConfig.min)
                    : undefined
                )}
                render={({ input, meta }) => (
                  <Textarea
                    {...commonProps}
                    {...input}
                    max={fieldConfig.max}
                    min={fieldConfig.min}
                    componentProps={fieldConfig.componentProps}
                    placeholder={fieldConfig.placeholder}
                    errorCode={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                  />
                )}
              />
            )
            break
          case FieldType.Radio:
            fieldElement = (
              <Field<string>
                {...commonFieldProps}
                initialValue={fieldConfig.value}
                validate={fieldConfig.required ? requiredValidator : undefined}
                render={({ input, meta }) => (
                  <Radio
                    {...commonProps}
                    {...input}
                    options={fieldConfig.options}
                    componentProps={fieldConfig.componentProps}
                    errorCode={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                  />
                )}
              />
            )
            break
          case FieldType.Dropdown:
            fieldElement = (
              <Field<string>
                {...commonFieldProps}
                validate={fieldConfig.required ? requiredValidator : undefined}
                render={({ input, meta }) => (
                  <Dropdown
                    {...commonProps}
                    {...input}
                    componentProps={fieldConfig.componentProps}
                    options={fieldConfig.options}
                    errorCode={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                  />
                )}
              />
            )
            break
          case FieldType.DropdownMulti:
            fieldElement = (
              <Field<string[]>
                {...commonFieldProps}
                validate={fieldConfig.required ? requiredValidator : undefined}
                render={({ input, meta }) => (
                  <DropdownMulti
                    {...commonProps}
                    {...input}
                    min={fieldConfig.min}
                    max={fieldConfig.max}
                    options={fieldConfig.options}
                    errorCode={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                  />
                )}
              />
            )
            break
          case FieldType.TaggedInput:
            fieldElement = (
              <Field<string[]>
                {...commonFieldProps}
                validate={composeValidators(
                  fieldConfig.required ? requiredValidator : undefined,
                  fieldConfig.min
                    ? minOptionsValidator(fieldConfig.min)
                    : undefined
                )}
                render={({ input, meta }) => (
                  <TaggedInput
                    {...commonProps}
                    {...input}
                    min={fieldConfig.min}
                    max={fieldConfig.max}
                    options={fieldConfig.options}
                    errorCode={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                  />
                )}
              />
            )
            break
          case FieldType.TagWidget:
            fieldElement = (
              <Field<string[]>
                {...commonFieldProps}
                validate={composeValidators(
                  fieldConfig.required ? requiredValidator : undefined
                )}
                render={({ input, meta }) => (
                  <TagWidget
                    {...commonProps}
                    {...input}
                    tagButtonLabel={fieldConfig.tag_button_label}
                    hasTagButtonLabel={fieldConfig.has_tag_button_label}
                    errorCode={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                  />
                )}
              />
            )
            break
          case FieldType.Numeric:
            fieldElement = (
              <Field<number>
                {...commonFieldProps}
                validate={fieldConfig.required ? requiredValidator : undefined}
                render={({ input, meta }) => (
                  <Numeric
                    {...commonProps}
                    {...input}
                    errorCode={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                  />
                )}
              />
            )
            break
          case FieldType.Percentage:
            fieldElement = (
              <Field<number>
                {...commonFieldProps}
                validate={fieldConfig.required ? requiredValidator : undefined}
                render={({ input, meta }) => (
                  <Percentage
                    {...commonProps}
                    {...input}
                    errorCode={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                  />
                )}
              />
            )
            break
          case FieldType.Currency:
            fieldElement = (
              <Field<string>
                {...commonFieldProps}
                validate={fieldConfig.required ? requiredValidator : undefined}
                render={({ input, meta }) => (
                  <Currency
                    {...commonProps}
                    {...input}
                    errorCode={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                  />
                )}
              />
            )
            break
          case FieldType.Date:
            fieldElement = (
              <Field<string>
                {...commonFieldProps}
                validate={fieldConfig.required ? requiredValidator : undefined}
                render={({ input, meta }) => (
                  <Date
                    {...commonProps}
                    {...input}
                    errorCode={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                  />
                )}
              />
            )
            break
          case FieldType.Checkbox:
            fieldElement = (
              <Field<string[]>
                {...commonFieldProps}
                validate={composeValidators(
                  fieldConfig.required ? requiredValidator : undefined,
                  fieldConfig.min
                    ? minOptionsValidator(fieldConfig.min)
                    : undefined,
                  fieldConfig.max
                    ? maxOptionsValidator(fieldConfig.max)
                    : undefined
                )}
                render={({ input, meta }) => (
                  <Checkbox
                    {...commonProps}
                    {...input}
                    options={fieldConfig.options}
                    min={fieldConfig.min}
                    max={fieldConfig.max}
                    errorCode={
                      meta.error && meta.touched ? meta.error : undefined
                    }
                  />
                )}
              />
            )
            break
          default:
            break
        }

        return fieldElement
      })}
    </>
  )
}

export default DynamicFields
