import { useState } from 'react'
import { ChangeEvent } from 'react'

import Button from 'common/components/button/Button'
import FormItem from 'common/components/formItem'
import Image from 'common/components/image'
import Input from 'common/components/input'

type Props = {
  name: string
  isLoading?: boolean
  logoUrl?: string
  inputLabel?: string
  submitButtonText: string
  placeholder?: string
  defaultIntegrationLabel?: string
  onCancel: () => void
  onSubmit: (label: string) => void
  className?: string
}

const IntegrationForm = ({
  name,
  logoUrl,
  inputLabel = 'Name',
  submitButtonText,
  defaultIntegrationLabel,
  placeholder,
  className,
  isLoading = false,
  onCancel,
  onSubmit,
}: Props) => {
  const [integrationLabel, setIntegrationLabel] = useState<string | undefined>(
    defaultIntegrationLabel
  )

  return (
    <div className={className}>
      <div className="flex flex-col items-center mb-2 h-19">
        <Image isResponsive src={logoUrl} alt={`${name}-logo`} />
      </div>
      <FormItem
        label={inputLabel}
        htmlFor={`${name}-integration`}
        className="w-full"
      >
        <Input
          disabled={isLoading}
          id={`${name}-integration`}
          type="text"
          aria-label="Integration name"
          variant="default"
          value={integrationLabel}
          placeholder={placeholder}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setIntegrationLabel(event.target.value)
          }}
          className="w-full mt-1"
        />
      </FormItem>
      <div className="w-full grid gap-2 grid-flow-col grid-cols-2">
        <Button
          aria-label="Cancel"
          ghost
          className="uppercase"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          data-testid="integration-submit-button"
          loading={isLoading}
          aria-label="Submit"
          variant="primary"
          className="uppercase"
          disabled={
            isLoading || !integrationLabel || integrationLabel?.trim() === ''
          }
          onClick={() => onSubmit(integrationLabel!)}
        >
          {submitButtonText}
        </Button>
      </div>
    </div>
  )
}

export default IntegrationForm
