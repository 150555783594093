import { useForm, useFormState } from 'react-final-form'
import isEmpty from 'lodash/isEmpty'

import Button from 'common/components/button'
import { Topic } from 'common/components/topics/interfaces'
import { useAppDispatch } from 'common/hooks/redux'
import { ArrowRight as ArrowRightIcon } from 'common/icons'
import { isTemplateElement } from 'features/futurama/api/interfaces'
import { getFirstComponentElement } from 'features/futurama/contentPage/helpers'
import {
  useContentOrTemplate,
  useSelectedElement,
} from 'features/futurama/contentPage/hooks'
import { workflowCardClicked } from 'features/futurama/store/contentSlice'

import { PageState } from '../StartHere'

type Props = {
  topics: Topic[]
  pageState: PageState
  isCreatingContent: boolean
  isUpdatingContent: boolean
  isGeneratingVariants: boolean
  revertTopics: () => void
}

const ActionButtons = ({
  topics,
  pageState,
  isCreatingContent,
  isUpdatingContent,
  isGeneratingVariants,
  revertTopics,
}: Props) => {
  const dispatch = useAppDispatch()

  const form = useForm()
  const formState = useFormState()

  const { content } = useContentOrTemplate()

  const areTopicsValid = topics.every((topic) => {
    return !isEmpty(topic.name)
  })
  const { data: selectedElement } = useSelectedElement()

  const onContinue = () => {
    const continueElement =
      selectedElement === undefined
        ? getFirstComponentElement(content)
        : isTemplateElement(selectedElement)
        ? selectedElement.elements[0]
        : selectedElement

    if (continueElement !== undefined) {
      dispatch(
        workflowCardClicked({
          elementId: continueElement.element_id,
        })
      )
    }
  }

  return (
    <div className="flex pt-6 justify-end items-center">
      {(pageState === 'showContent' ||
        pageState === 'showContentWithoutTopics') && (
        <>
          <span className="mr-4 text-base font-medium text-base-700">
            Next up...
          </span>
          <Button
            type="submit"
            data-cy="content-magic-button"
            data-testid="content-magic-button"
            variant="primary"
            disabled={formState.invalid || !areTopicsValid}
            suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
            loading={isGeneratingVariants}
          >
            Generate
          </Button>
        </>
      )}
      {pageState === 'topicsChanged' && (
        <>
          <span className="mr-4 text-base font-medium text-base-700">
            You've made changes...
          </span>
          <Button
            ghost
            className="mr-4"
            disabled={isGeneratingVariants}
            onClick={revertTopics}
          >
            Revert
          </Button>
          <Button
            type="submit"
            data-cy="content-regenerate-button"
            data-testid="content-regenerate-button"
            variant="primary"
            disabled={
              formState.invalid || !areTopicsValid || isGeneratingVariants
            }
            suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
            loading={isGeneratingVariants}
          >
            Regenerate
          </Button>
        </>
      )}
      {pageState === 'formChanged' && (
        <>
          <Button
            ghost
            className="mr-4"
            disabled={isUpdatingContent}
            onClick={() => form?.reset()}
          >
            Revert
          </Button>
          <Button
            type="submit"
            data-cy="content-refresh-topics-button"
            data-testid="content-refresh-topics-button"
            variant="primary"
            suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
            loading={isUpdatingContent}
            disabled={formState.invalid || isUpdatingContent}
          >
            Refresh topics
          </Button>
        </>
      )}
      {(pageState === 'showGeneratedContent' ||
        pageState === 'showGeneratedContentWithoutTopics') && (
        <>
          <span className="mr-4 text-base font-medium text-base-700">
            Next up...
          </span>
          <Button
            data-cy="content-continue-button"
            data-testid="content-continue-button"
            variant="primary"
            onClick={onContinue}
            suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
          >
            Continue
          </Button>
        </>
      )}
      {pageState === 'showTemplate' && (
        <>
          <span className="mr-4 text-base font-medium text-base-700">
            Next up...
          </span>
          <Button
            type="submit"
            disabled={
              formState.invalid || isCreatingContent || formState.pristine
            }
            loading={isCreatingContent}
            variant="primary"
            data-cy="content-generate-topics"
            data-testid="content-generate-topics"
            suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
          >
            Generate topics
          </Button>
        </>
      )}
      {pageState === 'showTemplateWithoutTopics' && (
        <>
          <span className="mr-4 text-base font-medium text-base-700">
            Next up...
          </span>
          <Button
            type="submit"
            disabled={
              formState.invalid || isCreatingContent || formState.pristine
            }
            loading={isCreatingContent}
            data-cy="content-magic-button"
            data-testid="content-magic-button"
            variant="primary"
            suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
          >
            Generate
          </Button>
        </>
      )}
      {pageState === 'formChangedWithoutTopics' && (
        <>
          <span className="mr-4 text-base font-medium text-base-700">
            You've made changes...
          </span>
          <Button
            ghost
            className="mr-4"
            disabled={isUpdatingContent}
            onClick={() => form?.reset()}
          >
            Revert
          </Button>
          <Button
            type="submit"
            data-cy="content-regenerate-variants-button"
            data-testid="content-regenerate-variants-button"
            variant="primary"
            disabled={
              formState.invalid || isUpdatingContent || formState.pristine
            }
            loading={isUpdatingContent}
            suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
          >
            Regenerate
          </Button>
        </>
      )}
    </div>
  )
}

export default ActionButtons
