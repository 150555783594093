import { useEffect, useState } from 'react'
import cx from 'classnames'
import { showBanner } from 'workflow/common/common.actions'

import { getTokens } from 'common/api/tokensApi'
import Button from 'common/components/button/Button'
import ButtonWithTooltip from 'common/components/button/withTooltip'
import ConfirmationModal from 'common/components/confirmationModal'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import PageContainer from 'common/components/PageContainer'
import Widget from 'common/components/Widget'
import { WidgetError } from 'common/components/WidgetMessage'
import { copyAndReplaceNewLineEmoji } from 'common/helpers/copyAndReplaceNewLineEmoji'
import { formatDate } from 'common/helpers/date'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Bin, Copy } from 'common/icons'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'

import { deleteToken, Token } from './api'
import GenerateToken from './GenerateToken'
import InputForm from './Input'

export const apiTokensText = {
  header: 'API Tokens',
  newTokenButtonName: 'New token',
  successDelete: 'Token deleted successfully',
  errorDelete: 'Failed to delete token',
  tokenNameLabel: 'Token name',
  deleteTokenTitle: 'Delete token',
  expirationDateLabel: 'Expiration date',
}

const MAX_TOKEN_ALLOWED = 10

const ApiTokens = () => {
  const dispatch = useAppDispatch()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const [isNewTokenFormVisible, setIsNewTokenFormVisible] =
    useState<boolean>(false)
  const [data, setData] = useState<Token[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const [tokenToBeDeleted, setTokenToBeDeleted] = useState<string>()
  const [hasError, setHasError] = useState<boolean>(false)

  useEffect(() => {
    setIsLoading(true)
    getTokens(accountId)
      .then(({ data }) =>
        setData(
          data.map(({ customer_identifier, _id, expires, token }) => ({
            id: _id,
            name: customer_identifier,
            expires,
            token,
          }))
        )
      )
      .catch(() => setHasError(true))
      .finally(() => setIsLoading(false))
  }, [accountId])

  const handleDelete = () => {
    if (!tokenToBeDeleted) {
      return
    }
    setIsDeleting(true)

    deleteToken({ id: tokenToBeDeleted, accountId })
      .then(() => {
        const filteredData = data?.filter((obj) => obj.id !== tokenToBeDeleted)

        setData(filteredData)
        dispatch(
          showBanner({
            content: apiTokensText.successDelete,
            type: 'success',
          })
        )
      })
      .catch(() =>
        dispatch(
          showBanner({
            content: apiTokensText.errorDelete,
            type: 'error',
          })
        )
      )
      .finally(() => {
        setIsDeleting(false)
        setIsDeleteModalOpen(false)
      })
  }

  const handleNewTokenCreated = (value: Token) => {
    setData(data?.length ? [...data, value] : [value])
    setIsNewTokenFormVisible(false)
  }

  const handleCopy = (text: string) => {
    copyAndReplaceNewLineEmoji(text)

    dispatch(
      showBanner({
        type: 'success',
        content: 'Token copied successfully',
      })
    )
  }

  const hasReachedMaxToken = data?.length === MAX_TOKEN_ALLOWED

  return (
    <PageContainer className="max-w-318 sm:mx-auto pt-10 sm:pl-4">
      <Widget data-cy="api-tokens-widget" data-testid="api-tokens-widget">
        {isLoading ? (
          <WidgetLoader />
        ) : (
          <>
            <div className="mb-6 flex justify-between">
              <h2 className="text-gray-900 text-xl font-medium">
                {apiTokensText.header}
              </h2>

              <Button
                data-cy="generate-token-button"
                data-testid="generate-token-button"
                variant="primary"
                onClick={() => setIsNewTokenFormVisible(true)}
                prefixIcon={<AddIcon width={24} height={24} />}
                disabled={isNewTokenFormVisible || hasReachedMaxToken}
              >
                {apiTokensText.newTokenButtonName}
              </Button>
            </div>
            <div className="mb-6">
              <p>Tokens function like ordinary OAuth access tokens.</p>
              <p>
                They can be used to authenticate to the API with the{' '}
                <span className="font-bold">
                  Authorization: Bearer (token) header.
                </span>
              </p>
              <p
                className={cx('mt-7 font-bold', {
                  'text-red-400': hasReachedMaxToken,
                })}
              >
                {data?.length
                  ? hasReachedMaxToken
                    ? 'You have 0 / 10 tokens remaining. If you need more please contact us.'
                    : `You have ${data.length} / 10 tokens remaining.`
                  : 'You can add up to 10 tokens for your account.'}
              </p>
            </div>

            {isNewTokenFormVisible ? (
              <div className="mb-6">
                <GenerateToken
                  accountId={accountId}
                  onNewTokenCreated={handleNewTokenCreated}
                  onClearClick={() => {
                    setIsNewTokenFormVisible(false)
                  }}
                />
              </div>
            ) : undefined}

            {hasError && <WidgetError />}
            <div className="grid gap-8">
              {data?.map(({ id, name, token, expires }) => (
                <div key={id} className="flex gap-4 items-end ">
                  <InputForm
                    width={300}
                    value={name ?? '-'}
                    onChange={() => {}}
                    isDisabled
                    label={apiTokensText.tokenNameLabel}
                  />

                  <div
                    className="flex items-center justify-between w-full"
                    style={{ maxWidth: 350 }}
                  >
                    <label
                      className="text-coolGray-800 font-medium"
                      onClick={(e) => e.preventDefault()} // prevent the button to be triggered on click
                    >
                      Token
                      <div
                        className="bg-coolGray-50 h-10 border border-coolGray-300 pl-4 focus:outline-none font-medium flex items-center justify-between"
                        style={{ width: 350 }}
                      >
                        <div
                          className="max-w-xs truncate"
                          style={{ width: 200 }}
                        >
                          {token}
                        </div>

                        <ButtonWithTooltip
                          data-cy="copy-token"
                          data-testid="copy-token"
                          tooltip={false}
                          title="Copy token to clipboard"
                          className="py-0"
                          onClick={() => handleCopy(token)}
                          icon={<Copy />}
                        />
                      </div>
                    </label>
                  </div>

                  <InputForm
                    label={apiTokensText.expirationDateLabel}
                    width={180}
                    onChange={() => {}}
                    value={formatDate(expires, 'd MMMM yyyy') ?? '-'}
                    isDisabled
                  />
                  <ButtonWithTooltip
                    data-cy="delete-token"
                    data-testid="delete-token"
                    tooltip={false}
                    title={apiTokensText.deleteTokenTitle}
                    className="py-0 mb-2"
                    onClick={() => {
                      setIsDeleteModalOpen(true)
                      setTokenToBeDeleted(id)
                    }}
                    disabled={isDeleting}
                    icon={<Bin />}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </Widget>
      <ConfirmationModal
        data-cy="delete-token-confirm-modal"
        data-testid="delete-token-confirm-modal"
        title="Whooooaaaaaa! Delete token?"
        confirmationText="Are you sure you want to delete this token? If you do this any
        application using this token will loose access to the Jacquard API."
        open={isDeleteModalOpen}
        isLoading={isDeleting}
        onConfirm={() => handleDelete()}
        onCancel={() => {
          setIsDeleteModalOpen(false)
        }}
      />
    </PageContainer>
  )
}

export default ApiTokens
