import cx from 'classnames'

type Props = {
  children?: React.ReactNode
  className?: string
}

const TemplatesConfigPanelHeader = ({ children, className }: Props) => {
  return (
    <div
      className={cx(
        'flex flex-col bg-gold-100  overflow-x-hidden relative',
        className
      )}
    >
      {children}
    </div>
  )
}

export default TemplatesConfigPanelHeader
