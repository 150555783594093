import { Element } from '@phrasee/phrasee-typings/typings/futurama/element'
import { useQueryClient } from '@tanstack/react-query'
import { cond, get, has } from 'lodash'

import useContent from 'common/hooks/useContent'
import {
  isComponentElement,
  isTemplateElement,
} from 'features/futurama/api/interfaces'

import { contentKeys } from '../../api/queryKeys'
import {
  contentVariantMapper,
  downloadCsvFile,
  downloadXlsFile,
  elementDataFormatter,
  optimizationVariantMapper,
} from '../exportHelpers'

export const FILES_TYPES = {
  CSV: 'csv',
  XLS: 'xls',
} as const

const useDownloadFile = () => {
  const { content } = useContent()

  const queryClient = useQueryClient()

  const getFileData = (element: Element, isCampaign: boolean) =>
    cond([
      [
        () => isCampaign,
        () => {
          const campaignData = queryClient.getQueryData([
            contentKeys.campaign(get(element, 'campaign_id', '')),
          ])

          return [
            elementDataFormatter({
              name: element.display_name || element.name,
              element: campaignData,
              variantsPath: 'data.sl',
              variantsMapperHandler: optimizationVariantMapper,
            }),
          ]
        },
      ],
      [
        () => isTemplateElement(element),
        () =>
          get(element, 'elements', []).map((item) =>
            elementDataFormatter({
              name: item.display_name,
              element: item,
              variantsPath: 'variants',
              variantsMapperHandler: contentVariantMapper,
            })
          ),
      ],
      [
        () => isComponentElement(element),
        () => [
          elementDataFormatter({
            name: element.display_name || element.name,
            element: element,
            variantsPath: 'variants',
            variantsMapperHandler: contentVariantMapper,
          }),
        ],
      ],
    ])()

  const downloadFile = (type?: 'csv' | 'xls') => {
    content?.elements?.forEach((element: Element) => {
      const isCampaign = has(element, 'campaign_id')
      const data = getFileData(element, isCampaign)

      if (FILES_TYPES.CSV === type) {
        downloadCsvFile(element, data, content)
      }
      if (FILES_TYPES.XLS === type) {
        downloadXlsFile(element, data, content)
      }
    })
  }

  return {
    downloadFile,
  }
}

export default useDownloadFile
