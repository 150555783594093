import { forwardRef, ReactElement } from 'react'
import {
  ControlledMenu as ControlledMenuRm,
  ControlledMenuProps,
  Menu,
  MenuButton as MenuButtonRM,
  MenuButtonProps,
  MenuDivider,
  MenuHeader as MenuHeaderRM,
  MenuHeaderProps,
  MenuItem as MenuItemRM,
  MenuItemProps,
  MenuProps,
  SubMenu as SubMenuRM,
  SubMenuProps,
} from '@szhsin/react-menu'
import cx from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'

import '@szhsin/react-menu/dist/core.css'
import '@szhsin/react-menu/dist/index.css'
import styles from './MenuButton.module.css'

const menuItemClassName = cx(
  styles.menuItem,
  'h-10 text-coolGray-800 hover:bg-coolGray-100'
)

const MenuItem = (props: MenuItemProps) => (
  <MenuItemRM {...props} className={menuItemClassName} />
)

const SubMenu = (props: SubMenuProps) => (
  <SubMenuRM
    {...props}
    menuClassName="mt-2 mr-2 shadow-2xl border border-coolGray-200 "
    itemProps={{ className: menuItemClassName }}
    offsetY={-7}
  />
)

const MenuHeader = (props: MenuHeaderProps) => (
  <MenuHeaderRM {...props} className="text-xs normal-case" />
)

interface ButtonProps extends MenuButtonProps {
  'data-cy'?: string
  'data-testid'?: string
}

type Props = {
  buttonProps?: ButtonProps
  options: ReactElement
  'data-testid'?: string
} & Partial<MenuProps>

const MenuButton = ({
  buttonProps,
  options,
  'data-testid': dataTestId,
  menuButton,
  ...rest
}: Props) => {
  return (
    <Menu
      menuClassName={cx('shadow-2xl', styles.menu)}
      // TODO: replace all instances of buttonProps with menuButton
      menuButton={menuButton ? menuButton : <MenuButtonRM {...buttonProps} />}
      data-testid={dataTestId}
      {...rest}
    >
      {options}
    </Menu>
  )
}

const ControlledMenu = forwardRef<HTMLDivElement, ControlledMenuProps>(
  (
    { children, className, menuClassName, ...rest }: ControlledMenuProps,
    ref
  ) => {
    const flags = useFlags()

    return flags?.showL2Components ? (
      <ControlledMenuRm
        ref={ref}
        className={className}
        menuClassName={cx(
          'bg-gold-50 border border-gold-200 border-solid p-6 rounded-sm',
          menuClassName
        )}
        {...rest}
      >
        {children}
      </ControlledMenuRm>
    ) : (
      <ControlledMenuRm
        ref={ref}
        className={className}
        menuClassName={menuClassName}
        {...rest}
      >
        {children}
      </ControlledMenuRm>
    )
  }
)

export { MenuDivider, MenuItem, SubMenu, MenuHeader, ControlledMenu }
export default MenuButton
