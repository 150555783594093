import cx from 'classnames'
import { Item as MenuItem } from 'rc-menu'

import { Check, SortAsc, SortDesc } from 'common/icons'

import type { Sort, SortBy, SortOrder } from '../store/imagesSlice'

type Props = {
  sortBy: SortBy
  label: string
  isActive: boolean
  onClick: ({ sortBy, sortOrder }: Sort) => void
  sortOrder: SortOrder
}

const ImageSortMenuItem = ({
  sortBy,
  label,
  isActive,
  onClick,
  sortOrder,
  ...restProps
}: Props) => (
  <MenuItem
    {...restProps}
    className={cx(
      'group flex items-center text-xs font-normal py-2 px-4 -mx-4 cursor-pointer hover:bg-gold-300 gap-2 w-58',
      {
        'text-base-700': isActive,
        'text-gold-700': !isActive,
      }
    )}
    onClick={() => onClick({ sortBy, sortOrder })}
  >
    <Check
      isDefaultColor={false}
      size={4}
      className={cx({
        visible: isActive,
        invisible: !isActive,
      })}
    />
    {label}
    <>
      {
        {
          ASC: (
            <button
              className="outline-none flex items-center gap-1"
              onClick={(e) => {
                e.stopPropagation()
                onClick({ sortBy, sortOrder: 'DESC' })
              }}
            >
              <SortAsc isDefaultColor={false} size={4} />
              Ascending
            </button>
          ),
          DESC: (
            <button
              className="outline-none flex items-center gap-1"
              onClick={(e) => {
                e.stopPropagation()
                onClick({ sortBy, sortOrder: 'ASC' })
              }}
            >
              <SortDesc isDefaultColor={false} size={4} />
              Descending
            </button>
          ),
        }[sortOrder]
      }
    </>
  </MenuItem>
)

export default ImageSortMenuItem
