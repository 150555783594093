import { getIsAuthorized, usersPermissions } from 'common/auth/permissions'
import Autocomplete from 'common/components/autocomplete'
import { SelectValue } from 'common/components/BaseSelect'
import Button from 'common/components/button/Button'
import ProjectMultiSelect from 'common/components/ProjectMultiSelect'
import { useAppSelector } from 'common/hooks/redux'
import { ReactComponent as AddDefault } from 'common/icons/add/default.svg'

import { User } from './api'

type Props = {
  onAddClick: () => void
  onSearch: (value: string) => void
  searchValue: string
  selectedProjectIds: string[]
  onSelectProjectIds: (ids: string[]) => void
  users: User[]
  isAddButtonVisible: boolean
}
const Header = ({
  onAddClick,
  onSearch,
  searchValue = '',
  selectedProjectIds,
  onSelectProjectIds,
  users,
  isAddButtonVisible,
}: Props) => {
  const { permissions } = useAppSelector((state) => state.authStates)

  const namesAndEmails = users.reduce<{
    names: SelectValue[]
    emails: SelectValue[]
  }>(
    (accumulator, user) => {
      accumulator.names.push({
        value: user.id,
        label: `${user.firstName} ${user.lastName}`,
      })
      accumulator.emails.push({
        value: user.id,
        label: user.email,
      })
      return accumulator
    },
    { names: [], emails: [] }
  )
  const options = [...namesAndEmails.names, ...namesAndEmails.emails]

  return (
    <>
      <ProjectMultiSelect
        className="w-60"
        data-cy="dashboard-project-select"
        data-testid="dashboard-project-select"
        selectedItems={selectedProjectIds}
        onApply={onSelectProjectIds}
      />
      <Autocomplete
        className="w-60"
        placeholder="Search"
        data-cy="users-search"
        data-testid="users-search"
        options={options}
        value={{ value: searchValue, label: searchValue }}
        defaultInputValue={searchValue}
        onSearch={onSearch}
        backspaceRemovesValue={true}
      />
      {getIsAuthorized(permissions, usersPermissions.create) &&
        isAddButtonVisible && (
          <Button
            data-cy="create-user-button"
            data-testid="create-user-button"
            aria-label="Create a new user"
            variant="primary"
            onClick={() => onAddClick()}
            className="text-base"
            prefixIcon={<AddDefault width={24} height={24} />}
          >
            User
          </Button>
        )}
    </>
  )
}

export default Header
