import { useState } from 'react'

import InlineTextEdit from 'common/components/inlineTextEdit'
import useUpdateImageMetadataMutation from 'features/admin/imageLibrary/api/mutations/useUpdateImageMetadataMutation'

type Props = {
  id: string
  name: string
}

const EditableImageName = ({ id, name }: Props) => {
  const [imageName, setImageName] = useState<string>(name)
  const { mutate, isLoading } = useUpdateImageMetadataMutation()

  return (
    <InlineTextEdit
      fontSizeClassName="text-xs"
      textColorClassName="text-base-700"
      className="bg-transparent border-none outline-none w-full"
      iconClassName="text-base-700 w-4"
      value={imageName}
      savedValue="Save..."
      placeholder="Fill image name..."
      minLength={1}
      onBlur={() => {
        if (imageName.length === 0) {
          setImageName(name)
        }
      }}
      onChange={(e) => setImageName(e.target.value)}
      onSave={() => {
        if (imageName.length > 0) {
          mutate(
            { imageId: id, body: { name: imageName } },
            {
              onError: () => {
                setImageName(name)
              },
            }
          )
        }
      }}
      isLoading={isLoading}
    />
  )
}

export default EditableImageName
