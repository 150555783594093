import { UpdateAccountQuery } from '@phrasee/phrasee-typings/typings/integrations/accounts'

import { integrationKeys } from '../../api/queryKeys'

type StackOneAccountId = UpdateAccountQuery['stackOneAccountId']

export const integrationAssetsKeys = {
  listEmailTemplates: (stackOneAccountId: StackOneAccountId) =>
    [
      ...integrationKeys.all,
      'list-email-templates',
      stackOneAccountId,
    ] as const,
}
