import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'

import { IconType } from 'common/icons/Icon'

type Props = {
  title: string
  to: string
  Icon?: IconType
  isDisabled?: boolean
  className?: string
}

const SubNavItem = ({ title, Icon, to, isDisabled, className }: Props) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <NavLink
      data-cy={`subnav-link-${title}`}
      data-testid="subnav-link"
      exact
      to={to}
      onClick={(event) => {
        if (isDisabled) {
          event.preventDefault()
        }
      }}
      className={cx(
        'group hover:text-yellow-200 transition-colors duration-300',
        {
          'cursor-not-allowed': isDisabled,
          'text-yellow-200': isActive,
          'text-white': !isActive,
        },
        className
      )}
      isActive={(match) => {
        setIsActive(!!match)

        return !!match
      }}
    >
      {Icon && <Icon className={cx('mr-4')} isDefaultColor={false} />}
      {title}
    </NavLink>
  )
}

export default SubNavItem
