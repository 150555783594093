export interface Page {
  id: string
  name: string
}

export const MOCK_PAGES: Page[] = [
  { id: '1', name: 'CS Dashboard' },
  { id: '2', name: 'Manager Dashboard' },
  { id: '3', name: 'Black Friday Campaign' },
]
