import { useState } from 'react'
import { showBanner } from 'workflow/common/common.actions'
import apiUtil from 'workflow/utils/Api'

import Button from 'common/components/button/Button'
import Modal from 'common/components/Modal'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { removeSelectedFromCampaigns } from 'features/campaigns/store/campaignSlice'

interface Props {
  open: boolean
  close: () => void
}

const ConfirmationModal = ({ open, close }: Props) => {
  const selectedCampaigns = useAppSelector(
    (state) => state.campaigns.selectedCampaigns
  )
  const dispatch = useAppDispatch()

  const count = selectedCampaigns.length

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const deleteCampaigns = () => {
    setIsLoading(true)

    apiUtil('campaigns/delete', {
      method: 'POST',
      body: {
        campaign_id: selectedCampaigns,
      },
    })
      .then(() => {
        dispatch(
          showBanner({
            content: `${count === 1 ? 'Experiment' : 'Experiments'} deleted`,
            type: 'success',
          })
        )
        dispatch(removeSelectedFromCampaigns())
      })
      .catch((error) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
      })
      .finally(() => {
        setIsLoading(false)
        close()
      })
  }

  return (
    <Modal
      visible={open}
      closable={false}
      footer={null}
      data-cy="delete-campaigns-modal"
      data-testid="delete-campaigns-modal"
    >
      <div className="flex">
        <h1 className="mt-2 text-2xl font-bold text-red-600">
          Hold up, delete experiments?
        </h1>
      </div>

      <div className="mt-4 mb-14 text-lg text-coolGray-500">
        You have selected {count} {count === 1 ? 'experiment' : 'experiments'}{' '}
        to be deleted, are you sure you would like to do this?
      </div>

      <div className="flex justify-end">
        <Button
          className="mr-4"
          onClick={close}
          ghost
          data-cy="delete-campaigns-cancel"
          data-testid="delete-campaigns-cancel"
        >
          No! - Stop this madness
        </Button>

        <Button
          variant="danger"
          onClick={() => {
            deleteCampaigns()
          }}
          loading={isLoading}
          data-cy="delete-campaigns-confirm"
          data-testid="delete-campaigns-confirm"
        >
          Yep, delete them
        </Button>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
