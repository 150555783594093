import { useState } from 'react'

import Button from 'common/components/button'
import { TreeNode } from 'common/components/catalog/Catalog'
import Loader from 'common/components/loaders/Loader'
import WidgetHeader from 'common/components/WidgetHeader'
import Icon from 'common/icons/Icon'
import { ReactComponent as TableIcon } from 'common/icons/table/default.svg'
import { ReactComponent as TreeChartIcon } from 'common/icons/treeChart/default.svg'
import type { CustomerAttributes } from 'features/personalization/api'
import { PersonalizationInfo } from 'features/personalization/personalizationsPage/interfaces'

import useGetCategoryPerformanceQuery from './api/queries/useGetCategoryPerformanceQuery'
import CategoryPerformanceTable from './CategoryPerformanceTable'
import CategoryPerformanceTreeMap from './CategoryPerformanceTreeMap'

type Props = {
  currentPersonalization: PersonalizationInfo | undefined
  customerAttributes: CustomerAttributes | undefined
  productCategories: TreeNode | undefined
}

const CategoryPerformance = ({
  currentPersonalization,
  customerAttributes,
  productCategories,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState<'opens' | 'clicks'>('opens')
  const [viewMode, setViewMode] = useState<'treeMap' | 'table'>('treeMap')
  const { id = '', personalizationRegion } = currentPersonalization ?? {}
  const personalizationPerformanceQuery = useGetCategoryPerformanceQuery({
    personalizationId: id,
    metric: selectedTab,
    customerAttributes,
    productCategories,
    personalizationRegion,
  })
  if (personalizationPerformanceQuery.data?.data.length === 0) {
    return null
  }

  return (
    <div>
      <Loader isLoading={personalizationPerformanceQuery.isLoading}>
        <WidgetHeader
          title="Category Performance"
          subtitle="Total lines for this time period, plotted against product categories."
          actions={
            <div className="flex flex-row">
              <Button onClick={() => setViewMode('treeMap')}>
                <Icon
                  defaultSvg={TreeChartIcon}
                  state={viewMode === 'treeMap' ? 'selected' : 'default'}
                />
              </Button>
              <Button onClick={() => setViewMode('table')}>
                <Icon
                  defaultSvg={TableIcon}
                  state={viewMode === 'table' ? 'selected' : 'default'}
                />
              </Button>
            </div>
          }
        />
        {viewMode === 'treeMap' ? (
          <CategoryPerformanceTreeMap
            onTabClick={setSelectedTab}
            selectedTab={selectedTab}
            data={personalizationPerformanceQuery.data?.data}
            isLoading={personalizationPerformanceQuery.isLoading}
          />
        ) : (
          <CategoryPerformanceTable
            data={personalizationPerformanceQuery.data?.data ?? []}
          />
        )}
      </Loader>
    </div>
  )
}

export default CategoryPerformance
