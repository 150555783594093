import { useEffect, useState } from 'react'
import { Campaign as GraphQLResponseCampaigns } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { gql } from 'graphql-request'

import { fetchGraphQL } from 'common/api'
import TextLoader from 'common/components/loaders/TextLoader'
import { useAppSelector } from 'common/hooks/redux'
import { ResponseStatus } from 'common/interfaces/campaign'
import { WigdetApiResponseBase } from 'common/interfaces/widgetListInterfaces'
import Link from 'common/typography/Link'

export interface ResponseCampaign
  extends Pick<
    GraphQLResponseCampaigns,
    '_id' | 'name' | 'status' | 'user_id'
  > {
  id: string
  name: string
  status: ResponseStatus
  user_id: string
}

export interface CampaignsResponse {
  campaigns: ResponseCampaign[]
  count: number
}

interface Props {
  userId: string
  setWidget: (val: WigdetApiResponseBase | undefined) => void
}

const Todo = ({ userId, setWidget }: Props) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const [toDo, setTodo] = useState<ResponseCampaign[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)

  useEffect(() => {
    const fetchTodo = async () => {
      setHasError(false)
      setIsLoading(true)
      const fetchTodoQuery = function (
        page: number,
        pageSize: number,
        userId: string
      ): Promise<CampaignsResponse> {
        const query = gql`
          query GetTodo(
            $page: Int
            $pageSize: Int
            $sorting: CampaignSorting
            $filter: CampaignFilter
          ) {
            data: campaigns(
              page: $page
              pageSize: $pageSize
              sorting: $sorting
              filter: $filter
            ) {
              campaigns {
                id: _id
                name
                status
                user_id
              }
            }
          }
        `

        const variables = {
          page,
          pageSize,
          sorting: {
            field: 'iso_send_date',
            direction: 'ASC',
          },
          filter: {
            userId,
            accountId,
          },
        }

        return fetchGraphQL<CampaignsResponse>({ query, variables })
      }

      fetchTodoQuery(0, 5, userId)
        .then(({ campaigns }: CampaignsResponse) => {
          if (campaigns.length) {
            setTodo(campaigns)
          } else {
            setWidget(undefined)
          }
        })
        .catch(() => {
          setHasError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    fetchTodo()
  }, [setTodo, setWidget, userId, accountId])

  const mapStatusToAction = (item: ResponseCampaign) => {
    const statusMap = {
      not_approved: 'Approve',
      not_completed: 'Finish it now',
      missing_results: 'Upload results',
      completed: 'View report',
    }

    return {
      id: item.id,
      name: item.name,
      status: item.status,
      progress: statusMap[item.status],
    }
  }

  const mapActionToDisplayCopy = (item: {
    id: string
    name: string
    status: ResponseStatus
    progress: string
  }) => {
    const progressMap = {
      not_approved: `${item.name}... is awaiting your approval.`,
      not_completed: `${item.name}... is not complete yet.`,
      missing_results: `${item.name}...  is missing results.`,
      completed: `A report is now ready for ${item.name} - jump on in.`,
    }

    return {
      id: item.id,
      progress: item.progress,
      copy: progressMap[item.status],
    }
  }

  return (
    <div className="mb-6 mx-6 lg:ml-0 mt-12 w-70 text-coolGray-800">
      <h2
        className="mb-6 text-xl font-medium"
        data-cy="todo-widget-title"
        data-testid="todo-widget-title"
      >
        To-do
      </h2>
      {hasError && <div>Loading campaigns failed</div>}
      {isLoading ? (
        <div data-testid="loader" data-cy="todo-loader">
          <TextLoader />
          <TextLoader />
          <TextLoader />
          <TextLoader />
        </div>
      ) : (
        <ul>
          {toDo
            .map(mapStatusToAction)
            .map(mapActionToDisplayCopy)
            .map(
              (
                {
                  copy,
                  progress,
                  id,
                }: {
                  copy: string
                  progress: string
                  id: string
                },
                index
              ) => (
                <li
                  key={id}
                  className="mb-6 break-words"
                  data-cy={`todo-widget-link${index + 1}`}
                  data-testid={`todo-widget-link${index + 1}`}
                >
                  {`${copy} `}
                  <Link
                    to={`/campaigns/${id}`}
                    className="text-maroon-200 inline"
                  >
                    {progress}
                  </Link>
                </li>
              )
            )}
        </ul>
      )}
    </div>
  )
}

export default Todo
