import Widget from 'common/components/Widget'
import { formatNumber } from 'common/helpers/numeric'
import { LineChart } from 'common/icons'

export function formatPercentage(value: number | string) {
  if (typeof value === 'string' && value.includes('%')) {
    const numberValue = parseFloat(value)
    return `${numberValue.toFixed(2)}%`
  }
  return value
}

const Graph = ({ label, value }: { label: string; value: number }) => {
  return (
    <Widget className="w-full grid h-full m-auto" type="basic">
      <div className="relative flex flex-col gap-2">
        <div className="text-coolGray-400 text-sm font-medium">{label}</div>
        <div className="text-gray-800 text-xl font-bold mt-auto">
          {typeof value === 'number'
            ? formatNumber(value)
            : formatPercentage(value)}
        </div>
      </div>
      <div className="absolute w-20 lg:w-30 right-12 bottom-7">
        <LineChart
          isDefaultColor={false}
          className="text-green-400 h-auto w-full"
        />
      </div>
    </Widget>
  )
}
export default Graph
