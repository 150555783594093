import { ChangeEvent } from 'react'

import Input from 'common/components/input'

const InputForm = ({
  label,
  width,
  value,
  onChange,
  isDisabled = false,
}: {
  label: string
  width: number
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  isDisabled?: boolean
}) => (
  <label className="text-coolGray-800 font-medium">
    {label}
    <Input
      onChange={onChange}
      value={value}
      type="text"
      disabled={isDisabled}
      className="bg-coolGray-50 h-10 border border-coolGray-300 pl-4 focus:outline-none font-medium disabled:bg-coolGray-100"
      placeholder="Give it a great name ;-)"
      style={{ width }}
    />
  </label>
)

export default InputForm
