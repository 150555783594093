import {
  Connector,
  ConnectSessionsRequest,
  ConnectSessionsResponse,
  IntegrationAccount,
} from '@phrasee/phrasee-typings'

import { connectInternalApi } from 'common/api'
import type { IntegrationProvider } from 'common/api/integrationsApi'

import { mapMergeTag } from './helpers'

export const getAvailableIntegrations = async (): Promise<
  IntegrationProvider[]
> => {
  const result = await connectInternalApi.get<Connector[]>(
    'v1/content-integrations/connectors'
  )

  return result.data.map((integration) => ({
    id: integration.provider,
    name: integration.provider_name,
    category: integration.category,
    logoUrl: integration.resources?.images?.logo_url,
  }))
}

export const getReactHubToken = async ({
  provider,
  accountId,
  accountName,
  userName,
  label,
}: ConnectSessionsRequest): Promise<string> => {
  const result = await connectInternalApi.post<ConnectSessionsResponse>(
    'v1/content-integrations/connect_sessions',
    {
      provider,
      accountId,
      accountName,
      userName,
      label,
    }
  )

  return result.data.token
}

export const updateIntegration = async ({
  id,
  label,
}: Pick<IntegrationAccount, 'id' | 'label'>) => {
  const result = await connectInternalApi.patch<ConnectSessionsResponse>(
    `v1/content-integrations/accounts/${id}`,
    { label }
  )

  return result.data.token
}

export const deleteIntegration = async ({
  id,
}: Pick<IntegrationAccount, 'id'>) => {
  const result = await connectInternalApi.delete<IntegrationAccount>(
    `v1/content-integrations/accounts/${id}`
  )

  return result.data
}

export type MergeTagsBEResponse = {
  _id: string
  name: string
  description: string
  integration: string
  category: string
  last_updated: string
  code: string
  example_values?: string[]
  created?: string
}

export type MergeTagListItem = {
  id: string
  name: string
  description: string
  integration: string
  category: string
  code: string
  lastModified: string
  exampleValues?: string[]
}

export const getMergeTags = async ({
  accountId,
}: {
  accountId: string
}): Promise<MergeTagListItem[]> => {
  const result = await connectInternalApi.get<MergeTagsBEResponse[]>(
    `v1/core/monorail/accounts/${accountId}/merge-tags`
  )

  return result.data.map(mapMergeTag)
}

export const getSystemMergeTags = async (): Promise<MergeTagListItem[]> => {
  const result = await connectInternalApi.get<MergeTagsBEResponse[]>(
    `v1/core/monorail/merge-tag-templates`
  )

  return result.data.map(mapMergeTag)
}

export const createMergeTag = async ({
  accountId,
  name,
  description,
  category,
  code,
  example_values,
  integration,
}: Omit<MergeTagsBEResponse, '_id' | 'last_updated'> & {
  accountId: string
}) => {
  await connectInternalApi.post(
    `v1/core/monorail/accounts/${accountId}/merge-tags`,
    {
      name,
      description,
      category,
      code,
      example_values,
      integration,
    }
  )
}

export const deleteMergeTag = async ({
  tagId,
  accountId,
}: {
  tagId: string
  accountId: string
}) => {
  await connectInternalApi.delete(
    `v1/core/monorail/accounts/${accountId}/merge-tags/${tagId}`
  )
}
