import { useLayoutEffect } from 'react'

import { useAppDispatch } from 'common/hooks/redux'
import { isTemplateElement } from 'features/unifiedFlow/api/interfaces'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import {
  getAllComponentElements,
  getElementHasVariants,
  hasDisabledAllElements,
} from '../../helpers'
import { useContent } from '../../hooks'

const useElementState = () => {
  const dispatch = useAppDispatch()

  const { content } = useContent()

  useLayoutEffect(() => {
    let selectedElementId: number | undefined = undefined
    const allComponentElements = getAllComponentElements(content)
    const templateElements = content?.elements.filter(
      (element) =>
        isTemplateElement(element) && hasDisabledAllElements(element.elements)
    )

    allComponentElements.some((element) => {
      if (getElementHasVariants(element)) {
        selectedElementId = element.element_id
        return true
      }
      return false
    })

    templateElements?.some((element) => {
      if (element.status === 'selected' || element.status === 'active') {
        selectedElementId = element.element_id
        return true
      } else {
        return false
      }
    })

    if (selectedElementId) {
      dispatch(selectElement({ elementId: selectedElementId }))
    }
    // This hook should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useElementState
