import Checkbox from 'common/components/checkbox'
import Slider from 'common/components/slider/Slider'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import {
  addPossibleConjunction,
  clickListReplacers,
  clickOxfordComma,
  removePossibleConjunction,
  updateListReplacerRange,
} from '../store/nodeSlice'

import NodeTags from './NodeTags'

const ReplacerSetttingsWidget = () => {
  const dispatch = useAppDispatch()
  const editedProjectNodes = useAppSelector(
    (state) => state.projectNodes.editedData.replacerSettings
  )

  const possibleConjunctions = editedProjectNodes.possibleConjunctions

  return (
    <Widget
      data-cy="replacer-settings-widget"
      data-testid="replacer-settings-widget"
      className="mt-8"
    >
      <WidgetHeader title="Replacer settings" />
      <Checkbox
        data-cy="list-replacer"
        data-testid="list-replacer"
        isChecked={editedProjectNodes.isUsingListReplacers}
        onChange={(isChecked) => {
          dispatch(clickListReplacers(isChecked))
        }}
        label="List replacers"
      />
      <div className="ml-10">
        <div className="text-coolGray-400 mb-2">
          Set the minimum and maximum number of items that should generate
          through a list replacer
        </div>
        <div className="w-72 mt-5">
          <Slider
            disabled={!editedProjectNodes.isUsingListReplacers}
            min={0}
            max={10}
            onChange={(value) => {
              dispatch(updateListReplacerRange(value as number[]))
            }}
            defaultValue={editedProjectNodes.range}
            range
          />
        </div>
      </div>
      <div className="mt-6">
        <NodeTags
          data-cy="replacer-settings-conjunctions"
          data-testid="replacer-settings-conjunctions"
          title="Possible conjunctions"
          subTitle="Add the conjunctions that can be used when generating lists."
          onAddClick={(value: string) => {
            dispatch(addPossibleConjunction(value))
          }}
          onRemoveClick={(value: string) => {
            dispatch(removePossibleConjunction(value))
          }}
          tags={possibleConjunctions.map((word) => ({
            label: word,
            value: word,
            isDisabled: possibleConjunctions.length === 1,
          }))}
          suggestions={['and', '&', '+']}
          tagButtonLabel="Conjunction"
        />
      </div>

      <Checkbox
        data-cy="oxford-comma"
        data-testid="oxford-comma"
        className="mt-6"
        isChecked={editedProjectNodes.isUsingOxfordComma}
        onChange={(isChecked) => {
          dispatch(clickOxfordComma(isChecked))
        }}
        label="Use Oxford comma ( apples, pears, and... )"
      />
    </Widget>
  )
}

export default ReplacerSetttingsWidget
