import { ChangeEvent } from 'react'
import { TableToggleCommonProps } from 'react-table'

import type { CheckboxProps } from '../checkbox'
import Checkbox from '../checkbox'

type Props = TableToggleCommonProps &
  Pick<CheckboxProps, 'labelColor' | 'backgroundColor' | 'colorChecked'>

const TableCheckbox = ({ checked, onChange, ...rest }: Props) => {
  const customOnChange = (_, event: ChangeEvent) => {
    if (onChange) {
      onChange(event)
    }
  }
  return (
    <Checkbox
      isChecked={checked ?? false}
      onChange={customOnChange}
      {...rest}
    />
  )
}

export default TableCheckbox
