import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import cx from 'classnames'

import Loader from 'common/components/loaders/Loader'
import { Upload } from 'common/icons'

type Props = {
  onUploadCsv: (file: File) => Promise<void>
}

const MAX_FILE_SIZE_BYTES = 5242880

const CsvUploader = ({ onUploadCsv }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [uploadingFileName, setUploadingFileName] = useState('')

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const csvFile = acceptedFiles[0]
        setUploadingFileName(csvFile.name)
        setIsLoading(true)
        onUploadCsv(csvFile).finally(() => setIsLoading(false))
      }
    },
    [onUploadCsv]
  )
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.csv'], // for Windows
    },
    multiple: false,
    maxSize: MAX_FILE_SIZE_BYTES,
    onDrop,
  })

  const componentPerState = {
    default: (
      <div className="flex flex-col gap-6 items-center justify-center h-full">
        <Upload isDefaultColor={false} size={18} />
        <p className=" font-medium text-center" style={{ width: 188 }}>
          Drag and drop your .CSV here. or{' '}
          <span className="underline text-coolGray-500">browse files</span>.
        </p>
      </div>
    ),
    dragActive: (
      <div
        className={cx(
          'border border-dashed flex-1 m-6 flex flex-col gap-6 items-center justify-center',
          { 'border-coolGray-300': isDragAccept },
          { 'border-red-400 text-red-400': isDragReject }
        )}
      >
        <Upload isDefaultColor={false} size={18} />
        <span>
          {isDragAccept ? 'Go on… drop it!' : 'Nope, that’s not a .CSV file'}
        </span>
      </div>
    ),
    loading: (
      <div className="flex flex-col items-center justify-center h-full ">
        <div className="mb-8">
          <Loader />
        </div>
        <div>Processing file {uploadingFileName}…</div>
      </div>
    ),
  } as const

  return (
    <div
      style={{
        height: 436,
      }}
      className="border-t-1 border-coolGray-100 bg-coolGray-50 flex flex-col items-stretch place-content-stretch"
    >
      <div
        className={cx(
          'text-coolGray-400 font-medium flex flex-col items-stretch justify-items-stretch',
          {
            'cursor-pointer': !isLoading,
            'cursor-not-allowed': isLoading,
          }
        )}
        {...getRootProps({})}
      >
        <input {...getInputProps()} />
        {
          componentPerState[
            isLoading ? 'loading' : isDragActive ? 'dragActive' : 'default'
          ]
        }
      </div>
    </div>
  )
}

export default CsvUploader
