import BottomToolbar from 'common/bottomToolbar'
import Button from 'common/components/button'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ArrowRight as ArrowRightIcon } from 'common/icons'
import useUpdateElementsMutation from 'features/futurama/api/mutations/useUpdateElementsMutation'
import {
  getElementHasVariants,
  getSelectedElement,
} from 'features/futurama/contentPage/helpers'
import { useContent } from 'features/futurama/contentPage/hooks'
import {
  cancelTemplatesAdd,
  MAX_TEMPLATES_TO_ADD,
  postAddTemplate,
  resetTemplatesToAdd,
} from 'features/futurama/store/contentSlice'

const TemplatesToAddBar = () => {
  const dispatch = useAppDispatch()
  const updateElementsMutation = useUpdateElementsMutation()

  const { content } = useContent()
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const activeContentId = content?._id

  const templatesToAdd = useAppSelector((state) => state.content.templatesToAdd)
  const templatesToAddCount = templatesToAdd.reduce(
    (acc, template) => acc + template.count,
    0
  )

  const handleAddTemplates = () => {
    if (activeContentId) {
      updateElementsMutation.mutate(
        {
          accountId,
          contentId: activeContentId,
          templates: templatesToAdd,
        },
        {
          onSuccess: (updatedContent) => {
            const selectedElement = getSelectedElement(updatedContent)
            dispatch(
              postAddTemplate({
                selectedElementId: selectedElement?.element_id,
                showComponentConfiguration:
                  !selectedElement?.user_input_form_compatible ||
                  !getElementHasVariants(selectedElement),
              })
            )
          },
        }
      )
    }
  }

  const alreadyAddedTemplatesCount = content?.elements.length
    ? content.elements.length - 1
    : 0
  const selectedText = `${templatesToAddCount} of ${
    MAX_TEMPLATES_TO_ADD - alreadyAddedTemplatesCount
  } selected`
  const selectedTextHelper = alreadyAddedTemplatesCount
    ? `You have ${alreadyAddedTemplatesCount} templates in your workflow already.`
    : `Select up to ${MAX_TEMPLATES_TO_ADD} templates and let us generate the magic.`
  const isCtaDisabled = templatesToAddCount === 0

  return (
    <>
      {templatesToAdd.length > 0 || alreadyAddedTemplatesCount > 0 ? (
        <BottomToolbar className="py-6 items-center justify-between">
          <div>
            <div className="text-coolGray-800 font-medium text-xl mb-1">
              {selectedText}
            </div>
            <div className="text-coolGray-500">{selectedTextHelper}</div>
          </div>
          <div className="flex gap-4">
            <Button
              ghost
              data-cy="add-templates-cancel-button"
              data-testid="add-templates-cancel-button"
              onClick={() => dispatch(cancelTemplatesAdd())}
            >
              Cancel
            </Button>
            <Button
              ghost
              data-cy="add-templates-reset-button"
              data-testid="add-templates-reset-button"
              disabled={isCtaDisabled}
              onClick={() => dispatch(resetTemplatesToAdd())}
            >
              Reset
            </Button>
            <Button
              data-cy="add-templates-button"
              data-testid="add-templates-button"
              disabled={isCtaDisabled}
              variant="primary"
              suffixIcon={<ArrowRightIcon isDefaultColor={false} size={4} />}
              onClick={handleAddTemplates}
            >
              Add
            </Button>
          </div>
        </BottomToolbar>
      ) : null}
    </>
  )
}

export default TemplatesToAddBar
