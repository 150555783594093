import { Children, cloneElement, isValidElement, ReactNode } from 'react'
import cx from 'classnames'

import FormError from 'common/components/formError'
import GuidingText from 'common/components/guidingText'
import Label from 'common/components/label'

export interface Props {
  label?: ReactNode
  htmlFor?: string
  isOptional?: boolean
  error?: string
  guidingText?: string
  className?: string
  style?: React.CSSProperties
  orientation?: 'vertical' | 'horizontal'
  children?: React.ReactNode
  'data-testid'?: string
}

const FormItem = ({
  label,
  htmlFor,
  isOptional,
  error,
  guidingText,
  className,
  style,
  orientation = 'vertical',
  children,
  'data-testid': dataTestId,
}: Props) => {
  const isDisabled = Children.toArray(children).some(
    (child) => isValidElement(child) && child.props.disabled
  )

  return (
    <div
      className={cx(
        'flex-1',
        {
          'last:mb-0 ': orientation === 'vertical',
          'mb-4': !style && orientation === 'vertical',
        },
        className
      )}
      style={style}
      data-testid={dataTestId}
    >
      {label && (
        <Label
          optional={isOptional}
          htmlFor={htmlFor}
          status={error === undefined || error === '' ? 'default' : 'error'}
          disabled={isDisabled}
        >
          {label}
        </Label>
      )}

      {Children.map(children, (child) =>
        isValidElement(child)
          ? cloneElement(child, { isError: !!error } as { isError: boolean })
          : child
      )}

      {error && <FormError className="mt-2">{error}</FormError>}
      {guidingText && !error && (
        <GuidingText
          text={guidingText}
          className="mt-2"
          disabled={isDisabled}
        />
      )}
    </div>
  )
}

export default FormItem
