import { Field } from 'react-final-form'

import Toggle from 'common/components/toggle'

import { TemplateInfoWithInternalId } from '../addedTemplates/AddedComponentList'
import { getPrefixedName } from '../helpers'

const IncludeImagesToggle = ({
  template,
}: {
  template?: TemplateInfoWithInternalId
}) => {
  return (
    <div className="flex flex-col rounded-sm bg-gold-40 gap-4 p-4 mt-6">
      <div className="font-sm uppercase text-base-700 font-medium">Images</div>
      <Field<boolean>
        name={getPrefixedName('imageOptimization', template)}
        type="checkbox"
        initialValue={false}
      >
        {({ input }) => (
          <Toggle
            name={input.name}
            label="Include images"
            checked={Boolean(input.checked)}
            onChange={input.onChange}
          />
        )}
      </Field>
      <p className="text-xs text-base-700">
        By including images in your experiment, you will be limited to
        generating a maximum of 5 language variants instead of 10.
        <br />
        <br />
        This is so that all of your images can be tested with all of your
        language variants, resulting in 15 total combinations.
      </p>
    </div>
  )
}

export default IncludeImagesToggle
