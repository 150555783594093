/* eslint-disable max-lines */

import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import * as types from '../redux/actionTypes'

import helpers from './utils/helpers'
import {
  CampaignNavigationSteps,
  CampaignWorkflowState,
  SubjectLine,
} from './interface'

export const initialStepsConfig = [
  {
    step: CampaignNavigationSteps.setup,
    name: 'Setup',
    finished: false,
    active: true,
    disabled: false,
  },
  {
    step: CampaignNavigationSteps.language_generation,
    name: 'Language Generation',
    finished: false,
    active: false,
    disabled: true,
  },
  {
    step: CampaignNavigationSteps.language_approval,
    name: 'Language Approval',
    finished: false,
    active: false,
    disabled: true,
  },
  {
    step: CampaignNavigationSteps.summary,
    name: 'Results',
    finished: false,
    active: false,
    disabled: true,
  },
]

const initialStatusSteps = [
  {
    title: 'Schedule',
    subTitle: null,
    description: null,
    status: 'wait',
    icon: null,
    key: 0,
  },
  {
    title: 'Split Results',
    subTitle: null,
    description: null,
    status: 'wait',
    icon: null,
    key: 1,
  },
  {
    title: 'Final Results',
    subTitle: null,
    description: null,
    status: 'wait',
    icon: null,
    key: 2,
  },
]

export const initialWorkflowState: CampaignWorkflowState = {
  isLoading: true,
  isLoadingCampaignDetails: false,
  resultsDetailsIsLoading: false,
  isScheduleButtonDisabled: true,
  distributionChannels: [],
  projecstList: [],
  combinedLanguageSettings: {
    isoLanguage: 'en_uk',
    isoCurrency: 'GBP',
  },
  subjectLines: [],
  campaignConfiguration: {},
  rejectLineLoading: false,
  tweakingLoading: false,
  showTweakLine: false,
  lineBeingTweaked: {},
  languageApprovalLoading: false,
  campaignName: '',
  distributionChannel: undefined,
  projectName: undefined,
  projectId: '',
  nfmUsed: 0,
  tweakUsed: 0,
  newlinesInVariants: {},
  currentCampaignId: '',
  typeOfIntegration: '',
  error: null,
  midListError: null,
  engageSfmcError: null,
  scheduleCheetahError: null,
  isUserInput: false,
  chosenStep: CampaignNavigationSteps.setup,
  isSaveContinue: null,
  shouldStopPolling: false,
  isWaitingState: {
    isWaitingFor: null,
    isWaiting: false,
  },
  results: {
    variants: null,
    split_campaign_results: null,
    final_campaign_results: null,
    machine_summary: null,
    reports: null,
  },
  filteredTableVariants: [],
  csv: null,
  warnings: null,
  shouldUpdateCampaignData: false,
  campaignValidationRules: null,
  fetchingMovableInkApiKeys: false,
  movableInkApiKeys: {},
  fetchingMovableInkApiKeysError: false,
  clearingMovableInkTestData: false,
  updatingMovableInkIntegrationData: false,
  isInIframe: false,
  scheduleResponse: null,
  sailthruIntegration: {
    splitCampaign: null,
    finalCampaign: null,
    hoursBeforeFinalDateTime: null,
    scheduleTime: null,
    scheduleDate: null,
    listCampaigns: [],
    summary: null,
  },
  cheetahCampaigns: [],
  emarsysCampaigns: [],
  cheetahTimeZone: '',
  timeZone: '',
  campaignData: {
    campaign_progress: {},
    steps: initialStepsConfig,
  },
  isSendDefinitionLoading: false,
  cancelResponse: {},
  summary: {
    isScheduled: null,
    finalCampaignResult: null,
    splitCampaignResult: null,
    hoursBeforeFinal: null,
  },
  emailList: [],
  mailingExists: false,
  splitsSent: false,
  folderName: null,
  modalVisible: false,
  ampScript: '',
  loadingAmpScript: false,
  daysToGoDate: null,
  values: [],
  conditionalHiddenStatus: null,
  conditionalOpenParents: null,
  validationErrors: {},
  isQuestionLoaded: false,
  stepTwoSubmitLoading: false,
  completed: false,
  expanded: false,
  loading: false,
  slGeneratingStatus: false,
  slAnimationProgress: null,
  animationImage: null,
  animationText: null,
  percentage: null,
  regenerateModalVisible: false,
  nerValidationModalVisible: false,
  nerValidationErrorMessage: null,
  questions: [],
  globalConditionalFields: null,
  currencyPattern: null,
  steps: initialStatusSteps,
  grammar: null,
  advancedVisualisationsLoading: false,
  advancedVisualisationsData: {},
  advancedVisualisationsError: false,
  secondaryValues: {},
  draftMessages: [],
  updatedMappMessage: null,
  shouldCloseMappDrawer: false,
  brazeCampaigns: [],
  brazeResponse: null,
  maxVariantOpenRate: 100,
  shouldDisplayBoomModal: false,
  midList: [],
  epsilonBusinessUnits: undefined,
  epsilonSeedList: undefined,
  epsilonFolders: undefined,
  epsilonTimeZone: undefined,
  epsilonCampaigns: undefined,
  epsilonSequences: undefined,
  sfmcFormSelectedItems: [],
  variantsToBeApprovedExperimentsFlow: [],
  variantsToBeApproved: [],
  teamsList: [],
  isDetailsPanelExpanded: false,
  dynamicOptimizationResultType: undefined,
}

export default function WorkflowCampaignReducer(
  state: CampaignWorkflowState = initialWorkflowState,
  action: any
): CampaignWorkflowState {
  switch (action.type) {
    case types.UPDATE_RESULTS_TABLE:
      return {
        ...state,
        results: {
          ...state.results,
          split_campaign_results: {
            ...state.results.split_campaign_results,
            values: action.newResult,
          },
        },
      }
    case types.SET_FILTERED_SUMMARY_TABLE_VARIANTS:
      return {
        ...state,
        filteredTableVariants: action.filteredVariants,
      }
    case types.SET_DISTRIBUTION_CHANNEL:
      return {
        ...state,
        distributionChannel: action.distributionChannel,
      }
    case types.SET_PROJECT_ID:
      return {
        ...state,
        projectId: action.id,
      }
    case types.IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case types.RESULTS_DETAILS_IS_LOADING:
      return {
        ...state,
        resultsDetailsIsLoading: action.isLoading,
        results: {
          variants: null,
          split_campaign_results: null,
          final_campaign_results: null,
          machine_summary: null,
          reports: null,
        },
      }
    case types.IS_WAITING:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    case types.START_NEW_CAMPAIGN:
      return {
        ...state,
        ...initialWorkflowState,
        isDetailsPanelExpanded: state.isDetailsPanelExpanded,
      }
    // TAKEN FROM COMMON REDUCER
    case types.STEP_TWO_SET_DAYS_TO_GO:
      return {
        ...state,
        daysToGoDate: action.daysToGoDate,
      }
    case types.STEP_TWO_SET_NEW_VALUES:
      return cloneDeep({
        ...state,
        values: action.values,
        conditionalHiddenStatus: action.conditionalHiddenStatus,
        conditionalOpenParents: action.conditionalOpenParents,
        validationErrors: action.validationErrors,
        isQuestionLoaded: false,
        secondaryValues: {
          ...state.secondaryValues,
          ...action.secondaryValues,
        },
      })
    case types.STEP_TWO_QUESTIONS_SUCCESS: {
      const conditionalHiddenStatus: any = {}
      const globalConditionalFields: any = {}
      const conditionalOpenParents: any = {}
      const { questions } = action
      questions.forEach((question) => {
        const conditionalFields = question.conditional_fields
        if (
          typeof conditionalFields !== 'undefined' &&
          Object.keys(conditionalFields).length > 0
        ) {
          globalConditionalFields[question.name] = conditionalFields
          // keep all dependent fields' names in an object and hide them by default
          Object.keys(conditionalFields).forEach((value) => {
            // loop through all fields for a given value
            conditionalFields[value].forEach((questionName: string) => {
              conditionalHiddenStatus[questionName] = true
            })
          })
        }
      })
      const question_state: any = { ...state }
      question_state.grammar = action.grammar
      question_state.currencyPattern = action.currencyPattern
      question_state.questions = action.questions
      question_state.loading = action.loading
      question_state.globalConditionalFields = globalConditionalFields
      question_state.conditionalHiddenStatus = conditionalHiddenStatus
      question_state.conditionalOpenParents = conditionalOpenParents
      question_state.isQuestionLoaded = true
      question_state.expanded = true
      return {
        ...question_state,
      }
    }
    case types.STEP_TWO_UPDATE_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: action.validationErrors,
      }
    case types.STEP_TWO_SUBMITTING:
      return {
        ...state,
        stepTwoSubmitLoading: action.loading,
      }
    case types.STEP_TWO_SUBMIT_SUCCESS:
      return {
        ...state,
        completed: true,
        expanded: false,
        loading: action.loading,
        slGeneratingStatus: false,
        stepTwoSubmitLoading: false,
      }
    case types.STEP_TWO_SUBMIT_ERROR:
      return {
        ...state,
        error: action.message,
        stepTwoSubmitLoading: action.loading,
      }
    case types.STEP_TWO_SUCCESS_ANIMATION:
      return {
        ...state,
        slGeneratingStatus: action.slGeneratingStatus,
      }
    case types.STEP_TWO_UPDATE_GENERATION_ANIMATION_STEP:
      return {
        ...state,
        slAnimationProgress: action.slAnimationProgress,
      }
    case types.STEP_TWO_SET_ANIMATION_IMAGE:
      return {
        ...state,
        animationImage: action.animationImage,
        animationText: action.animationText,
      }
    case types.STEP_TWO_SET_ANIMATION_PERCENTAGE:
      return {
        ...state,
        percentage: action.percentage,
      }
    case types.STEP_TWO_UPDATE_REGENERATE_MODAL:
      return {
        ...state,
        regenerateModalVisible: action.regenerateModalVisible,
      }
    case types.STEP_TWO_UPDATE_NER_VALIDATION_MODAL:
      return {
        ...state,
        nerValidationModalVisible: action.visible,
        nerValidationErrorMessage: action.message,
        stepTwoSubmitLoading: false,
      }
    case types.STEP_EDITING:
      if (action.editing_step !== 2) {
        return {
          ...state,
          expanded: false,
        }
      }
      return {
        ...state,
        expanded: true,
        isQuestionLoaded: true,
      }
    case types.STEP_EDITING_DONE:
      if (action.currentStep === 2) {
        return {
          ...state,
          loading: false,
          expanded: true,
        }
      }
      return {
        ...state,
      }
    case types.CAMPAIGN_LOADING_SUCCESS_STEP_TWO: {
      const questions = action.campaign_data.grammar_questions || [
        ...state.questions,
      ]
      const conditionalHiddenStatus: any = {}
      const globalConditionalFields: any = {}
      const conditionalOpenParents: any = {}
      questions.forEach((question: any) => {
        const conditionalFields = question.conditional_fields
        if (
          typeof conditionalFields !== 'undefined' &&
          Object.keys(conditionalFields).length > 0
        ) {
          globalConditionalFields[question.name] = conditionalFields
          // keep all dependent fields' names in an object and hide them by default
          Object.keys(conditionalFields).forEach((value) => {
            // loop through all fields for a given value
            conditionalFields[value].forEach((questionName: string) => {
              conditionalHiddenStatus[questionName] = !(
                question.type === 'yes_no' && value === '0'
              )
            })
          })
        }
      })
      const values: any = {}
      if (action?.campaign_data?.questions) {
        Object.keys(action.campaign_data.questions).forEach((key) => {
          values[key] = action.campaign_data.questions[key].value
        })
      }
      const sendDate = action?.campaign_data?.questions?.SEND_DATE
      let daysToGo: any = moment()
      if (sendDate && sendDate.value) {
        daysToGo = moment(sendDate.value.split('-').reverse().join('-')).format(
          'YYYY-MM-DD hh:mm:ss'
        )
      }
      return {
        ...state,
        completed: !!action.campaign_data.step2,
        expanded: !!(!action.campaign_data.step2 && action.campaign_data.step1),
        // step1 is true and step2 is false expand is true
        questions,
        values,
        secondaryValues: values,
        globalConditionalFields,
        conditionalHiddenStatus,
        conditionalOpenParents,
        daysToGoDate: daysToGo,
        currencyPattern: action.campaign_data.currency_pattern || '',
      }
    }
    // END COMMON REDUCER
    case types.FETCH_DISTRIBUTION_CHANNEL_LOADED:
      return {
        ...state,
        distributionChannels: action.result,
        isLoading: action.isLoading,
      }
    case types.FETCH_DISTRIBUTION_CHANNEL_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case types.FETCH_PROJECT_LIST_LOADING:
      return {
        ...state,
        isLoading: action.isloading,
      }
    case types.FETCH_PROJECT_LIST_LOADED:
      return {
        ...state,
        projecstList: action.result,
      }
    case types.LANGUAGE_APPROVAL_CAMPAIGN_LOADING_SUCCESS:
      return {
        ...state,
        subjectLines: action.campaign_data.sls || [],
        campaignConfiguration:
          action.campaign_data.campaign_configuration || {},
        languageApprovalLoading: action.languageApprovalLoading,
        campaignName: action.campaign_data.name,
        projectId: action.campaign_data.project_id,
        nfmUsed: action.campaign_data.nfm_used || 0,
        tweakUsed: action.campaign_data.tweak_used || 0,
      }
    case types.LANGUAGE_APPROVAL_TWEAK_LINE_SUCCESS: {
      let tweakedSubjectLines: any[] = []
      const subjectLines = cloneDeep(state.subjectLines)

      tweakedSubjectLines = subjectLines.map((subjectline) => {
        const sl = subjectline
        if (sl.api_id === action.tweakedData.old_sl_id) {
          sl.semantics = action.modifiedData.semantics
          sl.text = action.tweakedData.text
          sl.text_before_tweak = action.tweakedData.text_before_tweak
          sl.api_id = action.modifiedData.id
          sl.sort_order = action.tweakedData.sort_order
        }
        return sl
      })
      return {
        ...state,
        subjectLines: tweakedSubjectLines,
        showTweakLine: action.showTweakLine,
        lineBeingTweaked: action.lineBeingTweaked,
        tweakingLoading: action.tweakingLoading,
      }
    }
    case types.TWEAK_VARIANT_SUCCESS: {
      const subjectLines = cloneDeep(state.subjectLines)

      const index = subjectLines.findIndex(
        (subjectline) => subjectline._id === action.oldVariantId
      )

      let updatedSubjectlines = subjectLines

      if (index !== -1) {
        updatedSubjectlines = [
          ...subjectLines.slice(0, index),
          action.newVariant,
          ...subjectLines.slice(index + 1),
        ]
      }

      return {
        ...state,
        subjectLines: updatedSubjectlines,
        ...(helpers.shouldEnableVariantApprovals(state.campaignData)
          ? {
              variantsToBeApproved: [
                ...state.variantsToBeApproved.filter(
                  (id) => id !== action.oldVariantId
                ),
                action.newVariant._id,
              ],
            }
          : {}),
      }
    }
    case types.LANGUAGE_APPROVAL_TWEAK_LINE_SUCCESS_FEEDBACK_ONLY:
      const clonedSubjectLines = cloneDeep(state.subjectLines)
      return {
        ...state,
        tweakingLoading: action.tweakingLoading,
        showTweakLine: action.showTweakLine,
        subjectLines: clonedSubjectLines.map((line) => {
          const tweakedLine = line
          if (tweakedLine._id === action.tweakedLineData.id) {
            tweakedLine.why_tweak = action.tweakedLineData.why_tweak
          }
          return tweakedLine
        }),
      }
    case types.REJECT_VARIANT_SUCCESS: {
      const subjectLines = cloneDeep(state.subjectLines)

      const index = subjectLines.findIndex(
        (subjectline) => subjectline._id === action.oldVariantId
      )

      let updatedSubjectlines = subjectLines

      if (index !== -1) {
        updatedSubjectlines = [
          ...subjectLines.slice(0, index),
          action.newVariant,
          ...subjectLines.slice(index + 1),
        ]
      }

      return {
        ...state,
        subjectLines: updatedSubjectlines,
        // For linguo we dont want a subjectline which is just replaced to be considered as a variant to be approved
        ...(helpers.shouldEnableVariantApprovals(state.campaignData)
          ? {
              variantsToBeApproved: [
                ...state.variantsToBeApproved.filter(
                  (id) => id !== action.oldVariantId
                ),
                action.newVariant._id,
              ],
            }
          : {}),
      }
    }
    case types.LANGUAGE_APPROVAL_REJECT_LINE_SUCCESS: {
      const subjectLines = cloneDeep(state.subjectLines)
      const updatedSubjectlines: SubjectLine[] = subjectLines.map(
        (subjectline) => {
          if (subjectline._id === action.rejectedData.line_id) {
            subjectline.bandit_status = action?.newData?.bandit_status
            subjectline.semantics = action.newData.semantics
            subjectline._id = action.newData.id
            // update api_id because that is used to identify variant when rejecting
            subjectline.api_id = action.newData.id
            subjectline.text = action.newData.sl
            if (action.newData.sl?.body) {
              subjectline.text = action.newData.sl.text
              subjectline.body = action.newData.sl.body
            }
          }
          return subjectline
        }
      )

      return {
        ...state,
        subjectLines: updatedSubjectlines,
        rejectLineLoading: action.rejectLineLoading,
        showTweakLine: action.showTweakLine,
        lineBeingTweaked: action.lineBeingTweaked,
        // For linguo we dont want a subjectline which is just replaced to be considered as a variant to be approved
        ...(!helpers.isLinguoCampaign(state.campaignData) ||
        (helpers.isInitialised(state.campaignData) &&
          helpers.isLinguoCampaign(state.campaignData))
          ? {
              variantsToBeApprovedExperimentsFlow: [
                ...state.variantsToBeApprovedExperimentsFlow.filter(
                  (id) => id !== action.rejectedData.line_id
                ),
                action.newData.id,
              ],
              variantsToBeApproved: [
                ...state.variantsToBeApproved.filter(
                  (id) => id !== action.rejectedData.line_id
                ),
                action.newData.id,
              ],
            }
          : {}),
      }
    }
    case types.LANGUAGE_APPROVAL_REJECT_LINE:
      return {
        ...state,
        rejectLineLoading: action.rejectLineLoading,
      }
    case types.LANGUAGE_APPROVAL_OPEN_TWEAK_LINE:
      return {
        ...state,
        showTweakLine: action.showTweakLine,
        lineBeingTweaked: action.line,
      }
    case types.LANGUAGE_APPROVAL_CLOSE_TWEAK_LINE:
      return {
        ...state,
        showTweakLine: action.showTweakLine,
        lineBeingTweaked: action.lineBeingTweaked,
      }
    case types.LANGUAGE_APPROVAL_TWEAK_LINE_LOADING:
      return {
        ...state,
        tweakingLoading: action.tweakingLoading,
      }
    case types.LANGUAGE_APPROVAL_TWEAK_LINE_ERROR:
      return {
        ...state,
        tweakingLoading: action.tweakingLoading,
      }
    case types.LANGUAGE_APPROVAL_REJECT_LINE_ERROR:
      return {
        ...state,
        rejectLineLoading: action.rejectLineLoading,
      }
    case types.LANGUAGE_APPROVAL_REJECT_LINE_SUCCESS_FEEDBACK_ONLY:
      const subjectLines = cloneDeep(state.subjectLines)

      return {
        ...state,
        rejectLineLoading: action.rejectLineLoading,
        showTweakLine: action.showTweakLine,
        subjectLines: subjectLines.map((line) => {
          const tweakedLine = line
          if (tweakedLine._id === action.rejectedLineData.id) {
            tweakedLine.why_dislike = action.rejectedLineData.why_dislike
          }
          return tweakedLine
        }),
      }
    case types.LANGUAGE_APPROVAL_CAMPAIGN_LOADING:
      return {
        ...state,
        languageApprovalLoading: action.languageApprovalLoading,
      }
    case types.LANGUAGE_APPROVAL_CAMPAIGN_LOADING_ERROR:
      return {
        ...state,
        languageApprovalLoading: action.languageApprovalLoading,
      }
    case types.UPDATE_CAMPAIGN_DATA_FAIL:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        error: action.error,
      }
    case types.RENAME_CAMPAIGN_SUCCESS:
      return {
        ...state,
        campaignName: action.newName,
        campaignData: {
          ...state.campaignData,
          name: action.newName,
        },
      }
    case types.DOWNLOAD_RESUlTS_FILE: {
      return {
        ...state,
        csv: action.csv,
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    }
    case types.LANGUAGE_APPROVAL_SAVING: {
      return {
        ...state,
        languageApprovalLoading: action.languageApprovalLoading,
      }
    }
    case types.CAMPAIGN_DETAILS_LOADING:
      return {
        ...state,
        typeOfIntegration: action.typeOfIntegration,
        isLoadingCampaignDetails: !action.isBackgroundFetch,
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    case types.UPDATE_CAMPAIGN_DATA:
    case types.CAMPAIGN_DETAILS_LOADED:
      const { campaignData } = action
      return {
        ...state,
        ampScript: action.campaignData.amp_script || '',
        isUserInput: false,
        campaignName: action.campaignData.name,
        isLoading: action.isLoading,
        isLoadingCampaignDetails: false,
        isSaveContinue: action.isSaveContinue,
        campaignData: action.campaignData,
        nfmUsed: action.campaignData.nfm_used,
        tweakUsed: action.campaignData.tweak_used,
        newlinesInVariants:
          action.campaignData?.campaign_configuration?.validation_rules
            ?.newlines_in_variants,
        currentCampaignId: action.campaignData._id,
        projectId: action.campaignData.project_id,
        subjectLines: action.campaignData.sl || state.subjectLines,
        inputResultsAt: action.campaignData?.input_results_at,
        // eslint-disable-next-line prettier/prettier
        typeOfIntegration:
          action.campaignData?.campaign_configuration?.integration_options?.type
            ?.toString()
            .toLowerCase(),
        isWaitingState: {
          ...action.isWaitingState,
        },
        campaignValidationRules:
          action.campaignData?.campaign_configuration?.validation_rules,
        ...(!helpers.isLinguoCampaign(campaignData) ||
        (helpers.isInitialised(campaignData) &&
          helpers.isLinguoCampaign(campaignData))
          ? {
              variantsToBeApprovedExperimentsFlow: action.campaignData?.sl
                ?.filter(
                  (sl: SubjectLine) => sl?.bandit_status?.status === 'pending'
                )
                ?.map((sl: SubjectLine) => sl._id),
            }
          : {}),
        ...(helpers.shouldEnableVariantApprovals(campaignData)
          ? {
              variantsToBeApproved: action.campaignData?.sl
                ?.filter(
                  (sl: SubjectLine) => sl?.bandit_status?.status === 'pending'
                )
                ?.map((sl: SubjectLine) => sl._id),
            }
          : { variantsToBeApproved: [] }),
        acousticIntegration: action.campaignData.email_template
          ? {
              emailTemplate: action.campaignData.email_template,
              suppressionsLists: action.campaignData.suppressions_lists,
              mailingParameter: action.campaignData.mailing_parameter,
              sendDateTime: action.campaignData.send_date_time,
              waitTime: action.campaignData.wait_time,
              sendOptimization: action.campaignData.send_optimization,
              isAutoDeploy: action.campaignData.tobesent,
            }
          : undefined,
        combinedLanguageSettings: {
          isoCurrency:
            action.campaignData.combined_language_settings?.iso_currency,
          isoLanguage:
            action.campaignData.combined_language_settings?.iso_language,
          contentStructure:
            action.campaignData.combined_language_settings?.content_structure,
        },
      }
    case types.CONTROL_SUBJECT_LINE_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case types.CONTROL_SUBJECT_LINE_UPDATED:
      const newSubjectLines = state.subjectLines?.map((sl) => {
        if (sl._id === action.newSubjectLine?._id) {
          return { ...sl, text: action.newSubjectLine?.text }
        }
        return sl
      })
      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          sl: newSubjectLines,
          own_subject_line: action.newSubjectLine?.text,
        },
        subjectLines: newSubjectLines,
        isLoading: false,
      }
    case types.CONTROL_SUBJECT_LINE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    case types.UPDATE_VARIANT_SENTIMENTS:
      const { variant } = action

      const updatedSubjectLines = state.subjectLines.map((sl) => {
        if (sl._id === variant._id) {
          return { ...variant }
        }
        return sl
      })

      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          sl: updatedSubjectLines,
        },
        subjectLines: updatedSubjectLines,
      }
    case types.LATEST_VARIANTS_LOADED:
      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          sl: action.variants,
        },
        subjectLines: action.variants,
        ...(!helpers?.isLinguoCampaign(state.campaignData) ||
        (helpers?.isInitialised(state.campaignData) &&
          helpers?.isLinguoCampaign(state.campaignData))
          ? {
              variantsToBeApprovedExperimentsFlow: action.variants
                .filter((sl: any) => sl?.bandit_status?.status === 'pending')
                .map((sl: any) => sl._id),
            }
          : {}),
        ...(helpers.shouldEnableVariantApprovals(state.campaignData)
          ? {
              variantsToBeApproved: action.variants
                .filter((sl: any) => sl?.bandit_status?.status === 'pending')
                .map((sl: any) => sl._id),
            }
          : { variantsToBeApproved: [] }),
      }
    case types.SET_CAMPAIGN_TOKEN: {
      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          campaign_data: {
            ...state.campaignData.campaign_data,
            campaign_token: action.campaignToken,
          },
        },
      }
    }
    case types.LANGUAGE_APPROVAL_SAVING_SUCCESS:
      return {
        ...state,
        variantsToBeApprovedExperimentsFlow: [],
        variantsToBeApproved: [],
        isUserInput: action.isUserInput,
        campaignName: action.campaignData.name,
        isLoading: action.isLoading,
        languageApprovalLoading: action.languageApprovalLoading,
        isSaveContinue: action.isSaveContinue,
        campaignData: {
          ...((!action.campaignData ||
            !action.campaignData.campaign_progress ||
            !action.campaignData.campaign_progress.test_cancelled) && {
            ...state.campaignData,
          }),
          ...action.campaignData,
        },
        nfmUsed: action.campaignData.nfm_used,
        tweakUsed: action.campaignData.tweak_used,
        currentCampaignId: action.campaignData._id,
        projectId: action.campaignData.project_id,
        subjectLines: action.campaignData.sl || state.subjectLines,
        typeOfIntegration:
          action.campaignData?.campaign_configuration?.integration_options?.type
            ?.toString()
            .toLowerCase(),
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    case types.LANGUAGE_APPROVAL_SAVING_ERROR:
      return {
        ...state,
        languageApprovalLoading: action.languageApprovalLoading,
      }
    case types.UNLINK_MANAGED_FILE:
    case types.UPLOAD_MANAGED_FILE:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    case types.UNLINK_MANAGED_FILE_FAIL:
    case types.UPLOAD_MANAGED_FILE_FAIL:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        error: action.error,
      }
    case types.UPLOAD_RESUlTS_FILE_FAIL:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        shouldUpdateCampaignData: true,
        error: action.error,
      }
    case types.RESULTS_DETAILS_LOADED:
    case types.UPLOAD_RESUlTS_FILE:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        resultsDetailsIsLoading: action.isLoading,
        warnings: action.warnings,
        results: {
          variants: action.results.variants || null,
          split_campaign_results: action.results.split_results || null,
          final_campaign_results: action.results.final_results || null,
          machine_summary: action.results.machine_summary || null,
          reports: action.results.reports || null,
        },
      }
    case types.UPDATE_RESULTS_VARIANTS_STATUS:
      const clonedSplitCampaignResults = cloneDeep(
        state.results.split_campaign_results
      )
      const clonedVariants = cloneDeep(state.results.variants)
      if (clonedSplitCampaignResults) {
        const updatedValues = clonedSplitCampaignResults.values.map(
          (resultItem) => {
            if (action.updateData.variantId === resultItem._id) {
              resultItem.variant_status = action.updateData.variantStatus
            }
            return resultItem
          }
        )
        clonedSplitCampaignResults.values = [...updatedValues]
      } else if (clonedVariants) {
        const updatedValues = clonedVariants.values.map((resultItem) => {
          if (action.updateData.variantId === resultItem._id) {
            resultItem.variant_status = action.updateData.variantStatus
          }
          return resultItem
        })
        clonedVariants.values = [...updatedValues]
      }

      return {
        ...state,
        results: {
          variants: clonedVariants,
          split_campaign_results: clonedSplitCampaignResults,
          final_campaign_results: cloneDeep(
            state.results.final_campaign_results
          ),
          reports: cloneDeep(state.results.reports),
        },
      }
    case types.CAMPAIGN_DETAILS_FAILED_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        isLoadingCampaignDetails: false,
        error: action.error,
      }
    case types.FETCH_ACCOUNT_TEAMS_LIST_SUCCESS: {
      return {
        ...state,
        isLoading: action.isLoading,
        teamsList: action.teamsList,
      }
    }
    case types.FETCH_ACCOUNT_TEAMS_LIST_FAIL: {
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    }
    case types.FETCH_NFM_TWEAK_LIMIT_SUCCESS: {
      const { nfmUsed, tweakUsed, line } = action
      let shouldDisplayBoomModal = false
      if (state.campaignData?.campaign_configuration?.feedback_settings) {
        const {
          edit: { limit: editLimit },
          reject: { limit: rejectLimit },
        } = state.campaignData?.campaign_configuration?.feedback_settings
        shouldDisplayBoomModal = tweakUsed > editLimit || nfmUsed > rejectLimit
      }

      return {
        ...state,
        isLoading: action.isLoading,
        nfmUsed: action.nfmUsed,
        tweakUsed: action.tweakUsed,
        newlinesInVariants: action.newlinesInVariants,
        shouldDisplayBoomModal,
        showTweakLine: !shouldDisplayBoomModal,
        lineBeingTweaked: line,
      }
    }
    case types.CLICK_CLOSE_BOOM_MODAL:
      return {
        ...state,
        shouldDisplayBoomModal: false,
      }
    case types.FETCH_NFM_TWEAK_LIMIT_FAIL:
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
      }
    case types.SET_WORKFLOW_NAVIGATION:
      return {
        ...state,
        isUserInput: action.isUserInput,
        chosenStep: action.chosenStep,
      }
    case types.RESET_BANNER:
    case types.RENAME_CAMPAIGN_FAIL:
      return {
        ...state,
        error: action.error,
        warnings: null,
      }
    case types.FETCHING_MOVABLE_INK_API_KEYS:
      return {
        ...state,
        fetchingMovableInkApiKeys: true,
      }
    case types.FETCHING_MOVABLE_INK_API_KEYS_SUCCESS:
      return {
        ...state,
        fetchingMovableInkApiKeys: false,
        movableInkApiKeys: action.keys,
      }
    case types.FETCHING_MOVABLE_INK_API_KEYS_ERROR:
      return {
        ...state,
        fetchingMovableInkApiKeysError: true,
      }
    case types.CLEARING_MOVABLE_INK_TEST_DATA:
      return {
        ...state,
        clearingMovableInkTestData: true,
      }
    case types.CLEAR_MOVABLE_INK_TEST_DATA_SUCCESS:
      return {
        ...state,
        clearingMovableInkTestData: false,
      }
    case types.CLEAR_MOVABLE_INK_TEST_DATA_ERROR:
      return {
        ...state,
        clearingMovableInkTestData: false,
      }
    case types.UPDATING_MOVABLE_INK_INTEGRATION_DATA:
      return {
        ...state,
        updatingMovableInkIntegrationData: true,
      }
    case types.UPDATE_MOVABLE_INK_INTEGRATION_DATA_SUCCESS:
      return {
        ...state,
        campaignData: {
          ...state.campaignData,
          campaign_data: {
            integration_data: action.integrationData,
          },
        },
        updatingMovableInkIntegrationData: false,
      }
    case types.UPDATE_MOVABLE_INK_INTEGRATION_DATA_ERROR:
      return {
        ...state,
        updatingMovableInkIntegrationData: false,
      }
    // TAKEN FROM SUMMARY REDUCER
    case types.SEND_DEFINITION_LIST_IS_LOADING:
      return {
        ...state,
        isSendDefinitionLoading: action.isSendDefinitionLoading,
        engageSfmcError: null,
      }
    case types.CAMPAIGN_CANCEL_FAILED:
      const errorMsg =
        typeof action.error === 'string' ? action.error : 'Error occurred'
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        error: errorMsg,
        engageSfmcError: errorMsg,
        cancelResponse: {
          ...action.cancelResponse,
          sendsCancelled: action.sendsCancelled,
          message: action.error.message,
        },
      }
    case types.RESULTS_DETAILS_FAILED_LOADING:
      return {
        ...state,
        resultsDetailsIsLoading: action.isLoading,
        error: action.error,
        results: {
          variants: null,
          split_campaign_results: null,
          final_campaign_results: null,
          machine_summary: null,
          reports: null,
        },
      }
    case types.UPDATE_SUMMARY:
      return {
        ...state,
        cancelResponse: null,
        isWaitingState: {
          ...action.isWaitingState,
        },
        campaignData: {
          ...state.campaignData,
          campaign_progress: {
            ...action.summary.campaign_progress,
          },
        },
        summary: {
          isScheduled: action.summary.isScheduled,
          finalCampaignResult: action.summary.finalCampaignResult,
          splitCampaignResult: action.summary.splitCampaignResult,
          hoursBeforeFinal: action.summary.hoursBeforeFinal,
        },
      }
    case types.SUMMARY_DETAILS_LOADED:
      return {
        ...state,
        isLoading: action.isLoading,
        summary: {
          isScheduled: false,
          finalCampaignResult: action.summary.finalCampaign,
          splitCampaignResult: action.summary.splitCampaign,
          hoursBeforeFinal: action.summary.timeBeforeToScheduleFinal,
        },
      }
    case types.SUMMARY_DETAILS_FAILED_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
        error: action.error,
        summary: null,
      }
    case types.USER_INITIATED_EMAILS_LOADED:
      return {
        ...state,
        isSendDefinitionLoading: action.isSendDefinitionLoading,
        isScheduleButtonDisabled: action.isScheduleButtonDisabled,
        emailList: action.emailList,
      }
    case types.USER_INITIATED_EMAILS_FETCH_FAILED:
      return {
        ...state,
        isSendDefinitionLoading: action.isSendDefinitionLoading,
        isScheduleButtonDisabled: true,
        engageSfmcError: action.engageSfmcError,
      }
    case types.CAMPAIGN_CANCELLED_SUCCESSFULLY: {
      const progress =
        action.cancelResponse.campaign_progress || action.cancelResponse
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        cancelResponse: {
          ...action.cancelResponse,
          sendsCancelled: action.sendsCancelled,
        },
        campaignData: {
          ...state.campaignData,
          campaign_progress: {
            ...state.campaignData.campaign_progress,
            ...progress,
          },
        },
        summary: {},
        scheduleResponse: null,
      }
    }
    case types.SCHEDULE_CHEETAH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        scheduleResponse: action.scheduleResponse,
        cancelResponse: {
          ...state.cancelResponse,
          sendsCancelled: false,
        },
        scheduleCheetahError: null,
      }
    case types.SCHEDULE_ACOUSTIC_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        scheduleResponse: action.scheduleResponse,
        cancelResponse: {
          ...state.cancelResponse,
          sendsCancelled: false,
        },
        ampScript: action.scheduleResponse?.amp_script,
        campaignData: {
          ...state.campaignData,
          campaign_progress: {
            ...state.campaignData.campaign_progress,
            ...action.scheduleResponse.campaign_progress,
          },
        },
        acousticCampaignScheduled: true,
      }
    case types.SCHEDULE_SFMC_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        scheduleResponse: action.scheduleResponse,
        cancelResponse: {
          ...state.cancelResponse,
          sendsCancelled: false,
        },
        ampScript: action.scheduleResponse?.amp_script,
        campaignData: {
          ...state.campaignData,
          campaign_progress: {
            ...state.campaignData.campaign_progress,
            ...action.scheduleResponse.campaign_progress,
          },
        },
      }
    case types.SCHEDULE_CHEETAH_CAMPAIGN_FAIL:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        scheduleResponse: null,
        scheduleCheetahError: action.error,
      }
    case types.SCHEDULE_ACOUSTIC_CAMPAIGN_FAIL:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        scheduleResponse: null,
      }
    case types.SCHEDULE_SFMC_CAMPAIGN_FAIL:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        scheduleResponse: null,
        engageSfmcError: action.engageSfmcError,
      }
    case types.UPDATE_SUBJECTLINE_SAILTHRU_CAMPAIGN_SUCCESS:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case types.CHECK_BEFORE_CANCEL_SUCCESS:
      return {
        ...state,
        acousticCampaignScheduled: action.acousticCampaignScheduled,
        mailingExists: action.mailingExists,
        splitsSent: action.splitsSent,
        folderName: action.folderName,
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    case types.CHECK_BEFORE_CANCEL_FAILED:
      return {
        ...state,
        acousticCampaignScheduled: action.acousticCampaignScheduled,
        mailingExists: action.mailingExists,
        splitsSent: action.splitsSent,
        folderName: action.folderName,
        error: action.error,
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    case types.RESET_ACOUSTIC_DATA:
      return {
        ...state,
        acousticCampaignScheduled: false,
        mailingExists: undefined,
        splitsSent: undefined,
        folderName: null,
        error: null,
        isWaitingState: {
          isWaitingFor: null,
          isWaiting: false,
        },
      }
    case types.UPDATE_SUBJECTLINE_SAILTHRU_CAMPAIGN_FAIL:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    case types.SET_TYPE_INTEGRATION:
      return {
        ...state,
        typeOfIntegration: action.payload,
      }
    // END SUMMARY REDUCER
    // TAKEN FROM INTEGRATION OPTIONS
    case types.AMP_SCRIPT_LOAD_SUCCESS:
      return {
        ...state,
        ampScript: action.ampScript,
        loadingAmpScript: false,
      }
    case types.AMP_SCRIPT_LOADING:
      return {
        ...state,
        loadingAmpScript: true,
      }
    case types.AMP_SCRIPT_LOAD_ERROR:
      return {
        ...state,
        loadingAmpScript: false,
      }
    case types.UNSCHEDULED_CAMPAIGNS_LOADED:
      return {
        ...state,
        isLoading: action.isLoading,
        sailthruIntegration: {
          ...state.sailthruIntegration,
          listCampaigns: action.listCampaigns.blasts.sort(
            helpers.sortAlphabetically('name')
          ),
        },
      }
    case types.UNSCHEDULED_CAMPAIGNS_FAILED_LOADING:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        sailthruIntegration: {
          ...state.sailthruIntegration,
          listCampaigns: [],
        },
        error: action.error.message,
      }
    case types.SET_SPLIT_CAMPAIGN_ID:
      return {
        ...state,
        sailthruIntegration: {
          ...state.sailthruIntegration,
          splitCampaign: action.payload,
        },
      }
    case types.SET_FINAL_CAMPAIGN_ID:
      return {
        ...state,
        sailthruIntegration: {
          ...state.sailthruIntegration,
          finalCampaign: action.payload,
        },
      }
    case types.SET_TIME_BEFORE_FINAL_DATE:
      return {
        ...state,
        sailthruIntegration: {
          ...state.sailthruIntegration,
          hoursBeforeFinalDateTime: action.payload,
        },
      }
    case types.SET_SCHEDULE_TIME:
      return {
        ...state,
        sailthruIntegration: {
          ...state.sailthruIntegration,
          scheduleTime: action.payload,
        },
      }
    case types.SET_SCHEDULE_DATE:
      return {
        ...state,
        sailthruIntegration: {
          ...state.sailthruIntegration,
          scheduleDate: action.payload,
        },
      }
    case types.SET_SUBJECTS_LINE:
      return {
        ...state,
        sailthruIntegration: {
          ...state.sailthruIntegration,
          summary: {
            ...state.sailthruIntegration.summary,
            subjectsLines: action.payload,
          },
        },
      }
    case types.RESET_REDUX_FORM:
      return {
        ...state,
        sailthruIntegration: {
          ...initialWorkflowState.sailthruIntegration,
        },
      }
    // END INTEGRATION OPTIONS
    // CAMPAIGN STATUS
    case types.SUBMITTED_FOR_SCHEDULING:
      // Update Schedule step status to `process` as soon as submitted for scheduling
      const clonedSteps = cloneDeep(state.steps)

      return {
        ...state,
        steps: clonedSteps.map((step: any) => {
          if (step.title === 'Schedule') {
            step.status = 'process'
          }
          return step
        }),
      }
    case types.FETCH_CAMPAIGN_BG_STATUS_SUCCESS: {
      const currentScheduleStep = state.steps.find(
        (step: any) => step.title === 'Schedule'
      )
      return {
        ...state,
        shouldStopPolling: action.shouldStopPolling,
        campaignData: {
          ...state?.campaignData,
          ...(action.campaignDataSteps
            ? { steps: action.campaignDataSteps }
            : {}),
          campaign_progress: {
            ...action.campaign_progress,
          },
          campaign_data: {
            ...action.campaign_data,
          },
        },
        steps: action.steps.map((step: any, i: number) => {
          // if already updated Schedule step status to process
          // do not revert it back to 'wait'
          let { status } = step
          if (step.title === 'Schedule') {
            if (
              currentScheduleStep?.status === 'process' &&
              status === 'wait'
            ) {
              status = 'process'
            }
          }
          return {
            ...step,
            status,
            key: i,
          }
        }),
      }
    }
    case types.RETRIEVE_CHEETAH_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        cheetahCampaigns: action.campaigns,
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    }
    case types.RETRIEVE_CHEETAH_CAMPAIGN_ERROR: {
      return {
        ...state,
        error: action.message,
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    }
    case types.RETRIEVE_SINGLE_CHEETAH_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        cheetahCampaigns: Array({
          ...action.campaign,
          display_name: action.campaign.campName,
          ref_id: action.campaign.campId,
        }),
        isWaitingState: {
          ...action.isWaitingState,
        },
        cheetahTimeZone: action.cheetahTimeZone,
        timeZone: action.timeZone,
      }
    }
    case types.RETRIEVE_SINGLE_CHEETAH_CAMPAIGN_ERROR: {
      return {
        ...state,
        error: action.message,
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    }
    case types.RETRIEVE_CHEETAH_CAMPAIGN_TIMEZONE: {
      return {
        ...state,
        cheetahTimeZone: action.cheetahTimeZone,
        timeZone: action.timeZone,
      }
    }
    case types.RETRIEVE_TXT_CHEETAH_CAMPAIGN_SUCCESS: {
      helpers.downloadTxt(`${action.currentCampaignId}-import`, action.response)
      return {
        ...state,
      }
    }
    // END CAMPAIGN STATUS

    // ADVANCED VISUALISATIONS
    case types.ADVANCED_VISUALISATIONS_LOADING:
      return {
        ...state,
        advancedVisualisationsLoading: action.advancedVisualisationsLoading,
        advancedVisualisationsData: {
          campaignId: action.campaignId,
          ...state.advancedVisualisationsData,
          ...action.advancedVisualisationsData,
        },
        error: action.error,
      }
    case types.ADVANCED_VISUALISATIONS_RESET:
      return {
        ...state,
        advancedVisualisationsLoading: action.advancedVisualisationsLoading,
        advancedVisualisationsData: action.advancedVisualisationsData,
        advancedVisualisationsError: action.advancedVisualisationsError,
      }
    case types.ADVANCED_VISUALISATIONS_SUCCESS:
      return {
        ...state,
        advancedVisualisationsLoading: action.advancedVisualisationsLoading,
        advancedVisualisationsData: {
          campaignId: action.campaignId,
          ...state.advancedVisualisationsData,
          ...action.advancedVisualisationsData,
        },
        maxVariantOpenRate:
          action?.maxVariantOpenRate || initialWorkflowState.maxVariantOpenRate,
        advancedVisualisationsError: action.advancedVisualisationsError,
      }

    case types.ADVANCED_VISUALISATIONS_FAIL:
      return {
        ...state,
        advancedVisualisationsLoading: action.advancedVisualisationsLoading,
        advancedVisualisationsData: action.advancedVisualisationsData,
        advancedVisualisationsError: action.advancedVisualisationsError,
      }

    // END ADVANCED VISUALISATIONS
    /* ----------Mapp setup action types start---------------- */
    case types.FETCH_MAPP_MESSAGES_SUCCESS:
      return {
        ...state,
        draftMessages: action.draftMessages,
        shouldCloseMappDrawer: action.shouldCloseMappDrawer,
      }
    case types.FETCH_MAPP_MESSAGES_FAIL:
      return {
        ...state,
        draftMessages: action.draftMessages,
        shouldCloseMappDrawer: action.shouldCloseMappDrawer,
        error: action.error,
      }
    case types.UPDATE_MAPP_MESSAGES_SUCCESS:
      return {
        ...state,
        updatedMappMessage: action.updatedMappMessage,
      }
    case types.UPDATE_MAPP_MESSAGES_FAIL:
      return {
        ...state,
        updatedMappMessage: null,
        error: action.error,
      }
    /* ----------Mapp setup action types end---------------- */
    case types.FETCH_BRAZE_CAMPAIGNS_STARTED:
    case types.FETCH_BRAZE_CAMPAIGNS_SUCCESS:
    case types.FETCH_BRAZE_CAMPAIGNS_ERROR:
      return {
        ...state,
        brazeCampaigns: action.brazeCampaigns,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.SCHEDULE_BRAZE_CAMPAIGNS_STARTED:
    case types.SCHEDULE_BRAZE_CAMPAIGNS_SUCCESS:
    case types.SCHEDULE_BRAZE_CAMPAIGNS_ERROR:
      return {
        ...state,
        brazeResponse: action.brazeResponse,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.FETCH_TIME_ZONE_STARTED:
    case types.FETCH_TIME_ZONE_SUCCESS:
    case types.FETCH_TIME_ZONE_ERROR:
      return {
        ...state,
        timeZone: action.timeZone,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.CANCEL_BRAZE_CAMPAIGN_STARTED:
    case types.CANCEL_BRAZE_CAMPAIGN_ERROR:
      return {
        ...state,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.CANCEL_BRAZE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        error: action.error,
        isWaitingState: action.isWaitingState,
        campaignData: {
          ...state.campaignData,
          campaign_data: {
            integration_data: action.integrationData,
          },
        },
      }
    case types.GET_MID_LOADING:
    case types.GET_MID_SUCCESS:
    case types.GET_MID_ERROR:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
        midList: Array.isArray(action.midList) ? action.midList : [],
        midListError: action.midListError,
      }
    case types.RESET_CHEETAH_STATE:
      return {
        ...state,
        scheduleCheetahError: null,
      }
    case types.RESET_ENGAGE_SFMC_STATE:
      return {
        ...state,
        engageSfmcError: null,
      }
    /* ----------Epsilon integration action types start---------------- */
    case types.FETCH_EPSILON_BUSINESS_UNITS:
    case types.FETCH_EPSILON_BUSINESS_UNITS_SUCCESS:
    case types.FETCH_EPSILON_BUSINESS_UNITS_ERROR:
      return {
        ...state,
        epsilonBusinessUnits: action.epsilonBusinessUnits,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.FETCH_EPSILON_SEED_LIST_START:
    case types.FETCH_EPSILON_SEED_LIST_SUCCESS:
    case types.FETCH_EPSILON_SEED_LIST_ERROR:
      return {
        ...state,
        epsilonSeedList: action.epsilonSeedList,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.FETCH_EPSILON_FOLDERS:
    case types.FETCH_EPSILON_FOLDERS_SUCCESS:
    case types.FETCH_EPSILON_FOLDERS_ERROR:
      return {
        ...state,
        epsilonFolders: action.epsilonFolders,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.FETCH_EPSILON_CAMPAIGNS:
    case types.FETCH_EPSILON_CAMPAIGNS_SUCCESS:
    case types.FETCH_EPSILON_CAMPAIGNS_ERROR:
      return {
        ...state,
        epsilonCampaigns: action.epsilonCampaigns,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.FETCH_EPSILON_SEQUENCES:
    case types.FETCH_EPSILON_SEQUENCES_SUCCESS:
    case types.FETCH_EPSILON_SEQUENCES_ERROR:
      return {
        ...state,
        epsilonSequences: action.epsilonSequences,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.PUSH_VARIANTS_TO_EPSILON:
    case types.PUSH_VARIANTS_TO_EPSILON_SUCCESS:
    case types.PUSH_VARIANTS_TO_EPSILON_ERROR:
      return {
        ...state,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.FETCH_EPSILON_TIME_ZONE:
    case types.FETCH_EPSILON_TIME_ZONE_SUCCESS:
    case types.FETCH_EPSILON_TIME_ZONE_ERROR:
      return {
        ...state,
        epsilonTimeZone: action.epsilonTimeZone,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.DELETE_VARIANTS_FROM_EPSILON:
    case types.DELETE_VARIANTS_FROM_EPSILON_SUCCESS:
    case types.DELETE_VARIANTS_FROM_EPSILON_ERROR:
      return {
        ...state,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.SCHEDULE_EPSILON_CAMPAIGN:
    case types.SCHEDULE_EPSILON_CAMPAIGN_ERROR:
    case types.SCHEDULE_EPSILON_CAMPAIGN_SUCCESS:
      return {
        ...state,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.CANCEL_EPSILON_CAMPAIGN:
    case types.CANCEL_EPSILON_CAMPAIGN_ERROR:
    case types.CANCEL_EPSILON_CAMPAIGN_SUCCESS:
      return {
        ...state,
        error: action.error,
        isWaitingState: action.isWaitingState,
      }
    case types.RESET_EPSILON_STATE:
      return {
        ...state,
        epsilonBusinessUnits: undefined,
        epsilonTimeZone: undefined,
        epsilonCampaigns: undefined,
        epsilonFolders: undefined,
      }
    /* ----------Epsilon integration action types end---------------- */
    /* ----------Emarsys integration ---------------- */
    case types.PUSH_SPLIT_TO_EMARSYS_FAIL:
    case types.PUSH_SPLIT_TO_EMARSYS_SUCCESS:
      return {
        ...state,
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    case types.RETRIEVE_EMARSYS_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        timeZone: action.timeZone,
        emarsysCampaigns: action.campaigns,
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    }
    case types.RETRIEVE_EMARSYS_CAMPAIGN_ERROR: {
      return {
        ...state,
        error: action.message,
        isWaitingState: {
          ...action.isWaitingState,
        },
      }
    }
    /* ----------Emarsys integration end---------------- */
    case types.TOGGLE_DETAILS_PANEL: {
      return {
        ...state,
        isDetailsPanelExpanded: action.value,
      }
    }
    case types.SET_DYNAMIC_OPTIMIZATIONS_RESULT_TYPE: {
      return {
        ...state,
        dynamicOptimizationResultType: action.dynamicOptimizationResultType,
      }
    }
    default:
      return state
  }
}
