import { useQuery } from '@tanstack/react-query'

import { fetchCombinations, Region } from '../api'
import { personalizedContentKeys } from '../queryKeys'

const useGetCombinationLibraryQuery = ({
  personalizationId,
  accountId,
  region,
}: {
  personalizationId: string
  accountId: string | undefined
  region: Region | undefined
}) => {
  return useQuery(
    personalizedContentKeys.combinations({
      accountId,
      personalizationId,
      region,
    }),
    () => fetchCombinations({ accountId, personalizationId, region }),
    {
      enabled: !!accountId && !!region,
      retry: false,
      refetchOnWindowFocus: false,
      select: (data) => {
        return data?.items.map((item) => {
          return {
            ...item,
            // adding the following until the BE provides all missing properties
            owner: undefined,
            numberOfVariants: 10,
            approvalRate: '87%',
            rejectedCount: 0,
          }
        })
      },
      meta: {
        errorMessage: 'The combination library could not be loaded',
      },
    }
  )
}

export default useGetCombinationLibraryQuery
