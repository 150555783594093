import SuccessButton from 'workflow/CampaignSummary/IntegrationOptions/SuccessButton'

import BaseButton from 'common/components/button/Button'

import { actionButtonLabels } from './components/advancedOptions/constants'

type Props = {
  isScheduled: boolean
  isDisabled: boolean
  isLoading: boolean
  isUpdateable: boolean
  scheduleHandler: (isUpdating: boolean) => Promise<void>
  isExperimentBeingRestarted: boolean
}

const Button = ({
  isScheduled,
  isDisabled,
  isLoading,
  isUpdateable,
  scheduleHandler,
  isExperimentBeingRestarted,
}: Props) => {
  const renderButtonText = () => {
    if (isExperimentBeingRestarted) {
      return actionButtonLabels.restartingExperiment
    }
    if (isUpdateable && isScheduled) {
      return actionButtonLabels.updateSettings
    }
    return actionButtonLabels.startTheAwesome
  }

  return (
    <>
      {isScheduled && !isUpdateable ? (
        <SuccessButton text={actionButtonLabels.awesomeStarted} />
      ) : (
        <BaseButton
          variant="primary"
          disabled={isDisabled || isLoading}
          loading={isLoading}
          onClick={() => {
            scheduleHandler(isUpdateable)
          }}
          data-testid="start-the-awesome"
        >
          {renderButtonText()}
        </BaseButton>
      )}
    </>
  )
}

export default Button
