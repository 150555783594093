import { useState } from 'react'
import { useForm } from 'react-final-form'
import RouterPromptStay from 'app/router/RouterPromptStay'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

import Button from 'common/components/button'
import {
  INTERCOM_DEFAULT_VERTICAL_PADDING,
  useMoveIntercomButton,
} from 'common/components/intercom/useMoveIntercomButton'
import { Topic } from 'common/components/topics/interfaces'
import Topics from 'common/components/topics/Topics'
import { useAppDispatch } from 'common/hooks/redux'
import { ArrowRight as ArrowRightIcon } from 'common/icons'
import { resetUnsavedModal } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { PageState } from './BriefPage'

type Props = {
  topics: Topic[]
  pageState: PageState
  isGeneratingVariants: boolean
  isReadOnly: boolean
  isGenerateStepActive?: boolean
  contentId?: string
  setSelectedTopics: (topics: Topic[]) => void
  revertTopics: () => void
}

const GeneratedTopics = ({
  topics,
  pageState,
  isGeneratingVariants,
  isGenerateStepActive = false,
  setSelectedTopics,
  revertTopics,
  isReadOnly,
  contentId,
}: Props) => {
  const form = useForm()
  const dispatch = useAppDispatch()
  const [isRegenerating, setIsRegenerating] = useState<boolean>(false)

  const intercomVerticalPadding =
    pageState === 'generateVariants' ? 62 : INTERCOM_DEFAULT_VERTICAL_PADDING
  useMoveIntercomButton(intercomVerticalPadding)

  const areTopicsValid = topics.every((topic) => {
    return !isEmpty(topic.name)
  })

  const onRegenerateVariants = () => {
    setIsRegenerating(true)
    dispatch(resetUnsavedModal())
    form.change('submitAction', 'generateVariants')
  }

  return (
    <>
      {(pageState === 'showGeneratedContent' ||
        pageState === 'generateVariants' ||
        pageState === 'formChangedWithTopics' ||
        pageState === 'brandVoiceChanged' ||
        pageState === 'topicsChanged') && (
        <>
          <div className="relative mb-6 mt-12">
            <Topics
              isDisabled={isReadOnly}
              topics={topics}
              contentId={contentId}
              hideNewTopicsAddButton={true}
              onChange={(newTopics) => setSelectedTopics(newTopics)}
            />
          </div>
          {(pageState === 'generateVariants' ||
            pageState === 'topicsChanged' ||
            pageState === 'showGeneratedContent') && (
            <div
              className={cx('flex justify-end items-center cta-button', {
                hidden: pageState === 'showGeneratedContent',
                'mb-6':
                  pageState === 'generateVariants' && !isGenerateStepActive,
              })}
            >
              {pageState === 'generateVariants' && (
                <Button
                  disabled={isReadOnly}
                  type="submit"
                  data-cy="content-generate-variants-button"
                  data-testid="content-generate-variants-button"
                  variant="primary"
                  suffixIcon={
                    <ArrowRightIcon isDefaultColor={false} size={4} />
                  }
                  onClick={() => {
                    form.change('submitAction', 'generateVariants')
                  }}
                >
                  Generate
                </Button>
              )}
              {pageState === 'topicsChanged' && (
                <>
                  <Button
                    ghost
                    className="mr-4"
                    data-cy="content-topics-revert-button"
                    data-testid="content-topics-revert-button"
                    disabled={isGeneratingVariants || isReadOnly}
                    onClick={revertTopics}
                  >
                    Revert
                  </Button>
                  <Button
                    type="submit"
                    data-cy="content-regenerate-button"
                    data-testid="content-regenerate-button"
                    variant="primary"
                    disabled={
                      !areTopicsValid || isGeneratingVariants || isReadOnly
                    }
                    suffixIcon={
                      <ArrowRightIcon isDefaultColor={false} size={4} />
                    }
                    loading={isGeneratingVariants}
                    onClick={onRegenerateVariants}
                  >
                    Regenerate copy
                  </Button>
                </>
              )}
            </div>
          )}
        </>
      )}
      <RouterPromptStay
        shouldShow={
          pageState === 'topicsChanged' && !isRegenerating && !isReadOnly
        }
        onOK={() => Promise.resolve(true)}
      />
    </>
  )
}

export default GeneratedTopics
