import cx from 'classnames'

import { Triangle as TriangleIcon } from 'common/icons'

type Props = {
  className?: string
  hasBorder?: boolean
  hasIcon?: boolean
  width?: string
  height?: string
}

const ImageErrorFallback = ({
  className,
  width,
  height,
  hasBorder = false,
  hasIcon = false,
}: Props) => {
  return (
    <div
      className={cx(
        `${width} ${height}`,
        {
          'w-full': !width,
          'h-full': !height,
          'rounded-sm border-1 border-gold-200 p-4': hasBorder,
        },
        className
      )}
    >
      <div
        className={cx(
          'flex items-center justify-center w-full h-full bg-gold-50 rounded-sm'
        )}
      >
        <div className="flex items-center">
          {hasIcon && (
            <TriangleIcon isDefaultColor={false} className="text-gold-500" />
          )}

          <span
            className={cx('text-gold-500 text-xs font-normal', {
              'ml-2': hasIcon,
              'text-center': !hasIcon,
            })}
          >
            image error
          </span>
        </div>
      </div>
    </div>
  )
}

export default ImageErrorFallback
