interface FooterProps {
  count: number
  name: string
  clear: () => void
  onApply: () => void
  isApplyDisabled?: boolean
  errorMessage?: string
}

const DropdownFooter = ({
  count,
  clear,
  onApply,
  name,
  isApplyDisabled,
  errorMessage,
}: FooterProps) => {
  return (
    <div className="px-4 py-3 shadow-top">
      {errorMessage && (
        <div className="mb-2 text-right text-red-400">{errorMessage}</div>
      )}
      <div className="flex items-center justify-between">
        <button
          data-cy={`${name}-selector-clear-all`}
          data-testid={`${name}-selector-clear-all`}
          className="text-maroon-400 disabled:text-coolGray-400
            disabled:cursor-not-allowed"
          onClick={clear}
          disabled={!count}
        >
          Clear all {count ? `(${count})` : undefined}
        </button>

        <button
          data-cy={`${name}-selector-apply`}
          data-testid={`${name}-selector-apply`}
          className="py-1 px-2 bg-maroon-400 text-white font-medium
              disabled:bg-coolGray-300 disabled:cursor-not-allowed"
          onClick={onApply}
          disabled={isApplyDisabled}
        >
          Apply
        </button>
      </div>
    </div>
  )
}

export default DropdownFooter
