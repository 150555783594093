import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react'
import moment from 'moment'

import DateRange, {
  DateRangePickerType,
  FocusedInputType,
  isInclusivelyAfterDay,
  SelectorDate,
  TooltipProps as DateRangeTooltipProps,
} from 'common/components/dateRange'
import FormItem from 'common/components/formItem'
import { selectEndDate } from 'features/dashboard/store/dashboardSlice'

type Props = DateRangeTooltipProps & {
  applyDates: (startDate?: string, endDate?: string) => void
  selectedStartDate?: string
  selectedEndDate?: string
  isDisabled?: boolean
  areDefaultOptionsDisabled?: boolean
  flexWrapperRef?: RefObject<Element> | null
  label?: string
}

const DateRangePicker = ({
  selectedStartDate,
  selectedEndDate,
  applyDates,
  isDisabled = false,
  label = 'Date range',
  flexWrapperRef,
  areDefaultOptionsDisabled = false,
  ...rest
}: Props) => {
  const [startDate, setStartDate] = useState<SelectorDate>(null)
  const [endDate, setEndDate] = useState<SelectorDate>(null)
  const [focusedInput, setFocusedInput] =
    useState<FocusedInputType | null>(null)

  useEffect(() => {
    setStartDate(selectedStartDate ? moment(selectedStartDate) : null)
    setEndDate(selectedEndDate ? moment(selectedEndDate) : null)
  }, [selectedStartDate, selectedEndDate])

  const hasDateChanged =
    startDate?.toISOString() !== selectedStartDate ||
    endDate?.toISOString() !== selectedEndDate
  const areBothDatesSelected =
    (startDate && endDate) || (!startDate && !endDate)

  const datesChangeHandler: DateRangePickerType['onDatesChange'] = ({
    startDate: newStartDate,
    endDate: newEndDate,
  }) => {
    setStartDate(newStartDate)

    if (newStartDate === startDate) {
      setEndDate(newEndDate)
    } else {
      setEndDate(null)
    }
  }

  const ref = useRef<HTMLDivElement>(null)
  const [isOnFirstRow, setIsOnFirstRow] = useState(
    ref.current ? ref.current.offsetTop === 0 : undefined
  )
  if (isOnFirstRow === undefined && ref.current) {
    setIsOnFirstRow(ref.current.offsetTop === 0)
  }

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      if (ref.current) {
        setIsOnFirstRow(ref.current.offsetTop === 0)
      }
    })
    const container = flexWrapperRef?.current
    if (container) {
      observer.observe(container)
    }
    return () => {
      if (container) {
        observer.unobserve(container)
      }
    }
  })

  return (
    <div ref={ref}>
      <FormItem label={label}>
        <DateRange
          key={`${selectedStartDate}-${selectEndDate}`} // reset the state if initial values change
          startDate={startDate}
          endDate={endDate}
          startDateId="reports-start-date"
          endDateId="reports-end-date"
          onDatesChange={datesChangeHandler}
          isOutsideRange={(day) =>
            isInclusivelyAfterDay(day, moment().add(1, 'days'))
          }
          disabled={isDisabled}
          displayFormat={() => 'DD MMM YYYY'}
          showClearDates
          focusedInput={focusedInput}
          maxDate={moment()}
          anchorDirection={isOnFirstRow ? 'right' : 'left'}
          horizontalMargin={24}
          onOutsideClick={() => {
            setStartDate(selectedStartDate ? moment(selectedStartDate) : null)
            setEndDate(selectedEndDate ? moment(selectedEndDate) : null)
          }}
          onFocusChange={setFocusedInput}
          reopenPickerOnClearDates
          keepOpenOnDateSelect
          footer={{
            onCancel: () => setFocusedInput(null),
            onApply: () => {
              applyDates(startDate?.toISOString(), endDate?.toISOString())
              setFocusedInput(null)
            },
            isApplyDisabled: !areBothDatesSelected || !hasDateChanged,
            areDefaultOptionsDisabled: areDefaultOptionsDisabled,
            applyDefaultOption: (start, end) => {
              setStartDate(start)
              setEndDate(end)
            },
          }}
          {...rest}
        />
      </FormItem>
    </div>
  )
}

export default DateRangePicker
