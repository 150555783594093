import HeaderBanner from 'common/components/headerBanner'
import { useAppSelector } from 'common/hooks/redux'

const HomepageBanner = () => {
  const firstName = useAppSelector((state) => state.authStates.firstName)

  return (
    <HeaderBanner
      title={
        <>
          Hey {firstName}
          <br className="hidden sm:block" /> welcome back to Jacquard.
        </>
      }
      data-testid="homepage-banner"
    />
  )
}

export default HomepageBanner
