import { SplitSizeData } from 'workflow/interface'

import { DropdownType } from 'common/components/dynamicFields/interfaces'
import { SplitCalculatorConfiguration } from 'features/unifiedFlow/api'

import { TemplateInfoWithInternalId } from '../addedTemplates/AddedComponentList'

import Fixed from './versions/Fixed'
import MetricSelection from './versions/MetricSelection'
import Standard from './versions/Standard'
import VariantDropdown from './versions/VariantDropdown'

type Props = {
  configuration: SplitCalculatorConfiguration['splitCalculation']
  template?: TemplateInfoWithInternalId
  variantDropdownConfiguration?: {
    initialValue?: number
    options?: DropdownType['options']
  }
  isDisabled?: boolean
  splitCalculationResult?: SplitSizeData
  onLoading?: (isLoading: boolean) => void
  onResult?: (result?: SplitSizeData) => void
  resetCalculatorCallback?: (callback: () => void) => void
}

const SplitCalculator = ({
  template,
  configuration,
  variantDropdownConfiguration,
  splitCalculationResult,
  onLoading,
  onResult,
  resetCalculatorCallback,
  isDisabled,
}: Props) => {
  switch (configuration.type) {
    case 'metric_selection':
      return (
        <MetricSelection
          template={template}
          configuration={configuration}
          splitCalculationResult={splitCalculationResult}
          onLoading={onLoading}
          onResult={onResult}
          resetCalculatorCallback={resetCalculatorCallback}
          isDisabled={isDisabled}
        />
      )
    case 'standard':
      return (
        <Standard
          template={template}
          configuration={configuration}
          splitCalculationResult={splitCalculationResult}
          onLoading={onLoading}
          onResult={onResult}
          resetCalculatorCallback={resetCalculatorCallback}
          isDisabled={isDisabled}
        />
      )
    case 'fixed':
      return <Fixed template={template} configuration={configuration} />
    default:
      const userSelectedValue = splitCalculationResult?.split_number
      const templateScreenInitialValue =
        variantDropdownConfiguration?.initialValue

      return (
        <VariantDropdown
          initialValue={userSelectedValue || templateScreenInitialValue}
          options={variantDropdownConfiguration?.options}
          template={template}
          isDisabled={isDisabled}
        />
      )
  }
}

export default SplitCalculator
