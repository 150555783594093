import { useLayoutEffect } from 'react'

import { useAppDispatch } from 'common/hooks/redux'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { useContent } from '../../hooks'

const useElementState = () => {
  const { content } = useContent()
  const dispatch = useAppDispatch()

  useLayoutEffect(() => {
    const firstElement = content?.elements[0]
    dispatch(selectElement({ elementId: firstElement?.element_id }))

    // This hook should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useElementState
