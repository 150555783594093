import { useFlags } from 'launchdarkly-react-client-sdk'

import CampaignMetrics from './components/CampaignMetrics'
import MetricsCard from './experimentsMetrics/MetricsCard'

type Props = {
  accountId: string
}

const OptimizationMetrics = ({ accountId }: Props) => {
  const flags = useFlags()
  return (
    <div
      data-cy="optimization-metrics-widget"
      data-testid="optimization-metrics-widget"
      className="mb-6"
    >
      <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:border-r-1 border-gold-400 md:pr-6">
          <MetricsCard
            title={flags.switchToContentLibrary ? '' : 'Experiments'}
          />
        </div>
        <div className="md:border-r-1 border-gold-400 md:pr-6">
          <CampaignMetrics accountId={accountId} campaignType="broadcast" />
        </div>
        <CampaignMetrics accountId={accountId} campaignType="trigger" />
      </div>
    </div>
  )
}

export default OptimizationMetrics
