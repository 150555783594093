import Button from 'common/components/button/Button'
import Pathway from 'common/components/nodes/Pathway'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'
import { Node } from 'common/interfaces/nodes'

import NodesFilter from './filters/NodesFilter'

type Props = {
  nodes: Node[]
  selectedNodeId: string
  updateSelectedNodeId: (nodeId?: string) => void
  onAddNodeClick: () => void
}

const Header = ({
  nodes,
  selectedNodeId,
  updateSelectedNodeId,
  onAddNodeClick,
}: Props) => {
  return (
    <div className="flex justify-between items-center">
      <Pathway
        selectedNodeId={selectedNodeId}
        nodes={nodes}
        updateSelectedNodeId={updateSelectedNodeId}
      />
      <div className="flex">
        <Button
          data-cy="create-node-button"
          data-testid="create-node-button"
          aria-label="Create a new node"
          variant="primary"
          className="text-base mr-4"
          prefixIcon={<AddIcon width={24} height={24} />}
          onClick={onAddNodeClick}
        >
          Node
        </Button>
        <NodesFilter updateSelectedNodeId={updateSelectedNodeId} />
      </div>
    </div>
  )
}

export default Header
