import RadioGroup from 'common/components/radioGroup'
import { Edit, Replace } from 'common/icons'

import { useTweakVariant } from '../context/TweakVariantContext'

import Item from './Item'

const TypeSwitch = () => {
  const {
    selectedState,
    updateState,
    feedbackEditEnabled,
    feedbackRejectEnabled,
  } = useTweakVariant()

  return (
    <RadioGroup
      className="mt-6 font-normal text-base"
      name="reason_selection"
      variant="withBackground"
      radioClassName="w-1/2"
      value={selectedState}
      options={[
        ...(feedbackRejectEnabled
          ? [
              {
                label: (
                  <Item
                    data-testid="replace-type"
                    icon={Replace}
                    title="Replace"
                    isSelected={selectedState === 'replace'}
                  />
                ),
                value: 'replace',
                'data-cy': 'replace-type-select',
                'data-testid': 'replace-type',
              },
            ]
          : []),
        ...(feedbackEditEnabled
          ? [
              {
                label: (
                  <Item
                    data-testid="edit-type"
                    icon={Edit}
                    title="Edit"
                    isSelected={selectedState === 'edit'}
                  />
                ),
                value: 'edit',
                'data-cy': 'edit-type-select',
                'data-testid': 'edit-type',
              },
            ]
          : []),
      ]}
      onChange={(value) =>
        (value === 'replace' || value === 'edit') &&
        updateState({
          selectedState: value,
        })
      }
    />
  )
}

export default TypeSwitch
