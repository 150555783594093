import { ReactNode } from 'react'

import Tooltip from 'common/components/Tooltip'
import { InfoCircle } from 'common/icons'
import type { IconPropsBase } from 'common/icons/Icon'

export type InfoActionProps = {
  message: ReactNode
} & IconPropsBase

const InfoAction = ({ message, ...restProps }: InfoActionProps) => (
  <Tooltip overlay={message} overlayClassName="max-w-52">
    <div
      data-cy="widget-tooltip"
      data-testid="widget-tooltip"
      className="flex items-center"
    >
      <InfoCircle size={4} className='text-gold-700"' {...restProps} />
    </div>
  </Tooltip>
)

export default InfoAction
