import { useEffect, useState } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types'
import { Content as ContentType } from '@phrasee/phrasee-typings'
import { useQueryClient } from '@tanstack/react-query'
import CustomSwitch from 'app/router/Switch'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { errorToast } from 'common/components/toastNotification'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useWebsockets, { WsResponseBase } from 'common/hooks/useWebsockets'
import Templates from 'features/futurama/contentsPage/components/Templates'

import LoadingOverlay from '../../../common/components/LoadingOverlay'
import { contentKeys } from '../api/queryKeys'
import MainContent from '../components/MainContent'
import {
  contentPageCleanup,
  hideTemplates,
  toggleElementsDisabled,
  workflowCardClicked,
} from '../store/contentSlice'

import Header from './content/components/Header'
import IntegrationPage from './integration/IntegrationPage'
import Content from './content'
import { getAllComponentElements } from './helpers'
import { useContent } from './hooks'
import Preview from './preview'
import Workflow from './workflow'

const phrases = [
  'Preparing your content',
  'Generating ideas',
  'Crafting the perfect content',
  'Searching for that extra bit of awesomeness...',
  'Refining your masterpiece',
  'Finalizing the details',
  "Don't worry, we're almost ready to roll!",
  'Finishing the last touches...',
  'Polishing to perfection',
  "Sorry for the wait, it'll be worth it!",
]

interface WsResponse extends WsResponseBase {
  content?: ContentType
  elementId?: number
}

export const getGenerateWsTopic = (contentId: string, elementId: number) =>
  `generate/${contentId}/${elementId}`

const ContentPage = () => {
  useDocumentTitle('Create Content | Jacquard')

  const flags = useFlags()
  const queryClient = useQueryClient()
  const dispatch = useAppDispatch()

  const [nglPending, setNglPending] = useState(false)
  const {
    lastJsonMessage,
    sendJsonMessage,
    isConnectionReady,
  }: {
    lastJsonMessage: WsResponse
    sendJsonMessage: SendJsonMessage
    isConnectionReady: boolean
  } = useWebsockets()

  const { path } = useRouteMatch()
  const { content } = useContent()

  const showTemplateView = useAppSelector(
    (state) => state.content.showTemplateView
  )

  useEffect(() => {
    if (content && lastJsonMessage) {
      const elementId = Number(lastJsonMessage.elementId)
      const wsTopic = getGenerateWsTopic(content._id, elementId)

      if (lastJsonMessage.topics?.includes(wsTopic)) {
        if (lastJsonMessage.status === 'success') {
          queryClient.setQueryData(
            contentKeys.content(content._id),
            lastJsonMessage.content
          )

          sendJsonMessage({
            action: 'unsubscribe',
            topics: [wsTopic],
          })

          dispatch(toggleElementsDisabled(false))
          dispatch(
            workflowCardClicked({ elementId, shouldShowUnsavedModal: false })
          )
        }
      } else if (lastJsonMessage.status === 'error' && content) {
        queryClient.invalidateQueries(contentKeys.content(content._id))
        errorToast(lastJsonMessage.errorMessage)
        dispatch(toggleElementsDisabled(false))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage])

  useEffect(() => {
    setNglPending(false)

    if (isConnectionReady && content) {
      const allComponentElements = getAllComponentElements(content)
      allComponentElements.forEach((element) => {
        if (element.nlg_status === 'pending') {
          const wsTopic = getGenerateWsTopic(content._id, element.element_id)
          setNglPending(true)
          sendJsonMessage({
            action: 'subscribe',
            topics: [wsTopic],
          })
        }
      })
    }
  }, [content, isConnectionReady, sendJsonMessage])

  useEffect(() => {
    return () => {
      dispatch(contentPageCleanup())
    }
  }, [dispatch])

  return (
    <>
      <Header />
      <MainContent className="flex-wrap" id="main-content-page">
        <CustomSwitch>
          <Route exact path={path}>
            <LoadingOverlay
              isLoading={nglPending}
              phrases={phrases}
              title="Generating variants"
            />
            <Workflow />
            <Content />
            <Preview />
            {flags.crossChannelWorkflow && (
              <Templates
                isVisible={showTemplateView}
                onClose={() => dispatch(hideTemplates())}
                intialSelectedTab="all"
                mode="add"
              />
            )}
          </Route>
          <Route path="/content/:contentId/integration">
            <IntegrationPage />
          </Route>
        </CustomSwitch>
      </MainContent>
    </>
  )
}

export default ContentPage
