/* eslint-disable max-lines */
import { Form } from 'antd'
import type { Moment } from 'moment'
import moment from 'moment-timezone'

import Checkbox from 'common/components/checkbox'
import DatePicker from 'common/components/datePicker'
import TimePicker from 'common/components/timePicker'
import {
  DataCollection as DataCollectionIcon,
  Time as TimeIcon,
} from 'common/icons'

import SelectComponent from '../select/SelectComponent'

import { Date, Props, Time } from './interface'

import styles from 'workflow/CampaignSummary/IntegrationOptions/Integrations.module.css'

const FormItem = Form.Item

const defaultTimezone = moment.tz.guess()

export default function Scheduler({
  icon,
  title,
  startTimeTitle,
  endTimeTitle,
  isStartDateDisabled,
  isStartTimeDisabled,
  isEndDateDisabled,
  isEndTimeDisabled,
  dateFormat,
  timeFormat,
  startDate,
  startTime,
  endDate,
  endTime,
  minuteStep,
  onChange,
  integrationType,
  errorMessages,
  timeZone = defaultTimezone,
  testLength,
  autoDeploy,
  delayInDaysToRetrieveTheFinalResult,
  // This is needed in some case like responsys where if we retrieve the result
  // to early it then fail and prevent the flow to work fine
  delayInHourToRetrieveSplitResult,
}: Props) {
  const disableDate = ({
    current,
    startDate,
    compareStartDate = false,
    timeZone,
    delayInDaysToRetrieveTheFinalResult = 0,
  }): boolean => {
    let dateToCompare = moment().tz(timeZone).format('YYYY-MM-DD')
    if (compareStartDate) {
      dateToCompare = moment(startDate).format('YYYY-MM-DD')
    }
    if (delayInDaysToRetrieveTheFinalResult) {
      return (
        current &&
        moment(current)
          .subtract(delayInDaysToRetrieveTheFinalResult, 'days')
          .format('YYYY-MM-DD') < dateToCompare
      )
    } else {
      return current && moment(current).format('YYYY-MM-DD') < dateToCompare
    }
  }

  const getDisabledHoursArray = (
    dateFormatted: string,
    timeFormatted: string,
    extraTime: number = 3600
  ): number[] => {
    const hourIncludingExtraTime = moment(`${dateFormatted} ${timeFormatted}`)
      .add(extraTime, 'seconds')
      .hour()
    // if selected date is in the future, do not disable hours.
    return Array.from(Array(hourIncludingExtraTime).keys())
  }

  const disableStartHours = ({ startDate, timeZone }): Array<number> => {
    if (
      timeZone &&
      startDate &&
      !moment(startDate).isSame(moment().tz(timeZone), 'day') &&
      moment(startDate).format() > moment().tz(timeZone).format()
    ) {
      return []
    }
    if (moment(startDate).isSame(moment(), 'day')) {
      const dateFormatted = moment().tz(timeZone).format('Y-MM-DD')
      const timeFormatted = moment().tz(timeZone).format('HH:mm')
      return getDisabledHoursArray(
        dateFormatted,
        timeFormatted,
        delayInHourToRetrieveSplitResult
      )
    }
    return Array.from(Array(moment().daysInMonth() + 1).keys())
  }

  const disableStartMinutes = ({
    startDate,
    selectedHour,
    timeZone,
  }): Array<number> => {
    // If selected date is in future do not disable anything and return.
    if (
      startDate &&
      moment(startDate).format() > moment().tz(timeZone).format()
    ) {
      return []
    }
    const dateFormatted = moment().tz(timeZone).format('Y-MM-DD')
    const timeFormatted = moment().tz(timeZone).format('HH:mm')

    return getDisabledMinutesArray(selectedHour, dateFormatted, timeFormatted)
  }

  const getDisabledMinutesArray = (
    selectedHour: any,
    dateFormatted: string,
    timeFormatted: string
  ): Array<number> => {
    const extraTime = 3600
    const minutesIncludingExtraTime = moment(
      `${dateFormatted} ${timeFormatted}`
    )
      .add(extraTime, 'seconds')
      .minutes()
    const nowHourIncludingTimeZoneAndExtraTime = moment(
      `${dateFormatted} ${timeFormatted}`
    )
      .add(extraTime, 'seconds')
      .hour()
    if (
      selectedHour &&
      selectedHour - nowHourIncludingTimeZoneAndExtraTime >= 1
    ) {
      return []
    }
    // If NOT, figure out the UI.
    return Array.from(Array(minutesIncludingExtraTime).keys())
  }

  const disableEndHours = ({
    startTime,
    endDate,
    startDate,
    delayInDaysToRetrieveTheFinalResult = 0,
  }): Array<number> => {
    const dateFormatted = startTime.format('Y-MM-DD')
    const timeFormatted = startTime.format('HH:mm')
    if (
      startDate.isSame(endDate, 'day') ||
      (delayInDaysToRetrieveTheFinalResult &&
        startDate.diff(endDate, 'day') === -delayInDaysToRetrieveTheFinalResult)
    ) {
      return getDisabledHoursArray(dateFormatted, timeFormatted)
    }
    return []
  }

  const disableEndMinutes = ({
    selectedHour,
    startDate,
    startTime,
    endDate,
  }): Array<number> => {
    const dateFormatted = startDate.format('Y-MM-DD')
    const timeFormatted = startTime.format('HH:mm')
    // If selected date is in future do not disable anything and return.
    if (startDate.isSame(endDate, 'day')) {
      return getDisabledMinutesArray(selectedHour, dateFormatted, timeFormatted)
    }
    return []
  }

  return (
    <div className="mt-6 mb-4 bg-coolGray-50 p-4 border border-gray-200">
      <div className="flex text-lg items-center mb-4">
        {icon ? icon : <DataCollectionIcon />}
        <div className="font-bold ml-4">{title}</div>
      </div>
      <FormItem
        label={startTimeTitle}
        className="error"
        help={errorMessages?.startDate || errorMessages?.startTime}
      >
        <div className="flex justify-between">
          <DatePicker
            id="date-picker"
            numberOfMonths={1}
            verticalHeight={372}
            isTodayVisible={true}
            date={startDate}
            showClearDate={false}
            disabled={isStartDateDisabled}
            data-testid="startDateTestId"
            onDateChange={(val: Moment | null) => onChange(val, 'startDate')}
            isOutsideRange={(current: Moment | null): boolean =>
              disableDate({
                current,
                startDate,
                compareStartDate: false,
                timeZone,
              })
            }
          />
          <TimePicker
            value={startTime}
            allowClear={false}
            format={timeFormat}
            minuteStep={minuteStep}
            style={{ width: '100%' }}
            disabled={isStartTimeDisabled}
            data-testid="startTimeTestId"
            className={styles.integrationTimePicker}
            onChange={(val: Time) => onChange(val, 'startTime')}
            disabledHours={() => disableStartHours({ startDate, timeZone })}
            disabledMinutes={(selectedHour: number) =>
              disableStartMinutes({ selectedHour, startDate, timeZone })
            }
            suffixIcon={<TimeIcon size={6} />}
          />
        </div>
      </FormItem>

      {testLength && (
        <SelectComponent
          className="mb-6"
          dataTestId="select-test-length"
          isSelectDisabled={testLength.isDisabled}
          labelTitle="How long will the test run?"
          placeholder="Select wait hours"
          selectOptions={testLength.options}
          onChange={(val) => testLength.onChange(val)}
          selectValue={testLength.value}
          errorMessage={testLength.errorMessage}
        />
      )}

      <FormItem
        label={endTimeTitle}
        className="error"
        help={errorMessages?.endDate || errorMessages?.endTime}
      >
        <div className="flex justify-between">
          <DatePicker
            id="date-picker"
            numberOfMonths={1}
            verticalHeight={372}
            isTodayVisible={true}
            date={endDate as any}
            showClearDate={false}
            disabled={isEndDateDisabled}
            data-testid="endDateTestId"
            onDateChange={(val: Date) => onChange(val, 'endDate')}
            isOutsideRange={(current: Moment | null): boolean =>
              disableDate({
                current,
                startDate,
                compareStartDate: true,
                timeZone,
                delayInDaysToRetrieveTheFinalResult,
              })
            }
          />
          <TimePicker
            value={endTime}
            allowClear={false}
            format={timeFormat}
            minuteStep={minuteStep}
            style={{ width: '100%' }}
            disabled={isEndTimeDisabled}
            data-testid="endTimeTestId"
            className={styles.integrationTimePicker}
            onChange={(val: Time) => val && onChange(val, 'endTime')}
            disabledHours={() =>
              disableEndHours({
                startTime,
                endDate,
                startDate,
                delayInDaysToRetrieveTheFinalResult,
              })
            }
            disabledMinutes={(selectedHour) =>
              disableEndMinutes({
                selectedHour,
                startDate,
                startTime,
                endDate,
              })
            }
            suffixIcon={<TimeIcon size={6} />}
          />
        </div>
      </FormItem>

      {timeZone && integrationType ? (
        <>
          <div className="mb-2 font-medium">Account Timezone: {timeZone}</div>
          <div className="mb-4 text-coolGray-400">
            {'Input your experiment time details based on your '}
            {integrationType}
            {' Account timezone.'}
          </div>
        </>
      ) : undefined}

      {autoDeploy && (
        <Checkbox
          className="mt-6"
          data-testid="auto-deploy"
          data-cy="auto-deploy-checkbox"
          label="Automatically deploy the winner?"
          isChecked={autoDeploy.checked}
          isDisabled={autoDeploy.isDisabled}
          onChange={autoDeploy.onChange}
        />
      )}
    </div>
  )
}
