import Button from 'common/components/button'
import EmptyState from 'common/components/emptyState'
import PageHeader from 'common/components/PageHeader'
import PageTitle from 'common/components/PageTitle'
import { errorToast } from 'common/components/toastNotification'

const ProductCatalog = () => {
  return (
    <div className="flex flex-col gap-12">
      <PageHeader>
        <div className="flex justify-between flex-1">
          <div className="pt-9">
            <PageTitle title="Product catalogue" />
            <div className="text-coolGray-400">
              Configure the below settings to let Jacquard know what we should
              or should not use in the generation of your content.
            </div>
          </div>
        </div>
      </PageHeader>
      <EmptyState
        title="Start creating your first record"
        description="We don’t have any records stored. "
        action={
          <Button
            variant="primary"
            onClick={() => {
              errorToast('Not implemented')
            }}
          >
            Create record
          </Button>
        }
      />
    </div>
  )
}

export default ProductCatalog
