import { Node } from '../../interfaces/nodes'

type Props = {
  nodes: Node[]
  selectedNodeId: string
  updateSelectedNodeId: (nodeId?: string) => void
}

export const buildBreadcrumbs = (nodes: Node[], selectedNodeId: string) => {
  const breadcrumbs: Node[] = []
  let currentNode = nodes.find((node) => node.id === selectedNodeId)

  while (currentNode) {
    breadcrumbs.push(currentNode)

    const parent = currentNode?.parent
    currentNode = nodes.find((node) => node.id === parent)
  }

  const list = breadcrumbs.reverse()

  const breadcrumbsString = list.reduce((acc, node, index) => {
    return index === 0 ? node.name : `${acc} / ${node.name}`
  }, '')

  return { breadcrumbsString, breadcrumbsList: list }
}

const Breadcrumbs = ({
  nodes,
  selectedNodeId,
  updateSelectedNodeId,
}: Props) => {
  const { breadcrumbsList } = buildBreadcrumbs(nodes, selectedNodeId)

  return (
    <div
      data-cy="node-breadcrumbs"
      data-testid="node-breadcrumbs"
      className="text-coolGray-800 font-medium text-xl cursor-pointer"
    >
      {breadcrumbsList.map((breadcrumb, index) => {
        return (
          <span
            key={index}
            data-cy={`breadcrumb-${breadcrumb.name}`}
            data-testid={`breadcrumb-${breadcrumb.name}`}
            onClick={() => updateSelectedNodeId(breadcrumb.id)}
          >
            {breadcrumb.name}
            {index !== breadcrumbsList.length - 1 && <span> / </span>}
          </span>
        )
      })}
    </div>
  )
}

export default Breadcrumbs
