import cx from 'classnames'

type Props = {
  isVisible?: boolean
  children: React.ReactNode
  onOutsideClick?: () => void
}

const LeftPane = ({ isVisible, children, onOutsideClick }: Props) => {
  return (
    <div
      className={cx('md:flex ', {
        hidden: isVisible === false,
      })}
    >
      <div
        className={cx(
          'z-60 fixed right-0 w-full h-full bg-transparent md:hidden',
          {
            'pointer-events-none': !isVisible,
          }
        )}
        onClick={onOutsideClick}
      />
      <div
        className={cx(
          'z-60 fixed left-0 h-full shadow-xl shadow-offset-right md:shadow-none md:static md:flex flex-col bg-gold-40 w-90 px-6 pt-6',
          {
            flex: isVisible,
            hidden: isVisible === false,
            'hidden md:flex': isVisible === undefined,
          }
        )}
      >
        {children}
      </div>
    </div>
  )
}

export default LeftPane
