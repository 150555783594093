import Button from 'common/components/button/Button'

type Props = {
  disabled: boolean
  onCancel: () => void
  onAdd: () => void
}

const ImagesGalleryModalFooter = ({ onCancel, onAdd, disabled }: Props) => {
  return (
    <div className="w-full flex justify-end mt-8">
      <Button
        variant="link"
        className="uppercase underline text-gold-600"
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        variant="primary"
        className="uppercase ml-4"
        onClick={onAdd}
        disabled={disabled}
      >
        Add
      </Button>
    </div>
  )
}

export default ImagesGalleryModalFooter
