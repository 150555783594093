import { generatePath, Redirect, Route } from 'react-router-dom'
import Content from 'app/Content'
import {
  reportsDashboardPath,
  reportsInsightsPath,
  reportsPath,
  reportsPerformancePath,
  reportsPersonalizationPath,
} from 'app/navigation/paths'
import CustomSwitch from 'app/router/Switch'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useAppSelector } from 'common/hooks/redux'
import Personalization from 'features/personalization'

import Dashboard from './dashboard/Dashboard'
import Insights from './insights/Insights'
import Performance from './performance/Performance'
const Reports = () => {
  const flags = useFlags()

  const accountId = useAppSelector((state) => state.authStates.accountId)

  const performanceSubroute = generatePath(reportsPerformancePath, {
    accountId,
  })
  const insightsSubroute = generatePath(reportsInsightsPath, {
    accountId,
  })
  const personalizationSubroute = generatePath(reportsPersonalizationPath, {
    accountId,
  })

  const dashboardSubroute = generatePath(reportsDashboardPath, {
    accountId,
  })

  return (
    <CustomSwitch>
      <Route path={reportsPerformancePath}>
        <Content>
          <Performance />
        </Content>
      </Route>
      <Route path={reportsInsightsPath}>
        <Content>
          <Insights />
        </Content>
      </Route>
      <Route path={reportsPersonalizationPath}>
        <Personalization />
      </Route>
      <Redirect exact from={reportsPath} to={performanceSubroute} />
      <Redirect exact from="/reports" to={performanceSubroute} />
      <Redirect exact from="/reports/performance" to={performanceSubroute} />
      <Redirect exact from="/reports/insights" to={insightsSubroute} />
      <Redirect
        exact
        from="/reports/personalization"
        to={personalizationSubroute}
      />
      {flags.showReportingV1 && (
        <>
          <Route path={reportsDashboardPath}>
            <Dashboard />
          </Route>
          <Redirect exact from="/reports/dashboard" to={dashboardSubroute} />
        </>
      )}
    </CustomSwitch>
  )
}

export default Reports
