import isArray from 'lodash/isArray'

import { connectInternalApi, restApi } from 'common/api'
import { FieldType } from 'common/components/dynamicFields/interfaces'
import { ConfigurationState } from 'common/components/topics/components/nodeConfiguration/interfaces'
import { parseReplacer } from 'common/components/topics/helpers'
import { Topic } from 'common/components/topics/interfaces'
import { Replacer } from 'common/components/topics/interfaces'

interface ReplacerResponse {
  data: Replacer[]
  totalCount: number
}

export const getReplacerValue = async (
  request: ConfigurationState<FieldType>,
  replacerId: string,
  campaignId?: string
): Promise<string> => {
  const updatedRequest = request.map((item) => ({
    ...item,
    value: isArray(item.value) ? item.value.join(';') : item.value,
  }))

  // TODO: Use the gateway API once it's ready
  const result = await restApi.post<{ data: { value: string } }>(
    `campaigns/language/replacers/${replacerId}/value`,
    updatedRequest,
    { params: { campaign_id: campaignId } }
  )

  return result.data.data.value
}

export const getReplacers = async (nodeIds: string[]): Promise<Topic[]> => {
  const response = await connectInternalApi.get<ReplacerResponse>(
    `v1/language/content-updates/3/replacers?includeAncestry=true&node_ids=${nodeIds}`
  )

  const result = response.data.data
    .filter(({ replacerDefinition }) => Object.keys(replacerDefinition).length)
    .map((replacer) => parseReplacer(replacer))

  return result
}
