import { useEffect, useState } from 'react'

import BarGraph, { BarData } from 'common/components/BarGraph'
import * as GraphWidget from 'common/components/GraphWidget'
import InfoAction from 'common/components/InfoAction'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import ChartWidgetMenu from 'common/components/widget/menu/ChartWidgetMenu'
import { WidgetError, WidgetFirstUse } from 'common/components/WidgetMessage'
import barChartColors from 'common/helpers/ui/barChartColors'
import { useChartRefCallback } from 'common/hooks/useChartRefCallback'

import { fetchEmojiPerformanceReport } from '../api'
import { WidgetProps } from '../interfaces'

const EmojiPerformanceWidget = ({
  accountId,
  projectIds,
  startDate,
  endDate,
}: WidgetProps) => {
  const [results, setResults] = useState<BarData[]>([])

  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { chart, chartRefCallback } = useChartRefCallback()

  useEffect(() => {
    setResults([])
    setIsLoading(true)
    setHasError(false)

    fetchEmojiPerformanceReport({
      accountId,
      projectIds,
      startDate,
      endDate,
      reportingLevel: 'project',
    })
      .then((graphResponse) => {
        const graphData = graphResponse.data.data

        if (graphData === undefined || graphData.length === 0) {
          setResults([])
        } else {
          const data = graphData[0].values
            .find(({ name }) => name.toLowerCase() === 'average')
            ?.values.slice(0, 12) // display first 12 emojis in graph

          const dataWithColors = data?.map(({ value, label }, index) => ({
            color: barChartColors[index],
            name: label,
            value,
          }))

          if (dataWithColors) {
            setResults(dataWithColors)
          }
        }
      })
      .catch(() => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [accountId, projectIds, startDate, endDate])

  const title = 'Emoji performance'

  const getState = () => {
    if (isLoading) {
      return 'loading'
    } else if (hasError) {
      return 'error'
    } else if (!results.length) {
      return 'firstUse'
    } else if (results && results.length > 0) {
      return 'results'
    } else {
      return 'default'
    }
  }

  const state = getState()

  if (state === 'default') {
    return null
  }

  return (
    <GraphWidget.Widget
      type="inner"
      title={
        <div>
          <div className="text-coolGray-800 font-medium text-xl mb-4 mr-4">
            {title}
          </div>
          <p className="text-coolGray-500 text-sm font-normal">
            The most frequently tested emoji for this time period, plotted
            against their relative performance.
          </p>
        </div>
      }
      actions={
        state === 'results' && (
          <div className="inline-flex">
            <ChartWidgetMenu
              chart={chart}
              title={title}
              problematicExports={['pdf']}
            />
            <InfoAction
              message="The higher the bar is above zero, the more frequently the emoji 
              is found in high-performing language. Which emoji is your audience loving at the moment?"
            />
          </div>
        )
      }
    >
      {
        {
          error: <WidgetError className="mb-6" />,
          firstUse: (
            <WidgetFirstUse
              className="mb-6"
              text="Uh oh, it appears you have not tested any emojis in your experiment for the selected time period. Emojis can be a great way to increase diversity - try it out!"
            />
          ),
          loading: (
            <WidgetLoader
              data-cy="react-performance-widget-loader"
              data-testid="react-performance-widget-loader"
            />
          ),
          results: (
            <BarGraph
              data={results}
              ref={chartRefCallback}
              labelFontSize="24px"
            />
          ),
        }[state]
      }
    </GraphWidget.Widget>
  )
}

export default EmojiPerformanceWidget
