import { UpdateAccountQuery } from '@phrasee/phrasee-typings/typings/integrations/accounts'
import { ListEmailTemplatesResponse } from '@phrasee/phrasee-typings/typings/integrations/templates'
import { useInfiniteQuery } from '@tanstack/react-query'

import { getListEmailTemplates } from '../api'
import { integrationAssetsKeys } from '../queryKeys'

const useGetListEmailTemplatesInfiniteQuery = ({
  stackOneAccountId,
}: UpdateAccountQuery) => {
  const { data, ...rest } = useInfiniteQuery<ListEmailTemplatesResponse>(
    integrationAssetsKeys.listEmailTemplates(stackOneAccountId!),
    ({ pageParam = undefined }) =>
      getListEmailTemplates({
        stackOneAccountId: stackOneAccountId!,
        next: pageParam,
      }),
    {
      enabled: !!stackOneAccountId,
      getNextPageParam: (lastPage) => lastPage.next || undefined,
    }
  )

  return {
    data:
      data?.pages
        .flatMap((page) => page.data)
        .map(({ name, id }) => {
          return {
            label: name,
            value: id,
          }
        }) || [],
    ...rest,
  }
}

export default useGetListEmailTemplatesInfiniteQuery
