import { useState } from 'react'

import Widget from 'common/components/Widget'

import IntegrationDetails from './IntegrationDetails'
import IntegrationForm from './IntegrationForm'

type Props = {
  name: string
  logoUrl?: string
  isLoading?: boolean
  onSubmit?: (label: string, onClose: () => void) => void
}

const VIEWS_TYPES = {
  ADD_FORM: 'integrationForm',
  DETAILS: 'integrationDetails',
} as const

type ViewType = typeof VIEWS_TYPES[keyof typeof VIEWS_TYPES]

const IntegrationCard = ({
  name,
  logoUrl,
  isLoading = false,
  onSubmit,
}: Props) => {
  const [viewType, setViewType] = useState<ViewType>(VIEWS_TYPES.DETAILS)

  const getState = () => {
    if (viewType === VIEWS_TYPES.ADD_FORM) {
      return VIEWS_TYPES.ADD_FORM
    } else {
      return VIEWS_TYPES.DETAILS
    }
  }

  const onClose = () => {
    setViewType(VIEWS_TYPES.DETAILS)
  }

  const state = getState()

  return (
    <Widget
      type="inner"
      className="border border-solid hover:border-maroon-400 h-61"
    >
      {
        {
          [VIEWS_TYPES.DETAILS]: (
            <IntegrationDetails
              logoUrl={logoUrl}
              name={name}
              onClick={() => setViewType(VIEWS_TYPES.ADD_FORM)}
              className="p-8"
            />
          ),
          [VIEWS_TYPES.ADD_FORM]: (
            <IntegrationForm
              logoUrl={logoUrl}
              name={name}
              placeholder="Add name"
              className="px-6 pb-3 pt-4"
              submitButtonText="Add integration"
              isLoading={isLoading}
              onCancel={() => setViewType(VIEWS_TYPES.DETAILS)}
              onSubmit={(label) => onSubmit?.(label, onClose)}
            />
          ),
        }[state]
      }
    </Widget>
  )
}

export default IntegrationCard
