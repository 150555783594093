import FormItem from 'common/components/formItem'

import { HeaderType } from '../interfaces'

type Props = {
  label: HeaderType['label']
  componentProps?: HeaderType['componentProps']
}

const Header = ({ label, componentProps }: Props) => {
  return <FormItem label={label} style={componentProps?.style} />
}

export default Header
