import { useEffect } from 'react'

const INTERCOM_BUTTON_HEIGHT = 48
export const INTERCOM_DEFAULT_VERTICAL_PADDING = 32

function getCtaButtonPosition(): DOMRect | undefined {
  const ctaButtons = document.querySelectorAll('.cta-button')
  const ctaButton = ctaButtons[ctaButtons.length - 1] || undefined

  return ctaButton ? ctaButton.getBoundingClientRect() : undefined
}

function updateIntercomButtonPadding(vertical_padding: number) {
  const ctaButtonPosition = getCtaButtonPosition()

  let adjustedVerticalPadding = vertical_padding

  if (ctaButtonPosition) {
    const windowHeight = window.innerHeight
    const ctaButtonBottom = ctaButtonPosition.bottom

    const bottomPadding = windowHeight - ctaButtonBottom
    if (
      bottomPadding >
      INTERCOM_BUTTON_HEIGHT + INTERCOM_DEFAULT_VERTICAL_PADDING
    ) {
      adjustedVerticalPadding = INTERCOM_DEFAULT_VERTICAL_PADDING
    } else {
      adjustedVerticalPadding =
        windowHeight - ctaButtonBottom + INTERCOM_BUTTON_HEIGHT
    }
  }

  if (window.Intercom) {
    window.Intercom('update', {
      vertical_padding: adjustedVerticalPadding,
    })
  }
}

function updateIntercomDefault() {
  if (window.Intercom) {
    window.Intercom('update', {
      vertical_padding: INTERCOM_DEFAULT_VERTICAL_PADDING,
    })
  }
}

export function useMoveIntercomButton(vertical_padding: number = 96) {
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      updateIntercomButtonPadding(vertical_padding)
    })

    observer.observe(document.body)

    return () => {
      updateIntercomDefault()
      observer.unobserve(document.body)
    }
  }, [vertical_padding])
}
