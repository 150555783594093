import includes from 'lodash/includes'
import isString from 'lodash/isString'

import { VARIANTS_CATEGORIES } from 'features/unifiedFlow/contentPage/generate/constants'

import InsightsCard from './InsightsCard'

const Content = ({
  title,
  description,
}: {
  title?: string
  description: string | JSX.Element
}) => (
  <div className="p-6">
    {!!title && (
      <h4 className="text-base-700 mb-4 uppercase font-medium">{title}</h4>
    )}
    {isString(description) ? (
      <p className="text-sm text-base-700 font-normal">{description}</p>
    ) : (
      description
    )}
  </div>
)

const Exploration = () => {
  return (
    <InsightsCard
      className="bg-gold-40 border-none"
      content={
        <Content
          title="Exploration"
          description="By testing new and diverse language, syntax and sentiments you’re more likely to find high-performing language."
        />
      }
    />
  )
}

const Performance = () => {
  return (
    <InsightsCard
      className="bg-gold-40 border-none"
      content={
        <Content
          title="Performance"
          description="In 64% of experiments the winning variant is within the lines
              predicted to perform well."
        />
      }
    />
  )
}

const Baseline = () => {
  return (
    <InsightsCard
      className="bg-gold-40 border-none"
      content={
        <Content
          title="Baseline"
          description="To avoid confirmation bias it’s important to retest variants and
            features that have performed sub-optimally in previous experiments."
        />
      }
    />
  )
}

const UserModified = () => {
  return (
    <InsightsCard
      className="bg-gold-40"
      content={
        <Content
          title="Notification"
          description="Edits can affect the predicted performance of a variant. Experiments
            are designed to minimise bias and maximise learnings."
        />
      }
    />
  )
}

interface Props {
  category?: string
}

const InsightsCategories = ({ category }: Props) => {
  const getState = () => {
    if (category && includes(Object.values(VARIANTS_CATEGORIES), category)) {
      return category
    } else {
      return 'all'
    }
  }

  const state = getState()

  return (
    <>
      {
        {
          [VARIANTS_CATEGORIES.PERFORMANCE]: <Performance />,
          [VARIANTS_CATEGORIES.EXPLORATION]: <Exploration />,
          [VARIANTS_CATEGORIES.BASELINE]: <Baseline />,
          [VARIANTS_CATEGORIES.USER_MODIFIED]: <UserModified />,
          all: (
            <>
              <Performance />
              <Exploration />
              <Baseline />
            </>
          ),
        }[state]
      }
    </>
  )
}

export default InsightsCategories
