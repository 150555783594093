import cx from 'classnames'

export type Props = { className?: string; children?: React.ReactNode }

const FormError = ({ children, className = 'mt-2' }: Props) => (
  <div
    className={cx(
      'text-RAGred capitalize-first text-sm font-normal',
      className
    )}
  >
    {children}
  </div>
)

export default FormError
