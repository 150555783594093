import { FC, useCallback, useEffect, useState } from 'react'

import { Team } from 'common/api/teamsApi'
import Modal from 'common/components/Modal'
import { useAppSelector } from 'common/hooks/redux'

import { User } from '../../all/api'

import AddTeamFormContent from './addTeamFormContent'

interface Props {
  isVisible: boolean
  onClickCreateTeam: (val: Omit<Team, 'id'>) => void
  selectedTeamId: string | undefined
  users: User[]
  onClose: () => void
}

const AddTeamModal: FC<Props> = ({
  isVisible,
  onClickCreateTeam,
  onClose,
  selectedTeamId,
  users,
}) => {
  const { teams } = useAppSelector((state) => state.adminUsers)
  const [selectedTeam, setSelectedTeam] = useState<Team>({
    id: '',
    name: '',
    members: [],
  })
  const isEditMode = selectedTeamId !== undefined

  const [name, setName] = useState<string>(selectedTeam?.name ?? '')

  const resetState = useCallback(() => {
    setName('')
    setSelectedTeam({
      id: '',
      name: '',
      members: [],
    })
  }, [setSelectedTeam])

  useEffect(() => {
    const foundTeam = teams.find((team) => team.id === selectedTeamId)
    setName(foundTeam?.name ?? '')
    setSelectedTeam(
      foundTeam ?? {
        id: '',
        name: '',
        members: [],
      }
    )
  }, [selectedTeamId, teams, isVisible])

  const handleSubmit = () => {
    onClickCreateTeam({
      members: selectedTeam?.members,
      name,
    })
    resetState()
  }

  const handleClose = () => {
    onClose()
    resetState()
  }

  return (
    <Modal
      data-cy="add-team-modal"
      data-testid="add-team-modal"
      visible={isVisible}
      closable={false}
      footer={null}
      hasMargin={false}
      centered
    >
      <AddTeamFormContent
        isEditMode={isEditMode}
        selectedTeam={selectedTeam}
        setSelectedTeam={setSelectedTeam}
        teamName={name}
        teams={teams}
        onTeamNameChange={setName}
        users={users}
        onSubmit={handleSubmit}
        onClickCancel={handleClose}
      />
    </Modal>
  )
}

export default AddTeamModal
