import cx from 'classnames'

import { Info } from 'common/icons'

import Tooltip from '../Tooltip'

import SentimentAnalysisTooltip from './SentimentAnalysisTooltip'

export type TooltipPositions =
  | { [key: string]: { top: string; left: string } }
  | undefined

interface Props {
  tooltipPositions: TooltipPositions
  categories: string[]
  onClickSentiment?: (sentiment: string) => void
}

const SentimentAnalysisChartTooltips = ({
  tooltipPositions,
  categories,
  onClickSentiment,
}: Props) => (
  <>
    {categories.map((category) => {
      return (
        <span
          className={cx('absolute z-10', {
            hidden: tooltipPositions === undefined,
          })}
          style={{ ...tooltipPositions?.[category] }}
          key={category}
          onClick={() => onClickSentiment && onClickSentiment(category)}
        >
          <Tooltip overlay={categoriesWithTooltip[category]}>
            <span>
              <Info size={4} className="opacity-50 text-coolGray-400 mb-1" />
            </span>
          </Tooltip>
        </span>
      )
    })}
  </>
)

export default SentimentAnalysisChartTooltips

export const categoriesWithTooltip = {
  Impressed: (
    <SentimentAnalysisTooltip
      title="Impressed"
      sentimentFeatures={[
        'Emphatic adjectives around products or promotions (e.g. amazing prices)',
        'Positive direct address (e.g. 20% off because you’re awesome)',
        'Capitalisation',
        'Exclamative phrases (e.g. OH WOW!)',
      ]}
    />
  ),
  Helpful: (
    <SentimentAnalysisTooltip
      title="Helpful"
      sentimentFeatures={[
        'Informational or instructional (e.g. save on your trip)',
        'Practical and direct language (e.g. we’re here to help)',
        'Focus on benefit to the recipient (e.g. treat yourself)',
      ]}
    />
  ),
  Curious: (
    <SentimentAnalysisTooltip
      title="Curious"
      sentimentFeatures={[
        'Questions (e.g. have you heard?)',
        'Use of ellipses and hanging sentences (e.g. too hot to miss…)',
        'Intrigue over directness and specificity (e.g. we think you’ll like this) ',
        'Use of social proof (e.g. find out why everyone’s talking about…) ',
      ]}
    />
  ),
  Excited: (
    <SentimentAnalysisTooltip
      title="Excited"
      sentimentFeatures={[
        'Capitalisation',
        'Exclamation marks',
        'Enthusiastic language (e.g. we’ve got some amazing news)',
        'Building anticipation (e.g. the countdown is on…)',
      ]}
    />
  ),
  Surprising: (
    <SentimentAnalysisTooltip
      title="Surprising"
      sentimentFeatures={[
        'Mystery and intrigue (e.g. uncover today’s discount…)',
        'Hyperbole (e.g. unbelievable products inside)',
        'Colloquial exclamations (e.g. omg!)',
      ]}
    />
  ),
  Appreciative: (
    <SentimentAnalysisTooltip
      title="Appreciative"
      sentimentFeatures={[
        'Politeness strategies (e.g. a little thanks from us)',
        'Assumption of customer satisfaction (e.g. no need to thank us)',
        'A “you’ve earned it” tone (e.g. you deserve a treat)',
      ]}
    />
  ),
  Urgent: (
    <SentimentAnalysisTooltip
      title="Urgent"
      sentimentFeatures={[
        'Promo duration specifics (ends in 2 days)',
        'Limited-time language (save before it’s too late)',
        'Limited-range language (save on shoes before they’re gone!)',
        'Use of exclamation marks',
      ]}
    />
  ),
} as const
