import { useEffect, useState } from 'react'
import { gql } from 'graphql-request'

import { fetchGraphQL } from 'common/api'
import TextLoader from 'common/components/loaders/TextLoader'
import { WidgetError } from 'common/components/WidgetMessage'
import { ResponseVariantStatus } from 'common/interfaces/campaign'
import Link from 'common/typography/Link'

interface ResponseVariant {
  banditStatus: {
    status: ResponseVariantStatus
  }
}

interface Results {
  pending: number
  dropped: number
  live: number
}

interface Props {
  campaignId: string
}

const initalResults = { pending: 0, dropped: 0, live: 0 }

const ReactVariantsWidget = ({ campaignId }: Props) => {
  const [results, setResults] = useState<Results>(initalResults)
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchData = function (
      campaignId: string
    ): Promise<ResponseVariant[]> {
      const query = gql`
        query GetVariants {
          data: variants(
            filter: { campaign_id : "${campaignId}"}
          ) {
            banditStatus: bandit_status {
              status
            }
          }
        }
      `
      return fetchGraphQL<ResponseVariant[]>({ query })
    }

    setHasError(false)
    setIsLoading(true)

    fetchData(campaignId)
      .then((response) => {
        const results = { ...initalResults }

        response.forEach(({ banditStatus }) => {
          const availableStatuses = ['pending', 'dropped', 'live']
          const { status } = banditStatus

          if (availableStatuses.includes(status)) {
            results[status] = results[status] + 1
          }
        })

        setResults(results)
      })
      .catch(() => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [campaignId])

  const titleClass = 'text-coolGray-800 font-bold'
  const descriptionClass = 'mb-6 text-coolGray-500'

  const link = `/campaigns/${campaignId}`

  return (
    <>
      <div
        className="sm:ml-12 sm:w-69"
        data-cy="react-variants-widget"
        data-testid="react-variants-widget"
      >
        {isLoading && (
          <div data-testid="loader">
            <TextLoader />
          </div>
        )}
        {hasError && <WidgetError className="mb-6" />}

        {results.pending ? (
          <>
            <div className="text-red-400 font-bold">
              Language approval needed!
            </div>
            <div
              className={descriptionClass}
              data-cy="react-variants-widget-pending"
              data-testid="react-variants-widget-pending"
            >
              {`${
                results.pending === 1
                  ? 'You have  1 new variant that needs your approval.'
                  : `You have ${results.pending} new variants 
                  that need your approval.`
              }`}
              <Link to={link} className="text-maroon-400">
                Approve
              </Link>
            </div>
          </>
        ) : undefined}

        {results.live ? (
          <>
            <div className={titleClass}>Live Variants</div>
            <div
              className={descriptionClass}
              data-cy="react-variants-widget-live"
              data-testid="react-variants-widget-live"
            >
              {`${
                results.live === 1
                  ? 'You currently have 1 variant live.'
                  : `You currently have ${results.live} variations competing.`
              }`}
              <Link to={link} className="text-maroon-400">
                View now
              </Link>
            </div>
          </>
        ) : undefined}

        {results.dropped ? (
          <>
            <div className={titleClass}>Variants dropped</div>
            <div
              className={descriptionClass}
              data-cy="react-variants-widget-dropped"
              data-testid="react-variants-widget-dropped"
            >
              {results.dropped === 1
                ? '1 variant was dropped recently due to low performance.'
                : `${results.dropped} variants were 
                dropped recently due to low performance.`}
              <Link to={link} className="text-maroon-400">
                View now
              </Link>
            </div>
          </>
        ) : undefined}
      </div>
    </>
  )
}

export default ReactVariantsWidget
