import {
  Menu,
  MenuButton,
  MenuHeader,
  MenuItem as MenuItemRM,
} from '@szhsin/react-menu'
import cx from 'classnames'

import Tooltip from '../Tooltip'

import { TableData, TableProps } from './types/interfaces'
import Resizer from './Resizer'
import SortIcon from './SortIcon'

type Props<T extends TableData> = Pick<
  TableProps<T>,
  'headerClassName' | 'onSwapColumns' | 'columns' | 'dataCy' | 'headerGroups'
> & {
  setHoveredColumnIndex: (index?: number) => void
}

const TableHeader = <T extends TableData>({
  headerGroups,
  dataCy,
  columns,
  onSwapColumns,
  headerClassName,
  setHoveredColumnIndex,
}: Props<T>) => {
  const canSwapColumns = onSwapColumns !== undefined

  const tableHasSubColumns = headerGroups && headerGroups.length > 1

  const columnPadding = 'px-4 py-5 first:pl-6 last:pr-6'

  const hasColumnAction = columns.some((column) =>
    column.hasOwnProperty('columnAction')
  )

  const menuOptions = (columnId: string) => [
    <MenuHeader
      className="text-left normal-case text-base-700 text-sm py-2"
      key="columnTitle"
    >
      Column title
    </MenuHeader>,
    ...columns
      .filter(({ accessor, isEditable }) => accessor !== columnId && isEditable)
      .map(({ Header, accessor: targetColumnId }, index) => (
        <MenuItemRM
          key={Header?.toString() || index}
          className="font-normal hover:bg-gold-300 py-2"
          onClick={() => {
            onSwapColumns?.({
              sourceColumnId: columnId,
              targetColumnId: targetColumnId?.toString() ?? '', // TODO change the ?? ''
            })
          }}
        >
          {Header}
        </MenuItemRM>
      )),
  ]

  return (
    <div data-cy={`${dataCy}-header`} data-testid="table-header">
      {headerGroups.map((group) => (
        <div
          {...group.getHeaderGroupProps()}
          data-cy={`${dataCy}-header-row`}
          data-testid="table-header-row"
          key={group.id}
        >
          {group.headers.map((column, index) => {
            const {
              canSort,
              parent,
              columns,
              align,
              isSorted,
              isSortedDesc,
              isResizable,
              placeholderOf,
              getResizerProps,
              isEditable,
              isDisabled,
              columnAction,
            } = column

            const canShowColumnAction =
              hasColumnAction && !(onSwapColumns !== undefined && isEditable)

            const columnHeader = column.render('Header')

            const childrenCount = columns?.length
            const hasSubcolumns = childrenCount && childrenCount > 1
            const isSubcolumn = !!parent
            const isPlaceHolder = !!placeholderOf
            const sortedDirection = isSorted
              ? isSortedDesc
                ? 'desc'
                : 'asc'
              : undefined

            return (
              <div
                {...column.getHeaderProps()}
                className={cx(
                  'group relative font-medium',
                  {
                    'flex-none w-min': column.id === 'selection',
                    'text-center': hasSubcolumns,
                    [columnPadding]: !hasSubcolumns && !isPlaceHolder,
                    'pb-8':
                      tableHasSubColumns &&
                      !isSubcolumn &&
                      !hasSubcolumns &&
                      !isPlaceHolder,
                    'top-3': hasSubcolumns || isSubcolumn,
                    'opacity-50': isDisabled,
                    flex: canShowColumnAction,
                    'bg-gold-50': !headerClassName?.includes('bg-'),
                    'text-gold-700': !headerClassName?.includes('text-'),
                  },
                  column?.headerClassName,
                  headerClassName
                )}
                key={`${column.id}-${index}`}
                onMouseOver={() => setHoveredColumnIndex(index)}
                onMouseOut={() => setHoveredColumnIndex(undefined)}
              >
                <div
                  className={cx(
                    'font-medium flex items-center uppercase',
                    {
                      'justify-center': align === 'center',
                      'justify-end': align === 'right',
                      'relative top-3 z-10': hasSubcolumns,
                      'min-w-0': hasColumnAction,
                    },
                    column.className
                  )}
                  data-cy={`${dataCy}-header-cell`}
                  data-testid={`table-header-cell-${column.id}`}
                >
                  {canSort && (isSubcolumn || align === 'right') && (
                    <SortIcon
                      id={column.id}
                      className={cx('mr-2', {
                        hidden: isDisabled,
                      })}
                      sortedDirection={sortedDirection}
                      {...column.getSortByToggleProps()}
                    />
                  )}

                  {canSwapColumns && isEditable ? (
                    <div className="relative w-full">
                      <Tooltip overlay={`Swap ${columnHeader}`}>
                        <Menu
                          menuClassName="cursor-pointer"
                          menuButton={({ open }) => (
                            <MenuButton
                              className={cx(
                                'hover:bg-gold-300 whitespace-nowrap w-full',
                                'font-bold py-2 absolute left-0 outline-none px-0',
                                {
                                  'text-base-700 bg-yellow-200': open,
                                  '-top-2': !hasColumnAction,
                                  'text-right': align === 'right',
                                  'text-center': align === 'center',
                                }
                              )}
                            >
                              {columnHeader}
                            </MenuButton>
                          )}
                        >
                          {menuOptions(column.id)}
                        </Menu>
                      </Tooltip>
                    </div>
                  ) : (
                    <>
                      <div
                        className={
                          column.id !== 'selection' ? 'inline-block' : ''
                        }
                        title={
                          typeof columnHeader === 'string'
                            ? columnHeader
                            : undefined
                        }
                        style={{
                          ...(!isDisabled && { maxWidth: '80%' }),
                        }}
                      >
                        {columnHeader}
                      </div>
                      {column.canFilter ? (
                        <span className="ml-2 flex justify-center">
                          {column.render('Filter')}
                        </span>
                      ) : null}
                    </>
                  )}
                  {canShowColumnAction && columnAction}
                  {canSort && !isSubcolumn && align !== 'right' && (
                    <SortIcon
                      id={column.id}
                      className={cx('ml-2', {
                        hidden: column.isDisabled,
                      })}
                      sortedDirection={sortedDirection}
                      {...column.getSortByToggleProps()}
                    />
                  )}
                </div>
                {isResizable && <Resizer {...getResizerProps()} />}
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default TableHeader
