import Loader from 'common/components/loaders/Loader'
import { useAppSelector } from 'common/hooks/redux'

import DetailsSection from './DetailsSection'
import ExperimentSection from './ExperimentSection'
import UserSection from './UserSection'

const CampaignDetails = () => {
  const isLoadingCampaignData = useAppSelector(
    (state) => state.campaignStates.isLoadingCampaignDetails
  )

  if (isLoadingCampaignData) {
    return <Loader className="m-auto" />
  }

  return (
    <div
      className="grid gap-y-8 mx-6 p-6 bg-gold-40 rounded-sm gap-1 shadow-sm"
      role="contentinfo"
    >
      <UserSection />
      <DetailsSection />
      <ExperimentSection />
    </div>
  )
}

export default CampaignDetails
