import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu'
import cx from 'classnames'

import { Download } from 'common/icons'
import useDownloadFile, {
  FILES_TYPES,
} from 'features/unifiedFlow/contentPage/hooks/useDownloadFile'

const ExportMenuButton = () => {
  const { downloadFile } = useDownloadFile()

  return (
    <Menu
      menuClassName="cursor-pointer"
      data-testid="export-menu"
      menuButton={({ open }) => (
        <MenuButton
          aria-label="Export"
          className={cx('text-white hover:text-yellow-200 whitespace-nowrap')}
          data-testid="export-button"
        >
          <Download isDefaultColor={false} className="text-white w-6 h-6" />
        </MenuButton>
      )}
    >
      <MenuItem key="1" onClick={() => downloadFile(FILES_TYPES.CSV)}>
        Export all: CSV
      </MenuItem>
      <MenuItem key="2" onClick={() => downloadFile(FILES_TYPES.XLS)}>
        Export all: XLS
      </MenuItem>
    </Menu>
  )
}

export default ExportMenuButton
