import { useFlags } from 'launchdarkly-react-client-sdk'

import type { Props } from './FormError'
import FormError from './FormError'
import FormErrorDefault from './FormErrorDefault'

const FormErrorPerFlag = (props: Props) => {
  const flags = useFlags()

  return flags?.showL2Components ? (
    <FormError {...props} />
  ) : (
    <FormErrorDefault {...props} />
  )
}

export default FormErrorPerFlag
