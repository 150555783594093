export const contentKeys = {
  all: ['home-page'] as const,
  contentKpi: (accountId: string, isShowUnifiedFlow: boolean) =>
    ['contentKpi', accountId, isShowUnifiedFlow] as const,
  campaignMetrics: (id: string) =>
    [...contentKeys.all, 'campaingMetrics', id] as const,
  optimizationMetrics: ({
    accountId,
    dashboardMode,
    distributionType,
  }: {
    accountId: string
    dashboardMode: 'Incremental' | 'Average_uplift'
    distributionType: 'broadcast' | 'AlwaysOn'
  }) =>
    [
      ...contentKeys.all,
      'optimizationMetric',
      accountId,
      dashboardMode,
      distributionType,
    ] as const,
  recentContents: (accountId: string) =>
    [...contentKeys.all, 'recentContents', accountId] as const,
  contentLibrary: (accountId: string) =>
    [...contentKeys.all, 'contentLibrary', accountId] as const,
}

export const welcomeMessageKey = 'daily_welcome_message'
