import Button from 'common/components/button/Button'
import useGetImagesQuery from 'common/components/images/api/queries/useGetImagesQuery'
import GalleryEmptyState from 'common/components/images/galleryEmptyState'
import ImageSearchInput from 'common/components/images/imageSearchInput'
import ImageSort from 'common/components/images/imageSort'
import ImageStatusFilter from 'common/components/images/imageStatusFilter'
import Loader from 'common/components/loaders/Loader'
import { Add as AddIcon } from 'common/icons'

import ArchiveImageModal from './components/archiveImageModal'
import ImageGallery from './components/imageGallery'
import RemoveImagesButton from './components/RemoveImagesButton'
import useImageActions from './hooks/useImageActions'

type Props = {
  onClickAddImage: () => void
}

const ImageManager = ({ onClickAddImage }: Props) => {
  const { data, isLoading } = useGetImagesQuery()

  const {
    isArchiveModalVisible,
    selectedImages,
    setIsArchiveModalVisible,
    onSelectImage,
    onDeleteImage,
    onClearImages,
  } = useImageActions()

  const getState = () => {
    if (isLoading) {
      return 'loading'
    } else if (data?.data.length ?? 0 > 1) {
      return 'data'
    } else {
      return 'empty'
    }
  }

  const state = getState()

  return (
    <>
      <div className="flex justify-end items-center gap-4">
        <div className="flex justify-end items-center gap-2">
          <ImageStatusFilter />
          <ImageSort />
          <RemoveImagesButton
            isDisabled={selectedImages.length === 0}
            onClick={() => setIsArchiveModalVisible(true)}
          />
        </div>

        <div className="w-70">
          <ImageSearchInput />
        </div>

        <Button
          className="uppercase"
          variant="primary"
          prefixIcon={<AddIcon isDefaultColor={false} size={4} />}
          onClick={onClickAddImage}
        >
          Add Image
        </Button>
      </div>
      <hr className="w-full border-gold-400 my-6" />
      {
        {
          loading: (
            <div className="w-full h-full">
              <Loader />
            </div>
          ),
          data: (
            <ImageGallery
              data={data?.data ?? []}
              total={data?.total ?? 0}
              selectedImagesIds={selectedImages.map(({ id }) => id)}
              onSelectImage={onSelectImage}
              onDeleteImage={onDeleteImage}
            />
          ),
          empty: <GalleryEmptyState onClickAddImage={onClickAddImage} />,
        }[state]
      }
      <ArchiveImageModal
        selectedImages={selectedImages}
        isArchiveModalVisible={isArchiveModalVisible}
        onClearImages={onClearImages}
        onClose={() => setIsArchiveModalVisible(false)}
      />
    </>
  )
}

export default ImageManager
