import { useState } from 'react'
import cx from 'classnames'

import InfoAction from 'common/components/InfoAction'
import Loader from 'common/components/loaders/Loader'
import RadioGroup from 'common/components/radioGroup'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import { OnSwapColumns } from 'common/components/table/Table'
import { allowTabCharacter } from 'common/components/textarea/Textarea'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { ReactComponent as HashTag } from 'common/icons/hashTag/default.svg'
import { ReactComponent as Percentage } from 'common/icons/percentage/default.svg'
import { Format, ProcessedResults } from 'features/campaigns/api'

import type { Value } from '../InputResultModal'

import SplitResultsTable from './SplitResultsTable'
import ZoneButton from './ZoneButton'

const options = [
  {
    label: 'Number of recipients, opens and clicks',
    value: 'integer',
    icon: HashTag,
  },
  {
    label: 'Number of recipients, open rate and click rate (%)',
    value: 'percentage',
    icon: Percentage,
  },
]

type Props = {
  isProcessingResults: boolean
  isLoading: boolean
  modes: SelectValue[]
  selectedMode: Value
  onChangeMode: (value: Value) => void
  inputResults: string
  onChangeResults: (inputResult: string) => void
  updatedProccessedResults: ProcessedResults | undefined
  onClickRevert: ({ rowIndex }: { rowIndex: number }) => void
  onClickKeep: ({ rowIndex }: { rowIndex: number }) => void
  onChangeValue: (params: {
    name: string
    rowIndex: number
    value: number | undefined
  }) => void
  format: Format
  onChangeFormat: (format: Format) => void
  onSwapColumns: OnSwapColumns
  metrics: string[]
}
const SplitResults: React.FC<Props> = ({
  isProcessingResults,
  isLoading,
  inputResults,
  onChangeResults,
  onClickKeep,
  onClickRevert,
  onChangeValue,
  updatedProccessedResults,
  format,
  onChangeFormat,
  onSwapColumns,
  selectedMode,
  onChangeMode,
  modes,
  metrics,
}) => {
  const [hasClickedZoneButton, setHasClickedZoneButton] =
    useState<boolean>(false)
  return isLoading ? (
    <Loader />
  ) : (
    <>
      {updatedProccessedResults ? (
        <SplitResultsTable
          processedResults={updatedProccessedResults}
          onChangeValue={onChangeValue}
          onClickKeep={onClickKeep}
          onClickRevert={onClickRevert}
          onSwapColumns={onSwapColumns}
        />
      ) : (
        <>
          <h1 className="font-medium text-xl text-coolGray-800">
            Add your split results
          </h1>
          <h3 className="text-coolGray-500 mt-1">
            You can upload a .CSV file or copy and paste directly from Excel.
          </h3>
          <div className="mt-4 flex gap-8 justify-between items-center">
            <SingleSelect
              className="w-75"
              data-cy="splitResults-mode-select"
              data-testid="splitResults-mode-select"
              name="mode"
              options={modes}
              value={selectedMode}
              onChange={(val) => {
                if (val?.value) {
                  onChangeMode(val.value)
                }
              }}
            />
          </div>

          <div className="mt-8 mb-4 text-xl font-medium">
            What type of data do you have?
          </div>
          <RadioGroup
            name="format"
            onChange={(val) => {
              onChangeFormat(val === 'integer' ? 'integer' : 'percentage')
            }}
            value={format}
            options={options}
            isDisabled={inputResults.trim().length > 0}
            variant="large"
            radioClassName="w-55"
          />
          <Widget className="mt-8" type="table">
            <WidgetHeader
              className="mt-6 mx-6"
              title="Split results"
              actions={
                <div className="inline-flex">
                  <InfoAction
                    message={`Copy and paste your variant text and results in the boxes below.
                     Note, if you toggle to Numbers, you only need to paste for example, Number of Recipients and Num Unique Opens. 
                     Jacquard will calculate the rates for you. And vice versa for Percentages. 
                     You only need to paste ‘Open Rate’ and Jacquard will take care of the rest.`}
                  />
                </div>
              }
            />
            <div className="flex justify-between h-16">
              {metrics?.map((metric, index) => (
                <div
                  key={metric}
                  className={cx(
                    'border border-coolGray-200 border-t-0 flex-1 flex items-center text-coolGray-400 font-bold p-2',
                    {
                      'pl-6 border-l-0 flex-grow-2': index === 0,
                      'pr-8 grid justify-end  border-r-0':
                        index === metrics.length - 1,
                      'grid justify-center':
                        index !== 0 && index !== metrics.length - 1,
                    }
                  )}
                >
                  {metric}
                </div>
              ))}
            </div>

            <div
              style={{
                height: 350,
              }}
            >
              {!hasClickedZoneButton ? (
                <ZoneButton
                  className="py-8 h-full"
                  text="Click here and then paste your split results here."
                  onClick={() => {
                    setHasClickedZoneButton(true)
                  }}
                ></ZoneButton>
              ) : (
                <div className="flex items-stretch h-full">
                  <textarea // TODO: Use the TextArea component
                    onKeyDown={allowTabCharacter}
                    className={cx(
                      `outline-none py-5 px-6 text-sm placeholder-coolGray-300 caret-maroon-200
                           flex-1 resize-none box-border font-medium text-coolGray-400`,
                      {
                        'bg-maroon-40': isProcessingResults,
                        'bg-coolGray-50': !isProcessingResults,
                      }
                    )}
                    autoFocus
                    value={inputResults}
                    onChange={(e) => {
                      onChangeResults(e.target.value)
                    }}
                    placeholder="And paste…"
                  />
                </div>
              )}
            </div>
          </Widget>
        </>
      )}
    </>
  )
}

export default SplitResults
