import { useReducer } from 'react'
import filter from 'lodash/filter'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'

import type { ImageEntity } from 'common/components/images/api/api'

type SelectedImage = ImageEntity | undefined

export type Images =
  | [SelectedImage, SelectedImage, SelectedImage]
  | [SelectedImage, SelectedImage]

type State = {
  isImageGalleryVisible: boolean
  isVariantOnly: boolean
  previewIndex?: number
  imagesSlots: Images
  selectedImages: Images
}

const initialState: State = {
  isImageGalleryVisible: false,
  previewIndex: undefined,
  isVariantOnly: false,
  imagesSlots: [undefined, undefined, undefined],
  selectedImages: [undefined, undefined, undefined],
}

type ReducerActions =
  | { type: 'select_image'; value: { selectedImage: ImageEntity } }
  | { type: 'delete_image'; value: { index: number } }
  | { type: 'replace_image'; value: { index: number } }
  | { type: 'add_images' }
  | { type: 'toggle_variant_only'; value: { isVariantOnly?: boolean } }
  | { type: 'show_image_gallery'; value: { isImageGalleryVisible: boolean } }

const getImagesIds = (images: Images) =>
  map(
    filter(images, (img) => img),
    'id'
  )

const reducer = (state: State, action: ReducerActions) => {
  switch (action.type) {
    case 'add_images':
      return {
        ...state,
        imagesSlots: [...state.selectedImages] as Images,
        isImageGalleryVisible: false,
        selectedImages: [undefined, undefined, undefined] as Images,
        previewIndex: undefined,
      }

    case 'show_image_gallery':
      const isImageGalleryVisible = action.value.isImageGalleryVisible

      return {
        ...state,
        isImageGalleryVisible,
        selectedImages: isImageGalleryVisible
          ? ([...state.imagesSlots] as Images)
          : ([undefined, undefined, undefined] as Images),
        previewIndex: isImageGalleryVisible ? state.previewIndex : undefined,
      }

    case 'select_image':
      const newSelectedImages = [...state.selectedImages] as Images

      if (isUndefined(state.previewIndex)) {
        const existingIndex = newSelectedImages.findIndex(
          (item) => item?.id === action.value.selectedImage.id
        )

        if (existingIndex !== -1) {
          newSelectedImages[existingIndex] = undefined
        } else {
          const emptyIndex = newSelectedImages.findIndex(isUndefined)
          if (emptyIndex !== -1) {
            newSelectedImages[emptyIndex] = action.value.selectedImage
          }
        }
      } else {
        newSelectedImages[state.previewIndex] = action.value.selectedImage
      }

      return {
        ...state,
        selectedImages: newSelectedImages,
      }

    case 'delete_image':
      const imagesAfterDelete = [...state.imagesSlots] as Images
      imagesAfterDelete[action.value.index] = undefined

      return {
        ...state,
        imagesSlots: imagesAfterDelete,
      }

    case 'replace_image':
      const previewIndex = action.value.index ?? undefined

      return {
        ...state,
        previewIndex,
        isImageGalleryVisible: true,
        selectedImages: [...state.imagesSlots] as Images,
      }

    case 'toggle_variant_only':
      const isVariantOnly = action.value?.isVariantOnly ?? !state.isVariantOnly

      return {
        ...state,
        isVariantOnly,
        imagesSlots: isVariantOnly
          ? (state.imagesSlots.slice(0, 2) as Images)
          : ([...state.imagesSlots.slice(0, 2), undefined] as Images),
        selectedImages: isVariantOnly
          ? ([undefined, undefined] as Images)
          : ([undefined, undefined, undefined] as Images),
      }

    default:
      return state
  }
}

const useImagesReducer = (initializer: (arg: State) => State) => {
  const [state, dispatch] = useReducer(reducer, initialState, initializer)

  const onSelectImage = (selectedImage: ImageEntity) => {
    dispatch({ type: 'select_image', value: { selectedImage } })
  }

  const onDeleteImage = (index: number) => {
    dispatch({ type: 'delete_image', value: { index } })
  }

  const onReplaceImage = (index: number) => {
    dispatch({ type: 'replace_image', value: { index } })
  }

  const onAddImages = () => {
    dispatch({ type: 'add_images' })
  }

  const toggleVariantOnly = (isVariantOnly?: boolean) => {
    dispatch({ type: 'toggle_variant_only', value: { isVariantOnly } })
  }

  const showImageGallery = (isImageGalleryVisible: boolean) => {
    dispatch({ type: 'show_image_gallery', value: { isImageGalleryVisible } })
  }

  return {
    state: {
      ...state,
      selectedImagesIds: getImagesIds(state.selectedImages),
      imagesSlotsIds: getImagesIds(state.imagesSlots),
    },
    onSelectImage,
    onDeleteImage,
    onReplaceImage,
    onAddImages,
    toggleVariantOnly,
    showImageGallery,
  }
}

export default useImagesReducer
