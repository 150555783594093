type Props = {
  heading?: string
  subHeading?: string
}

const TableHeading = ({ heading, subHeading }: Props) => {
  if (!heading && subHeading) {
    return null
  }

  return (
    <div className="mb-9">
      <h3
        className="font-relais text-4xl text-base-700 font-semibold"
        data-testid="table-heading"
      >
        {heading}
      </h3>
      {!!subHeading && (
        <p
          className="text-base text-base-700 mt-3 font-medium"
          data-testid="table-subheading"
        >
          {subHeading}
        </p>
      )}
    </div>
  )
}
export default TableHeading
