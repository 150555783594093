import { ReactElement } from 'react'
import { MenuProps } from '@szhsin/react-menu'

import MenuButton from 'common/components/menuButton'
import { MoreOptions } from 'common/icons'

type Props = {
  name: string
  options: ReactElement
} & Partial<MenuProps>

const OptionsMenuButton = ({ name, options, ...rest }: Props) => {
  return (
    <MenuButton
      options={options}
      portal={true}
      viewScroll="auto"
      buttonProps={{
        'data-cy': 'kebab_button',
        'data-testid': 'kebab_button',
        'aria-label': name,
        children: (
          <MoreOptions
            className={`
             transform rotate-90 opacity-50
           text-coolGray-400`}
          />
        ),
      }}
      {...rest}
    />
  )
}

export default OptionsMenuButton
