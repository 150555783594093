import { FieldInputProps } from 'react-final-form'
import isString from 'lodash/isString'

import FormItem from 'common/components/formItem'
import Input from 'common/components/input'

import { CommonFieldPropsGeneric, TextType } from '../interfaces'

const ERROR_MESSAGES = {
  required: 'Please enter an answer.',
}

type Props = FieldInputProps<string, HTMLElement> &
  CommonFieldPropsGeneric & {
    value: TextType['value']
    componentProps?: TextType['componentProps']
  }

const Text = ({
  label,
  name,
  value,
  errorCode,
  tooltip,
  isDisabled,
  placeholder,
  componentProps,
  onChange,
  onBlur,
  'data-testid': dataTestId,
}: Props) => {
  const error = isString(errorCode)
    ? ERROR_MESSAGES[errorCode] || errorCode
    : undefined

  return (
    <FormItem
      label={label}
      guidingText={tooltip}
      htmlFor={name}
      error={error}
      style={componentProps?.style}
    >
      <Input
        type="text"
        id={name}
        name={name}
        data-testid={dataTestId}
        variant="default"
        placeholder={placeholder}
        disabled={isDisabled}
        onChange={(event) => onChange(event.target.value)}
        onBlur={onBlur}
        value={value}
      />
    </FormItem>
  )
}

export default Text
