import Badge from 'common/components/Badge'

type Props = {
  isRecommended: boolean
}

const RecommendedCell = ({ isRecommended }: Props) => {
  return (
    <>
      {isRecommended ? (
        <Badge
          size="small"
          variant="neutral"
          text="Recommended"
          className="flex-none"
        />
      ) : null}
    </>
  )
}

export default RecommendedCell
