import { Region } from './api'

const ROOT_KEY = 'personalizedContent'

export const personalizedContentKeys = {
  all: [ROOT_KEY] as const,
  combinations: ({
    accountId,
    personalizationId,
    region,
  }: {
    accountId: string | undefined
    personalizationId: string
    region: Region | undefined
  }) =>
    [
      ...personalizedContentKeys.all,
      accountId,
      personalizationId,
      region,
    ] as const,
  combinationVariants: ({
    accountId,
    personalizationId,
    combinationId,
    region,
  }: {
    accountId: string
    personalizationId: string
    combinationId: string
    region: Region | undefined
  }) =>
    [
      ...personalizedContentKeys.all,
      accountId,
      personalizationId,
      combinationId,
      region,
    ] as const,
}
