import { ReactNode } from 'react'
import { Field } from 'react-final-form'
import { FormatOptionLabelMeta } from 'react-select'

import FormItem from 'common/components/formItem'
import { SelectValue } from 'common/components/singleSelect'
import SingleSelect from 'common/components/singleSelect/SingleSelect'

type Props<OptionType extends SelectValue> = {
  name: string
  label: string
  options: OptionType[]
  className?: string
  isDisabled?: boolean
  formatOptionLabel?: (
    option: OptionType,
    labelMeta: FormatOptionLabelMeta<OptionType>
  ) => ReactNode
}

const SingleSelectField = <OptionType extends SelectValue>({
  name,
  label,
  options,
  className,
  isDisabled,
  formatOptionLabel,
}: Props<OptionType>) => (
  <Field<string>
    name={name}
    render={({ input, meta }) => (
      <FormItem
        label={label}
        htmlFor={name}
        className={className}
        error={meta.error}
      >
        <SingleSelect
          id={name}
          data-cy={name}
          data-testid={name}
          onChange={(val) => {
            input.onChange(val?.value)
          }}
          className="w-72"
          value={input.value}
          isSearchable={false}
          required
          isDisabled={isDisabled}
          options={options}
          // @ts-ignore
          formatOptionLabel={formatOptionLabel}
        />
      </FormItem>
    )}
  />
)

export default SingleSelectField
