import Loader from 'common/components/loaders/Loader'

const LoaderImageCard = () => {
  return (
    <div className="flex w-78 h-78 rounded-sm p-4 border-1 border-gold-200">
      <div className="flex items-center justify-center bg-gold-50 w-full h-full rounded-sm">
        <Loader />
      </div>
    </div>
  )
}

export default LoaderImageCard
