export const RESULT_TABLE_KEYS = {
  VARIANT: 'variant',
  CLICK_UPLIFT: 'click_uplift',
  OPEN_UPLIFT: 'open_uplift',
  JACQUARD_SCORE_RATE: 'phrasee_score_rate',
  JACQUARD_SCORE: 'phrasee_score',
  VARIANT_STATUS: 'variant_status',
  CONVERSIONS: 'conversions',
  IS_SIGNIFICANT: 'is_significant',
  NUM_OPENS: 'num_opens',
  NUM_MACHINE_OPENS: 'num_machine_opens',
  OPEN_RATE_EXCLUDED_MACHINE_OPENS: 'open_rate_excluded_machine_opens',
  OPEN_RATE: 'open_rate',
  OPEN_RATE_UPLIFT_MACHINE_OPENS_EXCLUDED:
    'open_rate_uplift_machine_opens_excluded',
  OWNSL: 'ownsl',
  NUM_RECIPIENTS: 'num_recipients',
  NUM_CLICKS: 'num_clicks',
  CLICK_RATE: 'click_rate',
}

export const variantStatusSelectOptions = {
  live: [
    {
      label: 'Live',
      value: 'live',
    },
    {
      label: 'Dropped',
      value: 'dropped',
    },
  ],
  approved: [
    {
      label: 'Approved',
      value: 'approved',
    },
    {
      label: 'Dropped',
      value: 'dropped',
    },
  ],
  dropped: [
    {
      label: 'Dropped',
      value: 'dropped',
    },
    {
      label: 'Approved',
      value: 'approved',
    },
  ],
  pending: [
    {
      label: 'Pending',
      value: 'pending',
    },
    {
      label: 'Approved',
      value: 'approved',
    },
    {
      label: 'Rejected',
      value: 'rejected',
    },
  ],
}
