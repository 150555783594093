import { useState } from 'react'
import { Field, useFormState } from 'react-final-form'
import { IntegrationTypeValue } from '@phrasee/phrasee-typings/typings/project/project-configuration.types'
import cx from 'classnames'
import get from 'lodash/get'
import ControlInput from 'workflow/common/controlInput'

import { TextareaType } from 'common/components/dynamicFields/interfaces'
import FormItem from 'common/components/formItem'
import { stripForbiddenCharacters } from 'common/helpers/stripForbiddenCharacters'

const countNumberOfBreakLines = (text: string) => {
  return text?.match(/\n|↵/g)?.length || 0
}

const DOUBLE_PERCENTAGE_ERROR = (
  <>
    You've included %%, please make sure your AmpScript is formatted correctly.{' '}
    <a
      href="https://help.salesforce.com/s/articleView?id=sf.mc_es_available_personalization_strings.htm&type=5"
      target="_blank"
      rel="noreferrer"
    >
      You can find available tags here.
    </a>
  </>
)

type Props = {
  name: string
  isDisabled?: boolean
  config: TextareaType
  integrationType?: IntegrationTypeValue
}

const ControlSubjectLine = ({
  name,
  config,
  integrationType,
  isDisabled,
}: Props) => {
  const [shouldShowLineBreaksWarning, setShouldShowLineBreaksWarning] =
    useState(false)
  const minRows =
    config.minMaxRows?.min && config.minMaxRows.min > 1
      ? config.minMaxRows.min
      : 1.334 // equals 38px
  const formState = useFormState()

  const checkLineBreaksWarning = (
    originalValue: string,
    maxLineBreaks: number = 0
  ) => {
    if (shouldShowLineBreaksWarning) {
      const targetValue = stripForbiddenCharacters(originalValue)

      if (countNumberOfBreakLines(targetValue) <= maxLineBreaks) {
        setShouldShowLineBreaksWarning(false)
      }
    }
  }

  const validateHumanControl = (value: string | undefined) => {
    if (!value?.trim()) {
      return 'Please enter a control.'
    }
    if (shouldShowLineBreaksWarning) {
      return " Line breaks aren't allowed for this type of content. Please edit your copy."
    }
    if (value.includes('%%') && integrationType === 'sfmcv2') {
      return DOUBLE_PERCENTAGE_ERROR
    }
  }

  //I added this additional logic because the Grammarly plugin was shifting the entire form's content on focus - HH-5075.
  const isTouched = !!formState.touched?.[name]
  const hasError = !!get(formState, `errors.${name}`, null)

  return (
    <div
      className={cx('relative min-h-24 w-full', {
        'mb-4': isTouched && hasError,
      })}
    >
      <Field<string> name={name} validate={validateHumanControl}>
        {({ input, meta }) => (
          <FormItem
            error={meta.touched ? meta.error : undefined}
            label={config.label}
            htmlFor={input.name}
            className="flex-none mb-4"
          >
            <ControlInput
              data-cy="control-subject-line-input"
              data-testid="control-subject-line-input"
              id={input.name}
              name={input.name}
              className="focus:border-maroon-400 outline-none shadow-none pt-2"
              autoSize={{ minRows }}
              maxRows={config.minMaxRows?.max || 0}
              placeholder="Enter your control"
              onBlur={input.onBlur}
              onChange={(value, originalValue) => {
                checkLineBreaksWarning(originalValue, config.minMaxRows?.max)
                input.onChange(value)
              }}
              onLineBreaksWarning={() => setShouldShowLineBreaksWarning(true)}
              value={input.value}
              disabled={isDisabled}
            />
          </FormItem>
        )}
      </Field>
    </div>
  )
}

export default ControlSubjectLine
