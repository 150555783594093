import cx from 'classnames'

import Alert from 'common/components/alert'
import { useAppSelector } from 'common/hooks/redux'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'
import { useContent } from 'features/unifiedFlow/contentPage/hooks'

import BriefCenterSection from '../../brief/centerSection/CenterSection'
import { CurrentUsersBanner } from '../../components/CurrentUsersBanner'
import CenterPanel from '../../components/layout/CenterPanel'
import { BRIEF_ELEMENT_ID } from '../../components/universalBriefElement/constants'
import { getStepStatus } from '../../helpers'
import { useSelectedElement } from '../../hooks'

import BriefAccordion from './briefAccordion/BriefAccordion'
import PersonalizedVariantsTable from './personalizeResults/PersonalizedVariantsTable'
import ContentTable from './contentTable'
import Images from './images'
import OptimizeResults from './optimizeResults'

type Props = {
  isWebsocketLoading: boolean
  isAdvancedSettingsOptionsShown?: boolean
  onClickAdvancedSettings?: () => void
}

const CenterSection = ({
  isWebsocketLoading,
  isAdvancedSettingsOptionsShown = false,
  onClickAdvancedSettings,
}: Props) => {
  const { data: selectedElement } = useSelectedElement()
  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )

  const { content } = useContent()

  const getState = () => {
    if (selectedElementId === BRIEF_ELEMENT_ID) {
      return 'universalBrief'
    } else if (!selectedElement) {
      return 'empty'
    } else if (
      isComponentElement(selectedElement) &&
      selectedElement?.personalization_id
    ) {
      return 'personalizeResults'
    } else if (
      isComponentElement(selectedElement) &&
      selectedElement?.is_image_element
    ) {
      return 'images'
    } else if (
      isComponentElement(selectedElement) &&
      selectedElement?.campaign_id
    ) {
      return 'optimizeResults'
    } else {
      return 'contentTable'
    }
  }

  const state = getState()

  const briefStepsStatus = getStepStatus(content?.steps_status, 'brief')
  const isBriefStepsStatusReadOnly = briefStepsStatus === 'read-only'

  const hasError =
    selectedElement && isComponentElement(selectedElement)
      ? selectedElement?.nlg_status === 'error'
      : false

  return (
    <CenterPanel
      data-testid="generateCenterPanel"
      className={cx('overflow-auto flex-col', { 'col-flex': hasError })}
    >
      <>
        {hasError && (
          <Alert type="error" className="mb-6" showIcon>
            Unable to generate language for this template, please try again
          </Alert>
        )}
        {
          {
            empty: null,
            universalBrief: (
              <BriefCenterSection isWebsocketLoading={isWebsocketLoading} />
            ),
            optimizeResults: (
              <div className="flex flex-col w-full">
                <CurrentUsersBanner />
                <BriefAccordion
                  isWebsocketLoading={isWebsocketLoading}
                  isAdvancedSettingsOptionsShown={
                    isAdvancedSettingsOptionsShown
                  }
                  onClickAdvancedSettings={onClickAdvancedSettings}
                />
                {!isWebsocketLoading && (
                  <OptimizeResults isReadOnly={isBriefStepsStatusReadOnly} />
                )}
              </div>
            ),
            personalizeResults: (
              <div className="flex flex-col w-full">
                <CurrentUsersBanner />
                <BriefAccordion
                  isWebsocketLoading={isWebsocketLoading}
                  isAdvancedSettingsOptionsShown={
                    isAdvancedSettingsOptionsShown
                  }
                  onClickAdvancedSettings={onClickAdvancedSettings}
                />
                {!isWebsocketLoading && (
                  <PersonalizedVariantsTable
                    isReadOnly={isBriefStepsStatusReadOnly}
                  />
                )}
              </div>
            ),
            contentTable: (
              <div className="flex flex-col w-full">
                <CurrentUsersBanner />
                <BriefAccordion
                  isWebsocketLoading={isWebsocketLoading}
                  isAdvancedSettingsOptionsShown={
                    isAdvancedSettingsOptionsShown
                  }
                  onClickAdvancedSettings={onClickAdvancedSettings}
                />
                {!isWebsocketLoading && (
                  <ContentTable isReadOnly={isBriefStepsStatusReadOnly} />
                )}
              </div>
            ),
            images: (
              <div className="flex flex-col w-full">
                <CurrentUsersBanner />
                <Images isReadOnly={isBriefStepsStatusReadOnly} />,
              </div>
            ),
          }[state]
        }
      </>
    </CenterPanel>
  )
}

export default CenterSection
