import { forwardRef } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import type { SliderInputProps } from './SliderInput'
import SliderInput from './SliderInput'
import SliderInputDefault from './SliderInputDefault'

const SliderInputPerFlag = forwardRef<HTMLInputElement, SliderInputProps>(
  (props: SliderInputProps, ref) => {
    const flags = useFlags()

    return flags?.showL2Components ? (
      <SliderInput {...props} />
    ) : (
      <SliderInputDefault {...props} />
    )
  }
)

export type { SliderInputProps }

export default SliderInputPerFlag
