type Props = {
  title: string
  description: string
  className?: string
}

const PageDetails = ({ title, description, className }: Props) => (
  <div className={className}>
    <h3 className="text-4xl font-medium text-base-700 font-relais pb-3">
      {title}
    </h3>
    <p className="text-base text-base-700 font-normal">{description}</p>
  </div>
)

export default PageDetails
