import {
  MAX_FORBIDDEN_WORDS,
  MAX_FORBIDDEN_WORDS_MESSAGE,
} from 'common/components/tags/Tags'
import TagWidget from 'common/components/widget/TagWidget/TagWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { addForbiddenWord, removeForbiddenWord } from '../store/regionsSlice'

const ForbiddenWordsWidget = () => {
  const dispatch = useAppDispatch()

  const regionEdited = useAppSelector((state) => state.regions.regionEdited)

  const forbiddenWords = regionEdited.forbiddenWords.map((word) => ({
    label: word,
    value: word,
  }))
  return (
    <TagWidget
      title="Forbidden words"
      subtitle="Enter the words that should never generate in variants for this region. Entries cannot contain spaces and should be in lower case."
      data-cy="forbidden-words-widget"
      data-testid="forbidden-words-widget"
      onInputChange={(value) => value.replaceAll(' ', '')}
      placeholder="Forbidden word..."
      tags={forbiddenWords}
      maxLengthPerTag={50}
      maxTagsNumber={MAX_FORBIDDEN_WORDS}
      maxTagsMessage={MAX_FORBIDDEN_WORDS_MESSAGE}
      onAddTag={(value: string) => {
        dispatch(addForbiddenWord(value))
      }}
      onRemoveTag={(value: string) => {
        dispatch(removeForbiddenWord(value))
      }}
    />
  )
}

export default ForbiddenWordsWidget
