import { accountSettingsPermissions } from 'common/auth/permissions'
import ApiTokens from 'features/admin/accountSettings/apiTokens'
import Integrations from 'features/admin/accountSettings/integrations'
import MergeTags from 'features/admin/accountSettings/mergeTags'
import RevenueReporting from 'features/admin/accountSettings/revenueReporting'

import { TabbedNavRoute } from './interfaces'
import {
  accountsTokensPaths,
  integrationSettingsPath,
  mergeTagsPath,
  revenueSettingsPath,
} from './paths'

export const integrationsSettingsRoute: TabbedNavRoute = {
  key: 'admin-account-integrations',
  name: 'Integrations',
  to: integrationSettingsPath,
  component: Integrations,
  accessFlags: {
    flags: ['showContentIntegrations'],
    type: 'hide',
  },
}

export const mergeTagsRoute: TabbedNavRoute = {
  key: 'admin-merge-tags',
  name: 'Merge tags',
  to: mergeTagsPath,
  component: MergeTags,
  accessFlags: {
    flags: ['showMergeTags'],
    type: 'hide',
  },
  accessPermission: accountSettingsPermissions.accountSettingsMergeTagsView,
  shouldValidateAccountAccess: true,
}

export const revenueSettingsRoute: TabbedNavRoute = {
  key: 'admin-account-revenue',
  name: 'Revenue reporting',
  to: revenueSettingsPath,
  component: RevenueReporting,
  accessFlags: {
    flags: ['showRevenueReporting'],
    type: 'hide',
  },
  accessPermission: accountSettingsPermissions.accountSettingsRevenueView,
  shouldValidateAccountAccess: true,
}

export const accountsTokensRoute: TabbedNavRoute = {
  key: 'admin-accounts-tokens',
  name: 'API Tokens',
  to: accountsTokensPaths,
  component: ApiTokens,
  accessPermission: accountSettingsPermissions.accountSettingsApiTokensView,
  shouldValidateAccountAccess: true,
}

export const accountSettingsSubroutes: TabbedNavRoute[] = [
  integrationsSettingsRoute,
  mergeTagsRoute,
  revenueSettingsRoute,
  accountsTokensRoute,
]
