import cx from 'classnames'

type Props = {
  className?: string
  text: string
  textColor?: string
  backgroundColor?: string
}

const Tag = ({
  className,
  text,
  backgroundColor = 'bg-maroon-400',
  textColor = 'text-white',
}: Props) => {
  return (
    <span
      className={cx(
        className,
        'text-sm py-1 px-2 whitespace-nowrap',
        textColor,
        backgroundColor
      )}
    >
      {text}
    </span>
  )
}

export default Tag
