import kebabCase from 'lodash/kebabCase'

import { parseFieldsConfiguration } from '../dynamicFields/helpers'

import { Node, Replacer, Topic } from './interfaces'

export const parseNode = (node: Node): Topic => {
  const id = node.nodeId
    ? `topic-${node.nodeId}`
    : `topic-${node.nodeName}-${kebabCase(node.displayName)}`
  return {
    id,
    type: 'topic',
    category: 'Topic',
    name: node.displayName,
    isConstant: node.isConstant,
    original: node,
  }
}

export const parseReplacer = (replacer: Replacer): Topic => {
  return {
    id: `replacer-${replacer.replacerId}-${kebabCase(replacer.value)}`,
    type: 'replacer',
    category: replacer.displayName || replacer.replacer,
    original: replacer,
    name: replacer.value,
    isConstant: replacer.isConstant,
    configuration: parseFieldsConfiguration(
      replacer.replacerDefinition.form_definition
    ),
  }
}
