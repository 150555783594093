import ConfirmationModal, {
  ConfirmationModalProps,
} from 'common/components/confirmationModal/ConfirmationModal'

type Props = Pick<ConfirmationModalProps, 'onCancel' | 'onConfirm'>

const DeleteConfirmationModal = ({ onCancel, onConfirm }: Props) => {
  return (
    <ConfirmationModal
      data-cy="delete-confirmation-modal"
      data-testid="delete-confirmation-modal"
      title="Are you sure you want to delete this?"
      confirmationText="Any associated results and experiments will also be deleted. Any active experiments will stop running when deleted."
      confirmButtonText="Delete"
      cancelButtonText="No, I want to keep this"
      open
      hasConfirmationSlider={false}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
}

export default DeleteConfirmationModal
