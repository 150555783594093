import { useEffect, useRef } from 'react'
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types'
import { updateContentGenerationData } from 'workflow/Workflow.actions'

import Loader from 'common/components/loaders/Loader'
import { errorToast } from 'common/components/toastNotification'
import { resetCurrentState } from 'common/components/topics/store/topicsSlice'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useWebsockets from 'common/hooks/useWebsockets'

import ControlVariant from './components/ControlVariant'
import {
  generationError,
  generationStarted,
} from './store/contentGenerationSlice'
import { getVariantsLlm } from './api'
import ContentApproval from './contentApproval'
import {
  generateWsTopic,
  WsResponseCampaignGeneration,
} from './ContentGeneration'

const ContentGenerationLlm = () => {
  const {
    lastJsonMessage,
    sendJsonMessage,
    isConnectionReady,
  }: {
    lastJsonMessage: WsResponseCampaignGeneration
    sendJsonMessage: SendJsonMessage
    isConnectionReady: boolean
  } = useWebsockets()

  const dispatch = useAppDispatch()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const isWaitingForCampaignLoad = useAppSelector(
    (state) =>
      state.campaignStates.isWaitingState.isWaiting &&
      state.campaignStates.isWaitingState.isWaitingFor === 'campaignsLoaded'
  )

  const nlgStatus = useAppSelector((state) => state.contentGeneration.nlgStatus)

  const humanLine = useAppSelector(
    (state) => state.campaignStates.campaignData.own_subject_line
  )
  const description = useAppSelector(
    (state) => state.campaignStates.campaignData.description
  )
  const numSplits = useAppSelector(
    (state) => state.campaignStates.campaignData.num_splits
  )
  const campaignId = useAppSelector(
    (state) => state.campaignStates.currentCampaignId
  )
  const subjectLines = useAppSelector(
    (state) => state.campaignStates.subjectLines
  )

  const wsTopic = generateWsTopic(campaignId)

  const generateVariants = async () => {
    dispatch(generationStarted())
    try {
      await getVariantsLlm({
        campaignId,
        numSplits,
        wsTopics: [wsTopic],
      })
    } catch {
      errorToast('Something went wrong. Please try again.')
      dispatch(generationError())
    }
  }

  useEffect(() => {
    if (subjectLines.length < 2) {
      generateVariants()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetCurrentState())
    }
  }, [dispatch])

  useEffect(() => {
    if (lastJsonMessage && lastJsonMessage.topics?.includes(wsTopic)) {
      if (lastJsonMessage.status === 'success' && lastJsonMessage.response) {
        dispatch(
          updateContentGenerationData(lastJsonMessage.response.subjectLines)
        )
      } else if (lastJsonMessage.status === 'error') {
        errorToast(lastJsonMessage.errorMessage)
        dispatch(generationError())
      }

      sendJsonMessage({
        action: 'unsubscribe',
        topics: [wsTopic],
      })
    }
    // Important, the dep array should only include lastJsonMessage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage])

  useEffect(() => {
    if (isConnectionReady && nlgStatus === 'pending') {
      sendJsonMessage({
        action: 'subscribe',
        topics: [wsTopic],
      })
    }
  }, [isConnectionReady, nlgStatus, sendJsonMessage, wsTopic])

  return (
    <div className="w-full h-full flex flex-col" ref={containerRef}>
      <div className="p-8 pb-24 flex flex-col overflow-y-auto flex-1-0">
        <div className="font-medium text-xl text-gray-800">
          Content generation
        </div>
        <div className="font-normal text-sm text-gray-500 mt-1">
          Select topics and configure some specifics to generate content for
          your experiment.
        </div>
        {isWaitingForCampaignLoad || nlgStatus === 'pending' ? (
          <div className="h-88">
            <Loader />
          </div>
        ) : (
          <>
            <div className="mt-8 border border-coolGray-300">
              <ControlVariant
                controlText={humanLine}
                description={description}
                fragments={[]}
                isAdvancedSettingsShown={false}
              />
            </div>

            {containerRef.current && subjectLines.length > 1 && (
              <ContentApproval containerElement={containerRef.current} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ContentGenerationLlm
