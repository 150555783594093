import useProjectsQuery from 'features/campaigns/queries/useProjectsQuery'

const useProjectOptions = ({ optimizationContentType, hideLinguo = false }) => {
  const { data: projects } = useProjectsQuery()

  const projectOptions = projects
    ?.filter((project) => {
      const isPush = optimizationContentType === 'push_notification'
      if (isPush) {
        return (
          (project.project_configuration?.campaign_configurations
            ?.distribution_channel === optimizationContentType ||
            project.project_configuration?.campaign_configurations
              ?.distribution_channel === 'web_push') &&
          project.project_configuration?.campaign_configurations?.testing_method
            ?.tested_content_sections?.[0]?.language_source !== 'NLG' &&
          (!hideLinguo ||
            project.project_configuration?.campaign_configurations
              ?.testing_method?.tested_content_sections?.[0]
              ?.language_source !== 'linguo') &&
          project.deleted !== true
        )
      }
      return (
        project.project_configuration?.campaign_configurations?.testing_method
          ?.tested_content_sections?.[0]?.section === optimizationContentType &&
        project.project_configuration?.campaign_configurations?.testing_method
          ?.tested_content_sections?.[0]?.language_source !== 'NLG' &&
        (!hideLinguo ||
          project.project_configuration?.campaign_configurations?.testing_method
            ?.tested_content_sections?.[0]?.language_source !== 'linguo') &&
        project.deleted !== true
      )
    })
    ?.map((project) => ({
      label: project.name,
      value: project._id,
    }))
    .sort((a, b) => {
      return a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    })

  return projectOptions
}

export default useProjectOptions
