import { Element } from '@phrasee/phrasee-typings'
import { useFlags } from 'launchdarkly-react-client-sdk'

import ActionIcon from 'common/components/ActionIcon'
import AvatarList from 'common/components/AvatarList'
import ContentTypeBubble from 'common/components/ContentTypeBubble'
import OverlappingItems from 'common/components/overlappingItems/OverlappingItems'
import Tooltip from 'common/components/Tooltip'
import Widget from 'common/components/Widget'
import { formatDate } from 'common/helpers/formatDate'
import {
  Bin as BinIcon,
  Clock as ClockIcon,
  Copy as CopyIcon,
  Favorite as FavoriteIcon,
} from 'common/icons'

type Props = {
  title: string
  createdDate: Date
  users: string[]
  onDelete: (e: React.MouseEvent<HTMLSpanElement>) => void
  onDuplicate: (e: React.MouseEvent<HTMLSpanElement>) => void
  onAddFavorite: () => void
  elements: Element[]
}

const SavedContentCard = ({
  title,
  createdDate,
  users,
  onDelete,
  onDuplicate,
  onAddFavorite,
  elements,
}: Props) => {
  const flags = useFlags()

  return (
    <Widget type="basic" className="h-full hover:shadow-md group">
      <div className="flex flex-row justify-between align-middle">
        <OverlappingItems
          size="medium"
          itemNames={elements?.map(
            (element) => element.display_name ?? element.name
          )}
          items={elements.map((element, index) => (
            <ContentTypeBubble
              key={index}
              iconType={element.icon || ''}
              name={element.display_name ?? element.name}
            />
          ))}
        />
         
        <AvatarList userNames={users} />
      </div>
      <div className="font-medium text-base my-4 max-w-xs line-clamp-3 font-relais">
        {title}
      </div>
      <div className="flex flex-row justify-between mt-auto text-base-700 font-normal text-xs">
        <div className="flex flex-row justify-start">
          <ClockIcon isDefaultColor={true} />
          <div className="ml-2.5 self-center">{formatDate(createdDate)}</div>
        </div>

        <div className="flex gap-4 invisible group-hover:visible">
          <Tooltip overlay="Delete">
            <ActionIcon data-testid="delete-icon" onClick={onDelete}>
              <BinIcon isDefaultColor={false} className="text-base-700" />
            </ActionIcon>
          </Tooltip>
          <Tooltip overlay="Copy">
            <ActionIcon data-testid="copy-icon" onClick={onDuplicate}>
              <CopyIcon isDefaultColor={false} className="text-base-700" />
            </ActionIcon>
          </Tooltip>
          {flags.showFavoriteIcon && (
            <ActionIcon data-testid="favorite-icon" onClick={onAddFavorite}>
              <FavoriteIcon isDefaultColor={false} className="text-base-700" />
            </ActionIcon>
          )}
        </div>
      </div>
    </Widget>
  )
}

export default SavedContentCard
