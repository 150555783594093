import { Route } from 'react-router-dom'
import { unifiedFlowPaths } from 'app/navigation/paths'
import CustomSwitch from 'app/router/Switch'

import ContentPage from './contentPage'
import ContentsPage from './contentsPage'

const UnifiedFlow = () => {
  return (
    <div className="flex flex-col h-full w-full relative bg-gold-40 overflow-y-auto">
      <CustomSwitch>
        <Route exact path={unifiedFlowPaths.home}>
          <ContentsPage />
        </Route>
        <Route key="unified-flow-content" path={unifiedFlowPaths.content}>
          <ContentPage />
        </Route>
      </CustomSwitch>
    </div>
  )
}

export default UnifiedFlow
