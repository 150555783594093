import cx from 'classnames'
import isNumber from 'lodash/isNumber'

import Badge from 'common/components/Badge'
import { containsArabic } from 'common/helpers/string'
import { TextWithLineBreaks } from 'common/variantsUtils'

type Props = {
  index: number | undefined
  text: string
  className?: string
  isControlVariant: boolean
}

const VariantCell = ({ index, text, isControlVariant, className }: Props) => {
  const isArabic = containsArabic(text)

  return (
    <div className={cx('flex items-center px-4 child-cell font-sm', className)}>
      {isControlVariant ? (
        <>
          <TextWithLineBreaks text={text} />
          <Badge
            size="small"
            className={cx('flex-none uppercase', {
              'ml-2': !isArabic,
              'mr-2': isArabic,
            })}
            text="Control"
            variant="info"
          />
        </>
      ) : isNumber(index) ? (
        <div className="flex items-center">
          <span className={cx({ 'mr-4': !isArabic })}>{`${index + 1}.`}</span>
          <TextWithLineBreaks text={text} />
        </div>
      ) : (
        <TextWithLineBreaks text={text} />
      )}
    </div>
  )
}

export default VariantCell
