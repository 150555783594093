import { useQueryClient } from '@tanstack/react-query'

import Button from 'common/components/button/Button'
import { imagesKeys } from 'common/components/images/api/queryKeys'
import { successToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'
import useUpdateImageStatusMutation from 'features/admin/imageLibrary/api/mutations/useUpdateImageStatusMutation'

type Props = {
  selectedImagesIds: string[]
  onCancel: () => void
  onClearImages: () => void
}

const ArchiveImageModalFooter = ({
  selectedImagesIds,
  onCancel,
  onClearImages,
}: Props) => {
  const queryClient = useQueryClient()
  const { mutate, isLoading } = useUpdateImageStatusMutation()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return (
    <div className="w-full flex justify-end mt-8">
      <Button
        variant="link"
        className="uppercase underline text-gold-600"
        onClick={onCancel}
      >
        cancel
      </Button>
      <Button
        disabled={isLoading || selectedImagesIds.length === 0}
        loading={isLoading}
        variant="danger"
        className="uppercase ml-4 bg-RAGred text-gold-40"
        onClick={() =>
          mutate(
            {
              imagesIds: selectedImagesIds.map((id) => id),
              status: 'ARCHIVED',
            },
            {
              onSuccess: ({ message }) => {
                successToast(message)
                queryClient.invalidateQueries(imagesKeys.byAccount(accountId))
                onClearImages()
              },
            }
          )
        }
      >
        archive
      </Button>
    </div>
  )
}

export default ArchiveImageModalFooter
