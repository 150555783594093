import { forwardRef } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import cx from 'classnames'
import has from 'lodash/has'

import Button from 'common/components/button'
import { FieldsConfiguration } from 'common/components/dynamicFields/interfaces'
import { useAppDispatch } from 'common/hooks/redux'
import { CloseSquare as CloseSquareIcon } from 'common/icons'

import { removeTopic } from '../store/topicsSlice'

import ConfigurationMenu from './nodeConfiguration'
import type { SortableTopicCardProps } from './SortableTopicCard'
import TopicCardName from './TopicCardName'

export type Props = SortableTopicCardProps &
  Partial<ReturnType<typeof useSortable>> & {
    style: React.CSSProperties
    isDragged?: boolean
    isActive?: boolean
  }

const TopicCard = forwardRef<HTMLDivElement, Props>(
  (
    {
      topic,
      isDisabled,
      isDragged = false,
      isActive = false,
      className,
      ...rest
    },
    ref
  ) => {
    const dispatch = useAppDispatch()
    const { id, name, type, original } = topic

    const isReplacer = type === 'replacer'
    const hasError = isReplacer && (name === undefined || name === '')
    const hasParentNode = has(topic, 'original.parentNodeId')

    const configuration: FieldsConfiguration | undefined =
      topic.type === 'replacer' ? topic.configuration : undefined

    if (isReplacer && configuration === undefined) {
      return null
    }

    return (
      <div
        {...rest}
        ref={ref}
        data-testid="topic-card"
        className={cx(
          'py-2 px-4 grid gap-2 rounded-sm justify-between min-w-40 min-h-10 max-w-36 max-w-52 items-center group',
          className,
          {
            'bg-red-50': hasError,
            'bg-gold-100 hover:bg-gold-300':
              !hasError && !hasParentNode && !isActive,
            'bg-coral-200 hover:bg-coral-100':
              !hasError && hasParentNode && !isActive,
            'bg-yellow-200': !hasError && isActive,
          }
        )}
        style={{
          gridTemplateColumns: 'minmax(0, 1fr) minmax(0, max-content)',
        }}
      >
        <TopicCardName
          name={name || original.displayName}
          hasError={hasError}
          hasParentNode={hasParentNode}
          isReplacer={isReplacer}
          isActive={isActive}
        />
        <div
          className={cx('flex justify-between items-center', {
            'text-red-400': hasError,
          })}
        >
          {type === 'replacer' && (
            <ConfigurationMenu
              topicId={id}
              isDisabled={isDisabled}
              hasReplacerError={hasError}
              configuration={configuration!}
            />
          )}

          <Button
            variant="icon"
            onClick={() => dispatch(removeTopic(id))}
            data-testid="remove-topic-button"
            disabled={isDisabled}
            className="h-auto"
          >
            <CloseSquareIcon
              size={4}
              isDefaultColor={false}
              className={cx({
                'text-red-400': hasError,
                'text-base-700': !hasError,
              })}
            />
          </Button>
        </div>
      </div>
    )
  }
)

export default TopicCard
