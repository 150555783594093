import { useEffect } from 'react'
import { useField, useForm } from 'react-final-form'

import { DropdownType } from 'common/components/dynamicFields/interfaces'

import { TemplateInfoWithInternalId } from '../../addedTemplates/AddedComponentList'
import { getPrefixedName } from '../../helpers'

type UseSplitNumberLimitProps = {
  template?: TemplateInfoWithInternalId
  formImageOptimizationEnabled: boolean
  variantDropdownOptions?: DropdownType['options']
  defaultVariantDropdownOption?: DropdownType['options'][number]
}

export const useSplitNumberLimitOnImageOptimization = ({
  template,
  formImageOptimizationEnabled,
  variantDropdownOptions,
  defaultVariantDropdownOption,
}: UseSplitNumberLimitProps) => {
  const form = useForm()

  const {
    input: { value: currentSplitNumber },
  } = useField(getPrefixedName('splitNumber', template))

  useEffect(() => {
    if (!template || !variantDropdownOptions || !formImageOptimizationEnabled) {
      return
    }

    const maxOptionValue = Math.max(
      ...variantDropdownOptions.map((option) => Number(option.value))
    )

    const isCurrentValueBiggerThanMax =
      Number(currentSplitNumber) > maxOptionValue

    if (isCurrentValueBiggerThanMax) {
      form.change(
        getPrefixedName('splitNumber', template),
        Number(defaultVariantDropdownOption?.value)
      )
    }
  }, [
    form,
    template,
    currentSplitNumber,
    variantDropdownOptions,
    defaultVariantDropdownOption,
    formImageOptimizationEnabled,
  ])
}
