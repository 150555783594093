export interface Props {
  actions: any
  projectId: string
  campaignId: string
  timeZone: string
  campaignData: any
  businessUnits: Array<EpsilonBusinessUnit>
  seedList: Array<EpsilonSeedList>
  folders: Array<EpsilonFolder>
  campaigns: Array<EpsilonCampaign>
  closeDrawer: () => void
  updateCampaignData: (campaignId: string, callback: any) => void
  isLoading: boolean
  onSuccess?: () => void
  onCancel?: () => void
}

export interface EpsilonCampaign {
  epsilon_campaign_name: string
  epsilon_campaign_id: string
}

export interface EpsilonBusinessUnit {
  business_unit_id: string
  business_unit_name: string
}

export interface EpsilonSeedList {
  epsilon_seed_id: string
  epsilon_seed_name: string
}

export interface EpsilonFolder {
  epsilon_folder_id: string
  epsilon_folder_name: string
}

export interface State {
  selectedLockOption: LockAudienceType
  selectedLockTimeOption: RelativeTimeType
  selectedLockTimeAmountOption: number
  selectedSeedListId: string
  selectedBusinessUnit: string
  selectedFolder: string
  selectedCampaign: string
  startDate: any
  startTime: any
  endDate: any
  endTime: any
  isVariantsPushed: boolean
  isCampaignStarted: boolean
  isCampaignScheduled: boolean
  errorMessages: ValidationErrors
  selectedDelayHours: number
  selectedDelayMinutes: number
  timeZone: string
}

export interface ValidationErrors {
  businessUnits: string
  folders: string
  campaigns: string
  isVariantsPushed: string
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  delayTime: string
  missingInfo: string
}

export interface HoursDropdownOptions {
  value: number
  label: string
}

export interface MinutesDropdownOptions {
  value: number
  label: string
}

export interface AudienceScheduleParams {
  lockAudienceType: LockAudienceType
  relativeTime: number | null
  relativeTimeType: RelativeTimeType | null
  scheduledLockDate: null // Not used
}

export enum LockAudienceType {
  SCHEDULE_TIME = 'SCHEDULE_TIME',
  RELATIVE_TIME = 'RELATIVE_TIME',
  DEPLOYMENT_TIME = 'DEPLOYMENT_TIME',
}

export enum RelativeTimeType {
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}
