import { ReactNode } from 'react'
import cx from 'classnames'

export interface WidgetHeaderProps {
  title: ReactNode
  actions?: ReactNode
  className?: string
  subtitle?: string
}

const WidgetHeader = ({
  title,
  subtitle,
  actions,
  className,
}: WidgetHeaderProps) => (
  <div className={cx('flex justify-between', className)}>
    <div className="mb-4 w-full">
      <div className="text-base-700 font-normal text-base capitalize-first">
        {title}
        {subtitle && (
          <div className="text-coolGray-400 mt-1 text-sm font-normal">
            {subtitle}
          </div>
        )}
      </div>
    </div>
    <div>{actions}</div>
  </div>
)

export default WidgetHeader
