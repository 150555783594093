import { FC, useMemo, useState } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useQuery } from '@tanstack/react-query'

import Avatar from 'common/components/Avatar'
import Button from 'common/components/button'
import FormError from 'common/components/formError'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import TableWidget from 'common/components/widget/tableWidget'
import { userRolePerKey } from 'common/helpers/user'
import { useAppSelector } from 'common/hooks/redux'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'
import { GetUserResponse, getUsers } from 'features/admin/users/all/api'
import { AvatarColor, avatarColors } from 'features/profile/AvatarColorPicker'
import { projectKeys } from 'features/projects/api/queryKeys'
import { getFieldError } from 'features/projects/helpers'

import AddUsersModal from '../addUsersModal'

export type User = {
  firstName: string
  lastName: string
  email: string
  role: string
  id: string
  avatarColor: AvatarColor
}

const NotificationRecipientsCard: FC = () => {
  const formState = useFormState()
  const { change: formChange } = useForm()
  const assignedUserEmails = useMemo(
    () => formState.values.notificationRecipients || [],
    [formState.values.notificationRecipients]
  )

  const { accountId } = useAppSelector((state) => state.authStates)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const { status, data: allUsers = [] } = useQuery(
    projectKeys.projectUsers(accountId),
    () => getUsers(accountId).then((res) => res.data),
    {
      select: (data: GetUserResponse[]): User[] =>
        data.map(({ _id, first_name, last_name, email, roles }, index) => ({
          id: _id,
          firstName: first_name ?? '',
          lastName: last_name ?? '-',
          email,
          role: roles[0],
          avatarColor: avatarColors[index % avatarColors.length],
        })),
      refetchOnWindowFocus: false,
    }
  )
  const selectedUsers = allUsers.filter(({ email }) =>
    assignedUserEmails.includes(email)
  )

  const applySelectedUsers = (users: User[]): void => {
    formChange(
      'notificationRecipients',
      users.map(({ email }) => email)
    )
    setIsModalOpen(false)
  }

  const columns: Column<User>[] = useMemo(() => {
    const unselectUser = (unselectedUserEmail: string): void => {
      formChange(
        'notificationRecipients',
        assignedUserEmails.filter((email) => email !== unselectedUserEmail)
      )
    }

    return [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: ({ row }) => {
          const { firstName, lastName, avatarColor } = row.original

          return (
            <BaseCell className="flex items-center">
              <Avatar
                firstName={firstName}
                lastName={lastName}
                className="text-xs w-7 h-7 min-w-7 mr-3"
                color={avatarColor}
              />
              <span className="font-medium">{`${firstName} ${lastName}`}</span>
            </BaseCell>
          )
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ value }) => (
          <BaseCell className="flex items-center">{value}</BaseCell>
        ),
      },
      {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ value }) => (
          <BaseCell className="flex items-center capitalize">
            {userRolePerKey[value]}
          </BaseCell>
        ),
      },
      generateDeleteButtonColumn<User>({
        onClick: (row: User) => unselectUser(row.email),
        buttonLabel: 'Remove notification recipient',
        hasTooltip: true,
      }),
    ]
  }, [assignedUserEmails, formChange])

  return (
    <>
      <TableWidget.Widget
        data-cy="notification-recipients-card"
        data-testid="notification-recipients-card"
        isLoading={status === 'loading'}
        hasError={status === 'error'}
        columns={columns}
        data={selectedUsers}
        hasWidgetMenu={false}
      >
        <TableWidget.Header
          title="Notification Recipients"
          subtitle="Add the users that should receive notifications about experiment status and results."
        >
          <Button
            data-cy="add-notification-recipients-button"
            data-testid="add-notification-recipients-button"
            variant="primary"
            prefixIcon={<AddIcon width={24} height={24} />}
            onClick={() => setIsModalOpen(true)}
          >
            Recipients
          </Button>
        </TableWidget.Header>
        <Field<string> name="notificationRecipients">
          {({ meta }) => (
            <FormError className="ml-6">{getFieldError(meta)}</FormError>
          )}
        </Field>
      </TableWidget.Widget>

      <AddUsersModal
        isOpen={isModalOpen}
        onCancelClick={() => setIsModalOpen(false)}
        users={allUsers}
        assignedUsers={selectedUsers}
        isLoading={status === 'loading'}
        applySelectedUsers={applySelectedUsers}
      />
    </>
  )
}

export default NotificationRecipientsCard
