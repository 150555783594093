import { WsMessage } from './types'

export const getWsTopic = (message: WsMessage) => {
  switch (message.action) {
    case 'tweakVariant':
      return `content/${message.data.contentId}/element/${message.data.elementId}/variant/${message.data.variantId}/tweakVariant`
    case 'rejectVariant':
      return `content/${message.data.contentId}/element/${message.data.elementId}/variant/${message.data.variantId}/rejectVariant`
    case 'refreshVariant':
      return `content/${message.data.contentId}/element/${message.data.elementId}/variant/${message.data.variantId}/refreshVariant`
    case 'moreLikeThisVariants':
      return `content/${message.data.contentId}/element/${message.data.elementId}/variant/${message.data.variantId}/moreLikeThisVariants`
    case 'generateGlobalBrief':
      return `content/${message.data.contentId}/generateGlobalBrief`
    case 'generateElementBrief':
      return `content/${message.data.contentId}/element/${message.data.elementId}/generateElementBrief`
    case 'generateElementVariants':
      return `content/${message.data.contentId}/element/${message.data.elementId}/generateElementVariants`
    default:
      return ''
  }
}
