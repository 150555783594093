import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  hideRightPanel,
  selectRightPanelVisibility,
  showRightPanel,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import { useContent } from '../../hooks'
import useGetLogsQuery from '../centerSection/api/queries/useGetLogsQuery'

const useRightPanelVisibilityState = ({ showOptimizeView }) => {
  const { content } = useContent()
  const dispatch = useAppDispatch()

  const { logsState } = useGetLogsQuery(content?._id)

  const rightPanelVisibility = useAppSelector(selectRightPanelVisibility)

  useEffect(() => {
    if (showOptimizeView) {
      dispatch(hideRightPanel({ visibility: 'none' }))
    } else if (
      !showOptimizeView &&
      logsState === 'hasLogs' &&
      rightPanelVisibility === 'none'
    ) {
      dispatch(showRightPanel({ visibility: 'auto' }))
    } else if (!showOptimizeView && logsState === 'empty') {
      dispatch(hideRightPanel({ visibility: 'none' }))
    }
  }, [logsState, dispatch, rightPanelVisibility, showOptimizeView])
}

export default useRightPanelVisibilityState
