import { unifiedFlowPaths } from 'app/navigation/paths'

import breakpoints from 'common/breakpoints'
import Button from 'common/components/button'
import SubNavBar from 'common/components/navigation/subNavBar'
import Tooltip from 'common/components/Tooltip'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useWindowDimensions } from 'common/hooks/useWindowDimensions'
import { SidebarLeftMenu as SidebarLeftMenuIcon } from 'common/icons'
import {
  hideLeftPanel,
  selectLeftPanelVisibility,
  showLeftPanel,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import HeaderActions from './HeaderActions'
import HeaderNavigation from './HeaderNavigation'
import WorkflowField from './WorkflowField'

const Header = () => {
  const dispatch = useAppDispatch()

  const { screenWidth } = useWindowDimensions()

  const leftPanelVisibility = useAppSelector(selectLeftPanelVisibility)

  return (
    <SubNavBar
      title={<WorkflowField />}
      backLink={unifiedFlowPaths.home}
      preTitle={
        <Button
          size="small"
          variant="icon"
          className="mr-2 lg:hidden"
          prefixIcon={
            <Tooltip
              overlay={
                leftPanelVisibility === 'hidden' ||
                (leftPanelVisibility === 'auto' && screenWidth < breakpoints.md)
                  ? 'Show panel'
                  : 'Hide panel'
              }
            >
              <SidebarLeftMenuIcon
                isDefaultColor={false}
                className="text-white w-5 h-5"
              />
            </Tooltip>
          }
          onClick={() => {
            leftPanelVisibility === 'hidden'
              ? dispatch(showLeftPanel())
              : leftPanelVisibility === 'auto' && screenWidth < breakpoints.md
              ? dispatch(showLeftPanel())
              : dispatch(hideLeftPanel())
          }}
        />
      }
      rightNav={<HeaderActions />}
    >
      <HeaderNavigation />
    </SubNavBar>
  )
}

export default Header
