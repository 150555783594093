import React, { ReactNode } from 'react'

import { formatNumber } from 'common/helpers/numeric'
import { useAppSelector } from 'common/hooks/redux'

import { isAcoustic } from './helpers'
import SectionTitle from './SectionTitle'

const moment = require('moment-timezone')

const DATE_FORMAT = process.env.REACT_APP_DEFAULT_DATE_TIME_FORMAT

interface UiTemplate {
  title: string
  value: string | ReactNode
}

interface Supression {
  _id: string
  name: string
}

const calculateSplit = (
  num_splits: number,
  split_size: number,
  list_size: number
) => {
  const calc = (num_splits * split_size) / list_size
  return `${Number(calc * 100).toFixed(2)}%`
}

let uiTemplate: UiTemplate[] = []

const updateUiTemplate = (campaignData: any) => {
  uiTemplate = []

  if ('list_size' in campaignData) {
    const { list_size } = campaignData
    uiTemplate.push({
      title: 'Audience size:',
      value: list_size ? formatNumber(list_size) : 'NA',
    })
  }

  if (
    'num_splits' in campaignData &&
    'split_size' in campaignData &&
    'list_size' in campaignData
  ) {
    const { num_splits, split_size, list_size } = campaignData
    uiTemplate.push({
      title: 'Split calculation:',
      value:
        num_splits && split_size && list_size
          ? `${num_splits} splits of ${split_size} recipients (${calculateSplit(
              num_splits,
              split_size,
              list_size
            )})`
          : 'NA',
    })
  }

  // IF SAILTHRU INTEGRATION:
  if (
    'typeOfIntegration' in campaignData &&
    campaignData.typeOfIntegration === 'sailthru'
  ) {
    if ('sailthruSummary' in campaignData) {
      const summary = campaignData.sailthruSummary

      if (
        'finalCampaignResult' in summary &&
        'splitCampaignResult' in summary
      ) {
        if (campaignData.sailthruSummary.finalCampaignResult) {
          const {
            sailthruSummary: {
              finalCampaignResult: { name: finalName },
              splitCampaignResult: {
                name: splitName,
                schedule_time: date_1,
                scheduleDate: date_2,
              },
              hoursBeforeFinal: { waitHours: hours, waitMinutes: minutes },
            },
          } = campaignData

          uiTemplate.push(
            {
              title: 'Final Campaign:',
              value: finalName,
            },
            {
              title: 'Split test campaign:',
              value: splitName,
            },
            {
              title: 'Send date and time:',
              value: `${moment(date_1 || date_2).format(DATE_FORMAT)}`,
            },
            {
              title: 'Wait time:',
              value: `${hours} hr(s) ${minutes} min(s)`,
            }
          )
        }
      }
    }
  }

  // IF ACOUSTIC INTEGRATION:
  if (
    'typeOfIntegration' in campaignData &&
    isAcoustic(campaignData.typeOfIntegration)
  ) {
    if ('send_date_time' in campaignData && 'time_zone' in campaignData) {
      const { send_date_time: sendDate, time_zone: campaignTimeZone } =
        campaignData

      uiTemplate.push({
        title: 'Send date and time:',
        value: `${moment(sendDate)
          .tz(campaignTimeZone)
          .format(DATE_FORMAT)} (${moment.tz(campaignTimeZone).zoneAbbr()})`,
      })
    }

    if ('wait_time' in campaignData) {
      const { wait_time } = campaignData
      uiTemplate.push({
        title: 'Wait time:',
        value: `${wait_time} Hour${wait_time > 1 ? 's' : ''}`,
      })
    }

    if ('email_template' in campaignData) {
      const { email_template } = campaignData
      uiTemplate.push({
        title: 'Template:',
        value: email_template,
      })
    }

    if ('suppressions_lists' in campaignData) {
      const { suppressions_lists } = campaignData

      uiTemplate.push({
        title: 'Suppression list:',
        value:
          suppressions_lists.length === 0
            ? 'N/A'
            : suppressions_lists.map((list: Supression) => (
                <span key={list._id}>
                  {list.name}
                  <br />
                </span>
              )),
      })
    }

    if ('tobesent' in campaignData) {
      const { tobesent } = campaignData
      uiTemplate.push({
        title: 'Auto-winner:',
        value: `${tobesent ? 'Yes' : 'No'}`,
      })
    }

    if (
      'mailing_parameter' in campaignData ||
      'send_optimization' in campaignData
    ) {
      const { mailing_parameter, send_optimization } = campaignData
      uiTemplate.push({
        title: 'Other settings:',
        value: (
          <span>
            {`Winner STO: ${send_optimization}`}
            <br />
            {`Mailing Parameter: ${mailing_parameter || 'N/A'}`}
          </span>
        ),
      })
    }
  }

  // IF SFMCv2 INTEGRATION:
  if (
    'typeOfIntegration' in campaignData &&
    campaignData.typeOfIntegration === 'sfmcv2'
  ) {
    if (
      'campaign_configuration' in campaignData &&
      'integration_options' in campaignData &&
      'send_definition_name' in campaignData
    ) {
      const { send_definition_name } = campaignData
      uiTemplate.push({
        title: 'User-initiated email:',
        value: send_definition_name,
      })
    }

    if (
      'campaign_configuration' in campaignData &&
      'integration_options' in campaignData &&
      'wait_time' in campaignData
    ) {
      const { wait_time } = campaignData
      uiTemplate.push({
        title: 'Wait time:',
        value: wait_time
          ? `${wait_time} Hour${wait_time > 1 ? 's' : ''}`
          : 'NA',
      })
    }

    if (
      'campaign_configuration' in campaignData &&
      'integration_options' in campaignData &&
      'send_date' in campaignData &&
      'timezone' in campaignData
    ) {
      const { send_date: sendDate, timezone: campaignTimeZone } = campaignData
      uiTemplate.push({
        title: 'Send date and time:',
        value: sendDate
          ? `${moment(sendDate)
              .tz(campaignTimeZone)
              .format(DATE_FORMAT)} (${moment.tz(campaignTimeZone).zoneAbbr()})`
          : 'NA',
      })
    }
  }

  if (
    'typeOfIntegration' in campaignData &&
    campaignData.typeOfIntegration === 'cheetah'
  ) {
    if (
      campaignData.campaign_data?.integration_data?.campaign_start_time &&
      campaignData.campaign_data?.integration_data?.esp_campaign_timezone
    ) {
      const {
        campaign_data: {
          integration_data: {
            campaign_start_time,
            esp_campaign_timezone: timezone,
          },
        },
      } = campaignData
      uiTemplate.push({
        title: 'Expected start time:',
        value: `${moment
          .tz(campaign_start_time, timezone)
          .format(DATE_FORMAT)} (Timezone: ${timezone || 'NA'})`,
      })
    }

    if (
      campaignData.campaign_data?.integration_data?.campaign_start_time &&
      campaignData.campaign_data?.integration_data?.esp_campaign_timezone
    ) {
      const {
        campaign_data: {
          integration_data: {
            campaign_end_time,
            esp_campaign_timezone: timezone,
          },
        },
      } = campaignData
      uiTemplate.push({
        title: 'Expected end time:',
        value: `${moment.tz(campaign_end_time, timezone).format(DATE_FORMAT)}`,
      })
    }
  }

  return uiTemplate
}

const Details = () => {
  const campaignData = useAppSelector(
    (state) => state.campaignStates.campaignData
  )
  const typeOfIntegration = useAppSelector(
    (state) => state.campaignStates.typeOfIntegration
  )
  const sailthruSummary = useAppSelector(
    (state) => state.campaignStates.summary
  )

  const mergedData = {
    ...campaignData,
    ...(sailthruSummary && { sailthruSummary }),
    typeOfIntegration,
  }

  updateUiTemplate(mergedData)

  return (
    <div className="flex flex-col py-6 pr-6 border-b border-coolGray-200">
      <SectionTitle>Details</SectionTitle>

      {uiTemplate.map(({ title, value }) => (
        <li key={title} className="flex mb-1">
          <span className="inline-block w-1/2 opacity-60">{title}</span>
          <span
            data-cy={`campaign-details-${title
              .toLocaleLowerCase()
              .replace(' ', '-')
              .replace(':', '')}`}
            data-testid={`campaign-details-${title
              .toLocaleLowerCase()
              .replace(' ', '-')
              .replace(':', '')}`}
            className="inline-block w-1/2 break-normal text-coolGray-800"
          >
            {value}
          </span>
        </li>
      ))}
    </div>
  )
}

export default Details
