import { useEffect, useMemo, useState } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'

import Button from 'common/components/button/Button'
import ConfirmationModal from 'common/components/confirmationModal'
import ToggleButtonGroup from 'common/components/toggleButtonGroup'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { CloseSquare as CloseSquareIcon, ImageIcon } from 'common/icons'
import ElementCardIcon from 'features/unifiedFlow/contentPage/components/ElementCardIcon'
import {
  removeTemplate,
  templateSetupComplete,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import { useExperimentForm } from '../context/ExperimentFormContext'
import { experimentType } from '../experimentMenu'
import {
  getFormValue,
  getIsContentFormValid,
  getTemplatePrefixedName,
} from '../helpers'

import { TemplateInfoWithInternalId } from './AddedComponentList'
import ToggleButtonGroupTabs from './ToggleButtonGroupTabs'

type Props = {
  template: TemplateInfoWithInternalId
}

const AddedComponentCard = ({ template }: Props) => {
  const [confirmModalTypeShown, setConfirmModalTypeShown] =
    useState<undefined | 'remove' | 'change'>(undefined)
  const form = useForm()
  const formState = useFormState()
  const dispatch = useAppDispatch()
  const { dispatch: experimentFormDispatch } = useExperimentForm()

  const [tabToShow, setTabToShow] = useState<string | undefined>(undefined)

  const handleToggleGroupClick = (value) => {
    setTabToShow(value)
    experimentFormDispatch({ type: 'reset_experiment_form' })

    form.change(`templateId-${internalId}`, {
      experimentType: experimentType[value],
    })

    dispatch(
      templateSetupComplete({
        internalId: template.internalId,
        status: 'pristine',
      })
    )
  }

  const handleRemoveTemplate = () => {
    experimentFormDispatch({ type: 'reset_experiment_form' })
    form.change(`templateId-${internalId}`, undefined)
    dispatch(removeTemplate({ internalId }))
  }

  const templatesToAdd = useAppSelector(
    (state) => state.unifiedFlow.templatesToAdd
  )

  const formStatus = useMemo(
    () =>
      templatesToAdd.find(
        (templateToAdd) => templateToAdd.internalId === template.internalId
      )?.status,
    [templatesToAdd, template.internalId]
  )

  const selectedExperimentType = getFormValue({
    formState,
    name: 'experimentType',
    templateInternalId: template.internalId,
  })

  const { internalId, optimization, personalization, displayName, icon } =
    template

  const items = useMemo(
    () => [
      {
        label: 'content',
        value: 'content',
        disabled: formStatus === 'complete' && tabToShow !== 'content',
        tooltipMessage: "You have already completed this template's form",
      },
      ...(optimization?.status !== 'disabled'
        ? [
            {
              label: 'optimise',
              value: 'optimize',
              icon: optimization?.images === 'enabled' && (
                <ImageIcon isDefaultColor={false} size={3} />
              ),
              disabled:
                optimization?.status === 'blocked' ||
                (formStatus === 'complete' &&
                  tabToShow !== 'optimize' &&
                  tabToShow !== 'content'),
              tooltipMessage:
                formStatus === 'complete' && tabToShow !== 'content'
                  ? "You have already completed this template's form"
                  : 'Talk to us about upgrading',
            },
          ]
        : []),
      ...(personalization?.status !== 'disabled'
        ? [
            {
              label: 'personalise',
              value: 'personalize',
              disabled:
                personalization?.status === 'blocked' ||
                (formStatus === 'complete' &&
                  tabToShow !== 'personalize' &&
                  tabToShow !== 'content'),
              tooltipMessage:
                formStatus === 'complete' && tabToShow !== 'content'
                  ? "You have already completed this template's form"
                  : 'Talk to us about upgrading',
            },
          ]
        : []),
    ],
    [
      optimization?.status,
      optimization?.images,
      personalization?.status,
      formStatus,
      tabToShow,
    ]
  )

  const onChangeType = (value: string) => {
    if (value === tabToShow) {
      return
    }

    if (formStatus === 'pristine' || tabToShow === 'content') {
      handleToggleGroupClick(value)
    } else {
      setConfirmModalTypeShown('change')
    }
  }

  const onModalConfirm = () => {
    if (confirmModalTypeShown === 'remove') {
      handleRemoveTemplate()
    }

    if (confirmModalTypeShown === 'change') {
      const experimentType: string =
        formState.values[`templateId-${internalId}`].experimentType

      if (experimentType === tabToShow) {
        return
      }

      handleToggleGroupClick(experimentType)

      if (experimentType === 'content') {
        const isContentFormValid = getIsContentFormValid(form, template)
        const status = isContentFormValid ? 'complete' : 'incomplete'
        dispatch(
          templateSetupComplete({
            internalId: template.internalId,
            status,
          })
        )
      }
    }

    setConfirmModalTypeShown(undefined)
  }

  const confirmModalText =
    confirmModalTypeShown === 'remove'
      ? 'Are you sure you want to remove this template? Your changes will be lost'
      : 'Are you sure you want to change the campaign type? Your changes will be lost.'

  useEffect(() => {
    const activeItems = items.filter((item) => !item.disabled)
    if (!selectedExperimentType) {
      if (activeItems.length === 1 && !tabToShow) {
        setTabToShow(activeItems[0].value)
      } else if (activeItems.length > 1 && !tabToShow) {
        setTabToShow(activeItems[1].value)
      }
    }
  }, [items, tabToShow, selectedExperimentType])

  return (
    <div
      className="bg-gold-40 flex flex-col p-6 rounded-sm"
      data-testid="added-template-card"
    >
      <div className="flex items-center mb-6">
        <ElementCardIcon icon={icon} size={6} className="mr-4" />
        <Field<string>
          name={getTemplatePrefixedName(template, 'experimentType')}
          initialValue={tabToShow}
        >
          {({ input }) => (
            <ToggleButtonGroup
              items={items}
              value={tabToShow}
              onChange={(value) => {
                input.onChange(value)
                onChangeType(value)
              }}
              className="uppercase"
            />
          )}
        </Field>
        <div className="flex ml-auto">
          <Button
            onClick={() => {
              if (formStatus === 'pristine' || tabToShow === 'content') {
                handleRemoveTemplate()
              } else {
                setConfirmModalTypeShown('remove')
              }
            }}
            className="px-0 ml-4 h-4"
            variant="icon"
            aria-label="close"
          >
            <CloseSquareIcon
              className="text-base-700 hover:text-gold-500 bezier-all-transition"
              state="default"
              size={4}
            />
          </Button>
        </div>
      </div>

      <p
        className="text-xl font-normal text-base-700"
        data-testid="template-name"
      >
        {displayName}
      </p>
      <ToggleButtonGroupTabs tabToShow={tabToShow} template={template} />
      <ConfirmationModal
        open={!!confirmModalTypeShown}
        title={
          formStatus === 'complete'
            ? 'Hold on!'
            : 'Your template setup is incomplete!'
        }
        data-cy="experiment-form-confirmation-modal"
        data-testid="experiment-form-confirmation-modal"
        confirmationText={confirmModalText}
        cancelButtonText="Cancel"
        confirmButtonText={
          confirmModalTypeShown === 'remove' ? 'Remove template' : 'Change type'
        }
        confirmButtonVariant="primary"
        onConfirm={onModalConfirm}
        onCancel={() => {
          if (confirmModalTypeShown === 'change') {
            form.change(`templateId-${internalId}`, {
              ...formState.values[`templateId-${internalId}`],
              experimentType: tabToShow,
            })
          }
          setConfirmModalTypeShown(undefined)
        }}
      />
    </div>
  )
}

export default AddedComponentCard
