import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { getCustomerJourneys } from '../api'
import { contentKeys } from '../queryKeys'

const useGetCustomerJourneyQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getCustomerJourneyQuery = useQuery(
    contentKeys.customerJourneys(accountId),
    () => getCustomerJourneys({ accountId }),
    {
      select: (data) =>
        data.map((customerJourney) => ({
          label: customerJourney.displayName,
          value: customerJourney.value,
        })),
    }
  )

  return getCustomerJourneyQuery
}

export default useGetCustomerJourneyQuery
