import { useFlags } from 'launchdarkly-react-client-sdk'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import {
  isComponentElement,
  isTemplateElement,
} from 'features/unifiedFlow/api/interfaces'
import {
  getExperimentBadgesState,
  hasDisabledAllElements,
  hasOneElement,
} from 'features/unifiedFlow/contentPage/helpers'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import AddTemplateButton from '../../components/AddTemplateButton'
import ElementCard from '../../components/ElementCard'
import ElementTemplateCard from '../../components/ElementTemplateCard'
import ExperimentTypeBadges from '../../components/ExperimentTypeBadges'
import UniversalBriefElement from '../../components/universalBriefElement'
import { useContent } from '../../hooks'
import { getWsTopic } from '../../hooks/websocketMessage/helpers'

const ElementsList = () => {
  const dispatch = useAppDispatch()

  const { content } = useContent()
  const { showImageExperimentSetup: showImageExperimentSetupFlagEnabled } =
    useFlags()
  const elements = content?.elements

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )
  const isUsingUniversalBrief = useAppSelector(
    (state) => state.unifiedFlow.isUsingUniversalBrief
  )

  const isRemoveOptionShown = content?.elements && content.elements.length > 1

  const handleElementSelect = (elementId: number) => {
    dispatch(selectElement({ elementId }))
  }

  return (
    <>
      <div role="tablist" data-testid="elementsContainer">
        {elements && elements.length > 0 && isUsingUniversalBrief && (
          <UniversalBriefElement />
        )}
        {elements?.map((element, index) => {
          const elementIndex = index + 1

          const wsTopicBrief = content
            ? getWsTopic({
                data: {
                  contentId: content._id,
                  elementId: element.element_id,
                },
                action: 'generateElementBrief',
              })
            : ''
          const wsTopicVariants = content
            ? getWsTopic({
                data: {
                  contentId: content._id,
                  elementId: element.element_id,
                },
                action: 'generateElementVariants',
              })
            : ''

          const isLoading = content?.ws_messages?.some(
            (msg) =>
              msg.wsTopic === wsTopicVariants || msg.wsTopic === wsTopicBrief
          )

          if (isComponentElement(element)) {
            const {
              element_id,
              name,
              display_name,
              icon,
              status,
              approved,
              nlg_status,
            } = element
            const badgesState = getExperimentBadgesState(element)
            const isElementDisabled = status === 'disabled'
            const hasError = nlg_status === 'error'

            return (
              <ElementCard
                className="mb-4"
                key={element_id}
                element={element}
                title={display_name || name}
                icon={icon}
                index={elementIndex}
                isLoading={isLoading}
                isApproved={approved}
                isDisabled={isElementDisabled}
                isRemoveOptionShown={isRemoveOptionShown}
                isSelected={
                  element_id === selectedElementId && !isElementDisabled
                }
                hasError={hasError}
                isConfigurable={true}
                content={
                  badgesState && <ExperimentTypeBadges {...badgesState} />
                }
                onClick={() =>
                  !isElementDisabled && handleElementSelect(element_id)
                }
              />
            )
          }

          if (isTemplateElement(element)) {
            if (hasDisabledAllElements(element.elements)) {
              const { element_id, name, display_name, icon, approved } = element
              return (
                <ElementCard
                  key={element_id}
                  className="mb-4"
                  index={elementIndex}
                  isLoading={isLoading}
                  element={element}
                  title={display_name || name}
                  icon={icon}
                  isApproved={approved}
                  isRemoveOptionShown={isRemoveOptionShown}
                  isSelected={element_id === selectedElementId}
                  isConfigurable={true}
                  onClick={() => handleElementSelect(element_id)}
                />
              )
            }

            if (!hasDisabledAllElements(element.elements)) {
              if (
                showImageExperimentSetupFlagEnabled &&
                hasOneElement(element.elements)
              ) {
                const { name, display_name, icon } = element
                const firstElement = element.elements[0]
                const { element_id, approved, status } = firstElement
                const hasError = isComponentElement(firstElement)
                  ? firstElement.nlg_status === 'error'
                  : false
                const isElementDisabled = status === 'disabled'
                const badgesState = getExperimentBadgesState(firstElement)

                return (
                  <ElementCard
                    className="mb-4"
                    key={element_id}
                    title={display_name || name}
                    icon={icon}
                    index={elementIndex}
                    isLoading={isLoading}
                    element={element}
                    isApproved={approved}
                    hasError={hasError}
                    isDisabled={isElementDisabled}
                    isRemoveOptionShown={isRemoveOptionShown}
                    isSelected={
                      element_id === selectedElementId && !isElementDisabled
                    }
                    isConfigurable={true}
                    content={
                      badgesState && <ExperimentTypeBadges {...badgesState} />
                    }
                    onClick={() =>
                      !isElementDisabled && handleElementSelect(element_id)
                    }
                  />
                )
              }

              return (
                <ElementTemplateCard
                  className="mb-4"
                  key={element.element_id}
                  index={elementIndex}
                  isLoading={isLoading}
                  element={element}
                  isRemoveOptionShown={isRemoveOptionShown}
                />
              )
            }
          }

          return null
        })}
      </div>
      <AddTemplateButton />
    </>
  )
}

export default ElementsList
