import { CampaignData } from '@phrasee/phrasee-typings/Graphql/interfaces'
import moment from 'moment'

import Alert from 'common/components/alert'
import CheckboxComponent from 'common/components/checkbox/Checkbox'
import { Info } from 'common/icons'
import Icon from 'common/icons/Icon'

interface DrawerAlertProps {
  testModeDescription: string
  campaignData: CampaignData
  isPaused: boolean
  setIsPaused: (isPaused: boolean) => void
}

export const TestModeAlert = ({
  testModeDescription,
  campaignData,
  isPaused,
  setIsPaused,
}: DrawerAlertProps) => {
  const isCurrentTimeBeforeStartDate = moment().isBefore(
    moment(campaignData?.optimization_start_time)
  )
  const testModeEnabled = isPaused || isCurrentTimeBeforeStartDate
  return (
    <Alert className="mt-5 mb-6" type={testModeEnabled ? 'warn' : 'success'}>
      <div className="flex flex-row content-between mb-6 pt-2">
        <div className="flex-1">
          <CheckboxComponent
            isDisabled={isCurrentTimeBeforeStartDate}
            data-cy="run-in-test-mode-checkbox"
            data-testid="run-in-test-mode-checkbox"
            label="Run in test mode"
            labelColor="text-white"
            onChange={setIsPaused}
            isChecked={testModeEnabled}
          />
        </div>
        <div className="w-6 h-6">
          <Icon
            isDefaultColor={false}
            className="text-white"
            defaultSvg={Info}
          />
        </div>
      </div>
      <p>{testModeDescription}</p>
    </Alert>
  )
}
