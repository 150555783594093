import { useState } from 'react'
import { components, GroupBase, OptionProps } from 'react-select'
import cx from 'classnames'
import copy from 'copy-to-clipboard'

import Button from 'common/components/button'
import IconTitleGroup from 'common/components/iconTitleGroup'
import Input from 'common/components/input/Input'
import Label from 'common/components/label/Label'
import Loader from 'common/components/loaders/Loader'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'
import { Copy as CopyIcon } from 'common/icons'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'
import useStartPersonalizationMutation from 'features/unifiedFlow/api/mutations/useStartPersonalizationMutation'

import { CurrentUsersBanner } from '../../components/CurrentUsersBanner'
import { useContent, useSelectedComponentElement } from '../../hooks'

import useGetTokensQuery from './api/queries/useGetTokensQuery'

export const Option = <T extends SelectValue>({
  isSelected,
  children,
  ...rest
}: OptionProps<T, false, GroupBase<T>>) => {
  return (
    <components.Option isSelected={isSelected} {...rest}>
      <div
        className={cx(
          `mx-3 px-3 py-2 flex justify-between items-center hover:bg-gray-100 whitespace-pre-line gap-4
          cursor-pointer`,
          {
            'text-coolGray-800': !isSelected,
            'text-maroon-400': isSelected,
          }
        )}
      >
        <div className="mr-4" role="option" aria-selected={isSelected}>
          {children}
        </div>
        <div className="max-w-22 truncate text-base-300">{rest.data.value}</div>
      </div>
    </components.Option>
  )
}

const PersonalizeView = () => {
  const { data: selectedElement } = useSelectedComponentElement()
  const isLoadingCampaignData = useAppSelector(
    (state) => state.campaignStates.isLoadingCampaignDetails
  )

  const tokensQuery = useGetTokensQuery()
  const [selectedToken, setSelectedToken] = useState<string | undefined>(
    selectedElement?.personalization_settings?.configuration
      .personalization_launch_token
  )

  const startPersonalizationMutation = useStartPersonalizationMutation()
  const { content } = useContent()

  const isPersonalizationLive =
    selectedElement !== undefined && isComponentElement(selectedElement)
      ? !!selectedElement.personalization_settings?.configuration
          .personalization_start_date
      : undefined

  const personalizationIntegrationUrl =
    selectedElement !== undefined && isComponentElement(selectedElement)
      ? selectedElement.personalization_integration_url ?? ''
      : ''

  if (!selectedElement) {
    return null
  }

  const handleCopy = (text: string) => {
    copy(text)
    successToast('Token copied to your clipboard')
  }
  const name = selectedElement.display_name ?? selectedElement.name

  const onClickStartPersonalization = () => {
    if (content && selectedToken) {
      startPersonalizationMutation.mutate({
        accountId: content.account_id,
        elementId: selectedElement.element_id,
        contentId: content?._id,
        tokenId: selectedToken,
      })
    }
  }
  return isLoadingCampaignData || tokensQuery.isLoading ? (
    <Loader className="m-auto" />
  ) : (
    <section className="bg-gold-40 w-full flex flex-col max-w-screen-sm mr-8">
      <CurrentUsersBanner />
      <header>
        <h5
          className="text-base-700 text-4xl font-medium mb-2.5"
          data-testid="launch-heading"
        >{`Launch: ${name}`}</h5>
        <p className="text-base-700 text-base font-normal">
          Copy URL/s into your provider and start your personalisation
        </p>
      </header>
      <div className="border flex items-stretch border-gold-400 my-10" />

      <article>
        <IconTitleGroup title="API TOKEN" className="pb-0.5" />
        <div className="text-base-700 text-xs">
          Choose which API token can be used with your provider.
        </div>
        <form className="flex gap-4 mt-5">
          <SingleSelect
            options={tokensQuery.data?.data.map((token) => ({
              label: `${token.customer_identifier}`,
              value: token.token,
            }))}
            value={selectedToken}
            components={{
              Option,
            }}
            onChange={(value) => value && setSelectedToken(value.value)}
            className="max-w-16 flex-1"
            label="Token name"
            noOptionsMessage={() => (
              <span className="uppercase">No token detected</span>
            )}
          />
          <div className="max-w-16 flex-1 relative">
            <Label>Token</Label>
            <Input
              type="text"
              className={cx('truncate', {
                'cursor-pointer': selectedToken !== undefined,
              })}
              width={300}
              value={selectedToken ?? '-'}
              readOnly
              disabled={selectedToken === undefined}
              suffixIcon={
                <Button
                  disabled={selectedToken === undefined}
                  onClick={() => selectedToken && handleCopy(selectedToken)}
                  suffixIcon={<CopyIcon size={4} />}
                />
              }
              onMouseDown={(e) => e.preventDefault()}
            />
          </div>
        </form>
      </article>
      <article className="mt-10">
        <IconTitleGroup title="Intergration URL" className="pb-0.5" />
        <div>
          <div className="text-base-700 text-xs">API URL</div>
        </div>
        <div className="mt-2 border border-gold-400 w-full rounded-sm bg-gold-100 px-4 py-2 flex gap-3 items-center">
          <div>
            <Button
              onClick={() => {
                copy(personalizationIntegrationUrl)
                successToast('Integration URL copied to your clipboard')
              }}
              suffixIcon={<CopyIcon size={4} />}
            />
          </div>
          <div className="break-all">{personalizationIntegrationUrl}</div>
        </div>
        <div className="text-base-700 text-xs mt-2">
          This allows your provider to connect to the correct experiment within
          Jacquard.{' '}
          <a
            href="https://support.jacquard.com/en/articles/10223324-personalised-campaigns-getting-started"
            target="blank"
            className="text-gold-600"
          >
            Learn more
          </a>
        </div>
      </article>
      <div className="flex items-end justify-end">
        <Button
          onClick={() =>
            isPersonalizationLive
              ? errorToast('Not yet implemented')
              : onClickStartPersonalization()
          }
          loading={startPersonalizationMutation.isLoading}
          variant="primary"
          className="mt-8 uppercase flex"
          disabled={
            selectedToken === undefined ||
            startPersonalizationMutation.isLoading
          }
        >
          {isPersonalizationLive ? 'Pause' : 'Start personalisation'}
        </Button>
      </div>
    </section>
  )
}

export default PersonalizeView
