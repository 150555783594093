import { useState } from 'react'
import { MenuItem } from '@szhsin/react-menu'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { Member } from 'common/api/teamsApi'
import Avatar from 'common/components/Avatar'
import Button from 'common/components/button/Button'
import ConfirmationModal from 'common/components/confirmationModal'
import ErrorPage from 'common/components/error/ErrorPage'
import OptionMenuButton from 'common/components/optionsMenuButton'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import TableWidget from 'common/components/widget/tableWidget/TableWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'
import { avatarColors } from 'features/profile/AvatarColorPicker'

import AddUsersModal from '../all/modal/AddUsersModal'
import NoResults from '../holdingPages/NoResults'
import {
  clickDeleteTeam,
  clickDeleteTeamMember,
  clickInviteUsers,
  hideAddUserModal,
} from '../store/usersSlice'

import Title from './Title'

type Props = {
  onEditTeam: ({ teamId }: { teamId: string }) => void
  searchTeamValue: string
}

export type MemberRow = Member & { teamId: string }

const TeamsNew = ({ onEditTeam, searchTeamValue }: Props) => {
  const { hasTeamTabError, teams } = useAppSelector((state) => state.adminUsers)
  const { assignableRoles, isUserModalVisible } = useAppSelector(
    (state) => state.adminUsers
  )
  const dispatch = useAppDispatch()
  const flags = useFlags()

  const [userToDelete, setUserToDelete] = useState<string>()
  const [teamIdToDelete, setTeamIdToDelete] = useState<string>()

  const teamsFiltered = teams.filter(({ name }) =>
    name
      .replaceAll(' ', '')
      .toLocaleLowerCase()
      .startsWith(searchTeamValue.replaceAll(' ', '').toLocaleLowerCase())
  )

  const columns: Column<MemberRow>[] = [
    {
      Header: 'Name',
      accessor: 'firstName',
      Cell: ({ row }) => {
        const { firstName, lastName } = row.original
        const avatarColor = avatarColors[row.index % avatarColors.length]

        return (
          <BaseCell className="flex items-center">
            <Avatar
              firstName={firstName}
              lastName={lastName}
              className="text-xs w-7 h-7 min-w-7 mr-3"
              color={avatarColor}
            />
            <span>{`${firstName} ${lastName}`}</span>
          </BaseCell>
        )
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }) => (
        <BaseCell className="flex items-center">{value}</BaseCell>
      ),
    },
    {
      Header: 'Role',
      accessor: 'roles',
      Cell: ({ value }) => (
        <BaseCell className="flex items-center capitalize">{value}</BaseCell>
      ),
    },
    generateDeleteButtonColumn<MemberRow>({
      onClick: (row) => {
        setTeamIdToDelete(row.teamId)
        setUserToDelete(row.id)
      },
      buttonLabel: 'Delete member',
      isDefaultColor: false,
    }),
  ]

  const options = (teamId: string) => (
    <>
      <MenuItem
        data-cy="delete-team-option"
        data-testid="delete-team-option"
        key="1"
        onClick={() => {
          setTeamIdToDelete(teamId)
        }}
      >
        Delete team?
      </MenuItem>
    </>
  )

  if (hasTeamTabError) {
    return <ErrorPage />
  }

  if (teams.length > 0 && teamsFiltered.length === 0) {
    return (
      <div className="text-5xl font-bold text-coolGray-800">
        No results found for '{searchTeamValue}'
      </div>
    )
  }
  return teams.length === 0 ? (
    <NoResults />
  ) : (
    <div className="grid gap-y-10">
      {teamsFiltered.map(({ id, members, name }) => (
        <TableWidget.Widget
          key={id}
          columns={columns}
          data={(members as MemberRow[])
            .filter(({ blocked }) => !blocked)
            .map((member, index) => ({
              id: member.id,
              teamId: id,
              email: member.email,
              firstName: member.firstName,
              lastName: member.lastName,
              roles: member.roles,
              avatarColor: avatarColors[index % avatarColors.length],
            }))}
          firstUseText="Nothing to see here… There is no users to show."
        >
          <TableWidget.Header
            title={
              <Title
                name={name}
                id={id}
                teams={teams}
                members={members}
                titleClassName="text-xl"
              />
            }
          >
            <Button
              data-cy="add-users-button"
              data-testid="add-users-button"
              aria-label="Add users to the team"
              variant="primary"
              prefixIcon={<AddIcon width={24} height={24} />}
              onClick={() => {
                onEditTeam({ teamId: id })
              }}
            >
              User
            </Button>
            <div className="ml-5 flex items-center">
              <OptionMenuButton name="widget menu" options={options(id)} />
            </div>
          </TableWidget.Header>
        </TableWidget.Widget>
      ))}
      <AddUsersModal
        assignableRoles={assignableRoles}
        isVisible={isUserModalVisible}
        onClose={() => {
          dispatch(hideAddUserModal())
        }}
        onClickInviteUsers={(users) => {
          dispatch(clickInviteUsers(users))
        }}
      />
      <ConfirmationModal
        data-cy="delete-confirmation-modal"
        data-testid="delete-confirmation-modal"
        title={
          userToDelete
            ? 'Remove user from team?'
            : `Delete team ${
                teams.find(({ id }) => teamIdToDelete === id)?.name ?? ''
              }?`
        }
        confirmationText={
          userToDelete
            ? 'This is sad… this user will be removed from this team and if they where added to any experiments, as part of this team, they will be removed from there too.'
            : 'This is all great but… all experiments that use this team will have this team removed and you will need to add another team per experiment if you need them. No sweat really, its not critical.'
        }
        confirmButtonText={userToDelete ? 'Remove them' : 'Delete it already'}
        cancelButtonText={userToDelete ? "Oh, I can't do it" : 'Cancel'}
        open={teamIdToDelete !== undefined}
        onConfirm={() => {
          if (teamIdToDelete) {
            if (userToDelete) {
              dispatch(
                clickDeleteTeamMember({
                  teamId: teamIdToDelete,
                  id: userToDelete,
                  showNewTeamPage: flags.showNewTeamPage,
                })
              )
            } else {
              dispatch(
                clickDeleteTeam({
                  teamId: teamIdToDelete,
                  showNewTeamPage: flags.showNewTeamPage,
                })
              )
            }
          }
          setTeamIdToDelete(undefined)
          setUserToDelete(undefined)
        }}
        onCancel={() => {
          setTeamIdToDelete(undefined)
          setUserToDelete(undefined)
        }}
      />
    </div>
  )
}

export default TeamsNew
