import cx from 'classnames'

type Props = {
  children: React.ReactNode
  className?: string
  'data-testid'?: string
}

const GridContainer = ({
  children,
  className,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <div
      className={cx(
        'grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-y-6 gap-x-4 sm:gap-x-6 auto-rows-fr',
        className
      )}
      data-testid={dataTestId}
    >
      {children}
    </div>
  )
}

export default GridContainer
