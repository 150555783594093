import { useEffect, useState } from 'react'
import cx from 'classnames'

import Loader from 'common/components/loaders/Loader'

import Overlay from './Overlay'

type Props = {
  isLoading: boolean
  title?: string
  className?: string
  phrases: string[]
  overlayType?: 'local' | 'global'
}

const LoadingOverlay = ({
  title,
  isLoading,
  overlayType,
  phrases,
  className,
}: Props) => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0)

  useEffect(() => {
    if (isLoading) {
      const interval = setInterval(() => {
        setCurrentPhraseIndex((prev) =>
          phrases.length - 1 > prev ? prev + 1 : prev
        )
      }, 5000)
      return () => clearInterval(interval)
    } else {
      setCurrentPhraseIndex(0)
    }
  }, [isLoading, phrases.length])

  return isLoading ? (
    <Overlay bgColor="bg-white" type={overlayType} className={className}>
      <div className="flex flex-col justify-center items-center text-white text-3xl ">
        <Loader />
        {title && (
          <div className="text-coolGray-900 text-base font-medium mt-4">
            {title}
          </div>
        )}
        <div
          className={cx('mt-5', {
            'text-coolGray-700 text-base': title,
            'text-coolGray-600 font-medium text-lg': !title,
          })}
        >
          {phrases[currentPhraseIndex]}
        </div>
      </div>
    </Overlay>
  ) : null
}

export default LoadingOverlay
