import { useMemo, useState } from 'react'
import { Role } from '@phrasee/phrasee-typings/typings/user/user'

import { getIsAuthorized, usersPermissions } from 'common/auth/permissions'
import Avatar from 'common/components/Avatar'
import Button from 'common/components/button/Button'
import ConfirmationModal from 'common/components/confirmationModal'
import ErrorPage from 'common/components/error/ErrorPage'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { avatarColors } from 'features/profile/AvatarColorPicker'

import NoResults from '../holdingPages/NoResults'
import { changeUserRole, clickReactivateUser } from '../store/usersSlice'

import { User } from './api'
import RoleSelect from './RoleSelect'

type Props = {
  searchValue: string
  users: User[]
  selectedProjectIds: string[]
  assignableRoles: Role[]
}
const DeactivatedUsers = ({
  searchValue,
  users,
  selectedProjectIds,
  assignableRoles,
}: Props) => {
  const { hasDeactivatedTabError } = useAppSelector((state) => state.adminUsers)
  const dispatch = useAppDispatch()

  const { permissions } = useAppSelector((state) => state.authStates)

  const [userIdToReactivate, setUserIdToReactivate] = useState<string>()

  const usersFiltered = useMemo(() => {
    const filterByNameAndEmail = ({ firstName, lastName, email }) =>
      [firstName, lastName, email]
        .join('')
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(searchValue.replaceAll(' ', '').toLowerCase())
    const filterByProjectIds = ({ projectIds }) =>
      selectedProjectIds.length
        ? projectIds.some((id) => selectedProjectIds.includes(id))
        : true
    return users.filter(filterByNameAndEmail).filter(filterByProjectIds)
  }, [selectedProjectIds, searchValue, users])
  const isFiltered = usersFiltered.length !== users.length

  const sortByFirstRole = useMemo(() => {
    return (rowA: any, rowB: any, id: string) => {
      return rowA.values[id][0] > rowB.values[id][0] ? 1 : -1
    }
  }, [])

  const columns: Column<User>[] = [
    {
      Header: 'Name',
      accessor: 'firstName',
      Cell: ({ row }) => {
        const { firstName, lastName } = row.original
        return (
          <BaseCell className=" h-full flex items-center">
            <Avatar
              firstName={firstName}
              lastName={lastName}
              className="text-xs w-7 h-7 mr-3"
              color={avatarColors[row.index % avatarColors.length]}
            />
            <span className="font-medium">{`${firstName} ${lastName}`}</span>
          </BaseCell>
        )
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }) => (
        <BaseCell className=" h-full flex items-center">
          <span>{value}</span>
        </BaseCell>
      ),
    },
    {
      Header: 'Role',
      accessor: 'roles',
      sortType: sortByFirstRole,
      // sortType: memo,
      Cell: ({ value, row }) => (
        <BaseCell
          className="h-full flex items-center "
          style={{ maxWidth: 150 }}
        >
          <RoleSelect
            className="w-full sm:w-60 font-medium"
            assignableRoles={assignableRoles}
            isSearchable={true}
            isInsideTable={true}
            value={value[0]}
            onChange={(val) => {
              if (val?.value) {
                dispatch(
                  changeUserRole({
                    roles: [val?.value],
                    userId: row.original.id,
                  })
                )
              }
            }}
          />
        </BaseCell>
      ),
    },
    {
      id: 'reactivate',
      accessor: 'id',
      disableSortBy: true,
      align: 'right',
      minWidth: 80,
      Cell: ({ row }) => (
        <BaseCell className=" h-full flex items-center justify-end">
          {getIsAuthorized(permissions, usersPermissions.deactivate) && (
            <Button
              aria-label="Reactivate user"
              variant="primary"
              onClick={() => {
                setUserIdToReactivate(row.original.id)
              }}
            >
              Reactivate
            </Button>
          )}
        </BaseCell>
      ),
    },
  ]

  if (hasDeactivatedTabError) {
    return <ErrorPage />
  }

  if (users.length > 0 && usersFiltered.length === 0) {
    return (
      <div className="text-5xl font-bold text-coolGray-800 leading-normal">
        There are no users matching that search
      </div>
    )
  }
  return users.length === 0 ? (
    <NoResults />
  ) : (
    <div className="grid gap-y-10">
      <div>
        <TableWidget.Widget
          isLoading={false}
          hasError={false}
          columns={columns}
          data={usersFiltered}
          initialState={{ pageSize: 15 }}
          autoResetPage={false}
        >
          <TableWidget.Header
            title={
              <div className="flex items-center" style={{ height: 38 }}>
                {isFiltered
                  ? `${usersFiltered.length} Search result`
                  : 'Deactivated users'}
              </div>
            }
          >
            <TableWidget.Menu />
          </TableWidget.Header>
        </TableWidget.Widget>
      </div>

      <ConfirmationModal
        data-cy="reactivate-confirmation-modal"
        data-testid="reactivate-confirmation-modal"
        title="Reactivate user?"
        confirmationText="This user will be reactivated and have access to previous projects and campaigns."
        confirmButtonText="Reactivate now"
        cancelButtonText="Stop, don't do it"
        open={userIdToReactivate !== undefined}
        hasConfirmationSlider={true}
        onConfirm={() => {
          if (userIdToReactivate) {
            dispatch(clickReactivateUser(userIdToReactivate))
          }
          setUserIdToReactivate(undefined)
        }}
        onCancel={() => {
          setUserIdToReactivate(undefined)
        }}
      />
    </div>
  )
}

export default DeactivatedUsers
