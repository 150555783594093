import { ReactNode } from 'react'
import cx from 'classnames'

type Props = {
  children?: ReactNode
  className?: string
  id?: string
}

const MainContent = ({ children, className, id }: Props) => {
  return (
    <div
      className={cx(
        className,
        'relative flex h-full bg-gold-40 overflow-y-auto overflow-x-hidden'
      )}
      id={id}
    >
      {children}
    </div>
  )
}

export default MainContent
