import { useCallback, useEffect, useMemo, useState } from 'react'

import AnimatedOverlay from 'common/components/animatedOverlay'
import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import Loader from 'common/components/loaders/Loader'
import Tabs, { addCountToTabName, Tab } from 'common/components/tabs'
import { MoreOptions } from 'common/icons'
import useGetTemplatesQuery from 'features/futurama/api/queries/useGetTemplatesQuery'

import { contentTypesByTab } from '../ContentsPage'
import { TemplateInfo } from '../interfaces'

import FuturamaAutocomplete from './FuturamaAutocomplete'
import TemplatesGrid from './TemplatesGrid'
import TemplatesToAddBar from './TemplatesToAddBar'

export type TemplatesMode = 'add' | 'select'

type Props = {
  isVisible: boolean
  intialSelectedTab: string
  mode?: TemplatesMode
  onClose: () => void
}
const Templates = ({
  isVisible,
  onClose,
  intialSelectedTab,
  mode = 'select',
}: Props) => {
  const { data, status } = useGetTemplatesQuery()

  const [selectedTab, setSelectedTab] = useState(intialSelectedTab)
  const [searchContentValue, setSearchContentValue] = useState<string>('')

  useEffect(() => {
    setSelectedTab(intialSelectedTab)
  }, [intialSelectedTab])

  const filterByContentType = useCallback(
    ({ contentType }: TemplateInfo) =>
      selectedTab === 'all' ? true : contentType.toLowerCase() === selectedTab,
    [selectedTab]
  )

  const filterByName = useCallback(
    ({ displayName }: TemplateInfo) =>
      displayName
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(searchContentValue.replaceAll(' ', '').toLowerCase()),
    [searchContentValue]
  )

  const templatesFiltered = useMemo(() => {
    return (data ?? []).filter(filterByContentType).filter(filterByName)
  }, [data, filterByContentType, filterByName])

  const tabs: Tab[] = [
    {
      key: 'all',
      name: addCountToTabName({
        tabName: 'All',
        count: data?.length || 0,
      }),
      content:
        templatesFiltered.length === 0 && searchContentValue !== '' ? (
          <div className="text-5xl font-bold text-coolGray-800 leading-normal">
            There are no templates matching your search, please try again
          </div>
        ) : (
          <TemplatesGrid
            templates={templatesFiltered}
            contentTypes={contentTypesByTab}
            mode={mode}
          />
        ),
      isDisabled: false,
    },
    ...Object.keys(contentTypesByTab)
      .filter((category) => category !== 'all')
      .map((category) => ({
        key: category,
        name: addCountToTabName({
          tabName: category,
          count:
            data?.filter((item) => item.contentType.toLowerCase() === category)
              .length || 0,
        }),
        content:
          templatesFiltered.length > 0 ? (
            <TemplatesGrid
              contentTypes={contentTypesByTab}
              templates={templatesFiltered}
              mode={mode}
            />
          ) : (
            <div className="text-5xl font-bold text-coolGray-800 leading-normal">
              {searchContentValue === ''
                ? 'There are no saved templates for this category'
                : 'There are no templates matching your search, please try again'}
            </div>
          ),
        isDisabled: false,
      })),
  ]

  return (
    <AnimatedOverlay
      title={mode === 'add' ? 'Add a Template' : 'Templates'}
      className="overflow-y-auto"
      onClose={onClose}
      isVisible={isVisible}
    >
      {
        {
          success: (
            <div className="w-full h-full">
              <div className="flex flex-col w-full h-full lg:px-40 px-6 bg-gold-40  max-w-screen-lg mx-auto justify-between">
                <div className="flex justify-between items-stretch relative mb-8 mt-12">
                  <Tabs
                    tabs={tabs.filter((tab) => !tab.isDisabled)}
                    defaultActiveKey={selectedTab}
                    activeKey={selectedTab}
                    onTabClick={(tab) => {
                      setSelectedTab(tab)
                    }}
                    variant="filter"
                    moreIcon={<MoreOptions />}
                  />

                  <div className="flex absolute right-0 items-start">
                    <FuturamaAutocomplete
                      placeholder="Find template"
                      data-cy="template-search"
                      data-testid="template-search"
                      onSearchContentValue={setSearchContentValue}
                      options={data?.map((template: TemplateInfo) => ({
                        value: template.id,
                        label: template.displayName,
                      }))}
                    />
                  </div>
                </div>
                <Footer className="pt-0" />
                <TemplatesToAddBar />
              </div>
            </div>
          ),
          loading: <Loader />,
          error: <ErrorPage />,
        }[status]
      }
    </AnimatedOverlay>
  )
}

export default Templates
