import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import { deleteMergeTag } from '../api'
import { mergeTagsKeys } from '../queryKeys'

const useDeleteMergeTagMutation = ({
  tagName,
  tagId,
  onSuccess,
}: {
  tagName: string
  tagId: string
  onSuccess?: () => void
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => deleteMergeTag({ tagId, accountId }),
    onSuccess: async () => {
      onSuccess?.()
      successToast(`Merge tag ${tagName} successfully deleted`)
      queryClient.invalidateQueries({
        queryKey: mergeTagsKeys.list(accountId),
      })
    },
    onError: () => {
      errorToast('Merge tag could not be deleted')
    },
  })
}

export default useDeleteMergeTagMutation
