import Button from 'common/components/button'
import { ArrowRight } from 'common/icons'

type Props = {
  onClick: () => void
  buttonText: string
  isDisabled: boolean
  isLoading: boolean
}

const ApproveButton = ({
  onClick,
  buttonText,
  isDisabled,
  isLoading,
}: Props) => {
  return (
    <Button
      variant="primary"
      loading={isLoading}
      data-cy="approve-button"
      data-testid="approve-button"
      disabled={isDisabled}
      onClick={() => onClick()}
    >
      <div className="flex items-center gap-2">
        {buttonText}
        <ArrowRight size={4} />
      </div>
    </Button>
  )
}

export default ApproveButton
