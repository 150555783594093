import { useEffect, useRef, useState } from 'react'

import Tooltip from 'common/components/Tooltip'

export const ProjectName = ({ name }: { name: string }) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false)

  const titleRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    const currentTitleRef = titleRef.current!

    if (currentTitleRef.offsetWidth < currentTitleRef.scrollWidth) {
      setIsTooltipShown(true)
    } else {
      setIsTooltipShown(false)
    }
  }, [])

  return (
    <Tooltip overlay={name} placement="top" show={isTooltipShown}>
      <span
        ref={titleRef}
        className="pb-1 text-sm text-coolGray-400 mr-6 truncate block"
        data-cy="campaign-nav-item-project-name"
        data-testid="campaign-nav-item-project-name"
      >
        {name}
      </span>
    </Tooltip>
  )
}

export default ProjectName
