import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import MenuButton, { MenuItem } from 'common/components/menuButton'
import Base from 'common/components/table/cells/Base'
import {
  Bin,
  Check,
  Clipboard,
  MoreOptions,
  Refresh,
  Replace,
  Spinner,
} from 'common/icons'

type Props = {
  onCopy?: () => void
  onRefresh?: () => void
  onDelete?: () => void
  onRequestChange?: () => void
  isTweaking?: boolean
  isSuccessful?: boolean
}

const ActionCell = ({
  onCopy,
  onRefresh,
  onDelete,
  onRequestChange,
  isTweaking,
  isSuccessful,
}: Props) => {
  return (
    <Base className="text-coolGray-400">
      {onRefresh && (
        <ButtonWithTooltip
          tooltip
          tooltipText={isTweaking ? 'Refreshing...' : 'Refresh'}
          aria-label="Refresh"
          data-testid="refresh-variant"
          size="small"
          prefixIcon={
            isSuccessful ? (
              <Check isDefaultColor={false} />
            ) : isTweaking ? (
              <Spinner className="animate-spin text-maroon-200" />
            ) : (
              <Refresh isDefaultColor={false} className="hover:text-base-700" />
            )
          }
          onClick={!isTweaking ? onRefresh : undefined}
        />
      )}
      {onRequestChange && (
        <ButtonWithTooltip
          tooltip
          tooltipText={isTweaking ? 'Refreshing...' : 'Request a change'}
          aria-label="Request change"
          data-testid="request-change"
          size="small"
          prefixIcon={
            isSuccessful ? (
              <Check isDefaultColor={false} />
            ) : isTweaking ? (
              <Spinner className="animate-spin text-maroon-200" />
            ) : (
              <Replace
                isDefaultColor={false}
                className="text-coolGray-400 hover:text-base-700"
              />
            )
          }
          onClick={!isTweaking ? onRequestChange : undefined}
        />
      )}
      {(onCopy || onDelete) && (
        <MenuButton
          portal
          buttonProps={{
            'aria-label': 'More options',
            'data-testid': 'more-options',
            children: (
              <MoreOptions
                isDefaultColor={false}
                className="hover:text-base-700"
              />
            ),
          }}
          options={
            <>
              {onCopy && (
                <MenuItem key="1" onClick={onCopy} aria-label="Copy">
                  <Clipboard
                    isDefaultColor={false}
                    className="mr-4 text-coolGray-400"
                  />
                  Copy to clipboard
                </MenuItem>
              )}
              {onDelete && (
                <MenuItem key="2" onClick={onDelete} aria-label="Delete">
                  <Bin
                    isDefaultColor={false}
                    className="mr-4 text-coolGray-400"
                  />
                  Delete
                </MenuItem>
              )}
            </>
          }
        />
      )}
    </Base>
  )
}

export default ActionCell
