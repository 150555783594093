import cx from 'classnames'
import RCTooltip from 'rc-tooltip' // Tooltip is used but completly different than the one in the component library

import Button from 'common/components/button'
import { ReactComponent as AlertDefault } from 'common/icons/alert/default.svg'

import { CellValue } from '..'

import BaseCell from './Base'

import 'rc-tooltip/assets/bootstrap.css'
import styles from './Mismatch.module.css'

export type OnClickValidation = ({ rowIndex }: { rowIndex: number }) => void

const ValidationCell =
  ({
    onClickRevert,
    onClickKeep,
    canShowMessage,
  }: {
    onClickRevert: OnClickValidation
    onClickKeep: OnClickValidation
    canShowMessage: boolean
  }) =>
  ({ value, row, rows }: CellValue) => {
    const { hasError, originalVariant } = row.original
    const firstRowIndexWithError = rows.findIndex(
      (row) => row.original.hasError
    )
    const isFirstRowWithError =
      canShowMessage && firstRowIndexWithError === row.index
    const Cell = (
      <BaseCell
        className={cx(
          'px-4 child-cell flex border border-transparent flex-1 justify-between ',
          {
            'hover:border-red-500 cursor-pointer text-red-400': hasError,
          }
        )}
        style={{ marginRight: 2 }}
      >
        <div className="whitespace-pre-line">{value}</div>
        {hasError && <AlertDefault width={24} height={24} />}
      </BaseCell>
    )
    return hasError ? (
      <RCTooltip
        defaultVisible={isFirstRowWithError}
        overlay={
          <div
            className="flex flex-col p-6 items-center"
            style={{ width: 320 }}
          >
            <div className="text-coolGray-800 text-2xl mb-4 font-bold">
              Mismatch found
            </div>
            <div className="text-coolGray-400 text-sm mb-2 font-medium">
              Original variant
            </div>
            <div className="text-base-700 text-base text-center whitespace-pre-line">
              {originalVariant}
            </div>
            <Button
              ghost={true}
              className="mt-8 mb-4 self-stretch"
              onClick={() => onClickKeep({ rowIndex: row.index })}
            >
              Keep my version
            </Button>
            <Button
              variant="primary"
              className="self-stretch"
              onClick={() => onClickRevert({ rowIndex: row.index })}
            >
              Revert to the original
            </Button>
          </div>
        }
        overlayClassName={cx(styles.overlay)}
        trigger="click"
      >
        {Cell}
      </RCTooltip>
    ) : (
      Cell
    )
  }

export default ValidationCell
