import { useState } from 'react'
import { useQueries } from '@tanstack/react-query'

import Loader from 'common/components/loaders/Loader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { getVariableCombinations } from 'features/unifiedFlow/api'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'
import { contentKeys } from 'features/unifiedFlow/api/queryKeys'
import { getExperimentBadgesState } from 'features/unifiedFlow/contentPage/helpers'
import { selectElement } from 'features/unifiedFlow/store/unifiedFlowSlice'

import ElementCard from '../../components/ElementCard'
import ExperimentTypeBadges from '../../components/ExperimentTypeBadges'
import ElementCardPersonalize from '../../components/personalizeCard/ElementCardPersonalize'
import { useContent, useSelectedComponentElement } from '../../hooks'
import useVariantsToApproveQuery from '../../hooks/useVariantsToApproveQuery'

type Props = {
  onSelectPersonalization: (personalizationId: string | undefined) => void
  selectedPersonalizationId: string | undefined
}

const ElementsList = ({
  selectedPersonalizationId,
  onSelectPersonalization,
}: Props) => {
  const dispatch = useAppDispatch()
  const [expandedElementId, setExpandedElementId] =
    useState<number | undefined>(undefined)

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )

  const { elementsWithNewLanguage, contentWithNewVariants } =
    useVariantsToApproveQuery()

  const accountId = useAppSelector((state) => state.authStates.accountId)
  const { content } = useContent()

  const elements = content?.elements
  const { data: selectedElement } = useSelectedComponentElement()

  const queries =
    elements
      ?.filter(isComponentElement)
      .filter((element) => !!element.personalization_id)
      .map((element) => {
        const currentPersonalization =
          element.personalization_id === selectedElement?.personalization_id
            ? element
            : undefined

        const region =
          currentPersonalization?.personalization_settings?.configuration
            .privacy_region ?? ''

        return {
          queryKey: contentKeys.variableCombinations({
            id: element?.personalization_id ?? '',
            region,
            accountId,
          }),
          queryFn: () =>
            getVariableCombinations({
              accountId,
              contentId: element!.personalization_id!,
              region: region ?? '',
            }),
          enabled: !!element?.personalization_id && !!region,
        }
      }) ?? []

  const results = useQueries({
    queries,
  })

  return (
    <Loader
      className="m-auto"
      hasWrapperFullHeight
      isLoading={results.some((result) => result.isInitialLoading)}
    >
      <div
        role="tablist"
        data-testid="elementsContainer"
        className="flex flex-col gap-4"
      >
        {elementsWithNewLanguage.map((element) => {
          const { element_id, name, display_name, icon, status } = element
          const badgesState = getExperimentBadgesState(element)

          const isElementDisabled = status === 'disabled'

          const hasError = isComponentElement(element)
            ? element.nlg_status === 'error'
            : undefined

          const commonProps = {
            key: element_id,
            element,
            title: display_name || name,
            icon,
            content: badgesState && <ExperimentTypeBadges {...badgesState} />,
          }

          const commonPropsComponentElement = {
            ...commonProps,
            isDisabled: isElementDisabled,
            hasError,
            isSelected: element_id === selectedElementId && !isElementDisabled,
            onClick: () => {
              if (selectedElementId !== element_id) {
                onSelectPersonalization(undefined)
                setExpandedElementId(undefined)
              }

              if (selectedPersonalizationId) {
                onSelectPersonalization(undefined)
              }

              !isElementDisabled &&
                dispatch(selectElement({ elementId: element_id }))
            },
          }

          if (isComponentElement(element)) {
            const personalizedIndex = elementsWithNewLanguage.findIndex(
              (elt) => elt.element_id === element_id
            )

            return badgesState?.isPersonalizable ? (
              <ElementCardPersonalize
                {...commonPropsComponentElement}
                selectedPersonalizationId={selectedPersonalizationId}
                onClickPersonalizationId={onSelectPersonalization}
                variableCombinations={
                  results[personalizedIndex]?.data?.items ?? []
                }
                expandedElementId={expandedElementId}
                onSetExpandedElementId={(elementId) =>
                  setExpandedElementId(elementId)
                }
                elements={contentWithNewVariants}
              />
            ) : (
              <ElementCard {...commonPropsComponentElement} />
            )
          }

          return null
        })}
      </div>
    </Loader>
  )
}

export default ElementsList
