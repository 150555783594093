import cx from 'classnames'

import Button from 'common/components/button'

const NavButton = ({ children, className, ...rest }) => {
  const { disabled, onClick } = rest

  return (
    <Button
      size="small"
      className={cx(
        'bg-gold-300 DayPickerNavigation_button__horizontalDefault w-10 top-5 text-gold-700',
        className,
        {
          'hover:bg-yellow-200': !disabled,
          'opacity-50': disabled,
        }
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}
export default NavButton
