import { Fragment, useEffect } from 'react'
import { Field, useForm } from 'react-final-form'

import { SelectValue } from 'common/components/BaseSelect'
import Button from 'common/components/button'
import Input from 'common/components/input'
import { Add as AddIcon, Bin as BinIcon } from 'common/icons'
import { MergeTagListItem } from 'features/admin/accountSettings/api'

const MAX_MERGE_TAG_EXAMPLE_VALUES = 3

type Props = {
  selectedCategory: SelectValue
  systemMergeTags?: MergeTagListItem[]
}

const ExampleValues = ({ systemMergeTags, selectedCategory }: Props) => {
  const form = useForm()

  const initialExampleValues = systemMergeTags?.find(
    (tag) => tag.id === selectedCategory?.value
  )?.exampleValues

  const formStateValues = form.getState().values.merge_tag_example_values || []
  const hasAnEmpyInput =
    formStateValues.length > 0 && formStateValues.some((value) => !value)

  useEffect(() => {
    if (initialExampleValues?.length) {
      form.change(`merge_tag_example_values`, initialExampleValues)
    }
  }, [form, initialExampleValues])

  return (
    <Fragment>
      {formStateValues.map((inputValue, index) => (
        <div className="flex justify-between items-center mb-4" key={index}>
          <Field<string>
            key={index}
            name={`merge_tag_example_values[${index}]`}
            initialValue={inputValue ?? ''}
            render={({ input }) => (
              <Input
                type="text"
                id={`example_values[${index}]`}
                variant="default"
                className="text-coolGray-800"
                value={input.value}
                name={input.name}
                onChange={input.onChange}
              />
            )}
          />
          <Button
            variant="icon"
            onClick={() => {
              form.change(
                `merge_tag_example_values`,
                formStateValues.filter((_, i) => i !== index)
              )
            }}
            prefixIcon={
              <BinIcon
                isDefaultColor={false}
                size={4}
                className="ml-4 text-base-700"
              />
            }
          />
        </div>
      ))}
      <div>
        <Button
          variant="primary"
          size="medium"
          disabled={
            formStateValues.length >= MAX_MERGE_TAG_EXAMPLE_VALUES ||
            hasAnEmpyInput
          }
          prefixIcon={<AddIcon isDefaultColor={false} size={4} />}
          onClick={() =>
            form.change(`merge_tag_example_values`, [...formStateValues, ''])
          }
        >
          Add
        </Button>
      </div>
    </Fragment>
  )
}

export default ExampleValues
