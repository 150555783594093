import { useCallback, useMemo, useState } from 'react'

import ErrorPage from 'common/components/error/ErrorPage'
import Footer from 'common/components/layout/Footer'
import Loader from 'common/components/loaders/Loader'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import Scrollable from 'common/components/scrollable'
import { useDocumentTitle } from 'common/hooks/custom'

import useGetPersonalizedContentQuery, {
  PersonalizedContentItem,
} from './api/queries/useGetPersonalizedContentQuery'
import Header from './components/Header'
import PersonalizedContentTable from './components/PersonalizedCampaignsTable'

const PersonalizedContent = () => {
  useDocumentTitle('Personalised content | Jacquard')

  const personalizedContentQuery = useGetPersonalizedContentQuery()

  const campaignNamesAndIds = [
    ...new Set(
      personalizedContentQuery.data
        ?.map((item) => item.campaignName)
        .filter(Boolean)
        .sort((a, b) => a.localeCompare(b))
    ),
    ...new Set(
      personalizedContentQuery.data?.map((item) => item.personalizationId)
    ),
  ].map((campaignName) => ({
    label: campaignName,
    value: campaignName,
  }))
  const statuses = [
    ...[...new Set(personalizedContentQuery.data?.map((item) => item.status))]
      .filter(Boolean)
      .map((status) => ({
        id: status,
        label: status,
        value: status,
      })),
  ]
  const accounts = [
    ...[
      ...new Set(
        personalizedContentQuery.data?.map((item) => item.accountName)
      ),
    ].map((account) => ({
      id: account,
      label: account,
      value: account,
    })),
  ]
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([])
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([])
  const [searchCampaign, setSearchCampaign] = useState<string>('')

  const dataFiltered = useMemo(() => {
    const filterByAccount = (item: PersonalizedContentItem) => {
      return selectedAccounts.length === 0
        ? true
        : selectedAccounts
            .map((account) => account.toLowerCase())
            .includes(item.accountName.toLowerCase())
    }

    const filterByCampaignNameOrId = (item: PersonalizedContentItem) => {
      return searchCampaign.trim().length > 0
        ? item.campaignName
            ?.toLowerCase()
            .includes(searchCampaign.trim().toLowerCase()) ||
            item.personalizationId
              ?.toLowerCase()
              .includes(searchCampaign.trim().toLowerCase())
        : true
    }

    const filterByStatus = (item: PersonalizedContentItem) => {
      return selectedStatuses.length === 0
        ? true
        : selectedStatuses
            .map((status) => status.toLowerCase())
            .includes(item.status.toLowerCase())
    }
    return (
      personalizedContentQuery.data?.filter(
        (item) =>
          filterByAccount(item) &&
          filterByCampaignNameOrId(item) &&
          filterByStatus(item)
      ) ?? []
    )
  }, [
    personalizedContentQuery.data,
    selectedAccounts,
    searchCampaign,
    selectedStatuses,
  ])

  const content = useCallback(
    (content: PersonalizedContentItem[]) => {
      if (content.length === 0) {
        if (searchCampaign !== '') {
          return (
            <div className="text-5xl font-bold text-coolGray-800 leading-normal">
              There are no content items matching your search, please try again
            </div>
          )
        }
        return (
          <div className="text-5xl font-bold text-coolGray-800 leading-normal">
            There are no results matching that search.
          </div>
        )
      } else {
        return <PersonalizedContentTable data={dataFiltered} />
      }
    },
    [searchCampaign, dataFiltered]
  )

  if (personalizedContentQuery.error) {
    return <ErrorPage />
  }

  if (personalizedContentQuery.isLoading) {
    return <Loader />
  }

  return (
    <Scrollable
      content={
        <PageContainer className="max-w-318 sm:mx-auto px-6 pt-8">
          <PageHeader>
            <div data-cy="header" data-testid="header">
              <div className="text-3xl text-coolGray-800 font-medium">
                Personalised content
              </div>
            </div>
          </PageHeader>
          <div className="flex flex-col gap-8">
            <Header
              selectedAccounts={selectedAccounts}
              onChangeAccounts={setSelectedAccounts}
              selectedStatuses={selectedStatuses}
              onChangeStatuses={setSelectedStatuses}
              onSearchCampaign={setSearchCampaign}
              accounts={accounts}
              statuses={statuses}
              campaignNames={campaignNamesAndIds}
            />

            {content(dataFiltered)}
          </div>

          <Footer />
        </PageContainer>
      }
    />
  )
}

export default PersonalizedContent
