import Button from 'common/components/button/Button'
import { useAppSelector } from 'common/hooks/redux'
import { Bin as BinIcon } from 'common/icons'

type Props = {
  onClick: () => void
  isDisabled: boolean
}

const RemoveImagesButton = ({ isDisabled, onClick }: Props) => {
  const imageStatus = useAppSelector((state) => state.images.imageStatus)

  if (imageStatus === 'ARCHIVED') {
    return null
  }

  return (
    <Button
      disabled={isDisabled}
      className="uppercase"
      variant="icon"
      prefixIcon={<BinIcon isDefaultColor={false} size={6} />}
      onClick={onClick}
      aria-label="remove-images"
    />
  )
}

export default RemoveImagesButton
