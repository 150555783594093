import cx from 'classnames'
import get from 'lodash/get'
import type { AppliedFilters } from 'workflow/interface'

import Filter from 'common/components/table/filters/Filter'

import { RESULT_TABLE_KEYS } from '../CampaignSummary/ResultsTable/constants'

const getColumnSorter = (columnKey: string | number) => {
  const getNormalizedValue = (value: string | number) =>
    value === '' ? -1 : Number(value.toString().replace('%', ''))

  const defaultSorter = (
    a: { values: { [key: string]: string | number } },
    b: { values: { [key: string]: string | number } }
  ) => {
    const aVal = a.values[columnKey] ?? ''
    const bVal = b.values[columnKey] ?? ''

    const aNum = getNormalizedValue(aVal)
    const bNum = getNormalizedValue(bVal)

    if (!isNaN(aNum) && !isNaN(bNum)) {
      return aNum - bNum
    }

    return aVal
      .toString()
      .toLowerCase()
      .localeCompare(bVal.toString().toLowerCase())
  }

  const stringSorter = (
    a: { values: { [key: string]: string | number } },
    b: { values: { [key: string]: string | number } }
  ) => {
    const aVal = (a.values[columnKey] ?? '').toString().toLowerCase()
    const bVal = (b.values[columnKey] ?? '').toString().toLowerCase()
    return aVal.localeCompare(bVal)
  }

  switch (columnKey) {
    case RESULT_TABLE_KEYS.VARIANT:
    case RESULT_TABLE_KEYS.VARIANT_STATUS:
      return stringSorter
    default:
      return defaultSorter
  }
}

const getColumnWidth = (columnKey: string) => {
  switch (columnKey) {
    case RESULT_TABLE_KEYS.VARIANT:
      return 400
    case RESULT_TABLE_KEYS.VARIANT_STATUS:
      return 150
    case RESULT_TABLE_KEYS.CLICK_UPLIFT:
      return 231
    case RESULT_TABLE_KEYS.OPEN_UPLIFT:
      return 230
    case RESULT_TABLE_KEYS.NUM_RECIPIENTS:
      return 190
    case RESULT_TABLE_KEYS.NUM_OPENS:
      return 155
    case RESULT_TABLE_KEYS.OPEN_RATE:
      return 310
    case RESULT_TABLE_KEYS.NUM_CLICKS:
      return 155
    case RESULT_TABLE_KEYS.CLICK_RATE:
      return 320
    default:
      return 200
  }
}

export const getOptimizedVariantsColumnParams = (
  accessor: string,
  isBanditCampaign: boolean,
  isLinguoCampaign: boolean,
  appliedFilters?: AppliedFilters,
  isSortingDisabled?: boolean,
  rowSelectionEnabled?: boolean
  // eslint-disable-next-line max-params
) => {
  const sortType = isSortingDisabled ? undefined : getColumnSorter(accessor)
  const width = getColumnWidth(accessor)

  switch (accessor) {
    case RESULT_TABLE_KEYS.VARIANT:
      return {
        width,
        sortType,
        className: cx('sticky z-10 bg-inherit', {
          'left-14': rowSelectionEnabled,
          'left-0': !rowSelectionEnabled,
        }),
        headerClassName: cx('sticky z-10 border-r-1 border-gold-200', {
          'left-14': rowSelectionEnabled,
          'left-0': !rowSelectionEnabled,
        }),
      }
    case RESULT_TABLE_KEYS.VARIANT_STATUS:
      const defaultFilteredValue =
        isBanditCampaign && isLinguoCampaign
          ? ['live', 'approved', 'pending']
          : isBanditCampaign
          ? ['live', 'approved']
          : []

      return {
        width,
        Filter: Filter({
          defaultFilters:
            get(appliedFilters, 'variant_status') || defaultFilteredValue,
        }),
        filter: 'includesSome',
        sortType,
        className: 'bg-inherit',
      }
    default:
      return {
        width,
        sortType,
        className: 'bg-inherit',
      }
  }
}

export const getPersonalizedVariantsColumnParams = (accessor: string) => {
  const sortType = getColumnSorter(accessor)
  const width = getColumnWidth(accessor)

  switch (accessor) {
    case RESULT_TABLE_KEYS.VARIANT_STATUS:
      return {
        width,
        Filter: Filter({
          defaultFilters: ['live', 'approved', 'pending'],
        }),
        filter: 'includesSome',
        sortType,
        className: 'bg-inherit',
      }
    default:
      return {
        width,
        sortType,
        className: 'bg-inherit',
      }
  }
}
