import { useState } from 'react'
import cx from 'classnames'

import Tooltip from 'common/components/Tooltip'
import { useAppSelector } from 'common/hooks/redux'
import ElementCardIcon from 'features/unifiedFlow/contentPage/components/ElementCardIcon'

import { OptimizationOptions, PersonalizationOptions } from '../interfaces'

import SelectBox from './components/SelectBox'
import TemplateBadges from './components/TemplateBadges'

export type ContentTemplateCardProps = {
  templateId: string
  icon: string
  header: string
  disabled?: boolean
  isComingSoon?: boolean
  optimization?: OptimizationOptions
  personalization?: PersonalizationOptions
}

const ContentTemplateCard = ({
  templateId,
  header,
  disabled,
  isComingSoon,
  optimization,
  personalization,
  icon,
}: ContentTemplateCardProps) => {
  const [container, setContainer] = useState<HTMLElement | null>(null)

  const [isHovered, setIsHovered] = useState<boolean>(false)

  const templatesToAdd = useAppSelector(
    (state) => state.unifiedFlow.templatesToAdd
  )
  const templateToAdd = templatesToAdd.find(
    (template) => template.id === templateId
  )
  const templateCount = templatesToAdd.filter(
    (template) => template.id === templateId
  ).length

  const isSelected = !disabled && templateToAdd && templateCount > 0
  const isEnabledAndHovered = !disabled && isHovered

  const cardState = isEnabledAndHovered ? 'select' : 'badges'

  return (
    <Tooltip
      overlay="Incompatible with selected template(s)"
      placement="top"
      mouseEnterDelay={0.8}
      show={disabled}
      getTooltipContainer={container ? () => container : undefined}
    >
      <div
        ref={setContainer}
        role="presentation"
        data-testid="template-card"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={{
          height: '116px',
        }}
        className="w-full flex cursor-auto"
      >
        <div
          role="option"
          aria-selected={isSelected}
          className={cx(
            'w-full flex flex-col p-6 rounded-sm bezier-all-transition',
            {
              'bg-gold-100': !isSelected,
              'bg-yellow-200': isSelected,
              'hover:bg-gold-300': !disabled,
              'opacity-50': disabled,
            }
          )}
        >
          <div className="flex flex-col justify-between h-full">
            <div className="flex items-center">
              <ElementCardIcon icon={icon} size={6} className="mr-3" />
              <span className="flex items-center font-normal text-base text-base-700">
                <span className="line-clamp-1" data-testid="template-name">
                  {header}
                </span>
              </span>
            </div>
            <div>
              {
                {
                  badges: (
                    <TemplateBadges
                      disabled={disabled}
                      isComingSoon={isComingSoon}
                      optimization={optimization}
                      personalization={personalization}
                    />
                  ),
                  select: (
                    <SelectBox
                      isOptimizable={optimization?.status === 'enabled'}
                      isPersonalizable={personalization?.status === 'enabled'}
                      templateId={templateId}
                    />
                  ),
                }[cardState]
              }
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  )
}

export default ContentTemplateCard
