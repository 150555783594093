import { ComponentProps } from 'react'

import Slider from 'common/components/slider'

type Props = {
  label: string
  value: number
  onChange: ComponentProps<typeof Slider>['onChange']
  ['data-cy']: string
  'data-testid': string
}
const EmojiSettingsSlider = ({
  label,
  value,
  onChange,
  'data-cy': dataCy,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <div className="flex-1 max-w-75" data-cy={dataCy} data-testid={dataTestId}>
      <div className="mb-4 font-medium text-sm text-coolGray-800">{label}</div>
      <Slider min={0} max={10} value={value} onChange={onChange} />
    </div>
  )
}

export default EmojiSettingsSlider
