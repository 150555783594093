import cx from 'classnames'

type Props = {
  name: string | undefined
  hasError: boolean
  isReplacer: boolean
  hasParentNode?: boolean
  isActive?: boolean
}

const TopicCardName = ({
  name,
  hasError,
  isReplacer,
  hasParentNode,
  isActive,
}: Props) => {
  return name ? (
    <span
      className={cx(
        'text-base font-normal overflow-ellipsis overflow-hidden whitespace-nowrap',
        {
          'text-red-600': hasError,
          'text-gold-700 group-hover:text-base-700':
            !hasError && !hasParentNode && !isActive,
          'text-base-700': !hasError && hasParentNode && !isActive,
          'text-base-700 font-normal': !hasError && isActive,
        }
      )}
    >
      {isReplacer ? name.replace(';', ', ') : name}
    </span>
  ) : null
}

export default TopicCardName
