import { useCallback, useMemo, useState } from 'react'
import { Role } from '@phrasee/phrasee-typings/typings/user/user'
import cx from 'classnames'
import isEqual from 'lodash/isEqual'

import { getIsAuthorized, usersPermissions } from 'common/auth/permissions'
import Avatar from 'common/components/Avatar'
import Button from 'common/components/button/Button'
import ConfirmationModal from 'common/components/confirmationModal'
import ErrorPage from 'common/components/error/ErrorPage'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { avatarColors } from 'features/profile/AvatarColorPicker'

import NoResults from '../holdingPages/NoResults'
import {
  changeUserRole,
  clickDeactivateUser,
  clickDeleteUser,
} from '../store/usersSlice'

import SummaryWidgetContainer from './SummaryWidget/SummaryWidgetContainer'
import { User } from './api'
import RoleSelect from './RoleSelect'
import UserDrawer from './userDrawer'

import 'rc-tabs/assets/index.css'

type Props = {
  searchValue: string
  users: User[]
  assignableRoles: Role[]
  selectedProjectIds: string[]
}
const AllUsers = ({
  searchValue,
  users,
  selectedProjectIds,
  assignableRoles,
}: Props) => {
  const { hasAllTabError } = useAppSelector((state) => state.adminUsers)
  const { user_id: loggedInUserId } = useAppSelector(
    (state) => state.authStates
  )
  const dispatch = useAppDispatch()

  const { permissions } = useAppSelector((state) => state.authStates)

  const [userIdToDelete, setUserIdToDelete] = useState<string>()
  const [userIdsToDeactivate, setUserIdsToDeactivate] = useState<string[]>([])
  const [userToEdit, setUserToEdit] = useState<User | undefined>(undefined)

  const usersFiltered = useMemo(() => {
    const filterByNameAndEmail = ({ firstName, lastName, email }) =>
      [firstName, lastName, email]
        .join('')
        .replaceAll(' ', '')
        .toLowerCase()
        .includes(searchValue.replaceAll(' ', '').toLowerCase())
    const filterByProjectIds = ({ projectIds }) =>
      selectedProjectIds.length
        ? projectIds.some((id) => selectedProjectIds.includes(id))
        : true
    return users.filter(filterByNameAndEmail).filter(filterByProjectIds)
  }, [selectedProjectIds, searchValue, users])
  const isFiltered = usersFiltered.length !== users.length

  const [selectedRows, setSelectedRows] = useState<User[]>([])
  const handleSelectRow = useCallback(
    (rows) => {
      if (!isEqual(rows, selectedRows)) {
        setSelectedRows(rows)
      }
    },
    [selectedRows]
  )

  const isSearching = searchValue.length > 0

  const isLoggedInUserSelected = selectedRows
    .map((user) => user.id)
    .includes(loggedInUserId)

  // TODO inline this variable when the backend supports deleting users
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const deleteColumn = generateDeleteButtonColumn<User>({
    onClick: (row) => setUserIdToDelete(row.id),
    buttonLabel: 'Delete user',
    hasTooltip: true,
  })

  const sortByArrayLength = useMemo(() => {
    return (rowA: any, rowB: any, id: string) => {
      return rowA.values[id].length > rowB.values[id].length ? 1 : -1
    }
  }, [])

  const sortByFirstRole = useMemo(() => {
    return (rowA: any, rowB: any, id: string) => {
      return rowA.values[id][0] > rowB.values[id][0] ? 1 : -1
    }
  }, [])

  const columns: Column<User>[] = [
    {
      Header: 'Name',
      accessor: 'firstName',
      Cell: ({ row }) => {
        const { firstName, lastName } = row.original
        return (
          <BaseCell className="h-full flex items-center">
            <Avatar
              firstName={firstName}
              lastName={lastName}
              className="text-xs w-7 h-7 mr-3"
              color={avatarColors[row.index % avatarColors.length]}
            />
            <span className="font-medium">{`${firstName} ${lastName}`}</span>
          </BaseCell>
        )
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }) => (
        <BaseCell className=" h-full flex items-center">
          <span>{value}</span>
        </BaseCell>
      ),
    },
    {
      Header: 'Projects',
      accessor: 'projectIds',
      sortType: sortByArrayLength,
      isHidable: true,
      Cell: ({ value }) => (
        <BaseCell className=" h-full flex items-center justify-end  ">
          <span>{value.length}</span>
        </BaseCell>
      ),
    },
    {
      Header: 'Role',
      accessor: 'roles',
      sortType: sortByFirstRole,
      isHidable: true,
      Cell: ({ value, row }) => (
        <BaseCell
          className=" h-full flex items-center "
          style={{ maxWidth: 150 }}
        >
          <RoleSelect
            className="w-full sm:w-60 font-medium"
            assignableRoles={assignableRoles}
            isSearchable={true}
            isInsideTable={true}
            value={value[0]}
            onChange={(val) => {
              if (val?.value) {
                dispatch(
                  changeUserRole({
                    roles: [val?.value],
                    userId: row.original.id,
                  })
                )
              }
            }}
          />
        </BaseCell>
      ),
    },
    // deleteColumn,
  ]

  if (hasAllTabError) {
    return <ErrorPage />
  }

  if (users.length > 0 && usersFiltered.length === 0) {
    return (
      <div className="text-5xl font-bold text-coolGray-800 leading-normal">
        There are no users matching that search
      </div>
    )
  }

  const hasDeactivatePermission = getIsAuthorized(
    permissions,
    usersPermissions.deactivate
  )

  return users.length === 0 ? (
    <NoResults />
  ) : (
    <div className="grid gap-y-10">
      <div className={cx(isSearching && 'hidden')}>
        <SummaryWidgetContainer selectedProjectIds={selectedProjectIds} />
      </div>
      <TableWidget.Widget<User>
        selectedRowsActionButtons={
          selectedRows.length > 0 &&
          !isLoggedInUserSelected &&
          hasDeactivatePermission ? (
            <Button
              variant="link"
              onClick={() =>
                setUserIdsToDeactivate(selectedRows.map((user) => user.id))
              }
            >
              Deactivate
            </Button>
          ) : undefined
        }
        columns={columns}
        data={usersFiltered}
        initialState={{ pageSize: 15 }}
        rowSelection="multiple"
        onSelectRow={handleSelectRow}
        onRowClick={(row) =>
          getIsAuthorized(permissions, usersPermissions.edit) &&
          setUserToEdit(row)
        }
        autoResetPage={false}
      >
        <TableWidget.Header
          title={
            <div className="flex items-center" style={{ height: 38 }}>
              {isFiltered
                ? `${usersFiltered.length} Search result`
                : 'All users'}
            </div>
          }
        >
          <TableWidget.Menu />
        </TableWidget.Header>
      </TableWidget.Widget>
      <UserDrawer
        key={`${userToEdit?.id}`}
        assignableRoles={assignableRoles}
        user={userToEdit}
        onClose={() => {
          setUserToEdit(undefined)
        }}
        color={
          userToEdit
            ? avatarColors[
                usersFiltered.findIndex(({ id }) => id === userToEdit.id) %
                  avatarColors.length
              ]
            : undefined
        }
      />
      <ConfirmationModal
        data-cy="deactivate-confirmation-modal"
        data-testid="deactivate-confirmation-modal"
        title={
          userIdsToDeactivate.length > 1
            ? 'Deactivate users?'
            : 'Deactivate user?'
        }
        confirmationText="Oh no… this user will be deactivated and will no longer have access to this account. All their campaigns will still be available to other users."
        confirmButtonText="Deactivate now"
        cancelButtonText="Stop, don't do it"
        open={userIdsToDeactivate.length > 0}
        hasConfirmationSlider={true}
        onConfirm={() => {
          if (userIdsToDeactivate.length > 0) {
            dispatch(clickDeactivateUser(userIdsToDeactivate))
          }
          setUserIdsToDeactivate([])
        }}
        onCancel={() => {
          setUserIdsToDeactivate([])
        }}
      />
      <ConfirmationModal
        data-cy="delete-confirmation-modal"
        data-testid="delete-confirmation-modal"
        title={
          userIdToDelete
            ? 'Remove user?'
            : `Delete user${
                users.find(({ id }) => userIdToDelete === id)?.firstName ?? ''
              }?`
        }
        confirmationText={
          userIdToDelete
            ? 'This is sad… this user will be removed from this team and if they where added to any experiments, as part of this team, they will be removed from there too.'
            : 'This is all great but… all experiments that use this team will have this team removed and you will need to add another team per experiment if you need them. No sweat really, its not critical.'
        }
        confirmButtonText={userIdToDelete ? 'Remove them' : 'Delete it already'}
        cancelButtonText={userIdToDelete ? "Oh, I can't do it" : 'Cancel'}
        open={userIdToDelete !== undefined}
        onConfirm={() => {
          if (userIdToDelete) {
            dispatch(clickDeleteUser(userIdToDelete))
          }
          setUserIdToDelete(undefined)
        }}
        onCancel={() => {
          setUserIdToDelete(undefined)
        }}
      />
    </div>
  )
}

export default AllUsers
