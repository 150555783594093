import ConfirmationModal, { ConfirmationModalProps } from './ConfirmationModal'

type Props = Pick<ConfirmationModalProps, 'onCancel' | 'onConfirm'>

const RegionChangeConfirmationModal = ({ onCancel, onConfirm }: Props) => {
  return (
    <ConfirmationModal
      data-cy="switch-region-modal"
      data-testid="switch-region-modal"
      title="Switch template?"
      confirmationText="You're switching templates, are you sure you want to leave without saving? 
      All unsaved content will be lost."
      confirmButtonText="Switch template"
      cancelButtonText="Stop, don’t do it"
      open
      hasConfirmationSlider={false}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
}

export default RegionChangeConfirmationModal
