import { useIsMutating } from '@tanstack/react-query'

const useUpsertAssetIsMutating = () => {
  const mutationAssetsCount = useIsMutating({
    predicate: (data) => data.state.variables.stackOneAccountId,
  })

  return mutationAssetsCount > 0
}

export default useUpsertAssetIsMutating
