import {
  createAction,
  createSlice,
  isAnyOf,
  PayloadAction,
} from '@reduxjs/toolkit'

import { changeAccountId } from 'features/auth/store/authSlice'

type DataIngestionState = {
  fileStates: {
    customer: {
      fileName: string
      created: string
      data: Object[]
      columns: string[]
      type: string
      isUploading: boolean
      hasError: boolean
    }
    product: {
      fileName: string
      created: string
      data: Object[]
      columns: string[]
      type: string
      isUploading: boolean
      hasError: boolean
    }
  }
}

export type DataType = 'customer' | 'product'

const initialState: DataIngestionState = {
  fileStates: {
    customer: {
      fileName: '',
      created: '',
      data: [],
      columns: [],
      type: '',
      isUploading: false,
      hasError: false,
    },
    product: {
      fileName: '',
      created: '',
      data: [],
      columns: [],
      type: '',
      isUploading: false,
      hasError: false,
    },
  },
}

const dataSourceSlice = createSlice({
  name: 'dataSource',
  initialState,
  reducers: {
    customerUploadStart: (
      state,
      action: PayloadAction<{
        data: Object[]
        fileName: string
      }>
    ) => {
      state.fileStates.customer.fileName = action.payload.fileName
      state.fileStates.customer.data = action.payload.data
      state.fileStates.customer.isUploading = true
      state.fileStates.customer.hasError = false
    },
    productUploadStart: (
      state,
      action: PayloadAction<{ data: Object[]; fileName: string }>
    ) => {
      state.fileStates.product.fileName = action.payload.fileName
      state.fileStates.product.data = action.payload.data
      state.fileStates.product.isUploading = true
      state.fileStates.product.hasError = false
    },
    fileNameConfirmed: (
      state,
      {
        payload: { fileName, type },
      }: PayloadAction<{ fileName: string; type: DataType }>
    ) => {
      state.fileStates[type].fileName = fileName
    },
    fileProcessError: (
      state,
      { payload: { type } }: PayloadAction<{ type: DataType }>
    ) => {
      state.fileStates[type].isUploading = false
      state.fileStates[type].hasError = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, () => initialState)
    builder.addMatcher(
      isAnyOf(fileProcessSuccess, fileProcessCancel),
      (state, { payload: { type } }: PayloadAction<{ type: DataType }>) => {
        state.fileStates[type] = initialState.fileStates[type]
      }
    )
  },
})

export const {
  customerUploadStart,
  productUploadStart,
  fileNameConfirmed,
  fileProcessError,
} = dataSourceSlice.actions

export const fileProcessSuccess = createAction<{
  type: DataType
}>('dataSource/fileProcessSuccess')
export const fileProcessCancel = createAction<{ type: DataType }>(
  'dataSource/fileProcessCancel'
)

export default dataSourceSlice.reducer
