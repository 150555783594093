import { useEffect, useState } from 'react'
import { useIMask } from 'react-imask'
import { useFlags } from 'launchdarkly-react-client-sdk'

import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import { numericInputStyles } from 'common/components/numericInput/NumericInput'
import { useAppSelector } from 'common/hooks/redux'

import { CommonFieldPropsGeneric, CurrencyType } from '../interfaces'

const ERROR_MESSAGES = {
  required: 'Please enter a valid currency amount.',
}

const decimalPointMaskOptions = {
  mask: Number,
  radix: '.',
  thousandsSeparator: ',',
}

const decimalCommaMaskOptions = {
  mask: Number,
  radix: ',',
}

type Props = CommonFieldPropsGeneric & { value: CurrencyType['value'] }

const Currency = ({
  label,
  name,
  value,
  tooltip,
  errorCode,
  isDisabled,
  placeholder,
  onChange,
  'data-testid': dataTestId,
}: Props) => {
  const flags = useFlags()
  const isoLanguageCode = useAppSelector(
    (state) => state.campaignStates.combinedLanguageSettings.isoLanguage
  )

  const maskOptions = ['en_uk', 'en_us'].includes(isoLanguageCode)
    ? decimalPointMaskOptions
    : decimalCommaMaskOptions

  const [options] = useState(maskOptions)

  const {
    ref,
    value: maskValue,
    setValue,
  } = useIMask(options, { onComplete: onChange })

  useEffect(() => {
    setValue(value || '')
  }, [setValue, value])

  return (
    <FormItem
      label={label}
      guidingText={tooltip}
      htmlFor={name}
      error={errorCode ? ERROR_MESSAGES[errorCode] : undefined}
    >
      {flags?.showL2Components ? (
        <Input
          type="text"
          id={name}
          data-testid={dataTestId}
          value={maskValue}
          placeholder={placeholder}
          disabled={isDisabled}
          ref={ref}
        />
      ) : (
        <input
          className={numericInputStyles}
          id={name}
          value={maskValue}
          placeholder={placeholder}
          disabled={isDisabled}
          ref={ref}
        />
      )}
    </FormItem>
  )
}

export default Currency
