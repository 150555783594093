import {
  createApi,
  createAxiosInstance,
  getDataSourceApiPerRegion,
} from 'common/api'

export type CustomerAttributesResponse = {
  name: string
  id: string
  description: string
}

export const updateCustomerAttributes = async ({
  accountId,
  attributeId,
  catalogueAttributeId,
  region,
  description,
}: {
  accountId: string
  region: string
  catalogueAttributeId: string
  attributeId: string
  description: string
}) => {
  const result = await getDataSourceApiPerRegion(region).patch<{
    items: ProfileAttributeItemResponse[]
  }>(
    `accounts/${accountId}/profile-attributes-catalogues/${catalogueAttributeId}/attributes/${attributeId}`,
    { description }
  )
  return result.data.items
}

export type ProductCatalogResponse = {
  _id: string
  file_name: string // name of the file
  type: string
  created: string
  categories: { productCategories: Object } // can really be anything
}

export const getProductCatalog = async ({
  accountId,
  baseUrl,
}: {
  accountId: string
  baseUrl: string | undefined
}): Promise<ProductCatalogResponse | null> => {
  if (!baseUrl) {
    throw new Error('Base URL is not defined')
  }

  const dataIngestionClient = createAxiosInstance(baseUrl)
  const api = createApi(dataIngestionClient)
  const result = await api.get<ProductCatalogResponse | null>(
    `accounts/${accountId}/data/product/category`
  )

  return result.data
}

type ProfileAttributesCatalogueResponse = {
  items: {
    account_id: string
    created_at: string
    description: string
    id: string
    name: string
    updated_at: null
  }[]
}
export interface ProfileAttribute {
  id: string
  name: string
  region: string
  updated: string
}

export const getCustomerAttributeRecords = async ({
  accountId,
  privacyRegions,
}: {
  accountId: string
  privacyRegions: string[]
}): Promise<ProfileAttribute[]> => {
  if (!privacyRegions || privacyRegions.length === 0) {
    throw new Error(
      "We're missing your region info for data privacy. Please contact support to resolve this. Thank you!"
    )
  }

  const results = await Promise.all(
    privacyRegions.map((region) =>
      getDataSourceApiPerRegion(region)
        .get<ProfileAttributesCatalogueResponse>(
          `accounts/${accountId}/profile-attributes-catalogues`
        )
        .then((response) => ({
          region,
          items: response.data.items,
        }))
    )
  )
  return results
    .map(({ region, items }) =>
      items.map(({ id, name, updated_at, created_at }) => ({
        id,
        name,
        region,
        updated: updated_at ?? created_at,
      }))
    )
    .flat()
}

export interface ProfileAttributeItemResponse {
  id: string
  profile_attribute_catalogue_id: string
  name: string
  description: string
  created_at: string
  updated_at: string
}

export const getCustomerAttributes = async ({
  accountId,
  catalogueAttributeId,
  region,
}: {
  accountId: string
  catalogueAttributeId: string
  region: string
}) => {
  const result = await getDataSourceApiPerRegion(region).get<{
    items: ProfileAttributeItemResponse[]
  }>(
    `accounts/${accountId}/profile-attributes-catalogues/${catalogueAttributeId}/attributes`
  )
  return result.data.items.map((item) => ({
    id: item.id,
    name: item.name,
    description: item.description,
  }))
}

export const createProfileAttributesCatalogue = async ({
  accountId,
  region,
  name,
}: {
  accountId: string
  region: string
  name: string
}): Promise<{ id: string; name: string; description: string }> => {
  const result = await getDataSourceApiPerRegion(region).post<{
    id: string
    name: string
    description: string
  }>(`accounts/${accountId}/profile-attributes-catalogues`, { name })
  return result.data
}
