import { useState } from 'react'
import { Element } from '@phrasee/phrasee-typings/typings/futurama/element'
import cx from 'classnames'

import Tooltip from 'common/components/Tooltip'
import { StartFlag } from 'common/icons'
import { ReactComponent as ApprovalTickIcon } from 'common/icons/smallTick/default.svg'

import ElementVerticalDotMenu from './ElementVerticalDotMenu'

type Props = {
  isConfigurable: boolean
  isSelected: boolean
  isRemoveOptionShown?: boolean
  element?: Element
  className?: string
  hasError?: boolean
  isApproved?: boolean
  hasElementActionsMenu?: boolean
  showCustomBriefIcon?: boolean
}

const ElementActionsMenu = ({
  isConfigurable,
  element,
  isSelected,
  isRemoveOptionShown,
  hasError = false,
  isApproved = false,
  className,
  hasElementActionsMenu = true,
  showCustomBriefIcon = true,
}: Props) => {
  const [isVerticalMenuOpen, setIsVerticalMenuOpen] = useState(false)

  const isCustomized =
    element?.settings_customisation ||
    (!element?.user_input_form_compatible &&
      !element?.user_input?.generated_brief)

  return (
    <div className={cx('ml-auto flex items-start gap-2', className)}>
      {showCustomBriefIcon && isCustomized && hasElementActionsMenu && (
        <Tooltip overlay="Uses a custom brief">
          <StartFlag
            isDefaultColor={false}
            size={6}
            className="text-base-700"
            data-testid="uses-custom-brief-icon"
          />
        </Tooltip>
      )}
      {isApproved && !hasError && (
        <ApprovalTickIcon width={24} height={24} className="text-green-600" />
      )}
      {isConfigurable && hasElementActionsMenu && (
        <ElementVerticalDotMenu
          isSelected={isSelected}
          element={element}
          isRemoveOptionShown={isRemoveOptionShown}
          isMenuOpen={isVerticalMenuOpen}
          isTransitionActive={isApproved || isCustomized || hasError}
          onMenuChange={({ open }) => setIsVerticalMenuOpen(open)}
        />
      )}
    </div>
  )
}

export default ElementActionsMenu
