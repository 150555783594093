import { IntegrationAccount } from '@phrasee/phrasee-typings/typings/integrations/accounts'

import Image from 'common/components/image'

import IntegrationsSelect from './integrationsSelect'

type Props = {
  account: IntegrationAccount
  accounts: IntegrationAccount[]
  contentId: string
  elementId: number
}

const IntegrationSelection = ({
  account,
  accounts,
  contentId,
  elementId,
}: Props) => {
  const { logoUrl, accountName, provider } = account

  return (
    <div>
      <p className="text-base-700 text-base pb-6 font-normal">
        Select an integration and share your message with your CEP or ESP.
      </p>
      <div className="flex mt-4 gap-6 pl-6">
        <div className="flex justify-center items-center h-20 w-20 px-2 border-gold-300 border">
          <Image isResponsive src={logoUrl} alt={`${accountName}-logo`} />
        </div>
        <div className="flex flex-col items-start">
          <div className="uppercase text-lg text-base-700 font-normal mb-1">
            {provider}
          </div>
          <span className="text-sm text-base-700">
            Connect your <span className="capitalize-first">{provider}</span>{' '}
            account with your Jacquard content.
          </span>
          <IntegrationsSelect
            contentId={contentId}
            elementId={elementId}
            accounts={accounts}
            variant="link"
            placement="bottomLeft"
          >
            Change integration
          </IntegrationsSelect>
        </div>
      </div>
    </div>
  )
}

export default IntegrationSelection
