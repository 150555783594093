import cx from 'classnames'
import { FileStatus } from 'filepond'

import Button from 'common/components/button/Button'
import type { FileUploaderFileType } from 'common/components/fileUploader'
import { Replace as ReplaceIcon } from 'common/icons'

type Props = {
  image: FileUploaderFileType
  onReplace: () => void
}
const ImageDetailsCard = ({ image, onReplace }: Props) => {
  const hasError =
    image.status === FileStatus.LOAD_ERROR ||
    image.status === FileStatus.PROCESSING_REVERT_ERROR ||
    image.status === FileStatus.PROCESSING_ERROR

  return (
    <div className="flex items-center bg-gold-40 p-2 min-h-9">
      <span
        className={cx('min-w-3.5 w-3.5 h-3.5 rounded-full mr-2.5', {
          'bg-RAGGreen': !hasError,
          'bg-RAGred': hasError,
        })}
      />
      <span className="text-base-700 text-sm truncate">{image.file.name}</span>
      {hasError && (
        <Button
          className="ml-auto"
          size="small"
          variant="icon"
          onClick={() => onReplace()}
        >
          <ReplaceIcon
            size={5}
            isDefaultColor={false}
            className="text-gold-700"
          />
        </Button>
      )}
    </div>
  )
}

export default ImageDetailsCard
