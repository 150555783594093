import { Form } from 'react-final-form'

import Badge from 'common/components/Badge'
import ElementCardIcon from 'features/unifiedFlow/contentPage/components/ElementCardIcon'

import AddedComponentList from '../addedTemplates/AddedComponentList'
import BrandVoiceBanner from '../brandVoiceBanner'
import { useExperimentForm } from '../context/ExperimentFormContext'
import { getFormValue } from '../helpers'
import SetupExperimentFooter from '../setupExperiment/SetupExperimentFooter'
import SetupOptimisation from '../setupExperiment/SetupOptimization'
import SetupPersonalization from '../setupExperiment/SetupPersonalization'
import SlidingPanel from '../slidingPanel'
import TemplatesToAddBar from '../TemplatesToAddBar'
import WorkFlowName from '../WorkFlowName'

import TemplatesConfigPanelFooter from './TemplatesConfigPanelFooter'
import TemplatesConfigPanelHeader from './TemplatesConfigPanelHeader'
import TemplatesConfigPanelMain from './TemplatesConfigPanelMain'

export const setFormError = ([name, error], state) => {
  const { fields } = state
  const touched = !!error

  const field = fields[name]
  if (field) {
    field.data.error = error
    field.touched = touched
  }
}

const TemplatesConfigPanel = () => {
  const {
    state: { isExperimentFormVisible, template },
  } = useExperimentForm()

  return (
    <div className="flex w-full">
      <Form
        initialValues={{
          workflowName: undefined,
        }}
        mutators={{
          setError: setFormError,
        }}
        onSubmit={() => {}}
        render={({ handleSubmit, values, form }) => {
          const selectedExperimentType = getFormValue({
            formState: form.getState(),
            name: 'experimentType',
            templateInternalId: template?.internalId,
          })

          return (
            <form
              id="workflow-form"
              data-testid="workflow-form"
              onSubmit={handleSubmit}
              className="flex overflow-hidden w-full"
            >
              <SlidingPanel
                isHiding={isExperimentFormVisible}
                className="flex-col w-full"
                isActive={!isExperimentFormVisible}
                data-testid="workflow-configuration-panel"
              >
                <TemplatesConfigPanelHeader className="flex-col">
                  <WorkFlowName />
                  <BrandVoiceBanner />
                </TemplatesConfigPanelHeader>
                <TemplatesConfigPanelMain className="bg-gold-100">
                  <AddedComponentList />
                </TemplatesConfigPanelMain>
                <TemplatesConfigPanelFooter>
                  <TemplatesToAddBar />
                </TemplatesConfigPanelFooter>
              </SlidingPanel>

              <SlidingPanel
                isHiding={isExperimentFormVisible}
                className="flex-col w-full"
                isActive={isExperimentFormVisible}
                data-testid="template-configuration-panel"
              >
                <TemplatesConfigPanelHeader>
                  <div className=" flex flex-col gap-6 w-full font-relais p-6 text-base-700">
                    <div>
                      <p
                        className="text-2xl mb-4 font-medium"
                        data-testid="template-config-heading"
                      >
                        Set up your{' '}
                        {selectedExperimentType === 'personalize'
                          ? 'personalisation'
                          : 'experiment'}
                      </p>
                      <p className="text-sm">
                        Configure your setup and ready for briefing
                      </p>
                    </div>
                    <div className="flex items-center">
                      <ElementCardIcon
                        size={6}
                        className="mr-2"
                        icon={template?.icon || ''}
                      />
                      <p className="text-xl" data-testid="template-type">
                        {template?.displayName}
                      </p>
                      <Badge
                        text={
                          selectedExperimentType === 'personalize'
                            ? 'personalisation'
                            : 'experiment'
                        }
                        variant="feature"
                        size="small"
                        className="ml-auto"
                        data-testid={`${
                          selectedExperimentType === 'personalize'
                            ? 'personalise'
                            : 'optimise'
                        }-badge`}
                      />
                    </div>
                  </div>
                </TemplatesConfigPanelHeader>
                <hr className="w-full border-gold-400" />
                <TemplatesConfigPanelMain className="bg-gold-100 py-6 px-4 sm:px-6">
                  {selectedExperimentType === 'personalize' ? (
                    <SetupPersonalization />
                  ) : (
                    <SetupOptimisation key={template?.internalId} />
                  )}
                </TemplatesConfigPanelMain>
                <hr className="w-full border-gold-400" />
                <TemplatesConfigPanelFooter>
                  <SetupExperimentFooter />
                </TemplatesConfigPanelFooter>
              </SlidingPanel>
            </form>
          )
        }}
      />
    </div>
  )
}

export default TemplatesConfigPanel
