import { useDocumentTitle } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'
import MainContent from 'features/unifiedFlow/components/MainContent'

import { getIsOptimized } from '../helpers'
import { useSelectedComponentElement } from '../hooks'

import useElementState from './hooks/useElementState'
import CenterSection from './centerSection'
import LeftSection from './leftSection'
import RightSection from './rightSection'

const Results = () => {
  useDocumentTitle('Results | Jacquard')
  useElementState()

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )
  const { data: selectedElement } = useSelectedComponentElement()
  const isOptimized = getIsOptimized(selectedElement)

  return (
    <MainContent id="main-content-page">
      <LeftSection />
      {!!selectedElementId && (
        <>
          <CenterSection />
          {isOptimized && <RightSection />}
        </>
      )}
    </MainContent>
  )
}

export default Results
