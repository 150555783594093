import { useFlags } from 'launchdarkly-react-client-sdk'

import type { Props } from './FormItem'
import FormItem from './FormItem'
import FormItemDefault from './FormItemDefault'

const FormItemPerFlag = (props: Props) => {
  const flags = useFlags()

  return flags?.showL2Components ? (
    <FormItem {...props} />
  ) : (
    <FormItemDefault {...props} />
  )
}

export default FormItemPerFlag
