import { useEffect, useState } from 'react'
import cx from 'classnames'
import identity from 'lodash/identity'

import { useValidationError, Validations } from 'common/hooks/custom'
import { Add as AddIcon } from 'common/icons'

import ButtonWithTooltip from '../ButtonWithTooltip'

import Autocomplete from './Autocomplete'
import Tag from './Tag'

export const MAX_FORBIDDEN_WORDS = 30
export const MAX_FORBIDDEN_WORDS_MESSAGE = `We only allow up to ${MAX_FORBIDDEN_WORDS} words and emojis.`

export type Option<T> = { label: string; value: T; isDisabled?: boolean }

type Props<T> = {
  tags: Option<T>[]
  maxLength?: number
  onRemoveClick?: (value: T) => void
  onAddClick?: (value: string) => void
  className?: string
  'data-testid'?: string
  'data-cy'?: string
  isFreeText: boolean
  suggestions?: string[]
  onInputChange?: (value: string) => string
  isDisabled?: boolean
  placeholder?: string
  searchValue?: string
  validations?: Validations
  hasTagButtonLabel?: boolean
  tagButtonLabel?: string
  isInsideTable?: boolean
  maxTagsNumber?: number
  maxTagsMessage?: string
}

const Tags = <T extends string>({
  tags: options,
  onRemoveClick,
  onAddClick,
  className,
  isDisabled,
  suggestions = [],
  onInputChange = identity,
  maxLength,
  searchValue,
  isFreeText,
  placeholder = 'Add a tag',
  validations,
  tagButtonLabel = 'Tag',
  hasTagButtonLabel,
  isInsideTable,
  'data-testid': dataTestId,
  'data-cy': dataCy,
  maxTagsNumber,
  maxTagsMessage = 'You have reached the maximum number of tags',
}: Props<T>) => {
  const {
    error: inputError,
    validate,
    resetError,
  } = useValidationError(validations)
  const [isAdding, setIsAdding] = useState(false)
  const filteredTags = options.filter((tag) =>
    tag.label.toLowerCase().includes((searchValue ?? '').toLocaleLowerCase())
  )
  const uniqueSuggestions = [...new Set(suggestions)]
  const hasUsedAllSuggestions =
    !isFreeText && uniqueSuggestions.length === options.length

  const isMaxTagsLimitReached = maxTagsNumber
    ? options.length >= maxTagsNumber
    : false

  useEffect(() => {
    if (isAdding && isMaxTagsLimitReached) {
      setIsAdding(false)
    }
  }, [isMaxTagsLimitReached, isAdding])

  return (
    <div
      className={cx('flex flex-wrap gap-2 items-center', className)}
      data-cy={dataCy}
      data-testid={dataTestId}
    >
      {filteredTags.map(({ label, value, isDisabled }) => (
        <Tag
          data-testid={dataTestId}
          value={value}
          label={label}
          isDisabled={isDisabled}
          key={value}
          maxLength={maxLength}
          onRemoveClick={onRemoveClick}
        />
      ))}
      {onAddClick && (
        <>
          <ButtonWithTooltip
            size="small"
            aria-label="Add tag"
            data-cy="add-tag-button"
            data-testid="add-tag-button"
            ghost
            variant="icon"
            tooltip={hasUsedAllSuggestions || isMaxTagsLimitReached}
            tooltipText={
              hasUsedAllSuggestions ? 'All selections used' : maxTagsMessage
            }
            disabled={
              isDisabled || hasUsedAllSuggestions || isMaxTagsLimitReached
            }
            className={cx(
              'hover:bg-coolGray-200 h-6 px-1 outline-none focus:outline-none',
              {
                hidden: isAdding,
              }
            )}
            onClick={() => {
              setIsAdding(true)
            }}
            prefixIcon={
              <div className="flex items-center">
                <AddIcon
                  isDefaultColor={false}
                  className="text-coolGray-400 w-4 h-4"
                />
                {hasTagButtonLabel && (
                  <div className="text-sm text-coolGray-400 ml-1.5">
                    {tagButtonLabel}
                  </div>
                )}
              </div>
            }
          />
          {isAdding && (
            <>
              <Autocomplete
                data-cy={dataCy}
                placeholder={placeholder}
                isFreeText={isFreeText}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    const target = event.target as HTMLElement

                    setIsAdding(true)
                    target.focus()
                    event.preventDefault()
                  }
                }}
                onBlur={(inputValue) => {
                  if (inputValue && !inputError) {
                    // prevent adding duplicate tags
                    if (
                      !options.some(
                        (option) =>
                          inputValue.toLowerCase() ===
                          option.label.toLocaleLowerCase()
                      )
                    ) {
                      if (
                        isFreeText ||
                        uniqueSuggestions
                          .map((suggestion) => suggestion.toLocaleLowerCase())
                          .includes(inputValue.toLocaleLowerCase().trim())
                      ) {
                        onAddClick(inputValue)
                      }
                    }
                  }
                  setIsAdding(false)
                  resetError()
                }}
                onInputChange={(value) => {
                  validate(value)

                  return onInputChange(value)
                }}
                options={uniqueSuggestions
                  .filter(
                    (suggestion) =>
                      !options.some((option) => option.label === suggestion)
                  )
                  .map((suggestion) => ({
                    value: suggestion,
                    label: suggestion,
                  }))}
                isInsideTable={isInsideTable}
              />
              {inputError && (
                <div className="text-red-400 text-sm">{inputError}</div>
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default Tags
