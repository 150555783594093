import { useAppSelector } from 'common/hooks/redux'
import PhraseeXIntegration from 'features/campaigns/integration-options/PhraseeX'

import { shouldShowThePhraseeXIntegrationPanel } from './helpers'
import SFMCIntegration from './SfmcIntegration'

type Props = {
  onSuccess?: () => void
  onCancel?: () => void
}

const SFMCIntegrationManager = ({ onSuccess, onCancel }: Props) => {
  const campaignData = useAppSelector(
    (state: any) => state.campaignStates?.campaignData
  )

  return shouldShowThePhraseeXIntegrationPanel(campaignData) ? (
    <PhraseeXIntegration onSuccess={onSuccess} />
  ) : (
    <SFMCIntegration onSuccess={onSuccess} onCancel={onCancel} />
  )
}

export default SFMCIntegrationManager
