import cx from 'classnames'

import Button from 'common/components/button'
import { useAppDispatch } from 'common/hooks/redux'
import { CloseSquare as CloseSquareIcon } from 'common/icons'
import { hideRightPanel } from 'features/unifiedFlow/store/unifiedFlowSlice'

type Props = {
  text: string
  'data-testid'?: string
  className?: string
  children?: React.ReactNode
}

const RightPanelHeader = ({
  text,
  'data-testid': dataTestId,
  className,
  children,
}: Props) => {
  const dispatch = useAppDispatch()

  return (
    <div className={cx('flex flex-row items-center w-full', className)}>
      <h4
        data-testid={dataTestId}
        aria-level={1}
        className="text-2xl text-base-700 font-relais"
      >
        {text}
      </h4>
      {children}
      <Button
        variant="icon"
        className="ml-auto h-fit"
        onClick={() =>
          dispatch(hideRightPanel({ visibility: 'hidden', isUserAction: true }))
        }
      >
        <CloseSquareIcon isDefaultColor={false} className="text-base-700" />
      </Button>
    </div>
  )
}

export default RightPanelHeader
