import { VirtuosoGrid } from 'react-virtuoso'

import type { ImageEntity } from 'common/components/images/api/api'
import useGetImagesInfiniteQuery from 'common/components/images/api/queries/useGetImagesInfiniteQuery'
import GalleryEmptyState from 'common/components/images/galleryEmptyState'
import useCleanImagesStateOnUnmount from 'common/components/images/hooks/useCleanImagesStateOnUnmount'
import ImageSearchInput from 'common/components/images/imageSearchInput'
import ImageSort from 'common/components/images/imageSort'
import Loader from 'common/components/loaders/Loader'
import Error from 'common/components/table/Error'

import styles from './ImageGalleryModal.module.css'

type Props = {
  itemContent: (image: ImageEntity) => React.ReactNode
}

const ImageList = ({ itemContent }: Props) => {
  const { data, fetchNextPage, hasNextPage, isInitialLoading, isError } =
    useGetImagesInfiniteQuery()

  useCleanImagesStateOnUnmount()

  const getState = () => {
    if (isInitialLoading) {
      return 'loading'
    } else if (data.length > 0 && !isError) {
      return 'data'
    } else if (isError) {
      return 'error'
    } else {
      return 'empty'
    }
  }

  const state = getState()

  return (
    <>
      <div className="flex pb-8 justify-between items-center">
        <div className="w-109">
          <ImageSearchInput />
        </div>

        <ImageSort />
      </div>
      {
        {
          loading: (
            <div className="h-125">
              <Loader />
            </div>
          ),
          data: (
            <VirtuosoGrid
              style={{ height: 500 }}
              totalCount={data.length}
              endReached={() => {
                if (hasNextPage) {
                  fetchNextPage()
                }
              }}
              overscan={500}
              listClassName={styles.list}
              itemClassName={styles.item}
              itemContent={(index) => itemContent(data[index])}
            />
          ),
          error: <Error />,
          empty: <GalleryEmptyState className="h-125" />,
        }[state]
      }
    </>
  )
}

export default ImageList
