import { AxiosResponse } from 'axios'

import { connectInternalApi } from 'common/api'

export interface TokenResponse {
  customer_identifier: string
  _id: string
  name: string
  token: string
  expires: string
}

export const getTokens = async (
  accountId: string
): Promise<AxiosResponse<TokenResponse[]>> => {
  return connectInternalApi.get<TokenResponse[]>(
    `v1/core/main/accounts/${accountId}/tokens`
  )
}
