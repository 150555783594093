import { GroupBase } from 'react-select'

import MultiSelect from 'common/components/MultiSelect'

const GroupLabel = (
  data: GroupBase<{
    id: string
    label: string
    value: string
  }>
) => (
  <div>
    <span>{data.label}</span>
  </div>
)

type Props = {
  owners: string[]
  projects: string[]
  selectedProjects: string[]
  selectedOwners: string[]
  selectedTypes: string[]
  selectedStatuses: string[]
  selectedTemplates: string[]
  onChangeProject: (value: string[]) => void
  onChangeOwner: (value: string[]) => void
  onChangeType: (value: string[]) => void
  onChangeStatus: (value: string[]) => void
  onChangeTemplate: (value: string[]) => void
}
const Filters = ({
  owners,
  projects,
  selectedOwners,
  selectedProjects,
  selectedTypes,
  selectedStatuses,
  selectedTemplates,
  onChangeOwner,
  onChangeProject,
  onChangeType,
  onChangeStatus,
  onChangeTemplate,
}: Props) => {
  return (
    <div className="flex gap-4 flex-wrap flex-grow-0">
      <MultiSelect
        className="w-52 flex-none"
        data-cy="reports-type-select"
        data-testid="reports-type-select"
        name="projects"
        label="Project"
        placeholder="All projects"
        onApply={onChangeProject}
        selectedItems={selectedProjects}
        items={projects.map((name) => ({
          label: name,
          value: name,
          id: name,
        }))}
      />
      <MultiSelect
        className="w-52 flex-none"
        data-cy="reports-type-select"
        data-testid="reports-type-select"
        name="types"
        label="Type"
        placeholder="All types"
        onApply={onChangeType}
        selectedItems={selectedTypes}
        items={[
          { label: 'Optimise', value: 'optimise', id: 'optimise' },
          { label: 'Personalise', value: 'personalise', id: 'personalise' },
          { label: 'Content', value: 'content', id: 'content' },
        ]}
      />
      <MultiSelect
        className="w-52 flex-none"
        data-cy="reports-type-select"
        data-testid="reports-type-select"
        name="templates"
        label="Template"
        placeholder="All templates"
        onApply={onChangeTemplate}
        selectedItems={selectedTemplates}
        items={[
          {
            label: 'Email',
            value: 'email',
            id: 'email',
            options: [
              {
                id: 'email headline',
                label: 'Headline',
                value: 'email headline',
              },
              {
                id: 'marketing email',
                label: 'Marketing email',
                value: 'marketing email',
              },
              {
                id: 'subject line and preheader',
                label: 'Subject line and preheader',
                value: 'subject line and preheader',
              },
              {
                id: 'email subject line',
                label: 'Subject line',
                value: 'email subject line',
              },
              // { id: 'email cta', label: 'CTA', value: 'email cta' },
              {
                id: 'email preheader',
                label: 'Preheader',
                value: 'email preheader',
              },
              {
                id: 'cold email',
                label: 'Cold email',
                value: 'cold email',
              },
            ],
          },
          {
            // social
            label: 'Social',
            value: 'social',
            id: 'social',
            options: [
              {
                id: 'organic Facebook post',
                label: 'Organic Facebook post',
                value: 'Organic Facebook post',
              },
              {
                id: 'Facebook ad primary text',
                label: 'Facebook ad primary text',
                value: 'Facebook ad primary text',
              },
              {
                id: 'Facebook ad',
                label: 'Facebook ad',
                value: 'Facebook ad',
              },
              {
                id: 'Instagram ad primary text',
                label: 'Instagram ad primary text',
                value: 'Instagram ad primary text',
              },
              {
                id: 'Organic Instagram post',
                label: 'Organic Instagram post',
                value: 'Organic Instagram post',
              },
            ],
          },
          {
            // "Messaging" with sub categories: "Push body","in-app message","Push title and body", "SMS"
            label: 'Messaging',
            value: 'messaging',
            id: 'messaging',
            options: [
              {
                id: 'Push body',
                label: 'Push body',
                value: 'Push body',
              },
              {
                id: 'In-app message',
                label: 'In-app message',
                value: 'In-app message',
              },
              {
                id: 'Push title and body',
                label: 'Push title and body',
                value: 'Push title and body',
              },
              {
                id: 'SMS',
                label: 'SMS',
                value: 'SMS',
              },
            ],
          },
          {
            label: 'Web',
            value: 'web',
            id: 'web',
            options: [
              {
                id: 'website headline',
                label: 'Headline',
                value: 'Website headline',
              },
              {
                id: 'website banner',
                label: 'Banner',
                value: 'website banner',
              },
              {
                id: 'website subheadline',
                label: 'Subheadline',
                value: 'website subheadline',
              },
            ],
          },
          {
            label: 'Ecommerce',
            value: 'ecommerce',
            id: 'ecommerce',
            options: [
              {
                id: 'product features',
                label: 'Product features',
                value: 'product features',
              },
              {
                id: 'product description',
                label: 'Product description',
                value: 'product description',
              },
              {
                id: 'product page',
                label: 'Product page',
                value: 'product page',
              },
            ],
          },
        ]}
        formatGroupLabel={GroupLabel}
      />
      <MultiSelect
        className="w-52 flex-none"
        data-cy="reports-type-select"
        data-testid="reports-type-select"
        name="statuses"
        label="Status"
        placeholder="All statuses"
        onApply={onChangeStatus}
        selectedItems={selectedStatuses}
        items={[
          { label: 'New language', value: 'New language', id: 'new language' },
          { label: 'Draft', value: 'Draft', id: 'draft' },
          {
            label: 'Pending approval',
            value: 'Pending approval',
            id: 'pending approval',
          },
          {
            label: 'Missing results',
            value: 'Missing results',
            id: 'missing results',
          },
          { label: 'Live', value: 'Live', id: 'live' },
          { label: 'Completed', value: 'Completed', id: 'completed' },
        ]}
      />
      <MultiSelect
        className="w-52 flex-none"
        data-cy="owners-select"
        data-testid="owners-select"
        name="owners"
        label="Owner"
        placeholder="All owners"
        selectedItems={selectedOwners}
        onApply={(values) => {
          onChangeOwner(values)
        }}
        items={owners.map((name) => ({
          label: name,
          id: name,
          value: name,
        }))}
      />
    </div>
  )
}

export default Filters
