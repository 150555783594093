import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import {
  PersonalizationRegion,
  updateVariantStatus,
  VariantsResponse,
} from '../api'
import { personalizationResultsKeys } from '../queryKeys'

const useUpdateVariantStatusMutation = ({
  accountId,
  personalizationId,
  personalizationRegion,
  resultType,
}: {
  accountId: string
  personalizationId: string | undefined
  personalizationRegion: PersonalizationRegion | undefined
  resultType: 'mature' | 'raw'
}) => {
  const queryClient = useQueryClient()
  const variantsResultsQueryKey = personalizationResultsKeys.variants({
    accountId,
    personalizationId,
    personalizationRegion,
    resultType,
  })

  return useMutation(updateVariantStatus, {
    onMutate: (variables) => {
      queryClient.cancelQueries(variantsResultsQueryKey)

      queryClient.setQueryData<VariantsResponse>(
        variantsResultsQueryKey,
        (old) => {
          if (!old) {
            return old
          }

          return old.map((variant) =>
            variant.variantId === variables.variantId
              ? { ...variant, variantStatus: variables.status }
              : variant
          )
        }
      )
    },
    onSettled: () => {
      queryClient.invalidateQueries(variantsResultsQueryKey)
    },
    onError: (error) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Something went wrong while tweaking the variant'
      errorToast(errorMessage)
    },
  })
}

export default useUpdateVariantStatusMutation
