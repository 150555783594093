import { IntegrationAccount } from '@phrasee/phrasee-typings'

import GridContainer from 'common/components/grid/GridContainer'
import { avatarColors } from 'features/profile/AvatarColorPicker'

import AccountCard from './AccountCard'

type Props = {
  accounts: IntegrationAccount[]
  onDelete: (integrationAccount: IntegrationAccount) => void
  onUpdate: (integrationAccount: IntegrationAccount) => void
}

const AccountsGrid = ({ accounts, onDelete, onUpdate }: Props) => {
  return (
    <GridContainer data-testid="accountsGridList">
      {accounts.map(
        ({ id, provider, logoUrl, userName, createdAt, label }, index) => (
          <AccountCard
            key={id}
            name={provider}
            logoUrl={logoUrl}
            userName={userName}
            createdAt={createdAt}
            label={label ?? ''}
            avatarColor={avatarColors[index % avatarColors.length]}
            onDelete={() => onDelete(accounts[index])}
            onUpdate={() => onUpdate(accounts[index])}
          />
        )
      )}
    </GridContainer>
  )
}

export default AccountsGrid
