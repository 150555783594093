import { forwardRef, ReactChild, ReactNode } from 'react'
import cx from 'classnames'
import RCTabs, { TabPane } from 'rc-tabs'

import 'rc-tabs/assets/index.css'
import styles from './Tabs.module.css'

export const addCountToTabName = ({
  count,
  tabName,
}: {
  count: number
  tabName: string
}) => (count > 0 ? `${tabName} (${count})` : tabName)

export type Tab<T = string> = {
  key: T
  name: string
  menu?: ReactNode
  content: ReactChild
  destroyInactiveTabPane?: boolean
  isDisabled?: boolean
}

interface Props<T extends string> {
  tabs: Tab<T>[]
  activeKey?: T
  defaultActiveKey?: T
  onTabClick?: (activeKey: T, e: React.KeyboardEvent | React.MouseEvent) => void
  variant: 'inPage' | 'modal' | 'filter'
  hasContentMargin?: boolean
  className?: string
  destroyInactiveTabPane?: boolean
  moreIcon?: ReactNode
  'data-testid'?: string
}

const Tabs = <T extends string>(
  {
    tabs,
    onTabClick,
    defaultActiveKey,
    activeKey,
    variant,
    hasContentMargin = true,
    className,
    destroyInactiveTabPane = true,
    moreIcon,
    'data-testid': dataTestid,
  }: Props<T>,
  ref: React.Ref<HTMLDivElement> | undefined
) => {
  return (
    <RCTabs
      ref={ref}
      destroyInactiveTabPane={destroyInactiveTabPane}
      onTabClick={(activeKey, event) =>
        onTabClick && onTabClick(activeKey as T, event)
      }
      data-testid={dataTestid}
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
      className={cx(className, {
        [styles.inPage]: variant === 'inPage',
        [styles.modal]: variant === 'modal',
        [styles.filter]: variant === 'filter',
      })}
      moreIcon={moreIcon}
      renderTabBar={(props, TabNavList) => (
        <TabNavList {...props} mobile={false} />
      )}
    >
      {tabs?.map(({ name, content, key, isDisabled = false, menu }, index) => (
        <TabPane
          tab={
            <>
              {menu ? (
                menu
              ) : (
                <span
                  data-cy={`tab-${index}`}
                  data-testid={`tab-${index}`}
                  className="capitalize"
                >
                  {name}
                </span>
              )}
            </>
          }
          key={key}
          disabled={isDisabled}
        >
          {variant === 'inPage' || variant === 'filter' ? (
            <div
              className={cx({
                'mt-9': hasContentMargin,
              })}
            >
              {content}
            </div>
          ) : (
            content
          )}
        </TabPane>
      ))}
    </RCTabs>
  )
}

export default forwardRef(Tabs)
