import { useState } from 'react'
import { Field, Form } from 'react-final-form'

import useGetAccountPrivacyRegionsQuery from 'common/api/queries/useGetAccountPrivacyRegionsQuery'
import { SelectValue } from 'common/components/BaseSelect'
import Button from 'common/components/button'
import FormItem from 'common/components/formItem/FormItem'
import Input from 'common/components/input/Input'
import Modal from 'common/components/Modal'
import SingleSelect from 'common/components/singleSelect'
import { errorToast, successToast } from 'common/components/toastNotification'
import { stripForbiddenCharacters } from 'common/helpers/stripForbiddenCharacters'

import useCreateCustomerAttributesMutation from '../../api/mutations/useCreateCustomerAttributesMutation'

export const RECORD_NAME_LIMIT = 128

interface Props {
  isVisible: boolean
  onClose: () => void
}

const parseRecordName = (
  value: string
): {
  isValid: boolean
  validationError: string | undefined
  value: string
} => {
  const trimmedValue = value.trimStart()
  const strippedValue = stripForbiddenCharacters(trimmedValue)
  const { isValid, validationError } = validateRecordName(strippedValue)

  return {
    isValid,
    validationError,
    value: isValid ? strippedValue : strippedValue.slice(0, -1),
  }
}

function validateRecordName(name: string | undefined) {
  if (!name) {
    return { isValid: true, validationError: undefined }
  }
  const characterLengthLimit = RECORD_NAME_LIMIT
  const isCharacterTypeError =
    name.length && /^[0-9a-zA-Z\s\-_]+$/.test(name.toString()) === false
  const lengthDifference = name.length - characterLengthLimit
  const lengthError = `The record name is ${lengthDifference} character${
    lengthDifference > 1 ? 's' : ''
  } too long. It should be less than ${characterLengthLimit} characters.`
  const hasError = isCharacterTypeError || name.length > characterLengthLimit
  const error = isCharacterTypeError
    ? 'Jacquard only accepts letters, numbers, spaces, hyphens and underscores in record names.'
    : lengthError
  return {
    isValid: !hasError,
    validationError: hasError ? error : undefined,
  }
}

const validatePrivacyRegion = (value: SelectValue | undefined) => {
  if (!value) {
    return 'Select your storage location.'
  }
}

const CreateRecordModal = ({ isVisible, onClose }: Props) => {
  const handleClose = () => {
    onClose()
  }
  const accountPrivacyRegionsQuery = useGetAccountPrivacyRegionsQuery()

  const [error, setError] = useState<string | undefined>()
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)

  const { mutate, isLoading } = useCreateCustomerAttributesMutation()

  return (
    <Modal
      className="max-w-104"
      data-cy="add-record-modal"
      data-testid="add-record-modal"
      visible={isVisible}
      closable={false}
      destroyOnClose
      centered
    >
      <div className="text-base-700 flex flex-col gap-2 mb-6">
        <div className="font-medium text-2xl">Create a record please</div>
        <div className="text-sm">
          This data source will be populated incrementally with the customer
          attributes received through your API requests.
        </div>
      </div>
      <Form<{ name: string; storageLocation: { label: string; value: string } }>
        initialValues={{
          name: '',
          storageLocation: undefined,
        }}
        onSubmit={(values) => {
          mutate(
            {
              name: values.name,
              region: values.storageLocation.value,
            },
            {
              onSuccess: () => {
                successToast('Record created successfully')
                onClose()
              },
              onError: () => {
                errorToast('Failed to create record')
              },
            }
          )
        }}
        render={({ handleSubmit, hasValidationErrors }) => (
          <form
            id="workflow-edit-form"
            onSubmit={handleSubmit}
            className="flex flex-col h-full overflow-hidden"
            autoComplete="false"
            {...{
              'data-lpignore': true,
            }}
          >
            <Field<string>
              initialValue=""
              name="name"
              parse={(value) => {
                const { value: parsedValue, validationError } =
                  parseRecordName(value)

                setError(validationError)
                return parsedValue
              }}
            >
              {({ input, meta }) => (
                <FormItem
                  error={meta.touched ? meta.error || error : undefined}
                  htmlFor={input.name}
                  label="Name"
                  className="flex-none mb-4"
                >
                  <Input
                    data-cy="edit-name-input"
                    data-testid="edit-name-input"
                    type="text"
                    variant="default"
                    className="w-full focus:bg-maroon-40"
                    onBlur={(e) => {
                      setIsInputFocused(false)
                      input.onBlur(e) // needed for meta.touched to work
                    }}
                    onFocus={() => setIsInputFocused(true)}
                    value={input.value}
                    required
                    onChange={input.onChange}
                    name={input.name}
                    id={`${input.name}-search`}
                    placeholder="Select your name"
                    autoComplete="false"
                    maxLength={RECORD_NAME_LIMIT}
                    {...{
                      'data-lpignore': true,
                    }}
                  />
                  {isInputFocused && (
                    <p className="h-4 text-coolGray-400 mt-2">
                      {input.value.length} / {RECORD_NAME_LIMIT}
                    </p>
                  )}
                </FormItem>
              )}
            </Field>
            <Field<SelectValue>
              name="storageLocation"
              validate={validatePrivacyRegion}
            >
              {({ meta, input }) => (
                <FormItem
                  label="Choose data storage location"
                  error={meta.touched ? meta.error : undefined}
                  className="w-full col-span-2"
                >
                  <SingleSelect
                    placeholder="Select your privacy region"
                    value={input.value.value}
                    options={accountPrivacyRegionsQuery.data?.map((region) => ({
                      label: region,
                      value: region,
                    }))}
                    onChange={input.onChange}
                    menuPortalTarget={document.body}
                    onBlur={input.onBlur} // needed for meta.touched to work
                  />
                </FormItem>
              )}
            </Field>

            <div className="flex gap-4 mt-10 justify-end ">
              <Button
                variant="link"
                ghost
                onClick={handleClose}
                data-cy="cancel"
                data-testid="cancel"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                type="submit"
                data-cy="create"
                data-testid="create"
                disabled={hasValidationErrors}
                loading={isLoading}
              >
                Create
              </Button>
            </div>
          </form>
        )}
      />
    </Modal>
  )
}

export default CreateRecordModal
