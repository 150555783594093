import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { getProfileAttributesCatalog, Region } from '../api'
import { contentKeys } from '../queryKeys'

const useGetProfileAttributesTags = ({
  region,
  attributeCatalogId,
}: {
  region: Region | undefined
  attributeCatalogId: string
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getProfileAttributesTags = useQuery(
    contentKeys.attributeCatalogTags({ accountId, attributeCatalogId, region }),
    () =>
      getProfileAttributesCatalog({ accountId, attributeCatalogId, region }),
    {
      enabled: !!region && !!accountId && !!attributeCatalogId,
      select: (data) =>
        data.map((entry) => ({ label: entry.name, value: entry.name })),
      meta: {
        errorMessage: 'Profile attributes could not be loaded',
      },
    }
  )

  return getProfileAttributesTags
}

export default useGetProfileAttributesTags
