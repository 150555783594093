/* eslint-disable max-lines */
import { useEffect, useState } from 'react'
import { Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import moment from 'moment-timezone'

import Button from 'common/components/button/Button'
import CampaignConfirmationModal from 'common/components/CampaignConfirmationModal'
import Loader from 'common/components/loaders/Loader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import LoginCard from 'features/campaigns/integration-options/components/card/LoginCard'
import { SelectOption } from 'features/campaigns/integration-options/components/select/SelectComponent'

import {
  checkFacebookTokenExists,
  createCampaign,
  disconnectProject,
  getCampaign,
  getFacebookAdAccounts,
  getFacebookAdSets,
  getFacebookAdverts,
  getFacebookCampaigns,
  onFacebookLoginClick,
  schedule,
  setError,
  setLoading,
  updateAdAccounts,
  updateAdSets,
  updateAdverts,
  updateBusinessId,
  updateCampaigns,
  updateFBData,
  updateFBLogin,
  updateScheduleStatus,
} from './store/facebookSlice'
import DrawerContent from './DrawerContent'

export const initialValidationErrors: ValidationErrors = {
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
  adAccount: '',
  campaign: '',
  adSet: '',
  advert: '',
  optimizationType: '',
}

interface ValidationErrors {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  adAccount: string
  campaign: string
  adSet: string
  advert: string
  optimizationType: string
}

interface State {
  startDate: moment.Moment | undefined
  startTime: moment.Moment | undefined
  endDate: moment.Moment | undefined
  endTime: moment.Moment | undefined
  adAccounts: SelectOption[] | undefined
  selectedAdAccount: string | undefined
  selectedCampaign: string | undefined
  selectedAdSet: string | undefined
  adverts: SelectOption[] | undefined
  selectedAdvert: string | undefined
  optimizationType: string | undefined
  errorMessages: ValidationErrors
  showModal: boolean
}

export default function FacebookIntegration() {
  const initialState: State = {
    startDate: undefined,
    startTime: undefined,
    endDate: undefined,
    endTime: undefined,
    adAccounts: undefined,
    selectedAdAccount: undefined,
    selectedCampaign: undefined,
    selectedAdSet: undefined,
    adverts: undefined,
    selectedAdvert: undefined,
    optimizationType: undefined,
    errorMessages: initialValidationErrors,
    showModal: false,
  }
  const dispatch = useAppDispatch()

  const [state, setState] = useState<State>(initialState)
  const {
    endTime,
    endDate,
    startDate,
    startTime,
    showModal,
    selectedAdAccount,
    selectedCampaign,
    selectedAdSet,
    selectedAdvert,
    optimizationType,
  } = state

  const {
    facebook: {
      fbConnected,
      fbUserName,
      adAccounts,
      isLoading,
      error,
      adverts,
      campaigns,
      adSets,
      campaignData: fbCampaignData,
      isCampaignScheduled,
      fbBusinessId,
    },
    campaignStates: {
      campaignData,
      campaignData: { project_id },
      campaignName,
      currentCampaignId,
    },
  } = useAppSelector((state) => state)

  useEffect(() => {
    if (!fbConnected && project_id) {
      dispatch(setLoading({ field: 'fbAuth', value: true }))
      checkFacebookTokenExists({ project_id })
        .then((data) => {
          dispatch(
            updateFBLogin({
              fbConnected: true,
              fbUserName: data?.data?.data?.displayName,
              fbUserId: '',
            })
          )
        })
        .finally(() => dispatch(setLoading({ field: 'fbAuth', value: false })))
    }

    if (fbConnected && !fbCampaignData) {
      dispatch(setLoading({ field: 'fbCampaign', value: true }))
      getCampaign({
        campaign_id: currentCampaignId,
      })
        .then((res) => dispatch(updateFBData(res?.data?.data)))
        .catch((err) => dispatch(setError(err.message)))
        .finally(() =>
          dispatch(setLoading({ field: 'fbCampaign', value: false }))
        )
    }

    if (fbConnected && !adAccounts) {
      dispatch(setLoading({ field: 'adAccounts', value: true }))
      getFacebookAdAccounts({ project_id })
        .then((data) => {
          dispatch(updateAdAccounts(data?.data?.data?.adaccounts))
        })
        .catch((err) => dispatch(setError(err.message)))
        .finally(() =>
          dispatch(setLoading({ field: 'adAccounts', value: false }))
        )
    }

    if (selectedAdAccount) {
      const foundAdAccount: any = adAccounts?.find(
        (adAccount: any) => adAccount.id === selectedAdAccount
      )
      dispatch(updateBusinessId(foundAdAccount?.business?.id))
      dispatch(setLoading({ field: 'campaigns', value: true }))
      getFacebookCampaigns({
        project_id: project_id,
        fb_ad_account_id: selectedAdAccount,
      })
        .then((data) => {
          dispatch(updateCampaigns(data?.data?.data))
        })
        .catch((err) => dispatch(setError(err.message)))
        .finally(() =>
          dispatch(setLoading({ field: 'campaigns', value: false }))
        )
    }

    if (selectedCampaign) {
      dispatch(setLoading({ field: 'adSets', value: true }))
      getFacebookAdSets({
        project_id: project_id,
        fb_ad_account_id: selectedAdAccount,
        fb_campaign_id: selectedCampaign,
      })
        .then((data) => {
          dispatch(updateAdSets(data?.data?.data))
        })
        .catch((err) => dispatch(setError(err.message)))
        .finally(() => dispatch(setLoading({ field: 'adSets', value: false })))
    }

    if (selectedAdSet) {
      dispatch(setLoading({ field: 'adverts', value: true }))
      getFacebookAdverts({
        project_id: project_id,
        fb_ad_account_id: selectedAdAccount,
        fb_campaign_id: selectedCampaign,
        fb_adset_id: selectedAdSet,
      })
        .then((data) => {
          dispatch(updateAdverts(data?.data?.data))
        })
        .catch((err) => dispatch(setError(err.message)))
        .finally(() => dispatch(setLoading({ field: 'adverts', value: false })))
    }

    if (selectedAdvert && fbCampaignData?.fb_ad_id !== selectedAdvert) {
      const foundAdAccount: any = adAccounts?.find(
        (adAccount: any) => adAccount.id === selectedAdAccount
      )
      const foundAd: any = adverts?.find((ad: any) => ad.id === selectedAdvert)
      const foundAdSet: any = adSets?.find(
        (adSet: any) => adSet.id === selectedAdSet
      )
      const foundCampaign: any = campaigns?.find(
        (campaign: any) => campaign.id === selectedCampaign
      )
      const data = {
        campaign_action: 'saveAndContinue',
        currentStep: 4,
        current_step: 1,
        enable_fb_integration: true,
        fb_ad_account_id: foundAdAccount?.id,
        fb_ad_account_name: foundAdAccount?.name,
        fb_ad_id: foundAd?.id,
        fb_ad_name: foundAd?.name,
        fb_adset_id: foundAdSet?.id,
        fb_adset_name: foundAdSet?.name,
        fb_business_id: fbBusinessId,
        fb_campaign_id: foundCampaign?.id,
        fb_campaign_name: foundCampaign?.name,
        id: currentCampaignId,
        isNoAdBody: false,
        isSaving: false,
        name: campaignData?.name,
        own_subject_line: campaignData?.own_subject_line,
        previousStep: 1,
        previous_step: 4,
        project_id,
        type: 'facebook',
      }
      createCampaign(data)
    }

    if (
      fbCampaignData?.fb_ad_account_id &&
      fbCampaignData?.fb_campaign_id &&
      fbCampaignData?.fb_adset_id &&
      fbCampaignData?.fb_ad_id &&
      (fbCampaignData?.is_ad_study === false ||
        fbCampaignData?.is_ad_study === true) &&
      !selectedAdAccount &&
      !selectedCampaign &&
      !selectedAdSet &&
      !selectedAdvert &&
      !optimizationType
    ) {
      const optimization = fbCampaignData?.is_ad_study ? 'adStudy' : 'standard'
      setState({
        ...state,
        selectedAdAccount: fbCampaignData.fb_ad_account_id,
        selectedCampaign: fbCampaignData.fb_campaign_id,
        selectedAdSet: fbCampaignData.fb_adset_id,
        selectedAdvert: fbCampaignData.fb_ad_id,
        optimizationType: optimization,
        startDate: fbCampaignData.fb_start_date
          ? moment(fbCampaignData.fb_start_date)
          : undefined,
        startTime: fbCampaignData.fb_start_date
          ? moment(fbCampaignData.fb_start_date)
          : undefined,
        endDate: fbCampaignData.fb_end_date
          ? moment(fbCampaignData.fb_end_date)
          : undefined,
        endTime: fbCampaignData.fb_end_date
          ? moment(fbCampaignData.fb_end_date)
          : undefined,
      })
      if (fbCampaignData?.fb_start_date && fbCampaignData?.fb_end_date) {
        dispatch(updateScheduleStatus(true))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fbCampaignData?.fb_ad_account_id,
    fbCampaignData?.fb_campaign_id,
    fbCampaignData?.fb_adset_id,
    fbCampaignData?.fb_ad_id,
    fbCampaignData?.is_ad_study,
    fbCampaignData?.fb_start_date,
    fbCampaignData?.fb_end_date,
    fbBusinessId,
    currentCampaignId,
    fbConnected,
    dispatch,
    project_id,
    selectedAdAccount,
    selectedCampaign,
    selectedAdSet,
    adAccounts,
    selectedAdvert,
  ])

  const validateInputs = (): boolean => {
    let isFormValid = true
    const validationErrors: ValidationErrors = initialValidationErrors
    // Validate that all required values exist
    if (!startDate || !startTime) {
      isFormValid = false
      validationErrors.startDate = 'Ad start date and time fields are required.'
    }
    if (!endDate || !endTime) {
      isFormValid = false
      validationErrors.endDate = 'Ad end date and time fields are required.'
    }
    if (!selectedAdAccount) {
      isFormValid = false
      validationErrors.adAccount = 'Ad account field is required.'
    }
    if (!selectedCampaign) {
      isFormValid = false
      validationErrors.campaign = 'Campaign field is required.'
    }
    if (!selectedAdSet) {
      isFormValid = false
      validationErrors.adSet = 'Ad set field is required.'
    }
    if (!selectedAdvert) {
      isFormValid = false
      validationErrors.advert = 'Ad field is required.'
    }
    if (!optimizationType) {
      isFormValid = false
      validationErrors.optimizationType = 'Optimisation type field is required.'
    }
    setState({ ...state, errorMessages: validationErrors })
    return isFormValid
  }

  const onSchedule = () => {
    if (validateInputs()) {
      const fb_start_date = `${moment(startDate).format('YYYY-MM-DD')} ${moment(
        startTime
      ).format('HH:mm')}`
      const fb_end_date = `${moment(endDate).format('YYYY-MM-DD')} ${moment(
        endTime
      ).format('HH:mm')}`
      const data = {
        fb_start_date,
        fb_end_date,
        fb_tz: moment.tz.guess(),
        campaign_id: currentCampaignId,
        project_id,
        fb_ad_account_id: selectedAdAccount,
        fb_campaign_id: selectedCampaign,
        fb_adset_id: selectedAdSet,
        fb_ad_id: selectedAdvert,
        fb_business_id: fbBusinessId,
        sls: campaignData?.sl,
        is_ad_study: optimizationType === 'adStudy',
        fb_start_date_unix: moment(fb_start_date).unix(),
        fb_end_date_unix: moment(fb_end_date).unix(),
        campaignName: campaignName,
        create_single_adset: optimizationType === 'standard',
        previousStep: 4,
        previous_step: 0,
        currentStep: 5,
        current_step: 4,
      }
      dispatch(setLoading({ field: 'scheduling', value: false }))
      schedule(data)
        .then(() => {
          dispatch(updateScheduleStatus(true))
        })
        .catch((err) => dispatch(setError(err.message)))
        .finally(() =>
          dispatch(setLoading({ field: 'scheduling', value: false }))
        )
    }
  }

  const showPopup = () => {
    setState({
      ...state,
      showModal: true,
    })
  }

  const handleOk = () => {
    dispatch(setLoading({ field: 'fbAuth', value: true }))
    disconnectProject({ project_id })
      .then(() => {
        dispatch(
          updateFBLogin({
            fbUserId: '',
            fbUserName: '',
            fbConnected: false,
          })
        )
      })
      .catch((err) => dispatch(setError(err.message)))
      .finally(() => dispatch(setLoading({ field: 'fbAuth', value: false })))
    setState({
      ...state,
      showModal: false,
    })
  }

  return (
    <>
      <Drawer.Content>
        <Form>
          <Loader isLoading={Object.values(isLoading).includes(true)}>
            <div
              data-testid="facebookIntegrationTitle"
              className="text-base font-medium text-gray-400"
            >
              Connect your Facebook account with your Jacquard experiment.
            </div>
            <LoginCard
              name={fbUserName}
              onSubmit={() =>
                onFacebookLoginClick(
                  project_id,
                  (
                    fbUserId: string,
                    fbUserName: string,
                    fbConnected: boolean
                  ) =>
                    dispatch(
                      updateFBLogin({ fbUserId, fbUserName, fbConnected })
                    )
                )
              }
              onDisconnect={showPopup}
              integrationType="Facebook"
            />
            {fbConnected && (
              <DrawerContent
                isLoading={isLoading}
                currentState={state}
                setState={setState}
                adAccounts={adAccounts}
                adverts={adverts}
                campaigns={campaigns}
                adSets={adSets}
                isCampaignScheduled={isCampaignScheduled}
              />
            )}
          </Loader>
        </Form>
      </Drawer.Content>
      <Drawer.Footer error={error}>
        <Button
          loading={isLoading.scheduling}
          variant="primary"
          disabled={
            !fbConnected ||
            !selectedAdAccount ||
            !selectedCampaign ||
            !selectedAdSet ||
            !selectedAdvert ||
            !optimizationType ||
            !startDate ||
            !startTime ||
            !endDate ||
            !endTime ||
            isCampaignScheduled
          }
          data-testid="scheduleBtn"
          onClick={onSchedule}
        >
          Save
        </Button>
      </Drawer.Footer>
      <CampaignConfirmationModal
        title="Are you sure want to disconnect your Facebook account?"
        visible={showModal}
        onOk={handleOk}
        onCancel={() =>
          setState({
            ...state,
            showModal: false,
          })
        }
      >
        <p>{`If you disconnect ${fbUserName}, Jacquard will be
        unable to continue running any active experiments.`}</p>
      </CampaignConfirmationModal>
    </>
  )
}
