import Autocomplete from 'common/components/autocomplete'
import SingleSelect from 'common/components/singleSelect'

import Filter from './Filter'
import {
  phrasesAutocompleteCustomStyles,
  selectCustomStyles,
} from './FilterStyles'

type Props = {
  matches: Set<string>
  searchValue: string | undefined
  onSearch: (value: string) => void
}

export const MINIMUM_SEARCH_LENGTH = 3

export const getCanSearch = (searchValue: string | undefined) =>
  searchValue && searchValue.length >= MINIMUM_SEARCH_LENGTH

const PhrasesTableFilter = ({ matches, searchValue, onSearch }: Props) => {
  const canSearch = getCanSearch(searchValue)

  return (
    <Filter>
      <SingleSelect
        options={[
          { label: 'Find', value: 'find' },
          { label: 'Find + replace', value: 'findAndReplace' },
        ]}
        value="find" // Fixed value for now
        customStyles={selectCustomStyles}
      />
      <Autocomplete
        className="w-60"
        customStyles={phrasesAutocompleteCustomStyles}
        placeholder="Phrase or tag"
        showSearchIcon={false}
        data-cy="phrase-table-searchbox"
        data-testid="phrase-table-searchbox"
        options={
          canSearch && matches
            ? [...matches].map((match) => ({
                label: match,
                value: match,
              }))
            : []
        }
        value={
          searchValue ? { value: searchValue, label: searchValue } : undefined
        }
        defaultInputValue={searchValue}
        onSearch={onSearch}
        backspaceRemovesValue={true}
      />
    </Filter>
  )
}

export default PhrasesTableFilter
