import { useState } from 'react'

import Input from 'common/components/input'
import { useAppDispatch } from 'common/hooks/redux'
import useDebounce from 'common/hooks/useDebounce'

import { onSearchChange, onSearchReset } from '../store/imagesSlice'

const ImageSearchInput = () => {
  const dispatch = useAppDispatch()

  const [searchTerm, setSearchTerm] = useState('')

  const debouncedOnSearchChange = useDebounce((value) => {
    dispatch(onSearchChange(value))
  }, 500)

  const handleOnSearch = ({ target }) => {
    setSearchTerm(target.value)
    debouncedOnSearchChange(target.value)
  }

  const handleOnSearchReset = () => {
    setSearchTerm('')
    dispatch(onSearchReset())
  }

  return (
    <Input
      type="search"
      variant="default"
      placeholder="Search"
      value={searchTerm}
      onChange={handleOnSearch}
      clear={() => handleOnSearchReset()}
    />
  )
}

export default ImageSearchInput
