import IconTitleGroup from 'common/components/iconTitleGroup'
import useGetImagesByIdsQuery from 'common/components/images/api/queries/useGetImagesByIdsQuery'
import { getInitialVariantOnly } from 'common/components/images/helpers'
import { getInitialImageIds } from 'common/components/images/helpers'
import Loader from 'common/components/loaders/Loader'
import Error from 'common/components/table/Error'
import { useSelectedComponentElement } from 'features/unifiedFlow/contentPage/hooks'

import TableHeading from '../tableHeading'

import ImageSelector from './ImageSelector'

type Props = {
  isReadOnly?: boolean
}

const Images = ({ isReadOnly }: Props) => {
  const {
    data: selectedElement,
    isLoading: isLoadingSelectedElement,
    isError: isErrorSelectedElement,
  } = useSelectedComponentElement()
  const {
    data: images,
    isInitialLoading: isLoadingImages,
    isError: isErrorImages,
  } = useGetImagesByIdsQuery(getInitialImageIds(selectedElement))

  const getState = () => {
    if (isLoadingImages || isLoadingSelectedElement) {
      return 'loading'
    } else if (isErrorSelectedElement || isErrorImages) {
      return 'error'
    } else {
      return 'imageSelector'
    }
  }

  const state = getState()

  return (
    <>
      <TableHeading
        heading={selectedElement?.display_name || selectedElement?.name}
        subHeading="We have selected the best images based on your brief and the variants we generated from your image library."
      />
      <IconTitleGroup title="Images" className="mb-4" />
      {
        {
          loading: (
            <div className="h-114.5">
              <Loader />
            </div>
          ),
          error: (
            <div className="h-114.5">
              <Error />
            </div>
          ),
          imageSelector: (
            <ImageSelector
              isReadOnly={isReadOnly}
              isVariantOnly={getInitialVariantOnly(selectedElement)}
              images={images}
            />
          ),
        }[state]
      }
    </>
  )
}

export default Images
