import { useEffect, useMemo, useRef, useState } from 'react'
import { Field, useFormState } from 'react-final-form'
import cx from 'classnames'

import FormItem from 'common/components/formItem/FormItem'
import InfoAction from 'common/components/InfoAction'
import Input from 'common/components/input/Input'
import Loader from 'common/components/loaders/Loader'
import NumericInput from 'common/components/numericInput'
import Tags from 'common/components/tags'
import useGetProfileAttributesTags from 'features/unifiedFlow/api/queries/useGetProfileAttributesTags'
import useLoadPersonalizationSelectors from 'features/unifiedFlow/components/templateSelection/setupExperiment/hooks/useLoadPersonalizationSelectors'

import { useExperimentForm } from '../context/ExperimentFormContext'
import { getExperimentPrefixedName, getFormValue } from '../helpers'

import CustomerJourney from './CustomerJourney'
import ShowMoreButton from './ShowMoreButton'
import SingleSelectField from './SingleSelectField'

type FormFieldOptions =
  | 'brandVoices'
  | 'privacyRegion'
  | 'productCatalog'
  | 'profileAttributes'
  | 'customerJourney'
  | 'controlSubjectLine'
  | 'holdout'

const validateNotEmptyControlLine = (value: string | undefined) => {
  return !value || !value.trim() ? 'Enter a non-empty control line' : undefined
}

export const validateNotEmptyField = (value: string | undefined) => {
  return !value ? 'Required' : undefined
}

const validateHoldout = (value: number | undefined) => {
  return value === undefined ? 'Select a holdout percentage' : undefined
}

const formFields: Record<FormFieldOptions, FormFieldOptions> = {
  brandVoices: 'brandVoices',
  privacyRegion: 'privacyRegion',
  productCatalog: 'productCatalog',
  profileAttributes: 'profileAttributes',
  customerJourney: 'customerJourney',
  controlSubjectLine: 'controlSubjectLine',
  holdout: 'holdout',
}

const SetUpPersonalization = () => {
  const [areAttributesExpanded, setAreAttributesExpanded] = useState(false)
  const [areAttributesOverflowing, setAreAttributesOverflowing] =
    useState(false)
  const attributeContainerRef = useRef<HTMLDivElement>(null)

  const {
    state: { template },
  } = useExperimentForm()

  const formState = useFormState()
  const formValues = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(formFields).map(([key]) => [
          key,
          getFormValue({
            formState,
            name: `experiment.${key}`,
            templateInternalId: template?.internalId,
          }),
        ])
      ),
    [formState, template]
  )

  const {
    brandVoices,
    brandVoicesQuery,
    privacyRegions,
    privacyRegionsQuery,
    productCatalogs,
    productCatalogsQuery,
    profileAttributeCatalogs,
    profileAttributeCatalogsQuery,
    customerJourneys,
    customerJourneyQuery,
  } = useLoadPersonalizationSelectors({
    privacyRegion: formValues.privacyRegion,
  })

  const { data: profileAttributesTags, ...profileAttributesTagsQuery } =
    useGetProfileAttributesTags({
      region: formValues.privacyRegion,
      attributeCatalogId: formValues.profileAttributes,
    })

  useEffect(() => {
    const container = attributeContainerRef.current
    if (container) {
      setAreAttributesOverflowing(
        container.scrollHeight > container.clientHeight
      )
    }
  }, [profileAttributesTags, areAttributesExpanded])

  const primaryDataLoading =
    brandVoicesQuery.isLoading ||
    privacyRegionsQuery.isLoading ||
    customerJourneyQuery.isLoading

  const areAttributeTagsLoading =
    profileAttributesTagsQuery.isLoading &&
    profileAttributesTagsQuery.fetchStatus !== 'idle'

  const dataSourcesLoading =
    (productCatalogsQuery.isLoading &&
      productCatalogsQuery.fetchStatus !== 'idle') ||
    (profileAttributeCatalogsQuery.isLoading &&
      profileAttributeCatalogsQuery.fetchStatus !== 'idle')

  if (!template) {
    return null
  }

  const formFieldsPerTemplate = Object.fromEntries(
    Object.entries(formFields).map(([key, value]) => [
      key,
      getExperimentPrefixedName(template, value),
    ])
  )

  if (
    primaryDataLoading ||
    (dataSourcesLoading && privacyRegions?.length === 1)
  ) {
    return (
      <div className="w-full">
        <Loader />
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="flex flex-col gap-6 pb-6">
        <SingleSelectField
          name={formFieldsPerTemplate.brandVoices}
          label="Brand voice"
          placeholder="Select your brand voice..."
          initialValue={
            brandVoices?.length === 1 ? brandVoices[0].value : undefined
          }
          options={brandVoices}
          validate={validateNotEmptyField}
          data-cy="brand-voice-select"
          data-testid="brand-voice-select"
          guidingText="This will set the language settings for the generated content."
          noOptionsMessage={() => (
            <span className="uppercase">No brand voices detected</span>
          )}
        />
        <SingleSelectField
          name={formFieldsPerTemplate.privacyRegion}
          label="Privacy region"
          placeholder="Select your privacy region..."
          initialValue={
            privacyRegions?.length === 1 ? privacyRegions[0].value : undefined
          }
          options={privacyRegions}
          validate={validateNotEmptyField}
          data-cy="privacy-region-select"
          data-testid="privacy-region-select"
          guidingText="This will filter and display catalogues based on the selected privacy region."
          noOptionsMessage={() => (
            <span className="uppercase">No privacy regions detected</span>
          )}
        />
        <hr className="w-full border-gold-400" />
        <Loader
          hasWrapperFullHeight={false}
          isLoading={dataSourcesLoading || areAttributeTagsLoading}
        >
          <div className="flex flex-col gap-6">
            <SingleSelectField
              name={formFieldsPerTemplate.productCatalog}
              label="Product catalogue"
              placeholder="Select your data source..."
              initialValue={productCatalogs?.[0]?.id ?? undefined}
              options={
                Array.isArray(productCatalogs)
                  ? productCatalogs?.map((catalog) => ({
                      label: catalog.name,
                      value: catalog.id,
                    }))
                  : []
              }
              validate={validateNotEmptyField}
              isDisabled={!formValues.privacyRegion}
              data-cy="product-catalog-select"
              noOptionsMessage={() => (
                <span className="uppercase">No file detected</span>
              )}
            />
            <SingleSelectField
              name={formFieldsPerTemplate.profileAttributes}
              label="Profile attributes"
              placeholder="Select your data source..."
              initialValue={profileAttributeCatalogs?.[0]?.id ?? undefined}
              options={
                Array.isArray(profileAttributeCatalogs)
                  ? profileAttributeCatalogs?.map((attributeCatalog) => ({
                      label: attributeCatalog.name,
                      value: attributeCatalog.id,
                    }))
                  : []
              }
              validate={validateNotEmptyField}
              isDisabled={!formValues.privacyRegion}
              data-cy="profile-attributes-select"
              data-testid="profile-attributes-select"
              noOptionsMessage={() => (
                <span className="uppercase">No file detected</span>
              )}
            />
            {!!formValues.profileAttributes && (
              <>
                <div
                  ref={attributeContainerRef}
                  className={cx('flex flex-col gap-2', {
                    'max-h-7 overflow-hidden': !areAttributesExpanded,
                  })}
                >
                  <Tags isFreeText tags={profileAttributesTags ?? []} />
                </div>
                {((areAttributesExpanded && !areAttributesOverflowing) ||
                  (!areAttributesExpanded && areAttributesOverflowing)) && (
                  <ShowMoreButton
                    isExpanded={areAttributesExpanded}
                    setIsExpanded={setAreAttributesExpanded}
                  />
                )}
              </>
            )}
          </div>
        </Loader>
        <hr className="w-full border-gold-400" />
        <CustomerJourney
          name={formFieldsPerTemplate.customerJourney}
          options={customerJourneys}
        />
        <Field<string>
          name={formFieldsPerTemplate.controlSubjectLine}
          validate={validateNotEmptyControlLine}
          render={({ input, meta }) => (
            <FormItem
              label="Control"
              htmlFor="controlSubjectLine"
              guidingText="This will be used to compare results against optimised
                  benchmarking."
              className="w-full"
              error={meta.touched ? meta.error : undefined}
            >
              <Input
                id="controlSubjectLine"
                type="text"
                variant="default"
                onChange={(val) => input.onChange(val)}
                onBlur={input.onBlur}
                value={input.value}
                placeholder="e.g Black Friday sale at 50% off today"
                data-cy="control-subject-line-input"
                data-testid="control-subject-line-input"
              />
            </FormItem>
          )}
        />
        <Field<number>
          name={formFieldsPerTemplate.holdout}
          validate={validateHoldout}
          initialValue={15}
          render={({ input, meta }) => (
            <FormItem
              label={
                <div className="flex gap-1">
                  <span>Holdout</span>
                  <InfoAction message="Adjust the percentage to control how frequently the control message reaches your audience, establishing a comparison group. Values can range from 5% to 30%" />
                </div>
              }
              htmlFor="holdout"
              error={meta.error}
              className="w-40"
            >
              <NumericInput
                id="holdout"
                variant="default"
                min={5}
                max={30}
                postfix="%"
                precision={0}
                onChange={(val) => input.onChange(val)}
                value={input.value}
                placeholder="Enter holdout..."
                data-cy="holdout-input"
                data-testid="holdout-input"
              />
            </FormItem>
          )}
        />
      </div>
    </div>
  )
}

export default SetUpPersonalization
