import { useEffect } from 'react'
import { Content } from '@phrasee/phrasee-typings'
import { useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'
import useWebsockets, { WsResponseBase } from 'common/hooks/useWebsockets'
import { contentKeys } from 'features/unifiedFlow/api/queryKeys'
import {
  useContent,
  useSelectedComponentElement,
} from 'features/unifiedFlow/contentPage/hooks'
import useWebsocketMessage from 'features/unifiedFlow/contentPage/hooks/websocketMessage'

interface WsResponse extends WsResponseBase {
  content: Content
}

type Props = {
  onRefreshSuccess?: (newVariantId: string) => void
  onMoreLikeThisSuccess?: (newVariantId: string) => void
}

const useCoreVariantsWebsocketSubscription = ({
  onRefreshSuccess,
  onMoreLikeThisSuccess,
}: Props) => {
  const {
    lastJsonMessage,
  }: {
    lastJsonMessage: WsResponse
  } = useWebsockets()
  const queryClient = useQueryClient()
  const { content } = useContent()
  const { sendWebsocketMessage } = useWebsocketMessage()
  const { data: selectedElement } = useSelectedComponentElement()

  useEffect(() => {
    if (content && selectedElement && lastJsonMessage) {
      const wsTopics = lastJsonMessage?.topics
      const replaceVariantRegex = new RegExp(
        `content/${content._id}/element/([^/]+)/variant/([^/]+)/refreshVariant`
      )

      const replaceVariantTopic = wsTopics?.find((topic) =>
        replaceVariantRegex.test(topic)
      )
      if (replaceVariantTopic) {
        const wsTopicElementId =
          replaceVariantTopic?.match(replaceVariantRegex)?.[1]
        const wsTopicVariantId =
          replaceVariantTopic?.match(replaceVariantRegex)?.[2]

        if (lastJsonMessage.status === 'success') {
          if (
            wsTopicVariantId &&
            wsTopicElementId === String(selectedElement.element_id)
          ) {
            onRefreshSuccess?.(wsTopicVariantId)
          }
          queryClient.setQueryData(
            contentKeys.content(content._id),
            lastJsonMessage.content
          )
        } else if (lastJsonMessage.status === 'error') {
          errorToast('Something went wrong. Please try again.')
        }
        if (wsTopicElementId && wsTopicVariantId) {
          sendWebsocketMessage({
            action: 'refreshVariant',
            data: {
              contentId: content._id,
              elementId: Number(wsTopicElementId),
              variantId: wsTopicVariantId,
            },
            subscriptionAction: 'unsubscribe',
          })
        }
      }

      const moreLikeThisVariantsRegex = new RegExp(
        `content/${content._id}/element/([^/]+)/variant/([^/]+)/moreLikeThisVariants`
      )

      const moreLikeThisVariantsTopic = wsTopics?.find((topic) =>
        moreLikeThisVariantsRegex.test(topic)
      )
      if (moreLikeThisVariantsTopic) {
        const wsTopicElementId = moreLikeThisVariantsTopic?.match(
          moreLikeThisVariantsRegex
        )?.[1]
        const wsTopicVariantId = moreLikeThisVariantsTopic?.match(
          moreLikeThisVariantsRegex
        )?.[2]

        if (lastJsonMessage.status === 'success') {
          if (
            wsTopicVariantId &&
            wsTopicElementId === String(selectedElement.element_id)
          ) {
            onMoreLikeThisSuccess?.(wsTopicVariantId)
          }
          queryClient.setQueryData(
            contentKeys.content(content._id),
            lastJsonMessage.content
          )
        } else if (lastJsonMessage.status === 'error') {
          errorToast('Something went wrong. Please try again.')
        }
        if (wsTopicElementId && wsTopicVariantId) {
          sendWebsocketMessage({
            action: 'moreLikeThisVariants',
            data: {
              contentId: content._id,
              elementId: Number(wsTopicElementId),
              variantId: wsTopicVariantId,
            },
            subscriptionAction: 'unsubscribe',
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage])
}

export default useCoreVariantsWebsocketSubscription
