import cx from 'classnames'

import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import { Add as AddIcon } from 'common/icons'

import { MOCK_PAGES } from '../interfaces'

interface Props {
  selectedPage: string
  onPageSelect: (id: string) => void
}

// TODO: Reuse components from UF.
const PagesColumn = ({ selectedPage, onPageSelect }: Props) => {
  return (
    <div className="border-r border-gold-400 shrink-0 pr-6 flex flex-col gap-2">
      <h1 className="text-2xl font-medium text-base-700 whitespace-nowrap tracking-tight mb-6">
        Reporting Dashboard
      </h1>
      <div className="flex items-center justify-between py-1">
        <h2 className="px-2 text-sm font-medium">PAGES</h2>
        <ButtonWithTooltip
          tooltip
          tooltipText="Add new page"
          size="small"
          prefixIcon={
            <AddIcon
              isDefaultColor={false}
              className="text-gold-600"
              size={4}
            />
          }
        />
      </div>
      {MOCK_PAGES.map((page) => (
        <div
          key={page.id}
          onClick={() => onPageSelect(page.id)}
          className={cx(
            'py-1 px-2 cursor-pointer text-sm text-base-700 font-normal',
            {
              'bg-yellow-200': selectedPage === page.id,
              'hover:bg-yellow-50': selectedPage !== page.id,
            }
          )}
        >
          {page.name}
        </div>
      ))}
    </div>
  )
}

export default PagesColumn
