import { forwardRef } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import type { InputProps } from './Input'
import Input from './Input'
import InputDefault from './InputDefault'

const InputPerFlag = forwardRef<HTMLInputElement, InputProps>(
  (props: InputProps, ref) => {
    const flags = useFlags()

    return flags?.showL2Components ? (
      <Input {...props} ref={ref} />
    ) : (
      <InputDefault {...props} ref={ref} />
    )
  }
)

export type { InputProps }

export default InputPerFlag
