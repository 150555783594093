import { PersonalizedVariant, Variant } from '@phrasee/phrasee-typings'

import { connectInternalApi, getRegionalizedApiPerRegion } from 'common/api'

export type Region = 'EU' | 'US' | 'UK'

type ContentResponse = {
  items: {
    id: string
    name: string
    account: { id: string; name: string }
    createdDate: string
    isActive: boolean
    region: Region
    updatedDate: string
    defaultStatus: 'New Combination' | 'Inactive'
  }[]
}

type DetailsResponse = {
  items: {
    personalizationId: string
    generatedVariants: number
    status: string
  }[]
}

export type PersonalizationContentResponse = {
  id: string
  account: { id: string; name: string }
  createdDate: string
  isActive: boolean
  name: string
  region: Region
  updatedDate: string
  personalizationId: string
  generatedVariants: number
  status: string
  defaultStatus: 'New Combination' | 'Inactive'
}[]

export type CombinationLibraryEntryStatus =
  | 'New combination'
  | 'Awaiting approval'
  | 'Regeneration'
  | 'Approved'

export type CombinationLibraryResponse = {
  items: {
    id: string
    channel: string
    campaignType: string
    productItem: string
    productCategory: string
    status: CombinationLibraryEntryStatus
    dimensions: { key: string; value: string }[]
  }[]
}

export type CombinationVariantsResponse = {
  personalized: PersonalizedVariant[]
  baselines: Variant[]
  newVariantIds: string[]
  baselineApproved: boolean
}

export async function fetchContent(): Promise<PersonalizationContentResponse> {
  const response = await connectInternalApi.get<ContentResponse>(
    `v1/accounts/personalization/library`
  )

  const regions = [
    ...new Set(response.data.items.map((item) => item.region.toLowerCase())),
  ]
  const details = await Promise.all(
    regions.map((region) =>
      getRegionalizedApiPerRegion(region).get<DetailsResponse>(
        '/accounts/personalization/details'
      )
    )
  )
  const flattenedDetails = details.flatMap(
    (detailsResponse) => detailsResponse.data.items
  )
  return response.data.items.map((item) => ({
    ...item,
    ...(flattenedDetails.find(
      (detail) => detail.personalizationId === item.id
    ) ?? {
      personalizationId: '',
      generatedVariants: 0,
      status: item.defaultStatus,
    }),
  }))
}

export async function fetchCombinations({
  accountId,
  personalizationId,
  region,
}: {
  accountId: string | undefined
  personalizationId: string
  region: Region | undefined
}): Promise<CombinationLibraryResponse> {
  if (!region) {
    throw new Error('Region is not defined')
  }

  if (!accountId) {
    throw new Error('Account id id not defined for this content')
  }

  const response = await getRegionalizedApiPerRegion(
    region.toLowerCase()
  ).get<CombinationLibraryResponse>(
    `/accounts/${accountId}/personalization/${personalizationId}/combinations`
  )
  return response.data
}

export const fetchCombinationVariants = async ({
  accountId,
  personalizationId,
  combinationId,
  region,
}: {
  accountId: string
  personalizationId: string
  combinationId: string
  region: Region | undefined
}) => {
  if (!region) {
    throw new Error('Region is not defined')
  }

  if (!accountId) {
    throw new Error('Account id id not defined for this content')
  }

  const response = await getRegionalizedApiPerRegion(
    region.toLowerCase()
  ).get<CombinationVariantsResponse>(
    `accounts/${accountId}/personalization/${personalizationId}/language`,
    {
      params: { combination_id: combinationId },
    }
  )

  return response.data
}

export const tweakVariant = async ({
  accountId,
  personalizationId,
  combinationId,
  region,
  variantId,
  variantText,
  reason,
  changeType,
}: {
  accountId: string
  personalizationId: string
  combinationId: string
  region: Region | undefined
  variantId: string
  variantText: string
  reason?: string
  changeType: 'tweak' | 'replace'
}) => {
  if (!region) {
    throw new Error('Region is not defined')
  }

  if (!accountId) {
    throw new Error('Account id id not defined for this content')
  }

  const response = await getRegionalizedApiPerRegion(region.toLowerCase()).post(
    `accounts/${accountId}/personalization/${personalizationId}/combinations/${combinationId}/feedback`,
    {
      variant_id: variantId,
      variant_text: variantText,
      reason,
      feedback_type: changeType,
    }
  )

  return response.data
}
