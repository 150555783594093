import { useState } from 'react'

import type { ImageActionsProps } from 'common/components/images/imageActions'
import ImageActions from 'common/components/images/imageActions'
import ImageErrorFallback from 'common/components/images/imageErrorFallback'
import type { SelectableImageButtonProps } from 'common/components/images/selectableImageButton'
import SelectableImageButton from 'common/components/images/selectableImageButton'

type Props = Pick<SelectableImageButtonProps, 'url'> &
  Omit<ImageActionsProps, 'children'>

const AddedImageCard = ({ url, onPreview, onDelete, onReplace }: Props) => {
  const [isValidImage, setIsValidImage] = useState(!!url)

  return (
    <>
      {isValidImage ? (
        <ImageActions
          onPreview={onPreview}
          onDelete={onDelete}
          onReplace={onReplace}
        >
          <SelectableImageButton
            url={url}
            width={312}
            height={312}
            setIsValidImage={(value) => setIsValidImage(value)}
          />
        </ImageActions>
      ) : (
        <ImageErrorFallback hasBorder width="w-78" height="h-78" hasIcon />
      )}
    </>
  )
}

export default AddedImageCard
