import { generatePath } from 'react-router'
import { isAtLeastOneAccessFlagEnabled } from 'app/navigation/helpers'
import { TabbedNavRoute } from 'app/navigation/interfaces'
import { useFlags } from 'launchdarkly-react-client-sdk'

import SubNavBar, {
  SubNavItem,
  SubNavProps,
} from 'common/components/navigation/subNavBar'

type Props = {
  subroutes: TabbedNavRoute[]
} & SubNavProps

const LanguageSubNav = ({ subroutes, ...rest }: Props) => {
  const flags = useFlags()
  const routes = subroutes
    .filter(({ accessFlags, accessPermission }) =>
      isAtLeastOneAccessFlagEnabled(flags, accessFlags?.flags)
    )
    .map((route) => {
      return { ...route, to: generatePath(route.to) }
    })
  return (
    <SubNavBar {...rest}>
      {routes.map(({ name, to }) => (
        <SubNavItem key={name} title={name} to={to} />
      ))}
    </SubNavBar>
  )
}

export default LanguageSubNav
