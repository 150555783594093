import { forwardRef } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import RcTimePicker from 'rc-time-picker'

import type { TimePickerProps } from './TimePicker'
import TimePicker from './TimePicker'
import TimePickerDefault from './TimePickerDefault'

const TimePickerPerFlag = forwardRef<RcTimePicker, TimePickerProps>(
  (props, ref) => {
    const flags = useFlags()

    return flags?.showL2Components ? (
      <TimePicker ref={ref} {...props} />
    ) : (
      <TimePickerDefault ref={ref as any} {...props} />
    )
  }
)

export default TimePickerPerFlag
