import { Fragment } from 'react'

import { formatDate } from 'common/helpers/date'
import { getAllElements } from 'features/unifiedFlow/contentPage/helpers'

import RightPanelEmpty from '../../components/layout/RightPanelEmpty'
import { useContent } from '../../hooks'
import useGetLogsQuery from '../centerSection/api/queries/useGetLogsQuery'

const IntegrationLogSection = () => {
  const { content } = useContent()
  const { data: logs, logsState } = useGetLogsQuery(content?._id)

  const allElements = getAllElements(content)

  if (logsState === 'empty' || logsState === 'loading') {
    return <RightPanelEmpty />
  }

  return (
    <div
      className="h-full bg-gold-100 flex flex-col p-6 flex-1"
      data-testid="integration-log-panel"
    >
      <h5 className="text-base-700 font-medium text-2xl">Log</h5>
      {
        {
          hasLogs: (
            <>
              {logs?.map(({ elementId, entries }, index) => {
                const element = allElements.find(
                  (element) => element.element_id === elementId
                )

                const elementName = element?.display_name || element?.name

                return (
                  <div
                    key={index}
                    className="flex flex-col mt-6 p-6 bg-gold-40"
                    data-testid="integration-log-section"
                  >
                    <span
                      className="uppercase font-medium text-base-700"
                      data-testid="element-name"
                    >
                      {elementName}
                    </span>
                    <hr className="my-4 border-gold-400" />
                    {entries.map(
                      ({ files, providerName, sendDate, user }, index) => (
                        <Fragment key={index}>
                          {index > 0 && (
                            <hr className="my-4 text-coolGray-300" />
                          )}
                          <div className="flex gap-2">
                            <div>
                              <span className="block text-gold-700 text-sm">
                                User:
                              </span>
                              <span className="block text-gold-700 text-sm">
                                Sent date:
                              </span>
                              <span className="block text-gold-700 text-sm mt-6">
                                Files:
                              </span>
                            </div>
                            <div className="text-sm text-base-700">
                              <span className="block" data-testid="user">
                                {user}
                              </span>
                              <span className="block" data-testid="sent-date">
                                {formatDate(sendDate)}
                              </span>
                              <div className="mt-6">
                                <span
                                  className="block font-bold"
                                  data-testid="provider-name"
                                >
                                  {providerName}
                                </span>
                                <ul
                                  className="list-disc list-inside pl-2"
                                  data-testid="asset-list"
                                >
                                  {files.map((file, index) => (
                                    <li key={index}>{file}</li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      )
                    )}
                  </div>
                )
              })}
            </>
          ),
        }[logsState]
      }
    </div>
  )
}

export default IntegrationLogSection
