import useGetBrandsQuery from '../api/queries/useGetBrandsQuery'
import useGetChannelsQuery from '../api/queries/useGetChannelsQuery'
import useGetPersonasQuery from '../api/queries/useGetPersonasQuery'

const useDemoData = () => {
  const { data: channels, ...channelsQuery } = useGetChannelsQuery()
  const { data: brands, ...brandsQuery } = useGetBrandsQuery()
  const { data: personas, ...personasQuery } = useGetPersonasQuery()

  return {
    brands,
    channels,
    personas,
    isLoading:
      brandsQuery.isLoading ||
      channelsQuery.isLoading ||
      personasQuery.isLoading,
    isError:
      brandsQuery.isError || channelsQuery.isError || personasQuery.isError,
  }
}

export default useDemoData
