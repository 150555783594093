import cx from 'classnames'

import CropImage from '../cropImage'

export type SelectableImageButtonProps = {
  url?: string
  isSelected?: boolean
  isDisabled?: boolean
  width: number
  height: number
  onClick?: () => void
  onDisableClick?: () => void
  setIsValidImage?: (value: boolean) => void
}

const SelectableImageButton = ({
  url,
  isSelected = false,
  isDisabled = false,
  width,
  height,
  onClick,
  onDisableClick,
  setIsValidImage,
}: SelectableImageButtonProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    if (!isDisabled) {
      onClick?.()
    }
    if (isDisabled) {
      onDisableClick?.()
    }
  }

  return (
    <button
      aria-disabled={isDisabled}
      onClick={handleClick}
      style={{ height, width }}
      className={cx('flex focus:outline-none', {
        'opacity-50': isDisabled,
        'cursor-not-allowed': isDisabled && !!onClick,
        'cursor-default': !onClick,
      })}
    >
      <div
        className={cx('relative w-full h-full', {
          'p-2': isSelected && !isDisabled,
        })}
      >
        {isSelected && !isDisabled && (
          <div className="absolute w-full h-full bg-yellow-200 bg-opacity-60 left-0 top-0 rounded-sm" />
        )}
        <CropImage
          width={width}
          height={height}
          src={url}
          onError={() => setIsValidImage?.(false)}
        />
      </div>
    </button>
  )
}

export default SelectableImageButton
