import { FC, useState } from 'react'
import CancelModal from 'workflow/CampaignSummary/CancelModal/CancelModal'
import { cancelSends, checkBeforeCancel } from 'workflow/Workflow.actions'

import { DetailsPanelButton as Button } from 'common/components/detailsPanel'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Stop as StopIcon } from 'common/icons'
import { getESPWithoutCancelBtn } from 'features/campaigns/integration-options/helpers'

import { isAcoustic, isSFMC } from './helpers'

export type Cancel = 'edit_and_resubmit' | 'cancel_final'

interface Props {
  isExpanded: boolean
}

const CancelButton: FC<Props> = ({ isExpanded }) => {
  const dispatch = useAppDispatch()

  const campaignId = useAppSelector(
    (state) => state.campaigns.selectedCampaignId
  )
  const campaignProgress = useAppSelector(
    (state) => state.campaignStates.campaignData?.campaign_progress ?? {}
  )
  const integrationType = useAppSelector(
    (state) => state.campaignStates.typeOfIntegration
  )

  const projectId = useAppSelector((state) => state.campaignStates.projectId)
  const stack = useAppSelector(
    (state) => state.campaignStates.campaignData?.stack
  )

  const {
    test_scheduled,
    test_results_received,
    test_cancelled,
    final_send_cancelled,
    final_send_schedule_started,
    test_schedule_started,
  } = campaignProgress

  // The cancel button is there just for few integration (Sfmc, Sailthru, Accoustic)
  // We then don't want to display it if the campaign is scheduled but the integration is not of those.
  const isScheduledAndIsNotAnIntegration = (integrationType: string) => {
    return test_scheduled && !getESPWithoutCancelBtn().includes(integrationType)
  }

  const isDisplayed =
    (!final_send_cancelled || !test_cancelled) &&
    !test_results_received &&
    isScheduledAndIsNotAnIntegration(integrationType) &&
    !final_send_schedule_started &&
    !isAcoustic(integrationType) &&
    !isSFMC(integrationType)

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const onCancel = (shouldCheck: boolean, cancelType?: Cancel) => {
    if (campaignId) {
      if (shouldCheck && isAcoustic(integrationType)) {
        return dispatch(checkBeforeCancel(campaignId)).then((response: any) => {
          setIsModalOpen(response !== null && response !== undefined)
        })
      } else {
        return dispatch(
          cancelSends(campaignId, projectId, integrationType, stack, cancelType)
        )
      }
    }
  }

  return isDisplayed ? (
    <>
      <Button
        isExpanded={isExpanded}
        disabled={test_schedule_started && !test_scheduled}
        data-testid="cancelButton"
        title="Cancel"
        onClick={() => onCancel(true)}
        icon={<StopIcon />}
      />

      <CancelModal
        shouldOpenCancelModal={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}
        handleCancelSends={(cancelType: Cancel) => onCancel(false, cancelType)}
        finalSendCancelled={final_send_cancelled}
      />
    </>
  ) : null
}

export default CancelButton
