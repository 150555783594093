import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'
import { ignoreNewLineEmoji } from 'common/variantsUtils'

import { contentKeys } from '../queryKeys'
import { ContentLibraryEntryResponse, fetchRecentContents } from '..'

const mapContentToCard = (
  content: ContentLibraryEntryResponse
): {
  id: string
  name: string
  elements: { icon: string | undefined }[]
} => ({
  id: content.id,
  name: ignoreNewLineEmoji(content.name),
  elements: content.elements,
})

const useGetRecentContentsQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getContentsQuery = useQuery(
    contentKeys.recentContents(accountId),
    () => fetchRecentContents(accountId),
    {
      select: (data) => data.map(mapContentToCard),
    }
  )

  return getContentsQuery
}

export default useGetRecentContentsQuery
