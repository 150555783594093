import { ReactNode } from 'react'
import cx from 'classnames'

type Props = {
  children?: ReactNode
  className?: string
}

const Page = ({ children, className }: Props) => {
  return (
    <div className={cx(className, 'flex h-full bg-gold-40 overflow-y-auto')}>
      {children}
    </div>
  )
}

export default Page
