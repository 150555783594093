import { useMemo, useState } from 'react'

import Button from 'common/components/button'
import ConfirmationModal from 'common/components/confirmationModal'
import { Column } from 'common/components/table'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import Tags from 'common/components/tags'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'

import {
  addIncompatibleWord,
  addIncompatibleWordsRow,
  removeIncompatibleWord,
  removeIncompatibleWordsRow,
} from '../store/regionsSlice'

type Row = {
  id: number
  word: string[]
}

const IncompatibleWordsWidget = () => {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [rowToDeleteIndex, setRowToDeleteIndex] = useState(-1)
  const [resetFilterKey, setResetFilterKey] = useState(0)

  const dispatch = useAppDispatch()
  const regionEdited = useAppSelector((state) => state.regions).regionEdited

  const handleDeleteClick = (rowToDeleteIndex) => {
    setRowToDeleteIndex(rowToDeleteIndex)
    setIsDeleteModalShown(true)
  }

  const handleDeleteCancel = () => {
    setRowToDeleteIndex(-1)
    setIsDeleteModalShown(false)
  }

  const handleDeleteConfirm = () => {
    setIsDeleteModalShown(false)
    dispatch(removeIncompatibleWordsRow(rowToDeleteIndex))
    setRowToDeleteIndex(-1)
  }

  const columns: Column<Row>[] = useMemo(
    () => [
      {
        accessor: 'word',
        disableSortBy: true,
        Cell: ({ value, row: { index } }) => {
          return (
            <div className="ml-6 my-4 flex items-center justify-space-between border-x-2">
              <Tags
                data-cy="incompatible-words"
                data-testid="incompatible-words"
                isFreeText
                onAddClick={(value: string) => {
                  dispatch(addIncompatibleWord({ value, index }))
                }}
                onRemoveClick={(value: string) => {
                  dispatch(removeIncompatibleWord({ value, index }))
                }}
                tags={value.map((value) => ({ value, label: value }))}
                maxLength={50}
                onInputChange={(value) => value.replaceAll(' ', '')}
              />
            </div>
          )
        },
        width: 500,
      },
      {
        ...generateDeleteButtonColumn<Row>({
          onClick: (row) => handleDeleteClick(row.id),
          buttonLabel: 'Delete',
        }),
        width: 50,
      },
    ],
    [dispatch]
  )
  const incompatibleWords: Row[] =
    regionEdited.incompatibleWords.map((word, index) => ({
      word: word,
      id: index,
    })) || []

  return (
    <>
      <TableWidget.Widget
        columns={columns}
        data={incompatibleWords}
        isHeaderHidden={true}
        data-cy="incompatible-words-widget"
        data-testid="incompatible-words-widget"
      >
        <TableWidget.Header
          title="Incompatible words"
          subtitle="To prevent conceptual repetition, entries within the same row will be blocked from generating within a single variant."
        >
          <Button
            data-cy="add-incompatible-word-button"
            data-testid="add-incompatible-word-button"
            className="mr-4"
            variant="primary"
            prefixIcon={<AddIcon width={24} height={24} />}
            onClick={() => {
              dispatch(addIncompatibleWordsRow())
              setResetFilterKey((prev) => prev + 1)
            }}
          >
            Incompatible word
          </Button>
          <TableWidget.Filter key={resetFilterKey} />
        </TableWidget.Header>
      </TableWidget.Widget>
      <ConfirmationModal
        open={isDeleteModalShown}
        title="Remove incompatible words row!"
        data-cy="incompatible-words-row-delete-modal"
        data-testid="incompatible-words-row-delete-modal"
        confirmationText="Are you sure you want to remove this row?"
        onCancel={() => handleDeleteCancel()}
        onConfirm={() => handleDeleteConfirm()}
      />
    </>
  )
}

export default IncompatibleWordsWidget
