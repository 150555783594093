import { IntegrationAccount } from '@phrasee/phrasee-typings/typings/integrations/accounts'

import IntegrationsSelect from './integrationsSelect'

type Props = {
  accounts: IntegrationAccount[]
  contentId: string
  elementId: number
}

const MultipleIntegrations = ({ accounts, contentId, elementId }: Props) => {
  return (
    <>
      <span className="text-base-700 text-base pb-6 font-normal">
        Select an integration and share your message with your CEP or ESP.
      </span>
      <div className="flex flex-col justify-center items-center mt-4 p-6">
        <div className="text-base-700 text-sm">
          Connect an integration and start sharing your message.
        </div>
        <IntegrationsSelect
          className="mt-4"
          contentId={contentId}
          elementId={elementId}
          accounts={accounts}
          variant="primary"
        >
          Select an integration
        </IntegrationsSelect>
      </div>
    </>
  )
}

export default MultipleIntegrations
