import { Dispatch, SetStateAction, useRef, useState } from 'react'
import Select, {
  ActionMeta,
  components,
  GroupBase,
  MultiValueRemoveProps,
} from 'react-select'
import { isEqual } from 'lodash'

import { SelectValue } from 'common/components/autocomplete/Autocomplete'
import { SelectRef } from 'common/components/BaseSelect'
import Button from 'common/components/button'
import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import { OptionComponentDefault } from 'common/components/MultiSelect/MultiSelect'
import { CloseSmall } from 'common/icons'
import { Add as AddIcon } from 'common/icons'

import styles from './Autocomplete.styles'

type Props = {
  options: SelectValue[]
  'data-cy'?: string
  'data-testid'?: string
  isInsideTable?: boolean
  menuPortalTarget?: HTMLElement
  values: SelectValue[]
  setValues: Dispatch<SetStateAction<SelectValue[]>>
  searchPlaceholder?: string
  tagButtonLabel?: string
}
const MenuList = (props) => {
  const { children } = props
  const { values, value: localValues, setValues } = props.selectProps

  const hasChangedValues = !isEqual(values, localValues)

  const handleAddClick = () => {
    props.selectProps.onBlur()
    props.selectProps.onMenuClose()
    setValues(localValues)
    props.selectProps.onChange(localValues)
  }

  const handleClearClick = () => {
    props.selectProps.onMenuClose()
    props.selectProps.onBlur()
    props.clearValue()
    setValues([])
  }

  return (
    <div>
      <div className="max-h-109 overflow-y-auto">{children}</div>
      <div className="sticky bottom-0 bg-white flex justify-between px-6 py-2 border-t border-gray-300">
        <Button onClick={handleClearClick}>Clear</Button>
        <Button
          variant="primary"
          onClick={handleAddClick}
          disabled={!hasChangedValues}
        >
          Add
        </Button>
      </div>
    </div>
  )
}

const ApproversAutocomplete = ({
  options,
  values,
  setValues,
  searchPlaceholder,
  tagButtonLabel,
}: Props) => {
  const [inputValue, setInputValue] = useState('')
  const ref =
    useRef<SelectRef<SelectValue, true, GroupBase<SelectValue>> | null>(null)
  const [isAdding, setIsAdding] = useState(false)

  const MultiValueRemove: React.FC<MultiValueRemoveProps<SelectValue>> = (
    props
  ) => {
    const handleMouseDown = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      event.stopPropagation()

      const removedValue = props.data
      const actionMeta: ActionMeta<SelectValue> = {
        action: 'deselect-option',
        option: props.selectProps.value as SelectValue,
      }
      const filteredValues = (props.selectProps.value as SelectValue[]).filter(
        (value) => value.value !== removedValue.value
      )
      props.selectProps.onChange(filteredValues, actionMeta)
      setValues(filteredValues)
    }

    return (
      <components.MultiValueRemove
        {...props}
        innerProps={{ onMouseDown: handleMouseDown }}
      >
        <CloseSmall
          isDefaultColor={false}
          className="text-maroon-200 hover:text-maroon-500"
        />
      </components.MultiValueRemove>
    )
  }

  const handleBlur = () => {
    setIsAdding(false)
    const { values, value: localValues } = ref.current?.props as any
    const hasChangedValues = !isEqual(values, localValues)
    if (hasChangedValues) {
      const actionMeta: ActionMeta<SelectValue> = {
        action: 'deselect-option',
        option: localValues as SelectValue,
      }
      ref.current?.props.onChange(values, actionMeta)
      ref.current?.blur()
    }
  }

  return (
    <div className="flex flex-row items-center">
      {(values.length > 0 || isAdding) && (
        <Select<SelectValue, true>
          ref={ref}
          styles={styles}
          //@ts-ignore
          values={values}
          setValues={setValues}
          components={{
            Option: OptionComponentDefault,
            MenuList,
            MultiValueRemove,
          }}
          onBlur={handleBlur}
          onInputChange={(newValue, actionMeta) => {
            if (actionMeta.action === 'input-change') {
              setInputValue(newValue)
            } else if (actionMeta.action === 'menu-close') {
              setInputValue('')
            }
          }}
          onFocus={() => setIsAdding(true)}
          isMulti
          backspaceRemovesValue
          inputValue={inputValue}
          hideSelectedOptions={false}
          blurInputOnSelect={false}
          noOptionsMessage={() => null}
          options={options}
          isSearchable={true}
          closeMenuOnSelect={false}
          openMenuOnFocus={true}
          autoFocus={isAdding}
          controlShouldRenderValue={!isAdding}
          placeholder={searchPlaceholder}
        />
      )}
      {!isAdding && (
        <ButtonWithTooltip
          size="small"
          aria-label="Add tag"
          data-cy="add-tag-button"
          data-testid="add-tag-button"
          ghost
          variant="icon"
          tooltip={values.length === options.length}
          tooltipText={tagButtonLabel ?? ''}
          disabled={values.length === options.length}
          className="hover:bg-coolGray-200 h-7 px-1 outline-none focus:outline-none"
          onClick={() => {
            ref.current?.focus()
            setIsAdding(true)
          }}
          prefixIcon={
            <div className="flex items-center">
              <AddIcon
                isDefaultColor={false}
                className="text-coolGray-400 w-4 h-4"
              />
              <div className="text-xs text-coolGray-400 ml-1.5">Approver</div>
            </div>
          }
        />
      )}
    </div>
  )
}

export default ApproversAutocomplete
