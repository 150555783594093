import React, { CSSProperties, FunctionComponent, SVGProps } from 'react'
import cx from 'classnames'

type ImportedSvg = FunctionComponent<
  SVGProps<SVGSVGElement> & { title?: string | undefined }
>

type State = 'default' | 'selected'
type Size = number

interface IconPropsBase {
  state?: State
  size?: number
  className?: string
  isDefaultColor?: boolean
  role?: React.AriaRole
  'data-cy'?: string
  'data-testid'?: string
}

interface IconProps extends IconPropsBase {
  defaultSvg: ImportedSvg
  selectedSvg?: ImportedSvg
}

function Icon({
  state = 'default',
  defaultSvg: DefaultSvg,
  selectedSvg: SelectedSvg,
  size = 6,
  className,
  isDefaultColor = true,
  role,
  'data-cy': dataCy,
  'data-testid': dataTestId,
}: IconProps) {
  const defaultColorClasses = cx(
    'text-base-700 hover:text-maroon-400 group-hover:text-maroon-400',
    { 'text-maroon-400': state === 'selected' }
  )
  const fullClassName = cx(
    `inline-block `,
    { [`${defaultColorClasses}`]: isDefaultColor },
    className
  )

  const style: CSSProperties = {
    width: size * 4,
    height: size * 4,
  }

  if (SelectedSvg && state === 'selected') {
    return (
      <SelectedSvg
        className={fullClassName}
        style={style}
        role={role}
        {...(dataCy ? { 'data-cy': dataCy } : {})} // prevent overriding data-cy if not provided
        {...(dataTestId ? { 'data-testid': dataTestId } : {})}
      />
    )
  } else {
    return (
      <DefaultSvg
        className={fullClassName}
        style={style}
        role={role}
        {...(dataCy ? { 'data-cy': dataCy } : {})} // prevent overriding data-cy if not provided
        {...(dataTestId ? { 'data-testid': dataTestId } : {})}
      />
    )
  }
}

export default Icon

export type { IconPropsBase, Size, State }

export type IconType = React.ComponentType<IconPropsBase>
