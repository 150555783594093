import cx from 'classnames'
import { showBanner } from 'workflow/common/common.actions'

import Badge from 'common/components/Badge'
import Button from 'common/components/button'
import { copyAndReplaceNewLineEmoji } from 'common/helpers/copyAndReplaceNewLineEmoji'
import { containsArabic } from 'common/helpers/string'
import { Copy } from 'common/icons'
import { replaceNewLineEmojiAsElement } from 'common/variantsUtils'

type ResultsTableVariantCellProps = {
  value?: string
  isControlVariant: boolean
}

const ResultsTableVariantCell = ({
  value = '',
  isControlVariant,
}: ResultsTableVariantCellProps) => {
  const isArabic = containsArabic(value)

  const handleCopy = (text: string) => {
    copyAndReplaceNewLineEmoji(text)

    showBanner({
      type: 'success',
      content: 'Variant copied successfully',
    })
  }

  return (
    <div
      className={cx('flex justify-between items-center w-full', {
        'text-right': isArabic,
      })}
      dir="auto"
    >
      <span>
        <span
          className={cx({
            'mr-2': isControlVariant && !isArabic,
            'ml-2': isControlVariant && isArabic,
          })}
        >
          {replaceNewLineEmojiAsElement(value)}
        </span>
        {isControlVariant && <Badge text="Control" variant="warning" />}
      </span>
      <Button
        data-cy="copy-variant"
        data-testid="copy-variant"
        title="Copy variant to clipboard"
        className="p-0"
        onClick={() => {
          handleCopy(value)
        }}
        prefixIcon={
          <Copy size={4} className="text-gold-500 hover:text-gold-500" />
        }
      />
    </div>
  )
}

export default ResultsTableVariantCell
