import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { contentKeys } from '../queryKeys'
import { fetchOptimizationMetrics } from '..'

type ContentLibraryElement = {
  id: string
  name: string
  project: string
  template: string
  campaign_id: string
  experimentType: string
  owner: string
  createdDate: string
  sendDate: string
  status: string
}

export type ContentLibraryEntry = {
  id: string
  name: string
  status: string
  createdDate: string
  isLegacy: boolean
  subRows: ContentLibraryElement[]
}

const useGetOptimizationMetricsQuery = ({
  isEnabled,
  dashboardMode,
  distributionType,
}: {
  isEnabled: boolean
  dashboardMode: 'Incremental' | 'Average_uplift'
  distributionType: 'broadcast' | 'AlwaysOn'
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const getContentLibraryQuery = useQuery(
    contentKeys.optimizationMetrics({
      accountId,
      dashboardMode,
      distributionType,
    }),
    () =>
      fetchOptimizationMetrics({ accountId, dashboardMode, distributionType }),
    {
      select: (data) => {
        return data
      },
      refetchOnWindowFocus: false,
      enabled: !!accountId && isEnabled,
    }
  )
  return getContentLibraryQuery
}

export default useGetOptimizationMetricsQuery
