import { AxiosResponse } from 'axios'

import { connectInternalApi } from 'common/api'
import { TokenResponse } from 'common/api/tokensApi'

export interface Token {
  id: string
  name: string
  token: string
  expires: string
}

export const generateToken = async ({
  name,
  accountId,
}: {
  name: string
  accountId: string
}): Promise<AxiosResponse<TokenResponse>> => {
  return connectInternalApi.post<TokenResponse>(
    `v1/core/main/accounts/${accountId}/tokens`,
    {
      name,
    }
  )
}

export const deleteToken = async ({
  id,
  accountId,
}: {
  id: string
  accountId: string
}) => {
  return connectInternalApi.delete(
    `v1/core/main/accounts/${accountId}/tokens/${id}`
  )
}
