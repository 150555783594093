import { Field, Form } from 'react-final-form'
import { PersonalizedVariant } from '@phrasee/phrasee-typings'

import Button from 'common/components/button'
import FormItem from 'common/components/formItem/FormItem'
import Modal from 'common/components/Modal'
import RadioGroup from 'common/components/radioGroup'
import Textarea from 'common/components/textarea/Textarea'

type Props = {
  isOpen: boolean
  onCancel: () => void
  onEditVariant: (values: {
    variantText: string
    variantId: string
    reason?: string
    changeType: 'tweak' | 'replace'
  }) => void
  isLoading: boolean
  tweakingVariant: PersonalizedVariant | undefined
}

const validateNotEmptyString = (value: string | undefined) => {
  return !value || !value.trim() ? 'Edited line cannot be empty' : undefined
}

const validateIsVariantEdited = ({
  editedText,
  initialText,
}: {
  editedText: string | undefined
  initialText: string | undefined
}) => {
  return initialText === editedText
    ? 'Edited line cannot be the same as the original'
    : undefined
}

const TweakVariantModal = ({
  isOpen,
  onCancel,
  onEditVariant,
  isLoading,
  tweakingVariant,
}: Props) => {
  const handleEditVariant = (values: {
    changeType: 'tweak' | 'replace'
    variantText: string
    reason?: string
  }) => {
    onEditVariant({ ...values, variantId: tweakingVariant?.variant_id ?? '' })
  }

  return (
    <Modal visible={isOpen} centered closable={false} destroyOnClose>
      <div className="flex flex-col gap-2 w-163 mb-10">
        <div className="text-base-700 text-base mb-2">Request a change to:</div>
        <div className="px-6 py-3 text-base-700 font-normal bg-gold-100 text-base">
          {tweakingVariant?.text}
        </div>
      </div>
      <Form
        onSubmit={handleEditVariant}
        initialValues={{
          changeType: 'tweak',
          variantText: tweakingVariant?.text,
        }}
        render={({ handleSubmit, hasValidationErrors, dirty }) => {
          return (
            <form onSubmit={handleSubmit}>
              <p className="text-base-700 text-base font-normal mb-4">
                What would you like to do?
              </p>
              <Field<string>
                name="changeType"
                render={({ input }) => (
                  <FormItem htmlFor="changeType">
                    <RadioGroup
                      className="font-normal text-base mb-6"
                      name={input.name}
                      onChange={input.onChange}
                      value={input.value}
                      options={[
                        {
                          value: 'tweak',
                          label: 'Edit',
                        },
                      ]}
                    />
                  </FormItem>
                )}
              />
              <Field<string | undefined>
                name="variantText"
                validate={(value) =>
                  validateIsVariantEdited({
                    editedText: value,
                    initialText: tweakingVariant?.text,
                  }) || validateNotEmptyString(value)
                }
                render={({ input, meta }) => (
                  <FormItem
                    label="Make your edits here:"
                    className="mb-6"
                    htmlFor="reason"
                    error={meta.touched ? meta.error : undefined}
                  >
                    <Textarea
                      className="h-25"
                      value={input.value}
                      onChange={input.onChange}
                      onBlur={input.onBlur}
                      showCharacterCount
                      maxLength={250}
                    />
                  </FormItem>
                )}
              />
              <Field<string | undefined>
                name="reason"
                render={({ input }) => (
                  <FormItem
                    label="Please tell us why (optional)"
                    htmlFor="reason"
                  >
                    <Textarea
                      className="h-17"
                      value={input.value}
                      onChange={input.onChange}
                      placeholder="Explain as best as possible so we can learn and give better results in the future."
                      showCharacterCount
                      maxLength={250}
                    />
                  </FormItem>
                )}
              />
              <div className="flex justify-end gap-8 mt-10">
                <Button variant="link" onClick={() => onCancel()}>
                  Cancel
                </Button>
                <Button
                  id="language-tool-variant-edit-button"
                  type="submit"
                  variant="primary"
                  disabled={hasValidationErrors || isLoading || !dirty}
                  loading={isLoading}
                >
                  Edit
                </Button>
              </div>
            </form>
          )
        }}
      />
    </Modal>
  )
}

export default TweakVariantModal
