import { useState } from 'react'

import { useDocumentTitle } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'
import MainContent from 'features/unifiedFlow/components/MainContent'
import { selectRightPanelVisibility } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { isComponentElement } from '../../api/interfaces'
import ContentLoadingOverlay from '../components/ContentLoadingOverlay'
import { useSelectedElement } from '../hooks'
import useWebsocketFullScreenLoader from '../hooks/useWebsocketFullScreenLoader'

import { VariantInsightsProvider } from './context/VariantInsightsContext'
import useElementState from './hooks/useElementState'
import useRightPanelVisibilityState from './hooks/useRightPanelVisibilityState'
import RightSectionContent from './rightSection/RightSectionContent'
import CenterSection from './centerSection'
import LeftSection from './leftSection'

const Generate = () => {
  useDocumentTitle('Generate | Jacquard')
  useElementState()
  useRightPanelVisibilityState()

  const { isWebsocketLoading, websocketAction } = useWebsocketFullScreenLoader()
  const [isAdvancedSettingsShown, setIsAdvancedSettingsShown] = useState(false)
  const { data: selectedElement } = useSelectedElement()

  const rightPanelVisibility = useAppSelector(selectRightPanelVisibility)

  const handleClickAdvancedSettings = () => {
    if (rightPanelVisibility === 'none' || rightPanelVisibility === 'hidden') {
      setIsAdvancedSettingsShown(true)
    } else {
      setIsAdvancedSettingsShown(!isAdvancedSettingsShown)
    }
  }

  const shouldShowExperimentSettings =
    selectedElement &&
    isComponentElement(selectedElement) &&
    selectedElement.campaign_id

  return (
    <VariantInsightsProvider
      onSetVariantState={() => setIsAdvancedSettingsShown(false)}
    >
      <MainContent className="flex-wrap" id="main-content-page">
        <LeftSection />
        <div className="relative flex flex-1">
          <ContentLoadingOverlay
            isLoading={isWebsocketLoading}
            action={websocketAction}
          />
          <CenterSection
            isWebsocketLoading={isWebsocketLoading}
            isAdvancedSettingsOptionsShown={!!shouldShowExperimentSettings}
            onClickAdvancedSettings={handleClickAdvancedSettings}
          />
          <RightSectionContent
            isAdvancedSettingsShown={isAdvancedSettingsShown}
          />
        </div>
      </MainContent>
    </VariantInsightsProvider>
  )
}

export default Generate
