import Imgix from 'react-imgix'

type Props = Pick<typeof Imgix, 'width' | 'height' | 'src' | 'onError'>

const CropImage = ({ width, height, src, onError }: Props) => {
  return (
    <Imgix
      width={width}
      height={height}
      src={src}
      htmlAttributes={{
        onError,
      }}
      imgixParams={{
        mask: 'corners',
        fit: 'crop',
        'corner-radius': 2,
      }}
    />
  )
}

export default CropImage
