import cx from 'classnames'
import cond from 'lodash/cond'
import stubTrue from 'lodash/stubTrue'

import BaseCell from 'common/components/table/cells/Base'
import Tooltip from 'common/components/Tooltip'

import { RESULT_TABLE_KEYS } from '../../CampaignSummary/ResultsTable/constants'
import { variantStatusSelectOptions } from '../../CampaignSummary/ResultsTable/constants'
import { getRoundedNumber } from '../../CampaignSummary/ResultsTable/helpers'
import {
  ResultsTableProps,
  Variant,
  VariantStatusUpdate,
} from '../../CampaignSummary/ResultsTable/interfaces'

import ResultsTableVariantCell from './components/ResultsTableVariantCell'
import StatusSelect from './components/StatusSelect'

type Props = {
  accessor: string
  testTrackedMetrics?: ResultsTableProps['testTrackedMetrics']
  resultType: ResultsTableProps['resultType']
  isBanditManualConversionsCampaign?: ResultsTableProps['isBanditManualConversionsCampaign']
  data: Variant
  isLoading: boolean
  isStatusDropdownDisabled?: ResultsTableProps['isStatusDropdownDisabled']
  setVariantToUpdate: (value: VariantStatusUpdate | undefined) => void
  isMatureTabAndAllZeroRecipients: boolean
  showMachineOpens?: ResultsTableProps['showMachineOpens']
}

const ResultsTableCell = ({
  accessor,
  testTrackedMetrics,
  resultType,
  data,
  isBanditManualConversionsCampaign,
  isStatusDropdownDisabled,
  isLoading,
  setVariantToUpdate,
  isMatureTabAndAllZeroRecipients,
  showMachineOpens,
}: Props) => {
  const isHumanUpliftCell =
    (accessor === RESULT_TABLE_KEYS.OPEN_UPLIFT ||
      accessor === RESULT_TABLE_KEYS.CLICK_UPLIFT) &&
    data[RESULT_TABLE_KEYS.OWNSL]

  const isPhraseeScoreCell =
    accessor === RESULT_TABLE_KEYS.JACQUARD_SCORE ||
    accessor === RESULT_TABLE_KEYS.JACQUARD_SCORE_RATE

  const isDroppedVariant = data[RESULT_TABLE_KEYS.VARIANT_STATUS] === 'dropped'

  const isTrackedMetricOrUpliftCell =
    testTrackedMetrics?.some((metricObj) => metricObj?.name === accessor) ||
    accessor === RESULT_TABLE_KEYS.OPEN_UPLIFT ||
    accessor === RESULT_TABLE_KEYS.CLICK_UPLIFT

  const isBanditManualConversionsCell =
    accessor === RESULT_TABLE_KEYS.CONVERSIONS &&
    data[accessor] !== '' &&
    isBanditManualConversionsCampaign &&
    resultType !== 'test'

  const currentValue = data[accessor]

  const getCellContent = () =>
    cond([
      [
        () => accessor === RESULT_TABLE_KEYS.VARIANT_STATUS,
        () => (
          <StatusSelect
            isDisabled={
              isLoading ||
              isStatusDropdownDisabled ||
              data[RESULT_TABLE_KEYS.OWNSL]
            }
            value={currentValue}
            onChange={(value) => {
              if (value === currentValue) {
                return
              }
              return setVariantToUpdate({
                id: data._id,
                variantStatus: value,
              })
            }}
            options={variantStatusSelectOptions[currentValue]}
          />
        ),
      ],
      [
        () =>
          (currentValue === '' ||
            (isMatureTabAndAllZeroRecipients && isTrackedMetricOrUpliftCell)) &&
          !isBanditManualConversionsCell &&
          !isHumanUpliftCell &&
          !(isPhraseeScoreCell && isDroppedVariant),
        () => (
          <Tooltip overlay="Data not available yet">
            <span className="text-left select-none font-bold text-gold-700">
              -
            </span>
          </Tooltip>
        ),
      ],
      [
        () => accessor === RESULT_TABLE_KEYS.IS_SIGNIFICANT,
        () => {
          if (currentValue === 1) {
            return <span>Yes</span>
          }
          if (currentValue === 0) {
            return <span>No</span>
          }

          return null
        },
      ],
      [
        () =>
          accessor === RESULT_TABLE_KEYS.NUM_OPENS &&
          !showMachineOpens &&
          data[RESULT_TABLE_KEYS.NUM_MACHINE_OPENS] !== undefined,
        () =>
          getRoundedNumber(
            currentValue - data[RESULT_TABLE_KEYS.NUM_MACHINE_OPENS],
            2
          ),
      ],
      [
        () =>
          accessor === RESULT_TABLE_KEYS.OPEN_RATE &&
          !showMachineOpens &&
          data[RESULT_TABLE_KEYS.OPEN_RATE_EXCLUDED_MACHINE_OPENS],
        () => data[RESULT_TABLE_KEYS.OPEN_RATE_EXCLUDED_MACHINE_OPENS],
      ],
      [
        () =>
          accessor === RESULT_TABLE_KEYS.OPEN_UPLIFT &&
          !showMachineOpens &&
          data[RESULT_TABLE_KEYS.OPEN_RATE_UPLIFT_MACHINE_OPENS_EXCLUDED],
        () => data[RESULT_TABLE_KEYS.OPEN_RATE_UPLIFT_MACHINE_OPENS_EXCLUDED],
      ],
      [
        () => accessor === RESULT_TABLE_KEYS.VARIANT,
        () => (
          <ResultsTableVariantCell
            value={data[RESULT_TABLE_KEYS.VARIANT]}
            isControlVariant={data[RESULT_TABLE_KEYS.OWNSL]}
          />
        ),
      ],
      [stubTrue, () => getRoundedNumber(currentValue, 2)],
    ])()

  const content = getCellContent()

  return (
    <BaseCell
      className={cx('w-full text-gold-700 font-normal', {
        'border-r-1 border-gold-200': accessor === RESULT_TABLE_KEYS.VARIANT,
        'pl-0 pr-0': accessor === RESULT_TABLE_KEYS.VARIANT_STATUS,
      })}
    >
      {content}
    </BaseCell>
  )
}

export default ResultsTableCell
