import { Template } from '@phrasee/phrasee-typings'
import { useQuery } from '@tanstack/react-query'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { useAppSelector } from 'common/hooks/redux'
import { TemplateInfo } from 'features/unifiedFlow/contentsPage/interfaces'

import { getTemplates } from '../api'
import { contentKeys } from '../queryKeys'

interface FeatureFlags {
  isPersonalizationEnabled: boolean
  isImageOptimizationEnabled: boolean
}

interface MapTemplateToCardArgs {
  template: Template
  featureFlags: FeatureFlags
}

const getOptimizationConfig = (
  template: Template,
  isImageOptimizationEnabled: boolean
): TemplateInfo['optimization'] => {
  if (!template.experiment_type?.optimization) {
    return undefined
  }

  return {
    status: template.experiment_type.optimization,
    images: isImageOptimizationEnabled
      ? template.experiment_type.imageOptimization
      : 'disabled',
    contentType: template.optimization_content_type,
  }
}

const getPersonalizationConfig = (
  template: Template,
  isPersonalizationEnabled: boolean
): TemplateInfo['personalization'] => {
  if (!template.experiment_type?.personalization) {
    return undefined
  }

  const status =
    isPersonalizationEnabled &&
    template.experiment_type.personalization !== 'disabled'
      ? 'enabled'
      : template.experiment_type.personalization

  return {
    status,
    contentType: template.personalization_content_type,
  }
}

const mapTemplateToCard = ({
  template,
  featureFlags: { isPersonalizationEnabled, isImageOptimizationEnabled },
}: MapTemplateToCardArgs): TemplateInfo => {
  const optimization = getOptimizationConfig(
    template,
    isImageOptimizationEnabled
  )

  const personalization = getPersonalizationConfig(
    template,
    isPersonalizationEnabled
  )

  return {
    id: template._id,
    name: template.name,
    displayName: template.display_name,
    contentType: template.content_type,
    icon: template.icon || '',
    description: template.description || '',
    disabled: template.template_status === 'preview',
    ...(optimization && { optimization }),
    ...(personalization && { personalization }),
  }
}

const useGetTemplatesQuery = () => {
  const flags = useFlags()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const featureFlags: FeatureFlags = {
    isPersonalizationEnabled: flags.switchOnPersonalizedCampaignCreation,
    isImageOptimizationEnabled: flags.showImageExperimentSetup,
  }

  return useQuery(
    contentKeys.templates(accountId),
    () => getTemplates(accountId),
    {
      staleTime: 10 * 60 * 1000,
      select: (data) => {
        return data
          .filter((template) => template.template_status !== 'inactive')
          .map((template) => mapTemplateToCard({ template, featureFlags }))
      },
    }
  )
}

export default useGetTemplatesQuery
