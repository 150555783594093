import cx from 'classnames'
import { Item as MenuItem } from 'rc-menu'

import Image from 'common/components/image'

import { Options } from './interfaces'

type Props = Pick<Options, 'label' | 'logoUrl'> & {
  isDisabled?: boolean
  alt: string
}

const IntegrationItem = ({
  logoUrl,
  alt,
  label,
  isDisabled = false,
  ...restProps
}: Props) => {
  return (
    <MenuItem
      className={cx(
        'group flex items-center text-base-700 text-sm font-normal py-2 px-4 -mx-4',
        {
          'cursor-not-allowed pointer-events-auto opacity-50': isDisabled,
          'cursor-pointer hover:bg-gold-300': !isDisabled,
        }
      )}
      disabled={isDisabled}
      {...restProps}
    >
      <div
        className={cx(
          'p-1 flex justify-center items-center h-11 w-11 border-gold-400 border mr-2 ',
          {
            'group-hover:border-gold-500': !isDisabled,
          }
        )}
      >
        <Image src={logoUrl} alt={alt} isResponsive />
      </div>
      <span className="max-w-35 truncate">{label}</span>
    </MenuItem>
  )
}

export default IntegrationItem
