import { useMemo, useState } from 'react'
import cx from 'classnames'
import clone from 'lodash/clone'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'
import unset from 'lodash/unset'

import { isSameDate } from 'common/helpers/date'
import {
  CustomerAttributes,
  DateAttribute,
  ListAttribute,
  NumberAttribute,
} from 'features/personalization/api'

import CatalogHeader from './CatalogHeader'
import RecursiveSubMenu from './RecursiveSubMenu'
import SearchResults from './SearchResults'

type EntryType = 'date' | 'number'

interface BaseTypedEntry {
  type: EntryType
}

interface DateType extends BaseTypedEntry {
  type: 'date'
  startDate: string
  endDate: string
}

interface NumberType extends BaseTypedEntry {
  type: 'number'
  min: number
  max: number
}

export type TypedEntry = DateType | NumberType

export type TypedEntryState<T extends string | number> = {
  [key: string]: { value: T[] }
}

export interface TreeNode {
  [key: string]: TreeNode | string[] | TypedEntry
}

type Props = {
  items: TreeNode
  title?: string
  selectedItems?: TreeNode
  onApply?: ({
    node,
    mapped,
  }: {
    node: TreeNode
    mapped?: CustomerAttributes
  }) => void
  searchValue?: string
  storedTypedAttributes?: CustomerAttributes
}
type SearchResult = { value: string; path: string[] }

const Catalog = ({
  items,
  onApply,
  selectedItems,
  searchValue,
  title,
  storedTypedAttributes,
}: Props) => {
  const [selectedPath, setSelectedPath] = useState<string[]>([])
  const [draftSelectedItems, setDraftSelectedItems] =
    useState<TreeNode | undefined>(selectedItems)

  const initialDatesPerKey: TypedEntryState<string> = Object.entries(
    items
  ).reduce((acc, [key, value]) => {
    if ('type' in value && value.type === 'date') {
      acc[key] = {
        value: [value.startDate, value.endDate],
      }
    }
    return acc
  }, {})

  const initialSliderPerKey: TypedEntryState<number> = Object.entries(
    items
  ).reduce((acc, [key, value]) => {
    if ('type' in value && value.type === 'number') {
      acc[key] = {
        value: [value.min, value.max],
      }
    }
    return acc
  }, {})

  const initialDraftSliders: TypedEntryState<number> = Object.entries(
    items
  ).reduce((acc, [key, value]) => {
    if ('type' in value && value.type === 'number') {
      const storedEntry = storedTypedAttributes?.find(
        (entry) => entry.name === key
      )

      const min =
        storedEntry && 'min' in storedEntry ? storedEntry.min : value.min
      const max =
        storedEntry && 'max' in storedEntry ? storedEntry.max : value.max

      acc[key] = {
        value: [min, max],
      }
    }
    return acc
  }, {})

  const initialDraftDates: TypedEntryState<string> = Object.entries(
    items
  ).reduce((acc, [key, value]) => {
    if ('type' in value && value.type === 'date') {
      const storedEntry = storedTypedAttributes?.find(
        (entry) => entry.name === key
      )

      const startDate =
        storedEntry && 'startDate' in storedEntry
          ? storedEntry.startDate
          : value.startDate
      const endDate =
        storedEntry && 'endDate' in storedEntry
          ? storedEntry.endDate
          : value.endDate

      acc[key] = {
        value: [startDate, endDate],
      }
    }
    return acc
  }, {})

  const [draftDatesPerKey, setDraftDatesPerKey] = useState(initialDraftDates)
  const [draftSliderPerKey, setDraftSliderPerKey] =
    useState(initialDraftSliders)
  const [datesPerKey, setDatesPerKey] = useState(initialDatesPerKey)
  const [sliderPerKey, setSliderPerKey] = useState(initialSliderPerKey)

  const mappedDraftSliders: NumberAttribute[] = Object.entries(
    draftSliderPerKey
  )
    .filter(([name]) => !!draftSelectedItems?.[name])
    .map(([name, { value }]) => ({
      name,
      type: 'number',
      min: value[0],
      max: value[1],
    }))

  const mappedDraftDates: DateAttribute[] = Object.entries(draftDatesPerKey)
    .filter(([name]) => !!draftSelectedItems?.[name])
    .map(([name, { value }]) => ({
      name,
      type: 'date',
      startDate: value[0],
      endDate: value[1],
    }))

  const mappedDraftLists = draftSelectedItems
    ? Object.entries(draftSelectedItems).reduce(
        (acc: ListAttribute[], [name, value]) => {
          if (Array.isArray(value)) {
            acc.push({
              name,
              type: 'list',
              values: value,
            })
          }
          return acc
        },
        []
      )
    : []

  const updatedSelectedItems: CustomerAttributes = [
    ...mappedDraftDates,
    ...mappedDraftSliders,
    ...(mappedDraftLists || []),
  ]

  const isDraftModified = !(
    compareDateState(draftDatesPerKey, initialDraftDates) &&
    isEqual(draftSliderPerKey, initialDraftSliders) &&
    isEqual(draftSelectedItems, selectedItems)
  )
  const isAllSelected =
    isEqual(draftSelectedItems, items) &&
    compareDateState(draftDatesPerKey, initialDatesPerKey) &&
    isEqual(draftSliderPerKey, initialSliderPerKey)

  const handleDateChange = ({
    key,
    value,
  }: {
    key: string
    value: string[]
  }) => {
    setDraftDatesPerKey((prev) => ({
      ...prev,
      [key]: { value },
    }))
  }

  const handleSliderChange = ({
    key,
    value,
  }: {
    key: string
    value: number[]
  }) => {
    setDraftSliderPerKey((prev) => ({
      ...prev,
      [key]: { value },
    }))
  }

  const shouldOverflow = Object.values(items).find(
    (prop) => 'type' in prop && prop.type === 'date'
  )

  const searchResults: SearchResult[] | undefined = useMemo(() => {
    if (!searchValue) {
      return undefined
    }
    const search = (
      tree: TreeNode,
      searchValue: string,
      path: string[]
    ): SearchResult[] => {
      const lowerCaseSearchValue = searchValue.toLowerCase()
      return Object.keys(tree).flatMap((key) => {
        const matches: SearchResult[] = key
          .toLowerCase()
          .includes(lowerCaseSearchValue)
          ? [{ value: key, path: path }]
          : []
        const subTree = tree[key]
        const newPath = path.length === 0 ? [key] : [...path, key]
        const children: SearchResult[] = Array.isArray(subTree)
          ? subTree
              .filter((value) =>
                value.toLowerCase().includes(lowerCaseSearchValue)
              )
              .map((value) => ({
                value,
                path: newPath,
              }))
          : typeof subTree === 'object' && !('type' in subTree)
          ? search(subTree, searchValue, newPath)
          : []
        return matches.concat(children)
      })
    }
    return search(items, searchValue, [])
  }, [searchValue, items])

  return (
    <div
      className={cx('w-full', {
        'bg-blueGray-100': !!selectedItems,
      })}
    >
      {selectedItems && title && draftSelectedItems && (
        <CatalogHeader
          title={title}
          onApply={() => {
            onApply?.({
              node: draftSelectedItems,
              mapped: updatedSelectedItems,
            })
            setDatesPerKey(draftDatesPerKey)
            setSliderPerKey(draftSliderPerKey)
          }}
          isApplyDisabled={!isDraftModified}
          isResetDisabled={!isDraftModified}
          isSelectAllChecked={isAllSelected}
          isSelectAllDisabled={false}
          isSelectAllIndeterminate={
            Object.keys(draftSelectedItems).length > 0 &&
            !isEqual(draftSelectedItems, items)
          }
          onClickSelectAll={() => {
            Object.keys(draftSelectedItems).length === 0
              ? setDraftSelectedItems(items)
              : setDraftSelectedItems({})
            setDraftDatesPerKey(initialDatesPerKey)
            setDraftSliderPerKey(initialSliderPerKey)
            setDatesPerKey(initialDatesPerKey)
            setSliderPerKey(initialSliderPerKey)
          }}
          onClickReset={() => {
            setDraftSelectedItems(selectedItems)
            setDraftDatesPerKey(datesPerKey)
            setDraftSliderPerKey(sliderPerKey)
          }}
        />
      )}
      <div className={cx('w-full', { 'overflow-auto': !shouldOverflow })}>
        <div
          className={cx(
            'bg-blueGray-100 flex h-full box-border border border-coolGray-200',
            {
              'w-max': !searchResults,
              'items-stretch flex-col': searchResults,
            }
          )}
        >
          {searchResults && draftSelectedItems ? (
            <SearchResults
              items={items}
              selectedItems={draftSelectedItems}
              setSelectedItems={setDraftSelectedItems}
              searchResults={searchResults}
            />
          ) : (
            <RecursiveSubMenu
              items={items}
              checkedItems={draftSelectedItems}
              selectedPath={selectedPath}
              isSearching={searchResults !== undefined}
              onDateChange={handleDateChange}
              dateRangePerKey={draftDatesPerKey}
              onSliderChange={handleSliderChange}
              sliderPerKey={draftSliderPerKey}
              onClickCheckbox={
                !selectedItems
                  ? undefined
                  : (itemName: string, depth: number) => {
                      if (!draftSelectedItems) {
                        return
                      }
                      const result = cloneDeep(draftSelectedItems)
                      if (depth === 0) {
                        if (result[itemName]) {
                          delete result[itemName]
                        } else {
                          result[itemName] = items[itemName]
                        }
                      } else {
                        const parentSelectedPath = selectedPath.slice(0, depth)
                        const parentSelectedItem = get(
                          draftSelectedItems,
                          parentSelectedPath
                        )
                        const isChecked =
                          parentSelectedItem === undefined
                            ? false
                            : Array.isArray(parentSelectedItem)
                            ? parentSelectedItem.some(
                                (current) => current === itemName
                              )
                            : parentSelectedItem[itemName] !== undefined

                        if (!isChecked) {
                          const itemClicked =
                            get(items, selectedPath.slice(0, depth)) ?? []
                          if (Array.isArray(itemClicked)) {
                            const pathItems =
                              get(items, selectedPath.slice(0, depth)) ?? []
                            const index = pathItems.findIndex(
                              (item) => item === itemName
                            )
                            const existingItems = [
                              ...get(
                                draftSelectedItems,
                                selectedPath.slice(0, depth),
                                []
                              ),
                            ]

                            index !== -1
                              ? existingItems.splice(index, 0, itemName)
                              : existingItems.push(itemName)

                            set(result, selectedPath, existingItems)
                          } else {
                            set(
                              result,
                              [...parentSelectedPath, itemName],
                              get(items, [...parentSelectedPath, itemName])
                            )
                          }
                        } else {
                          const children: TreeNode = get(
                            draftSelectedItems,
                            parentSelectedPath
                          )
                          let filtered
                          if (Array.isArray(children)) {
                            filtered = children.filter((a) => a !== itemName)
                          } else {
                            const clonedChildren = clone(children)
                            delete clonedChildren[itemName]
                            filtered = clonedChildren
                          }

                          set(result, parentSelectedPath, filtered)

                          // delete empty nodes
                          if (isEmpty(filtered)) {
                            selectedPath.forEach((_, index) => {
                              const path = parentSelectedPath.slice(
                                0,
                                parentSelectedPath.length - index
                              )
                              const parent = get(result, path)

                              if (isEmpty(parent)) {
                                const parentOfParentPath = selectedPath.slice(
                                  0,
                                  selectedPath.length - index
                                )
                                unset(result, parentOfParentPath)
                              }
                            })
                          }
                        }
                      }
                      setDraftSelectedItems(result)
                    }
              }
              onSelect={(item: string, depth: number) => {
                if (depth === 0) {
                  setSelectedPath([item])
                } else {
                  setSelectedPath((prev) => [...prev.slice(0, depth), item])
                }
              }}
              depth={0}
            />
          )}
        </div>
      </div>
    </div>
  )
}

function compareDateState(
  dateState1: TypedEntryState<string>,
  dateState2: TypedEntryState<string>
) {
  return Object.keys(dateState1).every((key) => {
    const draftDates = dateState1[key]?.value.flat()
    const dates = dateState2[key]?.value.flat()

    if (draftDates.length !== dates.length) {
      return false
    }

    return draftDates.every((draftDate, index) =>
      isSameDate(draftDate, dates[index], 'day')
    )
  })
}

export default Catalog
