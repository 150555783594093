import cx from 'classnames'

import { CloseSquare as CloseSquareIcon } from 'common/icons'

import Button from '../button'

export type RightPaneVisibility = 'auto' | 'hidden' | 'visible'

type Props = {
  children: React.ReactNode
  isOpen?: boolean
  className?: string
  width?: string
  title?: string
  onOpenChange?: (isOpen: boolean) => void
}

const DrawerPush = ({
  children,
  className,
  isOpen,
  title,
  onOpenChange,
  width = 'w-90',
}: Props) => {
  return (
    <div
      className={cx(
        `flex flex-col transform transition-all duration-300 ease-in-out bg-gold-100 
          overflow-y-auto overflow-x-hidden shadow-xl lg:shadow-none shadow-offset-left`,
        {
          'translate-x-full w-0': !isOpen,
          [width]: isOpen,
        },
        className
      )}
    >
      <div className="py-6 px-4">
        <div className="flex justify-between items-center">
          <div className="text-gold-700 text-xs">{title}</div>
          <div className="">
            <Button
              variant="icon"
              onClick={() => onOpenChange?.(false)}
              prefixIcon={
                <CloseSquareIcon
                  isDefaultColor={false}
                  className="text-base-700"
                />
              }
            ></Button>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

export default DrawerPush
