import React from 'react'
import moment, { Moment } from 'moment'

import DatePicker from 'common/components/datePicker'
import Input from 'common/components/input'
import { isYesterdayOrBefore } from 'common/helpers/date'

type Props = {
  defaultValue: string
  name: string
  type: string
  required: string
  disabled: boolean
  onChange: Function
  daysToGoDate: any
  stepProps?: Object
  format: string
  value: any
}

type State = {
  daysToGo: number
  daysUpdatedManually: boolean
  hideDaySection: string
  calenderDate: any | null
}
class DaysToGo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      daysToGo: 0,
      daysUpdatedManually: false,
      calenderDate: null,
      hideDaySection: 'none',
    }
    this.onDateChangeAntd = this.onDateChangeAntd.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
    this.onDaysChange = this.onDaysChange.bind(this)
    this.disabledDateTime = this.disabledDateTime.bind(this)
  }

  componentDidMount() {
    const { value } = this.props
    const { calenderDate } = this.state
    if (calenderDate === null && value) {
      this.setState({
        calenderDate: value,
      })
    }
  }

  componentWillReceiveProps(nextProps: any) {
    const { daysToGoDate, defaultValue } = nextProps
    const selectedDate = defaultValue.split('||')[0]
    const {
      onChange,
      name,
      type,
      required,
      defaultValue: this_defaultValue,
    } = this.props
    if (this_defaultValue !== defaultValue && defaultValue) {
      const checkDate = defaultValue.split('||')
      const calDate = moment(checkDate[0].split('-').reverse().join('-'))
      this.setState({
        calenderDate: calDate,
        daysToGo:
          checkDate.length > 1
            ? checkDate[1]
            : calDate.diff(moment(daysToGoDate, 'YYYY-MM-DD'), 'days'),
        hideDaySection: '',
      })
    }
    let formatedDate = ''
    if (selectedDate) {
      formatedDate = selectedDate.split('-').reverse().join('-')
      formatedDate = moment(formatedDate).format('YYYY-MM-DD hh:mm:ss')
    }
    if (new Date(formatedDate).getTime() < new Date(daysToGoDate).getTime()) {
      this.setState(
        {
          calenderDate: null,
          daysToGo: 0,
        },
        onChange(name, type, '', required)
      )
    }
  }

  onDateChange(calenderDate: Moment | null) {
    const { daysToGoDate } = this.props
    if (!calenderDate) {
      this.setState(
        {
          calenderDate: null,
          daysToGo: 0,
          daysUpdatedManually: false,
          hideDaySection: 'none',
        },
        this.parentOnChange
      )
    } else {
      const daysToGo = calenderDate.diff(
        moment(daysToGoDate, 'YYYY-MM-DD'),
        'days'
      )
      this.setState(
        {
          calenderDate,
          daysToGo,
          daysUpdatedManually: false,
          hideDaySection: '',
        },
        this.parentOnChange
      )
    }
  }
  onDateChangeAntd(date: Moment | null, dateString?: any) {
    const { daysToGoDate } = this.props
    if (dateString) {
      let calenderDate = dateString.split('-').reverse().join('-')
      calenderDate = moment(calenderDate)
      const daysToGo = calenderDate.diff(
        moment(daysToGoDate, 'YYYY-MM-DD'),
        'days'
      )
      this.setState(
        {
          calenderDate,
          daysToGo,
          daysUpdatedManually: false,
          hideDaySection: '',
        },
        this.parentOnChange
      )
    } else {
      this.setState(
        {
          calenderDate: null,
          daysToGo: 0,
          daysUpdatedManually: false,
          hideDaySection: 'none',
        },
        this.parentOnChange
      )
    }
  }

  onDaysChange(e: any) {
    const { calenderDate } = this.state
    const daysToGo =
      parseInt(e.target.value, 10) ||
      moment(calenderDate, 'DD-MM-YYYY').diff(moment(), 'days')
    this.setState(
      {
        daysToGo,
        daysUpdatedManually: true,
      },
      this.parentOnChange
    )
  }

  parentOnChange() {
    const { calenderDate, daysUpdatedManually, daysToGo } = this.state
    const { onChange, name, type, required } = this.props
    let value = ''
    if (calenderDate) {
      value = moment(calenderDate).format('DD-MM-YYYY')
      if (daysUpdatedManually) {
        value += `||${daysToGo}`
      }
    }
    onChange(name, type, value, required)
  }

  disabledDateTime(current: any) {
    const { daysToGoDate } = this.props
    const daysTogoDate = moment(daysToGoDate)
    const currentDate = moment().format('YYYY-MM-DD')
    const sendDate = moment(daysTogoDate).format('YYYY-MM-DD')
    if (moment(currentDate).isSameOrBefore(sendDate)) {
      return current < daysTogoDate.subtract(1, 'day').endOf('day')
    }
    return isYesterdayOrBefore(current)
  }

  render() {
    const { disabled } = this.props
    const { calenderDate, hideDaySection, daysToGo } = this.state
    return (
      <div>
        <DatePicker
          date={calenderDate}
          onDateChange={this.onDateChange}
          id="date"
          disabled={disabled}
          appendToBody
          openDirection="up"
          isOutsideRange={this.disabledDateTime}
          isTodayVisible={true}
        />
        <p
          className="leading-normal mt-2 text-coolGray-400"
          style={{ display: hideDaySection }}
        >
          {'Your sale will end on the day selected, do you want to say'}{' '}
          <div className="inline-flex text-coolGray-600">
            <Input
              variant="small"
              type="text"
              value={daysToGo.toString()}
              onChange={this.onDaysChange}
            />
          </div>{' '}
          days to go? If not please update.
        </p>
      </div>
    )
  }
}

export default DaysToGo
