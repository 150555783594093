import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { getCustomerAttributeRecords } from '../api'
import { keys } from '../queryKeys'

export type CustomerAttributeRecord = {
  id: string
  name: string
  region: string
  updated: string
}

const useGetCustomerAttributeRecordsQuery = ({
  privacyRegions,
}: {
  privacyRegions: string[]
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const getCustomerAttributesQuery = useQuery(
    keys.customerAttributeRecords({ accountId }),
    () => getCustomerAttributeRecords({ accountId, privacyRegions }),
    {
      enabled: !!accountId && privacyRegions.length > 0,
      retry: false,
      meta: {
        errorMessage: 'The customer attribute records could not be loaded',
      },
    }
  )

  return getCustomerAttributesQuery
}

export default useGetCustomerAttributeRecordsQuery
