import { useLocation } from 'react-router'
import { generatePath, useHistory } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { profileRoute } from 'app/navigation/staticRoutes'

import Avatar from 'common/components/Avatar'
import ButtonWithDropdown from 'common/components/ButtonWithDropdown'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useProjectsQuery from 'features/campaigns/queries/useProjectsQuery'

const AvatarButton = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  useProjectsQuery()

  const {
    profile: { avatarColor },
    firstName: userFirstName,
    lastName: userLastName,
    accountId,
  } = useAppSelector((state) => state.authStates)
  const { logout } = useAuth0()

  const onRouteClick = (to: string) => {
    const currentPath = location.pathname
    const newPath = generatePath(to, { accountId })
    if (currentPath !== newPath) {
      history.push(newPath)
    }
  }

  const signOutOption = {
    key: 'signOut',
    name: 'Sign out',
    to: null,
    onClick: () => {
      logout({
        returnTo: `${window.location.origin}/`,
      })
    },
  }

  const profileOptions = [profileRoute, signOutOption]

  return (
    <ButtonWithDropdown
      data-cy="profile-menu-button-dropdown"
      data-testid="profile-menu-button-dropdown"
      overlayClassName="capitalize"
      suffixIcon={false}
      placement="bottomLeft"
      className="pr-0"
      options={profileOptions
        .map(({ key, name, to, onClick }) => [
          {
            key: key,
            label: name,
            onClick: () => {
              if (to !== null) {
                onRouteClick(to)
              }
              onClick && onClick(dispatch)
            },
          },
        ])
        .flat()}
    >
      <Avatar
        data-cy="user-profile-avatar"
        data-testid="user-profile-avatar"
        className="w-10 h-10"
        firstName={userFirstName}
        lastName={userLastName}
        color={avatarColor}
      />
    </ButtonWithDropdown>
  )
}

export default AvatarButton
