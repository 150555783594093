import { ComponentElement, Element } from '@phrasee/phrasee-typings'

import IconTitleGroup from 'common/components/iconTitleGroup'
import { replaceNewLineEmojiAsString } from 'common/variantsUtils'
import {
  isComponentElement,
  isTemplateElement,
} from 'features/unifiedFlow/api/interfaces'

import ElementCardIcon from '../../components/ElementCardIcon'

type Props = {
  element: Element
}

const IntegrationContent = ({ element }: Props) => {
  const name = element.display_name || element.name
  const componentElementVariants = isComponentElement(element)
    ? element.variants?.filter((variant) =>
        element.selected_variants?.includes(variant.variant_id)
      ) || []
    : []

  return (
    <>
      <IconTitleGroup title="Content" className="pb-3" />
      <div className="mt-2 p-6 bg-gold-100">
        <div>
          <div className="flex items-center" data-testid="template-name">
            {element.icon && <ElementCardIcon icon={element.icon} size={6} />}
            <span className="ml-2 text-base text-base-700 font-medium uppercase">
              {name}
            </span>
          </div>
          <hr className="my-6 border-gold-400" />
          {isComponentElement(element) && componentElementVariants?.length > 0 && (
            <div
              className="text-base-700 font-medium text-xs"
              data-testid="element-name"
            >
              Messaging
            </div>
          )}
          {isComponentElement(element) &&
            componentElementVariants?.map((variant) => (
              <div
                key={variant.variant_id}
                dir="auto"
                className="text-sm text-base-700 whitespace-pre-line"
                data-testid="variant-text"
              >
                {replaceNewLineEmojiAsString(variant.text)}
              </div>
            ))}
          {isTemplateElement(element) &&
            element.elements.map((nestedElement) => {
              const variants =
                (nestedElement as ComponentElement).variants?.filter(
                  (variant) =>
                    (
                      nestedElement as ComponentElement
                    ).selected_variants?.includes(variant.variant_id)
                ) || []
              return (
                <div key={nestedElement.element_id}>
                  <div
                    className="text-base-700 mb-4 text-md font-medium uppercase"
                    data-testid="element-name"
                  >
                    {nestedElement.display_name || nestedElement.name}
                  </div>
                  {variants.map((variant) => (
                    <div
                      key={variant.variant_id}
                      className="text-sm text-base-700 last:mb-4 whitespace-pre-line"
                      data-testid="variant-text"
                    >
                      {replaceNewLineEmojiAsString(variant.text)}
                    </div>
                  ))}
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}

export default IntegrationContent
