import { useHistory } from 'react-router'
import { dashboardRoute, homeRoute } from 'app/navigation/staticRoutes'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Button from 'common/components/button'
import AuthFooter from 'features/auth/components/Footer'

const MessageComponent = (props) => {
  const {
    isPageNotFound,
    isPageUnauthorized,
    isErrorStaticPage,
    textPrimary,
    textSecondary,
    shouldRenderCta,
    distributionChannel,
    projectId,
  } = props

  let refUrl: any = undefined
  if (shouldRenderCta) {
    refUrl = `/campaign?distributionChannel=${distributionChannel}
      &projectId=${projectId}`
  }

  const flags = useFlags()
  const history = useHistory()

  const homePageUrl = flags.showHomePage ? homeRoute.to : dashboardRoute.to

  const pageNotFoundValues = {
    textPrimary: 'Error code: 404',
    textSecondary: "We can't seem to find the page you're looking for.",
    shouldRenderCta: true,
    ctaText: (
      <Button onClick={() => history.push(homePageUrl)} variant="primary">
        Back to Home
      </Button>
    ),
    url: homePageUrl,
  }

  const pageUnauthorizedValues = {
    textPrimary: 'Error: not authorized',
    textSecondary: 'Either no authentication was provided or it was invalid.',
    shouldRenderCta: true,
    ctaText: (
      <>
        <Button onClick={() => history.push(homePageUrl)} variant="primary">
          Back to Home
        </Button>
      </>
    ),
    url: '/',
  }

  const pageType = isPageNotFound ? pageNotFoundValues : pageUnauthorizedValues

  const handleClick = () => {
    const { history } = props
    const pathToGo: any = isErrorStaticPage ? pageType.url : refUrl
    if (history.push && !pathToGo.includes('/users/')) {
      history.push(pathToGo)
    } else {
      window.location = pathToGo
    }
  }

  return (
    <div
      className="relative min-h-screen flex flex-col justify-center
      px-12 py-12 bg-gold-40 overflow-x-hidden"
    >
      <div className="relative sm:max-w-xl sm:mx-auto mb-16 sm:mb-0 flex flex-col items-center">
        <h2 id="test-id-H2" className="my-8 text-6xl text-base-700 font-bold">
          {isErrorStaticPage ? pageType.textPrimary : textPrimary}
        </h2>
        <p id="test-id-P" className="mb-8 text-lg text-coolGray-400">
          {isErrorStaticPage ? pageType.textSecondary : textSecondary}
        </p>

        {(isPageNotFound || isPageUnauthorized) && (
          <p className="text-3xl font-light">{pageType.ctaText}</p>
        )}
        {shouldRenderCta && (
          <div className="text-center" title="Create new experiment">
            <button
              type="button"
              className="create-campaign-link"
              onClick={() => handleClick()}
            >
              <span>
                {isErrorStaticPage ? pageType.ctaText : 'Create new experiment'}
              </span>
            </button>
          </div>
        )}
      </div>
      <AuthFooter showBookDemo={false} />
    </div>
  )
}

export default MessageComponent
