import { useQuery } from '@tanstack/react-query'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'

import { useAppSelector } from 'common/hooks/redux'

import { getImages } from '../api'
import { imagesKeys } from '../queryKeys'

const useGetImagesQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const pagination = useAppSelector((state) => state.images.pagination)
  const query = useAppSelector((state) => state.images.query)
  const sort = useAppSelector((state) => state.images.sort)
  const imageStatus = useAppSelector((state) => state.images.imageStatus)

  const filteredQueryParams = omitBy(
    { ...pagination, ...sort, status: imageStatus, query },
    isNil
  )

  return useQuery({
    queryKey: imagesKeys.queryParams(accountId, filteredQueryParams),
    queryFn: () => getImages(accountId, filteredQueryParams),
    enabled: !!accountId,
    keepPreviousData: true,
    placeholderData: {
      data: [],
      total: 0,
    },
  })
}

export default useGetImagesQuery
