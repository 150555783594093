import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { fetchAccountPrivacyRegions } from '../dataSourceApi'
import { regionsPerAccountKeys } from '../queryKeys/regionsPerAccountKeys'

const useGetAccountPrivacyRegionsQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  return useQuery(
    regionsPerAccountKeys.regionsPerAccount(accountId),
    () => fetchAccountPrivacyRegions(accountId),
    {
      enabled: !!accountId,
      meta: {
        errorMessage: 'The privacy regions could not be loaded',
      },
    }
  )
}

export default useGetAccountPrivacyRegionsQuery
