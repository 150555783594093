import type { LDFlagSet } from 'launchdarkly-js-client-sdk'

export const isAtLeastOneAccessFlagEnabled = (
  flags: LDFlagSet,
  accessFlags?: string[]
) =>
  accessFlags && accessFlags.length > 0
    ? accessFlags.some((flag) => flags[flag])
    : true

export const isAtLeastOneDenyFlagEnabled = (
  flags: LDFlagSet,
  denyFlags?: string[]
) =>
  denyFlags && denyFlags.length > 0
    ? denyFlags.some((flag) => flags[flag])
    : false
