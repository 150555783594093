import cx from 'classnames'

import { Fragment } from 'common/components/topics/interfaces'
import { highlightTextFragments } from 'common/helpers/string'
import { CircleSlash as CircleSlashIcon } from 'common/icons'
import { RoundedRectangle as RoundedRectangleIcon } from 'common/icons'

import { PageState } from './BriefPage'
import RefreshBriefOverlay from './RefreshBriefOverlay'

type Props = {
  brief: string
  pageState: PageState
  fragments?: Fragment[]
}

const GeneratedBrief = ({ brief, pageState, fragments }: Props) => {
  const enhancedBrief = highlightTextFragments(brief, fragments)

  return (
    <>
      {(pageState === 'generateVariants' ||
        pageState === 'topicsChanged' ||
        pageState === 'formChangedWithBrief' ||
        pageState === 'formChangedWithTopics' ||
        pageState === 'brandVoiceChanged' ||
        pageState === 'showGeneratedContent') && (
        <>
          <hr className="w-full my-8 border-gold-400" />
          <div
            className={cx('relative flex flex-col bg-gold-100 p-4 rounded-sm')}
            data-testid="generated-brief"
          >
            {pageState === 'formChangedWithBrief' && <RefreshBriefOverlay />}
            <div className="flex items-center justify-start">
              <>
                <RoundedRectangleIcon
                  isDefaultColor={false}
                  className="w-5 text-base-700 mr-1"
                />
                <CircleSlashIcon
                  isDefaultColor={false}
                  className="w-3 text-base-700 mr-1"
                />
              </>
              <p className="text-sm font-medium text-base-700 uppercase">
                Jacquard has generated your brief, we will use this engineered
                brief to generate your content.
              </p>
            </div>

            <div
              className="mt-4 text-lg text-base-700 font-normal"
              data-testid="enhanced-brief"
              dangerouslySetInnerHTML={{ __html: enhancedBrief }}
            />
          </div>
        </>
      )}
    </>
  )
}

export default GeneratedBrief
