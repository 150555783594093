import Loader from 'common/components/loaders/Loader'
import { useAppSelector } from 'common/hooks/redux'
import { useVariantInsights } from 'features/unifiedFlow/contentPage/generate/context/VariantInsightsContext'

import RightPanel from '../../components/layout/RightPanel'

import Insights from './Insights'
import VariantInspector from './VariantInspector'
const RightSection = () => {
  const {
    rightPanelRef,
    state: { isVariantSelected },
  } = useVariantInsights()

  const {
    isLoading,
    isWaitingState: { isWaiting },
  } = useAppSelector((state) => state.campaignStates)

  const getState = () => {
    if (isVariantSelected) {
      return 'variantInspector'
    } else {
      return 'insights'
    }
  }

  const state = getState()

  return (
    <RightPanel className="bg-gold-100" width="w-97">
      <div ref={rightPanelRef} className="h-full">
        {
          {
            variantInspector:
              isLoading || isWaiting ? <Loader /> : <VariantInspector />,
            insights: <Insights />,
          }[state]
        }
      </div>
    </RightPanel>
  )
}

export default RightSection
