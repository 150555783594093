import cx from 'classnames'

import Badge from 'common/components/Badge'

type Props = {
  isOptimizable: boolean
  isPersonalizable: boolean
  className?: string
}

const ExperimentTypeBadges = ({
  isOptimizable,
  isPersonalizable,
  className,
}: Props) => {
  return (
    <div className={cx('flex flex-wrap gap-1', className)}>
      {isOptimizable && (
        <Badge
          text="optimise"
          variant="feature"
          size="small"
          data-testid="optimise-badge"
        />
      )}
      {isPersonalizable && (
        <Badge
          text="personalise"
          variant="feature"
          size="small"
          data-testid="personalise-badge"
        />
      )}
    </div>
  )
}

export default ExperimentTypeBadges
