import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useHistory } from 'react-router-dom'
import { Row } from 'react-table'
import { unifiedFlowPaths } from 'app/navigation/paths'
import cx from 'classnames'

import { contentPermissionsIds, getIsAuthorized } from 'common/auth/permissions'
import AvatarList from 'common/components/AvatarList'
import Badge from 'common/components/Badge'
import Button from 'common/components/button/Button'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import ExpandableCell from 'common/components/table/cells/Expandable'
import { TableData } from 'common/components/table/Table'
import { successToast } from 'common/components/toastNotification'
import Tooltip from 'common/components/Tooltip'
import TableWidget from 'common/components/widget/tableWidget'
import { formatDate, formatDateToAgo } from 'common/helpers/date'
import { useAppSelector } from 'common/hooks/redux'
import { useWindowDimensions } from 'common/hooks/useWindowDimensions'
import { Copy as CopyIcon } from 'common/icons'
import { Bin } from 'common/icons'

import ContentStatus from './components/ContentStatus'

type SubRow = {
  id: string
  name: string
  project: string
  template: string
  campaign_id: string
  experimentType: string
  owner: string
  createdDate: string
  sendDate: string
  status: string
}

export type LibraryEntry = {
  id: string
  name: string
  status: string
  createdDate: string
  isLegacy: boolean
  subRows: SubRow[]
}

type Library = LibraryEntry[]

type Props = {
  content: Library
  onDelete: Dispatch<SetStateAction<string>>
}

const ContentLibraryTable = ({ content, onDelete }: Props) => {
  const history = useHistory()

  const { permissions } = useAppSelector((state) => state.authStates)
  const columnId = useMemo(
    () =>
      getIsAuthorized(permissions, contentPermissionsIds.view)
        ? [
            {
              Header: 'Id',
              maxWidth: 140,
              accessor: (row: LibraryEntry | SubRow) => {
                return row.id
              },
              Cell: ({ row }) => {
                return (
                  <BaseCell>
                    <CopyToClipboard
                      text={row.original.id}
                      onCopy={() =>
                        successToast('Campaign ID copied successfully')
                      }
                    >
                      <Tooltip overlay="Copy campaign ID">
                        <Button
                          data-cy="campaignId-copy-button"
                          data-testid="campaignId-copy-button"
                          variant="icon"
                          prefixIcon={<CopyIcon size={4} />}
                        >
                          <span className="truncate w-20">
                            {row.original.id}
                          </span>
                        </Button>
                      </Tooltip>
                    </CopyToClipboard>
                  </BaseCell>
                )
              },
            },
          ]
        : [],
    [permissions]
  )
  const columns: Column<LibraryEntry>[] = useMemo(
    () => [
      {
        Header: 'Title',
        accessor: 'name',
        minWidth: 400,
        Cell: ({ row, toggleAllRowsExpanded }) => {
          return (
            <ExpandableCell<LibraryEntry>
              row={row}
              onCellClick={() => {
                row.depth === 0 &&
                  history.push(`${unifiedFlowPaths.home}/${row.original.id}/`)
              }}
              onExpandClick={() => toggleAllRowsExpanded(false)}
            >
              <div className="flex w-full justify-between items-center">
                <p className="text-gold-700 text-base">{row.values.name}</p>
                {row.original.isLegacy && (
                  <Badge
                    text="Legacy"
                    size="small"
                    className="ml-2 flex-shrink-0"
                  />
                )}
              </div>
            </ExpandableCell>
          )
        },
      },
      {
        Header: 'Template',
        accessor: (row: LibraryEntry | SubRow) => {
          return 'subRows' in row
            ? row.subRows.find((subRow) => subRow.template)?.template ?? ''
            : row.template
        },
        minWidth: 170,
        disableSortBy: true,
        Cell: ({ value, row }) => {
          const tooltipItemsToShow = row.subRows
            .map((subRow) => subRow.values.Template)
            .filter((element) => element !== '')
            .slice(1)

          return (
            <BaseCell>
              {!row.isExpanded && (
                <>
                  <span className="text-gold-700 text-base">{value}</span>
                  {tooltipItemsToShow.length > 0 && (
                    <Tooltip overlay={tooltipItemsToShow.join(', ')}>
                      <span
                        className={cx(
                          'flex border border-gold-50 items-center justify-center min-w-10 min-h-10 ml-2 rounded-full text-gold-700',
                          {
                            'bg-gold-100': tooltipItemsToShow.length >= 1,
                            'bg-gold-200': tooltipItemsToShow.length >= 4,
                            'bg-gold-300': tooltipItemsToShow.length >= 7,
                          }
                        )}
                      >
                        +{tooltipItemsToShow.length}
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
            </BaseCell>
          )
        },
      },
      {
        Header: 'Type',
        accessor: (row: LibraryEntry | SubRow) => {
          return 'subRows' in row
            ? row.subRows.map((element) => element.experimentType)
            : row.experimentType
        },
        minWidth: 120,
        width: 120,
        disableSortBy: true,
        Cell: ({ value, row }) => {
          const badgesToRender =
            row.depth === 0 ? new Set<string>(value) : value.split()
          return (
            <BaseCell className="flex flex-wrap gap-2 py-1">
              {!row.isExpanded &&
                [...badgesToRender]
                  .filter((element) => row.depth > 0 || element !== 'content')
                  .map((element, index) => (
                    <Badge
                      key={index}
                      text={element}
                      size="small"
                      variant="feature"
                      type="outline"
                    />
                  ))}
            </BaseCell>
          )
        },
      },
      {
        Header: 'Send Date',
        accessor: (row: LibraryEntry | SubRow) => {
          return 'subRows' in row
            ? row.subRows.find((subRow) => subRow.sendDate)?.sendDate ?? ''
            : row.sendDate
        },
        minWidth: 140,
        width: 140,
        Cell: ({ value, row }) => {
          const tooltipItemsToShow = row.subRows
            .filter((subRow, index) => index > 0 && subRow.original.sendDate)
            .map((subRow, index) => {
              const date = subRow.original.sendDate
              return !isNaN(new Date(date).getTime()) ? (
                <div key={index}>{formatDate(date)}</div>
              ) : null
            })

          return (
            <BaseCell className="text-gold-700 text-base">
              {!row.isExpanded && value && (
                <>
                  {formatDate(value)}
                  {tooltipItemsToShow.length > 1 && (
                    <Tooltip overlay={tooltipItemsToShow}>
                      <span className="ml-2 min-w-7 text-coolGray-400 font-bold">
                        +{tooltipItemsToShow.slice(1).length}
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
            </BaseCell>
          )
        },
      },
      {
        Header: 'Created',
        minWidth: 140,
        width: 140,
        accessor: 'createdDate',
        Cell: ({ value, row }) => {
          return (
            <BaseCell className="text-gold-700 text-base">
              {!row.isExpanded && formatDateToAgo(new Date(value))}
            </BaseCell>
          )
        },
      },
      {
        Header: 'Owner',
        minWidth: 100,
        width: 100,
        accessor: (row: LibraryEntry | SubRow) => {
          return 'subRows' in row
            ? row.subRows?.map((element) => element.owner)
            : [row.owner]
        },
        disableSortBy: true,
        Cell: ({ value, row }: { value: string[]; row: any }) => {
          const userNames = Array.from(
            new Set(value.filter((curr) => curr !== ''))
          ).map((initials) =>
            initials
              .split(' ')
              .map((word) => word[0])
              .join('')
          )

          return (
            !row.isExpanded && (
              <BaseCell className="flex justify-center">
                <AvatarList userNames={userNames} />
              </BaseCell>
            )
          )
        },
      },
      ...columnId,
      {
        Header: 'Status',
        minWidth: 200,
        accessor: (row: LibraryEntry | SubRow) => {
          return row.status
        },
        disableSortBy: true,
        Cell: ({ value, row }) => {
          return (
            <BaseCell
              className={cx('w-full flex group', {
                'justify-between': !row.isExpanded,
                'justify-end': row.isExpanded,
              })}
            >
              {!row.isExpanded && <ContentStatus value={value} />}
              {row.depth === 0 && (
                <Button
                  variant="icon"
                  className="invisible flex-shrink-0 group-hover:visible"
                  onClick={() => onDelete(row.original.id)}
                >
                  <Bin
                    isDefaultColor={false}
                    className="text-base-700 hover:text-yellow-700"
                  />
                </Button>
              )}
            </BaseCell>
          )
        },
      },
    ],
    [columnId, history, onDelete]
  )

  const { screenWidth } = useWindowDimensions()

  const generateRowClassName = useCallback(
    <T extends TableData>({ row, rows }: { row: Row<T>; rows: Row<T>[] }) => {
      return cx({
        'bg-yellow-200': row.isExpanded,
        'bg-yellow-100': row.depth > 0,
        'w-fit': screenWidth < 1110,
        'border-none': row.index === 0,
      })
    },
    [screenWidth]
  )

  return (
    <div data-testid="content-table" className="pb-12">
      <TableWidget.Widget
        columns={columns}
        data={content}
        firstUseText="Nothing to see here… There is no content to show."
        disableSubRowArrow={true}
        generateRowClassName={generateRowClassName}
        paginateExpandedRows={false}
        cacheId="1"
      />
    </div>
  )
}

export default ContentLibraryTable
