import { useEffect, useRef, useState } from 'react'
import cx from 'classnames'

import GuidingText from 'common/components/guidingText'

import { InputElement, SplitSizeData } from '../../interface'
import SplitSize from '../SplitSize'

import AudienceSize from './AudienceSize'
import { calculateSplitResult, CalculatorResponse } from './calculatorService'
import { extractFields, hasCalculatorFieldErrors } from './helpers'
import { FormInstance } from './interfaces'
import Rate from './Rate'
import SelectionMetric from './SelectionMetric'

let calculatorTimer
const SPLIT_CACLULATOR_DELAY = 500

type Props = {
  form: FormInstance
  projectId: string
  campaignId: string
  splitNumber?: number
  disabled?: boolean
  fields?: InputElement[]
  showBanner: (config: any) => void
  updateSetupState: (stateValue: object) => void
  setTriggerCalculationFunc: (func: () => void) => void
  requiredFields: string[]
}

const SplitCalculatorV3: React.FC<Props> = ({
  fields,
  form,
  projectId,
  disabled = false,
  splitNumber,
  campaignId,
  showBanner,
  updateSetupState,
  setTriggerCalculationFunc,
  requiredFields,
}) => {
  const [areRateFieldsEnabled, setAreRateFieldsEnabled] = useState(false)
  const [isCalculatorLoading, setIsCalculatorLoading] = useState(false)
  const [splitCalculationResult, setSplitCalculationResult] =
    useState<SplitSizeData | undefined>()
  const previousCalculationParamsString = useRef<string>('')

  const selectionMetric = form.getFieldValue('selection_metric')

  const shouldShowClickRate =
    selectionMetric === 'click_rate' || selectionMetric === 'phrasee_score'
  const shouldShowOpenRate =
    selectionMetric === 'open_rate' || selectionMetric === 'phrasee_score'
  const isPhraseeScoreSelected = selectionMetric === 'phrasee_score'

  const onChange = async ({ key, value }) => {
    updateSetupState({ [key]: value })
    getSplitCalculationDebounced()
  }

  const getSplitCalculationDebounced = ({
    minSplitNumber,
    maxSplitNumber,
  }: { minSplitNumber?: number; maxSplitNumber?: number } = {}) => {
    clearTimeout(calculatorTimer)
    setAreRateFieldsEnabled(true)
    calculatorTimer = setTimeout(async () => {
      if (!(await hasCalculatorFieldErrors(form, requiredFields))) {
        getSplitCalculation({ minSplitNumber, maxSplitNumber })
      }
    }, SPLIT_CACLULATOR_DELAY)
  }

  const getSplitCalculation = async ({
    minSplitNumber,
    maxSplitNumber,
  }: { minSplitNumber?: number; maxSplitNumber?: number } = {}) => {
    const selectionMetric = form.getFieldValue('selection_metric')
    const listSize = form.getFieldValue('list_size')
    const baselineOpenRate = form.getFieldValue('baseline_open_rate')
    const baselineClickRate = form.getFieldValue('baseline_click_rate')

    const calculationParamsString = [
      selectionMetric,
      listSize,
      baselineClickRate,
      baselineOpenRate,
    ].join('-')

    if (
      previousCalculationParamsString.current === '' ||
      previousCalculationParamsString.current !== calculationParamsString ||
      maxSplitNumber ||
      minSplitNumber
    ) {
      previousCalculationParamsString.current = calculationParamsString

      setIsCalculatorLoading(true)
      if (!splitCalculationResult) {
        setSplitCalculationResult({
          headline: 'Calculating your split size',
          is_full_test: false,
          is_head_to_head: false,
          other_split_options: [],
          show_other_split_options: false,
          split_number: 0,
          split_result: '',
          split_size: 0,
          total_split_audience: '0',
          campaign_data: undefined,
        })
      }

      let splitResult: CalculatorResponse = {}
      try {
        splitResult = await calculateSplitResult(projectId, campaignId, {
          selectionMetric,
          listSize,
          baselineOpenRate,
          baselineClickRate,
          maxSplitNumber,
          minSplitNumber,
        })
      } catch (error: any) {
        if (error.name === 'INVALID AUDIENCE_SIZE') {
          form.setFields({
            list_size: {
              value: form.getFieldValue('list_size'),
              errors: [error.message],
              touched: true,
            },
          })
        } else {
          showBanner({ content: error.message, type: 'error' })
        }
      }

      // Update engagement fields
      if (splitResult.result) {
        const rates = splitResult.expectedEngagementRates
        if (rates && isPhraseeScoreSelected) {
          const baseline_click_rate = rates['click_rate']
          const baseline_open_rate = rates['open_rate']

          form.setFields({
            ...(!baselineClickRate &&
              baseline_click_rate.value && {
                baseline_click_rate,
              }),
            ...(!baselineOpenRate &&
              baseline_open_rate.value && {
                baseline_open_rate,
              }),
          })
          updateSetupState({
            ...(!baselineClickRate && {
              baseline_click_rate: baseline_click_rate.value,
            }),
            ...(!baselineOpenRate && {
              baseline_open_rate: baseline_open_rate.value,
            }),
          })
        } else if (rates && !baselineClickRate && shouldShowClickRate) {
          const baseline_click_rate = rates['click_rate']
          form.setFields({ baseline_click_rate })
          updateSetupState({ baseline_click_rate: baseline_click_rate.value })
        } else if (rates && !baselineOpenRate && shouldShowOpenRate) {
          const baseline_open_rate = rates['open_rate']
          form.setFields({ baseline_open_rate })
          updateSetupState({ baseline_open_rate: baseline_open_rate.value })
        }
      }
      setSplitCalculationResult(splitResult.result)
      setIsCalculatorLoading(false)
      setAreRateFieldsEnabled(true)

      updateSetupState({
        splitSizeResult: splitResult.result,
        split_size: splitResult.result?.split_size,
        split_number: splitResult.result?.split_number,
        calculatorCampaignData: splitResult.campaignData,
      })
    }
  }

  useEffect(() => {
    setTriggerCalculationFunc(getSplitCalculationDebounced)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!fields || fields.length === 0) {
    return null
  }

  const { selectionMetricField, clickRateField, listSizeField, openRateField } =
    extractFields(fields)

  return (
    <>
      {selectionMetric ? (
        <fieldset
          className={`border-2 border-maroon-100 border-solid
          px-6 mb-5 mt-8`}
          style={{ width: '780px' }}
        >
          <legend className="-top-2">
            <SelectionMetric
              field={selectionMetricField}
              form={form}
              className="w-80"
              showTooltip={false}
              disabled={disabled}
              onChange={(value) => {
                setSplitCalculationResult(undefined)
                setAreRateFieldsEnabled(false)
                updateSetupState({ [selectionMetricField.field_id]: value })
              }}
            />
          </legend>
          {selectionMetricField.tooltip && (
            <GuidingText
              text={selectionMetricField.tooltip}
              style={{ position: 'relative', top: '-1rem' }}
            />
          )}
          <div className="flex w-156 mt-3">
            <AudienceSize
              field={listSizeField}
              form={form}
              disabled={disabled}
              className={cx('mr-6', {
                'w-3/5': isPhraseeScoreSelected,
                'w-1/2': !isPhraseeScoreSelected,
              })}
              onChange={(value) =>
                onChange({ key: listSizeField.field_id, value })
              }
            />
            {shouldShowOpenRate && openRateField && (
              <Rate
                field={openRateField}
                form={form}
                className={cx('mr-6', {
                  'w-1/5': isPhraseeScoreSelected,
                  'w-1/2': !isPhraseeScoreSelected,
                })}
                disabled={!areRateFieldsEnabled || disabled}
                onChange={(value) =>
                  onChange({ key: openRateField.field_id, value })
                }
              />
            )}
            {shouldShowClickRate && clickRateField && (
              <Rate
                field={clickRateField}
                form={form}
                className={cx({
                  'w-1/5': isPhraseeScoreSelected,
                  'w-1/2': !isPhraseeScoreSelected,
                })}
                disabled={!areRateFieldsEnabled || disabled}
                onChange={(value) =>
                  onChange({ key: clickRateField.field_id, value })
                }
              />
            )}
          </div>
          {splitCalculationResult &&
          Object.keys(splitCalculationResult).length > 0 ? (
            <div className="w-156 mb-6">
              <SplitSize
                value={splitNumber}
                isLoading={isCalculatorLoading}
                data={splitCalculationResult}
                isDisabled={disabled}
                updateSplitSizeNumber={(newSplitNumber: number) => {
                  if (newSplitNumber > splitNumber!) {
                    getSplitCalculationDebounced({
                      minSplitNumber: newSplitNumber,
                    })
                  } else {
                    getSplitCalculationDebounced({
                      maxSplitNumber: newSplitNumber,
                    })
                  }
                }}
              />
            </div>
          ) : undefined}
        </fieldset>
      ) : (
        <SelectionMetric
          field={selectionMetricField}
          form={form}
          onChange={(value) =>
            onChange({ key: selectionMetricField.field_id, value })
          }
          className="w-1/2"
        />
      )}
    </>
  )
}

export default SplitCalculatorV3
