import { ReactNode } from 'react'
import cx from 'classnames'

import AccordionItem from './AccordionItem'

type Props = {
  children: ReactNode
  className?: string
  'data-testid'?: string
}

const Accordion = ({
  children,
  className,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <div className={cx('w-full ', className)} data-testid={dataTestId}>
      {children}
    </div>
  )
}

Accordion.Item = AccordionItem

export default Accordion
