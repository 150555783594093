import FormItem from 'common/components/formItem'
import NumericInput from 'common/components/numericInput'

import { CommonFieldPropsGeneric, PercentageType } from '../interfaces'

const ERROR_MESSAGES = {
  required: 'Please enter a valid percentage.',
}

type Props = CommonFieldPropsGeneric & { value: PercentageType['value'] }

const Percentage = ({
  label,
  name,
  value,
  tooltip,
  errorCode,
  isDisabled,
  placeholder,
  onChange,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <FormItem
      label={label}
      guidingText={tooltip}
      htmlFor={name}
      error={errorCode ? ERROR_MESSAGES[errorCode] : undefined}
    >
      <NumericInput
        id={name}
        data-testid={dataTestId}
        className="w-25"
        placeholder={placeholder}
        onChange={onChange}
        postfix="%"
        min={0}
        max={100}
        value={value}
        disabled={isDisabled}
      />
    </FormItem>
  )
}

export default Percentage
