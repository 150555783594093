import { useMemo } from 'react'
import { PersonalizedVariant } from '@phrasee/phrasee-typings'

import EmptyState from 'common/components/emptyState'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { errorToast } from 'common/components/toastNotification'
import TableWidget from 'common/components/widget/tableWidget'
import useTableActions from 'common/hooks/useTableActions'
import ActionCell from 'features/unifiedFlow/contentPage/components/ActionsCell'

import FooterButtons from './FooterButtons'

type Props = {
  data: PersonalizedVariant[]
  onOpenTweakModal: (isModalOpen: boolean) => void
  onSetTweakingVariantId: (variantId: string) => void
  isReadOnly: boolean
}

type ExtendedPersonalizedVariant = PersonalizedVariant & {
  isLoading?: boolean
  isRefreshed?: boolean
}

const CombinationVariantsTable = ({
  data,
  onOpenTweakModal,
  onSetTweakingVariantId,
  isReadOnly,
}: Props) => {
  const { onCopy } = useTableActions()

  const columns: Column<ExtendedPersonalizedVariant>[] = useMemo(
    () => [
      {
        Header: `Variants`,
        accessor: 'text',
        disableSortBy: true,
        direction: 'auto',
        Cell: ({ row }) => {
          return (
            <BaseCell className="min-h-14">
              <div>{row.original.text}</div>
            </BaseCell>
          )
        },
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: 'variant_id',
        disableSortBy: true,
        align: 'right',
        disableFilters: true,
        width: 140,
        className: 'flex-none',
        Cell: ({ row }) => {
          return (
            <ActionCell
              onCopy={() => onCopy(row.values.text)}
              onRequestChange={() => {
                onOpenTweakModal(true)
                onSetTweakingVariantId(row.original.variant_id)
              }}
              onDelete={() => errorToast('Not implemented yet')}
              isTweaking={row.original.isLoading}
              isSuccessful={row.original.isRefreshed}
            />
          )
        },
      },
    ],
    [onCopy, onOpenTweakModal, onSetTweakingVariantId]
  )

  const areUnapprovedVariantsAvailable = data.some(
    (variant) => variant.status === 'pending'
  )

  return (
    <TableWidget.Widget
      data-cy="personalized-variants-table"
      data-testid="personalized-variants-table"
      data={data}
      columns={columns}
      autoResetPage={false}
      initialState={{
        hiddenColumns: isReadOnly ? ['actions'] : [],
      }}
      firstUseText={
        <EmptyState title="There is no new language to display at this time." />
      }
    >
      <TableWidget.ActionButtons className="w-full flex justify-end">
        <FooterButtons
          isReadOnly={isReadOnly}
          isApprovable={areUnapprovedVariantsAvailable}
        />
      </TableWidget.ActionButtons>
    </TableWidget.Widget>
  )
}

export default CombinationVariantsTable
