import Accordion from 'common/components/accordion'
import Button from 'common/components/button'
import IconTitleGroup from 'common/components/iconTitleGroup'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import BriefCenterSection from 'features/unifiedFlow/contentPage/brief/centerSection/CenterSection'
import { getStepStatus } from 'features/unifiedFlow/contentPage/helpers'
import { useContent, useVariants } from 'features/unifiedFlow/contentPage/hooks'
import { showRightPanel } from 'features/unifiedFlow/store/unifiedFlowSlice'

type Props = {
  isWebsocketLoading: boolean
  isAdvancedSettingsOptionsShown?: boolean
  onClickAdvancedSettings?: () => void
}

const BriefAccordion = ({
  isWebsocketLoading,
  isAdvancedSettingsOptionsShown = false,
  onClickAdvancedSettings,
}: Props) => {
  const dispatch = useAppDispatch()
  const { content } = useContent()

  const activeContentId = useAppSelector(
    (state) => state.unifiedFlow.activeContentId
  )
  const { data: variants } = useVariants(activeContentId, { withSubrows: true })

  const handleAdvancedSettingsClick = (e) => {
    e.stopPropagation()
    dispatch(showRightPanel({ visibility: 'visible', isUserAction: true }))
    onClickAdvancedSettings?.()
  }

  const briefStepsStatus = getStepStatus(content?.steps_status, 'brief')

  const isBriefStepsStatusActive = briefStepsStatus === 'enabled' || 'active'

  if (!isBriefStepsStatusActive) {
    return null
  }

  const isOpen = isWebsocketLoading || variants?.length === 0

  return (
    <Accordion className="mb-6" data-testid="brief-accordion">
      <Accordion.Item
        isOpen={isOpen}
        title={
          <div className="flex items-center justify-between w-full">
            <IconTitleGroup title="Brief" />
            {isAdvancedSettingsOptionsShown && (
              <Button
                size="small"
                variant="link"
                className="uppercase mr-3"
                data-testid="advanced-settings"
                onClick={handleAdvancedSettingsClick}
              >
                <span className="text-base-700 underline">
                  Advanced Settings
                </span>
              </Button>
            )}
          </div>
        }
      >
        <BriefCenterSection isWebsocketLoading={isWebsocketLoading} />
      </Accordion.Item>
    </Accordion>
  )
}

export default BriefAccordion
