import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Steps } from 'antd'

import { Spinner as SpinnerIcon } from 'common/icons'

import { fetchCampaignBackgroundStatus } from '../../Workflow.actions'

declare const window: any

const { Step } = Steps
export const BACKGROUND_CAMPAIGN_FETCH_INTERVAL = 5000
const fetchStatus = (
  campaignId: string,
  fetchAction: any,
  setIfIntervalEvoked: any
) => {
  if (campaignId) {
    fetchAction(campaignId)
  }
  setIfIntervalEvoked({ evoked: true })
}
const CampaignStatus = (props: any) => {
  const {
    steps,
    fetchCampaignBackgroundStatus: fetchAction,
    campaignId,
    shouldStopPolling,
    shouldRunIndependently,
  } = props
  const [intervalParams, setIfIntervalEvoked] = useState({
    evoked: false,
  })
  if (shouldRunIndependently) {
    window.pollInterval = setInterval(() => {
      fetchStatus(campaignId, fetchAction, setIfIntervalEvoked)
    }, BACKGROUND_CAMPAIGN_FETCH_INTERVAL)
    if (shouldStopPolling) {
      clearInterval(window.pollInterval)
    }
  }
  useEffect(() => {
    if (!intervalParams.evoked && shouldRunIndependently) {
      fetchStatus(campaignId, fetchAction, setIfIntervalEvoked)
    }
    // use effect returns a function to stop interval when component un-mounts
    return () => clearInterval(window.pollInterval)
  }, [intervalParams.evoked, shouldRunIndependently, campaignId, fetchAction])
  return (
    <div className="m-b--30 m-t--15">
      <Steps>
        {steps.map((item: any) => (
          <Step
            data-testid="step"
            status={item.status}
            key={item.key}
            title={item.title}
            subTitle={item.subTitle}
            icon={<SpinnerIcon className="animate-spin" />}
            description={item.description}
          />
        ))}
      </Steps>
    </div>
  )
}

export function mapStateToProps(state: any) {
  return {
    steps: state.campaignStates.steps,
    shouldStopPolling: state.campaignStates.shouldStopPolling,
  }
}
export function mapDispatchToProps(dispatch: any) {
  return {
    fetchCampaignBackgroundStatus: (campaignId: string) =>
      dispatch(fetchCampaignBackgroundStatus(campaignId)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CampaignStatus)
