import { ComponentElement } from '@phrasee/phrasee-typings'
import { useQueries, useQueryClient } from '@tanstack/react-query'
import isArray from 'lodash/isArray'
import { SubjectLine } from 'workflow/interface'
import apiUtil from 'workflow/utils/Api'
import helpers from 'workflow/utils/helpers'

import { useAppSelector } from 'common/hooks/redux'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'

import { contentKeys } from '../../api/queryKeys'
import { getAllComponentElements } from '../helpers'

import { useContent } from './index'

const getUnapprovedVariantsCount = (element) => {
  if (element?.personalization_id) {
    return element?.personalized_ids_to_approve?.length
  }
  return element?.sl?.filter(
    (subjectLine) => subjectLine?.bandit_status === 'pending'
  ).length
}

const useVariantsToApproveQuery = () => {
  const { content } = useContent()
  const queryClient = useQueryClient()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const allComponentElements = getAllComponentElements(content)

  const queries = allComponentElements
    .filter((element) => !!element.campaign_id || !!element.personalization_id)
    .map((element) => {
      if (element.campaign_id) {
        return {
          queryKey: [contentKeys.campaign(element.campaign_id as string)],
          queryFn: () =>
            apiUtil(`campaigns/${element.campaign_id}?includeDeleted=true`, {
              method: 'GET',
            }),
          staleTime: 60 * 1000 * 10, // 10min,
        }
      } else if (element.personalization_id) {
        return {
          queryKey: [contentKeys.content(element.personalization_id)],
          queryFn: () =>
            apiUtil(
              `v1/core/content-api/accounts/${accountId}/contents/${content?._id}`,
              {
                method: 'GET',
              },
              { useConnectApi: true, internal: true }
            ),
          staleTime: 60 * 1000 * 10, // 10min,
          select: (content) =>
            content.elements.find(
              (el) => el.personalization_id === element.personalization_id
            ),
        }
      } else {
        return {}
      }
    })

  const invalidateAll = () => {
    queries.forEach((query) => {
      queryClient.invalidateQueries(query.queryKey)
    })
  }
  const results = useQueries({
    queries: queries,
  })

  const variantsToBeApprovedCollection = results.reduce(
    (acc: SubjectLine[], item) => {
      const personalizedVariants = item?.data?.personalized_variants?.filter(
        (variant) => {
          return item?.data?.personalized_ids_to_approve?.includes(
            variant.variant_id
          )
        }
      )
      if (personalizedVariants) {
        acc.push(...personalizedVariants)
      }
      const subjectLines = item?.data?.data?.sl
      if (isArray(subjectLines) && subjectLines.length > 0) {
        const pendingVariants = subjectLines.filter(
          (variant) =>
            variant?.bandit_status?.status === 'pending' &&
            helpers.shouldEnableVariantApprovals(item.data.data)
        )
        acc.push(...pendingVariants)
      }
      return acc
    },
    []
  )

  const contentWithNewVariants: ComponentElement[] = results
    .map((result) => {
      const isPersonalizedWithNewLanguage =
        result.data?.personalization_id &&
        result.data?.personalized_ids_to_approve?.length > 0

      const isOptimizedWithNewLanguage = result?.data?.data?.sl?.some(
        (variant) => variant?.bandit_status?.status === 'pending'
      )
      return (
        (isPersonalizedWithNewLanguage && result.data) ||
        (isOptimizedWithNewLanguage && result.data.data) ||
        null
      )
    })
    .sort((a, b) => {
      const aLength = getUnapprovedVariantsCount(a)
      const bLength = getUnapprovedVariantsCount(b)
      return aLength - bLength
    })
    .reduce((acc, element) => {
      element !== null && acc.push(element)
      return acc
    }, [])

  const elements = content?.elements ?? []

  const elementsWithNewLanguageIds = contentWithNewVariants?.map(
    (element) =>
      (isComponentElement(element) && element.personalization_id) || element._id
  )

  const elementsWithNewLanguage = elements.filter((element) =>
    elementsWithNewLanguageIds.some(
      (id) =>
        (isComponentElement(element) && element.personalization_id === id) ||
        (isComponentElement(element) && element.campaign_id === id)
    )
  )

  return {
    isLoading: results.some((item) => item.isLoading),
    variantsToBeApprovedCount: variantsToBeApprovedCollection?.length || 0,
    contentWithNewVariants,
    elementsWithNewLanguage,
    invalidateAll,
  }
}

export default useVariantsToApproveQuery
