import SubNavBar from 'common/components/navigation/subNavBar'

import HeaderNavigation from './HeaderNavigation'

const Header = () => {
  return (
    <SubNavBar title="Data Sources">
      <HeaderNavigation />
    </SubNavBar>
  )
}

export default Header
