import cx from 'classnames'

const BADGES_TYPES = {
  SOLID: 'solid',
  OUTLINE: 'outline',
  TRANSPARENT: 'transparent',
} as const

type Props = {
  text: string | JSX.Element
  variant?: 'success' | 'warning' | 'danger' | 'info' | 'neutral' | 'feature'
  size?: 'small' | 'normal'
  className?: string
  isDisabled?: boolean
  type?: 'solid' | 'outline' | 'transparent'
  'data-testid'?: string
}

const variantStyles = {
  feature: {
    [BADGES_TYPES.SOLID]: 'font-medium text-base-700 bg-gold-40 border-gold-40',
    [BADGES_TYPES.OUTLINE]:
      'font-medium text-gold-700 bg-transparent border-gold-700',
    [BADGES_TYPES.TRANSPARENT]:
      'font-medium text-base-700 bg-transparent border-transparent',
  },
}

const Badge = ({
  className,
  text,
  size = 'normal',
  variant = 'neutral',
  isDisabled = false,
  type = BADGES_TYPES.SOLID,
  'data-testid': dataTestId,
}: Props) => {
  const baseStyles = variantStyles[variant]?.[type] || ''

  return (
    <span
      data-testid={dataTestId}
      className={cx(
        'whitespace-nowrap border border-solid rounded-sm uppercase',
        baseStyles,
        {
          'text-xs py-0.5 px-1': size === 'small',
          'text-sm py-1 px-2': size === 'normal',
          'bg-RAGGreen text-white': variant === 'success',
          'bg-RAGamber text-base-700': variant === 'warning',
          'bg-RAGred text-white': variant === 'danger',
          'bg-maroon-400 text-white': variant === 'info',
          'bg-base-100 text-base-700': variant === 'neutral',
          'opacity-30': isDisabled,
        },
        className
      )}
    >
      {text}
    </span>
  )
}

export default Badge
