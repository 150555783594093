import { unifiedFlowPaths } from 'app/navigation/paths'

import Button from 'common/components/button'
import { isComponentElement } from 'features/unifiedFlow/api/interfaces'
import useApproveElementMutation from 'features/unifiedFlow/api/mutations/useApproveElementMutation'
import { getAllElements } from 'features/unifiedFlow/contentPage/helpers'
import {
  useContent,
  useSelectedElement,
} from 'features/unifiedFlow/contentPage/hooks'

type Props = {
  isReadOnly?: boolean
  isApproveButtonDisabled?: boolean
}
const ContentTableActionButtons = ({
  isApproveButtonDisabled,
  isReadOnly = false,
}: Props) => {
  const { content } = useContent()
  const approveElementMutation = useApproveElementMutation({
    redirectTo: unifiedFlowPaths.launch,
  })
  const { data: selectedElement } = useSelectedElement()

  const allComponentElements =
    getAllElements(content).filter(isComponentElement)

  const approvedElements = allComponentElements.filter(
    (element) => element.approved
  )

  const handleApprove = () => {
    if (selectedElement && content) {
      approveElementMutation.mutate({
        accountId: content.account_id,
        element_id: selectedElement.element_id,
        content_id: content?._id,
      })
    }
  }

  return (
    <div className="flex items-center justify-between w-full">
      <p
        className="text-gold-500 font-normal text-sm"
        data-testid="approvals-complete"
      >
        {`${approvedElements.length} out of ${allComponentElements.length} approvals complete`}
      </p>
      <Button
        data-testid="approve-variant"
        disabled={
          isApproveButtonDisabled ||
          approveElementMutation.isLoading ||
          selectedElement?.approved ||
          isReadOnly
        }
        loading={approveElementMutation.isLoading}
        className="ml-4 cta-button"
        variant="primary"
        onClick={handleApprove}
      >
        {selectedElement?.approved ? 'Approved' : 'Approve'}
      </Button>
    </div>
  )
}

export default ContentTableActionButtons
