import Slider from 'common/components/slider/Slider'
import { errorToast } from 'common/components/toastNotification'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Node } from 'common/interfaces/nodes'

import {
  addDefaultNode,
  addPrivateNode,
  removeDefaultNode,
  removePrivateNode,
  updateSpecificityWeighting,
} from '../store/nodeSlice'

import NodeTags from './NodeTags'

type Props = {
  nodes: Node[]
}

const NodeSetttingsWidget = ({ nodes }: Props) => {
  const dispatch = useAppDispatch()
  const editedProjectNodes = useAppSelector(
    (state) => state.projectNodes.editedData.nodeSettings
  )

  const defaultNodes = editedProjectNodes.defaultNodes.map((word) => ({
    label: word,
    value: word,
  }))
  const privateNodes = editedProjectNodes.privateNodes.map((word) => ({
    label: word,
    value: word,
  }))
  return (
    <Widget data-cy="node-settings-widget" data-testid="node-settings-widget">
      <WidgetHeader title="Node settings" />
      <div className="flex flex-col gap-4">
        <NodeTags
          data-cy="default-nodes-tags"
          data-testid="default-nodes-tags"
          title="Default nodes"
          subTitle="Select the nodes that can always be used in generations. At least one default node needs to be entered for a project."
          onAddClick={(value: string) => {
            dispatch(addDefaultNode(value))
          }}
          onRemoveClick={(value: string) => {
            if (defaultNodes.length === 1) {
              errorToast(
                'You must have at least one default node configured on a project before saving.'
              )
            }
            dispatch(removeDefaultNode(value))
          }}
          tags={defaultNodes}
          suggestions={nodes.map((node) => node.name)}
          tagButtonLabel="Node"
        />
        <NodeTags
          data-cy="private-nodes-tags"
          data-testid="private-nodes-tags"
          title="Private nodes"
          subTitle="If you want to include private nodes in the node configuration, select them here."
          onAddClick={(value: string) => {
            dispatch(addPrivateNode(value))
          }}
          onRemoveClick={(value: string) => {
            dispatch(removePrivateNode(value))
          }}
          tags={privateNodes}
          suggestions={nodes
            .filter((node) => node.isPrivate)
            .map((node) => node.name)}
        />
        <div>
          <div className="text-coolGray-800 mb-1 font-bold">
            Specificity weighting
          </div>
          <div className="text-coolGray-400" style={{ maxWidth: 727 }}>
            Setting a higher weighting means the nodes further out will generate
            more often than the more generic central nodes. To force the
            language from the outer-most node, select 10.
          </div>
          <div className="w-72 mt-5 ml-2">
            <Slider
              min={0}
              max={10}
              onChange={(value) => {
                dispatch(updateSpecificityWeighting(value as number))
              }}
              value={editedProjectNodes.specificityWeighting}
            />
          </div>
        </div>
      </div>
    </Widget>
  )
}

export default NodeSetttingsWidget
