/* eslint-disable max-lines */

import { Team } from 'common/api/teamsApi'
import { Sentiments } from 'features/reports/insights/api'

import { EpsilonSeedList } from '../workflow/CampaignSummary/IntegrationOptions/EpsilonIntegration/EpsilonIntegration.types'

import { VisualisationTemplates } from './CampaignSummary/PhraseeXContent/AdvancedVisualisations/types'

export interface ApprovedBy {
  _id: string
  fname: string
  lname: string
}
export interface BanditStatus {
  status: 'approved' | 'dropped' | 'live' | 'pending'
  approved_by: ApprovedBy
}

export type AppliedFilters =
  | undefined
  | []
  | { variant_status: BanditStatus['status'][] }
export interface SubjectLine {
  _id: string
  api_id: string
  text: string
  sort_order: number
  ownsl: boolean
  user_id: string
  account_id: string
  campaign_id: string
  overwritten: boolean | null
  bandit_status?: BanditStatus
  why_tweak?: string
  why_dislike?: string
  category?: string
  sentiments?: Sentiments
  old_sl_id?: string
  text_before_tweak?: string
}
export interface DistributionChannel {
  type: DistributionChannelType
  icon?: string
  beta: boolean
  title: string
  tooltip: string
  enabled: boolean
}

export interface BusinessUnit {
  business_unit_id: string
  business_unit_name: string
}
export interface CampaignUnit {
  epsilon_campaign_id: string
  epsilon_campaign_name: string
}

export interface FolderUnit {
  epsilon_folder_id: string
  epsilon_folder_name: string
}

export interface Rule {
  message?: string | null
  required?: boolean
  type?: string
  pattern?: RegExp
  len?: number
  min?: number
  max?: number
  validator?: (rule: any, value: string) => Promise<void>
}

export interface AutoSizeType {
  min?: number
  max?: number
}

export interface InputElement {
  visible: boolean
  title: string
  tooltip: string | null
  property_name: string
  updates_UI: boolean
  placeholder: string
  usage?: 'split_calculator'
  field_id: string
  source_URL: string
  rules?: Rule[]
  minMaxRows?: AutoSizeType
  step?: number
  min?: number
  max?: number
  elements?: { id: string; name: string; value: string; default?: boolean }[]
  type: InputContentType
  conditioned_by?: any
  value?: any
  allow_past?: boolean
}

export interface CheetahCampaign {
  obj_id: number
  display_name: string
  type_id: string
  ref_id: number
  parent_obj_id: number
  eligibility_status_id: string
}

export interface CheetahPatchPayload {
  cheetah_campaign_id: string
  optimisation_start_time: string
  campaign_end_time: string
  timeZone: string
  espTimeZone: string
}

export interface CheetahRetrieveResultsPayload {
  espCampaignId: string
}

export interface EmarsysCampaign {
  campaignName: string
  campaignId: string
  alreadyUsed?: boolean
  emails: any[]
}

export type SendOptimization = 'SEND_24HRS' | 'NONE'
export type SuppressionList = { _id: string; name: string }

export type MachineSummary = {
  total_machine_opens: number
  total_opens: number
  total_other_opens: number
  other_opens_percent: string
  machine_opens_percent: string
}

export interface CampaignWorkflowState {
  cheetahCampaigns: CheetahCampaign[]
  emarsysCampaigns: EmarsysCampaign[]
  cheetahTimeZone: string
  timeZone: string
  isLoading: boolean
  isLoadingCampaignDetails: boolean
  resultsDetailsIsLoading: boolean
  isScheduleButtonDisabled: boolean
  distributionChannels: DistributionChannel[]
  distributionChannel: DistributionChannelType | undefined
  projectName: string | undefined
  projecstList: Project[]
  subjectLines: Array<any>
  combinedLanguageSettings: {
    isoCurrency: string
    isoLanguage: string
    contentStructure?: { name: string; length: number }[]
  }
  campaignConfiguration: Record<string, object>
  rejectLineLoading: boolean
  tweakingLoading: boolean
  showTweakLine: boolean
  lineBeingTweaked: Record<string, object>
  languageApprovalLoading: boolean
  campaignName: string
  projectId: string
  nfmUsed: number
  tweakUsed: number
  newlinesInVariants: object | null
  shouldDisplayBoomModal: boolean
  campaignData: any
  currentCampaignId: string
  error: ErrorObject | null | string
  midListError: ErrorObject | null
  engageSfmcError: ErrorObject | null
  scheduleCheetahError: ErrorObject | null
  typeOfIntegration: string
  isUserInput: boolean
  chosenStep: CampaignNavigationSteps
  isSaveContinue: null
  shouldStopPolling: boolean | null
  isWaitingState: {
    isWaitingFor: string | null
    isWaiting: boolean
  }
  results: {
    variants: { values: Record<string, number | string>[] } | null
    split_campaign_results: string | number | null | any
    final_campaign_results: Record<string, number | string> | null
    reports: Record<string, number | string> | null
    machine_summary?: MachineSummary | null
  }
  filteredTableVariants: SubjectLine[]
  csv: string | null
  warnings: Array<string> | null
  shouldUpdateCampaignData: boolean
  campaignValidationRules: object | null
  fetchingMovableInkApiKeys: boolean
  movableInkApiKeys: object | null
  fetchingMovableInkApiKeysError: boolean
  clearingMovableInkTestData: boolean
  updatingMovableInkIntegrationData: boolean
  isInIframe: boolean
  scheduleResponse: object | null
  sailthruIntegration: {
    splitCampaign: object | null
    finalCampaign: object | null
    hoursBeforeFinalDateTime: object | null
    scheduleTime: object | null
    scheduleDate: object | null
    listCampaigns: Array<string> | []
    summary: object | null
  }
  isSendDefinitionLoading: boolean
  cancelResponse: any
  summary: any
  emailList: Array<any>
  acousticCampaignScheduled?: boolean
  mailingExists?: boolean
  splitsSent?: boolean
  folderName: string | null
  modalVisible: boolean
  ampScript: string
  loadingAmpScript: boolean
  daysToGoDate: Date | string | null
  values: any | null
  conditionalHiddenStatus: any | null
  conditionalOpenParents: any | null
  validationErrors: any | null
  isQuestionLoaded: boolean
  stepTwoSubmitLoading: boolean
  completed: boolean
  expanded: boolean
  loading: boolean
  slGeneratingStatus: boolean
  slAnimationProgress: number | null
  animationImage: string | null
  animationText: string | null
  percentage: number | null
  regenerateModalVisible: boolean
  nerValidationErrorMessage: string | null
  nerValidationModalVisible: boolean
  questions: Array<any>
  globalConditionalFields: any | null
  currencyPattern: string | null
  steps: Array<any>
  grammar: string | null
  xml_download_enabled?: boolean
  xml_templated_text?: string
  advancedVisualisationsLoading?: boolean
  advancedVisualisationsData?: any
  advancedVisualisationsError: boolean
  secondaryValues: any
  draftMessages: any[] | null
  updatedMappMessage: any | null
  shouldCloseMappDrawer: boolean
  brazeCampaigns: any[]
  brazeResponse: any
  maxVariantOpenRate: number
  midList: MidObject[] | undefined
  epsilonBusinessUnits: BusinessUnit[] | undefined
  epsilonSeedList: EpsilonSeedList[] | undefined
  epsilonFolders: FolderUnit[] | undefined
  epsilonTimeZone: string | undefined
  epsilonCampaigns: CampaignUnit[] | undefined
  epsilonSequences: CampaignUnit[] | undefined
  sfmcFormSelectedItems: string[]
  inputResultsAt?: string
  variantsToBeApprovedExperimentsFlow: string[]
  variantsToBeApproved: string[]
  teamsList: Team[] | null
  isDetailsPanelExpanded: boolean
  acousticIntegration?: {
    emailTemplate: string
    suppressionsLists: SuppressionList[]
    mailingParameter: string | null
    sendDateTime: string
    waitTime: number
    sendOptimization: SendOptimization
    isAutoDeploy: boolean
  }
  dynamicOptimizationResultType: undefined | DynamicOptimizationResultType
}

export interface ErrorObject {
  message?: string
  status?: string
  data?: any
}
export interface MidObject {
  name: string
  mid: string
}
export interface Project {
  _id: string
  name: string
}

export interface SplitSizeData {
  is_full_test: boolean
  is_head_to_head: boolean
  split_number: number
  split_size: number
  total_split_audience: string
  visible?: boolean
  headline: string
  split_result: string
  show_other_split_options: boolean
  other_split_options: any[]
  campaign_data?: CampaignData
  warning?: string
}

interface CampaignDataValue {
  true_value?: number
  adjusted_value?: number
}

interface CampaignDataRate extends CampaignDataValue {
  type: 'open_rate' | 'click_rate'
}

interface CampaignDataSplitCalculation {
  number_of_splits: number
  split_size: number
}

export interface CampaignData {
  expected_audience_size: CampaignDataValue
  expected_engagement_rates: CampaignDataRate[]
  split_calculation: CampaignDataSplitCalculation
}

export interface SetupStepPayload {
  data: {
    campaign_name: string
    human_control: string
    estimated_audience_number?: number
    average_engagement_rate?: number
    project_id: string
    split_number?: number
    split_size?: number
    campaign_id?: string
  }

  save_and_continue: boolean
  save_and_exit: boolean
  save_and_stay: boolean
  step: CampaignNavigationSteps
}

//  ENUM

export enum CampaignNavigationSteps {
  setup = 'setup',
  language_generation = 'language_generation',
  content_generation = 'content_generation',
  content_generation_llm = 'content_generation_llm',
  language_approval = 'language_approval',
  summary = 'summary',
  insights = 'insights',
}

export enum DistributionChannelType {
  email = 'email',
  push_notification = 'push_notification',
  SMS = 'SMS',
  landing_page = 'landing_page',
  facebook = 'facebook',
  adwords = 'adwords',
  display = 'display',
  content_card = 'content_card',
  in_app_message = 'in_app_message',
  web_push = 'web_push',
}

export enum InputContentType {
  text = 'text',
  textArea = 'text_area',
  number = 'number',
  float = 'float',
  email = 'email',
  dropdown = 'dropdown',
  date = 'date',
}

export interface UserInput {
  isUserInput: boolean
  chosenStep: CampaignNavigationSteps | string
}

export interface Step {
  step: string
  finished: boolean
  active: boolean
  disabled: boolean
  isUserInput: boolean
}

export interface CampaignSetupUiPayback {
  conditional_fields: InputElement[]
  split_calculation_required_fields: string[]
  campaign_name_limit?: number
  human_control: IHumanControl
  split_calculator_version?: number
}

export interface IHumanControl {
  display: boolean
  is_optional: boolean
  integrationType?: string
}

export enum Key {
  Backspace = 8,
  Tab = 9,
  Enter = 13,
  Shift = 16,
  Ctrl = 17,
  Alt = 18,
  PauseBreak = 19,
  CapsLock = 20,
  Escape = 27,
  Space = 32,
  PageUp = 33,
  PageDown = 34,
  End = 35,
  Home = 36,

  LeftArrow = 37,
  UpArrow = 38,
  RightArrow = 39,
  DownArrow = 40,

  Insert = 45,
  Delete = 46,

  Zero = 48,
  ClosedParen = Zero,
  One = 49,
  ExclamationMark = One,
  Two = 50,
  AtSign = Two,
  Three = 51,
  PoundSign = Three,
  Hash = PoundSign,
  Four = 52,
  DollarSign = Four,
  Five = 53,
  PercentSign = Five,
  Six = 54,
  Caret = Six,
  Hat = Caret,
  Seven = 55,
  Ampersand = Seven,
  Eight = 56,
  Star = Eight,
  Asterik = Star,
  Nine = 57,
  OpenParen = Nine,

  A = 65,
  B = 66,
  C = 67,
  D = 68,
  E = 69,
  F = 70,
  G = 71,
  H = 72,
  I = 73,
  J = 74,
  K = 75,
  L = 76,
  M = 77,
  N = 78,
  O = 79,
  P = 80,
  Q = 81,
  R = 82,
  S = 83,
  T = 84,
  U = 85,
  V = 86,
  W = 87,
  X = 88,
  Y = 89,
  Z = 90,

  LeftWindowKey = 91,
  RightWindowKey = 92,
  SelectKey = 93,

  Numpad0 = 96,
  Numpad1 = 97,
  Numpad2 = 98,
  Numpad3 = 99,
  Numpad4 = 100,
  Numpad5 = 101,
  Numpad6 = 102,
  Numpad7 = 103,
  Numpad8 = 104,
  Numpad9 = 105,

  Multiply = 106,
  Add = 107,
  Subtract = 109,
  DecimalPoint = 110,
  Divide = 111,

  F1 = 112,
  F2 = 113,
  F3 = 114,
  F4 = 115,
  F5 = 116,
  F6 = 117,
  F7 = 118,
  F8 = 119,
  F9 = 120,
  F10 = 121,
  F11 = 122,
  F12 = 123,

  NumLock = 144,
  ScrollLock = 145,

  SemiColon = 186,
  Equals = 187,
  Comma = 188,
  Dash = 189,
  Period = 190,
  UnderScore = Dash,
  PlusSign = Equals,
  ForwardSlash = 191,
  Tilde = 192,
  GraveAccent = Tilde,

  OpenBracket = 219,
  ClosedBracket = 221,
  Quote = 222,
}

export interface InputState {
  message: string
  isInError: boolean
  value: any
}

export interface AdvancedVisualisationsDataResult {
  name: string
  data: number[][]
  id: string
  variant_status?: string | undefined
}

export interface AdvancedVisualisationsData {
  results: AdvancedVisualisationsDataResult[]
  average?: number[][]
  selectedRows?: any
  title?: string
  variantColorMapping?: any
}

export interface EpsilonCreateSplitsPayload {
  epsilon_campaign_id?: string
  business_unit_id: string
}

export interface EpsilonInitializeReactCampaignPayload {
  epsilon_campaign_id: string
  folder_id: string
  business_unit_id: string
  epsilon_sequence_id: string
  frequency: string
  optimization_start_time: string
  optimization_end_time: string
}

export interface EpsilonScheduleCampaignPayload {
  epsilon_campaign_id: string
  business_unit_id: string
  optimization_wait_in_seconds: number
  campaign_start_time: string
  campaign_end_time: string
  epsilon_seed_id: string
}

export interface EpsilonCancelCampaignPayload {
  business_unit_id: string
}

export interface EpsilonBusinessUnit {
  business_unit_id: string
  business_unit_name: string
}

export interface EpsilonFolder {
  epsilon_folder_id: string
  epsilon_folder_name: string
  brazeCampaigns: any[]
  brazeResponse: any
  maxVariantOpenRate: number
}
export interface FacebookResultsPayload {
  token: string
  campaign_id: string
  type: 'conversions' | 'clicks' | 'leads' | 'engagement'
}

export type DynamicOptimizationResultType = 'raw' | 'mature' | 'test'
export interface AdvancedVisualisationsDataParams {
  requestedGraphTemplate: VisualisationTemplates
  campaignId: string
  shouldResetState?: boolean
  resultType?: DynamicOptimizationResultType
}
interface ColorMappingObject {
  id: string
  color: string
}

export interface GraphsModuleProps {
  requestedGraphType: string
  requestedGraphTemplate: string
  sortedGraphList: string[]
  shouldEnableRestartButton: boolean
  handleSelectUiGraphChange: (...args: any[]) => void
  setCurrentIndex: (...args: any[]) => void
  renderAdvancedVisualisationGraph: (...args: any[]) => JSX.Element | undefined
  requestedGraphOrdinal: number
  variantColorMapping: Record<string, ColorMappingObject>
  className?: string
}
