import { FC } from 'react'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import { contentPath, unifiedFlowPaths } from 'app/navigation/paths'
import { unifiedFlowRoute } from 'app/navigation/topNavStaticRoutes'
import { useFlags } from 'launchdarkly-react-client-sdk'
import ErrorStaticComponent from 'workflow/common/MessageComponent'

import ErrorBoundary from 'common/components/ErrorBoundary'
import Auth0Provider from 'auth/Auth0Provider'
import ProtectedRoute from 'auth/ProtectedRoute'
import LogoutSso from 'features/auth/components/LogoutSso'
import SfmcLoginCallback from 'features/auth/sfmc-auth/sfmc-auth.callback'
import SfmcLogin from 'features/auth/sfmc-auth/sfmc-auth.login'

import ExpiredPassword from '../../auth/ExpiredPassword'
import Error from '../Error'
import {
  accountsRoute,
  adminRoute,
  campaignsRoute,
  dashboardRoute,
  demoRoute,
  futuramaContentRoute,
  homeRoute,
  profileRoute,
  projectsRoute,
  reportsRoute,
} from '../navigation/staticRoutes'

import CustomSwitch from './Switch'
const DPFE_CAMPAIGNS_PATH = '/campaign/:campaignId?'
const PREVIOUS_CAMPAIGNS_PATH = '/campaigns/:campaignId?'

export const NotFound = () => (
  <ErrorStaticComponent isPageNotFound isErrorStaticPage />
)
const UnAuthorized = () => (
  <ErrorStaticComponent isPageUnauthorized isErrorStaticPage />
)

// Subcomponent that is used with <MemoryRouter /> in tests
export const RouterContent: FC = () => {
  const flags = useFlags()

  return (
    <ErrorBoundary FallbackComponent={Error}>
      <Auth0Provider>
        <CustomSwitch>
          {/* TODO: figure out a way to map staticRoutes without unmounting App */}
          {flags.showHomePage ? (
            <ProtectedRoute
              path={homeRoute.path}
              exact={homeRoute.isExact}
              component={homeRoute.component}
              accessLevel={homeRoute.accessLevel}
              accessPermission={homeRoute.accessPermission}
            />
          ) : (
            <ProtectedRoute
              path={dashboardRoute.path}
              exact={dashboardRoute.isExact}
              component={dashboardRoute.component}
              accessLevel={dashboardRoute.accessLevel}
              accessPermission={dashboardRoute.accessPermission}
            />
          )}
          <ProtectedRoute
            path={accountsRoute.path}
            component={accountsRoute.component}
            accessLevel={accountsRoute.accessLevel}
            accessPermission={accountsRoute.accessPermission}
            accessFlags={accountsRoute.accessFlags?.flags}
            denyFlags={accountsRoute.denyFlags?.flags}
            exact={accountsRoute.isExact}
            shouldValidateAccountAccess={
              accountsRoute.shouldValidateAccountAccess
            }
          />
          <ProtectedRoute
            path={[
              DPFE_CAMPAIGNS_PATH,
              PREVIOUS_CAMPAIGNS_PATH,
              ...campaignsRoute.path,
            ]}
            component={campaignsRoute.component}
            accessLevel={campaignsRoute.accessLevel}
            accessPermission={campaignsRoute.accessPermission}
            shouldValidateAccountAccess={
              campaignsRoute.shouldValidateAccountAccess
            }
          />
          <ProtectedRoute
            path={projectsRoute.path}
            component={projectsRoute.component}
            accessPermission={projectsRoute.accessPermission}
            accessFlags={projectsRoute.accessFlags?.flags}
            denyFlags={projectsRoute.denyFlags?.flags}
            shouldValidateAccountAccess={
              projectsRoute.shouldValidateAccountAccess
            }
          />
          <Redirect exact from="/login" to="/" />
          {flags.showUnifiedFlow && (
            <Redirect
              from={`${contentPath}/*`}
              to={`${unifiedFlowPaths.home}/*`}
            />
          )}
          <ProtectedRoute
            path={reportsRoute.path}
            component={reportsRoute.component}
            accessLevel={reportsRoute.accessLevel}
            accessPermission={reportsRoute.accessPermission}
            accessFlags={reportsRoute.accessFlags?.flags}
            denyFlags={reportsRoute.denyFlags?.flags}
            shouldValidateAccountAccess={
              reportsRoute.shouldValidateAccountAccess
            }
          />
          <ProtectedRoute
            exact={profileRoute.isExact}
            path={profileRoute.path}
            component={profileRoute.component}
            accessLevel={profileRoute.accessLevel}
            accessPermission={profileRoute.accessPermission}
            accessFlags={profileRoute.accessFlags?.flags}
            denyFlags={profileRoute.denyFlags?.flags}
          />
          <ProtectedRoute
            path={adminRoute.path}
            component={adminRoute.component}
            accessLevel={adminRoute.accessLevel}
            accessPermission={adminRoute.accessPermission}
            accessFlags={adminRoute.accessFlags?.flags}
            denyFlags={adminRoute.denyFlags?.flags}
            shouldValidateAccountAccess={adminRoute.shouldValidateAccountAccess}
          />
          <ProtectedRoute
            path={futuramaContentRoute.path}
            component={futuramaContentRoute.component}
            accessLevel={futuramaContentRoute.accessLevel}
            accessPermission={futuramaContentRoute.accessPermission}
            accessFlags={futuramaContentRoute.accessFlags?.flags}
            denyFlags={futuramaContentRoute.denyFlags?.flags}
            shouldValidateAccountAccess={
              futuramaContentRoute.shouldValidateAccountAccess
            }
          />
          <ProtectedRoute
            path={unifiedFlowRoute.path}
            component={unifiedFlowRoute.component}
            accessLevel={unifiedFlowRoute.accessLevel}
            accessPermission={unifiedFlowRoute.accessPermission}
            accessFlags={unifiedFlowRoute.accessFlags?.flags}
            denyFlags={unifiedFlowRoute.denyFlags?.flags}
            shouldValidateAccountAccess={
              unifiedFlowRoute.shouldValidateAccountAccess
            }
          />
          <ProtectedRoute
            path={demoRoute.path}
            component={demoRoute.component}
            accessLevel={demoRoute.accessLevel}
            accessPermission={demoRoute.accessPermission}
            accessFlags={demoRoute.accessFlags?.flags}
            denyFlags={demoRoute.denyFlags?.flags}
          />
          <ProtectedRoute
            exact
            path="/sfmc-login"
            component={SfmcLogin}
            onlyDisplayComponent={true}
          />
          <ProtectedRoute
            exact
            path="/sfmc-callback"
            component={SfmcLoginCallback}
            onlyDisplayComponent={true}
          />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/401" component={UnAuthorized} />
          <Route exact path="/logout-sso" component={LogoutSso} />
          <Route exact path="/expired-password" component={ExpiredPassword} />
        </CustomSwitch>
      </Auth0Provider>
    </ErrorBoundary>
  )
}

const RouterComponent: FC = () => (
  <Router
    getUserConfirmation={() => {
      /* Empty callback to block the default browser prompt */
    }}
  >
    <RouterContent />
  </Router>
)

export default RouterComponent
