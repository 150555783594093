import { connectInternalApi } from 'common/api'

type BrandsResponse = {
  name: string
  description: string
  value: string
}[]

type ChannelsResponse = {
  label: string
  value: string
}[]

type PersonasResponse = {
  id: string
  age: number
  location: string
  loyalty: string
  name: string
}[]

export type VariantsQueryParams = {
  prevVariantsIds: string[]
  brand: string
  channel: string
  persona: string
}

export const fetchBrands = async (): Promise<BrandsResponse> => {
  return Promise.resolve([
    {
      name: 'True Agile',
      description:
        "The leader in youthful fashion, True Agile is at the forefront of all of the latest trends. Heavy lead by TikTok and and the hottest influencers, the brand offers a wide range of styles at affordable prices. True Agile's customers range in age and demographic but they always stay true to their youthful and current tone of voice.",
      value: 'retail',
    },
    {
      name: 'Delve',
      description:
        'A specialist in high-end luxury experiences. Delve sells to loyal customers that value a top tier experience with a focus on amenities. Their tone of voice is elevated and refined with restrictions on the usage of emojis, symbols, colloquialisms or hashtags.',
      value: 'travel',
    },
    {
      name: 'SwiftBite',
      description:
        'The number one for convenience, SwiftBite delivers pretty much anything to your door in the quickest amount of time. They recognise the breadth of their customer base and have opted for a tone of voice that is inclusive for all.',
      value: 'food delivery',
    },
  ])
}

export const fetchChannels = async (): Promise<ChannelsResponse> => {
  return Promise.resolve([
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Push',
      value: 'push',
    },
  ])
}

export const fetchPersonas = async (): Promise<PersonasResponse> => {
  return Promise.resolve([
    {
      id: '1',
      age: 21,
      location: 'London, UK',
      loyalty: 'Gold',
      name: 'Profile A',
    },
    {
      id: '2',
      age: 38,
      location: 'Los Angeles, US',
      loyalty: 'Silver',
      name: 'Profile B',
    },
    {
      id: '3',
      age: 46,
      location: 'Edinburgh, UK',
      loyalty: 'Gold',
      name: 'Profile C',
    },
    {
      id: '4',
      age: 30,
      location: 'New York, US',
      loyalty: 'Silver',
      name: 'Profile D',
    },
  ])
}

type VariantsResponse = {
  variantId: string
  brand: string
  genericVariant: string
  personalisedVariant: string
  attributes: { [key: string]: string }[]
}[]

export const fetchVariants = async ({
  accountId,
  queryParams,
}: {
  accountId: string
  queryParams: VariantsQueryParams
}): Promise<VariantsResponse> => {
  const { brand, channel, persona, prevVariantsIds } = queryParams

  const prevVariantsIdsQueryString = prevVariantsIds
    .map((id) => `prevVariantIds=${id}`)
    .join('&')

  const result = await connectInternalApi.get(
    `v1/accounts/${accountId}/personalization/demo/variants?${prevVariantsIdsQueryString}`,
    {
      params: {
        brand,
        channel,
        persona_id: persona,
      },
    }
  )

  return result.data
}
