import { FC } from 'react'
import cx from 'classnames'

type Props = {
  title: string
  value: string | number
  valueClassName: string
}

const Cell: FC<Props> = ({ title, value, valueClassName }) => (
  <div className="grid gap-y-3 grid-rows-2 w-full justify-between items-start border-r border-gold-400 last:border-r-0 pr-6">
    <span className="flex self-center  text-base-700 font-normal  text-xs sm:text-sm md:text-base">
      {title}
    </span>
    <div
      className={cx(
        'font-relais font-medium text-left leading-none text-sm sm:text-xl md:text-10.5',
        valueClassName
      )}
    >
      {value.toLocaleString('en')}
    </div>
  </div>
)

export default Cell
