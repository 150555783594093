import { DistributionChannel as CampaignDistributionChannel } from '@phrasee/phrasee-typings/Graphql/interfaces'
import cx from 'classnames'

import { formatDistributionChannel } from 'common/distributionChannelUtils'
import { DistributionChannel as ProjectDistributionChannel } from 'common/interfaces/projects'

interface Props {
  value: ProjectDistributionChannel | CampaignDistributionChannel
  'data-cy'?: string
  'data-testid'?: string
  className?: string
}

const DistributionChannel = ({
  value,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  className,
}: Props) => {
  return (
    <div
      data-cy={dataCy}
      data-testid={dataTestId}
      className={cx('inline-block capitalize', className)}
    >
      {formatDistributionChannel(value)}
    </div>
  )
}

export default DistributionChannel
