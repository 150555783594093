import { connectInternalApi } from 'common/api'
import { regionalReportingApis } from 'common/api'
import { TreeNode } from 'common/components/catalog/Catalog'
import { PersonalizationRegion } from 'common/components/personalizationReport/api'

export interface PersonalizationResponse {
  _id: string
  name: string
  useCase: string
  channels: string[]
  status: string
  startDate: string
  endDate: string
  optimisationId: string
  accountId: string
  control: { id: string; text: string }
  trainingContent?: 'contextual' | 'personalized'
}

type FilterItem = {
  displayName: string
  value: string
}

export type DateAttribute = {
  name: string
  type: 'date'
  startDate: string
  endDate: string
}

export type NumberAttribute = {
  name: string
  type: 'number'
  min: number
  max: number
}

export type ListAttribute = {
  name: string
  type: 'list'
  values: string[]
}

export type CustomerAttributes = (
  | DateAttribute
  | NumberAttribute
  | ListAttribute
)[]

type FiltersResponse = {
  useCases: FilterItem[]
  productCategories: TreeNode
  customerAttributes: CustomerAttributes
  channels: FilterItem[]
}

export type GraphsResponse = { displayName: string; value: number }[]

interface BaseVariant {
  variantText: string
  channel: string
  sends: number
}

export interface EmailVariant extends BaseVariant {
  clicks: number
  opens: number
}

export interface PushVariant extends BaseVariant {
  totalOpens: number
  directOpens: number
}

export type Variant = EmailVariant | PushVariant

export type VariantsData = {
  count: number
  variants: Variant[]
}

export async function fetchActivePersonalizedCampaigns(accountId: string) {
  const result = await connectInternalApi.get<PersonalizationResponse[]>(
    `v1/accounts/${accountId}/personalization`
  )

  return result.data
}

export const fetchFilters = async ({
  accountId,
  personalizationRegion,
}: {
  accountId: string
  personalizationRegion?: PersonalizationRegion
}) => {
  const result = personalizationRegion
    ? await regionalReportingApis[personalizationRegion].get<FiltersResponse>(
        `/accounts/${accountId}/personalization/filters`
      )
    : await connectInternalApi.get<FiltersResponse>(
        `v1/accounts/${accountId}/personalization/filters`
      )

  return result.data
}
