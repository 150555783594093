import { useEffect } from 'react'
import { Field } from 'react-final-form'

import { DropdownType } from 'common/components/dynamicFields/interfaces'
import SingleSelect from 'common/components/singleSelect'
import { useAppDispatch } from 'common/hooks/redux'
import { updateTemplateSplitConfiguration } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { TemplateInfoWithInternalId } from '../../addedTemplates/AddedComponentList'
import { getPrefixedName } from '../../helpers'

import CalculatorCard from './components/CalculatorCard'

const DEFAULT_OPTIONS = [
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
]

type Props = {
  initialValue?: number
  options?: DropdownType['options']
  template?: TemplateInfoWithInternalId
  isDisabled?: boolean
}

const VariantDropdown = ({
  template,
  isDisabled,
  initialValue = 10,
  options = DEFAULT_OPTIONS,
}: Props) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (template?.internalId) {
      dispatch(
        updateTemplateSplitConfiguration({
          internalId: template.internalId,
          splitConfiguration: {
            type: 'variant_dropdown',
          },
        })
      )
    }
  }, [dispatch, template?.internalId])

  return (
    <CalculatorCard className="items-center justify-between">
      <div className="text-xs font-normal text-base-700 mr-6">
        Select the number of variants you want to test:
      </div>
      <Field<number>
        initialValue={initialValue}
        name={getPrefixedName('splitNumber', template)}
      >
        {({ input }) => (
          <SingleSelect
            className="w-32"
            data-cy="split-number-select"
            data-testid="split-number-select"
            id={input.name}
            name={input.name}
            value={String(input.value)}
            onChange={(selectValue) =>
              input.onChange(Number(selectValue?.value))
            }
            onBlur={input.onBlur}
            options={options}
            menuPortalTarget={document.body}
            isDisabled={isDisabled}
          />
        )}
      </Field>
    </CalculatorCard>
  )
}

export default VariantDropdown
