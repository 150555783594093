import CenterPanel from '../../components/layout/CenterPanel'
import { useSelectedComponentElement } from '../../hooks'

import IntegrationSection from './IntegrationSection'
import OptimizeView from './OptimizeView'
import PersonalizeView from './PersonalizeView'

type Props = {
  showOptimizeView: boolean
  showPersonalizationView: boolean
}

const CenterSection = ({
  showOptimizeView,
  showPersonalizationView,
}: Props) => {
  const { data: selectedElement } = useSelectedComponentElement()
  return (
    <CenterPanel>
      {showOptimizeView ? (
        <OptimizeView />
      ) : showPersonalizationView ? (
        <PersonalizeView key={selectedElement?.element_id} />
      ) : (
        <IntegrationSection />
      )}
    </CenterPanel>
  )
}

export default CenterSection
