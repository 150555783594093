/* eslint-disable max-lines */

import React, { ChangeEvent, Component } from 'react'
import { Input } from 'antd'
import cx from 'classnames'
import { isEqual } from 'lodash'
import ControlInput from 'workflow/common/controlInput'

import Button from 'common/components/button'
import Modal from 'common/components/Modal'
import RadioGroup from 'common/components/radioGroup'
import { stringToNumber } from 'common/helpers/numeric'
import { Edit, Switch } from 'common/icons'
import { IconType } from 'common/icons/Icon'
import {
  replaceLineBreakAsNewLineEmoji,
  replaceNewLineEmojiAsElement,
} from 'common/variantsUtils'

import Loader from '../../common/components/loaders/Loader'
import { Key } from '../interface'

import { CampaignType } from './LanguageApproval'

const { TextArea } = Input

interface Props {
  tweakingLine: any
  showNFM: boolean
  tweakLine: Function
  closeTweakLine: any
  tweakingLoading: boolean
  rejectLineLoading: boolean
  rejectLine: Function
  feedbackSettings: any
  campaignType: CampaignType
  newlinesInVariants: {
    min: number
    max: number
  }
}

interface SelectedLine {
  old_sl_id: string
  sort_order: any
  text: string
  text_before_tweak: string
  api_id: string
  grammar_nfm_used: any
  ownsl: string
  _id: string
}

interface State {
  nfmReason: number
  nfmReasonDetails: string
  selectedLine: SelectedLine
  nfmNoReasonDetails: boolean
  variantText: string
  feedbackSettings: any
  showTweakingReason: boolean
  noTweakingReason: boolean
  tweakingReason: string
  showNfmModal: boolean
  whyDislike: any
  selectedState: 'replace' | 'edit'
}

const getInitialState = (props: Props): State => ({
  nfmReason: 1,
  nfmReasonDetails: '',
  nfmNoReasonDetails: false,
  variantText: '',
  feedbackSettings: '',
  selectedLine: {
    old_sl_id: '',
    sort_order: 0,
    text: '',
    text_before_tweak: '',
    api_id: '',
    grammar_nfm_used: '',
    ownsl: '',
    _id: '',
  },
  showTweakingReason: false,
  noTweakingReason: false,
  tweakingReason: '',
  showNfmModal: false,
  whyDislike: {
    1: 'Against our brand voice',
    2: 'Legal or compliance issue',
  },
  selectedState: props.feedbackSettings?.reject?.enabled ? 'replace' : 'edit',
})

class TweakVariant extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = getInitialState(props)
  }

  componentDidUpdate(prevProps: Props) {
    const { tweakingLine, showNFM } = this.props
    const { tweakingLine: prevTweakingLine, showNFM: prevShowNFM } = prevProps
    if (!isEqual(tweakingLine, prevTweakingLine) || showNFM !== prevShowNFM) {
      this.setState({
        variantText: tweakingLine.text,
        selectedLine: tweakingLine,
        showNfmModal: showNFM,
      })
      if (!showNFM) {
        this.setState(getInitialState(this.props))
      }
    }
  }

  componentWillUnmount() {
    // FIXME wonder why the state is set for a component that unmount, needs investigation
    this.setState(getInitialState(this.props))
  }

  nfmReasonDetails = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value.trim() !== '') {
      this.setState({
        nfmReasonDetails: event.target.value,
        nfmNoReasonDetails: false,
      })
    } else {
      this.setState({
        nfmReasonDetails: event.target.value,
        nfmNoReasonDetails: true,
      })
    }
  }

  rejectLine = () => {
    const { nfmReason, nfmReasonDetails, selectedLine, whyDislike } = this.state
    const { rejectLine, feedbackSettings, campaignType } = this.props
    if (nfmReasonDetails.trim() === '') {
      this.setState({ nfmNoReasonDetails: true })
    } else {
      let whyDislikeReason = whyDislike[nfmReason]
      if (nfmReason === 1 || nfmReason === 2) {
        whyDislikeReason = `${whyDislikeReason} - ${nfmReasonDetails}`
      }
      const data: any = {
        line_id: selectedLine._id,
        api_id: selectedLine.api_id,
        sort_order: selectedLine.sort_order || 0,
        grammar_nfm_used: selectedLine.grammar_nfm_used || 0,
        why_dislike: whyDislikeReason,
      }
      if (feedbackSettings.reject.feedback_only) {
        data.feedbackOnly = true
      }
      rejectLine(data, campaignType)
    }
  }

  tweakingLine = (value: string, originalValue: string) => {
    const { selectedLine } = this.state
    const showTweakingReason = selectedLine.text.trim() !== originalValue.trim()

    this.setState({
      variantText: value,
      showTweakingReason,
    })
  }

  tweakingReasonChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const reason = event.target.value
    if (reason.trim() !== '') {
      this.setState({ tweakingReason: reason, noTweakingReason: false })
    } else {
      this.setState({ tweakingReason: '', noTweakingReason: true })
    }
  }

  tweakLine = () => {
    const { tweakingReason, selectedLine, variantText, showTweakingReason } =
      this.state
    const { tweakLine } = this.props

    if (tweakingReason.trim() !== '') {
      const data = {
        line_id: selectedLine._id,
        text: variantText,
        text_before_tweak: selectedLine.text,
        old_sl_id: selectedLine.api_id,
        old_sl_own: selectedLine.ownsl || false,
        sort_order: selectedLine.sort_order || 0,
        why_tweak: tweakingReason,
      }
      tweakLine(data)
    } else if (tweakingReason.trim() === '' && showTweakingReason) {
      this.setState({ noTweakingReason: true })
    } else {
      this.setState({ showNfmModal: false })
    }
  }

  render() {
    const {
      showNfmModal,
      nfmReasonDetails,
      noTweakingReason,
      nfmNoReasonDetails,
      variantText,
      showTweakingReason,
      tweakingReason,
      selectedState,
    } = this.state
    const {
      closeTweakLine,
      tweakingLoading,
      rejectLineLoading,
      newlinesInVariants,
      feedbackSettings,
    } = this.props

    const changeReasonPlaceholder =
      'Explain as best as possible so we can learn and give better results in the future.'

    const feedbackEditEnabled = feedbackSettings?.edit?.enabled
    const feedbackRejectEnabled = feedbackSettings?.reject?.enabled
    return (
      <Modal
        visible={showNfmModal}
        centered
        maskClosable
        closable={false}
        width={398}
        footer={null}
      >
        <Loader isLoading={tweakingLoading}>
          <div className="min-w-86">
            <div>
              <h2 className="text-coolgray-700 font-medium">
                Request a change to:
              </h2>
              <p className="px-4 py-4 bg-maroon-40 text-maroon-400 font-medium border border-maroon-400">
                {replaceNewLineEmojiAsElement(variantText)}
              </p>
              <div>
                <p className="text-coolGray-700 font-medium mt-6 text-sm mb-1">
                  What would you like to do?
                </p>

                <div className="flex">
                  {feedbackRejectEnabled && (
                    <Item
                      className={cx({
                        ' mr-6': feedbackEditEnabled,
                      })}
                      onClick={() =>
                        this.setState({ selectedState: 'replace' })
                      }
                      icon={Switch}
                      title="Replace"
                      isSelected={selectedState === 'replace'}
                    />
                  )}
                  {feedbackEditEnabled && (
                    <Item
                      onClick={() => this.setState({ selectedState: 'edit' })}
                      icon={Edit}
                      title="Edit"
                      isSelected={selectedState === 'edit'}
                    />
                  )}
                </div>
              </div>
            </div>
            {feedbackRejectEnabled && selectedState === 'replace' && (
              <div className="column-first nfmmodalsection">
                <React.Fragment>
                  <p className="mt-6 mb-1 text-coolGray-700">
                    Please tell us why
                  </p>
                  <TextArea
                    data-cy="textarea-reason"
                    data-testid="replace-reason-textarea"
                    placeholder={changeReasonPlaceholder}
                    onChange={this.nfmReasonDetails}
                    value={nfmReasonDetails}
                    onPressEnter={(e): void => {
                      const numberOfBreakLine = (
                        (e.target as HTMLTextAreaElement).value.match(/\n/g) ||
                        []
                      ).length
                      const maxBreakLine = newlinesInVariants?.max
                      if (
                        maxBreakLine !== undefined &&
                        e.keyCode === Key.Enter &&
                        numberOfBreakLine >= maxBreakLine
                      ) {
                        // prevent default behavior
                        e.preventDefault()
                      }
                    }}
                    rows={4}
                  />
                  {nfmNoReasonDetails ? (
                    <div className="text-red-400">Please give us a reason</div>
                  ) : undefined}
                  <RadioGroup
                    className="mt-6 font-medium"
                    name="reason_selection"
                    value={this.state.nfmReason.toString()}
                    options={[
                      {
                        label: 'Against our brand voice',
                        value: '1',
                        'data-cy': 'replace-radio-brand-voice',
                        'data-testid': 'replace-radio-brand-voice',
                      },
                      {
                        label: 'Legal or compliance issue',
                        value: '2',
                        'data-cy': 'replace-radio-legal',
                        'data-testid': 'replace-radio-legal',
                      },
                    ]}
                    direction="vertical"
                    onChange={(value) =>
                      this.setState({ nfmReason: stringToNumber(value) || 0 })
                    }
                  />
                </React.Fragment>
              </div>
            )}
            {feedbackEditEnabled && selectedState === 'edit' && (
              <>
                <div>
                  <React.Fragment>
                    <div className="text-coolGray-700 mt-6 text-sm mb-1">
                      <p className="mb-1">Make your edits</p>
                      <ControlInput
                        data-cy="textarea-edited-line"
                        data-testid="edited-line-textarea"
                        onBlur={() =>
                          this.setState({
                            variantText:
                              replaceLineBreakAsNewLineEmoji(variantText),
                          })
                        }
                        value={variantText}
                        onChange={this.tweakingLine}
                        maxRows={newlinesInVariants?.max}
                        rows={5}
                      />
                    </div>
                    <div className="mt-6">
                      <p className="mb-1 font-medium">Please tell us why</p>
                      <TextArea
                        data-cy="textarea-reason-test"
                        data-testid="edit-reason-textarea"
                        onChange={this.tweakingReasonChange}
                        value={tweakingReason}
                        placeholder={changeReasonPlaceholder}
                        rows={4}
                      />
                      {showTweakingReason && noTweakingReason ? (
                        <div className="text-red-400">
                          Please give us a reason
                        </div>
                      ) : undefined}
                    </div>
                  </React.Fragment>
                </div>
              </>
            )}
            <div className="mt-12 flex justify-end">
              <Button
                ghost
                onClick={() => {
                  closeTweakLine()
                  this.setState({ showNfmModal: false })
                }}
                className="mr-4"
                data-cy="tweek-variant-cancel-button"
                data-testid="tweek-variant-cancel-button"
              >
                Cancel
              </Button>
              {selectedState === 'replace' ? (
                <Button
                  data-cy="save-one"
                  data-testid="save-one"
                  variant="primary"
                  loading={rejectLineLoading}
                  disabled={rejectLineLoading}
                  onClick={this.rejectLine}
                >
                  Replace
                </Button>
              ) : (
                <Button
                  data-cy="save-two"
                  data-testid="save-two"
                  variant="primary"
                  loading={tweakingLoading}
                  disabled={tweakingLoading}
                  onClick={this.tweakLine}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        </Loader>
      </Modal>
    )
  }
}

function Item({
  onClick,
  title,
  icon: Icon,
  isSelected,
  className,
}: {
  onClick: () => void
  title: string
  icon: IconType
  isSelected: boolean
  className?: string
}) {
  return (
    <div
      data-cy={`${title.toLocaleLowerCase()}-variant-button`}
      data-testid={`${title.toLocaleLowerCase()}-variant-button`}
      style={{ height: 114 }}
      className={cx(
        `flex-1 flex flex-col border group font-medium
         items-center relative cursor-pointer`,
        className,
        {
          'border-maroon-400 text-maroon-200 relative': isSelected,
          'border-coolGray-200 hover:border-maroon-200 hover:text-maroon-200':
            !isSelected,
        }
      )}
      onClick={onClick}
    >
      {isSelected && (
        <div className="absolute w-4 h-4 bg-maroon-200 rounded-full right-1 top-1 flex justify-center items-center">
          <div
            className=" bg-white rounded-full"
            style={{ width: 6, height: 6 }}
          />
        </div>
      )}
      <div className="mt-4">
        <span className="group-hover:hidden">
          <Icon state={isSelected ? 'selected' : 'default'} size={8} />
        </span>
        <span className="hidden group-hover:inline-block">
          <Icon state="selected" size={8} />
        </span>
      </div>
      <div className="absolute bottom-6">{title}</div>
    </div>
  )
}

export default TweakVariant
