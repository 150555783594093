import BMF from 'browser-md5-file'

import type { FileUploaderFileType } from 'common/components/fileUploader'

const hexToBase64 = (md5Hash) => {
  return btoa(
    md5Hash
      .match(/\w{2}/g)
      .map((a) => {
        return String.fromCharCode(parseInt(a, 16))
      })
      .join('')
  )
}

export const calculateMD5Base64 = (
  file: FileUploaderFileType['file']
): Promise<string> => {
  const bmf = new BMF()

  return new Promise((resolve, reject) => {
    bmf.md5(file, (err, md5Hash: string) => {
      if (err) {
        reject(err)
      } else {
        resolve(hexToBase64(md5Hash))
      }
    })
  })
}
