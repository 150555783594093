import { MouseEventHandler, ReactNode } from 'react'
import cx from 'classnames'

interface ButtonProps {
  children: ReactNode
  onClick: MouseEventHandler<HTMLButtonElement>
  className?: string
  disabled?: boolean
  'data-cy'?: string
  'data-testid'?: string
  'aria-label'?: string
}

/**
 * @deprecated TODO: use Button component
 */
const Button = ({
  children,
  onClick,
  className,
  disabled,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      data-cy={dataCy}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      className={cx('flex items-center p-3 opacity-50', className)}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
