/* eslint-disable max-lines */

import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Form } from 'antd'
import * as Drawer from 'app/IntegrationDrawer'
import { isEqual } from 'lodash'
import type { Moment } from 'moment'
import moment from 'moment-timezone'
import { RESET_CHEETAH_STATE } from 'redux/actionTypes'

import ActionIcon from 'common/components/ActionIcon'
import Alert from 'common/components/alert'
import Button from 'common/components/button/Button'
import CampaignConfirmationModal from 'common/components/CampaignConfirmationModal'
import Card from 'common/components/Card'
import CheckboxComponent from 'common/components/checkbox'
import DatePicker from 'common/components/datePicker'
import GuidingText from 'common/components/guidingText'
import Input from 'common/components/input'
import Loader from 'common/components/loaders/Loader'
import SingleSelect from 'common/components/singleSelect'
import TimePicker from 'common/components/timePicker'
import Tooltip from 'common/components/Tooltip'
import {
  Close as CloseIcon,
  Download as DownloadIcon,
  Time as TimeIcon,
  TimeZone as TimeZoneIcon,
} from 'common/icons'

import {
  CheetahCampaign,
  CheetahPatchPayload,
  CheetahRetrieveResultsPayload,
  InputState,
} from '../../../interface'
import {
  AsyncThunkDispatch,
  downloadTXTFile,
  fetchCampaignData,
  fetchCheetahCampaign,
  fetchSingleCheetahCampaign,
  loadResultsDetails,
  resetActionsStates,
  retrieveCheetahCampaignResults,
  retrieveCheetahCampaignTimeZone,
  updateCheetahCampaign,
} from '../../../Workflow.actions'
import HelpError from '../HelpError'
import SuccessButton from '../SuccessButton'

import styles from '../Integrations.module.css'

const FormItem = Form.Item
const LABEL_TEXT = {
  campaign: 'Select an existing campaign with Cells and Splits:',
  startDateTime: 'Expected send date and time ',
  checkboxText: 'Show only latest 50 campaigns',
  manuelIdInput: 'Enter my Cheetah Ref ID',
}

const SAVED_STATE_TIMEOUT = 2000

const TOOLTIP_TEXT = {
  campaign:
    'Select an existing campaign with the correct number of Cells and Splits.',
  startDate: 'Experiment Start Date',
  startTime: 'Experiment Start Time',
  endDate: `This will be the day/time that Jacquard pulls your results.
  This date should be a day or more after your send date.`,
  endTime: 'Experiment End Time',
}

interface CheetahIntegrationState {
  [key: string]: any
  isLoading: boolean
  campaign: InputState
  campaignStartDate: InputState
  campaignStartTime: InputState
  campaignEndDate: InputState
  campaignEndTime: InputState
  isCampaignScheduled: boolean
  showSavedMessage: boolean
  campaignsCheckbox: boolean
  showManualId: boolean
}

export interface Props {
  integrationData: any
  campaignData: any
  isLoadingSchedule: boolean
  userId: string
  projectId: string
  currentCampaignId: string
  cheetahCampaigns: CheetahCampaign[]
  cheetahTimeZone: string
  timeZone: string
  error?: string
  resetCheetahState: () => void
  fetchCampaignData: (campaignId: string) => Promise<void>
  fetchCheetahCampaign: (
    projectId: string,
    page?: number,
    count?: number
  ) => (dispatch: Function) => void
  fetchSingleCheetahCampaign: (
    campaignId: string,
    projectId: string
  ) => (dispatch: Function) => void
  updateCheetahCampaign: (
    campaignId: string,
    payload: CheetahPatchPayload,
    callback: () => void
  ) => (dispatch: Function) => void
  retrieveCheetahCampaignTimeZone: (
    campaignId: string,
    projectId: string
  ) => (dispatch: Function) => void
  closeDrawer: () => () => void
  retrieveCheetahCampaignResults: (
    campaignId: string,
    payload: CheetahRetrieveResultsPayload,
    closeDrawer: () => () => void
  ) => (dispatch: Function) => any
  loadResultsDetails: (campaignId: string) => void
  downloadTXTFile: (campaignId: string) => void
  campaignName: string
  retrievingResults: boolean
  isFetchingCheetahCampaigns: boolean
  isFetchingSingleCheetahCampaign: boolean
  isLoadingDownload: boolean
  isFetchingCheetahTimezone: boolean
}

const DATE_DISPLAY_FORMAT = 'Y-MM-DD'
const initialState = {
  isLoading: true,
  showManualId: false,
  campaign: {
    value: null,
    isInError: false,
    message: 'Please select an existing campaign.',
  },
  campaignStartDate: {
    value: null,
    isInError: false,
    message: '',
  },
  campaignStartTime: {
    value: null,
    isInError: false,
    message: '',
  },
  campaignEndTime: {
    value: null,
    isInError: false,
    message: '',
  },
  campaignEndDate: {
    value: null,
    isInError: false,
    message: '',
  },
  isRetrieveCheetahResultsModalVisible: false,
  retrievingCheetahResults: false,
  cheetahCampaignName: '',
  manualSelectedCampaign: '',
  isCampaignScheduled: false,
  showSavedMessage: false,
  campaignsCheckbox: true,
}

export class CheetahInt extends Component<Props & {}, CheetahIntegrationState> {
  constructor(props: any) {
    super(props)
    this.state = initialState
  }

  componentDidMount(): void {
    const { integrationData, projectId, fetchSingleCheetahCampaign } =
      this.props
    const espCampaignId = integrationData?.esp_campaign_id
    if (espCampaignId && projectId) {
      fetchSingleCheetahCampaign(espCampaignId.toString(), projectId)
      this.autoSelectMatchingCampaign(espCampaignId)
    }
  }

  componentDidUpdate(prevProps: any): void {
    const {
      retrievingResults: prevPropsRetrievingResults,
      cheetahCampaigns: prevCheetahCampaigns,
      integrationData: prevIntegrationData,
    } = prevProps

    const {
      retrievingResults,
      loadResultsDetails,
      currentCampaignId,
      cheetahCampaigns,
      integrationData,
    } = this.props
    if (
      prevPropsRetrievingResults !== retrievingResults &&
      !retrievingResults
    ) {
      loadResultsDetails(currentCampaignId)
    }
    if (
      !isEqual(cheetahCampaigns, prevCheetahCampaigns) ||
      !isEqual(integrationData, prevIntegrationData) ||
      !isEqual(
        integrationData?.esp_campaign_id,
        prevIntegrationData?.esp_campaign_id
      )
    ) {
      const espCampaignId = integrationData?.esp_campaign_id
      this.autoSelectMatchingCampaign(espCampaignId)
    }
  }

  componentWillUnmount() {
    const { currentCampaignId, fetchCampaignData, resetCheetahState } =
      this.props
    resetCheetahState()
    this.setState(initialState)
    fetchCampaignData(currentCampaignId)
  }

  filterOption = (input: string, option: any) => {
    return (
      option.props.children.props.children
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    )
  }

  getHourToDisableFrom = (time) => {
    return time.minute() <= 45 ? time.hour() : time.hour() + 1
  }

  getDisabledStartDates = (datepickerValue: Moment | null) => {
    const { timeZone } = this.props
    const todayDate = moment().tz(timeZone)
    const datepickerDate = moment(datepickerValue)
    return moment(datepickerDate).isBefore(todayDate, 'day')
  }

  getDisabledEndDates = (datepickerValue: Moment | null) => {
    const { campaignStartDate } = this.state
    const firstPossibleEndDate = moment(campaignStartDate.value).add(1, 'day')
    const datepickerDate = moment(datepickerValue)

    return moment(datepickerDate).isBefore(firstPossibleEndDate, 'day')
  }

  getDisabledStartHours = () => {
    const campaignStartDate = moment(this.state.campaignStartDate.value)
    const { timeZone } = this.props

    // campaigns cannot be given a start time earlier than one hour from current time
    const currentDateInTimezone = moment().tz(timeZone).add(1, 'hour')

    if (!campaignStartDate.isSame(currentDateInTimezone, 'day')) {
      return []
    } else {
      return [...Array(this.getHourToDisableFrom(currentDateInTimezone)).keys()]
    }
  }

  getDisabledEndHours = () => {
    const { campaignEndDate, campaignStartTime, campaignStartDate } = this.state
    const campaignStartDateTime = moment(
      `${campaignStartDate.value} ${campaignStartTime.value}`
    )
    const campaignEndDateTime = moment(campaignEndDate.value)

    if (
      !campaignEndDateTime.isSame(campaignStartDateTime.add(1, 'day'), 'day')
    ) {
      return []
    } else {
      return [...Array(this.getHourToDisableFrom(campaignStartDateTime)).keys()]
    }
  }

  getDisabledStartMinutes = (selectedHour: number) => {
    const campaignStartDate = moment(this.state.campaignStartDate.value)
    const { timeZone } = this.props
    const currentDateTimeWithTimeZone = moment().tz(timeZone)

    if (
      !campaignStartDate.isSame(currentDateTimeWithTimeZone, 'day') ||
      selectedHour > currentDateTimeWithTimeZone.hour() + 1
    ) {
      return []
    }
    const currentMinute = currentDateTimeWithTimeZone.minute()
    return [...Array(currentMinute).keys()]
  }

  getDisabledEndMinutes = (selectedHour: number) => {
    const { campaignStartDate, campaignStartTime, campaignEndDate } = this.state

    const campaignStartDateTime = moment(
      `${campaignStartDate.value} ${campaignStartTime.value}`
    )
    const campaignEndDateTime = moment(campaignEndDate.value)

    if (
      !campaignEndDateTime.isSame(campaignStartDateTime.add(1, 'day'), 'day') ||
      selectedHour !== campaignStartDateTime.hour()
    ) {
      return []
    }
    const campaignStartTimeMinute = campaignStartDateTime.minute()
    return [...Array(campaignStartTimeMinute).keys()]
  }

  isFormValid = () => {
    const {
      campaign,
      campaignStartDate,
      campaignStartTime,
      campaignEndDate,
      campaignEndTime,
    } = this.state
    const { timeZone } = this.props
    if (!campaign.value) {
      this.setState({
        campaign: {
          ...this.state.campaign,
          isInError: true,
        },
      })
      return false
    }
    if (
      !campaignStartDate.value ||
      campaignStartDate.value === 'Invalid date'
    ) {
      this.setState({
        campaignStartDate: {
          ...this.state.campaignStartDate,
          message: 'Please select an expected send date.',
          isInError: true,
        },
      })
      return false
    }
    this.setState({
      campaignStartDate: {
        ...this.state.campaignStartDate,
        message: '',
        isInError: false,
      },
    })
    if (
      !campaignStartTime.value ||
      campaignStartTime.value === 'Invalid date'
    ) {
      this.setState({
        campaignStartTime: {
          ...this.state.campaignStartTime,
          message: 'Please select an expected send time.',
          isInError: true,
        },
      })
      return false
    }
    this.setState({
      campaignStartTime: {
        ...this.state.campaignStartTime,
        message: '',
        isInError: false,
      },
    })
    if (!campaignEndDate.value || campaignEndDate.value === 'Invalid date') {
      this.setState({
        campaignEndDate: {
          ...this.state.campaignEndDate,
          message: 'Please select experiment end date.',
          isInError: true,
        },
      })
      return false
    }
    this.setState({
      campaignEndDate: {
        ...this.state.campaignEndDate,
        message: '',
        isInError: false,
      },
    })
    if (!campaignEndTime.value || campaignEndTime.value === 'Invalid date') {
      this.setState({
        campaignEndTime: {
          ...this.state.campaignEndTime,
          message: 'Please select experiment end time.',
          isInError: true,
        },
      })
      return false
    }
    this.setState({
      campaignEndTime: {
        ...this.state.campaignEndTime,
        message: '',
        isInError: false,
      },
    })
    const startTimeArray = campaignStartTime.value.split(':')
    const endTimeArray = campaignEndTime.value.split(':')
    const campaignStartDateTime = moment(campaignStartDate.value).set({
      hours: startTimeArray[0],
      minutes: startTimeArray[1],
    })
    const campaignEndDateTime = moment(campaignEndDate.value).set({
      hours: endTimeArray[0],
      minutes: endTimeArray[1],
    })

    if (
      campaignStartDateTime.format() <
      moment().add(1, 'hours').tz(timeZone).format()
    ) {
      this.setState({
        campaignStartTime: {
          ...this.state.campaignStartTime,
          isInError: true,
          message: 'This time is not available',
        },
      })
      return false
    }
    this.setState({
      campaignStartTime: {
        ...this.state.campaignStartTime,
        isInError: false,
        message: '',
      },
    })
    if (
      campaignEndDateTime.format() <
      campaignStartDateTime.add(1, 'days').format()
    ) {
      this.setState({
        campaignEndTime: {
          ...this.state.campaignEndTime,
          isInError: true,
          message: 'This time is not available',
        },
      })
      return false
    }
    this.setState({
      campaignEndTime: {
        ...this.state.campaignEndTime,
        isInError: false,
        message: '',
      },
    })
    return true
  }

  retrieveResultsClickHandler = () => {
    const { campaign } = this.state

    if (!campaign.value) {
      this.setState({
        campaign: {
          ...this.state.campaign,
          isInError: true,
        },
      })
      return false
    }

    this.setState({
      isRetrieveCheetahResultsModalVisible: true,
    })
    return true
  }

  handleRetrieveCheetahResultsModalOk = () => {
    const { retrieveCheetahCampaignResults, closeDrawer, currentCampaignId } =
      this.props
    const { campaign } = this.state
    retrieveCheetahCampaignResults(
      currentCampaignId,
      {
        espCampaignId: campaign.value,
      },
      closeDrawer
    )
  }

  handleRetrieveCheetahResultsModalCancel = () => {
    this.setState({
      isRetrieveCheetahResultsModalVisible: false,
    })
  }

  downloadTXTFile = () => {
    const { currentCampaignId, downloadTXTFile } = this.props
    downloadTXTFile(currentCampaignId)
  }

  onChangeUpdateState = (value: any, type: string): void => {
    const { [type]: unMutedState } = this.state
    this.setState({
      [type]: {
        ...unMutedState,
        value,
        isInError: false,
      },
    })
    // update Cheetah campaign name if that was updated
    if (type === 'campaign') {
      const { cheetahCampaigns } = this.props
      const selectedCheetahCampaign = cheetahCampaigns.find(
        (item) => item.ref_id.toString() === value
      )
      const cheetahCampaignName = selectedCheetahCampaign?.display_name || ''

      if (cheetahCampaignName) {
        this.setState({
          cheetahCampaignName,
        })
      } else {
        this.setState({
          manualSelectedCampaign: value,
        })
      }
    }
  }

  getCampaignStartDate = (startTime, timeZone) => {
    return startTime ? moment(startTime).tz(timeZone) : moment().tz(timeZone)
  }

  getCampaignEndDate = (endTime, timeZone) => {
    return endTime
      ? moment(endTime).tz(timeZone)
      : moment().add(1, 'days').tz(timeZone)
  }

  getCampaignStartTime = (startTime, timeZone) => {
    return startTime
      ? `${moment(startTime).tz(timeZone).hour()}:${moment(startTime)
          .tz(timeZone)
          .minutes()}`
      : `${moment().tz(timeZone).hour()}:${moment().tz(timeZone).minutes()}`
  }

  getCampaignEndTime = (endTime, timeZone) => {
    return endTime
      ? `${moment(endTime).tz(timeZone).hour()}:${moment(endTime)
          .tz(timeZone)
          .minutes()}`
      : `${moment().add(1, 'days').tz(timeZone).hour()}:${moment()
          .add(1, 'days')
          .tz(timeZone)
          .minutes()}`
  }

  autoSelectMatchingCampaign = (espCampaignId: string) => {
    const {
      cheetahCampaigns,
      campaignData: { campaign_data },
      timeZone,
    } = this.props
    const selectedCheetahCampaign = cheetahCampaigns.find(
      (item) => item.ref_id.toString() === espCampaignId
    )
    const startTime = campaign_data?.optimisation_start_time
    const endTime = campaign_data?.optimisation_end_time
    const cheetahCampaignName = selectedCheetahCampaign?.display_name || ''
    if (timeZone) {
      if (startTime && endTime && cheetahCampaignName) {
        this.setState({ isCampaignScheduled: true })
      }
      this.setState((state) => {
        return {
          campaignStartDate: {
            ...state.campaignStartDate,
            value: this.getCampaignStartDate(startTime, timeZone),
          },
          campaignStartTime: {
            ...state.campaignStartTime,
            value: this.getCampaignStartTime(startTime, timeZone),
          },
          campaignEndDate: {
            ...state.camp,
            value: this.getCampaignEndDate(endTime, timeZone),
          },
          campaignEndTime: {
            ...state.campaignEndTime,
            value: this.getCampaignEndTime(endTime, timeZone),
          },
          campaign: {
            ...state.campaign,
            value: espCampaignId,
          },
          cheetahCampaignName,
        }
      })
    }
  }

  clickHandler(): any {
    const {
      updateCheetahCampaign,
      currentCampaignId,
      closeDrawer,
      cheetahTimeZone,
      timeZone,
    } = this.props
    const {
      campaign,
      campaignStartDate,
      campaignStartTime,
      campaignEndDate,
      campaignEndTime,
    } = this.state
    if (this.isFormValid()) {
      const startTimeArray = campaignStartTime.value.split(':')
      const endTimeArray = campaignEndTime.value.split(':')

      const utcOffset = moment(campaignStartDate.value, DATE_DISPLAY_FORMAT)
        .tz(timeZone)
        .utcOffset()

      const formattedStartDate = moment(campaignStartDate.value).format(
        'YYYY-MM-DD'
      )
      const formattedEndDate = moment(campaignEndDate.value).format(
        'YYYY-MM-DD'
      )

      const campaignStartTimeInTimeZone = moment(
        `${formattedStartDate} ${startTimeArray[0]}:${startTimeArray[1]}`
      ).utcOffset(utcOffset, true)

      const campaignEndTimeInTimeZone = moment(
        `${formattedEndDate} ${endTimeArray[0]}:${endTimeArray[1]}`
      ).utcOffset(utcOffset, true)

      const optimizationStartTime = campaignStartTimeInTimeZone.local()

      const optimizationEndTime = campaignEndTimeInTimeZone.local()

      return updateCheetahCampaign(
        currentCampaignId,
        {
          optimisation_start_time: optimizationStartTime.format(),
          cheetah_campaign_id: campaign.value,
          campaign_end_time: optimizationEndTime.format(),
          espTimeZone: cheetahTimeZone,
          timeZone,
        },
        () => {
          this.setState({ isCampaignScheduled: true, showSavedMessage: true })
          setTimeout(() => {
            this.setState({ showSavedMessage: false })
            closeDrawer()
          }, SAVED_STATE_TIMEOUT)
        }
      )
    }
    return true
  }

  handleCheckboxChange = () => {
    const { campaignsCheckbox } = this.state
    const { projectId, fetchCheetahCampaign } = this.props
    if (campaignsCheckbox) {
      fetchCheetahCampaign(projectId)
    }
    if (!campaignsCheckbox) {
      fetchCheetahCampaign(projectId, 1, 50)
    }
    this.setState({ campaignsCheckbox: !campaignsCheckbox })
  }

  onCampaignSelectFocus = () => {
    const {
      integrationData,
      projectId,
      retrieveCheetahCampaignTimeZone,
      fetchCheetahCampaign,
      cheetahCampaigns,
    } = this.props
    const espCampaignId = integrationData?.esp_campaign_id

    if (projectId && !cheetahCampaigns?.length) {
      fetchCheetahCampaign(projectId, 1, 50)
      if (espCampaignId) {
        retrieveCheetahCampaignTimeZone(espCampaignId.toString(), projectId)
        this.autoSelectMatchingCampaign(espCampaignId)
      }
    }
  }

  render() {
    const {
      campaign,
      campaignStartDate,
      campaignStartTime,
      campaignEndDate,
      campaignEndTime,
      isRetrieveCheetahResultsModalVisible,
      cheetahCampaignName,
      manualSelectedCampaign,
      campaignsCheckbox,
      showManualId,
      isCampaignScheduled,
      showSavedMessage,
    } = this.state
    const {
      cheetahCampaigns,
      isLoadingSchedule,
      projectId,
      timeZone,
      error,
      campaignName,
      retrievingResults,
      isFetchingCheetahCampaigns,
      isFetchingSingleCheetahCampaign,
      isLoadingDownload,
      integrationData,
      isFetchingCheetahTimezone,
      retrieveCheetahCampaignTimeZone,
    } = this.props

    const isCampaignFinished = moment().isAfter(campaignEndDate.value)
    const espTimeZone =
      integrationData?.esp_campaign_timezone || timeZone || null

    // take it from state
    const disableManualIdBtn = cheetahCampaignName !== ''
    const tooltipMessage = `Select an expected send date and time based on
    the campaign timezone: ${espTimeZone || '[TIMEZONE]'}. Jacquard will use
    this to determine when to attempt to pick up the results.`

    const cheetahCampaignRefId = cheetahCampaigns
      ?.find((campaign) => campaign.display_name === cheetahCampaignName)
      ?.ref_id?.toString()
    return (
      <>
        <Drawer.Content>
          <Form>
            <div className="text-coolGray-500 font-medium mb-4">
              Schedule your Jacquard experiment with your Cheetah account.
            </div>
            {isFetchingCheetahCampaigns ||
            isFetchingSingleCheetahCampaign ||
            isFetchingCheetahTimezone ? null : (
              <>
                {isCampaignScheduled && (
                  <Alert type="success" className="my-6 flex items-center">
                    This experiment is currently saved and receiving data from
                    Cheetah.
                  </Alert>
                )}
                <Card className="mb-6 flex flex-col items-start">
                  <div
                    data-testid="split-creation"
                    className="font-medium text-coolGray-800"
                  >
                    Create a split test in Cheetah
                  </div>
                  <GuidingText
                    className="mt-1"
                    text={`Download the file ${campaignName}.csv and then upload 
                  it to your Cheetah account to create the cells and splits.
                  You will need to do this before you can set up
                  a schedule with Jacquard.`}
                  />
                  <Button
                    data-cy="cheetha-download-button"
                    data-testid="export-file"
                    className="ant-btn-lg mt-4 self-end"
                    onClick={() => this.downloadTXTFile()}
                    loading={isLoadingDownload}
                    variant="primary"
                  >
                    <DownloadIcon className="mr-4" isDefaultColor={false} />
                    Download
                  </Button>
                </Card>
              </>
            )}
            <div
              data-testid="split-results"
              className="font-medium text-coolGray-700"
            >
              Set up results retrieval
            </div>
            <GuidingText
              className="mt-1"
              text={`Once your split test is created, set these
          options for automated results retrieval.`}
            />
            <Loader
              isLoading={
                isFetchingCheetahCampaigns ||
                isFetchingSingleCheetahCampaign ||
                isFetchingCheetahTimezone
              }
            >
              <FormItem
                help={
                  campaign.isInError ? (
                    <HelpError>{campaign.message}</HelpError>
                  ) : null
                }
                className="mt-6"
                label={LABEL_TEXT.campaign}
              >
                <Tooltip
                  overlay={TOOLTIP_TEXT.campaign}
                  trigger="hover"
                  placement="right"
                  overlayStyle={{ maxWidth: 226 }}
                >
                  <SingleSelect
                    isLoading={
                      isFetchingCheetahCampaigns ||
                      isFetchingSingleCheetahCampaign
                    }
                    isDisabled={showManualId}
                    isSearchable
                    value={!showManualId ? cheetahCampaignRefId : undefined}
                    data-testid="Select"
                    menuPortalTarget={document.body}
                    menuShouldBlockScroll={true}
                    placeholder="Select an unscheduled campaign"
                    onFocus={this.onCampaignSelectFocus}
                    onChange={(val) => {
                      if (val) {
                        this.onChangeUpdateState(
                          val.value.toString(),
                          'campaign'
                        )
                        retrieveCheetahCampaignTimeZone(
                          val.value.toString(),
                          projectId
                        )
                      }
                    }}
                    options={cheetahCampaigns?.map((campaign) => ({
                      label: campaign.display_name,
                      value: campaign.ref_id.toString(),
                    }))}
                  />
                </Tooltip>
              </FormItem>

              <FormItem>
                <div className="flex items-center whitespace-nowrap">
                  <CheckboxComponent
                    data-testid="campaigns-checkbox"
                    isChecked={campaignsCheckbox}
                    onChange={this.handleCheckboxChange}
                  />
                  <div className="text-coolGray-800 ml-1">
                    {LABEL_TEXT.checkboxText}
                  </div>
                </div>
              </FormItem>

              {!showManualId && (
                <div className="mb-6">
                  <Button
                    data-cy="cheetah-manual-id-button"
                    data-testid="manual-id-btn"
                    disabled={disableManualIdBtn}
                    onClick={() => {
                      this.setState({ showManualId: true })
                    }}
                  >
                    {LABEL_TEXT.manuelIdInput}
                  </Button>
                </div>
              )}
              {showManualId && (
                <div className="mb-4">
                  <p className="text-coolGray-800">
                    Only enter a campaign ID if you have not selected it from
                    the dropdown above{' '}
                  </p>
                  <div className="flex items-center mt-1">
                    <Input
                      data-testid="manual-id-input"
                      type="number"
                      variant="default"
                      style={{ width: '90%' }}
                      placeholder="Enter Cheetah Ref ID"
                      onBlur={(e) => {
                        const val = e.target.value.toString()
                        this.onChangeUpdateState(val, 'campaign')
                        fetchSingleCheetahCampaign(val, projectId)
                        retrieveCheetahCampaignTimeZone(val, projectId)
                      }}
                    />
                    <ActionIcon
                      className="ml-3"
                      onClick={(e) => this.setState({ showManualId: false })}
                    >
                      <CloseIcon size={4} />
                    </ActionIcon>
                  </div>
                </div>
              )}
              <Card>
                <div className="ant-col ant-form-item-label text-left">
                  <TimeZoneIcon className="mr-4 text-gray-400" />

                  <label>Account Timezone: {espTimeZone}</label>
                </div>
                <GuidingText
                  className="mt-1"
                  text={`Input your experiment time details below based 
              on your Cheetah Account timezone.`}
                />
                <div className="mt-6">
                  <FormItem
                    label="When will you start the Experiment?"
                    help={
                      campaignStartDate.isInError ||
                      campaignStartTime.isInError ? (
                        <HelpError>
                          {campaignStartDate.message ||
                            campaignStartTime.message}
                        </HelpError>
                      ) : null
                    }
                  >
                    <div className="flex gap-4">
                      <Tooltip
                        overlay={tooltipMessage}
                        trigger="hover"
                        placement="left"
                        overlayStyle={{ maxWidth: 226 }}
                      >
                        <DatePicker
                          id="date-picker"
                          numberOfMonths={1}
                          verticalHeight={372}
                          isTodayVisible={true}
                          showClearDate={false}
                          data-testid="startDatePicker"
                          ariaLabel="startDatePicker"
                          disabled={
                            isCampaignFinished ||
                            (!espTimeZone && !showManualId) ||
                            (!timeZone && !showManualId) ||
                            (!cheetahCampaignName && !showManualId)
                          }
                          date={
                            campaignStartDate.value
                              ? moment(
                                  campaignStartDate.value,
                                  DATE_DISPLAY_FORMAT
                                )
                              : null
                          }
                          isOutsideRange={(current: Moment | null): boolean =>
                            this.getDisabledStartDates(current)
                          }
                          onDateChange={(date: Moment | null) => {
                            if (date) {
                              this.onChangeUpdateState(
                                moment(date).format(DATE_DISPLAY_FORMAT),
                                'campaignStartDate'
                              )
                            }
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        overlay={tooltipMessage}
                        placement="right"
                        trigger="hover"
                        overlayStyle={{ maxWidth: 226 }}
                      >
                        <TimePicker
                          allowClear={false}
                          data-testid="startTimePicker"
                          disabled={
                            isCampaignFinished ||
                            (!espTimeZone && !showManualId) ||
                            (!timeZone && !showManualId) ||
                            (!cheetahCampaignName && !showManualId) ||
                            !campaignStartDate.value
                          }
                          disabledHours={() => this.getDisabledStartHours()}
                          disabledMinutes={(selectedHour) =>
                            this.getDisabledStartMinutes(selectedHour)
                          }
                          className={styles.integrationTimePicker}
                          suffixIcon={<TimeIcon size={6} />}
                          format="HH:mm"
                          minuteStep={15}
                          placeholder="Time"
                          onChange={(val: Moment) =>
                            this.onChangeUpdateState(
                              val ? moment(val, 'HH:mm').format('HH:mm') : val,
                              'campaignStartTime'
                            )
                          }
                          value={
                            campaignStartTime.value
                              ? moment(campaignStartTime.value, 'HH:mm')
                              : undefined
                          }
                        />
                      </Tooltip>
                    </div>
                  </FormItem>
                  <div className="text-coolGray-800 font-medium mb-1">
                    When would you like Jacquard to pull results of your
                    Experiment?
                  </div>
                  <FormItem
                    style={{ marginBottom: 0 }}
                    help={
                      campaignEndDate.isInError || campaignEndTime.isInError ? (
                        <HelpError>
                          {campaignEndDate.message || campaignEndTime.message}
                        </HelpError>
                      ) : null
                    }
                  >
                    <div className="flex gap-4">
                      <Tooltip
                        overlay={TOOLTIP_TEXT.endDate}
                        trigger="hover"
                        placement="left"
                        overlayStyle={{ maxWidth: 226 }}
                      >
                        <DatePicker
                          id="date-picker"
                          numberOfMonths={1}
                          verticalHeight={372}
                          isTodayVisible={true}
                          disabled={
                            isCampaignFinished ||
                            (!espTimeZone && !showManualId) ||
                            (!timeZone && !showManualId) ||
                            (!cheetahCampaignName && !showManualId) ||
                            !campaignStartDate.value ||
                            !campaignStartTime.value
                          }
                          showClearDate={false}
                          isOutsideRange={(current) =>
                            this.getDisabledEndDates(current)
                          }
                          ariaLabel="endDatePicker"
                          date={
                            campaignEndDate.value
                              ? moment(
                                  campaignEndDate.value,
                                  DATE_DISPLAY_FORMAT
                                )
                              : null
                          }
                          onDateChange={(date: Moment | null) => {
                            if (date) {
                              this.onChangeUpdateState(
                                moment(date).format(DATE_DISPLAY_FORMAT),
                                'campaignEndDate'
                              )
                            }
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        overlay={TOOLTIP_TEXT.endTime}
                        placement="right"
                        trigger="hover"
                        overlayStyle={{ maxWidth: 226 }}
                      >
                        <TimePicker
                          disabled={
                            isCampaignFinished ||
                            (!espTimeZone && !showManualId) ||
                            (!timeZone && !showManualId) ||
                            (!cheetahCampaignName && !showManualId) ||
                            !campaignStartDate.value ||
                            !campaignStartTime.value ||
                            !campaignEndDate.value
                          }
                          allowClear={false}
                          data-testid="endTimePicker"
                          disabledHours={() => this.getDisabledEndHours()}
                          disabledMinutes={(selectedHour) =>
                            this.getDisabledEndMinutes(selectedHour)
                          }
                          className={styles.integrationTimePicker}
                          suffixIcon={<TimeIcon size={6} />}
                          format="HH:mm"
                          minuteStep={15}
                          value={
                            campaignEndTime.value
                              ? moment(campaignEndTime.value, 'HH:mm')
                              : undefined
                          }
                          onChange={(val: Moment) =>
                            this.onChangeUpdateState(
                              val ? moment(val, 'HH:mm').format('HH:mm') : val,
                              'campaignEndTime'
                            )
                          }
                        />
                      </Tooltip>
                    </div>
                  </FormItem>
                </div>
              </Card>
              <div className="font-medium text-coolGray-700 mt-6">
                Scheduling
              </div>
              <GuidingText
                text="You need to set up scheduling in the Cheetah interface."
                className="mt-1 mb-6"
              />
              <Card className="flex flex-col items-start">
                <div className="font-medium text-coolGray-800">
                  Already sent this experiment?
                </div>
                <GuidingText
                  className="mt-1"
                  text="Select a campaign in the dropdown
            and pull the results manually."
                />
                <Button
                  data-cy="cheetah-retrieve-results-button"
                  data-testid="retrieve-results"
                  className="mt-6 ant-btn-lg self-end"
                  onClick={() => this.retrieveResultsClickHandler()}
                  variant="primary"
                  loading={retrievingResults}
                  disabled={
                    (showManualId && !manualSelectedCampaign) ||
                    (!showManualId && !espTimeZone) ||
                    (!showManualId && !timeZone)
                  }
                >
                  Retrieve results
                </Button>
              </Card>
            </Loader>
          </Form>
        </Drawer.Content>
        <Drawer.Footer error={error}>
          {showSavedMessage ? (
            <SuccessButton text="Saved" />
          ) : (
            <Button
              data-cy="cheetah-save-button"
              data-testid="Submit"
              onClick={() => this.clickHandler()}
              loading={isLoadingSchedule}
              variant="primary"
              disabled={
                (!espTimeZone && !showManualId) ||
                (!timeZone && !showManualId) ||
                !campaignStartDate.value ||
                !campaignStartTime.value ||
                !campaignEndDate.value ||
                !campaignEndTime.value
              }
            >
              {isLoadingSchedule ? 'Saving' : 'Save'}
            </Button>
          )}
        </Drawer.Footer>
        <CampaignConfirmationModal
          title="Retrieve Cheetah results"
          visible={isRetrieveCheetahResultsModalVisible}
          onOk={this.handleRetrieveCheetahResultsModalOk}
          onCancel={this.handleRetrieveCheetahResultsModalCancel}
          okText={retrievingResults ? 'Retrieving...' : 'OK'}
        >
          <p>You are importing results:</p>
          <p>
            From Cheetah campaign:{' '}
            <strong>{cheetahCampaignName || manualSelectedCampaign}</strong>
            <br />
            Into Jacquard experiment: <strong>{campaignName}</strong>
          </p>
          <p>Do you wish to proceed?</p>
        </CampaignConfirmationModal>
      </>
    )
  }
}

function mapStateToProps(state: any, ownsProps: any): any {
  const {
    projectId,
    currentCampaignId,
    cheetahCampaigns,
    isWaitingState,
    cheetahTimeZone,
    timeZone,
    campaignName,
    campaignData,
    scheduleCheetahError,
  } = state.campaignStates
  return {
    userId: state.authStates.user_id,
    isLoadingDownload:
      isWaitingState.isWaitingFor === 'download' && isWaitingState.isWaiting,
    isLoadingSchedule:
      isWaitingState.isWaitingFor === 'schedule' && isWaitingState.isWaiting,
    retrievingResults:
      isWaitingState.isWaitingFor === 'retrieveResults' &&
      isWaitingState.isWaiting,
    isFetchingCheetahCampaigns:
      isWaitingState.isWaitingFor === 'fetchingCheetahCampaigns' &&
      isWaitingState.isWaiting,
    isFetchingSingleCheetahCampaign:
      isWaitingState.isWaitingFor === 'fetchingSingleCheetahCampaign' &&
      isWaitingState.isWaiting,
    isFetchingCheetahTimezone:
      isWaitingState.isWaitingFor === 'retrieveTimeZone' &&
      isWaitingState.isWaiting,
    cheetahCampaigns,
    cheetahTimeZone,
    timeZone,
    projectId,
    currentCampaignId,
    closeDrawer: ownsProps.closeDrawer,
    campaignName,
    campaignData,
    integrationData: campaignData?.campaign_data?.integration_data,
    error: scheduleCheetahError,
  }
}

function mapDispatchToProps(dispatch: AsyncThunkDispatch) {
  return {
    resetCheetahState: () => {
      dispatch(resetActionsStates(RESET_CHEETAH_STATE))
    },
    fetchCampaignData: async (campaignId: string) => {
      await dispatch(fetchCampaignData(campaignId))
    },
    fetchCheetahCampaign: (
      projectId: string,
      page?: number,
      count?: number
    ) => {
      dispatch(fetchCheetahCampaign(projectId, page, count))
    },
    fetchSingleCheetahCampaign: (campaignId: string, projectId: string) => {
      dispatch(fetchSingleCheetahCampaign(campaignId, projectId))
    },
    updateCheetahCampaign: (
      campaignId: string,
      payload: CheetahPatchPayload,
      closeDrawer: () => () => void
    ) => {
      dispatch(updateCheetahCampaign(campaignId, payload, closeDrawer))
    },
    retrieveCheetahCampaignTimeZone: (
      campaignId: string,
      projectId: string
    ) => {
      dispatch(retrieveCheetahCampaignTimeZone(campaignId, projectId))
    },
    retrieveCheetahCampaignResults: (
      campaignId: string,
      payload: CheetahRetrieveResultsPayload,
      closeDrawer: () => () => void
    ) => {
      dispatch(retrieveCheetahCampaignResults(campaignId, payload, closeDrawer))
    },
    loadResultsDetails: (id: string) => dispatch(loadResultsDetails(id)),
    downloadTXTFile: (campaignId: string) =>
      dispatch(downloadTXTFile(campaignId)),
  }
}

const CheetahIntegration: any = withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    CheetahInt
  )
)

export default CheetahIntegration
