import { getStepStatus } from 'features/unifiedFlow/contentPage/helpers'
import { useContent } from 'features/unifiedFlow/contentPage/hooks'

import Brief from '../brief'
import Generate from '../generate'

const BriefAndGenerateManager = () => {
  const { content } = useContent()

  const generationStepsStatus = getStepStatus(
    content?.steps_status,
    'generation'
  )
  const showGenerateStep =
    generationStepsStatus === 'enabled' ||
    generationStepsStatus === 'active' ||
    generationStepsStatus === 'read-only'

  if (showGenerateStep) {
    return <Generate />
  } else {
    return <Brief />
  }
}

export default BriefAndGenerateManager
