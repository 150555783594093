import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { fetchContent, PersonalizationContentResponse, Region } from '../api'
import { personalizedContentKeys } from '../queryKeys'

export type PersonalizedContentItem = {
  campaignName: string
  accountName: string
  accountId: string
  personalizationId: string
  createdDate: string
  lastEditDate: string
  numberOfVariants: number
  status: string
  region: Region
}

const useGetPersonalizedContentQuery = ({
  isEnabled = true,
}: {
  isEnabled?: boolean
} = {}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return useQuery<
    PersonalizationContentResponse,
    unknown,
    PersonalizedContentItem[]
  >(personalizedContentKeys.all, fetchContent, {
    enabled: !!accountId && isEnabled,
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) =>
      data.map((item) => ({
        campaignName: item.name,
        accountName: item.account.name,
        accountId: item.account.id,
        personalizationId: item.id,
        createdDate: item.createdDate,
        lastEditDate: item.updatedDate,
        numberOfVariants: item.generatedVariants,
        status: item.status,
        region: item.region,
      })),
    meta: {
      errorMessage: 'The personalized content could not be loaded',
    },
  })
}

export default useGetPersonalizedContentQuery
