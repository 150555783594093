import { useEffect } from 'react'

import Button from 'common/components/button'
import Modal from 'common/components/Modal'

import useDeleteIntegrationMutation from '../../api/queries/useDeleteIntegrationMutation'

type Props = {
  onCancel: () => void
  onSuccessDelete: () => void
  accountId: string
  integrationId: string
}

const IntegrationsDeleteModal = ({
  onCancel,
  onSuccessDelete,
  accountId,
  integrationId,
}: Props) => {
  const deleteMutation = useDeleteIntegrationMutation(accountId)
  const handleDeleteIntegration = () => {
    deleteMutation.mutate({ id: integrationId })
  }

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      onSuccessDelete()
    }
  }, [onSuccessDelete, deleteMutation.isSuccess])

  return (
    <Modal closable={false} visible centered>
      <div className="px-6" data-testid="integrations-delete-modal">
        <div className="flex" data-testid="deleteConfirmationModal">
          <h2 className="text-sm mb-3 text-coolGray-800 font-medium">
            Do you want to remove this integration?
          </h2>
        </div>
        <div className="flex flex-wrap justify-start">
          <Button
            ghost
            size="large"
            key="cancel"
            className="min-w-40 mr-4 uppercase"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="large"
            className="min-w-40 uppercase"
            key="delete"
            data-testid="deleteConfirmationButton"
            loading={deleteMutation.isLoading}
            onClick={handleDeleteIntegration}
          >
            Yes, remove it
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default IntegrationsDeleteModal
