import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { checkExperimentName } from '../api'
import { contentKeys } from '../queryKeys'

const useCheckExperimentNameQuery = ({
  projectId,
  experimentName,
}: {
  projectId: string
  experimentName: string
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const checkExperimentNameQuery = useQuery(
    contentKeys.experimentName(accountId, projectId, experimentName),
    () =>
      checkExperimentName({
        accountId,
        projectId,
        experimentName,
      }),
    {
      staleTime: 1000 * 60 * 5,
      enabled: false,
      retry: false,
    }
  )

  return checkExperimentNameQuery
}

export default useCheckExperimentNameQuery
