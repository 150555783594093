import { useState } from 'react'

import Button from 'common/components/button'
import EmptyState from 'common/components/emptyState'
import HeaderBanner from 'common/components/headerBanner'
import PageContainer from 'common/components/PageContainer'
import { useDocumentTitle } from 'common/hooks/custom'
import { Add as AddIcon } from 'common/icons'

import PagesColumn from './components/PagesColumn'
import { MOCK_PAGES } from './interfaces'

export default function Dashboard() {
  const [selectedPage, setSelectedPage] = useState('1')
  const currentPage = MOCK_PAGES.find((page) => page.id === selectedPage)

  useDocumentTitle(`Reports - ${currentPage?.name} | Jacquard`)

  return (
    <>
      <HeaderBanner title={currentPage?.name} />
      <div className="flex gap-8 p-8">
        <PagesColumn
          selectedPage={selectedPage}
          onPageSelect={setSelectedPage}
        />
        <PageContainer>
          <EmptyState
            className="max-h-78"
            title="Your dashboard is empty."
            description="Add widget to display your data"
            action={
              <Button variant="primary" prefixIcon={<AddIcon size={3} />}>
                ADD WIDGETS
              </Button>
            }
          />
        </PageContainer>
      </div>
    </>
  )
}
