/* eslint-disable max-len */
/* eslint-disable max-lines */
import {
  DashboardMode,
  User,
} from '@phrasee/phrasee-typings/Graphql/interfaces'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { gql } from 'graphql-request'
import { RootState } from 'redux/store'
import { showBanner } from 'workflow/common/common.actions'
import apiUtil from 'workflow/utils/Api'

import { fetchGraphQL, restApi } from 'common/api'
import { connectInternalApi } from 'common/api/api'
import { clearTableMetadataCache } from 'common/components/table/Table'
import { errorToast } from 'common/components/toastNotification'
import { identifyUser } from 'common/services/Logger'
import { AvatarColor } from 'features/profile/AvatarColorPicker'

import { getParamFromPath } from '../../../common/auth/url'
import { NoAccountsError } from '../components/NoAccountsError'

export type Account = {
  id: string
  name: string
  account_category: AccountCategory
  dashboardMode: DashboardMode
  data_ingestion_base_url: string
}

type SfmcUserInfo = {
  timezone: {
    longName?: string
    shortName?: string
    offset: number
    dst: boolean
  }
  zoneinfo?: string
  email?: string
  sfmc_user_id?: string
  business_unit: number
  stack?: string
  linkedPhraseeUser?: boolean
}

export type AccountCategory = 'Starter' | 'Pro' | 'Enterprise'

export type UserType = 'hero' | 'superhero'

export interface AuthState {
  isLoading: boolean
  token: string
  firstName: string
  lastName: string
  user_id: string
  accountId: string
  accountCategory: AccountCategory
  dashboardMode?: DashboardMode
  isLoggedIn: boolean
  facebookProjectAssigned: boolean
  subjectlinesProjectAssigned: boolean
  pushNotificationProjectAssigned: boolean
  isIntercomVisible: boolean
  intercomInfo: {
    user_id?: string
    email?: string
    job_title?: string
    phonenumber?: string
    company?: {
      name: string
      industry?: string
      esp?: string
      company_id?: string
    }
    account_id?: string
    fbLinked?: boolean
    created?: string
  }
  userType: UserType | ''
  sfmcUserInfo: SfmcUserInfo | null
  passwordExpired: boolean
  loginLoading: boolean
  resetToken: string
  profile: { avatarColor: AvatarColor }
  updateProfileLoading: boolean
  sfmcLoginFailed: boolean | null
  permissions?: string[]
  accounts?: Account[]
}

interface UserData extends User {
  emailId: string
  account_id: string
  accountCategory: AccountCategory
  fname: string
  lname: string
  user_type: UserType
  user_id: string
  intercomInfo: any
  profile: {
    avatar_color: AvatarColor
  }
  accounts: Account[]
}

interface PostLoginResponse {
  caller: string
  status: number
  message: string
  code: number
  timestamp: string
  data: UserData
}

export const initialState: AuthState = {
  isLoading: true,
  token: '',
  firstName: '',
  lastName: '',
  user_id: '',
  accountId: getParamFromPath(window.location.pathname, 'accounts') || '',
  isLoggedIn: false,
  facebookProjectAssigned: false,
  subjectlinesProjectAssigned: false,
  pushNotificationProjectAssigned: false,
  accountCategory: 'Enterprise',
  dashboardMode: undefined,
  intercomInfo: {},
  isIntercomVisible: true,
  userType: '',
  sfmcUserInfo: null,
  passwordExpired: false,
  loginLoading: false,
  resetToken: '',
  profile: { avatarColor: 'blue' },
  updateProfileLoading: false,
  sfmcLoginFailed: null,
}

// TODO: remove because redundant, auth0 handles this for us
export const validatingLogin = createAsyncThunk(
  'auth/validatingLogin',
  async (
    {
      email,
      password,
      sfmcUserId,
      sfmcUserCollectionId,
    }: {
      email: string
      password: string
      sfmcUserId: string | undefined
      sfmcUserCollectionId: string | undefined
    },
    { dispatch }
  ) => {
    const requestBody = {
      email,
      password,
      ...(sfmcUserId && { sfmc_user_id: sfmcUserId }),
      ...(sfmcUserCollectionId && {
        sfmc_user_collection_id: sfmcUserCollectionId,
      }),
      lrIgnoreUrl: true,
    }
    return apiUtil(
      'auth/login',
      { method: 'POST', body: requestBody },
      { noRedirectOnUnauthorised: true }
    )
      .then((response) => {
        const { intercomInfo } = response.data
        // Company ID and company name are the minimum requirements to pass a company into Intercom
        // Not doing so causes errors (see https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects)
        if (intercomInfo.company && !intercomInfo.company.company_id) {
          intercomInfo.company.company_id = intercomInfo.company.name
            ? intercomInfo.company.name
            : '1'
        }

        identifyUser({
          userId: intercomInfo.user_id,
          accountId: intercomInfo.account_id,
          email: intercomInfo.email,
          firstName: response.data.fname,
          lastName: response.data.lname,
        })

        return response.data
      })
      .catch((error) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
        throw error
      })
  }
)

// TODO: remove when because redundant, auth0 handles this for us. Keep this until we adopt auth0 reset pwd flow.
export const submitForgotPassword = createAsyncThunk(
  'auth/submitForgotPassword',
  async ({ email }: { email: string }) => {
    const requestBody = {
      email,
    }
    return apiUtil(
      'auth/forgot-password',
      { method: 'POST', body: requestBody },
      { noRedirectOnUnauthorised: true }
    )
  }
)

// TODO: remove because redundant, auth0 handles this for us. Keep this until we adopt auth0 reset pwd flow.
export const submitResetPassword = createAsyncThunk(
  'auth/submitResetPassword',
  async (
    {
      newPassword,
      resetToken,
      redirectCallback,
    }: {
      newPassword: string
      resetToken: string
      redirectCallback: () => void
    },
    { dispatch }
  ) => {
    const requestBody = {
      newPassword,
      resetToken,
    }
    apiUtil(
      'auth/reset-password',
      { method: 'POST', body: requestBody },
      { noRedirectOnUnauthorised: true }
    )
      .then(() => {
        dispatch(
          showBanner({
            content:
              'Password successfully reset. Please login with your new password.',
            type: 'success',
          })
        )
        redirectCallback()
      })
      .catch((error) => {
        dispatch(
          showBanner({
            content: `An error occurred when resetting your password: ${error.message}`,
            type: 'error',
          })
        )
      })
  }
)

// TODO: remove because redundant, auth0 handles this for us. Keep this until we adopt auth0 reset pwd flow.
export const submitChangePassword = createAsyncThunk(
  'auth/submitChangePassword',
  async (
    {
      newPassword,
      token,
      redirectCallback,
    }: {
      newPassword: string
      token: string
      redirectCallback: () => void
    },
    { dispatch }
  ) => {
    const requestBody = {
      newPassword,
    }
    apiUtil(
      'auth/change-password',
      {
        method: 'POST',
        body: requestBody,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      { noRedirectOnUnauthorised: false }
    )
      .then(() => {
        dispatch(
          showBanner({
            content:
              'Password successfully changed. Please login with your new password.',
            type: 'success',
          })
        )
        redirectCallback()
      })
      .catch((error) => {
        dispatch(
          showBanner({
            content: `An error occurred when changing your password: ${error.message}`,
            type: 'error',
          })
        )
      })
  }
)

// TODO: remove because redundant, auth0 handles this for us. Keep until we change how we handle SFMC users.
export const validateToken = createAsyncThunk(
  'auth/validateToken',
  async (token: string) => {
    const requestBody = {
      token,
    }
    return apiUtil(
      'auth/validate_token',
      { method: 'POST', body: requestBody },
      { noRedirectOnUnauthorised: true }
    ).then((response) => {
      // Company ID and company name are the minimum requirements to pass a company into Intercom
      // Not doing so causes errors (see https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects)
      const { intercomInfo } = response.data
      if (intercomInfo.company && !intercomInfo.company.company_id) {
        intercomInfo.company.company_id = intercomInfo.company.name
          ? intercomInfo.company.name
          : '1'
      }

      identifyUser({
        userId: intercomInfo.user_id,
        accountId: intercomInfo.account_id,
        email: intercomInfo.email,
        firstName: response.data.fname,
        lastName: response.data.lname,
      })
      return response.data
    })
  }
)

const ACCOUNT_ID_KEY = 'selectedAccountId'

export const getCachedAccountId = () => localStorage.getItem(ACCOUNT_ID_KEY)

export const setCachedAccountId = (accountId: string) =>
  localStorage.setItem(ACCOUNT_ID_KEY, accountId)

export const clearCachedAccountId = () =>
  localStorage.removeItem(ACCOUNT_ID_KEY)

export const callbackOAuth = createAsyncThunk<
  UserData & {
    account_category: AccountCategory
    defaultAccountId: string
    sortedAccounts: Account[]
    dashboardMode: DashboardMode
  },
  void,
  { state: RootState }
>('auth/callbackOAuth', async (_, { getState }) => {
  const state = getState()
  const accountId = state.authStates.accountId
  const response: AxiosResponse<PostLoginResponse> = await restApi.get(
    'auth/post-login'
  )
  const userData: UserData = response.data?.data

  const sortedAccounts = userData?.accounts.sort((a, b) =>
    a.name.localeCompare(b.name)
  )
  const defaultMultiAccountId =
    userData.accounts.find((account) => account.id === getCachedAccountId())
      ?.id ?? sortedAccounts[0].id

  const defaultAccountId =
    (accountId || defaultMultiAccountId) ?? userData.account_id

  const {
    data: {
      data: { account_category, dashboardMode },
    },
  } = await connectInternalApi.get<AxiosResponse<Account>>(
    `v1/core/monorail/accounts/${defaultAccountId}`
  )

  identifyUser({
    userId: userData.user_id,
    accountId: defaultAccountId,
    email: userData.intercomInfo?.email,
    firstName: userData.fname,
    lastName: userData.lname,
  })

  return {
    ...userData,
    account_category,
    dashboardMode,
    defaultAccountId,
    sortedAccounts,
  }
})

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (
    {
      fname,
      lname,
      jobTitle,
      phoneNumber,
      avatarColor,
    }: {
      fname: string
      lname: string
      jobTitle: string
      phoneNumber: string
      avatarColor: AvatarColor
    },
    { dispatch }
  ) => {
    const variables = {
      userProfile: {
        fname,
        lname,
        job_title: jobTitle,
        phonenumber: phoneNumber,
        avatar_color: avatarColor,
      },
    }

    const query = gql`
      mutation updateUserProfile($userProfile: UpdateUserProfileRequest) {
        updateUserProfile(userProfile: $userProfile)
      }
    `

    return fetchGraphQL({
      query,
      variables,
    })
      .then(() => {
        return variables.userProfile
      })
      .catch((error) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))

        throw error
      })
  }
)

export const changeAccountId = createAsyncThunk(
  'auth/changeAccount',
  async (accountId: string, thunkAPI) => {
    clearTableMetadataCache()
    setCachedAccountId(accountId)

    const {
      data: {
        data: { account_category, dashboardMode },
      },
    } = await connectInternalApi.get<AxiosResponse<Account>>(
      `v1/core/monorail/accounts/${accountId}`,
      {}
    )
    return { account_category, dashboardMode, accountId }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state) {
      state.token = ''
      state.user_id = ''
      state.accountId = ''
      state.isLoggedIn = false
    },
    sfmcLoginFail(state) {
      state.isLoading = false
      state.sfmcLoginFailed = true
    },
    sfmcLoginSuccess(
      state,
      action: PayloadAction<{
        sfmc_user: SfmcUserInfo
      }>
    ) {
      const payload = action.payload
      state.sfmcUserInfo = payload.sfmc_user
      state.sfmcLoginFailed = false
    },
    showIntercom(state) {
      state.isIntercomVisible = true
    },
    hideIntercom(state) {
      state.isIntercomVisible = false
    },
    ldUserIdentified(state) {
      state.isLoading = false
    },
    setPermissions(state, action: PayloadAction<string[]>) {
      // TODO: Removing the .relation is wrong and has to be removed.
      // This is a temporary patch for HH-3690
      const formatted = action.payload.map((item) =>
        item === 'list:projects.relation' ? item : item.split('.')[0]
      )
      state.permissions = [...new Set(formatted)]
    },
    selectAccountId(state, action: PayloadAction<Account['id']>) {
      state.accountId = action.payload
    },
    resetCategoryTypeAccount: (
      state,
      action: PayloadAction<AccountCategory>
    ) => {
      state.accountCategory = action.payload
    },
    setDashboardMode: (state, action: PayloadAction<DashboardMode>) => {
      state.dashboardMode = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, (state, action) => {
      state.dashboardMode = action.payload.dashboardMode
      state.accountCategory = action.payload.account_category
      state.accountId = action.payload.accountId
    })
    builder.addCase(validatingLogin.pending, (state) => {
      state.loginLoading = true
    })
    builder.addCase(validatingLogin.fulfilled, (state, action) => {
      const response = action.payload
      state.token = response.token
      state.firstName = response.fname
      state.lastName = response.lname
      state.userType = response.user_type
      state.passwordExpired = Boolean(response.password_expired)
      state.resetToken = response.reset_token
      state.facebookProjectAssigned = response.facebookProjectAssigned
      state.subjectlinesProjectAssigned = response.subjectlinesProjectAssigned
      state.pushNotificationProjectAssigned =
        response.pushNotificationProjectAssigned
      state.sfmcUserInfo = response.sfmcUserInfo
      state.intercomInfo = response.intercomInfo
      state.loginLoading = false
      state.isLoggedIn = true
      state.user_id = response.intercomInfo.user_id
      state.accountId = response.intercomInfo.account_id
      state.profile.avatarColor = response.profile?.avatar_color
    })
    builder.addCase(validateToken.fulfilled, (state, action) => {
      const response = action.payload

      state.token = response.token
      state.firstName = response.fname
      state.lastName = response.lname
      state.facebookProjectAssigned = response.facebookProjectAssigned
      state.subjectlinesProjectAssigned = response.subjectlinesProjectAssigned
      state.pushNotificationProjectAssigned =
        response.pushNotificationProjectAssigned
      state.userType = response.user_type
      state.sfmcUserInfo = response.sfmcUserInfo
      state.intercomInfo = response.intercomInfo
      state.isLoggedIn = true
      state.loginLoading = false
      state.isLoading = false
      state.user_id = response.intercomInfo.user_id
      state.accountId = response.intercomInfo.account_id
      state.passwordExpired = response.passwordExpired
      state.resetToken = response.resetToken
      if (response.profile?.avatar_color) {
        state.profile.avatarColor = response.profile.avatar_color
      }
    })
    builder.addCase(validatingLogin.rejected, (state) => {
      state.isLoggedIn = false
      state.loginLoading = false
    })
    builder.addCase(submitChangePassword.fulfilled, (state) => {
      state.token = ''
      state.user_id = ''
      state.accountId = ''
      state.isLoggedIn = false
    })
    builder.addCase(validateToken.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(validateToken.rejected, (state, action) => {
      state.isLoading = false
      state.isLoggedIn = false
    })
    builder.addCase(updateProfile.pending, (state) => {
      state.updateProfileLoading = true
    })
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.updateProfileLoading = false
    })
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.updateProfileLoading = false
      state.firstName = action.payload.fname
      state.lastName = action.payload.lname
      state.profile.avatarColor = action.payload.avatar_color
      state.intercomInfo.job_title = action.payload.job_title
      state.intercomInfo.phonenumber = action.payload.phonenumber
    })
    builder.addCase(callbackOAuth.fulfilled, (state, action) => {
      const {
        account_category,
        defaultAccountId,
        sortedAccounts,
        dashboardMode,
      } = action.payload

      state.firstName = action.payload?.fname
      state.lastName = action.payload?.lname
      state.userType = action.payload?.user_type
      state.user_id = action.payload?.user_id
      state.accountCategory = account_category
      if (!state.accountId) {
        state.accountId = defaultAccountId ?? sortedAccounts[0].id
      }
      if (!state.accountId) {
        errorToast(NoAccountsError, {
          closeButton: false,
          autoClose: false,
          closeOnClick: false,
        })
        state.isLoading = false
        return
      }

      state.intercomInfo = action.payload?.intercomInfo
      state.profile.avatarColor = action.payload?.profile?.avatar_color
      state.isLoggedIn = true
      state.loginLoading = false
      // Else loading will be set to false from LaunchDarklyIdentify component
      if (!action.payload?.intercomInfo?.email) {
        state.isLoading = false
      }
      state.accounts = sortedAccounts
      state.dashboardMode = dashboardMode
    })
  },
})

export const getSelectedAccountName = ({
  authStates,
}: RootState): string | undefined => {
  const selectedAccount = authStates.accounts?.find(
    (account) => account.id === authStates.accountId
  )

  return selectedAccount?.name
}

/*
 * Actions
 */
export const {
  logout,
  sfmcLoginFail,
  sfmcLoginSuccess,
  showIntercom,
  hideIntercom,
  setPermissions,
  selectAccountId,
  resetCategoryTypeAccount,
  ldUserIdentified,
  setDashboardMode,
} = authSlice.actions

export default authSlice.reducer
