import { useEffect, useState } from 'react'

import Modal from 'common/components/Modal'
import { Topic } from 'common/components/topics/interfaces'
import { useAppSelector } from 'common/hooks/redux'

import useGetReplacersQuery from '../../../../api/queries/useGetReplacersQuery'

import AddSpecifics from './steps/addSpecifics'
import SelectTopics from './steps/selectTopics'

const getNodeIds = (topics: Topic[]): string[] =>
  topics
    .filter(({ type }) => type === 'topic')
    .map(({ id }) => id.split('-')[1])

type Step = 'nodes' | 'replacers'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const AddTopicModal = ({ isOpen, onClose }: Props) => {
  const [currentStep, setCurrentStep] = useState<Step>('nodes')
  const [searchTerm, setSearchTerm] = useState<string>('')

  const currentTopics = useAppSelector((state) => state.topics.topics)

  const { data: replacers, isLoading } = useGetReplacersQuery({
    enabled: currentStep === 'nodes' && isOpen,
    nodeIds: getNodeIds(currentTopics),
  })

  useEffect(() => {
    if (currentStep === 'nodes' || isOpen) {
      setSearchTerm('')
    }
  }, [currentStep, isOpen])

  const onDoneClick = () => {
    if (replacers?.length) {
      setCurrentStep('replacers')
    } else {
      onClose()
    }
  }

  const resetState = () => {
    onClose()
    setCurrentStep('nodes')
    setSearchTerm('')
  }

  return (
    <Modal
      visible={isOpen}
      footer={null}
      size="large"
      destroyOnClose
      onClose={onClose}
    >
      {
        {
          nodes: (
            <SelectTopics
              searchTerm={searchTerm}
              onSearch={setSearchTerm}
              onDoneClick={onDoneClick}
            />
          ),
          replacers: (
            <AddSpecifics
              isLoading={isLoading}
              replacers={replacers}
              onPreviousClick={() => setCurrentStep('nodes')}
              onFinishedClick={resetState}
            />
          ),
        }[currentStep]
      }
    </Modal>
  )
}

export default AddTopicModal
