import { ChangeEvent, forwardRef } from 'react'
import cx from 'classnames'

import Button from 'common/components/button/Button'
import Input from 'common/components/input'

import { Edit } from '../../icons'

interface Props {
  value: string
  showIcon?: boolean
  shouldShowSaveButton?: boolean
  savedValue: string
  placeholder: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  onBlur: () => void
  setIsFocused?: (value: boolean) => void
  onKeyDown?: (event: React.KeyboardEvent) => void
  inputLabel?: string
  'data-cy'?: string
  'data-testid'?: string
  className?: string
  textColorClassName?: string
  iconClassName?: string
  fontSizeClassName?: string
  minLength?: number
  maxLength?: number
  onSaveClick?: () => void
}

const EditingValue = forwardRef<HTMLInputElement, Props>(
  (
    {
      value,
      savedValue,
      shouldShowSaveButton,
      placeholder,
      onChange,
      onBlur,
      onKeyDown,
      setIsFocused,
      inputLabel,
      'data-cy': dataCy,
      'data-testid': dataTestId,
      className,
      textColorClassName,
      fontSizeClassName,
      minLength,
      maxLength,
      iconClassName,
      showIcon,
      onSaveClick,
    },
    nameInputRef
  ) => {
    const fontSize = fontSizeClassName || 'text-2xl lg:text-3xl'

    return (
      <div className="flex items-center">
        {showIcon && (
          <Edit isDefaultColor={false} className={cx('mr-2', iconClassName)} />
        )}
        <Input
          data-cy={`${dataCy}-input`}
          data-testid={`${dataTestId}-input`}
          type="text"
          aria-label={inputLabel}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={() => setIsFocused && setIsFocused(true)}
          onBlur={onBlur}
          textColorClassName={
            textColorClassName || 'text-coolGray-800 placeholder-coolGray-800'
          }
          className={cx(`font-medium pl-0  ${fontSize} `, className, {
            'animate-slideInFromLeft': showIcon,
          })}
          maxLength={maxLength}
          minLength={minLength}
          ref={nameInputRef}
        />
        {shouldShowSaveButton && (
          <Button
            data-cy={`${dataCy}-save`}
            data-testid={`${dataTestId}-save`}
            onMouseDown={onSaveClick}
            type="submit"
            variant="primary"
            size="small"
            disabled={value === savedValue || !value.length}
          >
            Save
          </Button>
        )}
      </div>
    )
  }
)

export default EditingValue
