import { FC } from 'react'
import cx from 'classnames'

type Props = {
  value: number
  name: string
  className?: string
}

const Count: FC<Props> = ({ value, name, className }) => {
  return (
    <div
      data-cy={`${name}-count`}
      data-testid={`${name}-count`}
      className={cx('text-base font-bold capitalize', className)}
    >
      {`${value} ${value === 1 ? name : `${name}s`}`}
    </div>
  )
}

export default Count
