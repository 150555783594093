import { useMemo } from 'react'

import { getContentHasVariants, getElementHasVariants } from '../../helpers'
import { useContent, useSelectedElement } from '../../hooks'

const useCheckVariants = () => {
  const { content } = useContent()

  const { data: selectedElement } = useSelectedElement()

  // TODO: Check also the generated variants for optimise
  const hasVariants = useMemo(() => {
    if (selectedElement === undefined) {
      return getContentHasVariants(content)
    } else {
      return getElementHasVariants(selectedElement)
    }
  }, [content, selectedElement])

  return {
    hasVariants,
  }
}

export default useCheckVariants
