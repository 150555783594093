import { useEffect } from 'react'

import { useAppDispatch } from 'common/hooks/redux'

import { onResetState } from '../store/imagesSlice'

const useCleanImagesStateOnUnmount = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    return () => {
      dispatch(onResetState())
    }
  }, [dispatch])
}

export default useCleanImagesStateOnUnmount
