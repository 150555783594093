import moment from 'moment-timezone'

import Card from 'common/components/Card'
import FormItem from 'common/components/formItem'
import TimePicker from 'common/components/timePicker'
import {
  DataCollection as DataCollectionIcon,
  Time as TimeIcon,
} from 'common/icons'

import styles from 'workflow/CampaignSummary/IntegrationOptions/Integrations.module.css'

interface Props {
  isExperimentCreated: boolean
  championSendTimeHours: string | undefined
  championSendTimeMinutes: string | undefined
  updateChampionSendTimeHours: (value: string) => void
  updateChampionSendTimeMinutes: (value: string) => void
}

export default function ChampionSendCard({
  isExperimentCreated,
  championSendTimeHours,
  championSendTimeMinutes,
  updateChampionSendTimeHours,
  updateChampionSendTimeMinutes,
}: Props) {
  const disabledTimepickerHours = [
    0, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
  ]

  const getDisabledTimepickerMinutes = (championSendTimeHours) => {
    if (championSendTimeHours === '12') {
      return [...Array(60).keys()].slice(1)
    } else {
      return []
    }
  }

  return (
    <Card>
      <div className="flex text-lg items-center mb-4">
        <DataCollectionIcon />
        <div className="font-bold ml-4">Data collection</div>
      </div>
      <FormItem
        label="How long to wait before sending the winner?"
        className="error"
        guidingText="The time between sending the initial variants and sending the winning variant"
      >
        <div className="flex mb-3 space-x-4">
          <TimePicker
            disabled={isExperimentCreated}
            allowClear={false}
            format="HH"
            style={{ width: 'auto' }}
            data-testid="championHoursTestId"
            className={styles.integrationTimePicker}
            onChange={(val) =>
              updateChampionSendTimeHours(
                val && val.hours() ? val.hours().toString() : ''
              )
            }
            suffixIcon={<TimeIcon size={6} />}
            placeholder="Select hours"
            disabledHours={() => disabledTimepickerHours}
            hideDisabledOptions
            defaultValue={
              championSendTimeHours
                ? moment().set('hour', parseInt(championSendTimeHours))
                : undefined
            }
          />
          <TimePicker
            disabled={isExperimentCreated || !championSendTimeHours}
            allowClear={false}
            format="mm"
            minuteStep={5}
            style={{ width: 'auto' }}
            data-testid="championMinutesTestId"
            className={styles.integrationTimePicker}
            onChange={(val) =>
              updateChampionSendTimeMinutes(
                val && val.minutes() !== undefined
                  ? val.minutes().toString()
                  : ''
              )
            }
            suffixIcon={<TimeIcon size={6} />}
            placeholder="Select minutes"
            disabledMinutes={() =>
              getDisabledTimepickerMinutes(championSendTimeHours)
            }
            defaultValue={
              championSendTimeMinutes
                ? moment().set('minute', parseInt(championSendTimeMinutes))
                : undefined
            }
          />
        </div>
      </FormItem>
    </Card>
  )
}
