import {
  Article as ArticleIcon,
  ContentCard as ContentCardIcon,
  Display as DisplayIcon,
  EmailInbox as EmailInboxIcon,
  FacebookSquare as FacebookSquareIcon,
  GoogleCircular as GoogleCircularIcon,
  InApp as InAppIcon,
  InstagramSquare as InstagramSquareIcon,
  Message as MessageIcon,
  MessageAlignText as MessageAlignTextIcon,
  MobilePush as MobilePushIcon,
  ShoppingCart as ShoppingCartIcon,
  StartFlag as StartFlagIcon,
  WebPage as WebPageIcon,
  WebPush as WebPushIcon,
} from 'common/icons'

export type ContentType = keyof typeof mapContentTypeToIcon | (string & {})

export const mapContentTypeToIcon = {
  google: GoogleCircularIcon,
  startFlag: StartFlagIcon,
  messageText: MessageAlignTextIcon,
  email: EmailInboxIcon,
  push_notification: MessageIcon,
  sms: MessageIcon,
  landing_page: WebPageIcon,
  web: WebPageIcon,
  facebook: FacebookSquareIcon,
  instagram: InstagramSquareIcon,
  ads: GoogleCircularIcon,
  mobile: MessageIcon,
  messaging: MessageIcon,
  adwords: GoogleCircularIcon,
  'marketing email': EmailInboxIcon,
  'email headline': EmailInboxIcon,
  'email pre-header': EmailInboxIcon,
  'email cta': EmailInboxIcon,
  'email subject line': EmailInboxIcon,
  'cold email': EmailInboxIcon,
  'facebook ad': FacebookSquareIcon,
  social: FacebookSquareIcon,
  'instagram ad': InstagramSquareIcon,
  push: MobilePushIcon,
  'push title': MessageIcon,
  'push body': MessageIcon,
  heading: ArticleIcon,
  'section titles': ArticleIcon,
  'section paragraphs': ArticleIcon,
  'bullet points': ArticleIcon,
  article: ArticleIcon,
  articles: ArticleIcon,
  'website call to action': WebPageIcon,
  'website headline': WebPageIcon,
  'website banner': WebPageIcon,
  'google responsive search ad': GoogleCircularIcon,
  'google responsive display ad': GoogleCircularIcon,
  'product description': ShoppingCartIcon,
  'product features': ShoppingCartIcon,
  products: ShoppingCartIcon,
  ecommerce: ShoppingCartIcon,
  email_in_body: EmailInboxIcon,
  social_ad: InstagramSquareIcon,
  web_and_app: WebPageIcon,
  display: DisplayIcon,
  content_card: ContentCardIcon,
  in_app_message: InAppIcon,
  web_push: WebPushIcon,
} as const

type Props = {
  contentType: ContentType | undefined
  className?: string
}

export const ContentTypeIcon = ({ contentType, className }: Props) => {
  const Icon = contentType
    ? mapContentTypeToIcon[contentType.toLowerCase()]
    : undefined

  if (!Icon) {
    return <span className="ml-6"></span>
  }

  return <Icon isDefaultColor={false} className={className} />
}
