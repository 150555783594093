import cx from 'classnames'

import Badge from 'common/components/Badge'
import { ImageIcon } from 'common/icons'

import type { ContentTemplateCardProps } from '../ContentTemplateCard'

import ExperimentType from './ExperimentType'

type Props = Pick<
  ContentTemplateCardProps,
  'disabled' | 'isComingSoon' | 'optimization' | 'personalization'
>

const TemplateBadges = ({
  disabled,
  isComingSoon,
  optimization,
  personalization,
}: Props) => {
  return (
    <div className="flex">
      {isComingSoon && (
        <Badge
          text="Coming soon"
          variant="info"
          size="small"
          className="mr-2 inline-block border-0"
          data-testid="coming-soon-badge"
        />
      )}
      {optimization && (
        <ExperimentType
          name="optimise"
          icon={optimization.images === 'enabled' && <ImageIcon size={3} />}
          status={optimization.status}
          className={cx({
            'opacity-40': disabled || optimization.status === 'blocked',
          })}
        />
      )}
      {personalization && (
        <ExperimentType
          name="personalise"
          status={personalization.status}
          className={cx({
            'opacity-40': disabled || personalization?.status === 'blocked',
          })}
        />
      )}
    </div>
  )
}

export default TemplateBadges
