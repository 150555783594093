import { useState } from 'react'

import Button from 'common/components/button'
import { Add } from 'common/icons'

import AddTopicsModal from './components/modal'

type Props = {
  isDisabled?: boolean
}

const AddTopicButton = ({ isDisabled }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        data-cy="add-topics-button"
        data-testid="add-topics-button"
        variant="primary"
        onClick={() => setIsModalOpen(true)}
        className="mr-4"
        prefixIcon={<Add isDefaultColor={false} />}
        disabled={isDisabled}
      >
        Topics
      </Button>
      <AddTopicsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  )
}

export default AddTopicButton
