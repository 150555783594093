import { useState } from 'react'
import moment from 'moment'

import DateRange, {
  DateRangePickerType,
  FocusedInputType,
  isInclusivelyAfterDay,
  SelectorDate,
} from 'common/components/dateRange'
import FormItem from 'common/components/formItem'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import {
  applyDateRange,
  selectEndDate,
  selectStartDate,
} from '../../store/dashboardSlice'

const DatePicker = () => {
  const dispatch = useAppDispatch()

  const selectedStartDate = useAppSelector(selectStartDate)
  const selectedEndDate = useAppSelector(selectEndDate)

  const [startDate, setStartDate] = useState<SelectorDate>(
    selectedStartDate ? moment(selectedStartDate) : null
  )
  const [endDate, setEndDate] = useState<SelectorDate>(
    selectedEndDate ? moment(selectedEndDate) : null
  )
  const [focusedInput, setFocusedInput] =
    useState<FocusedInputType | null>(null)

  const hasDateChanged =
    startDate?.toISOString() !== selectedStartDate ||
    endDate?.toISOString() !== selectedEndDate
  const areBothDatesSelected =
    (startDate && endDate) || (!startDate && !endDate)

  const datesChangeHandler: DateRangePickerType['onDatesChange'] = ({
    startDate: newStartDate,
    endDate: newEndDate,
  }) => {
    setStartDate(newStartDate)

    if (newStartDate === startDate) {
      setEndDate(newEndDate)
    } else {
      setEndDate(null)
    }
  }

  return (
    <FormItem label="Date Range">
      <DateRange
        startDate={startDate}
        endDate={endDate}
        startDateId="dashboard-start-date"
        endDateId="dashboard-end-date"
        onDatesChange={datesChangeHandler}
        isOutsideRange={(day) =>
          isInclusivelyAfterDay(day, moment().add(1, 'days'))
        }
        displayFormat={() => 'DD MMM YYYY'}
        showClearDates
        focusedInput={focusedInput}
        maxDate={moment()}
        anchorDirection="right"
        onOutsideClick={() => {
          setStartDate(selectedStartDate ? moment(selectedStartDate) : null)
          setEndDate(selectedEndDate ? moment(selectedEndDate) : null)
        }}
        onFocusChange={setFocusedInput}
        reopenPickerOnClearDates
        keepOpenOnDateSelect
        footer={{
          onCancel: () => setFocusedInput(null),
          onApply: () => {
            dispatch(applyDateRange({ start: startDate, end: endDate }))
            setFocusedInput(null)
          },
          isApplyDisabled: !areBothDatesSelected || !hasDateChanged,
          applyDefaultOption: (start, end) => {
            setStartDate(start)
            setEndDate(end)
          },
        }}
      />
    </FormItem>
  )
}

export default DatePicker
