import AvatarList from 'common/components/AvatarList'
import ContentTypeBubble from 'common/components/ContentTypeBubble'
import Widget from 'common/components/Widget'
import { formatDate } from 'common/helpers/formatDate'
import { Clock as ClockIcon } from 'common/icons'

type Props = {
  title: string
  createdDate: Date
  users: string[]
  contentType: string
}

const PersonalizationCard = ({
  title,
  createdDate,
  users,
  contentType,
}: Props) => {
  return (
    <Widget type="basic" className="h-full group-hover:shadow-md">
      <div className="flex flex-row justify-between align-middle">
        <ContentTypeBubble iconType={contentType} /> 
        <AvatarList userNames={users} />
      </div>
      <div className="font-medium text-base my-4 max-w-xs line-clamp-3 text-base-700">
        {title}
      </div>
      <div className="flex flex-row justify-between mt-auto text-base-700 font-normal text-xs">
        <div className="flex flex-row justify-start">
          <ClockIcon isDefaultColor={false} />
          <div className="ml-2.5 self-center">{formatDate(createdDate)}</div>
        </div>
      </div>
    </Widget>
  )
}

export default PersonalizationCard
