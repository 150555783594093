import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { fetchPersonas } from '../api'
import { demoKeys } from '../queryKeys'

const useGetPersonasQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  return useQuery(demoKeys.personas(accountId), fetchPersonas, {
    select: (data) =>
      data.map((persona) => ({
        ...persona,
        value: persona.id,
        label: persona.name,
      })),
  })
}

export default useGetPersonasQuery
