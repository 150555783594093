import { generatePath, useHistory } from 'react-router-dom'

import { ContentTypeIcon } from 'common/components/ContentTypeIcon'
import IconTitleGroup from 'common/components/iconTitleGroup'
import { ResponseStatus } from 'common/interfaces/campaign'
import ListWidget, {
  ListWidgetItem,
} from 'features/homePage/components/listWidget'

import { ResponseCampaign } from '../api'

type Status = Exclude<ResponseStatus, 'all'>

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PROGRESS_MAP: { [key in Status]: string } = {
  not_approved: 'is awaiting your approval.',
  not_completed: 'is not complete yet.',
  missing_results: 'is missing results.',
  completed: 'report is now ready.',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const STATUS_MAP: { [key in Status]: string } = {
  not_approved: 'Approve',
  not_completed: 'Finish it now',
  missing_results: 'Upload results',
  completed: 'View report',
}

type Props = {
  campaigns: ResponseCampaign[]
  status: 'error' | 'success' | 'loading'
}

const Todo = ({ campaigns, status }: Props) => {
  const history = useHistory()

  return (
    <ListWidget
      title={
        <div className="flex flex-col w-full">
          <IconTitleGroup title="To-dos" />
          <hr className="w-full mt-4 mb-2 border-gold-400" />
        </div>
      }
      data-cy="todo-widget"
      data-testid="todo-widget"
      isLoading={status === 'loading'}
      className="w-full h-full border-none"
      bgClassName="bg-transparent"
      defaultPadding={false}
      error={
        status === 'error' ? (
          <div className="px-4 py-2">Loading To Do's failed</div>
        ) : null
      }
    >
      <ul>
        {campaigns.map((item, index) => (
          <ListWidgetItem
            key={item.id}
            onClick={() => {
              history.push(generatePath(`/campaigns/${item.id}`))
            }}
            data-cy={`todo-widget-link-${index}`}
            data-testid={`todo-widget-link-${index}`}
            className="px-0"
          >
            <span className="text-base-700">
              <ContentTypeIcon
                contentType={item.campaign_configuration.distribution_channel}
              />
            </span>
            <span className="text-base-700 font-medium ml-3 break-all">
              {item.name}
            </span>
            <span className="text-base-700">{PROGRESS_MAP[item.status]}</span>
            <span className="text-pink-500 group-hover:underline group-hover:text-maroon-400">
              {STATUS_MAP[item.status]}
            </span>
          </ListWidgetItem>
        ))}
      </ul>
    </ListWidget>
  )
}

export default Todo
