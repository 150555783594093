import cx from 'classnames'

import { ChevronDownSquare, ChevronUpSquare } from 'common/icons'

type Props = {
  id: string
  className?: string
  sortedDirection?: 'asc' | 'desc'
}

const SortIcon = ({ id, className, sortedDirection, ...restProps }: Props) => {
  return (
    <div
      data-testid={`table-sort-${id}`}
      className={cx(
        'flex flex-col h-4 w-4 relative group-hover:visible',
        className,
        {
          invisible: !sortedDirection,
        }
      )}
      {...restProps}
    >
      <ChevronUpSquare
        size={4}
        className={cx('absolute  -top-1', {
          'text-base-700': sortedDirection === 'asc',
          'text-gold-500': sortedDirection !== 'asc',
        })}
        isDefaultColor={false}
      />
      <ChevronDownSquare
        size={4}
        className={cx('absolute -bottom-1', {
          'text-base-700': sortedDirection === 'desc',
          'text-gold-500': sortedDirection !== 'desc',
        })}
        isDefaultColor={false}
      />
    </div>
  )
}

export default SortIcon
