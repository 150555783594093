import { Account } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import { RootState } from 'redux/store'

import { errorToast, successToast } from 'common/components/toastNotification'
import {
  colorMapping,
  createBrandVoice as createBrandVoiceApi,
  generateBrandTone as generateBrandToneApi,
  getBrandToneColor,
  getBrandVoices as getBrandVoicesApi,
  updateBrandTonesForBrandVoice as updateBrandTonesForBrandVoiceApi,
} from 'features/admin/brandVoice/api'
import { updateBrandVoice } from 'features/admin/styleGuide/api'
import { saveContent as saveStyleGuide } from 'features/admin/styleGuide/store/styleGuideSlice'
import { changeAccountId } from 'features/auth/store/authSlice'

export type Tone = {
  id: string
  description: string
  order: number
  title: string
  color?: string
  language?: string
}

export type AdvancedLanguageRule = {
  id: string
  name: string
  examples: { input: string; output: string }[]
  lastModified: string
  user: string
}

export type BrandVoice = {
  id: string
  name: string
  createdDate: string
  lastModified: string
  isDefault: boolean
  regionId: string
  tones: Tone[]
  languageRules?: AdvancedLanguageRule[]
}

type Content = {
  brandVoices: BrandVoice[]
}

export interface State {
  isLoading: boolean
  isGenerating: boolean
  hasError: boolean
  isSaving: boolean // TODO seems redundant with isLoading, try to remove it when woring with edit tones.
  rawContent: Content | undefined
  editedContent: Content
  regionId: string | undefined
  isDeleteDefaultBrandVoiceAlertVisible: boolean
  isDeleteConfirmationModalVisible: boolean
  isDeletionWarningWithRelatedProjectsModalVisible: boolean
}

export const initialState: State = {
  isLoading: true,
  isGenerating: false,
  hasError: false,
  isSaving: false,
  regionId: undefined,
  rawContent: undefined,
  editedContent: {
    brandVoices: [],
  },
  isDeleteDefaultBrandVoiceAlertVisible: false,
  isDeleteConfirmationModalVisible: false,
  isDeletionWarningWithRelatedProjectsModalVisible: false,
}

export const getTonesWithColorInfo = (
  brandVoice: BrandVoice | undefined
): Tone[] => {
  return brandVoice
    ? brandVoice.tones.map((tone, index) => {
        return {
          ...tone,
          order: index,
          color: getBrandToneColor(tone.title),
        }
      })
    : []
}

export const initializePage = createAsyncThunk<
  { content: Content },
  void,
  { state: RootState }
>('admin/brandVoice/initializePage', async (_, { getState }) => {
  const state = getState()
  const { accountId } = state.authStates

  const brandVoices = await getBrandVoicesApi({ accountId })
  const content = { brandVoices: brandVoices }
  return { content }
})

const processInitializePageResponse = (
  content: Content | undefined
): Content => {
  const coloredBrandVoices = content?.brandVoices.map((brandVoice) => {
    return {
      ...brandVoice,
      tones:
        brandVoice.tones?.map((tone) => {
          return { ...tone, color: colorMapping[tone.title.toLowerCase()] }
        }) ?? [],
    }
  })

  return {
    brandVoices: coloredBrandVoices ?? [],
  }
}

export const markAsDefault = createAsyncThunk<
  Account,
  { accountId: string; brandVoiceId: string; name: string },
  { state: RootState }
>(
  'admin/brandVoice/markAsDefault',
  async ({ brandVoiceId, accountId, name }, { getState, dispatch }) => {
    const account = await updateBrandVoice({
      accountId,
      brandVoiceId,
      data: {
        isDefault: true,
        name,
      },
    })
    return account
  }
)

export const saveContent = createAsyncThunk<
  Content,
  { accountId: string },
  { state: RootState }
>('admin/brandVoice/save', async ({ accountId }, { getState }) => {
  const state = getState()
  const { editedContent } = state.brandVoice

  const data = {
    brandVoices: editedContent.brandVoices,
  }

  //TODO use api to save data
  errorToast('not implemented yet')
  return data
})

export const createBrandVoice = createAsyncThunk<
  BrandVoice[],
  {
    brandVoice: Omit<BrandVoice, 'id' | 'createdDate'>
    clonedBrandVoice?: BrandVoice
  },
  { state: RootState }
>(
  'admin/brandVoice/create',
  async ({ brandVoice, clonedBrandVoice }, { getState }) => {
    const state = getState()
    const data = await createBrandVoiceApi({
      accountId: state.authStates.accountId,
      brandVoice,
      clonedBrandVoice,
    })

    return data
  }
)

export const generateBrandTone = createAsyncThunk<
  string[],
  {
    sampleText: string
    accountId: string
    brandVoiceId: string
  },
  { state: RootState }
>(
  'admin/brandVoice/generateBrandTone',
  async (
    {
      sampleText,
      accountId,
      brandVoiceId,
    }: { sampleText: string; accountId: string; brandVoiceId: string },
    { getState }
  ) => {
    const brandTones = await generateBrandToneApi(sampleText)
    const brandToneIds = brandTones.map((tone) => tone._id).slice(0, 5)
    await updateBrandTonesForBrandVoiceApi({
      accountId,
      brandVoiceId,
      brandToneIds,
    })

    return brandToneIds
  }
)

export const brandVoiceSlice = createSlice({
  name: 'admin/brandVoice',
  initialState,
  reducers: {
    resetContent: (state) => {
      if (state.rawContent) {
        state.editedContent = processInitializePageResponse(state.rawContent)
      }
    },
    removeBrandVoice: (state, action: PayloadAction<string>) => {
      state.editedContent.brandVoices = state.editedContent.brandVoices.filter(
        (brandVoice) => brandVoice.id !== action.payload
      )
    },
    toggleDeleteDefaultBrandVoiceAlertVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteDefaultBrandVoiceAlertVisible = action.payload
    },
    toggleDeleteConfirmationModalVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteConfirmationModalVisible = action.payload
    },
    toggleDeletionWarningWithRelatedProjectsModalVisibility: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeletionWarningWithRelatedProjectsModalVisible = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, () => initialState)
    builder
      .addCase(initializePage.pending, (state) => {
        state.hasError = false
        state.isLoading = true
      })
      .addCase(initializePage.fulfilled, (state, action) => {
        state.isLoading = false
        state.rawContent = action.payload.content
        state.editedContent = processInitializePageResponse(
          action.payload.content
        )
      })
      .addCase(initializePage.rejected, (state) => {
        state.isLoading = false
        state.hasError = true
      })
    builder
      .addCase(saveContent.pending, (state) => {
        state.isSaving = true
      })
      .addCase(saveContent.fulfilled, (state, action) => {
        state.isSaving = false
        state.rawContent = action.payload
        state.editedContent = processInitializePageResponse(action.payload)
      })
      .addCase(saveContent.rejected, (state) => {
        state.isSaving = false
        state.hasError = true
        errorToast('Failed to save Style Guide')
      })
      .addCase(createBrandVoice.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createBrandVoice.fulfilled, (state, action) => {
        state.isLoading = false
        state.editedContent.brandVoices = action.payload
      })
      .addCase(createBrandVoice.rejected, (state) => {
        state.isLoading = false
        state.hasError = true
        errorToast('Failed to save create brand voice')
      })
      .addCase(saveStyleGuide.fulfilled, (state, action) => {
        state.editedContent.brandVoices =
          (action.payload.brandVoicesResponse as any)?.map((brandVoice) => {
            return {
              id: brandVoice.id,
              name: brandVoice.name,
              regionId: brandVoice.region_id.toLowerCase(),
              languageSettings: brandVoice.language_settings,
              createdDate: brandVoice.created,
              lastModified: brandVoice.last_modified,
              isDefault: brandVoice.default,
              lastUpdated: brandVoice.last_updated,
              brandTones: brandVoice.brand_tones,
            }
          }) ?? []
      })
      .addCase(generateBrandTone.pending, (state) => {
        state.isGenerating = true
      })
      .addCase(generateBrandTone.fulfilled, (state, action) => {
        state.isGenerating = false
        successToast('Analyzed brand tones successfully')
      })
      .addCase(generateBrandTone.rejected, (state) => {
        state.isGenerating = false
        state.hasError = true
        errorToast('Something went wrong. Please try again.')
      })
      .addCase(markAsDefault.fulfilled, (state, action) => {
        if (action.payload.brand_voices) {
          state.editedContent = processInitializePageResponse({
            brandVoices: (action.payload.brand_voices as any).map(
              (brandVoice) => ({
                id: brandVoice.id,
                name: brandVoice.name,
                regionId: brandVoice.region_id,
                isDefault: brandVoice.default,
                createdDate: brandVoice.created,
                lastModified: brandVoice.last_updated,
                tones: [],
              })
            ),
          })
        }
        successToast('Brand voice updated')
      })
      .addCase(markAsDefault.rejected, (state) => {
        state.hasError = true
      })
      .addCase(markAsDefault.pending, (state, action) => {
        state.editedContent.brandVoices = state.editedContent.brandVoices.map(
          (brandVoice) => {
            return {
              ...brandVoice,
              isDefault: brandVoice.id === action.meta.arg.brandVoiceId,
            }
          }
        )
        state.hasError = false
      })
  },
})

/*
 * Actions
 */
export const {
  resetContent,
  removeBrandVoice,
  toggleDeleteDefaultBrandVoiceAlertVisibility,
  toggleDeleteConfirmationModalVisibility,
  toggleDeletionWarningWithRelatedProjectsModalVisibility,
} = brandVoiceSlice.actions

/*
 * Selectors
 */
export const selectHasBeenEdited = (state: RootState): boolean =>
  !isEqual(selectOriginalContent(state), state.brandVoice.editedContent)

export const selectOriginalContent = (state: RootState): Content | undefined =>
  state.brandVoice.rawContent &&
  processInitializePageResponse(state.brandVoice.rawContent)

export default brandVoiceSlice.reducer
