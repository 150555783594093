import { useEffect, useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { CAMPAIGN_NAME_LIMIT } from 'workflow/utils/helpers'

import InlineTextEdit from 'common/components/inlineTextEdit'
import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'
import useUpdateContentMutation from 'features/unifiedFlow/api/mutations/useUpdateContentMutation'
import { useContent } from 'features/unifiedFlow/contentPage/hooks'

import { parseWorkflowName } from '../../helpers'

const WorkflowField = () => {
  const { content } = useContent()
  const updateContentMutation = useUpdateContentMutation()
  const [value, setValue] = useState<string>(content?.display_name || '')

  const accountId = useAppSelector((state) => state.authStates.accountId)

  const updateWorkflowName = () => {
    const { value: parsedValue, isValid } = parseWorkflowName(value)
    if (!content || !isValid) {
      errorToast(
        'Jacquard only accepts letters, numbers, spaces, hyphens and underscores in experiment names.'
      )
      return
    }

    const userInputForm = cloneDeep(content.user_input.user_input_form)

    let previousName = ''
    userInputForm.forEach((field) => {
      if (field.name === 'workflow_name') {
        previousName = field.value as string
        field.value = parsedValue
      }
    })

    if (previousName !== value) {
      updateContentMutation.mutate({
        contentId: content._id,
        accountId,
        userInputForm,
        updatedName: parsedValue,
      })
    }
  }

  useEffect(() => {
    if (content?.display_name) {
      setValue(content.display_name)
    }
  }, [content?.display_name])

  if (!content?.display_name) {
    return null
  }
  return (
    <InlineTextEdit
      textColorClassName="text-white"
      fontSizeClassName="text-sm"
      className="bg-transparent border-none outline-none pl-0 w-full"
      iconClassName="text-white w-4"
      value={value}
      savedValue={content.display_name}
      placeholder=""
      minLength={1}
      onChange={(e) => setValue(e.target.value)}
      maxLength={CAMPAIGN_NAME_LIMIT}
      onSave={updateWorkflowName}
      isLoading={updateContentMutation.isLoading}
      showLeftEditIcon
      data-testid="campaign-name"
    />
  )
}

export default WorkflowField
