import get from 'lodash/get'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { getExperimentBadgesState } from 'features/unifiedFlow/contentPage/helpers'
import {
  hideLeftPanel,
  selectElement,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import ElementCard from '../../components/ElementCard'
import ExperimentTypeBadges from '../../components/ExperimentTypeBadges'
import LeftPanel from '../../components/layout/LeftPanel'
import { getElementGenerationComplete } from '../../helpers'
import { useContent } from '../../hooks'

const LeftSection = () => {
  const dispatch = useAppDispatch()
  const { content } = useContent()

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )

  const elements = get(content, 'elements', [])

  return (
    <LeftPanel onOutsideClick={() => dispatch(hideLeftPanel())}>
      {elements.map((element) => {
        const { element_id, name, display_name, icon, status, nlg_status } =
          element

        const isElementDisabled =
          status === 'disabled' ||
          !getElementGenerationComplete(content, element)

        const hasError = nlg_status === 'error'
        const badgesState = getExperimentBadgesState(element)

        return (
          <ElementCard
            className="mb-4"
            key={element_id}
            element={element}
            title={display_name || name}
            icon={icon}
            isDisabled={isElementDisabled}
            isSelected={element_id === selectedElementId && !isElementDisabled}
            hasError={hasError}
            isConfigurable={false}
            hasElementActionsMenu={false}
            content={badgesState && <ExperimentTypeBadges {...badgesState} />}
            onClick={() =>
              !isElementDisabled &&
              dispatch(
                selectElement({
                  elementId: element.element_id,
                })
              )
            }
          />
        )
      })}
    </LeftPanel>
  )
}

export default LeftSection
