import Autocomplete from 'common/components/autocomplete'
import { SelectValue } from 'common/components/BaseSelect'

type Props = {
  onSearch: (value: string) => void
  options: SelectValue[] | undefined
  placeholder?: string
  'aria-label'?: string
}

const IntegrationsSearchbox = ({
  onSearch,
  options,
  placeholder = 'Find content',
  'aria-label': ariaLabel,
}: Props) => {
  return (
    <Autocomplete
      className="w-60 ml-4"
      aria-label={ariaLabel}
      placeholder={placeholder}
      options={options}
      onSearch={onSearch}
      backspaceRemovesValue={true}
    />
  )
}

export default IntegrationsSearchbox
