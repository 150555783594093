import cx from 'classnames'

type Props = {
  className?: string
  children: React.ReactNode
}

const CalculatorCard = ({ className, children }: Props) => {
  return (
    <div className={cx('flex p-4 bg-gold-40 rounded-sm', className)}>
      {children}
    </div>
  )
}

export default CalculatorCard
