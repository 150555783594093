import cx from 'classnames'

import Badge from 'common/components/Badge'
import ButtonWithTooltip from 'common/components/button/withTooltip'
import { containsArabic } from 'common/helpers/string'
import { Copy } from 'common/icons'
import { replaceNewLineEmojiAsElement } from 'common/variantsUtils'

type Props = {
  text: string
  isControlVariant: boolean
  onClickCopy: (text: string) => void
}

const VariantCell = ({ text, isControlVariant, onClickCopy }: Props) => {
  const isArabic = containsArabic(text)

  return (
    <div
      className={cx('flex justify-between items-center', {
        'text-right': isArabic,
      })}
      dir="auto"
    >
      <span>
        <span
          className={cx({
            'mr-2': isControlVariant && !isArabic,
            'ml-2': isControlVariant && isArabic,
          })}
        >
          {replaceNewLineEmojiAsElement(text)}
        </span>
        {isControlVariant && <Badge text="Control" variant="warning" />}
      </span>
      <ButtonWithTooltip
        tooltip={false}
        data-cy="copy-variant"
        data-testid="copy-variant"
        title="Copy variant to clipboard"
        className="py-0"
        onClick={() => {
          onClickCopy(text)
        }}
        icon={<Copy />}
      />
    </div>
  )
}

export default VariantCell
