import { createSlice } from '@reduxjs/toolkit'

import { connectInternalApi } from 'common/api'
import { changeAccountId } from 'features/auth/store/authSlice'

interface SchedulePayload {
  test_start_time: string
  test_end_time: string
  primary_goal: string | undefined
}

interface CommonResponse {
  config: any
  data: any
  headers: any
  request: any
  status: number
}

interface ActivitiesResponse {
  data: {
    activities: {
      id: number
      modifiedAt: string
      name: string
      priority: number
      state: string
      thirdPartyId: string
      type: string
    }
  }
}

interface Activity {
  thirdPartyId: string
  name: string
  id: string
}

interface AdobeTargetState {
  activitiesList: Activity[] | undefined
  timeZone: string | undefined
  isLoading: {
    activities: boolean
    offers: boolean
    scheduler: boolean
  }
  error: string
}

interface CreateOffersPayload {
  activityId: number | undefined
}

export const initialState: AdobeTargetState = {
  activitiesList: undefined,
  timeZone: undefined,
  isLoading: {
    activities: false,
    offers: false,
    scheduler: false,
  },
  error: '',
}

export const adobeTargetSlice = createSlice({
  name: 'adobeTarget',
  initialState,
  reducers: {
    updateActivities: (state, action) => {
      state.activitiesList = action.payload.activities
    },
    setLoading: (state, action) => {
      state.isLoading[action.payload.field] = action.payload.value
    },
    setTimezone: (state, action) => {
      state.timeZone = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, () => initialState)
  },
})

export const { updateActivities, setLoading, setTimezone, setError } =
  adobeTargetSlice.actions

export async function fetchActivities(projectId: string) {
  return connectInternalApi.get<ActivitiesResponse>(
    `v1/integrations/giuseppe/projects/${projectId}/activities`
  )
}

export async function createOffers(
  projectId: string,
  campaignId: string,
  data: CreateOffersPayload
) {
  return connectInternalApi.post<CommonResponse>(
    `v1/integrations/giuseppe/projects/${projectId}/campaign/${campaignId}/offers`,
    data
  )
}

export async function deleteOffers(projectId: string, campaignId: string) {
  return connectInternalApi.delete<CommonResponse>(
    `v1/integrations/giuseppe/projects/${projectId}/campaign/${campaignId}/offers`
  )
}

export async function fetchTimezone(projectId: string) {
  return connectInternalApi.get<string>(
    `v1/integrations/giuseppe/projects/${projectId}/timezone`
  )
}

export function scheduleCampaign(
  projectId: string,
  campaignId: string,
  data: SchedulePayload
) {
  return connectInternalApi.post<CommonResponse>(
    `v1/integrations/giuseppe/projects/${projectId}/campaigns/${campaignId}/deployments`,
    data
  )
}

export function unscheduleCampaign(projectId: string, campaignId: string) {
  return connectInternalApi.delete<CommonResponse>(
    `v1/integrations/giuseppe/projects/${projectId}/campaigns/${campaignId}/deployments`
  )
}

export default adobeTargetSlice.reducer
