import cx from 'classnames'

type Props = {
  className?: string
  children?: React.ReactNode
}

const TemplatesConfigPanelMain = ({ className, children }: Props) => {
  return (
    <div
      className={cx('flex overflow-y-auto overflow-x-hidden flex-1', className)}
    >
      {children}
    </div>
  )
}

export default TemplatesConfigPanelMain
