import ConfirmationModal from 'common/components/confirmationModal'

import { ResultsTableProps } from '../../interfaces'

type ChangeVariantStatusModalProps = Pick<
  ResultsTableProps,
  'campaignId' | 'updateResultsVariantStatus' | 'isBroadcastCampaign'
> & {
  onCancel: () => void
  onConfirm: () => void
  isOpen: boolean
}

const ChangeVariantStatusModal = (props: ChangeVariantStatusModalProps) => {
  const {
    onCancel,
    onConfirm,
    isOpen = false,
    isBroadcastCampaign = false,
  } = props

  return (
    <ConfirmationModal
      data-cy="change-variant-status-modal"
      data-testid="change-variant-status-modal"
      title="Change status or variant?"
      confirmationText={`Do you really want to change the status of this variant? Changes could take up to ${
        isBroadcastCampaign ? '1 hour' : '24 hours'
      } to take effect.`}
      cancelButtonText="Cancel"
      confirmButtonText="Yes, do it!"
      isLoading={false}
      confirmButtonVariant="primary"
      open={isOpen}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
}

export default ChangeVariantStatusModal
