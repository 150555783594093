import { useEffect } from 'react'

import { getIsAuthorized, projectsPermissions } from 'common/auth/permissions'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Project } from 'features/campaigns/api/interface'
import {
  fetchAccountProjectsAsync,
  getActiveProjects,
  getAllActiveProjectsOfAnAccount,
  getAllNonTestProjectsOfAnAccount,
  getNonTestProjects,
} from 'features/campaigns/store/campaignSlice'

import MultiSelect, { MultiSelectValue } from './MultiSelect'

export const PROJECTS_SELECT_LABEL = 'Projects'

interface Props {
  'data-cy': string
  'data-testid': string
  selectedItems: string[] | undefined
  onApply: (val: string[]) => void
  className?: string
  isDisabled?: boolean
  isLabelHidden?: boolean
  placeholder?: string
  filteredProjects?: Project[]
  menuPortalTarget?: HTMLElement
  isSelectAllEnabled?: boolean
  isFooterHidden?: boolean
  areTestProjectsExluded?: boolean
  useAllAccountProjects?: boolean
}

const ProjectMultiSelect = ({
  'data-cy': dataCy,
  'data-testid': dataTestId,
  selectedItems,
  onApply,
  isDisabled,
  isLabelHidden = false,
  className,
  placeholder = 'All projects',
  filteredProjects,
  menuPortalTarget,
  isSelectAllEnabled = false,
  isFooterHidden = false,
  areTestProjectsExluded,
  useAllAccountProjects,
}: Props) => {
  const dispatch = useAppDispatch()
  const { permissions } = useAppSelector((state) => state.authStates)

  //to see all projects the permission list has only projectPermissions.list
  //for more details check [HH-3690]
  const hasPermissionToSeeAllAccountProjects =
    !getIsAuthorized(permissions, projectsPermissions.listRelation) &&
    getIsAuthorized(permissions, projectsPermissions.list)

  const assignedProjects = useAppSelector(
    areTestProjectsExluded ? getNonTestProjects : getActiveProjects
  )

  const allAccountProjects = useAppSelector(
    areTestProjectsExluded
      ? getAllNonTestProjectsOfAnAccount
      : getAllActiveProjectsOfAnAccount
  )

  useEffect(() => {
    dispatch(fetchAccountProjectsAsync())
  }, [dispatch])

  const projects =
    hasPermissionToSeeAllAccountProjects && useAllAccountProjects
      ? allAccountProjects
      : assignedProjects

  const items = (filteredProjects ?? projects).map(({ id, name }) => {
    return { id, value: id, label: name }
  })

  return (
    <MultiSelect<MultiSelectValue>
      className={className}
      data-cy={dataCy}
      data-testid={dataTestId}
      isSelectAllEnabled={isSelectAllEnabled}
      isFooterHidden={isFooterHidden}
      name="projects"
      label={isLabelHidden ? '' : PROJECTS_SELECT_LABEL}
      placeholder={placeholder}
      items={items}
      selectedItems={selectedItems}
      onApply={onApply}
      isDisabled={isDisabled}
      menuPortalTarget={menuPortalTarget}
    />
  )
}

export default ProjectMultiSelect
