/* eslint-disable max-lines */
import { useMemo } from 'react'
import { FC, useCallback, useState } from 'react'
import cx from 'classnames'
import { showBanner } from 'workflow/common/common.actions'
import helpers from 'workflow/utils/helpers'
import { loadResultsDetails } from 'workflow/Workflow.actions'

import { DetailsPanelButton as Button } from 'common/components/detailsPanel'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { Connect, Copy, Download, InputResults } from 'common/icons'
import { replaceNewLineEmojiAsString } from 'common/variantsUtils'
import { openDrawer } from 'features/campaigns/store/campaignSlice'

import CloningModal from '../cloningModal/CloningModal'
import InputResultModal from '../inputResult/InputResultModal'

import CancelButton from './CancelButton'
import { isSFMC } from './helpers'
import IntegrationButton from './IntegrationButton'

interface Props {
  isExpanded: boolean
  projectName: string
}

interface Variant {
  _id: string
  api_id: string
  text: string
  sort_order: number
  ownsl?: boolean
}

const Buttons: FC<Props> = ({ isExpanded, projectName }) => {
  const dispatch = useAppDispatch()

  const campaign = useAppSelector((state) => state.campaignStates.campaignData)
  const campaignId = useAppSelector(
    (state) => state.campaigns.selectedCampaignId
  )
  const variants = useAppSelector((state) => state.campaignStates.subjectLines)
  const integrationType = useAppSelector(
    (state) =>
      state.campaignStates.campaignData.campaign_configuration
        ?.integration_options?.type
  )

  const selectionType = useAppSelector(
    (state) =>
      state.campaignStates.campaignData.campaign_configuration?.testing_method
        ?.optimization_method?.selection_type
  )
  const isRealtimeApiEnabled = useAppSelector(
    (state) =>
      !!state.campaignStates.campaignData.campaign_configuration
        ?.realtime_api_enabled
  )
  const shouldUseVariantIds = useAppSelector(
    (state) =>
      state.campaignStates.campaignData.campaign_configuration?.campaign
        ?.export_options.copy_format.variant_ids || false
  )

  const campaignProgress = useAppSelector(
    (state) => state?.campaignStates?.campaignData?.campaign_progress
  )

  const resultsTableFilteredVariants = useAppSelector(
    (state) => state?.campaignStates?.filteredTableVariants
  )

  const currentChosenStep = useAppSelector(
    (state) => state?.campaignStates?.chosenStep
  )

  const variantsToBeApprovedExperimentsFlow = useAppSelector(
    (state) => state?.campaignStates?.variantsToBeApprovedExperimentsFlow
  )

  const {
    text_variants_generated: variantsGenerated,
    text_variants_accepted: variantsAccepted,
    test_results_manually_entered: testResultsManuallyEntered,
    final_results_manually_entered: finalResultsManuallyEntered,
    final_send_results_received: finalSendResultsReceived,
    test_scheduled: testScheduled,
    test_schedule_started: testScheduleStarted,
    test_results_received: testResultsReceived,
    phrasee_x_initialised: phraseeXInitialised,
    phrasee_x_optimisation_started: phraseeXOptimisationStarted,
    test_creation_started: testCreationStarted,
    test_creation_completed: testCreationCompleted,
  } = campaignProgress

  const showCloneCampaignButton: boolean =
    variantsGenerated &&
    !(
      testResultsManuallyEntered ||
      finalResultsManuallyEntered ||
      finalSendResultsReceived ||
      testScheduled ||
      testScheduleStarted ||
      testResultsReceived ||
      phraseeXInitialised ||
      phraseeXOptimisationStarted ||
      testCreationStarted ||
      testCreationCompleted
    )

  const [isCloningModalOpen, setCloningModalOpen] = useState<boolean>(false)
  const [isInputResultModalOpen, setIsInputResultModalOpen] =
    useState<boolean>(false)

  const isBandit = helpers.isBanditCampaign(campaign.campaign_configuration)
  const showInputResultsButton =
    (!isBandit ||
      helpers.isBanditManualConversionsCampaign(
        campaign.campaign_configuration
      )) &&
    variantsAccepted

  const showPhraseeXButton =
    (isRealtimeApiEnabled && variantsAccepted && !isSFMC(integrationType)) ||
    (variantsAccepted && !integrationType && isBandit)

  const formatVariant = (variant: Variant) => {
    if (shouldUseVariantIds) {
      return `${
        variant?.api_id || variant?._id
      } \t"${replaceNewLineEmojiAsString(variant.text)}"`
    } else {
      return `"${replaceNewLineEmojiAsString(variant.text)}"`
    }
  }

  const shouldCopyOnlyNewVariants =
    helpers.isBanditNlgInitialised(campaign) &&
    currentChosenStep === 'language_approval' &&
    variantsToBeApprovedExperimentsFlow.length > 0

  const copyVariantsToClipboard = () => {
    const variantsList = shouldCopyOnlyNewVariants
      ? variants
          .filter((variant) => variant.bandit_status?.status === 'pending')
          .map((variant) => formatVariant(variant))
          .join('\n')
      : variants.map((variant) => formatVariant(variant)).join('\n')

    helpers.copyToClipboard(variantsList)

    dispatch(
      showBanner({
        type: 'success',
        content: shouldCopyOnlyNewVariants
          ? 'New variants copied successfully'
          : 'Variants copied successfully',
      })
    )
  }

  const downloadVariants = () => {
    let downloadText = ''
    resultsTableFilteredVariants.forEach((variant) => {
      downloadText += `${replaceNewLineEmojiAsString(variant.text)}\n
      ----------
      \n`
    })

    const element = document.createElement('a')
    const file = new Blob([downloadText], { type: 'text/plain' })
    element.setAttribute('href', URL.createObjectURL(file))
    element.setAttribute('download', `${campaign.name}-${campaign._id}.txt`)
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  const downloadTemplateVariants = async () => {
    let text = campaign.ibm_watson_xml_template

    await campaign.sl.forEach((sl: any, index: any) => {
      text = text.replace(
        new RegExp(`SUBJECTLINE${index + 1}PLACEHOLDER`, 'g'),
        sl.text
      )
    })

    const element = document.createElement('a')
    const file = new Blob([text], { type: 'text/plain' })
    element.setAttribute('href', URL.createObjectURL(file))
    element.setAttribute(
      'download',
      `${campaign.name}-${campaign._id}-template.txt`
    )
    document.body.appendChild(element)
    element.click()
    document.body.removeChild(element)
  }

  const handleCloseInputResultModal = useMemo(
    () => () => setIsInputResultModalOpen(false),
    []
  )

  const handleSubmitInputResults = useCallback(() => {
    if (campaignId) {
      setIsInputResultModalOpen(false)
      dispatch(loadResultsDetails(campaignId))
    }
  }, [campaignId, dispatch])

  return (
    <div
      className={cx({
        'pb-8 border-b border-coolGray-200': isExpanded,
        'pb-6': !isExpanded,
      })}
    >
      <IntegrationButton
        selectionType={selectionType}
        isExpanded={isExpanded}
        typeOfIntegration={
          selectionType === 'bandit' && integrationType === 'epsilon'
            ? 'epsilon_react'
            : integrationType
        }
        variantsAccepted={variantsAccepted}
        isRealtimeApiEnabled={isRealtimeApiEnabled}
      />
      {integrationType !== 'facebook' && (
        <CancelButton isExpanded={isExpanded} />
      )}
      {showInputResultsButton && (
        <Button
          isExpanded={isExpanded}
          data-cy="input-results"
          data-testid="input-results"
          title="Input results"
          onClick={() => setIsInputResultModalOpen(true)}
          icon={<InputResults />}
        />
      )}

      {showPhraseeXButton && (
        <Button
          isExpanded={isExpanded}
          data-cy="phraseex"
          data-testid="phraseex"
          title="Dynamic Optimisation"
          onClick={() => dispatch(openDrawer('phraseex'))}
          icon={<Connect />}
        />
      )}

      {variantsGenerated && (
        <>
          <Button
            isExpanded={isExpanded}
            data-cy="copy-variants"
            data-testid="copy-variants"
            title={
              shouldCopyOnlyNewVariants
                ? 'Copy new variants to clipboard'
                : 'Copy variants to clipboard'
            }
            onClick={() => copyVariantsToClipboard()}
            icon={<Copy />}
          />
          <Button
            isExpanded={isExpanded}
            data-cy="download-variants"
            data-testid="download-variants"
            title="Download variants"
            onClick={() => {
              downloadVariants()
            }}
            icon={<Download />}
          />

          {campaign.xml_download_enabled && (
            <Button
              isExpanded={isExpanded}
              data-cy="download-templated-variants"
              data-testid="download-templated-variants"
              title="Download templated variants"
              onClick={() => {
                downloadTemplateVariants()
              }}
              icon={<Download />}
            />
          )}
        </>
      )}

      {showCloneCampaignButton && (
        <Button
          isExpanded={isExpanded}
          data-cy="clone-campaign"
          data-testid="clone-campaign"
          title="Clone experiment"
          onClick={() => setCloningModalOpen(true)}
          icon={<Copy />}
        />
      )}

      {campaignId && (
        <CloningModal
          isCampaignCloningModalOpen={isCloningModalOpen}
          onClose={() => setCloningModalOpen(false)}
          campaignId={campaignId}
        />
      )}
      {campaignId && (
        // InputResultsModal is memoized, use only functions with callbacks
        <InputResultModal
          campaignId={campaignId}
          projectName={projectName}
          campaignName={campaign.name}
          isOpen={isInputResultModalOpen}
          onClose={handleCloseInputResultModal}
          onSubmit={handleSubmitInputResults}
        />
      )}
    </div>
  )
}

export default Buttons
