import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { getProfileAttributeCatalogs } from '../api'
import { Region } from '../api'
import { contentKeys } from '../queryKeys'

const useGetProfileAttributeCatalogsQuery = ({
  region,
}: {
  region: Region | undefined
}) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getProfileAttributeCatalogsQuery = useQuery(
    contentKeys.profileAttributeCatalogs({ accountId, region }),
    () => getProfileAttributeCatalogs({ accountId, region }),
    {
      enabled: !!region && !!accountId,
      meta: {
        errorMessage: 'Profile attribute catalogs could not be loaded',
      },
    }
  )

  return getProfileAttributeCatalogsQuery
}

export default useGetProfileAttributeCatalogsQuery
