import cx from 'classnames'

type Props = {
  children: React.ReactNode
  className?: string
  isHiding?: boolean
  isActive: boolean
  'data-testid'?: string
}

const SlidingPanel = ({
  children,
  className,
  isHiding,
  isActive,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <div
      data-testid={dataTestId}
      className={cx(
        'flex flex-shrink-0 transform duration-300 transition-transform',
        {
          [`-translate-x-full`]: isHiding,
        },
        className
      )}
    >
      <div
        className={cx('flex flex-col w-full h-full', {
          'visible transition-visibility duration-0 ease-linear delay-0':
            isActive,
          'invisible transition-visibility duration-0 ease-linear delay-300':
            !isActive,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default SlidingPanel
