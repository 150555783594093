import { useEffect, useState } from 'react'
import { Column, Row } from 'react-table'
import cx from 'classnames'

import NumericInput from 'common/components/numericInput'
import { formatNumber } from 'common/helpers/numeric'

import type { OnCellChange } from '../types/interfaces'

const NumericEditableCell = ({
  value: initialValue,
  row: { index },
  column: { id, align },
  onCellChange, // This is a custom function that we supplied to our table instance
}: {
  value: number | undefined
  row: Row
  column: Column
  onCellChange: OnCellChange
}) => {
  const [value, setValue] = useState<number | undefined>(initialValue)

  const onChange: (value: number | undefined) => void = (value) => {
    setValue(value)
  }

  const onBlur = () => {
    if (!id) {
      throw new Error('The column id is missing')
    }
    onCellChange({ rowIndex: index, columnId: id, value })
  }

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <NumericInput
      value={value}
      isWithoutStyles={true}
      onChange={onChange}
      formatValue={(value) => {
        return value.endsWith('.')
          ? value
          : Number.isNaN(Number.parseFloat(value))
          ? value
          : formatNumber(Number(value), { maximumFractionDigits: 2 })
      }}
      onBlur={onBlur}
      className={cx(
        `w-full h-full outline-none bg-transparent px-4 py-4 cursor-pointer border border-transparent 
       focus-visible:border-maroon-400 focus:bg-maroon-40
       hover:border-coolGray-400 caret-maroon-200`,
        {
          'text-right': align === 'right',
          'text-center': align === 'center',
        }
      )}
    />
  )
}

export default NumericEditableCell
