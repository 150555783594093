import React from 'react'
import cx from 'classnames'

import LottieLoader from './LottieLoader'

type Props = {
  className?: string
  isFullPage?: boolean
  'data-testid'?: string
  'data-cy'?: string
  children?: React.ReactNode
  isLoading?: boolean
  isGrayscale?: boolean
  hasWrapperFullHeight?: boolean
  size?: 'small' | 'medium'
}

const Loader = ({
  className,
  isFullPage,
  'data-testid': dataTestId = 'loader',
  'data-cy': dataCy = 'loader',
  children,
  isLoading = true,
  hasWrapperFullHeight = true, // TODO: Remove when we use tailwind 3
  isGrayscale,
  size = 'medium',
}: Props) => {
  const hasChildren = React.Children.count(children) > 0

  if (!hasChildren) {
    return isLoading ? (
      <div
        className={cx('h-full flex items-center justify-center', className, {
          'h-screen': isFullPage,
        })}
        data-testid={dataTestId}
        data-cy={dataCy}
      >
        <LottieLoader size={size} isGrayscale={isGrayscale} />
      </div>
    ) : null
  }

  return (
    <div className={cx('relative', { 'h-full': hasWrapperFullHeight })}>
      {isLoading && (
        <div
          className="inline-block absolute top-1/2 transform -translate-y-1/2 ml-auto mr-auto left-0 right-0 w-fit"
          data-testid={dataTestId}
          data-cy={dataCy}
        >
          <LottieLoader size={size} isGrayscale={isGrayscale} />
        </div>
      )}
      <div
        className={cx('transition-opacity h-full', {
          'opacity-50 pointer-events-none select-none': isLoading,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export default Loader
