const Filter: React.FC = ({ children }) => {
  return (
    <div
      className="flex border border-gray-300 hover:border-maroon-100 bg-coolGray-50
   focus-within:border-maroon-100 focus-within:ring-1 focus-within:ring-maroon-100 focus-within:bg-white"
    >
      {children}
    </div>
  )
}

export default Filter
