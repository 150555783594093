import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { CampaignMetricsResponse, getCampaignMetrics } from '../api'
import { contentKeys } from '../queryKeys'

const useGetCampaignMetricsQuery = ({ isEnabled }: { isEnabled: boolean }) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getContentQuery = useQuery<
    CampaignMetricsResponse,
    unknown,
    { live: number; completed: number; scheduled: number; needApproval: number }
  >(
    contentKeys.campaignMetrics(accountId),
    () => getCampaignMetrics(accountId),
    {
      staleTime: Infinity,
      enabled: !!accountId && isEnabled,
      select: (data) => ({
        live: data.live?.count,
        completed: data.completed?.count,
        scheduled: data.scheduled?.count,
        needApproval: data.needApproval?.count,
      }),
    }
  )

  return getContentQuery
}

export default useGetCampaignMetricsQuery
