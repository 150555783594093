import { useEffect } from 'react'
import { fetchCampaignData } from 'workflow/Workflow.actions'

import Loader from 'common/components/loaders/Loader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { useSelectedComponentElement } from 'features/unifiedFlow/contentPage/hooks'
import useTweakSuccess from 'features/unifiedFlow/contentPage/hooks/useTweakSuccess'

import TweakVariantModal from '../../../components/tweakVariantModal/TweakVariantModal'
import useVariantTweakWebsocketSubscription from '../../../hooks/useVariantTweakWebsocketSubscription'

import ApprovalsTable from './approvalsTable/ApprovalsTable'

const OptimizeResults = () => {
  const { tweakSuccessVariantIds, onTweakSuccess } = useTweakSuccess()

  useVariantTweakWebsocketSubscription({ onTweakSuccess })

  const dispatch = useAppDispatch()
  const { data: selectedElement } = useSelectedComponentElement()

  const isLoading = useAppSelector((state) => state.campaignStates.isLoading)
  const isWaiting = useAppSelector(
    (state) => state.campaignStates.isWaitingState.isWaiting
  )

  useEffect(() => {
    const abortController = new AbortController()
    if (selectedElement?.campaign_id) {
      dispatch(
        fetchCampaignData(
          selectedElement.campaign_id,
          {},
          abortController.signal
        )
      )
    }
    return () => {
      abortController.abort()
    }
  }, [dispatch, selectedElement?.campaign_id])

  return isLoading || isWaiting ? (
    <Loader className="m-auto" />
  ) : (
    <>
      <div
        className="flex flex-col w-full gap-y-8"
        data-testid="optimizeResults"
      >
        <ApprovalsTable tweakSuccessVariantIds={tweakSuccessVariantIds} />
      </div>
      <TweakVariantModal campaignType="linguo" />
    </>
  )
}

export default OptimizeResults
