import { useEffect, useState } from 'react'
import { Campaign as GraphQLResponseCampaigns } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { gql } from 'graphql-request'

import { fetchGraphQL } from 'common/api'
import TextLoader from 'common/components/loaders/TextLoader'
import { useAppSelector } from 'common/hooks/redux'
import { ResponseStatus } from 'common/interfaces/campaign'
import { WigdetApiResponseBase } from 'common/interfaces/widgetListInterfaces'
import Link from 'common/typography/Link'

export interface ResponseCampaign
  extends Pick<
    GraphQLResponseCampaigns,
    '_id' | 'name' | 'status' | 'campaign_configuration' | 'user_id'
  > {
  id: string
  name: string
  project: { projectName: string }
  status: ResponseStatus
  user_id: string
}

export interface CampaignsResponse {
  campaigns: ResponseCampaign[]
  count: number
}

interface Props {
  setWidget: (val: WigdetApiResponseBase | undefined) => void
}

const RecentlyCreated = ({ setWidget }: Props) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const [recentlyCreated, setRecentlyCreated] = useState<ResponseCampaign[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [hasError, setHasError] = useState<boolean>(false)

  useEffect(() => {
    const fetchRecentlyCreated = async () => {
      setHasError(false)
      setIsLoading(true)
      const fetchRecentlyCreatedQuery = function (
        page: number,
        pageSize: number
      ): Promise<CampaignsResponse> {
        const query = gql`
          query GetRecentlyCreated(
            $page: Int
            $pageSize: Int
            $filter: CampaignFilter
          ) {
            data: campaigns(page: $page, pageSize: $pageSize, filter: $filter) {
              campaigns {
                id: _id
                name
                project {
                  projectName: name
                }
                status
                user_id
              }
            }
          }
        `

        const variables = {
          page,
          pageSize,
          filter: {
            accountId,
          },
        }

        return fetchGraphQL<CampaignsResponse>({ query, variables })
      }

      fetchRecentlyCreatedQuery(0, 5)
        .then(({ campaigns }: CampaignsResponse) => {
          if (campaigns.length) {
            setRecentlyCreated(campaigns)
          } else {
            setWidget(undefined)
          }
        })
        .catch(() => {
          setHasError(true)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
    fetchRecentlyCreated()
  }, [setRecentlyCreated, setWidget, accountId])

  return (
    <div className="my-6 mx-6 lg:ml-0 w-70">
      <h2
        className="mb-6 mt-9 text-xl font-medium"
        data-cy="recently-created-title"
        data-testid="recently-created-title"
      >
        Recently created
      </h2>
      {hasError && <div>Loading campaigns failed</div>}
      {isLoading ? (
        <div data-testid="loader" data-cy="recently-created-loader">
          <TextLoader />
          <TextLoader />
          <TextLoader />
          <TextLoader />
        </div>
      ) : (
        <ul>
          {recentlyCreated.map(
            ({ name, project: { projectName }, status, id }, index) => (
              <li key={id} className="mb-6">
                <span className="inline-block text-gray-400 text-xs font-medium">
                  <span className="inline capitalize-first">{projectName}</span>
                  {' / '}
                  <span className="inline capitalize-first">
                    {status?.replace(/_/g, ' ') ?? 'No status'}
                  </span>
                </span>

                <Link
                  to={`/campaigns/${id}`}
                  className="block text-sm text-coolGray-800 
                  hover:text-maroon-400 font-normal break-words"
                  data-cy={`recently-created-link${index + 1}`}
                  data-testid={`recently-created-link${index + 1}`}
                >
                  {name}
                </Link>
              </li>
            )
          )}
        </ul>
      )}
    </div>
  )
}

export default RecentlyCreated
