import { useMemo, useState } from 'react'

import Button from 'common/components/button'
import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import Drawer from 'common/components/drawer'
import EmptyState from 'common/components/emptyState'
import Loader from 'common/components/loaders/Loader'
import SingleSelect from 'common/components/singleSelect'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { successToast } from 'common/components/toastNotification'
import TableWidget from 'common/components/widget/tableWidget'
import { formatIsoDate } from 'common/helpers/date'
import { Edit as EditIcon } from 'common/icons'

import { CustomerAttributesResponse } from '../api'
import useUpdateCustomerAttributesMutation from '../api/mutations/useUpdateCustomerAttributesMutation'
import { CustomerAttributeRecord } from '../api/queries/useGetCustomerAttributeRecordsQuery'

import EditAttributeModal from './EditAttributeModal'
import ExplainAttributesModal from './ExplainAttributesModal'

type CustomerAttributesColumn = CustomerAttributesResponse

type Props = {
  onAddNewRecord: () => void
  onChangeRecord: (value: string | undefined) => void
  selectedRecordId: string | undefined
  records: CustomerAttributeRecord[]
  attributes: CustomerAttributesColumn[]
  isLoading: boolean
}

const CustomerAttributesTable = ({
  onAddNewRecord,
  onChangeRecord,
  selectedRecordId,
  records,
  attributes,
  isLoading,
}: Props) => {
  const [resetTableWidgetKey, setResetTableWidgetKey] = useState(0)

  const [draftDescription, setDraftDescription] = useState<string>('')
  const [attributeToEdit, setAttributeToEdit] =
    useState<CustomerAttributesColumn | undefined>(undefined)
  const [isEditAttributeModalOpened, setIsEditAttributeModalOpened] =
    useState(false)
  const [isExplainModalVisible, setIsExplainModalVisible] = useState(false)

  const [selectedAttribute, setSelectedAttribute] =
    useState<CustomerAttributesColumn | undefined>(undefined)

  const selectedRecord = records.find(
    (record) => record.id === selectedRecordId
  )

  const { mutate: updateAttribute, isLoading: isUpdatingAttribute } =
    useUpdateCustomerAttributesMutation()

  const columns: Column<CustomerAttributesColumn>[] = useMemo(
    () => [
      {
        Header: 'Attribute',
        accessor: 'name',
        width: 50,
        minWidth: 50,
        Cell: ({ row }) => {
          return (
            <BaseCell>
              <div className="capitalize-first">{row.original.name}</div>
            </BaseCell>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Description',
        accessor: 'description',
        width: 280,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <BaseCell className="capitalize-first">
              <div className="overflow-hidden whitespace-nowrap overflow-ellipsis">
                {row.original.description}
              </div>
            </BaseCell>
          )
        },
      },
      {
        width: 60,
        disableSortBy: true,
        accessor: 'id',
        align: 'right',
        Cell: ({ row }) => {
          return (
            <BaseCell>
              <ButtonWithTooltip
                tooltip
                tooltipText="Update description"
                variant="icon"
                onClick={() => {
                  setAttributeToEdit(row.original)
                  setIsEditAttributeModalOpened(true)
                  setDraftDescription(row.original.description)
                }}
                prefixIcon={<EditIcon />}
              />
            </BaseCell>
          )
        },
      },
    ],
    []
  )

  return (
    <div>
      <Loader isLoading={isLoading}>
        <TableWidget.Widget
          key={`${resetTableWidgetKey}`}
          columns={columns}
          data={attributes}
          isLoading={isLoading}
          className="mt-6"
          rowClassName="bg-coolGray-50"
          onRowClick={setSelectedAttribute}
          firstUseText={
            <div className="p-6">
              <EmptyState title="Awaiting data">
                <a
                  href="https://support.jacquard.com/en/articles/10223324-personalised-campaigns-getting-started"
                  className="underline uppercase text-gold-600 hover:text-gold-800"
                  target="_blank"
                  rel="noreferrer"
                >
                  Getting started guide
                </a>
              </EmptyState>
            </div>
          }
        >
          <TableWidget.Header
            title={
              <div className="mb-4 flex gap-6 text-gold-500 text-xs items-center">
                <SingleSelect
                  className="max-w-50"
                  onChange={(value) => {
                    onChangeRecord(value?.value)
                    setResetTableWidgetKey((prev) => prev + 1)
                  }}
                  value={selectedRecordId}
                  isDisabled={records.length === 1}
                  options={records.map((record) => ({
                    label: record.name,
                    value: record.id,
                  }))}
                />
                <div>Region: {selectedRecord?.region}</div>
                <div>Last update: {formatIsoDate(selectedRecord?.updated)}</div>
              </div>
            }
          >
            <Button
              data-cy="add-incompatible-word-button"
              data-testid="add-incompatible-word-button"
              className="mr-4"
              variant="primary"
              onClick={() => {
                onAddNewRecord()
                setResetTableWidgetKey((prev) => prev + 1)
              }}
            >
              Add new record
            </Button>
            <TableWidget.Filter />
          </TableWidget.Header>
        </TableWidget.Widget>
      </Loader>
      <Drawer
        title={selectedRecord?.name ?? ''}
        isOpen={selectedAttribute !== undefined}
        isPush={true}
        content={
          <div className="mt-6 flex flex-col gap-4 flex-nowrap">
            <div className="text-base-700 text-6 font-medium capitalize-first">
              {selectedAttribute?.name}
            </div>
            <div className="whitespace-pre-wrap break-words w-82">
              {selectedAttribute?.description}
            </div>
          </div>
        }
        onOpenChange={() => setSelectedAttribute(undefined)}
        container={document.getElementById('test')}
      />
      {attributeToEdit && (
        <EditAttributeModal
          attribute={attributeToEdit}
          description={draftDescription}
          onChangeDescription={setDraftDescription}
          isSaving={isUpdatingAttribute}
          onSave={({ description }) => {
            updateAttribute(
              {
                attributeId: attributeToEdit.id,
                catalogueAttributeId: selectedRecord?.id ?? '',
                description,
                region: selectedRecord?.region ?? '',
              },
              {
                onSuccess: () => {
                  setIsEditAttributeModalOpened(false)
                  successToast('Attribute updated successfully')
                },
              }
            )
          }}
          isOpened={isEditAttributeModalOpened}
          onClose={() => setIsEditAttributeModalOpened(false)}
          onClickNeedHelp={() => {
            setIsExplainModalVisible(true)
            setIsEditAttributeModalOpened(false)
          }}
        />
      )}
      <ExplainAttributesModal
        isOpened={isExplainModalVisible}
        onClose={() => {
          setIsExplainModalVisible(false)
          setIsEditAttributeModalOpened(true)
        }}
      />
    </div>
  )
}

export default CustomerAttributesTable
