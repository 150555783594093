import { useMemo } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'

import { SelectValue } from 'common/components/BaseSelect'
import FormItem from 'common/components/formItem'
import SingleSelect from 'common/components/singleSelect'
import { MergeTagListItem } from 'features/admin/accountSettings/api'

type Props = {
  systemMergeTags?: MergeTagListItem[]
}

const validate = (value: SelectValue) => {
  if (!value) {
    return 'Required'
  }
}

const Category = ({ systemMergeTags }: Props) => {
  const form = useForm()
  const formState = useFormState()
  const selectedIntegration = formState.values
    .merge_tag_integration as SelectValue

  const categories = useMemo(() => {
    const filteredCategories =
      systemMergeTags
        ?.filter((tag) => tag.integration === selectedIntegration?.value)
        .map(({ id, category }) => ({ label: category, value: id })) || []

    filteredCategories.push({
      label: 'Custom',
      value: 'custom',
    })

    return filteredCategories
  }, [systemMergeTags, selectedIntegration])

  const initialValue = useMemo(
    () => (categories.length === 1 ? categories[0] : undefined),
    [categories]
  )

  const resetFormValues = () => {
    form.change('merge_tag_description', undefined)
    form.change('merge_tag_code', undefined)
    form.change('merge_tag_example_values', undefined)
  }

  return (
    <Field<SelectValue>
      name="merge_tag_category"
      validate={validate}
      initialValue={initialValue}
    >
      {({ input, meta }) => (
        <FormItem
          label="Category"
          htmlFor={input.name}
          className="flex-none"
          error={meta.touched ? meta.error : undefined}
        >
          <SingleSelect
            data-testid="add-merge-tag-drawer-category-input"
            id={input.name}
            name={input.name}
            options={categories}
            value={input.value.value}
            onChange={(value) => {
              input.onChange(value)
              resetFormValues()
            }}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            isDisabled={!selectedIntegration}
          />
        </FormItem>
      )}
    </Field>
  )
}

export default Category
