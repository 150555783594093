import { useAuth0 } from '@auth0/auth0-react'

import Avatar from 'common/components/Avatar'
import { useAppSelector } from 'common/hooks/redux'
import LogoutButton from 'auth/LogoutButton'

const Welcome = ({
  firstUse,
  firstName,
  lastName,
  showSignoutButton,
}: {
  firstUse: boolean
  firstName?: string
  lastName?: string
  showSignoutButton?: boolean
}) => {
  const {
    profile: { avatarColor },
  } = useAppSelector((state) => state.authStates)
  const { isAuthenticated, isLoading } = useAuth0()

  const isSsoSignoutButtonVisible: boolean = isAuthenticated && !isLoading

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {firstName && lastName ? (
            <Avatar
              color={avatarColor}
              firstName={firstName}
              lastName={lastName}
              className="w-16 h-16 text-4xl font-medium"
            />
          ) : null}
        </div>
        {isSsoSignoutButtonVisible && <LogoutButton />}
      </div>
      <div className="flex justify-between">
        <div
          className="text-3xl text-coolGray-800 mt-6"
          data-cy="welcome-message"
          data-testid="welcome-message"
        >
          {`Hey ${firstName ? firstName : 'you'}, ${
            firstUse ? 'welcome to Jacquard!' : 'update your profile.'
          }`}
        </div>
      </div>
    </>
  )
}

export default Welcome
