import { useEffect, useMemo, useState } from 'react'
import { StatusMutator } from '@phrasee/phrasee-typings/Graphql/interfaces'
import { getColorForIndex } from 'workflow/CampaignSummary/PhraseeXContent/AdvancedVisualisations/graphsColorPalette'

import { connectInternalApi } from 'common/api'
import SentimentAnalysisWidget from 'common/components/SentimentAnalysis/SentimentAnalysisWidget'
import { useAppSelector } from 'common/hooks/redux'
import Logger from 'common/services/Logger'

import Loader from '../../common/components/loaders/Loader'

import SentimentAnalysisTable, { Data } from './SentimentAnalysisTable'

export type ResponseVariant = {
  text: string
  bandit_status?: { status: StatusMutator }
  _id: string
  ownsl: boolean
  sentiments: {
    [key: string]: {
      score: number
      weights: [string, string][]
    }
  }
}

export type VariantWithState = {
  _id: string
  variant: string
  variant_status: StatusMutator | undefined
}

type Props = {
  isReactCampaign: boolean
  variantsWithState: VariantWithState[]
  campaignId: string
}

type TableData = Omit<Data, 'color'>[]

function formatData(value: number | undefined): string {
  return value ? value.toFixed(1) : '-'
}

const Insights = ({
  isReactCampaign,
  variantsWithState,
  campaignId,
}: Props) => {
  const resultValues = useAppSelector(
    (state) =>
      state.campaignStates.results?.split_campaign_results?.values || []
  )
  const orderedVariants: string[] = useMemo(
    () => resultValues.map(({ variant }) => variant),
    [resultValues]
  )

  const [isLoading, setIsLoading] = useState(true)
  const [radarData, setRadarData] = useState<
    {
      name: string
      data: number[]
    }[]
  >([])
  const [tableData, setTableData] = useState<TableData>([])
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const [selectedSentiment, setSelectedSentiment] = useState('')

  useEffect(() => {
    const recordsInitiallySelected = isReactCampaign
      ? tableData.filter((record) => record.status === 'live')
      : tableData

    setSelectedRows(
      recordsInitiallySelected
        .map((record) => record.variant as string)
        .slice(0, 3)
    )
  }, [isReactCampaign, tableData])

  useEffect(() => {
    try {
      const fetch = async () => {
        const result = await connectInternalApi.get<{
          data: ResponseVariant[]
        }>(`v1/core/monorail/campaigns/${campaignId}/variants`)

        try {
          setRadarData(
            result.data.data.map((record) => {
              return {
                name: record.text,
                data: Object.values(record.sentiments ?? {}).map(
                  (sentiment) => sentiment.score
                ),
              }
            })
          )
        } catch (e) {
          // cannot render the graph because no sentiments are returned
          // a story will disable this tab when that is the case
        }

        const formattedData = result.data.data.map((record) => {
          const weightsPerSentiment = Object.entries(
            record.sentiments || {}
          ).reduce((current, [key, value]) => {
            return {
              ...current,
              [key]: value.weights ?? [],
            }
          }, {})

          return {
            key: record.text,
            variant: record.text,
            status: record.bandit_status?.status,
            isControlVariant: record.ownsl,
            ...Object.entries(record.sentiments ?? {}).reduce(
              (current, [key, value]) => {
                return {
                  ...current,
                  [key]: formatData(value.score),
                }
              },
              {}
            ),
            weightsPerSentiment,
          }
        })

        const orderedData = orderedVariants
          .map((variant) => formattedData.find(({ key }) => key === variant))
          .filter((val) => val !== undefined)

        setTableData(orderedData as TableData)
      }
      fetch()
    } catch (err) {
      Logger.error('Failed to fetch insights', err)
    } finally {
      setIsLoading(false)
    }
  }, [campaignId, orderedVariants])

  const radarDataFiltered = radarData.filter((serie) =>
    selectedRows.includes(serie.name)
  )

  const colorPerVariant: Record<string, string> = tableData.reduce(
    (previousValue, currentValue, index) => ({
      ...previousValue,
      [currentValue.variant as string]: getColorForIndex({
        index,
        highContrast: true,
      }),
    }),
    {}
  ) as Record<string, string>

  return (
    <div className="w-full h-full overflow-y-auto flex flex-col flex-auto">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="overflow-x-hidden flex-1-0 pb-24">
          <SentimentAnalysisWidget
            key={`${radarDataFiltered.length}`}
            series={radarDataFiltered
              .map((serie) => ({
                ...serie,
                data: serie.data.map((value) => {
                  return Number(formatData(value))
                }),
                color: colorPerVariant[serie.name],
              }))
              .filter((serie) => selectedRows.includes(serie.name))}
            onClickSentiment={setSelectedSentiment}
            selectedSentiment={selectedSentiment}
          />
          <SentimentAnalysisTable
            data={tableData.map((entry) => ({
              ...entry,
              key: entry.variant as string,
              variant: entry.variant as string,
              weightsPerSentiment: entry.weightsPerSentiment || {},
              status: variantsWithState.find(
                (variant) => variant.variant === (entry.variant as string)
              )?.variant_status,
              color: colorPerVariant[entry.variant as string],
            }))}
            isReactCampaign={isReactCampaign}
            onChangeSelectRow={setSelectedRows}
            selectedRowKeys={selectedRows}
            selectedSentiment={selectedSentiment}
          />
        </div>
      )}
    </div>
  )
}

export default Insights
