import { createContext, useContext, useReducer } from 'react'

import { TemplateInfoWithInternalId } from '../addedTemplates/AddedComponentList'

interface State {
  isExperimentFormVisible: boolean
  template?: TemplateInfoWithInternalId
}

type ExperimentFormReducerActions =
  | { type: 'set_experiment_form_visible'; value: boolean }
  | {
      type: 'set_experiment_form_template'
      value: TemplateInfoWithInternalId | undefined
    }
  | { type: 'reset_experiment_form' }

const initialState: State = {
  isExperimentFormVisible: false,
  template: undefined,
}

const experimentFormReducer = (
  state: State,
  action: ExperimentFormReducerActions
) => {
  switch (action.type) {
    case 'set_experiment_form_visible':
      return { ...state, isExperimentFormVisible: action.value }
    case 'set_experiment_form_template':
      return {
        ...state,
        isExperimentFormVisible: true,
        template: action.value,
      }
    case 'reset_experiment_form':
      return initialState
    default:
      return state
  }
}

const ExperimentFormContext =
  createContext<
    | {
        state: State
        dispatch: (action: ExperimentFormReducerActions) => void
      }
    | undefined
  >(undefined)

const ExperimentFormProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [state, dispatch] = useReducer(experimentFormReducer, initialState)
  const value = { state, dispatch }

  return (
    <ExperimentFormContext.Provider value={value}>
      {children}
    </ExperimentFormContext.Provider>
  )
}

const useExperimentForm = () => {
  const context = useContext(ExperimentFormContext)
  if (context === undefined) {
    throw new Error(
      'useExperimentForm must be used within a ExperimentFormProvider'
    )
  }
  return context
}

export { ExperimentFormProvider, useExperimentForm }
