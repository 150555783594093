import cx from 'classnames'

import BaseCell from 'common/components/table/cells/Base'

type Props = {
  channel: string
  value: string
  isPersonalisedVariant?: boolean
}

const splitValue = (value: string) => {
  const [header, ...body] = value.split('\n')
  return { header, body: body.length > 0 ? body.join('\n') : null }
}

const VariantCell = ({ channel, value, isPersonalisedVariant }: Props) => {
  const { header, body } = splitValue(value)

  return channel === 'push' ? (
    <div
      className={cx('flex flex-col justify-center px-4 py-3 w-full ', {
        'bg-yellow-50 group-hover:bg-yellow-100': isPersonalisedVariant,
      })}
    >
      <div className="font-semibold">{header}</div>
      {body && <div className="whitespace-pre-line">{body}</div>}
    </div>
  ) : (
    <BaseCell
      className={cx('p-3 whitespace-pre-line w-full', {
        'bg-yellow-50 group-hover:bg-yellow-100': isPersonalisedVariant,
      })}
    >
      {value}
    </BaseCell>
  )
}

export default VariantCell
