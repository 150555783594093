import { Children, CSSProperties, FC } from 'react'
import cx from 'classnames'

export type WidgetType = 'default' | 'table' | 'inner' | 'raised' | 'basic'

export interface WidgetProps {
  type?: WidgetType
  className?: string
  bgClassName?: string
  defaultPadding?: boolean
  'data-cy'?: string
  'data-testid'?: string
  style?: CSSProperties
  direction?: 'column' | 'row'
}

// TODO: remove bgClassName once Tailwind is updated
const Widget: FC<WidgetProps> = ({
  children,
  className,
  bgClassName = 'bg-white',
  type = 'default',
  'data-cy': dataCy,
  'data-testid': dataTestId,
  style,
  direction = 'row',
  defaultPadding = true,
}) => (
  <div
    data-cy={dataCy}
    data-testid={dataTestId}
    className={cx(
      `w-full flex flex-col ${bgClassName || 'bg-white'}`,
      {
        'p-6': ['default', 'raised', 'basic'].includes(type) && defaultPadding,
        'border border-solid': type !== 'inner',
        'shadow-widget': ['default', 'table'].includes(type),
        'shadow-md': type === 'raised',
        'overflow-hidden gap-6': direction === 'column',
      },
      className
    )}
    style={style}
  >
    {direction === 'row'
      ? children
      : Children.map(children, (child, index) =>
          child ? (
            <div key={index}>
              {child}
              {index < Children.count(children) - 1 ? (
                <div className="border border-coolGray-200 relative -ml-6 mt-6 w-screen overflow-hidden" />
              ) : undefined}
            </div>
          ) : null
        )}
  </div>
)

export default Widget
