import { useRef } from 'react'

import {
  DropdownType,
  FieldConfiguration,
  FieldType,
} from 'common/components/dynamicFields/interfaces'

interface Props {
  experimentMainFields?: FieldConfiguration[]
}

export const useGetVariantDropdownOptions = ({
  experimentMainFields,
}: Props) => {
  const initialValueRef = useRef<number | undefined>()

  const variantDropdownOptions = experimentMainFields?.find(
    (field): field is DropdownType =>
      field.name === 'split_num' && field.type === FieldType.Dropdown
  )?.options

  const defaultVariantDropdownOption = variantDropdownOptions?.find(
    // TODO: There is a typo on Backend side, so until it's fixed, we need to use defalut instead of default.
    // @ts-ignore
    (option) => option?.defalut
  )

  // Set initial value to default variant dropdown option if it's not set yet.
  // This prevents the value from being overridden by the initial value from the field in VariantDropdown.
  if (!initialValueRef.current && defaultVariantDropdownOption?.value) {
    initialValueRef.current = Number(defaultVariantDropdownOption.value)
  }

  return {
    variantDropdownOptions,
    defaultVariantDropdownOption,
    initialVariantDropdownValue: initialValueRef.current,
  }
}
