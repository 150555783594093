import { useState } from 'react'
import cx from 'classnames'

import { AddSquare } from 'common/icons'

import AddTopicsModal from './components/modal'

type Props = {
  isDisabled?: boolean
}

const AddTopicButton = ({ isDisabled }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  return (
    <>
      <button
        data-cy="add-topics-button"
        data-testid="add-topics-button"
        onClick={() => setIsModalOpen(true)}
        className={cx('flex uppercase text-gold-600 text-xs outline-none', {
          'opacity-50 cursor-not-allowed': isDisabled,
          'hover:text-base-700': !isDisabled,
        })}
        disabled={isDisabled}
      >
        <span className="font-medium underline">Add Topics</span>
        <AddSquare isDefaultColor={false} size={4} className="ml-1" />
      </button>
      <AddTopicsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  )
}

export default AddTopicButton
