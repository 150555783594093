import FormItem from 'common/components/formItem'
import Tags from 'common/components/tags'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'

import { CommonFieldPropsGeneric, Option, TagWidgetType } from '../interfaces'

const ERROR_MESSAGES = {
  required: 'Please enter an answer.',
}

const MAX_LENGTH_PER_TAG = 20

type Props = CommonFieldPropsGeneric & {
  value: TagWidgetType['value']
  componentProps?: TagWidgetType['componentProps']
  hasTagButtonLabel?: TagWidgetType['has_tag_button_label']
  tagButtonLabel?: TagWidgetType['tag_button_label']
}

export type Options = readonly Option[]

const TagWidget = ({
  label,
  name,
  value,
  errorCode,
  tooltip,
  placeholder,
  componentProps,
  onChange,
  hasTagButtonLabel,
  tagButtonLabel,
  'data-testid': dataTestId,
}: Props) => {
  const values = value ? value.map((val) => ({ label: val, value: val })) : []

  const setValues = (values: Options) => {
    onChange(values.length > 0 ? values.map((val) => val.value) : undefined)
  }

  const handleCreate = (inputValue: string) => {
    const trimmedValue = inputValue.trim()

    if (trimmedValue !== '') {
      const newTag = { label: trimmedValue, value: trimmedValue }
      setValues([...values, newTag])
    }
  }

  const handleRmove = (removableValue: string) => {
    const newValues = values.filter((val) => val.value !== removableValue)
    setValues(newValues)
  }

  return (
    <FormItem
      htmlFor={name}
      error={errorCode ? ERROR_MESSAGES[errorCode] : undefined}
      style={componentProps?.style}
    >
      <Widget
        data-testid={dataTestId}
        type="basic"
        style={componentProps?.style}
      >
        <WidgetHeader title={label} subtitle={tooltip} />
        <Tags
          data-cy="tags"
          data-testid="tags"
          isFreeText={true}
          onAddClick={handleCreate}
          onRemoveClick={handleRmove}
          tags={values}
          placeholder={placeholder}
          maxLength={MAX_LENGTH_PER_TAG}
          hasTagButtonLabel={hasTagButtonLabel}
          tagButtonLabel={tagButtonLabel}
        />
      </Widget>
    </FormItem>
  )
}

export default TagWidget
