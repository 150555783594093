import { FC, useEffect, useRef, useState } from 'react'
import { matchPath, useLocation } from 'react-router'
import { selectedCampaignPath } from 'app/navigation/paths'
import debounce from 'lodash/debounce'

import { createCancelTokenSource, isCancel } from 'common/api'
import Autocomplete from 'common/components/autocomplete'
import Button from 'common/components/button'
import Loader from 'common/components/loaders/Loader'
import { errorToast } from 'common/components/toastNotification'
import { Topic } from 'common/components/topics/interfaces'
import { useAppSelector } from 'common/hooks/redux'
import { ArrowRight } from 'common/icons'
import { getNodes } from 'features/campaigns/create/contentGeneration/api'

import Footer from '../../components/footer'
import Option from '../../components/option'

interface Props {
  searchTerm: string
  onSearch: (val: string) => void
  onDoneClick: () => void
}

const SelectTopics: FC<Props> = ({ searchTerm, onSearch, onDoneClick }) => {
  const location = useLocation()
  const [isLoadingNodes, setIsLoadingNodes] = useState<boolean>(false)
  const [nodes, setNodes] = useState<Topic[]>()

  const campaignId = useAppSelector(
    (state) => state.campaignStates.currentCampaignId
  )
  const isOnCampaignsRoute = !!matchPath(location.pathname, {
    path: selectedCampaignPath,
  })

  const optionListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const source = createCancelTokenSource()
    setIsLoadingNodes(true)

    const getNodesAsync = debounce(async () => {
      try {
        const result = await getNodes({
          campaignId: isOnCampaignsRoute ? campaignId : '',
          searchTerm,
          cancelToken: source.token,
        })

        setNodes(result)
        optionListRef.current?.scrollTo(0, 0)
      } catch (error) {
        if (!isCancel(error)) {
          errorToast('Failed to load nodes')
        }
      } finally {
        setIsLoadingNodes(false)
      }
    }, 500)

    getNodesAsync()
    return () => {
      getNodesAsync.cancel()
      source.cancel()
    }
  }, [campaignId, isOnCampaignsRoute, searchTerm])

  return (
    <div className="h-full flex flex-col justify-between gap-10">
      <div className="w-150 self-center">
        <Autocomplete
          data-cy="topic-search-select"
          data-testid="topic-search-select"
          type="search"
          placeholder="Search for a topic…"
          onSearch={(val) => onSearch(val)}
          options={undefined}
          menuIsOpen={false}
        />
        <div className="mt-8">
          <div className="mb-4 mx-6 text-base text-coolGray-400">
            {searchTerm.length
              ? `Search results ${nodes?.length}`
              : 'Suggested topics'}
          </div>
          <Loader isLoading={isLoadingNodes}>
            <div
              className="overflow-y-auto"
              style={{ maxHeight: `calc(100vh - 270px)` }}
              ref={optionListRef}
            >
              {nodes?.map((node) => {
                const { id } = node

                return <Option key={id} topic={node} />
              })}
            </div>
          </Loader>
        </div>
      </div>

      <Footer>
        <div className="col-start-2 self-center">Add topics</div>
        <Button
          data-cy="done-button"
          data-testid="done-button"
          suffixIcon={<ArrowRight isDefaultColor={false} />}
          variant="primary"
          className="place-self-end"
          onClick={onDoneClick}
        >
          Done
        </Button>
      </Footer>
    </div>
  )
}

export default SelectTopics
