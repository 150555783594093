import { useState } from 'react'
import Menu, { Item as MenuItem } from 'rc-menu'

import Button from 'common/components/button/Button'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import type { Sort, SortBy } from '../store/imagesSlice'
import { initialState, onSortChange, onSortReset } from '../store/imagesSlice'

import ImageSortMenuItem from './ImageSortMenuItem'

type Option = {
  key: SortBy
  label: string
}

const options: Option[] = [
  {
    key: 'createdAt',
    label: 'Order by date:',
  },
  {
    key: 'fileName',
    label: 'Order by title:',
  },
]

const ImageSortMenu = () => {
  const dispatch = useAppDispatch()

  const sort = useAppSelector((state) => state.images.sort)

  const [menuSort, setMenuSort] = useState<Sort>(sort)

  return (
    <Menu
      selectable={false}
      className="z-40 px-4 py-2 bg-gold-50 border border-gold-200 rounded-sm"
    >
      {options.map(({ key, label }) => {
        const isActive = key === menuSort.sortBy

        return (
          <ImageSortMenuItem
            key={key}
            sortBy={key}
            sortOrder={isActive ? menuSort.sortOrder : 'ASC'}
            isActive={isActive}
            label={label}
            onClick={(value: Sort) => setMenuSort(value)}
          />
        )
      })}
      <hr className="w-full my-2 border-gold-400" />
      <MenuItem className="flex justify-between">
        <Button
          size="small"
          variant="link"
          onClick={() => {
            setMenuSort(initialState.sort)
            dispatch(onSortReset())
          }}
          className="uppercase underline"
        >
          Reset
        </Button>
        <Button
          onClick={() => dispatch(onSortChange(menuSort))}
          variant="primary"
          className="uppercase"
          size="small"
        >
          Apply
        </Button>
      </MenuItem>
    </Menu>
  )
}

export default ImageSortMenu
