import { ReactFragment } from 'react'
import cx from 'classnames'

import Radio, { RadioIcon, RadioVariant } from './components/radio'

export type RadioLabel = string | ReactFragment
type Option<T> = {
  label: RadioLabel
  value: T
  icon?: RadioIcon
  'data-cy'?: string
  'data-testid'?: string
}

type Props<T> = {
  name: string
  options: Option<T>[]
  variant?: RadioVariant
  direction?: 'horizontal' | 'vertical'
  onChange: (val: T) => void
  value?: T
  isDisabled?: boolean
  className?: string
  radioClassName?: string
  'data-cy'?: string
  'data-testid'?: string
}

const RadioGroup = <T extends string>({
  value: selectedValue,
  variant = 'normal',
  direction = 'horizontal',
  name,
  options,
  onChange,
  isDisabled = false,
  className,
  radioClassName,
  'data-cy': dataCy,
  'data-testid': dataTestId,
}: Props<T>) => {
  return (
    <div
      data-cy={dataCy}
      data-testid={dataTestId}
      className={cx(
        'flex gap-4',
        { 'flex-col': direction === 'vertical' },
        className
      )}
    >
      {options.map(
        ({
          label,
          value,
          icon,
          'data-cy': dataCyOption,
          'data-testid': dataTestIdOption,
        }) => (
          <Radio
            key={value}
            data-cy={dataCyOption}
            data-testid={dataTestIdOption}
            className={radioClassName}
            name={name}
            variant={variant}
            icon={icon}
            label={label}
            isChecked={value === selectedValue}
            onChange={() => onChange(value)}
            isDisabled={isDisabled}
          />
        )
      )}
    </div>
  )
}

export default RadioGroup
