import { useEffect, useReducer } from 'react'
import { useForm } from 'react-final-form'

import Button from 'common/components/button'
import ConfirmationModal from 'common/components/confirmationModal'
import useCreateCustomerJourneyMutation from 'features/unifiedFlow/api/mutations/useCreateCustomerJourneyMutation'

import CreateCustomerJourney from './CreateCustomerJourney'
import {
  createCustomerJourneyInitialState,
  CreateCustomerJourneyReducer,
} from './createCustomerJourneyReducer'
import SingleSelectField from './SingleSelectField'

export const validateNotEmptyField = (value: string | undefined) => {
  return !value ? 'Required' : undefined
}

type Props = {
  name: string
  options: { value: string; label: string }[] | undefined
}

const CustomerJourney = ({ name, options }: Props) => {
  const form = useForm()
  const [createCustomerJourneyState, createCustomerJourneyDispatch] =
    useReducer(CreateCustomerJourneyReducer, createCustomerJourneyInitialState)

  const createCustomerJourneyMutation = useCreateCustomerJourneyMutation()

  useEffect(() => {
    if (createCustomerJourneyMutation.isSuccess) {
      createCustomerJourneyDispatch({
        type: 'createJourneyComplete',
      })
    }
  }, [createCustomerJourneyMutation.isSuccess])

  return (
    <>
      <SingleSelectField
        name={name}
        label="Customer journey"
        placeholder="Select customer journey..."
        initialValue={options?.length === 1 ? options[0].value : undefined}
        options={options}
        validate={validateNotEmptyField}
        guidingText="This will set the use case to help define generated content by scenario."
        noOptionsMessage={() => (
          <span className="uppercase">No customer journeys detected</span>
        )}
        footer={
          <div className="flex items-center justify-end">
            <Button
              variant="secondary"
              onClick={() =>
                createCustomerJourneyDispatch({ type: 'openForm' })
              }
            >
              Add new customer journey
            </Button>
          </div>
        }
      />
      {createCustomerJourneyState.isCustomerJourneyFormOpen && (
        <CreateCustomerJourney
          isOpen={createCustomerJourneyState.isCustomerJourneyFormOpen}
          isLoading={createCustomerJourneyMutation.status === 'loading'}
          onCancel={() =>
            createCustomerJourneyDispatch({
              type: 'cancelCreation',
            })
          }
          onCreateCustomerJourney={({
            contextDescription,
            customerJourneyName,
          }) => {
            form.change(
              name,
              customerJourneyName
                .split(' ')
                .map((word) => word.toLocaleLowerCase())
                .join('-')
            )
            createCustomerJourneyMutation.mutate({
              customerJourneyName,
              contextDescription,
            })
          }}
        />
      )}
      <ConfirmationModal
        onCancel={() =>
          createCustomerJourneyDispatch({ type: 'cancelConfirmation' })
        }
        onConfirm={() => {
          createCustomerJourneyDispatch({ type: 'closeConfirmation' })
        }}
        open={createCustomerJourneyState.isConfirmationOpen}
        title="Are you sure?"
        confirmationText="Are you sure you want to leave without creating a customer journey?"
        cancelButtonText="Stop, don't do it"
        confirmButtonText="Close"
      />
    </>
  )
}

export default CustomerJourney
