import { UniqueIdentifier } from '@dnd-kit/core'
import { useSortable } from '@dnd-kit/sortable'

type Props = {
  id: UniqueIdentifier
  className: string
  'data-testid'?: string
}

const DroppableContainer: React.FC<Props> = ({
  id,
  className,
  children,
  'data-testid': dataTestId = 'droppable-container',
}) => {
  const { attributes, listeners, setNodeRef } = useSortable({
    id,
    data: {
      type: 'container',
    },
    disabled: true,
  })

  const style = {
    cursor: 'default',
  }

  return (
    <div
      className={className}
      data-testid={dataTestId}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  )
}

export default DroppableContainer
