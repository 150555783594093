import { ReactNode } from 'react'

import Button from './button/Button'

export interface Props {
  cardText: string | ReactNode
  cardTitle: string
  buttonText: string
  isDisabled: boolean
  onSubmit: () => void
  onReject: () => void
  rejectButtonText: string
  isLoading: boolean
}

export default function ConfirmationCard({
  cardText,
  onSubmit,
  onReject,
  isLoading,
  cardTitle,
  buttonText,
  isDisabled,
  rejectButtonText,
}: Props) {
  return (
    <div className="bg-white p-4 border border-gray-200">
      <div className="flex text-2xl mb-4">
        <div className="font-bold mt-2 text-base-700">{cardTitle}</div>
      </div>
      <div className="mb-4 text-coolGray-800">{cardText}</div>
      <div className="content-end flex flex-wrap gap-4">
        <Button
          ghost
          disabled={isDisabled}
          data-testid="confirmRejectBtn"
          onClick={() => onReject()}
          className="ml-auto"
        >
          {rejectButtonText}
        </Button>
        <Button
          variant="primary"
          loading={isLoading}
          disabled={isDisabled}
          data-testid="confirmBtn"
          onClick={() => onSubmit()}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  )
}
