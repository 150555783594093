import cx from 'classnames'

type Props = {
  text: string
  onClick
  className?: string
}

const ZoneButton = ({ text, onClick, className }: Props) => {
  return (
    <div
      className={cx(
        'bg-coolGray-50 flex items-center justify-center hover:bg-maroon-40 hover:text-maroon-400 cursor-pointer relative group',
        'hover:font-medium',
        className
      )}
      onClick={() => {
        onClick()
      }}
    >
      <span
        className="text-coolGray-400 flex items-center justify-center cursor-pointer  text-center group-hover:text-maroon-400"
        style={{ width: 202 }}
      >
        {text}
      </span>
    </div>
  )
}

export default ZoneButton
