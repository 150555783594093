import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'
import { getAccountsList } from 'features/accounts/api'

import { contentKeys } from '../queryKeys'

const useGetPrivacyRegionsQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const userId = useAppSelector((state) => state.authStates.user_id)

  const getPrivacyRegionsQuery = useQuery(
    contentKeys.regions({ userId }),
    () => getAccountsList(userId),
    {
      select: (data) => {
        const currentAccount = data.find((entry) => entry.id === accountId)
        return currentAccount?.privacyRegions?.map((region) => ({
          label: region,
          value: region,
        }))
      },
      meta: {
        errorMessage: 'Privacy regions could not be loaded',
      },
    }
  )

  return getPrivacyRegionsQuery
}

export default useGetPrivacyRegionsQuery
