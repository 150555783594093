import Button from 'common/components/button'
import { errorToast } from 'common/components/toastNotification'
import { ArrowRight } from 'common/icons'

type Props = {
  isReadOnly: boolean
  isApprovable: boolean
}

const FooterButtons = ({ isReadOnly, isApprovable }: Props) => {
  return (
    <div className="flex gap-2">
      <Button variant="link" onClick={() => errorToast('Not implemented yet')}>
        {isReadOnly ? 'Prompt' : 'Edit prompt'}
      </Button>
      {!isReadOnly && (
        <Button
          variant="primary"
          disabled={!isApprovable}
          onClick={() => errorToast('Not implemented yet')}
          suffixIcon={<ArrowRight size={4} isDefaultColor={false} />}
        >
          Approve combination
        </Button>
      )}
    </div>
  )
}

export default FooterButtons
