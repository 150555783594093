import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import isUndefined from 'lodash/isUndefined'
import last from 'lodash/last'
import some from 'lodash/some'

import type { ImageEntity } from 'common/components/images/api/api'
import {
  getInitialImageIds,
  getInitialVariantOnly,
  getNonEmptyImages,
} from 'common/components/images/helpers'
import { infoToast } from 'common/components/toastNotification'
import { useSelectedComponentElement } from 'features/unifiedFlow/contentPage/hooks'

import EditableImageCard from './components/editableImageCard'
import ImageGalleryModal from './components/imageGalleryModal'
import ImagesActionButtons from './components/imagesActionButtons'
import VariantOnlyToggle from './components/variantOnlyToggle'
import type { Images } from './hooks/useImagesReducer'
import useImagesReducer from './hooks/useImagesReducer'

type Props = {
  isReadOnly?: boolean
  isVariantOnly: boolean
  images: (ImageEntity | undefined)[]
}

const ImageSelector = ({ isReadOnly, isVariantOnly, images }: Props) => {
  const { data: selectedElement } = useSelectedComponentElement()

  const {
    state,
    onSelectImage,
    onDeleteImage,
    onReplaceImage,
    showImageGallery,
    onAddImages,
    toggleVariantOnly,
  } = useImagesReducer((initialState) => {
    const updatedImagesSlots = isVariantOnly
      ? (initialState.imagesSlots.slice(0, 2) as Images)
      : ([...initialState.imagesSlots.slice(0, 2), undefined] as Images)

    return {
      ...initialState,
      isVariantOnly,
      imagesSlots: updatedImagesSlots.map((item, index) => {
        return images[index] ? images[index] : item
      }) as Images,
    }
  })

  const nonEmptyImages = getNonEmptyImages(state.imagesSlots)
  const emptySlotsLength = nonEmptyImages.length
  const imageIds = nonEmptyImages.map((image) => image.id)

  const isApproveButtonDisabled =
    some(state.imagesSlots, isUndefined) ||
    (isEqual(getInitialImageIds(selectedElement), state.imagesSlotsIds) &&
      isEqual(getInitialVariantOnly(selectedElement), state.isVariantOnly))

  const handleToggleVariantOnly = () => {
    if (
      state.imagesSlots.length === 3 &&
      !isUndefined(last(state.imagesSlots))
    ) {
      infoToast(
        'Your last image was removed due to enabling variant-only mode.'
      )
    }

    toggleVariantOnly()
  }

  return (
    <div>
      <VariantOnlyToggle
        isActionsDisabled={isReadOnly}
        isVariantOnly={state.isVariantOnly}
        toggleVariantOnly={handleToggleVariantOnly}
        onLoadedVariantOnly={(isVariantOnly) => {
          toggleVariantOnly(isVariantOnly)
        }}
      />
      <p className="text-base text-gold-700 font-normal mb-3">{`Added images: ${emptySlotsLength}/${state.imagesSlots.length}`}</p>
      <div className="flex gap-2 flex-wrap">
        {state.imagesSlots.map((image, index) => {
          return (
            <EditableImageCard
              isActionsDisabled={isReadOnly}
              index={index}
              key={`editable-image-${index}`}
              image={image}
              onDeleteImage={onDeleteImage}
              onReplaceImage={onReplaceImage}
              showImageGallery={showImageGallery}
            />
          )
        })}
      </div>
      <ImageGalleryModal
        previewIndex={get(
          state,
          `selectedImages[${state.previewIndex}].id`,
          undefined
        )}
        visible={state.isImageGalleryVisible}
        selectedImagesIds={state.selectedImagesIds}
        imageSelectionLimit={state.imagesSlots.length}
        onCancel={() => showImageGallery(false)}
        onAdd={() => onAddImages()}
        onSelectImage={(selectedItem) => onSelectImage(selectedItem)}
      />
      <ImagesActionButtons
        isVariantOnly={state.isVariantOnly}
        imageIds={imageIds}
        isReadOnly={isReadOnly}
        isApproveButtonDisabled={isApproveButtonDisabled}
      />
    </div>
  )
}

export default ImageSelector
