import { useEffect, useRef, useState } from 'react'

import { createCancelTokenSource, isCancel } from 'common/api'
import ConfirmationModal from 'common/components/confirmationModal'
import { DetailsPanelButton as Button } from 'common/components/detailsPanel'
import { errorToast, successToast } from 'common/components/toastNotification'
import { Deactivate } from 'common/icons'
import useUpdateProjectActionMutation from 'features/projects/mutations/useUpdateProjectActionMutation'

interface Props {
  accountId: string
  projectId: string
  isExpanded: boolean
}

const DeactivateButton = ({ isExpanded, accountId, projectId }: Props) => {
  const mutation = useUpdateProjectActionMutation()
  const sourceRef = useRef(createCancelTokenSource())

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    const source = sourceRef.current
    return () => source.cancel()
  }, [])

  const deactivate = async () => {
    const source = sourceRef.current

    try {
      await mutation.mutateAsync({
        action: 'deleted',
        value: true,
        accountId,
        projectId,
        source,
      })
      successToast('Project deactivated successfully')
      setIsModalOpen(false)
    } catch (error) {
      if (!isCancel(error)) {
        errorToast('Failed to deactivate project')
      }
    }
  }

  return (
    <>
      <Button
        isExpanded={isExpanded}
        data-cy="deactivate-project-button"
        data-testid="deactivate-project-button"
        title="Deactivate project"
        onClick={() => setIsModalOpen(true)}
        icon={<Deactivate />}
      />
      <ConfirmationModal
        data-cy="deactivate-project-confirm-modal"
        data-testid="deactivate-project-confirm-modal"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={deactivate}
        title="Deactivate project?"
        confirmationText="You will no longer be able to use it to create new experiments but you will be able 
        to see previous experiments and report on project for the period it was in use."
        confirmButtonText="Yes, deactivate it!"
        isLoading={mutation.isLoading}
      />
    </>
  )
}

export default DeactivateButton
