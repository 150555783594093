import type { FileUploaderFileType } from 'common/components/fileUploader'

import ImageDetailsCard from './ImageDetailsCard'

type Props = {
  images: FileUploaderFileType[]
  onReplace: (file: FileUploaderFileType) => void
}
const ImagesUploadList = ({ images, onReplace }: Props) => {
  if (images.length === 0) {
    return null
  }

  return (
    <div className="flex flex-col mt-10">
      <p className="mb-4 text-base-700 text-base font-medium">Files</p>
      <div className="grid grid-cols-2 gap-2 min-h-9">
        {images.map((image) => {
          return (
            <ImageDetailsCard
              key={image.id}
              image={image}
              onReplace={() => onReplace(image)}
            />
          )
        })}
      </div>
    </div>
  )
}

export default ImagesUploadList
