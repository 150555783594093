import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import { getImagesByIds } from '../api'
import { imagesKeys } from '../queryKeys'

const useGetImagesByIdsQuery = (imageIds: string[]) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const query = useQuery({
    queryKey: imagesKeys.byImageIds(accountId, imageIds),
    queryFn: () => getImagesByIds(accountId, imageIds),
    enabled: !!accountId && imageIds.length > 0,
  })

  return {
    ...query,
    data: query.data?.data || [],
  }
}

export default useGetImagesByIdsQuery
