import { useInfiniteQuery } from '@tanstack/react-query'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import uniqBy from 'lodash/uniqBy'

import type { ImagePageResponse } from 'common/components/images/api/api'
import { useAppSelector } from 'common/hooks/redux'

import { getImages } from '../api'
import { imagesKeys } from '../queryKeys'

const useGetImagesInfiniteQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const pagination = useAppSelector((state) => state.images.pagination)
  const query = useAppSelector((state) => state.images.query)
  const sort = useAppSelector((state) => state.images.sort)

  const filteredQueryParams = omitBy({ ...pagination, ...sort, query }, isNil)

  const { data, ...rest } = useInfiniteQuery<ImagePageResponse>(
    imagesKeys.infiniteQueryParams(accountId, filteredQueryParams),
    ({ pageParam = filteredQueryParams }) => {
      return getImages(accountId, pageParam)
    },
    {
      enabled: !!accountId,
      getNextPageParam: (lastPage, allPages) => {
        const totalItems = lastPage.total
        const itemsFetched = allPages.flatMap((page) => page.data).length

        return itemsFetched < totalItems
          ? {
              ...filteredQueryParams,
              offset: itemsFetched,
            }
          : undefined
      },
    }
  )

  return {
    data: uniqBy(data?.pages.flatMap((page) => page.data) || [], 'id'),
    ...rest,
  }
}

export default useGetImagesInfiniteQuery
