import { useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useParams } from 'react-router-dom'
import RouterPromptStay from 'app/router/RouterPromptStay'
import cx from 'classnames'

import BottomToolbar from 'common/bottomToolbar'
import Button from 'common/components/button'
import ConfirmationModal from 'common/components/confirmationModal'
import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import Footer from 'common/components/layout/Footer'
import MenuButton, { MenuItem } from 'common/components/menuButton'
import PageHeader from 'common/components/PageHeader'
import Scrollable from 'common/components/scrollable'
import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'
import { ChevronDown } from 'common/icons'
import { ProjectMappedUiFields } from 'common/interfaces/projects'
import ProjectFormCards from 'features/projects/components/projectFormCards'
import { validateProjectName } from 'features/projects/helpers'
import { UpdateProjectPayload } from 'features/projects/interface'
import useUpdateProjectMutation from 'features/projects/mutations/useUpdateProjectMutation'
import useProjectConfigQuery from 'features/projects/queries/useProjectConfigQuery'
import useProjectsQuery from 'features/projects/queries/useProjectsQuery'
import { showInvalidFormToast } from 'features/projects/utils/errors'

import SelectCampaignsModal from './selectCampaignsModal'

type FormValues = ProjectMappedUiFields & Partial<UpdateProjectPayload>

const Settings = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const { data: projects } = useProjectsQuery(accountId)
  const { projectId } = useParams<{ projectId: string }>()
  const projectConfigQuery = useProjectConfigQuery({ accountId, projectId })
  const updateProjectMutation = useUpdateProjectMutation()
  const mappedUIFields = projectConfigQuery.data?.mappedUIFields
  const isLinguo = mappedUIFields?.languageGenerationMethod === 'linguo'
  const savedAssignedUsers = mappedUIFields?.projectAccess?.assignedUsers

  const [isSelectCampaignsModalOpen, setIsSelectCampaignsModalOpen] =
    useState<boolean>(false)
  const [selectedCampaignIds, setSelectedCampaignIds] = useState<string[]>([])
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    useState<boolean>(false)

  const availableProjectNames = projects
    ?.filter(({ id }) => id !== projectId)
    .map(({ name }) => name)

  useEffect(() => {
    if (!isSelectCampaignsModalOpen) {
      setSelectedCampaignIds([])
    }
  }, [isSelectCampaignsModalOpen])

  const validateName = (value: string | undefined) => {
    if (!value) {
      return 'Please enter project name'
    }

    const { error } = validateProjectName(value, availableProjectNames)
    return error
  }

  let isFormValid = true
  let submit

  const onSubmitClick = () => {
    if (!isFormValid) {
      showInvalidFormToast()
    }

    submit()
  }

  // will always be defined as project config is fetched before rendering this component
  if (mappedUIFields) {
    return (
      <Form<FormValues>
        key={projectId}
        initialValues={{
          ...mappedUIFields,
          // handle projects created trough skinner
          projectType: mappedUIFields.projectType ?? 'experiment',
          sandboxModeEnabled: mappedUIFields.sandboxModeEnabled ?? false,
          // "none" option is not saved on BE
          splitSettingsType: mappedUIFields.splitSettingsType ?? 'none',
        }}
        onSubmit={async (values: FormValues) => {
          try {
            if (!values.applyChangesToExistingCampaignType) {
              throw new Error('applyChangesToExistingCampaignType was not set')
            } else {
              const config = values as Required<FormValues>
              const newAssignedUsers =
                config.projectAccess?.assignedUsers.filter(
                  (id) => !savedAssignedUsers?.includes(id)
                )
              const response = await updateProjectMutation.mutateAsync({
                accountId,
                projectId,
                config: {
                  ...config,
                  projectAccess: {
                    ...config.projectAccess,
                    assignedUsers: newAssignedUsers,
                  },
                },
              })

              if (response.state === 'validationError') {
                showInvalidFormToast()
                return response.validationErrors
              } else if (response.state === 'success') {
                projectConfigQuery.refetch()
                return response
              } else {
                return response
              }
            }
          } catch {
            errorToast('Failed to update project')
          }
        }}
        destroyOnUnregister
        render={({
          handleSubmit,
          dirty: isFormDirty,
          form,
          hasValidationErrors,
        }) => {
          submit = handleSubmit
          isFormValid = !hasValidationErrors

          return (
            <>
              <Scrollable
                isFullHeight={false}
                content={
                  <div
                    className={cx('px-8', {
                      'pt-8': isLinguo,
                      'pt-12': !isLinguo,
                    })}
                  >
                    {isLinguo && (
                      <PageHeader className="mb-8">
                        <div>
                          <div className="text-xl text-coolGray-800 font-medium">
                            Settings
                          </div>
                          <div className="text-coolGray-400">
                            About projects and what they are for...
                          </div>
                        </div>
                      </PageHeader>
                    )}

                    <form>
                      <Field<string> name="name" validate={validateName}>
                        {({ input, meta }) => (
                          <FormItem
                            label="Project name"
                            htmlFor="name"
                            error={meta.dirty ? meta.error : undefined}
                          >
                            <Input
                              data-cy="project-name"
                              data-testid="project-name"
                              className="max-w-xl"
                              type="text"
                              variant="default"
                              id="name"
                              name="name"
                              placeholder="Project name"
                              value={input.value}
                              onChange={input.onChange}
                              onBlur={input.onBlur}
                            />
                          </FormItem>
                        )}
                      </Field>
                      <ProjectFormCards />
                    </form>

                    <Footer />
                  </div>
                }
                footer={
                  isFormDirty && (
                    <BottomToolbar className="justify-end pr-18">
                      <MenuButton
                        align="end"
                        options={
                          <>
                            <MenuItem
                              data-cy="apply-option-new"
                              data-testid="apply-option-new"
                              onClick={(event) => {
                                form.change(
                                  'applyChangesToExistingCampaignType',
                                  'none'
                                )
                                onSubmitClick()
                              }}
                            >
                              New experiments only
                            </MenuItem>
                            <MenuItem
                              data-cy="apply-option-all"
                              data-testid="apply-option-all"
                              onClick={() => setIsConfirmationModalOpen(true)}
                            >
                              All experiments (new and existing)
                            </MenuItem>
                            <MenuItem
                              data-cy="apply-option-selected"
                              data-testid="apply-option-selected"
                              onClick={() =>
                                setIsSelectCampaignsModalOpen(true)
                              }
                            >
                              New and select existing experiments
                            </MenuItem>
                          </>
                        }
                        menuButton={
                          <Button
                            data-cy="apply-to-button"
                            data-testid="apply-to-button"
                            variant="primary"
                            loading={updateProjectMutation.isLoading}
                            disabled={updateProjectMutation.isLoading}
                            suffixIcon={
                              <ChevronDown
                                className="text-maroon-200"
                                size={4}
                              />
                            }
                          >
                            Apply to
                          </Button>
                        }
                      />

                      <ConfirmationModal
                        data-cy="confirm-apply-all-modal"
                        data-testid="confirm-apply-all-modal"
                        open={isConfirmationModalOpen}
                        confirmationText="The changes will be applied to all (new and existing) experiments"
                        title="Are you sure?"
                        confirmButtonText="Apply"
                        onConfirm={async () => {
                          setIsConfirmationModalOpen(false)
                          form.change(
                            'applyChangesToExistingCampaignType',
                            'all'
                          )
                          onSubmitClick()
                        }}
                        onCancel={() => setIsConfirmationModalOpen(false)}
                      />
                      <SelectCampaignsModal
                        accountId={accountId}
                        projectId={projectId}
                        isOpen={isSelectCampaignsModalOpen}
                        selectedIds={selectedCampaignIds}
                        onRowSelect={(ids: string[]) =>
                          setSelectedCampaignIds(ids)
                        }
                        onCancelClick={() =>
                          setIsSelectCampaignsModalOpen(false)
                        }
                        onApplyClick={async () => {
                          setIsSelectCampaignsModalOpen(false)

                          form.change(
                            'applyChangesToExistingCampaignType',
                            'few'
                          )
                          form.change('campaignIds', selectedCampaignIds)
                          onSubmitClick()
                        }}
                      />
                    </BottomToolbar>
                  )
                }
              />
              <RouterPromptStay
                shouldShow={isFormDirty}
                onOK={() => Promise.resolve(true)}
              />
            </>
          )
        }}
      />
    )
  } else {
    return null
  }
}

export default Settings
