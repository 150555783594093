import { useEffect, useState } from 'react'
import { Content as ContentType, NLGAction } from '@phrasee/phrasee-typings'
import { useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'
import { useAppDispatch } from 'common/hooks/redux'
import useWebsockets, { WsResponseBase } from 'common/hooks/useWebsockets'
import { contentKeys } from 'features/unifiedFlow/api/queryKeys'
import { useContent } from 'features/unifiedFlow/contentPage/hooks'
import { toggleElementsDisabled } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { getRootUserSelectedElement } from '../helpers'

import { getWsTopic } from './websocketMessage/helpers'
import useWebsocketMessage from './websocketMessage'
import { useSelectedElement } from '.'

const REFETCH_INTERVAL = 3 * 60000

interface WsResponse extends WsResponseBase {
  content?: ContentType
}

const useWebsocketFullScreenLoader = () => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()
  const { sendWebsocketMessage } = useWebsocketMessage()

  const [isLoading, setIsLoading] = useState(false)
  const [action, setAction] = useState<NLGAction>()
  const {
    lastJsonMessage,
    isConnectionReady,
  }: {
    lastJsonMessage: WsResponse
    isConnectionReady: boolean
  } = useWebsockets()

  const { content, refetch } = useContent()

  const { data: selectedElement } = useSelectedElement()
  const selectedRootElement = getRootUserSelectedElement(
    content,
    selectedElement?.element_id
  )

  useEffect(() => {
    if (content && lastJsonMessage) {
      const generateGlobalBriefWsTopic = getWsTopic({
        action: 'generateGlobalBrief',
        data: { contentId: content._id },
      })
      if (lastJsonMessage.topics?.includes(generateGlobalBriefWsTopic)) {
        if (lastJsonMessage.status === 'success') {
          queryClient.setQueryData(
            contentKeys.content(content._id),
            lastJsonMessage.content
          )
          sendWebsocketMessage({
            action: 'generateGlobalBrief',
            data: { contentId: content._id },
            subscriptionAction: 'unsubscribe',
          })
        } else if (lastJsonMessage.status === 'error' && content) {
          queryClient.invalidateQueries(contentKeys.content(content._id))
          errorToast(lastJsonMessage.errorMessage)
          dispatch(toggleElementsDisabled(false))
        }
      }

      const generateElementBriefRegex = new RegExp(
        `content/${content._id}/element/([^/]+)/generateElementBrief`
      )
      const generateElementBriefTopic = lastJsonMessage.topics?.find((topic) =>
        generateElementBriefRegex.test(topic)
      )

      if (generateElementBriefTopic) {
        if (lastJsonMessage.status === 'success') {
          const wsTopicElementId = generateElementBriefTopic?.match(
            generateElementBriefRegex
          )?.[1]
          queryClient.setQueryData(
            contentKeys.content(content._id),
            lastJsonMessage.content
          )
          if (wsTopicElementId) {
            sendWebsocketMessage({
              action: 'generateElementBrief',
              data: {
                contentId: content._id,
                elementId: Number(wsTopicElementId),
              },
              subscriptionAction: 'unsubscribe',
            })
          }
        } else if (lastJsonMessage.status === 'error' && content) {
          queryClient.invalidateQueries(contentKeys.content(content._id))
          errorToast(lastJsonMessage.errorMessage)
          dispatch(toggleElementsDisabled(false))
        }
      }

      const generateElementVariantsRegex = new RegExp(
        `content/${content._id}/element/([^/]+)/generateElementVariants`
      )
      const generateElementVariantsTopic = lastJsonMessage.topics?.find(
        (topic) => generateElementVariantsRegex.test(topic)
      )

      if (generateElementVariantsTopic) {
        if (lastJsonMessage.status === 'success') {
          const wsTopicElementId = generateElementVariantsTopic?.match(
            generateElementVariantsRegex
          )?.[1]
          queryClient.setQueryData(
            contentKeys.content(content._id),
            lastJsonMessage.content
          )
          if (wsTopicElementId) {
            sendWebsocketMessage({
              action: 'generateElementVariants',
              data: {
                contentId: content._id,
                elementId: Number(wsTopicElementId),
              },
              subscriptionAction: 'unsubscribe',
            })
          }
        } else if (lastJsonMessage.status === 'error' && content) {
          queryClient.invalidateQueries(contentKeys.content(content._id))
          errorToast(
            'Variants generation for one template was unsuccessful. Please try again.'
          )
          dispatch(toggleElementsDisabled(false))
        }
      }
    }
    // Respond only to lastJsonMessage changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage])

  useEffect(() => {
    setIsLoading(false)
    setAction(undefined)

    if (isConnectionReady && content) {
      const generateGlobalBriefWsTopic = getWsTopic({
        action: 'generateGlobalBrief',
        data: { contentId: content._id },
      })

      if (
        content.ws_messages?.some(
          (msg) => msg.wsTopic === generateGlobalBriefWsTopic
        )
      ) {
        setIsLoading(true)
        setAction('brief')
      }

      if (
        !selectedRootElement &&
        content.ws_messages?.some((msg) =>
          msg.wsTopic.includes('generateElementVariants')
        )
      ) {
        setIsLoading(true)
        setAction('variants')
      }

      if (selectedRootElement) {
        const generateElementBriefTopic = getWsTopic({
          action: 'generateElementBrief',
          data: {
            contentId: content._id,
            elementId: selectedRootElement.element_id,
          },
        })

        const generateElementVariantsTopic = getWsTopic({
          action: 'generateElementVariants',
          data: {
            contentId: content._id,
            elementId: selectedRootElement.element_id,
          },
        })

        if (
          content.ws_messages?.some(
            (msg) => msg.wsTopic === generateElementBriefTopic
          )
        ) {
          setIsLoading(true)
          setAction('brief')
        } else if (
          content.ws_messages?.some(
            (msg) => msg.wsTopic === generateElementVariantsTopic
          )
        ) {
          setIsLoading(true)
          setAction('variants')
        }
      }
    }
  }, [content, isConnectionReady, selectedRootElement])

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined
    if (isLoading) {
      interval = setInterval(() => {
        refetch()
      }, REFETCH_INTERVAL)
    } else {
      clearInterval(interval)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isLoading, refetch])

  return {
    isWebsocketLoading: isLoading,
    websocketAction: action,
  }
}

export default useWebsocketFullScreenLoader
