import isString from 'lodash/isString'

import { formatNumber } from 'common/helpers/numeric'
interface Props {
  className: string
  kpiTitle: string
  kpiValue: string | number
}

export const PerformanceIndicator = ({
  className,
  kpiTitle,
  kpiValue,
}: Props) => {
  const hasNoData = kpiValue === '0%'
  return (
    <div className={className}>
      {hasNoData && <span className="w-6 h-0.5 bg-maroon-400 my-6" />}
      {!hasNoData && (
        <div className="font-relais font-medium text-3xl lg:text-8 text-maroon-400 break-alls mb-3">
          {isString(kpiValue)
            ? kpiValue.toLocaleString()
            : formatNumber(kpiValue)}
        </div>
      )}
      <div className="text-sm font-normal text-base-700"> {kpiTitle}</div>
    </div>
  )
}
