import { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import Button from 'common/components/button/Button'
import { ReactComponent as Logout } from 'common/icons/logout/default.svg'

const LogoutButton: FC = () => {
  const { logout } = useAuth0()

  return (
    <Button
      prefixIcon={<Logout width={24} height={24} />}
      variant="secondary"
      data-cy="logout-button"
      data-testid="logout-button"
      onClick={() =>
        logout({
          returnTo: `${window.location.origin}/`,
        })
      }
    >
      Sign out
    </Button>
  )
}

export default LogoutButton
