import { FC, useState } from 'react'
import { Role } from '@phrasee/phrasee-typings/typings/user/user'

import Modal from 'common/components/Modal'

import { InviteUser } from '../api'

import AddUsersFormContent from './addUsersFormContent'

interface Props {
  isVisible: boolean
  onClickInviteUsers: (val: InviteUser[]) => void
  onClose: () => void
  assignableRoles: Role[]
}

const generateEmptyUser: () => InviteUser = () => ({
  email: '',
  firstName: '',
  lastName: '',
  role: '',
  roles: [],
  projectIds: [],
})

const initializeUsers: () => InviteUser[] = () => [
  generateEmptyUser(),
  generateEmptyUser(),
  generateEmptyUser(),
]

const AddUsersModal: FC<Props> = ({
  isVisible,
  onClickInviteUsers,
  onClose,
  assignableRoles,
}) => {
  const [users, setUsers] = useState<InviteUser[]>(initializeUsers())

  const resetState = () => {
    setUsers(initializeUsers())
  }

  const handleSubmit = (users: InviteUser[]) => {
    onClickInviteUsers(users)
    resetState()
  }

  const handleClose = () => {
    onClose()
    resetState()
  }

  return (
    <Modal
      data-cy="add-users-modal"
      data-testid="add-users-modal"
      visible={isVisible}
      closable={false}
      footer={null}
      hasMargin={false}
      centered
    >
      <AddUsersFormContent
        assignableRoles={assignableRoles}
        addUser={() => setUsers([...users, generateEmptyUser()])}
        users={users}
        updateUsers={setUsers}
        onSubmit={handleSubmit}
        onClickCancel={handleClose}
      />
    </Modal>
  )
}

export default AddUsersModal
