import { Node } from '../../interfaces/nodes'

import Breadcrumbs from './Breadcrumbs'

type Props = {
  nodes: Node[]
  selectedNodeId: string
  updateSelectedNodeId: (nodeId?: string) => void
}

const Pathway = ({ nodes, selectedNodeId, updateSelectedNodeId }: Props) => {
  return (
    <div
      data-cy="nodes-pathway"
      data-testid="nodes-pathway"
      className="flex flex-col"
    >
      <div className="text-coolGray-400 mb-1">Inherited pathway</div>
      <Breadcrumbs
        selectedNodeId={selectedNodeId}
        nodes={nodes}
        updateSelectedNodeId={updateSelectedNodeId}
      />
    </div>
  )
}

export default Pathway
