import { useQuery } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'
// FIXME common files should not import from features
import { getBrandVoices } from 'features/admin/brandVoice/api'
import { brandVoiceKeys } from 'features/admin/brandVoice/api/queryKeys'
import { BrandVoice } from 'features/admin/brandVoice/store/brandVoiceSlice'

const useGetBrandVoicesQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const getBrandVoicesQuery = useQuery<BrandVoice[]>(
    brandVoiceKeys.list(accountId),
    () => getBrandVoices({ accountId }),
    {
      staleTime: 10 * 60 * 1000,
      refetchOnMount: 'always',
      meta: {
        errorMessage: 'Brand voices could not be loaded',
      },
    }
  )

  return getBrandVoicesQuery
}

export default useGetBrandVoicesQuery
