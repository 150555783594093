import { forwardRef, useLayoutEffect, useRef, useState } from 'react'
import StateManagementSelect, {
  GroupBase,
  mergeStyles,
  Props,
  SelectInstance,
  StylesConfig,
} from 'react-select'
import cx from 'classnames'

import Label from 'common/components/label'

import { MAX_OPTIONS_MENU_WIDTH, styles } from './BaseSelectStyles'

declare module 'react-select/dist/declarations/src/Select' {
  export interface Props<
    Option,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    IsMulti extends boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Group extends GroupBase<Option>
  > {
    size?: 'normal' | 'large'
    showSearchIcon?: boolean
  }
}

export type Orientation = 'horizontal' | 'vertical'
export type SelectValue = {
  label: string
  value: string
}

export type BaseProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> & {
  label?: string
  isLabelOptional?: boolean
  size?: 'normal' | 'large'
  orientation?: Orientation
  selectClassName?: string
  customStyles?: StylesConfig<Option, IsMulti, Group>
  isInsideTable?: boolean
  isMinimized?: boolean
  maxOptions?: number
  'data-cy'?: string
  'data-testid'?: string
}

export type SelectRef<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
> = SelectInstance<Option, IsMulti, Group>

function BaseSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  {
    label,
    isLabelOptional,
    orientation = 'vertical',
    size = 'normal',
    className,
    selectClassName,
    menuPortalTarget,
    menuShouldBlockScroll,
    customStyles,
    isInsideTable = false,
    isMinimized = false,
    ...rest
  }: BaseProps<Option, IsMulti, Group>,
  ref: React.Ref<SelectRef<Option, IsMulti, Group>> | null
) {
  const refContainer = useRef<HTMLDivElement>(null)

  const [optionsPosition, setOptionsPosition] =
    useState<'left' | 'right'>('left')

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      if (refContainer.current) {
        const extraMargin = 60
        const { left } = refContainer.current.getBoundingClientRect()

        setOptionsPosition(
          document.body.offsetWidth - left <
            MAX_OPTIONS_MENU_WIDTH + extraMargin
            ? 'right'
            : 'left'
        )
      }
    })

    const container = refContainer.current
    if (container) {
      observer.observe(document.body)
    }
    return () => {
      if (container) {
        observer.unobserve(document.body)
      }
    }
  }, [])

  return (
    <div
      className={cx(
        { 'flex items-center': orientation === 'horizontal' },
        className
      )}
      ref={refContainer}
    >
      {label && (
        <Label orientation={orientation} optional={isLabelOptional}>
          {label}
        </Label>
      )}
      <StateManagementSelect
        aria-label={label}
        className={selectClassName}
        ref={ref}
        size={size}
        menuPortalTarget={isInsideTable ? document.body : menuPortalTarget}
        menuShouldBlockScroll={isInsideTable || menuShouldBlockScroll}
        styles={mergeStyles(
          styles<Option, IsMulti, Group>({
            optionsPosition,
            isInsideTable,
            isMinimized,
          }),
          customStyles
        )}
        data-cy={rest['data-cy']} // used in MultiSelect using selectProps value
        data-testid={rest['data-testid']} // used in MultiSelect using selectProps value
        {...rest}
      />
    </div>
  )
}

// Based on https://fettblog.eu/typescript-react-generic-forward-refs/
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null
}

export default forwardRef(BaseSelect)
