import { useState } from 'react'
import cx from 'classnames'

import { ChevronRight } from 'common/icons'
import { VariableCombination } from 'features/unifiedFlow/api'
import Searchbox, { Option } from 'features/unifiedFlow/components/Searchbox'

type Props = {
  variableCombinations: VariableCombination['items']
  onClickPersonalizationId: (personalizationId: string | undefined) => void
  selectedPersonalizationId: string | undefined
  pendingVariantsCountPerCombination: { [key: string]: number }
}
const LanguageContent = ({
  variableCombinations,
  selectedPersonalizationId,
  onClickPersonalizationId,
  pendingVariantsCountPerCombination,
}: Props) => {
  const [searchValue, setSearchValue] = useState('')

  const items =
    (variableCombinations ?? [])
      .filter((item) => !!pendingVariantsCountPerCombination?.[item.id])
      .map((item) => {
        return {
          count: pendingVariantsCountPerCombination?.[item.id],
          personalizationId: item.id,
          ...item.dimensions.reduce(
            (previousValue, currentValue) => ({
              ...previousValue,
              [currentValue.key]: currentValue.value,
            }),
            {}
          ),
        }
      })
      .filter((item) => {
        return Object.entries(item).some(([key, value]) => {
          return (
            (value as any) // FIXME any
              .toString()
              .toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            key.toLowerCase().includes(searchValue.toLowerCase())
          )
        })
      })
      .sort((a, b) => b.count - a.count) ?? []
  const options: Option[] = [
    ...new Set(
      items?.flatMap((item) => {
        const entries = Object.entries(item).filter(
          ([key, value]) =>
            key !== 'count' &&
            key !== 'personalizationId' &&
            typeof value !== 'number'
        ) as [string, string][]
        return entries.map(([_, value]) => value).flat()
      })
    ),
  ]
    .map((value: string) => ({ label: value, value: value }))
    .sort((a, b) => a.label.localeCompare(b.label))
  return (
    <div className="flex gap-y-4 flex-col">
      <Searchbox
        onSearchContentValue={(value) => {
          setSearchValue(value)
        }}
        options={options}
        placeholder="Search"
      />
      <div className="flex flex-col h-full gap-4 max-h-95 overflow-y-auto pr-4 -mr-4">
        {items.map(({ count, personalizationId, ...dimensions }, index) => (
          <div
            key={personalizationId}
            className={cx(
              'rounded-sm border-l-2 border-transparent hover:border-base-700 transition duration-300',
              'hover:bg-gold-200 text-gold-700 p-4 cursor-pointer focus:outline-none flex flex-col gap-4 ',
              {
                'bg-gold-40': personalizationId !== selectedPersonalizationId,
                'bg-yellow-200 border-base-700':
                  personalizationId === selectedPersonalizationId,
              }
            )}
            onClick={(event) => {
              event.stopPropagation()
              if (personalizationId !== selectedPersonalizationId) {
                onClickPersonalizationId(personalizationId)
              } else {
                onClickPersonalizationId(undefined)
              }
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onClickPersonalizationId(personalizationId)
              }
            }}
            role="button"
            tabIndex={0}
          >
            <div className="flex justify-between">
              <span className="text-base-700">Variants: {count}</span>
              <ChevronRight size={4} />
            </div>
            {Object.keys(dimensions).length > 0 && (
              <div className="flex gap-x-4 gap-y-1 w-full flex-wrap">
                {Object.entries(dimensions).map(([key, item]) => (
                  <span
                    key={key}
                    className="capitalize-first text-gold-700 break-all"
                  >
                    {item}
                  </span>
                ))}
              </div>
            )}
            {Object.keys(dimensions).length === 0 && (
              <div>No contextual variable</div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default LanguageContent
