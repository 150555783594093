import Button from 'common/components/button/Button'
import {
  CheckCircle as CheckCircleIcon,
  Clock as ClockIcon,
  Refresh as RefreshIcon,
} from 'common/icons'

const getIcon = (status: string) => {
  switch (status) {
    case 'complete':
      return (
        <CheckCircleIcon
          isDefaultColor={false}
          className="text-2xl text-green-600"
        />
      )
    case 'processing':
      return (
        <RefreshIcon
          isDefaultColor={false}
          className="text-2xl text-gray-300 animate-spin"
        />
      )
    default:
      return (
        <ClockIcon isDefaultColor={false} className="text-2xl text-gray-300" />
      )
  }
}

interface Props {
  completeMessage?: string
  inlineText?: boolean
  steps: Step[] | undefined
  buttonText: string
  onSubmit: () => void
  isCampaignLive: boolean
  isCampaignComplete: boolean
  liveCampaignTitle: string
  liveCampaignSubtitle: string
  showButton?: boolean
}

export interface Step {
  subtitle?: string
  title: string
  status: 'pending' | 'processing' | 'complete'
}

export default function StatusCard({
  steps,
  buttonText,
  onSubmit,
  isCampaignLive,
  isCampaignComplete,
  liveCampaignTitle,
  liveCampaignSubtitle,
  completeMessage,
  inlineText,
  showButton,
}: Props) {
  return (
    <div
      className={`mt-4 mb-4 p-4 border flex flex-col ${
        isCampaignComplete
          ? 'border-blue-400 bg-blue-50'
          : 'border-green-400 bg-green-50'
      }`}
    >
      {steps && !isCampaignLive && !isCampaignComplete && (
        <>
          {steps.map((step, index) => (
            <div key={step.title} className="flex flex-row items-center h-15">
              <div className="relative">
                {getIcon(step.status)}
                {index !== steps.length - 1 && (
                  <div
                    className={`w-6 h-px bg-gray-200 absolute
                  top-12 left-0 transform rotate-90`}
                  ></div>
                )}
              </div>
              <div
                className={`ml-4 flex ${inlineText ? 'flex-row' : 'flex-col'}`}
              >
                <div
                  className={`font-medium ${
                    step.status === 'complete' && 'text-green-600'
                  }
                  ${step.status === 'pending' && 'text-gray-400'}`}
                >
                  {step.title}
                </div>
                <div
                  className={`font-xs text-gray-400 ${
                    inlineText ? 'ml-6' : ''
                  }`}
                >
                  {step.subtitle}
                </div>
              </div>
            </div>
          ))}
          {showButton && (
            <div className="mt-3">
              <Button
                data-cy="status-card-button"
                data-testid="status-card-button"
                loading={false}
                variant="secondary"
                disabled={false}
                onClick={() => onSubmit()}
                className="mr-auto"
              >
                {buttonText}
              </Button>
            </div>
          )}
        </>
      )}
      {isCampaignLive && !isCampaignComplete && (
        <div className="flex flex-row items-center">
          <CheckCircleIcon
            isDefaultColor={false}
            className="text-2xl text-green-600"
          />
          <div className={`ml-4 flex ${inlineText ? 'flex-row' : 'flex-col'}`}>
            <div className="font-medium text-green-600">
              {liveCampaignTitle}
            </div>
            <div
              className={`font-xs text-gray-400 ${inlineText ? 'ml-6' : ''}`}
            >
              {liveCampaignSubtitle}
            </div>
          </div>
        </div>
      )}
      {isCampaignComplete && (
        <div className="flex flex-row items-center">
          <CheckCircleIcon
            isDefaultColor={false}
            className="text-2xl text-blue-400"
          />
          <div className="ml-4">
            <div className="font-medium text-blue-500">
              {'Experiment complete - '}
              <span className="font-normal">{completeMessage}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
