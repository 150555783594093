import { Element } from '@phrasee/phrasee-typings'
import cx from 'classnames'

import { AlertCircleInverted as AlertCircleInvertedIcon } from 'common/icons'

import ElementActionsMenu from './ElementActionsMenu'
import ElementCardIcon from './ElementCardIcon'
import ElementLoader, {
  ELEMENT_LOADER_ANIMATIONS_COUNT,
  ElementLoaderProps,
} from './ElementLoader'

export const getAnimationIndex = (index: number) =>
  String(
    ((index - 1) % ELEMENT_LOADER_ANIMATIONS_COUNT) + 1
  ) as ElementLoaderProps['animation']

type Props = {
  title: string
  className?: string
  content?: string | JSX.Element | null
  element?: Element
  icon?: string
  index?: number
  isRemoveOptionShown?: boolean
  isDisabled?: boolean
  isSelected?: boolean
  isLoading?: boolean
  hasError?: boolean
  isApproved?: boolean
  isConfigurable?: boolean
  hasSmallTitle?: boolean
  hasElementActionsMenu?: boolean
  showCustomBriefIcon?: boolean
  isElementTemplate?: boolean
  onClick?: () => void
}

const ElementCard = ({
  title,
  className,
  content,
  element,
  icon,
  index = 1,
  isRemoveOptionShown,
  isDisabled = false,
  isSelected = false,
  isLoading = false,
  hasError = false,
  isApproved = false,
  isConfigurable = false,
  hasSmallTitle = false,
  isElementTemplate = false,
  hasElementActionsMenu = true,
  showCustomBriefIcon = true,
  onClick,
}: Props) => {
  const hasContent = !!content

  const animationIndex = getAnimationIndex(index)

  return (
    <div
      data-testid="element-card"
      onClick={onClick}
      className={cx(
        'relative flex items-center group rounded-sm cursor-pointer hover:bg-gold-300 px-4 py-6',
        {
          'bg-gold-100 opacity-40 cursor-not-allowed': isDisabled,
          'bg-gold-100': !isElementTemplate && !isDisabled && !isSelected,
          'border-l-2 border-black bg-yellow-200': isSelected,
          'bg-white cursor-pointer': isElementTemplate && !isSelected,
          'bg-coral-100  cursor-pointer ': !isDisabled && hasError,
        },
        className
      )}
      aria-selected={isSelected}
      aria-label={isApproved ? 'Approved' : undefined}
      role="tab"
    >
      {icon && !isLoading && !hasError && (
        <div className="mr-3">
          <ElementCardIcon icon={icon} />
        </div>
      )}
      {hasError && !isLoading && (
        <div className="mr-3">
          <AlertCircleInvertedIcon
            isDefaultColor={false}
            className={cx({ 'text-RAGred': isSelected })}
          />
        </div>
      )}
      {isLoading && (
        <div className="flex items-center mr-3 h-6 w-6">
          <ElementLoader animation={animationIndex} />
        </div>
      )}
      <div
        className={cx(
          'flex items-center flex-wrap gap-1 font-medium overflow-hidden'
        )}
      >
        <span
          data-testid="element-card-title"
          className={cx('leading-4 uppercase', {
            'text-maroon-400': isLoading,
            'text-RAGred': hasError && isSelected,
            'text-sm text-base-700': !isDisabled && !hasSmallTitle,
            'text-sm text-coolGray-500': isDisabled && !hasSmallTitle,
            'text-xs text-base-700': hasSmallTitle && !isDisabled,
            'text-xs text-coolGray-500': hasSmallTitle && isDisabled,
          })}
          aria-disabled={isDisabled}
        >
          {title}
        </span>
        {hasContent && !hasError && <div className="min-h-5">{content}</div>}
        {hasError && (
          <div
            className={cx('min-h-5 text-2.5 text-base-700', {
              'text-RAGred': isSelected,
            })}
          >
            Unable to generate language, please try again.
          </div>
        )}
      </div>
      <ElementActionsMenu
        hasError={hasError}
        isApproved={isApproved}
        isConfigurable={isConfigurable}
        isRemoveOptionShown={isRemoveOptionShown}
        isSelected={isSelected}
        element={element}
        hasElementActionsMenu={hasElementActionsMenu}
        showCustomBriefIcon={showCustomBriefIcon}
      />
    </div>
  )
}

export default ElementCard
