import ChartWidgetMenu from 'common/components/widget/menu/ChartWidgetMenu'
import { useChartRefCallback } from 'common/hooks/useChartRefCallback'

import WidgetLoader from './loaders/WidgetLoader'
import * as GraphWidget from './GraphWidget'
import InfoAction from './InfoAction'
import UpliftGraph, { UpliftData } from './UpliftGraph'
import { WidgetError, WidgetFirstUse } from './WidgetMessage'

type Props = {
  title: string
  info: string
  isLoading: boolean
  xAxisLabels: string[]
  data: UpliftData[]
  hasError: boolean
}

const UpliftWidget = ({
  title,
  info,
  isLoading,
  xAxisLabels,
  data,
  hasError,
}: Props) => {
  const { chart, chartRefCallback } = useChartRefCallback()

  const getState = () => {
    if (isLoading) {
      return 'loading'
    } else if (!data.length && !hasError) {
      return 'firstUse'
    } else if (hasError) {
      return 'error'
    } else {
      return 'showData'
    }
  }

  const state = getState()

  return (
    <GraphWidget.Widget
      type="inner"
      title={title}
      data-cy="uplift-widget"
      data-testid="uplift-widget"
      actions={
        state === 'showData' ? (
          <div className="inline-flex">
            <ChartWidgetMenu chart={chart} title={title} />
            <InfoAction message={info} />
          </div>
        ) : undefined
      }
    >
      {
        {
          loading: (
            <WidgetLoader
              data-cy="uplift-widget-loader"
              data-testid="uplift-widget-loader"
            />
          ),
          firstUse: (
            <WidgetFirstUse shouldShowCreateCampaignButton className="mb-6" />
          ),
          error: <WidgetError className="mb-6" />,
        }[state]
      }
      {state === 'showData' ? (
        <UpliftGraph
          data={data}
          xAxisLabels={xAxisLabels}
          ref={chartRefCallback}
        />
      ) : undefined}
    </GraphWidget.Widget>
  )
}

export default UpliftWidget
