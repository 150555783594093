export const useGetMetricsQuery = ({
  accountId,
  dashboardMode,
  activeProjectIds,
  getMetricsQuery,
}) => {
  const { data: queryData, isError: hasApiError } = getMetricsQuery(
    accountId,
    dashboardMode,
    activeProjectIds
  )

  return { queryData, hasApiError }
}
