import type {
  ImageEntity,
  ImageStatus,
} from '@phrasee/phrasee-typings/typings/image/image'
import type {
  ImagePageOptions,
  ImagePageResponse,
  ImageSortableKeys,
  ImageSortOrder,
} from '@phrasee/phrasee-typings/typings/image/page'

import { connectInternalApi } from 'common/api'

export type {
  ImagePageResponse,
  ImageEntity,
  ImagePageOptions,
  ImageSortableKeys,
  ImageSortOrder,
  ImageStatus,
}

export const getImages = async (
  accountId: string,
  params: ImagePageOptions
) => {
  const result = await connectInternalApi.get<ImagePageResponse>(
    `v1/images-api/accounts/${accountId}/images`,
    {
      params,
    }
  )

  return result.data
}

export const getImageById = async (accountId: string, imageId: string) => {
  const result = await connectInternalApi.get<ImageEntity>(
    `v1/images-api/accounts/${accountId}/images/${imageId}`
  )

  return result.data
}

export const getImagesByIds = async (accountId: string, imageIds: string[]) => {
  const params = new URLSearchParams()
  imageIds.forEach((id) => params.append('imageIds', id))

  const result = await connectInternalApi.get<ImagePageResponse>(
    `v1/images-api/accounts/${accountId}/images?${params.toString()}`
  )

  return result.data
}
