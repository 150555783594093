import * as Highcharts from 'highcharts/highstock'
import get from 'lodash/get'

import { yAxisConfig } from 'common/helpers/ui/graph'

import BarGraph from './BarGraph'
import OpenRatesOverTime from './OpenRatesOverTime'
import StackedAreaGraph from './StackedAreaGraph'
import UpliftGraph from './UpliftGraph'

const CHART_MARGINS = {
  TOP: 8,
  RIGHT: 20,
  BOTTOM: 15,
  LEFT: 20,
}

const graphConfig: Highcharts.Options = {
  navigator: {
    outlineColor: 'transparent',
    height: 64,
    maskFill: 'rgba(144, 19, 86, 0.08)',
    handles: {
      symbols: [
        'url(/images/chart-handler-symbol.svg)',
        'url(/images/chart-handler-symbol.svg)',
      ],
      width: 20,
      height: 20,
    },
    series: {
      lineWidth: 1,
      lineColor: '#f1e0e5',
      fillColor: {
        linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
        stops: [
          [0.1353, 'rgba(144, 19, 86, 0.4)'],
          [1, 'rgba(255, 255, 255, 0.00004)'],
        ],
      },
    },
    xAxis: {
      gridLineColor: '#FBF6FB',
      type: 'datetime',
      labels: {
        formatter: (chart: any) => {
          return Highcharts.dateFormat('%b ‘%e', chart.value)
        },
        y: -45,
        x: 8,
        style: {
          color: '#A0261A',
          fontSize: '12px',
          fontFamily: 'Outfit',
        },
      },
    },
  },
  chart: {
    backgroundColor: '#fbf7f0',
    borderRadius: 2,
    borderWidth: 1,
    borderColor: '#E0C797',
    margin: [
      CHART_MARGINS.TOP,
      CHART_MARGINS.RIGHT,
      CHART_MARGINS.BOTTOM,
      CHART_MARGINS.LEFT,
    ],
    spacing: [0, 0, 0, 0],
    events: {
      load: function () {
        const navigator = get(this, 'navigator')

        if (navigator && !navigator.background) {
          navigator.background = this.renderer
            .rect()
            .attr({
              zIndex: 0,
              fill: 'rgba(144, 19, 86, 0.04)',
            })
            .add(navigator.group)
        }
      },
      render: function () {
        const navigator = get(this, 'navigator')
        if (navigator && navigator.background) {
          const bbox = navigator.navigatorGroup.getBBox()

          navigator.background.attr({
            x: bbox.x - CHART_MARGINS.LEFT,
            y: bbox.y,
            width: bbox.width + CHART_MARGINS.LEFT + CHART_MARGINS.RIGHT,
            height: bbox.height,
          })
        }
      },
    },
  },
  // main x axis
  xAxis: {
    lineColor: '#EFE2C8',
    tickColor: '#EFE2C8',
    labels: {
      style: {
        color: '#15020C',
        fontSize: '12px',
        fontWeight: '400',
      },
    },
  },
  // main y axis
  yAxis: {
    ...yAxisConfig,
    tickInterval: 10,
    labels: {
      format: '{value}%',
      style: {
        color: '#15020C',
        fontSize: '12px',
        fontWeight: '400',
      },
      align: 'center' as const,
      reserveSpace: true,
    },
    gridLineColor: '#EFE2C8',
    gridLineDashStyle: 'Solid',
  },
  title: {
    text: undefined,
    // float: true,
    align: 'left' as const,
  },
  scrollbar: {
    enabled: false,
  },
  accessibility: {
    announceNewData: {
      enabled: true,
    },
  },
  legend: {
    enabled: false,
  },
  lang: {
    rangeSelectorZoom: '',
  },
  time: {
    useUTC: false,
  },
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
    buttons: {},
  },
  navigation: {
    buttonOptions: {
      theme: {
        stroke: 'none',
      },
    },
  },
  rangeSelector: {
    inputDateFormat: '%e %b %Y',
    inputSpacing: 0,
    inputPosition: {
      align: 'left' as const,
      x: 24,
      y: 13,
    },
    buttonPosition: {
      align: 'right' as const,
      x: -5,
      y: 6,
    },
    buttonTheme: {
      fill: 'none',
      stroke: '#15020C',
      'stroke-width': 1,
      style: {
        color: '#15020C',
        backgroundColor: '#ffffff',
        fontFamily: 'Outfit',
        fontSize: '12px',
        fontWeight: '400',
      },
      padding: 5,
      states: {
        disabled: {
          style: {
            color: '#15020C',
            opacity: '50%',
          },
        },
        hover: {
          color: '#15020C',
        },
        select: {
          style: {
            color: '#15020C',
            fontWeight: '500',
          },
        },
      },
    },
    inputBoxBorderColor: 'none',
    inputBoxWidth: 80,
    inputStyle: {
      color: '#15020C',
      fontWeight: '400',
      fontSize: '12px',
      fontFamily: 'Outfit',
    },
    labelStyle: {
      // styles the arrow
      color: '#15020C',
      fontWeight: 'normal',
      fontSize: '16px',
      transform: 'translate(84px, 0px)',
    },
  },
}

export {
  BarGraph,
  StackedAreaGraph,
  OpenRatesOverTime,
  UpliftGraph,
  graphConfig,
  Highcharts,
}
