import { useRef, useState } from 'react'
import { FilePondErrorDescription, FilePondFile, FileStatus } from 'filepond'
import findLastIndex from 'lodash/findLastIndex'

import type { FileUploaderFileType } from 'common/components/fileUploader'
import FileUploader from 'common/components/fileUploader'
import Modal from 'common/components/Modal'
import { errorToast, successToast } from 'common/components/toastNotification'

import ImagesUploadList from './components/ImagesUploadList'
import ImageUploader from './components/ImageUploader'
import UploaderModalFooter from './components/UploaderModalFooter'
import useImageUpload from './hooks/useImageUpload'

import styles from 'common/components/images/imagesStyles.module.css'

type Props = {
  isModalVisible: boolean
  onClose: (value: boolean) => void
}

const UploaderModal = ({ isModalVisible, onClose }: Props) => {
  const [uploadError, setUploadError] =
    useState<FilePondErrorDescription | undefined>(undefined)
  const [images, setImages] = useState<FileUploaderFileType[]>([])
  const imageUploaderRef = useRef<FileUploader>(null)

  const handleReplaceImage = (file: FileUploaderFileType) => {
    if (imageUploaderRef.current) {
      imageUploaderRef.current.browse()
      imageUploaderRef.current.removeFile(file.id)
    }
  }

  const handleAddFile = (
    error: FilePondErrorDescription | null,
    file: FilePondFile
  ) => {
    if (!error && imageUploaderRef.current) {
      imageUploaderRef.current.moveFile(file.id, findLastIndex(images) - 1)
    }
  }

  const hasImages = images.length > 0
  const hasFilesLimitExceededError = uploadError?.body === 'Max files'
  const hasFileSizeError = images?.some(
    (image) => image.status === FileStatus.LOAD_ERROR
  )

  const onClearUploader = () => {
    onClose(false)
    imageUploaderRef.current?.removeFiles()
  }

  const { uploadImages, isLoading } = useImageUpload({
    processFiles: imageUploaderRef.current?.processFiles,
    onSuccess: () => {
      successToast('New images added to the Image Library.')
      onClearUploader()
    },
    onError: () => {
      errorToast('Failed to add images to the Image Library. Please try again.')
    },
  })

  const handleUpload = () => {
    const validImages = images.filter(
      (image) =>
        image.status !== FileStatus.LOAD_ERROR &&
        image.status !== FileStatus.PROCESSING_ERROR &&
        image.status !== FileStatus.PROCESSING_REVERT_ERROR
    )

    uploadImages(validImages)
  }

  return (
    <Modal
      visible={isModalVisible}
      width={600}
      className={styles.modal}
      centered
      closable={false}
      onClose={() => (isLoading ? onClose(false) : onClearUploader())}
    >
      <h4 className="text-base-700 text-2xl font-semibold mb-2">
        Upload new image
      </h4>
      <p className="text-base-700 text-base font-normal pb-4">
        Make sure your images are not bigger than 1MB, also we only accept the
        following formats: JPG, JPEG, WEBP and PNG.
      </p>
      <ImageUploader
        ref={imageUploaderRef}
        onUpdateFiles={setImages}
        images={images}
        allowMultiple
        maxFiles={10}
        onWarning={setUploadError}
        onError={setUploadError}
        onAddFile={handleAddFile}
      />
      <ImagesUploadList images={images} onReplace={handleReplaceImage} />
      <div className="mt-2">
        {hasFileSizeError && (
          <p className="text-RAGred font-normal text-xs">
            Some files exceed 1MB. Replace them with something smaller, or the
            format used is wrong. You can replace those or continuing with the
            upload ignoring those.
          </p>
        )}
        {hasFilesLimitExceededError && (
          <p className="text-RAGred font-normal text-xs">
            You can upload a maximum of 10 files at a time
          </p>
        )}
      </div>
      <UploaderModalFooter
        hasImages={hasImages}
        isLoading={isLoading}
        onCancel={() => (isLoading ? onClose(false) : onClearUploader())}
        onUpload={handleUpload}
      />
    </Modal>
  )
}

export default UploaderModal
