import { useAppDispatch } from 'common/hooks/redux'
import { hideLeftPanel } from 'features/unifiedFlow/store/unifiedFlowSlice'

import LeftPanel from '../../components/layout/LeftPanel'

import ElementsList from './ElementsList'

const LeftSection = () => {
  const dispatch = useAppDispatch()

  return (
    <LeftPanel onOutsideClick={() => dispatch(hideLeftPanel())}>
      <ElementsList />
    </LeftPanel>
  )
}

export default LeftSection
