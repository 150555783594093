import { useMemo, useState } from 'react'

import { Member, Team } from 'common/api/teamsApi'
import Avatar from 'common/components/Avatar'
import Checkbox from 'common/components/checkbox'
import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import Scrollable from 'common/components/scrollable'
import { User } from 'features/admin/users/all/api'
import { avatarColors } from 'features/profile/AvatarColorPicker'
import { User as UserRow } from 'features/projects/components/projectFormCards/accessCard/AccessCard'

import { Footer } from './Footer'
import Header from './Header'

type Props = {
  isEditMode: boolean
  selectedTeam: Team
  setSelectedTeam: (value: Team) => void
  teamName: string
  onTeamNameChange: (value: string) => void
  teams: Team[]
  users: User[]
  onClickCancel: () => void
  onSubmit: () => void
}

export const sortAlphabetically = (a: UserRow, b: UserRow) => {
  const nameA = `${a.firstName} ${a.lastName}`.toLowerCase()
  const nameB = `${b.firstName} ${b.lastName}`.toLowerCase()

  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}

export const isTeamNameAlreadyUsed = (
  teamName: string,
  teams: Team[],
  selectedTeamName?: string
) => {
  if (
    selectedTeamName &&
    selectedTeamName.trim().toLocaleLowerCase() ===
      teamName.trim().toLocaleLowerCase()
  ) {
    return false
  }

  return teams.some(
    (team) =>
      team.name.trim().toLocaleLowerCase() ===
      teamName.trim().toLocaleLowerCase()
  )
}

export function AddTeamFormContent({
  isEditMode,
  selectedTeam,
  setSelectedTeam,
  teamName,
  onTeamNameChange,
  teams,
  users,
  onSubmit,
  onClickCancel,
}: Props) {
  const [search, setSearch] = useState<string>('')

  const mappedUsers = useMemo(
    () =>
      users.map(({ id, firstName, lastName, email, roles }, index) => ({
        id: id,
        firstName: firstName ?? '',
        lastName: lastName ?? '-',
        email,
        role: roles[0],
        avatarColor: avatarColors[index % avatarColors.length],
      })),
    [users]
  )

  const filteredUsers = mappedUsers.filter(({ firstName, lastName }) => {
    const fullName = `${firstName} ${lastName}`
    return fullName.toLowerCase().includes(search.toLowerCase())
  })
  const hasSearchResults = filteredUsers.length && users.length

  const selectUser = (user: UserRow): void => {
    setSelectedTeam({
      ...selectedTeam,
      members: [
        ...(selectedTeam?.members as Member[]),
        {
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          roles: [user.role],
        },
      ],
    })
  }

  const unselectUser = (user: UserRow): void => {
    setSelectedTeam({
      ...selectedTeam,
      members: [
        ...(selectedTeam?.members as Member[]).filter(
          (member) => member.email !== user.email
        ),
      ],
    })
  }

  const isNameAlreadyUsed = isTeamNameAlreadyUsed(
    teamName,
    teams,
    selectedTeam.name
  )

  const isValidTeamName = teamName.length > 0

  const canSubmitForm = isEditMode
    ? selectedTeam.members.length > 0
    : isValidTeamName && !isNameAlreadyUsed && selectedTeam.members.length > 0

  return (
    <div className="w-123 max-h-screen flex pt-6 pb-6">
      <form
        onSubmit={(event) => {
          event.preventDefault()
          onSubmit()
        }}
        className="flex flex-col flex-1"
      >
        <Scrollable
          header={
            <div className="px-4 pb-4 flex flex-grow flex-col">
              <Header
                isEditMode={isEditMode}
                teamName={selectedTeam?.name ?? '-'}
              />
              {!isEditMode ? (
                <div className="mb-1">
                  <FormItem
                    label="Team name"
                    htmlFor="teamName"
                    className="text-base text-coolGray-800"
                    error={
                      isNameAlreadyUsed
                        ? 'This team name already exists!'
                        : undefined
                    }
                  >
                    <Input
                      variant="default"
                      type="text"
                      id="teamName"
                      name="teamName"
                      onChange={(event) => {
                        onTeamNameChange(event.target.value)
                      }}
                      value={teamName}
                      placeholder="Team name"
                      hasError={isNameAlreadyUsed}
                      isValid={isValidTeamName}
                    />
                  </FormItem>
                  <legend className="text-base font-medium text-coolGray-800 mb-0">
                    Add users
                  </legend>
                </div>
              ) : null}
              <Input
                data-cy="search-users"
                data-testid="search-users"
                type="search"
                variant="default"
                placeholder="Search users by name"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                clear={() => setSearch('')}
              />
            </div>
          }
          contentClassName="pl-6 mt-4 text-sm text-coolGray-500 grid flex-0 pr-6 max-h-149"
          content={
            <div role="group" aria-label="Add users">
              {filteredUsers.sort(sortAlphabetically).map((user) => {
                const { id, firstName, lastName, email, avatarColor } = user

                return (
                  <Checkbox
                    key={id}
                    data-cy={`user-checkbox-${firstName}-${lastName}`}
                    data-testid={`user-checkbox-${firstName}-${lastName}`}
                    className="py-3 min-w-full hover:bg-gray-50"
                    label={
                      <div className="flex items-center">
                        <Avatar
                          className="text-xs w-6 h-6 mr-4"
                          firstName={firstName}
                          lastName={lastName}
                          color={avatarColor}
                        />
                        <span aria-label="User name">
                          {firstName} {lastName}
                        </span>
                      </div>
                    }
                    isChecked={selectedTeam.members.some(
                      (member) => member.email === email
                    )}
                    onChange={(isChecked) =>
                      isChecked ? selectUser(user) : unselectUser(user)
                    }
                  />
                )
              })}
              {!hasSearchResults && 'No users match search'}
            </div>
          }
          footer={
            <Footer
              onClickCancel={() => {
                onClickCancel()
                setSearch('')
              }}
              isEditMode={isEditMode}
              canSubmitForm={canSubmitForm}
            />
          }
        />
      </form>
    </div>
  )
}

export default AddTeamFormContent
