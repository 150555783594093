import { forwardRef } from 'react'
import cx from 'classnames'

import type {
  FileUploaderFileType,
  FileUploaderProps,
} from 'common/components/fileUploader'
import FileUploader from 'common/components/fileUploader'

import { PresignedUrl } from '../../../api/api'

type Props = {
  allowMultiple: boolean
  images: FileUploaderFileType[] | FileUploaderProps['files']
  onUpdateFiles: (files: FileUploaderFileType[]) => void
  maxFiles?: number
  onWarning?: FileUploaderProps['onwarning']
  onError?: FileUploaderProps['onerror']
  onAddFile?: FileUploaderProps['onaddfile']
}

type Metadata = {
  id: string
  presignedUrl: PresignedUrl
}

const prepareFormData = (
  formDataFields: PresignedUrl['formDataFields'],
  file: FileUploaderFileType['file']
) => {
  const formData = new FormData()

  Object.entries(formDataFields).forEach(([key, value]) => {
    formData.append(key, value)
  })
  formData.append('Content-Type', file.type)
  formData.append('Content-Length', file.size.toString())
  formData.append('file', file)

  return formData
}

const labelIdle = `
  <div class="flex flex-col items-center">
    <span class="text-base-700 font-medium text-base pb-2">
       Upload image
    </span>
    <span class="text-base-700 text-xs font-normal">
      Drag and drop your file here or <span class="filepond--label-action text-gold-600">choose a file</span> to upload.
    </span>
  </div>`

const MAX_FILE_SIZE = '1048576'

const ImageUploader = forwardRef<FileUploader, Props>(
  (
    {
      images,
      onUpdateFiles,
      allowMultiple = true,
      maxFiles,
      onWarning,
      onError,
      onAddFile,
    },
    ref
  ) => {
    return (
      <div className={cx({ hidden: images?.length })}>
        <FileUploader
          className="h-26"
          instantUpload={false}
          maxFiles={maxFiles}
          ref={ref}
          allowFileSizeValidation
          maxFileSize={MAX_FILE_SIZE}
          name="image-library-uploader"
          acceptedFileTypes={['image/jpeg', 'image/png', 'image/webp']}
          credits={false}
          files={images as FileUploaderProps['files']}
          allowMultiple={allowMultiple}
          onupdatefiles={onUpdateFiles}
          labelIdle={labelIdle}
          onwarning={onWarning}
          onerror={onError}
          onaddfile={onAddFile}
          server={{
            // eslint-disable-next-line max-params
            process: async (
              _,
              file,
              metadata,
              load,
              error,
              progress,
              abort
              // eslint-disable-next-line max-params
            ) => {
              if (!metadata || !metadata?.presignedUrl) {
                error('Invalid metadata format')
                return
              }

              const {
                presignedUrl: { url, formDataFields },
                id,
              } = metadata as Metadata

              const request = new XMLHttpRequest()
              request.open('POST', url)

              request.onload = function () {
                if (request.status >= 200 && request.status < 300) {
                  load(id)
                } else {
                  error('File upload error')
                }
              }

              request.onerror = function () {
                error('File upload error')
              }

              request.send(prepareFormData(formDataFields, file))

              return {
                abort: () => {
                  request.abort()
                  abort()
                },
              }
            },
          }}
        />
      </div>
    )
  }
)

export default ImageUploader
