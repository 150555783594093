import {
  ConnectContentPayload,
  ConnectContentResponse,
  GetIntegrationRoutingResponse,
  IntegrationLogsResponse,
} from '@phrasee/phrasee-typings'

import { connectInternalApi } from 'common/api'

export const getLogs = async ({ contentId }: { contentId?: string }) => {
  const result = await connectInternalApi.get<IntegrationLogsResponse>(
    `v1/content-integrations/logs`,
    {
      params: {
        contentId,
      },
    }
  )

  return result.data
}

export const connectContent = async ({
  accountId,
  integrationAccountId,
  contentId,
  content,
  elementId,
}: ConnectContentPayload) => {
  const result = await connectInternalApi.post<ConnectContentResponse>(
    `v1/content-integrations/content`,
    {
      accountId,
      integrationAccountId,
      contentId,
      content,
      elementId,
      fromUnifiedFlow: true,
    }
  )

  return result.data
}

export const getIntegrationsRoutingConfig = async ({
  contentId,
}: {
  contentId: string
}) => {
  const result = await connectInternalApi.get<GetIntegrationRoutingResponse>(
    `v1/content-integrations/routing-config/${contentId}`
  )

  return result.data
}
