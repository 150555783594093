import { useState } from 'react'

import type { ImageActionsProps } from 'common/components/images/imageActions'
import ImageActions from 'common/components/images/imageActions'
import type { ImageDetailsProps } from 'common/components/images/imageDetails'
import ImageDetails from 'common/components/images/imageDetails'
import ImageErrorFallback from 'common/components/images/imageErrorFallback'
import type { SelectableImageButtonProps } from 'common/components/images/selectableImageButton'
import SelectableImageButton from 'common/components/images/selectableImageButton'
import { useAppSelector } from 'common/hooks/redux'

import EditableImageName from './editableImageName'

type Props = Pick<ImageActionsProps, 'onDelete' | 'onPreview'> &
  Pick<SelectableImageButtonProps, 'isSelected' | 'url' | 'onClick'> &
  Omit<ImageDetailsProps, 'children' | 'className'> & {
    id: string
    fileName: string
  }

const ImageCard = ({
  id,
  fileName,
  fileSize,
  url,
  updatedAt,
  isSelected,
  onClick,
  onDelete,
  onPreview,
}: Props) => {
  const [isValidImage, setIsValidImage] = useState(!!url)
  const imageStatus = useAppSelector((state) => state.images.imageStatus)

  const isArchived = imageStatus === 'ARCHIVED'

  return (
    <ImageDetails
      fileName={<EditableImageName id={id} name={fileName} />}
      fileSize={fileSize}
      updatedAt={updatedAt}
      className="w-60.5"
    >
      {isValidImage ? (
        <>
          <ImageActions
            onDelete={isArchived ? undefined : onDelete}
            onPreview={onPreview}
          >
            <SelectableImageButton
              width={242}
              height={242}
              isSelected={isSelected}
              url={url}
              onClick={isArchived ? undefined : onClick}
              setIsValidImage={setIsValidImage}
            />
          </ImageActions>
        </>
      ) : (
        <ImageErrorFallback width="w-60.5" height="h-60.5" hasIcon />
      )}
    </ImageDetails>
  )
}

export default ImageCard
