import { generatePath, Link } from 'react-router-dom'
import { imageLibraryPath } from 'app/navigation/paths'

import type { ImageEntity } from 'common/components/images/api/api'
import Modal from 'common/components/Modal'
import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import ImageCard from './ImageCard'
import ImageList from './ImageList'
import ImageGalleryModalFooter from './ImagesGalleryModalFooter'

import styles from 'common/components/images/imagesStyles.module.css'

type Props = {
  imageSelectionLimit: number
  visible: boolean
  selectedImagesIds: string[]
  previewIndex?: string
  onCancel: () => void
  onAdd: () => void
  onSelectImage: (image: ImageEntity) => void
}

const ImageGalleryModal = ({
  visible,
  selectedImagesIds,
  previewIndex,
  imageSelectionLimit,
  onCancel,
  onAdd,
  onSelectImage,
}: Props) => {
  const { accountId } = useAppSelector((state) => state.authStates)

  return (
    <Modal
      closable={false}
      visible={visible}
      centered
      style={{ maxWidth: 1000 }}
      className={styles.modal}
      isResponsive
      destroyOnClose
    >
      <div className="w-full">
        <h5 className="text-xl text-coolGray-800 font-medium mb-2">
          Image library
        </h5>
        <div className="flex pb-6 justify-between items-center">
          <p className="text-gold-700 text-base font-medium">{`Selected images: ${selectedImagesIds.length}/${imageSelectionLimit}`}</p>
          <Link
            to={generatePath(imageLibraryPath, { accountId })}
            className="font-medium text-base-700 uppercase underline text-xs hover:opacity-50"
          >
            Open image library
          </Link>
        </div>

        <ImageList
          itemContent={(image) => {
            const isReplace = !!previewIndex

            const isSelected = isReplace
              ? image.id === previewIndex
              : selectedImagesIds.includes(image.id)

            const isDisabled = isReplace
              ? selectedImagesIds
                  .filter((id) => id !== previewIndex)
                  .includes(image.id)
              : selectedImagesIds.length >= imageSelectionLimit && !isSelected

            return (
              <ImageCard
                {...image}
                fileName={image.name ?? image.fileName}
                width={200}
                height={200}
                isSelected={isSelected}
                isDisabled={isDisabled}
                onClick={() => {
                  onSelectImage(image)
                }}
                onDisableClick={() => {
                  if (selectedImagesIds.length >= imageSelectionLimit) {
                    errorToast(
                      `Image limit reached. You can only select ${imageSelectionLimit} images from the library.`
                    )
                  }
                }}
              />
            )
          }}
        />
      </div>
      <ImageGalleryModalFooter
        disabled={selectedImagesIds.length === 0}
        onCancel={onCancel}
        onAdd={onAdd}
      />
    </Modal>
  )
}

export default ImageGalleryModal
