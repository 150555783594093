import { useQuery } from '@tanstack/react-query'

import { getTokens } from 'common/api/tokensApi'
import { useAppSelector } from 'common/hooks/redux'

import { integrationKeys } from '../queryKeys'

const useGetTokensQuery = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return useQuery(
    integrationKeys.tokens(accountId),
    () => getTokens(accountId),
    { enabled: !!accountId }
  )
}

export default useGetTokensQuery
