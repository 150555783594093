import Banner from 'common/components/banner'
import Button from 'common/components/button'
import { Alert, ArrowRight } from 'common/icons'

type Props = {
  variantsToBeApprovedCount: number
  onClick: () => void
}

const NewVariantsBanner = ({ variantsToBeApprovedCount, onClick }: Props) => {
  return (
    <Banner
      type="error"
      cta={
        <>
          <span className="text-white font-medium text-sm mr-2">SHOW ME</span>
          <Button
            prefixIcon={
              <ArrowRight
                className="text-white"
                isDefaultColor={false}
                size={4}
              />
            }
            size="small"
            className="h-auto border-white"
            variant="secondary"
            ghost
            onClick={onClick}
          />
        </>
      }
      icon={
        <Alert
          size={4}
          className="text-white"
          state="default"
          isDefaultColor={false}
        />
      }
      text={
        variantsToBeApprovedCount > 1
          ? `There are ${variantsToBeApprovedCount} new variants that need your approval.`
          : `There is ${variantsToBeApprovedCount} new variant that needs your approval.`
      }
    />
  )
}

export default NewVariantsBanner
