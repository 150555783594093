import { useEffect, useRef, useState } from 'react'

import { createCancelTokenSource, isCancel } from 'common/api'
import ConfirmationModal from 'common/components/confirmationModal'
import { DetailsPanelButton as Button } from 'common/components/detailsPanel'
import { errorToast, successToast } from 'common/components/toastNotification'
import { Archive } from 'common/icons'
import useUpdateProjectActionMutation from 'features/projects/mutations/useUpdateProjectActionMutation'

interface Props {
  accountId: string
  projectId: string
  isExpanded: boolean
}

const UnarchiveButton = ({ isExpanded, accountId, projectId }: Props) => {
  const mutation = useUpdateProjectActionMutation()
  const sourceRef = useRef(createCancelTokenSource())

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  useEffect(() => {
    const source = sourceRef.current
    return () => source.cancel()
  }, [])

  const unarchive = async () => {
    const source = sourceRef.current

    try {
      await mutation.mutateAsync({
        action: 'archived',
        value: false,
        accountId,
        projectId,
        source,
      })
      successToast('Project unarchived successfully')
      setIsModalOpen(false)
    } catch (error) {
      if (!isCancel(error)) {
        errorToast('Failed to unarchive project')
      }
    }
  }

  return (
    <>
      <Button
        isExpanded={isExpanded}
        data-cy="unarchive-project-button"
        data-testid="unarchive-project-button"
        title="Unarchive project"
        onClick={() => setIsModalOpen(true)}
        icon={<Archive isDefaultColor={false} className="text-red-400" />}
      />
      <ConfirmationModal
        data-cy="unarchive-project-confirm-modal"
        data-testid="unarchive-project-confirm-modal"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onConfirm={unarchive}
        title="Unarchive project?"
        confirmationText="This project will be unarchived."
        confirmButtonText="Yes, unarchive it!"
        isLoading={mutation.isLoading}
      />
    </>
  )
}

export default UnarchiveButton
