import cx from 'classnames'

import { SmallTick as SmallTickIcon } from 'common/icons'

type Props = {
  text: string
  variant?: 'success' | 'primary'
}

const SuccessButton: React.FC<Props> = ({ text, variant = 'success' }) => {
  return (
    <div
      className={cx(
        'py-2 px-6 text-white text-base h-10',
        { 'bg-green-500': variant === 'success' },
        { 'bg-maroon-200': variant === 'primary' }
      )}
    >
      <SmallTickIcon className="text-white" isDefaultColor={false} />
      <span className="ml-4">{text}</span>
    </div>
  )
}

export default SuccessButton
