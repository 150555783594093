import { forwardRef, RefCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import HC_more from 'highcharts/highcharts-more'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { cloneDeep } from 'lodash'

import { getLabelWithCheckbox } from 'common/helpers/ui/graph'
import { replaceNewLineEmojiAsLineBreak } from 'common/variantsUtils'

import { ChartInstance } from '../AutoResizeChart'

import SentimentAnalysisChartTooltips, {
  categoriesWithTooltip,
} from './SentimentAnalysisChartTooltips'

HC_more(Highcharts)

export interface Serie {
  data: number[]
  name: string
  color?: string
}

interface Props {
  onClick?: () => void
  className?: StringConstructor
  series: Serie[]
  onClickSentiment?: (sentiment: string) => void
  selectedSentiment?: string
  ref?: RefCallback<ChartInstance>
  isLegendVisible?: boolean
}

const categories = Object.keys(categoriesWithTooltip)

export default forwardRef<ChartInstance, Props>(
  (
    {
      onClick,
      className,
      series,
      onClickSentiment,
      selectedSentiment,
      isLegendVisible = false,
    },
    ref
  ) => {
    const [tooltipPositions, setTooltipPositions] =
      useState<{ [key: string]: { top: string; left: string } } | undefined>()
    const isEmptyState = series.length === 0
    const emptySerie: Highcharts.SeriesOptionsType = {
      name: 'none',
      // dummy data that will be hidden, needed though to display the xAxis labels
      data: Array(categories.length).fill(0),
      color: 'none',
      type: 'area',
      showInLegend: false,
    }

    const seriesOrEmptyState = isEmptyState ? [emptySerie] : series

    function positionTooltips() {
      let positions = {}
      categories.forEach((category, index) => {
        const element = document.querySelector(`span.${category}`)

        if (element && element.parentElement) {
          const bounds = element.parentElement.getBoundingClientRect()
          const margin = 8
          const width = bounds.right - bounds.left
          const isTooltipOnTheRight = index < categories.length / 2
          const left = Number(
            element.parentElement.style.left.replace(/px/, '')
          )
          positions = {
            ...positions,
            [category]: {
              top: element.parentElement.style.top,
              left: isTooltipOnTheRight
                ? left + width + margin
                : left - margin * 3,
            },
          }
        }
      })
      setTooltipPositions(positions)
    }

    useEffect(() => {
      const textClassSelected = 'text-maroon-200'
      const onClickCategory = (event: MouseEvent) => {
        if (!onClickSentiment) {
          return
        }

        const element = event.currentTarget as HTMLElement
        const category = element.textContent || ''
        onClickSentiment(category.toLowerCase())

        document
          .querySelectorAll('.category')
          .forEach((element) => element?.classList.remove(textClassSelected))

        element.classList.add(textClassSelected)
      }
      categories.forEach((category) => {
        const element: HTMLElement | null = document.querySelector(
          `span.${category}`
        )
        if (element !== null) {
          element.addEventListener('click', onClickCategory)
          if (category.toLowerCase() === selectedSentiment?.toLowerCase()) {
            element.classList.add(textClassSelected)
          }
        }
      })

      // Remove event listener on cleanup
      return () => {
        categories.forEach((category) => {
          const element: HTMLElement | null = document.querySelector(
            `span.${category}`
          )
          if (element !== null) {
            element.removeEventListener('click', onClickCategory)
          }
        })
      }
    })

    const categoriesWithId = categories.map((category) => {
      const className = cx(category, 'category z-20', {
        'cursor-pointer hover:text-maroon-200': onClickSentiment,
      })
      return `<span class="${className}">${category}</span>`
    })

    const chartOptions: Highcharts.Options = {
      chart: {
        polar: true,
        margin: [0, 0, 0, 0],
        marginBottom: 60,
        events: {
          load: function () {
            positionTooltips()
          },
        },
      },

      exporting: {
        enabled: false,
      },
      xAxis: {
        tickmarkPlacement: 'on',
        categories: categoriesWithId,
        lineWidth: 1,
        lineColor: '#e5e7eb',
        labels: {
          useHTML: true,
          style: {
            color: '#4b5563',
            fontSize: '16px',
          },
        },
      },
      yAxis: {
        lineWidth: 0,
        min: 0,
        labels: {
          style: {
            color: '#1f384d',
            fontSize: '8px',
            opacity: isEmptyState ? 0 : 0.8, // visible property is not used to show the second circle
          },
        },
      },
      legend: isLegendVisible
        ? {
            y: 23,
            floating: true,
            enabled: true,
            useHTML: true,
            labelFormatter: getLabelWithCheckbox({ isTextColored: false }),
            itemStyle: {
              fontSize: '16px',
              fontWeight: 'normal',
              color: '#1f2937',
              opacity: 0.8,
              lineHeight: '32px',
            },
            symbolWidth: 1,
            symbolHeight: 1,
            symbolPadding: -5,
            symbolRadius: 0,
            verticalAlign: 'bottom',
          }
        : {
            enabled: false,
          },
      credits: {
        enabled: false,
      },
      title: undefined,
      tooltip: {
        enabled: !isEmptyState,
        useHTML: true,
        outside: true,
        formatter: function () {
          const category = this.x
          const value = this.y

          return `
            <span class="text-xs">
              ${category}
            </span>: <span class="font-bold">${value}</span> <br>
            <span style="color:${this.point.color}">●</span>
            ${this.series.name}
          `
        },
      },
      series: [
        ...seriesOrEmptyState.map((serie) => ({
          ...cloneDeep(serie), // cloneDeep is needed to avoid mutating the original series
          name: replaceNewLineEmojiAsLineBreak(serie.name),
          type: 'area',
          borderColor: serie.color,
          opacity: 0.25,
          pointPlacement: 'on',
        })),
        emptySerie,
      ],
    }

    return (
      <div
        className={cx('flex justify-center m-auto', className)}
        onClick={onClick}
      >
        <div className="relative z-10">
          <SentimentAnalysisChartTooltips
            categories={categories}
            onClickSentiment={onClickSentiment}
            tooltipPositions={tooltipPositions}
          />
          <HighchartsReact
            highcharts={Highcharts}
            constructorType="chart"
            options={chartOptions}
            ref={ref}
            immutable={false}
          />
        </div>
      </div>
    )
  }
)
