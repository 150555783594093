import { Route } from 'react-router-dom'
import {
  combinationPerPersonalizationPath,
  personalizationCombinationPerIdPath,
  personalizedContentPath,
} from 'app/navigation/paths'
import CustomSwitch from 'app/router/Switch'

import Combination from './combination/Combination'
import CombinationLibrary from './combinationLibrary/CombinationLibrary'
import PersonalizedContent from './PersonalizedContent'

const PersonalizedContentSwitch = () => {
  return (
    <CustomSwitch>
      <Route exact path={personalizedContentPath}>
        <PersonalizedContent />
      </Route>
      <Route path={combinationPerPersonalizationPath}>
        <Combination />
      </Route>
      <Route path={personalizationCombinationPerIdPath}>
        <CombinationLibrary />
      </Route>
    </CustomSwitch>
  )
}

export default PersonalizedContentSwitch
