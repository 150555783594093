import { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  getIsAuthorized,
  permissions as allPermissions,
} from 'common/auth/permissions'
import Button from 'common/components/button'
import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'
import {
  Bin as DeleteIcon,
  MoreOptions as MoreOptionsIcon,
  Move as MoveIcon,
} from 'common/icons'
import { Node } from 'common/interfaces/nodes'
import useDeleteNodeMutation from 'features/content/api/mutations/useDeleteNodeMutation'
import useMoveNodeMutation from 'features/content/api/mutations/useMoveNodeMutation'

import DeleteNodeModal from './DeleteNodeModal'
import EditNode from './EditNode'
import MoveNodesModal from './MoveNodesModal'
import NameInput from './NameInput'

type Props = {
  selectedNode: Node
  rootNodeId: string
  isParentNode: boolean
  nodes: Node[]
  setSelectedNodeId: (value: string) => void
  onNodeClick: (nodeId?: string, rootNodeId?: string) => void
}

const NodesActions = ({
  selectedNode,
  rootNodeId,
  isParentNode,
  nodes,
  setSelectedNodeId,
  onNodeClick,
}: Props) => {
  const moveNodeMutation = useMoveNodeMutation()
  const deleteNodeMutation = useDeleteNodeMutation()
  const [isMoveNodesModalOpen, setIsMoveNodesModalOpen] =
    useState<boolean>(false)
  const [isDeleteNodeModalOpen, setIsDeleteNodeModalOpen] =
    useState<boolean>(false)
  const { user_id: userId } = useAppSelector((state) => state.authStates)

  const flags = useFlags()
  const showDeleteNodeButton = flags.showDeleteNodeButton

  const { permissions } = useAppSelector((state) => state.authStates)
  const hasNodeEditPermission = getIsAuthorized(
    permissions,
    allPermissions.languageContentEdit
  )
  const handleMoveNodes = ({
    nodeId,
    parentNodeId,
  }: {
    nodeId: number
    parentNodeId: number
  }) => {
    moveNodeMutation.mutate(
      {
        userId,
        nodeId,
        targetParentNodeId: parentNodeId,
      },
      {
        onSuccess: () => {
          successToast('Node moved successfully')
          setIsMoveNodesModalOpen(false)
        },
        onError: () => errorToast('Failed to move node'),
      }
    )
  }

  const handleDeleteNode = () => {
    const parentNodeId = selectedNode.parent
    const nextRootNode =
      nodes.filter((node) => node.parent === parentNodeId).length > 1
        ? parentNodeId
        : nodes?.find((node) => node.id === parentNodeId)?.parent

    if (selectedNode.id) {
      deleteNodeMutation.mutate(
        {
          nodeId: parseInt(selectedNode.id),
          userId,
        },
        {
          onSuccess: () => {
            setIsDeleteNodeModalOpen(false)
          },
          onError: () => errorToast('Node delete failed. Please try again.'),
        }
      )
    }

    onNodeClick(parentNodeId, nextRootNode)
    setSelectedNodeId(parentNodeId)
  }
  return (
    <>
      <div className="flex justify-between">
        {hasNodeEditPermission ? (
          <NameInput currentNode={selectedNode} />
        ) : (
          <div className="font-medium text-3xl text-coolGray-800">
            {selectedNode.name}
          </div>
        )}
        {hasNodeEditPermission && selectedNode.id !== rootNodeId && (
          <div className="flex items-center">
            {showDeleteNodeButton && (
              <ButtonWithTooltip
                data-cy="delete-node-button"
                data-testid="delete-node-button"
                onClick={() => setIsDeleteNodeModalOpen(true)}
                size="small"
                prefixIcon={<DeleteIcon className="w-5 h-5" />}
                tooltip={isParentNode}
                tooltipText="Can’t delete nodes with children."
                disabled={isParentNode}
              />
            )}
            <Button
              data-cy="move-node-button"
              data-testid="move-node-button"
              onClick={() => setIsMoveNodesModalOpen(true)}
              size="small"
              prefixIcon={<MoveIcon className="w-5 h-5" />}
            />
            <Button
              data-cy="node-menu-button"
              data-testid="node-menu-button"
              onClick={() => {
                alert('Not yet implemented')
              }}
              size="small"
              prefixIcon={<MoreOptionsIcon className="w-5 h-5" />}
            />
          </div>
        )}
      </div>
      {hasNodeEditPermission && <EditNode currentNode={selectedNode} />}

      {selectedNode && (
        <MoveNodesModal
          currentNode={selectedNode}
          isOpen={isMoveNodesModalOpen}
          onCancel={() => setIsMoveNodesModalOpen(false)}
          onClickNext={handleMoveNodes}
          nodes={nodes}
        />
      )}
      <DeleteNodeModal
        isOpen={isDeleteNodeModalOpen}
        onClose={() => {
          setIsDeleteNodeModalOpen(false)
        }}
        onConfirm={() => {
          handleDeleteNode()
        }}
      />
    </>
  )
}

export default NodesActions
