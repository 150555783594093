import { useMemo } from 'react'
import { Variant } from '@phrasee/phrasee-typings'
import cx from 'classnames'

import { Column } from 'common/components/table'
import TableWidget from 'common/components/widget/tableWidget'
import useTableActions from 'common/hooks/useTableActions'
import TweakVariantModal from 'features/unifiedFlow/contentPage/components/tweakVariantModal'
import { checkVariantActionLoading } from 'features/unifiedFlow/contentPage/helpers'
import {
  useContent,
  useSelectedComponentElement,
} from 'features/unifiedFlow/contentPage/hooks'
import useTweakSuccess from 'features/unifiedFlow/contentPage/hooks/useTweakSuccess'

import ActionCell from '../../../components/ActionsCell'
import useVariantTweakWebsocketSubscription from '../../../hooks/useVariantTweakWebsocketSubscription'
import { VARIANTS_CATEGORIES } from '../../constants'
import ContentTableActionButtons from '../contentTable/contentTableActionButtons'
import useSelectedVariant from '../optimizeResults/useSelectedVariant'
import VariantCell from '../optimizeResults/VariantCell'
import TableHeading from '../tableHeading'

type ExtendedVariant = Variant & {
  isHighlighted: boolean
  category?: string
  isLoading?: boolean
  isRefreshed?: boolean
}

type Props = {
  isReadOnly?: boolean
}

const categoryOrder = [
  VARIANTS_CATEGORIES.BASELINE,
  VARIANTS_CATEGORIES.USER_MODIFIED,
  '',
]

const PersonalizedVariantsTable = ({ isReadOnly }: Props) => {
  const { content } = useContent()
  const { data: selectedElement } = useSelectedComponentElement()
  const variants: Variant[] | undefined = selectedElement?.variants

  const { selectedVariantId, setSelectedVariantId } = useSelectedVariant()

  const { tweakSuccessVariantIds, onTweakSuccess } = useTweakSuccess()

  useVariantTweakWebsocketSubscription({ onTweakSuccess })

  const variantsWithModifiedState = useMemo(() => {
    const mappedVariants = variants?.map((variant) => {
      const isModifiedByUser = !!variant?.why_tweak
      const category = variant.ownsl
        ? undefined
        : isModifiedByUser
        ? VARIANTS_CATEGORIES.USER_MODIFIED
        : VARIANTS_CATEGORIES.BASELINE
      const isSelected = selectedVariantId === variant.variant_id
      const isLoading = checkVariantActionLoading({
        content,
        selectedElement,
        variantId: variant.sl_id,
      })

      return {
        ...variant,
        ...(category ? { category } : {}),
        ...(variant.ownsl ? { category: '' } : {}),
        isHighlighted: isSelected,
        isRefreshed: tweakSuccessVariantIds.includes(variant.sl_id ?? ''),
        isLoading: isLoading,
      }
    })

    return mappedVariants?.toSorted((a, b) => {
      if (a.category && b.category) {
        return (
          categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category)
        )
      }
      return 0
    })
  }, [
    content,
    selectedElement,
    selectedVariantId,
    tweakSuccessVariantIds,
    variants,
  ])

  const isApproveButtonDisabled = variantsWithModifiedState?.some(
    (variant) => variant.isLoading
  )

  const elementId = selectedElement?.element_id

  const { onCopy, onRequestChange } = useTableActions()

  const columns: Column<ExtendedVariant>[] = useMemo(
    () => [
      {
        Header: 'Variants',
        accessor: 'text',
        disableSortBy: true,
        disableFilters: true,
        direction: 'auto',
        Cell: ({ row }) => {
          return (
            <VariantCell
              index={row.pageRowIndex}
              text={row.original.text}
              isControlVariant={!!row.original.ownsl}
              className={cx('min-h-14', {
                'text-base-700': row.original.isHighlighted,
                'text-gold-700': !row.original.isHighlighted,
              })}
            />
          )
        },
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: 'variant_id',
        disableSortBy: true,
        align: 'right',
        disableFilters: true,
        width: 140,
        className: 'flex-none',
        Cell: ({ row }) => {
          if (selectedElement?.approved) {
            return null
          }

          return (
            <ActionCell
              onCopy={() => onCopy(row.values.text)}
              onRequestChange={
                row.original.ownsl
                  ? undefined
                  : () => onRequestChange(row.original)
              }
              isTweaking={row.original.isLoading}
              isSuccessful={row.original.isRefreshed}
            />
          )
        },
      },
      {
        accessor: 'category',
      },
    ],
    [onCopy, selectedElement?.approved, onRequestChange]
  )

  const handleRowClick = (variant: ExtendedVariant) => {
    setSelectedVariantId(variant.variant_id)
  }

  const isFirstRowGrouped = !!variantsWithModifiedState?.[0]?.category

  if (variantsWithModifiedState?.length === 0) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full bg-gold-100 gap-4 text-base-700 p-8">
        <div className="font-medium text-7xl text-center">
          There are no lines to approve at this time.
        </div>
        <div className="text-5">
          We’ll notify you when new variants are generated.
        </div>
      </div>
    )
  }

  return (
    <div className="w-full">
      <TableHeading
        heading={selectedElement?.display_name || selectedElement?.name}
        subHeading={selectedElement?.description}
      />
      <TableWidget.Widget<ExtendedVariant>
        key={elementId}
        data={variantsWithModifiedState}
        columns={columns}
        autoResetPage={false}
        onRowClick={handleRowClick}
        headerClassName={cx({
          'bg-transparent text-base-700': isFirstRowGrouped,
        })}
        initialState={{
          groupBy: ['category'],
          hiddenColumns: ['category'],
        }}
      >
        {(variantsWithModifiedState?.length ?? 0) > 0 && (
          <TableWidget.ActionButtons className="w-full">
            <ContentTableActionButtons
              isReadOnly={isReadOnly}
              isApproveButtonDisabled={isApproveButtonDisabled}
            />
          </TableWidget.ActionButtons>
        )}
      </TableWidget.Widget>
      <TweakVariantModal campaignType="linguo" />
    </div>
  )
}

export default PersonalizedVariantsTable
