import { FormApi, FormState, getIn } from 'final-form'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'

import { TemplateToAdd } from 'features/unifiedFlow/store/unifiedFlowSlice'

import { TemplateInfoWithInternalId } from './addedTemplates/AddedComponentList'

export const getPrefixedName = (
  name: string,
  template?: TemplateInfoWithInternalId
) => (template ? getExperimentPrefixedName(template, name) : name)

export const getTemplatePrefixedName = (
  template: TemplateInfoWithInternalId,
  name: string
) => `templateId-${template.internalId}.${name}`

export const getExperimentPrefixedName = (
  template: TemplateInfoWithInternalId,
  name: string
) => `templateId-${template.internalId}.experiment.${name}`

export const getInitialExperimentNameValue = ({
  templatesToAdd,
  template,
  formState,
}: {
  templatesToAdd: TemplateToAdd[]
  template: TemplateInfoWithInternalId
  formState: FormState<Record<string, any>, Partial<Record<string, any>>>
}) => {
  let initExperimentNameValue: string | undefined

  const experimentNameValue = get(
    formState.values,
    getExperimentPrefixedName(template, 'experimentName'),
    undefined
  )

  if (!experimentNameValue) {
    const filteredTemplatesToAdd = templatesToAdd.filter(
      (item) => item.id === template.id
    )

    const templateIdx = findIndex(
      filteredTemplatesToAdd,
      (item) => item.internalId === template.internalId
    )

    initExperimentNameValue = `${
      formState.values.workflowName ? `${formState.values.workflowName} - ` : ''
    }${template.name}${
      filteredTemplatesToAdd.length > 1 ? ` - ${templateIdx + 1}` : ''
    }`
  }

  return initExperimentNameValue
}

export const getContentPrefixedName = (
  template: TemplateInfoWithInternalId,
  name: string
) => `templateId-${template.internalId}.content.${name}`

export const getFormValue = ({
  formState,
  templateInternalId,
  name,
}: {
  formState: FormState<Record<string, any>, Partial<Record<string, any>>>
  templateInternalId?: string
  name: string
}): any =>
  templateInternalId
    ? getIn(formState.values, `templateId-${templateInternalId}.${name}`)
    : formState.values[name]

export const getSelectedProjectId = (
  formState: FormState<Record<string, any>, Partial<Record<string, any>>>,
  template?: TemplateInfoWithInternalId | TemplateToAdd
) =>
  getFormValue({
    formState,
    templateInternalId: template?.internalId,
    name: template?.internalId ? 'experiment.project' : 'project',
  })?.value

export const getIsExperimentFormValid = (
  form: FormApi<Record<string, any>, Partial<Record<string, any>>>,
  template: TemplateInfoWithInternalId | undefined
) => {
  const experimentFields = form
    .getRegisteredFields()
    .filter((fieldName) =>
      fieldName.includes(`templateId-${template?.internalId}.experiment.` || '')
    )

  return experimentFields.length > 0
    ? experimentFields.every((fieldName) => {
        const fieldstate = form.getFieldState(fieldName)
        return fieldstate?.valid
      })
    : false
}

export const getExperimentFormStatus = (
  form: FormApi<Record<string, any>, Partial<Record<string, any>>>,
  template: TemplateInfoWithInternalId | undefined
): TemplateToAdd['status'] => {
  const experimentFields = form
    .getRegisteredFields()
    .filter((fieldName) =>
      fieldName.includes(`templateId-${template?.internalId}.experiment.` || '')
    )

  if (experimentFields.length === 0) {
    return 'pristine'
  }

  const isFormValid = getIsExperimentFormValid(form, template)
  if (isFormValid) {
    return 'complete'
  } else {
    return 'incomplete'
  }
}
export const getIsContentFormValid = (
  form: FormApi<Record<string, any>, Partial<Record<string, any>>>,
  template: TemplateInfoWithInternalId | undefined
) => {
  const brandVoiceValue = getFormValue({
    formState: form.getState(),
    templateInternalId: template?.internalId,
    name: 'content.brandVoice',
  })?.value

  return !!brandVoiceValue
}
