import { useEffect, useRef } from 'react'
import { generatePath, Redirect, Route, useParams } from 'react-router-dom'
import { StepName } from '@phrasee/phrasee-typings/typings/futurama/content'
import { unifiedFlowPaths } from 'app/navigation/paths'
import CustomSwitch from 'app/router/Switch'

import ErrorPage from 'common/components/error/ErrorPage'
import Loader from 'common/components/loaders/Loader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useWebsockets from 'common/hooks/useWebsockets'

import useGetContentQuery from '../api/queries/useGetContentQuery'
import {
  resetContentPageState,
  unifiedContentInitialize,
} from '../store/unifiedFlowSlice'

import Header from './components/header/Header'
import useTrackUnifiedFlowSteps from './hooks/useTrackUnifiedFlowSteps'
import BriefAndGenerateManager from './briefAndGenerateManager'
import { getStepStatus } from './helpers'
import Integrations from './integrations'
import Results from './results'
import Review from './review'
import useUserTrackingWebsocketSubscription from './useUserTrackingWebsocketSubscription'

const getPagePath = (stepName: StepName | undefined) => {
  if (stepName === 'generation') {
    return unifiedFlowPaths.generate
  } else if (stepName === 'launch') {
    return unifiedFlowPaths.launch
  } else if (stepName === 'review') {
    return unifiedFlowPaths.review
  } else if (stepName === 'results') {
    return unifiedFlowPaths.results
  } else {
    return unifiedFlowPaths.generate
  }
}

const ContentPage = () => {
  const dispatch = useAppDispatch()

  const { sendJsonMessage, isConnectionReady } = useWebsockets()

  useTrackUnifiedFlowSteps()

  const hasRegisteredWebsocketsRef = useRef(false)
  const { contentId } = useParams<{ contentId: string }>()

  useUserTrackingWebsocketSubscription(contentId)

  const activeContentId = useAppSelector(
    (state) => state.unifiedFlow.activeContentId
  )

  const { status, data: content } = useGetContentQuery(contentId)

  const selectedPage = content?.steps_status?.find(
    (step) => step.status === 'active'
  )?.step

  const firstEnabledPage = content?.steps_status?.find(
    (step) => step.status === 'enabled'
  )?.step

  const selectedPageWithFallback = selectedPage || firstEnabledPage
  const selectedPagePath = generatePath(getPagePath(selectedPageWithFallback), {
    contentId,
  })

  const launchStepsStatus = getStepStatus(content?.steps_status, 'launch')
  const resultsStepsStatus = getStepStatus(content?.steps_status, 'results')
  const reviewStepStatus = getStepStatus(content?.steps_status, 'review')

  useEffect(() => {
    if (content && !activeContentId) {
      dispatch(
        unifiedContentInitialize({
          activeContentId: contentId,
          isUsingUniversalBrief: content.use_universal_brief,
        })
      )
    }
  }, [activeContentId, content, contentId, dispatch])

  useEffect(() => {
    if (content && isConnectionReady && !hasRegisteredWebsocketsRef.current) {
      content.ws_messages?.forEach((message) => {
        sendJsonMessage({
          action: 'subscribe',
          topics: [message.wsTopic],
        })
      })
      hasRegisteredWebsocketsRef.current = true
    }
  }, [content, isConnectionReady, sendJsonMessage])

  useEffect(() => {
    return () => {
      dispatch(resetContentPageState())
    }
  }, [dispatch])

  const getState = () => {
    if (status === 'error') {
      return 'error'
    } else if (!!content && !!activeContentId) {
      return 'success'
    } else {
      return 'loading'
    }
  }

  const state = getState()

  return (
    <>
      {
        {
          loading: <Loader />,
          success: (
            <>
              <Header />
              <CustomSwitch>
                <Route
                  key="unified-flow-content-generate"
                  path={unifiedFlowPaths.generate}
                  exact
                  render={() => <BriefAndGenerateManager />}
                />
                <Route
                  key="unified-flow-content-launch"
                  path={unifiedFlowPaths.launch}
                  exact
                  render={() =>
                    launchStepsStatus === 'disabled' ? (
                      <Redirect to={selectedPagePath} />
                    ) : (
                      <Integrations />
                    )
                  }
                />
                <Route
                  key="unified-flow-content-review"
                  path={unifiedFlowPaths.review}
                  exact
                  render={() =>
                    reviewStepStatus === 'disabled' ||
                    reviewStepStatus === 'hidden' ? (
                      <Redirect to={selectedPagePath} />
                    ) : (
                      <Review />
                    )
                  }
                />
                <Route
                  key="unified-flow-content-results"
                  path={unifiedFlowPaths.results}
                  exact
                  render={() =>
                    resultsStepsStatus === 'disabled' ||
                    resultsStepsStatus === 'hidden' ? (
                      <Redirect to={selectedPagePath} />
                    ) : (
                      <Results />
                    )
                  }
                />
                <Redirect
                  exact
                  from={unifiedFlowPaths.content}
                  to={selectedPagePath}
                />
              </CustomSwitch>
            </>
          ),
          error: <ErrorPage />,
        }[state]
      }
    </>
  )
}

export default ContentPage
