const ROOT_KEY = 'demo'

export const demoKeys = {
  all: [ROOT_KEY] as const,
  brands: [ROOT_KEY, 'brand'] as const,
  channel: [ROOT_KEY, 'channel'] as const,
  personas: (accountId: string) => {
    return [ROOT_KEY, 'personas', accountId] as const
  },
  variants: ({
    accountId,
    brand,
    channel,
    persona,
    prevVariantsIds,
  }: {
    accountId: string
    brand: string
    channel: string
    persona: string
    prevVariantsIds: string[]
  }) => {
    return [
      ROOT_KEY,
      'variants',
      accountId,
      brand,
      channel,
      persona,
      prevVariantsIds,
    ] as const
  },
}
