import { ComponentProps } from 'react'

import NumericInput from '../../numericInput'

import 'rc-slider/assets/index.css'

const SliderInputDefault = ({
  onChange,
  value,
  min,
  max,
  'aria-label': ariaLabel,
  disabled,
}: Pick<
  ComponentProps<typeof NumericInput>,
  'onChange' | 'value' | 'min' | 'max' | 'aria-label' | 'disabled'
>) => (
  <NumericInput
    aria-label={ariaLabel}
    disabled={disabled}
    isWithoutStyles
    onChange={onChange}
    value={value}
    precision={0}
    min={min}
    className="bg-white m-2 border shadow-bottom outline-none focus:outline-none box-content font-medium
            focus:placeholder-maroon-200 focus:border-maroon-400 text-center text-coolGray-800 text-xs py-1 px-2"
    max={max}
    autoResize={{ paddingPx: 8 }}
  />
)

export default SliderInputDefault
