import { useQuery } from '@tanstack/react-query'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { TreeNode } from 'common/components/catalog/Catalog'
import { PersonalizationRegion } from 'common/components/personalizationReport/api'
import { useAppSelector } from 'common/hooks/redux'

import { fetchFilters } from '../api'
import { personalizationKeys } from '../queryKeys'

function removeColumnsWithTooManyValues({
  data,
  maxValues,
}: {
  data: TreeNode
  maxValues: number
}): TreeNode {
  return Object.entries(data).reduce((acc, [key, value]) => {
    if (Object.keys(value).length <= maxValues) {
      acc[key] = value
    }
    return acc
  }, {})
}

const useGetFiltersQuery = (
  personalizationRegion: PersonalizationRegion | undefined
) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const flags = useFlags()

  const query = useQuery(
    personalizationKeys.selectedFilters,
    () =>
      fetchFilters({
        accountId,
        ...(flags.switchToRegionalizedData && {
          personalizationRegion: personalizationRegion?.toLocaleLowerCase(),
        }),
      }),
    {
      enabled: !!accountId,
      retry: false,
      select: (data) => {
        const mappedAttributes: TreeNode = Object.fromEntries(
          data.customerAttributes.map((item) => {
            if (item.type === 'list') {
              return [item.name, item.values]
            }
            return [item.name, item]
          })
        )

        return {
          productCategories: removeColumnsWithTooManyValues({
            data: data.productCategories,
            maxValues: 100,
          }),
          customerAttributes: removeColumnsWithTooManyValues({
            data: mappedAttributes,
            maxValues: 100,
          }),
          channels: data.channels,
          useCases: data.useCases,
          initialCustomerAttributes: data.customerAttributes.filter(
            (item) => item.type !== 'list' || item.values.length <= 100
          ),
        }
      },
      meta: {
        errorMessage: 'The filters could not be loaded',
      },
    }
  )

  return query
}

export default useGetFiltersQuery
