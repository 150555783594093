type Props = {
  children: React.ReactNode
}

const ActionsContainer = ({ children }: Props) => {
  return (
    <div className="flex flex-col w-full">
      <hr className="w-full my-6 border-gold-400" />
      <div className="flex flex-row justify-between">{children}</div>
    </div>
  )
}

export default ActionsContainer
