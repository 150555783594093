import { ChangeEvent, useState } from 'react'

import Button from 'common/components/button'
import FormItem from 'common/components/formItem'
import Modal from 'common/components/Modal'
import Textarea from 'common/components/textarea'
import { errorToast } from 'common/components/toastNotification'
import { Replacer } from 'common/components/topics/interfaces'
import TagWidget from 'common/components/widget/TagWidget/TagWidget'
import { useAppSelector } from 'common/hooks/redux'
import { Tag } from 'common/interfaces/tags'
import { useBulkCreatePhraseMutation } from 'features/content/api/mutations'
import useGetReplacersQuery from 'features/content/queries/useGetConfiguredReplacersQuery'

import { validReplacerRegex } from './cells/PhraseCell'

const MAX_LINE_LENGTH = 60

type Props = {
  isOpen: boolean
  selectedNodeId: string
  close: () => void
  tags: Tag[]
}

const AddPhrasesModal = ({
  isOpen,
  selectedNodeId,
  close,
  tags: tagOptions,
}: Props) => {
  const [phrases, setPhrases] = useState<string>()
  const [tags, setTags] = useState<string[]>([])
  const [warning, setWarning] = useState<string>()

  const userId = useAppSelector((state) => state.authStates.user_id)
  const createPhrasesBulkMutation = useBulkCreatePhraseMutation(selectedNodeId)

  const { replacers } = useGetReplacersQuery()

  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value
    setPhrases(value)
    const showMaxLineWarning = value
      .split('\n')
      .some((line) => line.length > MAX_LINE_LENGTH)
    setWarning(
      showMaxLineWarning
        ? 'Please make sure all phrases are separated by line breaks'
        : undefined
    )
  }

  const onClose = () => {
    setPhrases(undefined)
    setWarning(undefined)
    close()
  }

  const handleAddPhrases = async () => {
    const errorList: string[] = []

    const phrasesList = phrases?.split('\n').filter((phrase) => phrase !== '')
    const successPhrasesList: string[] = []

    let hasInvalidReplacer = false
    phrasesList?.forEach((phrase) => {
      const replacerMatches = phrase?.match(validReplacerRegex)
      if (replacerMatches) {
        replacerMatches.forEach((match) => {
          const foundReplacer = replacers?.find(
            (replacer) => (replacer.original as Replacer).replacer === match
          )
          if (foundReplacer) {
            successPhrasesList.push(phrase)
          } else {
            errorList.push(phrase)
            hasInvalidReplacer = true
          }
        })
      } else {
        successPhrasesList.push(phrase)
      }
    })

    if (hasInvalidReplacer) {
      setWarning('Please make sure that phrases contain valid replacers.')
    }

    if (successPhrasesList && successPhrasesList.length > 0) {
      createPhrasesBulkMutation.mutate(
        {
          userId,
          phrases: successPhrasesList?.map((phrase) => ({
            frequency: '1.0',
            phrase,
          })),
          nodeId: Number(selectedNodeId),
          replacers,
          tags: tags,
          tagOptions,
        },
        {
          onSuccess: (result) => {
            result.failedPhrases.forEach((phrase) => {
              errorList.push(phrase)
              setWarning('Failed to add these phrases.')
            })

            if (errorList.length > 0) {
              errorToast(`Failed to add the following phrases.`)
              setPhrases(errorList.join('\n'))
            } else {
              onClose()
            }
          },
          onError: () => {
            errorToast('An error occurred while trying to add phrases.')
          },
        }
      )
    }
  }

  return (
    <Modal
      visible={isOpen}
      closable={false}
      isResponsive
      centered
      style={{ maxWidth: '1300px' }}
      destroyOnClose={true}
      onClose={onClose}
      maskClosable={false}
      footer={
        <div className="flex justify-end">
          <Button
            className="mr-4"
            ghost
            onClick={() => {
              onClose()
            }}
            disabled={createPhrasesBulkMutation.isLoading}
            data-cy="cancel-add-phrases"
            data-testid="cancel-add-phrases"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            data-cy="bulk-add-phrases-button"
            data-testid="bulk-add-phrases-button"
            onClick={handleAddPhrases}
            disabled={!phrases}
            loading={createPhrasesBulkMutation.isLoading}
          >
            Add
          </Button>
        </div>
      }
    >
      <div className="text-coolGray-800 text-2xl font-bold mn-4">
        Add multiple phrases
      </div>
      <div className="text-coolGray-500 mb-4 text-lg">
        Bulk add phrases to your selected node. Type or paste in the phrases you
        want to add, separated by line breaks (⏎).
      </div>
      <FormItem error={warning}>
        <Textarea
          allowTab
          className="h-75 resize-none bg-coolGray-100"
          autoFocus
          value={phrases}
          onChange={handleOnChange}
          placeholder="Add phrases..."
          data-cy="bulk-add-phrases-textarea"
          data-testid="bulk-add-phrases-textarea"
        />
      </FormItem>

      <TagWidget
        isFreeText
        onAddTag={(tag) => {
          setTags([...tags, tag])
        }}
        onRemoveTag={(tag) => {
          setTags(tags.filter((t) => t !== tag))
        }}
        hasTagButtonLabel
        data-cy="add-phrases-tag"
        data-testid="add-phrases-tag"
        placeholder="Tag"
        title="Tags"
        subtitle="Add tags that you want to apply in bulk to these phrases."
        tags={tags.map((tag) => ({ label: tag, value: tag }))}
        suggestions={tagOptions.map((tag) => tag.tag)}
      />
    </Modal>
  )
}

export default AddPhrasesModal
