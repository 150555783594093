import { Fragment } from 'react'
import { Role } from '@phrasee/phrasee-typings/typings/user/user'
import cx from 'classnames'
import { isEmpty, isString } from 'lodash'
import helpers from 'workflow/utils/helpers'

import Button from 'common/components/button/Button'
import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import Label from 'common/components/label'
import ProjectMultiSelect from 'common/components/ProjectMultiSelect'
import Scrollable from 'common/components/scrollable'
import { stripToValidateName } from 'common/helpers/stripForbiddenCharacters'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'
import { ReactComponent as Bin } from 'common/icons/bin/default.svg'

import { InviteUser } from '../../api'
import Email from '../../Email'
import RoleSelect from '../../RoleSelect'

import { Footer } from './Footer'
import Header from './Header'

type Props = {
  users: InviteUser[]
  updateUsers: (value: InviteUser[]) => void
  addUser: () => void
  onClickCancel: () => void
  onSubmit: (user: InviteUser[]) => void
  assignableRoles: Role[]
}

export function AddUsersFormContent({
  users,
  addUser,
  updateUsers,
  onSubmit,
  onClickCancel,
  assignableRoles,
}: Props) {
  const emails = users.map((users) => users.email)
  const nonEmptyEmails = emails.filter((email) => email.length > 0)
  const areValidEmails =
    nonEmptyEmails.length > 0 && nonEmptyEmails.every(helpers.isValidEmail)

  const formWithValues = users.filter((user) =>
    Object.values(user).some((value) => !isEmpty(value))
  )
  const canSubmitForm =
    formWithValues.length > 0 &&
    formWithValues.every((user) => {
      if (user.projectIds?.length === 0) {
        delete user.projectIds
      }
      const userProperties = Object.values(user)
      return (
        userProperties.every((value) => {
          return !isEmpty(isString(value) ? value.trim() : value)
        }) && areValidEmails
      )
    })

  return (
    <div className="flex pt-6 pb-6" style={{ width: 1068 }}>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          onSubmit(formWithValues)
        }}
        className="flex flex-col flex-1"
      >
        <Scrollable
          header={<Header />}
          contentClassName="pl-6 mt-4 text-sm text-coolGray-500 grid flex-0 pr-6 max-h-149"
          content={
            <>
              <fieldset>
                <div
                  className="grid gap-x-4 "
                  style={{
                    gridTemplateColumns:
                      '300px 1fr 1fr 160px 190px min-content',
                  }}
                >
                  <Label>Email address</Label>
                  <Label>First name</Label>
                  <Label>Last name</Label>
                  <Label>Role</Label>
                  <Label>Projects</Label>
                  <div></div>

                  {users.map(
                    (
                      { firstName, email, lastName, roles, projectIds },
                      index
                    ) => (
                      <Fragment key={index}>
                        <Email
                          onChange={(event) => {
                            const updatedUsers = [...users]
                            updatedUsers[index].email =
                              event.target.value.trim()
                            updateUsers(updatedUsers)
                          }}
                          value={email}
                        />
                        <FormItem
                          error={
                            email && isEmpty(firstName.trim())
                              ? 'First name is required'
                              : undefined
                          }
                        >
                          <Input
                            variant="default"
                            type="text"
                            onChange={(event) => {
                              const updatedUsers = [...users]
                              updatedUsers[index].firstName =
                                stripToValidateName(event.target.value)
                              updateUsers(updatedUsers)
                            }}
                            name="firstName"
                            value={firstName}
                            hasError={false}
                            placeholder="eg. Greg"
                          />
                        </FormItem>
                        <FormItem
                          error={
                            email && isEmpty(lastName.trim())
                              ? 'Last name is required'
                              : undefined
                          }
                        >
                          <Input
                            variant="default"
                            type="text"
                            onChange={(event) => {
                              const updatedUsers = [...users]
                              updatedUsers[index].lastName =
                                stripToValidateName(event.target.value)
                              updateUsers(updatedUsers)
                            }}
                            name="lastName"
                            value={lastName}
                            hasError={false}
                            placeholder="eg. Davis"
                          />
                        </FormItem>
                        <RoleSelect
                          className="font-medium"
                          isSearchable={true}
                          assignableRoles={assignableRoles}
                          value={roles[0]}
                          onChange={(val) => {
                            if (val?.value) {
                              const updatedUsers = [...users]
                              updatedUsers[index].role = val.value
                              updatedUsers[index].roles = [val.value]
                              updateUsers(updatedUsers)
                            }
                          }}
                          menuPortalTarget={document.body}
                        />
                        <ProjectMultiSelect
                          data-cy="invite-user-project-select"
                          data-testid="invite-user-project-select"
                          isSelectAllEnabled={true}
                          isFooterHidden={true}
                          isLabelHidden={true}
                          selectedItems={projectIds}
                          placeholder="Select"
                          onApply={(val) => {
                            const updatedUsers = [...users]
                            updatedUsers[index].projectIds = val
                            updateUsers(updatedUsers)
                          }}
                          useAllAccountProjects={true}
                          menuPortalTarget={document.body}
                        />

                        <Button
                          aria-label="delete"
                          className="ml-2 h-10"
                          variant="icon"
                          data-cy="invite-user-delete"
                          data-testid="invite-user-delete"
                          onClick={() => {
                            const updatedUsers = [...users]
                            updatedUsers.splice(index, 1)
                            updateUsers(updatedUsers)
                          }}
                        >
                          <Bin className="h-6 w-6" />
                        </Button>
                      </Fragment>
                    )
                  )}
                </div>
              </fieldset>
              <div
                className={cx('flex justify-start text-base items-center pb-6')}
              >
                <Button
                  variant="primary"
                  className="p-0"
                  onClick={() => {
                    addUser()
                  }}
                  data-cy="add-user"
                  data-testid="add-user"
                  prefixIcon={<AddIcon width={24} height={24} />}
                  disabled={
                    emails.length >= 10 ||
                    (emails.length > 0 &&
                      emails.some((email) => !helpers.isValidEmail(email)))
                  }
                >
                  User
                </Button>
              </div>
            </>
          }
          footer={
            <Footer
              onClickCancel={onClickCancel}
              canSubmitForm={canSubmitForm}
            />
          }
        />
      </form>
    </div>
  )
}

export default AddUsersFormContent
