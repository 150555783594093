import cx from 'classnames'

type Props = {
  title: string
  description?: string
  action?: React.ReactNode
  children?: React.ReactNode
  className?: string
  size?: 'small' | 'medium'
}

const EmptyState = ({
  title,
  description,
  action,
  children,
  className,
  size = 'medium',
}: Props) => {
  return (
    <div
      className={cx(
        'flex flex-col justify-center items-center bg-gold-50 p-20',
        {
          'w-full gap-10 ': size === 'medium',
          'gap-5': size === 'small',
        },
        className
      )}
    >
      <div
        className={cx('flex flex-col items-center', {
          'gap-4': size === 'medium',
          'gap-2': size === 'small',
        })}
      >
        <h2
          className={cx('font-medium', {
            'text-3xl': size === 'medium',
            'text-2xl': size === 'small',
          })}
        >
          {title}
        </h2>
        <p
          className={cx('text-lg text-gold-700', {
            'text-lg': size === 'medium',
            'text-xxs': size === 'small',
          })}
        >
          {description}
        </p>
      </div>
      {action}
      {children}
    </div>
  )
}

export default EmptyState
