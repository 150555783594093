import { routesPermissions } from 'common/auth/permissions'
import PersonalizationContentSwitch from 'features/admin/personalizedContent/PersonalizedContentSwitch'
import AdminRegionSettings from 'features/admin/regions/RegionSettings'
import AdminNodes from 'features/content/nodes'

import { TabbedNavRoute } from './interfaces'
import { nodesPath, regionSettingsPath } from './paths'

export const regionSettingsRoute: TabbedNavRoute = {
  key: 'region',
  name: 'Region settings',
  to: regionSettingsPath,
  component: AdminRegionSettings,
  accessPermission: routesPermissions.contentView,
}

export const nodesRoute: TabbedNavRoute = {
  key: 'nodes',
  name: 'Nodes',
  to: nodesPath,
  component: AdminNodes,
}
export const personalizationContentRoute: TabbedNavRoute = {
  key: 'personalizedContent',
  name: 'Personalised Content',
  to: '/admin/language/personalized-content',
  component: PersonalizationContentSwitch,
  accessFlags: { flags: ['showLanguagePersonalizedContent'], type: 'hide' },
  accessPermission: routesPermissions.personalizedContentView,
}

export const languageSubroutes: TabbedNavRoute[] = [
  nodesRoute,
  regionSettingsRoute,
  personalizationContentRoute,
]
