import React from 'react'
import { SyntheticEvent } from 'react'

import Button from 'common/components/button'
import Modal from 'common/components/Modal'

type Props = {
  visible: boolean
  onCancel?: (e: SyntheticEvent) => void
  onContinue?: React.MouseEventHandler<HTMLElement>
  message: string
}
class NerValidationModal extends React.Component<Props> {
  render() {
    const { visible, onCancel, onContinue, message } = this.props
    return (
      <Modal
        title=""
        className="ner-validation-modal"
        visible={visible}
        closable={false}
        onClose={onCancel}
        footer={null}
        centered
        style={{
          width: 520,
        }}
        wrapStyle={{ margin: 80 }}
      >
        <div>
          <div>
            <p>{message || ''}</p>
            <p>
              To get the best possible results, we recommend you go back and
              select options that generate the same content as your human line.
            </p>
            <p>What do you want to do?</p>
            <div className="buttons-row text-align--right">
              <Button
                className="ant-btn-secondary m-l--10"
                onClick={onCancel}
                data-cy="nerdvalidation-edit-options-button"
                data-testid="nerdvalidation-edit-options-button"
              >
                Edit my options
              </Button>
              <Button
                variant="primary"
                onClick={onContinue}
                data-cy="nerdvalidation-continue-button"
                data-testid="nerdvalidation-continue-button"
              >
                Continue with these options
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

export default NerValidationModal
