import { useState } from 'react'

import useCleanImagesStateOnUnmount from 'common/components/images/hooks/useCleanImagesStateOnUnmount'
import PageContainer from 'common/components/PageContainer'
import PageHeader from 'common/components/PageHeader'
import PageSubTitle from 'common/components/pageSubTitle'
import PageTitle from 'common/components/PageTitle'
import { useDocumentTitle } from 'common/hooks/custom'

import ImageManager from './components/imageManager'
import UploaderModal from './components/uploaderModal'

const ImageLibrary = () => {
  useDocumentTitle('Image library | Jacquard')

  useCleanImagesStateOnUnmount()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  return (
    <PageContainer className="px-6">
      <PageHeader className="mb-8">
        <div className="flex justify-between flex-1">
          <div className="pt-9">
            <PageTitle title="Image library" />
            <PageSubTitle description="Easily upload, organise, and access all your image assets in one place." />
          </div>
        </div>
      </PageHeader>

      <ImageManager onClickAddImage={() => setIsModalVisible(true)} />
      <UploaderModal
        isModalVisible={isModalVisible}
        onClose={setIsModalVisible}
      />
    </PageContainer>
  )
}

export default ImageLibrary
