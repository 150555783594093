import { forwardRef } from 'react'
import { useSortable } from '@dnd-kit/sortable'
import cx from 'classnames'

import Button from 'common/components/button'
import { FieldsConfiguration } from 'common/components/dynamicFields/interfaces'
import { useAppDispatch } from 'common/hooks/redux'
import { Close as CloseIcon } from 'common/icons'

import { removeTopic } from '../store/topicsSlice'

import ConfigurationMenu from './nodeConfiguration/Menu'
import type { SortableTopicCardProps } from './SortableTopicCard'
import TopicCardName from './TopicCardName'

export type Props = SortableTopicCardProps &
  Partial<ReturnType<typeof useSortable>> & {
    style?: React.CSSProperties
  }

const TopicCard = forwardRef<HTMLDivElement, Props>(
  ({ topic, isDisabled, ...rest }, ref) => {
    const dispatch = useAppDispatch()
    const { id, category, name, type } = topic

    const isReplacer = type === 'replacer'
    const hasError = isReplacer && (name === undefined || name === '')

    const configuration: FieldsConfiguration | undefined =
      topic.type === 'replacer' ? topic.configuration : undefined

    if (isReplacer && configuration === undefined) {
      return null
    }

    return (
      <div
        {...rest}
        ref={ref}
        data-testid={id}
        className={cx('p-2 border w-60 h-16 mr-4 flex-none mt-4 bg-white', {
          'bg-red-50 border-red-500': hasError,
          'border-coolGray-300': !hasError,
        })}
      >
        <div className="flex justify-between h-full">
          <div>
            <div
              className={cx('mb-1', {
                'text-red-400': hasError,
                'text-coolGray-500': !hasError,
              })}
            >
              {category}
            </div>
            <TopicCardName
              name={name}
              hasError={hasError}
              isReplacer={isReplacer}
            />
          </div>
          <div
            className={cx('flex justify-between items-center', {
              'text-red-400': hasError,
            })}
          >
            {type === 'replacer' && (
              <ConfigurationMenu
                topicId={id}
                isDisabled={isDisabled}
                hasReplacerError={hasError}
                configuration={configuration!}
              />
            )}
            <Button
              variant="icon"
              onClick={() => dispatch(removeTopic(id))}
              data-testid="remove-topic-button"
              disabled={isDisabled}
            >
              <CloseIcon
                size={4}
                isDefaultColor={!hasError}
                className={cx({ 'text-red-400': hasError })}
              />
            </Button>
          </div>
        </div>
      </div>
    )
  }
)

export default TopicCard
