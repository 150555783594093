import Button from './button/Button'
import Modal from './Modal'

interface Props {
  title: string
  message: string
  onClose: () => void
  isVisible: boolean
}

const InfoMessageModal = ({ title, message, isVisible, onClose }: Props) => {
  return (
    <Modal
      visible={isVisible}
      maskClosable
      onClose={onClose}
      centered
      closable={false}
      footer={null}
    >
      <div>
        <h1 className="text-2xl font-bold text-coolGray-800 mb-7 inline">
          {title}
        </h1>
      </div>
      <div className="mt-4 mb-14 text-lg text-coolGray-500">{message}</div>
      <div className="flex justify-end">
        <Button
          variant="primary"
          className="text-base"
          onClick={onClose}
          data-cy="limit-reached-cancel-button"
          data-testid="limit-reached-cancel-button"
        >
          Ok, thanks!
        </Button>
      </div>
    </Modal>
  )
}

export default InfoMessageModal
