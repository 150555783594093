import HoldingPage from './Page'

export const NEW_ACCOUNT_TITLE = 'New kid on the block huh? Welcome'

const NewAccount = () => {
  return (
    <HoldingPage>
      <HoldingPage.Title>{NEW_ACCOUNT_TITLE}</HoldingPage.Title>
      <HoldingPage.SubTitle className="max-w-212">
        You have no experiments yet. Once you have completed an experiment, come
        back and review your results.
      </HoldingPage.SubTitle>
    </HoldingPage>
  )
}

export default NewAccount
