import { Field } from 'react-final-form'

import FormItem from 'common/components/formItem'
import Input from 'common/components/input'

const MAX_NAME_LENGTH = 128

const nameValidation = (value: string) => {
  if (!value) {
    return 'Name is required'
  } else if (value.length > MAX_NAME_LENGTH) {
    return `Name must be ${MAX_NAME_LENGTH} characters or less`
  }

  return undefined
}

const MergeTagName = () => {
  return (
    <Field<string>
      name="merge_tag_name"
      validate={nameValidation}
      render={({ input, meta }) => (
        <FormItem
          label="Name"
          htmlFor="merge_tag_name"
          className="flex-none"
          error={meta.touched ? meta.error : undefined}
        >
          <Input
            type="text"
            id="merge_tag_name"
            variant="default"
            placeholder="Give it a meaningful name"
            className="text-coolGray-800"
            value={input.value}
            name={input.name}
            onChange={input.onChange}
            onFocus={input.onFocus}
            onBlur={input.onBlur}
          />
        </FormItem>
      )}
    />
  )
}

export default MergeTagName
