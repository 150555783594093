import { useFlags } from 'launchdarkly-react-client-sdk'

import CampaignResultsTableDefault from './ResultsTableDefault/CampaignResultsTableDefault'
import CampaignResultsTable from './CampaignResultsTable'
import { ResultsTableProps } from './interfaces'

const CampaignResultsTablePerFlag = (props: ResultsTableProps) => {
  const flags = useFlags()

  return flags?.showL2Components ? (
    <CampaignResultsTable {...props} />
  ) : (
    <CampaignResultsTableDefault {...props} />
  )
}

export default CampaignResultsTablePerFlag
