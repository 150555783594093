import TagWidget from 'common/components/widget/TagWidget/TagWidget'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { addActiveTag, removeActiveTag } from '../../store/nodeSlice'

const AlwaysOnTags = () => {
  const dispatch = useAppDispatch()
  const tags = useAppSelector((state) => state.projectNodes.tags)
  const editedActiveTags = useAppSelector(
    (state) => state.projectNodes.editedData.activeTags
  )

  return (
    <TagWidget
      data-cy="always-on-tags-widget"
      data-testid="always-on-tags-widget"
      className="mt-8"
      title="Always-On tags"
      subtitle="Select which tags can always be used in generation."
      placeholder="Always-on tag..."
      tags={editedActiveTags.map((tag) => ({ label: tag, value: tag }))}
      onAddTag={(value: string) => dispatch(addActiveTag(value))}
      onRemoveTag={(value: string) => dispatch(removeActiveTag(value))}
      suggestions={tags?.map(({ tag }) => tag)}
      isFreeText={false}
      hasTagButtonLabel
    />
  )
}

export default AlwaysOnTags
