import { ReactNode } from 'react'
import cx from 'classnames'

type Props = {
  children: ReactNode
  variant: 'nav' | 'subNav'
  className?: string
}

const subNavClassname = 'bg-maroon-600 border-b border-solid px-6 py-3'
const topNavClassname = 'shadow-bottom z-100 bg-maroon-400 px-6 py-4'

const NavbarLayout = ({ variant, className, children }: Props) => {
  return (
    <div
      data-cy={variant}
      data-testid={variant}
      className={cx('box-border flex items-center justify-between', className, {
        [`${subNavClassname}`]: variant === 'subNav',
        [`${topNavClassname}`]: variant === 'nav',
      })}
    >
      {children}
    </div>
  )
}

export default NavbarLayout
