import cx from 'classnames'

type Props = { className?: string }

const FormErrorDefault: React.FC<Props> = ({ children, className }) => (
  <div className={cx('mt-2 text-red-400 capitalize-first', className)}>
    {children}
  </div>
)

export default FormErrorDefault
