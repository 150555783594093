import React, { useCallback, useState } from 'react'
import cx from 'classnames'

import Tooltip from 'common/components/Tooltip'
import type { State } from 'common/icons/Icon'

type Props = {
  icon?: React.ComponentType<{ state?: State; size?: number }>
  index?: number
  title: string
  isSelected: boolean
  isNavCollapsed?: boolean
  isNavHovered?: boolean
}

function SubNavItem({
  icon: Icon,
  index,
  title,
  isSelected,
  isNavCollapsed,
  isNavHovered,
}: Props) {
  const [isTooltipShown, setIsTooltipShown] = useState(false)

  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const titleRef: React.RefCallback<HTMLSpanElement> = useCallback(
    (currentTitleRef) => {
      if (currentTitleRef !== null) {
        if (currentTitleRef.offsetWidth < currentTitleRef.scrollWidth) {
          setIsTooltipShown(true)
        } else {
          setIsTooltipShown(false)
        }
      }
    },
    []
  )

  return (
    <div
      className={cx(
        `p-2 hover:bg-coolGray-100 flex items-center group`,
        { 'ml-9 mr-4': !Icon },
        { 'mt-2': index === 0 } // TODO use first-of-type instead of the index when when migrate to Tailwind v3
      )}
    >
      {Icon && (
        <span
          className={cx({
            'mr-4 hover-group:text-selected-icon': !isNavCollapsed,
          })}
        >
          <Icon state={isSelected ? 'selected' : 'default'} size={5} />
        </span>
      )}

      {/* The tooltips are used for truncated projects in the campaign page */}
      <Tooltip overlay={title} show={isTooltipShown}>
        <span
          data-cy={`${title.replace(/\s+/g, '-').toLowerCase()}-menu-item`}
          data-testid={`${title.replace(/\s+/g, '-').toLowerCase()}-menu-item`}
          ref={titleRef}
          className={cx(
            `capitalize text-coolGray-500 group-hover:text-coolGray-800 
             truncate`,
            {
              'text-coolGray-800': isSelected,
            }
          )}
        >
          {title}
        </span>
      </Tooltip>
    </div>
  )
}

export default SubNavItem
