import cx from 'classnames'

import { mapContentTypeToIcon } from 'common/components/ContentTypeIcon'

const ElementCardIcon = ({
  icon,
  className,
  size,
}: {
  icon: string
  className?: string
  size?: number
}) => {
  const props = {
    height: 20,
    className: cx('text-base-700', className),
    size,
  }

  const Icon = mapContentTypeToIcon[icon]
  return Icon ? (
    <Icon data-testid={icon} isDefaultColor={false} {...props} />
  ) : null
}

export default ElementCardIcon
