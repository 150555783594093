import type { ImagePageResponse } from 'common/components/images/api/api'
import Pagination from 'common/components/table/pagination'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import {
  onPaginationLimitChange,
  onPaginationOffsetChange,
} from '../store/imagesSlice'

type Props = Pick<ImagePageResponse, 'total'> & {
  className?: string
}

const ImagePagination = ({ total, className }: Props) => {
  const dispatch = useAppDispatch()
  const limit = useAppSelector((state) => state.images.pagination.limit)
  const offset = useAppSelector((state) => state.images.pagination.offset)

  const pageIndex = Math.floor(offset / limit)

  return (
    <Pagination
      className={className}
      pageSize={limit}
      pageIndex={pageIndex}
      setPageSize={(limit) => dispatch(onPaginationLimitChange(limit))}
      pageCount={Math.ceil(total / limit)}
      totalRowCount={total}
      gotoPage={(page) => dispatch(onPaginationOffsetChange(page * limit))}
      canPreviousPage={pageIndex > 0}
      previousPage={() => {
        if (pageIndex > 0) {
          dispatch(onPaginationOffsetChange((pageIndex - 1) * limit))
        }
      }}
      nextPage={() => {
        if (pageIndex < Math.ceil(total / limit) - 1) {
          dispatch(onPaginationOffsetChange((pageIndex + 1) * limit))
        }
      }}
      canNextPage={pageIndex < Math.ceil(total / limit) - 1}
    />
  )
}

export default ImagePagination
