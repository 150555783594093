import { Content } from '@phrasee/phrasee-typings'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import { startPersonalizationElement } from '../api'
import { contentKeys } from '../queryKeys'

const useStartPersonalizationMutation = () => {
  const queryClient = useQueryClient()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return useMutation<
    Content,
    { response: { data: { message: string } } },
    Parameters<typeof startPersonalizationElement>[0],
    unknown
  >(startPersonalizationElement, {
    onError: (e) => {
      errorToast(e.response.data.message)
    },
    onSuccess: (content) => {
      queryClient.setQueryData(contentKeys.content(content._id), content)
      queryClient.invalidateQueries(contentKeys.contentLibrary(accountId))
    },
  })
}

export default useStartPersonalizationMutation
