import { useEffect, useState } from 'react'
import { gql } from 'graphql-request'

import { fetchGraphQL } from 'common/api'
import { SelectValue } from 'common/components/BaseSelect'
import ErrorPage from 'common/components/error/ErrorPage'
import NewAccount from 'common/components/holdingPages/NewAccount'
import Loader from 'common/components/loaders/Loader'
import PageContainer from 'common/components/PageContainer'
import { useDocumentTitle } from 'common/hooks/custom'
import { useAppSelector } from 'common/hooks/redux'
import { getAllProjects } from 'features/campaigns/store/campaignSlice'

import { Mode } from '../store/insightsSlice'

import NoResults from './holdingPages/NoResults'
import Welcome from './holdingPages/Welcome'
import { fetchCampaigns } from './api'
import Content from './Content'
import Header from './Header'

export const INSIGHTS_WELCOME_TITLE =
  'Run a language report and get a glimpse into the language driving your results.'

const getCampaignsCount = (accountId: string) => {
  const query = gql`
    query GetAllCampaignsCount($filter: CampaignFilter) {
      data: campaigns(filter: $filter) {
        count
      }
    }
  `
  const variables = {
    filter: {
      distributionChannelList: ['email', 'push_notification', 'sms', 'adwords'],
      accountId,
    },
  }

  return fetchGraphQL<{ count: number }>({ query, variables })
}

const Insights = () => {
  useDocumentTitle('Insights | Jacquard')

  const {
    project,
    projectIdToCompare,
    startDate,
    endDate,
    mode,
    startDateToCompare,
    endDateToCompare,
  } = useAppSelector((state) => state.insights)
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const [campaignsCount, setCampaignsCount] = useState<number>()
  const [campaignIdsForSelectedProject, setCampaignIdsForSelectedProject] =
    useState<string[]>([])

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hasError, setHasError] = useState<boolean>(false)

  const projects: SelectValue[] = useAppSelector(getAllProjects)
    .filter(
      ({ distributionChannel }) =>
        !['landing_page', 'facebook'].includes(distributionChannel)
    )
    .map(({ id, name }) => {
      return { value: id, label: name }
    })

  useEffect(() => {
    ;(async () => {
      try {
        setHasError(false)

        const countResponse = await getCampaignsCount(accountId)
        setCampaignsCount(countResponse.count)
      } catch {
        setHasError(true)
      }
      setIsLoading(false)
    })()
  }, [accountId])

  useEffect(() => {
    if (project && startDate && endDate) {
      ;(async () => {
        try {
          setIsLoading(true)
          setHasError(false)

          const { campaigns } = await fetchCampaigns(
            project,
            startDate,
            endDate,
            accountId
          )
          setCampaignIdsForSelectedProject(campaigns.map(({ _id }) => _id))
        } catch {
          setHasError(true)
        }
        setIsLoading(false)
      })()
    }
  }, [endDate, project, startDate, accountId])

  const getState = () => {
    if (
      project &&
      startDate &&
      endDate &&
      campaignIdsForSelectedProject.length === 0 &&
      mode === 'basicReport'
    ) {
      return 'noResults'
    } else if (campaignsCount === 0 && mode === 'basicReport') {
      return 'noCampaigns'
    } else if (
      mode === 'basicReport'
        ? project && startDate && endDate
        : mode === 'projectComparison'
        ? project && projectIdToCompare && startDate && endDate
        : project &&
          startDate &&
          endDate &&
          startDateToCompare &&
          endDateToCompare
    ) {
      return 'hasResults'
    } else {
      return 'welcome'
    }
  }
  const state = getState()

  if (hasError) {
    return <ErrorPage />
  }
  const welcomeTitlePerMode: Record<Mode, string> = {
    basicReport:
      'Run a language report and get a glimpse into the language driving your results.',
    projectComparison: 'Compare project vs project.',
    dateRangeComparison: 'Compare date vs date.',
  }

  return (
    <PageContainer>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col h-full">
          <Header title="Language insights" projects={projects} />
          {
            {
              noCampaigns: <NewAccount />,
              noResults: <NoResults />,
              hasResults: (
                <Content
                  project={project}
                  projects={projects}
                  startDate={startDate!}
                  endDate={endDate!}
                />
              ),
              welcome: <Welcome title={welcomeTitlePerMode[mode]} />,
            }[state]
          }
        </div>
      )}
    </PageContainer>
  )
}

export default Insights
