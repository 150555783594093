import { useMemo, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import { accountsPermissions, getIsAuthorized } from 'common/auth/permissions'
import ConfirmationModal from 'common/components/confirmationModal'
import InfoAction from 'common/components/InfoAction'
import { MenuItem } from 'common/components/menuButton'
import OptionsMenuButton from 'common/components/optionsMenuButton'
import { Column, Row } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import ImageCell from 'common/components/table/cells/Image'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppSelector } from 'common/hooks/redux'

import { Account } from '../..'

type Props = {
  accounts: Account[]
  isLoading: boolean
  hasError: boolean
  title: string
  editAccount: (account: Account) => void
  archiveAccount: (id: string) => void
  deactivateAccount: (id: string) => void
  reactivateAccount: (id: string) => void
  addMeToAccount: (id: string) => void
  removeMeFromAccount: (id: string) => void
}

const AccountsTable = ({
  accounts,
  hasError,
  isLoading,
  title,
  editAccount,
  archiveAccount,
  deactivateAccount,
  reactivateAccount,
  addMeToAccount,
  removeMeFromAccount,
}: Props) => {
  const [accountToDeactivate, setAccountToDeactivate] =
    useState<string | undefined>(undefined)
  const [accountToReactivate, setAccountToReactivate] =
    useState<string | undefined>(undefined)
  const [accountToArchive, setAccountToArchive] =
    useState<string | undefined>(undefined)
  const [accountToRestore, setAccountToRestore] =
    useState<string | undefined>(undefined)

  const flags = useFlags()
  const {
    accounts: userAccounts,
    permissions,
    accountId: currentAccountId,
  } = useAppSelector((state) => state.authStates)
  const userAccountsIds = userAccounts?.map((account) => account.id)

  const hasEditPermission = getIsAuthorized(
    permissions,
    accountsPermissions.edit
  )

  const columns: Column<Account>[] = useMemo(() => {
    const getMenuOptions = (row: Row<Account>): JSX.Element | null => {
      const { id, status } = row.original

      if (
        !hasEditPermission &&
        (status === 'deactivated' || status === 'archived')
      ) {
        return null
      }

      return (
        <>
          {
            {
              active: (
                <>
                  {hasEditPermission && (
                    <>
                      <MenuItem onClick={() => editAccount(row.original)}>
                        Edit
                      </MenuItem>
                      <MenuItem onClick={() => setAccountToDeactivate(id)}>
                        Deactivate
                      </MenuItem>
                      <MenuItem onClick={() => setAccountToArchive(id)}>
                        Archive
                      </MenuItem>
                    </>
                  )}
                  {!userAccountsIds?.includes(id) && (
                    <MenuItem onClick={() => addMeToAccount(id)}>
                      Add me to account
                    </MenuItem>
                  )}
                  {userAccountsIds?.includes(id) &&
                    userAccountsIds.length > 1 &&
                    currentAccountId !== id && (
                      <MenuItem onClick={() => removeMeFromAccount(id)}>
                        Remove me from account
                      </MenuItem>
                    )}
                </>
              ),
              deactivated: (
                <MenuItem onClick={() => setAccountToReactivate(id)}>
                  Reactivate
                </MenuItem>
              ),
              archived: (
                <MenuItem onClick={() => setAccountToRestore(id)}>
                  Restore
                </MenuItem>
              ),
            }[status]
          }
        </>
      )
    }

    const logoColumn: Column<Account> = {
      accessor: 'logoUrl',
      width: 60,
      disableSortBy: true,
      Cell: ({ value }) => <ImageCell src={value} width={86} />,
    }

    const columns: Column<Account>[] = [
      {
        Header: 'Company',
        accessor: 'companyName',
        minWidth: 250,
        sortType: (rowA, rowB) => {
          const companyNameA = rowA.original.companyName
          const companyNameB = rowB.original.companyName
          return companyNameA.localeCompare(companyNameB, undefined, {
            sensitivity: 'base',
          })
        },
      },

      {
        Header: 'Projects',
        accessor: 'projectsCount',
        align: 'right',
      },
      {
        Header: 'Experiments',
        accessor: 'campaignsCount',
        align: 'right',
      },
      {
        Header: 'Category',
        accessor: 'accountCategory',
        align: 'right',
      },
      {
        Header: 'Users',
        accessor: 'usersCount',
        align: 'right',
      },
      {
        Header: 'Dashboard mode',
        accessor: 'dashboardMode',
        align: 'right',
      },
      {
        Header: 'Status',
        accessor: 'status',
        align: 'right',
        Cell: ({ value }) => (
          <BaseCell>
            <span className="capitalize">{value}</span>
          </BaseCell>
        ),
      },
      {
        accessor: 'id',
        align: 'right',
        disableSortBy: true,
        shouldOverflow: true,
        Cell: ({ row }) => {
          const options = getMenuOptions(row)

          return (
            <>
              {options && (
                <BaseCell>
                  <OptionsMenuButton
                    name="row options"
                    options={options}
                    overflow="hidden"
                  />
                </BaseCell>
              )}
            </>
          )
        },
      },
    ]

    if (flags.showAccountLogo) {
      columns.unshift(logoColumn)
    }

    return columns
  }, [
    addMeToAccount,
    removeMeFromAccount,
    editAccount,
    flags.showAccountLogo,
    hasEditPermission,
    userAccountsIds,
    currentAccountId,
  ])

  return (
    <>
      <TableWidget.Widget
        isLoading={isLoading}
        hasError={hasError}
        columns={columns}
        data={accounts}
        initialState={{ sortBy: [{ id: 'companyName', desc: false }] }}
        firstUseText="Nothing to see here… There are no accounts to show."
      >
        <TableWidget.Header title={title}>
          <TableWidget.Menu />
          <InfoAction message="Info" />
        </TableWidget.Header>
      </TableWidget.Widget>
      <ConfirmationModal
        data-cy="deactivate-confirmation-modal"
        data-testid="deactivate-confirmation-modal"
        title="Deactivate account?"
        confirmationText="All standard users will no longer be able to access the account. Jacquard system users will still have access."
        confirmButtonText="Deactivate now"
        cancelButtonText="Stop, don’t do it"
        open={accountToDeactivate !== undefined}
        hasConfirmationSlider
        onConfirm={() => {
          accountToDeactivate && deactivateAccount(accountToDeactivate)
          setAccountToDeactivate(undefined)
        }}
        onCancel={() => {
          setAccountToDeactivate(undefined)
        }}
      />
      <ConfirmationModal
        data-cy="reactivate-confirmation-modal"
        data-testid="reactivate-confirmation-modal"
        title="Reactivate account?"
        confirmationText="All users will be able to access the account again."
        confirmButtonText="Reactivate now"
        cancelButtonText="Stop, don’t do it"
        open={accountToReactivate !== undefined}
        hasConfirmationSlider
        onConfirm={() => {
          accountToReactivate && reactivateAccount(accountToReactivate)
          setAccountToReactivate(undefined)
        }}
        onCancel={() => {
          setAccountToReactivate(undefined)
        }}
      />
      <ConfirmationModal
        data-cy="archive-confirmation-modal"
        data-testid="archive-confirmation-modal"
        title="Archive account?"
        confirmationText="No users will be able to see the account in their account list. The account can be restored at any time from the archived accounts list."
        confirmButtonText="Archive now"
        cancelButtonText="Stop, don’t do it"
        open={!!accountToArchive}
        hasConfirmationSlider
        onConfirm={() => {
          accountToArchive && archiveAccount(accountToArchive)
          setAccountToArchive(undefined)
        }}
        onCancel={() => {
          setAccountToArchive(undefined)
        }}
      />
      <ConfirmationModal
        data-cy="restore-confirmation-modal"
        data-testid="restore-confirmation-modal"
        title="Restore account?"
        confirmationText="Restore the account to its previous state."
        confirmButtonText="Restore now"
        cancelButtonText="Stop, don’t do it"
        open={!!accountToRestore}
        hasConfirmationSlider
        onConfirm={() => {
          accountToRestore && reactivateAccount(accountToRestore)
          setAccountToRestore(undefined)
        }}
        onCancel={() => {
          setAccountToRestore(undefined)
        }}
      />
    </>
  )
}

export default AccountsTable
