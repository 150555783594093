/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */

/* eslint-disable max-lines */
import { CampaignConfiguration } from '@phrasee/phrasee-typings'
import {
  Campaign,
  CampaignData,
} from '@phrasee/phrasee-typings/Graphql/interfaces'
import moment from 'moment-timezone'
import { LockAudienceType } from 'workflow/CampaignSummary/IntegrationOptions/EpsilonIntegration/EpsilonIntegration.types'
import { AppliedFilters, SubjectLine } from 'workflow/interface'
import * as XLSX from 'xlsx'

import { isSFMC } from 'features/campaigns/components/details/helpers'

declare const window: any

const isDevelopment = JSON.parse(
  process?.env?.REACT_APP_IS_DEVELOPMENT
    ? process?.env?.REACT_APP_IS_DEVELOPMENT
    : ''
)

export const CHARACTER_VALIDATION_ERROR =
  'Jacquard only accepts letters, numbers, spaces, hyphens and underscores' +
  ' in experiment names.'

const CAMPAIGN_NAME_REGEX = /^[0-9a-zA-Z\s\-_]+$/
export const CAMPAIGN_NAME_LIMIT = 128
export const SFMC_CAMPAIGN_NAME_LIMIT = 50

const isBanditCampaign = (config: any) => {
  const data = !!config?.campaign_configuration
    ? config?.campaign_configuration
    : config
  return data?.testing_method?.optimization_method?.selection_type === 'bandit'
}
const isInitialised = (campaignData: any): boolean => {
  return campaignData?.campaign_progress?.phrasee_x_initialised === true
}
const isNlgCampaign = (campaignData: any): boolean => {
  const testedSections =
    campaignData?.campaign_configuration?.testing_method
      ?.tested_content_sections
  return testedSections?.length
    ? testedSections[0]?.language_source === 'NLG'
    : false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  isInitialised,
  isNlgCampaign,
  isLinguoCampaign(campaignData: Campaign): boolean {
    const testedSections =
      campaignData?.campaign_configuration?.testing_method
        ?.tested_content_sections
    return testedSections?.length
      ? testedSections[0]?.language_source === 'linguo'
      : false
  },
  isLlmCampaign(campaignData: Campaign): boolean {
    const testedSections =
      campaignData?.campaign_configuration?.testing_method
        ?.tested_content_sections
    return testedSections?.length
      ? testedSections[0]?.language_source === 'llm'
      : false
  },
  isBanditCampaign,
  isBanditNlgInitialised(campaignData: any): boolean {
    const initialised = isInitialised(campaignData)
    const isBandit = isBanditCampaign(campaignData)
    const isNlg = isNlgCampaign(campaignData)
    return initialised && isBandit && isNlg
  },
  isBanditInitialised(campaignData: any): boolean {
    const initialised = isInitialised(campaignData)
    const isBandit = isBanditCampaign(campaignData)
    return initialised && isBandit
  },
  shouldEnableVariantApprovals(campaignData: CampaignData): boolean {
    return (
      this.isBanditNlgInitialised(campaignData) ||
      (isInitialised(campaignData) && this.isLinguoCampaign(campaignData)) ||
      (isInitialised(campaignData) && this.isLlmCampaign(campaignData))
    )
  },
  enumSelector<EnumT>(type: string, Enum: any): EnumT {
    const selectedValue: { [idx: string]: EnumT } = Enum
    return selectedValue[type]
  },
  isAManagedCampaign(campaignData: any): boolean {
    const testedSections =
      campaignData?.campaign_configuration?.testing_method
        ?.tested_content_sections
    return testedSections?.length
      ? testedSections[0]?.language_source === 'managed'
      : false
  },
  /**
   * Also known as "trigger" campaign
   * @param campaignData
   * @returns {boolean}
   */
  isReactCampaign(campaignData: any): boolean {
    return campaignData?.campaign_configuration?.product_type === 'react'
  },
  /**
   * Also known as "broadcast" campaign
   * @param campaignData
   * @returns {boolean}
   */
  isEngageCampaign(campaignData: any): boolean {
    return campaignData?.campaign_configuration?.product_type === 'engage'
  },
  isBroadcastCampaign(campaignData: Campaign): boolean {
    return (
      campaignData?.campaign_configuration?.distribution_type === 'broadcast'
    )
  },
  isEpsilonCampaign(campaign: Campaign) {
    return (
      campaign?.campaign_configuration?.integration_options?.type === 'epsilon'
    )
  },
  isBloomreachMoengageInAppMessage(campaign: Campaign) {
    return (
      (campaign?.campaign_configuration?.integration_options?.type ===
        'bloomreach' ||
        campaign?.campaign_configuration?.integration_options?.type ===
          'moengage') &&
      campaign?.campaign_configuration?.distribution_channel ===
        'in_app_message'
    )
  },
  isValidEmail(email: string): boolean {
    // Implemented as per the HTML5 spec for email validation (https://html.spec.whatwg.org/multipage/input.html#e-mail-state-(type%3Demail))
    // eslint-disable-next-line no-useless-escape
    return /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
      email
    )
  },
  shouldShowNextHour(nowMinutes: any): boolean {
    return nowMinutes >= 45
  },
  isLockAudienceRelativeTime(selectedLockOption: string): boolean {
    return selectedLockOption === LockAudienceType.RELATIVE_TIME
  },
  /**
   * Function should return false if any other character is passed than:
   * Alphanumeric value, underscore (_), hyphen (-) or whitespace ( )
   * Takes a string:
   * @param {String} name of the campaign name
   * @param {Object} validationRules set of validation rules
   */
  validateCampaignName(name: any, validationRules?: any) {
    const characterLengthLimit = validationRules || CAMPAIGN_NAME_LIMIT
    const isCharacterTypeError =
      name.length && CAMPAIGN_NAME_REGEX.test(name.toString()) === false
    const lengthDifference = name.length - characterLengthLimit
    const lengthError = `Your experiment name is ${lengthDifference} character${
      lengthDifference > 1 ? 's' : ''
    } too long. It should be less than ${characterLengthLimit} characters.`
    const shouldHaveError =
      isCharacterTypeError || name.length > characterLengthLimit
    const error = isCharacterTypeError
      ? CHARACTER_VALIDATION_ERROR
      : lengthError
    return {
      isValid: !shouldHaveError,
      validationError: shouldHaveError ? error : undefined,
    }
  },
  formatCurrency(isoLanguage = 'en-GB', isoCurrency = 'GBP') {
    return new Intl.NumberFormat(isoLanguage, {
      style: 'currency',
      currency: isoCurrency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  },
  removeUnwantedKeysFromObject(data: any) {
    // If passed param is not an object we just stop here and return it back
    if (typeof data !== 'object' || Array.isArray(data)) {
      return data
    }
    const newData = { ...data }
    // If any of the criteria is met we remove the whole key from object
    Object.keys(newData).forEach((key) => {
      if (
        newData[key] === '' ||
        newData[key] === ' ' ||
        newData[key] === 'undefined' ||
        newData[key] === null
      ) {
        delete newData[key]
      }
    })
    // And return new object without the key
    return newData
  },
  sortByOpenRate(openRateType: any) {
    const sortBy = (fn: any) => (a: any, b: any) => {
      // Due to unpredictable behaviour in Firefox return false when undefined.
      if (fn(a) === undefined && fn(b) === undefined) {
        return false
      }
      if (fn(a) === undefined) {
        return 1
      }
      if (fn(b) === undefined) {
        return -1
      }
      return fn(a) < fn(b) || -(fn(a) > fn(b))
    }
    // Gets the requested key/value pair from the object, and passes
    // it the sorting function
    return sortBy((o: any) => o[openRateType])
  },
  /**
   * Intended to be used by Array.prototype.sort()
   * Takes an array of objects, and sorts them alphabetically by the chosen field
   * @param {String} fieldName of the string field to sort by
   */
  sortAlphabetically(fieldName: any) {
    const sortBy = (fn: any) => (a: any, b: any) => fn(a).localeCompare(fn(b))
    return sortBy((o: any) => o[fieldName])
  },
  shortenNumberWithSuffix(num: any) {
    const orders = [
      {
        value: 10 ** 15,
        name: 'quadrillion',
        suffix: 'Q',
      },
      {
        value: 10 ** 12,
        name: 'trillion',
        suffix: 'T',
      },
      {
        value: 10 ** 9,
        name: 'billion',
        suffix: 'B',
      },
      {
        value: 10 ** 6,
        name: 'million',
        suffix: 'M',
      },
      {
        value: 10 ** 3,
        name: 'thousand',
        suffix: 'K',
      },
    ]

    // if invalid or negative number, return it without any change
    if (isNaN(num) || num < 0) {
      return num
    }

    let matchingOrder
    for (let i = 0; i < orders.length; i += 1) {
      const curOrder = orders[i]
      if (num >= curOrder.value) {
        matchingOrder = curOrder
        break
      }
    } // for()

    if (!matchingOrder) {
      return num
    }

    const shortenedNumber =
      (num / matchingOrder.value).toFixed(2) + matchingOrder.suffix
    return shortenedNumber
  },
  isAppInIFrame() {
    if (isDevelopment) {
      return true
    }
    return window !== window.parent
  },
  applyInputWidth(maxWidth: number) {
    const getInputValueWidth = (function () {
      // https://stackoverflow.com/a/49982135/104380
      function copyNodeStyle(sourceNode: any, targetNode: any) {
        const computedStyle = window.getComputedStyle(sourceNode)
        Array.from(computedStyle).forEach((key) =>
          targetNode.style.setProperty(
            key,
            computedStyle.getPropertyValue(key),
            computedStyle.getPropertyPriority(key)
          )
        )
      }

      function createInputMeasureElm(inputElm: any) {
        // create a dummy input element for measurements
        const measureElm = document.createElement('span')
        // copy the read input's styles to the dummy input
        copyNodeStyle(inputElm, measureElm)
        // set hard-coded styles needed for propper meassuring
        measureElm.style.width = 'auto'
        measureElm.style.position = 'absolute'
        measureElm.style.left = '-9999px'
        measureElm.style.top = '-9999px'
        measureElm.style.whiteSpace = 'pre'
        measureElm.textContent = inputElm.value || inputElm.placeholder

        // add the meassure element to the body
        document.body.appendChild(measureElm)
        const width = measureElm.offsetWidth
        document.body.removeChild(measureElm)
        return width
      }

      return function () {
        return createInputMeasureElm(this)
      }
    })()

    function onInputDelegate(e) {
      if (e.target.classList.contains('autoSize')) {
        const width = getInputValueWidth.call(e.target)

        e.target.style.width = `${width <= maxWidth ? width : maxWidth}px`
      }
    }
    // delegated event binding
    document.body.addEventListener('input', onInputDelegate)

    for (const input of document.querySelectorAll('input')) {
      onInputDelegate({ target: input })
    }
    return document.body.removeEventListener('input', onInputDelegate)
  },
  getParameterByName(name: any, url: any) {
    const parameterName = name.replace(/[[\]]/g, '\\$&')
    const regex = new RegExp(`[?&]${parameterName}(=([^&#]*)|&|#|$)`)
    const results = regex.exec(url)
    if (!results) {
      return undefined
    }
    if (!results[2]) {
      return ''
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  },
  /**
   * @deprecated TODO: Remove and use downloadCsv
   */
  downloadCsvDeprecated(name: string, csvData: string | undefined = '') {
    const ele = document.createElement.bind(document)
    const element = ele('a')
    const file = new Blob(['\uFEFF', csvData], {
      type: 'text/csv;charset=utf-8',
    })
    element.setAttribute('href', URL.createObjectURL(file))
    element.setAttribute(
      'download',
      `${name.toString().replace(/ /g, '_')}.csv`
    )
    document.body.appendChild(element)
    element.click()
    element.remove()
  },
  downloadCsv<T>(fileName: string, xlsData: T[][]) {
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.aoa_to_sheet(xlsData)

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')

    XLSX.writeFile(workbook, `${fileName}.csv`, { bookType: 'csv' })
  },
  downloadXls<T>(fileName: string, xlsData: T[][]) {
    const workbook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.aoa_to_sheet(xlsData)

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1')

    XLSX.writeFile(workbook, `${fileName}.xlsx`, { bookType: 'xlsx' })
  },
  /**
   * @deprecated TODO: Rewrite this one using XLSX
   */
  downloadTxt(name, csvData) {
    const ele = document.createElement.bind(document)
    const element = ele('a')
    const file = new Blob([csvData], { type: 'text/plain;charset=utf-8' })
    element.setAttribute('href', URL.createObjectURL(file))
    element.setAttribute(
      'download',
      `${name.toString().replace(/ /g, '_')}.txt`
    )
    document.body.appendChild(element)
    element.click()
    element.remove()
  },
  copyToClipboard(string: any) {
    const el = document.createElement('textarea')
    el.value = string
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  },
  cancellAllIntrvals: (id) => {
    if (id) {
      window.clearInterval(id)
    }
    if (
      window.phraseeIntervalIds?.length &&
      window.phraseeIntervalIds.length > 0
    ) {
      window.phraseeIntervalIds.forEach((id) => {
        window.clearInterval(id)
      })
      window.phraseeIntervalIds = []
    }
  },
  isBanditSfmcCampaign: (campaign_configuration) => {
    const integrationType = campaign_configuration?.integration_options?.type
    return (
      isBanditCampaign(campaign_configuration) && integrationType === 'sfmcv2'
    )
  },
  isBanditManualConversionsCampaign(
    campaignConfiguration: CampaignConfiguration | undefined
  ) {
    const isBandit = isBanditCampaign(campaignConfiguration)
    const isConversionsTracked =
      campaignConfiguration?.test_tracked_metrics?.some(
        (metric) => metric?.name === 'conversions'
      )
    const hasConversiontrackAsDataSyncSource =
      campaignConfiguration?.data_sync_settings?.sync_steps?.[0]?.sources?.includes(
        'conversiontrack'
      )
    return (
      isBandit && isConversionsTracked && !hasConversiontrackAsDataSyncSource
    )
  },
  hasClicksUnsubsConversionsAsDataSyncSource(
    campaignConfiguration: CampaignConfiguration | undefined
  ) {
    const possibleDataSyncSources = [
      'clicktrack',
      'unsubtrack',
      'conversiontrack',
    ]
    return campaignConfiguration?.data_sync_settings?.sync_steps?.[0]?.sources?.some(
      (source) => possibleDataSyncSources.includes(source)
    )
  },
  shouldShowTestMode(campaignConfiguration: CampaignConfiguration | undefined) {
    return (
      campaignConfiguration?.realtime_api_enabled ||
      this.isBanditSfmcCampaign(campaignConfiguration)
    )
  },

  /**
   * Should only show the banner if it is a realtime or sfmc campaign
   * @param { CampaignData } campaign_data - the campaign data object
   * @param { CampaignConfiguration } campaignConfiguration - The campaign configuration object
   * @returns { boolean } - true if the banner should be shown
   */
  shouldDisplayTestModeBanner: (
    campaignData: CampaignData | undefined,
    campaignConfiguration: CampaignConfiguration | undefined
  ): boolean => {
    if (
      (campaignConfiguration?.realtime_api_enabled ||
        isSFMC(campaignConfiguration?.integration_options?.type || '')) &&
      (campaignData?.bandit_data?.dynamic_optimization_paused === true ||
        (moment(moment()).isBefore(
          moment(campaignData?.optimization_start_time)
        ) &&
          campaignData?.is_bandit_initialised))
    ) {
      return true
    }
    return false
  },
  /**
   * Returns the text to show in the test mode banner
   * @param { CampaignData } campaignData - the campaign object
   * @param { boolean } shouldDisplayBanner - If the banner should be shown
   * @returns { string } - true if the banner should be shown
   */
  getTestModeBannerText: (
    campaignData: Campaign,
    shouldDisplayBanner: boolean
  ): string => {
    const beforeStartDateText =
      'Data is being received, but optimisation is off until the start date when test mode will be automatically turned off.'
    const afterStartDateText =
      'Data will be received, but optimisation will be off until you turn test mode off.'
    if (shouldDisplayBanner) {
      return moment(moment()).isBefore(
        moment(campaignData?.campaign_data?.optimization_start_time)
      )
        ? beforeStartDateText
        : afterStartDateText
    }
    return ''
  },
}

export function shouldShowNextHour(nowMinutes: any): boolean {
  return nowMinutes >= 45
}

export const disableDate = (
  current: any,
  compareStartDate: boolean,
  campaignStartDate: any,
  timeZone: string
  // eslint-disable-next-line max-params
) => {
  const dateToCompare = compareStartDate
    ? moment(campaignStartDate.value).tz(timeZone).format()
    : moment().tz(timeZone).format()
  return moment(current).tz(timeZone).format() < dateToCompare
}

export const getDisabledHoursArray = (
  dateFormatted: string,
  timeFormatted: string,
  extraTime: number
) => {
  const hourIncludingExtraTime = moment(`${dateFormatted} ${timeFormatted}`)
    .add(extraTime, 'minutes')
    .hour()
  const minutesIncludingTimeZoneAndExtraTime = moment(
    `${dateFormatted} ${timeFormatted}`
  )
    .add(extraTime, 'minutes')
    .minutes()
  const hoursToDisable = shouldShowNextHour(
    minutesIncludingTimeZoneAndExtraTime
  )
    ? hourIncludingExtraTime + 1
    : hourIncludingExtraTime
  // if selected date is in the future, do not disable hours.
  return [...Array(hoursToDisable).keys()]
}

export const getDisabledMinutesArray = (
  selectedHour: number,
  dateFormatted: string,
  timeFormatted: string,
  extraTime: number
  // eslint-disable-next-line max-params
) => {
  const minutesIncludingExtraTime = moment(`${dateFormatted} ${timeFormatted}`)
    .add(extraTime, 'minutes')
    .minutes()
  const nowHourIncludingTimeZoneAndExtraTime = moment(
    `${dateFormatted} ${timeFormatted}`
  )
    .add(extraTime, 'minutes')
    .hour()
  // When user chooses a future hour, we need to figure out disabled minutes for the new hour:
  if (
    selectedHour &&
    selectedHour - nowHourIncludingTimeZoneAndExtraTime >= 1
  ) {
    return []
  }
  const minutesToDisable = shouldShowNextHour(minutesIncludingExtraTime)
    ? 0
    : minutesIncludingExtraTime
  // If NOT, figure out the UI.

  return [...Array(minutesToDisable).keys()]
}

export const getDisabledStartMinutes = (
  selectedHour: number,
  campaignStartDate: any,
  timeZone: any
) => {
  // If selected date is in future do not disable anything and return.
  if (
    campaignStartDate.value &&
    timeZone &&
    (!moment(campaignStartDate.value).isSame(moment().tz(timeZone), 'day') ||
      moment(campaignStartDate.value).date() > moment().tz(timeZone).date())
  ) {
    return []
  }
  const dateFormatted = moment().tz(timeZone).format('YYYY-MM-DD')
  const timeFormatted = moment().tz(timeZone).format('HH:mm')
  return getDisabledMinutesArray(selectedHour, dateFormatted, timeFormatted, 60)
}

export const getControlSubjectLine = (
  subjectLines: SubjectLine[]
): SubjectLine | undefined => {
  return subjectLines.find((subjectLine) => subjectLine.ownsl === true)
}

export const getFilteredVariants = (
  subjectLines: SubjectLine[],
  filters: AppliedFilters,
  defaultVariantStatusFilter: string[]
) => {
  if (filters === undefined) {
    return subjectLines.filter((subjectLine) => {
      if (subjectLine?.bandit_status?.status === undefined) {
        return false
      }
      return defaultVariantStatusFilter.includes(
        subjectLine.bandit_status.status
      )
    })
  }
  if (Array.isArray(filters) || filters.variant_status.length === 0) {
    return subjectLines
  }
  return subjectLines.filter((subjectLine) => {
    if (subjectLine?.bandit_status?.status === undefined) {
      return false
    }
    return filters.variant_status.includes(subjectLine.bandit_status.status)
  })
}
