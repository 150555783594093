import {
  Content,
  StepName,
  StepStatus,
} from '@phrasee/phrasee-typings/typings/futurama/content'
import {
  ComponentElement,
  Element,
  TemplateElement,
} from '@phrasee/phrasee-typings/typings/futurama/element'
import trimStart from 'lodash/trimStart'
import helpers from 'workflow/utils/helpers'

import { stripForbiddenCharacters } from '../../../common/helpers/stripForbiddenCharacters'
import { isComponentElement, isTemplateElement } from '../api/interfaces'
import { ContentWithProgress } from '../api/queries/useGetContentQuery'

import { getWsTopic } from './hooks/websocketMessage/helpers'

export const getAllTemplateElements = (content?: Content) => {
  const allTemplateElements: TemplateElement[] = []

  content?.elements.forEach((element) => {
    if (isTemplateElement(element)) {
      allTemplateElements.push(element)
    }
  })

  return allTemplateElements
}

export const getAllComponentElements = (content?: Content) => {
  const allComponentElements: ComponentElement[] = []

  content?.elements.forEach((element) => {
    if (isComponentElement(element)) {
      allComponentElements.push(element)
    } else {
      allComponentElements.push(...element.elements.filter(isComponentElement))
    }
  })

  return allComponentElements
}

export const getAllElements = (content?: Content) => {
  const allElements: Element[] = []

  content?.elements.forEach((element) => {
    allElements.push(element)
    if (isTemplateElement(element)) {
      allElements.push(...element.elements)
    }
  })

  return allElements
}

export const getSelectedElement = (content?: Content) => {
  const allElements = getAllElements(content)
  return allElements?.find(({ status }) => status === 'selected')
}

export const getRootSelectedElement = (content?: Content) => {
  if (!content) {
    return
  }

  let selectedElement: Element | undefined
  content?.elements.some((element) => {
    if (element.status === 'selected') {
      selectedElement = element
      return true
    }
    if (isTemplateElement(element)) {
      return element.elements.some((el) => {
        if (el.status === 'selected') {
          selectedElement = element
          return true
        }
        return false
      })
    }
    return false
  })

  return selectedElement
}

export const getRootUserSelectedElement = (
  content?: Content,
  selectedElementId?: number
) => {
  if (!content) {
    return
  }

  let selectedRootElement: Element | undefined
  content?.elements.some((element) => {
    if (element.element_id === selectedElementId) {
      selectedRootElement = element
      return true
    }
    if (isTemplateElement(element)) {
      return element.elements.some((subElement) => {
        if (subElement.element_id === selectedElementId) {
          selectedRootElement = element
          return true
        }
        return false
      })
    }
    return false
  })

  return selectedRootElement
}

export const getFirstComponentElement = (content?: Content) => {
  const allComponentElements = getAllComponentElements(content)
  return allComponentElements.length > 0 ? allComponentElements[0] : undefined
}

export const getElementHasVariants = (element?: Element) => {
  if (!element) {
    return false
  }

  return isComponentElement(element)
    ? element.variants && element.variants?.length > 0
    : element &&
        element.elements.some(
          (el) =>
            isComponentElement(el) && el.variants && el.variants.length > 0
        )
}

export const getContentHasVariants = (content?: Content) =>
  getAllComponentElements(content)?.some(
    (element) => element.variants && element.variants.length > 0
  )

export const getElementGenerationComplete = (
  content?: Content,
  element?: Element
) => {
  if (!element || !content) {
    return false
  }

  let rootElement: Element | undefined
  for (const el of content.elements) {
    if (isComponentElement(el)) {
      if (el.element_id === element.element_id) {
        rootElement = el
        break
      }
    } else {
      if (el.element_id === element.element_id) {
        rootElement = el
        break
      }
      const foundElement = el.elements.find(
        (nestedElement) => nestedElement.element_id === element.element_id
      )

      if (foundElement) {
        rootElement = el
        break
      }
    }
  }

  return rootElement && isComponentElement(rootElement)
    ? rootElement.variants && rootElement.variants?.length > 0
    : rootElement &&
        rootElement.elements.every((el) => {
          return (
            isComponentElement(el) &&
            (el.is_image_element || (el.variants && el.variants.length > 0))
          )
        })
}

export const getOptimizedOrPersonalizedElements = (
  elements: Element[] | undefined
) =>
  elements?.filter(
    (element) =>
      !!element.campaign_id ||
      (isComponentElement(element) && !!element.personalization_id)
  ) || []

export const parseWorkflowName = (
  value: string
): {
  isValid: boolean
  validationError: string | undefined
  value: string
} => {
  const trimmedValue = trimStart(value)
  const strippedValue = stripForbiddenCharacters(trimmedValue)
  const { isValid, validationError } =
    helpers.validateCampaignName(strippedValue)

  return {
    isValid,
    validationError,
    value: isValid ? strippedValue : strippedValue.slice(0, -1),
  }
}

export const validateWorkflowName = (value: string | undefined) => {
  if (!value?.trim()) {
    return 'Enter a name for your workflow.'
  }
  const { validationError } = helpers.validateCampaignName(value)
  return validationError
}

// TODO it seems only the component element can have a badge, so change the type to ComponentElement
export const getExperimentBadgesState = (element: Element | undefined) => {
  if (!element) {
    return undefined
  }

  const isOptimizable = !!(element && element?.campaign_id)

  const isPersonalizable =
    isComponentElement(element) && !!element.personalization_id

  if (!isOptimizable && !isPersonalizable) {
    return undefined
  }

  return {
    isOptimizable,
    isPersonalizable,
  }
}

export const getStepStatus = (
  stepsStatus: StepStatus[] | undefined,
  stepName: StepName
) => {
  return stepsStatus?.find((stepStatus) => stepStatus.step === stepName)?.status
}

export const getFilteredVariants = (
  campaignData,
  variants,
  variantsToBeApproved: string[] = []
) => {
  let variantsFiltered: any[] = []
  if (
    helpers.isBanditNlgInitialised(campaignData) ||
    (helpers.isBanditInitialised(campaignData) &&
      !helpers.isLinguoCampaign(campaignData))
  ) {
    variantsFiltered = variants.filter(
      (item) => item.bandit_status?.status === 'live'
    )
  } else if (
    helpers.isLinguoCampaign(campaignData) &&
    variantsToBeApproved.length > 0
  ) {
    variantsFiltered = variants.filter(
      (item) =>
        item.bandit_status?.status !== 'pending' &&
        item.bandit_status?.status !== 'dropped'
    )
  } else if (
    helpers.isLinguoCampaign(campaignData) &&
    variantsToBeApproved.length === 0
  ) {
    variantsFiltered = variants.filter(
      (item) => item.bandit_status?.status !== 'dropped'
    )
  } else {
    variantsFiltered = variants
  }

  return variantsFiltered
}

export const checkVariantActionLoading = ({
  content,
  selectedElement,
  variantId,
}: {
  content: ContentWithProgress | undefined
  selectedElement: ComponentElement | undefined
  variantId: string | undefined
}) => {
  if (content && selectedElement && variantId) {
    const tweakWsTopic = getWsTopic({
      action: 'tweakVariant',
      data: {
        contentId: content._id,
        elementId: selectedElement.element_id,
        variantId: variantId,
      },
    })
    const rejectWsTopic = getWsTopic({
      action: 'rejectVariant',
      data: {
        contentId: content._id,
        elementId: selectedElement.element_id,
        variantId: variantId,
      },
    })
    return content.ws_messages?.some(
      (message) =>
        message.wsTopic === rejectWsTopic || message.wsTopic === tweakWsTopic
    )
  }
  return false
}

export function hasDisabledAllElements(elements: Element[]) {
  return elements.every((element) => element.status === 'disabled')
}

export function hasOneElement(elements: Element[]) {
  return elements.length === 1
}

export const getIsOptimized = (
  selectedElement: Element | undefined
): boolean => {
  return !!(selectedElement && selectedElement?.campaign_id)
}
