import FormItem from 'common/components/formItem'
import SingleSelect from 'common/components/singleSelect'

import { CommonFieldPropsGeneric, DropdownType } from '../interfaces'

const ERROR_MESSAGES = {
  required: 'Please select an option.',
}

type Props = CommonFieldPropsGeneric & {
  value: DropdownType['value']
  options: DropdownType['options']
  componentProps?: DropdownType['componentProps']
}

const Dropdown = ({
  label,
  name,
  value,
  options,
  tooltip,
  errorCode,
  isDisabled,
  placeholder,
  componentProps,
  onChange,
  'data-testid': dataTestId,
}: Props) => {
  return (
    <FormItem
      label={label}
      guidingText={tooltip}
      htmlFor={name}
      error={errorCode ? ERROR_MESSAGES[errorCode] : undefined}
      style={componentProps?.style}
    >
      <SingleSelect
        id={name}
        name={name}
        data-testid={dataTestId}
        onChange={(val) => onChange(val?.value)}
        value={value}
        isSearchable={true}
        placeholder={placeholder}
        options={options}
        isDisabled={isDisabled}
      />
    </FormItem>
  )
}

export default Dropdown
