import { useState } from 'react'

const useSelectedVariant = () => {
  const [selectedVariantId, setSelectedVariantId] =
    useState<string | number | undefined>(undefined)

  return {
    selectedVariantId,
    setSelectedVariantId,
  }
}

export default useSelectedVariant
