import { useEffect } from 'react'
import { Content, PersonalizedVariant, Variant } from '@phrasee/phrasee-typings'
import { useQueryClient } from '@tanstack/react-query'
import * as types from 'redux/actionTypes'
import { SubjectLine } from 'workflow/interface'

import { errorToast } from 'common/components/toastNotification'
import { useAppDispatch } from 'common/hooks/redux'
import useWebsockets, { WsResponseBase } from 'common/hooks/useWebsockets'
import { contentKeys } from 'features/unifiedFlow/api/queryKeys'
import {
  useContent,
  useSelectedComponentElement,
} from 'features/unifiedFlow/contentPage/hooks'
import useWebsocketMessage from 'features/unifiedFlow/contentPage/hooks/websocketMessage'

import useVariantsToApproveQuery from './useVariantsToApproveQuery'

interface WsResponse extends WsResponseBase {
  content: Content
  sl?: SubjectLine
  baselineVariant?: Variant
  personalizedVariant?: PersonalizedVariant
}

type Props = {
  onTweakSuccess?: (newVariantId: string) => void
}

const useVariantTweakWebsocketSubscription = ({ onTweakSuccess }: Props) => {
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const {
    lastJsonMessage,
  }: {
    lastJsonMessage: WsResponse
  } = useWebsockets()
  const { content } = useContent()
  const { sendWebsocketMessage } = useWebsocketMessage()
  const { data: selectedElement } = useSelectedComponentElement()
  const { invalidateAll: invalidateAllVariantsToApproveCountQuery } =
    useVariantsToApproveQuery()

  const handlePersonalizedCampaign = () => {
    invalidateAllVariantsToApproveCountQuery()
    queryClient.cancelQueries(contentKeys.content(lastJsonMessage.content._id))
    queryClient.setQueryData(
      contentKeys.content(lastJsonMessage.content._id),
      lastJsonMessage.content
    )
    queryClient.invalidateQueries(
      contentKeys.content(lastJsonMessage.content._id)
    )
  }

  useEffect(() => {
    if (content && selectedElement && lastJsonMessage) {
      const wsTopics = lastJsonMessage?.topics
      const rejectRegex = new RegExp(
        `content/${content._id}/element/([^/]+)/variant/([^/]+)/rejectVariant`
      )
      const rejectedTopic = wsTopics?.find((topic) => rejectRegex.test(topic))

      if (rejectedTopic) {
        const wsTopicElementId = rejectedTopic?.match(rejectRegex)?.[1]
        const wsTopicVariantId = rejectedTopic?.match(rejectRegex)?.[2]

        if (lastJsonMessage.status === 'success') {
          if (lastJsonMessage.sl) {
            dispatch({
              type: types.REJECT_VARIANT_SUCCESS,
              newVariant: lastJsonMessage.sl,
              oldVariantId: wsTopicVariantId,
            })
          }

          if (wsTopicElementId === String(selectedElement.element_id)) {
            const newVariantId =
              lastJsonMessage.sl?._id ||
              lastJsonMessage.baselineVariant?.sl_id ||
              lastJsonMessage.personalizedVariant?.variant_id
            if (newVariantId) {
              onTweakSuccess?.(newVariantId)
            }
          }

          if (selectedElement.personalization_id) {
            handlePersonalizedCampaign()
          }
        } else if (lastJsonMessage.status === 'error') {
          errorToast('Something went wrong. Please try again.')
        }

        if (wsTopicElementId && wsTopicVariantId) {
          sendWebsocketMessage({
            action: 'rejectVariant',
            data: {
              contentId: content._id,
              elementId: Number(wsTopicElementId),
              variantId: wsTopicVariantId,
            },
            subscriptionAction: 'unsubscribe',
          })
        }
      }

      const tweakRegex = new RegExp(
        `content/${content._id}/element/([^/]+)/variant/([^/]+)/tweakVariant`
      )
      const tweakedTopic = wsTopics?.find((topic) => tweakRegex.test(topic))

      if (tweakedTopic) {
        const wsTopicElementId = tweakedTopic?.match(tweakRegex)?.[1]
        const wsTopicVariantId = tweakedTopic?.match(tweakRegex)?.[2]
        if (lastJsonMessage.status === 'success') {
          if (lastJsonMessage.sl) {
            dispatch({
              type: types.TWEAK_VARIANT_SUCCESS,
              newVariant: lastJsonMessage.sl,
              oldVariantId: lastJsonMessage.sl?.old_sl_id,
            })
          }

          if (wsTopicElementId === String(selectedElement.element_id)) {
            const newVariantId =
              lastJsonMessage.sl?._id ||
              lastJsonMessage.baselineVariant?.sl_id ||
              lastJsonMessage.personalizedVariant?.variant_id
            if (newVariantId) {
              onTweakSuccess?.(newVariantId)
            }
          }

          if (selectedElement.personalization_id) {
            handlePersonalizedCampaign()
          }
        } else if (lastJsonMessage.status === 'error') {
          errorToast('Something went wrong. Please try again.')
        }

        if (wsTopicElementId && wsTopicVariantId) {
          sendWebsocketMessage({
            action: 'tweakVariant',
            data: {
              contentId: content._id,
              elementId: Number(wsTopicElementId),
              variantId: wsTopicVariantId,
            },
            subscriptionAction: 'unsubscribe',
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage])
}

export default useVariantTweakWebsocketSubscription
