import Button from 'common/components/button'
import InfoMessage from 'common/components/topics/components/InfoMessage'

type Props = {
  onBackClick: () => void
}

const RefreshVariantsInfo = ({ onBackClick }: Props) => {
  return (
    <>
      <InfoMessage
        textLines={['You made some changes, please refresh your variants.']}
        caption="Making changes to your Topics will update the optimised content created by Jacquard"
        className="mt-20"
      />
      <Button
        variant="link"
        className="self-center mt-6 text-base"
        onClick={onBackClick}
      >
        Oops! Go back a step
      </Button>
    </>
  )
}

export default RefreshVariantsInfo
