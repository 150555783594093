import { useState } from 'react'
import { showBanner } from 'workflow/common/common.actions'
import apiUtil from 'workflow/utils/Api'
import helpers from 'workflow/utils/helpers'

import {
  experimentsPermissions,
  getIsAuthorized,
} from 'common/auth/permissions'
import Button from 'common/components/button'
import { ButtonWithTooltip } from 'common/components/ButtonWithTooltip'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { ReactComponent as Bin } from 'common/icons/bin/default.svg'
import { ReactComponent as Export } from 'common/icons/export/default.svg'
import { ReactComponent as Move } from 'common/icons/move/default.svg'
import {
  getAllProjects,
  getSelectedProject,
  wipeSelectedCampaigns,
} from 'features/campaigns/store/campaignSlice'

import CardsFan from './CardsFan'
import DeleteModal from './DeleteModal'
import MoveModal from './MoveModal'

const SelectedCampaigns = () => {
  const selectedCampaigns = useAppSelector(
    (state) => state.campaigns.selectedCampaigns
  )
  const selectedProject = useAppSelector(getSelectedProject)
  const projects = useAppSelector(getAllProjects)
  const dispatch = useAppDispatch()

  const { permissions } = useAppSelector((state) => state.authStates)
  const hasDeletePermission = getIsAuthorized(
    permissions,
    experimentsPermissions.delete
  )

  const count = selectedCampaigns.length

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [isMoveModalOpen, setIsMoveModalOpen] = useState<boolean>(false)
  const [isLoadingExport, setisLoadingExport] = useState<boolean>(false)

  const downloadCsv = () => {
    setisLoadingExport(true)

    const projectName = projects.find(
      (project) => project.id === selectedProject?.id
    )!.name

    apiUtil(
      `projects/${
        selectedProject?.id
      }/results/csv?campaigns=${selectedCampaigns.join()}`,
      {
        method: 'GET',
      },
      { useConnectApi: false, internal: true }
    )
      .then((response) => {
        helpers.downloadCsvDeprecated(projectName, response)
        dispatch(
          showBanner({ content: 'CSV successfully exported', type: 'success' })
        )
      })
      .catch((error) => {
        dispatch(showBanner({ content: error.message, type: 'error' }))
      })
      .finally(() => setisLoadingExport(false))
  }

  return (
    <div
      className={`min-h-screen w-full flex flex-col justify-center 
    items-center`}
    >
      <div className="mb-4 text-lg text-coolGray-800 font-medium">
        {`${count} Selected ${count === 1 ? 'Experiment' : 'Experiments'}`}
      </div>

      <button
        onClick={() => dispatch(wipeSelectedCampaigns())}
        data-cy="clear-selected-campaigns"
        data-testid="clear-selected-campaigns"
        disabled={!count}
        className={`mb-6 text-base text-maroon-400 font-medium 
        disabled:cursor-not-allowed`}
      >
        Clear all
      </button>

      <div className="h-96 mb-16">
        <CardsFan />
      </div>

      <div className="flex items-stretch gap-6" style={{ height: 106 }}>
        <Button
          onClick={() => {
            setIsDeleteModalOpen(true)
          }}
          disabled={!count || !hasDeletePermission}
          data-cy="delete-campaigns-button"
          data-testid="delete-campaigns-button"
          size="xl"
          prefixIcon={<Bin className="w-6 h-6" />}
        >
          Delete
        </Button>

        <ButtonWithTooltip
          data-cy="move-project-button"
          data-testid="move-project-button"
          tooltip={!selectedProject?.id || !count}
          disabled={!selectedProject?.id || !count}
          tooltipText={`You can only move experiments from one compatible 
            project to a second project. To move experiments, please apply 
            a project filter`}
          onClick={() => setIsMoveModalOpen(true)}
          size="xl"
          prefixIcon={<Move className="w-6 h-6" />}
        >
          Move Project
        </ButtonWithTooltip>

        <ButtonWithTooltip
          data-cy="export-csv-button"
          data-testid="export-csv-button"
          tooltip={!selectedProject?.id || !count}
          disabled={!selectedProject?.id || !count || isLoadingExport}
          tooltipText={`You can only export experiments when a project filter
          is applied`}
          onClick={() => downloadCsv()}
          size="xl"
          prefixIcon={<Export className="w-6 h-6" />}
          loading={isLoadingExport}
        >
          Export
        </ButtonWithTooltip>
      </div>

      <DeleteModal
        open={isDeleteModalOpen}
        close={() => {
          setIsDeleteModalOpen(false)
        }}
      />

      {isMoveModalOpen && (
        <MoveModal
          open={isMoveModalOpen}
          close={() => {
            setIsMoveModalOpen(false)
          }}
        />
      )}
    </div>
  )
}

export default SelectedCampaigns
