import { useQuery } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import { getReplacers } from '../api'
import { replacersKeys } from '../queryKeys'

const useGetReplacersQuery = ({
  enabled = true,
  nodeIds,
}: {
  enabled: boolean
  nodeIds: string[]
}) => {
  const getReplacersQuery = useQuery(
    replacersKeys.all,
    () => getReplacers(nodeIds),
    {
      enabled,
      onError: () => {
        errorToast('Failed to load replacers')
      },
    }
  )

  return getReplacersQuery
}

export default useGetReplacersQuery
