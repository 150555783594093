import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useWebsockets from 'common/hooks/useWebsockets'

import { useUpdateCurrentUsersMutation } from '../api/mutations/useNotifyCurrentUsersMutation'
import { updateConcurrentUsers } from '../store/unifiedFlowSlice'

const useUserTrackingWebsocketSubscription = (contentId: string) => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const wsUserTrackingTopic = `content-user-tracking/${contentId}`
  const dispatch = useAppDispatch()
  const { lastJsonMessage, sendJsonMessage, isConnectionReady } =
    useWebsockets()
  const { mutate: notifyCurrentUsers } = useUpdateCurrentUsersMutation()

  useEffect(() => {
    if (isConnectionReady) {
      sendJsonMessage({
        action: 'subscribe',
        topics: [wsUserTrackingTopic],
      })
      notifyCurrentUsers({
        accountId,
        contentId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectionReady])

  useEffect(() => {
    return () => {
      sendJsonMessage({
        action: 'unsubscribe',
        topics: [wsUserTrackingTopic],
      })
      notifyCurrentUsers({
        accountId,
        contentId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const wsTopics = lastJsonMessage?.topics
    const subscribeRegex = new RegExp(`content-user-tracking/${contentId}`)
    const isSubscribe = wsTopics?.some((topic) => subscribeRegex.test(topic))
    if (isSubscribe) {
      dispatch(updateConcurrentUsers(lastJsonMessage?.users))
    }
  }, [dispatch, lastJsonMessage, contentId])
}

export default useUserTrackingWebsocketSubscription
