import ActionIcon from 'common/components/ActionIcon'
import Tag from 'common/components/Tag'
import Tooltip from 'common/components/Tooltip'
import Widget from 'common/components/Widget'
import { formatDate } from 'common/helpers/formatDate'
import {
  Clock as ClockIcon,
  Copy as CopyIcon,
  Edit as EditIcon,
  SmallTick,
} from 'common/icons'

type Props = {
  title: string
  createdDate: Date
  isDefault: boolean
  onEdit: (e: React.MouseEvent<HTMLSpanElement>) => void
  onMarkDefault: (e: React.MouseEvent<HTMLSpanElement>) => void
  onDuplicate: (e: React.MouseEvent<HTMLSpanElement>) => void
}

const LibraryCard = ({
  title,
  createdDate,
  isDefault,
  onEdit,
  onMarkDefault,
  onDuplicate,
}: Props) => {
  return (
    <div className="group h-full">
      <Widget type="basic" className="h-full group-hover:shadow-md">
        <div className="flex flex-row justify-between align-middle">
          {isDefault ? (
            <Tag text="Default" backgroundColor="bg-coolGray-500" />
          ) : undefined}
        </div>
        <div className="font-medium text-base my-4 max-w-xs line-clamp-3 text-base-700">
          {title}
        </div>
        <div className="flex flex-row justify-between mt-auto text-base-700 font-normal text-xs">
          <div className="flex flex-row justify-start">
            <ClockIcon isDefaultColor={false} />
            <div className="ml-2.5 self-center">{formatDate(createdDate)}</div>
          </div>
          <div className="flex gap-4 invisible group-hover:visible">
            <Tooltip overlay="Copy">
              <ActionIcon data-testid="copy-icon" onClick={onDuplicate}>
                <CopyIcon isDefaultColor={false} className="text-base-700" />
              </ActionIcon>
            </Tooltip>
            <Tooltip overlay="Edit">
              <ActionIcon data-testid="edit-icon" onClick={onEdit}>
                <EditIcon isDefaultColor={false} className="text-base-700" />
              </ActionIcon>
            </Tooltip>
            <Tooltip overlay="Mark as default">
              <ActionIcon
                data-testid="mark-default-icon"
                onClick={onMarkDefault}
              >
                <SmallTick isDefaultColor={false} className="text-base-700" />
              </ActionIcon>
            </Tooltip>
          </div>
        </div>
      </Widget>
    </div>
  )
}

export default LibraryCard
