/* eslint-disable max-lines */

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import { bindActionCreators } from 'redux'
import ActionBar from 'workflow/ActionBar'

import { Team, TeamWithColour } from 'common/api/teamsApi'
import {
  experimentsPermissions,
  getIsAuthorized,
  routesPermissions,
} from 'common/auth/permissions'
import Badge from 'common/components/Badge'
import Banner from 'common/components/banner'
import InfoMessageModal from 'common/components/InfoMessageModal'
import Tooltip from 'common/components/Tooltip'
import { Alert, Edit, Switch } from 'common/icons'
import { ReactComponent as CheckCircle } from 'common/icons/checkCircle/default.svg'
import { replaceNewLineEmojiAsElement } from 'common/variantsUtils'

import { showBanner } from '../common/common.actions'
import { SubjectLine } from '../interface'
import helpers, { getControlSubjectLine } from '../utils/helpers'
import * as campaignWorkflowActions from '../Workflow.actions'

import ApprovalRequestSentModal from './ApprovalRequestSentModal'
import SendForApprovalModal from './SendForApprovalModal'
import TweakControlVariant from './TweakControlVariant'
import TweakVariant from './TweakVariant'

import styles from './LanguageApproval.module.css'

const { isAManagedCampaign } = helpers

interface Props {
  actions: any
  campaign: any
  stepProps: any
  history: any
  feedbackSettings: any
  isFeedbackOnly: boolean
  isFeedbackOnlyType: string
  match: any
  questions: any
  subjectLines: SubjectLine[]
  shouldDisplayBoomModal: boolean
  variantsToBeApprovedExperimentsFlow: string[]
  teamsList: Team[]
  permissions: string[]
}

enum IsFeedbackOnlyType {
  WHY_TWEAK = 'edit',
  WHY_DISLIKE = 'reject',
}

export type CampaignType = 'frink' | 'linguo'

interface PropsPassedDown {
  type: CampaignType
  campaignData?: any
  actionBarContainerElement?: HTMLDivElement
}

interface LocalState {
  hasUnsavedData: boolean
  hasTriggeredSubmit: boolean
  exiting: boolean
  shouldShowTweakVariantModal: boolean
  showLanguageApprovalSelectionButtons: boolean
  shouldShowSendForApprovalModal: boolean
  shouldShowApprovalRequestSentModal: boolean
  selectedTeamsList: TeamWithColour[]
}

class LanguageApproval extends Component<Props & PropsPassedDown, LocalState> {
  parentDivRef: React.RefObject<HTMLDivElement>

  constructor(props: Props & PropsPassedDown) {
    super(props)
    this.state = {
      hasUnsavedData: false,
      hasTriggeredSubmit: false,
      exiting: false,
      shouldShowTweakVariantModal: false,
      showLanguageApprovalSelectionButtons: false,
      shouldShowSendForApprovalModal: false,
      shouldShowApprovalRequestSentModal: false,
      selectedTeamsList: [],
    }
    this.parentDivRef = React.createRef()
  }

  componentWillUnmount() {
    const { hasUnsavedData, hasTriggeredSubmit } = this.state
    const { campaignData } = this.props
    if (
      !hasTriggeredSubmit &&
      hasUnsavedData &&
      !helpers.isBanditNlgInitialised(campaignData)
    ) {
      this.completeLanguageApproval(true, hasUnsavedData)
    }
  }

  componentDidMount = () => {
    // Fetch the latest variants for this campaign
    const {
      type,
      actions,
      match: {
        params: { campaignId },
      },
    } = this.props
    if (campaignId && type === 'frink') {
      actions.fetchLatestVariants(campaignId)
    }
  }

  handleShouldShowTweakVariantModal = (shouldShowModal: boolean) => {
    this.setState({ shouldShowTweakVariantModal: shouldShowModal })
  }

  handleShouldShowSendForApprovalModal = (shouldShowModal: boolean) => {
    this.fetchAccountTeamsList()
    this.setState({
      shouldShowSendForApprovalModal: shouldShowModal,
      showLanguageApprovalSelectionButtons: false,
    })
  }

  handleShouldShowApprovalRequestSentModal = (shouldShowModal: boolean) => {
    this.setState({ shouldShowApprovalRequestSentModal: shouldShowModal })
  }

  updateSelectedTeamsList = (teams) => {
    this.setState({
      selectedTeamsList: teams,
      shouldShowApprovalRequestSentModal: true,
    })
  }

  fetchAccountTeamsList = () => {
    const { actions, campaign } = this.props
    actions.fetchAccountTeamsList(campaign.account_id)
  }

  rejectLine = (dataObj: any) => {
    const data = dataObj
    const { campaign, actions, campaignData, type } = this.props

    data.campaign_id = campaign._id
    // here we update the isFeedbackOnly property IF we are dealing with a managed campaign.
    if (isAManagedCampaign(campaignData)) {
      data.feedbackOnly = true
    }

    actions.rejectALine(data, type).then(() => {
      this.setState({
        hasUnsavedData: true,
      })
    })
  }

  tweakLine = (dataObj: any) => {
    const data = dataObj
    const { campaign, actions, feedbackSettings, type } = this.props
    data.campaign_id = campaign._id
    const isFeedbackOnly = feedbackSettings.edit.feedback_only
    if (isFeedbackOnly) {
      data.feedbackOnly = true
    }
    actions.tweakALine(data, type).then(() => {
      this.setState({
        hasUnsavedData: true,
      })
    })
  }

  completeLanguageApproval = (exiting: boolean, hasUnsavedData = false) => {
    const {
      campaign,
      actions,
      variantsToBeApprovedExperimentsFlow,
      campaignData,
    } = this.props
    const data = {
      step: 'language_approvals',
      save_and_continue: !exiting,
      save_and_exit: exiting,
      data: {
        campaign_id: campaign._id,
        project_id: campaign.project_id,
        ...(variantsToBeApprovedExperimentsFlow?.length > 0 &&
          (helpers?.isBanditNlgInitialised(campaignData) ||
            helpers?.isLinguoCampaign(campaignData)) && {
            variants_to_approve: variantsToBeApprovedExperimentsFlow,
          }),
      },
    }
    actions.saveLanguageApproval(data, hasUnsavedData).then(() => {
      this.setState({
        hasUnsavedData: false,
      })
    })
  }

  openTweakLine = async (sl: any) => {
    const { actions, campaign } = this.props
    actions.fetchNfmAndTweakLimit(campaign._id, sl)
  }

  closeTweakLine = () => {
    const { actions } = this.props
    actions.closeTweakLine()
  }

  groupUserFeedbackElements = (subjectLine: any) => {
    const { feedbackSettings } = this.props
    const userFeedback: Array<any> = []
    if (
      subjectLine.why_tweak &&
      feedbackSettings[IsFeedbackOnlyType.WHY_TWEAK].feedback_only
    ) {
      userFeedback.push(subjectLine.why_tweak)
    }
    if (
      subjectLine.why_dislike &&
      feedbackSettings[IsFeedbackOnlyType.WHY_DISLIKE].feedback_only
    ) {
      userFeedback.push(subjectLine.why_dislike)
    }
    return userFeedback
  }

  handleSubmit = (exiting: boolean) => {
    this.setState(
      {
        hasTriggeredSubmit: true,
      },
      () => {
        this.completeLanguageApproval(exiting)
      }
    )
  }

  toggleShowLanguageApprovalSelection = () => {
    this.setState({
      showLanguageApprovalSelectionButtons:
        !this.state.showLanguageApprovalSelectionButtons,
    })
  }

  handleLineItemPress = (subjectLine: SubjectLine) => {
    const { stepProps, feedbackSettings, type } = this.props
    const isBanditStatusPending: boolean =
      subjectLine?.bandit_status?.status === 'pending'
    const disabled = isBanditStatusPending
      ? false
      : stepProps.disabled || !stepProps.hasEditPermission
    if (disabled || subjectLine?.bandit_status?.status === 'live') {
      return
    } else if (subjectLine.ownsl) {
      if (type === 'frink') {
        this.handleShouldShowTweakVariantModal(true)
      }
    } else if (
      feedbackSettings?.edit?.enabled ||
      feedbackSettings?.reject?.enabled
    ) {
      this.openTweakLine(subjectLine)
    }
  }

  renderListElement = (subjectLine: any, index: number, listLength: number) => {
    const { type } = this.props
    if (!subjectLine) {
      return
    }

    const isBanditStatusPending: boolean =
      subjectLine?.bandit_status?.status === 'pending'
    const { feedbackSettings, stepProps, permissions } = this.props
    // `pending` variants should not be disabled (in-app approvals)
    const disabled = isBanditStatusPending
      ? false
      : stepProps.disabled || !stepProps.hasEditPermission
    const { groupUserFeedbackElements } = this

    const approvedBy = subjectLine?.bandit_status?.approved_by

    return (
      <li
        key={index}
        className={cx('flex justify-between items-center', styles.li)}
      >
        <div
          className={cx(
            `w-full py-4 flex justify-between items-center ml-6 group`,
            `hover:bg-coolGray-50 border-solid border-0 border-b border-coolGray-200`,
            {
              'border-none': index === listLength - 1,
              'cursor-default': disabled,
              'cursor-pointer':
                getIsAuthorized(permissions, routesPermissions.variantsEdit) &&
                subjectLine?.bandit_status?.status !== 'live',
            }
          )}
          onClick={() => {
            if (getIsAuthorized(permissions, routesPermissions.variantsEdit)) {
              this.handleLineItemPress(subjectLine)
            }
          }}
        >
          <div className="flex items-center">
            <span
              data-cy={`lng-approval-variant-text-${index}`}
              data-testid={`lng-approval-variant-text-${index}`}
              className="text-coolGray-800 mr-4 text-base"
            >
              {replaceNewLineEmojiAsElement(subjectLine.text)}
            </span>
            {subjectLine.ownsl ? (
              <Badge
                className="uppercase"
                size="small"
                variant="neutral"
                text="Control"
              />
            ) : undefined}
            {subjectLine?.bandit_status?.status !== 'live' && (
              <>
                {groupUserFeedbackElements(subjectLine).map(
                  (feedback: any, index: number) => (
                    <React.Fragment key={index.toString()}>
                      <br />
                      <span data-testid="feedback">
                        <span className="text-maroon-200">Your feedback: </span>
                        {feedback}
                      </span>
                    </React.Fragment>
                  )
                )}
              </>
            )}
          </div>
          <div className="flex justify-end">
            {(approvedBy?.fname || approvedBy?.lname) && (
              <span className="text-base text-right text-coolGray-400 mx-4 relative invisible group-hover:visible">
                Approved by:
                {approvedBy?.fname && ` ${approvedBy?.fname}`}
                {approvedBy?.lname && ` ${approvedBy?.lname}`}
              </span>
            )}
            {subjectLine?.bandit_status?.status !== 'live' &&
              getIsAuthorized(permissions, routesPermissions.variantsEdit) && (
                <>
                  {subjectLine.ownsl && type === 'frink' && (
                    <Tooltip overlay="Edit" placement="top">
                      <button
                        data-cy="lng-approval-edit-variant-button"
                        data-testid="lng-approval-edit-variant-button"
                        role="presentation"
                        title="Edit variant"
                        className={cx('mr-4', {
                          'cursor-not-allowed opacity-50': disabled,
                        })}
                        disabled={disabled}
                      >
                        <span className="group-hover:hidden">
                          <Edit state="default" />
                        </span>
                        <span className="hidden group-hover:inline">
                          <Edit state="selected" />
                        </span>
                      </button>
                    </Tooltip>
                  )}

                  {!subjectLine.ownsl &&
                    feedbackSettings &&
                    (feedbackSettings.edit.enabled ||
                      feedbackSettings.reject.enabled) && (
                      <Tooltip overlay="Request a change" placement="top">
                        <button
                          data-cy={`lng-approval-request-change-${index}`}
                          data-testid={`lng-approval-request-change-${index}`}
                          role="presentation"
                          title="Request change"
                          className={cx(
                            'mr-4 flex-grow-0 flex-shrink-0 ml-2 focus:outline-none focus-visible:outline-black',
                            {
                              'cursor-not-allowed opacity-50': disabled,
                            }
                          )}
                          disabled={disabled}
                        >
                          <span className="group-hover:hidden">
                            <Switch state="default" />
                          </span>
                          <span className="hidden group-hover:inline">
                            <Switch state="selected" />
                          </span>
                        </button>
                      </Tooltip>
                    )}
                </>
              )}
          </div>
        </div>
      </li>
    )
  }

  allLiveVariants = () => {
    const { subjectLines } = this.props
    return subjectLines.filter(
      (subjectLine: any) => subjectLine?.bandit_status?.status === 'live'
    )
  }

  actionBarNextInfoMessageReact = () => {
    return <>Go on…</>
  }

  actionBarNextInfoMessageEngage = () => {
    return <>So close now...</>
  }

  actionBarSaveButton = (languageApprovalLoading) => {
    return {
      isDisplayed: true,
      isDisabled: false,
      onClick: () => this.handleSubmit(true),
      isLoading: languageApprovalLoading,
    }
  }

  actionBarNextButton = (languageApprovalLoading) => {
    return {
      isDisabled: !getIsAuthorized(
        this.props.permissions,
        routesPermissions.variantsEdit
      ),
      name: 'Approve',
      isLoading: languageApprovalLoading,
    }
  }

  actionBarNextButtonOptions = [
    {
      key: 'send-for-approval',
      label: 'Send for approval',
      onClick: () => this.handleShouldShowSendForApprovalModal(true),
    },
    {
      key: 'approve-and-continue',
      label: 'Approve + continue',
      onClick: () => this.handleSubmit(false),
    },
  ]

  render() {
    const {
      campaign,
      stepProps: {
        languageApprovalLoading,
        params,
        disabled,
        hasEditPermission,
      },
      variantsToBeApprovedExperimentsFlow,
      actions,
      feedbackSettings,
      subjectLines,
      shouldDisplayBoomModal,
      campaignData,
      teamsList,
      actionBarContainerElement,
      type,
    } = this.props
    const variantsToBeApprovedCount: number | undefined =
      variantsToBeApprovedExperimentsFlow?.length
    const {
      hasUnsavedData,
      shouldShowTweakVariantModal,
      shouldShowSendForApprovalModal,
      shouldShowApprovalRequestSentModal,
      selectedTeamsList,
    } = this.state
    const controlSubjectLine = getControlSubjectLine(subjectLines)
    const { renderListElement, allLiveVariants: returnAllLiveVariants } = this

    const externalApprovalRequired =
      campaign.campaign_configuration.external_approval_required

    const isFrinkCampaign = type === 'frink'
    const actionBarContainer = isFrinkCampaign
      ? this.parentDivRef?.current
      : actionBarContainerElement

    return (
      <div
        className={cx({
          'w-full h-full overflow-y-auto flex flex-col flex-auto':
            isFrinkCampaign,
        })}
        ref={this.parentDivRef}
      >
        <>
          {helpers.isBanditNlgInitialised(campaignData) ? (
            <>
              {variantsToBeApprovedExperimentsFlow?.length > 0 && (
                <>
                  <div className="pt-8 ml-8 pr-12">
                    <h2 className="text-lg text-coolGray-800 mb-6 font-medium">
                      Awaiting approval:
                    </h2>
                    <ul className={`${styles.ul} mb-4`}>
                      {variantsToBeApprovedExperimentsFlow.map(
                        (variantId: string, index: number) => {
                          const subjectLine =
                            subjectLines[
                              subjectLines.findIndex(
                                (line) => line._id === variantId
                              )
                            ]
                          return renderListElement(
                            subjectLine,
                            index,
                            variantsToBeApprovedCount
                          )
                        }
                      )}
                    </ul>
                    {hasUnsavedData && (
                      <Banner
                        type="error"
                        icon={
                          <Alert
                            className="text-white"
                            isDefaultColor={false}
                          />
                        }
                        text={`Please approve your changes before leaving this screen as all changes will be lost. `}
                      />
                    )}
                  </div>
                  {externalApprovalRequired ? (
                    <ActionBar
                      shouldShowOnlyPrimary
                      nextInfoMessage={this.actionBarNextInfoMessageReact()}
                      nextButton={{
                        ...this.actionBarNextButton(languageApprovalLoading),
                        options: this.actionBarNextButtonOptions,
                      }}
                    />
                  ) : (
                    <ActionBar
                      shouldShowOnlyPrimary
                      nextInfoMessage={this.actionBarNextInfoMessageReact()}
                      nextButton={{
                        ...this.actionBarNextButton(languageApprovalLoading),
                        icon: <CheckCircle className="w-4 h-4" />,
                        onClick: () => this.handleSubmit(false),
                      }}
                    />
                  )}
                </>
              )}
              <>
                {returnAllLiveVariants().length > 0 && (
                  <div className="pt-8 ml-8 pr-12">
                    <h2 className="text-lg text-coolGray-800 mb-6 font-medium">
                      Currently live:
                    </h2>
                    <ul className={`${styles.ul} mb-4`}>
                      {returnAllLiveVariants().map(
                        (subjectLine: any, index: number) => {
                          return renderListElement(
                            subjectLine,
                            index,
                            returnAllLiveVariants().length
                          )
                        }
                      )}
                    </ul>
                  </div>
                )}
              </>
            </>
          ) : helpers.isBanditInitialised(campaignData) &&
            !helpers.isLinguoCampaign(campaignData) ? (
            <>
              {returnAllLiveVariants().length > 0 && (
                <div className="pt-8 ml-8 pr-12">
                  <h2 className="text-lg text-coolGray-800 mb-6 font-medium">
                    Currently live:
                  </h2>
                  <ul className={`${styles.ul} mb-4`}>
                    {returnAllLiveVariants().map(
                      (subjectLine: any, index: number) => {
                        return renderListElement(
                          subjectLine,
                          index,
                          returnAllLiveVariants().length
                        )
                      }
                    )}
                  </ul>
                </div>
              )}
            </>
          ) : // Linguo campaign
          helpers.isLinguoCampaign(campaignData) ? (
            <>
              {variantsToBeApprovedExperimentsFlow?.length > 0 ? (
                <>
                  <div className="pt-8 ml-8 pr-12">
                    <h2 className="text-lg text-coolGray-800 mb-6 font-medium">
                      Awaiting approval:
                    </h2>
                    <ul className={`${styles.ul} mb-4`}>
                      {variantsToBeApprovedExperimentsFlow.map(
                        (variantId: string, index: number) => {
                          const subjectLine =
                            subjectLines[
                              subjectLines.findIndex(
                                (line) => line._id === variantId
                              )
                            ]
                          return renderListElement(
                            subjectLine,
                            index,
                            variantsToBeApprovedCount
                          )
                        }
                      )}
                    </ul>
                    {hasUnsavedData && (
                      <Banner
                        type="error"
                        icon={
                          <Alert
                            className="text-white"
                            isDefaultColor={false}
                          />
                        }
                        text={`Please approve your changes before leaving this screen as all changes will be lost. `}
                      />
                    )}
                  </div>
                  <div>
                    {externalApprovalRequired ? (
                      <ActionBar
                        shouldShowOnlyPrimary
                        nextInfoMessage={this.actionBarNextInfoMessageReact()}
                        nextButton={{
                          ...this.actionBarNextButton(languageApprovalLoading),
                          options: this.actionBarNextButtonOptions,
                        }}
                      />
                    ) : (
                      <ActionBar
                        shouldShowOnlyPrimary
                        nextInfoMessage={this.actionBarNextInfoMessageReact()}
                        nextButton={{
                          ...this.actionBarNextButton(languageApprovalLoading),
                          icon: <CheckCircle className="w-4 h-4" />,
                          onClick: () => this.handleSubmit(false),
                        }}
                      />
                    )}
                  </div>
                  <div className="pt-8 ml-8 pr-12 overflow-x-auto flex-1-0 pb-24">
                    <ul className={styles.ul}>
                      {subjectLines &&
                        subjectLines
                          .filter(
                            (sl) =>
                              sl.bandit_status?.status !== 'pending' &&
                              sl.bandit_status?.status !== 'dropped'
                          )
                          .map((subjectLine: any, index: number) => {
                            return renderListElement(
                              subjectLine,
                              index,
                              subjectLines.length
                            )
                          })}
                    </ul>
                  </div>
                </>
              ) : (
                <div className="pt-8 ml-8 pr-12 overflow-x-auto flex-1-0 pb-24">
                  <ul className={styles.ul}>
                    {subjectLines &&
                      subjectLines
                        .filter((sl) => {
                          return sl.bandit_status?.status !== 'dropped'
                        })
                        .map((subjectLine: any, index: number) => {
                          return renderListElement(
                            subjectLine,
                            index,
                            subjectLines.length
                          )
                        })}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <div className="pt-8 ml-8 pr-12 overflow-x-auto flex-1-0 pb-24">
              {isFrinkCampaign && (
                <h2 className="text-lg text-coolGray-800 mb-6 font-medium">
                  Jacquard's optimal set for testing:
                </h2>
              )}
              <ul className={styles.ul}>
                {subjectLines &&
                  subjectLines.map((subjectLine: any, index: number) => {
                    return renderListElement(
                      subjectLine,
                      index,
                      subjectLines.length
                    )
                  })}
              </ul>
            </div>
          )}

          <SendForApprovalModal
            isVisible={shouldShowSendForApprovalModal}
            updateIsVisible={this.handleShouldShowSendForApprovalModal}
            teamsList={teamsList}
            updateSelectedTeamsList={this.updateSelectedTeamsList}
            campaignId={campaign._id}
            accountId={campaign.account_id}
          />
          <ApprovalRequestSentModal
            isVisible={shouldShowApprovalRequestSentModal}
            updateIsVisible={this.handleShouldShowApprovalRequestSentModal}
            teams={selectedTeamsList}
          />
          <TweakControlVariant
            updateIsVisible={this.handleShouldShowTweakVariantModal}
            isVisible={shouldShowTweakVariantModal}
            controlSubjectLine={controlSubjectLine}
            newlinesInVariants={campaign.newlinesInVariants}
          />
          <TweakVariant
            showNFM={params.showTweakLine}
            feedbackSettings={feedbackSettings}
            newlinesInVariants={campaign.newlinesInVariants}
            closeTweakLine={this.closeTweakLine}
            tweakingLoading={params.tweakingLoading}
            rejectLineLoading={params.rejectLineLoading}
            rejectLine={this.rejectLine}
            tweakLine={this.tweakLine}
            tweakingLine={params.lineBeingTweaked}
            campaignType={type}
          />
          <InfoMessageModal
            isVisible={shouldDisplayBoomModal}
            title="Boom! - Limit reached"
            message="You’ve reached your limit for rejections in this experiment,
                  please contact us on live chat."
            onClose={() => {
              actions.clickCloseBoomModal()
            }}
          />
        </>
        {!disabled && hasEditPermission
          ? actionBarContainer &&
            ReactDOM.createPortal(
              <ActionBar
                saveButton={
                  isFrinkCampaign
                    ? this.actionBarSaveButton(languageApprovalLoading)
                    : undefined
                }
                nextInfoMessage={this.actionBarNextInfoMessageEngage()}
                nextButton={
                  externalApprovalRequired
                    ? {
                        ...this.actionBarNextButton(languageApprovalLoading),
                        options: this.actionBarNextButtonOptions,
                      }
                    : {
                        ...this.actionBarNextButton(languageApprovalLoading),
                        icon: <CheckCircle className="w-4 h-4" />,
                        onClick: () => this.handleSubmit(false),
                      }
                }
              />,
              actionBarContainer
            )
          : undefined}
      </div>
    )
  }
}

function mapStateToProps(state: any, ownsProps: any) {
  const stepProps = {
    languageApprovalLoading: state.campaignStates.languageApprovalLoading,
    params: {
      showTweakLine: state.campaignStates.showTweakLine,
      lineBeingTweaked: state.campaignStates.lineBeingTweaked,
      tweakingLoading: state.campaignStates.tweakingLoading,
      rejectLineLoading: state.campaignStates.rejectLineLoading,
    },
    disabled:
      ownsProps.type === 'frink'
        ? state.campaignStates?.campaignData?.steps?.[2]?.disabled ?? false
        : state.campaignStates?.campaignData?.steps?.[1]?.disabled ?? false,
    hasEditPermission: state.authStates.permissions?.includes(
      experimentsPermissions.edit
    ),
  }
  const campaign = cloneDeep(ownsProps.campaignData)
  campaign.nfmUsed = cloneDeep(state.campaignStates.nfmUsed) || 0
  campaign.tweakUsed = cloneDeep(state.campaignStates.tweakUsed) || 0
  campaign.newlinesInVariants =
    cloneDeep(state.campaignStates.newlinesInVariants) || {}
  const feedbackSettings = campaign?.campaign_configuration?.feedback_settings
  const teamsList = cloneDeep(state.campaignStates.teamsList)
  const permissions = cloneDeep(state.authStates.permissions)

  return {
    subjectLines: state.campaignStates.subjectLines,
    variantsToBeApprovedExperimentsFlow:
      state.campaignStates.variantsToBeApprovedExperimentsFlow,
    feedbackSettings,
    isFeedbackOnly:
      feedbackSettings?.edit?.feedback_only ||
      feedbackSettings?.reject?.feedback_only,
    shouldDisplayBoomModal: state.campaignStates.shouldDisplayBoomModal,
    stepProps,
    campaign,
    teamsList,
    permissions,
  }
}

function mapDispatchToProps(dispatch: any) {
  return {
    actions: bindActionCreators(
      {
        ...campaignWorkflowActions,
        ...showBanner,
      },
      dispatch
    ),
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LanguageApproval)
)
