import { useState } from 'react'
import { Field, useForm } from 'react-final-form'
import helpers, { CAMPAIGN_NAME_LIMIT } from 'workflow/utils/helpers'

import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import { stripForbiddenCharacters } from 'common/helpers/stripForbiddenCharacters'

import './DatePickerStyles.css'

const validateName = (value: string | undefined, allValues, meta) => {
  if (!value?.trim()) {
    return 'Enter a name for your experiment.'
  }
  const {
    data: { error },
  } = meta

  return error
}

type Props = {
  name: string
  initialValue?: string
  isDisabled?: boolean
  maxCharacters?: number
}

const ExperimentName = ({
  name,
  initialValue,
  isDisabled,
  maxCharacters = CAMPAIGN_NAME_LIMIT,
}: Props) => {
  const form = useForm()
  const [error, setError] = useState<string | undefined>()
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)

  return (
    <Field<string>
      initialValue={initialValue}
      name={name}
      validate={validateName}
      parse={(value) => {
        const strippedValue = stripForbiddenCharacters(value)
        const { isValid, validationError } =
          helpers.validateCampaignName(strippedValue)

        if (isValid) {
          setError(undefined)
          return strippedValue
        } else {
          setError(validationError)
          return strippedValue.slice(0, -1)
        }
      }}
    >
      {({ input, meta }) => (
        <FormItem
          error={meta.touched ? meta.error || error : undefined}
          htmlFor={input.name}
          label="Experiment name"
          className="flex-none mb-4"
        >
          <Input
            data-cy="edit-experimentName-input"
            data-testid="experiment-name-input"
            type="text"
            variant="default"
            className="w-full focus:bg-maroon-40"
            value={input.value}
            onChange={(e) => {
              form.mutators.setError(input.name, undefined)
              input.onChange(e)
            }}
            onBlur={(e) => {
              setIsInputFocused(false)
              input.onBlur(e)
            }}
            onFocus={() => setIsInputFocused(true)}
            name={input.name}
            id={input.name}
            placeholder="Give your experiment a memorable name"
            maxLength={maxCharacters}
            disabled={isDisabled}
          />
          {isInputFocused && (
            <p className="h-4 text-coolGray-400 mt-2">
              {input.value.length} / {maxCharacters}
            </p>
          )}
        </FormItem>
      )}
    </Field>
  )
}

export default ExperimentName
