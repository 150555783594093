/* eslint-disable max-lines */
import { batch } from 'react-redux'
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import { clickNavItem } from 'app/navigation/navigationSlice'
import { parseISO } from 'date-fns'
import * as types from 'redux/actionTypes'
import { RootState } from 'redux/store'

import { createCancelTokenSource } from 'common/api'
import {
  ResponseDistributionChannel as BackendDistributionChannel,
  ResponseStatus as BackendStatus,
} from 'common/interfaces/campaign'
import { changeAccountId } from 'features/auth/store/authSlice'
import { fetchAccountProjects } from 'features/projects/api'

import {
  CampaignsResponse,
  fetchCampaigns,
  fetchProjects,
  ProjectsResponse,
  ResponseCampaign,
  ResponseProjects,
} from '../api'
import { Campaign, Project } from '../api/interface'

import { CampaignState } from './interfaces'

export const CAMPAIGNS_PAGE_SIZE = 50

const initialState: CampaignState = {
  status: 'loading',
  projectsStatus: 'loading',
  campaigns: [],
  selectedCampaignId: undefined,
  selectedCampaignIdBeforeCreate: undefined,
  count: undefined,
  filters: { name: '', status: 'all' },
  selectedProjectName: '',
  projects: [],
  allAccountProjects: [],
  shouldScrollToCampaignId: false,
  isNewCampaign: false,
  isEditing: false,
  selectedCampaigns: [],
  drawer: undefined,
  grammarId: '',
  isCampaignNavCollapsed: false,
  shouldShowPageExitModal: false,
}

function mapResponseCampaignsToCampaigns(
  responseCampaigns: ResponseCampaign[]
): Campaign[] {
  return responseCampaigns.map(
    ({
      _id,
      name,
      created,
      send_date,
      campaign_configuration,
      status,
      user_id,
      campaignOwner,
      project,
      grammar_id,
      is_mismatching_configuration,
    }) => ({
      id: _id,
      name,
      created,
      sent: send_date,
      status,
      distributionChannel: campaign_configuration?.distribution_channel,
      distributionType: campaign_configuration?.distribution_type,
      userId: user_id,
      user: {
        firstName: campaignOwner?.fname,
        lastName: campaignOwner?.lname,
      },
      projectId: project._id,
      projectName: project.name,
      grammarId: grammar_id,
      isMismatchingConfiguration: is_mismatching_configuration,
    })
  )
}

function mapResponseProjectsToProjects(
  responseProjects: ResponseProjects[]
): Project[] {
  return responseProjects.map(
    ({
      _id,
      name,
      created,
      project_configuration,
      project_type,
      assigned_grammars,
      deleted,
    }) => ({
      id: _id,
      name: deleted ? `${name} (deactivated)` : name,
      created,
      distributionChannel:
        project_configuration?.campaign_configurations?.distribution_channel ??
        'email',
      testProject: project_configuration?.test_project,
      distributionType:
        project_configuration?.campaign_configurations?.distribution_type,
      type: project_type,
      assignedGrammars: assigned_grammars,
      isDeleted: deleted,
    })
  )
}

type fetchCampaignsByPageArg = {
  pageNumber: number
}

export const fetchCampaignsByPageAsync = createAsyncThunk<
  CampaignsResponse,
  fetchCampaignsByPageArg,
  { state: RootState }
>(
  'campaign/fetchCampaignsByPage',
  async ({ pageNumber }, { signal, getState }) => {
    const { filters } = getState().campaigns
    const { accountId } = getState().authStates
    const { flags } = getState().flagStates
    const projectScreensFeatureFlag = flags['project-screens']

    const source = createCancelTokenSource()
    signal.addEventListener('abort', () => {
      source.cancel()
    })

    const campaignsPage = await fetchCampaigns({
      variables: {
        page: pageNumber,
        pageSize: CAMPAIGNS_PAGE_SIZE,
        filter: {
          ...filters,
          accountId,
        },
      },
      source,
      projectScreensFeatureFlag,
    })

    return campaignsPage
  }
)

export const startNewCampaign = () => {
  return (dispatch) => {
    dispatch({
      type: types.START_NEW_CAMPAIGN,
    })
  }
}

export const fetchProjectsAsync = createAsyncThunk<
  ProjectsResponse,
  void,
  { state: RootState }
>('campaign/fetchProject', async (_, { signal, getState }) => {
  const { accountId } = getState().authStates
  const source = createCancelTokenSource()
  signal.addEventListener('abort', () => {
    source.cancel()
  })

  return await fetchProjects(accountId, source)
})

export const fetchAccountProjectsAsync = createAsyncThunk<
  { data: ProjectsResponse },
  void,
  { state: RootState }
>('campaign/fetchAccountProjects', async (_, { signal, getState }) => {
  const { accountId } = getState().authStates

  return await fetchAccountProjects(accountId)
})

export const clickCreateCampaign = createAsyncThunk(
  'campaign/clickCreateCampaign',
  (_, thunkAPI) => {
    batch(() => {
      thunkAPI.dispatch(createCampaign())
      thunkAPI.dispatch(startNewCampaign())
      thunkAPI.dispatch(clickNavItem())
    })
  }
)

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    selectCampaign(state, action: PayloadAction<string>) {
      state.selectedCampaignId = action.payload
    },
    selectSearchFilter(state, action: PayloadAction<string>) {
      state.campaigns = []
      state.filters = { ...state.filters, name: action.payload }
    },
    selectProjectFilter(
      state,
      action: PayloadAction<{ id: string; title: string }>
    ) {
      state.campaigns = []
      state.selectedCampaigns = []
      state.selectedCampaignId = undefined
      state.filters = {
        name: state.filters.name,
        status: 'all',
        projectId: action.payload.id,
      }
      state.isCampaignNavCollapsed = false
      state.selectedProjectName = action.payload.title
    },
    selectStatusFilter(state, action: PayloadAction<BackendStatus>) {
      state.campaigns = []
      state.selectedCampaigns = []
      state.selectedCampaignId = undefined
      state.selectedProjectName = ''
      state.filters = { name: state.filters.name, status: action.payload }
      state.isCampaignNavCollapsed = false
    },
    selectDistributionChannelFilter(
      state,
      action: PayloadAction<BackendDistributionChannel>
    ) {
      state.campaigns = []
      state.selectedCampaigns = []
      state.selectedCampaignId = undefined
      state.filters = {
        name: state.filters.name,
        status: 'all',
        distributionChannel: action.payload,
      }
      state.isCampaignNavCollapsed = false
    },
    onLoadPageCampaign: (
      state,
      action: PayloadAction<{
        campaignId: string
      }>
    ) => {
      state.selectedCampaignId = action.payload.campaignId
      state.shouldScrollToCampaignId = true
    },
    markAsScrolledToCampaign: (state) => {
      state.shouldScrollToCampaignId = false
    },
    createCampaign: (state) => {
      state.selectedCampaignIdBeforeCreate = state.selectedCampaignId
      state.selectedCampaignId = undefined
      state.isNewCampaign = true
      state.isEditing = false
    },
    cancelCreateCampaign: (state) => {
      state.selectedCampaignId = state.selectedCampaignIdBeforeCreate
      state.isNewCampaign = false
    },
    addNewCampaign: (
      state,
      action: PayloadAction<{ campaign: Campaign; indexAt?: number }>
    ) => {
      const { indexAt, campaign } = action.payload
      const campaignIndex = state.campaigns.findIndex(
        (c) => c.id === campaign.id
      )
      if (campaignIndex === -1) {
        state.campaigns = indexAt
          ? [
              ...state.campaigns.slice(0, indexAt),
              campaign,
              ...state.campaigns.slice(indexAt),
            ]
          : [campaign, ...state.campaigns]

        if (state.count) {
          state.count = state.count + 1
        }
      }
      state.isNewCampaign = false
    },
    clickEditCampaigns: (state) => {
      state.isEditing = true
      state.selectedCampaignId = undefined
    },
    clickDoneCampaigns: (state) => {
      state.isEditing = false
      state.selectedCampaigns = []
    },
    toggleCheckedCampaign: (state, action: PayloadAction<string>) => {
      state.selectedCampaigns.includes(action.payload)
        ? (state.selectedCampaigns = state.selectedCampaigns.filter(
            (item) => item !== action.payload
          ))
        : state.selectedCampaigns.push(action.payload)
    },
    wipeSelectedCampaigns: (state) => {
      state.selectedCampaigns = []
    },
    removeSelectedFromCampaigns: (state) => {
      state.campaigns = state.campaigns.filter(
        (campaign) => !state.selectedCampaigns.includes(campaign.id)
      )
      if (state.count) {
        state.count = state.count - state.selectedCampaigns.length
      }
      state.selectedCampaigns = []
    },
    openDrawer: (state, action: PayloadAction<string>) => {
      state.drawer = action.payload
    },
    closeDrawer: (state) => {
      state.drawer = undefined
    },
    updateCampaignName: (
      state,
      action: PayloadAction<{ id: string; name: string }>
    ) => {
      const { id, name } = action.payload

      state.campaigns = state.campaigns.map((campaign) =>
        campaign.id === id ? { ...campaign, name } : campaign
      )
    },
    toggleNavCollapse: (state) => {
      state.isCampaignNavCollapsed = !state.isCampaignNavCollapsed
    },
    toggleShowPageExitModal: (state, action: PayloadAction<boolean>) => {
      state.shouldShowPageExitModal = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, () => initialState)
    builder
      .addCase(fetchCampaignsByPageAsync.pending, (state) => {
        state.status = 'loading'
      })
      .addCase(fetchCampaignsByPageAsync.fulfilled, (state, action) => {
        const responseCampaigns = action.payload.campaigns
        const campaigns: Campaign[] =
          mapResponseCampaignsToCampaigns(responseCampaigns)

        state.status = 'idle'
        state.campaigns = [...state.campaigns, ...campaigns]
        state.count = action.payload.count
      })
      .addCase(fetchCampaignsByPageAsync.rejected, (state, action) => {
        // don't show error if request was cancelled
        if (action.error.name !== 'AbortError') {
          state.status = 'failed'
        }
      })
      .addCase(fetchProjectsAsync.pending, (state) => {
        // TODO consider using a global loader
        state.projectsStatus = 'loading'
      })
      .addCase(fetchProjectsAsync.fulfilled, (state, action) => {
        const responseProjects = action.payload
        const mappedProjects = mapResponseProjectsToProjects(responseProjects)
        state.projects = mappedProjects
        state.projectsStatus = 'idle'
      })
      .addCase(fetchProjectsAsync.rejected, (state, action) => {
        // TODO log the error with a service like Logentries
        state.projects = []
        state.projectsStatus = 'failed'
      })

      .addCase(fetchAccountProjectsAsync.fulfilled, (state, action) => {
        const responseProjects = Array.isArray(action.payload.data)
          ? action.payload.data
          : []
        const mappedProjects = mapResponseProjectsToProjects(responseProjects)

        state.allAccountProjects = mappedProjects
      })
      .addCase(fetchAccountProjectsAsync.rejected, (state, action) => {
        // TODO log the error with a service like Logentries
        state.allAccountProjects = []
      })
  },
})

/*
 * Actions
 */
export const {
  selectCampaign,
  selectSearchFilter,
  selectStatusFilter,
  selectDistributionChannelFilter,
  selectProjectFilter,
  onLoadPageCampaign,
  markAsScrolledToCampaign,
  createCampaign,
  cancelCreateCampaign,
  addNewCampaign,
  clickEditCampaigns,
  clickDoneCampaigns,
  toggleCheckedCampaign,
  wipeSelectedCampaigns,
  removeSelectedFromCampaigns,
  openDrawer,
  closeDrawer,
  updateCampaignName,
  toggleNavCollapse,
  toggleShowPageExitModal,
} = campaignSlice.actions

const sortProjects = (projects: Project[]): Project[] => {
  // slice is used to clone the array because immer froze the array
  // and the sort method mutates it
  return projects.slice().sort(
    // sort by descending creation date
    (a, b) => parseISO(b.created).getTime() - parseISO(a.created).getTime()
  )
}

export const getAllProjects = (state: RootState) =>
  sortProjects(state.campaigns.projects)

export const getActiveProjects = (state: RootState) =>
  sortProjects(state.campaigns.projects.filter(({ isDeleted }) => !isDeleted))

export const getNonTestProjects = (state: RootState) =>
  getAllProjects(state).filter(
    ({ testProject, isDeleted }) => !testProject && !isDeleted
  )

export const getAllProjectsOfAnAccount = (state: RootState) =>
  sortProjects(state.campaigns.allAccountProjects)

export const getAllActiveProjectsOfAnAccount = (state: RootState) =>
  sortProjects(
    state.campaigns.allAccountProjects.filter(({ isDeleted }) => !isDeleted)
  )

export const getAllNonTestProjectsOfAnAccount = (state: RootState) =>
  getAllProjectsOfAnAccount(state).filter(
    ({ testProject, isDeleted }) => !testProject && !isDeleted
  )

const getCampaigns = (state: CampaignState) => {
  return state.campaigns
}

const getSelectedCampaignInfo = createSelector(
  getCampaigns,
  (state) => state.selectedCampaignId,
  (campaigns, selectedCampaignId) =>
    selectedCampaignId
      ? (campaigns as Campaign[]).filter(
          (campaign) => campaign.id === selectedCampaignId
        )[0]
      : undefined
)

const getSelectedProjectInfo = createSelector(
  getAllProjects,
  (state) => state.campaigns.filters.projectId,
  (projects, selectedProjectId) =>
    selectedProjectId
      ? (projects as Project[]).find(
          (project) => project.id === selectedProjectId
        )
      : undefined
)

export const getSelectedCampaignUser = (state: RootState) => {
  const selectedCampaign = getSelectedCampaignInfo(state.campaigns)
  return { id: selectedCampaign?.userId, ...selectedCampaign?.user }
}

export const getSelectedProject = (state: RootState) => {
  return getSelectedProjectInfo(state)
}

export default campaignSlice.reducer
