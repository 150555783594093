import { useMemo } from 'react'
import { Row } from 'react-table'
import cx from 'classnames'

import { LineArrowRight } from 'common/icons'

import BaseCell from '../cells/Base'
import Resizer from '../Resizer'
import type { TableData, TableProps } from '../types/interfaces'

import styles from '../Table.module.css'

export type DefaultRowProps<T extends object> = Pick<
  TableProps<T>,
  'onRowClick' | 'rowClassName' | 'isHeaderHidden'
> & {
  isResizingColumn?: string
  row: Row<T>
  rows: Row<T>[]
  dataCy?: string
  dataTestId?: string
  hoveredColumnIndex?: number
  rowIndex: number
  subRowIndex?: number
  depth: number
  disableSubRowArrow?: boolean
  generateRowClassName?: ({
    row,
    rows,
  }: {
    row: Row<T>
    rows: Row<T>[]
  }) => string
  isHorizontalSeparatorHidden?: boolean
}

const DefaultRow = <T extends TableData>({
  row,
  rows,
  onRowClick,
  dataCy,
  rowClassName,
  isResizingColumn,
  isHeaderHidden,
  rowIndex,
  depth,
  disableSubRowArrow,
  generateRowClassName,
  subRowIndex,
  hoveredColumnIndex,
  isHorizontalSeparatorHidden,
}: DefaultRowProps<T>) => {
  const generatedRowClassNames = useMemo(() => {
    return generateRowClassName?.({ row, rows })
  }, [generateRowClassName, row, rows])

  const isRowSelectedAndValid =
    (row.isSelected || row.original?.checked) && !row.values.hasError

  return (
    <div
      {...row.getRowProps()}
      onClick={(event) => {
        const element = event.target as HTMLElement
        if (onRowClick && isElementClickable(element)) {
          onRowClick(row.original)
        }
      }}
      data-cy={`${dataCy}-body-row`}
      data-testid="table-body-row"
      {...(row.original?.isHighlighted ? { 'data-highlighted': true } : {})}
      className={cx('group min-h-13', rowClassName, generatedRowClassNames, {
        'cursor-auto': !isResizingColumn,
        'bg-red-50': row.original.hasError,
        'hover:bg-yellow-50': !row.original.hasError,
        'bg-yellow-200': row.original.isHighlighted,
        'animate-rowFadeOut': row.original.isRefreshed,
        'cursor-pointer': !!onRowClick,
      })}
      key={`body-row-${row.id}`}
    >
      {depth > 0 && !disableSubRowArrow && (
        <div
          className={cx('flex justify-center items-center pl-6', {
            'bg-yellow-200': isRowSelectedAndValid,
            'border-gold-200 border-t': !isHorizontalSeparatorHidden,
          })}
        >
          <LineArrowRight className="text-gold-400 hover:text-gold-700 group-hover:text-gold-700" />
        </div>
      )}
      {row.cells.map((cell, index) => {
        const {
          column: {
            id: columnId,
            align,
            isResizable,
            shouldOverflow,
            getResizerProps,
            isEditable,
            isDisabled,
            canSort,
            className,
            direction,
          },
          value,
        } = cell

        return (
          <div
            {...cell.getCellProps()}
            key={`${columnId}-${index}`}
            dir={direction}
            className={cx(
              'relative border-gold-200 flex items-stretch',
              className,
              styles.common,
              {
                'flex-none w-min': columnId === 'selection',
                'border-t':
                  (isHeaderHidden ? rowIndex !== 0 : true) &&
                  subRowIndex !== 0 &&
                  !isHorizontalSeparatorHidden,
                'bg-yellow-200 group-hover:bg-yellow-50':
                  isEditable === false && // only when false, not undefined
                  !row.isSelected &&
                  hoveredColumnIndex !== index &&
                  !row.original.hasError,
                [styles.cell]: !shouldOverflow,
                'justify-end': align === 'right',
                'justify-center': align === 'center',
                'bg-yellow-200': isRowSelectedAndValid,
                'bg-gold-300 bezier-all-transition':
                  hoveredColumnIndex === index && !isResizingColumn && canSort,
                'text-coolGray-400': isDisabled,
              }
            )}
            data-cy={`${dataCy}-body-cell`}
            data-testid={`table-body-cell-${columnId}`}
          >
            {(value === undefined || value === null) &&
            !isEditable &&
            columnId !== 'selection' ? (
              <BaseCell>-</BaseCell>
            ) : (
              cell.render('Cell')
            )}
            {isResizable && <Resizer {...getResizerProps()} />}
          </div>
        )
      })}
    </div>
  )
}

export default DefaultRow

function isElementClickable(element: Element | null) {
  if (element === null) {
    return false
  }
  if (['button', 'svg', 'input'].includes(element.tagName.toLowerCase())) {
    return false
  }
  if (
    [
      element.getAttribute('role'),
      element.parentElement?.getAttribute('role') === 'cell',
    ].includes('cell')
  ) {
    return true
  }
  return isElementClickable(element.parentElement)
}
