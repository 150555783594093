import { useEffect, useState } from 'react'

import { connectInternalApi } from 'common/api'
import { ReportSummaryResponse } from 'common/api/reporting'
import * as GraphWidget from 'common/components/GraphWidget'
import InfoAction from 'common/components/InfoAction'
import WidgetLoader from 'common/components/loaders/WidgetLoader'
import ChartWidgetMenu from 'common/components/widget/menu/ChartWidgetMenu'
import { WidgetError } from 'common/components/WidgetMessage'
import { useChartRefCallback } from 'common/hooks/useChartRefCallback'

import { WidgetProps } from '../interfaces'

import ActivityGauge from './ActivityGauge'

export type GraphData = {
  label: string
  value: number
}[]

const AverageDiversityWidget = ({
  accountId,
  projectIds,
  startDate,
  endDate,
}: WidgetProps) => {
  const [results, setResults] = useState<GraphData>([])

  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { chart, chartRefCallback } = useChartRefCallback()

  useEffect(() => {
    function fetchData() {
      return connectInternalApi.get<ReportSummaryResponse>(
        `v1/core/reporting/reporting/accounts/${accountId}/insights/diversity/report`,
        {
          params: {
            project_ids: projectIds,
            campaign_ids: [],
            start_date: startDate,
            end_date: endDate,
            reporting_level: 'project',
          },
        }
      )
    }

    setResults([])
    setIsLoading(true)
    setHasError(false)

    fetchData()
      .then((graphResponse) => {
        const {
          data: { data: graphData },
        } = graphResponse
        const averageValuesPerPeriod = graphData.map(
          ({ values }) =>
            values.find(({ name }) => name === 'Average')?.values ?? []
        )
        const labels = averageValuesPerPeriod[0].map(({ label }) => label)

        const data: { [key: string]: number[] } = {}

        // assign labels as data keys
        labels.map((label) => (data[label] = []))
        // assign value array to data
        averageValuesPerPeriod
          .flat()
          .map(({ label, value }) => (data[label] = [...data[label], value]))

        const formattedData: GraphData = labels.map((label) => {
          const values = data[label]
          const sum = values.reduce((a, b) => a + b, 0)
          const average = sum / values.length || 0

          return {
            label,
            value: Number(average.toFixed(2)),
          }
        })

        setResults(formattedData)
      })
      .catch(() => {
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [accountId, projectIds, startDate, endDate])

  const hasResults = !hasError && !isLoading

  const title = 'Average diversity'

  return (
    <GraphWidget.Widget
      type="inner"
      title={
        <div>
          <div className="text-coolGray-800 font-medium text-xl mb-4 mr-4">
            {title}
          </div>
          <p className="text-coolGray-500 text-sm font-normal">
            Diversity is a key metric when it comes to providing uplift through
            language. The wider the space on the graph, the higher the diversity
            is within the language tested.
          </p>
        </div>
      }
      actions={
        hasResults && (
          <div className="inline-flex">
            <ChartWidgetMenu
              chart={chart}
              title={title}
              problematicExports={['print', 'pdf']}
            />
            <InfoAction
              message="Diversity is key when it comes to providing uplift through language. 
              Testing as wide a range of language as possible is what fuels Jacquard’s learning - the more 
              you test, the more Jacquard will know about your audience! Take a look at how diverse your 
              control copy is compared to the Jacquard variants."
            />
          </div>
        )
      }
    >
      {isLoading && (
        <WidgetLoader
          data-cy="average-diversity-widget-loader"
          data-testid="average-diversity-widget-loader"
        />
      )}
      {hasError && <WidgetError className="mb-6" />}
      {hasResults ? (
        <ActivityGauge
          total={100}
          selectedTimeIndex={1}
          results={results}
          ref={chartRefCallback}
        />
      ) : undefined}
    </GraphWidget.Widget>
  )
}

export default AverageDiversityWidget
