import { Field, useForm } from 'react-final-form'

import { SelectValue } from 'common/components/BaseSelect'
import FormItem from 'common/components/formItem'
import SingleSelect from 'common/components/singleSelect'

type Props = {
  isLoading: boolean
}

const validate = (value: SelectValue) => {
  if (!value) {
    return 'Required'
  }
}

const integrations = [
  { label: 'Acoustic', value: 'acoustic' },
  { label: 'Adobe Campaign Classic', value: 'adobe_campaign_classic' },
  { label: 'Adobe Campaign Standard', value: 'adobe_campaign_standard' },
  { label: 'Adobe Journey Optimizer', value: 'ajo' },
  { label: 'Bloomreach', value: 'bloomreach' },
  { label: 'Braze', value: 'braze' },
  { label: 'Cheetah', value: 'cheetah' },
  { label: 'Cordial', value: 'cordial' },
  { label: 'Emarsys', value: 'emarsys' },
  { label: 'Epsilon', value: 'epsilon' },
  { label: 'Iterable', value: 'iterable' },
  { label: 'Jacquard', value: 'jacquard' },
  { label: 'Klaviyo', value: 'klaviyo' },
  { label: 'Leanplum', value: 'leanplum' },
  { label: 'MessageGears', value: 'message_gears' },
  { label: 'MoEngage', value: 'moengage' },
  { label: 'Oracle Responsys', value: 'responsys' },
  { label: 'Sailthru', value: 'sailthru' },
  { label: 'Salesforce Marketing Cloud', value: 'sfmcv2' },
]

const Integrations = ({ isLoading }: Props) => {
  const form = useForm()

  const resetFormValues = () => {
    form.change('merge_tag_category', undefined)
    form.change('merge_tag_description', undefined)
    form.change('merge_tag_code', undefined)
    form.change('merge_tag_example_values', undefined)
  }

  return (
    <Field<SelectValue> name="merge_tag_integration" validate={validate}>
      {({ input, meta }) => (
        <FormItem
          label="Integration"
          htmlFor={input.name}
          className="flex-none"
          guidingText="Which integration will use this merge tag?"
          error={meta.touched ? meta.error : undefined}
        >
          <SingleSelect
            data-testid="add-merge-tag-drawer-integration-input"
            id={input.name}
            name={input.name}
            options={integrations}
            value={input.value.value}
            onChange={(value) => {
              input.onChange(value)
              resetFormValues()
            }}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            isLoading={isLoading}
          />
        </FormItem>
      )}
    </Field>
  )
}

export default Integrations
