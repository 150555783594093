import { useEffect } from 'react'

import { useAppDispatch } from 'common/hooks/redux'
import { showRightPanel } from 'features/unifiedFlow/store/unifiedFlowSlice'

const useRightPanelVisibilityState = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(showRightPanel({ visibility: 'auto' }))
  }, [dispatch])
}

export default useRightPanelVisibilityState
