import { useMutation, useQueryClient } from '@tanstack/react-query'

import { integrationsKeys } from 'common/api/queryKeys/integrationsKeys'
import { errorToast, successToast } from 'common/components/toastNotification'

import { deleteIntegration } from '../api'

const useDeleteIntegrationMutation = (accountId: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteIntegration,
    onSuccess: async () => {
      successToast('The integration has been successfully deleted', {
        autoClose: 3000,
      })
      queryClient.invalidateQueries({
        queryKey: integrationsKeys.accounts(accountId),
      })
    },
    onError: () => {
      errorToast('The integration could not be deleted', { autoClose: 3000 })
    },
  })
}

export default useDeleteIntegrationMutation
