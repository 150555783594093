import cx from 'classnames'

import Widget, { WidgetType } from 'common/components/Widget'

import HorizontalWidgetLoader from '../loaders/HorizontalWidgetLoader'
import { WidgetFirstUse } from '../WidgetMessage'

import Error from './Error'
import Item, { ItemType } from './SummaryItem'

export type Items = ItemType[]

type Props = {
  items: Items
  hasError?: boolean
  isLoading?: boolean
  className?: string
  widgetType?: WidgetType
}

export const SummaryWidgetContent = ({ items }: { items: Items }) => {
  return (
    <div className="flex justify-between gap-6">
      {items.map(({ value, label, className, shouldTruncate }) => (
        <Item
          key={label}
          value={value}
          label={label}
          className={className}
          shouldTruncate={shouldTruncate}
        />
      ))}
    </div>
  )
}

const SummaryWidget = ({
  items,
  isLoading,
  className,
  hasError = false,
  widgetType = 'default',
}: Props) => {
  const getStatus = () => {
    if (hasError) {
      return 'error'
    } else if (isLoading) {
      return 'loading'
    } else if (items.length) {
      return 'data'
    } else {
      return 'noData'
    }
  }

  const status = getStatus()

  return (
    <div className={className} data-testid="summaryWidget">
      {status === 'error' ? (
        <Error />
      ) : (
        <Widget className={cx({ 'py-4 px-4': isLoading })} type={widgetType}>
          {
            {
              loading: (
                <HorizontalWidgetLoader
                  data-cy="summary-widget-loader"
                  data-testid="summary-widget-loader"
                />
              ),
              noData: <WidgetFirstUse />,
              data: <SummaryWidgetContent items={items} />,
            }[status]
          }
        </Widget>
      )}
    </div>
  )
}

export default SummaryWidget
