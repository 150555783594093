import { useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import useGetBrandVoicesQuery from 'common/api/queries/useGetBrandVoicesQuery'
import useRegionsListQuery from 'common/api/queries/useRegionsListQuery'
import { RegionChangeConfirmationModal } from 'common/components/confirmationModal'
import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import SingleSelect, { SelectValue } from 'common/components/singleSelect'
import Widget from 'common/components/Widget'
import WidgetHeader from 'common/components/WidgetHeader'
import { formatDistributionChannel } from 'common/distributionChannelUtils'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import useCurrencyListQuery from '../queries/useCurrenciesListQuery'
import {
  saveRegion,
  selectCurrency,
  selectDateFormat,
} from '../store/contentSettingsSlice'

import Segments from './segments'

export const dateFormats = [
  'DD-MM-YYYY',
  'MM-DD-YYYY',
  'YYYY-DD-MM',
  'YYYY-MM-DD',
]
const currencySymbolToIso = {
  '£': 'GBP',
  '€': 'EUR',
  $: 'USD',
  '₽': 'RUB',
  '₸': 'KZT',
  грн: 'UAH',
  R: 'BRL',
  CHF: 'CHF',
  '₹': 'INR',
  '₪': 'ILS',
}
const dateFormatOptions: SelectValue[] = dateFormats.map((item) => ({
  value: item,
  label: item,
}))

type RegionChangeConfirmationModalState =
  | { isOpen: false }
  | {
      isOpen: true
      regionId: string | undefined
      brandVoiceId: string | undefined
    }

type Props = {
  projectId: string
}

const RegionStructureWidget = ({ projectId }: Props) => {
  const dispatch = useAppDispatch()
  const regionsListQuery = useRegionsListQuery()
  const currencyListQuery = useCurrencyListQuery()
  const brandVoicesQuery = useGetBrandVoicesQuery()
  const flags = useFlags()
  const { brandVoiceId, regionId, currency, dateFormat } = useAppSelector(
    (state) => state.contentSettings.editedContent.regionStructure
  )
  const distributionChannel = useAppSelector(
    (state) => state.contentSettings.distributionChannel
  )
  const languageGenerationMethod = useAppSelector(
    (state) => state.contentSettings.languageGenerationMethod
  )
  const isSavingRegion = useAppSelector(
    (state) => state.contentSettings.isSavingRegion
  )

  const [regionChangeConfirmationModal, setRegionChangeConfirmationModal] =
    useState<RegionChangeConfirmationModalState>({ isOpen: false })

  const regionOptions =
    regionsListQuery.data?.map(({ id, name }) => ({
      value: `region|${id}`,
      label: name,
    })) ?? []
  const brandVoiceOptions =
    brandVoicesQuery.data?.map(({ id, name }) => ({
      value: `brandvoice|${id}`,
      label: name,
    })) ?? []
  const templateOptions = [
    {
      label: 'Brand Voice',
      options: brandVoiceOptions,
    },
    {
      label: 'Regions',
      options: regionOptions,
    },
  ]
  const templateSelectValue = brandVoiceId
    ? `brandvoice|${brandVoiceId}`
    : regionId
    ? `region|${regionId}`
    : ''
  const currencyOptions = currencyListQuery.data?.map(({ symbol, name }) => ({
    value: currencySymbolToIso[symbol],
    label: symbol,
  }))

  return (
    <>
      <Widget
        data-cy="region-and-structure-widget"
        data-testid="region-and-structure-widget"
      >
        <WidgetHeader
          title="Settings"
          subtitle="Settings widget placeholder text here"
        />
        <div className="flex gap-4">
          <FormItem className="max-w-100" label="Template">
            <SingleSelect
              data-cy="template-select"
              data-testid="template-select"
              options={templateOptions}
              value={templateSelectValue}
              onChange={(value) => {
                if (value?.value && value.value !== templateSelectValue) {
                  const [templateType, templateId] = value.value.split('|')
                  let brandVoiceId: string | undefined
                  let regionId: string | undefined
                  if (templateType === 'region') {
                    regionId = templateId
                  }
                  if (templateType === 'brandvoice') {
                    brandVoiceId = templateId
                  }
                  setRegionChangeConfirmationModal({
                    isOpen: true,
                    brandVoiceId,
                    regionId,
                  })
                }
              }}
              isLoading={isSavingRegion}
            />
          </FormItem>
          <FormItem className="max-w-16" label="Currency">
            <SingleSelect
              data-cy="currency-select"
              data-testid="currency-select"
              options={currencyOptions}
              value={currency}
              onChange={(value) =>
                value?.value && dispatch(selectCurrency(value.value))
              }
            />
          </FormItem>
          <FormItem className="max-w-16" label="Date format">
            <SingleSelect
              data-cy="date-format-select"
              data-testid="date-format-select"
              options={dateFormatOptions}
              value={dateFormat}
              onChange={(value) =>
                value?.value && dispatch(selectDateFormat(value.value))
              }
            />
          </FormItem>
        </div>

        <FormItem className="max-w-16" label="Channel" htmlFor="channel">
          <Input
            data-cy="channel-input"
            data-testid="channel-input"
            className="capitalize"
            id="channel"
            name="channel"
            variant="default"
            type="text"
            value={
              distributionChannel &&
              formatDistributionChannel(distributionChannel)
            }
            disabled
          />
        </FormItem>

        {distributionChannel && (
          <Segments
            distributionChannel={distributionChannel}
            languageGenerationMethod={languageGenerationMethod}
          />
        )}
      </Widget>

      {regionChangeConfirmationModal.isOpen && (
        <RegionChangeConfirmationModal
          onConfirm={() => {
            dispatch(
              saveRegion({
                projectId,
                regionId: regionChangeConfirmationModal.regionId,
                brandVoiceId: regionChangeConfirmationModal.brandVoiceId,
                isEmojiInheritanceDisabled: flags.breakEmojiInheritance,
              })
            )
            setRegionChangeConfirmationModal({ isOpen: false })
          }}
          onCancel={() => setRegionChangeConfirmationModal({ isOpen: false })}
        />
      )}
    </>
  )
}

export default RegionStructureWidget
