/* eslint-disable max-lines */
import {
  Node,
  Region as RegionResponse,
} from '@phrasee/phrasee-typings/Graphql/interfaces'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import { RootState } from 'redux/store'

import { fetchRegionsList } from 'common/api/regionsApi'
import { removeMaybe } from 'common/helpers/typeUtils'
import { Regions } from 'common/interfaces/regions'
import { changeAccountId } from 'features/auth/store/authSlice'

import { fetchPronounsNodes, fetchRegion, updateRegion } from '../api'

type Pronouns = {
  masculine: number[]
  feminine: number[]
  epicene: number[]
}

type Synonym = {
  id: number
  headToken: string
  partOfSpeech: { label: string; value: string }[]
  output: string[]
}

type Emoji = {
  value: string
  triggerWords: string[]
}

type Region = {
  forbiddenWords: string[]
  incompatibleWords: string[][]
  pronouns: Pronouns
  locations: string[]
  synonyms: (Synonym & { hasError: boolean })[]
  emojis: Emoji[]
}
export interface RegionState {
  isSavingRegion: boolean
  rawRegion: RegionResponse
  regionsList: { value: string; label: string }[]
  regionEdited: Region
  nodes: Node[]
}

export const initialState: RegionState = {
  isSavingRegion: false,
  rawRegion: {},
  regionsList: [],
  regionEdited: {
    forbiddenWords: [],
    incompatibleWords: [],
    locations: [],
    pronouns: {
      masculine: [],
      feminine: [],
      epicene: [],
    },
    synonyms: [],
    emojis: [],
  },
  nodes: [],
}

export const initializePage = createAsyncThunk<RegionResponse, string>(
  'admin/regions/initializePage',
  fetchRegion
)

export const retrieveRegionsList = createAsyncThunk<Regions>(
  'admin/regions/retrieveRegionsList',
  fetchRegionsList
)

export const retrieveNodes = createAsyncThunk<Node[], number>(
  'admin/regions/fetchNodes',
  async (contentUpdateId) => {
    return fetchPronounsNodes(contentUpdateId)
  }
)

const processRegionResponse = (regionResponse: RegionResponse): Region => {
  const synonyms =
    regionResponse?.language_settings?.syns?.map((synonym, index) => {
      return {
        id: index,
        headToken:
          synonym?.pattern
            ?.flat()
            ?.map((pos) => pos?.LOWER)
            .join(' ') ?? '',
        partOfSpeech:
          synonym?.pattern?.flat()?.map((pos) => ({
            label: pos?.LOWER ?? '',
            value: pos?.POS?.toLowerCase() ?? '',
          })) ?? [],
        output:
          synonym?.replacements
            ?.flat()
            .map((replacement) => replacement ?? '') ?? [],
        hasError: false,
      }
    }) ?? []

  return {
    forbiddenWords:
      (regionResponse.language_settings?.vocabulary?.forbidden_words as
        | string[]
        | undefined) ?? [],
    incompatibleWords:
      (regionResponse.language_settings?.vocabulary?.incompatible_words as
        | string[][]
        | undefined) ?? [],
    pronouns: {
      masculine:
        (regionResponse.language_settings?.pronouns?.masculine as
          | number[]
          | undefined) ?? [],
      feminine:
        (regionResponse.language_settings?.pronouns?.feminine as
          | number[]
          | undefined) ?? [],
      epicene:
        (regionResponse.language_settings?.pronouns?.epicene as
          | number[]
          | undefined) ?? [],
    },
    locations:
      (regionResponse.language_settings?.locations_requiring_article as
        | string[]
        | undefined) ?? [],
    synonyms: synonyms,
    emojis:
      regionResponse.language_settings?.emojis_list
        ?.filter(removeMaybe)
        ?.map((emoji) => {
          return {
            value: emoji.lookup ?? '',
            triggerWords: emoji.values?.filter(removeMaybe) ?? [],
          }
        })
        .filter(Boolean) ?? [],
  }
}

export const isSynonymValid = (synonym: Synonym) => {
  return (
    synonym.headToken &&
    synonym.partOfSpeech &&
    synonym.output.length > 0 &&
    !synonym.output.some((word) => !word)
  )
}

const getDuplicatedSynonyms = (synonyms: Synonym[]) => {
  const concatPartOfSpeech = (current: Synonym) =>
    current.partOfSpeech.map((word) => word.value).join('-')

  const originalWordsWithPartOfSpeech = synonyms.reduce(
    (previous, current) => ({
      ...previous,
      [`${current.headToken}-${concatPartOfSpeech(current)}`]:
        (previous[`${current.headToken}-${concatPartOfSpeech(current)}`] ?? 0) +
        1,
    }),
    {}
  )

  const duplicateSynonimsWords = synonyms.filter(
    (word) =>
      originalWordsWithPartOfSpeech[
        `${word.headToken}-${concatPartOfSpeech(word)}`
      ] > 1
  )

  return duplicateSynonimsWords
}

const processNodesResponse = (nodesResponse: any): Node[] => {
  return nodesResponse.flat(1).map((node) => {
    return { id: node.nodeId, display_name: node.displayName, node: node.node }
  })
}

export const saveRegion = createAsyncThunk<
  RegionResponse,
  void,
  { state: RootState }
>('admin/regions/save', async (_, { getState }) => {
  const state = getState()
  const { regionEdited, rawRegion } = state.regions

  const isEmptyRow = (synonym: Synonym) =>
    !synonym.headToken.length &&
    !synonym.output.length &&
    !synonym.partOfSpeech.length
  const synonymsWithoutEmptyRows = regionEdited.synonyms.filter(
    (synonym) => !isEmptyRow(synonym)
  )

  if (getDuplicatedSynonyms(synonymsWithoutEmptyRows).length > 0) {
    return Promise.reject(
      'Some synonyms are duplicated, please make sure that each combination of head token word and part of speech is unique.'
    )
  }

  if (synonymsWithoutEmptyRows.some((synonym) => !isSynonymValid(synonym))) {
    return Promise.reject(
      'Some synonyms are invalid, please fill all the columns.'
    )
  }

  const incompatibleWordsWithoutEmptyRows =
    regionEdited.incompatibleWords.filter(
      (incompatibleWordRow) => incompatibleWordRow.length > 0
    )

  const regionToSave: RegionResponse = {
    ...rawRegion,
    language_settings: {
      ...rawRegion?.language_settings,
      vocabulary: {
        ...rawRegion?.language_settings?.vocabulary,
        forbidden_words: regionEdited.forbiddenWords,
        incompatible_words: incompatibleWordsWithoutEmptyRows,
      },
      pronouns: {
        masculine: regionEdited.pronouns.masculine,
        feminine: regionEdited.pronouns.feminine,
        epicene: regionEdited.pronouns.epicene,
      },
      syns: synonymsWithoutEmptyRows.map((synonym) => ({
        pattern: [
          [
            ...synonym.partOfSpeech.map((pos) => ({
              LOWER: pos.label,
              ...(pos.value === ''
                ? undefined
                : { POS: pos.value.toUpperCase() }),
            })),
          ],
        ],
        replacements: synonym?.output ?? [],
      })),
      locations_requiring_article: regionEdited.locations,
      emojis_list: regionEdited.emojis
        .filter(({ value }) => !!value)
        .map((emoji) => ({
          lookup: emoji.value,
          values: emoji.triggerWords,
        })),
    },
  }
  return updateRegion(regionToSave)
})

export const regionSlice = createSlice({
  name: 'admin/regions',
  initialState,
  reducers: {
    addForbiddenWord: (state, action: PayloadAction<string>) => {
      state.regionEdited.forbiddenWords = [
        ...state.regionEdited?.forbiddenWords,
        action.payload.toLowerCase(),
      ]
    },
    removeForbiddenWord: (state, action: PayloadAction<string>) => {
      state.regionEdited.forbiddenWords =
        state.regionEdited.forbiddenWords.filter(
          (word) => word !== action.payload
        )
    },
    addIncompatibleWord: (
      state,
      action: PayloadAction<{ value: string; index: number }>
    ) => {
      if (
        !state.regionEdited.incompatibleWords[action.payload.index].includes(
          action.payload.value.toLowerCase()
        )
      ) {
        state.regionEdited.incompatibleWords[action.payload.index].push(
          action.payload.value.toLowerCase()
        )
      }
    },
    removeIncompatibleWord: (
      state,
      action: PayloadAction<{ value: string; index: number }>
    ) => {
      const wordIndex = state.regionEdited.incompatibleWords[
        action.payload.index
      ].indexOf(action.payload.value)
      state.regionEdited.incompatibleWords[action.payload.index].splice(
        wordIndex,
        1
      )
    },
    addIncompatibleWordsRow: (state) => {
      state.regionEdited.incompatibleWords.unshift([])
    },
    removeIncompatibleWordsRow: (state, action: PayloadAction<number>) => {
      state.regionEdited.incompatibleWords.splice(action.payload, 1)
    },
    addPronounReplacer: (state, action: PayloadAction<any>) => {
      let pronounType = 'epicene'
      if (action.payload.index === 0) {
        pronounType = 'feminine'
      }
      if (action.payload.index === 1) {
        pronounType = 'masculine'
      }

      const currentNodes = state.regionEdited.pronouns.epicene.concat(
        state.regionEdited.pronouns.feminine,
        state.regionEdited.pronouns.masculine
      )

      // prevent duplicates across pronoun types
      if (!currentNodes.some((nodeId) => action.payload.value === nodeId)) {
        state.regionEdited.pronouns[`${pronounType}`] = [
          ...state.regionEdited?.pronouns[`${pronounType}`],
          action.payload.value,
        ]
      }
    },
    removePronounReplacer: (state, action: PayloadAction<any>) => {
      let pronounType = 'epicene'
      if (action.payload.index === 0) {
        pronounType = 'feminine'
      }
      if (action.payload.index === 1) {
        pronounType = 'masculine'
      }

      state.regionEdited.pronouns[`${pronounType}`] =
        state.regionEdited.pronouns[`${pronounType}`].filter(
          (word) => word !== action.payload.value
        )
    },
    resetRegion: (state) => {
      state.regionEdited = processRegionResponse(state.rawRegion)
    },
    addSynonymRow: (state) => {
      state.regionEdited.synonyms = [
        {
          headToken: '',
          partOfSpeech: [],
          output: [],
          id: 0,
          hasError: false,
        },
        ...state.regionEdited.synonyms.map((synonym) => ({
          ...synonym,
          id: synonym.id + 1,
        })),
      ]
    },
    updateSynonymCell: (
      state,
      action: PayloadAction<{
        rowIndex: number
        columnId: string
        value: unknown
      }>
    ) => {
      const { rowIndex, columnId, value } = action.payload
      const synonym = state.regionEdited.synonyms[rowIndex]
      synonym[columnId] = value
      synonym.hasError = synonym.hasError ? !isSynonymValid(synonym) : false
    },
    addEmojiRow: (state) => {
      state.regionEdited.emojis = [
        {
          value: '',
          triggerWords: [],
        },
        ...state.regionEdited.emojis,
      ]
    },
    updateEmojiCell: (
      state,
      action: PayloadAction<{
        rowIndex: number
        columnId: string
        value: unknown
      }>
    ) => {
      const { rowIndex, columnId, value } = action.payload
      const emoji = state.regionEdited.emojis[rowIndex]
      emoji[columnId] = value
    },
    addLocation: (state, action: PayloadAction<string>) => {
      const newLocation = action.payload.toLowerCase()
      if (!state.regionEdited.locations.includes(newLocation)) {
        state.regionEdited.locations = [
          ...state.regionEdited.locations,
          newLocation,
        ]
      }
    },
    removeLocation(state, action: PayloadAction<string>) {
      state.regionEdited.locations = state.regionEdited.locations.filter(
        (location) => location !== action.payload.toLocaleLowerCase()
      )
    },
    deleteSynonymRow: (state, action: PayloadAction<number>) => {
      state.regionEdited.synonyms = state.regionEdited.synonyms
        .filter((_, index) => index !== action.payload)
        .map((synonym, index) => ({ ...synonym, id: index }))
    },
    deactivateEmojis: (state, action: PayloadAction<string[]>) => {
      state.regionEdited.emojis = state.regionEdited.emojis.map((emoji) => {
        if (action.payload.includes(emoji.value)) {
          return { ...emoji, triggerWords: [] }
        }
        return emoji
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeAccountId.fulfilled, () => initialState)
    builder.addCase(initializePage.fulfilled, (state, action) => {
      state.rawRegion = action.payload
      state.regionEdited = processRegionResponse(action.payload)
    })
    builder.addCase(retrieveRegionsList.fulfilled, (state, action) => {
      state.regionsList = action.payload.map((region) => ({
        value: region.id,
        label: region.name,
      }))
    })

    builder.addCase(saveRegion.pending, (state) => {
      state.isSavingRegion = true
    })
    builder.addCase(saveRegion.fulfilled, (state, action) => {
      state.isSavingRegion = false
      state.rawRegion = action.payload
      state.regionEdited = processRegionResponse(action.payload)
    })
    builder.addCase(saveRegion.rejected, (state) => {
      state.isSavingRegion = false

      const duplicatedSynonyms = getDuplicatedSynonyms(
        state.regionEdited.synonyms
      )

      state.regionEdited.synonyms = state.regionEdited.synonyms.map(
        (synonym) => ({
          ...synonym,
          hasError:
            !isSynonymValid(synonym) || duplicatedSynonyms.includes(synonym),
        })
      )
    })

    builder.addCase(retrieveNodes.fulfilled, (state, action) => {
      state.nodes = processNodesResponse(action.payload)
    })
  },
})

/*
 * Actions
 */
export const {
  addForbiddenWord,
  removeForbiddenWord,
  resetRegion,
  addLocation,
  removeLocation,
  addIncompatibleWord,
  removeIncompatibleWord,
  addIncompatibleWordsRow,
  removeIncompatibleWordsRow,
  addPronounReplacer,
  removePronounReplacer,
  addSynonymRow,
  updateSynonymCell,
  deleteSynonymRow,
  addEmojiRow,
  deactivateEmojis,
  updateEmojiCell,
} = regionSlice.actions

/*
 * Selectors
 */
export const selectHasBeenEdited = (state: RootState): boolean =>
  !isEqual(selectOriginalRegion(state), state.regions.regionEdited)

export const selectOriginalRegion = (state: RootState): Region =>
  processRegionResponse(state.regions.rawRegion)

export default regionSlice.reducer
