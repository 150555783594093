import type { GroupBase, PlaceholderProps } from 'react-select'
import { components } from 'react-select'

import Autocomplete from 'common/components/autocomplete'

export type Option = {
  value: string
  label: string
}

type Props = {
  onSearchContentValue: (value: string) => void
  options: Option[] | undefined
  placeholder?: string
  'aria-label'?: string
  'data-testid'?: string
  className?: string
}

const Placeholder = ({
  children,
  ...props
}: PlaceholderProps<Option, false, GroupBase<Option>>) => (
  <components.Placeholder {...props} className="truncate">
    {children}
  </components.Placeholder>
)

const Searchbox = ({
  onSearchContentValue,
  options,
  placeholder = 'Find content',
  'aria-label': ariaLabel,
  'data-testid': dataTestId,
  className,
}: Props) => {
  return (
    <Autocomplete
      className={className}
      aria-label={ariaLabel}
      data-testid={dataTestId}
      placeholder={placeholder}
      options={options}
      onSearch={onSearchContentValue}
      backspaceRemovesValue={true}
      components={{ Placeholder }}
    />
  )
}

export default Searchbox
