import { FC, useMemo, useState } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useQuery } from '@tanstack/react-query'
import { union } from 'lodash'

import Avatar from 'common/components/Avatar'
import Button from 'common/components/button'
import FormError from 'common/components/formError'
import { Column } from 'common/components/table'
import BaseCell from 'common/components/table/cells/Base'
import { generateDeleteButtonColumn } from 'common/components/table/columns'
import TableWidget from 'common/components/widget/tableWidget'
import { userRolePerKey } from 'common/helpers/user'
import { useAppSelector } from 'common/hooks/redux'
import { ReactComponent as AddIcon } from 'common/icons/add/default.svg'
import { GetUserResponse, getUsers } from 'features/admin/users/all/api'
import { AvatarColor, avatarColors } from 'features/profile/AvatarColorPicker'
import { projectKeys } from 'features/projects/api/queryKeys'
import { getFieldError } from 'features/projects/helpers'

import AddUsersModal from '../addUsersModal'

export type User = {
  firstName: string
  lastName: string
  email: string
  role: string
  id: string
  avatarColor: AvatarColor
}

type Props = {
  isCreating: boolean
}

const AccessCard: FC<Props> = ({ isCreating }) => {
  const formState = useFormState()
  const { change: formChange } = useForm()
  const assignedUserIds = formState.values.projectAccess?.assignedUsers || []
  const unassignedUserIds =
    formState.values.projectAccess?.unassignedUsers || []
  const { accountId, user_id: userId } = useAppSelector(
    (state) => state.authStates
  )

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const { status, data } = useQuery(
    projectKeys.projectUsers(accountId),
    () => getUsers(accountId).then((res) => res.data),
    {
      select: (data: GetUserResponse[]): User[] =>
        data.map(({ _id, first_name, last_name, email, roles }, index) => ({
          id: _id,
          firstName: first_name ?? '',
          lastName: last_name ?? '-',
          email,
          role: roles[0],
          avatarColor: avatarColors[index % avatarColors.length],
        })),
      refetchOnWindowFocus: false,
    }
  )
  const allUsers = useMemo(() => data ?? [], [data])
  const defaultUsers = useMemo(() => {
    const superAdminIds = allUsers
      .filter(({ role }) => role === 'super-admin')
      .map(({ id }) => id)
    return union(superAdminIds, [userId])
  }, [allUsers, userId])
  const selectedUsers = allUsers.filter(({ id }) =>
    assignedUserIds.includes(id)
  )

  const unselectUser = (unselectedUserId: string): void => {
    formChange(
      'projectAccess.assignedUsers',
      assignedUserIds.filter((id) => id !== unselectedUserId)
    )
    formChange('projectAccess.unassignedUsers', [
      ...unassignedUserIds,
      unselectedUserId,
    ])
  }

  const applySelectedUsers = (users: User[]): void => {
    const updatedAssignedUserIds = users.map(({ id }) => id)

    formChange('projectAccess.assignedUsers', updatedAssignedUserIds)
    formChange(
      'projectAccess.unassignedUsers',
      unassignedUserIds.filter((id) => !updatedAssignedUserIds.includes(id))
    )
    setIsModalOpen(false)
  }

  const columns: Column<User>[] = [
    {
      Header: 'Name',
      accessor: 'firstName',
      Cell: ({ row }) => {
        const { firstName, lastName, avatarColor } = row.original

        return (
          <BaseCell className="flex items-center">
            <Avatar
              firstName={firstName}
              lastName={lastName}
              className="text-xs w-7 h-7 min-w-7 mr-3"
              color={avatarColor}
            />
            <span className="font-medium">{`${firstName} ${lastName}`}</span>
          </BaseCell>
        )
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }) => (
        <BaseCell className="flex items-center">{value}</BaseCell>
      ),
    },
    {
      Header: 'Role',
      accessor: 'role',
      Cell: ({ value }) => (
        <BaseCell className="flex items-center capitalize">
          {userRolePerKey[value]}
        </BaseCell>
      ),
    },
    generateDeleteButtonColumn<User>({
      onClick: (row) => unselectUser(row.id),
      buttonLabel: 'Unassign user from project',
      hasTooltip: true,
    }),
  ]

  return (
    <>
      <TableWidget.Widget
        data-cy="access-card"
        data-testid="access-card"
        className="mb-6"
        isLoading={status === 'loading'}
        hasError={status === 'error'}
        columns={columns}
        data={selectedUsers}
        hasWidgetMenu={false}
      >
        <TableWidget.Header
          title="Project access"
          subtitle="Add or remove existing users from this project."
        >
          <Button
            data-cy="add-users-button"
            data-testid="add-users-button"
            variant="primary"
            prefixIcon={<AddIcon width={24} height={24} />}
            onClick={() => setIsModalOpen(true)}
          >
            Users
          </Button>
        </TableWidget.Header>

        <Field<string[]>
          name="projectAccess.assignedUsers"
          initialValue={isCreating ? defaultUsers : undefined}
        >
          {({ meta }) => (
            <FormError className="ml-6">{getFieldError(meta)}</FormError>
          )}
        </Field>
      </TableWidget.Widget>

      <AddUsersModal
        isOpen={isModalOpen}
        onCancelClick={() => setIsModalOpen(false)}
        users={allUsers}
        assignedUsers={selectedUsers}
        isLoading={status === 'loading'}
        applySelectedUsers={applySelectedUsers}
      />
    </>
  )
}

export default AccessCard
