import { useCallback } from 'react'
import { Content } from '@phrasee/phrasee-typings'
import { useQueryClient } from '@tanstack/react-query'

import useWebsockets from 'common/hooks/useWebsockets'
import { contentKeys } from 'features/unifiedFlow/api/queryKeys'

import { getWsTopic } from './helpers'
import { WsMessage } from './types'

const useWebsocketMessage = () => {
  const queryClient = useQueryClient()

  const { sendJsonMessage } = useWebsockets()

  const sendWebsocketMessage = useCallback(
    (message: WsMessage) => {
      const { data, subscriptionAction } = message
      const wsTopic = getWsTopic(message)

      sendJsonMessage({
        action: subscriptionAction,
        topics: [wsTopic],
      })

      queryClient.setQueryData(
        contentKeys.content(data.contentId),
        (old: Content | undefined) => {
          if (old) {
            const ws_messages =
              subscriptionAction === 'subscribe'
                ? [
                    ...(old.ws_messages || []),
                    { wsTopic, timestamp: Date.now() },
                  ]
                : old.ws_messages
                ? [...old.ws_messages.filter((msg) => msg.wsTopic !== wsTopic)]
                : []

            return {
              ...old,
              ws_messages,
            }
          }

          return old
        }
      )
    },
    [queryClient, sendJsonMessage]
  )

  return {
    sendWebsocketMessage,
  }
}

export default useWebsocketMessage
