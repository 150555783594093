import { useMutation, useQueryClient } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'

import { CombinationVariantsResponse, Region, tweakVariant } from '../api'
import { personalizedContentKeys } from '../queryKeys'

const useTweakVariantMutation = ({
  accountId,
  personalizationId,
  combinationId,
  region,
}: {
  accountId: string
  personalizationId: string
  combinationId: string
  region: Region | undefined
}) => {
  const queryClient = useQueryClient()

  const combinationVariantsQueryKey =
    personalizedContentKeys.combinationVariants({
      accountId,
      personalizationId,
      combinationId,
      region,
    })

  return useMutation(tweakVariant, {
    onMutate: (variables) => {
      const { variantId } = variables
      if (variantId) {
        queryClient.setQueryData<CombinationVariantsResponse>(
          combinationVariantsQueryKey,
          (old) => {
            if (!old) {
              return old
            }
            return {
              ...old,
              personalized: old.personalized.map((variant) =>
                variant.variant_id === variantId
                  ? {
                      ...variant,
                      isLoading: true,
                    }
                  : variant
              ),
            }
          }
        )
      }
    },
    onSuccess: (_, variables) => {
      const { variantId, variantText } = variables

      if (variantId && variantText) {
        queryClient.cancelQueries(combinationVariantsQueryKey)

        queryClient.setQueryData<CombinationVariantsResponse>(
          combinationVariantsQueryKey,
          (old) => {
            if (!old) {
              return old
            }
            return {
              ...old,
              personalized: old.personalized.map((variant) =>
                variant.variant_id === variantId
                  ? {
                      ...variant,
                      text: variantText,
                      isRefreshed: true,
                    }
                  : variant
              ),
            }
          }
        )
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(combinationVariantsQueryKey)
    },
    onError: (error) => {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'Something went wrong while tweaking the variant'
      errorToast(errorMessage)
    },
  })
}

export default useTweakVariantMutation
