import { useCallback, useEffect, useState } from 'react'
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types'
import RouterPrompt from 'app/router/RouterPrompt'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { SubjectLine } from 'workflow/interface'
import {
  saveCampaign,
  updateContentGenerationData,
} from 'workflow/Workflow.actions'

import { createCancelTokenSource } from 'common/api'
import { experimentsPermissions } from 'common/auth/permissions'
import Loader from 'common/components/loaders/Loader'
import { errorToast } from 'common/components/toastNotification'
import Topics from 'common/components/topics'
import { Node, Replacer } from 'common/components/topics/interfaces'
import { resetCurrentState } from 'common/components/topics/store/topicsSlice'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import useWebsockets, { WsResponseBase } from 'common/hooks/useWebsockets'
import { toggleShowPageExitModal } from 'features/campaigns/store/campaignSlice'

import { getContentGenerationPayload, parseTopics } from './api/helpers'
import ControlVariant from './components/ControlVariant'
import GenerateActionBar from './components/GenerateActionBar'
import RefreshVariantsInfo from './contentApproval/RefreshVariantsInfo'
import {
  generationError,
  generationStarted,
  loadContentCards,
  revertTopics,
  updateCurrentTopics,
} from './store/contentGenerationSlice'
import { getVariants } from './api'
import ContentApproval from './contentApproval'

export const generateWsTopic = (campaignId: string) =>
  `generate-campaign/${campaignId}`

export interface WsResponseCampaignGeneration extends WsResponseBase {
  response?: {
    subjectLines: SubjectLine[]
    nodes: Node[]
    replacers: Replacer[]
  }
}

const ContentGeneration = () => {
  const {
    lastJsonMessage,
    sendJsonMessage,
    isConnectionReady,
  }: {
    lastJsonMessage: WsResponseCampaignGeneration
    sendJsonMessage: SendJsonMessage
    isConnectionReady: boolean
  } = useWebsockets()
  const fragments = useAppSelector((state) => state.contentGeneration.fragments)
  const descriptionFragments = useAppSelector(
    (state) => state.contentGeneration.descriptionFragments
  )

  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [isTopicListCollapsed, setIsTopicListCollapsed] = useState(false)

  const [shouldRefreshVariants, setShouldRefreshVariants] = useState(false)

  const [containerElement, setContainerElement] =
    useState<HTMLDivElement | null>(null)

  const dispatch = useAppDispatch()

  const isWaitingForCampaignLoad = useAppSelector(
    (state) =>
      state.campaignStates.isWaitingState.isWaiting &&
      state.campaignStates.isWaitingState.isWaitingFor === 'campaignsLoaded'
  )
  const topics = useAppSelector((state) => state.contentGeneration.topics)
  const currentTopics = useAppSelector(
    (state) => state.contentGeneration.currentTopics
  )

  const nlgStatus = useAppSelector((state) => state.contentGeneration.nlgStatus)

  const hasEditPermission = useAppSelector((state) =>
    state.authStates.permissions?.includes(experimentsPermissions.edit)
  )

  const isDisabled = useAppSelector(
    (state) =>
      state.campaignStates.campaignData?.steps?.[1]?.disabled ||
      !hasEditPermission
  )

  const humanLine = useAppSelector(
    (state) => state.campaignStates.campaignData.own_subject_line
  )
  const description = useAppSelector(
    (state) => state.campaignStates.campaignData.description
  )
  const numSplits = useAppSelector(
    (state) => state.campaignStates.campaignData.num_splits
  )
  const campaignId = useAppSelector(
    (state) => state.campaignStates.currentCampaignId
  )
  const projectId = useAppSelector(
    (state) => state.campaignStates.campaignData.project_id
  )
  const subjectLines = useAppSelector(
    (state) => state.campaignStates.subjectLines
  )
  const showExitModal = useAppSelector(
    (state) => state.campaigns.shouldShowPageExitModal
  )

  const wsTopic = generateWsTopic(campaignId)

  const revertTopicChanges = () => {
    setShouldRefreshVariants(false)
    dispatch(revertTopics())
  }

  const generateVariants = async () => {
    dispatch(generationStarted())
    setIsTopicListCollapsed(true)
    try {
      await getVariants({
        campaignId,
        topics: currentTopics,
        fragments,
        descriptionFragments,
        numSplits,
        wsTopics: [wsTopic],
      })
    } catch {
      errorToast('Something went wrong. Please try again.')
      setIsTopicListCollapsed(false)
      dispatch(generationError())
    }
  }

  const saveTopics = async () => {
    try {
      setIsSaving(true)
      const contentGenerationPayload = getContentGenerationPayload({
        topics: currentTopics,
        descriptionFragments,
        fragments,
        numSplits,
      })

      const data = {
        step: 'content_generation',
        save_and_continue: false,
        save_and_exit: true,
        data: {
          campaign_id: campaignId,
          project_id: projectId,
          ...contentGenerationPayload,
        },
      }
      return await dispatch(saveCampaign(data))
    } finally {
      setIsSaving(false)
    }
  }

  const containerRefCallback = useCallback((node) => {
    if (node !== null) {
      setContainerElement(node)
    }
  }, [])

  useEffect(() => {
    const source = createCancelTokenSource()

    const getContentCardsAsync = async () => {
      setIsLoading(true)
      await dispatch(
        loadContentCards({
          campaignId,
          humanLine,
          cancelToken: source.token,
        })
      )
      setIsLoading(false)
    }

    if (
      !isWaitingForCampaignLoad &&
      fragments.length === 0 &&
      topics.length === 0
    ) {
      getContentCardsAsync()
    }

    return () => source.cancel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWaitingForCampaignLoad])

  useEffect(() => {
    if (!isEqual(currentTopics, topics) && subjectLines.length > 1) {
      setShouldRefreshVariants(true)
    }
  }, [currentTopics, topics, dispatch, subjectLines.length])

  useEffect(() => {
    if (!isEqual(currentTopics, topics) && subjectLines.length === 1) {
      dispatch(toggleShowPageExitModal(true))
    } else {
      dispatch(toggleShowPageExitModal(false))
    }
  }, [currentTopics, dispatch, subjectLines.length, topics])

  const hasGeneratedSubjectLines = subjectLines.length > 1
  const isApprovalMode = hasGeneratedSubjectLines && !shouldRefreshVariants
  const areTopicsValid = currentTopics.every((topic) => {
    return !isEmpty(topic.name)
  })
  const isMagicButtonDisabled = isLoading || !areTopicsValid || isDisabled

  useEffect(() => {
    if (isApprovalMode) {
      setIsTopicListCollapsed(true)
    }
  }, [isApprovalMode])

  useEffect(() => {
    return () => {
      dispatch(resetCurrentState())
    }
  }, [dispatch])

  useEffect(() => {
    if (lastJsonMessage && lastJsonMessage.topics?.includes(wsTopic)) {
      if (lastJsonMessage.status === 'success' && lastJsonMessage.response) {
        const newTopics = parseTopics(
          lastJsonMessage.response.nodes,
          lastJsonMessage.response.replacers
        )
        dispatch(
          updateContentGenerationData(
            lastJsonMessage.response.subjectLines,
            newTopics
          )
        )
        setShouldRefreshVariants(false)
      } else if (lastJsonMessage.status === 'error') {
        errorToast(lastJsonMessage.errorMessage)
        setIsTopicListCollapsed(false)
        dispatch(generationError())
      }

      sendJsonMessage({
        action: 'unsubscribe',
        topics: [wsTopic],
      })
    }
    // Important the dep array should only include lastJsonMessage
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage])

  useEffect(() => {
    if (isConnectionReady && nlgStatus === 'pending') {
      sendJsonMessage({
        action: 'subscribe',
        topics: [wsTopic],
      })
    }
  }, [isConnectionReady, nlgStatus, sendJsonMessage, wsTopic])

  return (
    <div className="w-full h-full flex flex-col" ref={containerRefCallback}>
      <RouterPrompt
        shouldShow={showExitModal}
        isSaving={isSaving}
        onOK={() => saveTopics()}
        onCancel={() => {
          toggleShowPageExitModal(false)
          dispatch(resetCurrentState())
          return true
        }}
      />
      <div className="p-8 pb-24 flex flex-col overflow-y-auto flex-1-0">
        <div className="font-medium text-xl text-gray-800">
          Content generation
        </div>
        <div className="font-normal text-sm text-gray-500 mt-1">
          Select topics and configure some specifics to generate content for
          your experiment.
        </div>
        {isLoading || isWaitingForCampaignLoad ? (
          <div className="h-88">
            <Loader />
          </div>
        ) : (
          <>
            <div className="mt-8 border border-coolGray-300">
              <ControlVariant
                controlText={humanLine}
                description={description}
                fragments={fragments}
                isAdvancedSettingsShown={
                  (isApprovalMode || shouldRefreshVariants) &&
                  isTopicListCollapsed
                }
                onAdvancedSettingsClick={() => setIsTopicListCollapsed(false)}
              />
              <Topics
                className="bg-gray-50"
                topics={currentTopics}
                isDisabled={isDisabled}
                isCollapsed={isTopicListCollapsed}
                isCollapsible={true}
                fragmentsCount={fragments.length}
                onChange={(newTopics) => {
                  dispatch(updateCurrentTopics(newTopics))
                }}
                onCollapseClick={() =>
                  nlgStatus !== 'pending'
                    ? setIsTopicListCollapsed((prev) => !prev)
                    : undefined
                }
              />
            </div>
            {isApprovalMode && containerElement && (
              <ContentApproval containerElement={containerElement} />
            )}
            {shouldRefreshVariants && nlgStatus !== 'pending' && (
              <RefreshVariantsInfo onBackClick={revertTopicChanges} />
            )}
          </>
        )}
      </div>
      {!isApprovalMode && (
        <GenerateActionBar
          isDisabled={isMagicButtonDisabled}
          isLoading={nlgStatus === 'pending'}
          isSaving={isSaving}
          isSaveButtonShown={!hasGeneratedSubjectLines}
          onMagicButtonClick={generateVariants}
          onSaveButtonClick={saveTopics}
          actionType={shouldRefreshVariants ? 'refresh' : 'generate'}
        />
      )}
    </div>
  )
}

export default ContentGeneration
