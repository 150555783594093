import cx from 'classnames'

type ToggleProps = {
  name?: string
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
  className?: string
}

const Toggle = ({
  name,
  label,
  checked,
  onChange,
  disabled = false,
  className,
}: ToggleProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked)
  }

  return (
    <label
      className={cx(
        'inline-flex relative items-center gap-2.5',
        disabled ? 'cursor-not-allowed' : 'cursor-pointer',
        className
      )}
    >
      <div className="relative">
        <div
          className={cx(
            'w-9 h-6 rounded-full transition-colors duration-200 ease-in-out p-1',
            checked ? 'bg-gold-700' : 'bg-gold-300 hover:bg-gold-400',
            disabled && 'opacity-40'
          )}
        >
          <div
            className={cx(
              'absolute top-1 left-1 bg-gold-40 w-4 h-4 rounded-full shadow transition-transform duration-200 ease-in-out',
              checked ? 'transform translate-x-4' : 'translate-x-0'
            )}
          />
        </div>
      </div>
      <span className={cx('text-sm font-normal text-base-700')}>{label}</span>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        disabled={disabled}
        className="sr-only"
        onChange={handleChange}
      />
    </label>
  )
}

export default Toggle
