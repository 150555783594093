import { useState } from 'react'
import { useField, useForm, useFormState } from 'react-final-form'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  CAMPAIGN_NAME_LIMIT,
  SFMC_CAMPAIGN_NAME_LIMIT,
} from 'workflow/utils/helpers'

import { TextareaType } from 'common/components/dynamicFields/interfaces'
import Loader from 'common/components/loaders/Loader'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { isSFMC } from 'features/campaigns/components/details/helpers'
import useProjectsQuery from 'features/campaigns/queries/useProjectsQuery'
import useGetSplitCalculatorConfigurationQuery from 'features/unifiedFlow/api/queries/useGetSplitCalculatorConfigurationQuery'
import {
  selectTemplatesToAddWithOptimization,
  updateTemplateSplitConfiguration,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

import { useExperimentForm } from '../context/ExperimentFormContext'
import {
  getExperimentPrefixedName,
  getInitialExperimentNameValue,
  getPrefixedName,
  getSelectedProjectId,
} from '../helpers'
import SplitCalculator from '../splitCalculator'

import { useGetVariantDropdownOptions } from './hooks/useGetVariantDropdownOptions'
import { useSplitNumberLimitOnImageOptimization } from './hooks/useSplitNumberLimitOnImageOptimization'
import ControlSubjectLine from './ControlSubjectLine'
import ExperimentName from './ExperimentName'
import IncludeImagesToggle from './IncludeImagesToggle'
import Project from './Project'
import SendDate from './SendDate'

import './DatePickerStyles.css'

const SetupOptimisation = () => {
  const [isDatePickerFocused, setIsDatePickerFocused] = useState<boolean>(false)
  const [isSFMCProject, setIsSFMCProject] = useState<boolean>(false)
  const flags = useFlags()

  const {
    state: { template },
  } = useExperimentForm()

  const form = useForm()
  const formState = useFormState()
  const dispatch = useAppDispatch()
  const { data: projects } = useProjectsQuery()

  const templatesToAddWithOptimization = useAppSelector(
    selectTemplatesToAddWithOptimization
  )

  const splitCalculationResult = templatesToAddWithOptimization.find(
    (templ) => templ.internalId === template?.internalId
  )?.splitConfiguration?.splitCalculationResult

  const selectedProjectId =
    template && getSelectedProjectId(formState, template)

  const {
    input: { value: formImageOptimizationEnabled },
  } = useField(getPrefixedName('imageOptimization', template))

  const { data: projectSplitCalculatorConfiguration, isLoading } =
    useGetSplitCalculatorConfigurationQuery({
      projectId: selectedProjectId,
      isImageOptimizationEnabled:
        Boolean(formImageOptimizationEnabled) && flags.showImageExperimentSetup,
    })

  const controlSubjectLineConfig =
    projectSplitCalculatorConfiguration?.experimentMainFields?.find(
      (field) => field.name === 'own_subject_line'
    )

  const dateConfig =
    projectSplitCalculatorConfiguration?.experimentMainFields?.find(
      (field) => field.name === 'iso_send_date'
    )

  const splitCalculatorConfiguration =
    projectSplitCalculatorConfiguration?.splitCalculation
  const integrationType = projectSplitCalculatorConfiguration?.integrationType

  const templateImageOptimizationEnabled =
    flags.showImageExperimentSetup &&
    template?.optimization?.images === 'enabled'

  const {
    defaultVariantDropdownOption,
    variantDropdownOptions,
    initialVariantDropdownValue,
  } = useGetVariantDropdownOptions({
    experimentMainFields:
      projectSplitCalculatorConfiguration?.experimentMainFields,
  })

  useSplitNumberLimitOnImageOptimization({
    template,
    formImageOptimizationEnabled,
    variantDropdownOptions,
    defaultVariantDropdownOption,
  })

  const resetCalculatorFields = () => {
    const registeredFields = form.getRegisteredFields()
    const fieldNames = [
      'splitNumber',
      'openRate',
      'clickRate',
      'selectionMetric',
      'listSize',
      'imageOptimization',
    ]

    fieldNames.forEach((fieldName) => {
      const prefixedName = getPrefixedName(fieldName, template)
      if (registeredFields.includes(prefixedName)) {
        form.change(prefixedName, undefined)
      }
    })

    dispatch(
      updateTemplateSplitConfiguration({
        internalId: template?.internalId,
        splitConfiguration: undefined,
      })
    )
  }

  if (!template) {
    return null
  }

  const getState = () => {
    if (!selectedProjectId) {
      return 'noData'
    } else if (isLoading && selectedProjectId) {
      return 'loading'
    } else {
      return 'data'
    }
  }

  const state = getState()

  return (
    <div className="SetUpExperiment flex flex-col w-full">
      <ExperimentName
        name={getExperimentPrefixedName(template, 'experimentName')}
        maxCharacters={
          isSFMCProject ? SFMC_CAMPAIGN_NAME_LIMIT : CAMPAIGN_NAME_LIMIT
        }
        initialValue={getInitialExperimentNameValue({
          templatesToAdd: templatesToAddWithOptimization,
          template,
          formState,
        })}
      />
      <div className="grid grid-cols-3 gap-4">
        <Project
          name={getExperimentPrefixedName(template, 'project')}
          mode="create"
          optimizationContentType={template?.optimization?.contentType}
          data-testid="project-select"
          onChange={(projectOption) => {
            resetCalculatorFields()
            setIsDatePickerFocused(true)
            form.mutators.setError(
              getExperimentPrefixedName(template, 'experimentName'),
              undefined
            )
            const project = projects?.find(
              (project) => project._id === projectOption?.value
            )
            if (project) {
              const isSFMCprojectSelected = isSFMC(
                project.project_configuration.campaign_configurations
                  ?.integration_options?.type || ''
              )
              setIsSFMCProject(isSFMCprojectSelected)
            }
          }}
        />
        {dateConfig && (
          <SendDate
            name={getExperimentPrefixedName(template, 'sendDate')}
            config={dateConfig}
            isFocused={isDatePickerFocused}
            data-testid="send-date"
            onChange={() => setIsDatePickerFocused(false)}
          />
        )}
      </div>
      {
        {
          noData: null,
          loading: <Loader />,
          data: (
            <>
              {controlSubjectLineConfig && (
                <ControlSubjectLine
                  name={getExperimentPrefixedName(
                    template,
                    'controlSubjectLine'
                  )}
                  config={controlSubjectLineConfig as TextareaType}
                  integrationType={integrationType}
                />
              )}
              {splitCalculatorConfiguration && (
                <SplitCalculator
                  key={`${template?.internalId}-${selectedProjectId}`}
                  template={template}
                  variantDropdownConfiguration={{
                    initialValue: initialVariantDropdownValue,
                    options: variantDropdownOptions,
                  }}
                  configuration={splitCalculatorConfiguration}
                  splitCalculationResult={splitCalculationResult}
                />
              )}
              {templateImageOptimizationEnabled && (
                <IncludeImagesToggle template={template} />
              )}
            </>
          ),
        }[state]
      }
    </div>
  )
}

export default SetupOptimisation
