import Label from 'common/components/label'
import SingleSelect from 'common/components/singleSelect'

interface Props {
  value: string
  onChange: (val: string) => void
  isDisabled: boolean
}
const frequencies = [
  { name: 'Daily', value: 'days' },
  { name: 'Weekly', value: 'weeks' },
  { name: 'Monthly', value: 'months' },
]

export const FrequencySelector = ({ value, onChange, isDisabled }: Props) => {
  return (
    <div className="my-6">
      <Label
        className="mb-2"
        children="How frequently will this message be sent?"
      />
      <SingleSelect
        data-testid="Select"
        className="w-52"
        placeholder="Select a frequency"
        isDisabled={isDisabled}
        value={value}
        onChange={(val) => val?.value && onChange(val.value)}
        options={frequencies.map((frequency) => ({
          label: frequency.name,
          value: frequency.value,
        }))}
      />
    </div>
  )
}
