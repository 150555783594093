export const bytesToKilobytes = (bytes: number) => {
  return (bytes / 1024).toFixed()
}

export const getFileExtensionFromMimeType = (mimeType: string) => {
  if (!mimeType.includes('/')) {
    throw new Error('Invalid content type')
  }
  return mimeType.split('/').pop()
}
