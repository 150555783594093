import Lottie from 'react-lottie-player'
import cx from 'classnames'

import animationData from './page-loader-animation.json'

type Props = {
  isGrayscale?: boolean
  size?: 'small' | 'medium'
}

const LottieLoader = ({ size = 'medium', isGrayscale }: Props) => {
  const animation = {
    loop: true,
    play: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <div
      className={cx('text-base-700', {
        'max-w-22': size === 'medium',
        'max-w-10': size === 'small',
      })}
    >
      <Lottie
        {...animation}
        style={{ filter: isGrayscale ? 'brightness(0) invert(0)' : 'none' }}
      />
    </div>
  )
}

export default LottieLoader
