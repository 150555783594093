import { useMutation, useQueryClient } from '@tanstack/react-query'

import { showErrorToast } from 'common/api/helpers'
import { successToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'

import { createMergeTag } from '../api'
import { mergeTagsKeys } from '../queryKeys'

const useCreateMergeTagMutation = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createMergeTag,
    onSuccess: () => {
      successToast('Merge tag has been successfully added')
      queryClient.invalidateQueries({
        queryKey: mergeTagsKeys.list(accountId),
      })
    },
    onError: (error) => {
      showErrorToast(error)
    },
  })
}

export default useCreateMergeTagMutation
