import cx from 'classnames'
import cond from 'lodash/cond'
import stubTrue from 'lodash/stubTrue'

import Button from 'common/components/button'
import { useAppSelector } from 'common/hooks/redux'
import {
  ArrowRight as ArrowRightIcon,
  Check as CheckIcon,
  MissingData as MissingDataIcon,
} from 'common/icons'

import { useExperimentForm } from '../context/ExperimentFormContext'

import { TemplateInfoWithInternalId } from './AddedComponentList'

type SetupButtonProps = {
  template: TemplateInfoWithInternalId
  selectedTab: 'personalize' | 'optimize'
}
const SetupButton = ({ template }: SetupButtonProps) => {
  const { dispatch } = useExperimentForm()

  const templatesToAdd = useAppSelector(
    (state) => state.unifiedFlow.templatesToAdd
  )

  const currentTemplate = templatesToAdd.find(
    (item) => item.internalId === template.internalId
  )

  const isSetupCompleted = currentTemplate?.status === 'complete'
  const isSetupInCompleted = currentTemplate?.status === 'incomplete'
  const isSetupNotStarted = currentTemplate?.status === 'pristine'

  const getContent = cond([
    [
      () => isSetupCompleted,
      () => (
        <div
          className="flex items-center w-full"
          data-testid="configuration-complete"
        >
          <CheckIcon isDefaultColor={false} size={4} />
          <span className="text-base font-normal ml-2">Completed</span>
          <span className="uppercase ml-auto">Setup</span>
        </div>
      ),
    ],
    [
      () => isSetupInCompleted,
      () => (
        <div
          className="flex items-center w-full"
          data-testid="configuration-incomplete"
        >
          <MissingDataIcon isDefaultColor={false} size={4} />
          <span className="text-base font-normal ml-2">Incomplete</span>
          <span className="uppercase ml-auto">Setup</span>
        </div>
      ),
    ],
    [
      () => isSetupNotStarted,
      () => (
        <span className="uppercase" data-testid="configuration-setup">
          Setup
        </span>
      ),
    ],
    [stubTrue, () => null],
  ])

  const content = getContent()

  return (
    <Button
      data-testid="configure-template"
      className={cx(
        'w-full flex justify-between bg-transparent border hover:text-maroon-400 bezier-all-transition',
        {
          'border-maroon-400 text-base-700': isSetupNotStarted,
          'border-RAGGreen text-RAGGreen': isSetupCompleted,
          'border-RAGamber text-RAGamber': isSetupInCompleted,
        }
      )}
      onClick={() => {
        dispatch({
          type: 'set_experiment_form_template',
          value: template,
        })
      }}
      suffixIcon={
        <ArrowRightIcon isDefaultColor={false} size={4} className="ml-auto" />
      }
    >
      {content}
    </Button>
  )
}

export default SetupButton
