import { Field, Form } from 'react-final-form'

import Button from 'common/components/button'
import FormItem from 'common/components/formItem/FormItem'
import Input from 'common/components/input/Input'
import Loader from 'common/components/loaders/Loader'
import Modal from 'common/components/Modal'
import Textarea from 'common/components/textarea/Textarea'

type Props = {
  isOpen: boolean
  onCancel: () => void
  onCreateCustomerJourney: (values: {
    customerJourneyName: string
    contextDescription: string
  }) => void
  isLoading: boolean
}

const validateNotEmptyString = (value: string | undefined) =>
  value?.trim() === undefined || value.trim().length === 0
    ? 'Enter a non-empty string'
    : undefined

const CreateCustomerJourney = ({
  isOpen,
  onCancel,
  onCreateCustomerJourney,
  isLoading,
}: Props) => {
  const handleAddCustomerJourney = (values: {
    customerJourneyName: string
    contextDescription: string
  }) => {
    onCreateCustomerJourney(values)
  }

  return (
    <Form<{
      customerJourneyName: string
      contextDescription: string
    }>
      onSubmit={handleAddCustomerJourney}
      render={({ handleSubmit, hasValidationErrors, pristine }) => {
        return (
          <Modal
            visible={isOpen}
            centered
            onClose={() => {
              onCancel()
            }}
            isResponsive
            className="max-w-150"
          >
            <Loader isLoading={isLoading}>
              <form onSubmit={handleSubmit} className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                  <h4 className="font-relais text-2xl font-medium">
                    Create customer journey
                  </h4>
                  <p>
                    Creating a new customer journey will be added to your
                    options once added.
                  </p>
                </div>
                <div className="flex flex-col gap-2">
                  <Field<string | undefined>
                    name="customerJourneyName"
                    validate={validateNotEmptyString}
                    render={({ input, meta }) => (
                      <FormItem
                        label="Customer journey name"
                        htmlFor="customerJourneyName"
                        guidingText="Refer to a common use case in context to your business need."
                        error={meta.touched ? meta.error : undefined}
                      >
                        <Input
                          type="text"
                          id="customerJourneyName"
                          placeholder="Create a name for the new customer journey..."
                          variant="default"
                          className="text-coolGray-800"
                          value={input.value}
                          name={input.name}
                          onBlur={input.onBlur}
                          onChange={input.onChange}
                          maxLength={128}
                          required
                        />
                      </FormItem>
                    )}
                  />
                  <Field<string | undefined>
                    name="contextDescription"
                    validate={validateNotEmptyString}
                    render={({ input, meta }) => (
                      <FormItem
                        htmlFor="contextDescription"
                        label="Context description"
                        guidingText="We will use this for future campaign generation."
                        error={meta.touched ? meta.error : undefined}
                      >
                        <Textarea
                          maxLength={250}
                          name="contextDescription"
                          placeholder="Describe the new customer journey..."
                          showCharacterCount
                          value={input.value}
                          onBlur={input.onBlur}
                          onChange={input.onChange}
                        />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex justify-end gap-4">
                  <Button
                    data-cy="cancel-button"
                    data-testid="cancel-button"
                    variant="link"
                    onClick={() => {
                      onCancel()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={hasValidationErrors || pristine}
                  >
                    Add
                  </Button>
                </div>
              </form>
            </Loader>
          </Modal>
        )
      }}
    />
  )
}

export default CreateCustomerJourney
