import {
  ListAccountsRequest,
  ListAccountsResponse,
} from '@phrasee/phrasee-typings'

import { connectInternalApi } from 'common/api'

export type IntegrationProvider = {
  id: string
  name: string
  category: string
  logoUrl?: string
}

export const listAccounts = async ({
  accountId,
  provider,
}: ListAccountsRequest): Promise<ListAccountsResponse> => {
  const result = await connectInternalApi.get<ListAccountsResponse>(
    'v1/content-integrations/accounts',
    {
      params: {
        accountId,
        provider,
      },
    }
  )

  return result.data
}
