import { useState } from 'react'
import Select, {
  components,
  GroupBase,
  InputProps,
  OptionProps,
} from 'react-select'

import styles from './Autocomplete.styles'
import { Option } from './Tags'

type Props<T> = {
  options: Option<T>[]
  onBlur: (inputValue: string) => void
  onInputChange: (value: string) => string
  placeholder?: string
  isFreeText: boolean
  'data-cy'?: string
  'data-testid'?: string
  isInsideTable?: boolean
  menuPortalTarget?: HTMLElement
  onKeyDown?: (event: React.KeyboardEvent) => void
}

const Autocomplete = <T extends string>({
  options,
  onBlur,
  onInputChange,
  placeholder,
  isFreeText,
  'data-cy': dataCy,
  'data-testid': dataTestId,
  isInsideTable,
  menuPortalTarget,
  onKeyDown,
}: Props<T>) => {
  const [inputValue, setInputValue] = useState('')
  return (
    <Select
      data-cy={dataCy}
      data-testid={dataTestId}
      onChange={(newValue) => {
        if (newValue !== null) {
          setInputValue(newValue.label)
        }
      }}
      autoFocus
      backspaceRemovesValue
      styles={styles}
      placeholder={placeholder}
      inputValue={inputValue}
      hideSelectedOptions={false}
      defaultMenuIsOpen={!isFreeText}
      blurInputOnSelect={true}
      noOptionsMessage={() => null}
      onBlur={(e) => {
        e.preventDefault()
        if (inputValue) {
          onBlur(inputValue)
          setInputValue('')
        } else {
          onBlur('')
        }
      }}
      options={options}
      components={{
        Option: CustomOption,
        Input: CustomInput,
        Placeholder: () => null, // use our own placeholder from CustomInput
        SingleValue: () => null,
      }}
      onInputChange={(newValue, actionMeta) => {
        if (actionMeta.action === 'input-change') {
          setInputValue(onInputChange(newValue))
        }
      }}
      filterOption={(option, inputValue) => {
        if (inputValue) {
          return isValueMatchingOption({
            inputValue,
            optionLabel: option.label,
          })
        }
        return true
      }}
      isSearchable={true}
      menuPortalTarget={isInsideTable ? document.body : menuPortalTarget}
      menuShouldBlockScroll={isInsideTable}
      onKeyDown={onKeyDown}
    />
  )
}

export default Autocomplete

const CustomInput = <T,>({
  children,
  selectOption,
  hasValue,
  selectProps,
  ...rest
}: InputProps<T, false, GroupBase<T>>) => {
  const hasAnOptionSuggested = rest.options.some((option) =>
    isValueMatchingOption({
      inputValue: (rest.value as string) ?? '',
      optionLabel: (option as GroupBase<T>).label ?? '',
    })
  )
  return (
    // TODO try to use <components.Input instead in another PR
    <input
      {...rest}
      data-testid="add-tag-input"
      aria-label="add tag"
      type="text"
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (!hasAnOptionSuggested || hasValue) {
            e.currentTarget.blur()
          }
        }
      }}
      placeholder={selectProps.placeholder as Props<T>['placeholder']}
      autoFocus
      className="caret-maroon-400 outline-none placeholder-coolGray-300 bg-transparent text-maroon-500 font-medium"
    />
  )
}

const CustomOption = <T,>({
  isSelected,
  children,
  ...rest
}: OptionProps<T, false, GroupBase<T>>) => {
  return (
    <components.Option isSelected={isSelected} {...rest}>
      <div
        role="option"
        aria-selected={isSelected}
        className={`px-3 py-2 flex justify-between items-center hover:bg-gray-100 
        text-coolGray-800 font-medium cursor-pointer`}
      >
        {children}
      </div>
    </components.Option>
  )
}

const isValueMatchingOption = ({
  inputValue,
  optionLabel,
}: {
  inputValue: string
  optionLabel: string
}) =>
  optionLabel
    .replaceAll(' ', '')
    .toLowerCase()
    .includes(inputValue.replaceAll(' ', '').toLowerCase())
