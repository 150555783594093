import { InputNumber } from 'antd'

import GuidingText from 'common/components/guidingText'

import { InputElement } from '../../interface'

import FormItem from './FormItem'
import { FormInstance } from './interfaces'

type Props = {
  field: InputElement
  form: FormInstance
  disabled?: boolean
  onChange: (value: string) => void
  className?: string
}

const AudienceSize: React.FC<Props> = ({
  field,
  form,
  disabled = false,
  className,
  onChange,
}) => {
  const { getFieldDecorator } = form

  return (
    <FormItem field={field} form={form} className={className}>
      <div>
        {getFieldDecorator(field.property_name, {
          rules: field.rules,
        })(
          <InputNumber
            data-cy="campaign-setup-list-size"
            data-testid="campaign-setup-list-size"
            id={field.field_id}
            style={{ width: 170 }}
            min={0}
            disabled={disabled}
            placeholder={field.placeholder}
            precision={0}
            formatter={(value) =>
              `${value}`
                .replace(/[^\d]/g, '')
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) =>
              value?.replace(/[^\d]/g, '').replace(/\$\s?|(,*)/g, '') ?? ''
            }
            onChange={(value) => {
              onChange(
                value !== undefined && value !== null ? value.toString() : ''
              )
            }}
          />
        )}
        {field.tooltip && <GuidingText text={field.tooltip} />}
      </div>
    </FormItem>
  )
}

export default AudienceSize
