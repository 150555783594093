import { useEffect, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'

import Button from 'common/components/button/Button'
import ConfirmationModal from 'common/components/confirmationModal/ConfirmationModal'
import Drawer from 'common/components/drawer-old/Drawer'
import FormItem from 'common/components/formItem'
import Input from 'common/components/input'
import MultiSelect, { MultiSelectValue } from 'common/components/MultiSelect'
import SingleSelect, {
  SelectValue,
  SingleValue,
} from 'common/components/singleSelect'
import { errorToast, successToast } from 'common/components/toastNotification'
import { useAppDispatch } from 'common/hooks/redux'
import { callbackOAuth } from 'features/auth/store/authSlice'
import { setDashboardMode as setDashboardModeGlobal } from 'features/auth/store/authSlice'

import { Account, DashboardMode } from '../../Accounts'
import api, { PrivacyRegions } from '../../api'
import LogoUploader from '../logoUploader'

export type AccountsDrawerProps = {
  mode: 'edit' | 'create'
  isOpen: boolean
  account?: Account
  onClose: (shouldRefetch?: boolean) => void
}

const options: SelectValue[] = [
  { value: 'Education', label: 'Education' },
  {
    value: 'Business & Marketing Services',
    label: 'Business & Marketing Services',
  },
  { value: 'Automotive', label: 'Automotive' },
  {
    value: 'Computers, Technology & SaaS',
    label: 'Computers, Technology & SaaS',
  },
  {
    value: 'Non-Profit, Fund Raising & Politics',
    label: 'Non-Profit, Fund Raising & Politics',
  },
  {
    value: 'Ecommerce Site/Retail Store',
    label: 'Ecommerce Site/Retail Store',
  },
  { value: 'Financial Services', label: 'Financial Services' },
  { value: 'Healthcare', label: 'Healthcare' },
  {
    value: 'Media, Publishing & Paid Content',
    label: 'Media, Publishing & Paid Content',
  },
  { value: 'Travel & Hospitality', label: 'Travel & Hospitality' },
  { value: 'Other', label: 'Other' },
]

const accountCategories: SelectValue[] = [
  {
    value: 'Starter',
    label: 'Starter',
  },
  {
    value: 'Pro',
    label: 'Pro',
  },
  {
    value: 'Enterprise',
    label: 'Enterprise',
  },
]

const dashboardModes: SelectValue[] = [
  {
    value: 'Average_uplift',
    label: 'Average uplift',
  },
  {
    value: 'Incremental',
    label: 'Incremental',
  },
]

const privacyRegionsOptions: MultiSelectValue[] = [
  {
    id: 'US',
    value: 'US',
    label: 'US',
  },
  {
    id: 'EU',
    value: 'EU',
    label: 'EU',
  },
]

const urlToFileObject = async (imageUrl: string): Promise<File | undefined> => {
  try {
    const response = await fetch(imageUrl, {
      headers: { 'Cache-Control': 'no-cache' },
    })

    const blob = await response.blob()
    const file = new File([blob], 'image.jpg', { type: blob.type })

    return file
  } catch (error) {
    errorToast('Something went wrong. Please try again.')
    return
  }
}

const AccountsDrawer = ({
  isOpen,
  mode,
  account,
  onClose,
}: AccountsDrawerProps) => {
  const [hasError, setHasError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [logo, setLogo] = useState<File | undefined>()
  const [accountName, setAccountName] = useState('')
  const [industry, setIndustry] = useState<string | undefined>()
  const [accountCategory, setAccountCategory] = useState<string | undefined>()
  const [dashboardMode, setDashboardMode] =
    useState<DashboardMode | undefined>()
  const [privacyRegions, setPrivacyRegions] = useState<PrivacyRegions>([])
  const [selectedAccountCategory, setSelectedAccountCategory] =
    useState<string | undefined>()
  const [isChangeCategoryModalShown, setIsChangeCategoryModalShown] =
    useState<boolean>(false)

  const flags = useFlags()
  const dispatch = useAppDispatch()

  const isEditMode = mode === 'edit'
  const title = isEditMode ? 'Edit Account' : 'Create Account'
  const areAllFieldsFilled = !!(
    accountName &&
    industry &&
    accountCategory &&
    dashboardMode &&
    privacyRegions?.length > 0 &&
    (flags.showAccountLogo ? logo : true)
  )

  const onCloseOrCancel = (shouldRefetch?: boolean) => {
    setAccountName('')
    setLogo(undefined)
    setIndustry(undefined)
    setAccountCategory(undefined)
    setDashboardMode(undefined)
    setPrivacyRegions([])
    setHasError(false)
    onClose(shouldRefetch)
  }

  const createOrUpdateAccount = async (accountId?: string) => {
    if (
      accountName &&
      industry &&
      accountCategory &&
      dashboardMode &&
      privacyRegions?.length > 0
    ) {
      try {
        setIsLoading(true)
        setHasError(false)

        if (logo) {
          const params = {
            accountName,
            industry,
            accountId,
            accountCategory,
            dashboardMode,
            privacyRegions,
            logo,
          }

          const result = await api.createOrUpdateAccountWithLogo(params)
          handleSuccess(result)
        } else {
          const params = {
            accountName,
            industry,
            accountId,
            accountCategory,
            dashboardMode,
            privacyRegions,
          }

          const result = await api.createOrUpdateAccountWithoutLogo(params)
          handleSuccess(result)
        }
      } catch {
        setHasError(true)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleSuccess = (result) => {
    successToast(
      `Account ${result.companyName} ${isEditMode ? 'updated' : 'created'}`
    )
    dispatch(callbackOAuth())
    onCloseOrCancel(true)
  }

  const handleCategoryChange = (val: SingleValue<SelectValue>) => {
    if (isEditMode) {
      setSelectedAccountCategory(val?.value)
      setIsChangeCategoryModalShown(true)
    } else {
      setAccountCategory(val?.value)
    }
  }

  useEffect(() => {
    if (isEditMode && account && isOpen) {
      const loadFields = async () => {
        setAccountName(account.companyName)
        setIndustry(account.industry)
        setAccountCategory(account.accountCategory)
        setDashboardMode(account.dashboardMode)
        setPrivacyRegions(account.privacyRegions ?? [])

        if (flags.showAccountLogo) {
          const logoObject = await urlToFileObject(account.logoUrl!)
          setLogo(logoObject)
        }
      }
      loadFields()
    }
  }, [account, isEditMode, isOpen, flags.showAccountLogo])

  const content = (
    <>
      {flags.showAccountLogo && (
        <div className="my-8 h-40">
          <LogoUploader
            logo={logo}
            onClear={() => setLogo(undefined)}
            onDrop={(file) => setLogo(file)}
          />
        </div>
      )}

      <FormItem label="Account name">
        <Input
          aria-label="Account name"
          type="text"
          variant="default"
          value={accountName}
          onChange={(event) => setAccountName(event.target.value)}
        />
      </FormItem>
      <FormItem label="Industry">
        <SingleSelect
          aria-label="Industry"
          value={industry}
          onChange={(val) => setIndustry(val?.value)}
          options={options}
        />
      </FormItem>
      <FormItem label="Account category">
        <SingleSelect
          aria-label="Account category"
          value={accountCategory}
          onChange={handleCategoryChange}
          options={accountCategories}
        />
      </FormItem>
      <FormItem label="Dashboard mode">
        <SingleSelect
          aria-label="Dashboard mode"
          value={dashboardMode}
          onChange={(val) => {
            dispatch(setDashboardModeGlobal(val?.value as DashboardMode))
            setDashboardMode(val?.value as DashboardMode)
          }}
          options={dashboardModes}
        />
      </FormItem>
      <FormItem label="Privacy region">
        <MultiSelect
          aria-label="Privacy regions"
          name="privacy regions"
          placeholder="Select privacy region"
          selectedItems={privacyRegions?.map((name) => name) ?? []}
          onApply={(val) => setPrivacyRegions(val as PrivacyRegions)}
          items={privacyRegionsOptions}
        />
      </FormItem>
    </>
  )

  const footer = (
    <div className="flex justify-end text-base">
      <Button
        ghost
        className="mr-4 text-coolGray-400"
        onClick={() => onCloseOrCancel()}
        data-cy="account-drawer-cancel-button"
        data-testid="account-drawer-cancel-button"
      >
        Cancel
      </Button>
      {isEditMode ? (
        <Button
          variant="primary"
          onClick={() => createOrUpdateAccount(account?.id)}
          loading={isLoading}
          disabled={!areAllFieldsFilled}
          data-cy="edit-account-update-button"
          data-testid="edit-account-update-button"
        >
          Update
        </Button>
      ) : (
        <Button
          variant="primary"
          onClick={() => createOrUpdateAccount()}
          loading={isLoading}
          disabled={!areAllFieldsFilled}
          data-cy="add-account-save-button"
          data-testid="add-account-save-button"
        >
          Save
        </Button>
      )}
    </div>
  )

  return (
    <>
      <Drawer
        content={content}
        footer={footer}
        error={hasError ? 'Something went wrong. Please try again.' : undefined}
        title={title}
        isOpen={isOpen}
        onClose={onCloseOrCancel}
      />
      <ConfirmationModal
        title="Change the category?"
        confirmationText="Are you sure you want to change the category? This could impact what the users see when using the app and what they have access to."
        confirmButtonText="Yes"
        cancelButtonText="Cancel"
        open={isChangeCategoryModalShown}
        onConfirm={() => {
          setAccountCategory(selectedAccountCategory)
          setIsChangeCategoryModalShown(false)
        }}
        onCancel={() => setIsChangeCategoryModalShown(false)}
      />
    </>
  )
}

export default AccountsDrawer
