import Dropdown from 'rc-dropdown'

import Button from 'common/components/button/Button'
import { FilterHub } from 'common/icons'

import ImageStatusFilterMenu from './ImageStatusFilterMenu'

const ImageStatusFilter = () => {
  return (
    <Dropdown trigger={['click']} overlay={<ImageStatusFilterMenu />}>
      <Button
        variant="icon"
        prefixIcon={<FilterHub isDefaultColor={false} size={6} />}
      />
    </Dropdown>
  )
}

export default ImageStatusFilter
