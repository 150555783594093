import cx from 'classnames'

import Button from 'common/components/button'
import Tooltip from 'common/components/Tooltip'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { SidebarRightMenu as SidebarRightMenuIcon } from 'common/icons'
import {
  hideRightPanel,
  selectRightPanelVisibility,
  showRightPanel,
} from 'features/unifiedFlow/store/unifiedFlowSlice'

const RightPanelButton = () => {
  const dispatch = useAppDispatch()

  const rightPanelVisibility = useAppSelector(selectRightPanelVisibility)

  if (rightPanelVisibility === 'none') {
    return null
  }

  return (
    <div className="ml-6">
      <Button
        data-testid="hide-right-panel"
        size="small"
        variant="icon"
        className={cx('text-white hover:text-yellow-200', {
          hidden: rightPanelVisibility === 'hidden',
          'hidden lg:block': rightPanelVisibility === 'auto',
        })}
        prefixIcon={
          <Tooltip overlay="Hide panel">
            <SidebarRightMenuIcon
              isDefaultColor={false}
              className="text-white w-5 h-5"
            />
          </Tooltip>
        }
        onClick={() =>
          dispatch(hideRightPanel({ visibility: 'hidden', isUserAction: true }))
        }
      />
      <Button
        data-testid="show-right-panel"
        size="small"
        variant="icon"
        className={cx('text-white hover:text-yellow-200', {
          hidden: rightPanelVisibility === 'visible',
          'lg:hidden': rightPanelVisibility === 'auto',
        })}
        prefixIcon={
          <Tooltip overlay="Show panel">
            <SidebarRightMenuIcon
              isDefaultColor={false}
              className="text-white w-5 h-5"
            />
          </Tooltip>
        }
        onClick={() =>
          dispatch(
            showRightPanel({ visibility: 'visible', isUserAction: true })
          )
        }
      />
    </div>
  )
}

export default RightPanelButton
