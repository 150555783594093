import { ChangeEvent, useState } from 'react'
import cx from 'classnames'
import isEqual from 'lodash/isEqual'

import AvatarColorPicker, { AvatarColor } from './AvatarColorPicker'
import ProfileInput from './ProfileInput'
import ProfileUpdateButtons from './ProfileUpdateButtons'

import styles from './ProfileUpdate.module.css'

type Props = {
  firstname: string
  lastname: string
  email: string
  jobTitle: string
  phoneNumber: string
  avatarColor: AvatarColor
}

const ProfileUpdate = ({
  firstname,
  lastname,
  email,
  jobTitle,
  phoneNumber,
  avatarColor,
}: Props) => {
  const initialFormValues = {
    firstname,
    lastname,
    email,
    jobTitle,
    phoneNumber,
  } as const
  const [formValues, setFormValues] = useState(initialFormValues)
  const [selectedColor, setSelectedColor] = useState<AvatarColor>(avatarColor)

  const replaceForbiddenCharacters = (value: string) =>
    value.replace(/[0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]*$/g, '')

  return (
    <>
      <div className="flex flex-col sm:flex-row font-medium">
        <div className={cx('flex-1 grid gap-x-6 gap-y-7', styles.gridForm)}>
          <div className="col-span-1">
            <label>
              First name
              <ProfileInput
                data-cy="firstname-input"
                data-testid="firstname-input"
                value={formValues.firstname}
                handleChange={({
                  target: { value },
                }: ChangeEvent<HTMLInputElement>) => {
                  setFormValues({
                    ...formValues,
                    firstname: replaceForbiddenCharacters(value),
                  })
                }}
              />
            </label>
          </div>
          <div className="col-span-1">
            <label>
              Last name
              <ProfileInput
                data-cy="lastname-input"
                data-testid="lastname-input"
                value={formValues.lastname}
                handleChange={({
                  target: { value },
                }: ChangeEvent<HTMLInputElement>) => {
                  setFormValues({
                    ...formValues,
                    lastname: replaceForbiddenCharacters(value),
                  })
                }}
              />
            </label>
          </div>

          <div className="col-span-2 md:col-span-1">
            <label>
              Email address
              <ProfileInput
                data-cy="email-input"
                data-testid="email-input"
                value={formValues.email}
                type="email"
                handleChange={({
                  target: { value },
                }: ChangeEvent<HTMLInputElement>) => {
                  setFormValues({
                    ...formValues,
                    email: replaceForbiddenCharacters(value),
                  })
                }}
                disabled
              />
            </label>
          </div>
          <div className={cx('col-span-1 relative')}>
            <label>
              Job title
              <ProfileInput
                data-cy="jobtitle-input"
                data-testid="jobtitle-input"
                value={formValues.jobTitle}
                handleChange={({
                  target: { value },
                }: ChangeEvent<HTMLInputElement>) => {
                  setFormValues({
                    ...formValues,
                    jobTitle: replaceForbiddenCharacters(value),
                  })
                }}
              />
            </label>
          </div>
          <div className="col-span-1">
            <label>
              Phone number
              <ProfileInput
                data-cy="phonenumber-input"
                data-testid="phonenumber-input"
                value={formValues.phoneNumber}
                type="tel"
                handleChange={({
                  target: { value },
                }: ChangeEvent<HTMLInputElement>) => {
                  setFormValues({
                    ...formValues,
                    phoneNumber: value,
                  })
                }}
              />
            </label>
          </div>
        </div>
      </div>
      <div className="mt-14 flex justify-between">
        <div>
          <div className="text-coolGray-800 font-medium mb-1">
            Pick your favourite colour (Sets the colour of your avatar)
          </div>
          <div>
            <AvatarColorPicker
              selectedColor={selectedColor}
              onSelect={(color) => setSelectedColor(color)}
            />
          </div>
        </div>

        <ProfileUpdateButtons
          {...formValues}
          avatarColor={selectedColor}
          resetForm={() => {
            setFormValues(initialFormValues)
            setSelectedColor(avatarColor)
          }}
          isHidden={
            isEqual(initialFormValues, formValues) &&
            selectedColor === avatarColor
          }
        />
      </div>
    </>
  )
}

export default ProfileUpdate
