import cx from 'classnames'

import ButtonWithTooltip from 'common/components/ButtonWithTooltip'
import MenuButton, { MenuItem } from 'common/components/menuButton'
import Base from 'common/components/table/cells/Base'
import {
  Bin,
  Check,
  Clipboard,
  MoreLikeThis,
  MoreOptions,
  Refresh,
  Spinner,
} from 'common/icons'
type Props = {
  onCopy: () => void
  onRefresh: () => void
  onDelete: () => void
  onMoreLikeThis: () => void
  isDisabled?: boolean
  isRefreshSuccessful?: boolean
  isMoreLikeThisSuccessful?: boolean
  isRefreshing: boolean
  isMoreLikeThisEnabled?: boolean
  isLoadingMoreLikeThis?: boolean
}

export const ActionCells = ({
  onCopy,
  onRefresh,
  onDelete,
  onMoreLikeThis,
  isDisabled,
  isRefreshing,
  isMoreLikeThisSuccessful,
  isRefreshSuccessful,
  isMoreLikeThisEnabled,
  isLoadingMoreLikeThis,
}: Props) => (
  <Base className="text-coolGray-400">
    {isMoreLikeThisEnabled && (
      <ButtonWithTooltip
        tooltip={true}
        tooltipText="More like this"
        aria-label="More like this"
        data-testid="more-like-this"
        size="small"
        className={cx({ 'text-coolGray-300': isRefreshing })}
        disabled={isRefreshing}
        prefixIcon={
          isMoreLikeThisSuccessful ? (
            <Check isDefaultColor={false} />
          ) : isLoadingMoreLikeThis ? (
            <Spinner className="animate-spin text-maroon-200" />
          ) : (
            <MoreLikeThis isDefaultColor={false} />
          )
        }
        onClick={onMoreLikeThis}
      />
    )}
    <ButtonWithTooltip
      tooltip={true}
      tooltipText={isRefreshing ? 'Refreshing...' : 'Refresh'}
      aria-label="Refresh"
      data-testid="refresh-variant"
      size="small"
      className={cx({ 'text-coolGray-300': isLoadingMoreLikeThis })}
      disabled={isLoadingMoreLikeThis || isDisabled}
      prefixIcon={
        isRefreshSuccessful ? (
          <Check isDefaultColor={false} />
        ) : isRefreshing ? (
          <Spinner className="animate-spin text-maroon-200" />
        ) : (
          <Refresh isDefaultColor={false} />
        )
      }
      onClick={!isRefreshing ? onRefresh : () => {}}
    ></ButtonWithTooltip>
    <MenuButton
      portal
      buttonProps={{
        'aria-label': 'More options',
        'data-testid': 'more-options',
        children: <MoreOptions isDefaultColor={false} />,
        disabled: isRefreshing || isLoadingMoreLikeThis,
      }}
      options={
        <>
          <MenuItem key="1" onClick={onCopy} aria-label="Copy">
            <Clipboard
              isDefaultColor={false}
              className="mr-4 text-coolGray-400"
            />
            Copy to clipboard
          </MenuItem>
          <MenuItem key="2" onClick={() => onDelete()} aria-label="Delete">
            <Bin isDefaultColor={false} className="mr-4 text-coolGray-400" />
            Delete
          </MenuItem>
        </>
      }
    />
  </Base>
)
