import React, { ReactNode } from 'react'

import Button, { ButtonProps } from 'common/components/button'
import ButtonWithDropdown, {
  ButtonOptionsProps,
} from 'common/components/ButtonWithDropdown'
import { useMoveIntercomButton } from 'common/components/intercom/useMoveIntercomButton'

interface Props {
  shouldShowOnlyPrimary?: boolean
  cancelButton?: {
    isDisplayed: boolean
    onClick: () => void
  }
  saveButton?: {
    isDisplayed: boolean
    isDisabled: boolean
    onClick: () => void
    isLoading: boolean
  }
  nextButton: {
    isDisabled: boolean
    onClick?: () => void
    name: string
    icon?: ReactNode
    isLoading: boolean
    variant?: ButtonProps['variant']
    options?: ButtonOptionsProps[]
  }
  nextInfoMessage: React.ReactNode
}

const ActionBar = ({
  cancelButton,
  saveButton,
  nextButton,
  nextInfoMessage,
  shouldShowOnlyPrimary,
}: Props) => {
  useMoveIntercomButton()

  return (
    <>
      <div className="sticky bottom-0 py-4 px-6 bg-gold-40 flex flex-wrap h-auto justify-between shadow-top">
        <div className="flex items-center">
          {!shouldShowOnlyPrimary && (
            <>
              {cancelButton?.isDisplayed && (
                <Button
                  data-cy="cancel-campaign-creation"
                  data-testid="cancel-campaign-creation"
                  variant="danger"
                  className="mr-4 pointer-events-auto"
                  onClick={cancelButton.onClick}
                  ghost
                >
                  Cancel
                </Button>
              )}
              {saveButton?.isDisplayed && (
                <Button
                  data-cy="save-button"
                  data-testid="save-button"
                  variant="success"
                  ghost
                  loading={saveButton?.isLoading}
                  disabled={saveButton?.isDisabled}
                  onClick={saveButton?.onClick}
                >
                  Save
                </Button>
              )}
            </>
          )}
        </div>
        <div className="ml-6 flex flex-wrap items-center justify-end">
          <span className="text-base-700 mr-4 text-base font-medium">
            {nextInfoMessage}
          </span>
          {nextButton.options && nextButton.options.length > 0 ? (
            <ButtonWithDropdown
              data-cy="next-button"
              data-testid="next-button"
              variant="primary"
              loading={nextButton.isLoading}
              disabled={nextButton.isDisabled}
              options={nextButton.options}
              className="hover:bg-maroon-400"
            >
              {nextButton.name}
            </ButtonWithDropdown>
          ) : (
            <Button
              data-cy="next-button"
              data-testid="next-button"
              size="large"
              variant={nextButton.variant || 'primary'}
              prefixIcon={nextButton.icon}
              loading={nextButton.isLoading}
              disabled={nextButton.isDisabled}
              onClick={nextButton.onClick}
            >
              {nextButton.name}
            </Button>
          )}
        </div>
      </div>
    </>
  )
}

export default ActionBar
