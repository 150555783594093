import React from 'react'
import cx from 'classnames'

import Overlay from 'common/components/Overlay'
import { useAppDispatch, useAppSelector } from 'common/hooks/redux'

import { collapseNav } from './navigation/navigationSlice'

interface Props {
  children: React.ReactNode
  hasSidebar?: boolean
  fullPage?: boolean
  className?: string
}

function Content({ children, hasSidebar, fullPage, className }: Props) {
  const dispatch = useAppDispatch()

  const { isNewCampaign } = useAppSelector((state) => state.campaigns)
  const isNavCollapsed = useAppSelector(
    (state) => state.navigation.isNavCollapsed
  )

  return (
    <>
      <div
        className={cx(
          className,
          'w-full flex flex-1 transition-all items-stretch duration-300 ease-in overflow-x-hidden relative bg-gold-40',
          {
            'flex-col': !hasSidebar,
            'lg:pr-0 grid grid-flow-col grid-cols-1 ': hasSidebar,
            'px-6 pt-12 lg:pt-8': !fullPage,
          }
        )}
      >
        <Overlay
          type="global"
          onClick={() => dispatch(collapseNav())}
          className={cx('md:hidden', {
            hidden: isNavCollapsed || isNewCampaign,
          })}
        />
        {children}
      </div>
    </>
  )
}

export default Content
