import { useLayoutEffect, useRef, useState } from 'react'
import cx from 'classnames'

import { useAppSelector } from 'common/hooks/redux'
import { selectRightPanelVisibility } from 'features/unifiedFlow/store/unifiedFlowSlice'

export type RightPaneVisibility = 'auto' | 'hidden' | 'visible' | 'none'

type Props = {
  children: React.ReactNode
  className?: string
  width?: string
  'data-testid'?: string
}

const RightPanel = ({
  children,
  className,
  width = 'w-90',
  'data-testid': dataTestId,
}: Props) => {
  const panelRef = useRef<HTMLDivElement>(null)
  const heightRef = useRef<number>(0)
  const [isPanelFullyOpen, setIsPanelFullyOpen] = useState(false)

  const visibility = useAppSelector(selectRightPanelVisibility)

  useLayoutEffect(() => {
    if (panelRef.current) {
      heightRef.current = panelRef.current.offsetHeight
      const previousSibling = panelRef.current
        .previousElementSibling as HTMLElement
      if (previousSibling) {
        heightRef.current = previousSibling.offsetHeight
      }
    }
  }, [])

  if (visibility === 'none') {
    return null
  }

  return (
    <div
      ref={panelRef}
      onTransitionEnd={() => {
        const result = visibility === 'visible' || visibility === 'auto'
        setIsPanelFullyOpen(result)
      }}
      className={cx(
        `flex flex-col absolute right-0 lg:relative transform transition-all translate-x-0 duration-300 ease-in-out bg-gold-100 ml-6 min-h-full 
          overflow-y-auto shadow-xl lg:shadow-none shadow-offset-left ${width}`,
        {
          'lg:-ml-90 translate-x-full': visibility === 'hidden',
          'translate-x-full lg:translate-x-0': visibility === 'auto',
          'h-0': !isPanelFullyOpen && visibility === 'hidden',
        },
        className
      )}
      data-testid={dataTestId}
    >
      <div className="overflow-auto h-full">{children}</div>
    </div>
  )
}

export default RightPanel
