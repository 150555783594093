import { useMutation } from '@tanstack/react-query'

import { useAppSelector } from 'common/hooks/redux'

import type { UpdateImageStatusRequest } from '../api'
import { updateImageStatus } from '../api'

const useUpdateImageStatusMutation = () => {
  const accountId = useAppSelector((state) => state.authStates.accountId)

  return useMutation({
    mutationFn: (body: UpdateImageStatusRequest) =>
      updateImageStatus(accountId, body),
  })
}

export default useUpdateImageStatusMutation
