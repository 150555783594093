/* eslint-disable max-lines */
import { Role } from '@phrasee/phrasee-typings/typings/user/user'
import {
  createAsyncThunk,
  createSelector,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { RootState } from 'redux/store'

import {
  ApiMember,
  createTeam,
  deleteTeam,
  deleteTeamMember,
  getTeams,
  Member,
  Team,
  updateTeam,
} from 'common/api/teamsApi'
import { errorToast, successToast } from 'common/components/toastNotification'
import { changeAccountId } from 'features/auth/store/authSlice'

import {
  activateOrDeactivateUser,
  CreateUserResponse,
  deleteUser,
  getAssignableRoles,
  GetUserResponse,
  getUsers,
  InviteUser,
  InviteUserResponse,
  inviteUsers,
  UpdatedUserResponse,
  updateUser,
  updateUserRole,
  User,
} from '../all/api'

export interface DashboardState {
  teams: Team[]
  users: User[]
  assignableRoles: Role[]
  isLoading: boolean
  hasTeamTabError: boolean
  hasAllTabError: boolean
  hasDeactivatedTabError: boolean
  isTeamModalVisible: boolean
  isUserModalVisible: boolean
}

export const initialState: DashboardState = {
  teams: [],
  users: [],
  assignableRoles: [],
  isLoading: true,
  hasTeamTabError: false,
  hasAllTabError: false,
  hasDeactivatedTabError: false,
  isTeamModalVisible: false,
  isUserModalVisible: false,
}

export const initializePage = createAsyncThunk<
  { teams: Team[]; users: User[]; assignableRoles: Role[] },
  { showNewTeamPage?: boolean },
  { state: RootState }
>('admin/users/initializePage', async (showNewTeamPage, { getState }) => {
  const state = getState()
  const { accountId, user_id } = state.authStates
  const users = (await getUsers(accountId)).data
  const teamsResult = (await getTeams(accountId)).data
  const teams = showNewTeamPage
    ? teamsResult.map(mapApiTeamToTeam)
    : (teamsResult as Team[])
  const assignableRoles = (await getAssignableRoles(user_id)).data.data

  const getFilteredUsers = (users: GetUserResponse[], roles: string[]) => {
    return users.filter((user) =>
      user?.roles?.some((role: string) => roles.includes(role))
    )
  }

  return {
    teams,
    assignableRoles: assignableRoles.canAssign,
    users: getFilteredUsers(users, assignableRoles.canAssign).map((user) => ({
      id: user._id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      phoneNumber: user.phoneNumber,
      jobTitle: user.jobTitle,
      projectIds: user.projectIds,
      role: user.role,
      roles: user.roles,
      isSuspended: user.isSuspended,
      createdAt: user.created,
      lastLoginAt: user.last_login_at,
    })),
  }
})

export const clickCreateTeam = createAsyncThunk<
  Team,
  { team: Omit<Team, 'id'>; showNewTeamPage: boolean },
  { state: RootState }
>(
  'admin/users/clickCreateTeam',
  async ({ team, showNewTeamPage }, { getState }) => {
    const state = getState()
    const { accountId } = state.authStates
    const { members, name } = team
    const result = await createTeam({
      accountId,
      members,
      name,
      showNewTeamPage,
    })
    return showNewTeamPage
      ? mapApiTeamToTeam(result.data)
      : (result.data as Team)
  }
)

export const clickUpdateTeam = createAsyncThunk<
  Team | undefined,
  { team: Team; showNewTeamPage: boolean },
  { state: RootState }
>(
  'admin/users/clickUpdateTeam',
  async ({ team, showNewTeamPage }, { getState }) => {
    const state = getState()
    const { accountId } = state.authStates

    try {
      const result = await updateTeam({
        accountId,
        teamId: team.id,
        name: team.name,
        members: team.members,
        showNewTeamPage,
      })
      return showNewTeamPage
        ? mapApiTeamToTeam(result.data)
        : (result.data as Team)
    } catch (error: any) {
      if (error.response.type >= 500) {
        throw error
      }
    }
  }
)

export const clickDeleteTeam = createAsyncThunk<
  Team[],
  { teamId: string; showNewTeamPage: boolean },
  { state: RootState }
>(
  'admin/users/clickDeleteTeam',
  async ({ teamId, showNewTeamPage }, { getState }) => {
    const state = getState()
    const { accountId } = state.authStates
    const result = await deleteTeam({ accountId, teamId })
    return showNewTeamPage
      ? result.data.map(mapApiTeamToTeam)
      : (result.data as Team[])
  }
)

export const clickDeleteUser = createAsyncThunk<
  User,
  string,
  { state: RootState }
>('admin/users/clickDeleteUser', async (userId, { getState }) => {
  const state = getState()
  const { accountId } = state.authStates
  alert('not implemented yet, the page will refresh')
  window.location.reload()
  const result = await deleteUser({ accountId, userId })
  return result.data
})

export const clickDeactivateUser = createAsyncThunk<
  boolean[],
  string[],
  { state: RootState }
>('admin/users/clickDeactivateUser', async (userIds, { getState }) => {
  const state = getState()
  const { accountId } = state.authStates

  const result = await Promise.all(
    userIds.map((userId) =>
      activateOrDeactivateUser({ accountId, userId, action: 'deactivate' })
    )
  )
  return result.map(({ data }) => data.success)
})

export const clickReactivateUser = createAsyncThunk<
  boolean,
  string,
  { state: RootState }
>('admin/users/clickReactivateUser', async (userId, { getState }) => {
  const state = getState()
  const { accountId } = state.authStates

  const result = await activateOrDeactivateUser({
    accountId,
    userId,
    action: 'activate',
  })
  successToast('User Reactivated')
  return result.data.success
})

export const clickDeleteTeamMember = createAsyncThunk<
  Team,
  { teamId: string; id: string; showNewTeamPage: boolean },
  { state: RootState }
>(
  'admin/users/clickDeleteTeamMember',
  async ({ teamId, id, showNewTeamPage }, { getState }) => {
    const state = getState()
    const { accountId } = state.authStates
    const result = await deleteTeamMember({ accountId, teamId, id })
    return showNewTeamPage ? mapApiTeamToTeam(result.data) : result.data
  }
)

export const clickUpdateTeamName = createAsyncThunk<
  Team,
  {
    teamId: string
    name: string
    members: Member[] | string[] | ApiMember[]
    showNewTeamPage: boolean
  },
  { state: RootState }
>('admin/users/clickUpdateTeamName', async (params, { getState }) => {
  const { teamId, name, members } = params
  const state = getState()
  const { accountId } = state.authStates
  const result = await updateTeam({
    accountId,
    teamId,
    name,
    members,
    showNewTeamPage: params.showNewTeamPage,
  })
  return params.showNewTeamPage
    ? mapApiTeamToTeam(result.data)
    : (result.data as Team)
})

const mapCreateUserResponseToUser = (user: CreateUserResponse): User => ({
  id: user._id,
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email,
  phoneNumber: user.phoneNumber,
  jobTitle: user.jobTitle,
  projectIds: user.projectIds,
  role: user.role,
  roles: user.roles,
  isSuspended: user.blocked,
  createdAt: user.created,
  lastLoginAt: user.last_login_at,
})

const mapApiTeamToTeam = (apiTeam: Team): Team => {
  if (typeof apiTeam.members[0] === 'string') {
    return apiTeam
  }
  return {
    ...apiTeam,
    members: apiTeam.members.map((apiMember) => ({
      ...apiMember,
      id: apiMember._id,
      firstName: apiMember.fname,
      lastName: apiMember.lname,
    })),
  }
}

export const mapUserToUpdateUserPayload = (
  user: Partial<User>
): Partial<UpdatedUserResponse> &
  Pick<Partial<UpdatedUserResponse>, 'email' | '_id'> => ({
  _id: user.id,
  first_name: user.firstName,
  last_name: user.lastName,
  email: user.email,
  phonenumber: user.phoneNumber,
  job_title: user.jobTitle,
  assigned_projects: user.projectIds,
  roles: user.roles,
})

export const mapUpdatedUserResponseToUser = (
  user: UpdatedUserResponse
): User => ({
  id: user._id,
  firstName: user.first_name,
  lastName: user.last_name,
  email: user.email,
  phoneNumber: user.phonenumber,
  jobTitle: user.job_title,
  projectIds: user.assigned_projects,
  isSuspended: user.isSuspended,
  createdAt: user.createdAt,
  role: user.role,
  roles: user.roles,
})

export const changeUserRole = createAsyncThunk<
  User,
  { userId: string; roles: string[] },
  { state: RootState }
>('admin/users/changeUserRole', async ({ userId, roles }, { getState }) => {
  const state = getState()
  const { accountId } = state.authStates
  const result = await updateUserRole({ accountId, userId, roles })
  return mapCreateUserResponseToUser(result.data)
})

export const clickSaveUser = createAsyncThunk<
  User,
  Partial<User> & Pick<User, 'email'>,
  { state: RootState }
>('admin/users/clickSaveUser', async (params, { getState }) => {
  const user = params
  const mappedUser = mapUserToUpdateUserPayload(user)
  const state = getState()
  const { accountId } = state.authStates
  const result = await updateUser({
    accountId,
    user: mappedUser,
  })
  return mapUpdatedUserResponseToUser(result.data)
})

export const clickInviteUsers = createAsyncThunk<
  User[],
  InviteUser[],
  { state: RootState }
>('admin/users/clickInviteUsers', async (users, { getState }) => {
  const state = getState()
  const { accountId } = state.authStates
  const calls: Promise<AxiosResponse<InviteUserResponse>>[] = users.map(
    (user) => inviteUsers({ accountId, user })
  )

  const results = await Promise.all(calls)
  return results.map((r) => {
    const {
      email,
      assigned_projects: projectIds,
      first_name: firstName,
      _id: id,
      last_name: lastName,
      role,
      roles,
      blocked: isSuspended,
      created: createdAt,
    } = r.data
    return {
      email,
      projectIds,
      firstName,
      lastName,
      id,
      location: '',
      role,
      roles,
      isSuspended,
      createdAt,
    }
  })
})

export const userSlice = createSlice({
  name: 'admin/users',
  initialState,
  reducers: {
    showAddTeamModal: (state) => {
      state.isTeamModalVisible = true
    },
    showAddUserModal: (state) => {
      state.isUserModalVisible = true
    },
    hideAddTeamModal: (state) => {
      state.isTeamModalVisible = false
    },
    hideAddUserModal: (state) => {
      state.isUserModalVisible = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeAccountId.fulfilled, () => initialState)
      .addCase(initializePage.rejected, (state) => {
        state.teams = []
        state.isLoading = false
        state.hasTeamTabError = true
        state.assignableRoles = []
      })
      .addCase(initializePage.fulfilled, (state, action) => {
        state.isLoading = false
        state.teams = action.payload.teams
        state.users = action.payload.users
        state.assignableRoles = action.payload.assignableRoles
        state.hasTeamTabError = false
      })
      .addCase(clickDeleteTeam.fulfilled, (state, action) => {
        state.isLoading = false
        state.teams = action.payload
      })
      .addCase(clickDeleteUser.fulfilled, (state, action) => {
        state.isLoading = false
        state.users = state.users.filter(
          (user) => user.id !== action.payload.id
        )
      })
      .addCase(changeUserRole.fulfilled, (state, action) => {
        const userIndex = state.users.findIndex(
          (user) => user.id === action.payload.id
        )
        state.users[userIndex].role = action.payload.role
        state.users[userIndex].roles = action.payload.roles
      })
      .addCase(clickSaveUser.fulfilled, (state, action) => {
        state.isLoading = false
        const user = action.payload
        const userIndex = state.users.findIndex(({ id }) => id === user.id)
        if (userIndex !== -1) {
          state.users[userIndex] = user
        }
      })
      .addCase(clickUpdateTeamName.fulfilled, (state, action) => {
        const id = action.meta.arg.teamId
        const team = action.payload
        state.isLoading = false
        const teamIndex = state.teams.findIndex((team) => team.id === id)
        if (teamIndex !== -1) {
          state.teams[teamIndex] = team
        }
      })
      .addCase(clickUpdateTeam.fulfilled, (state, action) => {
        const id = action.meta.arg.team.id
        const team = action.payload
        state.isLoading = false
        if (team) {
          const teamIndex = state.teams.findIndex((team) => team.id === id)
          if (teamIndex !== -1) {
            state.teams[teamIndex] = team
          }
        }
      })
      .addCase(clickDeleteTeamMember.fulfilled, (state, action) => {
        const id = action.meta.arg.teamId
        const team = action.payload
        state.isLoading = false
        const teamIndex = state.teams.findIndex((team) => team.id === id)
        if (teamIndex !== -1) {
          state.teams[teamIndex] = team
        }
      })
      .addCase(clickCreateTeam.fulfilled, (state, action) => {
        const team = action.payload
        state.isLoading = false
        state.teams.push(team)
      })
      .addCase(clickDeactivateUser.fulfilled, (state, action) => {
        state.isLoading = false
        const usersDeactivated = action.meta.arg
        const userIndexPerId: Record<string, number> = state.users.reduce(
          (previousValue, currentValue, index) => {
            return {
              ...previousValue,
              [currentValue.id]: index,
            }
          },
          {}
        )
        usersDeactivated.forEach((user) => {
          const index = userIndexPerId[user]
          state.users[index].isSuspended = true
        })
        usersDeactivated.forEach((user) => {
          state.teams.forEach((team) => {
            if (typeof team.members[0] === 'string') {
              return
            }
            team.members = (team.members as Member[]).filter(
              (member) => member.id !== user
            )
          })
        })
      })
      .addCase(clickReactivateUser.fulfilled, (state, action) => {
        state.isLoading = false
        const userIdReactivated = action.meta.arg
        const index = state.users.findIndex(
          ({ id }) => id === userIdReactivated
        )
        if (index !== -1) {
          const reactivatedUser = state.users[index]
          state.users[index].isSuspended = false
          state.teams.forEach((team) => {
            ;(team.members as Member[]).push({
              id: userIdReactivated,
              email: reactivatedUser.email,
              firstName: reactivatedUser.firstName,
              lastName: reactivatedUser.lastName,
              roles: reactivatedUser.roles,
              blocked: reactivatedUser.isSuspended,
            })
          })
        }
      })
      .addCase(clickInviteUsers.fulfilled, (state, action) => {
        state.isUserModalVisible = false
        state.isLoading = false
        const users = action.payload
        state.users.unshift(...users)
        successToast('Users invited successfully')
      })
      .addCase(clickInviteUsers.rejected, (state) => {
        state.isLoading = false
        errorToast('Something went wrong. Please try again.')
      })
      .addMatcher(
        isAnyOf(
          initializePage.pending,
          clickCreateTeam.pending,
          clickDeleteTeam.pending,
          clickUpdateTeam.pending,
          clickUpdateTeamName.pending,
          clickDeleteTeamMember.pending,
          clickDeactivateUser.pending,
          clickReactivateUser.pending,
          clickDeleteUser.pending,
          clickInviteUsers.pending
        ),
        (state) => {
          state.isLoading = true
        }
      )
      .addMatcher(
        isAnyOf(
          clickDeleteUser.rejected,
          clickDeactivateUser.rejected,
          clickReactivateUser.rejected,
          changeUserRole.rejected
        ),
        (state) => {
          state.isLoading = false
          state.hasAllTabError = true
        }
      )
      .addMatcher(
        isAnyOf(
          clickCreateTeam.rejected,
          clickDeleteTeam.rejected,
          clickUpdateTeam.rejected,
          clickUpdateTeamName.rejected,
          clickDeleteTeamMember.rejected
        ),
        (state) => {
          state.isLoading = false
          state.hasTeamTabError = true
        }
      )
  },
})

const selectAllUsers = (state: RootState) => state.adminUsers.users

export const selectUsers = createSelector(selectAllUsers, (users) =>
  users.filter((user) => !user.isSuspended)
)

export const selectDeactivatedUsers = createSelector(selectAllUsers, (users) =>
  users.filter((user) => user.isSuspended)
)

export const {
  showAddTeamModal,
  hideAddTeamModal,
  showAddUserModal,
  hideAddUserModal,
} = userSlice.actions
export default userSlice.reducer
