import { ComponentElement } from '@phrasee/phrasee-typings'
import cx from 'classnames'

import { useAppDispatch, useAppSelector } from 'common/hooks/redux'
import { isComponentElement } from 'features/futurama/api/interfaces'
import { hideRightPane } from 'features/futurama/store/contentSlice'

import CardIcon from '../components/CardIcon'
import RightPane from '../components/RightPane'
import { getElementHasVariants } from '../helpers'
import { useContent } from '../hooks'

import PreviewCard from './PreviewCard'

const MultiStepPreview = () => {
  const dispatch = useAppDispatch()
  const { content } = useContent()

  const selectedElementId = useAppSelector(
    (state) => state.content.selectedElementId
  )
  const rightPaneVisibility = useAppSelector(
    (state) => state.content.rightPaneVisibility
  )

  const contentElements = content?.elements || []

  return (
    <RightPane
      visibility={rightPaneVisibility}
      scrollableParentId="main-content-page"
      onOutsideClick={() => dispatch(hideRightPane())}
    >
      <div className="text-xl font-medium text-coolGray-800 mt-6 ml-4">
        Preview
      </div>
      {contentElements.map((element) => {
        const isSelected = isComponentElement(element)
          ? element.element_id === selectedElementId
          : element.element_id === selectedElementId ||
            element.elements.some(
              (nestedElement) => nestedElement.element_id === selectedElementId
            )
        const isEmpty =
          !getElementHasVariants(element) && element.status === 'disabled'

        const getState = () => {
          if (element.element_type === 'template' && !isEmpty) {
            return 'template'
          } else if (element.element_type === 'template' && isEmpty) {
            return 'templateEmpty'
          } else {
            return 'component'
          }
        }
        const state = getState()

        return (
          <div
            className={cx('flex flex-col p-4 m-6 border', {
              'bg-coolGray-100 border-maroon-400': isSelected,
              'bg-coolGray-50 border-transparent': !isSelected,
            })}
            key={element.element_id}
          >
            <div className="flex items-center pb-4 border-dashed border-b-1 border-coolGray-300 w-full">
              <CardIcon icon={element.icon || ''} />
              <div className="ml-4 text-coolGray-800 font-medium text-base">
                {element.display_name}
              </div>
            </div>
            {
              {
                template: (
                  <>
                    {element.element_type === 'template' &&
                      element.elements.map((nestedElement) => {
                        const value = (nestedElement as ComponentElement).value
                        return (
                          <PreviewCard
                            key={nestedElement.element_id}
                            title={
                              nestedElement.display_name || nestedElement.name
                            }
                            type="step"
                            icon={nestedElement.icon || ''}
                            text={value || undefined}
                          />
                        )
                      })}
                  </>
                ),
                templateEmpty: (
                  <PreviewCard
                    key={element.element_id}
                    title={element.display_name || element.name}
                    type="channel"
                    icon={element.icon || ''}
                    isEmpty={isEmpty}
                  />
                ),
                component: (
                  <PreviewCard
                    title={element.display_name}
                    text={(element as ComponentElement).value || undefined}
                    icon={element.icon || ''}
                    isEmpty={isEmpty}
                    type="channel"
                  />
                ),
              }[state]
            }
          </div>
        )
      })}
    </RightPane>
  )
}

export default MultiStepPreview
