import { useMemo } from 'react'
import cx from 'classnames'
import filter from 'lodash/filter'
import { ResponseVariant } from 'workflow/Insights/Insights'

import { Column } from 'common/components/table'
import TableWidget from 'common/components/widget/tableWidget'
import { useAppSelector } from 'common/hooks/redux'
import useTableActions from 'common/hooks/useTableActions'
import ActionCell from 'features/unifiedFlow/contentPage/components/ActionsCell'
import { CurrentUsersBanner } from 'features/unifiedFlow/contentPage/components/CurrentUsersBanner'
import {
  useContent,
  useSelectedComponentElement,
} from 'features/unifiedFlow/contentPage/hooks'
import { getWsTopic } from 'features/unifiedFlow/contentPage/hooks/websocketMessage/helpers'

import ApprovalsTableActionButtons from './ApprovalsTableActionButtons'

type ResponseVariantExtended = ResponseVariant & {
  isLoading: boolean
  isRefreshed: boolean
  category: string | undefined
}

type Props = {
  tweakSuccessVariantIds: string[]
}

const ApprovalsTable = ({ tweakSuccessVariantIds }: Props) => {
  const { content } = useContent()
  const { data: selectedElement } = useSelectedComponentElement()

  const subjectLines = useAppSelector(
    (state) => state.campaignStates.subjectLines
  )
  const variantsToBeApproved = useAppSelector(
    (state) => state.campaignStates.variantsToBeApproved
  )

  const variantsToBeApprovedFiltered = useMemo(() => {
    const mappedVariants = filter(subjectLines, ({ _id }) =>
      variantsToBeApproved.includes(_id)
    ).map((variant) => {
      let isLoading = false
      const isModifiedByUser = !!variant?.why_tweak
      if (content && selectedElement) {
        const tweakWsTopic = getWsTopic({
          action: 'tweakVariant',
          data: {
            contentId: content._id,
            elementId: selectedElement.element_id,
            variantId: variant._id,
          },
        })
        const rejectWsTopic = getWsTopic({
          action: 'rejectVariant',
          data: {
            contentId: content._id,
            elementId: selectedElement.element_id,
            variantId: variant._id,
          },
        })
        isLoading =
          content.ws_messages?.some(
            (message) =>
              message.wsTopic === rejectWsTopic ||
              message.wsTopic === tweakWsTopic
          ) || false
      }
      return {
        ...variant,
        isLoading,
        isRefreshed: tweakSuccessVariantIds.includes(variant._id),
        category: isModifiedByUser ? 'User modified' : undefined,
      }
    })
    return mappedVariants?.toSorted(
      (a, b) => (a.category ? 1 : 0) - (b.category ? 1 : 0)
    )
  }, [
    content,
    selectedElement,
    subjectLines,
    tweakSuccessVariantIds,
    variantsToBeApproved,
  ])

  const isFirstRowGrouped = !!variantsToBeApprovedFiltered[0]?.category

  const isApproveButtonDisabled = variantsToBeApprovedFiltered.some(
    (variant) => variant.isLoading
  )

  const elementId = selectedElement?.element_id
  const { onRequestChange } = useTableActions()

  const columns: Column<ResponseVariantExtended>[] = useMemo(
    () => [
      {
        Header: `Variants to approve (${variantsToBeApproved.length})`,
        accessor: 'text',
        disableSortBy: true,
        disableFilters: true,
      },
      {
        Header: 'Actions',
        id: 'actions',
        accessor: '_id',
        disableSortBy: true,
        align: 'right',
        disableFilters: true,
        width: 140,
        className: 'flex-none',
        Cell: ({ row }) => (
          <ActionCell
            isTweaking={row.original.isLoading}
            isSuccessful={row.original.isRefreshed}
            onRequestChange={() => onRequestChange(row.original)}
          />
        ),
      },
      {
        accessor: 'category',
      },
    ],
    [onRequestChange, variantsToBeApproved.length]
  )

  return (
    <>
      <CurrentUsersBanner />
      <TableWidget.Widget<ResponseVariant>
        key={elementId}
        data={variantsToBeApprovedFiltered}
        columns={columns}
        autoResetPage={false}
        headerClassName={cx({
          'bg-transparent text-base-700': isFirstRowGrouped,
        })}
        initialState={{ groupBy: ['category'], hiddenColumns: ['category'] }}
      >
        <TableWidget.ActionButtons>
          <ApprovalsTableActionButtons
            variants={variantsToBeApprovedFiltered}
            isApproveButtonDisabled={isApproveButtonDisabled}
          />
        </TableWidget.ActionButtons>
      </TableWidget.Widget>
    </>
  )
}

export default ApprovalsTable
