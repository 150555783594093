import type { Variant } from '@phrasee/phrasee-typings/Graphql/interfaces'

import { ImageEntity } from 'common/components/images/api/api'
import useGetImagesByIdsQuery from 'common/components/images/api/queries/useGetImagesByIdsQuery'
import { removeMaybe } from 'common/helpers/typeUtils'

const useCampaignImages = (
  variants: Variant[]
): { [k: string]: ImageEntity } => {
  const imageIds = variants?.map(({ image_id }) => image_id).filter(removeMaybe)

  const { data } = useGetImagesByIdsQuery(imageIds)

  return data.reduce((acc, item) => {
    return {
      ...acc,
      [item.id]: item,
    }
  }, {})
}

export default useCampaignImages
