import React from 'react'
import { Role } from '@phrasee/phrasee-typings/typings/user/user'

import SingleSelect from 'common/components/singleSelect'
import { userRolePerKey } from 'common/helpers/user'

type Props = {
  assignableRoles?: Role[]
} & Omit<
  React.ComponentProps<typeof SingleSelect>,
  'options' | 'data-cy' | 'data-testid' | 'name'
>

const RoleSelect = ({ assignableRoles, ...rest }: Props) => {
  return (
    <SingleSelect
      data-cy="role-select"
      data-testid="role-select"
      name="role"
      options={assignableRoles?.map((role) => {
        return { value: role, label: userRolePerKey[role] }
      })}
      {...rest}
    />
  )
}

export default RoleSelect
