import { Element } from '@phrasee/phrasee-typings'
import { useMutation } from '@tanstack/react-query'

import { errorToast } from 'common/components/toastNotification'
import { useAppSelector } from 'common/hooks/redux'
import { updateElement } from 'features/unifiedFlow/api/api'
import { getAllComponentElements } from 'features/unifiedFlow/contentPage/helpers'
import { useContent } from 'features/unifiedFlow/contentPage/hooks'

const useAddElementImagesMutation = () => {
  const { content } = useContent()
  const accountId = useAppSelector((state) => state.authStates.accountId)

  const selectedElementId = useAppSelector(
    (state) => state.unifiedFlow.selectedElementId
  )

  return useMutation({
    mutationFn: ({
      imageIds,
      isVariantOnly,
    }: {
      imageIds: string[]
      isVariantOnly: boolean
    }) => {
      const componentElements = getAllComponentElements(content)

      const element = componentElements?.find(
        (element) => element.element_id === selectedElementId
      )

      const userInputForm = componentElements?.find(
        (element) => element.element_id === selectedElementId
      )?.user_input?.user_input_form

      const updatedUserInputForm = userInputForm?.map((item) => {
        if (item.type === 'image-multi') {
          return { ...item, value: imageIds }
        }
        if (item.type === 'toggle') {
          return { ...item, value: isVariantOnly }
        }

        return item
      })

      const updatedElement = {
        ...element,
        user_input: { user_input_form: updatedUserInputForm },
      } as Partial<Element>

      return updateElement({
        contentId: content!._id,
        elementId: selectedElementId as number,
        accountId,
        updatedElement,
      })
    },
    onError: () => {
      errorToast('Something went wrong. Please try again.')
    },
  })
}

export default useAddElementImagesMutation
