import cx from 'classnames'

import { CloseSmall } from 'common/icons'

import Button from '../button'
import Tooltip from '../Tooltip'

type Props<T> = {
  label: string
  value: T
  maxLength?: number
  'data-testid'?: string
  isDisabled?: boolean
  className?: string
  onRemoveClick?: (value: T) => void
}

const Tag = <T extends string>({
  label,
  value,
  maxLength,
  'data-testid': dataTestId,
  isDisabled,
  className,
  onRemoveClick,
}: Props<T>) => {
  return (
    <Tooltip
      overlay={label}
      key={value}
      show={maxLength === undefined ? false : label.length > maxLength}
      overlayStyle={{ maxWidth: 226 }}
    >
      <div
        data-cy="tag"
        data-testid={dataTestId && `${dataTestId}-${value}`}
        key={value}
        className={cx(
          `pl-2 flex items-center text-sm font-medium border h-6`,
          {
            'text-maroon-500 border-maroon-400 bg-maroon-40 hover:border-maroon-500':
              !isDisabled,
          },
          {
            'text-coolGray-400 border-coolGray-300 bg-coolGray-50': isDisabled,
          },
          { 'pr-2': !onRemoveClick },
          className
        )}
      >
        <span data-cy="tag-label" data-testid="tag-label">
          {maxLength !== undefined && label.length > maxLength
            ? `${label.slice(0, maxLength)}...`
            : label}
        </span>
        {onRemoveClick && (
          <Button
            aria-label="Remove tag"
            data-cy="remove-tag-button"
            data-testid="remove-tag-button"
            size="small"
            ghost
            variant="icon"
            className="pl-0 pr-0"
            onClick={() => onRemoveClick(value)}
            disabled={isDisabled}
            suffixIcon={
              <CloseSmall
                data-testid="remove-tag-icon"
                data-cy="remove-tag-icon"
                isDefaultColor={false}
                className={cx({
                  'hover:text-maroon-400 text-maroon-200': !isDisabled,
                })}
              />
            }
          />
        )}
      </div>
    </Tooltip>
  )
}

export default Tag
