import Button from 'common/components/button/Button'
import { Add as AddIcon } from 'common/icons'

type Props = {
  showImageGallery: () => void
}

const EmptyImageCard = ({ showImageGallery }: Props) => {
  return (
    <div className="flex w-78 h-78 rounded-sm p-4 border-1 border-gold-200">
      <div className="flex items-center justify-center bg-gold-50 w-full h-full rounded-sm">
        <Button
          onClick={showImageGallery}
          size="small"
          className="uppercase underline text-gold-600"
          variant="link"
          prefixIcon={
            <AddIcon
              isDefaultColor={false}
              size={4}
              className="text-base-700"
            />
          }
        >
          Add image
        </Button>
      </div>
    </div>
  )
}

export default EmptyImageCard
