/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'

import Loader from 'common/components/loaders/Loader'
import { errorToast } from 'common/components/toastNotification'

import apiUtil from '../../../workflow/utils/Api'

declare const window: any

const { REACT_APP_SFMC_REDIRECT, REACT_APP_BASE_URL, REACT_APP_ENVIRONMENT } =
  process.env

const SfmcLogin = () => {
  useEffect(() => {
    const getUserCredential = async () => {
      // Slightly different logic is required for CA, as the clients install it individually in their SFMC accounts, with different details for each
      if (REACT_APP_ENVIRONMENT === 'ca') {
        const client = window.location.search.split('=')[1]
        try {
          const userCredentials = await apiUtil(
            `auth/sfmc-user-details/?client=${client}`,
            { method: 'GET' }
          )
          if (!userCredentials) {
            errorToast('Invalid client', { autoClose: 20000 })
          } else {
            // We have to remove the token from the localStorage
            // eslint-disable-next-line
            window.location = `${userCredentials.data.auth_uri}v2/authorize?response_type=code&client_id=${userCredentials.data.client_id}&redirect_uri=${REACT_APP_BASE_URL}/sfmc-callback/?client=${client}`
          }
        } catch (err) {
          errorToast('Invalid client', { autoClose: 20000 })
        }
      } else {
        window.location = REACT_APP_SFMC_REDIRECT
      }
    }

    getUserCredential()
  }, [])

  return <Loader isFullPage />
}

export default SfmcLogin
